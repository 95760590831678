import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import Calendar from 'react-calendar';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import moment from 'moment';

class UtmTracker extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartDate: null,
					searchEndDate: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					ajaxLoaded: false,
					checkInfoOpen: false
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	document.addEventListener('mousedown', this.checkInfoOpen);
}
 
componentWillUnmount() {
	document.removeEventListener('mousedown', this.checkInfoOpen);
}
  
changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchSessionDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	console.log(mod);
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchSessionDetails(mod, this.state.page_num_count);
		}
}


fetchSessionDetails = (page_id, countId, searchText = this.state.searchBy) => {
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	$('.main_loader').attr({'style':'display:flex'});
	 axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getAllUtmValues", { page_id: page_id, countId: countId, searchText: searchText}).then(res => {
		 this.setState({
			 billing_info: res.data.responseData['users']
			 }, ()=>{ console.log('-------info', this.state.billing_info) });
		 $('.main_loader').hide();
	 }).catch(err =>{
	  console.log('----------there is problem------------', err);
	});

}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ showSearch: !this.state.showSearch, searchStartDate: null, searchEndDate: null, searchBy: '' });
			
			this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className="pay_head_title">UTM Tracker</div>
								</div>
								<div className="show_pay_page">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
											<option value="100000">All</option>
										</select>
									 </span>
									{/* this.state.billing_info.length > 0 &&
										<div className="exportDiv">
											<a href="#" id="exportcsvcommunity"><div className="rose_btn" onClick={()=> { this.exportCsv(); }}>Export CSV</div></a>
										</div>
									*/}
								</div>
								{/*
								<div className="show_search_opt">
									{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-4' : ' col-xl-6')}>
														<input type="text" className="form-control" placeholder="Search for email" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
													</div>
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-8' : ' col-xl-6')}>
														<div className="parent-row mb-3 full_width">				
															<div className="row mx-0 row1 d-flex">
																<div className="col-md-12 d-flex flex-wrap align-items-center">	
																	<div className="filter_item">
																		<span className="searh_span">From</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchStartDate} onChange={date => this.setState({searchStartDate: date})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchEndDate} onChange={date => this.setState({searchEndDate: date})} />
																	</div>
																</div>
															</div>
														</div>
												</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
								</div>
								*/}
								{ this.state.billing_info.length > 0 && 
									<table className="billing_table">
										<thead>
											<tr>
												<th>Medium</th>
												<th>Campaign</th>
												<th>UTM id</th>
												<th>Source</th>
												<th>User email</th>
											</tr>
										</thead>
										<tbody> 
											{ this.state.billing_info.map((item, key) =>
												<tr key={key}>
													<td>{item['utm_medium']}</td>
													<td>{item['utm_campaign']}</td>
													<td>{item['utm_id']}</td>
													<td>{item['utm_source']}</td>
													<td>{item['email']}</td>													
												</tr>
											
											) }
										</tbody>
									</table>
								}
							</div>
						</div>
					</div>
			</div>		
				
				
			</div>
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(UtmTracker);