import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import Calendar from 'react-calendar';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import { deleteCookie } from '../auth/Logout';
import SupportContainer from '../home/SupportContainer';
import moment from 'moment';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class member extends Component {
  
  constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state={
        sessionData:[],
        firstName:'',
        firstName1:'',
        lastName:'',
        email:'',
        memberId:'',
        msgSuccess:'',
        getMemberId:0,
        hostName:'',
		endDateTemp: new Date(),
		enddate: '',
		limitedMember: false,
		importStatus: '',
		enableImport: false,
		userImports: [],
		userAvatar: '',
		channelCreateUser: false,
		mainFaqs: [],
		channelSubdomain: '',
		searchValue: '',
		emailMessage: '',
		shownLength: 0,
		dataLength: 0,
		page_count: 1,
		supportPageName: 'Channel Members',
		supportPageApi: '/member',
		userTypeFaqs: 2,
		errorTime: false,
		emailError: false,
		firstNameError: false,
		lastNameError: false,
		editEmailError: false,
		editFirstNameError: false,
		editLastNameError: false,
    }
    this.validator = new SimpleReactValidator();  
    this.editValidator = new SimpleReactValidator();  
    this.deleteCookie = deleteCookie.bind(this);
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}
 
componentDidMount(){
	this.setState({userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image});
    let hostName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName
    this.setState({
        hostNameData:hostName
    });
    //console.log("MANMO=====>>", hostName);
    // if(localStorage.getItem('userData')){
      
    //     let ak = JSON.parse(localStorage.getItem('userData'));
    //     console.log("MANMO=====>>", ak);
    // }


	this.getMembers();
	this.getChannelSub();
  };
 

changeEndDate = (date) =>
{
	this.setState({endDateTemp: date})
}

loadMorePrevious = (e) => {
	let count_main = this.state.page_count + 1;
	
	this.setState({
		page_count: count_main,
	},()=>{ this.getMembers(); console.log(this.state.page_count, 'pageCount'); });
}

applyEndDate = () =>
{
	let getFullYear=new Date(this.state.endDateTemp).getFullYear();
	let getMonth=(new Date(this.state.endDateTemp).getMonth()+1);
	let getDate=new Date(this.state.endDateTemp).getDate();
	
	if ( getMonth < 10 ) { getMonth = '0'+getMonth; }
	if ( getDate < 10 ) { getDate = '0'+getDate; }
	this.setState({ enddate: getFullYear+'-'+getMonth+'-'+getDate, errorTime: false })
	$("#calenderModelEndDate").attr({'style':'display:none'})
}

getMembers=(e)=>{
    $('.main_loader').attr({'style':'display:flex'});
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
    //channdlId=1;
    if(!channelId){
        console.log('======sas=======',localStorage.getItem('channelId'))
      channelId=localStorage.getItem('channelId');
    }
    
    axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getMemberChannel", {'channelId': localStorage.getItem('channelId'), 'searchText': this.state.searchValue, 'pageCount' : this.state.page_count})          
		.then(responseData => {
		//console.log("GET MEMEBR LIST ======>>>>>>",responseData)
		
		for ( let ki in responseData.data.responseData.memberData )
			{
				var dtt = responseData.data.responseData.memberData[ki].endDate;
				let getFullYear=new Date(dtt).getFullYear();
				let getMonth=(new Date(dtt).getMonth()+1);
				let getDate=new Date(dtt).getDate();
				
				if ( getMonth < 10 ) { getMonth = '0'+getMonth; }
				if ( getDate < 10 ) { getDate = '0'+getDate; }
				
				if ( responseData.data.responseData.memberData[ki].endDate.indexOf('1970') === -1 )
					{
						//responseData.data.responseData.memberData[ki].endDate = getFullYear+'-'+getMonth+'-'+getDate;
						let formatEndDate = moment(new Date(dtt)).format('YYYY-MM-DD @ 23:59');
						responseData.data.responseData.memberData[ki].endDate = formatEndDate;
						
					}
				else
					{
						responseData.data.responseData.memberData[ki].endDate = '1970-12-31';
					}
			}
		
		$('.main_loader').hide();
		this.setState({
            sessionData:responseData.data.responseData.memberData,
			channelCreateUser: responseData.data.responseData.channelShow.createUser===0?false:true,
			emailMessage: responseData.data.responseData.channelShow.emailMessage,
			dataLength: responseData.data.responseData.allLength,
			shownLength: responseData.data.responseData.shownLength
        }, ()=>{}
        );
	}).catch(err =>{
		console.log('----------there is problem------------',err);
		$('.main_loader').hide();
		});
}

  addMembers=(e)=>{
    // let channelId = localStorage.getItem('channelId');
    let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
    //channdlId=1;
    if(!channelId){
        console.log('======sas=======',localStorage.getItem('channelId'))
       channelId=localStorage.getItem('channelId');
    }
    const userData ={
        channelId:channelId,
        firstName:this.state.firstName,
        lastName:this.state.lastName,
        email:this.state.email,
		endDate: this.state.limitedMember ? this.state.enddate : '1970-12-31',
		channelCreateUser: this.state.channelCreateUser,
		subdomain:this.state.channelSubdomain,
		emailMessage: this.state.emailMessage
		}
        // console.log("USERDATA====>",JSON.stringify(userData));
	
	
	
    if (this.validator.allValid()) {
		
	if ( this.state.limitedMember && this.state.enddate === '' )
		{
			this.setState({errorTime: true});
			return false;
		}	
	$('.main_loader').attr({'style':'display:flex'}); 
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;    
    axios.post(process.env.REACT_APP_NAME+"/api/v1/session/member",userData)
    .then(responseData => {
		$('.main_loader').hide();
        this.setState({
            msgSuccess:"Member has been added successfully!!"
        });
        this.getMembers();

		console.log("ADD MEMBER ======>>>>>>",responseData)
		$("#add_member_popup").attr({'style':'display:block'});
		$("#members_addModal").attr({'style':'display:none'});
		this.setState({
				firstName:'',
				lastName:'',
				email:'',
				limitedMember: false,
				errorTime: false
		}); 
	}).catch(err =>{
		$('.main_loader').hide();
        $("#member_exist").attr({'style':'display:block'});
		$("#members_addModal").attr({'style':'display:none'});
        this.setState({ 
			errMsgSuccess:"Member already exists",
			firstName:'',
			lastName:'',
			email:'',
			limitedMember: false
		});
		console.log('----------there is problem------------',err);
        });
    }else{
	if (!this.validator.fields.email)
		{
			this.setState({
				emailError: true,
			})
		}
		
	if (!this.validator.fields.firstName)
		{
			this.setState({
				firstNameError: true,
			})
		}
	
	if (!this.validator.fields.lastName)
		{
			this.setState({
				lastNameError: true,
			})
		}	
		
      this.validator.showMessages();
    // rerender to show messages for the first time
    // you can use the autoForceUpdate option to do this automatically`
    this.forceUpdate();
    }
  }

  //EDIT MEMBER FUNCTION
  editMembers=(e)=>{
    let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
    //channdlId=1;
    if(!channelId){
        console.log('======sas=======',localStorage.getItem('channelId'))
        channelId=localStorage.getItem('channelId');
    }
    // let memberid:this.state.sessionData[this.state.getMemberId].id;
    const editUserData ={
        id:this.state.sessionData[this.state.getMemberId].id,
        channelId:channelId,
        firstName:this.state.FirstName,
        lastName:this.state.LastName,
        email:this.state.Email,
		endDate: this.state.limitedMember ? this.state.enddate : '1970-12-31',
		status: this.state.statusID}
        let memid=this.state.sessionData[this.state.getMemberId].id;
		let toDayDate = moment(new Date()).format('YYYY-MM-DD');
		
		let choosenDate = new Date(this.state.enddate);
		let choosenFormated = moment(choosenDate).format('YYYY-MM-DD');
		let timeStatus = true;
		if( toDayDate > choosenFormated && this.state.limitedMember)	
			{
				timeStatus = false;
			} 	
			
	if ( this.state.limitedMember && this.state.enddate === '' )
		{
			this.setState({errorTime: true});
			return false;
		}	
	
    if (this.editValidator.allValid() && timeStatus) {
		
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;     
    axios.put(process.env.REACT_APP_NAME+"/api/v1/session/member?memberId="+memid,editUserData)
    .then(responseData => {
        this.setState({
            msgSuccess:"Member has been updated successfully!!"
        });
         this.getMembers();
		this.setState({errorTime: false});
		console.log("EDIT MEMBER ======>>>>>>",responseData)
		$("#edit_member_popup").attr({'style':'display:block'});
		$("#members_editModal").attr({'style':'display:none'});
        }).catch(err =>{
            this.setState({errMsgSuccess:"Email already exists" });
            $("#member_Editexist").attr({'style':'display:block'});
            $("#members_editModal").attr({'style':'display:none'});
            console.log('----------there is problem------------',err);
        });
        
		}
		else{
            console.log('----------------This is a error--------------------');
			
			if (!this.editValidator.fields.Email)
				{
					this.setState({
						editEmailError: true,
					})
				}
				
			if (!this.editValidator.fields.FirstName)
				{
					this.setState({
						editFirstNameError: true,
					})
				}
			
			if (!this.editValidator.fields.LastName)
				{
					this.setState({
						editLastNameError: true,
					})
				}	
			if (!timeStatus)
				{
					this.setState({
						errorTime: true,
					})
				}
            this.editValidator.showMessages();
        // rerender to show messages for the first time
        // you can use the autoForceUpdate option to do this automatically`
        this.forceUpdate();
      }
    }

  /****** UPDATE STATUS MEMBER ******/
  updateStatusMembers=(e)=>{
      let id = this.state.statusValue;
      let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
      //channdlId=1;
      if(!channelId){
          console.log('======sas=======',localStorage.getItem('channelId'))
          channelId=localStorage.getItem('channelId');
      }
        let statusValue='';
       if(this.state.statusID==1){
          statusValue=0;
       }if(this.state.statusID==0){
        statusValue=1; 
       }
        const updatedStatusData ={
        status:statusValue
        }
        console.log("status DATA====>",this.state.MemberId,JSON.stringify(updatedStatusData));
        let memid = this.state.MemberId;

        //console.log("MEMID=====>>>",memid);
        // let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
        // axios.defaults.headers.common['Authorization'] = token;    
        axios.patch(process.env.REACT_APP_NAME+"/api/v1/session/member/?memberId="+memid,updatedStatusData)
    .then(responseData => {
        this.setState({
            msgSuccess:"Member Status has been update successfully!!"
        });
         this.getMembers();
         $("#member_status").attr({'style':'display:none'});
		//console.log("STATUS UPDATE MEMBER ======>>>>>>",responseData)	
    }).catch(err =>{
            console.log('----------there is problem------------',err);
        });  
    }

    /****** SOFT DELETE MEMBER ******/
  deleteStatusMembers=(e)=>{
    //let id = e.target.id;
    let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
    //channdlId=1;
    if(!channelId){
        console.log('======sas=======',localStorage.getItem('channelId'))
        channelId=localStorage.getItem('channelId');
    }
      let statusValue=2;
      const updatedStatusData ={
      status:statusValue
      }
      //console.log("status DATA====>",JSON.stringify(updatedStatusData));
      let memid = this.state.MemberId;

      //console.log("MEMID=====>>>",memid);
      let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
      axios.defaults.headers.common['Authorization'] = token;    
      axios.patch(process.env.REACT_APP_NAME+"/api/v1/session/member/?memberId="+memid,updatedStatusData)
        .then(responseData => {
            this.setState({
                msgSuccess:"Member has been Deleted successfully!!"
            });
            this.getMembers();
            $("#delete_member").attr({'style':'display:none'})
            //console.log("Delete MEMBER ======>>>>>>",responseData)	
        }).catch(err =>{
          console.log('----------there is problem------------',err);
      });  
    }
	
	openMemberEdit =(i, status)=>
	{
		this.setState({
						FirstName:this.state.sessionData[i].firstName,
						getMemberId:i,
						LastName:this.state.sessionData[i].lastName,
						Email:this.state.sessionData[i].email,
						endDateTemp: this.state.sessionData[i].endDate ? new Date(this.state.sessionData[i].endDate) : new Date(),
						limitedMember: this.state.sessionData[i].endDate !== '1970-12-31',
						statusID: status
						
					},()=> { this.applyEndDate(); $("#members_editModal").attr({'style':'display:block'}); })
	
	}
	
	applyImport =()=>
	{
		$('.main_loader').attr({'style':'display:flex'});
		let channelId = JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
		if ( !channelId )
			{
				channelId = localStorage.getItem('channelId');
			}
		
		const userData ={
							channelId:channelId,
							subdomain:this.state.channelSubdomain,
							importData: this.state.userImports,
							channelCreateUser: this.state.channelCreateUser,
							emailMessage: this.state.emailMessage
						}
		
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		axios.defaults.headers.common['Authorization'] = token;
		axios.post(process.env.REACT_APP_NAME+"/api/v1/session/member", userData)
			.then(responseData => {
				$('.main_loader').hide();
				
				this.getMembers();
				
				$("#add_member_popup").attr({'style':'display:block'});
				$("#members_importModal").attr({'style':'display:none'});
				this.setState({importStatus: '', enableImport: false});
				$("#selImportMem").val('');
				
			}).catch(err =>{
				
				$('.main_loader').hide();
				this.forceUpdate();
				console.log('Error adding', err);
				this.props.showMainErrorPop('One or more members have been already added');
				this.setState({importStatus: '', enableImport: false});
				$("#selImportMem").val('');
			});
	}
	
	
	startImportMembers =()=>
	{
	
		this.setState({enableImport: false});
		let file = $('#selImportMem')[0].files[0];
		if ( file )
			{
				if ( file.type == 'application/vnd.ms-excel' || file.type == 'text/csv' || ( file.type == '' && file.name.substr(-4) == '.csv' ) )
					{
						var maint = this;
						var fr=new FileReader();
						fr.onload = function(e)
							{
								var file_content = this.result;
								maint.setState({importStatus: 'File loaded, checking structure...', userImports: []});
								file_content = file_content.replace(/\r/g, '');
								
								var linesS = file_content.split("\n");
								
								console.log('found lines', linesS);
								
								var goodlines = 0;
								var userImports = [];
								for ( var i = 0; i < linesS.length; i++)
									{
										var splitLine = linesS[i].split(',');
										
										console.log('found items', splitLine);
										
										if ( splitLine.length == 5 )
											{
												if ( /^[a-zA-Z'-]+$/.test(splitLine[0]) && /^[a-zA-Z'-]+$/.test(splitLine[1]) && /\S+@\S+\.\S+/.test(splitLine[2]) && ( splitLine[3] == 0 || splitLine[3] == 1 ) && splitLine[4].length == 8 && parseInt(splitLine[4]) > 10000000 && parseInt(splitLine[4]) < 13311970 )
													{
														goodlines++;
														userImports.push(splitLine);
													}
											}
									}
								
								if ( goodlines == 0 )
									{
										maint.setState({importStatus: 'Unable to find correct data.'});
									}
								else
									{
										maint.setState({importStatus: 'File loaded with ' + goodlines + ' members', enableImport: true, userImports: userImports});
									}
								
							}
						
						fr.readAsText(file);
						
						
						this.setState({importStatus: 'Loading file data...'});
					}
				else
					{
						this.setState({importStatus: 'Wrong file type. Please select CSV file'});
					}
			}
		else
			{
				this.setState({importStatus: 'No File Selected'});
			}
				
	}
	
	closeImportMember = e =>
		{
			$("#members_importModal").attr({'style':'display:none'});
			this.setState({importStatus: '', enableImport: false});
			$("#selImportMem").val('');		
		}
		
	closeAddMemberPop = e =>
		{
			$("#members_addModal").attr({'style':'display:none'});
			this.setState({
					firstName:'',
					lastName:'',
					email:'',
					limitedMember: false,
			}); 
		}
	
	getFaq=(e)=>{
		 axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqsById", {id: e})          
		.then(res => {
			this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs, true)});
		})
		.catch(err =>{ });  
	}
	
	getChannelSub=(e)=>{
		 axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelById", {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel})          
		.then(res => {
			this.setState({channelSubdomain: res.data.responseData.subdomain});
		})
		.catch(err =>{ });  
	}
	
	updateState = (newState) => {
		this.setState(newState, ()=>{
				let createVirdioUser = this.state.channelCreateUser===false?0:1;
				console.log(createVirdioUser);
				axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/updateMemberCreateUser", {'createUser' : createVirdioUser, 'channelId' : JSON.parse(localStorage.getItem('userData')).data.responseData.channel})          
				.then(res => {
					console.log('succ');
					this.getMembers();
				})
				.catch(err =>{ }); 
			}
		);
		 
	}
	
	changeInputVal = (state) =>{
		console.log(state);
		this.setState(state);
	}

  render() {
	 return (
        <div>
            <div id="root">
                <div className="App">
                    <div className="container-fluid custom-width pb-5">
                        <div className="row top-header px-2 px-md-4 py-4">
                            <div className="col-lg-6 flex-wrap d-flex justify-content-center justify-content-lg-start p-2">
                            <div className="align-self-center">
                                <img src="/images/login-logo.png" className="logo my-2 cursor-pointer" alt="logo" onClick={e=>{$("#go_Dashboard").attr({'style':'display:block'});browserHistory.push('/DashboardLanding')}}/>
                            </div>
                            <div className="d-flex d-md-block justify-content-center px-2 px-md-4 py-2">
                                <div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
                                <div className="pl-4">
                                <h3>Welcome {this.state.hostNameData}!</h3>
                                     {/* <p>No Class coming up this week</p> */}
                                    {/* <p>Next Class, Wednesday, 24 July 2019</p> */}
                                </div>
                                </div>
                            </div>
                            </div>
                             <div className="col-lg-6 ">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Average Attendance</p>
                                        <h3>0%</h3>
                                    </div>
                                    <span className=""></span>
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Views</p>
                                        <h3>0</h3>
                                    </div>
                                    {/* <span className="border-right gray-border"></span> */}
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Revenue</p>
                                        <h3>$0</h3>
                                    </div>
                                    {/* <span className="border-right gray-border"></span> */}
                                    <div className="message-notification mx-2 d-none">
                                        <img src="/images/message.png" alt = '#'/>
                                        <span className="message-count">0</span>
                                    </div>
                                    <button className="btn btn-outline-secondary font-weight-bold radius-8  align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}}>LOG OUT</button>
                                </div>
                            </div> 
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center flex-wrap">
                            <h4 className="text-white font-22 pt-1 pl-2 mb-2">Manage Member</h4>
                        </div> */}
                        <div className="gray-box2 radius-10 member-sec pb-5 relative">
                            <Link to='/DashboardLanding'>
								<div className="closePopup mainclose_position"></div>
							</Link>
                            <div className="d-flex justify-content-between align-items-center px-4 pb-4 border-bottom member_title_hold">
                                <div className="mt-4">
                                    <h4 className="text-white d-inline-block font-22 pt-1 pl-2 mb-0 membertopheader">Manage Member <img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb member_bulb" onClick={e=>{this.getFaq(21)}}/></h4>
									<Link to="/member" className="pick ml-4"><img src="/images/add.png" alt="" onClick={e=>{$("#members_addModal").attr({'style':'display:block'})}}/></Link>
									<Link to="/member" className="pick ml-4"><img src="/images/icon_upload.svg" alt="" onClick={e=>{$("#members_importModal").attr({'style':'display:block'})}}/></Link>
                                </div>
								<div className="member_create_user">
									<span className="demand_middle toggle_distance">Create Virdio User When Importing Members</span>
									<label className="switch">
										<input type="checkbox" id = "channelCreateUser" checked={this.state.channelCreateUser} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.channelCreateUser})}}/>
										<span className="slider round"></span>
									</label>
									<div className="toggle_option">{this.state.channelCreateUser ? 'ON' : 'OFF'}</div>
									<img src="/images/bulb.png" onClick={e=>{this.props.showMainErrorPop('Create Virdio User When Importing Members.')}} className="ml-3 mb-2 cursor-pointer channel_bulb"/>
								</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center px-4 pb-4 flex-wrap">
                                <div className="mt-4 col-md-7 px-md-0 d-flex align-items-center">
                                    {/* <p className="text-white mb-0 font-18">Show</p>
                                    <select className="select_input-field mx-3" id="exampleFormControlSelect1">
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                    </select>
                                    <p className="text-white mb-0 font-18">Members</p> */}
                                </div>
                                <div className="mt-4 col-md-5 px-md-0">
                                    <div className="input-group mb-3 mt-4">
                                        <input type="text" className="form-control shadow-effect" id="searchValue" placeholder="Enter member name or email" value={this.state.searchValue}  onChange={(e)=>{this.changeInputVal({[e.target.id]:e.target.value})}}/>
                                        <div className="input-group-append pointer" onClick={()=>{this.getMembers()}}>
                                            <span className="input-group-text">
                                                <img src="/images/search.png" alt="search" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-md-4 membertable add_scroll_mem">
                                <div className="row mx-0 px-md-4 border-bottom">
                                    <div className="col-6 pl-0 pl-sm-2">
                                        <p className="text-white font-18">Name</p>
                                    </div>
                                    <div className="col-2">
                                        <p className="text-white text-center font-18">End Date</p>
                                    </div>
                                    <div className="col-2">
                                        <p className="text-white text-center font-18">Status</p>
                                    </div>
                                    <div className="col-2">
                                        <p className="text-white text-center font-18">Edit</p>
                                    </div>
                                    <div className="col-2 d-none">
                                        <p className="text-white text-center font-18">Delete</p>
                                    </div>
                                </div>
                                { this.state.sessionData.length > 0 &&	
									(this.state.sessionData.map((row,i)=>
									<div className="row mx-0 px-md-4 py-4" key={i}>
								   
										<div className="col-6 pl-0 pl-sm-2">
											<div className="d-flex">
												<img src="images/gray-attendee.png" className="ml-2 mr-3 align-self-start user-avtar" alt="user-icon" />
												<div>
													<p className="checktxt_name mb-2 breakWord">{row.firstName} {row.lastName}</p>
													<p className="checktxt mb-0">{row.email}</p>
													{/* <p className="checktxt mb-0">Past Revenue $45,000</p> */}
												</div>
											</div>
										</div>
										
									
										<div className="col-2 d-flex justify-content-center align-items-center ch_fnt14 no_padd_lr wd30 add_fn14">
											{ row.endDate == '1970-12-31' ?
												<p className="mb-0">Not Limited</p>
												:
												<p className="mb-0">{ this.getSessionDate(row.endDate)} @ {this.getSessionTime(row.endDate)}</p>
											}
										</div>   
										
										<div className="col-2 d-flex justify-content-center align-items-center wd10">
										   
										{row.status===1?
												<button className="btn btn-outline-success"  
												onClick={e=>this.setState({MemberId:row.id,statusID:row.status},()=>{$("#member_status").attr({'style':'display:block'})})}
												 >Active</button>
											
										   :'' }                                        
											<p className="">
											{row.status===0?
											   <button className="btn btn-outline-dark" onClick={e=>this.setState({MemberId:row.id,statusID:row.status},this.updateStatusMembers,()=>'')}>Inactive</button>
											
										   : ''}
											</p>
										</div>                                

									<div className="col-2 d-flex justify-content-center align-items-center wd10">
										<Link to="/member"><button className="session_btn text-uppercase member_edit_button" onClick={e=>this.openMemberEdit(i, row.status)}>Edit</button></Link>
									</div>
									
									<div className="col-2 d-flex justify-content-center align-items-center d-none">
										<p className="d-none"><Link to="/member"><img src="../images/delete1.png" onClick={e=>this.setState({MemberId:row.id},()=>{$("#delete_member").attr({'style':'display:block'});console.log("MEMBERID--->",this.state.MemberId)})}/></Link></p>
									 </div>
										
									</div>
									))
								}
								{ this.state.sessionData.length === 0 &&	
									<div className="empty_template">No results found.</div>
								}
								
								{ this.state.dataLength !== this.state.shownLength &&
									<div className="dashboard_load_hold">
										<div className="custom_btn1 px-5 mt-3 load_more_main_button" onClick={(e) => this.loadMorePrevious()}>load more</div>
									</div>
								}
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            {/* Coming Soon model  */}
            <div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
                <div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
                    <div className="modal-content bg-transparent">
                        <div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
                            <p className="mb-0 py-4 white text-center">Coming Soon</p>
                        </div>
                        <div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal" onClick={e=>{$("#coming_soon_alert").attr({'style':'display:none'})}}>ok</button></div>
                    </div>
                </div>
            </div>

              {/* ALREADY MEMBER EXIST POP UP */}
              <div className="modal pr-0" id="member_exist" aria-modal="true">
                <div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
                    <div className="modal-content bg-transparent">
                        <div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
                            <p className="mb-0 py-4 white text-center">Member already exists!</p>
                        </div>
                        <div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal" onClick={e=>{$("#member_exist").attr({'style':'display:none'})}}>ok</button></div>
                    </div>
                </div>
            </div>

            {/* ALREADY MEMBER EXIST ON EDIT POP UP */}
            <div className="modal pr-0" id="member_Editexist" aria-modal="true">
                <div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
                    <div className="modal-content bg-transparent">
                        <div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
                            <p className="mb-0 py-4 white text-center">Member's email already exists for same channel!</p>
                        </div>
                        <div className="text-center" onClick={e=>{$("#member_Editexist").attr({'style':'display:none'})}}><button type="button" className="custom_btn1 mt-3">ok</button></div>
                    </div>
                </div>
            </div>

            {/* MEMBER STATUS MODAL */}
            <div className="modal member_screen_mdl" id="member_status">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-transparent px-2">                        
                        <div className="modal-body py-5 px-5 modalbg radius-10">
                            <button type="button" className="close white closepurple translatX-Y" onClick={e=>{$("#member_status").attr({'style':'display:none'})}}><span>x</span></button>                           
                            <div className="p-4">
                                <p className="mb-0 text-white font-18 txt_center">Are you sure you want to change status to inactive?</p>							
                            </div>
                        </div>
                        <div className="modal-footer border border-0 justify-content-center">
                            <button type="button" className="done mx-2 mt-3" onClick={e=>{$("#member_status").attr({'style':'display:none'})}}>Cancel</button>
                            <button type="button" className="done mx-2 mt-3" onClick ={this.updateStatusMembers}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>	

            {/* MEMBER STATUS for INACTIVE MODAL */}
            {/* <div className="modal member_screen_mdl" id="member_inact_act_status">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-transparent px-2">                        
                        <div className="modal-body py-5 px-5 modalbg radius-10">
                            <button type="button" className="close white closepurple translatX-Y" onClick={e=>{$("#member_status").attr({'style':'display:none'})}}><span>x</span></button>                           
                            <div className="p-4">
                                <p className="mb-0 text-white font-18">Are you sure you want to change status to active?</p>							
                            </div>
                        </div>
                        <div className="modal-footer border border-0 justify-content-center">
                            <button type="button" className="done mx-2 mt-3" onClick={e=>{$("#member_status").attr({'style':'display:none'})}}>Cancel</button>
                            <button type="button" className="done mx-2 mt-3" onClick ={this.updateStatusMembers}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>	 */}

             {/* DELETE MEMBER  MODAL */}
             <div className="modal member_screen_mdl" id="delete_member">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-transparent px-2">                        
                        <div className="modal-body py-5 px-5 modalbg radius-10">
                            <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>{$("#delete_member").attr({'style':'display:none'})}}><span>x</span></button>                           
                            <div className="p-4">
                                <p className="mb-0 text-center text-white font-18">Are you sure you want to delete?</p>							
                            </div>
                        </div>
                        <div className="modal-footer border border-0 justify-content-center">
                            <button type="button" className="done mx-2 mt-3" onClick={e=>{$("#delete_member").attr({'style':'display:none'})}}>Cancel</button>
                            <button type="button" className="done mx-2 mt-3" onClick={this.deleteStatusMembers}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* OPEN POP UP AFTER ADD MEMBER MODAL */}
             <div className="modal member_screen_mdl" id="add_member_popup">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-transparent px-2">                        
                        <div className="modal-body py-5 px-5 modalbg radius-10">
                            <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>{$("#add_member_popup").attr({'style':'display:none'})}}><span>x</span></button>                           
                            <div className="p-4">
                                <p className="mb-0 text-center text-white font-18">Member has been added successfully!</p>	
                                						
                            </div>
                        </div>
                        <div className="modal-footer border border-0 justify-content-center">
                            <button type="button" className="done mx-2 mt-3" onClick={e=>{$("#add_member_popup").attr({'style':'display:none'});this.setState({firstName:'',getMemberId:'',lastName:'',email:''})}}>Ok</button> 
                        </div>
                    </div>
                </div>
            </div>	

            {/* OPEN POP UP AFTER EDIT MEMBER MODAL */}
            <div className="modal member_screen_mdl" id="edit_member_popup">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-transparent px-2">                        
                        <div className="modal-body py-5 px-5 modalbg radius-10">
                            <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>{$("#edit_member_popup").attr({'style':'display:none'})}}><span>x</span></button>                           
                            <div className="p-4">
                                <p className="mb-0 text-center text-white font-18">Member has been updated successfully!</p>                                						
                            </div>
                        </div>
                        <div className="modal-footer border border-0 justify-content-center">
                            <button type="button" className="done mx-2 mt-3" onClick={e=>{$("#edit_member_popup").attr({'style':'display:none'})}}>Ok</button>                            
                        </div>
                    </div>
                </div>
            </div>

            {/* For "X" icon go to dashboard */}
            {/* <div className="modal pr-0" id="go_Dashboard" aria-modal="true">
                <div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
                <div className="modal-content bg-transparent px-2">
                    <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
                    <p className="py-4 text-light text-center font-20">You have made changes to the class, do you want to exit without saving?</p>  
                    </div>
                    <div className="text-center">
                    <button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={e=>{$("#go_Dashboard").attr({'style':'display:none'});}}>Cancel</button>
                    <button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={e=>{$("#go_Dashboard").attr({'style':'display:none'});browserHistory.push('/DashboardLanding')}}>yes</button>
                    </div>
                </div>
                </div>
            </div>	 */}

             {/* ADD MEMBER MODAL */}
             <div className="modal member_screen_mdl" id="members_addModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content bg-transparent px-2">
                        <div className="modal-header pb-2 border border-0 px-0 justify-content-center">
                        <div className="text-center"><img src="/images/host.png" alt="host" />
                        <h4 className="white text-center mb-0 mt-3">Add a Member</h4>
                        </div>                            
                        </div>                       
                        <div className="modal-body py-3 px-3 modalbg radius-10">                            
                            <button type="button" className="close white closepurple translatX-Y" onClick={this.closeAddMemberPop}><span>x</span></button>   
                        {/* <p className="text-white mr-3 mt-3">{this.state.errMsgSuccess}</p>*/}
                            <div className="d-flex align-items-start flex-wrap mt-4">
                                <p className="text-white mr-3 mt-3 w-108">First Name</p>
                                <div className="form-group flex-grow-1 mb-0">
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" className={"input-field "+(this.state.firstNameError ? "red-alert" : "")} id = "firstName" 
                       value= {this.state.firstName} 
                       onChange = {(e)=>this.setState({[e.target.id] : e.target.value, firstNameError: false})}
                        //, ()=>{console.log("FISSS====",this.state.firstName)})} 
                       placeholder="First Name" maxLength="30" />
                       {this.validator.message('firstName', this.state.firstName, 'required|alpha_space|min:1|max:30')}
                                    <span className="signedup_2"></span>
                                </div>							
                            </div>
                            <div className="d-flex align-items-start flex-wrap">
                                <p className="text-white mr-3 mt-3 w-108">Last Name</p>
                                <div className="form-group flex-grow-1 mb-0">
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" className={"input-field " + (this.state.lastNameError ? "red-alert" : "" )} id="lastName" 
                                    value= {this.state.lastName} onChange = {(e)=>this.setState({[e.target.id] : e.target.value, lastNameError: false})} placeholder="Last Name" maxLength="30" />
                                    {this.validator.message('lastName', this.state.lastName, 'required|alpha_space|min:1|max:30')}
                                    <span className="signedup_2"></span>
                                </div>							
                            </div>
                            <div className="d-flex align-items-start flex-wrap">
                                <p className="text-white mr-3 mt-3 w-108">Email Address</p>
                                <div className="form-group flex-grow-1 mb-0">
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" className={"input-field " + (this.state.emailError ? "red-alert" : "")} id="email"  value= {this.state.email} onChange = {(e)=>this.setState({[e.target.id] : e.target.value, emailError: false})}  placeholder="Email" maxLength="100" />
                                    {this.validator.message('email', this.state.email, 'required|email')}
                                    <span className="form_email"></span>
                                </div>							
                            </div>
                            <div className="d-flex align-items-start flex-wrap mt-3">
                                <p className="text-white w-108">Limited Time</p>
                                <div className="form-group flex-grow-1 mb-0" style={{marginLeft: '15px'}}>
                                    <label className="switch">
										<input type="checkbox" id="limitedMember" checked={this.state.limitedMember} onChange={(e)=>this.setState({[e.target.id] : !this.state.limitedMember, errorTime: false})}/>
										<span className="slider round"></span>
									</label>
									<div className="toggle_option toggle_top">{this.state.limitedMember ? 'ON' : 'OFF'}</div>
                                </div>							
                            </div>
                            {this.state.limitedMember && <div className="d-flex align-items-start flex-wrap">
                                <p className="text-white mr-3 mt-3 w-108">End Date</p>
                                <div className="form-group flex-grow-1 mb-0" onClick={e=>{$("#calenderModelEndDate").attr({'style':'display:block'});}}>
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" className={"input-field " + (this.state.errorTime ? 'red-alert' : '')} id="enddate" value={this.state.enddate}  placeholder="End Date" readOnly />
                                </div>							
                            </div>}
                            <div className="text-center">
                                <button type="button" className="done mx-2 mt-3" onClick={this.addMembers}
                                >Save</button>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div> 

             {/* EDIT MEMBER MODAL */}
             <div className="modal member_screen_mdl" id="members_editModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content bg-transparent px-2">
                        <div className="modal-header pb-2 border border-0 px-0 justify-content-center">
                        <div className="text-center"><img src="/images/edit-member.png" alt="host" />
                        <h4 className="white text-center mb-0 mt-3">Edit a Member</h4></div>                            
                        </div>
                        {this.state.sessionData.length>0 ?                       
                        <div className="modal-body py-3 px-3 modalbg radius-10">                            
                            <button type="button" className="close white closepurple translatX-Y" onClick={(e)=>$("#members_editModal").attr({'style':'display:none'})}><span>x</span></button>  
                                                     
                            <div className="d-flex align-items-start flex-wrap mt-4">
                                <p className="text-white mr-3 mt-3 w-108">First Name</p>
                                <div className="form-group flex-grow-1 mb-0">
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" className={"input-field " + (this.state.editFirstNameError ? "red-alert" : "")} id="FirstName" placeholder='First Name' value={this.state.FirstName} onChange = {(e)=>this.setState({[e.target.id] : e.target.value, editFirstNameError: false},()=>console.log("tt"))}/>
                                    {this.editValidator.message('FirstName', this.state.FirstName, 'required|string')}
                                    <span className="signedup_2"></span>
                                </div>							
                            </div>
                            <div className="d-flex align-items-start flex-wrap">
                                <p className="text-white mr-3 mt-3 w-108">Last Name</p>
                                <div className="form-group flex-grow-1 mb-0">
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" className={"input-field " + (this.state.editLastNameError ? "red-alert" : "")} id="LastName" placeholder="Last Name" maxLength="30" value={this.state.LastName} onChange = {(e)=>this.setState({[e.target.id] : e.target.value, editLastNameError: false})}/>
                                    {this.editValidator.message('LastName', this.state.LastName, 'required|string')}
                                    <span className="signedup_2"></span>
                                </div>							
                            </div>
                            <div className="d-flex align-items-start flex-wrap">
                                <p className="text-white mr-3 mt-3 w-108">Email Address</p>
                                <div className="form-group flex-grow-1 mb-0">
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" className={"input-field " + (this.state.editEmailError ? "red-alert" : "")} id="Email" placeholder="Email" maxLength="100" value={this.state.Email} onChange = {(e)=>this.setState({[e.target.id] : e.target.value, editEmailError: false})}/>
                                    {this.editValidator.message('Email', this.state.Email, 'required|email')}
                                    <span className="form_email"></span>
                                </div>							
                            </div>
                            <div className="d-flex align-items-start flex-wrap mt-3">
                                <p className="text-white w-108">Limited Time</p>
                               <div className="form-group flex-grow-1 mb-0" style={{marginLeft: '15px'}}>
                                    <label className="switch">
										<input type="checkbox" id="limitedMember" checked={this.state.limitedMember} onChange={(e)=>this.setState({[e.target.id] : !this.state.limitedMember, errorTime: false})}/>
										<span className="slider round"></span>
									</label>
									<div className="toggle_option toggle_top">{this.state.limitedMember ? 'ON' : 'OFF'}</div>
                                </div>							
                            </div>
                            {this.state.limitedMember && <div className="d-flex align-items-start flex-wrap">
                                <p className="text-white mr-3 mt-3 w-108">End Date</p>
                                <div className="form-group flex-grow-1 mb-0" onClick={e=>{$("#calenderModelEndDate").attr({'style':'display:block'});}}>
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" className={"input-field " + (this.state.errorTime ? 'red-alert' : '')} id="enddate" value={this.state.enddate}  placeholder="End Date" readOnly />
                                </div>							
                            </div>}
                            <div className="text-center">
                                <button type="button" className="done mx-2 mt-3" onClick={this.editMembers}>Save</button>
                            </div>
                        </div> 
                        :''}                       
                    </div>
                </div>
            </div>

			<div className="modal cal_modal mt-3" id="calenderModelEndDate" data-backdrop="">
				<div className="modal-dialog d-md-flex d-block large_width1 mb-0">
					<div className="modal-content modalbg m-auto">
						<div className="modal-header px-4 pt-3 pb-0">
							<h4 className="white modal-title">Choose Date</h4>
							<button type="button pr-3" className="close white closepopup" onClick={e=>$("#calenderModelEndDate").attr({'style':'display:none'})}>&times;</button>
						</div>
						<div className="modal-body modal-body px-1 px-sm-3">
							<h3>Calender</h3>

							<Calendar
							   onChange={this.changeEndDate}
							   value={this.state.endDateTemp}
							   minDate={new Date()}
							 />
						</div>
						<div className="text-center position-absolute btn_btn1">
						{this.state.signUpSessionStatus?'':<button type="button" className="done mt-0" onClick={this.applyEndDate} data-dismiss="modal">done</button>}
					</div>
					</div>
				</div>
			</div>



			{/* ADD IMPORT MEMBER MODAL */}
             <div className="modal member_screen_mdl" id="members_importModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content bg-transparent px-2">
                        <div className="modal-header pb-2 border border-0 px-0 justify-content-center">
							<div className="text-center"><img src="/images/host.png" alt="host" />
								<h4 className="white text-center mb-0 mt-3">Import Members</h4>
							</div>                            
                        </div>                       
                        <div className="modal-body py-3 px-3 modalbg radius-10">
							<button type="button" className="close white closepurple p-0 translatX-Y" data-dismiss="modal" onClick={this.closeImportMember}><span>&times;</span></button>
							
							<a href="/csv_example.csv" target="_blank" download><div className="immem_example">Download Example File</div></a>
							<div className="immem_file">
								Click here to select CSV file and import channel members.
								<input type="file" id="selImportMem" name="selImportMem" onChange={this.startImportMembers} />
							</div>
							
							<div className="immem_status">{this.state.importStatus}</div>
							
						<div className="text-center position-absolute btn_btn1">
							{this.state.enableImport && <button type="button" className="done mt-0" style={{width: '250px'}} onClick={this.applyImport} data-dismiss="modal">IMPORT MEMBERS</button>}
						</div>
							
							
                        </div>                        
                    </div>
                </div>
            </div>

			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>			
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
	    </div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(member);
