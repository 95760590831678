import { readBlob } from '../util/readBlob.js';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import $ from 'jquery';

var global_songs = [];
var global_song_now = 0;
var global_pl = '';
var globalpromise = null;
var globalreject = null;

var globtimerm = null;

var allSongsAdded = function(res){
	if( res.data == "allSongsAdded" && globalpromise )
		{
			globalpromise("success");
		}
	else if(res.data == "songAddingError") {
		globalreject("error");
	}
	
	else if( res.data == "songAddingLengthError" ){
		globalreject("databasefull");
	}
}
 
if (window.addEventListener)
	{
		window.addEventListener("message", allSongsAdded, false);
	}
else
	{
		window.attachEvent("message", allSongsAdded);
	}

var putSongsInDb = function (songs, playlist_name) {
    var promise = new Promise((resolve, reject) => {
			globalpromise = resolve;
			globalreject = reject;
			console.log('Started playlist', songs);
			if ( safari || window['useS'] == 1 )
				{
					$('#enablepopups').hide();
					
					setTimeout(function ()
						{
							var newWin = window.open(window.location+'?closeTab=1');             

							if ( !newWin || newWin.closed || typeof newWin.closed == 'undefined' || typeof newWin.close == 'undefined' ) 
								{ 
									//POPUP BLOCKED, please enable
									$('#enablepopups').show();
									reject();
								}
							else
								{
									newWin.close();
									
									global_pl = playlist_name;
									global_songs = songs;
									
									pushNextSong();
									
									window.addEventListener("focus", pushNextSong);
								}
						}, 1000);
				}
			else
				{
					var iframe = document.getElementById('clientIframe');
					var iframeWindow = (iframe.contentWindow || iframe.contentDocument);
					
					console.log('Started playlist', songs);
					
					var songs_array = [];
					Array.prototype.forEach.call(songs, (file) => {
						
						console.log('Each file');
						
						readBlob(file).then((bblob) => {
							
								console.log('Each blob');
								
								let fileData = new File([bblob], file.name, {type: 'video/mp3'});
								const reader = new FileReader();
								reader.addEventListener("loadend", function()
									{
										console.log('Read complete');
										
										songs_array.push({songId: file.name, name: playlist_name, blob: reader.result});
										
										console.log('Read all', songs_array, songs);
										if ( songs_array.length == songs.length )
											{
												iframeWindow.postMessage([songs_array, playlist_name], iframe.src);
											}
									});
								reader.readAsArrayBuffer(fileData);
						});
					});
				}
		});
	
	return promise;
};



function pushNextSong()
	{
		if ( globtimerm !== null ) { clearTimeout(globtimerm); }
		
		globtimerm = setTimeout(function ()
			{
				console.log('Adding ', global_songs.length, global_song_now);
				if ( global_songs.length > global_song_now )
					{
						var file = global_songs[global_song_now];
						console.log('Adding - ' + file.name);
						
						readBlob(global_songs[global_song_now]).then((bblob) => {
							
								console.log('Each blob');
								
								let fileData = new File([bblob], file.name, {type: 'video/mp3'});
								const reader = new FileReader();
								reader.addEventListener("loadend", function()
									{
										console.log('Read complete');
										
										$('#all_data0').val(file.name);
										$('#all_data1').val(global_pl);
										$('#all_data2').val(reader.result);
										$('#playlistform').submit();
										global_song_now++;
									});
								reader.readAsDataURL(fileData);
						});
					}
				else
					{
						global_song_now = 0;
						console.log('All done');
						window.removeEventListener("focus", pushNextSong);
						globalpromise('success');
					}
			}, 3000);
	}


export { putSongsInDb } 