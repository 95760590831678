import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import SupportContainer from '../home/SupportContainer';


class newsPage extends Component {
constructor(props) {
	super(props);
	this.state = {
		pageContent: [],
		supportPageName: 'News',
		supportPageApi: '/news',
		userTypeFaqs: 2
	}

}

readMore = (i) => {
	let array = this.state.pageContent;
	
	array[i]['openReadMore'] = !array[i]['openReadMore'];
	
	this.setState({
				pageContent: array
			});
}

openDemo = () => {
	window.open('https://calendly.com/brent-platt-virdio/virdio-demo');
}

componentDidMount(){
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getNews", {})
		.then(res => {
			this.setState({
				pageContent: res.data.responseData
			});
		}).catch(err =>{ 
			
		});
	
}
 
  render() {

    return (
		<div className="allNewsPage overnot">
			<div className="news_header">NEWS
				<Link to="/"><div className="abs_virdio_logo"></div></Link>
			</div>
			<div className="for_all_news">
				{this.state.pageContent.map((con, i)=>
					<div className="one_news" key={i}>
						<div className="left_img">
							<img src={con.image} />
						</div>
						<div className={ "news_text " + ( con.openReadMore ? "all_height" : "" ) }>{con.text}</div>
						{ !con.openReadMore &&
							<div className="for_read_more">
								<div className="abs_date">{con.date_added}</div>
								<div className="read_more"><span onClick={ () => this.readMore(i) }>Read More</span></div>
							</div>
						}
						{ con.openReadMore &&
							<div className="footer_news">
								<div className="news_person">{con.footer_text}</div>
								<div className="for_read_more paddl0">
									<div className="abs_date">{con.date_added}</div>
									<div className="read_more"><span onClick={ () => this.readMore(i) }>Read Less</span></div>
								</div>
							</div>
						}
					</div>
				)}
			</div>
			<div className="bottom_txt">If you would like to learn more about how Virdio can help you transform your virtual business, please click this link to schedule an appointment <span onClick={this.openDemo}>Virdio Demo</span></div>
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}

export default newsPage;