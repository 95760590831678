import React, { Component } from "react";
import { Link } from 'react-router';
import Slider from "react-slick";
import DatePicker from "react-datepicker";
import $ from 'jquery';
import {browserHistory} from 'react-router'
import FacebookLogin from 'react-facebook-login';
import axios from "axios";
import Calendar from 'react-calendar';
import SearchResults from './SearchResults';
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
import SupportContainer from '../home/SupportContainer';

class Dashboard extends Component {
constructor(props) {
	super(props);
	
	var splitedMain = process.env.REACT_APP_PATH.split('//');
	var homepagePath = splitedMain[0] + '//home.' + splitedMain[1];
	window.location.href = homepagePath;
	return false; 
	
	this.state = {
	// carouselData :[{path:"/images/banner-image.png"},{path:"/images/banner2.jpg"},{path:"/images/banner3.jpg"}],
	carouselData :[{path:"/images/banner-image.png"}],
	fitnessData:[],
	wineData:[{path:"/images/wine.jpg"},{path:"/images/wine.jpg"},{path:"/images/wine.jpg"},{path:"/images/wine.jpg"},{path:"/images/wine.jpg"},{path:"/images/wine.jpg"}],
	cookingData:[{path:"/images/wine.jpg"},{path:"/images/wine.jpg"},{path:"/images/wine.jpg"},{path:"/images/wine.jpg"},{path:"/images/wine.jpg"},{path:"/images/wine.jpg"}],
	customCheck1:false,
	sessionData:[],
	indiviualSessionData:'',
	interestData:[],
	searchFields: [],
	searchSessionResults: [],
	interestHeading:'',
	areaInterestList: [],
	searchShowTime: '',
	showSignUp: true,
	accountLogin: '/DashboardLanding',
	supportPageName: 'Homepage',
	supportPageApi: '/home',
	userTypeFaqs: 2
	}
	this.getGroupData();
	
}
 
componentDidMount(){
//	console.log(process.env.REACT_APP_NAME);
//   console.log('Ak');
//   console.log('new Date().getTime()',new Date().getTime());
//   let ka= new Date();
//   ka.setDate(ka.getDate() - 1);
//   console.log(ka.getTime(),'ka.setDate(ka.getDate() - 1)',ka);
//   let date = new Date();
//   console.log(date);
//   let timeSelection =  new Date (date.getTime()).getMonth() ;
//   console.log(timeSelection);
//   date = new Date(Date.UTC(2019, timeSelection, 1));
//   var days = [];
//   var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
//   console.log('date.getMonth()',date.getMonth());
//   while (date.getMonth() === 10) {

	 //days.push(new Date(date).getDate());

	//  let n ={date:new Date(date).getDate(),day:dayofWeek[new Date(date).getDay()]}
	//  days.push(n);

	 //days.push(new Date(date).getDay());

// 	 date.setDate(date.getDate() + 1);
//   }
//   for (let i=18 ;i<days.length-1;i++){
// 	  console.log(days[i])
//   } 
  
//   console.log(days[29],days.length,'DAYS**************************************',days);
	$(".parent-row").hide();

	if ( localStorage.getItem('userData') )
		{
			var uData = JSON.parse(localStorage.getItem("userData")).data.responseData;
			if ( uData['tokenExpire'] )
				{
					let expireTime = (uData['tokenExpire'] - (new Date().getTime()));
					
					console.log('Expire in: ', expireTime);
					
					if ( expireTime > 0 )
						{
							this.setState({
								showSignUp: false,
								accountLogin: uData.type == 2 ? '/participant-dashboard' : '/DashboardLanding'
							});
						}
						else
						{
							localStorage.removeItem("userData");
						}
				}
		}
 }

 
 
 
getGroupData=e=>{
	axios      
	.get(process.env.REACT_APP_NAME+"/api/v1/user/getAllGroupsWithInterest")          
	.then(res => {
	 // console.log("==============sslalit===",res.data.responseData);
	     this.setState({
		  fitnessData:res.data.responseData,
		  
	  })
  })
	.catch(err =>{
	//	console.log('----------there is problem------------');
	});
}

 getSessionTime = (date1)=>{
	let ab = new Date(date1).getTime();
	// console.log('-----------------',new Date(date1).getHours()+new Date(date1).getMinutes())
	let hour=new Date(ab).getUTCHours();
	let minute=new Date(ab).getUTCMinutes();
	let sessionTime= hour+':'+minute;
	if(hour<=12){
	return sessionTime + 'AM';
	}else{
		return sessionTime + 'PM';
	}  
  }
 getSessionDate=(date1)=>{
	let ab = new Date(date1).getTime();
	let day=new Date(date1).getDate();
	let month=(new Date(date1).getMonth())+1;
	let year=new Date(date1).getFullYear();
	let sessionDate= day+'/'+month+'/'+year;
	return sessionDate; 
	
}
 
startMainSearch = () =>
	{
		this.props.showMainErrorPop("Virdio Marketplace Coming Soon!");
		/* if( !this.state.fullSearch ) { this.setState({ fullSearch: true }) }
		else
			{
				if ( this.state.searchFields.searchHostName && this.state.searchFields.searchHostName != '' )
					{
						$('.main_loader').attr({'style':'display:flex'});
						axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkForHost", {'name': this.state.searchFields.searchHostName})          
						.then(res => {
								$('.main_loader').hide();
								
								if ( res.data.responseData.length > 0 )
									{
										$('.main_loader').attr({'style':'display:flex'});
										axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/searchSessions", this.state.searchFields)          
										.then(res => {
										 $('.main_loader').hide();
										 this.setState({ showResults: true, searchSessionResults: res.data.responseData });
										 
									  })
										.catch(err =>{ $('.main_loader').hide(); this.props.showMainErrorPop("Something went wrong! Please try again."); });
									}
								else
									{
										this.props.showMainErrorPop("Instructor with chosen name doesn't exist.");		
									}
						 })
					}
				else
					{
						if( Object.keys(this.state.searchFields).length > 0 )
							{
								$('.main_loader').attr({'style':'display:flex'});
								axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/searchSessions", this.state.searchFields)          
								.then(res => {
								 $('.main_loader').hide();
								 this.setState({ showResults: true, searchSessionResults: res.data.responseData });
								 
							  })
								.catch(err =>{ $('.main_loader').hide(); this.props.showMainErrorPop("Something went wrong! Please try again."); });
							}
					}
			} */
	}

 setStartDate =(date)=>{
	let date1=date;
	let upcomingSession=[];
	// console.log('----------------',new Date(date).getMonth(),new Date(date).getDate());
	let dateofMonth = new Date(date).getDate();
	 let timeSelection =  (new Date (date).getMonth()) ;
	// console.log(timeSelection);
	  date = new Date(Date.UTC(2019, timeSelection, 1));
	 var days = [];
	 var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
//	console.log('date.getMonth()',date.getMonth());
	 while (date.getMonth() === timeSelection) {
		//days.push(new Date(date).getDate());
		 let n ={date:new Date(date).getDate(),
			day:dayofWeek[new Date(date).getDay()],
			timestamp:new Date(date).getTime(),
		}
		 days.push(n);
		 //days.push(new Date(date).getDay());
		 date.setDate(date.getDate() + 1);
	  }
	//console.log(days)
	  for (let i=dateofMonth-1 ;i<days.length;i++){
		   upcomingSession.push(days[i]);
	  } 
	
	this.setState({
		startDate:date1,
		daysOfMonth:days,
		upcomingSession:upcomingSession,
	});
	
	}

	setStartDate1 =(date)=>{
		let date1=date;
		let upcomingSession=[];
		// console.log('----------------',new Date(date).getMonth(),new Date(date).getDate());
		let dateofMonth = new Date(date).getDate();
		 let timeSelection =  (new Date (date).getMonth()) ;
		 
		  date = new Date(Date.UTC(2019, timeSelection, 1));
		 var days = [];
		 var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
		
		 while (date.getMonth() === timeSelection) {
			//days.push(new Date(date).getDate());
			 let n ={date:new Date(date).getDate(),
				day:dayofWeek[new Date(date).getDay()],
				timestamp:new Date(date).getTime(),
			}
			 days.push(n);
			 //days.push(new Date(date).getDay());
			 date.setDate(date.getDate() + 1);
		  }
		
		  for (let i=dateofMonth-1 ;i<days.length;i++){
			  
			  upcomingSession.push(days[i]);
		  } 
		
		this.setState({
			startDate1:date1,
			daysOfMonth:days,
			upcomingSession:upcomingSession,
		});
		
		}
	

 customChecked=(e)=>{
	
	this.setState({
		[e.target.id]:!this.state.customCheck1,
		},()=>{
			if(this.state.customCheck1){
				$(".parent-row").show();
			}else{
				$(".parent-row").hide();
			}
		})
}
sessionList =(e) =>{
	let interestHeading=e.target.name;
	axios      
	.get(process.env.REACT_APP_NAME+"/api/v1/session/"+e.target.id+"/sessionList")          
	.then(res => {
	  this.setState({
		  sessionData:res.data.responseData,
		  interestHeading:interestHeading
	  })
  })
	.catch(err =>{
		

	});
}
indiviualSessionDetail =e=>{
	axios      
	.get(process.env.REACT_APP_NAME+"/api/v1/session/"+e.target.id+"/individualsessiondetailForAdmin")          
	.then(res => {
	
	  if(res.data.responseData){
		//  console.log('res.data.responseData.firstName',res.data.responseData.firstName)
	  }else{
		  //console.log('====================')
	  }
	  this.setState({
		  indiviualSessionData:res.data.responseData
	  })
  })
	.catch(err =>{
	

	});
	}

setTimeCalendar = date => {
	let getFullYear=new Date(date).getFullYear();
	let getMonth=(new Date(date).getMonth())+1;
	let getDate=new Date(date).getDate();
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const timePeriod=monthArray[getMonth-1]+' '+getDate+', '+getFullYear;
	const newDate = new Date(date).toUTCString();
	this.setState({ ...this.state, searchShowTime: timePeriod, searchFields: { ...this.state.searchFields,  searchMainTime: newDate }});

	$("#calenderModel").attr({'style':'display:none'});
}

responseFacebook = (response) => {
	console.log(response.accessToken);
	if(response.accessToken){
		console.log(response.email)
		browserHistory.push('/DashboardLanding');
	}
}  

backFromSearch = () => {
	this.setState({ showResults: false });
}

closeSearch = () => {
	this.setState({ fullSearch: false });
}

clearDate = () => {
	let fields = this.state.searchFields;
	fields.searchMainTime = '';
	this.setState({ searchShowTime: '', searchFields: fields });
}


  render() {

	var settings = {
		dots: false,
		infinite: true,
		slidesToShow: 3,//4
		slidesToScroll: 1,
		autoplay: false,//true
		speed: 2000,
		autoplaySpeed: 3000,
		arrows:true,//false
  		pauseOnHover:true,
		cssEase: "linear",
		responsive: [
			{
		  breakpoint: 1024,
		  settings: {
			slidesToShow: 3,//3
			slidesToScroll: 1,
		  }
		},
		{
		  breakpoint: 600,
		  settings: {
			slidesToShow: 2,//2
			slidesToScroll: 1,
			arrows:true//false
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows:true//false
		  }
		}
		]
	  };

	
	  var settings1 = {
		dots: false,
		infinite: true,
		slidesToShow: 3,//4
		slidesToScroll: 1,
		autoplay: false,//true
		speed: 2000,
		autoplaySpeed: 3000,
		arrows:true,//false
  		pauseOnHover:true,
		cssEase: "linear",
		responsive: [
			{
		  breakpoint: 1024,
		  settings: {
			slidesToShow: 3,//3
			slidesToScroll: 1,
		  }
		},
		{
		  breakpoint: 600,
		  settings: {
			slidesToShow: 2,//2
			slidesToScroll: 1,
			arrows:true//false
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows:true//false
		  }
		}
		]
	  };

    return (
	<div className="custom-width mx-auto myfaqhere">
		{ this.state.showResults &&  
			<SearchResults
				backFromSearch={ this.backFromSearch }
				searchSessionResults={this.state.searchSessionResults} />
		}
	<div className="outer-container">
	<div className="container-fluid px-3 px-md-5">
	
		<div className="slider-header">
			<div className="row">
				<div className="col-md-5 relative">
					<h1 className="logo-text text-center text-md-left pt-4"><img src="/images/login-logo.png" className="logo" alt="logo" /></h1>
					<div className="for_all_header_faq">
						{ /* <div className="one_header_faq">SOLUTIONS</div> */ }
						{ /* <div className="one_header_faq">PRICING</div> */ }
						{/*  <div className="one_header_faq">COMPANY</div> */ }
						<Link to="/support"><div className="one_header_faq lst_header">CUSTOMER SUPPORT</div></Link>
				</div>
				</div>
				{ this.state.showSignUp &&
					<div className="col-md-7 mt15_mob">
						<div className="d-flex justify-content-md-end justify-content-center align-items-center flex-wrap">
							<p className="pt-4 header-signup-text">Sign Up, It's Free</p>
							<button className="btn btn-primary text-uppercase mr-2 mr-md-3 font-weight-bold radius-8 mt-4" onClick={e=>browserHistory.push("/accesscode")}>Sign Up</button>
							<button className="btn btn-outline-secondary font-weight-bold radius-8 mt-4" onClick={e=>browserHistory.push("/login")}>LOG IN</button>
						</div>
					</div>
				}
				{ !this.state.showSignUp &&
					<div className="col-md-7 mt15_mob">
						<div className="d-flex justify-content-md-end justify-content-center align-items-center flex-wrap">
							<button className="btn btn-primary text-uppercase mr-2 mr-md-3 font-weight-bold radius-8 mt-4" onClick={e=>browserHistory.push(this.state.accountLogin)}>DASHBOARD</button>
						</div>
					</div>
				}
			</div>	
		</div>
	
		<div className="slider_sec mt-3">
			<div id="demo" className="carousel slide">
			  {/* <ul className="carousel-indicators">
			    <li data-target="#demo" data-slide-to="0" className="active"></li>
			    <li data-target="#demo" data-slide-to="1"></li>
			    <li data-target="#demo" data-slide-to="2"></li>
			  </ul> */}
			  <div className="carousel-inner">
				{/* {this.state.carouselData.map((row,i)=> */}
				{/* <div className={(i===0?"carousel-item active":"carousel-item")} key ={i}> */}
				<div className="carousel-item active" >
				<div className="pb-1">								
							<h3 className="font-32 white text-center mb-0 pt-2 pt-md-3">Live Fitness Classes, Anytime Anywhere</h3>
						</div>
					  <img src="/images/banner-image.png" alt="" />
				     
					    <div className="carousel-caption position-relative new_ses_font">
							<div className="d-flex justify-content-between flex-wrap">
								<div className="inner_text d-flex flex-wrap align-items-center mt-2 mt-sm-0">
									<Link to ="/" className="text-white text-decoration-none" onClick={(e)=>{$("#thank_you").attr({'style':'display:block'});}} ><div className="upper_inner_text learn_more d-flex align-items-center">
										<h3 className="font-22 font-book mb-0 new_ses_font">Want to Host a Class? Click to learn <span> more  <div className="bordr new_inl_arrow new_marg_left_arrow"><i className="fa fa-angle-right newPost_arrow more_left_arrow"></i></div></span> </h3>
										
									</div></Link>
								</div>
								<div className="inner_text d-flex flex-wrap align-items-center mt-sm-0">
									<Link to ="/" className="text-white text-decoration-none" onClick={(e)=>{$("#thank_you").attr({'style':'display:block'});}}><div className="upper_inner_text learn_more d-flex align-items-center">
										<h3 className="font-22 font-book mb-0 new_ses_font">Want to attend a Class? Search Virdio for Upcoming  <span> Classes <div className="bordr new_inl_arrow new_marg_left_arrow"> <i className="fa fa-angle-down newPost_arrow"></i> </div> </span>  </h3>
									</div></Link>
								</div> 
							</div> 
						</div>
			    </div>
				
			    {/* <div className="carousel-item">
			      <img src="images/banner2.jpg" alt="" />
			      <div className="carousel-caption">
			        <div className="inner_text d-flex flex-wrap align-items-center ">
			      		<div className="upper_inner_text">
					        <h3>Next generation of virtual studio</h3>
					        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</p>
					    </div>
					    <div className="lower_inner_text">
					    	<div className="learn_more d-flex justify-content-between">
					    		<p className="mb-0">Learn more about hosting classes on virdio</p>
					    		<div className="bordr"><i className="fa fa-angle-right"></i></div>
					    	</div>
					    </div>
				      </div>  
			      </div>   
			    </div> */}
			    {/* <div className="carousel-item">
			      <img src="images/banner3.jpg" alt="" />
			      <div className="carousel-caption">
			        <div className="inner_text d-flex flex-wrap align-items-center ">
			      		<div className="upper_inner_text">
					        <h3>Next generation of virtual studio</h3>
					        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</p>
					    </div>
					    <div className="lower_inner_text">
					    	<div className="learn_more d-flex justify-content-between">
					    		<p className="mb-0">Learn more about hosting classes on virdio</p>
					    		<div className="bordr"><i className="fa fa-angle-right"></i></div>
					    	</div>
					    </div>
				      </div>  
			      </div>   
			    </div> */}
			  </div>
			</div>
		</div>
		
	</div>
	
	<div className="container-fluid px-3 px-md-5 slides_sec py-4">
	
			{ /* <div className="d-flex flex-wrap align-items-center">
				<h2 className="">Search</h2>
				<div className="flex-grow-1 input_field_container py-0 radius-8 main_search_item relative">
					{ this.state.fullSearch &&
						<div className="close_search" onClick={this.closeSearch}></div>
					}
					{ !this.state.fullSearch ?  
						<div className="row mx-0 frst-input-row shadow-1 py-3 align-items-center radius-8 cursor" onClick={ () => { this.startMainSearch() }} >
							<div className="flex-grow-1 cursor">
								<input disabled type="text" className="form-control search_input_dashboard cursor" placeholder="Search Virdio for your next Virtual Experience" name="" />
							</div>
						</div>
						: 
						<div className="row mx-0 frst-input-row shadow-1 pt-4 align-items-center radius-8">				
							<div className="row mx-0 row1 d-flex dash_search_row">
								  <div className="col-md-2 pr-md-6">
									<div className="form-group"><span className="cover-border"></span>
									  <label className="label">Area</label>
									  <select className="input-field" value={ this.state.searchFields.searchArea  || '' } onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchArea: e.target.value, searchInterest: '' } })} >
										  <option></option>
										  { this.state.fitnessData.map((item, key) => 
											<option value={item['id']} key={key}>{item['name']}</option>
										  )}
									  </select>
									</div>
								  </div>
								  <div className="col-md-2 pl-md-6">
									<div className="form-group"><span className="cover-border"></span>
									  <label className="label">Interest</label>
										<select value={ this.state.searchFields.searchInterest || ''} className="input-field" onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchInterest: e.target.value } })} >
											<option></option>
											{ this.state.searchFields.searchArea && this.state.searchFields.searchArea === '1' &&
												<optgroup label="Fitness">
													{ this.state.fitnessData && this.state.fitnessData[0] && this.state.fitnessData[0].interestList.map((item, key) => 
														<option value={item['id']} key={key}>{item['name']}</option>
													)}
												</optgroup>
											}
											{ this.state.searchFields.searchArea && this.state.searchFields.searchArea === '2' &&
												<optgroup label="Wine">
													{ this.state.fitnessData && this.state.fitnessData[1] && this.state.fitnessData[1].interestList.map((item, key) => 
														<option value={item['id']} key={key}>{item['name']}</option>
													)}
												</optgroup>
											}
										</select>
									  </div>
								  </div>
								  <div className="col-md-3 pl-md-6">
									<div className="form-group"><span className="cover-border"></span>
									  <label className="label">Channel Name</label>
										<div className="">
											<input 
												type="text"
												value={this.state.searchFields.searchChannelName || ''}
												onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchChannelName: e.target.value } })}
												className="input-field"  />
										</div>
									</div>
								  </div>
								  <div className="col-md-3 pl-md-6">
									<div className="form-group"><span className="cover-border"></span>
									  <label className="label">Instructor Name</label>
										<div className="">
											<input 
												type="text"
												value={this.state.searchFields.searchHostName || ''}
												onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchHostName: e.target.value } })}
												className="input-field"  />
										</div>
									  </div>
								  </div>
								  <div className="col-md-2 pl-md-6 relative">
									  <div className="form-group" onClick={e=>{$("#calenderModel").attr({'style':'display:block'});}}>
											<span className="cover-border"></span>
											<label className="label">Pick Date</label>
											<div className="">
												<input type="text" value={this.state.searchShowTime || ''} className="input-field" readOnly/>
											</div>
									  </div>
									  { this.state.searchShowTime != '' &&
										<div className="abs_clear" onClick={this.clearDate}>Clear Date</div>
									  }
								  </div>
								  <div className="col-md-2 pl-md-6">
									<div className="form-group"><span className="cover-border"></span>
									  <label className="label">Duration</label> 
										<select className="input-field" value={ this.state.searchFields.searchDuraction  || '' } onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchDuraction: e.target.value } })} >
											<option></option>
											<option value="30">30 Minute</option>
											<option value="60">1 Hour</option>
											<option value="120">2 Hours</option>
										</select>
									  </div>
								  </div>
								   <div className="col-md-2 pl-md-6">
									<div className="form-group"><span className="cover-border"></span>
									  <label className="label">City</label>
										<div className="">
											 <input 
												type="text"
												value={this.state.searchFields.searchCity || '' }
												onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchCity: e.target.value } })}
												className="input-field"  />
										</div>
									  </div>
								  </div>
								  <div className="col-md-3 pl-md-6">
									<div className="form-group"><span className="cover-border"></span>
									  <label className="label">State</label>
										<select className="input-field" value={ this.state.searchFields.searchState || '' } onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields,  searchState: e.target.value } })} >
											<option></option>
											<option value="49">49</option>
										</select>
									  </div>
								  </div>
								  <div className="col-md-1 pl-md-6 d-flex justify-content-center align-items-center">
									<p className="mr-3 mb-4">Live</p>
									<div className="custom-control custom-checkbox mb-4">
										<input onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields,  searchLive: !this.state.searchFields.searchLive } })} type="checkbox" className="custom-control-input" id="customCheck" name="" />
										<label className="custom-control-label" htmlFor="customCheck"></label>
									</div>
								  </div>
								  <div className="col-md-3 pl-md-6 justify-content-center mt-4 mt-lg-0 d-flex align-items-center">
									<p className="mr-3 mb-4">On Demand</p>
									<div className="custom-control custom-checkbox mb-4">
										<input onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchOnDemand: !this.state.searchFields.searchOnDemand } })} type="checkbox" className="custom-control-input" id="customCheck2" name="" />
										<label className="custom-control-label" htmlFor="customCheck2"></label>
									</div>
								  </div>
							</div>
						</div>
					}

					<div className={"right-small-box" + (this.state.fullSearch ? ' wide_search_opened' : '')} onClick={ () => { this.startMainSearch() }}>
						
						{ !this.state.fullSearch ? <img src="/images/search.png" alt="search" /> : <img src="/images/search_loop.png" alt="search" />	}
						
					</div>
				</div>
			</div> */ }
		{this.state.fitnessData.map((row,k)=>	
		<div className="small_img_part mt-4" key={k}>
			{row.name == 'Fitness' &&
			<div>
			<h3 className="my-3">{row.name}<sup className="ml-2">({row.interestList.length})</sup></h3>
				<div className="fitness_main_hold">
				{row.interestList.map((interestRow,l)=> 
				<div className="fitness_showed_items" data-toggle="modal"  data-target="#fitness_attendee_popup1" key ={l}>
					{/* <img src={k===1?"/images/wine.jpg":'/images/session-image.jpg'} alt="#" id={l} name={interestRow.name} onClick = {this.sessionList}/> */}
					{/* <img src={k===1?"/images/wine.jpg":'/images/session-image.jpg'} alt="#" id={l} name={interestRow.name} onClick = {e=>{browserHistory.push('/accesscode')}}/> */}

					<div className="fitness_inner_hold" style={{backgroundImage: 'url('+interestRow.image+')'}}  id={l} onClick = {e=>{$("#thank_you").attr({'style':'display:block'})}}></div>
					<p className="mt-3">{interestRow.name}</p>
				</div>
				)}
				</div>
			</div>
			}
		</div>
			)}
		{/* {this.state.interestData.length>0?
		<div className="small_img_part">
			<h3 className="my-3">FITNESS<sup className="ml-2">({this.state.interestData.length})</sup></h3>
			<Slider {...settings}>
				{this.state.interestData.map((row,i)=>
				<div className="item slide-ele" data-toggle="modal"id={i} onClick = {this.sessionList} data-target="#fitness_attendee_popup" key ={i}>
					<img src="/images/wine.jpg"  alt="#"/>
					<p className="mt-3">{row.name}</p>
				</div>
				)}
				</Slider>
				
		</div>
		:''} */}
		{/* <div className="small_img_part">
			<h3 className="my-3">WINE<sup className="ml-2">({this.state.cookingData.length})</sup></h3>
			<Slider {...settings}>
				{this.state.cookingData.map((row,i)=>
				<div className="item slide-ele" data-toggle="modal" onClick = {this.sessionList} data-target="#fitness_attendee_popup" key ={i}>
					<img src={row.path} alt="#"/>
					<p className="mt-3">Strength Training</p>
				</div>
				)}
				</Slider>
				
		</div> */}
	</div>
	
</div>

<div className="modal cal_modal mt-3" id="calenderModel" data-backdrop="">
  <div className="modal-dialog d-md-flex d-block large_width1 mb-0">
    <div className="modal-content modalbg m-auto">
      <div className="modal-header px-4 pt-3 pb-0">
        <h4 className="white modal-title">Choose Date</h4>
        <button type="button pr-3" className="close white closepopup" onClick={e=>$("#calenderModel").attr({'style':'display:none'})}>&times;</button>
      </div>
      <div className="modal-body modal-body px-1 px-sm-3">
		<h3>Calender</h3>
        <Calendar
		   onChange={this.setTimeCalendar}
		   value={this.state.sessionCalender}
		   minDate={new Date()}
		 />
      </div>
    </div>
  </div>
</div>

<footer>
	<div className="footer_sec px-4 px-lg-5 pt-4 pb-2 mb-3 pb60">
		<div className="row">
			<div className="col-md-3 col-sm-5 px-md-2 px-lg-3">
				<h1 className="logo-text text-sm-center mt-5"><img src="/images/login-logo.png" className="logo" alt="logo" /></h1>
				<div className="social_icons text-sm-center mt-4">
					 <a href="https://www.facebook.com/Virdio-107758997449902/?modal=admin_todo_tour"><i className="fa fa-facebook"></i></a> 
					{/* <FacebookLogin
					appId="2468127960075530"
					autoLoad={false}
					fields="name,email,picture"
					callback={this.responseFacebook}
					//cssClass="my-facebook-button-class"
					icon="fa fa-facebook" 
					textButton=""
					/> */}
					<a href="https://twitter.com/VirdioOfficial"><i className="fa fa-twitter"></i></a>
					<a href="https://www.instagram.com/officialvirdio/"><i className="fa fa-instagram"></i></a>
					{/* <Link to="/"><i className="fa fa-envelope-o"></i></Link> */}
					<a href="https://www.linkedin.com/company/virdio"><i className="fa fa-linkedin"></i></a>
				</div>
			</div>
			<div className="col-md-3 col-sm-3">
				<p>GET STARTED</p>
				<ul className="list">
					<li><Link to="/login">Sign In</Link></li>
					<li><Link to="/accesscode">Sign Up</Link></li>
					<li><Link to="/" onClick={(e)=>{$("#thank_you").attr({'style':'display:block'});}}>Learn More</Link></li>
					<li><Link to="/SessionGuideline" target="_blank">Class Guidelines</Link></li>
				</ul>
			</div>
			<div className="col-md-3 col-sm-4">
				<p>VIRDIO</p>
				<ul className="list">
					<li><Link to="/" onClick={(e)=>{$("#thank_you").attr({'style':'display:block'});}}>About</Link></li>
					<li><Link to="/newspage">News</Link></li>
					<li><Link to="/newsletter" target="_blank">Contact</Link></li>
					<li><Link to="/PrivacyPolicy" target="_blank">Privacy</Link></li>
					<li><Link to="/TermsofService" target="_blank">Terms of service</Link></li>
				</ul>
			</div>
			<div className="col-md-3 col-sm-7 px-sm-5 px-md-0">
				<p>SUBSCRIBE TO OUR NEWSLETTER FOR UPDATES</p>
				<p className="foot_txt">By giving us your email you agree to our <Link to ="/TermsofService" target="_blank">Terms of service</Link> and <Link to ="/PrivacyPolicy" target="_blank">Privacy</Link></p>
				<div className="input-group mb-3 mt-4">
				    <input type="text" className="form-control shadow-effect" placeholder="Email Address" name="" />
				    <div className="input-group-append">
				    	<span className="input-group-text"><Link to ="/newsletter"><i className="fa fa-angle-right"></i></Link></span>
				    </div>
			    </div>
			</div>
		</div>
	</div>
</footer>
{/* Thank you model */}
		<div className="modal pr-0" id="thank_you" aria-modal="true">
            <div className="modal-dialog modal-dialog-centered signUpwidth">
                <div className="modal-content gray-box2">
                    <div className="modal-body px-4 pb-4">
                        <div className="d-flex justify-content-end">
                            <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={(e)=>{$("#thank_you").attr({'style':'display:none'});}}><span>x</span></button>
                        </div>
                      <h4 className="modal-title font-30 white pt-3 pb-2 text-center">Thank you for your interest in Virdio.</h4>
                        <p className="pt-5 mt-4 pb-4 text-white font-20 text-center">To subscribe to the Newsletter, Please press <button type="button" className="custom_btn1 ml-3" onClick={(e)=>{$("#thank_you").attr({'style':'display:none'});browserHistory.push('/newsletter')}}>newsletter</button></p>
                        <p className="text-white text-center font-20 text-center">By using our products and services, you are agreeing to our <Link to="/TermsofService" target="_blank"><u>Terms Of Services</u></Link>, and <Link to="/PrivacyPolicy" target="_blank"><u>Privacy Policy</u></Link>.</p>
                    </div>
                </div>
            </div>
          </div>

<div className="main_loader">
	<div className="main_loader_inner"></div>
</div>
{/* Fitness Attendees Modal */}
<div className="modal pr-0" id="fitness_attendee_popup">
        <div className="modal-dialog w-90">
            <div className="modal-content modalbg px-4 radius-10">
                <div className="modal-header border_bottom_purple px-0 py-4">
                    <h4 className="modal-title text-white font-20">Recommended Classes on {this.state.interestHeading}</h4>
                    <button type="button" className="close white closepopup" data-dismiss="modal" onClick={e=>$("#fitness_attendee_popup").attr({'style':'display:none'})}>×</button>
                </div>
                <div className="modal-body px-0 py-0">
					{this.state.sessionData.map((row,i)=>
                    <div className="fitness_attendee_inner border-bottom py-4" key = {i}>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="f_image">
                                    <img src="/images/img1.jpg" id={row.id} onClick={this.indiviualSessionDetail} className="radius-10" data-toggle="modal" data-dismiss="modal" data-target="#detail_session" />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="">
                                    <h4 className="modal-title white font-book font-32 mb-2">{row.description}</h4>
                                    <p className="light_text font-18">By <span className="text-white font-weight-bold">{row.name}</span> on <span className="text-white font-weight-bold">{this.getSessionDate(row.scheduleDate)}, at {this.getSessionTime(row.scheduleDate)}</span></p>
                                    <div className="d-flex justify-content-between align-items-center pt-1">
                                        <div>
                                            <p className="light_text text-uppercase mr-4 mb-0 float-left">interests</p>
                                            <p className="text-workout float-left mb-0 border_bottom_purple pb-1">Workout</p>
                                        </div>
                                        <div className="">
                                            <p className="number mb-0"><sup className="mr-2 light_text">$</sup>0<sup className="ml-2 light_text"></sup></p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                        <div>
                                            <p className="light_text text-uppercase mr-4 mb-0 float-left">members</p>
                                            <p className="text-white font-weight-bold float-left"><span>0 have signed up</span></p>
                                        </div>
                                        <div className="">
                                            <p className="mb-0">
                                                <button className="btn btn-primary px-5 py-2" data-dismiss="modal" onClick={e=>browserHistory.push('/accesscode')}>Sign up</button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
					)}

                    {/* <div className="fitness_attendee_inner border-bottom py-4">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="">
                                    <img src="images/img1.jpg" className="img-fluid radius-10" data-toggle="modal" data-dismiss="modal" data-target="#detail_session" />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="">
                                    <h4 className="modal-title white font-book font-32 mb-2">Another Class Title without Price</h4>
                                    <p className="light_text font-18">By <span className="text-white font-weight-bold">Anthony Greg</span> on <span className="text-white font-weight-bold">Sep 15, 2019, at 10:30 AM</span></p>
                                    <div className="d-flex justify-content-between align-items-center pt-1">
                                        <div>
                                            <p className="light_text text-uppercase mr-4 mb-0 float-left">interests</p>
                                            <p className="text-workout float-left mb-0 border_bottom_purple pb-1">Workout</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                        <div>
                                            <p className="light_text text-uppercase mr-4 mb-0 float-left">members</p>
                                            <p className="text-white font-weight-bold float-left"><span>43 have signed up</span></p>
                                        </div>
                                        <div className="">
                                            <p className="mb-0">
                                                <button className="btn btn-primary px-5 py-2" data-dismiss="modal" onClick={e=>browserHistory.push('/signUp')}>Sign up</button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                     {/* <div className="fitness_attendee_inner py-4">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="">
                                    <img src="images/img1.jpg" className="img-fluid radius-10" data-toggle="modal" data-dismiss="modal" data-target="#detail_session" />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="">
                                    <h4 className="modal-title white font-book font-32 mb-2">Class no price and no members</h4>
                                    <p className="light_text font-18">By <span className="text-white font-weight-bold">Anthony Greg</span> on <span className="text-white font-weight-bold">Sep 15, 2019, at 10:30 AM</span></p>
                                    <div className="d-flex justify-content-between align-items-center pt-1">
                                        <div>
                                            <p className="light_text text-uppercase mr-4 mb-0 float-left">interests</p>
                                            <p className="text-workout float-left mb-0 border_bottom_purple pb-1">Workout</p>
                                        </div>
                                    </div>
                                    <div className="mt-3 overflow-hidden">
                                        <p className="mb-0 float-right">
                                            <button className="btn btn-primary px-5 py-2" data-dismiss="modal" onClick={e=>browserHistory.push('/signUp')}>Sign up</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
					
	{/* Coming Soon model  */}
	<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
		<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
			<div className="modal-content bg-transparent">
				<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
					<p className="mb-0 py-4 white text-center">Coming Soon</p>
				</div>
				<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
			</div>
		</div>
	</div>
	{/* Session Details Modal */}
	
	<div className="modal pr-0" id="detail_session">
        <div className="modal-dialog w-90 m-auto">
		{this.state.indiviualSessionData?
            <div className="modal-content bg-none px-4 radius-10 py-5">
                <div className="modal-header px-0 py-4 border-0">
                    <h4 className="modal-title">Upper body workout session (intensive)</h4>
                </div>
                <div className="modal-body px-0 py-0 ">
                    <div className="detail_session_img">
                        <img src="images/session-banner.jpg" className="w-100" />
                    </div>
                    <div className="detail_session_inner modalbg px-md-5 px-4 pb-md-5 pb-4 radius-10 position-relative">
                        <div className="d-flex align-items-end translatey flex-wrap">
                            <p className="mb-0"><img src="images/person.jpg" className="radius-10" /></p>
                         <p className="light_text font-18 ml-md-5 mt-3 mt-md-0 mb-0">By <span className="text-white font-weight-bold">{this.state.indiviualSessionData.firstName} {this.state.indiviualSessionData.lastName}</span> on <span className="text-white font-weight-bold">{this.getSessionDate(this.state.indiviualSessionData.sessionData.scheduleDate)}, at {this.getSessionTime(this.state.indiviualSessionData.sessionData.scheduleDate)}</span></p>
                        </div>
                        <div className="border-bottom pb-md-5 pb-4 mt--20">
						<p className="text-white font-16">
                                {this.state.indiviualSessionData.sessionData.description}
                            </p>
                            <div className="d-flex justify-content-between align-items-center pt-4">
                                <div>
                                    <p className="light_text text-uppercase mr-4 mb-0 float-left">members</p>
									 <p className="text-white font-weight-bold float-left"><span>0 have signed up</span></p>
                                </div>
                                <div>
                                    <p className="light_text text-uppercase mr-4 mb-0 float-left">cut off date</p>
                                    <p className="text-white font-weight-bold float-left"><span>Sep 15, 2019</span></p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center pt-1">
                                <div>
                                    <p className="light_text text-uppercase mr-4 mb-0 float-left">interests</p>
                                    <p className="text-workout float-left mb-0 border_bottom_purple pb-1">Workout</p>
                                </div>
                            </div>
                        </div>
                        <div className="border-bottom py-md-5 py-4">
                            <div className="row">
                                <div className="col-md-6 pr-lg-5">
                                    <div className="pr-lg-5">
                                        <p className="light_text text-uppercase mb-4 mr-4"><img src="images/shopping_icon.png" className="mr-3" />equipments required</p>
                                        {/* <div className="d-flex justify-content-between align-items-center pl-2">
                                            <p className="text-white font-18 font-medium">
                                                <i className="fa fa-circle mr-3 purple_dots"></i>Dumbells (3kg each)</p>
                                            <p className="text-white font-18 font-medium">2 units</p>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center pl-2">
                                            <p className="text-white font-18 font-medium">
                                                <i className="fa fa-circle mr-3 purple_dots"></i>Stretch band (2 m)</p>
                                            <p className="text-white font-18 font-medium">1 units</p>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center pl-2">
                                            <p className="text-white font-18 font-medium">
                                                <i className="fa fa-circle mr-3 purple_dots"></i>Torso band (1 m)</p>
                                            <p className="text-white font-18 font-medium">1 units</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-md-6 pl-lg-5">
                                    <div className="pl-lg-5">
                                        <p className="light_text text-uppercase mb-4 mr-4"><img src="images/shopping-icon.png" className="mr-3" />shopping required</p>
                                        {/* <div className="d-flex justify-content-between align-items-center pl-2">
                                            <p className="text-white font-18 font-medium">
                                                <i className="fa fa-circle mr-3 purple_dots"></i>Dumbells (3kg each)</p>
                                            <p className="text-white font-18 font-medium">2 units</p>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center pl-2">
                                            <p className="text-white font-18 font-medium">
                                                <i className="fa fa-circle mr-3 purple_dots"></i>Stretch band (2 m)</p>
                                            <p className="text-white font-18 font-medium">1 units</p>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center pl-2">
                                            <p className="text-white font-18 font-medium">
                                                <i className="fa fa-circle mr-3 purple_dots"></i>Torso band (1 m)</p>
                                            <p className="text-white font-18 font-medium">1 units</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center pt-md-5 pt-4">
                            <p className="light_text text-uppercase mr-4 mb-0 float-left">entry fees</p>
                            <p className="number mb-0 font-weight-bold font-medium"><sup className="mr-2 light_text">$</sup>0<sup className="ml-2 light_text">.99</sup></p>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-5">
                <button type="button" className="btn btn-primary px-5 py-2" data-dismiss="modal" onClick={e=>browserHistory.push('/accesscode')}>Sign up</button>
            </div>
            </div>
			:''}
			

        </div>

    </div>
	
	
		<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
	</div>	
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(Dashboard);
