import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import Calendar from 'react-calendar';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import { deleteCookie } from '../auth/Logout';
import moment from 'moment';
import SupportContainer from '../home/SupportContainer';

class SessionReviews extends Component {
  
  constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state={
		userAvatar: '',
		hostNameData: '',
		sessionsArray: [],
		selectedSessionId: 0,
		selectedSession: {},
		hostsArray: [],	
		searchSessionName: '',
		filterHost: 0,
		pickedDate: '',
		searchShowTime: '',
		upcomingSessions: [],
		selectedUpcomingSessionId: [],
		selectedUpcomingSession: [],
		popText: '',
		isOpenPop: false,
		hasSearchedOnce: false,
		sessionToDelete: {},
		supportPageName: 'Session Reviews',
		supportPageApi: '/SessionReviews',
		userTypeFaqs: 2
    }

    this.deleteCookie = deleteCookie.bind(this);
}
 
componentDidMount(){
	this.setState({userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image});
    let hostName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName
    this.setState({hostNameData: hostName });
	this.getReviews();
	this.getHosts();
  };

getReviews=()=>{
	var postData = {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getReviewSessions", postData).then(res => {
		this.setState({sessionsArray: res.data.responseData});
	}).catch(err =>{ }); 
}

choseSession=(item)=>{
	if ( this.state.selectedSessionId !== item.id )
		{
			this.setState({
							selectedSessionId: item.id, 
							selectedSession: item,
							searchSessionName: item.name,
							filterHost: item.hostId,
							pickedDate: new Date(item.scheduleDate),
						}, () => { /* this.findUpcomingSessions(); */ });
		}
	else
		{
			this.setState({
							selectedSessionId: 0, 
							selectedSession: {},
							searchSessionName: '',
							filterHost: 0,
							pickedDate: ''
						});
		}
}

closePopSelected=()=>{
	this.setState({
					selectedSessionId: 0, 
					selectedSession: {},
					searchSessionName: '',
					filterHost: 0,
					pickedDate: '',
					selectedUpcomingSessionId: [],
					selectedUpcomingSession: [],
				});
}

openConfirmPop=(item)=>{
	var popTextVar = '';

	var upcomingSessionString = '';
	for ( var i = 0; i < item.length; i++ )
		{
			if ( this.state.selectedSession.eventFailed === 'classSchedule.updated' )
				{
					upcomingSessionString += item[i].name + ', ' + item[i].firstName + ' ' + item[i].lastName + ', ' + moment(item[i].scheduleDate).format('MM/DD/YYYY HH:mm') + ', ';
				}
				
			if ( this.state.selectedSession.eventFailed === 'class.updated' )
				{
					upcomingSessionString += item[i].firstName + ' ' + item[i].lastName + ', ';
				}

			if ( this.state.selectedSession.eventFailed === 'classRosterBooking.created' )
				{
					upcomingSessionString += item[i].name + ', ';
				}
		}
		
	if ( upcomingSessionString.length > 0 ) { upcomingSessionString = upcomingSessionString.substring(0, upcomingSessionString.length - 2); }

	if ( this.state.selectedSession.eventFailed === 'classSchedule.updated' )
		{
			popTextVar = 'Are you sure you want to match '+this.state.selectedSession.name+', '+this.state.selectedSession.firstName+' '+this.state.selectedSession.lastName+', '+moment(this.state.selectedSession.scheduleDate).format('MM/DD/YYYY HH:mm')+' with '+upcomingSessionString+'?';
		}
	
	if ( this.state.selectedSession.eventFailed === 'class.updated' )
		{
			popTextVar = 'Are you sure you want to change Instructor to  '+this.state.selectedSession.firstName+' '+this.state.selectedSession.lastName+' from '+upcomingSessionString+'?';
		}
		
	if ( this.state.selectedSession.eventFailed === 'classRosterBooking.created' )
		{
			popTextVar = 'Are you sure you want to add '+JSON.parse(this.state.selectedSession.userToImport).firstName+' '+JSON.parse(this.state.selectedSession.userToImport).lastName+' to '+upcomingSessionString+'?'
		}
		
	this.setState({ popText: popTextVar }, () => { $('#update_review').attr({'style':'display:block'}); });
}

choseSessionUpcoming=(item)=>{
	var sessionIdsArray = this.state.selectedUpcomingSessionId;
	var sessionArray = this.state.selectedUpcomingSession;
	
	if ( sessionIdsArray.indexOf(item.id) === -1 )
		{
			sessionIdsArray.push(item.id);
			sessionArray.push(item);
		}
	else
		{
			var indexOf = sessionIdsArray.indexOf(item.id);
			sessionIdsArray.splice(indexOf, 1);
			sessionArray.splice(indexOf, 1);
		}
	
	this.setState({ 
					selectedUpcomingSessionId: sessionIdsArray, 
					selectedUpcomingSession: sessionArray,
				});
}

handleFilterChange = e => {
	this.setState({
		[e.target.id]: e.target.value
	});	
}

getHosts=()=>{
	let postdata = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.channel, dashStatus : true }
    axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getChannelHosts", postdata)
	.then(res => {
			this.setState({
				hostsArray: res.data.responseData
			});
	}).catch(err =>{ });
}	

setSearchDate =(date, mod)=>{
	let date1=date;
	var momentDateTime = moment(date).format('YYYY-MM-DD 00:00:01');
	var momentDateTimeEnd = moment(date).format('YYYY-MM-DD 23:59:59');

	let dateofMonth = new Date(date).getDate();
	let timeSelection =  (new Date (date).getMonth()) ;
	date = new Date(Date.UTC(2019, timeSelection, 1));
	var days = [];
	var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	while (date.getMonth() === timeSelection) 
		{
			let n ={date:new Date(date).getDate(),
			day:dayofWeek[new Date(date).getDay()],
			timestamp:new Date(date).getTime(),
			}
			days.push(n);
			date.setDate(date.getDate() + 1);
		}
	
	if ( mod == 1 )
		{
			this.setState({
				pickedDate: date1,
			});

		}
}

findUpcomingSessions=()=>{
	var dateToSendStart = moment(this.state.pickedDate).format('YYYY-MM-DD 00:00:00');
	var dateToSendEnd = moment(this.state.pickedDate).format('YYYY-MM-DD 23:59:59');
	var postData = {
						channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
						sessionName: this.state.searchSessionName,
						hostId: this.state.filterHost,
						startDate: dateToSendStart,
						endDate: dateToSendEnd
					};

	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getUpcomingToReview", postData)
	.then(res => {
		this.setState({upcomingSessions: res.data.responseData, hasSearchedOnce: true});
		$('.main_loader').attr({'style':'display:none'});
	}).catch(err =>{ $('.main_loader').attr({'style':'display:none'}); });
}

closeEdit=()=>{
	$('#update_review').attr({'style':'display:none'});
	this.setState({ selectedUpcomingSessionId: [], selectedUpcomingSession: [] });
}

updateSession=()=>{
	
	if ( this.state.selectedSession.eventFailed === 'classSchedule.updated' )
		{
			var dateToSendStart = moment(this.state.selectedSession.scheduleDate).format('YYYY-MM-DD HH:mm');
			var dateToSendEnd = moment(this.state.selectedSession.scheduleEndDate).format('YYYY-MM-DD HH:mm');
			
			var description = this.state.selectedSession.description;
			if ( description.length > 250 ) { description = description.slice(0, 250); }
			
			var postData = {
								'sessionArray': JSON.stringify(this.state.selectedUpcomingSessionId),
								'reviewSessionId': this.state.selectedSession.id,
								'startDate': dateToSendStart,
								'endDate': dateToSendEnd,
								'hostId': this.state.selectedSession.hostId,
								'name': this.state.selectedSession.name,
								'duration': this.state.selectedSession.duration,
								'channelId': JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
								'currentHostId': this.state.selectedSession.hostId,
								'maxCapacity': this.state.selectedSession.maxCapacity,
								'description': description
							};
			
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/session/updateReview", postData).then(res => {
				$('.main_loader').attr({'style':'display:none'});
				$('#update_review').attr({'style':'display:none'});
				this.getReviews();
				this.setState({selectedUpcomingSession: [], selectedUpcomingSessionId: [], selectedSession: {}, selectedSessionId: 0});
			}).catch(err =>{ 
				this.props.showMainErrorPop(err.response.data.errorData); 
				this.closeEdit();
				$('.main_loader').attr({'style':'display:none'}); 
			});
		}
	
	if ( this.state.selectedSession.eventFailed === 'class.updated' )
		{
			var postData = {
								'newHostId': this.state.selectedSession.hostId,
								'sessionsArray': JSON.stringify(this.state.selectedUpcomingSession),
								'reviewSessionId': this.state.selectedSessionId
							};
					
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/session/updateReviewHost", postData).then(res => {
				$('.main_loader').attr({'style':'display:none'});
				$('#update_review').attr({'style':'display:none'});
				this.getReviews();
				this.setState({selectedUpcomingSession: [], selectedUpcomingSessionId: [], selectedSession: {}, selectedSessionId: 0});
			}).catch(err =>{ 
				this.closeEdit();
				$('.main_loader').attr({'style':'display:none'}); 
			});
		}
	
	if ( this.state.selectedSession.eventFailed === 'classRosterBooking.created' )
		{
			var postData = {
								'userObject': this.state.selectedSession.userToImport,
								'sessionArray': JSON.stringify(this.state.selectedUpcomingSessionId),
								'channelId': JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
								'reviewSessionId': this.state.selectedSession.id
							};
			
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/session/updateReviewUser", postData).then(res => {
				$('.main_loader').attr({'style':'display:none'});
				$('#update_review').attr({'style':'display:none'});
				this.getReviews();
				this.setState({selectedUpcomingSession: [], selectedUpcomingSessionId: [], selectedSession: {}, selectedSessionId: 0});
			}).catch(err =>{ 
				this.props.showMainErrorPop(err.response.data.errorData); 
				this.closeEdit();
				$('.main_loader').attr({'style':'display:none'}); 
			});
		}
}

openDeleteReview=(item)=>{
	this.setState({sessionToDelete: item}, () => { $('#delete_review').attr({'style':'display:block'}); });
}

closeDeleteReview=()=>{
	this.setState({sessionToDelete: {}}, () => { $('#delete_review').attr({'style':'display:none'}); });
}

deleteReview=()=>{
	var postData = {'id': this.state.sessionToDelete.id};
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/deleteReviewSession", postData).then(res => {
		$('.main_loader').attr({'style':'display:none'});
		this.getReviews();
		this.closeDeleteReview();
	}).catch(err =>{ 
		this.props.showMainErrorPop('Something went wrong. Please try again.'); 
		$('.main_loader').attr({'style':'display:none'}); 
	});
}

openInfoReview=(i)=>{
	var sessions = this.state.sessionsArray;
	sessions[i].isOpenInfo = !sessions[i].isOpenInfo;
	this.setState({ sessionsArray: sessions });
}

  render() {
	 return (
        <div>
            <div id="root">
                <div className="App">
                    <div className="container-fluid custom-width pb-5">
                        <div className="row top-header px-2 px-md-4 py-4">
                            <div className="col-lg-6 flex-wrap d-flex justify-content-center justify-content-lg-start p-2">
                            <div className="align-self-center">
                                <img src="/images/login-logo.png" className="logo my-2 cursor-pointer" alt="logo" onClick={e=>{$("#go_Dashboard").attr({'style':'display:block'});browserHistory.push('/DashboardLanding')}}/>
                            </div>
                            <div className="d-flex d-md-block justify-content-center px-2 px-md-4 py-2">
                                <div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
                                <div className="pl-4">
                                <h3>Welcome {this.state.hostNameData}!</h3>
                                   
                                </div>
                                </div>
                            </div>
                            </div>
							 <div className="col-lg-6 ">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Average Attendance</p>
                                        <h3>0%</h3>
                                    </div>
                                    <span className=""></span>
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Views</p>
                                        <h3>0</h3>
                                    </div>
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Revenue</p>
                                        <h3>$0</h3>
                                    </div>
                                    <div className="message-notification mx-2 d-none">
                                        <img src="/images/message.png" alt = '#'/>
                                        <span className="message-count">0</span>
                                    </div>
                                    <button className="btn btn-outline-secondary font-weight-bold radius-8  align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}}>LOG OUT</button>
                                </div>
                            </div> 
                        </div>
                      
                        <div className="gray-box2 radius-10 pb-5 relative">
                            <Link to='/DashboardLanding'>
								<div className="closePopup mainclose_position"></div>
							</Link>
                            <div className="d-flex justify-content-between align-items-center px-4 pb-4 border-bottom">
                                <div className="mt-4">
                                    <h4 className="text-white d-inline-block font-22 pt-1 pl-2 mb-0 membertopheader">Class Reviews</h4>
                                </div>
                            </div>
							{ this.state.sessionsArray.length > 0 &&
								<div className="review_list_all">
									<div className="reviews_menu rpr40">
										<div className="one_reviews_menu w25rev">Class Name</div>
										<div className="one_reviews_menu w25rev">Class Date</div>
										<div className="one_reviews_menu w25rev">Class Instructor</div>
										<div className="one_reviews_menu w25rev">Event Failed</div>
									</div>
									{ this.state.sessionsArray.map((row, i)=>
										<div className="one_review rpr40" key={i}>
											<div className={"review_cbx " + (this.state.selectedSessionId === row.id ? 'active' : '')} onClick={() => this.choseSession(row)}></div>
											<div className="one_reviews_menu w25rev review_item relative_review_name">{row.name}
												{ row.failedInfo !== '' &&
													<div className="info_review_icon" onClick={() => {this.openInfoReview(i)}}></div>
												}
												{ row.isOpenInfo &&
													<div className="review_info">{row.failedInfo}</div>
												}
											</div>
											<div className="one_reviews_menu w25rev review_item">{moment(row.scheduleDate).format('MM/DD/YYYY hh:mm a')}</div>
											<div className="one_reviews_menu w25rev review_item">{row.firstName} {row.lastName}</div>
											<div className="one_reviews_menu w25rev review_item">{row.eventFailed}</div>
											<div className="delete_review" onClick={() => this.openDeleteReview(row)}></div>
										</div>
									)}
								</div>
							}
							
							{ this.state.sessionsArray.length === 0 &&
								<div className="empty_review">No available Classes to Review</div>
							}
                        </div> 
                    </div>
                </div>
            </div>
			
			{ this.state.selectedSessionId > 0 &&
				<div className="pop_container">
					<div className="relative_pop_holder">
						<div className="closePopup" onClick={this.closePopSelected}></div>
						<div className="review_list_all">
							<div className="reviews_title">Class Search</div>
							<div className="session_info_chosen">
								<div className="session_item_chosen">{this.state.selectedSession.name}</div>
								<div className="session_item_chosen">{moment(this.state.selectedSession.scheduleDate).format('MM/DD/YYYY hh:mm a')}</div>
								<div className="session_item_chosen">{this.state.selectedSession.firstName} {this.state.selectedSession.lastName}</div>
							</div>
							<div className="for_search_review">
								<div className="inline_review_inputs">
									<div className="form-group-header">
										<span className="cover-border"></span>
										<label className="label dashboard_search_label">Class Name</label>
										<input className="input-field" id="searchSessionName" value={this.state.searchSessionName} onChange={this.handleFilterChange} />
									</div>
								</div>
								<div className="inline_review_inputs">
									<div className="form-group-header">
										<span className="cover-border"></span>
										<label className="label dashboard_search_label">Pick Date</label>
										<div className="">
											<input type="text" value={this.state.searchShowTime || ''} className="input-field layout_border" readOnly/>
											<div className="date_picker_hold dashboard_search_date_picker t5"><DatePicker className="form-control dt_input date_picker_hold t5 date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedDate} onChange={date => this.setSearchDate(date, 1)} /></div>
											<div className="when-icon search_calendar_icon">
												<div className="flex_calendar_hold">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="inline_review_inputs">
									<div className="form-group-header">
										<span className="cover-border"></span>
										<label className="label dashboard_search_label">Instructor</label>
										<select className="input-field dashboard_main_select" id="filterHost" value={this.state.filterHost} onChange={this.handleFilterChange} >
											<option value=""></option>
											{ this.state.hostsArray.sort(function(a, b){ if(a.username < b.username) { return -1; } if(a.username > b.username) { return 1; } return 0; }).map((item, key) => 
												<option value={item.userId} key={key}>{item.username}</option>
											)}
										</select>
									</div>
								</div>
							</div>
							<div className="for_search_review">
								<div className="pending_btn find_btn" onClick={this.findUpcomingSessions}>SEARCH</div>
							</div>
							{ this.state.upcomingSessions.length > 0 &&
								<div>
									<div className="reviews_menu">
										<div className="one_reviews_menu">Class Name</div>
										<div className="one_reviews_menu">Class Date</div>
										<div className="one_reviews_menu">Class Instructor</div>
									</div>
									{ this.state.upcomingSessions.map((row, i)=>
										<div className="one_review" key={i}>
											<div className={"review_cbx " + (this.state.selectedUpcomingSessionId.indexOf(row.id) > -1 ? 'active' : '')} onClick={() => this.choseSessionUpcoming(row)}></div>
											<div className="one_reviews_menu review_item">{row.name}</div>
											<div className="one_reviews_menu review_item">{moment(row.scheduleDate).format('MM/DD/YYYY hh:mm a')}</div>
											<div className="one_reviews_menu review_item">{row.firstName} {row.lastName}</div>
										</div>
									)}
									{ this.state.selectedUpcomingSessionId.length > 0 &&
										<div className="for_search_review">
											<div className="pending_btn find_btn" onClick={() => this.openConfirmPop(this.state.selectedUpcomingSession)}>MATCH</div>
										</div>
									}
									{ this.state.selectedUpcomingSessionId.length === 0 &&
										<div className="for_search_review">
											<div className="pending_btn find_btn opacity05">MATCH</div>
										</div>
									}
								</div>
							}
							{ this.state.upcomingSessions.length === 0 && this.state.hasSearchedOnce &&
								<div className="empty_review">No available Upcoming Classes. Please search again.</div>
							}
							{ this.state.upcomingSessions.length === 0 && !this.state.hasSearchedOnce &&
								<div className="empty_review">Start searching Upcoming Classes by clicking on Search button.</div>
							}
						</div>
					</div>
				</div>
			}

			<div className="modal pr-0" id="update_review" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center popup_review_text">{this.state.popText}</p>
						</div>
						<div className="text-center">
							<button type="button" className="col-4 custom_btn1 mt-3" onClick={this.closeEdit}>NO</button>
							<button type="button" className="custom_btn1 col-4 mt-3" style={{marginLeft: '50px'}} onClick={this.updateSession}>YES</button>
						</div>
					</div>
				</div>
			</div>	
			
			<div className="modal pr-0" id="delete_review" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Are you sure you want to delete {this.state.sessionToDelete.name} from list?</p>
						</div>
						<div className="text-center">
							<button type="button" className="col-4 custom_btn1 mt-3" onClick={this.closeDeleteReview}>NO</button>
							<button type="button" className="custom_btn1 col-4 mt-3" style={{marginLeft: '50px'}} onClick={this.deleteReview}>YES</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>	
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(SessionReviews);
