import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import ReportsPages from '../reports/ReportsPages';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import moment from 'moment';
import { getLocalDateTime } from '../../util/formatDateFunctions';

class MembersTrackingList extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					showSearch: false,
					searchFirstDate: new Date(),
					searchFirstTo: new Date(),
					searchRegisterDate: null,
					searchRegisterTo: null,
					searchBy: '',
					membersArray: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					sortByVal: ['member_visit_tracking.id', 'DESC'],
					ajaxLoaded: false,
					checkInfoOpen: false,
					itemsDescription: 'Members',
					allCategories: [],
					allSubcategories: [],
					allSubcategoriesTemp: [],
					filterCategory: '',
					filterSubCategory: '',
					allWeeks: [],
					filterWeek: '',
					registerPage: '',
					verificationPage: '',
					accountVerified: '',
					manageMembership: '',
					registerEntries: '',
					subTab: 'On-Demand classes',
					filterBoolOptions: [{ title: 'Register page', param: 'registerPage'},
										{ title: 'Verification page', param: 'verificationPage'},
										{ title: 'Account verified', param: 'accountVerified'}, 
										{ title: 'Manage membership', param: 'manageMembership'},
										{ title: 'Register entries', param: 'registerEntries'}]
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
	this.getLocalDateTime = getLocalDateTime.bind(this);
}

componentDidMount(){
	this.getWeeks(0);
	this.checkForMembers();
}
 
componentWillUnmount() {

}

checkForMembers=()=>{
	 $('.main_loader').attr({'style':'display:flex'});
  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getAllMembersTracking", {})          
  .then(res => {
	  if (res.data.responseData.userArray)
		{
			this.setState({
				membersArray: res.data.responseData.userArray,
			});
		}
	else 
		{
			this.setState({
				membersArray: [],
			});
		}		
	   $('.main_loader').attr({'style':'display:none'});
  }) .catch(err =>{
	  this.setState({
				membersArray: [],
			});
	  console.log('----------there is problem------------', err);
	  $('.main_loader').attr({'style':'display:none'});
  });
}

changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchMemberDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchMemberDetails(mod, this.state.page_num_count);
		}
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ 
								showSearch: !this.state.showSearch, 
								searchFirstDate: null, 
								searchFirstTo: null, 
								searchRegisterDate: null, 
								searchRegisterTo: null, 
								searchBy: '', 
								filterFirstDateWeek: '', 
								filterRegisterDateWeek: '',
								
								registerPage: null,
								registerEntries: null,
								verificationPage: null,
								accountVerified: null,
								manageMembership: null }, ()=>{
								this.fetchMemberDetails(this.state.page_id, this.state.page_num_count);
							});
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}


searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchMemberDetails(pg, this.state.page_num_count); 
}

fetchMemberDetails = (page_id, countId, searchText = this.state.searchBy, firstDate= this.state.searchFirstDate, firstTo= this.state.searchFirstTo, registerDate= this.state.searchRegisterDate, registerTo= this.state.searchRegisterTo) => {

	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});

  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getAllMembersTracking", {
																			page_id: page_id,
																			countId: countId,
																			searchText: searchText,
																			firstDate: moment(firstDate).startOf('day').toDate(),
																			firstTo: moment(firstTo).endOf('day').toDate(),
																			registerDate: registerDate ? moment(registerDate).startOf('day').toDate() : null,
																			registerTo: registerTo ? moment(registerTo).endOf('day').toDate() : null,
																			sortby: this.state.sortByVal,
																			
																			
																			registerPage: this.state.registerPage,
																			registerEntries: this.state.registerEntries,
																			verificationPage: this.state.verificationPage,
																			accountVerified: this.state.accountVerified,
																			manageMembership: this.state.manageMembership,
																		})

  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({membersArray: res.data.responseData['members'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	
	
	if( (this.state.page_id+3) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			let newState = this.state.page_id < 3 ? 0 : this.state.page_id - 2;
			
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchMemberDetails(this.state.page_id, this.state.page_num_count);
	});
}

handleFilterChange = (e, mod) => {

	let mainthis = this;
	this.setState({
		[e.id]: e.value
	}, ()=>{
		if (mod)
			{
				mainthis.choseWeek(e.value, mod)
			}
	});
}

choseWeek = (e, mod) =>{
	let mainthis = this;
	let tempStart = '';
	let tempEnd = '';
	if (e != '' )
		{
			tempStart = new Date(e);
			let temp = tempStart.getTime()+(7*24*60*59*1000);
			tempEnd = new Date(temp);
		}
	if ( mod == 'firstDateWeek' )
		{
			this.setState({
				searchFirstDate: tempStart, 
				searchFirstTo: tempEnd
			}, ()=>{ if(mod) {
					mainthis.fetchMemberDetails(mainthis.state.page_id, mainthis.state.page_num_count);
				}});
		}
	else
		{
			this.setState({
				searchRegisterDate: tempStart, 
				searchRegisterTo: tempEnd
			}, ()=>{ if(mod) {
					mainthis.fetchMemberDetails(mainthis.state.page_id, mainthis.state.page_num_count);
				}});
		}
}

getWeeks = mod =>{ 
	let mainthis = this;
	var dt = new Date();
	var currentYear = dt.getFullYear();
	var currentMonth = dt.getMonth();
	let fistDayMonth = new Date((new Date().getFullYear()-mod), 0, 1);
	let topDate = new Date();
	
	let dates = []
	const addDays = function (days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};
		
	let currentDate = fistDayMonth
	if (currentDate.getDay() > 0) {
		currentDate.setDate(currentDate.getDate() - currentDate.getDay());
	}
	 
	while (currentDate <= topDate) {
	  let endWeekDate = addDays.call(currentDate, 6);
	  dates.push({
			begin: currentDate, 
			end: endWeekDate,
			title: moment(currentDate).format('MMM DD, YYYY')+'/'+moment(endWeekDate).format('MMM DD, YYYY'),
			value: currentDate
		});
	  currentDate = addDays.call(currentDate, 7);
	 }
	let reverseWeeks = dates.reverse();
	this.setState({
		allWeeks: reverseWeeks,
	}, ()=> { mainthis.fetchMemberDetails(mainthis.state.page_id, mainthis.state.page_num_count);});
}


  render() {
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className={"month_h1 active_tab "}>Members Visited Virdio</div>
								</div>			
								<div className="show_pay_page chwidth15">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
											<option value="10000000">All</option>
										</select>
									 </span>
									{this.state.itemsDescription}
								</div>								
								<div className="show_search_opt chwidth85">	
								
								{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center col-xl-6 fl43">
														<input type="text" className="form-control" placeholder="Search for member name or email" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
														{ this.state.filterBoolOptions.map((item, key)=>
																		<div key={key} className="form-group-header interest_search">
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label text_wrap text_limit">{item.title}</label>
																			<select className="input-field dashboard_main_select" id={item.param} value={this.state[item.param]} onChange={(e) => this.handleFilterChange(e.target)} >
																				<option value=""></option> 
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>
																	)}
													</div>
													<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center nopadlr col-xl-6 fl57">
														<div className="parent-row mb-3 full_width">				
															<div className="row mx-0 row1 d-flex">
																<div className="col-md-12 d-flex flex-wrap align-items-center nopadlr">	
																	<div className="filter_item">
																		<span className="searh_span">First Date</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchFirstDate} onChange={date => this.setState({searchFirstDate: date, filterFirstDateWeek: ''})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchFirstTo} onChange={date => this.setState({searchFirstTo: date, filterFirstDateWeek: ''})} />
																	</div>
																	<div className="form-group-header interest_search mrglft15">
																		<span className="cover-border"></span>
																		<label className="label dashboard_search_label">Week</label>
																		<select className="input-field dashboard_main_select" id="filterFirstDateWeek" value={this.state.filterFirstDateWeek} onChange={(e) => this.handleFilterChange(e.target, 'firstDateWeek')} >
																			<option value=""></option>
																				{ this.state.allWeeks.map((item, key)=> 
																					<option value={item.value} key={key}>{item.title}</option>
																				)}
																		</select>
																	</div>
																</div>
																<div className="col-md-12 d-flex flex-wrap align-items-center nopadlr">	
																	<div className="filter_item">
																		<span className="searh_span">Register Date</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchRegisterDate} onChange={date => this.setState({searchRegisterDate: date, filterRegisterDateWeek: ''})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchRegisterTo} onChange={date => this.setState({searchRegisterTo: date, filterRegisterDateWeek: ''})} />
																	</div>
																	<div className="form-group-header interest_search mrglft15">
																		<span className="cover-border"></span>
																		<label className="label dashboard_search_label">Week</label>
																		<select className="input-field dashboard_main_select" id="filterRegisterDateWeek" value={this.state.filterRegisterDateWeek} onChange={(e) => this.handleFilterChange(e.target, 'registerDateWeek')} >
																			<option value=""></option>
																				{ this.state.allWeeks.map((item, key)=> 
																					<option value={item.value} key={key}>{item.title}</option>
																				)}
																		</select>
																	</div>
																</div>
															</div>
														</div>
												</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
								
								
								</div>
								{ this.state.membersArray.length > 0 && 
									<table className="billing_table">
										<thead>
											<tr>
												<th className="paddR20">User Id
													<div onClick={ (e) => this.sortArrays('cuserId')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">First Visit
													<div onClick={ (e) => this.sortArrays('member_visit_tracking.firstTimeVisitDate')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Full Name
													<div onClick={ (e) => this.sortArrays('users.firstName')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Email
													<div onClick={ (e) => this.sortArrays('users.email')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Registered at
													<div onClick={ (e) => this.sortArrays('users.createAt')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Register page
													<div onClick={ (e) => this.sortArrays('member_visit_tracking.register')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Register entries
													<div onClick={ (e) => this.sortArrays('member_visit_tracking.register_entry')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Verification page
													<div onClick={ (e) => this.sortArrays('member_visit_tracking.verification')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Account verified
													<div onClick={ (e) => this.sortArrays('member_visit_tracking.verify')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Manage membership
													<div onClick={ (e) => this.sortArrays('member_visit_tracking.membership')} className="reports_sort_icon vertMidd"></div>
												</th>
												{/*
												<th className="paddR20">Website</th>
												*/}
											</tr>
										</thead>
										<tbody>
											{ this.state.membersArray.map((item, key) =>
												<tr key={key}>
													<td>
														{item.cuserId ? 
															<span> {item.cuserId} </span>
															:
															<span>{item.id}*</span>
														}
													</td>
													<td>
														<span> {getLocalDateTime(item.firstTimeVisitDate)}</span>
													</td>
													<td>	
														{item.cuserId ? 
															<span> {item.firstName} {item.lastName}</span>
															:
															<span> - </span>
														}
													</td>
													<td>	
														{item.cuserId ? 
															<span> {item.email}</span>
															: item.email_entry ?
															<span>{item.email_entry}</span>
															:
															<span> - </span>
														}
													</td>
													<td>	
														{item.cuserId ? 
															<span> {getLocalDateTime(item.createAt)}</span>
															:
															<span> - </span>
														}
													</td>
													<td>
														{item.register ? 
															<span>Yes</span>
															:
															<span> - </span>
														}
													</td>
													<td>
														{item.register_entry ? 
															<span>Yes</span>
															:
															<span> - </span>
														}
													</td>
													<td>
														{item.verification ? 
															<span>Yes</span>
															:
															<span> - </span>
														}
													</td>
													<td>
														{item.verify ? 
															<span>Yes</span>
															:
															<span> - </span>
														}
													</td>
													<td>
														{item.membership ? 
															<span>Yes</span>
															:
															<span> - </span>
														}
													</td>
													{/*
													<td>
														{item.website_visit ? 
															<span>Yes</span>
															:
															<span> - </span>
														}
													</td>
													*/}
												</tr>
											)}
										</tbody>
									</table>
								}
								{ this.state.membersArray.length > 0 && 
									<ReportsPages 
										page_id={this.state.page_id}
										page_num_count={this.state.page_num_count}
										fullCount={this.state.fullCount}
										maxPages={this.state.maxPages}
										changePage={this.changePage}
										page_numbers={this.state.page_numbers}
										checkInfoOpen={this.state.checkInfoOpen}
										itemsDescription={this.state.itemsDescription}
									/>
								}
								{ this.state.membersArray.length == 0 && 
									<div className="noResults2">No Members Found</div> 
								}
							</div>
						</div>
					</div>
				</div>
			</div>		
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default MembersTrackingList;