import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class ActivityList extends Component {
  
  constructor(props) {
	super(props);
	this.state={
		months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		isVideoOpen: false,
		activityName: '',
		videoPlaying: false,
		activityVideo: '',
		videoDuration: 0,
		durationTime: 0,
		intervalStart: '',
    }
	
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}
 
componentDidMount(){
	
}


openVideo =(mod)=>
{
	console.log('open video', mod);
	this.setState({
		isVideoOpen: true,
		activityName: mod['ActivityName'],
		activityDuration: mod['Count'],
		activityVideo: mod['Video'],
		videoDuration: mod['VideoDuration']
	}, () => { 
		
		//console.log(this.refs.video);
		//console.log(this.state.videoDuration);
		let time = this.state.videoDuration;
		var minutes = Math.floor(time / 60);
		//And to get the remaining seconds, multiply the full minutes with 60 and subtract from the total seconds:

		var seconds = time - minutes * 60;
		//Now if you also want to get the full hours too, divide the number of total seconds by 3600 (60 minutes/hour · 60 seconds/minute) first, then calculate the remaining seconds:

		var hours = Math.floor(time / 3600);
		time = time - hours * 3600;
			
			
		let durationTime = minutes+':'+Math.round(seconds);	
		
		this.setState({
			durationTime: durationTime,
		});
	})
}
closeVideo =(e)=>{
	this.setState({
		isVideoOpen: false,
	})
}
playVideo =(e)=>{
	this.refs.video.play();
	this.setState({
		videoPlaying: true,
	}, () => {this.startDurationWidth('start')});
}
stopVideo=e=>{
	console.log('aaaaaa');
	this.refs.video.pause();
	console.log(this.refs.video.currentTime);
	this.setState({
		videoPlaying: false,
	}, () => { this.startDurationWidth('stop') })
}

startDurationWidth=(mod)=>{
	{/*
	let interval ;
	if (mod == 'start')
		{
			let mainthis = this;
			interval = setInterval( function()
				{
					console.log(mainthis.refs.video.currentTime);	
				}, 1000);
		}
	else 
		{
			clearInterval(interval);
		}	
	*/}	
}

  render() {
	 return (
		<div className="modal-dialog mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent ">
					{ !this.state.isVideoOpen &&
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none no_padd_modal">
							<button type="button" className="close white closepurple p-0" data-dismiss="modal"><span>&times;</span></button>
							<div className="activity_pop_holder pb0">
								<div className="act_h1" >Class Name: <span className="act_span1" >{this.props.state.individualSession.name}</span> </div>
								<div className="act_h2">
									<span className="act_span2 ml0"><img src="/images/record.svg" className="clock_img_act" /> { this.getSessionDate(this.props.state.individualSession.scheduleDate)} @ {this.getSessionTime(this.props.state.individualSession.scheduleDate)} </span> 
									<span className="act_span2 disp_blck"><img src="/images/clock.svg" className="clock_img_act" /> {this.props.state.individualSession.duration} Min</span>
								</div>
							</div>
							<div className="activity_inner">
								<div className="activity_title_main">Activities</div>
								<div className="workout_container_main">
									{ this.props.state.individualSession.activityArray.map((item, i)=>
										<div className="one_workout">
											{ !item.ActivityDisplayName ?
												<div className="title_workout">{item.ActivityName}</div>
												:
												<div className="title_workout">{item.ActivityDisplayName}</div>
											}
											<div className="bottom_container_work">
												<div className="time_container_work">
													<div className="time_worknum">{item.Count}</div>
													<div className="time_worktype">{item.DurationType === "Time" ? 'Sec' : 'Reps'}</div>
												</div>
												{ item.Video !== '' &&		
													<div className="workout_video" onClick={ e => {this.openVideo(item)}}></div>
												}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					}
					{ this.state.isVideoOpen &&
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none no_padd_modal">		
							<button type="button" className="close white closepurple p-0" onClick={this.closeVideo}><span>&times;</span></button>
							<div className="activity_pop_holder" >
								<div className="act_h1 pb30">Activity Name: <span className="act_span1" >{this.state.activityName}</span> </div>
								<div className="videoHolder_new">
									<video width="100%" height="100%" ref="video" controls disablePictureInPicture>
										<source src={this.state.activityVideo} type="video/mp4" />
									</video>
									{/*
									<div className="video_cntrls">
										<div className="duration_line">
											<div className="abs_duration_line"></div>
											<div className="abs_circle_pointer" ></div>
										</div>
										<div className="cntrls_holder">
										{ !this.state.videoPlaying ?
											<div className="play_video_btn" onClick={this.playVideo}></div>
											:
											<div className="play_video_btn stop_video_btn" onClick={this.stopVideo}></div>
										}
											<div className="play_video_btn stop_video" onClick={this.stop}></div>
											<div className="play_video_btn sound_ctrl"></div>
											<div className="videoDuration_new">0:00 / {this.state.durationTime}</div> 
										</div>				
									</div> */}
									
									
								</div>
							</div>
						</div>
					}
				</div>
			</div>
  )}
}
export default ActivityList;
