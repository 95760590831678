import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import moment1 from 'moment-timezone';

import SimpleReactValidator from 'simple-react-validator';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import {  browserHistory } from 'react-router'
import Countdown from 'react-countdown-now';
import Login from '../auth/Login';
import MainSignUp from '../auth/MainSignUp';
import ChargingSession from '../home/ChargingSession';
import { submitHost } from '../../util/submitHost';
import { verify } from '../../util/verify';
import { channelCheckUser } from '../../util/channelCheckUser';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import ReportsDaily from '../home/ReportsDaily';
import ReportsPast from '../home/ReportsPast';
import ReportsFuture from '../home/ReportsFuture';
import ReportsAdminBilling from '../home/ReportsAdminBilling';
import ReportsOvercharge from '../home/ReportsOvercharge';
import ReportsChannelPlan from '../home/ReportsChannelPlan';
import ReportsMonthBilling from '../home/ReportsMonthBilling';
import ReportsHostSignUp from '../home/ReportsHostSignUp';
import ReportsHistoryChannel from '../home/ReportsHistoryChannel';
import ReportsChannelWaitingList from '../home/ReportsChannelWaitingList';
import ReportsVirdioCommunity from '../home/ReportsVirdioCommunity';
import ReportsAmbassador from '../home/ReportsAmbassador';
import ReportsHistorySession from '../home/ReportsHistorySession';
import ReportsHistoryOnDemandSessions from '../home/ReportsHistoryOnDemandSessions';
import ReportsHistoryHosts from '../home/ReportsHistoryHosts';
import ReportsHistoryParticipants from '../home/ReportsHistoryParticipants';
import ReportsHistoryWaiver from '../home/ReportsHistoryWaiver';
import ReportsNewSessions from '../home/ReportsNewSessions';
import ReportsParticipantSignUp from '../home/ReportsParticipantSignUp';
import Header from './Header';
import ReactLightCalendar from '@lls/react-light-calendar'
import '@lls/react-light-calendar/dist/index.css'
import ReportsPlanDetails from '../home/ReportsPlanDetails';
import SupportContainer from '../home/SupportContainer';
import ReportsInstructorCodes from '../home/ReportsInstructorCodes';
import UtmTracker from '../home/UtmTracker';


class ReportsDashboard extends Component {
constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state = {
		dailyActivity: true,
		futureSession: false,
		pastSessions: false,
		historicalReports: false,
		billingReports: false,
		ongoingSessions: true,
		dailyHost: false,
		dailyParticipant: false,
		dailiyNewSession: false,
		billingPlan: false,
		overCharge: false,
		channelPlan: false,
		historyChannels: false,
		historySessions: false,
		historyOnDemand: false,
		historyWaivers: false,
		brandAmbassador: false,
		historyHosts: false,
		historyParticipants: false,
		historyChannelWaitingList: false,
		historyVirdioCommunity: false,
		billingPlanReports: false,
		NextMonthBilling: false,
		trialDetails: false,
		supportPageName: 'Admin Reports',
		supportPageApi: '/ReportsDashboard',
		userTypeFaqs: 2,
		historyInstructorCodes: false,
		utmTracker: false,
	}
}

componentDidMount() {
	document.addEventListener('mousedown', this.handleClickOutside);
	
	if ( localStorage['userData'] )
		{
			let userData = JSON.parse(localStorage.getItem('userData'));
			if ( userData.data && userData.data.responseData && userData.data.responseData.adminReports != 1 )
				{
					browserHistory.push("/DashboardLanding");
				}
		}
	else
		{
			browserHistory.push("/login");
		}
		
}

componentWillUnmount() {
	document.removeEventListener('mousedown', this.handleClickOutside);
}

handleClickOutside = (e) => {
	if(e.target !== "info_btn" && $(e.target).closest('.info_btn').length === 0 )
		{
			$('.all_info').removeClass('active');
		}
}

changeReportsComp=(e)=>{
	this.setState({
		ongoingSessions: false,
		dailyHost: false,
		dailyParticipant: false,
		dailiyNewSession: false,
		billingPlan: false,
		overCharge: false,
		billingPlan: false,
		channelPlan: false
	});
	
	if(e == 1)
		{
			this.setState({
				dailyActivity: true,
				futureSession: false,
				pastSessions: false,
				historicalReports: false,
				billingReports: false,
				ongoingSessions: true,
				historyChannels: false,
				historySessions: false,
				historyOnDemand: false,
				historyWaivers: false,
				brandAmbassador: false,
				historyHosts: false,
				historyParticipants: false,
				historyChannelWaitingList: false,
				billingPlanReports: false,
				NextMonthBilling: false,
				trialDetails: false,
			});
		}
	
	else if(e == 2)
		{
			this.setState({
				dailyActivity: false,
				futureSession: true,
				pastSessions: false,
				historicalReports: false,
				billingReports: false,
				historyChannels: false,
				historySessions: false,
				historyOnDemand: false,
				historyWaivers: false,
				brandAmbassador: false,
				historyHosts: false,
				historyParticipants: false,
				historyChannelWaitingList: false,
				billingPlanReports: false,
				NextMonthBilling: false,
				trialDetails: false,
			});
		}
		
	else if(e == 3)
		{
			this.setState({
				dailyActivity: false,
				futureSession: false,
				pastSessions: true,
				historicalReports: false,
				billingReports: false,
				historyChannels: false,
				historySessions: false,
				historyOnDemand: false,
				historyWaivers: false,
				brandAmbassador: false,
				historyHosts: false,
				historyParticipants: false,
				historyChannelWaitingList: false,
				billingPlanReports: false,
				NextMonthBilling: false,
				trialDetails: false,
			});
		}
		
	else if(e == 4)
		{
			this.setState({
				dailyActivity: false,
				futureSession: false,
				pastSessions: false,
				historicalReports: true,
				billingReports: false,
				historyChannels: true,
				historySessions: false,
				historyOnDemand: false,
				historyWaivers: false,
				brandAmbassador: false,
				historyHosts: false,
				historyParticipants: false,
				historyChannelWaitingList: false,
				billingPlanReports: false,
				NextMonthBilling: false,
				trialDetails: false,
			});
		}
		
	else if(e == 5)
		{
			this.setState({
				dailyActivity: false,
				futureSession: false,
				pastSessions: false,
				historicalReports: false,
				billingReports: true,
				billingPlan: true,
				historyChannels: false,
				historySessions: false,
				historyOnDemand: false,
				historyWaivers: false,
				brandAmbassador: false,
				historyHosts: false,
				historyParticipants: false,
				historyChannelWaitingList: false,
				billingPlanReports: true,
				NextMonthBilling: false,
				trialDetails: false,
			});
		}
}

changeDailyComp=(e, type?)=>{
	this.setState({
		ongoingSessions: false,
		dailyHost: false,
		dailyParticipant: false,
		dailiyNewSession: false,
		billingPlan: false,
		overCharge: false,
		channelPlan: false,
		historyChannels: false,
		historySessions: false,
		historyWaivers: false,
		historyOnDemand: false,
		NextMonthBilling: false,
		historyHosts: false,
		historyParticipants: false,
		historyChannelWaitingList: false,
		historyWaivers: false,
		NextMonthBilling: false,
		trialDetails: false,
		billingPlanReports: false,
		brandAmbassador: false,
		historyVirdioCommunity: false,
		historyInstructorCodes: false,
		utmTracker: false,
		historyInstructorCodes: false
	})
	
	
	if ( type && type == 1 )
		{
			this.setState({
				billingPlanReports: true,
			})
		}
	else if ( type && type == 2 )
		{
			this.setState({
				billingPlan: true,
			})
		}		
	this.setState(e);
}

  render() {

    return (
		<div className="container-fluid custom-width">
			<Header loginName={this.state.loginName} totalSession={this.state.totalSession} fullsubdomain={this.state.fullsubdomain}></Header>
			<div className="reports_main_title add_pos_rel">Reports Dashboard
				<div className="closePopup noprint right25" onClick={e=>{ browserHistory.push('/DashboardLanding') }}></div>
			</div>
			<div className="reports_activity_hold wrapf">
				<div className={ "reports_session_hold neww16 " + ( this.state.dailyActivity ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(1)}}>
					<div className="reports_session_title">Daily Activity</div>
					<div className="reports_session_title_image daily_icon"></div>
					<div className={ "reports_session_box " + ( this.state.dailyActivity ? "reports_session_box_off" : "" ) } ></div>
				</div>
				<div className={ "reports_session_hold neww16 " + ( this.state.futureSession ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(2)}}>
					<div className="reports_session_title">See Future Classes</div>
					<div className="reports_session_title_image"></div>
					<div className={ "reports_session_box " + ( this.state.futureSession ? "reports_session_box_off" : "" ) } ></div>
				</div>
				<div className={ "reports_session_hold neww16 " + ( this.state.pastSessions ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(3)}}>
					<div className="reports_session_title">See Past Classes</div>
					<div className="reports_session_title_image past_sessions_iocn"></div>
					<div className={ "reports_session_box " + ( this.state.pastSessions ? "reports_session_box_off" : "" ) } ></div>
				</div>
				<div className={ "reports_session_hold neww16 " + ( this.state.historicalReports ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(4)}}>
					<div className="reports_session_title">Historical Reports</div>
					<div className="reports_session_title_image undo_icon"></div>
					<div className={ "reports_session_box " + ( this.state.historicalReports ? "reports_session_box_off" : "" ) } ></div>
				</div>
				<div className={ "reports_session_hold neww16 " + ( this.state.billingReports ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(5)}}>
					<div className="reports_session_title">Billing Reports</div>
					<div className="reports_session_title_image billing_icon"></div>
					<div className={ "reports_session_box " + ( this.state.billingReports ? "reports_session_box_off" : "" ) } ></div>
				</div>
			</div>
			<div>
				{ this.state.dailyActivity &&
					<div className="daily_session_hold">
						<div className={ "daily_section " + ( this.state.ongoingSessions ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({ongoingSessions: true})}}>
							<div className="daily_title">Ongoing Classes</div>
							<div className="daily_arrow"></div>
							<div className={ "daily_arrow " + ( this.state.ongoingSessions ? "daily_arrow_active" : "" ) }></div>
						</div>
						<div className={ "daily_section " + ( this.state.dailyHost ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({dailyHost: true})}}>
							<div className="daily_title">Instructor Sign Up</div>
							<div className={ "daily_arrow " + ( this.state.dailyHost ? "daily_arrow_active" : "" ) }></div>
						</div>
						<div className={ "daily_section " + ( this.state.dailyParticipant ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({dailyParticipant: true})}}>
							<div className="daily_title">Member Sign Up</div>
							<div className={ "daily_arrow " + ( this.state.dailyParticipant ? "daily_arrow_active" : "" ) }></div>
						</div>
						<div className={ "daily_section " + ( this.state.dailiyNewSession ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({dailiyNewSession: true})}}>
							<div className="daily_title">New Class of the Day</div>
							<div className={ "daily_arrow " + ( this.state.dailiyNewSession ? "daily_arrow_active" : "" ) }></div>
						</div>
						<div className="daily_section dailiy_none">
						</div>
					</div>
				}
			</div>
			<div>
				{ this.state.historicalReports &&
					<div>
						<div className="daily_session_hold">
							<div className={ "daily_section " + ( this.state.historyChannels ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({historyChannels: true})}}>
								<div className="daily_title">Channels</div>
								<div className="daily_arrow"></div>
								<div className={ "daily_arrow " + ( this.state.historyChannels ? "daily_arrow_active" : "" ) }></div>
							</div>
							<div className={ "daily_section " + ( this.state.historySessions ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({historySessions: true})}}>
								<div className="daily_title">Classes</div>
								<div className={ "daily_arrow " + ( this.state.historySessions ? "daily_arrow_active" : "" ) }></div>
							</div>
							<div className={ "daily_section " + ( this.state.historyWaivers ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({historyWaivers: true})}}>
								<div className="daily_title">Waivers</div>
								<div className={ "daily_arrow " + ( this.state.historyWaivers ? "daily_arrow_active" : "" ) }></div>
							</div>
							<div className={ "daily_section " + ( this.state.historyOnDemand ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({historyOnDemand: true})}}>
								<div className="daily_title">On Demand Classes</div>
								<div className={ "daily_arrow " + ( this.state.historyOnDemand ? "daily_arrow_active" : "" ) }></div>
							</div>
							<div className={ "daily_section " + ( this.state.historyHosts ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({historyHosts: true})}}>
								<div className="daily_title">Instructors</div>
								<div className={ "daily_arrow " + ( this.state.historyHosts ? "daily_arrow_active" : "" ) }></div>
							</div>
						</div>
						<div className="daily_session_hold">
							<div className={ "daily_section " + ( this.state.historyParticipants ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({historyParticipants: true})}}>
								<div className="daily_title">Members</div>
								<div className={ "daily_arrow " + ( this.state.historyParticipants ? "daily_arrow_active" : "" ) }></div>
							</div>
							<div className={ "daily_section " + ( this.state.historyChannelWaitingList ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({historyChannelWaitingList: true})}}>
								<div className="daily_title">Channel Waiting List</div>
								<div className={ "daily_arrow " + ( this.state.historyChannelWaitingList ? "daily_arrow_active" : "" ) }></div>
							</div>
							<div className={ "daily_section " + ( this.state.historyVirdioCommunity ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({historyVirdioCommunity: true})}}>
								<div className="daily_title">Virdio Community</div>
								<div className={ "daily_arrow " + ( this.state.historyVirdioCommunity ? "daily_arrow_active" : "" ) }></div>
							</div>
							<div className={ "daily_section " + ( this.state.historyInstructorCodes ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({historyInstructorCodes: true})}}>
								<div className="daily_title">Instructor Codes</div>
								<div className={ "daily_arrow " + ( this.state.historyInstructorCodes ? "daily_arrow_active" : "" ) }></div>
							</div>
							<div className={ "daily_section " + ( this.state.utmTracker ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({utmTracker: true})}}>
								<div className="daily_title">UTM Tracker</div>
								<div className={ "daily_arrow " + ( this.state.utmTracker ? "daily_arrow_active" : "" ) }></div>
							</div>
							
						</div>
					</div>
				}
			</div>
			{ this.state.billingReports &&
				<div className="daily_session_hold">
					<div className={ "daily_section " + ( this.state.billingPlanReports ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({billingPlanReports: true}, 2)}}>
							<div className="daily_title">Billing Plan</div>
							<div className={ "daily_arrow " + ( this.state.billingPlanReports ? "daily_arrow_active" : "" ) }></div>
					</div>
					<div className={ "daily_section " + ( this.state.NextMonthBilling ? "daily_section_active" : "" ) } id="NextMonthBilling" onClick={e=>{this.changeDailyComp({NextMonthBilling: true})}}>
							<div className="daily_title">Billing For Next Month</div>
							<div className={ "daily_arrow " + ( this.state.NextMonthBilling ? "daily_arrow_active" : "" ) }></div>
					</div>
					
					<div className={ "daily_section " + ( this.state.trialDetails ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({trialDetails: true})}}>
							<div className="daily_title">Plans Details</div>
							<div className={ "daily_arrow " + ( this.state.trialDetails ? "daily_arrow_active" : "" ) }></div>
					</div>
					
					<div className="daily_section dailiy_none"></div>
					<div className="daily_section dailiy_none"></div>
				</div>
				
			}
			<div>
				{ this.state.ongoingSessions &&
					<div>
						{ this.state.ongoingSessions && this.state.dailyActivity &&
							<ReportsDaily
								state={this.state}
							></ReportsDaily>
						}
					</div>
				}
				
				{ this.state.pastSessions &&
				<ReportsPast
					state={this.state}
				></ReportsPast>
				}
				
				{ this.state.futureSession &&
				<ReportsFuture
					state={this.state}
				></ReportsFuture>
				}
				
				{ this.state.dailyHost &&
				<ReportsHostSignUp
					state={this.state}
				></ReportsHostSignUp>
				}
				
				{ this.state.dailyParticipant &&
				<ReportsParticipantSignUp
					state={this.state}
				></ReportsParticipantSignUp>
				}
				
				{ this.state.dailiyNewSession &&
				<ReportsNewSessions
					state={this.state}
				></ReportsNewSessions>
				}
				
				{ this.state.historyChannels &&
				<ReportsHistoryChannel
					state={this.state}
				></ReportsHistoryChannel>
				}
				
				{ this.state.historySessions &&
				<ReportsHistorySession
					state={this.state}
				></ReportsHistorySession>
				}
				
				{ this.state.historyOnDemand &&
				<ReportsHistoryOnDemandSessions
					state={this.state}
				></ReportsHistoryOnDemandSessions>
				}
				
				{ this.state.historyWaivers &&
				<ReportsHistoryWaiver
					state={this.state}
				></ReportsHistoryWaiver>
				}

				{ this.state.billingPlan &&
					<ReportsAdminBilling
					state={this.state}
					changeDailyComp = {this.changeDailyComp}
					></ReportsAdminBilling>
				}
				
				{ this.state.overCharge &&
					<ReportsOvercharge
					state={this.state}
					changeDailyComp = {this.changeDailyComp}
					></ReportsOvercharge>
				}
				
				{ this.state.channelPlan &&
					<ReportsChannelPlan
					state={this.state}
					changeDailyComp = {this.changeDailyComp}
					></ReportsChannelPlan>
				}
				{ this.state.brandAmbassador &&
					<ReportsAmbassador
					state={this.state}
					changeDailyComp = {this.changeDailyComp}
					></ReportsAmbassador>
				}
				{ this.state.historyHosts &&
					<ReportsHistoryHosts
					state={this.state}
					></ReportsHistoryHosts>
				}
				{ this.state.historyParticipants &&
					<ReportsHistoryParticipants
					state={this.state}
					></ReportsHistoryParticipants>
				}
				{ this.state.historyChannelWaitingList &&
					<ReportsChannelWaitingList
					state={this.state}
					></ReportsChannelWaitingList>
				}
				{ this.state.historyVirdioCommunity &&
					<ReportsVirdioCommunity
					state={this.state}
					></ReportsVirdioCommunity>
				}
				{this.state.NextMonthBilling &&
					<ReportsMonthBilling
						state={this.state}
					>
					</ReportsMonthBilling>
				}
				{this.state.trialDetails &&
					<ReportsPlanDetails
						state={this.state}
					>
					</ReportsPlanDetails>
				}	
				{this.state.historyInstructorCodes &&
					<ReportsInstructorCodes
						state={this.state}
					>
					</ReportsInstructorCodes>
				}
				{this.state.utmTracker &&
					<UtmTracker
						state={this.state}
					>
					</UtmTracker>
				}				
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}

export default ReportsDashboard;