import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import ReportsPages from '../reports/ReportsPages';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import moment from 'moment';

class PaymentsTransactions extends Component {
constructor(props) {
	super(props);
	this.state = 
				{
					showSearch: false,
					searchTransactionDate: new Date(),
					searchTransactionTo: new Date(),
					searchExpiredDate: null,
					searchExpiredTo: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					sortByVal: ['mmpp.updatedDate', 'DESC'],
					ajaxLoaded: false,
					checkInfoOpen: false,
					itemsDescription: 'Members',
					allWeeks: [],
					filterWeek: '',
					memberPlansArray: [],
					filterOptions: [{ title: 'Plan Name', param: 'planName', options: ["Single user", "Family"]},
										{ title: 'Plan type', param: 'planType', options: ["Annual", "Monthly"]},
										{ title: 'Has CC', param: 'hasCreditCard', options: ["Yes", "No"]}, 
										{ title: 'Status', param: 'transactionStatus', options: ["Successful", "Failed"]}]
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.getWeeks(0);
	this.fetchMemberDetails(this.state.page_id, this.state.page_num_count); 
}
 
componentWillUnmount() {
}

getPaymentsTransactions=()=>{
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/getMemberPaymentTransactions", {})          
	.then(res => {
		
		if ( res.data.responseData.plansData )
			{
				this.setState({
					memberPlansArray: res.data.responseData.plansData,
				})
			}
		else 
			{
				this.setState({
					memberPlansArray: [],
				});
			}			
		
		$('.main_loader').attr({'style':'display:none'});
	}).catch(err =>{
		$('.main_loader').attr({'style':'display:none'});
		this.setState({
			memberPlansArray: [],
		});
		console.log('----------there is problem------------', err);
	});
	
}

changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchMemberDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchMemberDetails(mod, this.state.page_num_count);
		}
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ 
							showSearch: !this.state.showSearch, 
							searchExpiredDate: null, 
							searchExpiredTo: null, 
							searchTransactionDate: null, 
							searchTransactionTo: null, 
							planName: null,
							planType: null,
							hasCreditCard: null,
							transactionStatus: null,
							searchBy: '', 
							filterExpiredDateWeek: '', 
							filterTransactionDateWeek: ''}, ()=>{
					this.fetchMemberDetails(this.state.page_id, this.state.page_num_count);
				});
			
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}


searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchMemberDetails(pg, this.state.page_num_count); 
}

fetchMemberDetails = (page_id, countId, searchText = this.state.searchBy, expiredDate= this.state.searchExpiredDate, expiredTo= this.state.searchExpiredTo, transactionDate= this.state.searchTransactionDate, transactionTo= this.state.searchTransactionTo) => {

	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});

  axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/getMemberPaymentTransactions", {
																			page_id: page_id,
																			countId: countId,
																			searchText: searchText,
																			expiredDate: moment(expiredDate).startOf('day').toDate(),
																			expiredTo: moment(expiredTo).endOf('day').toDate(),
																			transactionDate: transactionDate ? moment(transactionDate).startOf('day').toDate() : null,
																			transactionTo: transactionTo ? moment(transactionTo).endOf('day').toDate() : null,
																			sortby: this.state.sortByVal,
																			
																			
																			planName: this.state.planName,
																			planType: this.state.planType,
																			hasCreditCard: this.state.hasCreditCard,
																			transactionStatus: this.state.transactionStatus
																		})

  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({memberPlansArray: res.data.responseData['plansData'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	if( (this.state.page_id+3) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			let newState = this.state.page_id < 3 ? 0 : this.state.page_id - 2;
			
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchMemberDetails(this.state.page_id, this.state.page_num_count);
	});
}

handleFilterChange = (e, mod) => {

	let mainthis = this;
	this.setState({
		[e.id]: e.value
	}, ()=>{
		if (mod)
			{
				mainthis.choseWeek(e.value, mod)
			}
	});
}

choseWeek = (e, mod) =>{
	let mainthis = this;
	let tempStart = '';
	let tempEnd = '';
	if (e != '' )
		{
			tempStart = new Date(e);
			let temp = tempStart.getTime()+(7*24*60*59*1000);
			tempEnd = new Date(temp);
		}
	if ( mod == 'expiredDateWeek' )
		{
			this.setState({
				searchExpiredDate: tempStart, 
				searchExpiredTo: tempEnd
			}, ()=>{ if(mod) {
					mainthis.fetchMemberDetails(mainthis.state.page_id, mainthis.state.page_num_count);
				}});
		}
	else
		{
			this.setState({
				searchTransactionDate: tempStart, 
				searchTransactionTo: tempEnd
			}, ()=>{ if(mod) {
					mainthis.fetchMemberDetails(mainthis.state.page_id, mainthis.state.page_num_count);
				}});
		}
}

getWeeks = mod =>{ 
	let mainthis = this;
	var dt = new Date();
	var currentYear = dt.getFullYear();
	var currentMonth = dt.getMonth();
	let fistDayMonth = new Date((new Date().getFullYear()-mod), 0, 1);
	let topDate = new Date();
	
	let dates = []
	const addDays = function (days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};
		
	let currentDate = fistDayMonth
	if (currentDate.getDay() > 0) {
		currentDate.setDate(currentDate.getDate() - currentDate.getDay());
	}
	 
	while (currentDate <= topDate) {
	  let endWeekDate = addDays.call(currentDate, 6);
	  dates.push({
			begin: currentDate, 
			end: endWeekDate,
			title: moment(currentDate).format('MMM DD, YYYY')+'/'+moment(endWeekDate).format('MMM DD, YYYY'),
			value: currentDate
		});
	  currentDate = addDays.call(currentDate, 7);
	 }
	let reverseWeeks = dates.reverse();
	this.setState({
		allWeeks: reverseWeeks,
	}, ()=> { mainthis.fetchMemberDetails(mainthis.state.page_id, mainthis.state.page_num_count);});
}


  render() {
	
    return (
		<div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className="pay_head_title">Payment Transactions</div>
								</div>
								<div className="show_pay_page chwidth15">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
											<option value="10000000">All</option>
										</select>
									 </span>
									{this.state.itemsDescription}
								</div>
								<div className="show_search_opt chwidth85">	
								
								{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-end radius-8">
													<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center col-xl-4 fl43">
														<input type="text" className="form-control" placeholder="Search for member name or email" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
														{ this.state.filterOptions.map((item, key)=>
																		<div key={key} className="form-group-header interest_search">
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label text_wrap text_limit">{item.title}</label>
																			<select className="input-field dashboard_main_select" id={item.param} value={this.state[item.param]} onChange={(e) => this.handleFilterChange(e.target)} >
																				<option value=""></option>
																				{ item.options.map((value, key)=>
																					<option value={value} key={key}>{value}</option> 
																				)}
																			</select>
																		</div>
																	)}
													</div>
													<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center nopadlr col-xl-6 fl57">
														<div className="parent-row mb-3 full_width">				
															<div className="row mx-0 row1 d-flex">
																<div className="col-md-12 d-flex flex-wrap align-items-center">	
																	<div className="filter_item">
																		<span className="searh_span">Transaction</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchTransactionDate} onChange={date => this.setState({searchTransactionDate: date, filterTransactionDateWeek: ''})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchTransactionTo} onChange={date => this.setState({searchTransactionTo: date, filterTransactionDateWeek: ''})} />
																	</div>
																	<div className="form-group-header interest_search mrglft15">
																		<span className="cover-border"></span>
																		<label className="label dashboard_search_label">Week</label>
																		<select className="input-field dashboard_main_select" id="filterTransactionDateWeek" value={this.state.filterTransactionDateWeek} onChange={(e) => this.handleFilterChange(e.target, 'transactionDateWeek')} >
																			<option value=""></option>
																				{ this.state.allWeeks.map((item, key)=> 
																					<option value={item.value} key={key}>{item.title}</option>
																				)}
																		</select>
																	</div>
																</div>
																<div className="col-md-12 d-flex flex-wrap align-items-center">	
																	<div className="filter_item">
																		<span className="searh_span">Expired</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchExpiredDate} onChange={date => this.setState({searchExpiredDate: date, filterExpiredDateWeek: ''})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchExpiredTo} onChange={date => this.setState({searchExpiredTo: date, filterExpiredDateWeek: ''})} />
																	</div>
																	<div className="form-group-header interest_search mrglft15">
																		<span className="cover-border"></span>
																		<label className="label dashboard_search_label">Week</label>
																		<select className="input-field dashboard_main_select" id="filterExpiredDateWeek" value={this.state.filterExpiredDateWeek} onChange={(e) => this.handleFilterChange(e.target, 'expiredDateWeek')} >
																			<option value=""></option>
																				{ this.state.allWeeks.map((item, key)=> 
																					<option value={item.value} key={key}>{item.title}</option>
																				)}
																		</select>
																	</div>
																</div>
															</div>
														</div>
												</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
								
								
								</div>
									{ this.state.memberPlansArray && this.state.memberPlansArray.length > 0 && 
										<table className="billing_table">
											<thead>
												<tr>
													<th>Member Name</th>
													<th>Member Email</th>
													<th className="paddR20">Expired Date
														<div onClick={ (e) => this.sortArrays('member_monthly_plan.trialEndDate')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th className="paddR20">Date of transaction
														<div onClick={ (e) => this.sortArrays('mmpp.updatedDate')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th className="paddR20">Plan Name
														<div onClick={ (e) => this.sortArrays('mmpp.planName')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th className="paddR20">Plan type
														<div onClick={ (e) => this.sortArrays('mmpp.planType')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Plan Price</th>
													<th>Coupon Name</th>
													<th className="paddR20">Discount
														<div onClick={ (e) => this.sortArrays('mmpp.discountValue')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Total for charge</th>
													<th className="paddR20">Has CC
														<div onClick={ (e) => this.sortArrays('hasCC')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th className="paddR20">Status
														<div onClick={ (e) => this.sortArrays('mmpp.status')} className="reports_sort_icon vertMidd"></div>
													</th>											
												</tr>
											</thead>
											<tbody> 
												{ this.state.memberPlansArray.map((item, key) =>
													<tr key={key}>
														<td>{item['firstName']} {item['lastName']}</td>
														<td>{item['email']}</td>
														<td>{this.getSessionDate(item['trialEndDate'])}</td>
														<td>{this.getSessionDate(item['updatedDate'])}</td>
														<td>{item['planName']}</td>
														{item['planType'] == 1 ?
															<td>Monthly</td>
															:
															<td>Annual</td>
														}															
														<td className="td_price" >
															<div className="planPrice_span text_align_price_span" > ${(item['planPrice']).toFixed(2)} </div>																										
														</td>
														<td>{item['subCode']}</td>
														<td className="td_price" >
															<div className="planPrice_span text_align_price_span" > {item['discountValue']} %</div>																								
														</td>
														<td className="td_price" >
															<div className="planPrice_span text_align_price_span" > ${(item['calcPrice']).toFixed(2)} </div>																										
														</td>
														<td>{(item['hasCC'] == 1 ? 'Yes' : 'No')}</td>
														{item['status'] == 2 ?
															<td className="successfulTxtMsg" >Successful</td>
															:
															<td className="failedTxtMsg" >Failed</td>
														}
													</tr>
												)}
											</tbody>
										</table>
									}
									{ this.state.memberPlansArray.length > 0 && 
										<ReportsPages 
											page_id={this.state.page_id}
											page_num_count={this.state.page_num_count}
											fullCount={this.state.fullCount}
											maxPages={this.state.maxPages}
											changePage={this.changePage}
											page_numbers={this.state.page_numbers}
											checkInfoOpen={this.state.checkInfoOpen}
											itemsDescription={this.state.itemsDescription}
										/>
									}
									{ this.state.memberPlansArray.length == 0 && 
										<div className="noPaymentsFound" >No corresponding transactions found</div>
									}
								<div>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>	
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default PaymentsTransactions;