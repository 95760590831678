const userPermissions = function(role)
{
	var permissions = { 
						createClass: false, 
						editClass: false, 
						addInstructors: false, 
						addQA: false, 
						addOnDemad: false, 
						addReplayClasses: false, 
						reviewClassContent: false, 
						addNotes: false,
						copyLiveClassToAnotherChannel: false,
						canChangeInstructor: false,
						canChangeDuration: false,
						canChangeTime: false,
					};
						
	if ( role == 'Owner' || role == 'Admin' )
		{
			permissions.createClass = true;
			permissions.editClass = true;
			permissions.addInstructors = true;
			permissions.addQA = true;
			permissions.addOnDemad = true;
			permissions.addReplayClasses = true;
			permissions.reviewClassContent = true;
			permissions.addNotes = true;
			permissions.copyLiveClassToAnotherChannel = true;
			permissions.canChangeInstructor = true;
			permissions.canChangeDuration = true;
			permissions.canChangeTime = true;
		}
		
	if ( role == 'Instructor' )
		{
			permissions.editClass = true;
			permissions.addNotes = true;
		}
		
	if ( role == 'Operations Team' )
		{
			permissions.createClass = true;
			permissions.editClass = true;
			permissions.addInstructors = true;
			permissions.addQA = true;
			permissions.addOnDemad = true;
			permissions.addReplayClasses = true;
			permissions.reviewClassContent = true;
			permissions.addNotes = true;
			permissions.canChangeInstructor = true;
			permissions.canChangeDuration = true;
			permissions.canChangeTime = true;
		}
		
	if ( role == 'Fitness Programming' )
		{
			permissions.createClass = true;
			permissions.editClass = true;
			permissions.addOnDemad = true;
			permissions.reviewClassContent = true;
			permissions.canChangeInstructor = true;
			permissions.canChangeDuration = true;
			permissions.canChangeTime = true;
			permissions.addNotes = true;
		}
		
	if ( role == 'QA' )
		{
			permissions.copyLiveClassToAnotherChannel = true;
			permissions.addNotes = true;
		}
		
	return permissions;
}

export { userPermissions };