import React, { Component } from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import axios from "axios";
import SimpleReactValidator from 'simple-react-validator';
import {  browserHistory} from 'react-router'
import SupportContainer from '../home/SupportContainer';
import MarketplaceHeader from '../home/MarketplaceHeader';

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "../../actions/types";

import $ from 'jquery';

class Forgotpassword extends Component {
            constructor() {
            super();
           // let email;
            this.state = {
                email:"",
                msg:"",
                showError:false,
                messageFromServer:'',
                type:"1",
                errors: {},
				supportPageName: 'Forgot Password',
				supportPageApi: '/forgotpassword',
				userTypeFaqs: 2,
				emailInput: '',
              };
              this.validator = new SimpleReactValidator();

            }

componentDidMount() 
{
}

componentWillMount()
{
}

submitNow =()=>{
		if ( this.state.emailInput == '' )
			{
				return false;
			}
		let checkingEmail = this.emailValidation();
		if ( checkingEmail == false )
			{
				this.setState({
					errorEmail: true,
				});
				
				return false
			}
		const userData = { email: this.state.emailInput };	
		
		$('.main_loader').attr({'style':'display:flex'});
		
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/forgotpassword",userData).then(res => {
			
			if (res.data.responseMessage == "Email doesn't exists in system")
				{
					this.setState({
                            msg: res.data.responseData.message
                          }, () => { window.$('#forgot_password_success_sent_email').modal('show'); });
				}
			else if (res.data.responseMessage === 'success')
				{
					 this.setState({
                          msg: res.data.responseData.message
                        }, () => { window.$('#forgot_password_success_sent_email').modal('show'); });
				}				
			$('.main_loader').hide();	
				
		}).catch(err =>{
			$('.main_loader').hide();
			console.log('there is problem');
			if(err.response.data.responseCode===417)
				{
					this.setState({
						msg:err.response.data.errorData
					}, () => { window.$('#forgot_password_success_sent_email').modal('show'); });
				}
		});
	}
	
emailValidation(){
		const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if(!this.state.emailInput || regex.test(this.state.emailInput) === false)
			{
				return false;
			}
			return true;
	}

        render() {
            const {password,errors,isLoading,updated}=this.state;

        return (
			<div className="memberRegisterContainer login_container">
				<MarketplaceHeader></MarketplaceHeader>
				<div className="memberRegisterHolder web_login_holder">
					<div className="exit_content">
						<div>
							<Link to="/login">
								<div className="inline_event">
									<div className="arrow_exit"></div>
									<div className="exit_screen">BACK</div>
								</div>
							</Link>
						</div>
					</div>
					<div className="sign_title">Forgot Password</div>
					<div className="info_row_holder">
						<div className="one_form_container">
							<div className="form_title">Email</div>
							<div className="relative_holder">
								<input type="text" tabIndex="1" className={classnames({'gray_inputs': true, 'error_input': this.state.errorEmail})} 
								id="emailInput" 
								value={this.state.emailInput}
								onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorEmail: false})}
								onFocus={(e) => this.setState({focusedElement: 'emailInput'})}							
								onBlur={(e) => this.setState({focusedElement: ''})}	
								value={this.state.emailInput}/>
								{ this.state.emailInput !== '' &&
									<div className="empty_input" onClick={()=>this.setState({emailInput: ""})}></div>
								}
								{this.state.errorEmail &&
									<div className="email_error_msg" >The email must be a valid email address.</div>
								}
							</div>
						</div>
					</div>
					<div className="bottom_arrow">
						<div className={classnames({'inline_event': true, 'opacity_event': this.state.emailInput == ''})} onClick={(e)=>this.submitNow()} >
							<div className="bottom_title_small">SUBMIT</div>
							<div className="arrow_next"></div>
						</div>
					</div>
				</div>
				<div id="forgot_password_success_sent_email" className="modal zindex999999" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
								<pre id="login-alert-text" className={"py-4 text-light text-center font-20 popup_error_text"+(this.props.popupAlign ? ' popup_align_left' : '')}>{this.state.msg}</pre>
							</div>
							<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={e=>{window.$('#forgot_password_success_sent_email').modal('hide');}}>OK</button></div>
						</div>
					</div>
				</div>
				<div className="main_loader">
					<div className="main_loader_inner"></div>
				</div>
				<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
			</div>
         );

         }

}

const container = {
    "padding": "0px 15px",
    "max-width": "1140px"
  
  };

// export default connect()(Forgotpassword);@ak
export default Forgotpassword;



