import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import { connect } from "react-redux";
const joinSessionValidate = function(isHost, userId, userToken, demandId)
{
	var deepLinkButton = 'Web App';
	var urlPathVar = '';
	var urlElectronVar = '';
	
	if ( !demandId )
		{
			//live sessions
			
			if ( android && deviceType === 'mobile' )
				{
					urlElectronVar = 'intent://virdio/?userId='+userId+'&userToken='+userToken+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;';
					deepLinkButton = 'Allow';
				}
			else if ( ios && deviceType === 'mobile' )
				{
					urlElectronVar = process.env.REACT_APP_URL+"auth/?userId="+userId+"&token="+userToken;
					deepLinkButton = 'Allow';
				}
			else if ( tablete || deviceType === 'tablet' )
				{
					if ( android )
						{
							urlPathVar = process.env.REACT_APP_URL+'auto-login'+'/'+userId+'/'+userToken;
							urlElectronVar = 'intent://virdio/?userId='+userId+'&userToken='+userToken+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;';
						}
						
					if ( ios )
						{
							urlPathVar = process.env.REACT_APP_URL+'auto-login'+'/'+userId+'/'+userToken;
							urlElectronVar = process.env.REACT_APP_URL+"auth/?userId="+userId+"&token="+userToken;
						}
						
					deepLinkButton = 'Web App';
				}
			else if ( osname === "Windows" || osname === "Mac OS" )
				{
					urlPathVar = process.env.REACT_APP_URL+'auto-login/'+userId+'/'+userToken;
					urlElectronVar = 'virdioapp://login?userId='+userId+'&userToken='+userToken+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;';
				}
			else
				{
					urlPathVar = process.env.REACT_APP_URL+'auto-login/'+userId+'/'+userToken;
				}
				
			if ( isHost && !chrome && ( osname === "Windows" || osname === "Mac OS" ) ) { urlPathVar = ''; } 
		}
	else
		{
			//on demand sessions
			urlPathVar = process.env.REACT_APP_URL+'auto-login'+'/'+userId+'/'+userToken+'/'+demandId;
			
			if ( android && deviceType === 'mobile' )
				{
					urlElectronVar = 'intent://virdio/?userId='+userId+'&userToken='+userToken+'&onDemandId='+demandId+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;';
					deepLinkButton = 'Allow';
				}
			else if ( ios && deviceType === 'mobile' )
				{
					urlElectronVar = process.env.REACT_APP_URL+"auth/?userId="+userId+"&token="+userToken+'&ondemandid='+demandId;
					deepLinkButton = 'Allow';
				}
			else if ( tablete || deviceType === 'tablet' )
				{
					if ( android )
						{
							urlElectronVar = 'intent://virdio/?userId='+userId+'&userToken='+userToken+'&onDemandId='+demandId+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;';
						}
						
					if ( ios )
						{
							urlElectronVar = process.env.REACT_APP_URL+"auth/?userId="+userId+"&token="+userToken+'&ondemandid='+demandId;
						}
				}
			else if ( osname == "Windows" || osname == "Mac OS" )
				{
					urlElectronVar = 'virdioapp://login?userId='+userId+'&userToken='+userToken+'&ondemandId='+demandId;
				}
		}
	
	var returnObject = {deepLinkButton: deepLinkButton, urlPathVar: urlPathVar, urlElectronVar: urlElectronVar};
	
	return returnObject;
}

export { joinSessionValidate };