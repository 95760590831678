import React, { Component } from "react";
import { browserHistory } from 'react-router';

class ReportsPages extends Component {
constructor(props) {
	super(props);
	this.state = {
		
	}
}

render() {
	
    return (
			<div>
				<div className="showPageNavigation">
					<div className="showpinfo">Showing { ((this.props.page_id)*this.props.page_num_count) } to { this.props.page_num_count == '10000000' ? 'All' : ((this.props.page_id+1)*this.props.page_num_count) } of { this.props.fullCount } { this.props.itemsDescription }</div>
					<div className="shopnumbers">
						
						{ this.props.maxPages > 5 &&
							<span onClick={(e) => this.props.changePage(e, 0)}>First Page</span>
						}
						
						<span>
							{ this.props.page_numbers.map((nitem, nindex) => 
								<span key={nindex} onClick={(e) => this.props.changePage(e, nitem-1)} className={"pgNumbers " + (this.props.page_id+1 === nitem ? 'active' : '')}>{nitem}</span>	
							)}
						</span>
						
						 { this.props.maxPages > 5 &&
							<span onClick={(e) => this.props.changePage(e, this.props.maxPages-1)}>Last Page</span>
						 }
						</div>
				</div>
				<div className={ this.props.checkInfoOpen ? 'mob_pad_b250' : ''}></div>
			</div>
    );
  }
}

export default ReportsPages;