import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import moment1 from 'moment-timezone';
import Calendar from 'react-calendar';
import SimpleReactValidator from 'simple-react-validator';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import {  browserHistory } from 'react-router'
import Countdown from 'react-countdown-now';
import Login from '../auth/Login';
import MainSignUp from '../auth/MainSignUp';
import OnDemandSessions from '../home/OnDemandSessions';
import { submitHost } from '../../util/submitHost';
import { verify } from '../../util/verify';
import { channelCheckUser } from '../../util/channelCheckUser';
import { connect } from "react-redux";
import { updatesse } from '../../util/updatesse';
import classnames from "classnames";
import ChargingSession from '../home/ChargingSession';
import DatePicker from "react-datepicker";
import customProtocolCheck from "custom-protocol-check";
import { showMainErrorPop } from '../../actions/popups';
import { deleteCookie } from '../auth/Logout';
import { joinSessionValidate } from '../../util/joinSessionValidate';
import { setCookie } from '../../util/cookie';
import SupportContainer from '../home/SupportContainer';
import ParticipantSignedUp from '../home/participantSignedUp';

import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class Channel extends Component {
constructor(props) {
	super(props);
	this.state = {
		sessionType: 'Upcoming',
		isLoginOpen: false,
		email: '',
		password: '',
		mainChannel: {},
		mainChannelSessions: {},
		weeksArray: [],
		firstName: '',
		lastName: '',
		searchNewFiedls: [],
		email: '',
		openedChannelName: '',
		phone: '',
		password: '',
		rePassword: '',
		verify: false,
		signup: false,
		validateEmail: true,
		sms: false,
		byEmail: true,
		message: '',
		passwordText: '',
		otp: '',
		msg: '',
		userType: '',
		otperror: '',
		errorClass_FirstName: false,
		errorClass_LastName: false,
		errorClass_Phone: false,
		errorClass_Email: false,
		errorClass_Password: false,
		errorClass_RePassword: false,
		redirectToRoute: '',
		userName: '',
		participantData: '',
		participantValidate: true,
		validateEmail: false,
		inValidAccess: true,
		isOpenSign: false,
		isOpenLoginAll: false,
		sessionId: 0,
		redirect: 'Sign Up',
		hostEmail: '',
		duration: 0,
		scheduleDate: 0,
		type:"1",
	    errors: {},
	    errormsg:'',
	    rememberMe:false,
	    platformStatus:true,
	    pageMessage:'',
	    redirectPath:'/login',
		loginUserName: '',
		upcomingSessionCount: 0,
		hostBy: {},
		isFoundChannel: true,
		emailSign: '',
		chosenSession: {},
		isChargingSessionIncluded: false,
		sessionProperty: 'public',
		allAboutSession: '',
		totalSessions: 0,
		latestSessionId: 0,
		pickedSessions: [],
		searchFields: [],
		mainDemandObject: {},
		demandPreview: 'list',
		upcomingPreview: 'list',
		isDashboard: false,
		demandToDelete: 0,
		gridDemandView: false,
		hasGridView: false,
		readOnceDemand: false,
		onDemandSessionCount: 0,
		demandSessionId: 0,
	    urlPath:'',
	    urlElectron:'',
		ondemandlink: '',
		electronlink: '',
		appDownload:'',
		showWindowsApp: true,
		backroundColorPick: '#000000',
		fontColorPick: '#ffffff',
		fontTitleColorPick: '#ffffff',
		buttonsColorPick: '#bd00ff',
		highlightColorPick: '#bd00ff',
		searchBackgroundColor: '#492158',
		searchBoxShadow: '2px 4px 18px 11px #3d1a4a inset',
		searchBackground: '',
		searchFont: '',
		searchInputBackground: '',
		searchInputBorder: '',
		newChannelIntrests_array: '',
		userImageProfile : '',
		userFullName: '',
		userInstructorProfileText : '',
		mainUserData: {},
		showAgreePop: 0,
		mainUrlView: '',
		channelWaiverUrl: '',
		userMainId: 0,
		hostChannelId: 0,
		hostSessionId: 0,
		deepLinkButton: 'Web App',
		sessionCalender: new Date(),
		filterEquipment: '',
		logedUser: false,
		logedUserName : '',
		expandSearchStatus: false,
		caseForSort: '',
		choosenSort: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		fullStartDate: '',
		fullEndDate: '',
		filterLevel: '',
		sortedByHost: false,
		sortedByDuration: false,
		sortedBySession: false,
		sortedByLevel: false,
		sortedByinterest: false,
		sortedDateRecent: false,
		sortedDateOlder: false,
		searchedBy: '',
		hostsArray: [],
		filterHost: '',
		startPage: 0,
		hasMoreLoad: false,
		loadingMore: false,
		firstTabOnDemand: false,
		detectChannelPage: true,
		supportPageName: 'Channel',
		supportPageApi: '/channel',
		userTypeFaqs: 2,
		userSignedUp: false,
		loggedUserData: {},
		sessionStartTimeHigher15Min: false,
		sessionScheduleDate: '',
		sessionTimezone: '',
		sessionChannelName: '',
		isHost: false,
		sessionName: '',
		isOnDemandSession: false,
		openedDemand: {},
		isLoadingSessions: false
	}
	
	this.validator = new SimpleReactValidator({autoForceUpdate: this});
	this.otpValidation = new SimpleReactValidator();
	this.emailValidate  = new SimpleReactValidator();
	this.submitHost = submitHost.bind(this);
	this.verify = verify.bind(this);
	this.channelCheckUser = channelCheckUser.bind(this);
	this.updatesse = updatesse.bind(this);
	this.deleteCookie = deleteCookie.bind(this);
	this.joinSessionValidate = joinSessionValidate.bind(this);
	this.setCookie = setCookie.bind(this);  
	
	this.getSessionDateUtil = getSessionDate.bind(this);
	this.getSessionTimeUtil = getSessionTime.bind(this);
}

onUpcoming=(e)=>{
	this.setState({
		sessionType:'Upcoming',
		isLoginOpen: false,
		filterEquipment: '',
		expandSearchStatus: false,
		caseForSort: '',
		choosenSort: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		fullStartDate: '',
		fullEndDate: '',
		filterLevel: '',
		searchFields: [],
		searchedBy: '',
		startPage: 0
	}, ()=>{/* this.fetchSessionDetails(this.state.openedChannelName, this.state.searchFields); */});
}

onDemand=(e)=>{
	this.setState({
		sessionType:'On Demand',
		isLoginOpen: false,
		filterEquipment: '',
		expandSearchStatus: false,
		caseForSort: '',
		choosenSort: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		fullStartDate: '',
		fullEndDate: '',
		filterLevel: '',
		searchFields: [],
		searchedBy: '',
		startPage: 0
	}, ()=>{/* this.fetchSessionDetails(this.state.openedChannelName, this.state.searchFields); */});
}

openLogin=(id, red, dur, dat, hostBy, sess)=>{
	this.setState({
		isLoginOpen: true,
		sessionId: id,
		redirect: red,
		duration: dur,
		scheduleDate: dat,
		hostBy: hostBy,
		chosenSession: sess,
		sessionProperty: sess.sessionProperty===0?"public":"private",
		allAboutSession: sess
	});
	
	var id_s = [];
	id_s.push(id);
	localStorage.setItem('sessionIdData', JSON.stringify(id_s));
	localStorage.setItem('scheduleDate', dat);
	localStorage.setItem('duration', dur);
}

openSendingEmail=(email, red)=>{
	this.setState({
		isLoginOpen: true,
		redirect: red,
		hostEmail: email
	});
}

closeLogin=(e)=>{
	this.setState({
		isLoginOpen: false,
		isOpenLoginAll: false,
	});
	
	$("#session_alert_new").attr({'style':'display:none'});
}

closeLoginNative=(e)=>{
	
	this.setState({
		isLoginOpen: false,
		isOpenLoginAll: false,
	});
	
	$("#session_alert_new").attr({'style':'display:none'});
	window.$('.channelPg #autologinnative').modal('show'); 
}

updateRefState = (ref, mod) => {
	if ( mod == 'firstName' )
		{
			this._firstName = ref;
		}
	else if ( mod == 'lastName' )
		{
			this._lastName = ref;
		}
	else if ( mod == 'email' )
		{
			this._email = ref;
		}
	else if ( mod == 'password' )
		{
			this._password = ref;
		}
	else if ( mod == 'repassword' )
		{
			this._rePassword = ref;
		}
}



onChange=(e)=> {
    this.setState({ [e.target.id]: e.target.value });
};

clearDate=(e)=> {
    e.preventDefault();
	e.stopPropagation();
	
	console.log(this.state.searchFields);
	let srch_fild = this.state.searchFields;
	srch_fild.searchMainTime = [];
	this.setState({ searchShowTime: undefined, searchFields: srch_fild });
};

preventDef=(e)=> {
	e.preventDefault();
	e.stopPropagation();

};

openSignUp=(e)=> {
	this.setState({
		isOpenSign: true,
	});
};

closeSign=(e)=> {
	this.setState({
		isOpenSign: false,
		verify: false,
	});
};

openMoreInfo=(e)=> {
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
};

resend=(e)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : this.state.emailSign})
    .then(res => {
			if( res.data.responseMessage == "success" )
				{
					$("#resend_verification").attr({'style':'display:block'});
				}
			else
				{
					this.setState({
						msg:''
					}) 
				}
    }).catch(err =>{
		this.setState({
			msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
		}) 
    });
}

startMainSearch = (e) =>
{
	e.stopPropagation();
	e.preventDefault();
	let filterInterestName = '';
	let searchDuration = '';
	if((this.state.pickedStartDate !== '' && this.state.pickedEndDate == '') || (this.state.pickedStartDate == '' && this.state.pickedEndDate != ''))
		{
			this.props.showMainErrorPop('If you want to search by Date, both Start Date and End Date cannot be empty');
			return false;
		}
	
	let isAllEmpty = true;
	$.each(this.state.searchFields, function( a, b ) {
		if ( b && b !== '' ) { isAllEmpty = false; }
	});
	
	if(this.state.interestItems.length > 0)
		{
			for(var i = 0; i<this.state.interestItems.length; i++)
				{
					if(this.state.interestItems[i].id == this.state.searchFields['searchInterest'])
						{
							filterInterestName = this.state.interestItems[i].title;
						}
				}
		}
		
	
	if(this.state.searchFields['searchDuraction'] && this.state.searchFields['searchDuraction'] != '')
		{
			searchDuration = this.state.searchFields['searchDuraction'];
			console.log(this.state.searchFields['searchDuraction'], 'IMMMMMMMMPORTNATATA');
		}
		
	let hostName = '';	
	if(this.state.hostsArray.length > 0)
		{
			for(var a = 0; a<this.state.hostsArray.length; a++)
				{
					if(this.state.hostsArray[a].userId == this.state.filterHost)
						{
							hostName = this.state.hostsArray[a].username;
						}
				}
		}
		
if( Object.keys(this.state.searchFields).length > 0 && !isAllEmpty || this.state.filterEquipment != '' || this.state.filterLevel != '' || this.state.fullStartDate != '' || this.state.fullEndDate != '' || this.state.filterHost != '')
		{
			$('.main_loader').attr({'style':'display:flex'});
			this.setState({ searchNewFiedls: this.state.searchFields, startPage: 0 }, ()=>{ this.fetchSessionDetails(this.state.openedChannelName, this.state.searchFields); });
		}
	else
		{
			console.log(22);
			$('.main_loader').attr({'style':'display:flex'});
			this.setState({ fullSearch: false, searchFields: [], startPage: 0 }, ()=>{ this.fetchSessionDetails(this.state.openedChannelName, this.state.searchFields);  });
		}
	
	let sessionNameInfo = this.state.searchFields['searchSessionName'] ? this.state.searchFields['searchSessionName'].trim() + ', ' : '';
	let interestInfo =  filterInterestName != '' ? filterInterestName + ', ' : '';
	let hostInfo =  hostName ? hostName + ', ' : '';
	let levelInfo =  this.state.filterLevel != '' ? this.state.filterLevel + ', ' : '';
	let durationInfo = searchDuration != '' ? searchDuration + ', ' : ''; 
	let equipmentInfo =  this.state.filterEquipment != '' ? this.state.filterEquipment.trim() + ', ' : '';
	let startDateInfo =  this.state.fullStartDate != '' ? this.state.fullStartDate + ', ' : '';
	let endDateInfo =  this.state.fullEndDate != '' ? this.state.fullEndDate + ', ' : '';
	let searchInfo = sessionNameInfo + interestInfo + hostInfo + levelInfo + durationInfo + equipmentInfo + startDateInfo + endDateInfo;
	searchInfo = searchInfo.slice(0, -2)
	this.setState({searchedBy: searchInfo});
		
	this.setState({expandSearchStatus: false});
}

setTimeCalendar = date => {
	let getFullYear=new Date(date).getFullYear();
	let getMonth=(new Date(date).getMonth())+1;
	let getDate=new Date(date).getDate();
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const timePeriod=monthArray[getMonth-1]+' '+getDate+', '+getFullYear;
	const newDate = new Date(new Date(date).toLocaleDateString()).toISOString();
	this.setState({ ...this.state, searchShowTime: timePeriod, searchFields: { ...this.state.searchFields,  searchMainTime: newDate }});

	$("#calenderModel").attr({'style':'display:none'});
}

closePopNotVerified = e =>{
	this.setState({ isOpenLoginAll: true
	},()=>$("#user_not_verified2").attr({'style':'display:none'}))
}

validateEmailMethod=(e)=>{
  /*thist part run only for onDemand session if user not logged in*/
  /*not onDemand sessions always lead to session link*/
  if ( this.emailValidate.allValid() ) 
	  {
		let dataRes;
		let userType;
		$('.main_loader').attr({'style':'display:flex'});
	
		let postdata = {"email":this.state.email,"sessionId": [this.state.sessionId]};
	
		if ( this.state.sessionType === "On Demand" ) 
			{ 
				postdata = { "email":this.state.email } 
				
				postdata['channelId'] = this.state.mainChannel.id;
				postdata['sessionIsPrivate'] = true;
				postdata['onDemandId'] = this.state.demandSessionId;
					
				postdata['loginFromSessionLink'] = true; 
				postdata['dontSendOtp'] = true;
				postdata['registerPar'] = true;
			}
	
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserExist", postdata )
		.then(res => {
			  dataRes=res.data.responseData;
			if ( dataRes == "Proceed creating account" )
				{
					this.setState({
						  validateEmail:false,
						  signup:true,
						  isOpenLoginAll: false,
						  isOpenSign: true,
						  emailSign: this.state.email
					})
					$('.main_loader').hide();
					return false;
				}
			
			if ( dataRes !== 'undefined' || dataRes == 'User exist, move to login' ) 
				  {
					if ( dataRes.sessionPrivateMemberOnly )
						{
							this.closeLogin();
							this.props.showMainErrorPop('This On-demand video can only be viewed by members of '+this.state.mainChannel.name+'. Please contact '+this.state.mainChannel.name+' or your trainer to get access.');
							$('.main_loader').hide();
							return false;
						}
					  
					let user_main_id = res.data.responseData.id;
					this.setState({
						mainUserData: res.data.responseData,
						userMainId: user_main_id,
					});
					
					
					if ( this.state.sessionType === "On Demand" )
						{
							if ( Object.keys(dataRes).length === 0 )
								{
									this.setState({
									  validateEmail:false,
									  signup:true,
									  isOpenSign: true,
									  emailSign: this.state.email
									})
								}
							else
								{
									this.setState({ isOpenLoginAll: true });
								}
							
							$('.main_loader').hide();
						}
					else
						{
							this.checkIfUserHasWaiver(res.data.responseData.id, false, 'login');
						}
						
				  }
			
		}).catch(err =>{
			
			$('.main_loader').hide();
			
			if(!dataRes)
				{
					this.setState({
					  validateEmail:false,
					  signup:true,
					  isOpenLoginAll: false,
					  isOpenSign: true,
					  emailSign: this.state.email
					})
				}
			else 
				{   
					this.setState({
					  otperror:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
					},()=>$("#error_otp_msg").attr({'style':'display:block'}))
				}
		});
		
	  }
  else
	  {
		  this.emailValidate.showMessages();
		  $("#error_email_msg").attr({'style':'display:block'})
	   
			this.forceUpdate();
	  }
  
}

checkIfUserHasWaiver=(e, par, mod)=> {
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/channelSignedWaivers",{'channel_id' : this.state.hostChannelId, 'user_id' : e, 'session_id' : this.state.sessionId, 'pdf_link' : this.state.channelWaiverUrl, 'openedFrom' : par, 'waiver_end_date' : moment(this.state.waiverEndDate).format('MM-DD-YYYY')})
	.then(res => {
			let ak='';
			if(mod == 'login')
				{
					if(!par)
						{
							if ( res && res.data && res.data.responseData && res.data.responseData.channelId )
								{
									this.channelCheckUser(this.state.mainUserData);
									window.$("#waiverAgree").modal('hide');
								}
							else
								{
									if(this.state.showAgreePop == 1 && new Date().getTime() < new Date(this.state.waiverEndDate).getTime())
										{
											if(this.state.mainUserData.privateMember === 'no' || this.state.mainUserData.memberExpire)
												{
													console.log('1111111111111');
													window.$("#waiverAgree").modal('show');
													let waiver_link = 'https://docs.google.com/gview?url='+encodeURIComponent(this.state.channelWaiverUrl)+'&embedded=true&t='+(new Date().getTime());
													$('.waiver_iframe').attr('src', '');
													setTimeout(function(){ $('.waiver_iframe').attr('src', waiver_link); }, 2000);
												}
												
											else
												{
													this.channelCheckUser(this.state.mainUserData);
												}
										}
										
									else
										{
											this.channelCheckUser(this.state.mainUserData);
										}
								}
						}
						
					else
						{
							 this.channelCheckUser(this.state.mainUserData);
							 window.$("#waiverAgree").modal('hide');
						}
				}
			$('.main_loader').hide();
		}).catch(err => {
			console.log('----------there is problem------------',err);
		});
}

calculatePrice=(mod, arr, numb)=>{
	let totalPrice = 0;
	let pricePerSession = '';
	let priceArray = [];
	let diferentValue = 0;
	for (var i = 0; i < arr.length; i++) {
		if ( arr[i].sessionProperty == '0' )
			{
				if ( mod == 1 )
					{
						totalPrice = totalPrice+arr[i].chargeForSession;
						if ( priceArray.length > 0 && priceArray.indexOf(arr[i].chargeForSession) == -1 )
							{
								diferentValue = 1;
							}
						
						priceArray.push(arr[i].chargeForSession);
					}
				else
					{
						totalPrice = totalPrice+arr[i].chargeForSessionNonMember;
						if ( priceArray.length > 0 && priceArray.indexOf(arr[i].chargeForSessionNonMember) == -1 )
							{
								diferentValue = 1;
							}
						
						priceArray.push(arr[i].chargeForSessionNonMember);
					}
			}
		else
			{
				totalPrice = totalPrice+arr[i].chargeForSession;
				if ( priceArray.length > 0 && priceArray.indexOf(arr[i].chargeForSession) == -1 )
					{
						diferentValue = 1;
					}
				
				priceArray.push(arr[i].chargeForSession);
			}
	}
	
	if ( !numb )
		{
			return totalPrice;
		}
	else
		{
			if ( diferentValue == 0 )
				{
					pricePerSession = priceArray[0];
					return pricePerSession;
				}
			else
				{
					console.log('priceArray', priceArray);
					let max = Math.max.apply(Math, priceArray) 
					let min = Math.min.apply(Math, priceArray) 
					pricePerSession = min+'-'+max;
					return pricePerSession;
				}
		}
}

closeEmail=(e)=> {
	this.setState({
		isLoginOpen: false,
		isOpenLoginAll: false,
	});
	
	$("#email_sent").attr({'style':'display:none'});
}

openChannelInfo = (channel_subdomain) => {
	var adminpath = process.env.REACT_APP_PATH;
	adminpath = adminpath.replace('http://', '.').replace('https://', '.');
	const final_subdomain = channel_subdomain + adminpath;
	window.open('https://'+final_subdomain);
}

updateCookieAndStorage=(id, token, expire)=>{
	window['lastUserId'] = 0;
   //set cookie after register
	this.setCookie(process.env.REACT_APP_COOKIE_ID, id, expire);
	this.setCookie(process.env.REACT_APP_COOKIE_TOKEN, token, expire);
	//set cookie after register
	window['user_loggedIn'] = 1;
}

onSubmit = e => {
	e.preventDefault();
	
	if ( this.emailValidate.allValid() && this.state.password != '' ) 
		{
			$('.main_loader').attr({'style':'display:flex'});
			 
			 const userData = {
								  email: this.state.email,
								  password: this.state.password,
								  // name: this.state.name,
								  //type: 2,
								  sessionId: [this.state.sessionId],
								  duration: this.state.duration,
								  scheduleDate: this.state.scheduleDate,
							};
				
				console.log('login channel');
			 axios.post(process.env.REACT_APP_NAME+"/api/v1/user/adminLogin", userData)
				.then(res => {
				  
					// Save to localStorage
					const token  = res.data.responseData.token;
					const userId  = res.data.responseData.id;

					if ($('#remember_me').is(':checked')) 
						{
							localStorage.setItem('email', $('#email').val());
							localStorage.setItem('password_remember', $('#password').val());
							localStorage.setItem('chkbx', $('#remember_me').val());
						} 
					else 
						{
							localStorage.removeItem('email');
							localStorage.removeItem('chkbx');
						}

				  if(res.data.responseData.totalSession < 2) 
					  {
						  localStorage.setItem("userData", JSON.stringify(res.data.responseData));
					  } 
				  else 
					  {
						  localStorage.setItem("sessionList", JSON.stringify(res.data.responseData));
					  }
					  
					
					if(res && res.data && res.data.responseData && res.data.responseData.settings)
						{
							var globalSettings = res.data.responseData.settings;
					
							this.setState({appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink )});
						}
					
					if( this.state.redirect !='Sign Up' )
						{ 
							var isHost = false;
							if ( this.state.chosenSession.hostId === userId ) { isHost = true; }
							 
							var checkSessionValidate = this.joinSessionValidate(isHost, userId, token, null);
							this.setState({ 
									message: '',
									userType: res.data.responseData.type,
									urlPath: checkSessionValidate.urlPathVar,
									urlElectron: checkSessionValidate.urlElectronVar,
									deepLinkButton: checkSessionValidate.deepLinkButton
							},()=>$("#session_alert_new").attr({'style':'display:block'})); 
							
							$('.main_loader').hide();
						}
					 else
						 {
							this.setState({
							  message:'',
							  userType:res.data.responseData.type,
							  isOpenSign: false,
							  verify: false,
							 },()=>$("#registration_popup").attr({'style':'display:block'}))
							 
							  $('.main_loader').hide();
						  }
						  
					$('.main_loader').hide();
				})
				.catch(err => {
					$('.main_loader').hide();
					$('#login-alert-text').text(err.response.data.errorData);
					$("#login-alert").attr({'style': 'display: block'});
				  }
				);
		}
	else
		{
			this.validator.showMessages();
		
			this.forceUpdate();
		}
};

closePopup(){
    $('#login-alert-text').text('');
    $("#login-alert").attr({'style': 'display:none'});
}
 
reloadPg = (e) => {
	window.location.reload();
}

getGroupData=data=>{
	/*
	axios      
	.get(process.env.REACT_APP_NAME+"/api/v1/user/getAllGroupsWithInterest")          
	.then(res => {
	 console.log("==============sslalit===",res.data.responseData);
	     this.setState({
		  interestItems: res.data.responseData[data.groupId-1]['interestList']
	  })
  })
	.catch(err =>{
	//	console.log('----------there is problem------------');
	}); 
	*/
}


fetchSessionDetails = (chanelName, aditionalSearch = this.state.searchNewFiedls, firstRun = false) => {
	if ( this.state.isLoadingSessions ) { return false; }
	this.setState({isLoadingSessions: true})
	console.log('----------refresh-------');
  if( aditionalSearch ) { this.setState({ searchNewFiedls: aditionalSearch }); console.log('aditionalSearch'); console.log(aditionalSearch);}

  if ( localStorage.getItem("userData") )
	{
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		axios.defaults.headers.common['Authorization'] = token;
	}
	console.log('MATCH');
	console.log(aditionalSearch);
	
	if ( this.state.startPage == 0 )
		{
			this.setState({
								mainChannelSessions: {},
								weeksArray: [],
								upcomingSessionCount: 0,
								hasMoreLoad: false
							});
		}
	
	this.setState({ loadingMore: true });
	
	
  axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/channeinfo", {
																			channelname: chanelName,
																			additionalSearch: aditionalSearch,
																			filterEquipment: this.state.filterEquipment,
																			filterLevel: this.state.filterLevel,
																			startDate: this.state.fullStartDate,
																			endDate: this.state.fullEndDate,
																			startPage: this.state.startPage
																	})
  .then(res => {
	  	  
		if ( !res.data.responseData.id )
			{
				$('.main_loader').attr({'style':'display:none'});
				this.setState({isLoadingSessions: false, isFoundChannel: false })
				return false;
			}
		
		/* Add next set of sessions */
		var hasMoreLoad = false;
		var newSessions = this.state.mainChannelSessions;
		if ( res.data.responseData.sessions )
			{
				res.data.responseData.weeksArray.forEach((item) => {
					if ( res.data.responseData.sessions && res.data.responseData.sessions[item] )
						{
									if ( !newSessions[item] ) { newSessions[item] = []; }
									
									res.data.responseData.sessions[item].forEach((itemArray) => {
										newSessions[item].push(itemArray);
									});
						}
				});
				
				hasMoreLoad = true;
			}
		res.data.responseData.sessions = newSessions;
		
		
		var newWeeks = this.state.weeksArray;
		
		res.data.responseData.weeksArray.forEach((item) => { if ( newWeeks.indexOf(item) < 0 ) { newWeeks.push(item); } });
		
		res.data.responseData.weeksArray = newWeeks;
		
		/* Add next set of sessions */
		
		
	  var upcomingSessionC = res.data.responseData.totalSessionsUpcoming;
	  var upcomingSessionN = res.data.responseData.totalSessionsNow;
	  if ( upcomingSessionN < 20 || upcomingSessionC < 21 ) { hasMoreLoad = false; }
	
	var hasFoundNext = false;
	var hasFoundNextIn15 = false;
	if ( res.data.responseData.sessions['This Week'] && res.data.responseData.sessions['This Week'].length > 0 )
		{
			for ( var i = 0; i < res.data.responseData.sessions['This Week'].length; i++ )
				{
					if ( res.data.responseData.sessions['This Week'][i]['sessionStatus'] !== 'started' )
						{
							var mainthis = this;
							var currentTime = new Date().getTime();
							if ( currentTime < new Date(res.data.responseData.sessions['This Week'][i]['scheduleDate']).getTime() && !hasFoundNext )
								{
									hasFoundNext = true;
									var nextChange = new Date(res.data.responseData.sessions['This Week'][i]['scheduleDate']).getTime();
									nextChange = nextChange - (new Date()).getTime();
									setTimeout(function ()
										{
											if ( nextChange > 1000 )
												{
													mainthis.setState({ startPage: 0 }, ()=>{  mainthis.fetchSessionDetails(chanelName); });
												}
										}, nextChange);
								}
								
							if ( currentTime < new Date(res.data.responseData.sessions['This Week'][i]['scheduleDate']).getTime() && !hasFoundNextIn15 )
								{
									hasFoundNextIn15 = true;
									var nextChange15 = new Date(res.data.responseData.sessions['This Week'][i]['scheduleDate']).getTime() - 900000;
									nextChange15 = nextChange15 - (new Date()).getTime();
									setTimeout(function ()
										{
											if ( nextChange15 > 1000 )
												{
													mainthis.setState({ startPage: 0 }, ()=>{  mainthis.fetchSessionDetails(chanelName); });
												}
										}, nextChange15);
								}
						}
				}
			
		}
	
	if( !this.state.interestItems ) { this.getGroupData(res.data.responseData); }
	
	var mainthis = this;
	mainthis.updatesse('channelId', res.data.responseData.id, function () { mainthis.setState({ startPage: 0 }, ()=>{  mainthis.fetchSessionDetails(chanelName); }); });
	
	
	
	
	
	this.setState({
		mainChannel: res.data.responseData,
		sessionChannelName: res.data.responseData.name,
		mainChannelSessions: res.data.responseData.sessions,
		weeksArray: res.data.responseData.weeksArray,
		upcomingSessionCount: upcomingSessionC,
		isFoundChannel: true,
		hostsArray: res.data.responseData.channelHosts,
		hasMoreLoad: hasMoreLoad,
		startPage: this.state.startPage + 20,
		loadingMore: false
	}, ()=>{this.sortSessionsAll(this.state.caseForSort);});
	
	if ( parseInt(res.data.responseData.settings.showNativeApplications) === 0 )
		{
			this.setState({showWindowsApp: false});
		}
	
	if ( window.location.hash.length > 5 )
		{
			let showPreview = JSON.parse(atob(window.location.hash.substr(1, 1000)));
			
			this.setState({
				backroundColorPick: showPreview['backroundColorPick'],
				fontColorPick: showPreview['fontColorPick'],
				fontTitleColorPick: showPreview['fontTitleColorPick'],
				buttonsColorPick: showPreview['buttonsColorPick'],
				highlightColorPick: showPreview['highlightColorPick'],
				searchBackground: showPreview['searchBackground'],
				searchFont: showPreview['searchFont'],
				searchInputBackground: showPreview['searchInputBackground'],
				searchInputBorder: showPreview['searchInputBorder']
			});
		}
		
	else
		{
			this.setState({
					backroundColorPick: res.data.responseData.channelSettings.backgroundColor ? res.data.responseData.channelSettings.backgroundColor : '#000000',
					fontColorPick: res.data.responseData.channelSettings.fontColor ? res.data.responseData.channelSettings.fontColor : '#ffffff',
					fontTitleColorPick: res.data.responseData.channelSettings.fontTitleColor ? res.data.responseData.channelSettings.fontTitleColor : '#ffffff',
					buttonsColorPick: res.data.responseData.channelSettings.buttonsColor ? res.data.responseData.channelSettings.buttonsColor : '#bd00ff',
					highlightColorPick: res.data.responseData.channelSettings.highlightTextColor ? res.data.responseData.channelSettings.highlightTextColor : '#bd00ff',
					searchBackground: res.data.responseData.channelSettings.searchBackground ? res.data.responseData.channelSettings.searchBackground : '#492158',
					searchFont: res.data.responseData.channelSettings.searchFont ? res.data.responseData.channelSettings.searchFont : '#ffffff',
					searchInputBackground: res.data.responseData.channelSettings.inputBackground ? res.data.responseData.channelSettings.inputBackground : '#492158',
					searchInputBorder: res.data.responseData.channelSettings.searchBorder ? res.data.responseData.channelSettings.searchBorder : '#bd00ff',
					showAgreePop: res.data.responseData.channelSettings.showWaiver,
					channelWaiverUrl: res.data.responseData.channelSettings.waiverLink,
					hostChannelId: res.data.responseData.id,
					waiverEndDate: res.data.responseData.channelSettings.waiverEndDate
				});
		}
	
	this.getOnDemandsSessions(res.data.responseData.id);
	
	if ( firstRun )
		{
			let typeSession = "Upcoming";
			if ( res.data.responseData && res.data.responseData.channelSettings && res.data.responseData.channelSettings.firstTabOnDemand && res.data.responseData.channelSettings.firstTabOnDemand == 1 ) 
				{
					typeSession = "On Demand";
				}
			this.setState({
				sessionType : typeSession
			});
		}
	
	  this.setState({
		interestItems : res.data.responseData.channelInterestList,
		firstTabOnDemand: res.data.responseData.channelSettings.firstTabOnDemand
	  });
	
	$('.main_loader').hide();

  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);

	  this.setState({
		  isFoundChannel: false
	  });
	  
	  $('.main_loader').hide();
  });
}

closeExpandFilter = (e) =>
{
	e.preventDefault();
	e.stopPropagation();

	this.setState({
		fullSearch : false,
		searchFields: [],
		filterEquipment: '',
		pickedStartDate: '',
		pickedEndDate: '',
		fullEndDate: '',
		fullStartDate: '',
		pickedDate: '',
		choosenSort: '',
		caseForSort: '',
		expandSearchStatus: false,
		searchedBy: '',
		filterLevel: '',
		filterHost: '',
		startPage: 0
	}, ()=>{$('.main_loader').attr({'style':'display:flex'});this.fetchSessionDetails(this.state.openedChannelName, this.state.searchFields);});
}

getOnDemandsSessions=(id)=>{
	
	let data = {channelId: id, isFromChannel: true, additionalSearch: {}, filterEquipment: this.state.filterEquipment, filterLevel: this.state.filterLevel, startDate: this.state.fullStartDate, endDate: this.state.fullEndDate }
	if ( JSON.stringify(this.state.searchNewFiedls) !== '[]' && JSON.stringify(this.state.searchNewFiedls) !== '{}' )
		{
			data['additionalSearch'] = this.state.searchNewFiedls;
		}
	
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getAllOnDemand", data)
	 .then(res => {
		 var alldemand = [];
		 
		 this.setState({isLoadingSessions: false})
		 
		 this.setState({
				mainDemandObject: res.data.responseData,
				onDemandSessionCount: res.data.responseData.demandProcessedCount
			}, ()=>{this.sortOnDemand(this.state.caseForSort);});
			
		if ( res.data.responseData.allDemandSession.length > 25 && res.data.responseData.allInterests.length > 1 )
			{
				this.setState({
					gridDemandView: true,
					hasGridView: true,
					readOnceDemand: true
				});
			}

	 }).catch(err =>{ this.setState({isLoadingSessions: false}) })
}

openDemandSession=(id, mod)=>{
	/*probably open onDemand if user logged in*/
	if ( this.state.logedUser )
		{
			var privateDemand = false;
			if ( mod.channelPrivateMemberOnly === 1 ) { privateDemand = true; }
		
			let postdata = {"channelId": this.state.mainChannel.id, "email": JSON.parse(localStorage.getItem('userData')).data.responseData.email, "sessionIsPrivate": privateDemand, "onDemandId": this.state.demandSessionId};
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserExist", postdata ).then(res => {
				var dataRes = res.data.responseData;
				if ( dataRes.sessionPrivateMemberOnly )
					{
						this.props.showMainErrorPop('This On-demand video can only be viewed by members of '+this.state.mainChannel.name+'. Please contact '+this.state.mainChannel.name+' or your trainer to get access.');
						return false;
					}
					
				this.setState({
					demandSessionId: id
				}, () => {
							let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
							let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
							
							var isHost = false;
							if ( mod.hostId === loginId ) { isHost = true; } 
							
							var checkSessionValidate = this.joinSessionValidate(true, loginId, token, this.state.demandSessionId);
							
							this.setState({
								isLoginOpen: false,
								isOpenLoginAll: false,
								ondemandlink: checkSessionValidate.urlPathVar,
								electronlink: checkSessionValidate.urlElectronVar,
								urlPath: checkSessionValidate.urlPathVar,
								urlElectron: checkSessionValidate.urlElectronVar,
								deepLinkButton: checkSessionValidate.deepLinkButton,
								userSignedUp: true,
								sessionScheduleDate: mod.createdAt,
								sessionTimezone: '',
								loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
								isHost: isHost,
								sessionName: mod.name,
								isOnDemandSession: true
							});
						});
			  
			}).catch(err =>{ });
		}
	else
		{
			this.setState({
				demandSessionId: id,
				isLoginOpen: true,
				openedDemand: mod
			});
		}
}

openDemandDrops=(ev, i, mod)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	let mainArray = this.state.mainDemandObject;
	var last_open = false;
	$.each(mainArray.allDemandSession, function( a, b ) {
		if ( a != i )
			{
				b['descriptionDropOpen'] = false;
				b['equipmentDropOpen'] = false;
				b['goalDropOpen'] = false;
			}
		else
			{
				if ( b[mod] ) { last_open = true; }
				if ( b['descriptionDropOpen'] ) { b['descriptionDropOpen'] = false; }
				if ( b['equipmentDropOpen'] ) { b['equipmentDropOpen'] = false; }
				if ( b['goalDropOpen'] ) { b['goalDropOpen'] = false; }
				
			}
	});
	
	if( !last_open ){ mainArray.allDemandSession[i][mod] = !mainArray.allDemandSession[i][mod]; }

	this.setState({
				mainDemandObject: mainArray
			});
}

openGridView=(title)=>{
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getTitleId", {"title" : title, "channel" : true})
	 .then(res => {
		if ( res.data.responseData )
			{
				if ( res.data.responseData[0] )
					{
						let srchInterest = {'searchInterest' : 0};
						srchInterest['searchInterest'] = res.data.responseData[0]['id'];
						this.setState({
							searchFields : srchInterest,
							searchInterest : res.data.responseData[0]['id']
							}, () => {
								console.log(this.state.searchFields);
								console.log(this.state.searchInterest);
							});
							
					}		
			}
		}).catch(err =>{})
	
	let mainArray = this.state.mainDemandObject;
	mainArray.allDemandSession = JSON.parse(JSON.stringify(mainArray.allDemandSessionsByInterest[title]));
	this.setState({
					gridDemandView: false,
					mainDemandObject: mainArray
				});
}

closeGridView=()=>{
	this.setState({
					gridDemandView: true,
					searchFields : []
				});
}

changeDemandScreen=(mod)=>{
	console.log(mod);
	localStorage.setItem('demandPreview', mod); 
	this.setState({
				demandPreview: mod
			});
}

changeUpcomingScreen=(mod)=>{
	localStorage.setItem('upcomingPreview', mod);
	this.setState({
				upcomingPreview: mod
			});
}

continuePay=(e)=> {
	 if ( this.state.redirect === 'Sign Up' )
		 {		 
			localStorage.setItem('verifyEmailID', this.state.emailSign);
		 }
	else
		{
			localStorage.setItem('verifyEmailID', this.state.email);
		}
	
	 this.setState({
        log_or_signup: true
        },()=>{
		$("#chargePopup").attr({'style':'display:none'})
		$("#sessionPay").attr({'style':'display:block'})
		$('.hidden_div_sign').trigger('click');
	});
}

updateStateFromChild = (newState, mod) => {
	this.setState(newState);
	if ( mod ) 
		{
			$("#registration_popup").attr({'style':'display:block'});
			$('.main_loader').hide();
		}
}

updateStateFromChildSession = (newState) => {
	this.setState(newState);
	$("#session_alert_new").attr({'style':'display:block'})
}

showLoginAlert = (e) => {
	$("#login_error_alert_new").attr({'style':'display:block'})
}

getSessionTime = (date1)=>{

	let hour=new Date(date1).getHours();
	let minute = new Date(date1).getMinutes();

	const  timezone = moment1.tz.guess(date1);
	var tz = moment1.tz(timezone).format('z');

	let sessionTime= hour+':'+minute;
	if(minute<10){ minute='0'+minute }
	
	if(hour<12){
	if(hour<10){	
			sessionTime = '0'+hour+':'+minute+" AM " + tz;
	}else{
			sessionTime=hour+':'+minute+" AM " + tz;
	}
	return sessionTime ;
	}
	if(hour>12){
	if((hour-12)<10){
			sessionTime='0'+(hour-12)+':'+minute+" " +'PM ' + tz;
	}else{
			sessionTime=(hour-12)+':'+minute+" " +'PM ' + tz;
	}	
	return sessionTime ;
	}
	if(hour==12 && minute>=0){
		sessionTime=hour+':'+minute+" " +'PM ' + tz;
	return sessionTime ;
	}    
  }

getSessionDate=(date1)=> {
	let ab = new Date(date1).getTime();
	let day=new Date(ab).getDate();
	let day1=new Date(ab).getDay();
	let month=(new Date(ab).getMonth())+1;
	let year=new Date(ab).getFullYear();
	if ( month < 10 ) { month = '0' + month }
	
	let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	
	let sessionDate= weekday[day1] + ' ' + month+'/'+day;
	return sessionDate; 
}

fixSessionDate=(sesobj)=> {
	
	if ( sesobj.sessionStatus == 'waitingforit' )
	{
		return this.getSessionDateUtil(sesobj.scheduleDate) + ' @ ' + this.getSessionTimeUtil(sesobj.scheduleDate);
	}
	else
	{
		return sesobj.sessionStartTime;
	}
}

renderer2 = ({minutes, seconds}) => {

   return <div>
			   <div>
				   {minutes>=10 && seconds >=10?<div>Starting in <span>{minutes}</span> : <span>{seconds}</span></div>:''}
				  {minutes<10 && seconds <10?<div>Starting in <span>0{minutes}</span> : <span>0{seconds}</span></div>:''}
				  {minutes>=10 && seconds <10?<div>Starting in <span>{minutes}</span> : <span>0{seconds}</span></div>:''}
				  {minutes<10 && seconds >=10?<div>Starting in <span>0{minutes}</span> : <span>{seconds}</span></div>:''}	
			   </div>
		</div>;
   
};


componentDidMount(){
	
	if ( localStorage['demandPreview'] )
		{
			this.setState({
				demandPreview : localStorage['demandPreview'] 
			});
		}
	if ( localStorage['upcomingPreview'] )
		{
			this.setState({
				upcomingPreview : localStorage['upcomingPreview'] 
			});
		}	
	
	if( !this.props.from_search ) 
		{
			$('.main_loader').attr({'style':'display:flex'});
			let host = window.location.host;
			let parts = host.split(".");
			let channelName = parts[0];

			if ( window.location == 'http://localhost:3000/' ) { channelName = 'mindurossss'; }

			this.setState({ openedChannelName : channelName });
			
			this.fetchSessionDetails(channelName, false, true); 
		}
	else
		{
			this.setState({ isFoundChannel: true, sessionType: 'SearchResult', weeksArray: this.props.weekslist , mainChannel: this.props.sessionslist });
		}
		
	if ( localStorage.getItem("userData") )
		{
			//this.setState({ logedUser: true });
			this.setState({
				logedUser: true,
				logedUserName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName
		}, () => {
			console.log(this.state.logedUserName);
		});
			
		}
}

 
componentWillUnmount() {
	clearInterval(window['mainInterval']);
}

hideNativePop = (mod) => {
	window.$('.channelPg #autologinnative').modal('hide'); 
	
	if ( mod ) { this.updateUserDemand(); }
}

openNativeUrl = (nurl) => {
	
	if(!this.state.appDownload && localStorage.getItem('appDownload'))
		{
			this.setState({appDownload: localStorage.getItem('appDownload')});
		}
	
	if(!this.state.appDownload && localStorage.getItem('userData') && !localStorage.getItem('appDownload'))
		{
			var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;
		
			this.setState({appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink )});
		}
		
	$('.main_loader').attr({'style':'display:flex'});
	customProtocolCheck(nurl,
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative.auto_open_channel').modal('hide');
							window.$('.channelPg #autologinnative').modal('hide');
							$("#nativedownload.auto_open_channel").show();
							window.$('#nativedownload2.auto_open_channel').modal('hide');
						  },
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative.auto_open_channel').modal('hide');
							window.$('#nativedownload2.auto_open_channel').modal('hide');
							window.$('.channelPg #autologinnative').modal('hide');
						  }, 15000);
}

updateUserDemand=()=>{
	if ( this.state.sessionType !== 'On Demand' ) { return false; }
	
	var onDemandId = this.state.demandSessionId;
	var userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	var postData = {onDemandId: onDemandId, userId: userId};
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateUserDemand", postData).then(res => { }).catch(err =>{ });
}

openNativeDemand = (item) => {
	console.log('ITEM');
	console.log(item);
	if ( JSON.parse(localStorage.getItem('userData')) )
		{
			var user = JSON.parse(localStorage.getItem('userData'));
			var checkSessionValidate = this.joinSessionValidate(true, user.data.responseData.id, user.data.responseData.token, item.id);
			this.setState({ 
					urlPath: checkSessionValidate.urlPathVar,
					urlElectron: checkSessionValidate.urlElectronVar,
					deepLinkButton: checkSessionValidate.deepLinkButton
			});
		}
	else
		{
			this.setState({urlPath: item.deeplink, urlElectron: item.electronlink});
		}
		
	window.$('#autologinnative.auto_open_channel').modal('show');
}

openProfileInfo = (session, type, ev) =>
{
	if ( type === 'upcoming')
		{
			this.setState({
				userImageProfile : session['image'],
				userInstructorProfileText: session['instructorText'],
				userFullName : session['hostBy']['firstName'] + ' ' + session['hostBy']['lastName']
			});
		}
	else 
		{
			ev.preventDefault();
			ev.stopPropagation();
			this.setState({
				userImageProfile : session['userImage'],
				userInstructorProfileText: session['instructorText'],
				userFullName : session['firstName'] + ' ' + session['lastName']
			});
		}		
	window.$('#profileInfoModal').modal('show');
	
}

openURL=(url, mod) => {
	if ( mod && localStorage.getItem('userData') )
		{
			console.log('mod true');
			let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
			let userToken = JSON.parse(localStorage.getItem('userData')).data.responseData.token;

			var isHost = false;
			if ( mod.hostId === userId ) { isHost = true; }
			var checkSessionValidate = this.joinSessionValidate(isHost, userId, userToken, null);

				
			this.setState({
				message:'',
				userType: 2,
				urlPath:checkSessionValidate.urlPathVar,
				urlElectron: checkSessionValidate.urlElectronVar,
				deepLinkButton: checkSessionValidate.deepLinkButton,
				userSignedUp: true,
				sessionScheduleDate: mod.scheduleDate,
				sessionTimezone: mod.timeZone,
				loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
				isHost: isHost,
				sessionName: mod.name,
				isOnDemandSession: false
			})
			  
			$('.main_loader').hide();
			return false;
		}
		
	//localStorage.setItem('fromChannelToSessionLink', window.location.href);
	
	window.open(url+'#'+btoa(window.location.href));
	
	//window.location.href = url;
}

updateStateSearch = (e, newState) => {
	e.preventDefault();
	e.stopPropagation();
	this.setState(newState);
}

closeSearch = e =>
{
	if(this.state.expandSearchStatus)
		{
			this.setState({
				expandSearchStatus: false
			});
		}
	
}

closeLoginPop = (e) => {
	this.setState({
		isOpenLoginAll: false,
		isLoginOpen: false
	});
}

stayOpenedSearch = (e) =>
{
	e.preventDefault();
	e.stopPropagation();
	this.setState({
		expandSearchStatus: true
	});
}

toggleSortObjects=(selected)=>{
	if(selected != this.state.choosenSort)
		{
			this.setState({caseForSort: selected, choosenSort: selected});
		}
	
	else
		{
			this.setState({caseForSort: '', choosenSort: ''});
		}
}

closeLoginPop = (e) => {
	this.setState({
		isOpenLoginAll: false,
		isLoginOpen: false
	});
} 

setSearchDate =(date, mod)=>{
	let date1=date;
	var momentDateTime = moment(date).format('YYYY-MM-DD 00:00:01');
	var momentDateTimeEnd = moment(date).format('YYYY-MM-DD 23:59:59');
	
	console.log(momentDateTime, 'full date');
	console.log('----------------',new Date(date).getMonth(),new Date(date).getDate());
	let dateofMonth = new Date(date).getDate();
	let timeSelection =  (new Date (date).getMonth()) ;
	console.log(timeSelection);
	date = new Date(Date.UTC(2019, timeSelection, 1));
	var days = [];
	var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	console.log('date.getMonth()',date.getMonth());
	while (date.getMonth() === timeSelection) 
		{
			//days.push(new Date(date).getDate());
			let n ={date:new Date(date).getDate(),
			day:dayofWeek[new Date(date).getDay()],
			timestamp:new Date(date).getTime(),
			}
			days.push(n);
			//days.push(new Date(date).getDay());
			date.setDate(date.getDate() + 1);
		}
	console.log(days)
	
	if(mod == 1)
		{
			this.setState({
				pickedDate:date1,
				pickedStartDate:date1,
				pickedEndDate:date1,
				fullStartDate: momentDateTime,
				fullEndDate: momentDateTimeEnd
			});

		}
		
	else if(mod == 2)
		{
			this.setState({
				pickedStartDate:date1,
				fullStartDate: momentDateTime,
			});
		}
	
	else
		{
			this.setState({
				pickedEndDate:date1,
				fullEndDate: momentDateTimeEnd
			});
		}
}

sortOnDemand=(selected)=>{
	let mainArray = this.state.mainDemandObject;
	
	if ( selected === 'by Host' )
		{
			if ( !this.state.sortedByHost )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.firstName < b.firstName) { return -1; }
						if(a.firstName > b.firstName) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByHost: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.firstName > b.firstName) { return -1; }
						if(a.firstName < b.firstName) { return 1; }
						return 0;
					})
					
					this.setState({sortedByHost: false});
				}
		}
		
	if ( selected === 'by Session' )
		{
			if ( !this.state.sortedBySession )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.name < b.name) { return -1; }
						if(a.name > b.name) { return 1; }
						return 0;
					})
					
					//this.setState({sortedBySession: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.name > b.name) { return -1; }
						if(a.name < b.name) { return 1; }
						return 0;
					})
					
					this.setState({sortedBySession: false});
				}
		}
		
	if ( selected === 'by Duration' )
		{
			if ( !this.state.sortedByDuration )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.duration < b.duration) { return -1; }
						if(a.duration > b.duration) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByDuration: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.duration > b.duration) { return -1; }
						if(a.duration < b.duration) { return 1; }
						return 0;
					})
					
					this.setState({sortedByDuration: false});
				}
		}
		
	if ( selected === 'by Level' )
		{
			if ( !this.state.sortedByLevel )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level < b.level) { return -1; }
								if(a.level > b.level) { return 1; }
							}
						return 0;
					})
					
					//this.setState({sortedByLevel: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level > b.level) { return -1; }
								if(a.level < b.level) { return 1; }
							}
						return 0;
					})
					
					this.setState({sortedByLevel: false});
				}
		}
		
	if ( selected === 'by Interest' )
		{
			if ( !this.state.sortedByinterest )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.interestTitles < b.interestTitles) { return -1; }
						if(a.interestTitles > b.interestTitles) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByinterest: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.interestTitles > b.interestTitles) { return -1; }
						if(a.interestTitles < b.interestTitles) { return 1; }
						return 0;
					})
					
					this.setState({sortedByinterest: false});
				}
		}
		
	if ( selected === 'by Recent Date' )
		{
			if ( !this.state.sortedDateRecent )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return -1; }
						if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return 1; }
						return 0;
					})
				}
				
		}
		
	if ( selected === 'by Older Date' )
		{
			if ( !this.state.sortedDateOlder )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return -1; }
						if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return 1; }
						return 0;
					})
				}
				
		}
		
	this.setState({
				mainDemandObject: mainArray
			});
}

sortSessionsAll=(selected)=>{
	let mainArray = this.state.mainChannelSessions;
	let weekArray = this.state.weeksArray;

	for ( var k = 0; k < weekArray.length; k++ )
		{
			if ( !weekArray[k] || !mainArray[weekArray[k]] ) { return 0; }
			
			if ( selected === 'by Host' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(a.hostFirstName < b.hostFirstName) { return -1; }
						if(a.hostFirstName > b.hostFirstName) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Duration' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(a.duration < b.duration) { return -1; }
						if(a.duration > b.duration) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Session' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(a.name < b.name) { return -1; }
						if(a.name > b.name) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Level' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level < b.level) { return -1; }
								if(a.level > b.level) { return 1; }
							}
						return 0;
					})
				}
				
			if ( selected === 'by Interest' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(a.interestTitles < b.interestTitles) { return -1; }
						if(a.interestTitles > b.interestTitles) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Recent Date' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
						if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Older Date' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return -1; }
						if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return 1; }
						return 0;
					})
				}
				
			if ( selected === '' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
						if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
						return 0;
					})	
				}
				
			this.setState({
						mainChannelSessions: mainArray
					});
		}
}

logoutUser=(e)=>{
	localStorage.removeItem('userData'); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_ID); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); 
	window['user_loggedIn'] = 0;
	this.setState({ logedUser: false }); 
	window.location.reload();
}

openInfoPopUp = (e, mod) => {
	if(mod == 'over')
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active2');
			$(e.currentTarget).find('.tooltip_hold').addClass('tooltip_active2');
		}
		
	else
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active2');
		}
	
}

loadMoreSessions = () => { this.fetchSessionDetails(this.state.openedChannelName); }


  render() {

    return (
		<div className="channelPg">
		
		
		
<style>
	.main_search_item, .expanded_search_hold, .dashboard_search_hold {'{ background-color: '+this.state.searchBackground+' !important;}'}
	.rose_btn, .rose_clr, .closepurple, .form-radio:checked, .btn-cancel:hover, .custom_btn1, .custom_btn, .right-small-box, .nav-link_new.active:after, .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__header, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {'{ background-color: '+this.state.buttonsColorPick+' !important; }'}
	.save-btn.btn-primary, .btn-login, .sort_by_container, .filter_apply {'{ background-color: '+this.state.buttonsColorPick+' !important; border: '+this.state.buttonsColorPick+' !important; }'}
	.channel_main_name, .channel_main_desc {'{ color: '+this.state.fontTitleColorPick+' !important; }'}
	.one_info, .save-btn.btn-primary, .sess_interest, .pick.pb-2, .checktxt_name.border.border-0.mb-0, .pick.mt-2.mb-4.font-18, .font-20, .custom_btn1, .rose_btn, custom_btn, .channel_clear_date, .srv-validation-message, .top_info_title, .description_text, .one_equ, .one_shopping_list, .left_part.bolded, .right_part.bolded, .mb-0.py-4, .sign_main_text, .login-tagline, .label, .back_text, .grid_title, .main_color_part, .color_a.active, .week_part, .channel_no_results, .new_logout_inls {'{ color: '+this.state.fontColorPick+' !important; }'}
	.container-fluid {'{ background-color: '+this.state.backroundColorPick+' !important; }'}
	.purple_text, .forgot-password {'{ color: '+this.state.highlightColorPick+' !important; }'}
	.input_field_container{'{ border: 2px solid '+this.state.buttonsColorPick+' !important; }'}
	.absolute_iks {'{ color: '+this.state.buttonsColorPick+' !important; }'}
	.channel_login_input{'{ border: 2px solid '+this.state.buttonsColorPick+' !important; border-top: none !important }'}
	.input-field {'{ border: 2px solid '+this.state.buttonsColorPick+' !important; }'}
	.channel_cancel_button, .sml_input_box_ak input, .form-radio:checked, .form-radio, .expanded_search_hold {'{ border-color: '+this.state.buttonsColorPick+' !important; }'}
	.custom-control-label::before {'{ background-color: '+this.state.buttonsColorPick+' !important; border-color: '+this.state.buttonsColorPick+' !important; }'}
	.cover-border {'{ border: 2px solid '+this.state.searchInputBackground+' !important;}'}
	.search_font, .search_font option, .react-datepicker__input-container input {'{ color: '+this.state.searchFont+' !important;}'}
	.layout_border {'{ border: 2px solid '+this.state.searchInputBorder+' !important; background-color: '+this.state.searchInputBackground+' !important; }'}
	.react-datepicker__triangle {'{ border-top-color: '+this.state.buttonsColorPick+' !important; border-bottom-color: '+this.state.buttonsColorPick+' !important; }'}
</style>
		
		
		
			{ this.state.isFoundChannel &&
			<div> 
				<div className={"container-fluid custom-width " + (this.props.from_search ? 'full_width' : '')} onClick={()=>{this.closeSearch()}}>
					{ !this.props.from_search &&
						<div className="row top-header px-1 px-sm-4 main_ch_header add_pos_rell">
							{ this.state.logedUser ? 
								<div className="logoutChannel">
									<div className="new_logout_inls new_padd_logout">{this.state.logedUserName}</div>
									<div className="new_logout_inls" onClick={e=>{this.logoutUser()}}>
										<svg width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_2" x="0px" y="0px" viewBox="0 0 24.303 24.303">
											<g xmlns="http://www.w3.org/2000/svg">
												<path d="M10.269,11.298V1.883C10.269,0.844,11.113,0,12.152,0s1.883,0.844,1.883,1.883v9.415 c0,1.039-0.844,1.883-1.883,1.883S10.269,12.337,10.269,11.298z M19.616,2.761c-0.61-0.483-1.5-0.377-1.983,0.234 c-0.483,0.612-0.378,1.5,0.234,1.984c2.24,1.767,3.524,4.413,3.524,7.261c0,5.094-4.145,9.239-9.238,9.239 c-5.094,0-9.239-4.145-9.239-9.239c0-2.847,1.283-5.492,3.521-7.258c0.612-0.483,0.717-1.371,0.234-1.984 c-0.483-0.612-1.37-0.716-1.984-0.234C1.764,5.069,0.089,8.523,0.089,12.24c0,6.652,5.412,12.063,12.063,12.063 s12.063-5.412,12.063-12.063C24.215,8.521,22.538,5.067,19.616,2.761z" fill={this.state.buttonsColorPick} />
											</g>
										</svg>
									</div>
								</div>	
								:
								''
							}
							<div className="inline_header">
								{ this.state.mainChannel.image == 'default.png' &&
								<img src="/images/powered_virdio.png" className="povered" /> 
								}
								{ this.state.mainChannel.image != 'default.png' &&
									<img src={this.state.mainChannel.image} className="povered" /> 
								}
							</div>
							<div className="channel_name">
								<div className="channel_main_name less_font_channel">{this.state.mainChannel.name}</div>
								<div className="channel_main_desc">{this.state.mainChannel.description}</div>
							</div>
							<br />
							<div className="channel_name channel_name2">
							
								<div className="relative">
									<div className="flex-grow-1 input_field_container py-0 radius-8 main_search_item2 name_new_stats dashboard_search_hold" id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}} >
										<div className="row mx-0 frst-input-row shadow-1 py-2 align-items-center radius-8 filter_hold_icon pointer_events">
											<div className="flex-grow-1"><input readOnly="" type="text" className="form-control search_input_dashboard_new pointer_events" placeholder="Search Classes and Equipments" name="" value={this.state.searchedBy} disabled/>
											</div>
										</div>
										<div className="right-small-box filter_dropdown_icon" id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}}></div>
									</div>
									{ this.state.expandSearchStatus && 
										
										<div className="expanded_search_hold search_font" onClick={(e)=>{this.stayOpenedSearch(e)}}>
											<div className="filter_sortby_hold">
												<div className="filter_container_title">Sort By</div>
												<div className="sort_by_inside">
													<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Session')}}>Class Name {this.state.choosenSort == 'by Session' && <div className="checked_sort"></div>}</div>
													<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Interest')}}>Interest {this.state.choosenSort == 'by Interest' && <div className="checked_sort"></div>}</div>
													<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Duration')}}>Duration {this.state.choosenSort == 'by Duration' && <div className="checked_sort"></div>}</div>
													<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Level')}}>Level {this.state.choosenSort == 'by Level' && <div className="checked_sort"></div>}</div>
													<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Host')}}>Instructor {this.state.choosenSort == 'by Host' && <div className="checked_sort"></div>}</div>
													<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Recent Date')}}>Date (Recent to Older) {this.state.choosenSort == 'by Recent Date' && <div className="checked_sort"></div>}</div>
													<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Older Date')}}>Date (Older to Recent) {this.state.choosenSort == 'by Older Date' && <div className="checked_sort"></div>}</div>
												</div>
											</div>
											<div className="filter_main_hold">
												<div className="filter_container_title search_font">Filter By</div>
												<div className="filter_inside">
													<div className="form-group-header">
														<span className="cover-border"></span>
														<label className="label dashboard_search_label search_font">Class Name</label>
														<input 
															type="text"
															value={this.state.searchFields.searchSessionName || ''}
															onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchSessionName: e.target.value } })}
															className="input-field layout_border search_font"  
														/>
													</div>
													<div className="form-group-header interest_search">
														<span className="cover-border"></span>
														<label className="label dashboard_search_label search_font">Interest</label>
														<select value={ this.state.searchFields.searchInterest || ''} className="input-field deny_grind channel_choose_search layout_border search_font" onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchInterest: e.target.value } })} >
															<option></option>
															{ this.state.interestItems && this.state.interestItems.map((item, key) => 
																<option value={item['id']} key={key}>{item['title']}</option>
															)}
														</select>
														<div className="select_channel_arrow">
															<svg width="28px" height="28px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 20.000000 12.000000">
																<g transform="translate(0.000000,12.000000) scale(0.100000,-0.100000)"
																fill={this.state.searchInputBorder} stroke="none">
																	<path d="M10 113 c1 -5 21 -30 45 -58 l45 -50 45 50 c24 28 44 53 45 58 0 4 -40 7 -90 7 -50 0 -90 -3 -90 -7z m140 -21 c0 -5 -12 -20 -27 -34 l-28 -25 -28 33 -29 34 56 0 c31 0 56 -4 56 -8z"/>
																</g>
															</svg>
														</div>
													</div>
													<div className="form-group-header">
														<span className="cover-border"></span>
														<label className="label dashboard_search_label search_font">Instructor</label>
														<select id="filterHost" className="input-field pr45 deny_grind channel_choose_search layout_border search_font" value={this.state.filterHost} onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, filterHost: e.target.value }, filterHost: e.target.value })}>
															<option value=""></option>
															{this.state.hostsArray.sort(function(a, b){ if(a.username < b.username) { return -1; } if(a.username > b.username) { return 1; } return 0; }).map((item, key) => 
																<option value={item.userId} key={key}>{item.username}</option>
															)}
														</select>
														<div className="select_channel_arrow">
															<svg width="28px" height="28px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 20.000000 12.000000">
																<g transform="translate(0.000000,12.000000) scale(0.100000,-0.100000)"
																fill={this.state.searchInputBorder} stroke="none">
																	<path d="M10 113 c1 -5 21 -30 45 -58 l45 -50 45 50 c24 28 44 53 45 58 0 4 -40 7 -90 7 -50 0 -90 -3 -90 -7z m140 -21 c0 -5 -12 -20 -27 -34 l-28 -25 -28 33 -29 34 56 0 c31 0 56 -4 56 -8z"/>
																</g>
															</svg>
														</div>
													</div>
													<div className="form-group-header">
														<span className="cover-border"></span>
														<label className="label dashboard_search_label search_font">Level</label>
														<select className="input-field pr45 deny_grind channel_choose_search layout_border dashboard_main_select search_font" id="filterLevel" value={this.state.filterLevel} onChange={(e)=>this.setState({[e.target.id]:e.target.value})}>
															<option value=""></option>
															<option value="Beginner">Beginner</option>
															<option value="Intermediate">Intermediate</option>
															<option value="Advanced">Advanced</option>
															<option value="Multi-Level">Multi-Level</option>
														</select>
														<div className="select_channel_arrow">
															<svg width="28px" height="28px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 20.000000 12.000000">
																<g transform="translate(0.000000,12.000000) scale(0.100000,-0.100000)"
																fill={this.state.searchInputBorder} stroke="none">
																	<path d="M10 113 c1 -5 21 -30 45 -58 l45 -50 45 50 c24 28 44 53 45 58 0 4 -40 7 -90 7 -50 0 -90 -3 -90 -7z m140 -21 c0 -5 -12 -20 -27 -34 l-28 -25 -28 33 -29 34 56 0 c31 0 56 -4 56 -8z"/>
																</g>
															</svg>
														</div>
													</div>
													 <div className="form-group-header">
														<span className="cover-border"></span>
														<label className="label dashboard_search_label search_font">Duration (*Minutes)</label>
														<select id="durationSelect" className="input-field pr45 deny_grind channel_choose_search layout_border search_font" value={ this.state.searchFields.searchDuraction  || '' } onChange={ (e)=> this.setState({ ...this.state, searchFields: { ...this.state.searchFields, searchDuraction: e.target.value } })} >
															<option></option>
															<option value="29">Less than 30 Minute</option>
															<option value="30">30 Minutes</option>
															<option value="45">45 Minutes</option>
															<option value="60">More than 60min</option>
														</select>
														<div className="select_channel_arrow">
															<svg width="28px" height="28px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 20.000000 12.000000">
																<g transform="translate(0.000000,12.000000) scale(0.100000,-0.100000)"
																fill={this.state.searchInputBorder} stroke="none">
																	<path d="M10 113 c1 -5 21 -30 45 -58 l45 -50 45 50 c24 28 44 53 45 58 0 4 -40 7 -90 7 -50 0 -90 -3 -90 -7z m140 -21 c0 -5 -12 -20 -27 -34 l-28 -25 -28 33 -29 34 56 0 c31 0 56 -4 56 -8z"/>
																</g>
															</svg>
														</div>
													</div>
													 <div className="form-group-header">
														<span className="cover-border"></span>
														<label className="label dashboard_search_label search_font">Equipment</label>
														<input 
															type="text"
															id="filterEquipment"
															value={this.state.filterEquipment}
															onChange={(e)=>this.setState({[e.target.id]:e.target.value})}
															className="input-field layout_border search_font"  
														/>
													</div>
													 <div className="form-group-header">
														<span className="cover-border"></span>
														<label className="label dashboard_search_label search_font">Pick Date</label>
														<div className="">
															<input type="text" name="searchPickDate" autoComplete="none" value={this.state.searchShowTime || ''} className="input-field layout_border search_font" readOnly/>
															<div className="date_picker_hold dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_hold date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedDate} onChange={date => this.setSearchDate(date, 1)} /></div>
															<div className="when-icon search_calendar_icon">
																<div className="flex_calendar_hold">
																	<svg width="28px" height="28px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 30.000000 30.000000">
																		<g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill={this.state.searchInputBorder} stroke="none">
																		<path d="M50 290 c0 -6 -11 -15 -25 -20 -25 -9 -25 -11 -25 -128 0 -78 4 -122 12 -130 17 -17 259 -17 276 0 8 8 12 52 12 130 0 116 -1 119 -24 128 -13 5 -27 15 -30 22 -5 10 -7 10 -12 1 -7 -16 -164 -18 -164 -3 0 6 -4 10 -10 10 -5 0 -10 -5 -10 -10z m4 -52 c5 -10 7 -10 12 0 8 17 160 17 168 0 5 -10 7 -10 12 0 9 19 34 14 34 -8 0 -19 -7 -20 -130 -20 -123 0 -130 1 -130 20 0 22 25 27 34 8z m224 -130 l-3 -83 -125 0 -125 0 -3 83 -3 82 131 0 131 0 -3 -82z"/>
																		<path d="M50 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M90 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M140 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M190 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M230 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M50 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M90 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M140 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M190 85 c0 -32 2 -35 30 -35 28 0 30 3 30 35 0 32 -2 35 -30 35 -28 0 -30 -3 -30 -35z m40 0 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
																		<path d="M50 60 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M90 60 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M140 60 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		</g>
																	</svg>
																</div>
															</div>
														</div>
													</div>
													 <div className="form-group-header filter_date_hold">
														<div className="above_picker">From</div>
														<span className="cover-border"></span>
														<label className="label dashboard_search_label search_font">Start Date</label>
														<div className="">
															<input type="text" name="searchStartDate" autoComplete="none" value={this.state.searchStartTime || ''} className="input-field layout_border search_font" readOnly/>
															<div className="date_picker_hold dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_hold date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedStartDate} onChange={date => this.setSearchDate(date, 2)} /></div>
															<div className="when-icon search_calendar_icon">
																<div className="flex_calendar_hold">
																	<svg width="28px" height="28px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 30.000000 30.000000">
																		<g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill={this.state.searchInputBorder} stroke="none">
																		<path d="M50 290 c0 -6 -11 -15 -25 -20 -25 -9 -25 -11 -25 -128 0 -78 4 -122 12 -130 17 -17 259 -17 276 0 8 8 12 52 12 130 0 116 -1 119 -24 128 -13 5 -27 15 -30 22 -5 10 -7 10 -12 1 -7 -16 -164 -18 -164 -3 0 6 -4 10 -10 10 -5 0 -10 -5 -10 -10z m4 -52 c5 -10 7 -10 12 0 8 17 160 17 168 0 5 -10 7 -10 12 0 9 19 34 14 34 -8 0 -19 -7 -20 -130 -20 -123 0 -130 1 -130 20 0 22 25 27 34 8z m224 -130 l-3 -83 -125 0 -125 0 -3 83 -3 82 131 0 131 0 -3 -82z"/>
																		<path d="M50 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M90 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M140 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M190 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M230 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M50 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M90 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M140 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M190 85 c0 -32 2 -35 30 -35 28 0 30 3 30 35 0 32 -2 35 -30 35 -28 0 -30 -3 -30 -35z m40 0 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
																		<path d="M50 60 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M90 60 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M140 60 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		</g>
																	</svg>
																</div>
															</div>
														</div>
													</div>
													 <div className="form-group-header filter_date_hold">
														<div className="above_picker">To</div>
														<span className="cover-border"></span>
														<label className="label dashboard_search_label search_font">End Date</label>
														<div className="">
															<input type="text" name="searchEndDate" autoComplete="none" id="filterEndDate" value={this.state.searchShowTime || ''} className="input-field layout_border search_font" readOnly/>
															<div className="date_picker_hold dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_hold date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedEndDate} onChange={date => this.setSearchDate(date, 3)} /></div>
															<div className="when-icon search_calendar_icon">
																<div className="flex_calendar_hold">
																	<svg width="28px" height="28px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 30.000000 30.000000">
																		<g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill={this.state.searchInputBorder} stroke="none">
																		<path d="M50 290 c0 -6 -11 -15 -25 -20 -25 -9 -25 -11 -25 -128 0 -78 4 -122 12 -130 17 -17 259 -17 276 0 8 8 12 52 12 130 0 116 -1 119 -24 128 -13 5 -27 15 -30 22 -5 10 -7 10 -12 1 -7 -16 -164 -18 -164 -3 0 6 -4 10 -10 10 -5 0 -10 -5 -10 -10z m4 -52 c5 -10 7 -10 12 0 8 17 160 17 168 0 5 -10 7 -10 12 0 9 19 34 14 34 -8 0 -19 -7 -20 -130 -20 -123 0 -130 1 -130 20 0 22 25 27 34 8z m224 -130 l-3 -83 -125 0 -125 0 -3 83 -3 82 131 0 131 0 -3 -82z"/>
																		<path d="M50 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M90 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M140 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M190 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M230 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M50 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M90 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M140 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M190 85 c0 -32 2 -35 30 -35 28 0 30 3 30 35 0 32 -2 35 -30 35 -28 0 -30 -3 -30 -35z m40 0 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
																		<path d="M50 60 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M90 60 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		<path d="M140 60 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
																		</g>
																	</svg>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="filter_bottom_options">
												<div className="filter_clear_all" onClick={(e)=>{this.closeExpandFilter(e)}}>Clear All</div>
												<div className="filter_apply search_font" onClick={(e)=>{this.startMainSearch(e)}}>APPLY</div>
											</div>
										</div>
									}
								</div>
							</div>
						</div>
					}
					<div className="main_part_pg">
						{ !this.props.from_search &&
							<div className="cont_bg">
								<div className="row relative">
									<div className="col-xl-8 col-lg-7 new_dash_width">
										{ this.state.firstTabOnDemand ?
											<ul className="nav nav-tabs none_bord">
												<li className="nav-item new_padd_nav cursor new_padd_nav2" onClick={this.onDemand}>
													<a className={ "nav-link_new upcoming_sess color_a " + ( this.state.sessionType == "On Demand" ? "active" : "" ) }>On-demand Classes ({this.state.onDemandSessionCount})</a>
												</li>
												<li className="nav-item new_padd_nav cursor new_padd_nav2" onClick={this.onUpcoming}> 
													<a className={ "nav-link_new upcoming_sess color_a " + ( this.state.sessionType == "Upcoming" ? "active" : "" ) }>Upcoming Class ({this.state.upcomingSessionCount})</a>
												</li>
											</ul>
											:
										<ul className="nav nav-tabs none_bord">
											<li className="nav-item new_padd_nav cursor new_padd_nav2" onClick={this.onUpcoming}> 
												<a className={ "nav-link_new upcoming_sess color_a " + ( this.state.sessionType == "Upcoming" ? "active" : "" ) }>Upcoming Class ({this.state.upcomingSessionCount})</a>
											</li>
											<li className="nav-item new_padd_nav cursor new_padd_nav2" onClick={this.onDemand}>
												<a className={ "nav-link_new upcoming_sess color_a " + ( this.state.sessionType == "On Demand" ? "active" : "" ) }>On-demand Classes ({this.state.onDemandSessionCount})</a>
											</li>
										</ul>
										}
									</div>
									{ this.state.sessionType === "Upcoming" &&
										<div className="abs_view_demand new_text_align">
											<div className={"inline_btns_header btns_show grid_view deny_grind inline_btns_header_new pos_rel_pass" + (this.state.upcomingPreview === 'square' ? ' active' : '')} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') } onClick={ () => { this.changeUpcomingScreen('square') }}>
												<div className="tooltip_hold pink_info">
													<div className="tooltip_text">Panel View</div>
												</div>
												 <div className="grid_hld">
													 <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 360.49 360.49">
														<g>
															<g>
																<path d="M96.653,0H13.061C7.29,0,2.612,4.678,2.612,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449V10.449C107.102,4.678,102.424,0,96.653,0z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M222.041,0h-83.592C132.678,0,128,4.678,128,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449V10.449C232.49,4.678,227.812,0,222.041,0z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M96.653,125.388H13.061c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C107.102,130.066,102.424,125.388,96.653,125.388z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M222.041,125.388h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C232.49,130.066,227.812,125.388,222.041,125.388z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M347.429,0h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449V10.449C357.878,4.678,353.199,0,347.429,0z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M347.429,125.388h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C357.878,130.066,353.199,125.388,347.429,125.388z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M96.653,256H13.061c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C107.102,260.678,102.424,256,96.653,256z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M222.041,256h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C232.49,260.678,227.812,256,222.041,256z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M347.429,256h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C357.878,260.678,353.199,256,347.429,256z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
															</g>
														</g>
													</svg>
												</div>
											</div>
											<div className={"inline_btns_header btns_show list_view deny_grind inline_btns_header_new pos_rel_pass" + (this.state.upcomingPreview === 'list' ? ' active' : '')} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') } onClick={ () => { this.changeUpcomingScreen('list') }}>
												 <div className="tooltip_hold pink_info">
													<div className="tooltip_text">List View</div>
												</div>
												 <div className="grid_hld">
													 <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 394.971 394.971">
														<g>
															<g>
																<path d="M56.424,146.286c-28.277,0-51.2,22.923-51.2,51.2s22.923,51.2,51.2,51.2s51.2-22.923,51.2-51.2     S84.701,146.286,56.424,146.286z M56.424,227.788L56.424,227.788c-16.735,0-30.302-13.567-30.302-30.302     s13.567-30.302,30.302-30.302c16.735,0,30.302,13.567,30.302,30.302S73.16,227.788,56.424,227.788z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M379.298,187.037H143.151c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449h236.147     c5.771,0,10.449-4.678,10.449-10.449S385.069,187.037,379.298,187.037z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M56.424,0c-28.277,0-51.2,22.923-51.2,51.2s22.923,51.2,51.2,51.2s51.2-22.923,51.2-51.2S84.701,0,56.424,0z      M56.424,81.502c-16.735,0-30.302-13.567-30.302-30.302s13.567-30.302,30.302-30.302S86.726,34.465,86.726,51.2     S73.16,81.502,56.424,81.502z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M143.151,61.649h236.147c5.771,0,10.449-4.678,10.449-10.449s-4.678-10.449-10.449-10.449H143.151     c-5.771,0-10.449,4.678-10.449,10.449S137.38,61.649,143.151,61.649z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M56.424,292.571c-28.277,0-51.2,22.923-51.2,51.2c0,28.277,22.923,51.2,51.2,51.2s51.2-22.923,51.2-51.2     C107.624,315.494,84.701,292.571,56.424,292.571z M86.726,343.771c0,16.735-13.567,30.302-30.302,30.302v0     c-16.735,0-30.302-13.567-30.302-30.302c0-16.735,13.567-30.302,30.302-30.302S86.726,327.036,86.726,343.771L86.726,343.771z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M379.298,333.322H143.151c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449h236.147     c5.771,0,10.449-4.678,10.449-10.449S385.069,333.322,379.298,333.322z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
															</g>
														</g>
													</svg>
												</div>
											</div>
										</div>
									}
									{ this.state.sessionType === "On Demand" && !this.state.gridDemandView &&
										<div className="abs_view_demand new_text_align">
											<div className={"inline_btns_header btns_show grid_view deny_grind inline_btns_header_new pos_rel_pass" + (this.state.demandPreview === 'square' ? ' active' : '')} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') } onClick={ () => { this.changeDemandScreen('square') }}>
												 <div className="tooltip_hold pink_info more_top_pos">
													<div className="tooltip_text">Panel View</div>
												</div>
												 <div className="grid_hld">
													 <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 360.49 360.49">
														<g>
															<g>
																<path d="M96.653,0H13.061C7.29,0,2.612,4.678,2.612,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449V10.449C107.102,4.678,102.424,0,96.653,0z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M222.041,0h-83.592C132.678,0,128,4.678,128,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449V10.449C232.49,4.678,227.812,0,222.041,0z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M96.653,125.388H13.061c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C107.102,130.066,102.424,125.388,96.653,125.388z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M222.041,125.388h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C232.49,130.066,227.812,125.388,222.041,125.388z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M347.429,0h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449V10.449C357.878,4.678,353.199,0,347.429,0z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M347.429,125.388h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C357.878,130.066,353.199,125.388,347.429,125.388z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M96.653,256H13.061c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C107.102,260.678,102.424,256,96.653,256z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M222.041,256h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C232.49,260.678,227.812,256,222.041,256z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M347.429,256h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592     c5.771,0,10.449-4.678,10.449-10.449v-83.592C357.878,260.678,353.199,256,347.429,256z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
															</g>
														</g>
													</svg>
												</div>
											</div>
											<div className={"inline_btns_header btns_show list_view deny_grind inline_btns_header_new pos_rel_pass" + (this.state.demandPreview === 'list' ? ' active' : '')} onClick={ () => { this.changeDemandScreen('list') }} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') }>
												 <div className="tooltip_hold pink_info more_top_pos">
													<div className="tooltip_text">List View</div>
												</div>
												 <div className="grid_hld">
													 <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 394.971 394.971">
														<g>
															<g>
																<path d="M56.424,146.286c-28.277,0-51.2,22.923-51.2,51.2s22.923,51.2,51.2,51.2s51.2-22.923,51.2-51.2     S84.701,146.286,56.424,146.286z M56.424,227.788L56.424,227.788c-16.735,0-30.302-13.567-30.302-30.302     s13.567-30.302,30.302-30.302c16.735,0,30.302,13.567,30.302,30.302S73.16,227.788,56.424,227.788z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M379.298,187.037H143.151c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449h236.147     c5.771,0,10.449-4.678,10.449-10.449S385.069,187.037,379.298,187.037z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M56.424,0c-28.277,0-51.2,22.923-51.2,51.2s22.923,51.2,51.2,51.2s51.2-22.923,51.2-51.2S84.701,0,56.424,0z      M56.424,81.502c-16.735,0-30.302-13.567-30.302-30.302s13.567-30.302,30.302-30.302S86.726,34.465,86.726,51.2     S73.16,81.502,56.424,81.502z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M143.151,61.649h236.147c5.771,0,10.449-4.678,10.449-10.449s-4.678-10.449-10.449-10.449H143.151     c-5.771,0-10.449,4.678-10.449,10.449S137.38,61.649,143.151,61.649z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M56.424,292.571c-28.277,0-51.2,22.923-51.2,51.2c0,28.277,22.923,51.2,51.2,51.2s51.2-22.923,51.2-51.2     C107.624,315.494,84.701,292.571,56.424,292.571z M86.726,343.771c0,16.735-13.567,30.302-30.302,30.302v0     c-16.735,0-30.302-13.567-30.302-30.302c0-16.735,13.567-30.302,30.302-30.302S86.726,327.036,86.726,343.771L86.726,343.771z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
																<path d="M379.298,333.322H143.151c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449h236.147     c5.771,0,10.449-4.678,10.449-10.449S385.069,333.322,379.298,333.322z" data-original="#000000" className="active-path" fill={this.state.buttonsColorPick}/>
															</g>
														</g>
													</svg>
												</div>
											</div>
										</div>
									}
								</div>
							</div>
						}
						
						{ this.state.sessionType  == 'Upcoming' && 
							
							<div className="channel_topscroll">
								<div className={( this.state.upcomingPreview === 'list' ? "channel_subscroll" : "" ) }>
									{this.state.weeksArray.length > 0 &&
									<div>
										{ this.state.upcomingPreview === 'list' &&
											<div className="cont_bg main_info_menu">
												<div className="row row_pd row_inline">
													<div className="one_info when_info">When</div>
													<div className="one_info">Hosted By</div>
													<div className="one_info">Minutes</div>
													<div className="one_info">Class</div>
													<div className="one_info">Interest</div>
													{ /* <div className="one_info">Members</div>
													<div className="one_info">Not Members</div> */ }
												</div>
											</div>
										}
									{ this.state.weeksArray.map((week, k)=>
										<div className="cont_bg bord_main_part" key={k}>
											{ this.state.mainChannelSessions &&
												<div>
													<div className={ "week_part " + ( this.state.upcomingPreview === 'square' ? "mt20" : "" ) }>{week}</div>
													{ this.state.mainChannelSessions[week] && 
														<div> 
															{ this.state.upcomingPreview === 'list' &&
															<div>
																{this.state.mainChannelSessions[week].map((sess, i)=>
																	<div className="row row_pd row_inline" key={i}>
																		{ sess.sessionStatus != 'halfhourless' && 
																			<div className="one_info relative_padd when_info">{this.fixSessionDate(sess)}
																				<div className="one_info show_on_scroll" data='test'>
																					<div>
																						{ sess.alreadyMember && (sess.sessionStatus == 'started' || sess.sessionStatus == 'halfhourless') && 
																							
																							<div className="rose_btn" onClick={() => this.openURL(sess.sessionLink, sess)}>Join</div>
																						}
																					</div>
																					<div>
																						{ sess.singedUpUsers != sess.maxAttendee && sess.sessionStatus != 'started' && sess.sessionStatus != 'halfhourless' && 
																							<div className="rose_btn"  onClick={() => this.openURL(sess.sessionLink)}>
																								{ sess.alreadyMember ?
																									<span>Signed Up</span>
																									:
																									<span>Sign Up</span>
																								}
																							</div>
																						}
																					</div>
																					<div>
																						{ !sess.alreadyMember && sess.singedUpUsers != sess.maxAttendee && (sess.sessionStatus == 'started' || sess.sessionStatus == 'halfhourless') && 
																							
																								<div className="rose_btn" onClick={() => this.openURL(sess.sessionLink)}>Join</div>
																						}
																					</div>
																					<div>
																						{ !sess.alreadyMember && sess.singedUpUsers == sess.maxAttendee && 
																							
																							<div className="rose_btn" onClick={() => this.openURL(sess.sessionLink)}>Full - Msg Instructor</div>
																						}
																					</div>
																				</div>
																			</div>
																		}
																		{ sess.sessionStatus == 'halfhourless' && 
																			<div className="one_info relative_padd when_info">
																				<Countdown date={Date.now() + ((new Date(sess.scheduleDate).getTime()-new Date().getTime()))} renderer={this.renderer2}></Countdown>
																				<div className="one_info show_on_scroll" data='test'>
																					<div>
																						{ sess.alreadyMember && (sess.sessionStatus == 'started' || sess.sessionStatus == 'halfhourless') && 
																							
																							<div className="rose_btn" onClick={() => this.openURL(sess.sessionLink, sess)}>Join</div>
																						}
																					</div>
																					<div>
																						{ sess.singedUpUsers != sess.maxAttendee && sess.sessionStatus != 'started' && sess.sessionStatus != 'halfhourless' && 
																							<div className="rose_btn"  onClick={() => this.openURL(sess.sessionLink)}>
																								{ sess.alreadyMember ?
																									<span>Signed Up</span>
																									:
																									<span>Sign Up</span>
																								}
																							</div>
																						}
																					</div>
																					<div>
																						{ !sess.alreadyMember && sess.singedUpUsers != sess.maxAttendee && (sess.sessionStatus == 'started' || sess.sessionStatus == 'halfhourless') && 
																							
																							<div className="rose_btn" onClick={() => this.openURL(sess.sessionLink)}>Join</div>
																						}
																					</div>
																					<div>
																						{ !sess.alreadyMember && sess.singedUpUsers == sess.maxAttendee && 
																							
																							<div className="rose_btn" onClick={() => this.openURL(sess.sessionLink)}>Full - Msg Instructor</div>
																						}
																					</div>
																				</div>
																			</div>
																		}
																		{ sess.hostBy && 
																			<div className="one_info relative_padd">{sess.hostBy.firstName} {sess.hostBy.lastName}
																				<div className="new_info_btn" onClick={e=>{this.openProfileInfo(sess, 'upcoming', e)}}></div>
																			</div>
																		}
																		<div className="one_info relative_padd">{sess.duration} Minutes</div>
																		<div className="one_info relative_padd">{sess.name}</div>
																		<div className="one_info relative_padd text_wrap_demand" title={sess.interestTitles} >{sess.interestTitles}</div>
																		{ /* <div className="one_info relative_padd">${sess.chargeForSession}</div>
																		<div className="one_info relative_padd">${sess.chargeForSessionNonMember}</div> */ }
																		<div className="info_btn" onClick={this.openMoreInfo}>
																			<div className={ "all_info " + ( sess.isOpenMoreInfo ? "active" : "" ) } onClick={this.preventDef}>
																				<div className="arrow_info"></div>
																					<div className="relative_over">
																					<div className="absolute_iks" onClick={this.openMoreInfo}>&#10005;</div>
																					<div className="top_info_title">Description:</div>
																					<div className="description_text">{sess.description}</div>
																					<div className="left_part bolded">Signed Up: {sess.singedUpUsers}</div>
																					<div className="right_part bolded">Max Allowed: {sess.maxAttendee}</div>
																					{ sess.shoppingList.length > 0 && 
																						<div>
																							<div className="top_info_title txt_center">Shopping List</div>
																							<div className="one_shopping_list">
																								<div className="one_shop oneshop25 bolded txt_left">Item Name</div>
																								<div className="one_shop oneshop25 bolded txt_center">Quantity</div>
																								<div className="one_shop oneshop25 bolded txt_center">Price</div>
																								<div className="one_shop oneshop25 bolded txt_right">Item Notes</div>
																							</div>
																							{sess.shoppingList.map((shop, ind)=>
																								<div className="one_shopping_list" key={ind}>
																									<div className="one_shop oneshop25 txt_left">{shop.link}</div>
																									<div className="one_shop oneshop25 txt_center">{shop.quantity}</div>
																									<div className="one_shop oneshop25 txt_center">{shop.price}$</div>
																									<div className="one_shop oneshop25 txt_right">{shop.item_note}</div>
																								</div>
																							)}
																						</div>
																					}
																					
																					{ sess.equipmentList.length > 0 && 
																						<div>
																							<div className="top_info_title txt_center">Equipment List</div>
																							<div className="one_shopping_list">
																								<div className="one_equ txt_left">Items</div>
																								<div className="one_equ txt_right">Quantity</div>
																							</div>
																							{sess.equipmentList.map((equ, ind)=>
																								<div className="one_shopping_list" key={ind}>
																									<div className="one_equ txt_left">{equ.equipment_name}</div>
																									<div className="one_equ txt_right">{equ.quantity}</div>
																								</div>
																							)}
																						</div>
																					}
																				</div>			
																			</div>
																					
																		</div>
																		<div className="one_info hidden_on_scroll" data='test'>
																			<div>
																				{ sess.alreadyMember && (sess.sessionStatus == 'started' || sess.sessionStatus == 'halfhourless') && 
																					
																					<div className="rose_btn" onClick={() => this.openURL(sess.sessionLink, sess)}>Join</div>
																				}
																			</div>
																			<div>
																				{ sess.singedUpUsers != sess.maxAttendee && sess.sessionStatus != 'started' && sess.sessionStatus != 'halfhourless' && 
																					<div className="rose_btn"  onClick={() => this.openURL(sess.sessionLink)}>
																						{ sess.alreadyMember ?
																							<span>Signed Up</span>
																							:
																							<span>Sign Up</span>
																						}
																					</div>
																				}
																			</div>
																			<div>
																				{ !sess.alreadyMember && sess.singedUpUsers != sess.maxAttendee && (sess.sessionStatus == 'started' || sess.sessionStatus == 'halfhourless') && 
																					
																						<div className="rose_btn" onClick={() => this.openURL(sess.sessionLink)}>Join</div>
																				}
																			</div>
																			<div>
																				{ !sess.alreadyMember && sess.singedUpUsers == sess.maxAttendee && 
																					
																					<div className="rose_btn" onClick={() => this.openURL(sess.sessionLink)}>Full - Msg Instructor</div>
																				}
																			</div>
																		</div>
																	</div>
																)}
															</div>
															}
															{ this.state.upcomingPreview === 'square' &&
																<div className="all_demain_view pad_upc">
																	{this.state.mainChannelSessions[week].map((sess, i)=>
																		<div key={i} className={classnames("demand_preview_square ", { 'odd_square': Math.abs(i % 2) == 1 })} style={{backgroundImage: "url('" + sess.image + "')"}}>
																			<div className="all_inner_square auto_cur">
																				<div className="sess_interest text_wrap_demand">{sess.interestTitles}</div>
																				<div className="relative_level padb70">
																					<div className="sess_interest inline">{sess.level}</div>
																					{ sess.level === 'Beginner' &&
																						<div className="level_square green_square"></div>
																					}
																					{ sess.level === 'Intermediate' &&
																						<div className="level_square yellow_square"></div>
																					}
																					{ sess.level === 'Advanced' &&
																						<div className="level_square red_square"></div>
																					}
																					{ sess.level === 'Multi-Level' &&
																						<div className="level_square multi_level_square"></div>
																					}
																				</div>
																				<div className="footer_square">
																					<div className="footer_pad">
																						<div className="footer_square50 main_color_part">{sess.name}</div>
																						<div className="footer_square25 main_color_part">{sess.hostBy.firstName} {sess.hostBy.lastName}
																							<div className="new_info_btn" onClick={e=>{this.openProfileInfo(sess, 'upcoming', e)}}></div>
																						</div>
																						<div className="footer_square25 relative pl25 main_color_part">{sess.duration} Min
																							<div className="icon_foot_part_l clock_img"></div>
																						</div>
																					</div>
																				</div>
																				<div className="footer_pad">
																					<div className="footer_square100 description_demand relative pl25">
																						{ sess.sessionStatus != 'halfhourless' && <span>{this.fixSessionDate(sess)}</span> }
																						{ sess.sessionStatus == 'halfhourless' && <span><Countdown date={Date.now() + ((new Date(sess.scheduleDate).getTime()-new Date().getTime()))} renderer={this.renderer2}></Countdown></span> }
																						<div className="icon_foot_part_l record_img"></div>
																					</div>
																					<div className="footer_square33 description_demand">Member ${sess.chargeForSession}</div>
																					<div className="footer_square33 description_demand">Non-Member ${sess.chargeForSessionNonMember}</div>
																					<div className="footer_square33 description_demand relative">
																						<div className="info_btn winfo100" onClick={this.openMoreInfo}>
																							<div className={ "all_info " + ( sess.isOpenMoreInfo ? "active" : "" ) } onClick={this.preventDef}>
																								<div className="arrow_info"></div>
																								<div className="relative_over">
																									<div className="absolute_iks" onClick={this.openMoreInfo}>&#10005;</div>
																									<div className="top_info_title">Description:</div>
																									<div className="description_text">{sess.description}</div>
																									<div className="left_part bolded">Signed Up: {sess.singedUpUsers}</div>
																									<div className="right_part bolded">Max Allowed: {sess.maxAttendee}</div>
																									{ sess.shoppingList.length > 0 && 
																										<div>
																											<div className="top_info_title txt_center">Shopping List</div>
																											<div className="one_shopping_list">
																												<div className="one_shop oneshop25 bolded txt_left">Item Name</div>
																												<div className="one_shop oneshop25 bolded txt_center">Quantity</div>
																												<div className="one_shop oneshop25 bolded txt_center">Price</div>
																												<div className="one_shop oneshop25 bolded txt_right">Item Notes</div>
																											</div>
																											{sess.shoppingList.map((shop, ind)=>
																												<div className="one_shopping_list" key={ind}>
																													<div className="one_shop oneshop25 txt_left">{shop.link}</div>
																													<div className="one_shop oneshop25 txt_center">{shop.quantity}</div>
																													<div className="one_shop oneshop25 txt_center">{shop.price}$</div>
																													<div className="one_shop oneshop25 txt_right">{shop.item_note}</div>
																												</div>
																											)}
																										</div>
																									}
																									{ sess.equipmentList.length > 0 && 
																										<div>
																											<div className="top_info_title txt_center">Equipment List</div>
																											<div className="one_shopping_list">
																												<div className="one_equ txt_left">Items</div>
																												<div className="one_equ txt_right">Quantity</div>
																											</div>
																											{sess.equipmentList.map((equ, ind)=>
																												<div className="one_shopping_list" key={ind}>
																													<div className="one_equ txt_left">{equ.equipment_name}</div>
																													<div className="one_equ txt_right">{equ.quantity}</div>
																												</div>
																											)}
																										</div>
																									}
																								</div>
																							</div>	
																						</div>
																					</div>
																				</div>
																				<div className="for_btns_sess">
																						{ sess.singedUpUsers != sess.maxAttendee && sess.sessionStatus != 'started' && sess.sessionStatus != 'halfhourless' && 
																							<button type="button" className="done mt-3 rose_clr" onClick={() => this.openURL(sess.sessionLink)}>
																								{ sess.alreadyMember ?
																									<span>Signed Up</span>
																									:
																									<span>Sign Up</span>
																								}
																							</button>
																						}
																						{ !sess.alreadyMember && sess.singedUpUsers != sess.maxAttendee && (sess.sessionStatus == 'started' || sess.sessionStatus == 'halfhourless') && 
																							<button type="button" className="done mt-3 rose_clr" onClick={() => this.openURL(sess.sessionLink)}>Join</button>
																						}
																						{ sess.alreadyMember && (sess.sessionStatus == 'started' || sess.sessionStatus == 'halfhourless') && 
																							<button type="button" className="done mt-3 rose_clr" onClick={() => this.openURL(sess.sessionLink, sess)}>Join</button>
																						}
																						{ !sess.alreadyMember && sess.singedUpUsers == sess.maxAttendee && 
																							<button type="button" className="done mt-3 rose_clr" onClick={() => this.openURL(sess.sessionLink)}>Full - Msg Instructor</button>
																						}
																				</div>
																			</div>
																		</div>
																	)}
																</div>
															}
														</div>
													}
												</div>
											}
										</div>
									)}
									</div>
									}
									<div>
										{this.state.weeksArray.length == 0 &&
											<div className="ondemand_form">
												<div className="channel_no_results">No results found</div>
											</div>
										}
									</div>
									<div>
										{ !this.state.loadingMore && this.state.hasMoreLoad &&
											<div className="dashboard_load_hold hold_new_text_align">
												<div className="custom_btn1 px-5 mt-3 load_more_main_button" onClick={(e) => this.loadMoreSessions()}>LOAD MORE</div>
											</div>
										}
										{ this.state.upcomingSessionCount > 0 && this.state.loadingMore && <div class="main_loader_inner mt20"></div> }
									</div>
								</div>
							</div>
						}
						
						{ this.state.sessionType  == 'SearchResult' && 
							<div className="channel_topscroll">
								<div className="channel_subscroll">
									<div className="cont_bg main_info_menu">
										<div className="row row_pd row_inline">
											<div className="one_info big_one_info">When</div>
											<div className="one_info">Interest</div>
											<div className="one_info">Channel Name</div>
											<div className="one_info">City,State Code</div>
											<div className="one_info">Instructor Name</div>
											<div className="one_info">Duration</div>
											<div className="one_info">Live/On-demand</div>
											<div className="one_info get_small">Members</div>
											<div className="one_info get_small">Not Members</div>
										</div>
									</div>
									{ this.state.weeksArray.map((week, k)=>
										<div className="cont_bg bord_main_part search_week_part" key={k}>
											{ this.state.mainChannelSessions && 
												<div>
													<div className="week_part">{week}</div>
													{ this.state.mainChannelSessions[week] && 
														<div> 
															{this.state.mainChannelSessions[week].map((sess, i)=>
																<div className="row row_pd row_inline" key={i}>
																	{ sess.sessionStatus != 'halfhourless' && 
																		<div className="one_info relative_padd big_one_info">{this.fixSessionDate(sess)}</div>
																	}
																	{ sess.sessionStatus == 'halfhourless' && 
																		<div className="one_info relative_padd big_one_info">
																			<Countdown date={Date.now() + ((new Date(sess.scheduleDate).getTime()-new Date().getTime()))} renderer={this.renderer2}></Countdown>
																		</div>
																	}
																	<div className="one_info relative_padd">{sess.interest}</div>
																	<div className="one_info relative_padd">{sess.channel_name}</div>
																	<div className="one_info relative_padd">{sess.city}, { sess.state_code }</div>
																	<div className="one_info relative_padd">{sess.firstName  + ' ' + sess.lastName}</div>
																	<div className="one_info relative_padd">{sess.duration} Minutes</div>
																	<div className="one_info relative_padd">{sess.onDemand == '1' ? 'On Demand' : 'Live'}</div>
																	<div className="one_info relative_padd get_small">${sess.chargeForSession}</div>
																	<div className="one_info relative_padd get_small">${sess.chargeForSessionNonMember}</div> 
																	<div className="info_btn" onClick={this.openMoreInfo}>
																		<div className={ "all_info " + ( sess.isOpenMoreInfo ? "active" : "" ) } onClick={this.preventDef}>
																			<div className="arrow_info"></div>
																				<div className="relative_over">
																				<div className="absolute_iks" onClick={this.openMoreInfo}>&#10005;</div>
																				<div className="top_info_title">Description:</div>
																				<div className="description_text">{sess.description}</div>
																				<div className="left_part bolded">Signed Up: {sess.singedUpUsers}</div>
																				<div className="right_part bolded">Max Allowed: {sess.maxAttendee}</div>
																				{ sess.shoppingList.length > 0 && 
																					<div>
																						<div className="top_info_title txt_center">Shopping List</div>
																						<div className="one_shopping_list">
																							<div className="one_shop bolded txt_left">Item Name</div>
																							<div className="one_shop bolded txt_center">Quantity</div>
																							<div className="one_shop bolded txt_right">Item Notes</div>
																						</div>
																						{sess.shoppingList.map((shop, ind)=>
																							<div className="one_shopping_list" key={ind}>
																								<div className="one_shop txt_left">{shop.link}</div>
																									<div className="one_shop txt_center">{shop.quantity}</div>
																								<div className="one_shop txt_right">{shop.item_note}</div>
																							</div>
																						)}
																					</div>
																				}
																				
																				{ sess.equipmentList.length > 0 && 
																					<div>
																						<div className="top_info_title txt_center">Equipment List</div>
																						<div className="one_shopping_list">
																							<div className="one_equ txt_left">Items</div>
																							<div className="one_equ txt_right">Quantity</div>
																						</div>
																						{sess.equipmentList.map((equ, ind)=>
																							<div className="one_shopping_list" key={ind}>
																								<div className="one_equ txt_left">{equ.equipment_name}</div>
																								<div className="one_equ txt_right">{equ.quantity}</div>
																							</div>
																						)}
																					</div>
																				}
																			</div>
																						
																					</div>
																				
																	</div>
																	<div className="one_info info_btn_small">
																		<div>
																			{ sess.subdomain && sess.subdomain != '' && 
																				<div className="rose_btn no_back" onClick={ () => this.openChannelInfo(sess.subdomain) } >View</div>
																			}
																			{/* sess.onDemand == '1' ? 
																					<div className="rose_btn no_back" onClick={ () => this.openLogin(sess.id, 'Sign Up', sess.duration, sess.scheduleDate, { firstName: sess.firstName, lastName: sess.lastName }, sess) } >View</div>
																				:
																					<div className="rose_btn no_back" onClick={ () => this.openLogin(sess.id, 'Join',  sess.duration, sess.scheduleDate,{ firstName: sess.firstName, lastName: sess.lastName }, sess) }>Join</div>	
																			*/} 
																		</div>
																	</div>
																</div>
															)}
														</div>
													}
												</div>
											}
										</div>
									)}
									
									<div>
										{ !this.state.loadingMore && this.state.hasMoreLoad &&
											<div className="dashboard_load_hold hold_new_text_align">
												<div className="custom_btn1 px-5 mt-3 load_more_main_button" onClick={(e) => this.loadMoreSessions()}>LOAD MORE</div>
											</div>
										}
										
										{ this.state.upcomingSessionCount > 0 && this.state.loadingMore && <div class="main_loader_inner mt20"></div> }
									</div>
								</div>
							</div>
						}
						
						{ this.state.sessionType  == 'On Demand' && this.state.mainDemandObject && this.state.mainDemandObject.allDemandSession &&
							<div>
								<div className={"ondemand_form" + (this.state.demandPreview === 'list' && !this.state.gridDemandView ? ' non_padd' : '')}>
									<OnDemandSessions
										state={this.state}
										openDemandDrops={this.openDemandDrops}
										openGridView={this.openGridView}
										closeGridView={this.closeGridView}
										openDemandSession={this.openDemandSession}
										openProfileInfo = {this.openProfileInfo}
										preventDef={this.preventDef}
										openNativeDemand={this.openNativeDemand}
									>
									</OnDemandSessions>
								</div>
								{ this.state.logedUser ?
									<div className="displ_none">
										<Login 
											state={this.state}
											closeLogin={this.closeLogin}
											updateStateFromChild={this.updateStateFromChild}
											updateStateFromChildSession={this.updateStateFromChildSession}
											showLoginAlert={this.showLoginAlert}
											closeLoginPop={this.closeLoginPop}
											>
										</Login>
									</div>
									:
									''
								}
							</div>
						}
						
					</div>
				</div>
			</div>
			}
			
			{ !this.state.isFoundChannel &&
				<div className="all_pg_info">
					<div className="width1px"></div>
					<div className="no_channel_info">No available Channel with this subdomain.</div>
				</div>
			}
			
			<div className="modal cal_modal mt-3" id="calenderModel" data-backdrop="">
			  <div className="modal-dialog d-md-flex d-block large_width1 mb-0">
				<div className="modal-content modalbg m-auto">
				  <div className="modal-header px-4 pt-3 pb-0">
					<h4 className="white modal-title">Choose Date</h4>
					<button type="button pr-3" className="close white closepopup" onClick={e=>$("#calenderModel").attr({'style':'display:none'})}>&times;</button>
				  </div>
				  <div className="modal-body modal-body px-1 px-sm-3">
					<h3>Calender</h3>
					<Calendar
					   onChange={this.setTimeCalendar}
					   value={this.state.sessionCalender}
					 />
				  </div>
				</div>
			  </div>
			</div>
			
			<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Coming Soon</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="email_verification" aria-modal="true">
				<div className="modal-dialog px-2 dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
							<p className="py-4 text-light text-center font-20">A verification code has been sent to your email address. Please enter the code on the following screen after pressing "OK"</p>
						</div> 
						<div className="text-center">
						  <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#email_verification").attr({'style':'display:none'});}}>ok</button>
						</div>                       
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="registration_popup" aria-modal="true">
				<div className="modal-dialog px-2 dialogwidth modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
						  <h4 className="modal-title font-weight-bold white text-center">Welcome To Virdio</h4>
						  <p className="pt-4 text-white text-center font-22">Your sign up was successful.</p>
						  <p className="pb-4 text-light text-center font-20"> A mail is also sent to your inbox to let you check if your preferred device/system is ready for participating in the session.</p>

						  
						  </div>
						<div className="text-center">
						  <button type="button" onClick={this.reloadPg} className="custom_btn1 mt-3">ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className={ "main_pop_sign " + ( this.state.isLoginOpen ? "active" : "" ) }>
				<div className="for_main_form centered_pop">
					<div>
						{ !this.state.isOpenLoginAll && 
							<div>
								<div className="col-12 main_login_logos">
									<img src="/images/login-logo.png" className="login-logo" />
									<p className="login-tagline">Login and Join a Virtual Studio</p>
								 </div>
								<div className="gray-box-4 px-4 pt-5 pb-4 input_bx-cont for_sign relative">
									<button type="button" className="close white closepurple translatX-Y abs_close" onClick={this.closeLogin}><span>x</span></button>
									<div className="form-group">
										<label className="label">Email Address<span className="inp_cover-border"></span></label>
										<input type="email" 
										className={(this.state.errorClass_Email  ?"input-field red-outline":"input-field")} 
										value={this.state.email} id ='email'
										ref={mail=> (this._email = mail)} 
										onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_Email:false})} />
										{this.emailValidate.message('email', this.state.email, 'required|email')}
										<span className="form_email deny_grind">
											 <div>
												 <svg width="27px" height="27px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 46.000000 46.000000">
													<g transform="translate(0.000000,46.000000) scale(0.100000,-0.100000)"
														fill={this.state.buttonsColorPick} stroke="none">
														<path d="M16 384 c-13 -12 -16 -41 -16 -148 0 -89 4 -136 12 -144 17 -17 419 -17 436 0 8 8 12 55 12 144 0 107 -3 136 -16 148 -23 24 -405 24 -428 0z m314 -99 c-47 -47 -92 -85 -100 -85 -8 0 -53 38 -100 85 l-85 85 185 0 185 0 -85 -85z m-243 -102 l-57 -58 0 115 0 115 57 -58 58 -57 -58 -57z m343 55 l0 -113 -57 57 -58 58 55 55 c30 30 56 55 57 55 2 0 3 -51 3 -112z m-200 -68 c8 0 26 11 40 25 l26 25 59 -60 59 -60 -184 0 -184 0 59 60 59 60 26 -25 c14 -14 32 -25 40 -25z"/>
													</g>
												</svg>
											</div>
										</span>
									</div>
								</div>
								<div className="text-center">
									<button type="button" className="save-btn btn btn-primary mt-3 font-book mx-auto font-18" onClick={this.validateEmailMethod}>Continue</button>
								</div>
							</div>
						}
					</div>
					<div> 
						{ this.state.isOpenLoginAll && 
							<Login 
								state={this.state}
								closeLogin={this.closeLogin}
								updateStateFromChild={this.updateStateFromChild}
								updateStateFromChildSession={this.updateStateFromChildSession}
								showLoginAlert={this.showLoginAlert}
								closeLoginPop={this.closeLoginPop}
								>
							</Login>
						}
					</div>
				</div>
			</div>
			
			
			<div className={ "main_pop_sign " + ( this.state.isOpenSign ? "active" : "" ) }>
				<div className="text-center"><img src="/images/participate.png" alt="host" className="w-60" /></div>
				<h4 className="white text-center pb-2 font-20 sign_main_text">Signing up as a Member</h4>
				<MainSignUp 
					state={this.state}
					closeSign={this.closeSign}
					onChange={this.onChange}
					resend={this.resend}
					submitHost={this.submitHost}
					updateRefState={this.updateRefState}
					updateStateFromChild={this.updateStateFromChild}
					verify={this.verify}
					>
				</MainSignUp>
			</div>
			
			<div id="login-alert" className="modal" data-backdrop="static" data-keyboard="false">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p id="login-alert-text" className="mb-0 py-4 white text-center"></p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={this.closePopup}>ok</button></div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0 zi9999" id="error_msg_register" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
							<p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
						</div>
						<div className="text-center">
							<button type="button" 
							className="custom_btn1 mt-3" 
							data-dismiss="modal" 
							onClick={e=>{ $("#error_msg_register").attr({'style':'display:none'}); }}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			 <div className="modal pr-0" id="error_msg" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
							<p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
						</div>
						<div className="text-center">
							<button type="button" 
							className="custom_btn1 mt-3" 
							data-dismiss="modal" 
							onClick={e=>{
							$("#error_msg").attr({'style':'display:none'});
							// alert(!this.validator.fieldValid('firstname'))
							if(!this.validator.fieldValid('firstname')){
							if(!this.validator.getErrorMessages().firstName){
							this.setState({
							errorClass_FirstName:false
							})
							}
							else{
							this.setState({
							errorClass_FirstName:true
							})
							}
							this._firstName.focus();
							}
							if(!this.validator.fieldValid('lastName')){
							this.setState({
							errorClass_LastName:true
							})
							this._lastName.focus();
							}
							if(!this.validator.fieldValid('email') ){
							this.setState({
							errorClass_Email:true
							})
							this._email.focus();
							}
							if(!this.validator.fieldValid('password') || this.state.msg==="Password mismatch!"){
							this.setState({
							errorClass_Password:true
							})
							this._password.focus();
							}
							if(!this.validator.fieldValid('rePassword')){
							this.setState({
							errorClass_RePassword:true
							})
							this._rePassword.focus();
							}
							}}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="session_alert_new" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center font-20">Your class is ready to Join.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							{
								this.state.urlElectron ?
								<div className=""><button className="custom_btn1 mt-3 mx-2" onClick={this.closeLoginNative}>join</button></div>
								:
								<a href ={this.state.urlPath} target="_blank"><div className=""><button className="custom_btn1 mt-3 mx-2" onClick={this.closeLogin}>join</button></div></a>
							}
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0" id="existingUserModel" aria-modal="true" >
				<div className="modal-dialog dialogwidth mx-auto px-2 modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body modl_bg_drk shadow-none px-4 pb-4 radius-8">
						  
						  { this.state.hostBy.firstName && 
							 <p className="py-4 text-light text-center font-20">
							 {this.state.hostBy.firstName + " has set this class to be for channel members only and your name is not showing up on the member list. If you feel this is a mistake, please contact " +this.state.hostBy.firstName+" and have them add you to their member list and try again."}
							  </p> 	
						  }
						 
						</div>
						<div className="text-center">
						<button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#existingUserModel").attr({'style':'display:none'});}}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="memberExpired" aria-modal="true" >
				<div className="modal-dialog dialogwidth mx-auto px-2 modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body modl_bg_drk shadow-none px-4 pb-4 radius-8">
						  { this.state.hostBy.firstName && 
							 <p className="py-4 text-light text-center font-20">
								{this.state.hostBy.firstName + " has set this class to be for channel members only and your membership is expired. If you feel this is a mistake, please contact " +this.state.hostBy.firstName+"."}
							 </p> 	
						  }
						</div>
						<div className="text-center">
						<button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#memberExpired").attr({'style':'display:none'});}}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="email_sent" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center font-20">Instructor has been notified that class is full and you tried to sign up</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<button className="custom_btn1 mt-3 mx-2" onClick={this.closeEmail}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="exist_session" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center font-20">Hi {this.state.loginUserName} you are already signed up for this class.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<button className="custom_btn1 mt-3 mx-2" onClick={e=>$("#exist_session").attr({'style':'display:none'})}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
			<div className="modal pr-0" id="openondemand" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center font-20">You are about to open On-Demand Class view.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
							{
								this.state.electronlink ?
								<a href="#" onClick={e=> {  window.$('#openondemand').modal('hide'); window.$('#autologinnative.auto_open_channel').modal('show'); }}><button className="custom_btn1 mt-3 mx-2">NEXT</button></a>
								:
								<a href={this.state.ondemandlink}><button className="custom_btn1 mt-3 mx-2">NEXT</button></a>
							}
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal" id="chargePopup" aria-modal="true">
				<div className="for_main_form centered_pop">
				<div>
					<div className="gray-box-4 px-4 pt-5 pb-4 input_bx-cont for_sign relative back_cl2a">
						<button type="button" className="close white closepurple translatX-Y abs_close" onClick={e=>$("#chargePopup").attr({'style':'display:none'})}><span>x</span></button>
						{ this.state.sessionsTotal <= 1 && 
							<p className="py-4 text-light text-center font-20">{this.state.sessionHostFirstName} {this.state.sessionHostLastName} is charging ${this.state.chargeForSession} for the class. A Credit Card is required to hold on your spot, which will be charged at the start of the class. You can cancel up to 4 hours prior to the start of the class.</p>
						} 
						{ this.state.sessionsTotal > 1 && 
							<p className="py-4 text-light text-center font-20">{this.state.sessionHostFirstName} {this.state.sessionHostLastName} is charging ${this.state.chargeForSession}/class, which comes to ${this.state.finalChargeForSession} for the {this.state.sessionsTotal} classes you have selected. A Credit Card is required to hold your spot, which will be charged at the start of each class. You can cancel up to 4 hours prior to the start of a class.</p>
						}
					</div>
					<div className="text-center">
						<button type="button" className="save-btn btn btn-primary mt-3 font-book mx-auto font-18" onClick={this.continuePay}>Continue</button>
					</div>
				</div>
				</div>
				
			</div> 
		
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="modal over_payment_modal" id="sessionPay" aria-modal="true">
				<div className="for_main_form centered_pop">
					<ChargingSession state={this.state} updateStateChanelFromChildSession={this.updateStateFromChild}></ChargingSession>
				</div>
			</div>
			
			<div className="modal pr-0 auto_open_channel" id="autologinnative" aria-modal="true">
				<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="join_pop_container">
							<button type="button" className="close white closepurple join_close_iks" data-dismiss="modal"><span>x</span></button>
							{ this.state.showWindowsApp && this.state.urlElectron !== '' &&
								<div className="join_parent">
									<div className="join_title">Virdio recommends the Virdio App for the best experience</div>
									<div className="button_join_container">
										{ mobileTab && ios ?
											<a href={this.state.urlElectron} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); this.updateUserDemand(); }}>VIRDIO APP</div></a>
											:
											<div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); this.updateUserDemand(); }}>VIRDIO APP</div>
										}
									</div>
									<div className="browser_run">
										{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank"><span className="run_web" onClick={() => this.state.urlPath.indexOf('intent') > -1 || this.state.urlPath.indexOf('auth/') > -1 ? this.hideNativePop(true) : this.hideNativePop()}>Run in the browser instead</span></a> }
									</div>
								</div>
							}
							{ !this.state.showWindowsApp || this.state.urlElectron === '' &&
								<div className="join_parent">
									<div className="join_title">Virdio recommends Web browser for the best experience</div>
									<div className="button_join_container">
										 <a href={this.state.urlPath} target="_blank"><div className="join_pop_btn" onClick={() => this.state.urlPath.indexOf('intent') > -1 || this.state.urlPath.indexOf('auth/') > -1 ? this.hideNativePop(true) : this.hideNativePop()}>Run in the browser</div></a>
									</div>
									<div className="browser_run"></div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
			
			<div className="download_back_container auto_open_channel" id="nativedownload">     
				<div className="download_content">
					<div className="down_close" onClick={e=> { $("#nativedownload.auto_open_channel").hide(); }}></div>
					<div className="for_down_logo">
						<div className="download_logo"></div>
					</div>
					<div className="download_text">Download the Virdio App to install on your device.</div>                
					<div className="download_text">Once downloaded, double click on the installer then drag and drop the Virdio icon into the Applications folder.</div>                
					<div className="for_down_btn">
						<a href={this.state.appDownload} target="_blank"><div className="down_btn" onClick={e=> { $("#nativedownload.auto_open_channel").hide(); window.$("#nativedownload2.auto_open_channel").modal('show') }}>Download</div></a>
					</div>
				</div>
			</div> 
			
			<div className="modal pr-0 auto_open_channel" id="nativedownload2" aria-modal="true">
				<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
							{ ( chrome || edge ) && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible on the bottom left of the browser, click on the file to Install Virdio.</p> }
							{ !chrome && !edge && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible at the top right of the browser, click on the file to Install Virdio.</p> }
							<p className="mb-0 py-4 white text-center">2. After the Installation is complete, click on the JOIN button below.</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 col-4 mt-3" onClick={() => { this.openNativeUrl(this.state.urlElectron); window.$("#nativedownload2").modal('hide') }}>JOIN</button></div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0 auto_open_channel" id="profileInfoModal" aria-modal="true">
				<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<div className="profile_info_name new_text_prof">Hosted By</div>
							<div>
								<div className="profile_info_holder">
									<div className="profile_picture_hold prof_pic_mob_width" style={{ backgroundImage: this.state.userImageProfile ? "url(" + this.state.userImageProfile + ")" : 'url(/images/host-user.png)' }}></div>
								</div>
								<div className="profile_info_holder info_hold_width">
									<div className="profile_info_name">{this.state.userFullName}</div>
									<div className="settings_subject_content settings_wrap_content">
										<div className="profile_info_name profile_text" dangerouslySetInnerHTML={{__html: this.state.userInstructorProfileText}}></div>
									</div>
								</div>
							</div>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 col-4 mt-3" onClick={() => {window.$("#profileInfoModal").modal('hide') }}>Close</button></div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="waiverAgree" aria-modal="true">
			<div className="modal-dialog px-2 dialogwidth modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
					  <div className="d-flex justify-content-end">
						<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>{window.$("#waiverAgree").modal('hide');}}><span>x</span></button>
					  </div>
					  <h4 className="modal-title font-weight-bold white text-center">Waiver</h4>
					  <p className="pt-4 text-white text-center font-22">Before joinnig the class, you need to agree our conditions</p>
						<div className="relative">
							<iframe className="waiver_iframe" title="Virdio Waiver"></iframe>
							<div className="show_loader_iframe">
								<div className="main_loader_inner"></div>
							</div>
						</div>
					  </div>
					<div className="text-center">
						<button type="button" onClick={() => {this.checkIfUserHasWaiver(this.state.userMainId, true, 'login')}} className="custom_btn1 mt-3">AGREE</button>
					</div>
				</div>
			</div>
		</div>
		<div className="modal pr-0" id="user_not_verified2" aria-modal="true">
			<div className="modal-dialog mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent inline_pop">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
						<p className="mb-0 py-4 white text-center font-20">Your username is already registered, but has not been verified. To complete your registration please select OK and you will be guided through the process.</p>
					</div>
					<div className="text-center flex-wrap d-flex justify-content-center">
						<div className="">
							<div className="main_link" ><button className="custom_btn1 mt-3 mx-2" onClick={() => { this.closePopNotVerified()}}>OK</button></div>
						</div>
					</div>
				</div>
			</div>
		</div>
			
		{ this.state.userSignedUp &&
			<div className="full_popup">
				<ParticipantSignedUp 
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					openNativeUrl={this.openNativeUrl}
					sessionStartTimeHigher15Min={this.state.sessionStartTimeHigher15Min}
					urlElectron={this.state.urlElectron}
					urlPath={this.state.urlPath}
					scheduleDate={this.state.sessionScheduleDate}
					timeZone={this.state.sessionTimezone}
					sessionChannelName={this.state.sessionChannelName}
					isHost={this.state.isHost}
					sessionName={this.state.sessionName}
					isOnDemandSession={this.state.isOnDemandSession}
					onDemandId={this.state.demandSessionId}
					>
				</ParticipantSignedUp>
			</div>
		}
		
		<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		
		</div>
		
		
		
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(Channel);