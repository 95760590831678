
import React, { Component } from "react";
import axios from "axios";
import uniqueId from 'lodash/uniqueId';
import Sortable from 'react-sortablejs';
import ReactLightCalendar from '@lls/react-light-calendar'
import '@lls/react-light-calendar/dist/index.css'
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router';
import TimePicker from 'react-time-picker';
import moment from 'moment';
import moment1 from 'moment-timezone';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import Calendar from 'react-calendar';
import $ from 'jquery';
import {  browserHistory} from 'react-router'
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import Header from './Header';
import SessionFields from '../session/SessionFields';
import SessionScript from '../session/SessionScript';
import SessionWineSession from '../session/SessionWineSession';
import SessionShopingList from '../session/SessionShopingList';
import SessionEquipmentList from '../session/SessionEquipmentList';
import SessionSettings from '../session/SessionSettings';
import SessionCharges from '../session/SessionCharges';
import SessionPlaylistAndHost from '../session/SessionPlaylistAndHost';
import SessionFeedFm from '../home/SessionFeedFm';
import ReplayClasses from '../home/ReplayClasses';
import { browserName as browName} from "react-device-detect";

import { togglePlaylistPopup } from '../../actions/popups';
import PlaylistPopup from './PlaylistPopup';
import MainTutorial from '../home/MainTutorial';
import { showMainErrorPop } from '../../actions/popups';
import { deleteCookie } from '../auth/Logout';
import { updatesse } from '../../util/updatesse';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import { userPermissions } from '../../util/userPermissions';
import SupportContainer from '../home/SupportContainer';

//import DateTimeField from "react-bootstrap-datetimepicker";
const override = css`
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  border: 4px solid;
  border-top-color: #bd00ff;
  border-right-color: #bd00ff;
  border-bottom-color: transparent;
  border-left-color: #bd00ff;
`;
const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',special:'@',hour:'numeric',minute:'numeric'};
const DATE_OPTIONS_CUSTOM = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
class SessionCreation extends Component {
  
  constructor(props) {
    super(props);
    const date = new Date();
    const startDate = date.getTime();
	this.sessionFieldsRef = React.createRef();
	
	var plus5 = new Date();
	 plus5.setMinutes(plus5.getMinutes()+5);
	 
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
    this.state = {
        sessions: [],
        hostList:[],
        equipmentList:[],
        // equipmentList:[{id: 1, interestId: 2, name: "trademill", equipment_description: "This is running equipments", status: 1,type:false,Quantity:0,Link:'X'},
        // {id: 2, interestId: 2, name: "bench", equipment_description: "This is", status: 1,type:false,Quantity:0,Link:'X'},
        // {id: 3, interestId: 2, name: "weight-lift", equipment_description: "This is weight lift", status: 1,type:false,Quantity:0,Link:'X'},
        // {id: 4, interestId: 2, name: "ball", equipment_description: "This is using to fit body", status: 1,type:false,Quantity:0,Link:'X'}],
        shoppingList:[],
        activityType:[],
        session_details:'',
        session_image: '',
        send_input:'',
        // time: new Date (date.getTime()).getHours()+':'+new Date (date.getTime()).getMinutes(),
         time2: moment(plus5).format('HH:mm'),
        time: '00:00',
       // time2: '00:00',
        msg:'',
        sessionCalender: new Date(),
        reminderCalender: new Date(),
        recurEndCalender:new Date(),
        urlLink:'',
        sess_name:'',
        sess_time:'',
        uname:'',
        //////////Calender /////////////
        startDate, // Today
        endDate: '', // Today + 6 days
        dateFormat : '',
        singUpEndDate:'',
        cutoffStartDate:date.getTime(),
        repeatSession:false,
        errorClass_Description:false,
		errorClass_Duration: false,
        errorClass_MaximumParticipant:false,
        errorClass_MinimumParticipant:false,
        errorClass_Datevalidatior:false,
        errorClass_SessionDetail:false,
        cutoffEndDate:'',
        cutoffDateTime:'',
        whenTime:'',
        descriptionValidation:false,
        sessionMonth:'',
        sessionYear:'',
        sessionDay:'',
        sessionTime:'',
        demandWine:false,
        sessionHour:0,
        sessionMinute:30,
        sessionAttribute:[],
        sessionClass:[false,false,false,false,false,false,false,false,false,false],
        signUpSessionStatus:false,
        signUpRepeatSession:[],
        signUpClass:[false,false,false,false,false,false,false,false,false,false],
        reminderSessionTime:'',
        reminderMonth:'',
        reminderYear:'',
        reminderDay:'',
        reminderTime:'',
        localTimeZone:Intl.DateTimeFormat().resolvedOptions().timeZone,
        //////////header state////////
        totalViews : '',
        weeklyAttendance:'',
        totalRevenue:'',
        messageCount:'',
        ///////////session info state ////////////////
        sessionName:'',
        when:'',
        phoneNumber:'',
        description:'',
        minimumParticipants:1,
        isChecked:'',
        maximumParticipants:6,
        min_participants : '',
        max_participants :'',
        sessionAmount:'',
        exampleFormControlSelect2:'Pick a Duration',
        exampleFormControlSelect1 : 'Beginner',
        heartRateMonitor:true,
        zoneTracking : true,
        searchParticipant: false,
        sessionProperty:false,
        sessionCharge:false,
        disableParticipant:false,
        allowParticipant:false,
        showParticipant:false,
        activityHour:0,
        activityminutes:0,
        amountCharge: '',
        orderWine:false,
        onDemand:false,
        hostSessionStart:'',
        participantSessionStart:'',
        minimumNotMet: '',
        signUpDateTime:'',
        /////////////Group Location//////////
        allowLocation:false,
        ////////////Script////////////
        scriptHeartRateMonitor : false,
        scriptZoneTracking:false,
        ////////////////Activity table row//////////////////////////
        ActivityName: '',
        ActivityDisplayName: '',
        ActivityNotes: '',
        ActivityLengthAvailable: 30,
        ActivityLengthLeft: 30,
        ActivityDisplayLengthLeft: 30,
        ActivityDisplayLengthLeftEdit: 30,
        ActivityType: 'Aerobic',
        DurationType:'',
        Count:'',
		repsSeconds: 0,
        Video:'',
        VideoRepeat:'',
        VideoDuration:'',
        TargetBPM:'',
        TargetZone:'',
        whenSessionTime:'',
        errormsg:'',
        editActivityToken:-1,
        allowSS:true,
        mon:false,tue:false,wed:false,thu:false,fri:false,sat:false,sun:false,endRecrwhen:'',
        weekDaysData:[],
        buttonText:'Copy Url',
        tablerows:[],
        ///////////////Equipment List
        selectedOption: null,
        selected: {},
        equipmentName : '',
        equipmentQunatity:'',
        equipmentArray : [],
        quantityValue:{},
       // equipmentList : [{ name: "Tom",type:false,Quantity:0,Link:'X' },{ name: "Tommy",type:false,Quantity:0,Link:'X' }],
      //  @ak commeted was removed
        hostList2:[],
        hostName:'',
        equipmentList1 : [],
        duplicateList:[],
        addToequipmentList1 : [],
        searchEquipment: "",
       // shoppingList : [{ itemName: "Tom",type:false,Quantity:0,itemNote:"X" ,Link :"addLink"},{ itemName: "Tommy",type:false,Quantity:0,itemNote:"X" ,Link :"addLink"}],
        shoppingList1:[],
        duplicateShoppingList: [],
        shoppingListValue: "",
        validateList:'',
       // shareholders: [{ name: "" },{ name: "" },{ name: "" },{ name: "" }],
       shareholders: [{ name: "",type:false,Quantity:"1" },{ name: "",type:false,Quantity:"1" }],
        euipmentCheckBox : [false,false],
        loginName:'',
        responseMessage:'',
        automatic:true,
        manual:false,
        loading: false,
        descriptionStatus:'',
		selectedPlaylist: [],
		selectedPlaylist_id: -1,
        //////////////////////////
		shopQuantity: 1,
		itemNameShop: '',
		itemNotesShop: '',
		itemNotesPrice: 0,
		totalPrice: 0,
		addedShopList: [],
		editShopIndex: -1,
		editEquIndex: -1,
		openedList: -1,
		openedMainIndex: -1,
		wineProduct:[],
		emojiesList:[],
		tablerows:[],
        tablerows1:[],
        tablerows3:[],
		wineProduct:[],
		wineInfoArray: [],
		emojiForWineProduct:0,
		groupId:0,
		sessionPublicChannel: true,
		recordOfferDemand: true,
		sessionSearchable: true,
		manualAutomaticScript: true,
		showEquipment: false,
		showHostParticipant: false,
		showOtherParticipants: false,
		showShoppingList: false,
		startRecording: false,
		startMusic: false,
		chosenWineShop: '',
		chosenWinePrice: 0,
		chooseWine: true,
		wineObjectToPush: [{}],
		wineObjectToEmojis: {},
		mainWineArray: [],
		mainWineArrayNames: [],
		editWineIndex: -1,
		saveind: -1,
		liveSessionMemberCharges: 0,
		liveSessionNonMemberCharges: 0,
		onDemandMemberCharges: '',
		onDemandNonMemberCharges: '',
		chosenWineShop: '',
		hostFirstName: '',
		cardsArray: [],
		equipmentListJSON: [],
		equipmentListMain: [],
		equipmentListMainNames: [],
		chosenEquipmentName: '',
		defaultReps: [{'repeat': 0, 'second': 0, 'nameSelect': '-'}, {'repeat': 4, 'second': 10, 'nameSelect': '4 repeats 10 seconds'}, {'repeat': 8, 'second': 20, 'nameSelect': '8 repeats 20 seconds'}, {'repeat': 12, 'second': 30, 'nameSelect': '12 repeats 30 seconds'}, {'repeat': 15, 'second': 50, 'nameSelect': '15 repeats 50 seconds'}],
		lowResolutions: ['Low Resolutions', '1280×960','1024×768','960×720','800×600','640×480'],
		highResolutions: ['High Definition', 'Standard Definition', 'Low Definition'],
		highDefSelect: 'Standard Definition',
		selectedHighDef: '',
		selectedLowDef: '',
		chosenTutorialIndex: 0,
		showTutorial: false,
		tutorialToShow: '',
		channelSettings: {},
		recordAndOffer: false,
		VideoName: '',
		activityTotalTime: 0,
		activityTotalTimeSeconds: 0,
		activityRemainingTime: 0,
		activityRemainingTime2: 0,
		sessionListMain: true,
		templateList: false,
		templateWhaitForConfirm: false,
		searchBy: '',
		page_count: 1,
		showLoadMore: false,
		shownLength: 0,
		dataLength: 0,
		isAdmin: 0,
		saveAsTemplate: 0,
		repsDefaultTime: '',
		mainFaqs: [],
		repsDefaultTime: '',
		showIfTemplate: 0,
		creationMenu: 'Session Info',
		equipmentEditObject: {},
		equipmentEditName: '',
		equipmentEditQuantity: 1,
		equipmentEditNotes: '',
		ActivityNameEdit: '',
		ActivityDisplayNameEdit: '',
		ActivityNotesEdit: '',
		ActivityTypeEdit: '',
		DurationTypeEdit: '',
		CountEdit: 0,
		repsSecondsEdit: 0,
		RepeatEdit: '',
		activityHourEdit: 0,
		activityminutesEdit: 0,
		activityHourEditBackup: 0,
		activityminutesEditBackup: 0,
		VideoEdit: '',
		VideoRepeatEdit: false,
		isLoadingSessions: false,
		copyFromWorkout: false,
		parentRecrId: 0,
		allowPartMove: false,
		usingMarkup: false,
		calculateAssessmentDimensions: false,
		haveActivePlan: true,
		latestRecurringSessionEndDate: '',
		recurringArray: [],
		editRecurringSession: false,
		haveActivePlan: true,
		pendingHostSeconds: 30,
		hostRequestingEdit: '',
		isSessionRecurring: 0,
		disableNotificationSound: false,
		supportPageName: 'Create Session',
		supportPageApi: '/FitnessSessionCreation',
		userTypeFaqs: 2,
		timezonesArray: ['ADT', 'AKDT', 'BST', 'CDT', 'CEST', 'CET', 'CST', 'EDT', 'EEST', 'EET', 'EST', 'GMT', 'HDT', 'HST', 'IST', 'MDT', 'MSK', 'MST', 'NDT', 'PDT', 'WEST'],
		timezoneCityArray: ['America/Goose_Bay', 'America/Anchorage', 'Europe/London', 'America/Chicago', 'Europe/Belgrade', 'Africa/Tunis', 'America/Belize', 'America/New_York', 'Europe/Athens', 'Europe/Kaliningrad', 'America/Cancun', 'Greenwich', 'America/Adak', 'US/Hawaii', 'Asia/Calcutta', 'America/Denver', 'Europe/Moscow', 'America/Phoenix', 'America/St_Johns', 'America/Los_Angeles', 'Europe/Lisbon'],
		chosenTimezone: moment1().tz(moment1.tz.guess()).format('z'),
		chosenTimezoneCity: 'America/New_York',
		hoursArray: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
		minutesArray: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', 
						'11', '12', '13', '14', '15', '16', '17', '18', '19', '20', 
						'21', '22', '23', '24', '25', '26', '27', '28', '29', '30', 
						'31', '32', '33', '34', '35', '36', '37', '38', '39', '40', 
						'41', '42', '43', '44', '45', '46', '47', '48', '49', '50', 
						'51', '52', '53', '54', '55', '56', '57', '58', '59'],
		ampmArray: ['AM', 'PM'],
		sessionHours: '01',
		sessionMinutes: '00',
		sessionAMPM: 'AM',
		deviceName: '',
		showVirtual: 0,
		playlistId: '',
		remainingReccurings: 0,
		firstActiveRecurringSessionStartDate: '',
		recurStartCalender: new Date(),
		firstScheduleDateWithoutTime: '',
		chosenRecurringDays: '',
		endRecrwhenTemp: '',
		cancelSessionsMsg1: '',
		cancelSessionsMsg2: '',
		cancelSessionsMsg3: '',
		recurringByHostLeft: 0,
		sessionHostFirstName: '',
		fmStations: [],
		chosenFMStation: "",
		chosenFMStationEdit: "",
		sessionFmTimes: [],
		sessionFmStations: [],
		isMarketplaceChannel: false,
		marketplaceStatus: 0,
		isInReview: false,
		replayClasses: [],
		pickedReplayId: 0,
		isOpenReplay: false,
		userPermissions: {},
		showSaveBtn: false,
		durationNums: [],
		virdioMusic: [],
		chosenMusic: "",
		fmMenu: "During Class",
		notesNumAvailable: 200,
		noteNumLeft: 200,
		nameNumAvailable: 30,
		nameNumLeft: 30,
		scriptDurationErrorShowed: false,
		replayName: "",
		noteEditNumLeft: 200,
		nameEditNumLeft: 30,
		workoutSoundStart: "None",
		workoutSoundEnd: "None",
		showPersonalBest: "For all Classes",
		showPersonalBestOptions: ["For all Classes", "Only for this Class"],
		workoutStartOptions: ["None", "Bell sound", "Ding", "Go", "All done"],
		workoutEndOptions: ["None", "Bell sound", "Ding", "Go", "3, 2, 1", "All done",  "3, 2, 1 - No sound"],
		showReviewMessageBefore: false,
		marketPlaceEditStatus: false,
		editReplayClass: false,
		activityPauseTotalTimeMin: 0,
		activityPauseTotalTimeSec: 0,
		activityDurationTotalMin: 0,
		activityDurationTotalSec: 0,
		rememberUpdateAll: false,
		channelData: [],
		expandNewIntrest: false,
		newInstrestSelected: [],
		selectedinterestsNames: '',
		expandNewIntrestSubcategories: false,
		channelInterestSelection:[],
		channelInterestSelectionNew: [],
		expandLiveIntrestSubcategories: false,
		liveInterestSubcategories: [],
		liveSelectedInterestsSubcategoriesNames: '',
		channelLiveSubcategoriesSelection: [],
		channelInterestSubcategories: [],
		changePlace: 0,
		changePlaceNew: '',
		finalClassDurationMinutes: 0
    }
	
    this.validator = new SimpleReactValidator();
    this.shoppingValidator = new SimpleReactValidator();
    this.equipmentValidator = new SimpleReactValidator();
    this.scriptValidate = new SimpleReactValidator();   
    this.endDateValidate = new SimpleReactValidator();
	this.deleteCookie = deleteCookie.bind(this);	
	this.updatesse = updatesse.bind(this);
	this.getSessionDateUtil = getSessionDate.bind(this);
	this.getSessionTimeUtil = getSessionTime.bind(this);
	this.userPermissions = userPermissions.bind(this);
	window['onbeforeunload'] = this.onbeforeunload;
}


componentDidUpdate(prevProps, prevState){
	if(this.state.templateWhaitForConfirm && (this.props.openedTemplateSave === null || this.props.openedTemplateSave === '' ))
		{
			this.setState({templateWhaitForConfirm: false}, () => {
				window.location.href = '/SessionTemplates ';
			});
			
		}
}

 
componentDidMount(prevProps){
	window['moment1'] = moment1;
  
  if(window.location.hash == '#fromTemplate')
	{
		this.setState({showIfTemplate: 1, supportPageName: 'Create Template'})
	}
	
 if (window.location.hash == '#marketPlaceEdit')
	{
		this.setState({
			marketPlaceEditStatus: true,
		})
	}
  //  console.log(prevProps);
  window.scrollTo(0, 0);
  if(localStorage.getItem('userData')){
	let groupId = parseInt(localStorage.getItem('groupId')); 

  if ( localStorage.getItem('hostNameEditSession'))
	{
		this.setState({
			hostName: localStorage.getItem('hostNameEditSession')
		});
	}
  let hostNameEditSession = localStorage.getItem('hostNameEditSession');
  localStorage.removeItem('hostNameEditSession');
  this.fetchExistingHostList(hostNameEditSession);
  /* this.fetchExistingEquipments();
  this.fetchExistingShopping();
  this.fetchActivityType(); */
  if ( groupId == 2 ) { this.ProductEmojiList(); }
  
	if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 0 )
		{
		  if ( localStorage.getItem('is_admin') && JSON.parse(localStorage.getItem('is_admin')) === 1 ) 
			{ 
				this.setState({isAdmin: 1},
					()=>{
							if( this.props.params.id )
								{
									this.onSessionChanged();
								}
						}) 
			}
		  else if( this.props.params.id )
			{
				this.onSessionChanged();
			}
		}
	else
		{
			this.setState({isAdmin: 1},
			()=>{
					if( this.props.params.id )
						{
							this.onSessionChanged();
						}
				}) 
		}
	
  //console.log(this.validator.getErrorMessages().description,this.state.description,!this.state.description);
  
  
  var newactivitytype=[{id: 1, interestId: 2, activity_type: "Aerobic",  status: 1},{id: 2, interestId: 2, activity_type: "Balance",  status: 1},{id: 3, interestId: 2, activity_type: "Cardio",  status: 1}, {id: 4, interestId: 2, activity_type: "Flexibility", status: 1},{id: 5, interestId: 2, activity_type: "Rest", status: 1},{id: 6, interestId: 2, activity_type: "Strength", status: 1},{id: 7, interestId: 2, activity_type: "Stretching", status: 1},{id: 8, interestId: 2, activity_type: "Warm Up", status: 1},{id: 9, interestId: 2, activity_type: "Mobility", status: 1},{id: 10, interestId: 2, activity_type: "Core", status: 1},{id: 11, interestId: 2, activity_type: "Foam Rolling", status: 1},{id: 12, interestId: 2, activity_type: "Pause", status: 1},{id: 13, interestId: 2, activity_type: "Virtual Equipment",  status: 1}/* ,{id: 14, interestId: 2, activity_type: "Virtual Assistant",  status: 1} */];
	if ( this.state.isMarketplaceChannel )
	{
		newactivitytype=[{id: 1, interestId: 2, activity_type: "Regular",  status: 1}, {id: 5, interestId: 2, activity_type: "Rest", status: 1}, {id: 12, interestId: 2, activity_type: "Pause", status: 1},{id: 13, interestId: 2, activity_type: "Virtual Equipment",  status: 1},{id: 14, interestId: 2, activity_type: "Pause Virtual",  status: 1}];
	}
  
  this.setState({
    activityType: newactivitytype,
    groupId: (localStorage.getItem("groupId") ? parseInt(localStorage.getItem("groupId")) : 0),
    loginName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName,
	Count: 10
   });
   
  if ( !this.props.params.id ) {
   
   axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/insertChannelSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel, "user_id" : JSON.parse(localStorage.getItem('userData')).data.responseData.id})
	.then(res_e => {
		this.setState({
			channelSettings: res_e.data.responseData
		});
		
		// set Channel default settings
		if ( res_e.data.responseData.publicSession === 1 ) { this.setState({ sessionProperty: false }); }
		else { this.setState({ sessionProperty: true }); }
		
		if ( res_e.data.responseData.publishChannelPage === 1 ) { this.setState({ sessionPublicChannel: true }); }
		else { this.setState({ sessionPublicChannel: false }); }
		
		if ( res_e.data.responseData.sessionSearchable === 1 ) { this.setState({ sessionSearchable: true }); }
		else { this.setState({ sessionSearchable: false }); }
		
		if ( res_e.data.responseData.recordSession === 1 ) { this.setState({ startRecording: true, recordAndOffer: true }); }
		else { this.setState({ startRecording: false, recordAndOffer: false }); }
		
		if ( res_e.data.responseData.showParticipants === 1 ) { this.setState({ showOtherParticipants: true }); }
		else { this.setState({ showOtherParticipants: false }); }
		
		if ( res_e.data.responseData.showHostParticipant === 1 ) { this.setState({ showHostParticipant: true }); }
		else { this.setState({ showHostParticipant: false }); }  
		
		if ( res_e.data.responseData.disableNotificationSound === 1 ) { this.setState({ disableNotificationSound: true }); }
		else { this.setState({ disableNotificationSound: false }); }

		if ( res_e.data.responseData.defaultRepsTime != 0 ) { this.setState({ repsSeconds: res_e.data.responseData.defaultRepsTime, repsDefaultTime: res_e.data.responseData.defaultRepsTime }); }
		
		this.setState({showVirtual: res_e.data.responseData.enabledVirtual});
		
		if ( res_e.data.responseData.enabledVirtual === 0 )
			{
				var workoutActivity = [{id: 1, interestId: 2, activity_type: "Aerobic",  status: 1},{id: 2, interestId: 2, activity_type: "Balance",  status: 1},{id: 3, interestId: 2, activity_type: "Cardio",  status: 1}, {id: 4, interestId: 2, activity_type: "Flexibility", status: 1},{id: 5, interestId: 2, activity_type: "Rest", status: 1},{id: 6, interestId: 2, activity_type: "Strength", status: 1},{id: 7, interestId: 2, activity_type: "Stretching", status: 1},{id: 8, interestId: 2, activity_type: "Warm Up", status: 1},{id: 9, interestId: 2, activity_type: "Mobility", status: 1},{id: 10, interestId: 2, activity_type: "Core", status: 1},{id: 11, interestId: 2, activity_type: "Foam Rolling", status: 1},{id: 12, interestId: 2, activity_type: "Pause", status: 1}];
				if ( this.state.isMarketplaceChannel )
					{
						workoutActivity=[{id: 1, interestId: 2, activity_type: "Regular",  status: 1}, {id: 5, interestId: 2, activity_type: "Rest", status: 1}, {id: 12, interestId: 2, activity_type: "Pause", status: 1},{id: 13, interestId: 2, activity_type: "Virtual Equipment",  status: 1}];
					}
				this.setState({
					activityType: workoutActivity
				});
			}
		
		 if ( res_e.data.responseData.durationNums && this.state.isMarketplaceChannel)
				{
					var numArray = [];
	
					for ( var i = 0; i < res_e.data.responseData.durationNums.length; i++ )
						{
							let currentNum = res_e.data.responseData.durationNums[i]['num'];
							numArray.push(currentNum);
						}
					
					this.setState({
						durationNums: numArray,
					});
				}
		else 
			{
				let durationNumRegular = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59];
				this.setState({
						durationNums: durationNumRegular,
					});
			}			
		
		axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/insertHostSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel, "user_id" : JSON.parse(localStorage.getItem('userData')).data.responseData.id})
		.then(res_h => {
			
			if ( res_h.data.responseData.defaultRepsTime != 0 ) { this.setState({ Count: res_h.data.responseData.defaultRepsTime, repsDefaultTime: res_h.data.responseData.defaultRepsTime }); }
			
		}).catch(err =>{ });
		
	}).catch(err =>{ });
	
  }
  
  else
	  {
		  axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/insertChannelSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel, "user_id" : JSON.parse(localStorage.getItem('userData')).data.responseData.id})
			.then(res_e => {
				this.setState({
					channelSettings: res_e.data.responseData
				});
					
				if ( res_e.data.responseData.durationNums && this.state.isMarketplaceChannel)
						{
							var numArray = [];
			
							for ( var i = 0; i < res_e.data.responseData.durationNums.length; i++ )
								{
									let currentNum = res_e.data.responseData.durationNums[i]['num'];
									numArray.push(currentNum);
								}
							
							this.setState({
								durationNums: numArray,
							});
						}
				else 
					{
						let durationNumRegular = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59];
						this.setState({
								durationNums: durationNumRegular,
							});
					}
				
				if ( res_e.data.responseData.defaultRepsTime != 0 ) { this.setState({ repsDefaultTime: res_e.data.responseData.defaultRepsTime }); }
				
				this.setState({showVirtual: res_e.data.responseData.enabledVirtual});
		
				if ( res_e.data.responseData.enabledVirtual === 0 )
					{
						var workoutActivity = [{id: 1, interestId: 2, activity_type: "Aerobic",  status: 1},{id: 2, interestId: 2, activity_type: "Balance",  status: 1},{id: 3, interestId: 2, activity_type: "Cardio",  status: 1}, {id: 4, interestId: 2, activity_type: "Flexibility", status: 1},{id: 5, interestId: 2, activity_type: "Rest", status: 1},{id: 6, interestId: 2, activity_type: "Strength", status: 1},{id: 7, interestId: 2, activity_type: "Stretching", status: 1},{id: 8, interestId: 2, activity_type: "Warm Up", status: 1},{id: 9, interestId: 2, activity_type: "Mobility", status: 1},{id: 10, interestId: 2, activity_type: "Core", status: 1},{id: 11, interestId: 2, activity_type: "Foam Rolling", status: 1},{id: 12, interestId: 2, activity_type: "Pause", status: 1}];
						if ( this.state.isMarketplaceChannel )
							{
								workoutActivity=[{id: 1, interestId: 2, activity_type: "Regular",  status: 1}, {id: 5, interestId: 2, activity_type: "Rest", status: 1}, {id: 12, interestId: 2, activity_type: "Pause", status: 1},{id: 13, interestId: 2, activity_type: "Virtual Equipment",  status: 1}];
							}
						this.setState({
							activityType: workoutActivity
						});
					}
				
				axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/insertHostSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel, "user_id" : JSON.parse(localStorage.getItem('userData')).data.responseData.id})
				.then(res_h => {
					
					if ( res_h.data.responseData.defaultRepsTime != 0 ) { this.setState({ Count: res_h.data.responseData.defaultRepsTime, repsDefaultTime: res_h.data.responseData.defaultRepsTime }); }
					
				}).catch(err =>{ });
			
			}).catch(err =>{ });
	  }
	  
	  
	
	//checking for channel subscription plan
	
	let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	let channelId = JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
	let postdataplan = {'id': channelId};
	
	if ( channelId && channelId > 0 )
		{
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getChannelOwner", postdataplan).then(res => {
				var ownerId = res.data.responseData[0].userId;
				if ( userId === ownerId )
					{
						var postData = {channelId: channelId};
						let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
						axios.defaults.headers.common['Authorization'] = token;

						axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkExpiredPlanTime", postData).then(res => {
							if ( res.data.responseData === 0 )
								{
									this.setState({haveActivePlan: false});
								}
						}).catch(err =>{ }); 
					}
			}).catch(err =>{ });
		}
	
  }else{
  browserHistory.push("/login");
  }       


	
		let tutorialShow = '';
		let groupId = parseInt(localStorage.getItem('groupId'));
		
		if ( groupId == 1 ) { tutorialShow = 'fitnessSessionTutorial'; }	
		else { tutorialShow = 'wineSessionTutorial'; }
			
		 this.setState({
			 tutorialToShow: tutorialShow
		 });
		
		let postdata = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.id };
	
		/* axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkSessionTutorial", postdata)
		.then(res => {
			if ( res.data.responseData.sessionTutorial === 0 )
				{
					$('#calenderModel').attr({'style':'display:block'});
					$('body').attr({'style':'overflow: hidden'});
					this.setState({
						showTutorial: true
					});
				}
		}).catch(err =>{ }); */

		console.log('this.props.params.id');
		console.log(this.props.params.id);
		if ( this.props.params.id )
			{
				var mainthis = this;
				let s_id = this.props.params.id;
				if ( localStorage.getItem('parentRecrId') )
					{
						s_id = localStorage.getItem('parentRecrId');
						mainthis.setState({isSessionRecurring: localStorage.removeItem('parentRecrId')});
						localStorage.removeItem('parentRecrId');
					}
				mainthis.updatesse('sessionIdEdit', [s_id], function (data)
					{
						console.log('MATCHING');
						mainthis.setState({hostRequestingEdit: data}, () => {$('#editing_alert').attr({'style':'display:block'});});
						
						window['sessionEditInterval'] = setInterval(function(){
							var seconds = mainthis.state.pendingHostSeconds;
							seconds = seconds - 1;
							if ( seconds == 0 )
								{
									clearInterval(window['sessionEditInterval']);
									mainthis.setState({pendingHostSeconds: 30});
									$('#editing_alert').attr({'style':'display: none'});
									mainthis.stopEditingSession();  
									browserHistory.push('/dashboardLanding');
									return false;
								}
								
							mainthis.setState({pendingHostSeconds: seconds});
						}, 1000);
					});
					
				this.setState({supportPageName: 'Edit Session'});
			}
			
			
		var currentDate = new Date();
		currentDate.setMinutes(currentDate.getMinutes() + 5);
		var currentHours = currentDate.getHours();
		var ampm = currentHours >= 12 ? 'PM' : 'AM';
		currentHours = currentHours % 12;
		currentHours = currentHours ? currentHours : 12;
		if ( currentHours < 10 ) { currentHours = '0'+currentHours; }
		currentHours = currentHours.toString();
		var currentMinutes = currentDate.getMinutes();
		if ( currentMinutes < 10 ) { currentMinutes = '0'+currentMinutes; }
		
		this.setState({sessionHours: currentHours, sessionMinutes: currentMinutes, sessionAMPM: ampm});
		
		if ( window['feedFmPlayer'] )
			{
				var mainThisf = this;
				window['feedFmPlayer'].on('all', function(event)
					{
						if ( event == 'stations' )
							{
								mainThisf.getFMStations();
							}
					});
				mainThisf.getFMStations();
			}
		else
			{
				setTimeout(() => { this.getFMStations(); }, 3000);
			}
		
		document.addEventListener("keydown", this.keyDownHandler);
		
		if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
			{
				var newactivitytype=[{id: 1, interestId: 2, activity_type: "Regular",  status: 1}, {id: 5, interestId: 2, activity_type: "Rest", status: 1}, {id: 12, interestId: 2, activity_type: "Pause", status: 1},{id: 13, interestId: 2, activity_type: "Virtual Equipment",  status: 1},{id: 14, interestId: 2, activity_type: "Pause Virtual",  status: 1}];
				
				this.setState({isMarketplaceChannel: true, DurationType: 'Time', maximumParticipants: 5000, activityType: newactivitytype, ActivityType: "Regular"});
				var role = JSON.parse(localStorage.getItem('userData')).data.responseData.channelRole;
				var permissions = this.userPermissions(role);
				this.setState({userPermissions: permissions});
				
				if ( role == "Owner" || role == "Admin" || role == "Operations Team" || role == "Fitness Programming") 
					{
						//can create and edit class
						this.setState({showSaveBtn: true});
					}
				
				if ( ( role == "Instructor"  || role == "Fitness Programming" ) && this.props.params.id )
					{
						//instructor can only edit class
						this.setState({showSaveBtn: true});
					}

				this.getReplayClasses();
				this.getFitnessCategories();
				
				
				console.log('SET STATES*************************')
				var newInstrestSelected = [];
				var selectedinterestsNames = '';
				var channelInterestSelection = [];
				var channelInterestSelectionNew = [];
				var liveInterestSubcategories = [];
				var liveSelectedInterestsSubcategoriesNames = '';
				var channelLiveSubcategoriesSelection = [];
				var channelInterestSubcategories = [];
				if (localStorage.getItem("newChannelInterest"))
					{
						var inte = parseInt(localStorage.getItem("newChannelInterest"));
						newInstrestSelected.push(inte);
					}
				if (localStorage.getItem("newChannelInterestNamesString"))
					{
						selectedinterestsNames = localStorage.getItem("newChannelInterestNamesString");
					}
				if (localStorage.getItem("channelInterestSelection"))
					{
						channelInterestSelection = JSON.parse(localStorage.getItem("channelInterestSelection"));
					}
				if (localStorage.getItem("channelInterestSelectionNew"))
					{
						channelInterestSelectionNew = JSON.parse(localStorage.getItem("channelInterestSelectionNew"));
					}
					
				if (localStorage.getItem("liveInterestSubcategories"))
					{
						liveInterestSubcategories = JSON.parse(localStorage.getItem("liveInterestSubcategories"));
					}
				if (localStorage.getItem("liveSelectedInterestsSubcategoriesNames"))
					{
						liveSelectedInterestsSubcategoriesNames = localStorage.getItem("liveSelectedInterestsSubcategoriesNames");
					}	
				if (localStorage.getItem("channelLiveSubcategoriesSelection"))
					{
						channelLiveSubcategoriesSelection = JSON.parse(localStorage.getItem("channelLiveSubcategoriesSelection"));
					}
				if (localStorage.getItem("channelInterestSubcategories"))
					{
						channelInterestSubcategories = JSON.parse(localStorage.getItem("channelInterestSubcategories"));
					}
				
				this.setState({
						newInstrestSelected: newInstrestSelected,
						selectedinterestsNames: selectedinterestsNames,
						channelInterestSelection: channelInterestSelection,
						channelInterestSelectionNew: channelInterestSelectionNew,
						liveInterestSubcategories: liveInterestSubcategories,
						liveSelectedInterestsSubcategoriesNames: liveSelectedInterestsSubcategoriesNames,
						channelLiveSubcategoriesSelection: channelLiveSubcategoriesSelection,
						channelInterestSubcategories: channelInterestSubcategories,
					});
			}	
		else
			{
				//show btn to non marketplace channels
				this.setState({showSaveBtn: true});
			}
			
  }
  
  openNewIntrest = (ev) =>{
	ev.preventDefault();
	ev.stopPropagation();
	if (!this.state.expandNewIntrest)
		{
			this.setState({
				expandNewIntrest: true,
				expandLiveIntrestSubcategories: false
			});
		}
	else 
		{
			this.setState({
				expandNewIntrest: false
			});
		}
}

newSelectIntrest = (type, mod, text, ev) =>{	
	console.log("THIS ! ! !");
	
	ev.preventDefault();
	ev.stopPropagation();
	let inter = [];
	inter.push(mod);
	let selectedinterestsNames = text;
	this.setState({
				newInstrestSelected : inter,
				selectedinterestsNames: selectedinterestsNames,
				expandNewIntrest: false
		}, ()=>{ this.addLiveSubcategories(this.state.newInstrestSelected); });
}

addLiveSubcategories = (mod)=>{
	let showSubcategories = [];
	if ( mod.length > 0 )
		{
			for(let i=0; i<this.state.channelInterestSubcategories.length; i++)
				{
					if ( mod.indexOf(this.state.channelInterestSubcategories[i]['interestId']) > -1 )
						{
							showSubcategories.push(this.state.channelInterestSubcategories[i]);
						}
				}
		}
		
	this.setState({
				liveInterestSubcategories: [],
				liveInterestSubcategoriesNamesArray: [],
				liveSelectedInterestsSubcategoriesNames: '',
				channelLiveSubcategoriesSelection: showSubcategories
			});
			
	
}

openLiveIntrestSubcategories = (ev)=>{
	ev.preventDefault();
	ev.stopPropagation();
	if (!this.state.expandLiveIntrestSubcategories)
		{
			this.setState({
				expandLiveIntrestSubcategories: true,
				expandNewIntrest: false
			});
		}
	else 
		{
			this.setState({
				expandLiveIntrestSubcategories: false
			});
		}
}

liveSelectIntrestSubcategory = (mod, text, ev) =>{
	if ( ev )
		{
			ev.preventDefault();
			ev.stopPropagation();
		}
		
	let liveInterestSubcategories = [];
	let selected_names_array = '';
		
	if (this.state.liveInterestSubcategories && this.state.liveInterestSubcategories.length > 0 && this.state.liveInterestSubcategories.indexOf(mod) > -1)
		{
			liveInterestSubcategories = [];
			selected_names_array = '';
		}
	else
		{
			liveInterestSubcategories = [];
			liveInterestSubcategories.push(mod);
			selected_names_array = text;
		}
	
	this.setState({
				liveInterestSubcategories : liveInterestSubcategories,
				liveSelectedInterestsSubcategoriesNames : selected_names_array,
				expandLiveIntrestSubcategories: false
			})
}
  
 getFitnessCategories=()=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFitnessCategories").then(res => { 
		this.setState({	virdioMusic: res.data.responseData.virdioMusic });
	}).catch(err =>{ });	
}
  
getFMStations=()=>{
	if ( window['feedFmPlayer'] && window['feedFmPlayer']._stations )
		{
			console.log('using JS SDK FeedFM');
			var stations = window['feedFmPlayer']._stations;
			var stationsArray2 = [{id: "0", name: "Stop Playing"}];
			for ( var st of stations )
				{
					stationsArray2.push(st);
				}
			this.setState({fmStations: stationsArray2});
			console.log('Found stations', stationsArray2);
		}
	else
		{
			console.log('using API FeedFM');
			var authToken = btoa('b02f46164c0c7fa7cb98975483c80c8a2089d700'+':'+'b1a3f09e8163fe49abebc9f0ff771acb361177b6');
			axios.defaults.headers.common['Authorization'] = "Basic "+authToken;
			axios.post("https://feed.fm/api/v2/session").then(res => {
				var stations = res.data.stations;
				stations.push({id: "0", name: "Stop Playing"});
				this.setState({fmStations: stations});
				console.log('Found stations', stations);
			}).catch(err =>{ });
		}
}

getReplayClasses=()=>{
	var postData = { channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel };
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getReplayClasses", postData).then(res => {
		this.setState({replayClasses: res.data.responseData}, ()=>{
			this.getReplayName()
		});
	}).catch(err =>{ });
}

pickReplayClass=(id)=>{
	this.setState({pickedReplayId: id, isOpenReplay: false}, () => this.getReplayName());
	
	if ( id > 0 )
		{
			var hostListVar = this.state.hostList;
			hostListVar.map((item) => { item.status = false });
			
			this.setState({
				tablerows: [], 
				sessionFmStations: [], 
				equipmentListMain: [], 
				hostList2: [],
				hostName: '',
				hostList: hostListVar,
				selectedPlaylist: [],
				selectedPlaylist_id: -1,
			});	
		}
}

getReplayName=()=>{
	var replayNameVar = "";
	this.state.replayClasses.map((item) => { 
		if ( this.state.pickedReplayId == item.id )
			{
				replayNameVar = item.name;
			}
	});
	this.setState({replayName: replayNameVar});
}

keyDownHandler = (event) => {
	if ( event.keyCode == 13 )
		{
			if ( this.state.creationMenu === 'Workout Builder' )
				{
					let visibleChangePlace = $('#changeActivityPlace').is(":visible");
					if (visibleChangePlace)
						{
							this.saveChangePlace();
						}
					else
						{	
							this.addRow(true);
						}
				}
		}
}	

updateEdit=(par)=>{
	if ( par === 'reject' )
		{
			let s_id = parseInt(this.props.params.id);
			if ( this.state.isSessionRecurring > 0 )
				{
					s_id = parseInt(this.state.isSessionRecurring);
				}
			
			var postData = {sessionId: s_id, request: 'reject'}
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateEditRequest", postData).then(res => {
				$('#editing_alert').attr({'style':'display:none'});
				clearInterval(window['sessionEditInterval']);
				this.setState({pendingHostSeconds: 30});
			}).catch(err =>{ });
		}
	else
		{
			let s_id = parseInt(this.props.params.id);
			if ( this.state.isSessionRecurring > 0 )
				{
					s_id = parseInt(this.state.isSessionRecurring);
				}
			
			var postData = {sessionId: s_id, request: 'accept'}

			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateEditRequest", postData).then(res => {
				$('#editing_alert').attr({'style':'display: none'});
				this.stopEditingSession();  
				browserHistory.push('/dashboardLanding');
				clearInterval(window['sessionEditInterval']);
				this.setState({pendingHostSeconds: 30});
			}).catch(err =>{ });
		}
}
  
changeTutorialIndex=(pg)=>{
	let index = this.state.chosenTutorialIndex;
	
	$('#calenderModel').attr({'style':'display:none'});
	
	if ( pg == 'Next' ) { index++; }
	else { index--; }
	
	this.setState({
		chosenTutorialIndex: index
	});
	
	if ( index == 0 )
		{
			$("html, body").animate({ scrollTop: 0 }, "slow");
			$('#calenderModel').attr({'style':'display:block'});
		}
		
	if ( index == 1 )
		{
			let host_tutorial = $('#host_tutorial').offset(); 
			$("html, body").animate({ scrollTop: host_tutorial.top }, "slow");
		}
		
	if ( index == 2 )
		{
			let script_tutorial = $('#script_tutorial').offset(); 
			$("html, body").animate({ scrollTop: script_tutorial.top }, "slow");
		}
		
}

skipTutorial=()=>{
	let postdata = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.id };
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateSessionTutorial", postdata)
    .then(res => {
		this.setState({
			showTutorial: false 
		})
		
		$('body').attr({'style':'overflow: auto'});
	}).catch(err =>{ });
}
 
  
/* WINE START */


removeToWineDescription = (e) => {
  let wineArray = this.state.wineInfoArray;
  wineArray.splice(e.target.id,1);
  this.setState({
    wineInfoArray:wineArray
    },()=>
    { console.log(this.state.wineInfoArray,'Wine Product==>',this.state.wineInfoArray);
  });

}

testerStatus = (e) =>{
  let testerContainer = this.state.tablerows1;
    testerContainer[e.target.id].testerStatus = !testerContainer[e.target.id].testerStatus;
    this.setState({
    tablerows1 : testerContainer,
    },()=>
    { console.log('testerContainer==>',this.state.tablerows1);
    });
}

modalClose = e => {
  $("#successResultEdit").attr({'style':'display:none'});
}

removeWineActivity = (e) => {
  console.log('e.target.id',e.target.id);
  let wineArray = this.state.tablerows;
  let wineContainer = this.state.wineProduct;
  for(let i =0 ;i<wineArray.length;i++){
    console.log(e.target.id ,'===', wineArray[i].id)
    if(e.target.id == wineArray[i].id){
      wineContainer[wineArray[i].wineProductId].type=false;
      wineArray.splice(i,1);
      this.setState({
        tablerows:wineArray,
        emojiForWineProduct:0,
        wineProduct:wineContainer
        
        },()=>
        { console.log('this.state.tablerows==>',this.state.tablerows);
      });
    }
  }
  wineArray = this.state.tablerows3; //removeWineStatus
  for(let i =0 ;i<wineArray.length;i++){
    console.log(e.target.id ,'===', wineArray[i].id)
    if(e.target.id == wineArray[i].id){
      wineArray.splice(i,1);
      this.setState({
        tablerows3:wineArray, //removeWineStatus
        },()=>
        { 
      });
    }
  }
  wineArray = this.state.tablerows1;
  for(let i =0 ;i<wineArray.length;i++){
    console.log(e.target.id ,'===', wineArray[i].id)
    if(e.target.id == wineArray[i].id){
      wineArray.splice(i,1);
      this.setState({
        tablerows1:wineArray, //removeWineStatus
        },()=>
        { console.log('this.state.tablerows1==>',this.state.tablerows1);
      });
    }
  }
}

resetEmoji = (e) => {
	/* let wineContainer = this.state.wineProduct;
	$.each(wineContainer, function( a, b ) {
	  b['type'] = false;
	});
	
	this.setState({
		wineProduct: wineContainer
	 }); */
}

wineProductSelect = (e) => {
	let wineContainer = this.state.wineProduct;
	
	wineContainer[e.target.id].type = !wineContainer[e.target.id].type;
	
	console.log(wineContainer);
	
	 this.setState({
		 wineProduct: wineContainer
	 });
}

setChooseWineStatus=(e)=>{
	this.setState({
		wineObjectToPush: [{}],
		wineObjectToEmojis: [{}]
      },()=> {
		  
			let wineContainer = this.state.wineProduct;
			 for ( let ind in wineContainer )
				 {
					 console.log(wineContainer, ind);
					 if ( wineContainer[ind].type )
						 {
							 this.wineProductSelect2(ind);
						 }
				 }
	  });
	 
}

openInfoPopUp = (e, mod) => {
	if(mod == 'over')
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active');
			$(e.currentTarget).find('.tooltip_hold').addClass('tooltip_active');
		}
		
	else
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active');
		}
	
}

wineProductSelect2 = (e) => {
	
  let wineContainer = this.state.wineProduct;//array
  let addWine;
  let wineArray = [];
  let wineObjectToPushVar = {};
  let productModelStatus = this.state.productModelStatus;//status
  let wineStatus = this.state.chooseWine;//boolean value
  if(productModelStatus===0){
    wineStatus = !wineStatus; //status change
  }
  productModelStatus = productModelStatus+1;//product status change
  wineArray = this.state.tablerows3;//blanck array or array of selected product
 
  if(wineContainer[e].type){ // only when product status is true
    let indexValue = (this.state.wineIndexValue+1); //product index change
  
    let la1=[];
    let la2=[];
    let ka = [];let appearanceSta=[];let aromaCheck=[];let palateCheck=[]; //all emoji is empty in first step
    for(let i=0;i<this.state.emojiesList.length;i++){ //emoji load
		if(this.state.emojiesList[i].emojies_type==="Appearance")
			{
    let n;
    n={appearanceStatus:true};
    appearanceSta.push(n);
      
     n = {interestId:this.state.emojiesList[i].interestId,
      path: this.state.emojiesList[i].path,
      emojies_type:this.state.emojiesList[i].emojies_type,
      status:false, 
      name:this.state.emojiesList[i].name,
      id:this.state.emojiesList[i].id };
      ka.push(n);//appearance array
     }
		 
		 if(this.state.emojiesList[i].emojies_type==="Aroma")
			{
      let n;
      n={appearanceStatus:true};
      aromaCheck.push(n);
      n = {interestId:this.state.emojiesList[i].interestId,
      path:this.state.emojiesList[i].path,
      emojies_type:this.state.emojiesList[i].emojies_type, 
      status:false, 
      name:this.state.emojiesList[i].name,
      id:this.state.emojiesList[i].id };
      la1.push(n);//aroma array
      }
      
		  if(this.state.emojiesList[i].emojies_type==="Palate")
			  {
        let n;
        n={appearanceStatus:true};
        palateCheck.push(n);
        n ={interestId:this.state.emojiesList[i].interestId,
        path:this.state.emojiesList[i].path,
        emojies_type:this.state.emojiesList[i].emojies_type,
        status:false, 
        name:this.state.emojiesList[i].name,
        id:this.state.emojiesList[i].id };
        la2.push(n); //palate array
      } 
     
    }

	let price = 0;
	if ( wineContainer[e]['price'] != null ) { price = Math.round(parseFloat(wineContainer[e]['price'])* 100) / 100; }
	
    wineObjectToPushVar = {
      wineChoice:wineContainer[e].product_name,//product name of wine
      price:wineContainer[e].price,//product name of wine
      id:indexValue,//index value of wine
      wineProductId:e.id,//id of wine
      productId:wineContainer[e].id,//product id of wine
      appearanceSelect:appearanceSta,
      aromaSelect:aromaCheck,
      palateSelect:palateCheck,
      listAppearance:ka,
      listAroma :la1,
      listPalate:la2,
	  testerStatus:true,
		price: price
      }
	  
	  let mainArray = this.state.wineObjectToPush;
	  
	  if ( !mainArray[0]['wineChoice'] ) { mainArray[0] = wineObjectToPushVar; }
	  else { mainArray.push(wineObjectToPushVar); }
     
	 this.setState({
		wineObjectToPush: mainArray,
		wineObjectToEmojis: mainArray
      });
  }
  
}

setEmojis = (e) => {
	let object = JSON.parse(JSON.stringify(this.state.wineObjectToPush[e]));

        this.setState({
			wineObjectToEmojis: object,
			saveind: e
        });
      }

selectEmojis = (i, mod) => {
	let wineObjectToEmojisVar = this.state.wineObjectToEmojis;
	
	wineObjectToEmojisVar[mod][i].status = !wineObjectToEmojisVar[mod][i].status;
	
	 this.setState({
		wineObjectToEmojis: wineObjectToEmojisVar
	 });
    }

removeWine = (i) => {
	let array = this.state.mainWineArray;
	let arrayNames = this.state.mainWineArrayNames;
	let nameToRemove = array[i]['wineChoice'];
	let indexToRemove = arrayNames.indexOf(nameToRemove);
	
	array.splice(i, 1);
	arrayNames.splice(indexToRemove, 1);
	
	this.setState({
		mainWineArray: array,
		mainWineArrayNames: arrayNames
	});

  }
  
changeStatusTesters = (e) => {
	let object = this.state.wineObjectToPush;
	object[e]['testerStatus'] = !object[e]['testerStatus'];

	this.setState({
		wineObjectToPush: object
	});

}

pushNewWine = (e) => {
	let newArray = this.state.mainWineArray;
	let newArrayNames = this.state.mainWineArrayNames;
	let wineContainer = this.state.wineProduct;
	
	if ( this.state.wineObjectToPush[e] && this.state.wineObjectToPush[e].wineChoice && this.state.wineObjectToPush[e].wineChoice != '' )
		{
			$.each(wineContainer, function( a, b ) {
			  b['type'] = false;
			});
			
			this.state.wineObjectToPush[e]['id'] = Date.now();
			
			if ( this.state.editWineIndex == -1 )
				{
					newArray.push(this.state.wineObjectToPush[e]);
					newArrayNames.push(this.state.wineObjectToPush[e].wineChoice);
				}
			else
				{
					newArray[this.state.editWineIndex] = this.state.wineObjectToPush[e];
				}
				
			let objNew = this.state.wineObjectToPush;
			objNew.splice(e, 1);
			
			if ( objNew.length == 0 ) { objNew = [{}]; }
			
			this.setState({
						wineObjectToPush: objNew,
						wineObjectToEmojis: objNew,
						mainWineArray: newArray,
						wineProduct: wineContainer,
						tablerows: newArray,
						mainWineArrayNames: newArrayNames,
						editWineIndex: -1
			});
			
			 let mainthis = this;
			 setTimeout(function(){ console.log(mainthis.state.mainWineArrayNames); }, 1000);
		}

}

editWine = (i) => {
	let objectToEdit = JSON.parse(JSON.stringify(this.state.mainWineArray[i]));
	let mainArray = this.state.mainWineArray;
	let nameToRemove = mainArray[i]['wineChoice'];
	mainArray.splice(i, 1);
	
	let objNew = this.state.wineObjectToPush;
	
	if ( !objNew[0]['wineChoice'] ) { objNew[0] = objectToEdit; }
	else { objNew.push(objectToEdit) }
	
	let arrayNames = this.state.mainWineArrayNames;
	
	let indexToRemove = arrayNames.indexOf(nameToRemove);
	arrayNames.splice(indexToRemove, 1);
	
	this.setState({
		mainWineArray: mainArray,
		mainWineArrayNames: arrayNames,
		wineObjectToEmojis: objNew,
		wineObjectToPush: objNew
	});
}

closeEditWine = () => {
	this.setState({
		editWineIndex: -1,
		wineObjectToPush: {},
		wineObjectToEmojis: {}
	});
}

copyWine = (i) => {
	let objectToPush = JSON.parse(JSON.stringify(this.state.mainWineArray[i]));
	objectToPush['id'] = Date.now();
	
	let array = this.state.mainWineArray;
	array.push(objectToPush);
	
	this.setState({
						wineObjectToPush: {},
						wineObjectToEmojis: {},
						mainWineArray: array,
						tablerows: array,
						editWineIndex: -1
			});
}


palateSelect = (e) => {
	let emojiContainer = this.state.tablerows;
	emojiContainer[this.state.emojiForWineProduct].listPalate[e.target.id].status = !emojiContainer[this.state.emojiForWineProduct].listPalate[e.target.id].status;
	this.setState({
		tablerows : emojiContainer,
	},()=>
	{ console.log('PalateEmoji==>',this.state.tablerows);
	});
}

searchByInput = (e) => {
	let searchText = this.state.searchBy;
	this.setState({
		showLoadMore: true,
		page_count: 1
	});
	let mainthis = this;
	setTimeout(function(){ mainthis.fetchPrevSessionList(); }, 200);
}

fetchPrevSessionListTemp = (e) =>{
	let mainthis = this;
	setTimeout(function(){ mainthis.fetchPrevSessionList('workout'); }, 1);
}

loadMorePrevious = (e) => {
	let count_main = this.state.page_count + 1;
	
	this.setState({
		page_count: count_main,
		showLoadMore: true
	});
	
	console.log(count_main);
	let mainthis = this;
	setTimeout(function(){ mainthis.fetchPrevSessionList(); }, 200);
}

finalEmoji = () =>{
	
	let object = this.state.wineObjectToPush;
	
	object[this.state.saveind] = JSON.parse(JSON.stringify(this.state.wineObjectToEmojis));

    this.setState({
		wineObjectToPush: object
	 });
}


ProductEmojiList(){
let  channelId=localStorage.getItem('channelId');
let groupId=localStorage.getItem('groupId');
let channelInterest=localStorage.getItem("channelInterest");
let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
let searchText = this.state.searchBy;
axios.defaults.headers.common['Authorization'] = token;
  axios      
  
  .post(process.env.REACT_APP_NAME+"/api/v1/session/productEmojiesList",{"channelId":channelId,"groupId":groupId})          
  .then(res => {
//     console.log('---------productEmojiList--------&&&&&&&&&&&&&&',res.data.responseData);

	  let eqarray=res.data.responseData.remainingProduct;        
 
	let ka=[];
	for(let i=0;i<eqarray.length;i++){
	  //type:false,name:"Mersedes Benz"
	  let n = {
		id: eqarray[i].id,
		type:false,
		channelId:channelId,
		interestId:channelInterest,
		product_name:eqarray[i].name,
		active:eqarray[i].active,
		description: eqarray[i].description,
		price: eqarray[i].price,
	  };  
	  ka.push(n)
	}

  let emojiContainer = [];
  let emojiList=res.data.responseData.emojiesList  
for (let k = 0; k < emojiList.length; k++) {
  let emojiName = emojiList[k].emojiesName
  let n = {}
  for (let k1 = 0; k1 < emojiName.length; k1++) {
	n = {
	  id: emojiName[k1].id,
	  interestId: 0,
	  groupId: emojiName[k1].groupId,
	  name: emojiName[k1].name,
	  emojies_type: emojiName[k1].emojies_type,
	  path:"/"+emojiName[k1].path,
	  status: 1,
	  type: 0,
	  createdAt: "2019-10-14T18:30:00.000Z"
	}
	emojiContainer.push(n)
  }
}
  this.setState({
	  wineProduct:ka,
	  emojiesList: emojiContainer
		},()=>console.log("==========emojiList===============",this.state.emojiesList));
  })
  .catch(err =>{
	  console.log('------Wine Product----there is problem------------');

  });
}

getWineProduct(productList) {
      console.log("================getWineProduct", productList);
      let wine_Product = [];
      for (let k = 0; k < productList.length; k++) {
        let n = {
          id: productList[k].id,
          type: false,
          channelId: localStorage.getItem("channelId"),
          interestId: localStorage.getItem("channelInterest"),
          product_name: productList[k].name,
          description: productList[k].description
        };
        wine_Product.push(n);
      }
  
      this.setState({
        wineProduct: wine_Product
      }, () => console.log("==============wineProduct==", this.state.wineProduct))
  
    }

getEmoji(emojiList) {
  console.log("=================getEmoji", emojiList);
	let emojiContainer = [];

	for (let k = 0; k < emojiList.length; k++) {
	  let emojiName = emojiList[k].emojiesName
	  let n = {}
	  for (let k1 = 0; k1 < emojiName.length; k1++) {
		n = {
		  id: emojiName[k1].id,
		  interestId: emojiName[k1].id,
		groupId: emojiName[k1].groupId,
		name: emojiName[k1].name,
		emojies_type: emojiName[k1].emojies_type,
		path:"/"+emojiName[k1].path,
		status: 1,
		type: 0,
		createdAt: "2019-10-14T18:30:00.000Z"
	  }
	  emojiContainer.push(n)
	}
  }
  this.setState({
	emojiesList: emojiContainer
  }, () => console.log("======================-----------=====", this.state.emojiesList))
  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", emojiContainer);
}

getEmojiProductList(scriptDetail) {
  
console.log("=================",scriptDetail)
let wineArray = [];
let wineArrayNames = [];
let wineArray1 = [];
let wineArray2 = [];
let wineArray3 = [];
let wine_Product = [];

 let wineJson = scriptDetail;
  for (let k = 0; k < wineJson.length; k++) {
	let emoji = wineJson[k].Emojies;
	let la2 = [];
	let la1 = [];
	let ka = [];
	let appearanceSta = [{ appearanceStatus: false }, { appearanceStatus: false }, { appearanceStatus: false }];
	let aromaCheck = [{ appearanceStatus: false }, { appearanceStatus: false }, { appearanceStatus: false }];
	let palateCheck = [{ appearanceStatus: false }, { appearanceStatus: false }, { appearanceStatus: false }]
	for (let k1 = 0; k1 < emoji.length; k1++) {
	  let E_detail = emoji[k1].emojiesDetail
	  for (let k2 = 0; k2 < E_detail.length; k2++) {
		if (emoji[k1].emojiesGroup === "Palate") {
		  console.log("EmojiGroup=======", emoji[k1].emojiesGroup)
		  palateCheck = [{ appearanceStatus: true }, { appearanceStatus: false }, { appearanceStatus: false }]
		  let n;
		  n = {
			interestId: E_detail[k2].sessionScriptId,
			path: "/" + E_detail[k2].path,
			emojies_type: emoji[k1].emojiesGroup,
			status: true,
			name: E_detail[k2].name,
			id: E_detail[k2].id
		  };
		  la2.push(n); //palate array
		}
		if (emoji[k1].emojiesGroup === "Aroma") {
		  aromaCheck = [{ appearanceStatus: true }, { appearanceStatus: false }, { appearanceStatus: false }];
		  let n;
		  n = {
			interestId:E_detail[k2].sessionScriptId,
			path: "/" + E_detail[k2].path,
			emojies_type: emoji[k1].emojiesGroup,
			status: true,
			name: E_detail[k2].name,
			id: E_detail[k2].id
		  };
		  la1.push(n); //palate array
		}

		if (emoji[k1].emojiesGroup === "Appearance") {
		  console.log("EmojiGroup=======", E_detail[k2])
		  appearanceSta = [{ appearanceStatus: true }, { appearanceStatus: false }, { appearanceStatus: false }];
		  let n;
		  n = {
			interestId: E_detail[k2].sessionScriptId,
			path: "/" + E_detail[k2].path,
			emojies_type: emoji[k1].emojiesGroup,
			status: true,
			name: E_detail[k2].name,
			id: E_detail[k2].id
		  };
		  ka.push(n); //palate array


		}
	  }
	}
	let addWine = {
	  wineChoice: wineJson[k].name,//product name of wine
	  price: wineJson[k].price,//product name of wine
	  id: wineJson[k].id,//index value of wine
	  wineProductId: 0,//id of wine
	  productId: wineJson[k].sessionScriptId,//product id of wine
	  appearanceSelect: appearanceSta,
	  aromaSelect: aromaCheck,
	  palateSelect: palateCheck,
	  listAppearance: ka,
	  listAroma: la1,
	  listPalate: la2,
	  testerStatus: true
	}
	wineArray.push(addWine);
	wineArray1.push(addWine);
	wineArray2.push(addWine);
	wineArray3.push(addWine);
	wineArrayNames.push(wineJson[k].name);
	let n1 = {
	  id: wineJson[0].id,
	  type: true,
	  channelId: localStorage.getItem('channelId'),
	  interestId: localStorage.getItem('channelInterest'),
	  product_name: wineJson[0].name,
	  description: wineJson[0].description
	};
	wine_Product.push(n1)
	}
  this.setState({
	//wineProduct: this.state.wineProduct.concat(wine_Product),
	tablerows: wineArray,
	mainWineArray: wineArray,
	mainWineArrayNames: wineArrayNames
  })

}


/* WINE END */ 
  
  fetchActivityType() {
   
    let  interestId=2;   
  //  console.log('-----c----------',interestId);
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;              
      axios      
      .get(process.env.REACT_APP_NAME+"/api/v1/session/"+interestId+"/activityType")          
      .then(res => {
       // console.log('---------Interestactivity--------------',res.data.responseData);

      

        // this.setState({
        //  // activityType: res.data.responseData,
        //     });
           
      })
      .catch(err =>{
          console.log('----------there is problem------------');

      });

  }

  fetchExistingHostList(mod) {

  let channelId=localStorage.getItem("channelId");  

 let n=[{userId: 70, username: "ka1 ka",status:false },
 { userId: 71, username: "ak ka" ,status:false}]
 let hostarray = []
 for(let i=0;i<n.length;i++){
  let k={userId: n[i].userId, username: n[i].username,status:false }
  hostarray.push(k);
  }
 this.setState({
  hostList: hostarray
  }, () => {console.log(this.state.hostList)});
	 let hostName;
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;              
      axios      
    
      .get(process.env.REACT_APP_NAME+"/api/v1/session/"+channelId+"/hosts-list1")          
      .then(res => {
        let hostarray = [];
        
        let hostarray2=[];
       
        if(res.data.responseData.length===1)
       {
      hostarray = this.state.hostList2;
      hostarray.push(res.data.responseData[0].userId);
	  hostName = res.data.responseData[0].username;
      this.setState({
       hostList: res.data.responseData,
       hostList2:hostarray,
	   hostName: mod ? mod: hostName
       },() => {console.log(this.state.hostList)});     
      
      }else{
        let k;
        let n;
        let hostid = this.state.isChecked ? this.state.isChecked : JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	
        for(let i=0;i<res.data.responseData.length;i++){
          if(hostid===res.data.responseData[i].userId){
          n=i;
          hostarray2.push(res.data.responseData[i].userId);
            k={userId: res.data.responseData[i].userId, userFirstName: res.data.responseData[i].userFirstName, username: res.data.responseData[i].username,act_status: res.data.responseData[i].act_status,status:true }  
            hostName=res.data.responseData[i].username;
          }else{
          k={userId: res.data.responseData[i].userId, userFirstName: res.data.responseData[i].userFirstName, username: res.data.responseData[i].username,act_status: res.data.responseData[i].act_status,status:false }
          }
          hostarray.push(k);
        }
		
        this.setState({
          hostList: hostarray,
          isChecked:n,
          hostList2:hostarray2,
          hostName: mod ? mod : hostName
        },()=>console.log(this.state.hostList));
      }
      })
      .catch(err =>{
          console.log('----------there is problem------------');

      });

  }


  fetchExistingEquipments() {
    
    let  interestId=2;  

    // let eqarray = [{id: 1, interestId: 2, name: "trademill", equipment_description: "This is running equipments", status: 1},
    // {id: 2, interestId: 2, name: "bench", equipment_description: "This is", status: 1},
    // {id: 3, interestId: 2, name: "weight-lift", equipment_description: "This is weight lift", status: 1},
    // {id: 4, interestId: 2, name: "ball", equipment_description: "This is using to fit body", status: 1}]
    // let ka = []; 
    // for(let i=0;i<eqarray.length;i++){
    //   let n = {id:eqarray[i].id, interestId: eqarray[i].interestId, name: eqarray[i].name, equipment_description: eqarray[i].equipment_description, status: eqarray[i].status,type:false,Quantity:0,Link:'X'};
    //   ka.push(n);

    // }
    // this.setState({
    //   equipmentList:ka
    // },()=>console.log('------------------------',this.state.equipmentList))


   // console.log('-----a----------',interestId);
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;              
      axios            
      .get(process.env.REACT_APP_NAME+"/api/v1/session/"+interestId+"/equipments")          
      .then(res => {
       // console.log('---------channelEquipments--------------',res.data.responseData);

      let eqarray=res.data.responseData;

      let ka = []; 
      for(let i=0;i<eqarray.length;i++){
        let n = {id:eqarray[i].id, interestId: eqarray[i].interestId, name: eqarray[i].name, equipment_description: eqarray[i].equipment_description, status: eqarray[i].status,type:false,Quantity:'',Link:''};
        ka.push(n);  
      }

        this.setState({
          equipmentList:ka
            });
            
      })
      .catch(err =>{
          console.log('----------there is problem------------');

      });

  }

  fetchExistingShopping() {
    
    let  interestId=2;  

    // let eqarray=[{id: 1, interestId: 2, name: "trademill", createdAt: "2019-09-02T08:23:17.000Z", status: 1},
    //     {id: 2, interestId: 2, name: "ball", createdAt: "2019-09-02T08:23:17.000Z", status: 1},
    //     {id: 3, interestId: 2, name: "weight-machine", createdAt: "2019-09-02T08:23:17.000Z", status: 1}
    //     ]
    // let ka = [];
    // for(let i=0;i<eqarray.length;i++){
    //   let n ={id:eqarray[i].id, interestId:eqarray[i].interestId , name:eqarray[i].name, createdAt:eqarray[i].createdAt , status:eqarray[i].status ,type:false,Quantity:0,itemNote:"X",Link :"addLink"}
    //   ka.push(n);

    // }
    // this.setState({
    //   shoppingList:ka
    // },()=>console.log('------------------------',this.state.shoppingList))


  //  console.log('-----b----------',interestId);
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;              
      axios      
      .get(process.env.REACT_APP_NAME+"/api/v1/session/"+interestId+"/shoppinglist")          
      .then(res => {
        //console.log('---------channelShopping--------------',res.data.responseData);

          let eqarray=res.data.responseData;

          let ka = [];
          for(let i=0;i<eqarray.length;i++){
            let n ={id:eqarray[i].id, interestId:eqarray[i].interestId , name:eqarray[i].name, createdAt:eqarray[i].createdAt , status:eqarray[i].status ,type:false,Quantity:'',itemNote:'',Link :''}
            ka.push(n);

          }
        this.setState({
          shoppingList:ka
            });
            
      })
      .catch(err =>{
          console.log('----------there is problem------------');

      });

  }

  fetchPrevSessionList(fromWhere) {

	  this.setState({isLoadingSessions: true})
	  
	  if ( fromWhere )
		  {
			   if ( fromWhere === 'workout' ) { this.setState({copyFromWorkout: true}); }
			   else { this.setState({copyFromWorkout: false}); }
		  }
	 
      let channelId=localStorage.getItem("channelId");  
	  let searchText = this.state.searchBy;

      let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	  let count_load = this.state.page_count;
	  let session_type = this.state.saveAsTemplate;
	  $('.main_loader').attr({'style':'display:flex'});
	  
      axios.defaults.headers.common['Authorization'] = token;              
		axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getPreviousSessionsByChannel", { channel_id: channelId, searchText: searchText, count_show: count_load, type: session_type })          
        .then(res => {
		   $('.main_loader').hide();
           this.setState({
			  sessions: res.data.responseData.sessionData,
			  showLoadMore: false,
			  dataLength: res.data.responseData.dataLength,
			  shownLength: res.data.responseData.shownLength,
			  isLoadingSessions: false
              });
          })
        .catch(err =>{
		  console.log('----------there is problem------------', err);
		   $('.main_loader').hide();
		    this.setState({
				  showLoadMore: false,
				   isLoadingSessions: false
			  });
        });
    }
    sessionCopyFromId=e=>{
	  let sessionId=e.currentTarget.value;
	  
	  let device_name;
	  let playlistId;
	  if ( this.state.sessions && this.state.sessions.length > 0 )
		{
			for ( let i = 0; i < this.state.sessions.length; i++ )
				{
					if ( this.state.sessions[i]['id'] && this.state.sessions[i]['id'] == sessionId )
						{
							if ( this.state.sessions[i]['playlist_id'])
								{
									playlistId = this.state.sessions[i]['playlist_id'];
									if ( this.state.sessions[i]['device_name'])
										{
											device_name = this.state.sessions[i]['device_name'];
										}
								}										
						}
				}
		}
		
      this.setState({
        copyFromId:sessionId,
		deviceName: device_name ? device_name : '',
		playlistId: playlistId ? playlistId : '',
      })
  }
  
  formatAMPM =(date)=> {
	  var hours = date.getHours();
	  var minutes = date.getMinutes();
	  var ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours < 10 ? '0'+hours : hours;
	  hours = hours ? hours : 12; // the hour '0' should be '12'
	  minutes = minutes < 10 ? '0'+minutes : minutes;
	  var strTime = hours + ':' + minutes + ampm;
	  return strTime;
 }
 
onSessionChangedWorkout=(e)=>{
	 let sessionId = this.state.copyFromId;	
	 if( e && !sessionId ) { return; }
	 let url = process.env.REACT_APP_NAME+"/api/v1/session/"+sessionId+"/individualSessionDetailForAdmin";
	 let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	 axios.defaults.headers.common['Authorization'] = token;
	 axios.get(url).then(res => {
		 
		this.setState({
			manualAutomaticScript: res.data.responseData.sessionData.manualAutomaticScript == 1 || this.state.isMarketplaceChannel ? true : false,
			allowPartMove: res.data.responseData.sessionData.allowPartMove == 1 ? true : false,
			usingMarkup: res.data.responseData.sessionData.usingMarkup == 1 ? true : false,
			calculateAssessmentDimensions: res.data.responseData.sessionData.calculateAssessmentDimensions == 1 ? true : false,
		});
		
		if ( res.data.responseData.sessionData.sessionActivity.length > 0 )
			{	
				let arr2 = this.state.tablerows;
				
				for ( let i = 0; i < res.data.responseData.sessionData.sessionActivity.length; i++ )
					{
						if ( this.state.manualAutomaticScript || this.state.isMarketplaceChannel )
							{
								let currentActivty = res.data.responseData.sessionData.sessionActivity[i];
								if ( !currentActivty['fmStationId'] || currentActivty['fmStationId'] == 'null' )
									{
										currentActivty['fmStationId'] = '';
									}
								arr2.push(currentActivty);
							}
						else 
							{
								if ( res.data.responseData.sessionData.sessionActivity[i]['ActivityType'] != 'Pause' )
									{
										let currentActivty = res.data.responseData.sessionData.sessionActivity[i];
										if ( !currentActivty['fmStationId'] || currentActivty['fmStationId'] == 'null' )
											{
												currentActivty['fmStationId'] = '';
											}
										arr2.push(currentActivty);
									}
							}							
					}
					
				this.setState({tablerows: arr2}, () => { this.calculateTotalActivity(); });
			}
		else
			{
				this.setState({tablerows: []}, () => { this.calculateTotalActivity(); });
				this.props.showMainErrorPop(res.data.responseData.sessionData.name + " doesn't contain any Workout, please try some other Class.");
			}
		 
	 }).catch(err =>{ this.props.showMainErrorPop('Something went wrong. Please try again!') });
}

    //onSessionChanged
    onSessionChanged = (e) => {
    let sessionId = this.state.copyFromId;
	this.setState({tablerows : []});
	if( e && !sessionId ) { return; }
	let url;
	var isCopyFrom = false;
	if( this.props.params.id )
		{ 
			console.log(this.state.isAdmin, 'isAdminStatus');
			if(this.state.isAdmin == 1)
				{
					$('.main_loader').attr({'style':'display:flex'});
					url = process.env.REACT_APP_NAME+"/api/v1/session/"+this.props.params.id+(parseInt(localStorage.getItem("groupId")) === 1 ? "/individualSessionDetailForAdmin" : "/individualsessiondetailWine")+'?lockedit=1';
					console.log(this.state.isAdmin, 'isAdminStatus111111');
				}
				
			else
				{
					url = process.env.REACT_APP_NAME+"/api/v1/session/"+this.props.params.id+(parseInt(localStorage.getItem("groupId")) === 1 ? "/individualsessiondetail" : "/individualsessiondetailWine")+'?lockedit=1';
					console.log(this.state.isAdmin, 'isAdminStatus222222');
				}
		}
	else
		{
			url = process.env.REACT_APP_NAME+"/api/v1/session/"+sessionId+(this.state.groupId === 1 ? "/individualSessionDetailForAdmin" : "/individualsessiondetailWine");
			isCopyFrom = true;
		}
      let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
      axios.defaults.headers.common['Authorization'] = token;
      axios      
      .get(url)        
      .then(res => {
		if ( res.data.responseData.isReplay )
			{
				this.setState({
					editReplayClass: true,
				});
			}
		else 
			{
				this.setState({
					editReplayClass: false,
				});
			}
			
	  if ( this.props.params.id ) 
		{ 
			if ( parseInt(res.data.responseData.sessionData.parentRecrId) > 0 )
				{
					
					if ( res.data.responseData.sessionData.latestRecurringSessionEndDate )
						{
							let getFullYear=new Date(res.data.responseData.sessionData.latestRecurringSessionEndDate).getFullYear();
							let getMonth=(new Date(res.data.responseData.sessionData.latestRecurringSessionEndDate).getMonth())+1;
							let getDate=new Date(res.data.responseData.sessionData.latestRecurringSessionEndDate).getDate();

							let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
							let timePeriod=monthArray[getMonth-1]+' '+getDate+', '+getFullYear;
							this.setState({ 
										latestRecurringSessionEndDate: res.data.responseData.sessionData.latestRecurringSessionEndDate,
										//endRecrwhen: timePeriod,
										recurEndwhen: moment(new Date(res.data.responseData.sessionData.latestRecurringSessionEndDate)).format('YYYY-MM-DD HH:mm:00'),
										editRecurringSession: timePeriod,
										recurringArray: res.data.responseData.sessionData.sessionDetails
									});
						}
					this.setState({
						parentRecrId: res.data.responseData.sessionData.parentRecrId
					}) 
				}
		}
       
		let awk;
		let dateMain = new Date();
		
		let awk2;
		let firstDate = new Date();
		
		var indexOfTz = this.state.timezonesArray.indexOf(res.data.responseData.sessionData.timeZone);
		var sessionTzCity = this.state.timezoneCityArray[indexOfTz];
		
		if( this.props.params.id ) { awk = new Date(res.data.responseData.sessionData.scheduleDate); dateMain = new Date(res.data.responseData.sessionData.scheduleDate); awk2 = new Date(res.data.responseData.sessionData.firstActiveRecurringSessionByDate); firstDate = new Date(res.data.responseData.sessionData.firstActiveRecurringSessionByDate); }
		else { awk = new Date(); awk.setMinutes(new Date().getMinutes() + 5); dateMain.setMinutes(new Date().getMinutes() + 5); awk2 = new Date(); awk2.setMinutes(new Date().getMinutes() + 5); dateMain.setMinutes(new Date().getMinutes() + 5); }
		
		
		if ( this.props.params.id ) 
			{
				var newTimeMoment = moment1(res.data.responseData.sessionData.scheduleDate).tz(sessionTzCity);
				var newTimeMoment2 = moment1(res.data.responseData.sessionData.firstActiveRecurringSessionByDate).tz(sessionTzCity);
				
				awk.setFullYear(newTimeMoment.year());
				awk.setMonth(newTimeMoment.month());
				awk.setDate(newTimeMoment.date());
				awk.setHours(newTimeMoment.hour());
				awk.setMinutes(newTimeMoment.minutes());
				dateMain.setFullYear(newTimeMoment.year());
				dateMain.setMonth(newTimeMoment.month());
				dateMain.setDate(newTimeMoment.date());
				dateMain.setHours(newTimeMoment.hour());
				dateMain.setMinutes(newTimeMoment.minutes());
				
				awk2.setFullYear(newTimeMoment2.year());
				awk2.setMonth(newTimeMoment2.month());
				awk2.setDate(newTimeMoment2.date());
				awk2.setHours(newTimeMoment2.hour());
				awk2.setMinutes(newTimeMoment2.minutes());
				firstDate.setFullYear(newTimeMoment2.year());
				firstDate.setMonth(newTimeMoment2.month());
				firstDate.setDate(newTimeMoment2.date());
				firstDate.setHours(newTimeMoment2.hour());
				firstDate.setMinutes(newTimeMoment2.minutes());
			}
		
		let hour = 0;
		let minute= 0;
	   
		if ( this.state.isMarketplaceChannel )
			{
				hour = 0;
				minute=res.data.responseData.sessionData.duration;
			}
		else 
			{
				hour = (res.data.responseData.sessionData.duration)/60;
				if( hour>=24 )
					{
						hour=0;
					}

				 minute = (res.data.responseData.sessionData.duration)%60;
			}
		
       
	   
        let ab=new Date(awk).getTime();
        let hr=new Date(awk).getHours();
        let min=new Date(awk).getMinutes();
        let ak1=new Date(awk).getHours();
        let ak2=new Date(awk).getMinutes(); 

        let day=new Date(awk).getDate();
	    let month=(new Date(awk).getMonth())+1;
        let year=new Date(awk).getFullYear();

        let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
		
		
		let schDate = '';
		console.log(this.formatAMPM(dateMain));
		if ( this.formatAMPM(dateMain) === "00:00PM" )
			{
				schDate=monthArray[month-1]+' '+day+', '+year+" "+"12:00 PM"; 
			}
		else 
			{
				schDate=monthArray[month-1]+' '+day+', '+year+" "+this.formatAMPM(dateMain); 	
			}

        let day2=new Date(awk2).getDate();
	    let month2=(new Date(awk2).getMonth())+1;
        let year2=new Date(awk2).getFullYear();	
		let firstScheduleDate = '';
		let firstScheduleDateWithoutTime = '';
		if ( this.formatAMPM(firstDate) === "00:00PM" )
			{
				firstScheduleDate=monthArray[month2-1]+' '+day2+', '+year2+" "+"12:00 PM";
				firstScheduleDateWithoutTime = monthArray[month2-1]+' '+day2+', '+year2;
			}
		else 
			{
				firstScheduleDate=monthArray[month2-1]+' '+day2+', '+year2+" "+this.formatAMPM(firstDate);
				firstScheduleDateWithoutTime = monthArray[month2-1]+' '+day2+', '+year2;
			}

        let akDate=year+"-"+month+"-"+day+" "+ak1+":"+ak2;
        const monthOfSession = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']
        let activityArray=[];
		let shoppingArray = [];
        let arrayOfEquipment = [];
        let arrayOfEquipmentNames = [];
		if( this.state.groupId === 1 )
			{
				for (let i = 0; i < res.data.responseData.sessionData.equipmentList.length; i++) {
					  if (res.data.responseData.sessionData.equipmentList[i].equipment_name) 
						  {
							let n = {
							  name: res.data.responseData.sessionData.equipmentList[i].equipment_name,
							  type: true,
							  id: res.data.responseData.sessionData.equipmentList[i].id,
							  Quantity: res.data.responseData.sessionData.equipmentList[i].quantity,
							  Link: res.data.responseData.sessionData.equipmentList[i].link,
							  id: Date.now() + "" + Math.floor(Math.random() * 1000000000000),
							  status: res.data.responseData.sessionData.equipmentList[i].status
							};
							
							arrayOfEquipment.push(n);
							arrayOfEquipmentNames.push(res.data.responseData.sessionData.equipmentList[i].equipment_name);
						  } 
					}
			}
		else
			{
				for (let i = 0; i < res.data.responseData.sessionData.shoppingList.length; i++) {
				  if (!res.data.responseData.sessionData.shoppingList[i].name) {
	  
				  } else {
					let n = {
					  name: res.data.responseData.sessionData.shoppingList[i].name,
					  type: true,
					  id: res.data.responseData.sessionData.shoppingList[i].id,
					  Quantity: res.data.responseData.sessionData.shoppingList[i].quantity,
					  itemNote: res.data.responseData.sessionData.shoppingList[i].item_note,
					  Link: res.data.responseData.sessionData.shoppingList[i].link,
					  price: res.data.responseData.sessionData.shoppingList[i].price
					};
					shoppingArray.push(n);
				  }
				}
			}
		 
        let arr2 = this.state.tablerows;
		for ( let i = 0; i < res.data.responseData.sessionData.sessionActivity.length; i++ )
			{
				let currentActivty = res.data.responseData.sessionData.sessionActivity[i];
				if ( !currentActivty['fmStationId'] || currentActivty['fmStationId'] == 'null' )
					{
						currentActivty['fmStationId'] = '';
					}
				arr2.push(currentActivty);
			}
			
		var shopArray = [];
		if ( res.data.responseData.sessionData.shoppingList.length > 0 )
			{
				var dataList = res.data.responseData.sessionData.shoppingList;
				let ids_shop = 1;
				
				for ( var i = 0; i < dataList.length; i++ )
					{			
						shopArray.push({
									'id': 0,
									'Link': dataList[i]['link'],
									'Quantity': dataList[i]['quantity'],
									'itemNote': dataList[i]['item_note'],
									'id_main': ids_shop,
									'price': dataList[i]['price']
								});
								
						ids_shop++;
					}
			}

		var ctime2 = ( new Date(awk).getHours() < 10 ? '0'+new Date(awk).getHours() : new Date(awk).getHours() ) + ':' + ( new Date(awk).getMinutes() < 10 ? '0'+new Date(awk).getMinutes() : new Date(awk).getMinutes() );
		
		let session_image = '';
		
		if ( res.data.responseData.sessionData.image ) 
			{
				session_image = res.data.responseData.sessionData.image;
			}
		else if ( res.data.responseData.sessionData.sessionImage )
			{
				session_image = res.data.responseData.sessionData.sessionImage;
			}
			
		var sessionStations = res.data.responseData.sessionData.sessionStations;
		var sessionStationTimes = [];
		var sessionStationsVar = [];
		for ( let i = 0; i < sessionStations.length; i++ )
			{
				sessionStationTimes.push(sessionStations[i].time);
				sessionStationsVar.push({fmStationId: sessionStations[i].stationId, fmStationName: sessionStations[i].stationName, time: sessionStations[i].time});
			}
			
		if ( sessionStationsVar.length > 0 && sessionStationsVar[0].time == -1 )
			{
				if ( this && this.sessionFieldsRef && this.sessionFieldsRef.current ) 
					{
						let stationId = 0;
						for ( var a = 0; a < this.state.fmStations.length; a++ )
							{
								let currentState = this.state.fmStations[a];
								if ( currentState['name'] == sessionStationsVar[0].fmStationName )
									{
										stationId = currentState['id'];
									}
							}	
						this.sessionFieldsRef.current.updatePickedState(stationId);
					}
			}
			
		
		if(this.props.params.id)
		{
			let hostContainer = this.state.hostList;
			let hostList2 = [];
			console.log(hostContainer, 'host container');
			  let hostName;
				$.each(hostContainer, function(a, b) {
					if(res.data.responseData.sessionData.hostId == b.userId)
						{ 
							hostList2.push(b.userId);
							hostContainer[a]['status'] = true;
							hostName = b.username;
						}
						
					else
						{
							hostContainer[a]['status'] = false;
						}
					
				});
			  		
				if ( !hostName )
					{
						let hostContainer2 = this.state.hostList;
						let hostName2 = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName;
						let hostId2 = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
						
						$.each(hostContainer2, function(a, b) {
							if ( b['userId'] == hostId2 )
								{
									hostList2.push(b.userId);
									b['status'] = true;
								}
							else
								{
									b['status'] = false;
								}
						});

						this.setState({
							hostList:hostContainer2,
							hostList2: hostList2,
							isChecked: hostId2,
							hostName:hostName2
						});
					}
				else
					{
					  this.setState({
						hostList: hostContainer,
						hostList2: hostList2,
						isChecked: res.data.responseData.sessionData.hostId,
						hostName: hostName
					  
						}, () => {console.log(this.state.hostList)});
					}
		}
		
		this.setState({
						sessionProperty:res.data.responseData.sessionData.sessionProperty===0?false:true,
						sessionPublicChannel: res.data.responseData.sessionData.sessionPublicChannel == 1 ? true : false,
						sessionSearchable: res.data.responseData.sessionData.sessionSearchable == 1 ? true : false,
						startMusic: res.data.responseData.sessionData.StartMusicSession===0?false:true,
						startRecording: res.data.responseData.sessionData.StartRecordSession===0 ? false : true,
						showOtherParticipants: res.data.responseData.sessionData.showOtherParticipants===0?false:true,
						highDefSelect: ( res.data.responseData.sessionData.CustomResolutionHigh === '180p' ? 'Low Definition' : ( res.data.responseData.sessionData.CustomResolutionHigh === '360p' ? 'Standard Definition' : 'High Definition' ) ),
						disableNotificationSound: res.data.responseData.sessionData.disableNotificationSound === 0 ? false : true
					});
					
		this.pickReplayClass(res.data.responseData.sessionData.replayClassId);
        this.setState({
               tablerows:arr2,
                session_details:res.data.responseData.sessionData.name,
				session_image: session_image,
                 description:res.data.responseData.sessionData.description,
                 descriptionStatus:res.data.responseData.sessionData.description,
                 exampleFormControlSelect1:res.data.responseData.sessionData.level,
                 when:akDate,
                 whenSessionTime:schDate,
                 minimumParticipants:res.data.responseData.sessionData.minAttendee,
                 maximumParticipants:res.data.responseData.sessionData.maxAttendee,
				 
				 recordOfferDemand: res.data.responseData.sessionData.recordOfferDemand == 0 ? true : false,
				 
				 manualAutomaticScript: res.data.responseData.sessionData.manualAutomaticScript == 1 || this.state.isMarketplaceChannel ? true : false,
				 allowPartMove: res.data.responseData.sessionData.allowPartMove == 1 ? true : false,
				 usingMarkup: res.data.responseData.sessionData.usingMarkup == 1 ? true : false,
				 calculateAssessmentDimensions: res.data.responseData.sessionData.calculateAssessmentDimensions == 1 ? true : false,
				 showEquipment: res.data.responseData.sessionData.showEquipment == 0 ? true : false,
				 showShoppingList: res.data.responseData.sessionData.showShoppingList == 0 ? true : false,
				 
				 showHostParticipant: res.data.responseData.sessionData.showHostParticipant == 1 ? true : false,
				 
                recordAndOffer:res.data.responseData.sessionData.onDemand===0?false:true,
                orderWine:res.data.responseData.sessionData.orderWine===0?false:true,
                 sessionCharge:res.data.responseData.sessionData.sessionChargeAllowed===0?false:true,
                 liveSessionMemberCharges:res.data.responseData.sessionData.chargeForSession,
                 liveSessionNonMemberCharges:res.data.responseData.sessionData.chargeForSessionNonMember,
                 hostSessionStart:res.data.responseData.sessionData.hostReminder,
                participantSessionStart:res.data.responseData.sessionData.participantReminder,
                minimumNotMet:res.data.responseData.sessionData.minNotMetNoticeTime,
                automatic:res.data.responseData.sessionData.activity==="automatic"?true:false,
                manual:res.data.responseData.sessionData.activity==="manual"?true:false,
                // signUpDateTime:year+"-"+month+"-"+day+" "+hr+":"+min,
                // // participantSessionStart:5,
                 sessionHour:hour.toString().split(".")[0],
                 sessionMinute:minute,
                 scriptHeartRateMonitor:res.data.responseData.sessionData.heartRateMonitor===0?false:true,
                 scriptZoneTracking:res.data.responseData.sessionData.zoneTracking===0?false:true,
                 searchParticipant:res.data.responseData.sessionData.showParticipantsCount===0?false:true,
                //signUpDateTime:schDate1,
                // //cutoff_date_time:"2019-11-2 15:06:01",
                disableParticipant:res.data.responseData.sessionData.participantDisableDM===0?false:true,
                showParticipant:res.data.responseData.sessionData.showParticipantsPicToOtherPartcipants===0?false:true,
                allowParticipant:res.data.responseData.sessionData.participantsPickPlaylist===0?false:true,
                shoppingList:shoppingArray,
                shoppingList1:shoppingArray, 
                equipmentListMain:arrayOfEquipment,
                equipmentListMainNames:arrayOfEquipmentNames,
                equipmentList1:arrayOfEquipment, 
                allowLocation:res.data.responseData.sessionData.allowGroupLocation===0?false:true,
				selectedPlaylist: res.data.responseData.sessionData.playlist && res.data.responseData.sessionData.playlist.length > 0 ? ( res.data.responseData.sessionData.playlist[0]['browser'] === browName || res.data.responseData.sessionData.playlist[0]['browser'] === 'Native App' || res.data.responseData.sessionData.playlist[0]['browser'] === 'Native Windows' || res.data.responseData.sessionData.playlist[0]['browser'] === 'Native Mac OS' ) ? res.data.responseData.sessionData.playlist[0] : [] : [],
				selectedPlaylist_id: res.data.responseData.sessionData.playlist && res.data.responseData.sessionData.playlist.length > 0 ? ( res.data.responseData.sessionData.playlist[0]['browser'] === browName || res.data.responseData.sessionData.playlist[0]['browser'] === 'Native App' || res.data.responseData.sessionData.playlist[0]['browser'] === 'Native Windows' || res.data.responseData.sessionData.playlist[0]['browser'] === 'Native Mac OS' ) ? res.data.responseData.sessionData.playlist[0]['playlist_id'] : -1 : -1,
                addedShopList: shopArray,
				recurEndCalender: new Date(awk),
				time2: ctime2,
				sessionCalender: new Date(awk),
				isSessionRecurring: res.data.responseData.sessionData.parentRecrId>0?res.data.responseData.sessionData.parentRecrId:0,
				chosenTimezone: res.data.responseData.sessionData.timeZone,
				allowSS: res.data.responseData.sessionData.allowSS == 1 ? true : false,
				remainingReccurings: res.data.responseData.sessionData.reccuringsRemaining,
				firstActiveRecurringSessionStartDate:firstScheduleDate,
				recurStartCalender: new Date(awk2),
				firstScheduleDateWithoutTime: firstScheduleDateWithoutTime,
				recurringByHostLeft: res.data.responseData.sessionData.recurringByHostLeft,
				sessionFmTimes: sessionStationTimes,
				sessionFmStations: sessionStationsVar,
				isInReview: (res.data.responseData.sessionData.marketplaceStatus == 1 || res.data.responseData.sessionData.marketplaceStatus == 2) ? true: false,
				chosenMusic: res.data.responseData.sessionData.musicType,
				workoutSoundStart: res.data.responseData.sessionData.workoutSoundStart,
				workoutSoundEnd: res.data.responseData.sessionData.workoutSoundEnd,
				showPersonalBest: res.data.responseData.sessionData.showPersonalBest

                // sessionDay :day,
                // sessionMonth:monthOfSession[month-1],
                // sessionYear:year,
                // sessionTime:sessionHourFormat

        
          }, () => {
						if ( this.props.params.id ) { localStorage.setItem('channelInterest', res.data.responseData.sessionData.interestId); }
						
						if( this.state.groupId === 2 )
							{
								this.getEmojiProductList(res.data.responseData.sessionData.scriptDetail);
								this.getEmoji(res.data.responseData.emojiesList);
								//this.getWineProduct(res.data.responseData.remainingProduct); 
								this.calculateTotal();
							}
						else
							{
								this.calculateTotalActivity(); 
							}
				});
				
			if ( this.state.isMarketplaceChannel )
				{
					if ( res.data.responseData.sessionData.interestArray && res.data.responseData.sessionData.interestArray.length > 0 )
						{
							let nameInterest = '';
							if ( this.state.channelInterestSelection && this.state.channelInterestSelection.length > 0 )
								{
									for (let i=0; i<this.state.channelInterestSelection.length; i++) 
										{
											if ( res.data.responseData.sessionData.interestArray[0] == this.state.channelInterestSelection[i]['id'])
												{
													nameInterest = this.state.channelInterestSelection[i]['title'];
												}
										}
								}
							this.setState({
									newInstrestSelected: res.data.responseData.sessionData.interestArray,
									selectedinterestsNames: nameInterest
								}, ()=>{ this.addLiveSubcategories(this.state.newInstrestSelected);});
						}
					if ( res.data.responseData.sessionData.interestSubcategoryArray && res.data.responseData.sessionData.interestSubcategoryArray.length )
						{
							let nameSubcategory = '';
							if ( this.state.channelLiveSubcategoriesSelection && this.state.channelLiveSubcategoriesSelection.length )
								{
									console.log('this.state.channelLiveSubcategoriesSelection');
									console.log(this.state.channelLiveSubcategoriesSelection);
									for (let i=0; i<this.state.channelLiveSubcategoriesSelection.length; i++) 
										{
											if ( res.data.responseData.sessionData.interestSubcategoryArray[0]['subcategoryId'] == this.state.channelLiveSubcategoriesSelection[i]['id'])
												{
													nameSubcategory = this.state.channelLiveSubcategoriesSelection[i]['title'];
												}
										}
								}
							this.setState({
									liveInterestSubcategories: res.data.responseData.sessionData.interestSubcategoryArray,
									liveSelectedInterestsSubcategoriesNames: nameSubcategory
								});
						}
					else
						{
							this.setState({
									liveInterestSubcategories: []
								});
						}
				}		
				
			
			var newTime = moment1(res.data.responseData.sessionData.scheduleDate).tz(sessionTzCity);
			var currentDate = new Date(res.data.responseData.sessionData.scheduleDate);
			
			if ( !this.props.params.id )
				{
					var date5 = new Date();
					date5 = date5.setMinutes(date5.getMinutes() + 5);
					newTime = moment1(date5).tz(sessionTzCity);
					currentDate = new Date(date5);
				}
			
			currentDate.setFullYear(newTime.year());
			currentDate.setMonth(newTime.month());
			currentDate.setDate(newTime.date());
			currentDate.setHours(newTime.hour());
			currentDate.setMinutes(newTime.minutes());
			var currentHours = currentDate.getHours();
			var ampm = currentHours >= 12 ? 'PM' : 'AM';
			currentHours = currentHours % 12;
			currentHours = currentHours ? currentHours : 12;
			if ( currentHours < 10 ) { currentHours = '0'+currentHours; }
			currentHours = currentHours.toString();
			var currentMinutes = currentDate.getMinutes();
			if ( currentMinutes < 10 ) { currentMinutes = '0'+currentMinutes; }
			
			this.setState({sessionHours: currentHours, sessionMinutes: currentMinutes, sessionAMPM: ampm});
				
				
			this.checkManualAutomaticScript();
		
      $('.main_loader').hide();
	  })
      .catch(err =>{
           $('.main_loader').hide();
		  console.log('there is problem');
          console.log(err);
		  
			if ( err && err.response && err.response.data && err.response.data.responseCode === 417 )
				{
					if ( err.response.data.errorData && err.response.data.errorData[0] && err.response.data.errorData[0] == 'locked' )
					{
						this.props.showMainErrorPop(err.response.data.errorData[1], false, function ()
						{
							browserHistory.push('/DashboardLanding');
						});
					}
				}
		  
		  
		  
          // dispatch({

          //   type: GET_ERRORS,
          //   payload: err.response.data
          // })
      });

    }

//////////////////////////////Integration Api///////////////////////////////////
//////////Calender

onChange1 = date =>
{
  //2019-10-20
  const month = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']
  let getFullYear=new Date(date).getFullYear();
  let getMonth=month[(new Date(date).getMonth())];
  let getDate=new Date(date).getDate();
 // console.log(getFullYear+'-'+getMonth+'-'+getDate,'--------------',new Date(date).getFullYear(),new Date(date).getDate(),new Date(date).getMonth());
//let sessionDate =new Date(this.state.when).getDate()+'-'++''new Date(this.state.when).getDate() 
	this.setState({
		sessionCalender: date,
		sessionDay:getDate,
		sessionMonth:getMonth,
		time2: '12:00',
		sessionYear:getFullYear,
		sessionHours: '12',
		sessionMinutes: '00',
		sessionAMPM: 'PM'
	})
}



onChange2 = date =>
{
  //2019-10-20
  const month = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']
  let getFullYear=new Date(date).getFullYear();
  let getMonth=month[(new Date(date).getMonth())];
  let getDate=new Date(date).getDate();
  console.log(getFullYear+'-'+getMonth+'-'+getDate,'--------------',new Date(date).getFullYear(),new Date(date).getDate(),new Date(date).getMonth());
//let sessionDate =new Date(this.state.when).getDate()+'-'++''new Date(this.state.when).getDate() 
this.setState({
  reminderCalender: date,
  reminderDay:getDate,
  reminderYear:getFullYear,
  reminderMonth:getMonth
},()=>console.log(this.state.reminderCalender))

}

copyClick = () => {  
  let buttonText = this.state.buttonText == "Copy Url" ? "Copied" :'Copied'      
  this.setState({buttonText: buttonText})
}

// blubClick = () => {
//   $("#createdSessionSuccess").attr({'style':'display:block'});
//   $("#successResult").attr({'style':'display:none'});
//   // $("#calenderModel").attr({'style':'display:none'});
// }
/***ONCHANGE DATE FOR RUCURRING SESSION */

onChangeRecurEnd = date =>
{
    //2019-10-20
    const month = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']
    let getFullYear=new Date(date).getFullYear();
    let getMonth=month[(new Date(date).getMonth())];
    let getDate=new Date(date).getDate();
    console.log(getFullYear+'-'+getMonth+'-'+getDate,'--------------',new Date(date).getFullYear(),new Date(date).getDate(),new Date(date).getMonth());
  //let sessionDate =new Date(this.state.when).getDate()+'-'++''new Date(this.state.when).getDate() 
  this.setState({
    recurEndCalender: date,
    recurEndDay:getDate,
    recurEndYear:getFullYear,
    recurEndMonth:getMonth
  },()=>console.log(this.state.recurEndCalender))
}

onChangeStartCalender = date =>
{
  this.setState({
    recurStartCalender: date,
  },()=>console.log(this.state.recurStartCalender))
}

////for Reminder
timeset = time => {
  
  console.log(time.split(':')[0],time.split(':')[1]);
  
    let b = time.split(':')[1];
    let a = parseInt(time.split(':')[0]);
    b= parseInt(time.split(':')[1]);
    if(a==12 && b>=0){
      a=a+ ' PM';
    }
    if(a>12 ){
      a=a-12+ ' PM';
    }
    if(a<12){
      a = a+' AM';
    }
    // if(b>59){
    //   b=0;
    //   time=parseInt(time.split(':')[0])+':'+b;
    // }else{
    //   time=parseInt(time.split(':')[0])+':'+parseInt(time.split(':')[1])
    // }
  // console.log(time1 ,'print')
this.setState(
  { time:time,
    reminderTime:a,
 },()=>console.log(this.state.time))}

convertAMPM=(time12h)=>{
	const [time, modifier] = time12h.split(' ');

	let [hours, minutes] = time.split(':');

	if (hours === '12') {
		hours = '00';
	}

	if (modifier === 'PM') {
		hours = parseInt(hours, 10) + 12;
	}

	return `${hours}:${minutes}`;
}

// end
//For Session
timeset2 = e => {
	this.setState({[e.target.id]: e.target.value}, () => {
		var sessionDateFull = this.state.sessionHours + ':' + this.state.sessionMinutes + ' ' + this.state.sessionAMPM;
		var sessionTimeVar = this.state.sessionHours + ' ' + this.state.sessionAMPM;
		
		var time2 = this.convertAMPM(sessionDateFull);
		
		this.setState({time2: time2, sessionTime: sessionTimeVar});
	})
}

// hour value
forWineHour() {
  var arr = [];
    for (let i = 0; i <= 23; i++) {
        arr.push(<option key={i} value={i}>{i}</option>)
    }
  return arr; 
}

forActivityHour() {
  var arr = [];
    for (let i = 0; i <= 59; i++) {
        arr.push(<option key={i} value={i}>{i}</option>)
    }
  return arr; 
}
forActivityMinute() {
  var arr = [];
    for (let i = 0; i <= 200; i++) {
        arr.push(<option key={i} value={i}>{i}</option>)
    }
  return arr; 
}

// minute value
forWineMinute() {
  var arr = [];
	
    for (let i = 0; i < this.state.durationNums.length; i++) 
		{
			let currentNum = this.state.durationNums[i];
			arr.push(<option key={currentNum} value={currentNum}>{currentNum}</option>)
		}
  return arr; 
}

updateDescField = (inp) => {
	this._description = inp;
}

// end time
sessionDate=(e)=>{
 console.log('CHANGE DATE---', this.state.sessionCalender);

 //let getFullYear=new Date(this.state.sessionCalender).getFullYear();
 let getFullYear=moment(new Date(this.state.sessionCalender)).format('YYYY');
  //let getMonth=(new Date(this.state.sessionCalender).getMonth())+1;
  let getMonth=moment(new Date(this.state.sessionCalender)).format('MM');
  //let getDate=new Date(this.state.sessionCalender).getDate();
  let getDate=moment(new Date(this.state.sessionCalender)).format('DD');
  let b = this.state.time2.split(':')[1];
    let a = parseInt(this.state.time2.split(':')[0]);
    b= parseInt(this.state.time2.split(':')[1]);
    let timePeriod;
   let timeofsession='';

    if(a==12 && b>=0){
      if ( b < 10 ) { b = '0'+b; }
	  
	  timeofsession=a+':'+b+ 'PM';
      a=a+':'+b+':'+'00'+ 'PM';
    }
    if(a>12 ){
      if((a-12)<10 && b<10){
		timeofsession='0'+(a-12)+':'+'0'+b+ 'PM';
        }
        if((a-12)<10 && b>=10){
        timeofsession='0'+(a-12)+':'+b+ 'PM';
        }
        if((a-12)>=10 && b<10){
        timeofsession=a-12+':'+'0'+b+ 'PM';
        }
        if((a-12)>=10 && b>=10){
        timeofsession=a-12+':'+b+ 'PM';
        }
      a=a-12+':'+b+':'+'00'+ 'PM';
    }
    if(a<12){
      if(a<10 && b<10){
        timeofsession='0'+a+':'+'0'+b+ 'AM';

        }
        if(a<10 && b>=10){
        timeofsession='0'+a+':'+b+ 'AM';
        }
        if(a>=10 && b<10){
        timeofsession=a+':'+'0'+b+ 'AM';
        }
        if(a>=10 && b>=10){
        timeofsession=a+':'+b+ 'AM';
        }
      
      a = a+':'+b+':'+'00'+ 'AM';
    }
    let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
	
    timePeriod=monthArray[getMonth-1]+' '+getDate+', '+getFullYear+' '+timeofsession;
  this.setState({
    when:getFullYear+'-'+(getMonth)+'-'+getDate+' '+this.state.time2,
    whenSessionTime:timePeriod,
	errorClass_Datevalidatior: false
  },()=>{{$("#calenderModel").attr({'style':'display:none'}); 
	  
		console.log('when---', this.state.when); 
		console.log('whenSessionTime---', this.state.whenSessionTime);		
		
		if ( this.state.endRecrwhen )
			{
				this.getMultipleDateArray();
			}
	}})
  
}

/***this function use for click on setup recurring button on calender */
// end time
recurringSessionDate=(e)=>{
	
  //let getFullYear=new Date(this.state.sessionCalender).getFullYear();
 let getFullYear=moment(new Date(this.state.sessionCalender)).format('YYYY');
  //let getMonth=(new Date(this.state.sessionCalender).getMonth())+1;
  let getMonth=moment(new Date(this.state.sessionCalender)).format('MM');
  //let getDate=new Date(this.state.sessionCalender).getDate();
  let getDate=moment(new Date(this.state.sessionCalender)).format('DD');
  let b = this.state.time2.split(':')[1];
    let a = parseInt(this.state.time2.split(':')[0]);
    b= parseInt(this.state.time2.split(':')[1]);
    let timePeriod;
   let timeofsession='';

    if(a==12 && b>=0){
      timeofsession=a+':'+'0'+b+ 'PM';
      a=a+':'+b+':'+'00'+ 'PM';
      //timePeriod="PM";
    }
    if(a>12 ){
      if((a-12)<10 && b<10){
        timeofsession='0'+(a-12)+':'+'0'+b+ 'PM';
        }
        if((a-12)<10 && b>=10){
        timeofsession='0'+(a-12)+':'+b+ 'PM';
        }
        if((a-12)>=10 && b<10){
        timeofsession=a-12+':'+'0'+b+ 'PM';
        }
        if((a-12)>=10 && b>=10){
        timeofsession=a-12+':'+b+ 'PM';
        }
      //timeofsession=a-12+':'+b+ 'PM';
      a=a-12+':'+b+':'+'00'+ 'PM';
      //timePeriod="PM";
    }
    if(a<12){
      if(a<10 && b<10){
        timeofsession='0'+a+':'+'0'+b+ 'AM';

        }
        if(a<10 && b>=10){
        timeofsession='0'+a+':'+b+ 'AM';
        }
        if(a>=10 && b<10){
        timeofsession=a+':'+'0'+b+ 'AM';
        }
        if(a>=10 && b>=10){
        timeofsession=a+':'+b+ 'AM';
        }
      //timeofsession=a+':'+b+ 'AM';
      a = a+':'+b+':'+'00'+ 'AM';
      //timePeriod="AM";
    }
    let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
    // timePeriod=monthArray[getMonth-1]+' '+getDate+', '+getFullYear+' '+a;
    timePeriod=monthArray[getMonth-1]+' '+getDate+', '+getFullYear+' '+timeofsession;
	
	let firstSession = this.state.firstScheduleDateWithoutTime+' '+timeofsession;
	
  this.setState({
    when:getFullYear+'-'+(getMonth)+'-'+getDate+' '+this.state.time2+':'+'00',
    whenSessionTime: timePeriod,
	firstActiveRecurringSessionStartDate: firstSession
  },()=>{
		{
			if ( this.state.editRecurringSession )
				{
					let temp = this.state.editRecurringSession;
					if ( this.state.endRecrwhenTemp != '' )
						{
							console.log('new EndDAte');
							temp = this.state.endRecrwhenTemp;
						}
					this.setState({
							endRecrwhen: temp
						});
				}
			else
				{
					this.setState({
							recurStartCalender: this.state.sessionCalender
						});
				}
				
			if ( this.state.chosenRecurringDays != '' )
				{
					for (var i = 0; i <= this.state.chosenRecurringDays.length; i++) {
						if ( this.state.chosenRecurringDays[i] )
							{
								console.log(this.state.chosenRecurringDays[i]);
								let dayTemp = String(this.state.chosenRecurringDays[i]);
								let day = dayTemp.substr(0,3);
								if ( day == 'Mon' )
									{
										this.setState({
												mon: true
											});
									}
								else if ( day == 'Tue' )
									{
										this.setState({
												tue: true
											});
									}
								else if ( day == 'Wed' )
									{
										this.setState({
												wed: true
											});
									}
								else if ( day == 'Thu' )
									{
										this.setState({
												thu: true
											});
									}
								else if ( day == 'Fri' )
									{
										this.setState({
												fri: true
											});
									}
								else if ( day == 'Sat' )
									{
										this.setState({
												sat: true
											});
									}
								else if ( day == 'Sun' )
									{
										this.setState({
												sun: true
											});
									}
							}
					 } 
				}
			else
				{
					for (var i = 0; i <= this.state.recurringArray.length; i++) {
						if ( this.state.recurringArray[i] && this.state.recurringArray[i]['sdFormateDate'] && this.state.recurringArray[i]['isCompleted'] == 0)
							{
								let day = this.state.recurringArray[i]['sdFormateDate'].substr(0,3);
								if ( day == 'Mon' )
									{
										this.setState({
												mon: true
											});
									}
								else if ( day == 'Tue' )
									{
										this.setState({
												tue: true
											});
									}
								else if ( day == 'Wed' )
									{
										this.setState({
												wed: true
											});
									}
								else if ( day == 'Thu' )
									{
										this.setState({
												thu: true
											});
									}
								else if ( day == 'Fri' )
									{
										this.setState({
												fri: true
											});
									}
								else if ( day == 'Sat' )
									{
										this.setState({
												sat: true
											});
									}
								else if ( day == 'Sun' )
									{
										this.setState({
												sun: true
											});
									}
							}
					 } 
				}
			$("#calenderModel").attr({'style':'display:none'});$("#recurring_schedule").attr({'style':'display:block'})
		}})  
}

/*** For recurring interface END DATE calender button*/
recurringCalender=(e)=>{
    //let getFullYear=new Date(this.state.recurEndCalender).getFullYear();
	let getFullYear=moment(new Date(this.state.recurEndCalender)).format('YYYY');
	//let getMonth=(new Date(this.state.recurEndCalender).getMonth())+1;
	let getMonth=moment(new Date(this.state.recurEndCalender)).format('MM');
	//let getDate=new Date(this.state.recurEndCalender).getDate();
	let getDate=moment(new Date(this.state.recurEndCalender)).format('DD');
  let b = this.state.time2.split(':')[1];
    let a = parseInt(this.state.time2.split(':')[0]);
    b= parseInt(this.state.time2.split(':')[1]);
    let timePeriod;
   let timeofsession='';

    if(a==12 && b>=0){
      timeofsession=a+':'+'0'+b+ 'PM';
      a=a+':'+b+':'+'00'+ 'PM';
      //timePeriod="PM";
    }
    if(a>12 ){
      if((a-12)<10 && b<10){
        timeofsession='0'+(a-12)+':'+'0'+b+ 'PM';
        }
        if((a-12)<10 && b>=10){
        timeofsession='0'+(a-12)+':'+b+ 'PM';
        }
        if((a-12)>=10 && b<10){
        timeofsession=a-12+':'+'0'+b+ 'PM';
        }
        if((a-12)>=10 && b>=10){
        timeofsession=a-12+':'+b+ 'PM';
        }
      //timeofsession=a-12+':'+b+ 'PM';
      a=a-12+':'+b+':'+'00'+ 'PM';
      //timePeriod="PM";
    }
    if(a<12){
      if(a<10 && b<10){
        timeofsession='0'+a+':'+'0'+b+ 'AM';

        }
        if(a<10 && b>=10){
        timeofsession='0'+a+':'+b+ 'AM';
        }
        if(a>=10 && b<10){
        timeofsession=a+':'+'0'+b+ 'AM';
        }
        if(a>=10 && b>=10){
        timeofsession=a+':'+b+ 'AM';
        }
      //timeofsession=a+':'+b+ 'AM';
      a = a+':'+b+':'+'00'+ 'AM';
      //timePeriod="AM";
    }
    let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
    // timePeriod=monthArray[getMonth-1]+' '+getDate+', '+getFullYear+' '+a;
    timePeriod=monthArray[getMonth-1]+' '+getDate+', '+getFullYear;
  this.setState({
    recurEndwhen:getFullYear+'-'+(getMonth)+'-'+getDate+' '+this.state.time2+':'+'00',
    endRecrwhen:timePeriod,
	endRecrwhenTemp: timePeriod,
    // whenSessionTime:timePeriod,
  },()=>{{$("#calender_recurring_modal").attr({'style':'display:none'})}})  
}

recurringStartCalender=(e)=>{
  //let getFullYear=new Date(this.state.recurStartCalender).getFullYear();
	let getFullYear=moment(new Date(this.state.recurStartCalender)).format('YYYY');
	//let getMonth=(new Date(this.state.recurStartCalender).getMonth())+1;
	let getMonth=moment(new Date(this.state.recurStartCalender)).format('MM');
	//let getDate=new Date(this.state.recurStartCalender).getDate();
	let getDate=moment(new Date(this.state.recurStartCalender)).format('DD');

    let b = this.state.time2.split(':')[1];
    let a = parseInt(this.state.time2.split(':')[0]);
    b= parseInt(this.state.time2.split(':')[1]);
    let timePeriod;
   let timeofsession='';
   
   
	console.log('-------', a); 
	
	
    if(a==12 && b>=0){
      if ( b < 10 ) { b = '0'+b; }
	  
	  timeofsession=a+':'+b+ 'PM';
      a=a+':'+b+':'+'00'+ 'PM';
	  
	  console.log('-------', a); 
    }
    if(a>12 ){
      if((a-12)<10 && b<10){
		timeofsession='0'+(a-12)+':'+'0'+b+ 'PM';
        }
        if((a-12)<10 && b>=10){
        timeofsession='0'+(a-12)+':'+b+ 'PM';
        }
        if((a-12)>=10 && b<10){
        timeofsession=a-12+':'+'0'+b+ 'PM';
        }
        if((a-12)>=10 && b>=10){
        timeofsession=a-12+':'+b+ 'PM';
        }
      a=a-12+':'+b+':'+'00'+ 'PM';
    }
    if(a<12){
      if(a<10 && b<10){
        timeofsession='0'+a+':'+'0'+b+ 'AM';

        }
        if(a<10 && b>=10){
        timeofsession='0'+a+':'+b+ 'AM';
        }
        if(a>=10 && b<10){
        timeofsession=a+':'+'0'+b+ 'AM';
        }
        if(a>=10 && b>=10){
        timeofsession=a+':'+b+ 'AM';
        }
      
      a = a+':'+b+':'+'00'+ 'AM';
    }
    let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    timePeriod=monthArray[getMonth-1]+' '+getDate+', '+getFullYear+' '+timeofsession;
  this.setState({
    //recurEndwhen:getFullYear+'-'+(getMonth)+'-'+getDate+' '+this.state.time2+':'+'00',
    firstActiveRecurringSessionStartDate:timePeriod,
    // whenSessionTime:timePeriod,
  },()=>{{$("#calender_recurring_modal2").attr({'style':'display:none'})}})  
}

/**START DATE END DATE CALCULATION MULTIPLE DATE ARRAY FOR RECURRING SESSION */
getMultipleDateArray=(e)=>{

let whennTemp = '';
let whenn = '';
if ( this.state.recurStartCalender)
	{
		if ( this.state.editRecurringSession )
			{
				whennTemp = new Date(this.state.recurStartCalender);
			}
		else
			{
				whennTemp = new Date(moment(this.state.when));
			}
		
		
		whenn = new Date (whennTemp.getTime()).getFullYear() +"-"+(new Date (whennTemp.getTime()).getMonth()+1)+"-"+new Date (whennTemp.getTime()).getDate();
		
		console.log('whenn---', whenn);
	}
	
	let sliceWhen = this.state.when.split(' ')[1];
	let finalVariable = whenn+' '+sliceWhen;
	
	let awk2 = new Date(finalVariable);
	let day2=new Date(awk2).getDate();
	let month2=(new Date(awk2).getMonth())+1;
	let year2=new Date(awk2).getFullYear();
	let firstScheduleDateWithoutTime = '';
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	if ( this.formatAMPM(new Date(finalVariable)) === "00:00PM" )
		{
			firstScheduleDateWithoutTime = monthArray[month2-1]+' '+day2+', '+year2;
		}
	else 
		{
			firstScheduleDateWithoutTime = monthArray[month2-1]+' '+day2+', '+year2;
		}
	this.setState({ firstScheduleDateWithoutTime: firstScheduleDateWithoutTime});

 if(this.state.recurEndwhen===undefined){
	this.props.showMainErrorPop("Ending date is required.");
	return false;
 }
 if(this.state.mon==false && this.state.tue==false && this.state.wed==false && this.state.thu==false && this.state.fri==false && this.state.sat==false && this.state.sun==false){
	this.props.showMainErrorPop("You must select at least one day of the week to repeat.");
	return false;
 }
 
 
let endDate = new Date(this.state.recurEndwhen.replace(/-/g, '/')).getTime();
let startDate = new Date(finalVariable.replace(/-/g, '/')).getTime();

if(endDate <= startDate){
  this.props.showMainErrorPop("End Date should be greater than Start Date.");
	return false;
}

var current = new Date(finalVariable.replace(/-/g, '/'));
  var newDaycheck = new Date()
  var week = [];
  var weekDays=[];
	var first ;
	// if(current.getDay()===0){
	// 	first = current.getDate() - 7 + 1;
	// }else{
	// 	first = current.getDate() - current.getDay() + 1;
	// }
	// console.log("GET FIRST DATE-->>>>>",this.state.when);
	//console.log(/\((.*)\)/.exec(new Date().toString())[1],Intl.DateTimeFormat().resolvedOptions().timeZone);
 // current.setDate(first);

  //just test code day calculation
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  var firstDate = new Date(finalVariable.replace(/-/g, '/'));
  var secondDate = new Date(this.state.recurEndwhen.replace(/-/g, '/'));
  var diffDays = Math.round(Math.abs((secondDate-firstDate) / oneDay));

 //CALCULATE RECURRING SESSION DAYS NOT MORE THAN 120 DAYS 
  // if(diffDays > 120){
  //   this.setState({
  //     responseMessage:"Cannot exceed more than 120 Days."
  //     },()=>$("#endDateValid").attr({'style':'display:block'}))
  //     return;
  // }
  
  // var noOfDay = Math.round((this.state.recurEndwhen-current)/(1000*60*60*24));

	for (var i = 0; i <= diffDays; i++) {
    var getCurr = new Date(+current);
    
    //FOR MONDAY
    if(this.state.mon && getCurr.getDay()==1){
      week.push(getCurr); 
    }
    //FOR Tuesday
    if(this.state.tue && getCurr.getDay()==2){
      week.push(getCurr);
    }
    //FOR Wednesday
    if(this.state.wed && getCurr.getDay()==3){
      week.push(getCurr);
    }
    //FOR Thrusday
    if(this.state.thu && getCurr.getDay()==4){
      week.push(getCurr);
    }
    //FOR Friday
    if(this.state.fri && getCurr.getDay()==5){
      week.push(getCurr);  
    }
    //FOR Saturday
    if(this.state.sat && getCurr.getDay()==6){
      week.push(getCurr);
    }
    //FOR Sunday
    if(this.state.sun && getCurr.getDay()==0){
      week.push(getCurr);
    }
    //console.log("JJJJJJJ-----",new Date(+current));
     //week.push(getCurr);
   
		current.setDate(current.getDate()+1);
  }
  // week = new Date(getCurr.replace(/-/g, '/')).toUTCString();
  console.log("MMMMM---",week);
  this.setState({
    weekDaysData:week,
	chosenRecurringDays: week
  },()=>{{$("#recurring_schedule").attr({'style':'display:none'})}});

 // alert(Math.round((week[6]-newDaycheck)/(1000*60*60*24)));
//  $("#recurring_schedule").attr({'style':'display:none'});
}



reminderDate=(e)=>{
  let getFullYear=new Date(this.state.reminderCalender).getFullYear();
  let getMonth=(new Date(this.state.reminderCalender).getMonth())+1;
  let getDate=new Date(this.state.reminderCalender).getDate();
  this.setState({
    signUpDateTime:getFullYear+'-'+getMonth+'-'+getDate+' '+this.state.time+':'+'00'
  })
  
}


signUpCutOff = (cutoffStartDate, cutoffEndDate) => {
  const month = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']
  const cutoffDateTime = cutoffStartDate;
  let day;
  let year;
  let time;
  let t;
  const singUpEndDate = cutoffEndDate;
  if(this.state.singUpEndDate<cutoffEndDate){
    cutoffStartDate=cutoffEndDate
   console.log('next Date');
  }else
  { 
    console.log('previous date');
    cutoffEndDate=cutoffStartDate;
  }
  let dt2 = new Date(cutoffStartDate);
 cutoffStartDate=cutoffEndDate;
  this.setState({
    cutoffStartDate,
    cutoffEndDate,
    cutoffDateTime,
    singUpEndDate
  });
  let timeSelection =  new Date (dt2.getTime()).getHours() ;
  if(timeSelection>=13){
  timeSelection =  ((new Date (dt2.getTime()).getHours())-12) + ':' +new Date (dt2.getTime()).getMinutes()+ ':' +new Date (dt2.getTime()).getSeconds()+' PM';
  time = ((new Date (dt2.getTime()).getHours())-12)+' PM';
  }else {
  timeSelection =  new Date (dt2.getTime()).getHours() + ':' +new Date (dt2.getTime()).getMinutes()+ ':' +new Date (dt2.getTime()).getSeconds()+' AM';  
  time = new Date (dt2.getTime()).getHours()+' AM';
  }
  day = new Date (dt2.getTime()).getDate();
  year =new Date (dt2.getTime()).getFullYear();
  t= month[new Date (dt2.getTime()).getMonth()]; 
 
  dt2 = new Date (dt2.getTime()).getFullYear() +"-"+(new Date (dt2.getTime()).getMonth()+1)+"-"+new Date (dt2.getTime()).getDate()+" ";
  this.setState({
  signUpDateTime : dt2,
  reminderSessionTime :timeSelection,
  reminderMonth:t,
  reminderDay:day,
  reminderYear:year,
  reminderTime:time
  },()=>console.log('Duration ===================================>',this.state.when))
console.log('*****************',this.state.dateFormat);
}

signUpAttribute = (e) => {
  console.log(e.target.id);
  let x=2,n=0;
  console.log('e.target.id',e.target.id);
  let attributeArray = this.state.signUpRepeatSession;
  let classArray = this.state.signUpClass;
  for(let i =0 ;i<attributeArray.length;i++){
    if(e.target.id == attributeArray[i]){
     x=1;n=i;
    }
  }

  if(x==1){
    attributeArray.splice(n,1);
    classArray[e.target.name] = false;
    this.setState({
      signUpRepeatSession:attributeArray,
      signUpClass:classArray
      },()=>
      { console.log('add Attribute==>',this.state.signUpRepeatSession);
    });
  }
  else{
    attributeArray.push(e.target.id);
    classArray[e.target.name] = true;
    this.setState({
      signUpRepeatSession:attributeArray,
      signUpClass:classArray
      },()=>
      { console.log(this.state.signUpClass,'add Attribute==>',this.state.signUpRepeatSession);
    });
  }
  }
  sessionAttribute = (e) => {
    console.log(e.target.id);
    let x=2,n=0;
    console.log('e.target.id',e.target.id);
    let attributeArray = this.state.sessionAttribute;
    let classArray = this.state.sessionClass;
    for(let i =0 ;i<attributeArray.length;i++){
      if(e.target.id == attributeArray[i]){
       x=1;n=i;
      }
    }
  
    if(x==1){
      attributeArray.splice(n,1);
      classArray[e.target.name] = false;
      this.setState({
        sessionAttribute:attributeArray,
        sessionClass:classArray
        },()=>
        { console.log('add Attribute==>',this.state.sessionAttribute);
      });
    }
    else{
      attributeArray.push(e.target.id);
      classArray[e.target.name] = true;
      this.setState({
        sessionAttribute:attributeArray,
        sessionClass:classArray
        },()=>
        { console.log(this.state.sessionClass,'add Attribute==>',this.state.sessionAttribute);
      });
    }
  
  }
saveSessionCalenderDetail = (e) =>{
  console.log(this.state.sessionAttribute,this.state.sessionDuration,this.state.sessionFrequency);
}    
saveSignUpCalender = (e) => {
 console.log(this.state.signUpRepeatSession,this.state.signUpDuration,this.state.signUpFrequency);
}
onChange = (startDate, endDate) => {
  const month = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']
  let day;
  let year;
  let time;
  let t;
  const dateFormat = endDate;
 // let dt = new Date(startDate).toUTCString();
 console.log(new Date(startDate),'compare==========',new Date(endDate))
 if(this.state.dateFormat<endDate){
   startDate=endDate
  console.log('next Date');
 }else{console.log('previous date');
endDate=startDate
}
  //let dt2 = new Date(startDate);
  // dt = dt.split('GMT')
//  endDate = startDate;
 // startDate=endDate;
  let dt2 = new Date(startDate);
  this.setState({ startDate, endDate,dateFormat },
  ()=>console.log('sds',this.state.startDate,this.state.endDate));
 // let dt2 = new Date(endDate);
   let timeSelection =  new Date (dt2.getTime()).getHours() ;
  if(timeSelection>=13){
  timeSelection =  ((new Date (dt2.getTime()).getHours())-12) + ':' +new Date (dt2.getTime()).getMinutes()+ ':' +new Date (dt2.getTime()).getSeconds()+' PM';
  time = ((new Date (dt2.getTime()).getHours())-12)+' PM';
  }else {
  timeSelection =  new Date (dt2.getTime()).getHours() + ':' +new Date (dt2.getTime()).getMinutes()+ ':' +new Date (dt2.getTime()).getSeconds()+' AM';  
  time = new Date (dt2.getTime()).getHours()+' AM';
  }
  day = new Date (dt2.getTime()).getDate();
  year =new Date (dt2.getTime()).getFullYear();
  t= month[new Date (dt2.getTime()).getMonth()]; 
 
  dt2 = new Date (dt2.getTime()).getFullYear() +"-"+(new Date (dt2.getTime()).getMonth()+1)+"-"+new Date (dt2.getTime()).getDate()+" ";
  this.setState({
  when : dt2,
  whenTime :timeSelection,
  sessionMonth:t,
  sessionDay:day,
  sessionYear:year,
  sessionTime:time
  },()=>console.log('Duration ===================================>',this.state.when))
console.log('*****************',this.state.dateFormat);

//   let dt = new Date(startDate).toUTCString();

//   console.log(typeof(startDate),'dt==',dt.split('GMT'));
//   dt = dt.split('GMT')
//   this.setState({ startDate, endDate },
// ()=>console.log('sds',this.state.startDate,this.state.endDate))


// this.setState({
//   when : dt[0]
// },()=>console.log('Duration ===================================>',this.state.when))
// console.log('*****************',dt);
}

////////////set data
sessionInfo = e =>{
  this.setState({
      [e.target.id] : e.target.value,
      errormsg:'',
     //@ ak commented was here
  })

	if ( e.target.id == 'sessionMinute' || e.target.id == 'sessionHour' )
		{
			this.setState({
				errorClass_Duration: false,
			})
		}
	if ( this.state.groupId === 1 )
		{
			let mainthis = this;
			setTimeout(function(){ mainthis.calculateTotalActivity(); }, 100);
		}
  
	
}

checkManualAutomaticScript = () => {
	/*check manualAutomaticScript*/
	/*run this function on Edit Class or Create Class/Copy from Previous*/
	/*this already work on Copy Workout from Previous*/
	if (!this.state.manualAutomaticScript)
		{
			if ( this.state.activityType && this.state.activityType.length > 0 )
				{
					for ( var i = 0; i <this.state.activityType.length; i++ )
						{
							
							if (this.state.activityType[i]['activity_type'] == 'Pause')
								{
									this.state.activityType.splice(i,1);
								}
						}
				}
		}
}

updateStateFromChild = (newState, mod = false) => {
	
	if (newState['manualAutomaticScript'])
		{
			var newactivitytype=[{id: 1, interestId: 2, activity_type: "Aerobic",  status: 1},{id: 2, interestId: 2, activity_type: "Balance",  status: 1},{id: 3, interestId: 2, activity_type: "Cardio",  status: 1}, {id: 4, interestId: 2, activity_type: "Flexibility", status: 1},{id: 5, interestId: 2, activity_type: "Rest", status: 1},{id: 6, interestId: 2, activity_type: "Strength", status: 1},{id: 7, interestId: 2, activity_type: "Stretching", status: 1},{id: 8, interestId: 2, activity_type: "Warm Up", status: 1},{id: 9, interestId: 2, activity_type: "Mobility", status: 1},{id: 10, interestId: 2, activity_type: "Core", status: 1},{id: 11, interestId: 2, activity_type: "Foam Rolling", status: 1},{id: 12, interestId: 2, activity_type: "Pause", status: 1},{id: 13, interestId: 2, activity_type: "Virtual Equipment",  status: 1}/* ,{id: 14, interestId: 2, activity_type: "Virtual Assistant",  status: 1} */];
			if ( this.state.isMarketplaceChannel )
				{
					newactivitytype=[{id: 1, interestId: 2, activity_type: "Regular",  status: 1}, {id: 5, interestId: 2, activity_type: "Rest", status: 1}, {id: 12, interestId: 2, activity_type: "Pause", status: 1},{id: 13, interestId: 2, activity_type: "Virtual Equipment",  status: 1},{id: 14, interestId: 2, activity_type: "Pause Virtual",  status: 1}];
				}
			this.setState({
				activityType: newactivitytype
				});
		}
	if ( mod ) 
		{
			if ( this.state.manualAutomaticScript )
				{
					for ( var i = 0; i <this.state.activityType.length; i++ )
						{
							
							if (this.state.activityType[i]['activity_type'] == 'Pause')
								{
									this.state.activityType.splice(i,1);
								}
						}
				}
		}		
		
	if ( newState['ActivityType'] && ( newState['ActivityType'] === "Foam Rolling" || newState['ActivityType'] === 'Pause' ))
		{
			newState['DurationType'] = "Time";
		}
	
	if ( newState['ActivityName'] )
		{
			for ( var i = 0; i<this.state.tablerows.length; i++ )
				{
					let activity = this.state.tablerows[i];
					if ( activity['ActivityName'] == newState['ActivityName'] )
						{
							this.setState({
								ActivityDisplayName: activity['ActivityDisplayName'],
							});
						}
				}
		}
	
	let charactesLeft = 0;
	
	if ( newState['ActivityName'] || newState['ActivityName'] == '' )
		{
			let currentNum = newState['ActivityName'].length;
			charactesLeft = this.state.ActivityLengthAvailable - currentNum;
			this.setState({
				ActivityLengthLeft: charactesLeft,
			})	
		}
	
	if ( newState['ActivityDisplayName'] || newState['ActivityDisplayName'] == '' )
		{
			let currentNum = newState['ActivityDisplayName'].length;
			charactesLeft = this.state.ActivityLengthAvailable - currentNum;
			this.setState({
				ActivityDisplayLengthLeft: charactesLeft,
			})	
		}
	
	if ( newState['ActivityDisplayNameEdit'] || newState['ActivityDisplayNameEdit'] == '' )
		{
			let currentNum = newState['ActivityDisplayNameEdit'].length;
			charactesLeft = this.state.ActivityLengthAvailable - currentNum;
			this.setState({
				ActivityDisplayLengthLeftEdit: charactesLeft,
			})	
		}

	let notesCharactesLeft = 0;
	if ( newState['ActivityNotes'] || newState['ActivityNotes'] == '' )
		{
			let currentNum = newState['ActivityNotes'].length;
			notesCharactesLeft = this.state.notesNumAvailable - currentNum;
			this.setState({
				noteNumLeft: notesCharactesLeft,
			})	
		}	

	let notesEditCharactersLeft = 0;
	if ( newState['ActivityNotesEdit'] || newState['ActivityNotesEdit'] == '' )
		{
			let currentNum = newState['ActivityNotesEdit'].length;
			notesEditCharactersLeft = this.state.notesNumAvailable - currentNum;
			this.setState({
				noteEditNumLeft: notesEditCharactersLeft,
			})	
		}		
	
	
	let nameCharactersLeft = 0;
	if ( newState['session_details'] || newState['session_details'] == '' )
		{
			let currentNum = newState['session_details'].length;
			nameCharactersLeft = this.state.nameNumAvailable - currentNum;
			this.setState({
				nameNumLeft: nameCharactersLeft,
			})
		}

	let nameEditChrLeft = 0;
	if ( newState['ActivityNameEdit'] || newState['ActivityNameEdit'] == '' )
		{
			let currentNum = newState['ActivityNameEdit'].length;
			nameEditChrLeft = this.state.nameNumAvailable - currentNum;
			this.setState({
				nameEditNumLeft: nameEditChrLeft,
			})	
		}
		
	this.setState(newState);
	
	setTimeout( () => {
	if (!this.state.manualAutomaticScript)
		{
			if ( this.state.tablerows.length > 0 )
			{
				let currentArray = this.state.tablerows;
				for ( var i = 0; i < currentArray.length; i++ )
					{
						if ( currentArray[i]['ActivityType'] == "Pause" )
							{
								currentArray.splice(i, 1);
							}
					}
				this.setState({
					tablerows: currentArray,
				})
			}
		}
	}, 300);
	
}
///////////////Add row Activity table
addRow = (e, enterKey) =>{
	
   let mainthis = this;
   
  // add new data from here 
   if (this.scriptValidate.allValid()) {
	   let actName = this.state.ActivityName.trim();
	   
	   if ( this.state.ActivityName === '' || actName.length == 0 )
		   {
			    if (!enterKey)
					{
						this.props.showMainErrorPop("Activity name should not be empty.");
					}
				return false;
		   }
	   
	   if ( this.state.ActivityType == 'Virtual Equipment' && !this.state.ActivityDisplayName )
		   {
			    if (!enterKey)
					{
						this.props.showMainErrorPop("Activity Display name should not be empty.");
					}
				return false;
		   }
		  
	   if ( this.state.DurationType === '' )
		   {
			   if (!enterKey)
					{
						this.props.showMainErrorPop("Activity duration type should not be empty.");
					}
				return false;
		   }

	if ( ( mainthis.state.DurationType == "Time" && parseInt(this.state.activityHour) == 0 && parseInt(this.state.activityminutes) == 0 ) || ( mainthis.state.DurationType !== "Time" && ( parseInt(this.state.Count) == 0 || isNaN(parseInt(this.state.Count)) || this.state.repsSeconds == '' || this.state.repsSeconds === 0 ) ) )
		{
			if (!enterKey)
				{
					this.props.showMainErrorPop("Activity time should not be zero.");
				}
		}
	else
	    {
			let autoAddPauseVirtual = false;
			if ( mainthis.state.ActivityType == 'Virtual Equipment' )
				{
					let virtualNames = [];
					for ( let i=0; i<mainthis.state.tablerows.length; i++ )
						{
							virtualNames.push(mainthis.state.tablerows[i]['ActivityName']);
						}
					let lengthTableRows = mainthis.state.tablerows.length;
					if ( virtualNames.indexOf(mainthis.state.ActivityName) == -1 && ( lengthTableRows == 0 || ( lengthTableRows > 0 && mainthis.state.tablerows[lengthTableRows-1]['ActivityType'] != 'Pause Virtual' )))
						{
							autoAddPauseVirtual = true;
						}					
				}
			
			if ( mainthis.state.DurationType==="Time" )
				{
					let time = 1;
					if ( autoAddPauseVirtual ) { time = 24; } 
					let canAddThisWorkout = mainthis.checkAllWorkoutDuration(time, false);
					if ( !canAddThisWorkout )
						{
							mainthis.setState({
									finalClassDurationMinutes: (parseInt(this.state.sessionHour)*60) + parseInt(this.state.sessionMinute)
								}, ()=>{ $('#workoutDurationError').attr({'style':'display:block'});})
							return false;
						}
				}
			
			$('.main_loader').attr({'style':'display:flex'});
			this.getVideoDuration(this.state.Video.trim()).then(function (dur)
			{
				$('.main_loader').hide();
				
				var fmStationName = '';
				for ( var i = 0; i < mainthis.state.fmStations.length; i++ )
					{
						if ( mainthis.state.chosenFMStation == mainthis.state.fmStations[i].id )
							{
								fmStationName = mainthis.state.fmStations[i].name;
							}
					}
				
				var newdata = {
								ActivityName:mainthis.state.ActivityName.trim(),
								ActivityDisplayName: mainthis.state.ActivityDisplayName ? mainthis.state.ActivityDisplayName.trim() : '',
								ActivityType:mainthis.state.ActivityType,
								DurationType:mainthis.state.DurationType,
								Count:mainthis.state.DurationType==="Time"?parseInt((mainthis.state.activityHour*60))+parseInt(mainthis.state.activityminutes) : parseInt(mainthis.state.Count), //mainthis.state.Count,
								Repeat:mainthis.state.Count,
								repsSeconds: mainthis.state.DurationType==="Time" ? 0 : mainthis.state.repsSeconds,
								Video:mainthis.state.Video.trim(),
								TargetBPM:mainthis.state.TargetBPM,
								TargetZone:mainthis.state.TargetZone,
								VideoRepeat:mainthis.state.VideoRepeat ? true : false,
								VideoDuration: dur,
								sessionScriptId: 'new_'+(new Date().getTime()),
								fmStationId: mainthis.state.chosenFMStation,
								fmStationName: fmStationName,
								ActivityNotes: mainthis.state.ActivityNotes
							};
							
				if ( autoAddPauseVirtual )
					{
						
						let newData = JSON.parse(JSON.stringify(newdata));
						newData['ActivityName'] = 'Demo '+ newdata['ActivityName'];
						newData['ActivityType'] = 'Pause Virtual';
						newData['ActivityDisplayName'] = '';
						newData['Count'] = 20;
						newData['Video'] = '';
						newData['VideoDuration'] = 0;
						newData['sessionScriptId'] = 'new_'+((new Date().getTime())-1);
						mainthis.setState({
								tablerows: mainthis.state.tablerows.concat(newData)
							});
					}		
				
			  mainthis.setState({
				  tablerows: mainthis.state.tablerows.concat(newdata),
				  ActivityName: '',
				  ActivityDisplayName: '',
				  ActivityType: mainthis.state.ActivityType,
				  DurationType: mainthis.state.isMarketplaceChannel ? 'Time' : '',
				  Count: 10,
				  repsSeconds: mainthis.state.repsDefaultTime ? mainthis.state.repsDefaultTime : '',
				  activityminutes: 0,
				  activityHour: 0,
				  Video: '',
				  ActivityNotes: '',
				  VideoRepeat: false,
				  VideoDuration: 0,
				  chosenFMStation: "",
				  ActivityLengthLeft: 30,
				  ActivityDisplayLengthLeft: 30,
				  noteNumLeft: 200
			   }, ()=>{ 
					if ( autoAddPauseVirtual )
						{
							$('#autoAddPauseVirtual').attr({'style':'display:block'});
						}
			   });
			  
			  }, function ()
			  {
				  $('.main_loader').hide();
				 mainthis.props.showMainErrorPop('Wrong video.');
			  });
		}
   }
   setTimeout(function(){ mainthis.calculateTotalActivity();}, 100);

}

checkAllWorkoutDuration =(pauseVirtualTime, isEditing)=>{
	let alreadyAddedWorkoutTime = parseInt(this.state.activityTotalTime*60) + parseInt(this.state.activityTotalTimeSeconds);
	let newWorkout = isEditing ? parseInt((this.state.activityHourEdit*60))+parseInt(this.state.activityminutesEdit)+parseInt(pauseVirtualTime) : parseInt((this.state.activityHour*60))+parseInt(this.state.activityminutes)+parseInt(pauseVirtualTime);
	var sessionDurationMinutes = (parseInt(this.state.sessionHour)*60) + parseInt(this.state.sessionMinute);
	let sessionDurationInSeconds = sessionDurationMinutes*60;
	
	let result = true;
	if ( ((sessionDurationInSeconds+30) - (alreadyAddedWorkoutTime+newWorkout)) < 0 )
		{
			result = false;
		}
		
	return result;
}

saveEditActivity=()=>{
	let mainthis = this;
	
	let actName = this.state.ActivityNameEdit.trim();
	
	if ( this.state.ActivityNameEdit === '' || actName.length == 0 )
		   {
				this.props.showMainErrorPop("Activity name should not be empty.");
				return false;
		   }
	
   if ( this.state.ActivityTypeEdit == 'Virtual Equipment' && !this.state.ActivityDisplayNameEdit )
	   {
			this.props.showMainErrorPop("Activity Display name should not be empty.");
			return false;
	   }
		  
	if ( this.state.DurationTypeEdit === '' )
	   {
			this.props.showMainErrorPop("Activity duration type should not be empty.");
			return false;
	   }
	
	if ( this.state.DurationTypeEdit === "Time" )
		{
			var previousTime = (parseInt(this.state.activityHourEditBackup)*60) + parseInt(this.state.activityminutesEditBackup);
			
			let canAddThisWorkout = mainthis.checkAllWorkoutDuration(previousTime*-1, true);
			if ( !canAddThisWorkout )
				{
					this.setState({
							finalClassDurationMinutes: (parseInt(this.state.sessionHour)*60) + parseInt(this.state.sessionMinute)
						}, ()=>{ $('#workoutDurationError').attr({'style':'display:block'});})
					return false;
				}
		}
	   
	if ( ( mainthis.state.DurationTypeEdit == "Time" && parseInt(this.state.activityHourEdit) == 0 && parseInt(this.state.activityminutesEdit) == 0 ) || ( mainthis.state.DurationTypeEdit !== "Time" && ( parseInt(this.state.CountEdit) == 0 || isNaN(parseInt(this.state.CountEdit)) ) ) )
		{
			this.props.showMainErrorPop("Activity time should not be zero.");
		}
		
	else
		{
			$('.main_loader').attr({'style':'display:flex'});
			this.getVideoDuration(this.state.VideoEdit.trim()).then(function (dur)
				{
					var fmStationName = '';
					for ( var i = 0; i < mainthis.state.fmStations.length; i++ )
						{
							if ( mainthis.state.chosenFMStationEdit == mainthis.state.fmStations[i].id )
								{
									fmStationName = mainthis.state.fmStations[i].name;
								}
						}
					
					$('.main_loader').hide();
					let tokenArray=mainthis.state.tablerows;
					tokenArray[mainthis.state.editActivityToken].ActivityName=mainthis.state.ActivityNameEdit.trim();
					tokenArray[mainthis.state.editActivityToken].ActivityDisplayName= mainthis.state.ActivityTypeEdit == 'Virtual Equipment' ? mainthis.state.ActivityDisplayNameEdit.trim() : '';
					tokenArray[mainthis.state.editActivityToken].ActivityType=mainthis.state.ActivityTypeEdit;
					tokenArray[mainthis.state.editActivityToken].DurationType=mainthis.state.DurationTypeEdit;
					tokenArray[mainthis.state.editActivityToken].Count=mainthis.state.DurationTypeEdit==="Time"?parseInt((mainthis.state.activityHourEdit*60))+parseInt(mainthis.state.activityminutesEdit) : parseInt(mainthis.state.CountEdit);
					tokenArray[mainthis.state.editActivityToken].Repeat=mainthis.state.CountEdit;
					tokenArray[mainthis.state.editActivityToken].repsSeconds= mainthis.state.DurationTypeEdit === "Time"? 0 : mainthis.state.repsSecondsEdit;
					tokenArray[mainthis.state.editActivityToken].Video=mainthis.state.VideoEdit.trim();
					tokenArray[mainthis.state.editActivityToken].TargetBPM=mainthis.state.tablerows[mainthis.state.editActivityToken].TargetBPM;
					tokenArray[mainthis.state.editActivityToken].TargetZone=mainthis.state.tablerows[mainthis.state.editActivityToken].TargetZone;
					tokenArray[mainthis.state.editActivityToken].VideoRepeat=mainthis.state.VideoRepeatEdit ? true : false;
					tokenArray[mainthis.state.editActivityToken].VideoDuration=dur;
					tokenArray[mainthis.state.editActivityToken].sessionScriptId=mainthis.state.tablerows[mainthis.state.editActivityToken].sessionScriptId;
					tokenArray[mainthis.state.editActivityToken].fmStationId=mainthis.state.chosenFMStationEdit;
					tokenArray[mainthis.state.editActivityToken].fmStationName=fmStationName;
					tokenArray[mainthis.state.editActivityToken].ActivityNotes=mainthis.state.ActivityNotesEdit;

					mainthis.setState({ 
							tablerows: tokenArray,
							ActivityNameEdit: '',
							ActivityDisplayNameEdit: '',
							ActivityTypeEdit: '',
							DurationTypeEdit: '',
							CountEdit: 0,
							repsSecondsEdit: 0,
							RepeatEdit: '',
							activityHourEdit: 0,
							activityminutesEdit: 0,
							VideoEdit: '',
							VideoRepeatEdit: false,
							editActivityToken: -1,
							chosenFMStationEdit: ''
					});
					
				 }, function ()
				 {
						$('.main_loader').hide();
						mainthis.props.showMainErrorPop('Wrong video.');
				 });
		}
		
	setTimeout(function(){ mainthis.calculateTotalActivity(); }, 100);
}



getVideoDuration(videoURL)
{
	return new Promise(function (resolve, reject) {
		if ( videoURL == '' ) { resolve(0); return false; }
	
		var video = document.createElement('video');
		video.preload = 'metadata';

		video.onloadedmetadata = function() { resolve(video.duration); }
		video.onerror = function(e) { console.log('Video error', e); reject('error'); }

		video.src = videoURL;
	});
}

fancyTimeFormat(duration)
	{   
		// Hours, minutes and seconds
		var hrs = ~~(duration / 3600);
		var mins = ~~((duration % 3600) / 60);
		var secs = ~~duration % 60;

		// Output like "1:01" or "4:03:59" or "123:03:59"
		var ret = "";
		let hoursMins = 0;
		if (hrs > 0) {
			ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
			hoursMins = hrs*60;
		}

		ret += "" + mins + ":" + (secs < 10 ? "0" : "");
		ret += "" + secs;
		if ( hoursMins > 0 ) { mins = mins+hoursMins;}
		return [mins, secs];
	}
		  
calculateTotalActivity=()=>{
	var allActivityDurationSeconds = 0;
	var allActivityArray = this.state.tablerows;
	
	$.each(allActivityArray, function( a, b ) {
		
		if(b['DurationType'] == 'Time')
			{
				allActivityDurationSeconds = allActivityDurationSeconds + parseInt(b['Count']);
			}
		else
			{
				allActivityDurationSeconds = allActivityDurationSeconds + ( parseInt(b['Count'])*parseInt(b['repsSeconds']) );
			}
		
	});
	
	var pauseTime = 0;
	for ( var i = 0; i < allActivityArray.length; i++ )
		{
			if ( allActivityArray[i]['ActivityType'] == 'Virtual Equipment' )
				{
					pauseTime = pauseTime + 2;
				}
			else if ( allActivityArray[i]['ActivityType'] == 'Pause Virtual')
				{
					pauseTime = pauseTime + 2;
				}				
			else 
				{
					pauseTime = pauseTime + 1;
				}				
		}
	
	var totalScriptTime = allActivityDurationSeconds + pauseTime;
	
	var formatPauseTime = this.fancyTimeFormat(pauseTime);
	var formatTotalScriptDuration = this.fancyTimeFormat(totalScriptTime);
	
	var allActivityDurationMinutes = this.fancyTimeFormat(allActivityDurationSeconds);
	
	var sessionDurationMinutes = (parseInt(this.state.sessionHour)*60) + parseInt(this.state.sessionMinute);
	
	var remainingTime = sessionDurationMinutes - formatTotalScriptDuration[0];
	
	var remainingTime2 = 0;
	if ( formatTotalScriptDuration[1] > 0 )
		{
			remainingTime--;
			remainingTime2 = 60 - formatTotalScriptDuration[1];
			
			if ( remainingTime2 < 0 ) { remainingTime2 = 0; }
		}
	
	if ( remainingTime < 0 ) { remainingTime = 0; }
	
	var activitySecondsAll = (allActivityDurationMinutes[0] * 60) + allActivityDurationMinutes[1];
	var sessionDurationSeconds = (parseInt(this.state.sessionHour)*60) + parseInt(this.state.sessionMinute) * 60;

	if ( activitySecondsAll >= sessionDurationSeconds ) { remainingTime = 0; remainingTime2 = 0; }
	
	 this.setState({
			activityTotalTime: formatTotalScriptDuration[0],
			activityTotalTimeSeconds: formatTotalScriptDuration[1],
			activityDurationTotalMin: allActivityDurationMinutes[0],
			activityDurationTotalSec: allActivityDurationMinutes[1],
			activityPauseTotalTimeMin: formatPauseTime[0],
			activityPauseTotalTimeSec: formatPauseTime[1],
			activityRemainingTime: remainingTime,
			activityRemainingTime2: remainingTime2
	 });	  
}
      
getSessionTime = (date1)=>{
//	let ab = new Date(date1).getTime();
	// console.log(date1);
	// console.log(">>>>>>>>>>>>>>>>.aa",new Date(date1),new Date ('2020-1-24 04:03:00').getUTCMilliseconds(),new Date (ab).getUTCHours(),new Date (ab).getUTCMinutes(),"==========UTC=====",new Date (ab-19800000),">>>>>>>>>>>",new Date(ab+19800000).getHours());
	//console.log('-----------------',new Date(date1).getHours()+new Date(date1).getMinutes())
	let jk=new Date('2020-01-27T12:13:28.000Z').getTime();
//	console.log("----------UTC Time 2020-01-27T12:13:28.000Z",new Date(jk+19800000).getUTCHours());
	// let hour=new Date(ab).getUTCHours();
	// let minute=new Date(ab).getUTCMinutes();
	let hour=new Date(date1).getHours();
	let minute = new Date(date1).getMinutes();
	//let year=new Date(ab+19800000).getFullYear();
	let sessionTime= hour+':'+minute;
	//let ak= <p className="mb-4"><img src="/images/gray-icons/date.png" className="mr-3"  alt="" />Cut off date {sessionDate}</p>
  //arr.push(<option key={i} value={i}>{i}</option>)
  if(minute<10){
    minute='0'+minute;
  }
	if(hour<12 && hour<10){
	sessionTime ='0'+hour+':'+minute+" " +'AM'
	return sessionTime ;
  }
  if(hour<12 && hour>=10){
    sessionTime =hour+':'+minute+" " +'AM'
    return sessionTime ;
    }
    if(hour>=12 && (hour-12)===0){
      sessionTime=(hour)+':'+minute+" " +'PM'
       return sessionTime ;
      }  
  if(hour>=12 && (hour-12)>=10){
	sessionTime=(hour-12)+':'+minute+" " +'PM'	
	return sessionTime ;
  }
  if(hour>=12 && (hour-12)<10){
    sessionTime='0'+(hour-12)+':'+minute+" " +'PM'	
    return sessionTime ;
    }    
  }
 getSessionDate=(date1)=>{
   let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
	let ab = new Date(date1).getTime();
	let day=new Date(date1).getDate();
	let month=(new Date(date1).getMonth())+1;
	let year=new Date(date1).getFullYear();
	let sessionDate= monthArray[month-1]+' '+day+'\, '+year;
	return sessionDate; 
	  
	
	
}

updateMainInput = (inp) => {
	this._input = inp;
}

//////////////remove Activity
removeActivity = (e) => {
  var dataArray = this.state.tablerows;
  dataArray.splice(e, 1);
  this.setState({
    tablerows:dataArray
  })
  
   let mainthis = this;
   setTimeout(function(){ mainthis.calculateTotalActivity(); }, 100);
}
editActivity = (e) => {

  var dataArray = this.state.tablerows;
  var activityArray = JSON.parse(JSON.stringify(this.state.tablerows[e]));

  let tokenValue=e;
  let minutes;
  let seconds;
  if (activityArray.Count>59){
    minutes=Math.floor((activityArray.Count)/60);
    seconds=activityArray.Count%60;
  }else{
    minutes=0;
    seconds=activityArray.Count;
  }
	
	let nameEditLeft = 0;
	nameEditLeft = this.state.nameNumAvailable - activityArray.ActivityName.length;
	
	let nameDEditLeft = 0;
	if ( activityArray.ActivityDisplayName ) { nameDEditLeft = this.state.nameNumAvailable - activityArray.ActivityDisplayName.length; }
	
	let notesNumLeft = 0;
	notesNumLeft = this.state.notesNumAvailable - activityArray.ActivityNotes.length;
 
	let stationName = activityArray.fmStationName;
	let fmStationId = undefined;
	
	for ( var a = 0; a < this.state.fmStations.length; a++ )
		{
			let currentStation = this.state.fmStations[a];
			if ( currentStation['name'] == stationName )
				{
					fmStationId = currentStation['id'];
				}
		}

  this.setState({
    tablerows:dataArray,
    ActivityNameEdit: activityArray.ActivityName,
    ActivityDisplayNameEdit: activityArray.ActivityDisplayName,
    ActivityNotesEdit: activityArray.ActivityNotes,
    ActivityTypeEdit:activityArray.ActivityType,
    DurationTypeEdit:activityArray.DurationType,
    CountEdit:activityArray.Count,
    repsSecondsEdit:activityArray.repsSeconds,
    RepeatEdit:activityArray.Repeat,
    activityHourEdit:minutes,
    activityminutesEdit:seconds,
    activityHourEditBackup:minutes,
    activityminutesEditBackup:seconds,
    editActivityToken:tokenValue,
    VideoEdit:activityArray.Video,
    VideoRepeatEdit:activityArray.VideoRepeat ? true : false,
	chosenFMStationEdit: fmStationId,
	noteEditNumLeft: notesNumLeft,
	nameEditNumLeft: nameEditLeft,
	ActivityDisplayLengthLeftEdit: nameDEditLeft,
  })
}

closeEditActivity=()=>{
	this.setState({
			ActivityNameEdit: '',
			ActivityDisplayNameEdit: '',
			ActivityTypeEdit: '',
			DurationTypeEdit: '',
			CountEdit: 0,
			repsSecondsEdit: 0,
			RepeatEdit: '',
			activityHourEdit: 0,
			activityminutesEdit: 0,
			VideoEdit: '',
			VideoRepeatEdit: false,
			editActivityToken: -1,
			chosenFMStationEdit: ''
	  })
}

// Copy Activity
CopyActivity = (e) => {
  var activityArray=this.state.tablerows[e];
    var newdata = {
		ActivityName: activityArray.ActivityName,
		ActivityDisplayName: activityArray.ActivityDisplayName,
		sessionScriptId: Date.now() + "" + Math.floor(Math.random() * 1000000000000),
		ActivityType: activityArray.ActivityType,
		DurationType: activityArray.DurationType,
		Count: activityArray.Count,
		repsSeconds: activityArray.repsSeconds,
		Repeat: activityArray.Repeat,
		Video: activityArray.Video,
		videoName: this.state.videoName,
		TargetBPM: this.state.TargetBPM,
		TargetZone: this.state.TargetZone,
		VideoDuration: activityArray.VideoDuration ? activityArray.VideoDuration : 0,
		VideoRepeat: activityArray.VideoRepeat ? true : false,
		fmStationId: activityArray.fmStationId,
		fmStationName: activityArray.fmStationName,
		ActivityNotes: activityArray.ActivityNotes,
	  }
	  
    //if ( this._input ) { this._input.focus(); }
	
	let tempData = this.state.tablerows;
	let newArray = [];
	/*
	for (let i=0; i<tempData.length; i++)
		{
			newArray.push(tempData[i]);
			if ( i == e )
				{
					newArray.push(newdata);
				}
		}
	*/
	tempData.push(newdata);
  //take the existing state and concat the new data and set the state again    
  this.setState({ tablerows: tempData,
  ActivityName:'',
  ActivityDisplayName:'',
  ActivityType: this.state.isMarketplaceChannel ? 'Regular' : 'Aerobic',
  DurationType: this.state.isMarketplaceChannel ? 'Time' : '',
  Count:'',
  repsSeconds:'',
  activityminutes:0,
  activityHour:0,
  Video:'',
  VideoRepeat: false,
  ActivityLengthLeft: 30,
  ActivityDisplayLengthLeft: 30,
   });
  
  window.scrollTo(10, document.body.scrollHeight);
  
	let mainthis = this;
   setTimeout(function(){ 
		mainthis.calculateTotalActivity(); 
		}, 100);
}

////////////////// shopping List
selectShoppingList =(e)=> {
  let shoppingContainer = this.state.shoppingList;
  shoppingContainer[e.target.id].type = !shoppingContainer[e.target.id].type;
  if(shoppingContainer[e.target.id].type) {
  } 
  else {
    shoppingContainer[e.target.id].Quantity = '';
    shoppingContainer[e.target.id].itemNote = '';
    shoppingContainer[e.target.id].Link = '';
    let arrayCheck = [];
    if(this.state.shoppingList1.length>0){
     for(let i=0;i<this.state.shoppingList1.length;i++){
        if(this.state.shoppingList1[i].name === shoppingContainer[e.target.id].name){
          arrayCheck = this.state.shoppingList1;
          arrayCheck[i] = shoppingContainer[e.target.id];
          this.setState({
            shoppingList1 : arrayCheck 
          },()=> console.log('check or uncheck shoppingList', this.state.shoppingList1))
        }
      }
    }
  }
  this.setState({
    shoppingList : shoppingContainer,
    },()=>
    { console.log('setEuipmentContainer==>',this.state.shoppingList);
      });
}
handleShoppingQuantity = idx => evt => {
  const newShareholders = this.state.shoppingList.map((shareholder, sidx) => {
    if (idx !== sidx) return shareholder;
    return { ...shareholder, Quantity: evt.target.value };
  });

  this.setState({ shoppingList: newShareholders },()=> {
    console.log('equipmentList',this.state.shoppingList[idx].Quantity)
  }
  );
}
handleShoppingitemNote= idx => evt => {
  const newShareholders = this.state.shoppingList.map((shareholder, sidx) => {
    if (idx !== sidx) return shareholder;
    return { ...shareholder, itemNote: evt.target.value };
  });

  this.setState({ shoppingList: newShareholders },()=> {
    console.log('item Note',this.state.shoppingList[idx].itemNote)
  }
  );
}
handleShoppingLink = idx => evt => {
  const newShareholders = this.state.shoppingList.map((shareholder, sidx) => {
    if (idx !== sidx) return shareholder;
    return { ...shareholder, Link: evt.target.value };
  });

  this.setState({ shoppingList: newShareholders },()=> {
    console.log('item Note',this.state.shoppingList[idx].Link)
  }
  );
}
setShoppingList = (e) =>{
 if (this.state.duplicateShoppingList.length>0) { 
    this.setState({shoppingListValue:'',
    shoppingList:this.state.duplicateShoppingList,
    duplicateShoppingList : []
    },()=>console.log('setShoppingList',this.state.shoppingList))
  }
}
addToShoppingList = () => {
  if (this.shoppingValidator.allValid()) {
    console.log(this.state.shoppingListValue,'****************************************************',this.state.duplicateShoppingList);
    let addToShoppingListArray = [];
    let ka = [];
    if((this.state.duplicateShoppingList.length > 0 && this.state.shoppingList.length>0) && this.state.shoppingListValue !== "" ){
    console.log('Search part');
    let x =0 ,n=0;
    for(let i =0 ;i<this.state.duplicateShoppingList.length;i++){
      if(this.state.duplicateShoppingList[i].name === this.state.shoppingList[0].name){
        //this.state.duplicateList[i].Quantity=this.state.equipmentList[0].Quantity  ;
        addToShoppingListArray = this.state.duplicateShoppingList;
        addToShoppingListArray[i].Quantity=this.state.shoppingList[0].Quantity;
        addToShoppingListArray[i].itemNote=this.state.shoppingList[0].itemNote;
        addToShoppingListArray[i].Link=this.state.shoppingList[0].Link;
        this.setState({
          duplicateShoppingList : addToShoppingListArray
        })
      console.log(this.state.duplicateShoppingList,'matched*********************',this.state.shoppingList);  
      }
    }
    // checking for new insertion or update
    for(let i =0;i<this.state.shoppingList1.length;i++){
      if(this.state.shoppingList1[i].name === this.state.shoppingList[0].name){
        x=1;n=i;
        console.log('Search ---------------Update');
      
     }
    }
    if(x===1){
      //this.state.equipmentList1[n].Quantity=this.state.equipmentList[0].Quantity // update
      addToShoppingListArray = this.state.shoppingList1; 
      addToShoppingListArray[n].Quantity=this.state.shoppingList[0].Quantity; // update
      addToShoppingListArray[n].ItemNote=this.state.shoppingList[0].ItemNote;
      addToShoppingListArray[n].Link=this.state.shoppingList[0].Link;
      this.setState({
        shoppingList1:addToShoppingListArray
      })
    } 
    else { // new insertion
          console.log('Search ---------------new insertion');
          if((this.state.shoppingList[0].type===true) && (this.state.shoppingList[0].Quantity>0)){
          this.setState({
            shoppingList1:this.state.shoppingList1.concat(this.state.shoppingList)
            });
        }
    }
  
  } else {
    this.setState({
      duplicateShoppingList : []
    })
    let x,n ;
    // checking for new insertion or update
    for (let i=0;i<this.state.shoppingList.length;i++) {
      x=0;n=0;
      for(let l=0;l<this.state.shoppingList1.length;l++){
        if((this.state.shoppingList[i].name===this.state.shoppingList1[l].name)){
          x=1;n=l;
          addToShoppingListArray = this.state.shoppingList1;
          console.log(this.state.shoppingList1,'++++++++++++++++++++++',addToShoppingListArray);
          // addToShoppingListArray[n].Quantity=this.state.shoppingList[i].Quantity ;// default 0 qunatity will not populate list
          // addToShoppingListArray[n].type = this.state.shoppingList[i].type;
          // addToShoppingListArray[n].ItemNote = this.state.shoppingList[i].itemNote;
          addToShoppingListArray[n] =this.state.shoppingList[i];
          this.setState({
            shoppingList1 : addToShoppingListArray
          }, ()=> console.log('!!!!!!!!!!!!!!!!!!!!!!!! update euipment List',this.state.shoppingList1 ))  
        }
      }
      if(x===1){ // update
        console.log('Search ****************************Update');
      } else { // new insertion
        console.log('Search ******************************new insertion');
        if((this.state.shoppingList[i].type===true) && (this.state.shoppingList[i].Quantity>0)){
       // n = this.state.equipmentList[n];
       ka = [];
       ka = this.state.shoppingList1;
       ka.push(this.state.shoppingList[i]);
        this.setState({
          shoppingList1:ka
          },()=> console.log(this.state.shoppingList1,'>>>>>>>>>>>>>>>>@index',i,'*****',this.state.shoppingList[i]));
        }
      }
    }
  }
  }else{
    console.log('----------------This is a error--------------------')
      this.shoppingValidator.showMessages();
    // rerender to show messages for the first time
    // you can use the autoForceUpdate option to do this automatically`
    this.forceUpdate();
  }
 }
findListIndex =(listItem) =>{
  console.log(listItem,this.state.searchEquipment)
  if (listItem.name === this.state.searchEquipment) {
    console.log(listItem.type);
    return listItem};
  // return listItem.name.toLowerCase().search(
  //   this.state.searchEquipment.toLowerCase()) !== -1;
}
findShoppingList = (listItem) => {
  console.log(listItem,this.state.shoppingListValue)
  if (listItem.name === this.state.shoppingListValue) {
    console.log(listItem.type);
    return listItem};
  // return listItem.name.toLowerCase().search(
  //   this.state.shoppingListValue.toLowerCase()) !== -1;
}
removeShoppingList = (e) => {
  console.log('=====================================',e.target.id);
  console.log(e.target.value);
  var dataArray1 =  this.state.shoppingList;
  for(let i=0;i<this.state.shoppingList.length;i++) {
      if(this.state.shoppingList[i].name === this.state.shoppingList1[e.target.id].name){
      dataArray1[i].type=!dataArray1[i].type  ;
      // dataArray1[i].itemNote= "X";
      // dataArray1[i].Quantity = 0;
      // dataArray1[i].Link = "addLink";
      dataArray1[i].itemNote= '';
      dataArray1[i].Quantity = '';
      dataArray1[i].Link = '';
      console.log('matched*********************',dataArray1[i]);  
      }
    }
    var dataArray = this.state.shoppingList1;
    dataArray.splice(e.target.id, 1);
    this.setState({
      shoppingList1:dataArray,
      shoppingList:dataArray1
    },()=>console.log('******Remove**********',this.state.shoppingList1))
  }
////////////////Equipment List
handleSelect = (e) => {
  
  let equipmentContainer = this.state.equipmentList;
  equipmentContainer[e.target.id].type = !equipmentContainer[e.target.id].type;
  if(equipmentContainer[e.target.id].type) {
     // equipmentContainer[e.target.id].name = e.target.name;
      //if(e.target.value===''){equipmentContainer[e.target.id].Quantity = 0}
    } 
  else {
    let arrayCheck = [];
    // equipmentContainer[e.target.id].Quantity = 0;
    // equipmentContainer[e.target.id].Link='X';
    equipmentContainer[e.target.id].Quantity = '';
    equipmentContainer[e.target.id].Link='';
    if(this.state.equipmentList1.length>0){
      this.state.equipmentList1.map((row,i)=>{
        if(row.name === equipmentContainer[e.target.id].name){
          arrayCheck = this.state.equipmentList1;
          // arrayCheck[i].Quantity = 0;
          // arrayCheck[i].Link = 'X';
          arrayCheck[i].Quantity = '';
          arrayCheck[i].Link = '';
          arrayCheck[i].type = equipmentContainer[e.target.id].type;
          this.setState({
            equipmentList1 : arrayCheck 
          },()=> console.log('check or uncheck equipmentList', this.state.equipmentList1))
        }
      })
    }
  }
  this.setState({
    equipmentList : equipmentContainer,
    },()=>
    { console.log('setEuipmentContainer==>',this.state.equipmentList);
      });
 
}
addHost=e=>{
   let hostarray=[];
  hostarray.push(this.state.hostList[this.state.isChecked].userId)

  this.setState({
    hostList2:[],
    hostList2:hostarray
    },()=>console.log(this.state.hostList2));
}
onSiteChanged=e=> {
  let hostContainer = this.state.hostList;
  let hostName;
  
	$.each(hostContainer, function(a, b) {
		b['status'] = false;
	});
  
   hostContainer[e.target.id].status = true;
   console.log('hostContainer[e.target.id]');
   console.log(hostContainer[e.target.id]);
   hostName = hostContainer[e.target.id].username;
  this.setState({
    hostList:hostContainer,
    isChecked: e.currentTarget.id,
    hostName:hostName
    });
}
selectHost = (e) => {

  let hostContainer = this.state.hostList;
  console.log('this.state.hostList',this.state.hostList);
  let hostarray = [];
  let x =2,n=0;
  hostarray = this.state.hostList2;

  console.log('*************lalit***********',hostContainer[e.target.id].userId);
   for(let i=0;i<hostContainer.length;i++){
      x=0;n=0;
     for(let l=0;l<hostarray.length;l++){
      if(hostarray[l] == hostContainer[e.target.id].userId ){
       x=1;n=l;
        console.log(false,hostarray);
      //hostarray.splice(l,1);
     }
    }
    if(x===0){
      alert("x===0",hostContainer[e.target.id].userId)
      hostarray.push(hostContainer[e.target.id].userId);
      console.log("x==-",hostarray,e.target.id)
      this.setState({
        hostList : hostContainer,
        hostList2:hostarray,
        isChecked:e.target.id
        },()=>
        { console.log(this.state.isChecked, hostarray,this.state.hostList2,'setEuipmentContainer==>',this.state.hostList);
          });
      return ;    
      
    }
    if(x===1){
      alert("x===1",x)
      hostarray.splice(n,1);
      this.setState({
        hostList : hostContainer,
        hostList2:hostarray
        },()=>
        { console.log( hostarray,this.state.hostList2,'setEuipmentContainer==>',this.state.hostList);
          });
      return ; 
    }
    }
  }
searchShoppingList = (e) => {
  if(this.state.duplicateShoppingList.length>0){
    this.setState({
     shoppingList:[]
    }, function() { // called by React after the state is updated
      this.setState({
        shoppingList:this.state.addToequipmentList1.concat(this.state.duplicateShoppingList.filter(this.findShoppingList)),
        addToequipmentList1 : this.state.shoppingList
       },()=> console.log('SearchShopiingList','duplicateList',this.state.duplicateShoppingList,'----------Check-----------',this.state.shoppingList));
    });
  } else {
    this.setState({
     duplicateShoppingList: this.state.shoppingList,
     shoppingList:[]
   }, function() { // called by React after the state is updated
      this.setState({
        shoppingList:this.state.addToequipmentList1.concat(this.state.duplicateShoppingList.filter(this.findShoppingList)),
        addToequipmentList1 : this.state.shoppingList
      },()=> console.log('SearchduplicateList',this.state.duplicateShoppingList,'----------Check-----------',this.state.shoppingList));
    });
    
  }
}
searchEquipmentMethod =(e)=>{
 
  if(this.state.duplicateList.length>0){
   this.setState({
    equipmentList:[]
   }, function() { // called by React after the state is updated
     this.setState({
       equipmentList:this.state.addToequipmentList1.concat(this.state.duplicateList.filter(this.findListIndex)),
       addToequipmentList1 : this.state.equipmentList
      },()=> console.log('addToequipmentList',this.state.addToequipmentList1,'duplicateList',this.state.duplicateList,'----------Check-----------',this.state.equipmentList));
   });
 } else {
   this.setState({
    duplicateList: this.state.equipmentList,
    equipmentList:[]
  }, function() { // called by React after the state is updated
     this.setState({
       equipmentList:this.state.addToequipmentList1.concat(this.state.duplicateList.filter(this.findListIndex)),
       addToequipmentList1 : this.state.equipmentList
     },()=> console.log('addToequipmentList',this.state.addToequipmentList1,'duplicateList',this.state.duplicateList,'----------Check-----------',this.state.equipmentList));
   });
   
 }


}

openAddingEquipment = (e) => {
	var dataArray = JSON.parse(JSON.stringify(this.state.equipmentList));
	
	this.setState({
		equipmentListJSON: dataArray
	});
	
	$('#myModal2').attr({'style':'display:block'});
}

closeAddingEquipment = (e) => {
	let equipmentContainer = this.state.equipmentList;
		$.each(equipmentContainer, function( a, b ) {
			b['type'] = false;
		});
	
	this.setState({
		equipmentList: equipmentContainer
	});
	
	$('#myModal2').attr({'style':'display:none'});
}

choseEquipmentFromList = (e) => {
	let equipmentContainer = this.state.equipmentList;
	$.each(equipmentContainer, function( a, b ) {
		b['type'] = false;
	});
	
	let equName = '';
	
	equipmentContainer[e.target.id].type = !equipmentContainer[e.target.id].type;
	
	if ( equipmentContainer[e.target.id].type ) { equName = equipmentContainer[e.target.id].name; }
	
	 this.setState({
		  equipmentList: equipmentContainer,
		  chosenEquipmentName: equName
	 });
}

handleShareholderNameChange = idx => evt => {
  const newShareholders = this.state.equipmentList.map((shareholder, sidx) => {
    if (idx !== sidx) return shareholder;
    return { ...shareholder, Quantity: evt.target.value };
  });

  this.setState({ equipmentList: newShareholders },()=> {
    console.log('equipmentList',this.state.equipmentList)
  }
  );
};

handleShareholderLink = idx => evt => {
  const newShareholders = this.state.equipmentList.map((shareholder, sidx) => {
    if (idx !== sidx) return shareholder;
    return { ...shareholder, Link: evt.target.value };
  });

  this.setState({ equipmentList: newShareholders },()=> {
    console.log('equipmentList',this.state.equipmentList)
  }
  );
};

handleShow=()=>{
  this.setState({
    onSave:false
  })
   console.log(this.state.onSave);
 }
 handleClose=(e)=>{
   this.textArea.select();
   document.execCommand('copy');
   e.target.focus();
   $("#link_generator").attr({'style':'display:none'});
   this.setState({
     onSave:false
   })
 }
 
 
 
handleSelectPlaylist = (mod) =>
{
	this.setState((state) => ({ selectedPlaylist: mod, selectedPlaylist_id: mod.playlist_id }));
	this.props.togglePlaylistPopup();
} 

removeFromSelectedPlaylists = () => {
	this.setState((state) => ({ selectedPlaylist: [], selectedPlaylist_id: -1 }));
}

handleTempSelectedItem = (mod) => {
	this.setState((state) => ({ selectedPlaylist_id: mod.playlist_id }));
}
 
changeQuantity=(st) => {
	var num = this.state.shopQuantity;
	if ( st == '+' ) { num++; }
	else { if ( num > 1 ) { num--; } }
	
	this.setState({
		shopQuantity: num
	})
}

addNewShop=(e)=>{
	
	
	if ( this.state.itemNameShop == '' )
		{
			this.props.showMainErrorPop("Item Name can not be empty.");
		}
	else if ( isNaN(parseInt(this.state.itemNotesPrice)) || parseInt(this.state.itemNotesPrice) == 0 )
		{
			this.props.showMainErrorPop("Item Price is required.");
		}
	else
		{
			if  ( this.state.editShopIndex == -1 )
				{
					let objectToPush = {
											'id': 0,
											'Link': this.state.itemNameShop,
											'Quantity': this.state.shopQuantity,
											'itemNote': this.state.itemNotesShop,
											'id_main': Date.now(),
											'price': parseInt(this.state.itemNotesPrice)
										};
								
					let array = this.state.addedShopList;
					array.push(objectToPush);
					
					this.setState({
						itemNameShop: '',
						shopQuantity: 1,
						itemNotesShop: '',
						itemNotesPrice: 0,
						addedShopList: array 
					})
				}
			else
				{
					var old_state = this.state.addedShopList.slice();
					old_state[this.state.editShopIndex] = {
																'id': 0,
																'Link': this.state.itemNameShop,
																'Quantity': this.state.shopQuantity,
																'itemNote': this.state.itemNotesShop,
																'price': parseInt(this.state.itemNotesPrice),
																'id_main': Date.now()
															};
					
					this.setState({
						itemNameShop: '',
						shopQuantity: 1,
						itemNotesShop: '',
						itemNotesPrice: 0,
						addedShopList: old_state,
						editShopIndex: -1
					})
				
				}
				
			this.calculateTotal();
			
		}
		
}

addToEquipmentList = () => {
	let equName = this.state.chosenEquipmentName;
	let equipmentContainer = this.state.equipmentList;
	$.each(equipmentContainer, function( a, b ) {
		b['type'] = false;
	});
	
	this.setState({
					itemNameShop: equName,
					equipmentList: equipmentContainer,
					chosenEquipmentName: '',
				})
	
	$('#myModal2').attr({'style':'display:none'});
}

addNewEquipment=(e)=>{ 
	let array = this.state.equipmentListMain;
	let arraynames = this.state.equipmentListMainNames;
	
	if ( this.state.itemNameShop != ''  )
		{
			let objectToPush = {
									'Link': this.state.itemNotesShop,
									'Quantity': this.state.shopQuantity,
									'equipment_description': '',
									'id': parseInt(Date.now() + "" + Math.floor(Math.random() * 1000000000000)),
									'interestId': 2,
									'name': this.state.itemNameShop,
									'status': 1,
									'type': true
							};
							
			array.push(objectToPush);
			
			arraynames.push(this.state.itemNameShop);
			
			this.setState({
						itemNameShop: '',
						shopQuantity: 1,
						itemNotesShop: '',
						equipmentListMain: array,
						equipmentListMainNames: arraynames
					})
		}
	else
		{
			this.props.showMainErrorPop('Item Name of Equipment is required field.')
		}
}

openScriptPop = (e) =>{
	var array = this.state.addedShopList;
	var arrayNames = [];
	
	$.each(array, function( a, b ) {
		arrayNames.push(b['Link']);
	});
	
	$.each(this.state.tablerows, function( a, b ) {
		if ( arrayNames.indexOf(b['wineChoice']) == -1 && isNaN(parseInt(b['price'])) == false && parseInt(b['price']) > 0 )
			{
				var object = {
								'id': 0,
								'Link': b['wineChoice'],
								'Quantity': 1,
								'itemNote': '',
								'id_main': Date.now() + "" + Math.floor(Math.random() * 1000000000000),
								'price': b['price']
							};
							
				array.push(object);
			}
	});
	
	this.setState({
		addedShopList: array 
	}); 
}

calculateTotal=()=>{
	let total = 0;
	$.each(this.state.addedShopList, function( a, b ) {
		total = total + ( b['Quantity'] * b['price'] );
	});
	
	this.setState({
		totalPrice: total
	});
}

editShop=(i)=>{
	this.setState({
		itemNameShop: this.state.addedShopList[i]['Link'],
		shopQuantity: this.state.addedShopList[i]['Quantity'],
		itemNotesShop: this.state.addedShopList[i]['itemNote'],
		itemNotesPrice: this.state.addedShopList[i]['price'],
		editShopIndex: i
	})
}

editEqu=(i)=>{
	var equipmentEditObjectVar = JSON.parse(JSON.stringify(this.state.equipmentListMain[i]));

	this.setState({
		equipmentEditObject: equipmentEditObjectVar,
		equipmentEditName: equipmentEditObjectVar.name,
		equipmentEditQuantity: equipmentEditObjectVar.Quantity,
		equipmentEditNotes: equipmentEditObjectVar.Link,
		editEquIndex: i
	})
}

saveEditedEquipment=()=>{
	if ( this.state.equipmentEditName !== '' )
		{		
			var old_state = this.state.equipmentListMain.slice();
			var old_state_names = this.state.equipmentListMainNames.slice();
			old_state[this.state.editEquIndex] = {
														'Link': this.state.equipmentEditNotes,
														'Quantity': this.state.equipmentEditQuantity,
														'equipment_description': '',
														'id': parseInt(Date.now() + "" + Math.floor(Math.random() * 1000000000000)),
														'interestId': 2,
														'name': this.state.equipmentEditName,
														'status': 1,
														'type': true,
													};
													
			old_state_names[this.state.editEquIndex] = this.state.equipmentEditName;			

			this.setState({
					equipmentEditName: '',
					equipmentEditQuantity: 1,
					equipmentEditNotes: '',
					equipmentListMain: old_state,
					equipmentListMainNames: old_state_names,
					editEquIndex: -1
				});
		}
	else
		{
			this.props.showMainErrorPop('Item Name of Equipment is required field.');
		}
}

changeQuantityEdit=(st) => {
	var num = this.state.equipmentEditQuantity;
	if ( st == '+' ) { num++; }
	else { if ( num > 1 ) { num--; } }
	
	this.setState({
		equipmentEditQuantity: num
	})
}

copyShop=(i)=>{
	let objectToPush = JSON.parse(JSON.stringify(this.state.addedShopList[i]));
	objectToPush['id_main'] = Date.now();
	let array = this.state.addedShopList; 
	array.push(objectToPush);
	
	this.setState({
		addedShopList: array
	})
	
	let mainthis = this;
	setTimeout(function(){ mainthis.calculateTotal(); }, 200);
}

copyEqu=(i)=>{
	let objectToPush = JSON.parse(JSON.stringify(this.state.equipmentListMain[i]));
	objectToPush['id'] = Date.now() + "" + Math.floor(Math.random() * 1000000000000)
	let array = this.state.equipmentListMain; 
	array.push(objectToPush); 
	
	this.setState({
		equipmentListMain: array
	})
}

removeShop=(i)=>{
	var old_state = this.state.addedShopList.slice();
	old_state.splice(i, 1);
	
	this.setState({
		addedShopList: old_state
	})
	
	let mainthis = this;
	setTimeout(function(){ mainthis.calculateTotal(); }, 200);
}

removeEquipmentList = (i) => {
	var old_state = this.state.equipmentListMain.slice();
	var old_stateNames = this.state.equipmentListMainNames.slice();
	old_state.splice(i, 1);
	old_stateNames.splice(i, 1);
	
	this.setState({
		equipmentListMain: old_state,
		equipmentListMainNames: old_stateNames
	});
}

closeEdit=(e)=>{
	this.setState({
		itemNameShop: '',
		shopQuantity: 1,
		itemNotesShop: '',
		editShopIndex: -1
	})
}

closeEditEqu=(e)=>{
	this.setState({
		equipmentEditName: '',
		equipmentEditQuantity: 1,
		equipmentEditNotes: '',
		editEquIndex: -1
	})
}

choosenElement;
choosenElementOffset;

parentElementMain;
reorderItem = false;


handleDraggStart = (e) => {
	e.preventDefault();
	e.preventDefault();
	//console.log(e);
	
	this.choosenElement = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
	this.choosenElementOffset = this.choosenElement.getBoundingClientRect();
	this.parentElementMain = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
	this.choosenElement.classList.add("ondrag");
	this.choosenElement.style.top = "0px";
	this.reorderItem = false;
}

lastIndex = 0;
handleDraggitem = (e) => {
	if( this.choosenElementOffset && this.choosenElement )
		{
			var newClientY = e.clientY;
			if ( newClientY < 120 )
				{
					window.scrollBy(0,-30);
					this.choosenElementOffset = this.choosenElement.getBoundingClientRect();
				}	
			else if ( (window.innerHeight-newClientY) < 120 )
				{ 
					window.scrollBy(0,30);
					this.choosenElementOffset = this.choosenElement.getBoundingClientRect();
				}
			var new_top = newClientY - (this.choosenElementOffset.top) - (this.choosenElementOffset.height / 2);
			var songsMainHolder = this.parentElementMain;
			var songsMainHolderChilds = songsMainHolder.children;
			
			var blue = 1;
			var over_index = Math.abs(Math.round(new_top / this.choosenElementOffset.height));
			
			let elementHeight = this.choosenElementOffset.height;
			let newElementHeightValue = 85;
			if( new_top > (newElementHeightValue / 2) )
				{ 
					var mainind = parseInt(this.choosenElement.getAttribute("data-itemkey"));
					if( (parseInt(mainind)+1) < songsMainHolderChilds.length )
						{
							var choosen_elem = songsMainHolderChilds[mainind]; 
							
							if( choosen_elem.nextElementSibling ) 
								{
									choosen_elem.setAttribute('data-itemkey', parseInt(mainind)+1);
									choosen_elem.nextElementSibling.setAttribute('data-itemkey', parseInt(mainind)); 
									songsMainHolder.insertBefore(choosen_elem, choosen_elem.nextElementSibling.nextElementSibling)
								}
							
							
							this.reorderItem = true;
							this.choosenElement.style.top = "0px";
							new_top = 0;
							this.choosenElement = songsMainHolderChilds[choosen_elem.getAttribute("data-itemkey")];
							this.choosenElementOffset = this.choosenElement.getBoundingClientRect();
						}
				}
			else if( new_top < (newElementHeightValue / -2) )
				{ 
					var mainind = parseInt(this.choosenElement.getAttribute("data-itemkey"));
					if( (parseInt(mainind)) > 0 )
						{
							var choosen_elem = songsMainHolderChilds[mainind]; 
							
							if( choosen_elem.previousElementSibling ) 
								{
									choosen_elem.setAttribute('data-itemkey', parseInt(mainind)-1);
									choosen_elem.previousElementSibling.setAttribute('data-itemkey', parseInt(mainind)); 
									songsMainHolder.insertBefore(choosen_elem, choosen_elem.previousElementSibling)
								}
							
							this.reorderItem = true;
							
							this.choosenElement.style.top = "0px";
							new_top = 0;
							this.choosenElement = songsMainHolderChilds[choosen_elem.getAttribute("data-itemkey")];
							this.choosenElementOffset = this.choosenElement.getBoundingClientRect();
						}
				}
				
			new_top = newClientY - (this.choosenElementOffset.top) - (this.choosenElementOffset.height / 2);
			this.choosenElement.style.top = new_top +"px";
			
		}
}

handleDraggEnd = (e) =>{
	if( this.choosenElement )
		{
			const mainthis = this;
			this.choosenElement = undefined;
			this.choosenElementOffset = undefined;
			
			var songsMainHolder = this.parentElementMain;
			var songsMainHolderChilds = songsMainHolder.children;
			
			var arrayName = 'addedShopList';
			if ( songsMainHolder.className == 'for_all_activity' )
				{
					arrayName = 'tablerows';
				}
			else if ( this.state.groupId == 1 )
				{
					arrayName = 'equipmentListMain';
				}
			else if ( songsMainHolder.className == 'for_all_wines' )
				{
					arrayName = 'mainWineArray';
				}
			
			var old_state_in = JSON.parse(JSON.stringify(this.state[arrayName]));
			
			var old_state = [];
			
			for( var i = 0; i < songsMainHolderChilds.length; i++ )
				{
					var old_attr = parseInt(songsMainHolderChilds[i].getAttribute("data-oldarrange"));
					var new_attr = parseInt(songsMainHolderChilds[i].getAttribute("data-itemkey"));
					songsMainHolderChilds[i].setAttribute("data-itemkey", i);
					songsMainHolderChilds[i].setAttribute("data-oldarrange", i);
					old_state.push(old_state_in[old_attr]);
				} 
			
			if ( songsMainHolder.className == 'for_all_activity' )
				{
					this.setState((state) => ({ tablerows: old_state }));
				}
			else if ( this.state.groupId == 1 )
				{
					this.setState((state) => ({ equipmentListMain: old_state }));
				}
			else if ( songsMainHolder.className == 'for_all_wines' )
				{
					this.setState((state) => ({ mainWineArray: old_state }));
				}
			else
				{
					this.setState((state) => ({ addedShopList: old_state }));
				}
			
			if( document.querySelector('.ondrag') )
				{
					document.querySelector('.ondrag').style.top = "0px";
					document.querySelector('.ondrag').classList.remove("ondrag");
				}
		}	
}

changePlace = (ind) =>{
	this.state.changePlace = ind;
	$("#changeActivityPlace").attr({'style': 'display:block'});
}

saveChangePlace = () =>{
	if ( this.state.changePlaceNew )
		{
			if ( this.state.changePlaceNew > this.state.tablerows.length || parseInt(this.state.changePlaceNew) == 0 || ( this.state.changePlaceNew.length > 0 && this.state.changePlaceNew[0] == 0 ))
				{
					$("#activityPositionNumber").attr({'style': 'display:block'});
					return false;
				}
			
			let newInd = this.state.changePlaceNew-1
			let arr = this.state.tablerows;
			let element = arr.splice(this.state.changePlace, 1)[0];

			arr.splice(newInd, 0, element);
			this.setState({
					tablerows: arr,
					changePlaceNew: ''
				});
			
			$("#changeActivityPlace").attr({'style': 'display:none'});
		}
}

closeChangePlace = () =>{
	this.setState({
					changePlaceNew: ''
				});
			
	$("#changeActivityPlace").attr({'style': 'display:none'});
}

closeAddingShop = (e) =>{
	$("#wine_script").attr({'style':'display:none'});
}

choseWineShop = (i) =>{
	let name = this.state.tablerows[i]['wineChoice'];
	let num = this.state.tablerows[i]['price'];
	
	this.setState({
		chosenWineShop: name,
		chosenWinePrice: num
	})
}

addToShopName = (e) =>{
	let name = this.state.chosenWineShop;
	let num = this.state.chosenWinePrice;
	
	this.setState({
		chosenWineShop: '',
		chosenWinePrice: 0,
		itemNameShop: name,
		itemNotesPrice: num
	})
	
	$("#wine_script").attr({'style':'display:none'});
}
 
turnOffChargingSession = (e) =>{
	this.setState({
		sessionCharge: false,
		liveSessionMemberCharges: 0,
		liveSessionNonMemberCharges: 0
	})
	$("#charging_session_verification").attr({'style':'display:none'});
}

changeSectionComp = (e, mod) =>{
	let hasClass = $(e.currentTarget).hasClass('list_section_active');
	if(hasClass){return false;} 
	
	if(mod == 1)
		{
			this.setState({
				sessionListMain: true,
				templateList: false,
				saveAsTemplate: 0
			},()=>{this.fetchPrevSessionList()});
		}
		
	else if(mod == 2)
		{
			this.setState({
				sessionListMain: false,
				templateList: true,
				saveAsTemplate: 1
			},()=>{this.fetchPrevSessionList()});
		}
}

sortArrays=(mod, array, array_name)=>{
	let arraySort = array;
	
	if( mod == 'interest' )
		{
			if ( !this.state.sortedChannelName )
				{
					arraySort.sort(function(a, b){
						if(a.interest_title < b.interest_title) { return -1; }
						if(a.interest_title > b.interest_title) { return 1; }
						return 0;
					})
					
					this.setState({ sortedChannelName: true });
				}
			else
				{
					arraySort.sort(function(a, b){
							if(a.interest_title > b.interest_title) { return -1; }
							if(a.interest_title < b.interest_title) { return 1; }
							return 0;
						})
				
					this.setState({ sortedChannelName: false });
				}
			
		}
		
	else if ( mod == 'ses_name' )
		{
			if ( !this.state.sortedSessionName )
				{
					arraySort.sort(function(a, b){
						if(a.name < b.name) { return -1; }
						if(a.name > b.name) { return 1; }
						return 0;
					})
					
					this.setState({ sortedSessionName: true });
				}
			else
				{
					arraySort.sort(function(a, b){
							if(a.name > b.name) { return -1; }
							if(a.name < b.name) { return 1; }
							return 0;
						})
				
					this.setState({ sortedSessionName: false });
				}
		}
		
	else if ( mod == 'host_name' )
		{
			if ( !this.state.sortedHostName )
				{
					arraySort.sort(function(a, b){
						if(a.hostName < b.hostName) { return -1; }
						if(a.hostName > b.hostName) { return 1; }
						return 0;
					})
					
					this.setState({ sortedHostName: true });
				}
			else
				{
					arraySort.sort(function(a, b){
							if(a.hostName > b.hostName) { return -1; }
							if(a.hostName < b.hostName) { return 1; }
							return 0;
						})
				
					this.setState({ sortedHostName: false });
				}
		}
		
	else if ( mod == 'date_time' )
		{
			console.log(123);
			if ( !this.state.sortedDate )
				{
					console.log(1);
					arraySort.sort(function(a, b){
						if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
						if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
						
						return 0;
					})
					
					this.setState({ sortedDate: true });
				}
			else
				{
					console.log(2);
					arraySort.sort(function(a, b){
							if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return -1; }
							if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return 1; }
							return 0;
							
						})
					
					this.setState({ sortedDate: false });
				}
		}
		
	this.setState({
		array_name: arraySort
	});
}


openCardsScreen = (e) =>{
	$("#expired_card").attr({'style':'display:none'});
	$("#sessionPay").attr({'style':'display:block'});
	
	$("#sessionPay .hidden_div").trigger("click");
	
}

stopEditingSession = (e) => {
	
	document.removeEventListener("keydown", this.keyDownHandler);
	
	if ( this.props.params.id )
		{
			let url = process.env.REACT_APP_NAME+"/api/v1/session/"+this.props.params.id+'/stoplock';
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
			axios.get(url).then(res => { }).catch(err =>{ });
		}
}


goToPlans=(e)=>{
	e.preventDefault();
	e.stopPropagation();
	browserHistory.push("/trainingPlans");
}

logOutPlan=(e)=>{
	e.preventDefault();
	e.stopPropagation();
	localStorage.removeItem('userData');
	localStorage.removeItem('channelId');
	localStorage.removeItem('groupId');
	localStorage.removeItem('channelInterest'); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_ID); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); 
	window['user_loggedIn'] = 0; 
	browserHistory.push("/");
}
 
////////////////Submit data
submitForm = (event, mod) => {
	if ( !this.state.haveActivePlan )
		{
			//channel doesn't have active subscription plan, block session creation
			$('#planexpired').attr({'style':'display:block'});
			return false;
		}
	
	if ( this.props.params.id && this.state.parentRecrId > 0 && ((this.state.isAdmin == 1 && this.state.remainingReccurings > 1) || (this.state.isAdmin == 0 && this.state.recurringByHostLeft > 1 )) ) 
		{
			$("#reccuring_question").attr({'style': 'display:block'});
			return false;
		}
	
	if ( this.state.editReplayClass && this.state.pickedReplayId == 0 )
		{
			this.props.showMainErrorPop('To continue you need to add replay class first');
			return false;
		}
	
	this.setState({
		saveAsTemplate: mod
	}, () => {
		this.continueSubmitForm(false)
	});
	
	event.preventDefault();

	return false;
}

setStatusAndSave=(st)=>{
	$("#marketplace_save").attr({'style':'display:none'});
	this.setState({marketplaceStatus: st}, () => {this.continueSubmitForm(this.state.rememberUpdateAll)});
}

onbeforeunload=()=>{
	console.log(window.location.pathname, 'pathName');
	if ( window.location.pathname == '/FitnessSessionCreation' ) { return false; }
}

continueSubmitForm = (updateAll, singleSession, cancelSessions) => {
	var indexOfTz = this.state.timezonesArray.indexOf(this.state.chosenTimezone);
	var sessionTzCity = this.state.timezoneCityArray[indexOfTz];
	this.setState({chosenTimezoneCity: sessionTzCity});

  if(this.state.editActivityToken > -1)
	  {
		  this.props.showMainErrorPop('To continue you need to close or save your edited Activity first');
		  return false;
	  }
  
  if( this.state.whenSessionTime == '' )
	 {
		this.setState({
		  errorClass_Datevalidatior: true
		}, ()=>{console.log(this.state.errorClass_Datevalidatior, '-----session date-----');}) 
	}
	
  if ( parseInt(this.state.maximumParticipants) == 0 && this.state.isMarketplaceChannel === false )
		{
			this.setState({
				errorClass_MaximumParticipant:true
				});
		
			return false;
		}

  if( (parseInt(this.state.minimumParticipants) < 1 || parseInt(this.state.minimumParticipants) > 50 || this.state.minimumParticipants == '' || parseInt(this.state.minimumParticipants) > parseInt(this.state.maximumParticipants)) && this.state.isMarketplaceChannel === false )
	  {
		 this.setState({
		  errorClass_MinimumParticipant:true
		});
		
		return false;
	  }
  
  if( (parseInt(this.state.maximumParticipants) > 50 || parseInt(this.state.maximumParticipants) == 0 || parseInt(this.state.maximumParticipants) < 1 || this.state.maximumParticipants == '' || parseInt(this.state.minimumParticipants) > parseInt(this.state.maximumParticipants)) && this.state.isMarketplaceChannel === false )
	  {
		  this.setState({
		  errorClass_MaximumParticipant:true
		});
		
		return false;
	  }
  
  if ( this.state.session_details.trim() == '' || this.state.description.trim() == '' )
	  {
		this.props.showMainErrorPop("Please fill all required fields correctly.");
		 if ( this.state.session_details.trim() == '')
			 {
				 this.setState({
					  errorClass_SessionDetail:true
					})
			 }
		
		if ( this.state.description.trim() == '')
			 {
				 this.setState({
					  errorClass_Description:true
					})
			 }
			 
		if ( this.state.sessionMinute == 0 && this.state.sessionHour == 0 )
			{
				this.setState({
					errorClass_Duration: true,
				})
			}
			{
				
			}			
		return false;
	  }
  
  if( this.state.when == '' && this.state.saveAsTemplate == 0)
	  {
		this.props.showMainErrorPop("Please select date and time of session.");
		return false;
	  }
	  
  if ( this.state.ActivityName !== '' )
	  {
		  this.props.showMainErrorPop("You filled Activity name in Workout Builder form but not add it. Please remove or add it.");
		  return false;
	  }
  
  if ( this.state.itemNameShop != '' || this.state.itemNotesShop != '' )
	  {
		if(this.state.groupId == 2)
			{
				this.props.showMainErrorPop("You filled some inputs of Shopping form but not add it. Please remove or add it.");
				return false;
			}
		
		else if( this.state.groupId == 1 )
			{
				this.props.showMainErrorPop("You filled some inputs of Equipment form but not add it. Please remove or add it.");
				return false;
			}
	  }

	if ( parseInt(this.state.sessionHour) == 0 && parseInt(this.state.sessionMinute) == 0 )
		{
			this.props.showMainErrorPop("Class Duration should not be zero.");
			this.setState({
				errorClass_Duration: true,
			})
			return false;
		}
		
	var previousTime = (parseInt(this.state.activityHourEditBackup)*60) + parseInt(this.state.activityminutesEditBackup);
			
	let canAddThisWorkout = this.checkAllWorkoutDuration(previousTime*-1, true);
	if ( !canAddThisWorkout )
		{
			this.setState({
					finalClassDurationMinutes: (parseInt(this.state.sessionHour)*60) + parseInt(this.state.sessionMinute)
				}, ()=>{ $('#workoutDurationError').attr({'style':'display:block'});})
			return false;
		}

	if ( this.state.isMarketplaceChannel && this.state.showReviewMessageBefore == false && !this.state.showIfTemplate )
		{
			if ( this.state.activityTotalTime != 0 || this.state.activityTotalTimeSeconds != 0 )
				{		
					let durationInSecs = this.state.sessionMinute * 60;
					let activityDurationInSecs = this.state.activityTotalTimeSeconds + (this.state.activityTotalTime * 60);
					if ( durationInSecs != activityDurationInSecs && this.state.scriptDurationErrorShowed == false )
						{
							this.setState({
								scriptDurationErrorShowed: true,
							});
							this.props.showMainErrorPop("Your script duration is not same as your class duration. Please check if you want to edit that.");
							return false;
						}
					
				}
			this.setState({
					showReviewMessageBefore: true,
					rememberUpdateAll: updateAll
			});
			$("#marketplace_save").attr({'style':'display:block'});
			return false;
		}
		
  //$("#link_generator").attr({'style':'display:block'});
  var activity_info = [];
  var activities = [];
  let input_result=[];
  let min_participants='';
  let max_participants='';
  this.state.onSave=true;
// @ak commented code was here
  let tm=this.state.when.replace(/ /g,"T");
//@ak commented code was here

let fName=JSON.parse(localStorage.getItem('userData')).data.responseData.firstName;
 
 
let momentWhenDate = moment(this.state.when).format('YYYY/MM/DD HH:mm:ss');
var dateMomentCurrent = moment1().tz(sessionTzCity).format('YYYY/MM/DD HH:mm:ss');

var dateMomentTZ = moment1(this.state.when).tz(sessionTzCity).format();

if( dateMomentCurrent > momentWhenDate && this.state.saveAsTemplate == 0 ){
	this.props.showMainErrorPop("Hi "+fName+", you should be planning your class in advance from the current time.");
	
	return false;
}
if ( this.state.sessionCharge )
	{
		if ( this.state.liveSessionMemberCharges == '' || parseInt(this.state.liveSessionMemberCharges) <= 0 || this.state.liveSessionNonMemberCharges == '' || parseInt(this.state.liveSessionNonMemberCharges) <= 0 ) //this.state.onDemandMemberCharges == '' || this.state.onDemandMemberCharges == 0 || this.state.onDemandNonMemberCharges == '' || this.state.onDemandNonMemberCharges == 0
			{
				this.props.showMainErrorPop(""+fName+", you have set Charging for Class flag to Yes, but you do not have valid amounts entered.  Would you like to set Charging for Class to No or Add valid price for Class?");
				return;
			}
	}

let wineDetail = []; 
// this.state.tablerows;
let ap = [];
let ar = [];
let pa = [];

for(let i=0;i<this.state.mainWineArray.length;i++){ //convert into json as required here
    ap = { wineChoice: this.state.mainWineArray[i].wineChoice,
      id: this.state.mainWineArray[i].productId,
	  price: this.state.mainWineArray[i].price,
      Emojies:[],
      testerStatus: this.state.mainWineArray[i].testerStatus };
    wineDetail.push(ap);
    for(let t=0;t<this.state.mainWineArray[i].listAppearance.length;t++){
     
      if(this.state.mainWineArray[i].listAppearance[t].status){
        let emojiStructure = { path: this.state.mainWineArray[i].listAppearance[t].path, 
        emojies_type:this.state.mainWineArray[i].listAppearance[t].emojies_type, 
        name: this.state.mainWineArray[i].listAppearance[t].name,
        id:this.state.mainWineArray[i].listAppearance[t].id ,
        interestId:this.state.mainWineArray[i].listAppearance[t].interestId};
        wineDetail[i].Emojies.push(emojiStructure);
      }
    }

    for(let v=0;v<this.state.mainWineArray[i].listAroma.length;v++){
      if(this.state.mainWineArray[i].listAroma[v].status){
        ar.push(this.state.mainWineArray[i].listAroma[v]);
        let emojiStructure = { path: this.state.mainWineArray[i].listAroma[v].path, 
          emojies_type:this.state.mainWineArray[i].listAroma[v].emojies_type, 
          name: this.state.mainWineArray[i].listAroma[v].name,
          id:this.state.mainWineArray[i].listAroma[v].id ,
          interestId:this.state.mainWineArray[i].listAroma[v].interestId};
        wineDetail[i].Emojies.push(emojiStructure);
      }
    }

    for(let l=0;l<this.state.mainWineArray[i].listPalate.length;l++){
      if(this.state.mainWineArray[i].listPalate[l].status){
        pa.push(this.state.mainWineArray[i].listPalate[l]);
        let emojiStructure = { path: this.state.mainWineArray[i].listPalate[l].path, 
          emojies_type: this.state.mainWineArray[i].listPalate[l].emojies_type, 
          name: this.state.mainWineArray[i].listPalate[l].name,
          id:this.state.mainWineArray[i].listPalate[l].id,
          interestId:this.state.mainWineArray[i].listPalate[l].interestId };
        wineDetail[i].Emojies.push(emojiStructure);
      }
    }
    
  }
  console.log('*******************************************',wineDetail);

let tempUpdateAll = updateAll;


var single_day = [];
let noWeekDays;
var startDateData = [];
let d=(parseInt(this.state.sessionHour)*60)+parseInt(this.state.sessionMinute);  
if(this.state.weekDaysData==''){
  single_day.push(this.state.when)//this.state.when,
  startDateData = single_day;//new Date(this.state.when.replace(/-/g, '/')).toUTCString();//this.state.when
  console.log("SINGLEEE DATA>>>>==",startDateData);
  noWeekDays = true;
}else{
	if ( this.props.params.id && this.state.parentRecrId > 0 && !tempUpdateAll )
		{
			if (singleSession)
				{
					single_day.push(this.state.when)//this.state.when,
					startDateData = single_day;//new Date(this.state.when.replace(/-/g, '/')).toUTCString();//this.state.when
					console.log("SINGLEEE DATA>>>>==",startDateData);
					noWeekDays = true;
				}
			else
				{
					var weekDays = this.state.weekDaysData;
					for ( var i = 0; i < weekDays.length; i++ )
						{
							var dateToPush = moment(weekDays[i]).format('YYYY-MM-DD HH:mm');
							startDateData.push(dateToPush);
						}

					//startDateData=this.state.weekDaysData;
					console.log("MULTIPLEDATE DATA>>>>==",startDateData);
					//  start_date:this.state.weekDaysData;//this.state.when,
					noWeekDays = false;
				}
		}
	else
		{
			var weekDays = this.state.weekDaysData;
			for ( var i = 0; i < weekDays.length; i++ )
				{
					var dateToPush = moment(weekDays[i]).format('YYYY-MM-DD HH:mm');
					startDateData.push(dateToPush);
				}

			//startDateData=this.state.weekDaysData;
			console.log("MULTIPLEDATE DATA>>>>==",startDateData);
			//  start_date:this.state.weekDaysData;//this.state.when,
			noWeekDays = false;
		}
}
//return;

if ( this.props.params.id && this.state.parentRecrId > 0 && this.state.remainingReccurings == 1 && startDateData && startDateData.length > 1 ) 
	{
		tempUpdateAll = true;
	}
	
 let recStatus = false;
 if ( this.state.endRecrwhen != '' &&  !this.state.editRecurringSession )
	{
		recStatus = true;
	}

let interestSubcategories = localStorage.getItem('sessionInterestSubcategories');

const session ={
      sessionChannelId: localStorage.getItem("channelId"),
      interest_id: localStorage.getItem('sessionInterestArray'),
      groupId: localStorage.getItem("groupId"),
      name: this.state.session_details.trim(),
      image: this.state.session_image ? this.state.session_image : '',
     // when:this.state.when,
     //start_date:"2019-10-20 15:06:01",
      start_date:startDateData,//new Date(this.state.when.replace(/-/g, '/')).toUTCString(),
      description:this.state.description.trim(),
      //duration:this.state.exampleFormControlSelect2,
      //duration:(parseInt(this.state.sessionHour)*60)+parseInt(this.state.sessionMinute),
      duration:d,
      timeZone: this.state.chosenTimezone,
	  timezoneCity: sessionTzCity,
      level:this.state.exampleFormControlSelect1,
      min_participants:this.state.minimumParticipants,
      max_participants:this.state.maximumParticipants,
      searchParticipant:this.state.searchParticipant,
      sessionProperty:this.state.sessionProperty,
      recordOfferDemand:this.state.recordOfferDemand,
      sessionPublicChannel:this.state.sessionPublicChannel,
      sessionSearchable:this.state.sessionSearchable,
      manualAutomaticScript:this.state.manualAutomaticScript,
      showShoppingList:this.state.showShoppingList,
      showEquipment:this.state.showEquipment,
      showHostParticipant:this.state.showHostParticipant,
      startMusic:this.state.startMusic,
      showOtherParticipants:this.state.showOtherParticipants,
      startRecording:this.state.startRecording,
      disableNotificationSound:this.state.disableNotificationSound,
      onDemand:this.state.recordAndOffer,
	  sessionChargeAllowed: this.state.sessionCharge,
      orderWine:this.state.orderWine,
      session_charge:this.state.sessionCharge,
      currency:"USD",
      hour:(parseInt(this.state.sessionHour)*60)+parseInt(this.state.sessionMinute),
      show_particpants_count:"false",
	  liveSessionMemberCharges:this.state.liveSessionMemberCharges,
	  liveSessionNonMemberCharges:this.state.liveSessionNonMemberCharges,
      allowSS:this.state.allowSS,
	  resolution: ( this.state.highDefSelect === 'Low Definition' ? '180p' : ( this.state.highDefSelect === 'Standard Definition' ? '360p' : '720p' ) ),
	  isTemplate: this.state.saveAsTemplate,
	  reccuringId: this.state.parentRecrId,
	  updateAll: tempUpdateAll,
	  allowPartMove: this.state.allowPartMove,
	  usingMarkup: this.state.usingMarkup,
	  calculateAssessmentDimensions: this.state.calculateAssessmentDimensions,
	  noWeekDays: (tempUpdateAll && noWeekDays ) ? true : false,
	  recStatus: recStatus,
	  confirmCancelSessions: cancelSessions ? true : false,
	  interestSubcategories: (interestSubcategories && interestSubcategories.length > 0) ? interestSubcategories : [],
	  marketplaceStatus: this.state.marketplaceStatus,
	  replayClassId: this.state.pickedReplayId,
	  musicType: this.state.chosenMusic,
	  workoutSoundStart: this.state.workoutSoundStart,
	  workoutSoundEnd: this.state.workoutSoundEnd,
	  showPersonalBest: this.state.showPersonalBest === "Only for this Class" ? true : false
      }


	if ( this.state.isMarketplaceChannel )
		{
			session['resolution'] = '1080p';
			
			if ( this.state.newInstrestSelected && this.state.newInstrestSelected.length > 0 )
				{
					session['interest_id'] = JSON.stringify(this.state.newInstrestSelected);
				}
			if ( this.state.liveInterestSubcategories && this.state.liveInterestSubcategories.length > 0 )
				{
					session['interestSubcategories'] = JSON.stringify(this.state.liveInterestSubcategories);
				}
		}	
		
      const reminder = {
        host_reminder:this.state.hostSessionStart,
        participants_reminder:this.state.participantSessionStart,
         //cutoff_date_time:this.state.signUpDateTime,
        cutoff_date_time:"2019-11-2 15:06:01",
        min_participants_not_met:this.state.minimumNotMet
      }
      const privacy ={
        allow_participants_disable_dm:this.state.disableParticipant,
        show_part_pic_to_other_part:this.state.showParticipant,
        allow_participants_pick_playlist:this.state.allowParticipant
      }
      const groups = {
        allow_group_location : this.state.allowLocation
        }
     
      // const fitnessActivity = {
      //   fitnessActivity : this.state.tablerows
      // } 
	  
	  console.log('TABLE ROWSSSSS');
	  console.log(this.state.tablerows);
	  if( this.state.groupId === 1 )
		{
			for(let v =0 ; v<this.state.tablerows.length;v++){
		  //  console.log(this.state.tablerows[v]);
			let activity_data ={
			  "name": this.state.tablerows[v].ActivityName,
			  "attributes" : [
				{
				 "attrKey": "Activity Type",
				 "attrValue": this.state.tablerows[v].ActivityType,
				 "orderNo": 1
				},
				{
				 "attrKey": "Duration",//"Duration Type"
				 "attrValue": this.state.tablerows[v].DurationType,
				 "orderNo": 4
				},
				{
				 "attrKey": "counter",//"Count"
				 "attrValue": this.state.tablerows[v].Count,
				 "orderNo": 5
				},
				{
				 "attrKey": "repeat",//"Count"
				 "attrValue": this.state.tablerows[v].Repeat, 
				 "orderNo": 7
				},
				{
				 "attrKey": "Video",
				 "attrValue": this.state.tablerows[v].Video,
				 "orderNo": 2
				},
				{
				 "attrKey": "Target BPM",
				 "attrValue": this.state.tablerows[v].TargetBPM,
				 "orderNo": 6
				},
				{
				 "attrKey": "Target Zone",
				 "attrValue": this.state.tablerows[v].TargetZone,
				 "orderNo": 3
				},
				{
				 "attrKey": "VideoRepeat",
				 "attrValue": this.state.tablerows[v].VideoRepeat ? 1 : 0,
				 "orderNo": 8
				},
				{
				 "attrKey": "VideoDuration",
				 "attrValue": this.state.tablerows[v].VideoDuration,
				 "orderNo": 9
				},
				{
				 "attrKey": "repsSeconds",//"Count"
				 "attrValue": this.state.tablerows[v].repsSeconds,
				 "orderNo": 10
				},
				{
				 "attrKey": "ActivityDisplayName",//"Count"
				 "attrValue": this.state.tablerows[v].ActivityDisplayName,
				 "orderNo": 11
				},
			  ]
			 } 
			 activities.push(activity_data);
			 //console.log("activities",activities,'activity_data=======lalit222222===========',activity_data);   
		  }
		}
	  else 
		{  
			activities = wineDetail; 
		}
		
		var mainActivity = this.state.tablerows;
		console.log('mainActivity---', mainActivity);
		
		
      const script ={
        next_activity : this.state.automatic?"activity":"manual",
        heart_rate_monitor:this.state.scriptHeartRateMonitor,
        zone_tracking:this.state.scriptZoneTracking

      }
      
      const shopping_list ={
        shoppingList:this.state.addedShopList
      }
	  
	  const user_playlist = {
		  userPlaylist: this.state.selectedPlaylist.playlist_id
	  }
	  
	  var equipmentListArray = this.state.equipmentListMain;
	  
      const equipment_list = {
        equipmentList: equipmentListArray 
      }
      const host_list = {
        hostList : this.state.hostList2
      }
	  
	  var stations = this.state.sessionFmStations;
     
    //console.log("========sessioncreation222==================>",{shopping_list,equipment_list, activities,reminder,privacy,session,groups,script,host_list});
    
      if (this.validator.allValid()) {
	
// @ak commented was here
        if( (parseInt(this.state.minimumParticipants) > 0 && parseInt(this.state.maximumParticipants) <= 50 && (parseInt(this.state.maximumParticipants)>=parseInt(this.state.minimumParticipants))) || this.state.isMarketplaceChannel === true ){  
    //if(this.state.tablerows.length>0){ 
        this.setState({
        loading:true
        })    
      let token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTEsImlhdCI6MTU3MTg0NTI0MiwiZXhwIjoxNTcxOTMxNjQyfQ.bt7j269i43_73TiyzrFOFWM6sTizdcaHn6i4Sjdwb3w";
      let token1 = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
      axios.defaults.headers.common['Authorization'] = token1;
	  
	  let url;
	  if( this.props.params.id )
		{ 
			url = process.env.REACT_APP_NAME+"/api/v1/session/"+this.props.params.id+"/editFitnessSession";
		}
	  else
		{
			url = process.env.REACT_APP_NAME+"/api/v1/session/create";
		}
		
	  const mthis = this;
	  //console.log('ALL DATA');
	  console.log({user_playlist, shopping_list,equipment_list, activities, mainActivity, reminder,privacy, session, groups, script, host_list});
		
	let isAdmin = this.state.isAdmin;		

	axios({method: (this.props.params.id ? "put" : "post"), url: url, data: {user_playlist, shopping_list,equipment_list, activities, mainActivity, reminder,privacy, session, groups, script, host_list, isAdmin, stations}})
      .then(res => {
          if(res.data.responseMessage == "success")
            {
				document.removeEventListener("keydown", this.keyDownHandler);
				if( mthis.props.params.id )
					{
						mthis.stopEditingSession();
						this.setState({
							responseMessage:"Class data updated successfully",
							loading:false
						  },()=>{$("#successResultEdit").attr({'style':'display:block'});});
					}
				else
					{
						if ( !this.state.isMarketplaceChannel )
							{
								let hostFirstName = '';
								if ( !this.state.saveAsTemplate )
									{
										for ( let i = 0; i<this.state.hostList.length; i++ )
											{
												if ( this.state.hostList[i]['userId'] == this.state.hostList2[0] )
													{
														hostFirstName = this.state.hostList[i]['userFirstName'];
													}
											}
									}
									
								this.setState({
							   // msg: "Class hasbeen created Successfully!!!!!!!",
								urlLink:res.data.responseData.urlcode,
								// sess_name:res.data.responseData.sessionDt.name,
								// sess_time:res.data.responseData.sessionDt.scheduleDate,
								// uname:res.data.responseData.sessionDt.firstName,
								loading:false,
								sessionHostFirstName: hostFirstName
							  });
								if ( localStorage.getItem("sessionInterestSubcategories"))
									{
										localStorage.removeItem("sessionInterestSubcategories");
									}
								if ( !this.state.saveAsTemplate )
									{
										$("#successResult").attr({'style':'display:block'});  
									}
								else
									{
										this.setState({templateWhaitForConfirm: true});
										this.props.showMainErrorPop('Template has been saved successfully');
									}
							}
						else
							{
								if ( this.state.saveAsTemplate )
									{
										this.setState({templateWhaitForConfirm: true});
										this.props.showMainErrorPop('Template has been saved successfully');									
									}
								else 
									{
										browserHistory.push('/dashboardLanding');
									}									
								
							}
					}
           }else{

          this.setState({
            msg: "There Is a error in class creation",
          });

        }

      }).catch(err=>{
        if(err.response.data.responseCode===417){
          console.log(!err.response.data.errorData)
          if(!err.response.data.errorData){
              this.setState({
                loading:false
			  });
			  this.props.showMainErrorPop("This should not have happened! Due to few unacceptable characters inputs, we are not able to process further.Please remove all the text and try entering different value.");
          }else{
			  this.setState({
				  loading:false
			  });
			  
			    if ( err.response.data.errorData.sessionOverlap )
					{
						if ( err.response.data.errorData.sessionOverlap.length > 1 )
							{
								let SesOverlap = [];
								for ( var j = 0; j < err.response.data.errorData.sessionOverlap.length; j++)
									{
										SesOverlap.push(err.response.data.errorData.sessionOverlap[j].name+",  On "+this.getSessionDateUtil(err.response.data.errorData.sessionOverlap[j].scheduleDate, true)+"  at "+this.getSessionTimeUtil(err.response.data.errorData.sessionOverlap[j].scheduleDate));
									}
								let newMsg="You currently have more than one class, ["+SesOverlap+"], from the same recurring classes group, in the same day. Can't set up same start time for all this classes.!";
								this.props.showMainErrorPop(newMsg);
							}
						else
							{
								let SesOverlap = err.response.data.errorData.sessionOverlap.name+",  On "+this.getSessionDateUtil(err.response.data.errorData.sessionOverlap.scheduleDate, true)+"  at "+this.getSessionTimeUtil(err.response.data.errorData.sessionOverlap.scheduleDate);
								let newMsg="You currently have an overlapping class, ["+SesOverlap+"], during the time you are trying to schedule this class. Please change the time of this class or cancel/modify time of the overlapping class.!";
								this.props.showMainErrorPop(newMsg);
							}
					}
				else if ( err.response.data.errorData.sessionsForCancel )
					{
						if ( err.response.data.errorData.sessionsForCancel.length > 1 )
							{
								let SesOverlap = [];
								for ( var j = 0; j < err.response.data.errorData.sessionsForCancel.length; j++)
									{
										if ( err.response.data.errorData.sessionsForCancel.length > 1 && j != 0 )
											{
												SesOverlap.push(" "+err.response.data.errorData.sessionsForCancel[j].name+",  On "+this.getSessionDateUtil(err.response.data.errorData.sessionsForCancel[j].scheduleDate, true)+"  at "+this.getSessionTimeUtil(err.response.data.errorData.sessionsForCancel[j].scheduleDate));
											}
										else
											{
												SesOverlap.push(err.response.data.errorData.sessionsForCancel[j].name+",  On "+this.getSessionDateUtil(err.response.data.errorData.sessionsForCancel[j].scheduleDate, true)+"  at "+this.getSessionTimeUtil(err.response.data.errorData.sessionsForCancel[j].scheduleDate));
											}
									}
								this.setState({
									  cancelSessionsMsg1 : "Due to a classes schedule change, some classes will be canceled:",
									  cancelSessionsMsg2 : "["+SesOverlap+"]",
									  cancelSessionsMsg3 : "Do you want to cancel these classes?"
									}, ()=>{window.$('#cancel_sessions_warning').modal('show');});
							}
						else
							{
								let SesOverlap = err.response.data.errorData.sessionsForCancel[0].name+",  On "+this.getSessionDateUtil(err.response.data.errorData.sessionsForCancel[0].scheduleDate, true)+"  at "+this.getSessionTimeUtil(err.response.data.errorData.sessionsForCancel[0].scheduleDate);
								this.setState({
									  cancelSessionsMsg1 : "Due to a classes schedule change, one class will be canceled:",
									  cancelSessionsMsg2 : "["+SesOverlap+"]",
									  cancelSessionsMsg3 : "Do you want to cancel this class?"
									}, ()=>{window.$('#cancel_sessions_warning').modal('show');});
							}
					}
				else if ( err.response.data.errorData.errM )
					{
						this.props.showMainErrorPop(err.response.data.errorData.errM);
					}
				else if ( typeof err.response.data.errorData == 'string' )
					{
						this.props.showMainErrorPop(err.response.data.errorData);
					}
			}
          }else{
			  this.setState({
                loading:false
			  });
			  this.props.showMainErrorPop("We are getting trouble in reaching to our server/s. Please refresh the page and try again!");
          }
          
        
      })
	 /*
    }// for activity checking
     else{
		let resMessage = '';
		if( this.state.groupId === 1 )
			{
				resMessage = 'Virdio requires a minimum of one completed Activity which includes: Activity Name, Activity Type, Duration Type and Count(secs).';
			}
		else
			{
				resMessage = 'Virdio requires minimum one Wine in Class.';
			}
		
      this.setState({
        responseMessage: resMessage
    },()=>$("#email_verification").attr({'style':'display:block'}))
    //this._input.focus();
     } 
	 */

	}
    else {
      console.log('Wrong');
      this.setState({
        errormsg:'Please Check minimum and maximum Member value'
      })
    }
	   
    }else{

      console.log('----------------This is a error--------------------')
     this.validator.showMessages();
    //@ak commented code was which was removed    
        $("#error_msg").attr({'style':'display:block'});

      //}
        
    // rerender to show messages for the first time
    // you can use the autoForceUpdate option to do this automatically`
    this.forceUpdate();
    }
    
}

getFaq=(e)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqsById", {id: e})          
	.then(res => {
		if(e == 29 || e == 37 || e == 22 || e == 15 || e == 28)
			{
				this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs, true)});
			}
			
		else
			{
				this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs)});
			}
	})
	.catch(err =>{ });  
}

changeSessionImage =(e)=>{
	let file = e.target.files[0];
	if ( file.type === 'image/png' || file.type === 'image/jpeg' )
		{
			
			var mthis = this;
			var fr=new FileReader();
			fr.onload = function(e)
				{
					$('.main_loader').attr({'style':'display:flex'});
					
					const data = new FormData();
					data.append('file', file);
					data.append('sessionImage', 1);
					
					axios.post(process.env.REACT_APP_NAME+"/api/v1/upload/upload", data)
					.then(res => {
						mthis.setState({session_image: res.data.responseData.file});
						$('.main_loader').hide();
					}).catch(err =>{ $('.main_loader').hide(); });
				};
			fr.readAsDataURL(file);
		}
		
	else
		{
			this.props.showMainErrorPop("Only .png and .jpeg extensions are supported.");
		}
}

changeMenu=(menu)=>{
	this.setState({creationMenu: menu});
}


setRecEndDate = ()=>{
	
	$("#calender_recurring_modal").attr({'style':'display:block'});
	if ( this.state.editRecurringSession )
		{
			this.setState({
							recurEndCalender: new Date(this.state.editRecurringSession)
						});
		}
}

setRecStartDate = ()=>{
	if ( this.state.editRecurringSession )
		{
			this.setState({
							recurStartCalender: new Date(this.state.recurStartCalender)
						});
		}
	setTimeout( function(){
		$("#calender_recurring_modal2").attr({'style':'display:block'});
	}, 5);
}

checkPlaylistIfExist = (e)=>{
	
	let userDeviceName = localStorage.getItem('userDeviceName');
	/*check if exist user deviceName*/
	/*check if in session exist playlist*/
	/*check if that playlist have deviceName*/
	/*if not, run popup*/
	/*if yes, check if the deviceName from playlist is the same as user deviceName, if not, run popup*/
	if ( userDeviceName && this.state.playlistId != '' && this.state.deviceName != '' && this.state.deviceName != userDeviceName )
		{
			window.$('#copy_from_previous_playlist_check').modal('show');
			return;
		} 
	
	window.$('#allprevsession').modal('hide');
	if ( this.state.copyFromWorkout )
		{
			this.onSessionChangedWorkout();
		}
	else
		{
			this.onSessionChanged();
		};
}

useSession = (e)=>{
	window.$('#allprevsession').modal('hide');
	window.$('#copy_from_previous_playlist_check').modal('hide');
	if ( this.state.copyFromWorkout )
		{
			this.onSessionChangedWorkout();
		}
	else
		{
			this.onSessionChanged();
		};
}

cancelUseSession = (e)=>{
	window.$('#copy_from_previous_playlist_check').modal('hide');
}

cancelThisAction = (e)=>{
	window.$('#cancel_sessions_warning').modal('hide');
}

cancelSessionsAndFinishEditRecurring = (e)=>{
	window.$('#cancel_sessions_warning').modal('hide');
	this.continueSubmitForm(true,false,true);
}

addStation=(object)=>{
	var sessionStationMinutes = this.state.sessionFmTimes.sort(function(a, b){ if(a < b) { return -1; } if(a > b) { return 1; } return 0; });
	var sessioStations = this.state.sessionFmStations.sort(function(a, b){ if(a.time < b.time) { return -1; } if(a.time > b.time) { return 1; } return 0; });
	sessionStationMinutes.push(object.time);
	sessioStations.push(object);

	this.setState({sessionFmTimes: sessionStationMinutes, sessionFmStations: sessioStations, chosenStationName: object && object['fmStationName'] ? object['fmStationName'] : '' });
}

removeStation=(i)=>{
	var sessionStationMinutes = this.state.sessionFmTimes.sort(function(a, b){ if(a.time < b.time) { return -1; } if(a.time > b.time) { return 1; } return 0; });
	var sessioStations = this.state.sessionFmStations.sort(function(a, b){ if(a.time < b.time) { return -1; } if(a.time > b.time) { return 1; } return 0; });
	
	if ( sessioStations[i].time == -1 && this && this.sessionFieldsRef && this.sessionFieldsRef.current ) 
		{
			this.sessionFieldsRef.current.updatePickedState("");
		}
	
	sessionStationMinutes.splice(i, 1);
	sessioStations.splice(i, 1);
	
	this.setState({sessionFmTimes: sessionStationMinutes, sessionFmStations: sessioStations});
}

editStation=(object, i)=>{
	var sessionStationMinutes = this.state.sessionFmTimes.sort(function(a, b){ if(a < b) { return -1; } if(a > b) { return 1; } return 0; });
	var sessioStations = this.state.sessionFmStations.sort(function(a, b){ if(a.time < b.time) { return -1; } if(a.time > b.time) { return 1; } return 0; });
	sessionStationMinutes[i] = object.time;
	sessioStations[i] = object;
	
	this.setState({sessionFmTimes: sessionStationMinutes, sessionFmStations: sessioStations});
}

closeMarketplaceSavePop=(e)=>{
	this.setState({
		showReviewMessageBefore: false,
		rememberUpdateAll: false
	});
	$("#marketplace_save").attr({'style':'display:none'});
}

goToDashboardFun=(e)=>{
	this.stopEditingSession();  
	$("#go_Dashboard").attr({'style':'display:none'});
	if ( this.state.showIfTemplate == 0 )
		{
			if ( this.state.marketPlaceEditStatus )
				{
					browserHistory.push('/DashboardLanding#classManagement');
				}
			else 
				{
					browserHistory.push('/DashboardLanding')
				}				
		}
	else 
		{
			browserHistory.push('/SessionTemplates');
		}		
	
}

enterInputValue = (mod) =>{
	let temp = mod.replace(/[^0-9]/g,"");
	
	this.setState({changePlaceNew: temp});
}

////////////////////////////////////////////////////////////////////////////////

  render() {

    let strold=[];
    if(this.state.mon==true){
      strold.push('Mon')
    }
    if(this.state.tue==true){
      strold.push('Tue')
    }
    if(this.state.wed==true){
      strold.push('Wed')
    }
    if(this.state.thu==true){
      strold.push('Thu')
    }
    if(this.state.fri==true){
      strold.push('Fri')
    }
    if(this.state.sat==true){
      strold.push('Sat')
    }
    if(this.state.sun==true){
      strold.push('Sun')
    }
    strold=strold.toString();
    var selectWeekDays = strold.replace(/,\s*$/, "");
    // console.log(selectWeekDays);
    //const { activitytype } = this.state.activityType;

   // console.log('----------activityType------------',this.state.activityType)
    let activitynewtype = '';
    activitynewtype =this.state.activityType.sort((a,b) => {
		if(a.activity_type < b.activity_type) { return -1; }
		if(a.activity_type > b.activity_type) { return 1; }
		return 0;
	}).map((e, key) => {
      return (
      <option key={key} value={e.activity_type}>{e.activity_type}</option>
      ); 
  })


    let  arr =[];
    let allSessions = '';
    let hostSession='';
    const { startDate, endDate } = this.state;
	let mainthis = this;
	allSessions = this.state.sessions.map((session, idx) => {
   
		  const { id, Name, channelName, hostName, interestId, interest_title, scheduleDate, duration } = session;
		  return (
					<tr data-position="100000000000000" id={"online-user-row-"+id} key={idx}>
						<td>{session.name}</td>
						{ this.state.sessionListMain &&
							<td>{session.interest_titles}</td> 
						}
						<td>{session.hostName}</td>
						{ session.isTemplate === 0 ?
							<td>{ mainthis.getSessionDateUtil(session.scheduleDateNotFormated)} @ {mainthis.getSessionTimeUtil(session.scheduleDateNotFormated)}</td>
							: 
							<td>{ mainthis.getSessionDateUtil(session.createdAtNotFormated)} @ {mainthis.getSessionTimeUtil(session.createdAtNotFormated)}</td>
						}
						<td>{session.duration}</td>
						<td>
							<div className="custom-control custom-radio">
								<input type="radio" className="custom-control-input" name="id"  value={id}  id={"customRadiobtn"+id}  onChange={this.sessionCopyFromId} />
								<label className="custom-control-label" name="id" htmlFor={"customRadiobtn"+id}></label>
							</div> 
						</td>
					</tr>		
			);
	  })
    

      hostSession=  this.state.hostList.map((row,i) => { 
        return (
          <div className="row checkboxdiv_3" key = {i}>
          <div className="col-md-4">
            <label className="form-check labelborder_host">
              <input type="checkbox" 
               name={row.userId}
               id ={i} 
               onChange={this.onSiteChanged}
               checked={row.status}
               value={row.userId}
			   className="form-radio"
               />
              <span className="form-check-label">{row.username}</span>
            </label>
          </div>
          </div>
        );
      })

     // console.log('----------lalitsession------------------',this.state.hostList2);

    return (<div>
	  <PlaylistPopup handleTempSelectedItem={this.handleTempSelectedItem} selectedPlaylist_id={this.state.selectedPlaylist_id} selectedPlaylist={this.state.selectedPlaylist} handleSelectPlaylist={this.handleSelectPlaylist} togglePopup={this.props.togglePlaylistPopup} openMainPopup={this.props.openMainPopup} playlists={this.props.userPlaylist}></PlaylistPopup>
	
      <div className="container-fluid custom-width">
	  <Header loginName={this.state.loginName} totalSession={this.state.totalSession} fullsubdomain={this.state.fullsubdomain}></Header>
	  
	  <div className={"session_creation_container contRelative rel_pos_less "+( !this.props.params.id ? "paddtp50" : "" )}>
		<div className="create_header">
			<div className="create_session_title width_texxt">
				<span className="relative">
					{ !this.props.params.id && this.state.showIfTemplate == 0 &&
						'Create Class'
					}
					{ this.props.params.id && this.state.showIfTemplate == 0 &&
						'Edit Class'
					}					
					{ !this.props.params.id && this.state.showIfTemplate == 1 &&
						'Create Template'
					}
					{ this.props.params.id && this.state.showIfTemplate == 1 &&
						'Edit Template'
					}
					{ !this.props.params.id &&
						<img src="/images/bulb.png" className="create_session_bulb" onClick={e=>{this.props.showMainErrorPop('Select the "+" icon next to Upcoming Classes and follow the steps outlined below:\
																	-Select the Class Type; you can choose multiple Areas of Categories.\
																	-Complete the Class Info tab; this is the only required information.\
																	-Workout Builder tab; Adding activities to the Class is optional but recommended for the best experience.\
																	-Select your Settings for the Class.\
																	-Add any Equipment you would like your Members to have for the Class. This information will be available to Members when they sign up.', true)}}/> 
						
					}
				</span>
				{ !this.props.params.id && this.state.pickedReplayId == 0 &&
					<div className="paddlft60">
						<div className="copyWork w_unset" data-toggle="modal" data-target="#allprevsession" onClick={()=>{this.fetchPrevSessionList('previous')}} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') } aria-hidden="true">
							<svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="svg_copy_icon"><path d="M1696 384q40 0 68 28t28 68v1216q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-288h-544q-40 0-68-28t-28-68v-672q0-40 20-88t48-76l408-408q28-28 76-48t88-20h416q40 0 68 28t28 68v328q68-40 128-40h416zm-544 213l-299 299h299v-299zm-640-384l-299 299h299v-299zm196 647l316-316v-416h-384v416q0 40-28 68t-68 28h-416v640h512v-256q0-40 20-88t48-76zm956 804v-1152h-384v416q0 40-28 68t-68 28h-416v640h896z" fill="#ffffff"/></svg>
							<div className="tooltip_hold pink_info tp25">
								<div className="tooltip_text">Copy from a Previous Class/Template</div>
							</div> 
						</div>
						<div className="inline_bulb" onClick={e=>{this.props.showMainErrorPop('Copy from a Previous Class/Template.', true)}}></div>
					</div>
				}
			</div>
			<div className="previous_container new_text_align_create_sessions">
				{/*{ !this.props.params.id && 
						 <div className="previous_part" data-toggle="modal" data-target="#allprevsession" onClick={()=>{this.fetchPrevSessionList('previous')}}>Copy from a Previous Class/Template
							<div className="abs_previous_img"></div>
						 </div>
				}*/}
				 <div className={"close_session_creation  close_abs_position"} onClick={(e)=>$("#go_Dashboard").attr({'style':'display:block'})}></div>
			</div>
		</div>
		<div className="creation_menu"> 
			<div className="one_creation_menu"><span className={"menu_create_span " + (this.state.creationMenu === 'Session Info' ? 'active_creation_menu' : '')} onClick={ () => { this.changeMenu('Session Info') }}>Class Info</span></div>
			{ this.state.pickedReplayId == 0 && !this.state.editReplayClass && 
				<div className="one_creation_menu"><span className={"menu_create_span " + (this.state.creationMenu === 'Workout Builder' ? 'active_creation_menu' : '')} onClick={ () => { this.changeMenu('Workout Builder') }}>Workout Builder</span></div>
			}
			{ (this.state.showIfTemplate == 0 && this.state.pickedReplayId == 0 && !this.state.isMarketplaceChannel) && 
				<div className="one_creation_menu"><span className={"menu_create_span " + (this.state.creationMenu === 'Settings' ? 'active_creation_menu' : '')} onClick={ () => { this.changeMenu('Settings') }}>Settings</span></div> 
			}
			{ this.state.pickedReplayId == 0 && !this.state.editReplayClass && 
				<div className="one_creation_menu"><span className={"menu_create_span " + (this.state.creationMenu === 'Equipment List' ? 'active_creation_menu' : '')} onClick={ () => { this.changeMenu('Equipment List') }}>Equipment List</span></div>
			}
			{ /* 
			{ (this.state.isMarketplaceChannel && this.state.pickedReplayId == 0) &&
				<div className="one_creation_menu"><span className={"menu_create_span " + (this.state.creationMenu === 'Feed FM' ? 'active_creation_menu' : '')} onClick={ () => { this.changeMenu('Feed FM') }}>Feed FM</span></div> 
			} */}
			{ /* <div className="one_creation_menu"><span className={"menu_create_span " + (this.state.creationMenu === 'Charges' ? 'active_creation_menu' : '')} onClick={ () => { this.changeMenu('Charges') }}>Charges</span></div>  */}
		</div>
		<div className="" className={"main_container_creation " + ( this.state.creationMenu !== 'Session Info' ? " d-none " : "" )}>
			<div className="container_title">Class Info</div>
			<div className="container_subtitle">Fields marked with an <span>*</span> are Required.</div>
			<div className="for_info_fields">
				<div className="fields_left">
					<SessionFields 
						forWineHour={this.forWineHour}
						sessionInfo={this.sessionInfo}
						updateDescField={this.updateDescField}
						forWineMinute={this.forWineMinute}
						updateStateFromChild={this.updateStateFromChild}
						addStations={this.addStation} 
						removeStation={this.removeStation} 
						editStation={this.editStation}
						state={this.state}
						choseLowDef={this.choseLowDef}
						changeSessionImage={this.changeSessionImage}
						getFaq={this.getFaq}
						openNewIntrest={this.openNewIntrest}
						newSelectIntrest={this.newSelectIntrest}
						openLiveIntrestSubcategories={this.openLiveIntrestSubcategories}
						liveSelectIntrestSubcategory={this.liveSelectIntrestSubcategory}
						params={this.props.params}
						ref={this.sessionFieldsRef}
						>
					</SessionFields>
					<div className="absolute_border_part"></div>
				</div>
				<div className="fiels_right">
					<SessionPlaylistAndHost state={this.state} getFaq={this.getFaq} removeFromSelectedPlaylists={this.removeFromSelectedPlaylists} updateStateFromChild={this.updateStateFromChild}></SessionPlaylistAndHost>
				</div>
			</div>
		</div>
		
		{ this.state.creationMenu === 'Workout Builder' &&
			<div className="main_container_creation">
				<div className="workout_container">
					<SessionScript
						state={this.state}
						editActivity={this.editActivity}
						CopyActivity={this.CopyActivity}
						updateMainInput={this.updateMainInput}
						removeActivity={this.removeActivity}
						forActivityHour={this.forActivityHour}
						forActivityMinute={this.forActivityMinute}
						sessionInfo={this.sessionInfo}
						location={this.props.location}
						addRow={this.addRow}
						activitynewtype={activitynewtype}
						updateStateFromChild={this.updateStateFromChild}
						choseDefaultReps={this.choseDefaultReps}
						handleDraggEnd={this.handleDraggEnd}
						handleDraggStart={this.handleDraggStart} 
						handleDraggitem={this.handleDraggitem}
						openInfoPopUp={this.openInfoPopUp}
						getFaq={this.getFaq}
						closeEditActivity={this.closeEditActivity}
						saveEditActivity={this.saveEditActivity}
						fetchPrevSessionListTemp={this.fetchPrevSessionListTemp}
						changePlace={this.changePlace}
					>
					</SessionScript>
				</div>
			</div>
		}
		
		{ this.state.creationMenu === 'Settings' &&
			<div className="main_container_creation">
				<div className="container_title">Settings</div>
				<SessionSettings state={this.state} updateStateFromChild={this.updateStateFromChild} getFaq={this.getFaq}></SessionSettings> 
			</div>
		}
		
		{ this.state.creationMenu === 'Equipment List' &&
			<div className="main_container_creation">
				<div className="container_title">Equipment List</div>
				<div className="workout_container">
					<SessionEquipmentList
						state={this.state}
						removeEquipmentList={this.removeEquipmentList}
						openAddingEquipment={this.openAddingEquipment}
						updateStateFromChild={this.updateStateFromChild}
						addNewEquipment={this.addNewEquipment}
						changeQuantity={this.changeQuantity}
						changeQuantityEdit={this.changeQuantityEdit}
						editEqu={this.editEqu}
						saveEditedEquipment={this.saveEditedEquipment}
						closeEditEqu={this.closeEditEqu}
						copyEqu={this.copyEqu}
						handleDraggEnd={this.handleDraggEnd}
						handleDraggStart={this.handleDraggStart} 
						handleDraggitem={this.handleDraggitem}
						openInfoPopUp={this.openInfoPopUp}
					 >
					 </SessionEquipmentList>
				 </div>
			</div>
		}
		
		{ this.state.creationMenu === 'Charges' &&
			<div className="main_container_creation">
				<div className="container_title inline_title">Charging for Class</div>
				<div className="inline_bulb" onClick={e=>{this.getFaq(37)}}></div>
				<label className="switch toggle_align_middle toggle_switch">
					<input type="checkbox" id="sessionCharge" checked={this.state.sessionCharge} onChange = {(e)=>{this.updateStateFromChild({[e.target.id]:!this.state.sessionCharge})}}/>
					<span className="slider round"></span>
				</label>
				<div className="toggle_option">{this.state.sessionCharge ? 'ON' : 'OFF'}</div>
				<SessionCharges state={this.state} updateStateFromChild={this.updateStateFromChild}></SessionCharges> 
			</div>
		}
		
		{ this.state.creationMenu === 'Feed FM' &&
			<div className="main_container_creation">
				<div className="container_title inline_title">Feed FM</div>
				<SessionFeedFm state={this.state} addStations={this.addStation} removeStation={this.removeStation} editStation={this.editStation} updateStateFromChild={this.updateStateFromChild}></SessionFeedFm>
			</div>
		}
		
		{ this.state.showSaveBtn &&
			<div className="for_save_create">
				<ClipLoader css={override} size={150} color={"#123abc"} loading={this.state.loading} />
				{ this.state.showIfTemplate == 0 &&
					<div className="create_session_btn" onClick={(e)=> {this.submitForm(e, 0)}}>SAVE & EXIT</div>
				}
				{ !this.props.params.id && this.state.showIfTemplate == 1 &&
					<div className="create_session_btn" onClick={(e)=> {this.submitForm(e, 1)}}>SAVE AS TEMPLATE</div>
				}
				{ this.props.params.id && this.state.showIfTemplate == 1 &&
					<div className="create_session_btn" onClick={(e)=> {this.submitForm(e, 1)}}>SAVE TEMPLATE</div>
				}
			</div>
		}
		
		
	  </div>
     
	 {
		/* { this.state.groupId === 1 ?
			<div className="relative" id="script_tutorial">
				
				{  this.state.chosenTutorialIndex == 2 && this.state.showTutorial && 
					<div className="all_tutorials tutorial_third_session">
						<MainTutorial
							chosenTutorial={this.state.tutorialToShow}
							state={this.state}
							changeTutorialIndex={this.changeTutorialIndex}
							skipTutorial={this.skipTutorial}
						></MainTutorial>
						<div className="arrow_tutorial arrow_session_cal"></div>
					</div>
				}
			</div>
			:
			<SessionWineSession 
				state={this.state}
				removeToWineDescription={this.removeToWineDescription}
				removeWineActivity={this.removeWineActivity}
				location={this.props.location}
				testerStatus={this.testerStatus}
				updateStateFromChild={this.updateStateFromChild}
				setEmojis={this.setEmojis}
				pushNewWine={this.pushNewWine}
				removeWine={this.removeWine}
				changeStatusTesters={this.changeStatusTesters}
				handleDraggEnd={this.handleDraggEnd}
				handleDraggStart={this.handleDraggStart} 
				handleDraggitem={this.handleDraggitem}
				editWine={this.editWine}
				closeEditWine={this.closeEditWine}
				copyWine={this.copyWine}
				openInfoPopUp={this.openInfoPopUp}
			></SessionWineSession>
		}
		 
		{ this.state.groupId === 2 &&
		 
		<SessionShopingList
		 state={this.state}
		 changeQuantity={this.changeQuantity}
		 addNewShop={this.addNewShop}
		 closeEdit={this.closeEdit}
		 editShop={this.editShop}
		 copyShop={this.copyShop}
		 removeShop={this.removeShop}
		 handleDraggEnd={this.handleDraggEnd}
		 handleDraggStart={this.handleDraggStart} 
		 handleDraggitem={this.handleDraggitem}
		 updateStateFromChild={this.updateStateFromChild}
		 openScriptPop={this.openScriptPop}
		 openInfoPopUp={this.openInfoPopUp}
		>
		</SessionShopingList>
		} */
		
	 }
	
  <div style ={{color:"red"}}>{this.state.errormsg}</div>
    
{/* Host Selection Start*/}
 <div className="modal py-3" id="myHost">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content mx-2">      
        <div className="modal-header headerborder">
          {/* <div className="plusicon"><i className="fa fa-plus"  aria-hidden="true"></i></div> */}
          <h4 className="modal-title white">Pick from existing instructor list</h4>
          {/* <button onClick={this.addHost}>ok</button> */}
          <button type="button" className="close white closepopup" data-dismiss="modal">&times;</button>
        </div>
        
        <div className="modal-body ">
         <div className="card cardbg">
          {/* Pick from existing Shopp */}
          {this.state.hostList.length>1?
            // @ak commented was remove
              hostSession
              :
              (this.state.hostList.map((row,i) => (  
                <div className="row checkboxdiv_3" key = {i}>
                  <div className="col-md-4">
                    <label className="form-check labelborder">
                      <input type="checkbox" 
                       name={row.userId}
                       id ={i} 
                        checked={true} 
                       onChange={this.selectHost}
                       className="form-radio"/>
                      <span className="form-check-label">{row.username}</span>
                    </label>
                  </div>
                 </div>
              )))
              }
         
        </div>
        
       </div>
       <div className="text-center">
          <div className="donebg"><button type="button" className="done" data-dismiss="modal" onClick={this.addHost}>ok</button></div>

        </div>
      </div>

    </div>
  </div>
{/* Host Selection End */}

{/*  Shopping List Selection Start */}

  <div className="modal" id="myModal3">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
      
        <div className="modal-header headerborder px-4">
          <div className="plusicon"><i onClick = {this.addToShoppingList} className="fa fa-plus" aria-hidden="true"></i></div>
          <h4 className="modal-title white">Pick from existing Shopping list</h4>
          <button type="button" onClick= {this.setShoppingList} className="close white closepopup" data-dismiss="modal">&times;</button>
        </div>
        
        <div className="modal-body px-4">
         <div className="card cardbg">
                
                
                <div className="searchbar mb-4">
                  <input type="text" 
                  id = "shoppingListValue" 
                  value ={this.state.shoppingListValue} 
                  onChange = {(e)=> this.setState({[e.target.id]:e.target.value},()=> console.log(this.state.shoppingListValue))}  
        
                  className="searchbarinput" 
                  placeholder="Search for Equipment"/>
                  <button className="inputbtn" onClick = {this.searchShoppingList} type="button">
                     
                  </button>
                </div>

                {this.state.shoppingList.map((row,i) => (
                <div className="row checkboxdiv_3" key = {i}>
                  <div className="col-md-3 pt-2">
                    <label className="custom-control custom-checkbox lebelheight pl-0">
                      <input type="checkbox" 
                       name={row.name}
                       id ={i} 
                       checked={row.type} 
                       onChange={this.selectShoppingList}
                       value = '20'
                      className="form-radio"/>
                      <span className="checktxt">{row.name}</span>
                    </label>
                  </div>
                  
                  {this.state.shoppingList[i].type ?
                  <div className="col-md-3">
                    <div  className="form-group mb-0"><span className="cover-border"></span>
                    <input type="text" 
                    id ={i}
                    value={row.Quantity}
                    onChange={this.handleShoppingQuantity(i)}
                    className="input-field-2" 
                    placeholder="Quantity"/></div>
                    {this.shoppingValidator.message('Quantity', row.Quantity, 'required|numeric')}
                    {/* <div  className="col-md-5" className="form-group"><span className="cover-border"></span>
                    <input type="text" 
                    id ={i}
                    value={row.itemNote}
                    onChange={this.handleShoppingitemNote(i)}
                    className="input-field-2" 
                    placeholder="item Note"/></div>
                    <div  className="col-md-5" className="form-group"><span className="cover-border"></span>
                    <input type="text" 
                    id ={i}
                    value={row.Link}
                    onChange={this.handleShoppingLink(i)}
                    className="input-field-2" 
                    placeholder="Add Link"/></div> */}
                  </div>
                  
                  : ''
                  }

                  {this.state.shoppingList[i].type ?
                  <div className="col-md-3">
                    <div  className="form-group mb-0"><span className="cover-border"></span>
                    <input type="text" 
                    id ={i}
                    value={row.itemNote}
                    onChange={this.handleShoppingitemNote(i)}
                    className="input-field-2" 
                    placeholder="item Note"/></div>
                    {this.shoppingValidator.message('Item Note', row.itemNote, 'required')}
                    </div>
                   : ''}
                   {this.state.shoppingList[i].type ?
                  <div className="col-md-3">
                    <div  className="form-group"><span className="cover-border"></span>
                    <input type="text" 
                    id ={i}
                    value={row.Link}
                    onChange={this.handleShoppingLink(i)}
                    className="input-field-2" 
                    placeholder="Add Link"/></div>
                    {this.shoppingValidator.message('Add Link', row.Link, 'url')}
                    </div>
                   : ''}


                </div>
                ))}
                
                {this.state.shoppingList.map((row,i) => (
                <div className="checkboxdiv_2" key = {i}>
               
        </div>
        ))}
        </div>
        
       </div> 
      </div>
       {/* <div className="donebg"><button type="button" className="done">Done</button></div> */}

    </div>
  </div>

  {/* Shopping List Selection End */}
  
  
  {/*  @ak commeted code remove here */}
  <div className="modal pt-3 pt-md-4" id="allprevsession">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content bg-transparent">
      <div className="modal-body pt-0 py-3 modalbg px-4 radius-8">
        <button type="button" className="close white closepurple translatX-Y session_list_close" data-dismiss="modal"><span>x</span></button>
        <div className="modal-title headerborder pb-3 px-4 mx-3 session_list_title">Class List</div>
		<div className="list_section_hold">
			<div className={ "list_section " + ( this.state.sessionListMain ? "list_section_active" : "" ) } onClick={e=>{this.changeSectionComp(e, 1)}}>Classes</div>
			<div className={ "list_section_right " + ( this.state.templateList ? "list_section_active" : "" ) } onClick={e=>{this.changeSectionComp(e, 2)}}>Templates</div>
		</div>
		<div className="main_input_section">
			<div className="flex-grow-1 input_field_container py-0 radius-8 main_section_search">
				<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
					<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center">
						<input type="text" className="form-control" placeholder="Search for instructor and category" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
					</div>
					<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-8' : ' col-xl-6')}>
						{/* <div className="">
							<div className="px-4 d-flex justify-content-end align-items-center">				
								<div className="custom-control custom-checkbox mb-0">
									<input type="checkbox" className="custom-control-input" id="customCheck1" value="false" checked={this.state.customCheck1} onChange={this.customChecked} name="example1" />
									<label className="custom-control-label" htmlFor="customCheck1"><p className="pl-2 p-top-3 mb-0">ADVANCED</p></label>
								</div>
							</div>
						</div> */}
					</div>
				</div>
				<div className="right-small-box" onClick={this.searchByInput}>
					<img src="/images/search_loop.png" alt="search" />
				</div>
			</div>
		</div>
        <div className="billing_table">
			{ !this.state.isLoadingSessions && this.state.sessions.length > 0 &&
				  <table className="table">
					<thead>
					  <tr>
						<th scope="col">Class Name
							<div onClick={ (e) => this.sortArrays('ses_name', this.state.sessions, 'sessions')} className="reports_sort_icon"></div>
						</th>
						{ this.state.sessionListMain &&
							<th scope="col">Category
								<div onClick={ (e) => this.sortArrays('interest', this.state.sessions, 'sessions')} className="reports_sort_icon"></div>
							</th>
						}
						<th scope="col">Instructor
							<div onClick={ (e) => this.sortArrays('host_name', this.state.sessions, 'sessions')} className="reports_sort_icon"></div>
						</th>
						<th scope="col">Date
							<div onClick={ (e) => this.sortArrays('date_time', this.state.sessions, 'sessions')} className="reports_sort_icon"></div>
						</th>
						<th scope="col">Duration</th>
						<th scope="col"></th>
					  </tr>
					</thead>
					{ this.state.sessions.length > 0 &&
						<tbody id="online-user-list">
						{allSessions}                
						</tbody>
					}
				  </table>
			}
			{ !this.state.isLoadingSessions && this.state.sessions.length === 0 &&
				<div className="empty_template pt20">No results found.</div>
			}
        </div>
		<div className="text-center">
			{ this.state.shownLength != this.state.dataLength && !this.state.isLoadingSessions &&
				<div className="dashboard_load_hold">
					<div className="custom_btn1 px-5 mt-3 load_more_main_button"><div onClick={(e) => this.loadMorePrevious()}>load more</div><img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb load_more_bulb" onClick={e=>{this.getFaq(30)}}/></div>
				</div>
			}
		</div>
      </div>
      <div className="text-center sticky_button">
        <button type="button" className="custom_btn1 px-5 mt-3 stickybutton" onClick={this.checkPlaylistIfExist}>ok</button>
      </div>
    </div>
  </div>
</div>  
<div className="modal" id="linkGenerator">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">

      <div className="modal-header">
        <h4 className="modal-title text-white text-center">{this.state.Abhi}You have successfully created a class</h4>
        <button type="button" className="close" data-dismiss="modal">&times;</button>
      </div>


      <div className="modal-body">
        <h5 className="text-white">Congratulations ,you have created the class "Introduction to wine testing" to be hosted by Arjun on August 13th2019 12:30PM</h5>
        <h5 className="text-white"> You can start inviting Members by sharing  the link below </h5><br></br>
        <div className="row" >
          <div className="form-group mb-5 m-auto"><span className="cover-border"></span>
            <label className="label">Link</label>
            <input type="text" 
            id ='Link'
            value='https://virdio.com'
            disabled
            className="input-field" />
          </div>
        </div>
    </div>

       </div>
  </div>
</div> 

<div className="modal cal_modal mt-3" id="calenderModel" data-backdrop="">
  <div className="modal-dialog d-md-flex d-block large_width1 mb-0">
    <div className="modal-content modalbg m-auto relative mw750">
      <div className="modal-header px-4 pt-3 pb-0">
        <h4 className="white modal-title">Choose Date and Time</h4>
        <button type="button pr-3" className="close white closepopup" onClick={e=>$("#calenderModel").attr({'style':'display:none'})}>&times;</button>
      </div>
	  {  this.state.chosenTutorialIndex == 0 && this.state.showTutorial && 
			<div className="all_tutorials tutorial_first_session">
				<MainTutorial
					chosenTutorial={this.state.tutorialToShow}
					state={this.state}
					changeTutorialIndex={this.changeTutorialIndex}
					skipTutorial={this.skipTutorial}
				></MainTutorial>
				<div className="arrow_tutorial arrow_session_cal"></div>
			</div>
		}
      <div className="modal-body modal-body px-1 px-sm-3">
      <h3>Calender</h3>
      
     

        <Calendar
           onChange={this.onChange1}
           value={this.state.sessionCalender}
           minDate={new Date()}
         />
      <div className="botm_container">
        <div className="row mx-0 mt-4 justify-content-between flex-wrap">
          <div className="mt-2 px-1 px-sm-2 inline_hours">
            <div className="form-group mb-0 select_hours_session">
				<span className="cover-border"></span>
                <label className="label">Hours</label>
				<select className="input-field fields_back select_color_new" id="sessionHours" value={this.state.sessionHours} onChange={this.timeset2}>
					{ this.state.hoursArray.map((row, i) => 
						<option value={row} key={i}>{row}</option>
					)}
				</select> 
            </div>
			<div className="form-group mb-0 select_hours_session">
				<span className="cover-border"></span>
                <label className="label">Minutes</label>
				<select className="input-field fields_back select_color_new" id="sessionMinutes" value={this.state.sessionMinutes} onChange={this.timeset2}>
					{ this.state.minutesArray.map((row, i) => 
						<option value={row} key={i}>{row}</option>
					)}
				</select>
			</div>
			<div className="form-group mb-0 select_hours_session">
				<span className="cover-border"></span>
                <label className="label">AM/PM</label>
				<select className="input-field fields_back select_color_new" id="sessionAMPM" value={this.state.sessionAMPM} onChange={this.timeset2}>
					{ this.state.ampmArray.map((row, i) => 
						<option value={row} key={i}>{row}</option>
					)}
				</select>
			</div>
          </div>
		  
		  <div className="mt-2 px-1 px-sm-2 w180">
			 <div className="form-group mb-0">
				<span className="cover-border"></span>
				<label className="label">Enter Timezone</label>
				<select className="input-field fields_back select_color_new" id="chosenTimezone" value={this.state.chosenTimezone} onChange={this.sessionInfo}>
					{ this.state.timezonesArray.map((row, i) => 
						<option value={row} key={i}>{row}</option>
					)}
				</select>
			</div>
		  </div>
          
          
			
			{ (!this.props.params.id || ( this.state.endRecrwhen != '' || this.state.editRecurringSession ) ) && !this.state.isMarketplaceChannel &&  
				<div className="mt-2 px-1 px-sm-2 reccuring_width">
					{ this.state.endRecrwhen != '' || this.state.editRecurringSession ?
						<div className=""><button className="session_btn px-1 px-sm-3 py-2" onClick={this.recurringSessionDate}><i className="fa fa-eye" aria-hidden="true"></i> View Recurring</button></div>
						:	
						<div className="">
							{ !this.props.params.id &&
								<div className="relative">   
									<img src="/images/bulb.png" className="mr-2 mb-2 cursor-pointer" onClick={e=>{this.getFaq(25)}}/>
									<button className="session_btn px-1 px-sm-3 py-2 relative" onClick={this.recurringSessionDate}>
										<img src="../images/recurring.png" className="mr-1 mr-sm-2" />Setup As Recurring
									</button>
								</div>
							}
						</div>
					}
				</div>
			}
          
		  <div className="col-md-6 pr-4 d-none">
       
          </div>
        </div>
      </div>
      </div>
        <div className="text-center position-absolute btn_btn1">
        {this.state.repeatSession?'':<button type="button" className="done mt-0" onClick={this.sessionDate} >done</button>}
        </div>
      </div>
      {this.state.repeatSession?
      <div className="wd align-self-end d-none d-md-block"><img src="/images/path.png" className="w-100" /></div>:''}
      {this.state.repeatSession?
      <div className="modal-content modalbg align-self-end px-4 py-4 mt-2 mt-md-0">
      <div className="modal-header headerborder px-0">
      <div style={{color:"red"}}>This feature is't available</div>
        <h4 className="white modal-title">Repeat Class</h4>
      </div>
      <div className="modal-body px-0">
      <h5 className="white">Frequency</h5>
      <div className="d-flex flex-wrap">
      <a href="#" id='varietal' name='0' onClick = {this.sessionAttribute} className={(this.state.sessionClass[0]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>varietal</a>
      <a href="#" id='Every day' name='1' onClick = {this.sessionAttribute} className={(this.state.sessionClass[1]?"btn btn-primary":"btn")+" btn-outline-secondary text-uppercase mr-2 mt-2"}>Every day</a>
      <a href="#" id='once a week' name='2' onClick = {this.sessionAttribute} className={(this.state.sessionClass[2]?"btn btn-primary":"btn")+" btn-outline-secondary text-uppercase mr-2 mt-2"}>once a week</a>
      <a href="#" id='twice a week' name='3' onClick = {this.sessionAttribute} className={(this.state.sessionClass[3]?"btn btn-primary":"btn")+" btn-outline-secondary text-uppercase mr-2 mt-2"}>twice a week</a>
      <a href="#" id='3 times a week' name='4' onClick = {this.sessionAttribute} className={(this.state.sessionClass[4]?"btn btn-primary":"btn")+" btn-outline-secondary text-uppercase mr-2 mt-2"}>3 times a week</a>
      <select className="custom_field mt-2 mb-0" 
      id="sessionFrequency"
      value = {this.state.sessionFrequency}
      onChange = {this.sessionInfo}>
        <option>custom</option>
        <option>1 week</option>
        <option>2 week</option>
        <option>3 week</option>
        <option>4 week</option>
        </select>
      </div>
      <h5 className="white mt-4">Duration</h5>
      <div className="d-flex flex-wrap">
      <a href="#" id='1 week' name='5' onClick = {this.sessionAttribute} className={(this.state.sessionClass[5]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>1 week</a>
      <a href="#" id='2 weeks' name='6' onClick = {this.sessionAttribute} className={(this.state.sessionClass[6]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>2 weeks</a>
      <a href="#" id='3 weeks' name='7' onClick = {this.sessionAttribute} className={(this.state.sessionClass[7]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>3 weeks</a>
      <a href="#" id='4 weeks' name='8' onClick = {this.sessionAttribute} className={(this.state.sessionClass[8]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>4 weeks</a>
      <a href="#" id='5 weeks' name='9' onClick = {this.sessionAttribute} className={(this.state.sessionClass[9]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>5 weeks</a>
      <select className="custom_field mt-2 mb-0" 
      id="sessionDuration"
      value = {this.state.sessionDuration}
      onChange = {this.sessionInfo}
      >
        <option>custom</option>
        <option>1 week</option>
        <option>2 week</option>
        <option>3 week</option>
        <option>4 week</option>
        </select>
      </div>
      </div>
      <div className="text-center position-absolute btn_btn1">
          <button type="button" onClick={this.saveSessionCalenderDetail} className="done mt-0">save</button>
      </div>
     {/* <img src="images/path.png" className="small_cont" /> */}
      {/* <div className="modalbg small_cont"></div> */}
      </div>:''}
  </div>
</div>

{/* SETUP AS RECURRING MODAL */}

<div className="modal pt-3" id="recurring_schedule" data-backdrop="">
  <div className="modal-dialog">
    <div className="modal-content px-2 bg-transparent">
      <div className="modal-body py-2 pt-sm-3 modalbg px-3 radius-8">
        <div className="overflow-hidden">
          <button type="button pr-3" className="close white closepopup" onClick={(e)=>$("#recurring_schedule").attr({'style':'display:none'})}>&times;</button>
        </div>
        <div className="inner-box mx-auto">
          <h4 className="white text-center pb-0 pb-sm-2">Recurring Schedule</h4>
          <div className="mt-4">
            <div className="form-group" onClick={this.props.params.id && this.state.firstActiveRecurringSessionStartDate ? this.setRecStartDate : ''}>
              <span className="cover-border bg_gray_clr"></span>
              <label className="label">Start Date<sup>*</sup></label>
              <input type="text" id="when" value ={ this.props.params.id && this.state.firstActiveRecurringSessionStartDate ? this.state.firstActiveRecurringSessionStartDate :  this.state.whenSessionTime} className="input-field fade_start" readOnly />
              <a className="when-icon"></a>
            </div>
          </div>
          <div className="mt-4">
            <div className="form-group" onClick={this.setRecEndDate}>
              <span className="cover-border bg_gray_clr"></span>
              <label className="label">End Date<sup>*</sup></label>
              <input
                type="text"
                id = "endRecrwhen" 
                value = { this.props.params.id && this.state.latestRecurringSessionEndDate == '' ? this.state.whenSessionTime :  this.state.endRecrwhen}
                className="input-field"
                placeholder="Pick a date and time"
                readOnly
              />
              <a className="when-icon" onClick={this.setRecEndDate}></a>
            </div>
          </div>
          <p className="text-white mb-0 mb-sm-3">Repeat on the following days</p>
          <div className="d-flex flex-wrap">
            <div className="mr-3">
            <label className="custom-control custom-checkbox lebelheight d-flex align-items-center pl-0 mb-0">
              <input type="checkbox" id="mon" className="form-radio" checked={this.state.mon} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.mon},()=>console.log('Mon',this.state.mon))}} /><p className="text-white mb-0 mt-2 pt-1">Mon</p>
            </label>
            </div>
            <div className="mr-3">
            <label className="custom-control custom-checkbox lebelheight d-flex align-items-center pl-0 mb-0">
              <input type="checkbox" id="tue" className="form-radio" checked={this.state.tue} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.tue},()=>console.log('Tue',this.state.tue))}} /><p className="text-white mb-0 mt-2 pt-1">Tue</p>
            </label>
            </div>
            <div className="mr-3">
            <label className="custom-control custom-checkbox lebelheight d-flex align-items-center pl-0 mb-0">
              <input type="checkbox" id="wed" className="form-radio" checked={this.state.wed} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.wed},()=>console.log('Wed',this.state.wed))}}/><p className="text-white mb-0 mt-2 pt-1">Wed</p>
            </label>
            </div>
            <div className="mr-3">
            <label className="custom-control custom-checkbox lebelheight d-flex align-items-center pl-0 mb-0">
              <input type="checkbox" id="thu" className="form-radio" checked={this.state.thu} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.thu},()=>console.log('Thu',this.state.thu))}}/><p className="text-white mb-0 mt-2 pt-1">Thu</p>
            </label>
            </div>
            <div className="mr-3">
            <label className="custom-control custom-checkbox lebelheight d-flex align-items-center pl-0 mb-0">
              <input type="checkbox" id="fri" className="form-radio" checked={this.state.fri} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.fri},()=>console.log('Fri',this.state.fri))}}/><p className="text-white mb-0 mt-2 pt-1">Fri</p>
            </label>
            </div>
            <div className="mr-3">
            <label className="custom-control custom-checkbox lebelheight d-flex align-items-center pl-0 mb-0">
              <input type="checkbox" id="sat" className="form-radio" checked={this.state.sat} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.sat},()=>console.log('Sat',this.state.sat))}}/><p className="text-white mb-0 mt-2 pt-1">Sat</p>
            </label>
            </div>
            <div className="mr-3">
            <label className="custom-control custom-checkbox lebelheight d-flex align-items-center pl-0 mb-0">
              <input type="checkbox" id="sun" className="form-radio" checked={this.state.sun} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.sun},()=>console.log('Sun',this.state.sun))}}/><p className="text-white mb-0 mt-2 pt-1">Sun</p>
            </label>
            </div>
          </div>
          <div className="mt-4 mb-md-4 pt-md-3">
          <div className="form-group input-txt">
            <div className="float-left">
			{this.state.allowSS?<span className="toggle_top toggle_distance">Allow Single Class Sign Up</span>:<span className="toggle_top toggle_distance">Disallow Single Class Sign Up</span>}
              <label className="switch toggle_align_middle toggle_switch">
              <input type="checkbox" id = "allowSS"  checked={this.state.allowSS} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.allowSS},()=>console.log('allowSS',this.state.allowSS))}}/>
              <span className="slider round"></span>
              </label>
			  <div className="toggle_option">{this.state.allowSS ? 'ON' : 'OFF'}</div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button type="button" className="custom_btn1 px-5 mt-3 mx-3" data-dismiss="modal" onClick={this.getMultipleDateArray}>ok</button>
      </div>
    </div>
  </div>
</div>

{/* CALENDAR MODAL TO SELECT END DATE & TIME */}

<div className="modal cal_modal mt-3" id="calender_Model" data-backdrop="">
  <div className="modal-dialog small_width1">
    <div className="modal-content modalbg m-auto">
      <div className="modal-header px-4 pt-3 pb-0">
        <h4 className="white modal-title">Choose Date and Time</h4>
        <button type="button pr-3" className="close white closepopup" onClick={e=>$("#calender_Model").attr({'style':'display:none'})}>&times;</button>
      </div>
      <div className="modal-body">
      <h3>Calender</h3>
        <Calendar
           onChange={this.onChange1}
           value={this.state.sessionCalender}
           minDate={new Date()}
          // calendarType= "ISO 8601"
         />
      <div className="botm_container">
        <div className="row mt-4 flex-wrap">
          <div className="mt-2 px-4">
            <div className="form-group mb-0"><span className="cover-border"></span>
                <label className="label">Enter Time</label>
                <TimePicker
                  onChange={this.timeset2}
                  value={this.state.time2}
                  disableClock	={true}
                  />
            </div>
          </div>
        </div>
      </div>
      </div>
        <div className="text-center position-absolute btn_btn1">
        <button type="button" className="done mt-0">done</button>
        </div>
      </div>
    </div>
  </div>

{/* VIEW RECURRING CALENDAR MODAL */}

  <div className="modal cal_modal mt-3" id="calender_recurring_modal" data-backdrop="">
  <div className="modal-dialog small_width1">
    <div className="modal-content modalbg m-auto">
      <div className="modal-header px-4 pt-3 pb-0">
        <h4 className="white modal-title">Choose Date and Time</h4>
        <button type="button pr-3" className="close white closepopup" onClick={e=>$("#calender_recurring_modal").attr({'style':'display:none'})}>&times;</button>
      </div>
      <div className="modal-body px-1 px-sm-3">
      <h3>Calender</h3>
        <Calendar
           onChange={this.onChangeRecurEnd}
           value={this.state.recurEndCalender}
           minDate={new Date()}
          // calendarType= "ISO 8601"
         />
      <div className="botm_container">
        <div className="row mt-4 mx-0 justify-content-between flex-wrap">
        </div>
      </div>
      </div>
        <div className="text-center position-absolute btn_btn1">
        <button type="button" className="done mt-0" onClick={this.recurringCalender}>done</button>
        </div>
      </div>
    </div>
  </div>
  
 {/* VIEW RECURRING CALENDAR MODAL (START DATE)*/} 
 
  <div className="modal cal_modal mt-3" id="calender_recurring_modal2" data-backdrop="">
  <div className="modal-dialog small_width1">
    <div className="modal-content modalbg m-auto">
      <div className="modal-header px-4 pt-3 pb-0">
        <h4 className="white modal-title">Choose Date and Time</h4>
        <button type="button pr-3" className="close white closepopup" onClick={e=>$("#calender_recurring_modal2").attr({'style':'display:none'})}>&times;</button>
      </div>
      <div className="modal-body px-1 px-sm-3">
      <h3>Calender</h3>
        <Calendar
           onChange={this.onChangeStartCalender}
           value={this.state.recurStartCalender}
           minDate={new Date()}
          // calendarType= "ISO 8601"
         />
      <div className="botm_container">
        <div className="row mt-4 mx-0 justify-content-between flex-wrap">
        </div>
      </div>
      </div>
        <div className="text-center position-absolute btn_btn1">
        <button type="button" className="done mt-0" onClick={this.recurringStartCalender}>done</button>
        </div>
      </div>
    </div>
  </div>

 {/* Add a new Product End */}
 <div className="modal" id="link_generator" >
  <div className="modal-dialog small_width">
    <div className="modal-content">
      <div className="modal-header headerborder">
        <h4 className="modal-title text-white text-center">You have successfully created a class</h4>
      </div>
      <div className="modal-body">
      <h6 className="white text-center submit_congrat">Congratulations ,you have created the class "Introduction to wine testing" to be hosted by Arjun on August 13th2019 12:30PM</h6>
      <h6 className="white text-center submit_congrat">You can start inviting Members by sharing  the link below </h6>
      
      <div className="form-group mb-0">
       
      <input type="text" ref={(textarea)=>this.textArea=textarea}  className="input-field2" placeholder="Class Name" value="https//virdio.com" onChange={(e)=>console.log()}/>
             
      </div>
      
    </div>
    <div className="fitness_save">
          <Link className="copy_link btn btn-primary mx-auto"   variant="secondary" onClick={this.handleClose}>
            Copy link
          </Link>
      </div>

       </div>
  </div>
</div> 
  

  
{/* end */}
{/* Sign Up Calender Start */}
<div className="modal cal_modal" id="signUpCalenderModel"  data-backdrop="">
  <div className="modal-dialog large_width1">
    <div className="modal-content mx-auto">
      <div className="modal-header pb-0 pt-4">
        <h4 className="modal-title white">Select Duration</h4>
        <button type="button pr-3" className="close white closepopup" data-dismiss="modal">&times;</button>
      </div>
     
      <div className="modal-body">
      <h3>Calender</h3>
      <Calendar
           onChange={this.onChange2}
           value={this.state.reminderCalender}
           minDate={new Date()}
          // calendarType= "ISO 8601"
         />
      <div className="botm_container">
        <div className="row mt-4">
        <div className="col-md-5 mt-2 pl-4">
            <div className="form-group mb-0"><span className="cover-border"></span>
                <label className="label">Enter Time</label>
                {/* <input type="text" className="clockk input-field" id="timepicker2" placeholder="Time" /> */}
                <TimePicker
                  onChange={this.timeset}
                  value={this.state.time}
                  disableClock	={true}
                  />
                {/* <span className="clock-icon "></span> */}
            </div>
          </div>
          <div className="col-md-7">
          <p className="mb-2 input-txt">On {this.state.reminderMonth} {this.state.reminderDay} {this.state.reminderYear}, at {this.state.reminderTime}</p>
          </div>
        </div>
      </div>
      </div>
        <div className="text-center position-absolute btn_btn1">
        {this.state.signUpSessionStatus?'':<button type="button" className="done mt-0"onClick= {this.reminderDate} data-dismiss="modal">done</button>}
        </div>
      </div>
      {this.state.signUpSessionStatus?
      <div className="wd align-self-end d-none d-md-block"><img src="/images/path.png" className="w-100" /></div>:''}
      {this.state.signUpSessionStatus?
      <div className="modal-content modalbg align-self-end px-4 py-4 mt-2 mt-md-0">
      <div className="modal-header headerborder px-0">
      <div style={{color:"red"}}>This feature is't available</div>
        <h4 className="white modal-title">Repeat Class</h4>
      </div>
      <div className="modal-body px-0">
      <h5 className="white">Frequency</h5>
      <div className="d-flex flex-wrap">
      <a href="#" id='varietal' name='0' onClick = {this.signUpAttribute} className={(this.state.signUpClass[0]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>varietal</a>
      <a href="#" id='Every day' name='1' onClick = {this.signUpAttribute} className={(this.state.signUpClass[1]?"btn btn-primary":"btn")+" btn-outline-secondary text-uppercase mr-2 mt-2"}>Every day</a>
      <a href="#" id='once a week' name='2' onClick = {this.signUpAttribute} className={(this.state.signUpClass[2]?"btn btn-primary":"btn")+" btn-outline-secondary text-uppercase mr-2 mt-2"}>once a week</a>
      <a href="#" id='twice a week' name='3' onClick = {this.signUpAttribute} className={(this.state.signUpClass[3]?"btn btn-primary":"btn")+" btn-outline-secondary text-uppercase mr-2 mt-2"}>twice a week</a>
      <a href="#" id='3 times a week' name='4' onClick = {this.signUpAttribute} className={(this.state.signUpClass[4]?"btn btn-primary":"btn")+" btn-outline-secondary text-uppercase mr-2 mt-2"}>3 times a week</a>
      <select className="custom_field mt-2 mb-0" 
      id="signUpFrequency"
      value = {this.state.signUpFrequency}
      onChange = {this.sessionInfo}
      >
        <option>custom</option>
        <option>1 week</option>
        <option>2 week</option>
        <option>3 week</option>
        <option>4 week</option>
        </select>
      </div>
      <h5 className="white mt-4">Duration</h5>
      <div className="d-flex flex-wrap">
      <a href="#" id='1 week' name='5' onClick = {this.signUpAttribute} className={(this.state.signUpClass[5]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>1 week</a>
      <a href="#" id='2 weeks' name='6' onClick = {this.signUpAttribute} className={(this.state.signUpClass[6]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>2 weeks</a>
      <a href="#" id='3 weeks' name='7' onClick = {this.signUpAttribute} className={(this.state.signUpClass[7]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>3 weeks</a>
      <a href="#" id='4 weeks' name='8' onClick = {this.signUpAttribute} className={(this.state.signUpClass[8]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>4 weeks</a>
      <a href="#" id='5 weeks' name='9' onClick = {this.signUpAttribute} className={(this.state.signUpClass[9]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"}>5 weeks</a>
      <select className="custom_field mt-2 mb-0" 
      id="signUpDuration"
      value = {this.state.signUpDuration}
      onChange = {this.sessionInfo}
      >
        <option>custom</option>
        <option>1 week</option>
        <option>2 week</option>
        <option>3 week</option>
        <option>4 week</option>
        </select>
      </div>
      </div>
      <div className="text-center position-absolute btn_btn1">
          <button type="button" onClick = {this.saveSignUpCalender} className="done mt-0">save</button>
      </div>
     {/* <img src="images/path.png" className="small_cont" /> */}
      {/* <div className="modalbg small_cont"></div> */}
      </div>:''}
  </div>
</div>


  {/* Charging for class */}
	
  
  <div className="modal pr-0" id="charging_session_verification" aria-modal="true">
   	<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
      <div className="modal-content bg-transparent px-2">
      	<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
					<p className="py-4 text-light text-center font-20">{this.state.responseMessage}</p> 	
          
          </div>
          <div className="text-center">
          <button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={this.turnOffChargingSession}>no</button>
          <button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={e=>{$("#charging_session_verification").attr({'style':'display:none'});}}>add</button>
          </div>
      </div>
    </div>
  </div>

  {/* For "X" icon go to dashboard */}
  <div className="modal pr-0" id="go_Dashboard" aria-modal="true">
   	<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
      <div className="modal-content bg-transparent px-2">
      	<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
					<p className="py-4 text-light text-center font-20">You have made changes to the class, do you want to exit without saving?</p> 	
          
          </div>
          <div className="text-center">
          <button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={e=>{$("#go_Dashboard").attr({'style':'display:none'});}}>Cancel</button>
		  {this.state.showIfTemplate == 0 ?
			<button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={e=>{this.goToDashboardFun(e)}}>yes</button>
			:
			<button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={e=>{this.goToDashboardFun(e)}}>yes</button>
          }
		  </div>
      </div>
    </div>
  </div>
 {/*@ak  commented code was here of error_msg ehich was removed  */}
  <div className="modal pr-0" id="error_msg" aria-modal="true" >
   	<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
      <div className="modal-content bg-transparent">
      	<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8 m-2">
					<p className="py-4 text-light text-center font-20 mb-0">One or more fields has an error. Please correct and press SAVE again</p> 	
           
          </div>
          <div className="text-center">
          <button type="button" 
            className="done mt-3" 
            data-dismiss="modal" 
            onClick={e=>{
              $("#error_msg").attr({'style':'display:none'});
              if(!this.validator.fieldValid('description')){
               // console.log(this.validator.getErrorMessages())
                this.setState({
                  errorClass_Description:true
                })
                this._description.focus();
               }
               if(!this.validator.fieldValid('when')){
                this._when.focus();
              }
               if(!this.validator.fieldValid('session_details')){
                this.setState({
                  errorClass_SessionDetail:true
                })
                this._session_details.focus();
              }
             /*  if(!this.validator.fieldValid('minimumParticipants')){
                this.setState({
                  errorClass_MinimumParticipant:true
                })
                this._minimumParticipants.focus();
              }
              if(!this.validator.fieldValid('maximumParticipants')){
                //console.log(this.validator.getErrorMessages(),'==============',this.validator.getErrorMessages().description)
                this.setState({
                  errorClass_MaximumParticipant:true
                })
                this._maximumParticipants.focus();
              } */
          }}>ok</button>
          </div>
      </div>
    </div>
  </div>
  
  <div className="modal pr-0" id="shopping_alert" aria-modal="true">
   	<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
      <div className="modal-content bg-transparent">
      	<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
			{ this.state.groupId == 2 &&
			<p className="py-4 text-light text-center font-20">You filled some inputs of Shopping form but not add it. Please remove or add it.</p> 	
			}
			
			{ this.state.groupId == 1 &&
				<p className="py-4 text-light text-center font-20">You filled some inputs of Equipment form but not add it. Please remove or add it.</p> 	
			}
        </div>
        <div className="text-center">
        <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#shopping_alert").attr({'style':'display:none'});}}>ok</button>
        </div>
      </div>
    </div>
  </div>
 
<div className="modal pick_product_hold" id="myPickWineModel">
	<div className="modal-dialog dialogwidth centered_wine">
	<div className="modal-content modalbg">
	  <div className="modal-header headerborder">
	  <h4 className="modal-title white">Pick a Product</h4>
	  <button type="button" className="close white closepopup" onClick={this.resetEmoji} data-dismiss="modal">×</button>
	</div>
	<form>
	<div className="modal-body ">
	  <div className="card cardbg">
		<article className="card-group-item">
		  <div className="filter-content">
		  <div className="card-body ">
		  {/* <form> */}
		  {this.state.wineProduct.map((row,i) => row['active'] === 1 &&
			  
			  <div key={i}>
				{ this.state.mainWineArrayNames.indexOf(row.product_name) == -1 &&
				  <div>
		  <label className="form-check labelborder" key ={i}>
		  <input 
		  className="form-radio" 
		  type="checkbox" 
		  name={row.product_name} 
		  id={i} 
		  checked={row.type}
		  value={row.product_name} 
		  onChange={this.wineProductSelect}/>
		  <span className="form-check-label">{row.product_name}</span>
		  </label>
				  </div>
				}
			  </div>
		  )
		  }
		  
		  {!this.state.wineProduct || this.state.wineProduct.length == 0 ? <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8"><p className="py-4 text-light text-center font-20">No Products found</p></div> : '' }
		  
		  {/* <label className="form-check labelborder">
		  <input className="form-radio" type="radio" name="audio-type" id="lbl-communications" value="communications" /><span className="form-check-label">Nissan Altima</span></label>
		  <label className="form-check labelborder">
		  <input className="form-radio" type="radio" name="audio-type" id="lbl-communications" value="communications" /><span className="form-check-label">Another Brand</span></label> */}
		{/* </form> */}
	  </div>
	  </div>
	  </article>
	  </div>
	  <div className="ooterborder text-center mt-4"><button className="btn-primary"   onClick={this.setChooseWineStatus} data-dismiss="modal">Done</button></div>
	  </div>
	  </form>
	  <div className="modal-footer footerborder"></div>
	</div>
	</div>
</div>

	<div className="main_pop_sign above_all" id="wine_script">
		<div className="modal-dialog dialogwidth centered_wine">
			<div className="modal-content modalbg">
				<div className="modal-header headerborder">
					<h4 className="modal-title white">Add to Shopping List</h4>
					<button type="button" className="close white closepopup" onClick={this.closeAddingShop}>×</button>
				</div>
				<div className="modal-body">
					{ this.state.tablerows.length > 0 &&
						<div>
							<div className="card cardbg">
								<article className="card-group-item"> 
									<div className="card-body">
										{this.state.tablerows.map((row,i) =>
											<div className="relative_shop marb10" key={i}>{row.wineChoice}
												<div className={ "abs_cbx " + ( this.state.chosenWineShop == row.wineChoice ? "active" : "" ) } onClick={ () => this.choseWineShop(i) }>
													<div className="abs_cbx_checked">&#10004;</div>
												</div>
											</div>
										)}
									</div>
								</article>
							</div>
							<div className="text-center"><button type="button" className="custom_btn1 cursor mt-3"  onClick={this.addToShopName}>Add</button></div>
						</div>
					}
					{ this.state.tablerows.length == 0 &&
						<div className="card cardbg">
							<div className="subd_exam red_alert bigger_alert">No Available Products</div>
						</div>
					}
				</div>
			</div>
		</div>
	</div>


<div className="modal show py-3" id="pick_emojis_modal">
	<div className="modal-dialog emojis-dialogwidth">
		<div className="modal-content">
			<div className="modal-header modalbg">
				<h4 className="modal-title white">Assign Emojis</h4>
				<button type="button" className="close" id = {this.state.emojiForWineProduct}  data-dismiss="modal">×</button>
			</div>
			<form>
			<div className="modal-body modalbg">
				
					<div className="row">
						<div className="col-md-4">
							<div className="card cardbg">
							{this.state.wineObjectToEmojis && this.state.wineObjectToEmojis.appearanceSelect && this.state.groupId === 2 ?
							(this.state.wineObjectToEmojis.appearanceSelect.map((row,i) => (
							  (i===0?
							 <label className="form-check mb-3" key = {i}>
								{/* <input 
								className="form-radio"
								id={i}
								name='appearanceStatus'
								type = "checkbox"
								checked={row.appearanceStatus} 
								onChange={this.appearanceEmojiSelect}
								></input> */}
								<span className="form-check-label ml-3">APPEARANCE</span></label>  
							 :'') ))):''}                        
								{/* {this.state.tablerows[this.state.emojiForWineProduct].listAppearance.map((row,i) => ( */}
								{this.state.wineObjectToEmojis && this.state.wineObjectToEmojis.listAppearance && this.state.groupId === 2?
								(this.state.wineObjectToEmojis.listAppearance.map((row,i) => (
								<label className="form-check mb-3" key = {i}>
									<input className="form-radio" 
								   type="checkbox" 
								   name={row.name}
								   id ={i} 
								   checked={row.status} 
								   onChange={ () => this.selectEmojis(i, 'listAppearance') } />
									<span className="form-check-label">
									<img src={row.path} className="mx-3" alt="" />
									{row.name}</span>
								</label>
								))) :''}
							  </div>
						</div>
						<div className="col-md-4">
							<div className="card cardbg"> 
								{/* <label className="form-check mb-4">
								<span className="form-check-label ml-3">AROMA</span></label>
															  */}
								{this.state.wineObjectToEmojis && this.state.wineObjectToEmojis.aromaSelect && this.state.groupId === 2?
							(this.state.wineObjectToEmojis.aromaSelect.map((row,i) => (
							  (i===0?
							 <label className="form-check mb-3" key = {i}>
								{/* <input 
								className="form-radio"
								id={i}
								name='appearanceStatus'
								type = "checkbox"
								checked={row.appearanceStatus} 
								onChange={this.aromaEmojiSelect}
								></input> */}
								<span className="form-check-label ml-3">AROMA</span></label>  
							 :'') ))):''}                              
								{this.state.wineObjectToEmojis && this.state.wineObjectToEmojis.listAroma && this.state.groupId === 2 ?
								(this.state.wineObjectToEmojis.listAroma.map((row,i) => (
								<label className="form-check mb-3" key = {i}>
								   <input className="form-radio" 
									type="checkbox" 
									name={row.name}
									id ={i} 
									checked={row.status} 
									onChange={ () => this.selectEmojis(i, 'listAroma') } />
									<span className="form-check-label">
									<img src={row.path} className="mx-3" alt='' />{row.name}</span>
									</label>
								))):''}
							  </div>
						</div>
						<div className="col-md-4">
							<div className="card cardbg"> 
								{/* <label className="form-check mb-4">
								<span className="form-check-label ml-3">PALATE</span></label>                             
								 */}

							{this.state.wineObjectToEmojis && this.state.wineObjectToEmojis.palateSelect && this.state.groupId === 2?
							(this.state.wineObjectToEmojis.palateSelect.map((row,i) => (
							  (i===0?
							 <label className="form-check mb-3" key = {i}>
								{/* <input 
								className="form-radio"
								id={i}
								name='palateStatus'
								type = "checkbox"
								checked={row.appearanceStatus} 
								onChange={this.palateEmojiSelect}
								></input> */}
								<span className="form-check-label ml-3">PALATE</span></label>  
							 :'') ))):''}
								{this.state.wineObjectToEmojis && this.state.wineObjectToEmojis.listPalate && this.state.groupId === 2 ?
								(this.state.wineObjectToEmojis.listPalate.map((row,i) => (
								<label className="form-check mb-3" key = {i}>
									<input 
									className="form-radio" 
									type="checkbox" 
									name={row.name}
									id ={i} 
									checked={row.status} 
									 onChange={ () => this.selectEmojis(i, 'listPalate') } />
									<span className="form-check-label">
									<img src={row.path} className="mx-3" alt="" />
									{row.name}</span>
								   
								</label>
								 ))):''}
								</div>
						</div>
					</div>
				
			</div>
			<div className="ooterborder text-center mt-4"><button className="btn-primary"   onClick={this.finalEmoji} data-dismiss="modal">SELECT</button></div>
			</form>
		</div>
	</div>
</div>


{/* CLICK ON BLUB ICON OPEN INFORMATION POP UP MODAL */}
{/* <div className="modal" id="createdSessionSuccess">
  <div className="modal-dialog mw-770 mx-auto modal-dialog-centered">
    <div className="modal-content bg-transparent">
      <div className="modal-body px-2 px-md-3 pt-3 m-2 radius-8">
        <div className="d-flex justify-content-end">
          <button type="button" className="close whitecross" data-dismiss="modal" onClick={e=>$("#createdSessionSuccess").attr({'style':'display:none'}),$("#successResult").attr({'style':'display:block'})} >&times;</button>
        </div>
          <div className="table_box2 px-2 pb-2">
            <div className="parti_id_box">
              <p className="font-16 texr-center pb-4">Comming Soon.</p>
            </div>                                               
          </div>
      </div>
    </div>
  </div>
</div> */}


<div className="modal" id="successResultEdit">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content equipmodalbg">
      <div className="modal-header headerborder">
        <h4 className="modal-title white">Class data Updated</h4>
        <button type="button" className="close white closepopup" onClick={this.modalClose.bind(this)} data-dismiss="modal">&times;</button>
      </div>
      <div className="modal-body">
        <p>Congratulation, Class data successfully updated!</p>
      <Link to={this.state.showIfTemplate === 1 ? "/SessionTemplates" : !this.state.isMarketplaceChannel ? "/DashboardLanding" : "/DashboardLanding#classManagement"} className="copy_link btn btn-primary mx-auto"   variant="secondary" data-dismiss="modal">
	  { this.state.showIfTemplate === 0 ? "Go to Dashboard" : "Go to Templates" }
      </Link>
      </div>
    </div>
  </div>
</div> 


{/* AFTER CREATE SESSION OPEN SUCCESS POPUP */}

<div className="modal" id="successResult">
  <div className="modal-dialog mw-900 modal-dialog-centered">
    <div className="modal-content px-2 bg-transparent radius-8">
      <div className="modal-body modalbg px-3 px-sm-4 radius-8">
      <h4 className="modal-title white border-bottom text-center mb-4 pb-2">Class Created
      </h4>
        <div className="d-flex justify-content-between flex-wrap"> 
        <div className="col-md-6">
        <div className="d-flex">
        <p className="text-white font-16 w-112 text-right session_created_alert creating_session_mob">Class Instructor :</p> <p className="ml-1">
          { ((this.state.sessionHostFirstName).length > 29) ? (((this.state.sessionHostFirstName).substring(0,21-3)) + '...') : this.state.sessionHostFirstName }
        </p>
        </div>
        { this.state.when !== '' &&
			<div className="d-flex">
					<p className="text-white font-16 w-112 text-right session_created_alert creating_session_mob ">Starting : </p>
					{moment1(this.state.when).tz(this.state.chosenTimezoneCity) &&
						<p className="ml-1">
							{moment1(this.state.when).tz(this.state.chosenTimezoneCity).format('ddd, MMM Do, YYYY hh:mm a')}
						</p>
					}
			</div>
		}
        {this.state.weekDaysData.length>0?
        <div className="d-flex flex-wrap">
          <p className="text-white font-16 w-112 text-right creating_session_mob">Repeats On :</p> <p className="ml-1">
            {selectWeekDays}
               {/* {this.state.mon==true? 'Mon,':''} {this.state.tue==true? 'Tue,':''} {this.state.wed==true? 'Wed,':''} {this.state.thu==true? 'Thu,':''} {this.state.fri==true? 'Fri,':''} {this.state.sat==true? 'Sat,':''} {this.state.sun==true? "Sun":''} */}
              
          </p>
        </div>:''}
        </div>  
         <div className="col-md-6">
            <div className="d-flex">
              <p className="text-white font-16 w-190 text-right creating_session_mob">Duration :</p> <p className="ml-1">
               {this.state.sessionHour >0 ? this.state.sessionHour+" Hours," :''}{this.state.sessionMinute} Minutes 
              </p>
            </div>
            {this.state.weekDaysData.length>0?
            <div className="d-flex">
              <p className="text-white font-16 w-190 text-right">Ending : </p><p className="ml-1">
              {(new Date(this.state.recurEndwhen.replace(/-/g, '/'))).toLocaleDateString('en-US', DATE_OPTIONS_CUSTOM)}
                {/* {this.state.recurEndwhen} */}
              </p> 
            </div>
             :''} 
             {this.state.weekDaysData.length>0?
            <div className="d-flex">
            <p className="text-white font-16 w-190 text-right">Single Class Sign up :</p> 
            <p className="ml-1">{this.state.allowSS==true ? "Allowed":"Disallowed"}</p>
            </div>:''}
        </div>
        </div>
        {/* <button type="button" className="close white closepopup" onClick={this.modalClose.bind(this)} data-dismiss="modal">&times;</button> */}
        {/* <p>Congratulation, You have created the class,"{this.state.sess_name}" to be hosted by {this.state.uname} on {this.state.sess_time}...
          you can start inviting the paticipants by sharingthe link below. you can also find this link in class details,
           from your Dashboard.
        </p> */}
        {/* <p className="font-20">Congratulations {this.state.uname}, your class {this.state.sess_name}, hosted by {this.state.uname} on {this.getSessionDate(this.state.sess_time)+" "+this.getSessionTime(this.state.sess_time)}, is created.
        </p> */}
        {/* <p className="font-20 mb-4">        
          Please copy and share the link below with anyone you would like to attend the class. Same link is accessible through class details of the class info, available on your Dashboard.
        </p> */}

        <div className="d-flex align-items-center">
		{/* <div className=""><img src="/images/bulb.png" className="ml-3 mb-2" onChange = {(e)=>alert("Coming Soon!s")}/></div> */}
          <div className="flex-grow-1 mx-2 copy_link_clipboard"><input type="text" ref={(textarea)=>this.textArea=textarea} value ={this.state.urlLink} onChange = {(e)=>console.log(e.target.value)} className="input-field mb-0" id="urlLink" /></div>
          <div className="">
          <CopyToClipboard text={this.state.urlLink}>
          <button className="copy_link btn btn-primary font-16 copy_main_button" onClick={this.copyClick}>{this.state.buttonText}</button></CopyToClipboard>
                  {/* <Link to = "/FitnessSessionCreation" className="join_button ml-2 d-inline-block" onClick={e=>{this.textArea.select();document.execCommand('copy');e.target.focus();},this.copyClick}>{this.state.buttonText}</Link> */}
            </div>          
        </div>      
        <p className="font-16 texr-center pb-4 mt-3 ml-4">        
          Send link to your clients to sign up for the class(es). You will also receive an email with the link.
        </p>
        <div className="texr-center pb-4">
        <Link to="/DashboardLanding" className="copy_link btn btn-primary mt-3 mx-auto"   variant="secondary" data-dismiss="modal">
        Go to Dashboard
      </Link>
      </div>
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div> */}

    </div>
  </div>
</div>
      </div>
	  
	  
		<div className="modal pr-0" id="expired_card" aria-modal="true">
			<div className="modal-dialog mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent inline_pop">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
						<button type="button" className="close white closepurple translatX-Y abs_close" onClick={e=>{$("#expired_card").attr({'style':'display:none'});}}><span>x</span></button>
						<p className="mb-0 py-4 white text-center font-20">{this.state.hostFirstName}, your Credit Card on file has expired, on the next screen please provide a valid CreditCard to host future classes on this Channel. You can skip providing a Credit Card, but you will not be able to start your class until a valid Credit card is provided.</p>
					</div>
					<div className="text-center flex-wrap d-flex justify-content-center">
						<div className="">
							<button className="custom_btn1 mt-3 mx-2" onClick={e=>{ $("#expired_card").attr({'style':'display:none'}); this.continueSubmitForm(); }}>Skip</button>
							<button className="custom_btn1 mt-3 mx-2" onClick={this.openCardsScreen}>Continue</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="modal pr-0" id="marketplace_save" aria-modal="true">
			<div className="modal-dialog mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent inline_pop">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative w600 pop700">
						<button type="button" className="close white closepurple translatX-Y abs_close" onClick={e=>{this.closeMarketplaceSavePop(e)}}><span>x</span></button>
						<p className="mb-0 py-4 white text-center font-20">Finish {this.props.params.id ? "Editing" : "Saving"}?</p>
					</div>
					<div className="text-center flex-wrap d-flex justify-content-center">
						<div className="">
							<button className="custom_btn1 mt-3 mx-2" onClick={() => this.setStatusAndSave(0)}>{this.props.params.id ? "Pending Instructor" : "Continue Edit Later"}</button>
							<button className="custom_btn1 mt-3 mx-2" onClick={() => this.setStatusAndSave(1)}>Send for review</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="reccuring_question" aria-modal="true">
			<div className="modal-dialog mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent inline_pop">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
						<button type="button" className="close white closepurple translatX-Y abs_close" onClick={e=>{$("#reccuring_question").attr({'style':'display:none'});}}><span>x</span></button>
						<p className="mb-0 py-4 white text-center font-20">The Class you are Editing is Recurring. Do you want to apply these Edits to all of the Recurring Classes?</p>
					</div>
					<div className="text-center flex-wrap d-flex justify-content-center">
						<div className="">
							<button className="custom_btn1 mt-3 mx-2" onClick={e=>{ $("#reccuring_question").attr({'style':'display:none'}); this.continueSubmitForm(false, true); }}>Single Class</button>
							<button className="custom_btn1 mt-3 mx-2" onClick={e=>{ $("#reccuring_question").attr({'style':'display:none'}); this.continueSubmitForm(true); }}>All Classes</button>
						</div>
					</div>
				</div>
			</div>
		</div>

			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			
			<div className="modal pr-0" id="planexpired" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Hi {this.state.loginName}, your plan is expired, please click bellow and update your plan.</p>
						</div>
						<div className="text-center"> 
							<Link onClick={(e) => this.goToPlans(e)}><button type="button" className="col-4 custom_btn1 mt-3">Select Plan</button></Link>
							<Link onClick={(e) => this.logOutPlan(e)}><button type="button" className="custom_btn1 col-4 mt-3" style={{marginLeft: '50px'}}>LOGOUT</button></Link>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="editing_alert" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">{this.state.hostRequestingEdit} would like to edit this Class</p>
							<p className="mb-0 py-4 white text-center">{this.state.pendingHostSeconds}</p>
						</div>
						<div className="text-center"> 
							<button type="button" className="col-4 custom_btn1 mt-3" onClick={() => this.updateEdit('reject')}>DON'T ALLOW </button>
							<button type="button" className="custom_btn1 col-4 mt-3" style={{marginLeft: '50px'}} onClick={() => this.updateEdit('accept')}>ALLOW</button>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0 zInd2000" id="copy_from_previous_playlist_check" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
							<p className="mb-0 py-4 white text-center font-20 no_pad_bott">The class you selected has playlist created on a different device and this playlist can only be used to host Classes on that device.</p>
							<p className="mb-0 py-4 white text-center font-20">Do you want to continue to use this class?</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<button className="custom_btn1 mt-3 mx-2" onClick={this.useSession}>YES</button>
								<button className="custom_btn1 mt-3 mx-2" onClick={this.cancelUseSession}>NO</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0 zInd2000" id="cancel_sessions_warning" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
							<p className="mb-0 py-4 white text-center font-20 no_pad_bott">{this.state.cancelSessionsMsg1}</p>
							<p className="mb-0 py-4 white text-center font-20 no_pad_bott canceled_sessions_warn">{this.state.cancelSessionsMsg2}</p>
							<p className="mb-0 py-4 white text-center font-20">{this.state.cancelSessionsMsg3}</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<button className="custom_btn1 mt-3 mx-2" onClick={this.cancelSessionsAndFinishEditRecurring}>YES</button>
								<button className="custom_btn1 mt-3 mx-2" onClick={this.cancelThisAction}>NO</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="changeActivityPlace" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<div className="closePopup mainclose_pos15" onClick={this.closeChangePlace}></div>
							<p className="mb-0 py-4 white text-center">Change place to</p>
							<div className="centDiv">
								<input 
								  className="changePlaceInp"
								  type="text"
								  id = "changePlaceNew" 
								  maxlength="4"
								  value ={this.state.changePlaceNew} 
								  onChange = {(e)=> this.enterInputValue(e.target.value)}
								/>
							</div>
						</div>
						<div className="text-center"> 
							<button type="button" className="col-4 custom_btn1 mt-3" onClick={this.saveChangePlace}>SAVE</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0 zInd2000" id="workoutDurationError" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
								<p className="mb-0 py-4 white text-center font-20 no_pad_bott">The length of the class is {this.state.finalClassDurationMinutes} minutes, your workout exceeds the maximum allowed {this.state.finalClassDurationMinutes} minutes & 30 seconds.</p>
							<p className="mb-0 py-4 white text-center font-20">Please reduce the time of the activities</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<button className="custom_btn1 mt-3 mx-2" onClick={e=>{$("#workoutDurationError").attr({'style':'display:none'});}}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0 zInd2000" id="autoAddPauseVirtual" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
							<p className="mb-0 py-4 white text-center font-20 no_pad_bott">Pause Virtual is required before first time Virtual Equipment is used, we added it.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<button className="custom_btn1 mt-3 mx-2" onClick={e=>{$("#autoAddPauseVirtual").attr({'style':'display:none'});}}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0 zInd2000" id="activityPositionNumber" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
							<p className="mb-0 py-4 white text-center font-20 no_pad_bott">Change activity position number does not exist.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<button className="custom_btn1 mt-3 mx-2" onClick={e=>{$("#activityPositionNumber").attr({'style':'display:none'});}}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		
		
			{ this.state.isOpenReplay &&
				<ReplayClasses 
					replayClasses={this.state.replayClasses} 
					pickedReplayId={this.state.pickedReplayId} 
					updateStateFromChild={this.updateStateFromChild}
					pickReplayClass={this.pickReplayClass}
				></ReplayClasses>
			}
		</div>
    );
  }
}

const mapStateToProps = state => {
	return { 
		userPlaylist: state.menagePlaylist.playlists,
		openMainPopup: state.libraryPopups.showPlaylistMainPopup,
		openedTemplateSave: state.libraryPopups.mainErrorPopMsg
	}
}

export default connect(
  mapStateToProps,
  { 
	togglePlaylistPopup,
	showMainErrorPop
  }
)(SessionCreation);
