import React, { Component } from "react";
import $ from 'jquery';
import Login from '../auth/Login';
import MainSignUp from '../auth/MainSignUp';
import SimpleReactValidator from 'simple-react-validator';
import axios from "axios";
import { submitHost } from '../../util/submitHost';
import { verify } from '../../util/verify';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import { Link, browserHistory } from 'react-router'
import customProtocolCheck from "custom-protocol-check";
import { setCookie } from '../../util/cookie';

import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';

class ChargingSession extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		log_or_signup: true,
		make_payment: false,
		review_session: false,
		listCard: false,
		newCard: false,
		email: '',
		password: '',
		message: '',
		verify: false,
		msg: '',
		errorClass_FirstName: false,
		errorClass_LastName: false,
		errorClass_Phone: false,
		errorClass_Email: false,
		errorClass_Password: false,
		errorClass_RePassword: false,
		_firstName: '',
		firstName: '',
		lastName: '',
		phone: '',
		rePassword: '',
		fullName: '',
		cardNumber: '',
		cardMonth: '',
		cardYear: '',
		securityCode: '',
		zipCode: '',
		saveToFile: false,
		creditCards : [],
		pickedCreditCard: [],
		pickedSessions: [],
		currentUserId: '',
		currentUserToken: '',
		callbackwhenDone: null,
		pickedCardFinal: {},
		byEmail: true,
		sms: false,
		openedFrom: '',
		confirmationNumber: 0,
		cardYears: [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035],
		error_card_show: false,
		
	}
	this.validator = new SimpleReactValidator({autoForceUpdate: this});  
	
	this.validator = new SimpleReactValidator({
		autoForceUpdate: this,
		validators: {
		  is_card_expired: {  // name the rule
			message: 'Your credit card has been expired!',
			rule: (val) => {
				var get_date  = val.split("/");
				var card_date = new Date(get_date[0]+'/31/'+get_date[1]).getTime();
				if ( get_date[1].length < 4 ) { return false; }
				if ( get_date[1] < new Date().getFullYear() ) { return false; }
				
				return card_date > new Date().getTime(); 
			}
		  }
		}
	  });
	
	
	this.otpValidation = new SimpleReactValidator();

	this.submitHostC = submitHost.bind(this); 
	this.verify = verify.bind(this);
	this.setCookie = setCookie.bind(this);
}
 
updateStateChargeFromChild = (newState) => {
	this.setState(newState);
	
	
} 

updateStatesPayments = (newState) => {
	this.props.updateStateChanelFromChildSession(newState, true);
}

updateStatesSign = (newState) => {
	this.props.updateStateChanelFromChildSession(newState);
}
 
updateStateChanellFromChildSession = (newState) => {
	this.props.updateStateChanellFromChildSession2(newState);
} 
 
 continuePay=(e)=> {
	 this.setState({
        log_or_signup: true
        },()=>{
	$("#chargePopup").attr({'style':'display:none'})
	$("#sessionPay").attr({'style':'display:block'})
	});
}

closePay=(e)=> {
	if ( this.state.newCard )
		{
			this.setState({
				listCard: true,
				newCard: false
			});
		}
	else
		{
			this.setState({
				log_or_signup: true,
				make_payment: false,
				review_session: false,
				listCard: false,
				newCard: false,
				creditCards : [],
				pickedCreditCard: []
				},()=>{
					$("#sessionPay").attr({'style':'display:none'});
					localStorage.removeItem('secondStep');
			});
		}
}



moveToChannel=(e)=>{
	window.location.href = localStorage.getItem("backToChannel");
	localStorage.removeItem('backToChannel');
}

acceptSessionPayments=(e)=>{
	 $('.main_loader').attr({'style':'display:flex'});
						
	  axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/acceptsessionpayments", {'pickedSessions': this.state.pickedSessions, 'pickedCreditCard': this.state.pickedCreditCard, 'userId': this.state.currentUserId}, {headers: {'Authorization': this.state.currentUserToken}})
	  .then(res => {
		 if(res.data.responseMessage === "success")
			  {
				  
				  var newDoLogin = true;
				  if ( this.state.callbackwhenDone !== null )
					{
						newDoLogin = false;
					}
				  
				 this.setState({
						log_or_signup: newDoLogin,
						make_payment: false,
						review_session: false,
						listCard: false,
						newCard: false,
						creditCards : [],
						pickedCreditCard: []
						},()=>{
							
							console.log('THIS CALLBACK');
							console.log(this.state.callbackwhenDone);
							
							if ( this.state.callbackwhenDone === 1 )
								{
									localStorage.setItem('sessionNewData', localStorage.getItem('tempSession'));
									localStorage.setItem('secondStep', '1');
									
									this.submitHostC();
								}
							else if ( this.state.callbackwhenDone !== null )
								{
									localStorage.setItem('sessionNewData', localStorage.getItem('tempSession'));
									localStorage.setItem('secondStep', '1');
									
									this.state.callbackwhenDone();
								}
							
							
							$("#sessionPay").attr({'style':'display:none'});
					});
			  }
		 else
			 {
			   this.setState({
				 msg:''
			   }) 
			 }
			 
	$('.main_loader').hide();
	  }).catch(err =>{
			 if ( err.response )
				 {
					  if(err.response.data.responseCode===417){
						this.setState({
						  msg:err.response.data.errorData
						},()=>$("#error_msg2").attr({'style':'display:block'})) 
					  }
				 }
	  });
}

onSubmit = e => {
	console.log('MATCH');
}

addNewCreditCard = e => {
	this.setState({
        listCard: false,
		newCard: true
     });
}

onChange=(e)=> {
    this.setState({ [e.target.id]: e.target.value });
};

showCharging=(e)=> {
	this.setState({  
			log_or_signup: false,
			make_payment: true,
			review_session: false,
			listCard: true,
			newCard: false
	});
}

showTrainingPayment=(e)=> {
	this.setState({  
			log_or_signup: true,
			make_payment: false,
			review_session: false,
			listCard: true,
			newCard: false
	});
}

updateSignEmail=(e)=> {
	if ( localStorage.getItem('verifyEmailID') )
		{
			this.setState({emailSign: localStorage.getItem('verifyEmailID') });
		}
}


updateRefState = (ref, mod) => {
	if ( mod === 'firstName' )
		{
			this.firstName = ref;
		}
	else if ( mod === 'lastName' )
		{
			this.lastName = ref;
		}
	else if ( mod === 'emailSign' )
		{
			this.emailSign = ref;
		}
	else if ( mod === 'password' )
		{
			this.password = ref;
		}
	else if ( mod === 'repassword' )
		{
			this.rePassword = ref;
		}
	else if ( mod === 'fullName' )
		{
			this.fullName = ref;
		}
	else if ( mod === 'cardNumber' )
		{
			this.cardNumber = ref;
		}
	else if ( mod === 'cardMonth' )
		{
			this.cardMonth = ref;
		}
	else if ( mod === 'cardYear' )
		{
			this.cardYear = ref;
		}
	else if ( mod === 'securityCode' )
		{
			this.securityCode = ref;
		}
	else if ( mod === 'zipCode' )
		{
			this.zipCode = ref;
		}
}
 
 
resend=(e)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : this.state.emailSign})
    .then(res => {
			if( res.data.responseMessage === "success" )
				{
					$("#resend_verification").attr({'style':'display:block'});
				}
			else
				{
					this.setState({
						msg:''
					}) 
				}
    }).catch(err =>{
		this.setState({
			msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
		}) 
    });
}

saveToFile=(e)=>{
	let checkedSaveToFile = this.state.saveToFile;
	checkedSaveToFile = !checkedSaveToFile;
	this.setState({
		saveToFile: checkedSaveToFile
        });
}

picKThisCard=(e)=>{
	let creditCardsArray = this.state.creditCards;
	creditCardsArray[e]['checked'] = !creditCardsArray[e]['checked'];
	if ( creditCardsArray[e]['checked'] )
		{
			for(let i=0;i<creditCardsArray.length;i++){
				if ( i !== e )
					{
						creditCardsArray[i]['checked'] = false;
					}
			}
		}
	
	this.setState({
		creditCards: creditCardsArray
        });
}

deleteCreditCard=(e)=>{
	let creditCardsArray = this.state.creditCards;
	let id = creditCardsArray[e]['id'];
	creditCardsArray.splice(e,1);
	this.setState({
		creditCards: creditCardsArray
        });
		
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	if ( token ) { axios.defaults.headers.common['Authorization'] = token; }
		
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/deleteUserCard",{"id": id})
	.then(res_e => {}).catch(err =>{ });
}

getCardType=(number)=>{
	
	// visa
	var re = new RegExp("^4");
	if (number.match(re) != null)
		return "Visa";

	// Mastercard
	re = new RegExp("^5[1-5]");
	if (number.match(re) != null)
		return "Mastercard";

	// AMEX
	re = new RegExp("^3[47]");
	if (number.match(re) != null)
		return "AMEX";

	// Discover
	re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
	if (number.match(re) != null)
		return "Discover";

	// Diners
	re = new RegExp("^36");
	if (number.match(re) != null)
		return "Diners";

	// Diners - Carte Blanche
	re = new RegExp("^30[0-5]");
	if (number.match(re) != null)
		return "Diners - Carte Blanche";

	// JCB
	re = new RegExp("^35(2[89]|[3-8][0-9])");
	if (number.match(re) != null)
		return "JCB";

	// Visa Electron
	re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
	if (number.match(re) != null)
		return "Visa Electron";

	return "";
}

saveCardNumber=(e)=> {
	if ( this.validator.allValid() ) 
		{
			if ( parseInt(this.state.cardMonth) < 13 )
				{
					let udata = JSON.parse(localStorage.getItem('userData')).data.responseData;
					this.setState({currentUserId: udata.id, currentUserToken: udata.token});
					
					const cr_card = {
										'userId': udata.id,
										'type': this.getCardType(this.state.cardNumber),
										'nameOnCredicCard': this.state.fullName,
										'expires': this.state.cardMonth+'/'+this.state.cardYear,
										'securityCode': this.state.securityCode,
										'zipCode': this.state.zipCode,
										'saveToFile': this.state.saveToFile,
										card_number: this.state.cardNumber,
										expire_month: this.state.cardMonth,
										expire_year: this.state.cardYear.slice(-2)
									};
							 let cardsArray = this.state.creditCards;
							 let shortCard;
							 $('.main_loader').attr({'style':'display:flex'});
							  axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/addcard", cr_card, {headers: {'Authorization': udata.token}})
							  .then(res => {
							  if(res.data.responseMessage === "success")
								  {
									  cr_card.id = res.data.responseData.creditCardId;
									  cr_card.cardtype = cr_card.type.replace(' ', '').replace(' ', '');
									  cr_card.card_number = cr_card.card_number.slice(-4);
									  cardsArray.push(cr_card);
										this.setState({
											creditCards: cardsArray,
											listCard: true,
											newCard: false,
											getCardType: '',
											fullName: '',
											cardMonth: '',
											cardYear: '',
											zipCode: '',
											securityCode: '',
											cardNumber: '',
											saveToFile: false
									  });
								  }
							 else
								 {
								   this.setState({
									 msg:''
								   }) 
								 }
								 
							$('.main_loader').hide();
							  }).catch(err =>{
									$('.main_loader').hide();
												
									this.props.showMainErrorPop('Card was not accepted.');
							  });
				}
			else 
				{
					this.setState({errorClass_CardMonth:true});
				}				
		}
	else 
		{
			if ( !this.validator.fieldValid('fullName') ) { if(!this.validator.getErrorMessages().fullName) { this.setState({errorClass_FullName:false}) } else { this.setState({ errorClass_FullName:true }) }}
			if ( !this.validator.fieldValid('cardNumber') ) { if(!this.validator.getErrorMessages().cardNumber) { this.setState({errorClass_CardNumber:false}) } else { this.setState({ errorClass_CardNumber:true }) }}
			if ( !this.validator.fieldValid('cardMonth') ) 
			{
				
				if(!this.validator.getErrorMessages().cardMonth) 
					{ 
						this.setState({errorClass_CardMonth:false, error_card_show : false}); 
					} 
				else 
					{ 
						this.setState({ errorClass_CardMonth:true, error_card_show : false }); 
						console.log(this.validator.getErrorMessages().cardMonth);
						if ( this.validator.getErrorMessages().cardMonth.toString() == 'Your credit card has been expired!' )
							{
								this.setState({error_card_show : true});
								this.props.showMainErrorPop("Your credit card expire date can't be empty and must be in the future.");
								if ( !this.validator.fieldValid('cardYear') ) { if(!this.validator.getErrorMessages().cardYear) { this.setState({errorClass_CardYear:false}) } else { this.setState({ errorClass_CardYear:true }) }}
			
								return;
							}
						
					}
			}
			if ( !this.validator.fieldValid('cardYear') ) { if(!this.validator.getErrorMessages().cardYear) { this.setState({errorClass_CardYear:false}) } else { this.setState({ errorClass_CardYear:true }) }}
			if ( !this.validator.fieldValid('securityCode') ) { if(!this.validator.getErrorMessages().securityCode) { this.setState({errorClass_SecurityCode:false}) } else { this.setState({ errorClass_SecurityCode:true }) }}
			if ( !this.validator.fieldValid('zipCode') ) { if(!this.validator.getErrorMessages().zipCode) { this.setState({errorClass_ZipCode:false}) } else { this.setState({ errorClass_ZipCode:true }) }}
			
			
		  this.validator.showMessages();
		  this.props.showMainErrorPop('One or more fields has an error. Please correct and press SAVE again!');
		  this.forceUpdate();
		}
}

goToReview=(e)=> {
	
	console.log('goToReview');
	console.log('this.state.creditCards', this.state.creditCards);
	console.log('this.props.fromCreate', this.props.fromCreate);
	let url_loc = window.location.href;
	let canGoNext = false;
	let pickedCard = [];
	for(let i=0;i<this.state.creditCards.length;i++){
		if ( this.state.creditCards[i]['checked'] )
			{
				canGoNext = true;
				pickedCard = this.state.creditCards[i];
				
				
				this.setState({
						pickedCardFinal: pickedCard
					});
			}
	}
	
	if ( canGoNext )
		{
			if ( this.props.fromCreate || url_loc.indexOf('/trainingPlans') > -1 || url_loc.indexOf('/myProfile') > -1 )
				{
					// Just add card to file and continue
					
					let expiresSplit = pickedCard['expires'].split('/');
					let expireMonth = parseInt(expiresSplit[0]);
					let expireYear = parseInt(expiresSplit[1]);
					var today, exireday;
					today = new Date();
					exireday = new Date();
					
					exireday.setFullYear(expireYear, expireMonth-1, 1);
					var lastDate = new Date(exireday.getFullYear(), exireday.getMonth()+1, 0);
					exireday.setDate(lastDate.getDate());
					exireday.setHours(23);
					exireday.setMinutes(59);
					exireday.setSeconds(59);
					
					localStorage.setItem("cardNumber", pickedCard.card_number);
					localStorage.setItem("cardtype", pickedCard.cardtype);
					
					if ( exireday.getTime() < today.getTime() )
						{
							this.setState({
								hostFirstName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName
							});
							
							this.props.showMainErrorPop('Your Credit Card date is expired.');
							return false;
						}
					
					$('.main_loader').attr({'style':'display:flex'});
					let udata = JSON.parse(localStorage.getItem('userData')).data.responseData;
					axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/selectdefaultcard", pickedCard, {headers: {'Authorization': udata.token}})
					  .then(res => {
						  
						  
						  if ( url_loc.indexOf('/trainingPlans') === -1 && url_loc.indexOf('/myProfile') === -1 ) { this.props.fromCreate(); }
						  if ( url_loc.indexOf('/trainingPlans') > -1  ) 
							{  
								if ( this.props.state.isLoggedUser )
									{
										let postData;
										let userFirstName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName;
										if ( !this.props.state.isFreeTrial )
											{
												postData = 
															{
																userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, 
																planId: this.props.state.chosenPlanId, 
																demandPlanId: this.props.state.chosenDemandPlanId, 
																channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
																couponCode: this.props.state.promoCode,
																firstName: userFirstName,
																channelName: this.props.state.channelName,
																manualPayment: this.props.state.manualPayment
															};
											}	
										else
											{
												postData = 
															{
																userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, 
																planId: this.props.state.chosenPlanId, 
																demandPlanId: this.props.state.chosenDemandPlanId, 
																channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
																freeTrial: 1,
																couponCode: this.props.state.promoCode,
																firstName: userFirstName,
																channelName: this.props.state.channelName,
																manualPayment: this.props.state.manualPayment
															};
											}
										let confNumber;
										axios.post(process.env.REACT_APP_NAME+"/api/v1/user/addUserPlan", postData)
										.then(res => {
											if ( res.data.responseData.toString().indexOf('not able') > 0 )
												{
													this.props.showMainErrorPop(res.data.responseData);
												}
											else
												{
													confNumber = res.data.responseData;
													localStorage.setItem('confirmation_number', confNumber);
													if ( !this.props.state.isLoggedUser ) { browserHistory.push("/login"); }
													else { $("#succes_pay").attr({'style':'display:block'}); }
												}
											$('.main_loader').hide();
											
										}).catch(err =>{
											console.log(err);
											$('.main_loader').hide();
											this.props.showMainErrorPop("Error processing this transaction.");
										});
									}
								else
									{
										$('.main_loader').hide();
										if ( !this.props.state.isLoggedUser ) { browserHistory.push("/login"); }
										else { $("#succes_pay").attr({'style':'display:block'}); }
									}
							}
						else
							{
								$("#sessionPay").attr({'style':'display:none'});
								$("#expired_card").attr({'style':'display:none'});
								$('.main_loader').hide();
							}
						  if ( url_loc.indexOf('/myProfile') > -1  ) { $("#succes_pay").attr({'style':'display:block'}); }
					  });
				}
			else
				{
					this.setState({
						pickedCreditCard: pickedCard.id,
						make_payment: false,
						review_session: true,
						listCard: false,
						newCard: false,
						pickedSessions: this.props.state.pickedSessions
					});
				}
		}
	else
		{
			if ( this.state.creditCards.length === 0 )
				{
					this.props.showMainErrorPop('Please add Credit Card');
				}
			else
				{
					this.props.showMainErrorPop('Please check Credit Card');
				}
		}	
}
 
componentDidMount(){
	if(localStorage.getItem('verifyEmailID'))
		{
			this.setState({emailSign: localStorage.getItem('verifyEmailID') });
		}

	let url_loc = window.location.href;
	let urlPart = url_loc.split('/');
	let openedFromComp = urlPart[urlPart.length - 1];
	this.setState({openedFrom: openedFromComp});
  };

componentDidUpdate(prevProps, prevState) {
	
	 if ( this.props.cardsArray && this.props.cardsArray !== this.state.creditCards )
		{
			this.setState({creditCards: this.props.cardsArray});
		}
}
  
  
closeLogin=(e)=>{
	this.setState({
		isLoginOpen: false,
		isOpenLoginAll: false,
	});

	$("#session_alert_new").attr({'style':'display:none'});
}

openNativeUrl = (nurl) => {
	
	var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;
	
	this.setState({appDownload: ( osname === "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink )});
	
	$('.main_loader').attr({'style':'display:flex'});
	customProtocolCheck(nurl,
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
							window.$('#nativedownload').modal('show');
						  },
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
						  }, 15000);
}
  
updateTokenAndStorage = (id, token, expire) => {
	window['lastUserId'] = 0;
   //set cookie after register
	this.setCookie('id', id, expire);
	this.setCookie('token', token, expire);
	//set cookie after register
}
  
 render() {
	 
	 return (
		 <div>
		 
			<div className="modal pr-0" id="session_alert_new" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center font-20">Your class is ready to Join.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							{
								this.state.urlElectron ?
								<a href="#" data-toggle="modal" data-target="#autologinnative"><div className=""><button className="custom_btn1 mt-3 mx-2" onClick={this.closeLogin}>join</button></div></a>
								:
								<a href ={this.state.urlPath} target="_blank"><div className=""><button className="custom_btn1 mt-3 mx-2" onClick={this.closeLogin}>join</button></div></a>
							}
							
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0 zi9999" id="error_msg_register" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
							<p className="py-4 text-light text-center font-20 verfiy_error_pop">{this.state.msg}</p> 	
						</div>
						<div className="text-center">
							<button type="button" 
							className="custom_btn1 mt-3" 
							data-dismiss="modal" 
							onClick={e=>{ $("#error_msg_register").attr({'style':'display:none'}); }}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="error_msg2" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
							<p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
						</div>
						<div className="text-center">
							<button type="button" 
							className="custom_btn1 mt-3" 
							data-dismiss="modal" 
							onClick={e=>{
							$("#error_msg2").attr({'style':'display:none'});
							// alert(!this.validator.fieldValid('firstname'))
							if(!this.validator.fieldValid('firstname')){
							if(!this.validator.getErrorMessages().firstName){
							this.setState({
							errorClass_FirstName:false
							})
							}
							else{
							this.setState({
							errorClass_FirstName:true
							})
							}
							this.firstName.focus();
							}
							if(!this.validator.fieldValid('lastName')){
							this.setState({
							errorClass_LastName:true
							})
							this.lastName.focus();
							}
							if(!this.validator.fieldValid('emailSign') ){
							this.setState({
							errorClass_Email:true
							})
							this.emailSign.focus();
							}
							if(!this.validator.fieldValid('password') || this.state.msg==="Password mismatch!"){
							this.setState({
							errorClass_Password:true
							})
							this.password.focus();
							}
							if(!this.validator.fieldValid('rePassword')){
							this.setState({
							errorClass_RePassword:true
							})
							this.rePassword.focus();
							}
							}}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="error_msg3" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
							<p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
						</div>
						<div className="text-center">
							<button type="button" 
							className="custom_btn1 mt-3" 
							data-dismiss="modal" 
							onClick={e=>{
							$("#error_msg3").attr({'style':'display:none'});
							// alert(!this.validator.fieldValid('firstname'))
							if(!this.validator.fieldValid('FullName')){
							if(!this.validator.getErrorMessages().fullName){
							this.setState({
							errorClass_FullName:false
							})
							}
							else{
							this.setState({
							errorClass_FullName:true
							})
							}
							this.fullName.focus();
							}
							if(!this.validator.fieldValid('CardNumber')){
							if(!this.validator.getErrorMessages().cardNumber){
							this.setState({
							errorClass_CardNumber:false
							})
							}
							else{
							this.setState({
							errorClass_CardNumber:true
							})
							}
							this.cardNumber.focus();
							}
							
							
							
							
							if(!this.validator.fieldValid('CardMonth') )
							{
								if(!this.validator.getErrorMessages().cardMonth)
									{
										if ( parseInt(this.state.cardMonth) < 1 || parseInt(this.state.cardMonth) > 12 )
											{
												this.setState({
													errorClass_CardMonth:true
												})
											}
										else
											{
												this.setState({
													errorClass_CardMonth:false
												})
											}
									}
								else
									{
										this.setState({
											errorClass_CardMonth:true
										})
									}
								
								this.cardMonth.focus();
							}
							
							
							
							
							
							if(!this.validator.fieldValid('CardYear') ){
							if(!this.validator.getErrorMessages().cardYear){
							this.setState({
							errorClass_CardYear:false
							})
							}
							else{
							this.setState({
							errorClass_CardYear:true
							})
							}
							this.cardYear.focus();
							}
							if(!this.validator.fieldValid('SecurityCode')){
							if(!this.validator.getErrorMessages().securityCode){
							this.setState({
							errorClass_SecurityCode:false
							})
							}
							else{
							this.setState({
							errorClass_SecurityCode:true
							})
							}
							this.securityCode.focus();
							}
							if(!this.validator.fieldValid('ZipCode')){
							if(!this.validator.getErrorMessages().zipCode){
							this.setState({
							errorClass_ZipCode:false
							})
							}
							else{
							this.setState({
							errorClass_ZipCode:true
							})
							}
							this.zipCode.focus();
							}
							}}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="error_msg4" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
							<p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
						</div>
						<div className="text-center">
							<button type="button" 
							className="custom_btn1 mt-3" 
							data-dismiss="modal" 
							onClick={e=>{
							$("#error_msg4").attr({'style':'display:none'});}}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			 { this.props.state.isChargingSessionIncluded &&
				<div className="modal" id="chargePopup" aria-modal="true">
					<div className="for_main_form centered_pop">
						<div>
							<div className="gray-box-4 px-4 pt-5 pb-4 input_bx-cont for_sign relative back_cl2a">
								<button type="button" className="close white closepurple translatX-Y abs_close" onClick={e=>$("#chargePopup").attr({'style':'display:none'})}><span>x</span></button>
								{ this.props.state.sessionsTotal <= 1 && 
									<p className="py-4 text-light text-center font-20">{this.props.state.sessionHostFirstName} {this.props.state.sessionHostLastName} is charging ${this.props.state.chargeForSession} for the class. A Credit Card is required to hold on your spot, which will be charged at the start of the class. You can cancel up to 4 hours prior to the start of the class.</p>
								} 
								{ this.props.state.sessionsTotal > 1 && 
									<p className="py-4 text-light text-center font-20">{this.props.state.sessionHostFirstName} {this.props.state.sessionHostLastName} is charging ${this.props.state.chargeForSession}/class, which comes to ${this.props.state.finalChargeForSession} for the {this.props.state.sessionsTotal} classes you have selected. A Credit Card is required to hold your spot, which will be charged at the start of each class. You can cancel up to 4 hours prior to the start of a class.</p>
								}
							</div>
							<div className="text-center">
								<button type="button" className="save-btn btn btn-primary mt-3 font-book mx-auto font-18" onClick={this.continuePay}>Continue</button>
							</div>
						</div>
					</div>
				</div>
			 }
					<div> 
						<div className={"gray-box-4 px-4 pt-5 pb-4 input_bx-cont for_sign2 relative back_cl2a profile_pay_form main_payment_hold inner_session_holder " + (this.state.log_or_signup ? 'extend_verify_pop' : '')}>
							<div className="hidden_div" onClick={this.showCharging}></div>
							<div className="hidden_div_train" onClick={this.showTrainingPayment}></div>
							<div className="hidden_div_sign" onClick={this.updateSignEmail}></div>
							<div className="pay_top_menu">
								<div className="wid33_dsp main_menus tx_lft log_div">
									<div className="flx_div new_pos_rel">
										<button type="button" className="close white closepurple p-0 translatX-Y new_closepurple_channel"  data-dismiss="modal" onClick={e=>$('.chargingPlans #sessionPay').attr({'style':'display:none'})}><span>×</span></button>								
										<div className={ "pay_circ lck_icn " + ( ( this.state.log_or_signup || this.state.make_payment || this.state.review_session ) ? "active" : "" ) }></div>
										<div className="pay_circle_text">LOGIN / SIGN UP</div>
									</div>
								</div>
								<div className="wid33_dsp main_menus make_pay_div tx_cnt">
									<div className="flx_div">
										<div className={ "pay_circ wall_icn " + ( this.state.make_payment || this.state.review_session ? "active" : "" ) }></div>
										<div className="pay_circle_text">MAKE PAYMENT</div>
									</div>
								</div>
								<div className="wid33_dsp main_menus tx_rght review_sess_div">
									<div className="flx_div">
										<div className={ "pay_circ eye_icn " + ( this.state.review_session ? "active" : "" ) }></div>
										<div className="pay_circle_text">REVIEW CLASS</div>
									</div>
								</div>
							</div>
							{ this.state.log_or_signup &&
								<div>
									{ this.props.state.log_in &&
										<div className="changedLogin overflow_payment">
											<Login 
												state={this.props.state}
												closeLogin={this.closeLogin}
												showLoginAlert={this.showLoginAlert}
												updateStateChargeFromChild={this.updateStateChargeFromChild}
												updateStateFromChild={this.updateStatesPayments}
												updateStateFromChildSession={this.props.updateStateParticipantSignUpFromChildSession/*, this.props.updateStateChanelFromChildSession*/}
												>
											</Login>
										</div>
									}
									{ this.props.state.sign_up &&
										<div className="changedLogin overflow_payment sign_up_verify">
											<MainSignUp 
												state={this.state}
												closeSign={this.closeSign}
												onChange={this.onChange}
												resend={this.resend}
												submitHost={this.submitHostC}
												updateRefState={this.updateRefState}
												updateStateFromChild={this.updateStateChargeFromChild}
												verify={this.verify}
												>
											</MainSignUp>
										</div>
									}
								</div>
							}
							{ this.state.make_payment &&
								<div className="changedLogin overflow_payment">
									{ this.state.listCard &&
										<div className="listcard">
											<div className={"outer_charge_hold  " + ( this.state.openedFrom == 'myProfile' ? "" : "summary_popup_background" )}>
												<div className="charge_hold_title">
													<div className="charge_top_title">Make Payment</div>
													<div className="charge_bottom_title">We Accept all major Credit Cards</div>
												</div>
												<div className="credit_cards_hold">
													<div className="credit_cards_icon"></div>
													<div className="credit_cards_icon card_mastercard"></div>
													<div className="credit_cards_icon card_visa"></div>
													<div className="credit_cards_icon card_visa_electron"></div>
													<div className="credit_cards_icon card_maestro"></div>
												</div>
											</div>
											<div className="paymentSignUp2 tx_lft d-none">Credit/Debit Cards</div>
											
											{ this.state.creditCards.length === 0 ?
												<div className="card_error_info">No available Credit Cards. Please add bellow.</div>
											:
												<div className="credit_card_list_hold">
													<div className="marg_btm10">
														<div className="titleColumns">
															<div className="creditCard card_holder d-none">
																<div className="inner_card_text">Your saved credit and debit cards</div>
															</div>
															<div className="nameOnCard card_holder d-none">
																<div className="inner_card_text">Name on card</div>
															</div>
															<div className="expires card_holder d-none">Expires on
															</div>
														</div>
													</div>
													<div className="marg_btm10"> 
														{ this.state.creditCards.map((cred, i)=>
															<div key={i}>
																<div className={"titleColumns add_hei_flex card_inner_hold white_txx " + (cred.checked ? "card_back_active" : "")}>
																	<div className="creditCard">
																		<div>
																			<div className="creditCardCheckbox tx_cnt">
																				<div className={ "cstm_check " + ( cred.checked ? "active" : "" ) } onClick={ () => this.picKThisCard(i) }></div>
																			</div>
																			<div className="creditCardPic">
																				<div className={ "creditPic " + ( cred.cardtype ? cred.cardtype : "" ) }></div>
																			</div>
																			<div className="creditCardName text_wrap card_wrap_padding">{cred.type}: ***{cred.card_number}</div>
																		</div>
																	</div>
																	<div className="expires">Exp. {cred.expires}
																		<div className="rec_bin" onClick={ () => this.deleteCreditCard(i) }></div>
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>
											}
											<div>
												<div className="widt40 tx_lft">
													<div className="display-inline-block cursor-pointer add_new_card_hold" data-toggle="modal" onClick={this.addNewCreditCard}>
														<img src="/images/add.png" className="add_new_card" alt="" />
														Add New Card
													</div>
												</div>
												<div className="widt60 text-center">
													{this.state.openedFrom != 'myProfile' && <div className="add_butt payment_main_button" onClick={this.goToReview}>Pay Now</div>}
													{this.state.openedFrom == 'myProfile' && <div className="add_butt" onClick={this.goToReview}>Continue</div>}
												</div>
											</div>
										</div>
									}
									{ this.state.newCard &&
										<div>
											<div className="gray-box-4 px-2 px-sm-4 pt-4 pb-0 input_bx-cont for_sign relative payments_defualt_mobile add_card_credit">
												<div className="mrg_22">
													<div className="form-group">
														<label className="label">Full Name<span className="inp_cover-border"></span></label>
														<input type="text" 
														className={(this.state.errorClass_FullName?"input-field red-outline add_inp_fld":"input-field add_inp_fld")} 
														value={this.state.fullName} 
														ref={fullname=> this.updateRefState(fullname, 'fullName')} 
														id ='fullName' 
														onChange={(e)=>{
														  this.updateStateChargeFromChild({
															[e.target.id]:e.target.value,
															errorClass_FullName:false
														  })}}  />
														{this.validator.message('fullName', this.state.fullName, 'required|min:1|max:30')}
													</div>
												</div>
												<div className="mrg_22">
													<div className="form-group">
														<label className="label">Card Number<span className="inp_cover-border"></span></label>
														<input type="text" 
														className={(this.state.errorClass_CardNumber?"input-field red-outline add_inp_fld":"input-field add_inp_fld")} 
														value={this.state.cardNumber} 
														ref={cardnumber=> this.updateRefState(cardnumber, 'cardNumber')} 
														id ='cardNumber' 
														onChange={(e)=>{
														  this.updateStateChargeFromChild({
															[e.target.id]:e.target.value,
															errorClass_CardNumber:false
														  })}}/>
														{this.validator.message('cardNumber', this.state.cardNumber, 'required|card_num|min:1|max:16')}
													</div>
												</div>
												<div className="mrg_22">
													<div className="wid33_dsp new_font360">Expiration
													</div>
													<div className="wid33_dsp padd10_rght">
														<div className="form-group">
															<label className="label">Month<span className="inp_cover-border"></span></label>
															<input type="text" 
															className={(this.state.errorClass_CardMonth?"input-field red-outline add_inp_fld":"input-field add_inp_fld")} 
															value={this.state.CardMonth} 
															ref={cardmonth=> this.updateRefState(cardmonth, 'cardMonth')} 
															id ='cardMonth' 
															onChange={(e)=>{
															  this.updateStateChargeFromChild({
																[e.target.id]:e.target.value,
																errorClass_CardMonth:false
															  })}}  />
															{this.validator.message('cardMonth', this.state.cardMonth, 'required|min:1|max:2')}
															{this.validator.message('cardMonth', (this.state.cardMonth+'/'+this.state.cardYear), 'is_card_expired')}
														</div>
													</div>
													<div className="wid33_dsp padd10_lft">
														<div className="form-group">
															<label className="label new_font360">Full Year<span className="inp_cover-border"></span></label>
															<select className={(this.state.errorClass_CardYear?"input-field select_card_main red-outline add_inp_fld":"input-field select_card_main add_inp_fld")} id="cardYear" value={this.state.cardYear} ref={cardyear=> this.updateRefState(cardyear, 'cardYear')}  onChange={(e)=>{ this.updateStateChargeFromChild({ [e.target.id]:e.target.value, errorClass_CardYear:false })}} >
																<option value=""></option>
																{ this.state.cardYears.map((item, key)=> 
																	<option value={item} key={key}>{item}</option>
																)}
															</select>
															{this.validator.message('cardYear', this.state.cardYear, 'required|min:4|max:4')}
															{this.validator.message('cardYear', (this.state.cardMonth+'/'+this.state.cardYear), 'is_card_expired')}
														</div>
													</div>
												</div>
												<div className="mrg_22">
													<div className="w50_dspl padd10_rght">
														<div className="form-group">
															<label className="label">Security Code<span className="inp_cover-border"></span></label>
															<input type="text" 
															className={(this.state.errorClass_SecurityCode?"input-field red-outline add_inp_fld":"input-field add_inp_fld")} 
															value={this.state.securityCode} 
															ref={securitycode=> this.updateRefState(securitycode, 'securityCode')} 
															id ='securityCode' 
															onChange={(e)=>{
															  this.updateStateChargeFromChild({
																[e.target.id]:e.target.value,
																errorClass_SecurityCode:false
															  })}}  />
															{this.validator.message('securityCode', this.state.securityCode, 'required|integer|min:3|max:4')}
														</div>
													</div>
													<div className="w50_dspl padd10_lft">
														<div className="form-group">
															<label className="label">Zip Code<span className="inp_cover-border"></span></label>
															<input type="text" 
															className={(this.state.errorClass_ZipCode?"input-field red-outline add_inp_fld":"input-field add_inp_fld")} 
															value={this.state.zipCode} 
															ref={zipcode=> this.updateRefState(zipcode, 'zipCode')} 
															id ='zipCode' 
															onChange={(e)=>{
															  this.updateStateChargeFromChild({
																[e.target.id]:e.target.value,
																errorClass_ZipCode:false
															  })}}  />
															{this.validator.message('zipCode', this.state.zipCode, 'required|integer|min:3|max:5')}
														</div>
													</div>
												</div>
												<div className="mrg_22">
													<div className="w50_dspl tx_lft w100paybtns">
														<div className={ "cstm_check " + ( this.state.saveToFile ? "active" : "" ) } onClick={this.saveToFile}></div>
														<div className="cstm_check_title" onClick={this.saveToFile}>Save to File</div>
													</div>
													<div className="w50_dspl tx_rght w100paybtns">
														<div className="add_butt mr20" onClick={this.closePay}>Cancel</div>
														<div className="add_butt" onClick={this.saveCardNumber}>Save</div>
													</div>
												</div>
											</div>
										</div>
									}
								</div>
							}
							{
								this.state.review_session &&
								<div className="changedLogin">
									<div className="listcard2">
										<div className="paymentSignUp2 tx_cnt">Summary of Purchase</div>
										<div className="over_flw mh55">
											<div className="tp_bt_grey">
												<div className="titl_25_lf">Instructor Name</div>
												<div className="titl_25_lf">Date & Time</div>
												<div className="titl_25_lf txtcent">Duration</div>
												<div className="titl_25_lf txtcent">Amount</div>
											</div>
											<div>
												{ this.state.pickedSessions.map((ses, i)=>
													<div key={i} className="chargeseslist">
														<div className="titl_25_lf">{ses.hostName}</div>
														<div className="titl_25_lf">{ses.sessionStartTime}</div>
														<div className="titl_25_lf txtcent">{ses.duration}</div>
														<div className="titl_25_lf txtcent">${ses.amount}</div>
													</div>
												)}
											</div>
											<div className="tp_bt_grey">
												<div className="titl_75_lf fs18">Total charge is ${this.props.state.finalChargeForSession}, charged to Credit Card ending in &nbsp;
													{ typeof this.state.pickedCardFinal !== 'undefined' &&  typeof this.state.pickedCardFinal.card_number !== 'undefined' &&
														<span>
															{ this.state.pickedCardFinal.card_number.slice(-4) }
														</span>
													}
												</div>
												<div className="titl_25_lf fs22 txtcent">${this.props.state.finalChargeForSession}</div>
											</div>
										</div>
									</div>
								</div>
							}
						</div>
						{
							this.state.review_session &&
							<div className="text-center" >
								<button type="button" className="btn btn-primary px-4 py-2 mt-1 font-20 font-weight-bold ml-2" onClick={this.acceptSessionPayments}>Sign Up</button>
							</div>
						}
					</div>
			
			<div className="modal pr-0" id="registration_popup" aria-modal="true">
				<div className="modal-dialog px-2 small_width modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
						  <h4 className="modal-title font-weight-bold white text-center">Welcome To Virdio</h4>
						  <p className="pt-4 text-white text-center font-22">Your sign up was successful.</p>
						  <p className="pb-4 text-light text-center font-20"> A mail is also sent to your inbox to let you check if your preferred device/system is ready for participating in the class.</p>

						  
						  </div>
						<div className="text-center">
						  <button type="button" onClick={this.reloadPg} className="custom_btn1 mt-3">ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="autologinnative" aria-modal="true">
				<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="join_pop_container">
							<button type="button" className="close white closepurple join_close_iks" data-dismiss="modal"><span>x</span></button>
							{ this.state.urlElectron !== '' &&
								<div className="join_parent">
									<div className="join_title">Virdio recommends the Virdio App for the best experience</div>
									<div className="button_join_container">
										{ mobileTab && ios ?
											<a href={this.state.urlElectron} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div></a>
											:
											<div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div>
										}
										
									</div>
									<div className="browser_run">
										{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank"><span className="run_web">Run in the browser instead</span></a> }
									</div>
								</div>
							}
							{ this.state.urlElectron === '' &&
								<div className="join_parent">
									<div className="join_title">Virdio recommends Web browser for the best experience</div>
									<div className="button_join_container">
										 <a href={this.state.urlPath} target="_blank"><div className="join_pop_btn">Run in the browser</div></a>
									</div>
									<div className="browser_run"></div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="nativedownload" aria-modal="true">
				<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
							<p className="mb-0 py-4 white text-center">Looks like the Virdio App is not installed on your device, please click the Download button to download the Virdio App Installer.</p>
							{ osname == 'Windows' && <p className="mb-0 py-4 white text-center">Once the Installer is downloaded, double click on the installer to launch it and proceed with installation steps.</p> }
							{ osname !== 'Windows' && <p className="mb-0 py-4 white text-center">Once the Installer is downloaded, double click on the installer to launch it and then move the Virdio Icon to the folder.</p> }
						</div>
						<div className="text-center"><a href={this.state.appDownload} target="_blank" onClick={e=> { window.$("#nativedownload").modal('hide'); window.$("#nativedownload2").modal('show') }}><button type="button" className="col-4 custom_btn1 mt-3">DOWNLOAD</button></a></div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="nativedownload2" aria-modal="true">
				<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
							{ ( chrome || edge ) && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible on the bottom left of the browser, click on the file to Install Virdio.</p> }
							{ !chrome && !edge && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible at the top right of the browser, click on the file to Install Virdio.</p> }
							<p className="mb-0 py-4 white text-center">2. After the Installation is complete, click on the JOIN button below.</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 col-4 mt-3" onClick={() => { this.openNativeUrl(this.state.urlElectron); window.$("#nativedownload2").modal('hide') }}>JOIN</button></div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="user_not_verified" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
							<p className="mb-0 py-4 white text-center font-20">Your username is already registered, but has not been verified. To complete your registration please select OK and you will be guided through the process.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<Link className="main_link" to='/login'><button className="custom_btn1 mt-3 mx-2">OK</button></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
  }
}

export default connect(
  null,
  {
	showMainErrorPop
  }
)(ChargingSession);
