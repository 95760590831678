import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";
import { getSessionDate } from '../../util/formatDateFunctions';
import moment from 'moment';
import moment1 from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";

class ChannelSearch extends Component {
  
  constructor(props) {
	super(props);
	this.state={
		equipmentInput: '',
    }
	this.getSessionDate = getSessionDate.bind(this);
}
 
componentDidMount(){
	this.delayTimer = '';
}

checkFilters = (e, mod)=>{
	
	if ( mod == this.props.state.activeFilter )
		{
			this.props.updateStateFromChild({activeFilter: '', showLogOutBtn: false}, e);
		}
	else 
		{
			this.props.updateStateFromChild({activeFilter: mod, showLogOutBtn: false}, e);
		}		
	
}

setDayBefore=(e, mod)=>{
	
	e.preventDefault();
	let currentDate = '';
	this.props.updateStateFromChild({activeFilter: ''}, e);
	if ( mod == 'start' )
		{
			currentDate = this.props.state.startDateFilter;
		}
	else 
		{
			currentDate = this.props.state.endDateFilter;
		}		
	
	var oneDayLess = currentDate.setDate(currentDate.getDate() - 1);
	oneDayLess = new Date(oneDayLess);
	
	if ( mod == 'start' )
		{
			let backFilterCounter = this.props.state.backFilter;
			backFilterCounter = backFilterCounter - 1;
			this.props.updateStateFromChild({startDateFilter: oneDayLess, backFilter: backFilterCounter}, e);
		}
	else 
		{
			let backFilterCounter = this.props.state.backFilterEnd;
			backFilterCounter = backFilterCounter - 1;
			this.props.updateStateFromChild({endDateFilter: oneDayLess}, e);
		}	

	var date1 = new Date(this.props.state.startDateFilter);
	date1.setHours(0);
	date1.setMinutes(0);
	
	var date2 = new Date(this.props.state.endDateFilter);
	date2.setHours(0);
	date2.setMinutes(0);
	
	var Difference_In_Time = 0;
	if ( date1 > date2 )
		{
			this.props.updateStateFromChild({showClearButton: true});
		}
	else 
		{
			Difference_In_Time = date2.getTime() - date1.getTime();
		}				
	
	var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
	let difNum = Math.round(Difference_In_Days)
	//console.log(difNum);
	this.props.updateStateFromChild({backFilterEnd : difNum, backFilterEndChanged: true});
	setTimeout(() => {
			this.props.getUpcomingSession('filter');
		}, 200);
}

setNextDay=(e, mod)=>{
	e.preventDefault();
	let currentDate = '';
	this.props.updateStateFromChild({activeFilter: ''}, e);
	if ( mod == 'start' )
		{
			currentDate = this.props.state.startDateFilter;
		}
	else 
		{
			currentDate = this.props.state.endDateFilter;
		}		
	
	var nextDay = currentDate.setDate(currentDate.getDate() + 1);
	nextDay = new Date(nextDay);
	
	if ( mod == 'start' )
		{
			let backFilterCounter = this.props.state.backFilter;
			backFilterCounter = backFilterCounter + 1;
			this.props.updateStateFromChild({startDateFilter: nextDay, backFilter: backFilterCounter}, e);
			
			var dateTemp = new Date(nextDay);
			var dateTemp2 = new Date(this.props.state.endDateFilter);
			
			if ( dateTemp >= dateTemp2 )
				{
					var nextDay = dateTemp.setDate(dateTemp.getDate() + 1);
					nextDay = new Date(nextDay);
					this.props.updateStateFromChild({endDateFilter:nextDay});
				}
		}
	else 
		{
			let backFilterCounter = this.props.state.backFilterEnd;
			backFilterCounter = backFilterCounter + 1;
			this.props.updateStateFromChild({endDateFilter: nextDay, backFilterEnd: backFilterCounter}, e);
		}

	var date1 = new Date(this.props.state.startDateFilter);
	date1.setHours(0);
	date1.setMinutes(0);
	
	var date2 = new Date(this.props.state.endDateFilter);
	date2.setHours(0);
	date2.setMinutes(0);
	
	var Difference_In_Time = 0;
	if ( date1 >  date2 )
		{
			this.props.updateStateFromChild({showClearButton: true});
		}
	else 
		{
			Difference_In_Time = date2.getTime() - date1.getTime();
		}				
	
	var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
	let difNum = Math.round(Difference_In_Days)
	this.props.updateStateFromChild({backFilterEnd : difNum, backFilterEndChanged: true});
	setTimeout(() => {
			this.props.getUpcomingSession('filter');
		}, 200);
}

getSessionDateFilter=(date)=>{
	let todayDate = new Date();
	let todateDateMoment = moment(todayDate).format('dddd, MMM DD');
	let dateMom = moment(date).format('dddd, MMM DD');
	let finishedDateType = '';
	
	let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	
	let day1=new Date(date).getDay();
	if ( dateMom == todateDateMoment )
		{
			finishedDateType = 'Today, ' + moment(date).format('MMM DD');
		}
	else 
		{
			finishedDateType = weekday[day1]+', ' + moment(date).format('MMM DD');
		}
	return finishedDateType;
}

closeFilter=(e, mod)=>{
	e.preventDefault();
	if ( mod == 'Interest' )
		{
			this.props.updateStateFromChild({selectedInterests: [], activeFilter: ''}, e);	
		}
	else if ( mod == 'Level' )
		{
			this.props.updateStateFromChild({selectedLevel: '', activeFilter: ''}, e);
		}
	else if ( mod == 'Host' )
		{
			this.props.updateStateFromChild({selectedHostsArray: [], activeFilter: ''}, e);
		}
	else if ( mod == 'Duration' )
		{
			this.props.updateStateFromChild({minDuration: 0, maxDuration: 0, activeFilter: ''}, e);
		}
	else if ( mod == 'Equipment' )
		{
			this.props.updateStateFromChild({selectedEquipmentArray: [], activeFilter: ''}, e);
		}		
		
	setTimeout(() => {
			this.props.startFilterSearch();
		}, 200);
		
}

setDuration=(e)=>
{
	if ( e.target.id == 'minDuration' )
		{
			this.props.updateStateFromChild({minDuration: e.target.value}, e);
		}
	else
		{
			this.props.updateStateFromChild({maxDuration: e.target.value}, e);
		}		
}

searchFilter=(e)=>{
	e.stopPropagation();
	let mainthis = this;
	if ( mainthis.delayTimer )
		{
			
			clearTimeout(mainthis.delayTimer);
		}
	mainthis.props.updateStateFromChild({searchByName: e.target.value}, e);
	
	mainthis.delayTimer = setTimeout(function() {
		mainthis.props.startFilterSearch();
	}, 2000);
}

startFilterDuration=(e, mod)=>{
	let mainthis = this;
	setTimeout(() => {
	if ( mod == 'min' )
		{
			let minDuration = mainthis.props.state.minDuration;
			let maxDuration = mainthis.props.state.maxDuration;
			if ( parseInt(minDuration) > parseInt(maxDuration) )
				{
					var newDuration = parseInt(minDuration) + 1;
					mainthis.props.updateStateFromChild({maxDuration: newDuration}, e);
				}
			
		}
	else 
		{
			let minDuration = mainthis.props.state.minDuration;
			let maxDuration = mainthis.props.state.maxDuration;
			if ( parseInt(minDuration) > parseInt(maxDuration) )
				{
					if ( maxDuration > 0 )
						{
							var newDuration = parseInt(maxDuration) - 1;
							
						}
					else 
						{
							var newDuration = 0;
						}
					mainthis.props.updateStateFromChild({minDuration: newDuration}, e);	
				}
		}		
		setTimeout(() => {
				mainthis.props.startFilterSearch();
			}, 200);
	}, 100); 
}

closeSearchInput=(e)=>{
	if (this.props.state.searchByName.length > 0 )
		{
			this.props.updateStateFromChild({expandSearch: false, searchByName: ''});
			setTimeout(() => {
					this.props.startFilterSearch();
				}, 200);
		}
	else 
		{
			this.props.updateStateFromChild({expandSearch: false});
		}		
}

searchForEquipment=(e)=>{
	
	let mainArray = this.props.state.selectedEquipmentArray;
	let currentItem = '';
	if (e.keyCode == 13 && e.target.value.length > 0 )
		{	
			currentItem = e.target.value;
			mainArray.push(currentItem);
			this.props.updateStateFromChild({selectedEquipmentArray: mainArray, equipmentInput: ''}, e);
		}		
}

deleteEquipment=(e, mod)=>{
	let mainArray = this.props.state.selectedEquipmentArray;
	if ( mainArray.indexOf(mod) > -1 )
		{
			let indexOf = mainArray.indexOf(mod);
			mainArray.splice(indexOf, 1);
		}
	this.props.updateStateFromChild({selectedEquipmentArray: mainArray}, e);	
	
	if ( mainArray.length == 0 )
		{
			this.resetEquipmentFilter();
		}
}

resetEquipmentFilter=(e)=>{
	this.props.updateStateFromChild({selectedEquipmentArray: [], activeFilter: ''}, e);
	setTimeout(() => {
			this.props.startFilterSearch();
		}, 200);
}

calenderDate=(mod, type, e)=>{
	if ( type == 'start' )
		{
			var date1 = new Date(this.props.state.minDateFilter);
			var date2 = new Date(mod);
			var Difference_In_Time = date2.getTime() - date1.getTime();
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			let difNum = Math.round(Difference_In_Days)
			
			this.setState({
				startDateFilter: mod,
			});
			
			let momentStartDate = moment(new Date(mod)).format('YYYY-MM-DD 00:00:00');
			
			var dateTemp = new Date(momentStartDate);
			var dateTemp2 = new Date(this.props.state.endDateFilter);
			/*console.log(dateTemp);
			console.log(dateTemp2);*/
			var Difference_In_Time_Temp = 0;
			if ( dateTemp >=  dateTemp2 )
				{
					var nextDay = dateTemp.setDate(dateTemp.getDate() + 1);
					nextDay = new Date(nextDay);
					this.props.updateStateFromChild({endDateFilter:nextDay});
				}
			else 
				{
					Difference_In_Time_Temp = dateTemp2.getTime() - dateTemp.getTime();
				}				
			
			var Difference_In_Days_Temp = Difference_In_Time_Temp / (1000 * 3600 * 24);
			let difNum_Temp = Math.round(Difference_In_Days_Temp);
			this.props.updateStateFromChild({startDateFilter: mod, backFilter: difNum, backFilterEnd : difNum_Temp});
			//console.log(difNum_Temp);
		}
	else 
		{
			let momentStartDate = moment(new Date(this.props.state.startDateFilter)).format('YYYY-MM-DD 00:00:00');
			
			var date1 = new Date(momentStartDate);
			var date2 = new Date(mod);
			/*console.log(date1);
			console.log(date2);*/
			var Difference_In_Time = 0;
			if ( date1 >  date2 )
				{
					this.props.updateStateFromChild({showClearButton: true});
				}
			else 
				{
					Difference_In_Time = date2.getTime() - date1.getTime();
				}				
			
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			let difNum = Math.round(Difference_In_Days)
			
			this.props.updateStateFromChild({endDateFilter: mod, backFilterEnd : difNum, backFilterEndChanged: true});
			this.setState({
				endDateFilter: mod,
			});
			//console.log(difNum);
		}
	setTimeout(() => {
			this.props.getUpcomingSession('filter');
		}, 200);	
}

expandSearchFunction=(e)=>{
	this.props.updateStateFromChild({expandSearch: true, activeFilter: ''}, e);
	setTimeout(() => {
			$('#searchByName').focus();
		}, 100);
}

clickOnDataPicker=(e)=>{
	$('.datePickerCh').blur();
}

checkBlurInput=(e)=>{
	if ( android || ios )
		{
			let mainArray = this.props.state.selectedEquipmentArray;
			let currentItem = '';
			
			if ( e.target.value.length > 0 )
				{
					currentItem = e.target.value;
					mainArray.push(currentItem);
					this.props.updateStateFromChild({selectedEquipmentArray: mainArray, equipmentInput: ''}, e);
				}
			
			
		}
}

openStartDatePicker=()=>{
	this._calendar.setOpen(true);
}

openEndDatePicker=()=>{
	this._calendar1.setOpen(true);
}

applyEquipmentSearch=(e)=>{
	this.props.updateStateFromChild({activeFilter: ''});
	this.props.startFilterSearch();
}

  render() {
	 return (
		<div>
			<div className={classnames("searchExpHolder " + (this.props.state.expandSearch ? "searchExpHolderPadding" : ""))} >
				<div className="search_half_holder" className={classnames("search_half_holder " + (this.props.state.choosenTab == 'On-Demand' ? "onDemandSearchCh" : ""))} >
					{ this.props.state.expandSearch ? 
						<div className="inpt_search_holder" >
							<div className="closeSearchAbs" onClick={(e)=>{this.closeSearchInput(e)}} >
								<svg width="14" height="14" viewBox="0 0 14 14" fill={this.props.state.inputFillColor} xmlns="http://www.w3.org/2000/svg">
									<path d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z" />
								</svg>
							</div>
							<input className="searchInputCh" placeholder="Search..." id="searchByName" onKeyUp={(e)=>this.searchFilter(e)} className={classnames("channelSearch searchInputHolder ")} autoComplete="off" ></input>
						</div>
						:
						<div className="expandSearchBox"  className={classnames("expandSearchBox " + (this.props.state.choosenTab == 'On-Demand' ? "onDemandSearchColor" : ""))} onClick={(e)=>{this.expandSearchFunction(e)}} >
							<div className="searchSvgInput" >
								{this.props.state && this.props.state.searchFont ?
									<svg version="1.1" fill={this.props.state.searchFont}  id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"> <g> <g> <path d="M447.615,64.386C406.095,22.866,350.892,0,292.175,0s-113.92,22.866-155.439,64.386 C95.217,105.905,72.35,161.108,72.35,219.824c0,53.683,19.124,104.421,54.132,144.458L4.399,486.366 c-5.864,5.864-5.864,15.371,0,21.236C7.331,510.533,11.174,512,15.016,512s7.686-1.466,10.617-4.399l122.084-122.083c40.037,35.007,90.775,54.132,144.458,54.132c58.718,0,113.919-22.866,155.439-64.386c41.519-41.519,64.385-96.722,64.385-155.439S489.134,105.905,447.615,64.386z M426.379,354.029c-74.001,74-194.406,74-268.407,0c-74-74-74-194.407,0-268.407c37.004-37.004,85.596-55.5,134.204-55.5c48.596,0,97.208,18.505,134.204,55.5C500.378,159.621,500.378,280.028,426.379,354.029z"/></g></g></svg>
									:
									<svg version="1.1"  fill="#b002ed" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"> <g> <g> <path d="M447.615,64.386C406.095,22.866,350.892,0,292.175,0s-113.92,22.866-155.439,64.386 C95.217,105.905,72.35,161.108,72.35,219.824c0,53.683,19.124,104.421,54.132,144.458L4.399,486.366 c-5.864,5.864-5.864,15.371,0,21.236C7.331,510.533,11.174,512,15.016,512s7.686-1.466,10.617-4.399l122.084-122.083c40.037,35.007,90.775,54.132,144.458,54.132c58.718,0,113.919-22.866,155.439-64.386c41.519-41.519,64.385-96.722,64.385-155.439S489.134,105.905,447.615,64.386z M426.379,354.029c-74.001,74-194.406,74-268.407,0c-74-74-74-194.407,0-268.407c37.004-37.004,85.596-55.5,134.204-55.5c48.596,0,97.208,18.505,134.204,55.5C500.378,159.621,500.378,280.028,426.379,354.029z"/></g></g></svg>
								}
							</div>
						</div>
					}
				</div>
				{ this.props.state.choosenTab == 'live' &&
					<div className="calendarHolderSearch" >
							<div className="calendarInputHolder" >
							<div className="minDateStart" >
								<div className="calendarHolderImg">
									<div className="calendarImgHolderSvg" >
										<svg width="20" height="21" viewBox="0 0 20 21" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
											<path d="M15 2.5H19C19.2652 2.5 19.5196 2.60536 19.7071 2.79289C19.8946 2.98043 20 3.23478 20 3.5V19.5C20 19.7652 19.8946 20.0196 19.7071 20.2071C19.5196 20.3946 19.2652 20.5 19 20.5H1C0.734784 20.5 0.48043 20.3946 0.292893 20.2071C0.105357 20.0196 0 19.7652 0 19.5V3.5C0 3.23478 0.105357 2.98043 0.292893 2.79289C0.48043 2.60536 0.734784 2.5 1 2.5H5V0.5H7V2.5H13V0.5H15V2.5ZM2 8.5V18.5H18V8.5H2ZM4 12.5H9V16.5H4V12.5Z" />
										</svg>
									</div>
									<DatePicker ref={(a) => this._calendar = a} className="datePickerCh" id="datepicker"  onFocus={e=>{this.clickOnDataPicker(e)}} minDate={this.props.state.minDateFilter} onChange={date =>{this.calenderDate(date,'start')}} selected={this.props.state.startDateFilter} dateFormat="MMMM d, yyyy" />
								</div>
								
								{this.props.state.startDateFilter != '' &&
									<div className="calendarTextHolder" onClick={(e)=>{this.openStartDatePicker()}}> {this.getSessionDateFilter(this.props.state.startDateFilter)} </div>
								}
								
							</div>
								{this.props.state.backFilter > 0 &&
									<div className="day1Search "  onClick={e=>{this.setDayBefore(e, 'start')}} >
										<svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M8 15.5L1.03683 8.53683L8 1.57366" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
								}
								<div className="day1Search day2Search" onClick={e=>{this.setNextDay(e, 'start')}}>
									<svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 1.5L7.96317 8.46317L1 15.4263" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</div>
							</div>
							<div className="midLineSearch" ></div>
							<div className="calendarInputHolder" >
								<div className="minDateStart" >
								<div className="calendarHolderImg">
									<div className="calendarImgHolderSvg" >
										<svg width="20" height="21" viewBox="0 0 20 21" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
											<path d="M15 2.5H19C19.2652 2.5 19.5196 2.60536 19.7071 2.79289C19.8946 2.98043 20 3.23478 20 3.5V19.5C20 19.7652 19.8946 20.0196 19.7071 20.2071C19.5196 20.3946 19.2652 20.5 19 20.5H1C0.734784 20.5 0.48043 20.3946 0.292893 20.2071C0.105357 20.0196 0 19.7652 0 19.5V3.5C0 3.23478 0.105357 2.98043 0.292893 2.79289C0.48043 2.60536 0.734784 2.5 1 2.5H5V0.5H7V2.5H13V0.5H15V2.5ZM2 8.5V18.5H18V8.5H2ZM4 12.5H9V16.5H4V12.5Z" />
										</svg>
									</div>
									<DatePicker ref={(a) => this._calendar1 = a} className="datePickerCh" onFocus={e=>{this.clickOnDataPicker(e)}} id="datepickerEnd" minDate={this.props.state.startDateFilter} onChange={date =>{this.calenderDate(date,'end')}} selected={this.props.state.endDateFilter} dateFormat="MMMM d, yyyy" />
								</div>
									{this.props.state.endDateFilter != '' &&
										<div className="calendarTextHolder" onClick={(e)=>{this.openEndDatePicker()}}> {this.getSessionDateFilter(this.props.state.endDateFilter)} </div>
									}
								</div>
								
								{this.props.state.backFilterEnd > 1 &&
									<div className="day1Search " onClick={e=>{this.setDayBefore(e, 'end')}} >
										<svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M8 15.5L1.03683 8.53683L8 1.57366" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</div>
								}
								<div className="day1Search day2Search"onClick={e=>{this.setNextDay(e, 'end')}} >
									<svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 1.5L7.96317 8.46317L1 15.4263" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</div>
							</div>
					</div>
				}
			</div>	
				<div className="notExpHolder" >
					
					<div className="durationHolderFull" >
						<div 
							className={classnames("durationHolder firstDr_holder " + (this.props.state.activeFilter == 'Duration' ? "activeDurationHolder " : "") + (this.props.state.minDuration != 0 || this.props.state.maxDuration != 0 ? "activeFilterBorder " : "" ) + (this.props.state.activeFilter != 'Duration' && this.props.state.minDuration == 0 && this.props.state.maxDuration == 0 ? "notActiveArrowFilter" : "" ) )} onClick={ (e)=>{this.checkFilters(e, 'Duration')} } >
								<div className="absArrowFilter" >
									{this.props.state.activeFilter != 'Duration' && this.props.state.minDuration == 0 && this.props.state.maxDuration == 0 &&
										<svg width="16" height="6" viewBox="0 0 16 6" fill='none' xmlns="http://www.w3.org/2000/svg">
											<path d="M15 1L8.03683 4.48159L1.07366 1" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									}
								</div>
							{ (this.props.state.minDuration != 0 || this.props.state.maxDuration != 0) ? 
								<span>{this.props.state.minDuration} - {this.props.state.maxDuration} MIN</span>
								:
								<span>Duration</span>
							}
						</div>
							{this.props.state.activeFilter == 'Duration' && this.props.state.minDuration == 0 && this.props.state.maxDuration == 0 &&
								<div className="filterActivitySelected actFilterArrow" onClick={ (e)=>{this.checkFilters(e, 'Duration')} } >
									<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 4.5L7.96317 1.01841L14.9263 4.5" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</div>
							}
							{ (this.props.state.minDuration != 0 || this.props.state.maxDuration != 0) &&
								<div className="filterActivitySelected" onClick={(e)=>{this.closeFilter(e, 'Duration')}} >
									<svg width="14" height="14" viewBox="0 0 14 14" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
										<path d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z" />
									</svg>
								</div>
							}
							{this.props.state.activeFilter == 'Duration' &&
								<div className="durationHolderAbs" onClick={(e)=>{e.stopPropagation()}} >
									<div className="durationMin" >Min</div>
									<div className="durationMin minNum" >{this.props.state.minDuration} min</div>
									<input type="range" value={this.props.state.minDuration} className="inputRange" className={classnames("inputRange " + (this.props.state.minDuration == 0 ? "inactiveColorRange" : "") )} onChange={(e)=>this.setDuration(e)} id="minDuration" onMouseUp={(e)=>this.startFilterDuration(e, 'min')} onTouchEnd={(e)=>this.startFilterDuration(e, 'min')} />
									<div className="durationMin" >Max</div>
									<div className="durationMin minNum" id="maxDuration" >{this.props.state.maxDuration} min</div>
									<input type="range" value={this.props.state.maxDuration} className="inputRange" className={classnames("inputRange " + (this.props.state.maxDuration == 0 ? "inactiveColorRange" : "") )} onChange={(e)=>this.setDuration(e)} onMouseUp={(e)=>this.startFilterDuration(e, 'max')} onTouchEnd={(e)=>this.startFilterDuration(e, 'max')}/>
								
								</div>
							}
					</div>
					<div className="durationHolderFull" >
						<div className={classnames("durationHolder " + (this.props.state.activeFilter == 'Interest' ? "activeDurationHolder " : "") + ( this.props.state.selectedInterests.length > 0 ? "activeFilterBorder " : "" ) + ( this.props.state.selectedInterests.length == 0 && this.props.state.activeFilter != 'Interest' ? "notActiveArrowFilter " : "") )} onClick={ (e)=>{this.checkFilters(e, 'Interest')} } >
						<div className="absArrowFilter" >
							{this.props.state.selectedInterests.length == 0 && this.props.state.activeFilter != 'Interest' &&
								<svg width="16" height="6" viewBox="0 0 16 6" fill='none' xmlns="http://www.w3.org/2000/svg">
									<path d="M15 1L8.03683 4.48159L1.07366 1" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							}
						</div>
						Interest 
							{this.props.state.selectedInterests.length > 0 &&
								<span className="spanCounter" >({this.props.state.selectedInterests.length})</span>
							}
						</div>
						{this.props.state.selectedInterests.length > 0 &&
							<div className="filterActivitySelected" onClick={(e)=>{this.closeFilter(e, 'Interest')}} >
								<svg width="14" height="14" viewBox="0 0 14 14" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
									<path d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z" />
								</svg>
							</div>
						}
						{this.props.state.selectedInterests.length == 0 && this.props.state.activeFilter == 'Interest' &&
							<div className="filterActivitySelected actFilterArrow" onClick={ (e)=>{this.checkFilters(e, 'Interest')} } >
								<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 4.5L7.96317 1.01841L14.9263 4.5" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
						}
						{this.props.state.activeFilter == 'Interest' &&
							<div className="durationHolderAbs interestArrayHolder" onClick={(e)=>{e.stopPropagation()}} >
								<div className="intListHolderScroll" >
									{this.props.state.channelInterestList.map((item, i)=>
										<div className="selectHolder1" key={i} onClick={(e)=>{this.props.chooseInterest(e, item)}} >
											<div className="selectCircle1" >
												{this.props.state.selectedInterests.indexOf(item.id) > -1 &&
													<div className="activeCircleCh" ></div>
												}
											</div>
											<div className="selectText1" >{item.title}</div>
										</div>
									)}
								</div>
							</div>
						}
					</div>
					<div className="durationHolderFull" >
						<div className={classnames("durationHolder " + (this.props.state.activeFilter == 'Level' ? "activeDurationHolder " : "") + ( this.props.state.selectedLevel != '' ? "activeFilterBorder " : "" ) + ( this.props.state.activeFilter != 'Level' && this.props.state.selectedLevel == '' ? "notActiveArrowFilter" : "" ) )} onClick={ (e)=>{this.checkFilters(e, 'Level')}} >
							<div className="absArrowFilter" >
								{this.props.state.activeFilter != 'Level' && this.props.state.selectedLevel == '' && 
									<svg width="16" height="6" viewBox="0 0 16 6" fill='none' xmlns="http://www.w3.org/2000/svg">
										<path d="M15 1L8.03683 4.48159L1.07366 1" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								}
							</div>
							{this.props.state.selectedLevel == '' ? 
								<span> Level </span>
								:
								<span>{this.props.state.selectedLevel}</span>
							}
							
						</div>
							{this.props.state.activeFilter == 'Level' && this.props.state.selectedLevel == '' &&
								<div className="filterActivitySelected actFilterArrow" onClick={ (e)=>{this.checkFilters(e, 'Level')}} >
									<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 4.5L7.96317 1.01841L14.9263 4.5" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</div>
							}
							{this.props.state.selectedLevel != '' &&
								<div className="filterActivitySelected" onClick={(e)=>{this.closeFilter(e, 'Level')}} >
									<svg width="14" height="14" viewBox="0 0 14 14" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
										<path d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z" />
									</svg>
								</div>
							}
							{this.props.state.activeFilter == 'Level' &&
								<div className="durationHolderAbs lvlWidth" onClick={(e)=>{e.stopPropagation()}} >
									<div className="selectHolder1" onClick={(e)=>{this.props.chooseLevel(e, 'Beginner')}} >
										<div className="selectCircle1" >
										{this.props.state.selectedLevel == 'Beginner' &&
											<div className="activeCircleCh" ></div>
										}
										</div>
										<div className="selectText1" >Beginner</div>
									</div>
									<div className="selectHolder1" onClick={(e)=>{this.props.chooseLevel(e, 'Intermediate')}} >
										<div className="selectCircle1" >
											{this.props.state.selectedLevel == 'Intermediate' &&
												<div className="activeCircleCh" ></div>
											}
										</div>
										<div className="selectText1" >Intermediate</div>
									</div>
									<div className="selectHolder1" onClick={(e)=>{this.props.chooseLevel(e, 'Advanced')}} >
										<div className="selectCircle1" >
											{this.props.state.selectedLevel == 'Advanced' &&
												<div className="activeCircleCh" ></div>
											}
										</div>
										<div className="selectText1" >Advanced</div>
									</div>
									<div className="selectHolder1" onClick={(e)=>{this.props.chooseLevel(e, 'Multi-Level')}} >
										<div className="selectCircle1" >
											{this.props.state.selectedLevel == 'Multi-Level' &&
												<div className="activeCircleCh" ></div>
											}
										</div>
										<div className="selectText1" >Multi-Level</div>
									</div>
								</div>
							}
					</div>
					<div className="durationHolderFull" >
						<div className={classnames("durationHolder " + (this.props.state.activeFilter == 'Host' ? "activeDurationHolder " : "") + (this.props.state.selectedHostsArray.length > 0 ? "activeFilterBorder " : "") + ( this.props.state.activeFilter != 'Host' && this.props.state.selectedHostsArray.length == 0 ? "notActiveArrowFilter" : "" ) )} onClick={ (e)=>{this.checkFilters(e, 'Host')}}>
							<div className="absArrowFilter" >
								{this.props.state.activeFilter != 'Host' && this.props.state.selectedHostsArray.length == 0 && 
									<svg width="16" height="6" viewBox="0 0 16 6" fill='none' xmlns="http://www.w3.org/2000/svg">
										<path d="M15 1L8.03683 4.48159L1.07366 1" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								}
							</div>
							Instructor
							{this.props.state.selectedHostsArray.length > 0 &&
								<span className="spanCounter" >({this.props.state.selectedHostsArray.length})</span>
							}
						</div>
							{this.props.state.activeFilter == 'Host' && this.props.state.selectedHostsArray.length == 0 &&
								<div className="filterActivitySelected actFilterArrow" onClick={ (e)=>{this.checkFilters(e, 'Host')}} >
									<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 4.5L7.96317 1.01841L14.9263 4.5" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</div>
							}
							{this.props.state.selectedHostsArray.length > 0 &&
								<div className="filterActivitySelected" onClick={(e)=>{this.closeFilter(e, 'Host')}} >
									<svg width="14" height="14" viewBox="0 0 14 14" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
										<path d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z" />
									</svg>
								</div>
							}
							{this.props.state.activeFilter == 'Host' &&
								<div className="durationHolderAbs lvlWidth interestArrayHolder" onClick={(e)=>{e.stopPropagation()}}>
									<div className="intListHolderScroll" >
										{this.props.state.channelHost.map((item, i)=>
											<div className="selectHolder1" key={i} onClick={(e)=>{this.props.chooseHosts(e, item)}} >
												<div className="selectCircle1" >
													{this.props.state.selectedHostsArray.indexOf(item.userId) > -1 &&
														<div className="activeCircleCh" ></div>
													}
												</div>
												<div className="selectText1" >{item.username}</div>
											</div>
										)}
									</div>
								</div>	
							}
					</div>
					<div className="durationHolderFull" >	
						<div className={classnames("durationHolder eqTextShow " + (this.props.state.activeFilter == 'Equipment' ? "activeDurationHolder " : "") + (this.props.state.selectedEquipmentArray.length > 0 ? "activeFilterBorder " : "") +  ( this.props.state.activeFilter != 'Equipment' && this.props.state.selectedEquipmentArray.length == 0 ? "notActiveArrowFilter " : "" ) )} onClick={ (e)=>{this.checkFilters(e, 'Equipment')}} >
							<div className="absArrowFilter" >
								{this.props.state.activeFilter != 'Equipment' && this.props.state.selectedEquipmentArray.length == 0 && 
									<svg width="16" height="6" viewBox="0 0 16 6" fill='none' xmlns="http://www.w3.org/2000/svg">
										<path d="M15 1L8.03683 4.48159L1.07366 1" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								}
							</div>
							Equipment 
							{this.props.state.selectedEquipmentArray.length > 0 &&
								<span className="spanCounter" >({this.props.state.selectedEquipmentArray.length})</span>
							}
							</div>
							{this.props.state.activeFilter == 'Equipment' && this.props.state.selectedEquipmentArray.length == 0 &&
								<div className="filterActivitySelected actFilterArrow" onClick={ (e)=>{this.checkFilters(e, 'Equipment')}} >
									<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 4.5L7.96317 1.01841L14.9263 4.5" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</div>
							}
							{this.props.state.selectedEquipmentArray.length > 0 &&
								<div className="filterActivitySelected" onClick={(e)=>{this.closeFilter(e, 'Equipment')}} >
									<svg width="14" height="14" viewBox="0 0 14 14" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
										<path d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z" />
									</svg>
								</div>
							}
							{this.props.state.activeFilter == 'Equipment' &&
								<div className="durationHolderAbs absEquipmentHolder" onClick={(e)=>{e.stopPropagation()}}>
									
									<input className="equipmentInput" value={this.props.state.equipmentInput} id="equipmentInput"  onBlur={(e)=>{this.checkBlurInput(e)}} onKeyUp={(e)=>this.searchForEquipment(e)} onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value})} autoComplete="off" />
									<div className="inptHolderSvg" >
									{this.props.state && this.props.state.searchFont ?
										<svg version="1.1" fill={this.props.state.searchFont}  id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"> <g> <g> <path d="M447.615,64.386C406.095,22.866,350.892,0,292.175,0s-113.92,22.866-155.439,64.386 C95.217,105.905,72.35,161.108,72.35,219.824c0,53.683,19.124,104.421,54.132,144.458L4.399,486.366 c-5.864,5.864-5.864,15.371,0,21.236C7.331,510.533,11.174,512,15.016,512s7.686-1.466,10.617-4.399l122.084-122.083c40.037,35.007,90.775,54.132,144.458,54.132c58.718,0,113.919-22.866,155.439-64.386c41.519-41.519,64.385-96.722,64.385-155.439S489.134,105.905,447.615,64.386z M426.379,354.029c-74.001,74-194.406,74-268.407,0c-74-74-74-194.407,0-268.407c37.004-37.004,85.596-55.5,134.204-55.5c48.596,0,97.208,18.505,134.204,55.5C500.378,159.621,500.378,280.028,426.379,354.029z"/></g></g></svg>
										:
										<svg version="1.1"  fill="#b002ed" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"> <g> <g> <path d="M447.615,64.386C406.095,22.866,350.892,0,292.175,0s-113.92,22.866-155.439,64.386 C95.217,105.905,72.35,161.108,72.35,219.824c0,53.683,19.124,104.421,54.132,144.458L4.399,486.366 c-5.864,5.864-5.864,15.371,0,21.236C7.331,510.533,11.174,512,15.016,512s7.686-1.466,10.617-4.399l122.084-122.083c40.037,35.007,90.775,54.132,144.458,54.132c58.718,0,113.919-22.866,155.439-64.386c41.519-41.519,64.385-96.722,64.385-155.439S489.134,105.905,447.615,64.386z M426.379,354.029c-74.001,74-194.406,74-268.407,0c-74-74-74-194.407,0-268.407c37.004-37.004,85.596-55.5,134.204-55.5c48.596,0,97.208,18.505,134.204,55.5C500.378,159.621,500.378,280.028,426.379,354.029z"/></g></g></svg>
									}
									</div>
									<div className="equipmentArrayHolder" >
										<div className="eqScrollHolder" >	
											{this.props.state.selectedEquipmentArray && this.props.state.selectedEquipmentArray.map((item, i)=>
												<div className="eqPartHolder eqPart2nd" key={i}> 
													<div className="deleteEqButton" onClick={(e)=>{this.deleteEquipment(e, item)}} >
														<svg width="14" height="14" viewBox="0 0 14 14" fill={this.props.state.eqImgFillColor} xmlns="http://www.w3.org/2000/svg">
															<path d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z" />
														</svg>
													</div>
													{item}
												</div>
											)}
										</div>
										{this.props.state.selectedEquipmentArray.length > 0 &&
											<div className="eqBtnsHolder" >
												<div className="applyBtnEq" onClick={(e)=>{this.applyEquipmentSearch()}} >Apply</div>
												<div className="applyBtnEq resetBtn" onClick={(e)=>{this.resetEquipmentFilter(e)}} >Reset</div>
											</div>
										}
									</div>
								</div>
							}
					</div>
					{ ( this.props.state.backFilterEndChanged || this.props.state.backFilter > 0 || this.props.state.searchByName != '' || this.props.state.maxDuration > 0 || this.props.state.minDuration > 0 || this.props.state.selectedHostsArray.length > 0 || this.props.state.selectedInterests.length > 0 || this.props.state.selectedEquipmentArray.length > 0 || this.props.state.selectedLevel || this.props.state.showClearButton)&&
						<div className="clearFilterHolder" >	
							<div className="clearAllFilterBtn" onClick={(e)=>{this.props.clearFilter()}} >Clear all filters</div>
						</div>
					}
				</div>
				
		</div>
    );
  }
}
export default ChannelSearch;
