import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import { getOnDemandDuration } from '../../util/formatDateFunctions';
import ViewUsers from '../home/ViewUsers';

class OnDemandSessions extends Component {
  
  constructor(props) {
	super(props);
	this.state={
		months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		viewUsers: []
    }
	
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
	this.getOnDemandDuration = getOnDemandDuration.bind(this);
}
 
componentDidMount(){
	
}

getDate(date)
{
	let day = new Date(date).getDate();
	let month = this.state.months[new Date(date).getMonth()];
	let year = new Date(date).getFullYear();
	let finalDate = day + ' ' +  month + ', ' + year;
	return finalDate;
}

updateUserDemand=(id)=>{
	var onDemandId = id;
	var userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	var postData = {onDemandId: onDemandId, userId: userId};
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateUserDemand", postData).then(res => { }).catch(err =>{ });
}

openViews=(ev, item, i)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	if ( item.totalviews > 0 )
		{
			var mainDemandObjectVar = this.props.state.mainDemandObject;
			if ( !item.isOpenViews )
				{
					for ( var a = 0; a < mainDemandObjectVar.allDemandSession.length; a++ )
						{
							mainDemandObjectVar.allDemandSession[a].isOpenViews = false;
						}
					
					axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getDemandViews", {'id': item.id}).then(res => {
						var userList = res.data.responseData;
						for ( var q = 0; q < userList.length; q++ )
							{
								if ( !userList[q].image || userList[q].image == 'null' || ( userList[q].image !== '' && userList[q].image.indexOf('.s3') == -1 ) )
									{
										userList[q].image = '/images/host-user.png';
									}	
							}
						
						mainDemandObjectVar.allDemandSession[i].isOpenViews = true;
						this.setState({viewUsers: userList});
					}).catch(err =>{ });
				}
			else
				{
					mainDemandObjectVar.allDemandSession[i].isOpenViews = false;
				}
				
			this.props.updateStateFromChild({mainDemandObject: mainDemandObjectVar});
		}
}

  render() {
	 return (
       <div>
			{ this.props.state.demandPreview === 'square' && !this.props.state.gridDemandView &&
				<div className="all_demain_view">
					{ this.props.state.hasGridView &&
						<div className="for_demand_back">
							<div className="inline cursor" onClick={this.props.closeGridView}>
								<div className="back_img deny_grind">
									 <div>
										 <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
											<g>
												<g>
													<path d="M142.716,293.147l-94-107.602l94-107.602c7.596-8.705,6.71-21.924-1.995-29.527c-8.705-7.596-21.917-6.703-29.527,1.995    L5.169,171.782c-6.892,7.882-6.892,19.65,0,27.532l106.026,121.372c4.143,4.729,9.94,7.157,15.771,7.157    c4.883,0,9.786-1.702,13.755-5.169C149.427,315.071,150.319,301.852,142.716,293.147z" data-original="#000000" className="active-path" fill={this.props.state.buttonsColorPick}/>
												</g>
												<g>
													<path d="M359.93,164.619H20.926C9.368,164.619,0,173.986,0,185.545c0,11.558,9.368,20.926,20.926,20.926H359.93    c60.776,0,110.218,49.441,110.218,110.211S420.706,426.893,359.93,426.893H48.828c-11.558,0-20.926,9.368-20.926,20.926    c0,11.558,9.368,20.926,20.926,20.926H359.93c83.844,0,152.07-68.219,152.07-152.063S443.781,164.619,359.93,164.619z" data-original="#000000" className="active-path" fill={this.props.state.buttonsColorPick}/>
												</g>
											</g>
										</svg>
									</div>
								</div>
								<div className="back_text">Back</div>
							</div>
						</div>
					}
					{ this.props.state.mainDemandObject.allDemandSession.map((item, i) => 
						<div key={i} className={classnames("demand_preview_square", { 'hidden': item.isHidden || (item.processed == 0 && item.sessionId > 0 && !this.props.state.isDashboard )})} style={{backgroundImage: "url('" + item.image + "')"}}>
							<div className="all_inner_square"  onClick={ item.processed == 0 ? '' : ( this.props.state.isDashboard ? () => this.props.openNativeDemand(item) : () => { this.props.openDemandSession(item.id, item) } ) }>
								{ this.props.state.isDashboard &&
									<div>
										{ (( !this.props.state.isMarketplaceChannel && (item.hostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id) || this.props.state.isAdmin == 1 ) || ( this.props.state.isMarketplaceChannel && this.props.state.userPermissions.addOnDemad ) ) &&
											<div>
												<div className="abs_demand_icons delete_img new_titles_img" onClick={ (e) => { this.props.openDeleteDemand(e, item.id) }}></div>
												<div className="abs_demand_icons edit_h_w r60 new_titles_img new2img_edit edit_OD" onClick={ (e) => { this.props.openEditDemand(e, i) }}>EDIT</div> 
											</div>
										}
										<a onClick = { (e) => { this.props.openDownloadOnDemand(e, item) }} target="_blank" download>
											<div className={classnames("abs_demand_icons download_img new_titles_img new2Img", {'r10': ((!this.props.state.isMarketplaceChannel && item.hostId != JSON.parse(localStorage.getItem('userData')).data.responseData.id && this.props.state.isAdmin == 0) || (this.props.state.isMarketplaceChannel && !this.props.state.userPermissions.addOnDemad))})}></div>
										</a>
										{ item.processed == 0 && <div className="ondemandprocess">Video Processing...{ item.progressBarValue ? <div className="ODProgress"><div style={{width: item.progressBarValue+"%"}}></div></div> : '' }</div> }
										{ item.onDemandSessionLink && item.onDemandSessionLink !== '' && item.processed === 1 &&
											<div className="footer_square25 text-right onDemandLinkBtn_squre"><button  name={item.onDemandSessionLink} className="session_btn text-uppercase align-self-start session_btn_od" id='od_open' onClick={this.props.uneditableMode} >On Demand Link</button></div>
										}
									</div>
								}
								
								{ item.electronlink && this.props.state.isDashboard ?
									<a href="javascript:void(0)" onClick={ item.processed == 0 ? () => {} : () =>  {this.props.openNativeDemand(item); }}><div className="sess_interest text_wrap_demand new_titles_width" title={item.interest_titles} >{item.interest_titles}</div>
										{!this.props.state.isMarketplaceChannel &&
										<div className="relative_level">
											<div className="sess_interest inline">{item.level}</div>
											{ item.level === 'Beginner' &&
												<div className="level_square green_square"></div>
											}
											{ item.level === 'Intermediate' &&
												<div className="level_square yellow_square"></div>
											}
											{ item.level === 'Advanced' &&
												<div className="level_square red_square"></div>
											}
											{ item.level === 'Multi-Level' &&
												<div className="level_square multi_level_square"></div>
											}
										</div>
										}
									{item.channelPublish == 1 && this.props.state.isDashboard &&
										<div className="sess_interest published_interest">Published on Channel Page</div>
									}
									</a>
									:
									<a onClick={() => this.props.state.isDashboard ? this.props.openNativeDemand(item) : {}}><div className="sess_interest text_wrap_demand new_titles_width" title={item.interest_titles}>{item.interest_titles}</div>
										{!this.props.state.isMarketplaceChannel &&
											<div className="relative_level">
												<div className="sess_interest inline">{item.level}</div>
												{ item.level === 'Beginner' &&
													<div className="level_square green_square"></div>
												}
												{ item.level === 'Intermediate' &&
													<div className="level_square yellow_square"></div>
												}
												{ item.level === 'Advanced' &&
													<div className="level_square red_square"></div>
												}
												{ item.level === 'Multi-Level' &&
													<div className="level_square multi_level_square"></div>
												}
											</div>
										}
									</a>
								}
								
								
								<div className="footer_square">
									<div className="footer_pad">
										<div className="footer_square50 main_color_part">{item.name}</div>
										<div className="footer_square25 main_color_part shWord">{item.firstName} {item.lastName}
											{ !this.props.state.isDashboard &&
												<div className="new_info_btn" onClick={ this.props.state.isDashboard ? () => {} : (e) => { this.props.openProfileInfo(item, 'onDemand', e) }}></div>
											}
										</div>
										<div className="footer_square25 relative pl25 main_color_part">{this.getOnDemandDuration(item.duration)}
											<div className="icon_foot_part_l clock_img"></div>
										</div>
									</div>
									{ item.description.length <= 50 &&
										<div className="footer_pad">
											<div className="relative pl25 pr25 description_demand">{item.description}
												<div className="icon_foot_part_l list_img"></div>
											</div>
										</div>
									}
									{ item.description.length > 50 &&
										<div className="footer_pad">
											<div className="relative pl25 pr25 description_demand"><span className="dots_text">{item.description}</span>
												<div className="icon_foot_part_l list_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.descriptionDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'descriptionDropOpen') }}></div>
												{ item.descriptionDropOpen &&
													<div className="abs_demand_drop" onClick={this.props.preventDef}>{item.description}</div>
												}
											</div>
										</div>
									}
									<div className="footer_pad">
										{ item.equipment.length <= 50 && item.equipment !== '' &&
											<div className="footer_square50 footer_50_first description_demand relative pl25 pr25 equipmentdemand">{item.equipment}
												<div className="icon_foot_part_l wrench_img"></div>
											</div>
										}
										{ item.equipment.length > 50 && item.equipment !== '' &&
											<div className="footer_square50 footer_50_first description_demand relative pl25 pr25"><span className="equipmentdemand dots_text">{item.equipment}</span>
												<div className="icon_foot_part_l wrench_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.equipmentDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'equipmentDropOpen') }}></div>
												{ item.equipmentDropOpen &&
													<div className="abs_demand_drop"  onClick={this.props.preventDef}>{item.equipment}</div>
												}
											</div>
										}
										{ item.goal.length <= 15 && item.goal !== '' &&
											<div className="footer_square50 description_demand relative pl25 pr25">{item.goal}
												<div className="icon_foot_part_l target_img"></div>
											</div>
										}
										{ item.goal.length > 15 && item.goal !== '' &&
											<div className="footer_square50 description_demand relative pl25 pr25"><span className="dots_text">{item.goal}</span>
												<div className="icon_foot_part_l target_img"></div> 
												<div className={"icon_foot_part_r plus_main " + (item.goalDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'goalDropOpen') }}></div>
												{ item.goalDropOpen &&
													<div className="abs_demand_drop"  onClick={this.props.preventDef}>{item.goal}</div>
												}
											</div>
										}
									</div>
									<div className="footer_pad">
										<div className="footer_square33 description_demand relative pl25 add_padd_dash">{ this.getSessionDate(item.createdAt)} @ {this.getSessionTime(item.createdAt)}
											<div className="icon_foot_part_l record_img"></div>
										</div>
										{ /* <div className="footer_square25 description_demand">Member ${item.memberCharges}</div>
										<div className="footer_square25 description_demand">Non-Member ${item.nonMemberCharges}</div> */ }
										<div className="footer_square50 description_demand relative pl25">{item.totalviews ? item.totalviews : 0}
											<div className="icon_foot_part_l view_img" onClick={ this.props.state.isDashboard ? (e) => this.openViews(e, item, i) : () => {}}></div>
											{ item.isOpenViews &&
												<ViewUsers usersArray={this.state.viewUsers}></ViewUsers>
											}
										</div>
										{ item.availableUntil !== '' && !this.props.state.isDashboard &&
											<div className="footer_square33 description_demand relative pl25">{item.availableUntil}
												<div className="icon_foot_part_l deletecan_img"></div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					)}
					
					<div>
						{this.props.state.mainDemandObject.allDemandSession.length == 0 &&
							<div className="channel_no_results">No results found</div>
						}
					</div>
				</div>
			}
			{ this.props.state.demandPreview === 'list' && !this.props.state.gridDemandView &&
				<div className="all_demain_view">
					{ this.props.state.hasGridView &&
						<div className="for_demand_back">
							<div className="inline cursor" onClick={this.props.closeGridView}>
								<div className="back_img deny_grind">
									 <div>
										 <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
											<g>
												<g>
													<path d="M142.716,293.147l-94-107.602l94-107.602c7.596-8.705,6.71-21.924-1.995-29.527c-8.705-7.596-21.917-6.703-29.527,1.995    L5.169,171.782c-6.892,7.882-6.892,19.65,0,27.532l106.026,121.372c4.143,4.729,9.94,7.157,15.771,7.157    c4.883,0,9.786-1.702,13.755-5.169C149.427,315.071,150.319,301.852,142.716,293.147z" data-original="#000000" className="active-path" fill={this.props.state.buttonsColorPick}/>
												</g>
												<g>
													<path d="M359.93,164.619H20.926C9.368,164.619,0,173.986,0,185.545c0,11.558,9.368,20.926,20.926,20.926H359.93    c60.776,0,110.218,49.441,110.218,110.211S420.706,426.893,359.93,426.893H48.828c-11.558,0-20.926,9.368-20.926,20.926    c0,11.558,9.368,20.926,20.926,20.926H359.93c83.844,0,152.07-68.219,152.07-152.063S443.781,164.619,359.93,164.619z" data-original="#000000" className="active-path" fill={this.props.state.buttonsColorPick}/>
												</g>
											</g>
										</svg>
									</div>
								</div>
								<div className="back_text">Back</div>
							</div>
						</div>
					}
					{ this.props.state.mainDemandObject.allDemandSession.map((item, i) => 
						<div className={classnames("one_list_demand", { 'hidden': item.isHidden || (item.processed == 0 && item.sessionId > 0 && !this.props.state.isDashboard )})}	key={i}>
							<div className="list_header">
								<div className="sess_interest inline_interest text_wrap_demand new_titles_width" title={item.interest_titles}>{item.interest_titles}</div>
								{!this.props.state.isMarketplaceChannel &&
									<span>
										<div className="sess_interest inline">{item.level}</div>
										{ item.level === 'Beginner' &&
											<div className="level_square green_square"></div>
										}
										{ item.level === 'Intermediate' &&
											<div className="level_square yellow_square"></div>
										}
										{ item.level === 'Advanced' &&
											<div className="level_square red_square"></div>
										}
										{ item.level === 'Multi-Level' &&
											<div className="level_square multi_level_square"></div>
										}
									</span>
								}
								{item.channelPublish == 1 && this.props.state.isDashboard && item.processed == 1 &&
									<div className="sess_interest inline published_list">Published on Channel Page</div>
								}
							</div>
							<div className="relative_list_info less_left_info">
							{ item.electronlink && this.props.state.isDashboard ?
								<div>
									{item.processed === 1  ?
										<a href="javascript:void(0)" onClick={() => { this.props.openNativeDemand(item); }}><div className="abs_demand_image" style={{backgroundImage: "url('" + item.image + "')"}} ></div></a>
										:	
										<a href="javascript:void(0)"><div className="abs_demand_image" style={{backgroundImage: "url('" + item.image + "')"}} > <div className="ond_process_list">Video Processing...{ item.progressBarValue ? <div className="ODProgress"><div style={{width: item.progressBarValue+"%"}}></div></div> : '' }</div> </div></a>
									}
									
								</div>
								:
								<a><div className="abs_demand_image" style={{backgroundImage: "url('" + item.image + "')"}} onClick={ this.props.state.isDashboard ? () => { this.props.openNativeDemand(item) } : () => { this.props.openDemandSession(item.id, item) }}></div></a>
							}
								<div className="footer_pad">
									<div className="footer_square25 main_color_part">{item.name}</div>
									<div className="footer_square25 main_color_part breakWord">{item.firstName} {item.lastName}
										{ !this.props.state.isDashboard &&
											<div className="new_info_btn" onClick={ this.props.state.isDashboard ? () => {} : (e) => { this.props.openProfileInfo(item, 'onDemand', e) }}></div>
										}
									</div>
									<div className="footer_square25 relative pl25 main_color_part">{this.getOnDemandDuration(item.duration)}
										<div className="icon_foot_part_l clock_img"></div>
									</div>
								</div>
								<div className="footer_pad">
									<div className="footer_square50">
										{ item.description.length <= 20 &&
											<div className="relative pl25 pr25 description_demand">{item.description}
												<div className="icon_foot_part_l list_img"></div>
											</div>
										}
										{ item.description.length > 20 &&
											<div className="relative pl25 pr25 description_demand"><span className="dots_text">{item.description}</span>
												<div className="icon_foot_part_l list_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.descriptionDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'descriptionDropOpen') }}></div>
												{ item.descriptionDropOpen &&
													<div className="abs_demand_drop" onClick={this.props.preventDef}>{item.description}</div>
												}
											</div>
										}
									</div>
									<div className="footer_square25">
										{ item.equipment.length <= 15 && item.equipment !== '' &&
											<div className="description_demand relative pl25 pr25">{item.equipment}
												<div className="icon_foot_part_l wrench_img"></div>
											</div>
										}
										{ item.equipment.length > 15 && item.equipment !== '' &&
											<div className="description_demand relative pl25 pr25"><span className="dots_text">{item.equipment}</span>
												<div className="icon_foot_part_l wrench_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.equipmentDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'equipmentDropOpen') }}></div>
												{ item.equipmentDropOpen &&
													<div className="abs_demand_drop" onClick={this.props.preventDef}>{item.equipment}</div>
												}
											</div>
										}
									</div>
									<div className="footer_square25">
										{ item.goal.length <= 20 && item.goal !== '' &&
											<div className="description_demand relative pl25 pr25">{item.goal}
												<div className="icon_foot_part_l target_img"></div>
											</div>
										}
										{ item.goal.length > 20 && item.goal !== '' &&
											<div className="description_demand relative pl25 pr25"><span className="dots_text">{item.goal}</span>
												<div className="icon_foot_part_l target_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.goalDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'goalDropOpen') }}></div>
												{ item.goalDropOpen &&
													<div className="abs_demand_drop" onClick={this.props.preventDef}>{item.goal}</div>
												}
											</div>
										}
									</div>
								</div>
								<div className="footer_pad">
									<div className="footer_square33 description_demand relative pl25">{ this.getSessionDate(item.createdAt)} @ {this.getSessionTime(item.createdAt)}
										<div className="icon_foot_part_l record_img"></div>
									</div>
									{ /* <div className="footer_square25 description_demand">Member ${item.memberCharges}</div>
									<div className="footer_square25 description_demand">Non-Member ${item.nonMemberCharges}</div> */ }
									<div className="footer_square25 description_demand relative pl25">{item.totalviews ? item.totalviews : 0}
										<div className="icon_foot_part_l view_img" onClick={ this.props.state.isDashboard ? (e) => this.openViews(e, item, i) : () => {}}></div>
										{ item.isOpenViews &&
											<ViewUsers usersArray={this.state.viewUsers}></ViewUsers>
										}
									</div>
									{ item.availableUntil !== '' && !this.props.state.isDashboard &&
										<div className="footer_square33 description_demand relative pl25">{item.availableUntil}
											<div className="icon_foot_part_l deletecan_img"></div>
										</div>
									}	
								</div>
								{ this.props.state.isDashboard && item.onDemandSessionLink && item.onDemandSessionLink !== '' && item.processed === 1 &&
									<div className="footer_square25 text-right onDemandLinkBtn"><button  name={item.onDemandSessionLink} className="session_btn text-uppercase align-self-start session_btn_od line_session_btn" id='od_open' onClick={this.props.uneditableMode} >On Demand Link</button></div>
								}
								{ this.props.state.isDashboard &&
									<div>
										{ this.props.state.isDashboard && <a href={item.video} target="_blank" download><div className="abs_icons_list download_img less_top"></div></a> }
										{ (( !this.props.state.isMarketplaceChannel && (item.hostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id) || this.props.state.isAdmin == 1 ) || ( this.props.state.isMarketplaceChannel &&  this.props.state.userPermissions.addOnDemad ) ) &&
											<div>
												<div className="abs_icons_list edit_OD new_editOd_padd less_padd_edit" onClick={ (e) => { this.props.openEditDemand(e, i) }}>EDIT</div>
												<div className="abs_icons_list delete_img t60" onClick={ (e) => { this.props.openDeleteDemand(e, item.id) }}></div>
											</div>
										}
									</div>
								}
							</div>
						</div>
					)}
					<div>
						{this.props.state.mainDemandObject.allDemandSession.length == 0 &&
							<div className="channel_no_results">No results found</div>
						}
					</div>
				</div>
			}
			{ this.props.state.gridDemandView &&
				<div className="all_demain_view">
					{ this.props.state.mainDemandObject.allInterestsMain.map((item, i) => 
						<div key={i} className="demand_preview_square" style={{backgroundImage: "url(" + item.image + ")"}}>
							<div className="all_inner_square">
								<div className="grid_title" onClick={ () => { this.props.openGridView(item.title) }}>{item.title} ({this.props.state.mainDemandObject.allDemandSessionsByInterest[item.title].length})</div>
							</div>
						</div>
					)}
				</div>
			}
		</div>
    );
  }
}
export default OnDemandSessions;
