import React, { Component } from "react";
import classnames from "classnames";
import { browserHistory } from 'react-router';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import $ from 'jquery';
import axios from "axios";

class MemberAbout extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
		genderList: ['Male', 'Female', 'Non-Binary', 'Prefer not to disclose'],
		memberHeightFeet: '',
		memberHeightInches: '',
		memberWeightPounds: '',
		memberDay: '',
		memberMonth: '',
		memberYear: '',
		memberGender: 'Prefer not to disclose',
    }
	
}
 
componentDidMount(){
	
}

goToLogin=()=>{
	localStorage.setItem("registeredEmail", this.props.state.emailInput);
	localStorage.setItem("registeredPassword", this.props.state.passwordInput);
	browserHistory.push('/login#MemberSignUp');
}

updateInfo=()=>{
	if ( this.state.memberHeightFeet == '' || parseInt(this.state.memberHeightFeet) < 0 || isNaN(parseInt(this.state.memberHeightFeet)) )
		{
			this.props.showMainErrorPop("Please enter valid height feet");
			return false;
		}
		
	var feetCentimeters = parseInt(this.state.memberHeightFeet) * 30.48;
	var inchCentimeters = parseInt(this.state.memberHeightInches) * 2.54;
	var finalHeightCentimeters = feetCentimeters + inchCentimeters;	
	
	if ( finalHeightCentimeters > 250 )
		{
			this.props.showMainErrorPop("Please enter valid height");
			return false;
		}
		
	if ( this.state.memberHeightInches == '' || parseInt(this.state.memberHeightInches) < 0 || isNaN(parseInt(this.state.memberHeightInches)) )
		{
			this.props.showMainErrorPop("Please enter valid height inches");
			return false;
		}
		
	if ( this.state.memberWeightPounds == '' || parseInt(this.state.memberWeightPounds) < 0 || isNaN(parseInt(this.state.memberWeightPounds)) )
		{
			this.props.showMainErrorPop("Please enter valid weight pounds");
			return false;
		}

	var weightKg = parseInt(this.state.memberWeightPounds) * 0.45359237;
	
	if ( weightKg > 200 )
		{
			this.props.showMainErrorPop("Please enter valid weight");
			return false;
		}
	
	var dob = this.state.memberYear+'-'+this.state.memberMonth+'-'+this.state.memberDay;
	
	if ( this.state.memberYear.length < 4 )
		{
			this.props.showMainErrorPop("Please enter valid year");
			return false;
		}
	
	if ( new Date(dob) == 'Invalid Date' )
		{
			this.props.showMainErrorPop("Please enter valid date of birth");
			return false;
		}
		
	if ( new Date(dob).getTime() > new Date().getTime() ) 
		{
			this.props.showMainErrorPop("You can't select date in future for birthday. Please enter valid date of birth");
			return false;
		}


	var postData = {
		id: this.props.state.userRegisterId,
		heightFeet: this.state.memberHeightFeet,
		heightInches: this.state.memberHeightInches,
		weightPounds: this.state.memberWeightPounds,
		birthday: dob,
		gender: this.state.memberGender,
		ages: new Date().getFullYear() - parseInt(this.state.memberYear),
	};	
	
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/insertMemberInfo", postData).then(res => {
		let mainthis = this;
		setTimeout(function(){
			$('.main_loader').attr({'style':'display:none'});
			mainthis.props.goToNextStep(7);
			//mainthis.goToLogin();
		}, 500);
	}).catch(err =>{
		console.log('----------there is problem------------',err);
	});	
		
		
}

setNewDay=(mod)=>{
	if ( mod.memberDay > 31 )
		{
			return false;
		}
	else if ( mod.memberDay == '00' )
		{
			return false;
		}		
	this.setState(mod);
}

setNewMonth=(mod)=>{
	if ( mod.memberMonth > 12 )
		{
			return false;
		}
	else if ( mod.memberMonth == '00' )
		{
			return false;
		}		
	this.setState(mod);
}

setNewYear=(mod)=>{
	
	if ( mod.memberYear.length > 4 )
		{
			return false;
		}
	this.setState(mod);
}

  render() {
	 return (
		<div className="memberRegisterHolder more_width_welcome">
			<div className="skip_holder chaPos">
				<div className="exit_screen pointerNext" onClick={this.goToLogin}>SKIP</div>
			</div>
			<div className="sign_title">About you</div>
			<div className="sign_title reg_message">Tell us a few more things to help us improve your Virdio experience</div>
			<div className="all_info_container">
				<div className="about_inline_div">
					<div className="saved_info_title">Height</div>
					<input type="tel" className="fitness_input" id="memberHeightFeet" maxLength="1" value={this.state.memberHeightFeet} onChange={(e) => this.setState({[e.target.id]: e.target.value})} />
					<span className="spanFt">ft</span>
					<input type="tel" className="fitness_input" id="memberHeightInches" maxLength="2" value={this.state.memberHeightInches} onChange={(e) => this.setState({[e.target.id]: e.target.value})}/>
					<span className="spanFt">in</span>
				</div>
				<div className="about_inline_div">
					<div className="saved_info_title">Weight</div>
					<input type="tel" className="fitness_input weight_input" id="memberWeightPounds" maxLength="3" value={this.state.memberWeightPounds} onChange={(e) => this.setState({[e.target.id]: e.target.value})}/>
					<span className="spanFt">lbs</span>
				</div>
				<div className="about_inline_div">
					<div className="saved_info_title">Gender</div>
					<select className="fitness_input full_input select_back" id="memberGender" value={this.state.memberGender} onChange={(e) => this.setState({[e.target.id]: e.target.value})}>
						{ this.state.genderList.map((def, i)=> 
							<option value={def} key={i} style={{color:'#000'}} >{def}</option>
						)}
					</select>
				</div>
			</div>
			<div className="all_info_container dateContainer">
				<div className="saved_info_title">Date of Birth</div>
				<div className="yyyy_container">
					<div className="number_input_title">MM</div>
					<input type="number" className="fitness_input dates_width" maxLength="2" id="memberMonth" maxLength="2" 
					value={this.state.memberMonth} onChange={(e) => this.setNewMonth({[e.target.id]: e.target.value})}
					/>	
				</div>
				<div className="yyyy_container">
					<div className="number_input_title">DD</div>
					<input type="number" className="fitness_input dates_width" maxLength="2" 
					id="memberDay" maxLength="2" value={this.state.memberDay} 
					onChange={(e) => this.setNewDay({[e.target.id]: e.target.value})}
					/>
				</div>
				<div className="yyyy_container">
					<div className="number_input_title">YYYY</div>
					<input type="number" className="fitness_input year_width" maxLength="4" id="memberYear" maxLength="4" 
					value={this.state.memberYear} 
					onChange={(e) => this.setNewYear({[e.target.id]: e.target.value})}
					/>
				</div>
			</div>
			<div className="bottom_arrow">
				<div className={classnames({'inline_event': true})} onClick={this.updateInfo}>
					<div className="bottom_title_small">CONTINUE</div>
					<div className="arrow_next"></div>
				</div>
			</div>
		</div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  },
  null,
  {forwardRef: true}
)(MemberAbout);