import axios from "axios";
import {  browserHistory} from 'react-router'

const setCookie = function(name, value, exdays)
	{
		var date = new Date();
		date.setTime(exdays);
		var expires = "expires="+ date.toUTCString();
		document.cookie = name + "=" + value + ";" + expires + ";path=/;domain=virdio.com";
	}

const setRegisterCookie = function(name, value, exdays)
	{
		var date = new Date();
		date.setTime(exdays);
		var expires = "expires="+ date.toUTCString();
		document.cookie = name + "=" + value + ";" + expires + ";path=/;domain=virdio.com";
	}

const getCookie = function(cookieName) 
	{
		var name = cookieName + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for( var i = 0; i <ca.length; i++ ) 
			{
				var c = ca[i];
				while (c.charAt(0) == ' ')
					{
						c = c.substring(1);
					}
				if (c.indexOf(name) == 0) 
					{
						return c.substring(name.length, c.length);
					}
			}
		return "";
	}

const getRegisterCookie = function(cookieName) 
	{
		console.log('-----cookieName------', cookieName);
		var name = cookieName + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for( var i = 0; i <ca.length; i++ ) 
			{
				var c = ca[i];
				while (c.charAt(0) == ' ')
					{
						c = c.substring(1);
					}
				if (c.indexOf(name) == 0) 
					{
						return c.substring(name.length, c.length);
					}
			}
		return "";
	}
	
const deleteRegisterCookie = function(name)
	{
		var date = new Date();
		date.setTime(-1);
		var expires = "expires="+ date.toUTCString();
		document.cookie = name + "=" + '' + ";" + expires + ";path=/;domain=virdio.com";
	}	
	
const checkSubdomainCookie = function()
	{
		let userId = getCookie(process.env.REACT_APP_COOKIE_ID);
		if ( !userId && window['user_loggedIn'] )
			{
				window['user_loggedIn'] = 0;
				localStorage.removeItem('userData');
				//window.location.reload();
				//window.location.href = '/login';
				var adminpath = process.env.REACT_APP_PATH+'/login';
				window.location.href = adminpath;
				return false;
			}
		
		if ( userId && localStorage.getItem('userData') )
			{
				var newId = JSON.parse(localStorage.getItem('userData')).data.responseData.id
				
				var tokenExpire = JSON.parse(localStorage.getItem('userData')).data.responseData.tokenExpire
				
				if ( tokenExpire && tokenExpire < (new Date()).getTime() )
					{
						window['user_loggedIn'] = 0;
						localStorage.removeItem('userData');
					}
				else if ( window['lastUserId'] == 0 && window['user_loggedIn'] )
					{
						window['lastUserId'] = newId;
					}
				else if ( window['lastUserId'] == 0 && !window['user_loggedIn'] )
					{
						window.location.reload();
					}
				else if ( newId && window['lastUserId'] != newId )
					{
						window.location.href = '/';
					}
				else if ( newId && userId != newId )
					{
						window.location.reload();
					}
				
				return false;
			}
		
		if ( userId && !window['user_loggedIn'] )
			{
				var currentLocation = window.location.href.split('/');
				let lastNum = currentLocation.length;
				lastNum = parseInt(lastNum) - 1;
				var currentLocationValue = currentLocation[lastNum];			
				if ( currentLocationValue != 'MemberRegister' )
					{
						window.location.reload();
					}
			}
	}

var userIdCookie = getCookie(process.env.REACT_APP_COOKIE_ID);
window['user_loggedIn'] = ( localStorage.getItem('userData') && userIdCookie ) ? 1 : 0;
window['lastUserId'] = 0;

setInterval( function(){ checkSubdomainCookie(); }, 3000);

const checkCookie = function()
	{
		var appLink = window.location;
		var user_loged;
		var user_token;
		var hashCheck = false;
		
		if ( appLink.hash.indexOf('#') > -1 )
			{
				var splitString = appLink.hash.split(';');
				
				if ( ( splitString.length == 3 || splitString.length == 4 ) && splitString[0] == '#autologin' )
					{
						user_loged = splitString[2];
						user_token = splitString[1];
						hashCheck = true;
					}
			}
		
		if ( user_loged == undefined )
			{
				user_loged = getCookie(process.env.REACT_APP_COOKIE_ID);
				user_token = getCookie(process.env.REACT_APP_COOKIE_TOKEN);
			}
		
		var oldId = JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).data && JSON.parse(localStorage.getItem('userData')).data.responseData  && JSON.parse(localStorage.getItem('userData')).data.responseData.id ? JSON.parse(localStorage.getItem('userData')).data.responseData.id : 0;
		
		if ( (user_loged && oldId != user_loged) || (user_loged && oldId == user_loged && hashCheck) )
			{
				checkUserLogin(user_loged, user_token, hashCheck);
				return true;
			}
		else if ( hashCheck )
			{
				var oldLocation = window.location.href.split('#');
				window.location = oldLocation[0];
			}
		
		/*else
			{
				setCookie(process.env.REACT_APP_COOKIE_ID, '', -1);
				setCookie(process.env.REACT_APP_COOKIE_TOKEN, '', -1);

				//if ( localStorage.getItem('userData') == null )
					//{
						//logout user
						//localStorage.removeItem('userData');
						//localStorage.removeItem('channelId');
						//localStorage.removeItem('groupId');
						//localStorage.removeItem('channelInterest');
					//	localStorage.removeItem('completedDefaultSession');
						//browserHistory.push("/")
						//logout user
					//} 
			}*/
		
		return false;
	}

const checkUserLogin = function(id, token, hash){
	
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserLogin", {userId: id, auth: token})
	.then(res => {
		if(res.data.responseMessage == "success" && res.data.responseData && res.data.responseData.id )
			{
				localStorage.setItem("userData", JSON.stringify(res));
				window['user_loggedIn'] = 1;
				window['lastUserId'] = 0;
				setCookie(process.env.REACT_APP_COOKIE_ID, res.data.responseData.id, res.data.responseData.tokenExpire);
				setCookie(process.env.REACT_APP_COOKIE_TOKEN, res.data.responseData.token, res.data.responseData.tokenExpire);
			}
		else
			{
				setCookie(process.env.REACT_APP_COOKIE_ID, '', -1);
				setCookie(process.env.REACT_APP_COOKIE_TOKEN, '', -1);
			}
		
		if ( hash )
			{
				var oldLocation = window.location.href.split('#');
				window.location = oldLocation[0];
			}
		else
			{
				window.location.reload();
			}
		}).catch(err =>{
			
			setCookie(process.env.REACT_APP_COOKIE_ID, '', -1);
			setCookie(process.env.REACT_APP_COOKIE_TOKEN, '', -1);
			if ( hash )
				{
					var oldLocation = window.location.href.split('#');
					window.location = oldLocation[0];
				}
			else
				{
					window.location.reload();
				}
	});
}

export { setCookie, checkCookie, getCookie, getRegisterCookie, setRegisterCookie, deleteRegisterCookie};