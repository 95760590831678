import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {  browserHistory} from 'react-router'
import Countdown from 'react-countdown-now';
import { Link } from 'react-router';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import $ from 'jquery';
import ParticipantDashboardUpcomingSessions from '../home/ParticipantDashboardUpcomingSessions';
import OnDemandSessionsParticipant from '../home/OnDemandSessionsParticipant';
import PastSessionsParticipant from '../home/PastSessionsParticipant';
import MyChannelsParticipant from '../home/MyChannelsParticipant';
import ActivityList from '../home/activityList';
import classnames from "classnames";
import customProtocolCheck from "custom-protocol-check";
import moment from 'moment';
import { deleteCookie } from '../auth/Logout';
import { joinSessionValidate } from '../../util/joinSessionValidate';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";

import { updatesse } from '../../util/updatesse';
import MainTutorial from '../home/MainTutorial';
import SupportContainer from '../home/SupportContainer';
import ParticipantSignedUp from '../home/participantSignedUp';

class ParticipantDashboard extends Component {
  
  constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state={
		
		pagePreview: 'square',
		startDate:new Date(),
		daysOfMonth:[],
		upcomingSession:[],
		upcomingSessionArray:[],
		week:'Week 11',
		upComingScheduleDate:'',
		loginName:'',
		loginLastName: '',
		sessionData:[11, 12, 13],
		pastSessionData:[],
		lastestSessionData:'',
		latestUpComingSession:'',
		indiviualSessionData:'',
		totalSession:'',
		sessionType:'Upcoming',
		lastUpcomingDate:'',
		dateForUpcomingSession:[],
		totalUpcomingSession:'',//used for storing current week totalSession 
		sessionData2:[],
		responseMessage:'',
		sessionCurrentWeek:[],
		updateForSession:'',
		cancelSessionId:'',
		cancelSessionDate:'',
		dateOfNextSession:'',
		currentWeekData:[],
		urlPath:'',
		urlElectron:'',
		appDownload:'',
		platformStatus:true,
		chosenTutorialIndex: 0,
		showTutorial: false,
		userAvatar: '',
		showWindowsApp: true,
		deepLinkButton: 'Web App',
		canc_pop_status : false,
		defaultJoinTime: 15,
		onDemandSessions: [],
		sessionPreview: 'square',
		demandSessionId: 0,
		ondemandlink: '',
		electronlink: '',
		show_modal: false,
		showloaderfromnative: false,
		expandSearchStatus: false,
		hostsArray: [],
		applySort: false,
		count: 1,
		onDemandFound: 0,
		onDemandShown: 0,
		channelsData: [],
		countUpcoming: 1,
		upcShow: 0,
		upcDataLength: 0,
		sessionActivity: [],
		individualSession: {"activityArray": []},
		months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		pickedStartDate: '',
		pickedEndDate: '',
		searchSessionName: '',
		filterLevel: '',
		filterDuration: '',
		filterEquipment: '',
		fullStartDate: '',
		fullEndDate: '',
		foundSessions: [],
		startWeekDay: '',
		endWeekDay: '',
		nextWeekCount: 0,
		currentDayChoosen: 0,
		pastSessionCount: 1,
		pastSessionsCounter: 0,
		currentShownPast: 0,
		nextSession: [],
		viewStatus : false,
		calendarStartTime: 0,
		calendarCurrentTime: 0,
		onDemandCounter: 0,
		upcomingCounter: 0,
		channelsCounter: 0,
		isHostDashboard: 0,
		isAdmin: 0,
		supportPageName: 'Dashboard',
		supportPageApi: '/participant-dashboard',
		userTypeFaqs: 1,
		userSignedUp: false,
		loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
		sessionStartTimeHigher15Min: false,
		sessionScheduleDate: '',
		sessionTimezone: '',
		sessionChannelName: '',
		isHost: false,
		sessionName: '',
		isOnDemandSession: false,
		firstTimeLoading: true,
		cancelReccuringId: 0
	}
		
	this.deleteCookie = deleteCookie.bind(this);	
	this.updatesse = updatesse.bind(this);
	this.joinSessionValidate = joinSessionValidate.bind(this);
}
 

componentDidMount() {
	
	this.checkAllOnDemand();
	this.checkViewType();
	this.checkChannels();
	this.checkUpcomingSessions(this.state.startDate, 'thisWeek');
	this.fullLoad();
	
	var startOfWeek = moment().startOf('isoweek').toDate(); 
	var endOfWeek   = moment().endOf('isoweek').toDate();
	
	let startCalendar = new Date (startOfWeek).getTime() / 1000;
	this.setState({
		calendarStartTime: startCalendar
	});
	
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

	this.setState({
		week:'Week of ' + monthArray[new Date(startOfWeek).getMonth()] +' '+ new Date(startOfWeek).getDate()
	});
	
	this.setState({
		loginName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName,
		loginLastName: JSON.parse(localStorage.getItem('userData')).data.responseData.lastName,
		userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image,
		startWeekDay: startOfWeek,
		endWeekDay: endOfWeek,
		//week:'Week of ' + startOfWeek
		})
			
	let curr = new Date 
	let week = []

	for (let i = 1; i <= 7; i++) {
	  let first = curr.getDate() - curr.getDay() + i 
	  let day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
	  //week.push(day)
	  week.push({
			name:"No Data",
			hostFirstName:'',
			hostLastName:'',
			chargeForSession:0,
			scheduleDate: day
		})
	}
	this.setState({
		newCurrentWeek : week,
	}, () => {console.log(this.state.newCurrentWeek);})
	this.pastSessionInfo();
	
	let checkStatus = JSON.parse(localStorage.getItem('userData')).data.responseData.dashTutorial;
	if ( checkStatus == 0 )
		{
			this.checkTutorial();
		}
		
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/changeUserType", {'userId': JSON.parse(localStorage.getItem('userData')).data.responseData.id, 'type': 2}).then(res => {
		var userData = JSON.parse(localStorage.getItem('userData'));
		userData.data.responseData.type = 2;
		localStorage.setItem('userData', JSON.stringify(userData));
	}).catch(err =>{ });
}

fullLoad = e => 
{
	let token1 = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	let loginId1 = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	if(osname=="Windows" || osname=="Linux" || osname=="Ubuntu" || osname=="Mac OS"){
		if((ff==true) && (parseFloat(fbrowserversion) < 72)){
			this.setState({
				platformStatus:false
			})
		}
		  if((chrome==true) && (parseFloat(fbrowserversion) < 79)){
			this.setState({
				platformStatus:false
			})
		  }
		  if((edge==true) && (parseFloat(fbrowserversion) < 78)){
			this.setState({
				platformStatus:false
			})
		  }
	  }
	
	var checkSessionValidate = this.joinSessionValidate(false, loginId1, token1, null);
	this.setState({ 
			urlPath: checkSessionValidate.urlPathVar,
			urlElectron: checkSessionValidate.urlElectronVar,
			deepLinkButton: checkSessionValidate.deepLinkButton
	});
	
	

}

checkTutorial = e=>{
	let postdata = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.id };
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkDashboardTutorial", postdata)
    .then(res => {
		if ( res.data.responseData.dashTutorial === 0 )
			{
				this.setState({
					showTutorial: true,
					sessionType: 'Upcoming'
				});
				$('body').attr({'style':'overflow: hidden'});
			}
	}).catch(err =>{ });
}

skipTutorial=()=>{
	let postdata = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.id };
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateDashboardTutorial", postdata)
    .then(res => {
		this.setState({
			showTutorial: false 
		})
		let allUserData = JSON.parse(localStorage.getItem('userData'));
		let userDataNew = JSON.parse(localStorage.getItem('userData')).data.responseData;
		userDataNew['dashTutorial'] = 1;
		allUserData['data']['responseData'] = userDataNew;
		localStorage.setItem("userData", JSON.stringify(allUserData));
		$('body').attr({'style':'overflow: auto'});
	}).catch(err =>{ });
}

goToDate = (date) =>{
	let count = this.state.nextWeekCount;
	let newCount = count + 1;
	
	var current = new Date(date)
	let nextWeek = [];
	var first ;
	if(current.getDay()===0){
		first = current.getDate() - 7 + 1;
	}else{
		first = current.getDate() - current.getDay() + 1;
	}

	current.setDate(first);

	let curr = current; 
	var week = [];
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	
	for (let i = 2; i <= 8; i++) {
		let first2;
		if ( i != 8 )
			{
				first2 = curr.getDate() - curr.getDay() + i;
			}
		else
			{
				first2 = curr.getDate() - curr.getDay()+1;
			}

	  let day = new Date(curr.setDate(first2)).toISOString().slice(0, 10);

	  week.push(day);
	  nextWeek.push({
			name:"No Data",
			hostFirstName:'',
			hostLastName:'',
			chargeForSession:0,
			scheduleDate: day
		})
	}
	
	let currentTimeMonday = new Date(nextWeek[1].scheduleDate).getTime() / 1000;
	let timeSpace = currentTimeMonday - this.state.calendarStartTime;
	timeSpace = timeSpace / 86400;
	
	let fowardTimes = timeSpace / 7;
	fowardTimes = Math.round(fowardTimes);
	
	this.setState({
		newCurrentWeek : nextWeek,
		currentDayChoosen: 0,
		week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate(),
		nextWeekCount: fowardTimes
	}, () => 
	{
		this.checkUpcomingSessions(this.state.newCurrentWeek[0].scheduleDate, 'nextWeek');
	})
}

checkUpcomingSessions = (date, type) => {
	let ak = JSON.parse(localStorage.getItem('userData'));
	var current = new Date(date)
	var week = [];
	var first ;
	if(current.getDay()===0){
		first = current.getDate() - 7 + 1;
	}else{
		first = current.getDate() - current.getDay() + 1;
	}
	current.setDate(first);
		for (var i = 0; i < 7; i++) {
		week.push(new Date(+current).toISOString());
		current.setDate(current.getDate()+1);
		}
		
	let userData = {};	
	if (type && type == 'nextWeek')	
		{
			userData =
				{
					email : ak.data.responseData.email,
					type:ak.data.responseData.type,
					countUpcoming: this.state.countUpcoming,
					filterLevel: this.state.filterLevel,
					filterHost: this.state.filterHost,
					filterIntrest: this.state.filterIntrest,
					filterEquipment: this.state.filterEquipment,
					filterDuration: this.state.filterDuration,
					startDate: this.state.fullStartDate,
					endDate: this.state.fullEndDate,
					searchSessionName: this.state.searchSessionName,
					sessionDate: date
				}
		}
	else 
		{
			userData =
				{
					email : ak.data.responseData.email,
					type:ak.data.responseData.type,
					countUpcoming: this.state.countUpcoming,
					filterLevel: this.state.filterLevel,
					filterHost: this.state.filterHost,
					filterIntrest: this.state.filterIntrest,
					filterEquipment: this.state.filterEquipment,
					filterDuration: this.state.filterDuration,
					startDate: this.state.fullStartDate,
					endDate: this.state.fullEndDate,
					searchSessionName: this.state.searchSessionName,
					sessionDate:''
				}
		}		
	
		
		
	localStorage.setItem('participantTotalSessions', 0);	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/participantUpcomingSessions",userData)
    .then(res => {
		let scheduleDate;
		let awk=[];
		this.setState({
			upcShow: res.data.responseData.shownLength,
			upcDataLength: res.data.responseData.dataLength,
			upcomingCounter : res.data.responseData.dataLength
		});
		 if ( res.data.responseData.sessionData && res.data.responseData.sessionData.length > 0 )
			{
				localStorage.setItem('participantTotalSessions', res.data.responseData.dataLength);
				scheduleDate =res.data.responseData.sessionData[res.data.responseData.sessionData.length-1].scheduleDate;
			
				this.setState({ upcomingSessionArray : res.data.responseData.sessionData, foundSessions: res.data.responseData.sessionData},
				() => {
					//this.setTimeRefresh(this.state.upcomingSessionArray[0]);
					if (type && type == 'thisWeek')	
						{
							var allChannels = [];
							let loginId1 = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
							
							for ( let k in this.state.upcomingSessionArray )
								{
									allChannels.push(this.state.upcomingSessionArray[k]['sessionChannelId']);
								}
							
							allChannels.push(loginId1);
							
							console.log('AL CHANELSSS---', allChannels);

							var mainthis = this;
							
							mainthis.updatesse('channelId,userId', allChannels, function ()
								{
									if ( mainthis.state.sessionType == 'Upcoming' )
										{
											mainthis.checkUpcomingSessions(mainthis.state.startDate, 'thisWeek');
										}
									else if ( mainthis.state.sessionType == 'Past' )
										{
											mainthis.pastSessionInfo();
										}
									else if ( mainthis.state.sessionType == 'On-Demand' )
										{
											mainthis.checkAllOnDemand();
										}
									
								});
								
						this.sortSessionsAll(this.state.caseForSort, 'Upcoming');
					}
					});
				for(let j=0;j<week.length;j++)
					{					
						let day=new Date(week[j]).getDate();
						for(let i=0;i<res.data.responseData.sessionData.length;i++)
							{
								let day2=new Date(res.data.responseData.sessionData[i].scheduleDate).getDate();
								if(day===day2)
									{
										day = 0;
										awk.push(res.data.responseData.sessionData[i]);
									}
							}
						if (day == 0 )
							{	
							
							}
						else 
							{
								awk.push({
									name:"No Data",
									hostFirstName:'',
									hostLastName:'',
									chargeForSession:0,
									scheduleDate:week[j]
									})
							}							
					}
			if (awk.length > 0 )
				{
					this.setState({newCurrentWeek: awk});
				}				
			this.setState({ sessionData2:awk, upComingScheduleDate:scheduleDate})	
			}
		else 
			{
				let nextSessionArray = [];
				console.log(res.data.responseData.sessionInNextWeek);
				if ( res.data.responseData.sessionInNextWeek.length > 0 )
					{
						nextSessionArray.push(res.data.responseData.sessionInNextWeek[0]);
					}
				else
					{
						if ( this.state.firstTimeLoading && !this.state.showTutorial && JSON.parse(localStorage.getItem('userData')).data.responseData.isMarketplaceUser == 0 )
							{
								this.setState({firstTimeLoading: false}, () => { this.myChannels(); });
							}	
					}
				
				this.setState({ 
					upcomingSessionArray : [], 
					foundSessions : [],
					nextSession: nextSessionArray
				});
				
				var mainthis = this;
				mainthis.updatesse('userId', JSON.parse(localStorage.getItem('userData')).data.responseData.id, function ()
					{
						if ( mainthis.state.sessionType == 'Upcoming' )
							{
								mainthis.checkUpcomingSessions(mainthis.state.startDate, 'thisWeek');
							}
						else if ( mainthis.state.sessionType == 'Past' )
							{
								mainthis.pastSessionInfo();
							}
						else if ( mainthis.state.sessionType == 'On-Demand' )
							{
								mainthis.checkAllOnDemand();
							}
						
					})
									
									
			} 
	}).catch(err =>{
    console.log('----------there is problem------------',err);
	});;
}	
checkAllOnDemand = e =>	
{
	if ( localStorage.getItem('userData') )
		{
			let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
			
			const userData ={
						id : loginId,
						countShow: this.state.count,
						filterLevel: this.state.filterLevel,
						filterHost: this.state.filterHost,
						filterIntrest: this.state.filterIntrest,
						filterEquipment: this.state.filterEquipment,
						filterDuration: this.state.filterDuration,
						startDate: this.state.fullStartDate,
						endDate: this.state.fullEndDate,
						searchSessionName: this.state.searchSessionName,
						sessionDate:''
					}
				let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
				axios.defaults.headers.common['Authorization'] = token;
				axios.post(process.env.REACT_APP_NAME+"/api/v1/user/participantDashboardOnDemand",userData)
				.then(res => {
					this.setState({
						onDemandFound: res.data.responseData.dataLength,
						onDemandShown: res.data.responseData.shownLength
						})
					if ( res.data.responseData.sessionData.length > 0 )			
						{
							this.setState({
								onDemandSessions: res.data.responseData.sessionData,
								onDemandCounter: res.data.responseData.sessionData.length
							}, () => { this.sortSessionsAll(this.state.caseForSort, 'On-Demand') })
						}
					else 
						{
							this.setState({
								onDemandSessions: [],
								onDemandCounter: 0
							})
						}						
				});
		}		
}
checkChannels = e =>{
	if ( localStorage.getItem('userData') )
		{
			let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
			let email = JSON.parse(localStorage.getItem('userData')).data.responseData.email;
			const userData ={
				id : loginId,
				email: email,
				}
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;	
				axios.post(process.env.REACT_APP_NAME+"/api/v1/user/participantChannels",userData)
				.then(res => {
					 if ( res.data.responseData.length > 0 )
						{
							this.setState({
								channelsData: res.data.responseData,
								channelsCounter: res.data.responseData.length 
							});
							
							if ( window['newChannelsArray'] )
								{
									window['newChannelsArray']();
								}
						}
					else 
						{
							this.setState({
								channelsData: [],
								channelsCounter: 0
							})
						}
				})
		}
}

checkViewType = e => {
	if ( this.state.sessionType == 'On-Demand' && localStorage.getItem('participantOnDemandView') && localStorage.getItem('participantOnDemandView') != '' )
		{
			this.setState({sessionPreview: localStorage.getItem('participantOnDemandView')});
		}
	else if ( this.state.sessionType == 'Upcoming' && localStorage.getItem('participantUpcomingView') && localStorage.getItem('participantUpcomingView') != '' )
		{
			this.setState({sessionPreview: localStorage.getItem('participantUpcomingView')});
		}
	else if ( this.state.sessionType == 'Past' && localStorage.getItem('participantPastView') && localStorage.getItem('participantPastView') != '' )
		{
			this.setState({sessionPreview: localStorage.getItem('participantPastView')});
		}		
}
changeSessionScreen = (mod) =>
{
	this.setState({sessionPreview: mod}, () => {
		if (this.state.sessionType == 'On-Demand')
			{
				localStorage.setItem('participantOnDemandView', mod);
			}
		else if ( this.state.sessionType == 'Upcoming' )
			{
				localStorage.setItem('participantUpcomingView', mod);
			}
		else 
			{
				localStorage.setItem('participantPastView', mod);
			}			
	});
}


openDemandDrops=(ev, i, mod)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	let mainArray = this.state.onDemandSessions;
	var last_open = false;
	$.each(mainArray, function( a, b ) {
		if ( a != i )
			{
				b['descriptionDropOpen'] = false;
				b['equipmentDropOpen'] = false;
				b['goalDropOpen'] = false;
			}
		else
			{
				if ( b[mod] ) { last_open = true; }
				if ( b['descriptionDropOpen'] ) { b['descriptionDropOpen'] = false; }
				if ( b['equipmentDropOpen'] ) { b['equipmentDropOpen'] = false; }
				if ( b['goalDropOpen'] ) { b['goalDropOpen'] = false; }
				
			}
	});
	
	if( !last_open ){ mainArray[i][mod] = !mainArray[i][mod]; }

	this.setState({
				onDemandSessions: mainArray
			});
}

openUpcDrops=(ev, i, mod)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	let mainArray = this.state.upcomingSessionArray;
	var last_open = false;
	$.each(mainArray, function( a, b ) {
		if ( a != i )
			{
				b['descriptionDropOpen'] = false;
				b['equipmentDropOpen'] = false;
				b['goalDropOpen'] = false;
			}
		else
			{
				if ( b[mod] ) { last_open = true; }
				if ( b['descriptionDropOpen'] ) { b['descriptionDropOpen'] = false; }
				if ( b['equipmentDropOpen'] ) { b['equipmentDropOpen'] = false; }
				if ( b['goalDropOpen'] ) { b['goalDropOpen'] = false; }
				
			}
	});
	
	if( !last_open ){ mainArray[i][mod] = !mainArray[i][mod]; }

	this.setState({
				upcomingSessionArray: mainArray
			});
}

openPastDrops=(ev, i, mod)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	let mainArray = this.state.pastSessionData;
	var last_open = false;
	$.each(mainArray, function( a, b ) {
		if ( a != i )
			{
				b['descriptionDropOpen'] = false;
				b['equipmentDropOpen'] = false;
				b['goalDropOpen'] = false;
			}
		else
			{
				if ( b[mod] ) { last_open = true; }
				if ( b['descriptionDropOpen'] ) { b['descriptionDropOpen'] = false; }
				if ( b['equipmentDropOpen'] ) { b['equipmentDropOpen'] = false; }
				if ( b['goalDropOpen'] ) { b['goalDropOpen'] = false; }
				
			}
	});
	
	if( !last_open ){ mainArray[i][mod] = !mainArray[i][mod]; }

	this.setState({
				pastSessionData: mainArray
			});
}

onDemand = e => {
	this.setState({
		sessionType:'On-Demand',
		caseForSort: '', 
		choosenSort: '',
		filterHost : '',
		filterIntrest: '',
		filterLevel: '',
		filterDuration: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		filterEquipment: '',
		searchSessionName: '',
		searchedBy: '',
		caseForSort: '', 
		choosenSort: '',
		pastSessionCount: 1
	}, () => {
		this.checkAllOnDemand();
		this.checkViewType();
		})
}

myChannels = e => {
	this.setState({
		sessionType:'My Channels',
		caseForSort: '', 
		choosenSort: '',
		filterHost : '',
		filterIntrest: '',
		filterLevel: '',
		filterDuration: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		filterEquipment: '',
		searchSessionName: '',
		searchedBy: '',
		caseForSort: '', 
		choosenSort: '',
		pastSessionCount: 1
	}, () => { this.checkChannels() })
}	

upcomingSession = e => {
	this.setState({
		sessionType:'Upcoming',
		filterHost : '',
		filterIntrest: '',
		filterLevel: '',
		filterDuration: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		filterEquipment: '',
		searchSessionName: '',
		searchedBy: '',
		caseForSort: '', 
		choosenSort: '',
		currentDayChoosen: 0,
		pastSessionCount: 1,
		viewStatus: false
	}, () => {
		this.checkUpcomingSessions(this.state.startDate, 'thisWeek');
		this.checkViewType();
		this.backToCurrentDate();
	})
}
pastSession= e => {
	this.setState({
		sessionType:'Past',
		filterHost : '',
		filterIntrest: '',
		filterLevel: '',
		filterDuration: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		filterEquipment: '',
		searchSessionName: '',
		searchedBy: '',
		caseForSort: '', 
		choosenSort: '',
		currentDayChoosen: 0,
	}, () => 
	{	
		this.pastSessionInfo() 
		this.checkViewType();
	});
}	
openDemandSession=(item)=>{
	this.setState({
		demandSessionId: item.id
	}, () => {
				let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
				let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
				
				let deeplink = '';
				let electronPath = '';
				
				var checkSessionValidate = this.joinSessionValidate(true, loginId, token, this.state.demandSessionId);
				
				deeplink = checkSessionValidate.urlPathVar;
				electronPath = checkSessionValidate.urlElectronVar;
				
				this.setState({
					ondemandlink: deeplink,
					electronlink: electronPath,
					urlPath: deeplink,
					urlElectron: electronPath,
					deepLinkButton: checkSessionValidate.deepLinkButton,
					show_modal: true,
					userSignedUp: true,
					sessionScheduleDate: item.createdAt,
					sessionTimezone: '',
					sessionChannelName: item.channelName,
					sessionName: item.name,
					isOnDemandSession: true
				});
			});
}
hideNativePop = e =>
{	
	window.$('#autologinnative').modal('hide');
}

openNativeUrl = (nurl) => {
	var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;

	this.setState({appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink ), showloaderfromnative: true});
	
	$('.main_loader').attr({'style':'display:flex'});
	customProtocolCheck(nurl,
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
							$("#nativedownload").show();
							this.setState({showloaderfromnative: false})
						  },
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
							this.setState({showloaderfromnative: false, urlPath: this.state.urlPathMain, urlElectron: this.state.urlElectronMain});
						  }, 15000);
}

updateStateSearch = (e, newState) => {
	e.preventDefault();
	e.stopPropagation();
	this.setState(newState);
}	
	
stayOpenedSearch = (e) =>
{
	e.preventDefault();
	e.stopPropagation();
	this.setState({
		expandSearchStatus: true
	});
}

toggleSortObjects=(selected)=>{
	if(selected != this.state.choosenSort)
		{
			this.setState({caseForSort: selected, choosenSort: selected});
		}
	
	else
		{
			this.setState({caseForSort: '', choosenSort: ''});
		}
}


setSearchDate =(date, mod)=>{
	let date1=date;
	var momentDateTime = moment(date).format('YYYY-MM-DD 00:00:01');
	var momentDateTimeEnd = moment(date).format('YYYY-MM-DD 23:59:59');
	
	let dateofMonth = new Date(date).getDate();
	let timeSelection =  (new Date (date).getMonth()) ;
	date = new Date(Date.UTC(2019, timeSelection, 1));
	var days = [];
	var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	while (date.getMonth() === timeSelection) 
		{
			//days.push(new Date(date).getDate());
			let n ={date:new Date(date).getDate(),
			day:dayofWeek[new Date(date).getDay()],
			timestamp:new Date(date).getTime(),
			}
			days.push(n);
			//days.push(new Date(date).getDay());
			date.setDate(date.getDate() + 1);
		}
	if(mod == 1)
		{
			this.setState({
				pickedDate:date1,
				pickedStartDate:date1,
				pickedEndDate:date1,
				fullStartDate: momentDateTime,
				fullEndDate: momentDateTimeEnd
			});

		}
		
	else if(mod == 2)
		{
			this.setState({
				pickedStartDate:date1,
				fullStartDate: momentDateTime,
			});
		}
	
	else
		{
			this.setState({
				pickedEndDate:date1,
				fullEndDate: momentDateTimeEnd
			});
		}
}

closeExpandFilter = e =>
	{
		this.setState({
			filterHost : '',
			filterIntrest: '',
			filterLevel: '',
			filterDuration: '',
			pickedDate: '',
			pickedStartDate: '',
			pickedEndDate: '',
			filterEquipment: '',
			searchSessionName: '',
			caseForSort: '',
			fullStartDate: '',
			fullEndDate: '',
			choosenSort: '',
			noFoundSearch: false,
			countMaximum: false
		}, ()=>{
			this.searchResults(null, 2)
		});
	}
searchResults=(e, mod)=>
{
	if ( e )
		{
			e.preventDefault();
			e.stopPropagation();
		}
		
	if((this.state.pickedStartDate !== '' && this.state.pickedEndDate == '') || (this.state.pickedStartDate == '' && this.state.pickedEndDate != ''))
		{
			this.props.showMainErrorPop('If you want to search by Date, both Start Date and End Date cannot be empty');
			return false;
		}
	
	let filterInterestName = '';
	let hostName = '';
	
		
	let sessionNameInfo = this.state.searchSessionName != '' ? this.state.searchSessionName + ', ' : '';
	let interestInfo =  filterInterestName != '' ? filterInterestName + ', ' : '';
	let hostInfo =  hostName != '' ? hostName + ', ' : '';
	let levelInfo =  this.state.filterLevel != '' ? this.state.filterLevel + ', ' : '';
	let durationInfo =  this.state.filterDuration != '' ? this.state.filterDuration + ', ' : '';
	let equipmentInfo =  this.state.filterEquipment != '' ? this.state.filterEquipment + ', ' : '';
	let startDateInfo =  this.state.fullStartDate != '' ? this.state.fullStartDate + ', ' : '';
	let endDateInfo =  this.state.fullEndDate != '' ? this.state.fullEndDate + ', ' : '';
	let searchInfo = sessionNameInfo + interestInfo + hostInfo + levelInfo + durationInfo + equipmentInfo + startDateInfo + endDateInfo;
	searchInfo = searchInfo.slice(0, -2)
	this.setState({searchedBy: searchInfo});
	
	if(sessionNameInfo == '' && hostInfo == '' && levelInfo == '' && durationInfo == '' && startDateInfo == '' && endDateInfo == '' && (interestInfo != '' || equipmentInfo != ''))
		{
			this.setState({ countMaximum: true });
		}
	
	else
		{
			this.setState({ countMaximum: false });
		}

		
	this.setState({applySort: true, expandSearchStatus: false, noFoundSearch: mod == 1 ? true : false}, ()=>{
		if ( this.state.sessionType === 'Upcoming' )
			{
				this.checkUpcomingSessions(this.state.startDate, 'thisWeek');
			}
		else if ( this.state.sessionType === 'On-Demand' )
			{
				this.checkAllOnDemand();
			}
		else if ( this.state.sessionType === 'Past' )
			{
				this.pastSessionInfo();
			}
	});	
}
showMore = e =>
{
	var currentNum = this.state.count;
	var newCount = currentNum + 1;
	
	this.setState({
		count: newCount
	}, () => {
		this.checkAllOnDemand()
		})
}

openClosePop = (e, i)=>{
	let a = e.id;
	let id=i;
	if(new Date().getTime()>new Date(this.state.upcomingSessionArray[id].scheduleDate).getTime())
		{
			let responseMessage="Class in progress, cannot be cancelled."	
			this.setState({
			cancelSessionId:a,
			cancelSessionDate:'',
			canc_pop_status: true,	
			responseMessage:responseMessage,
		
			},()=>$("#cancel_session").attr({'style':'display:block'}))	
		}
	else
		{
			if ( parseInt(e.parentRecrId) > 0 )
				{
					this.setState({
					cancelSessionId:a,
					cancelSessionDate:this.state.upcomingSessionArray[id].scheduleDate,
					cancelReccuringId: parseInt(e.parentRecrId),
					canc_pop_status: false,	
					},()=>$("#participant_cancel_session_recc").attr({'style':'display:block'}))
				}
			else
				{
					let responseMessage="Are you sure you want to cancel the class?"
					this.setState({
					cancelSessionId:a,
					cancelSessionDate:this.state.upcomingSessionArray[id].scheduleDate,
					responseMessage:responseMessage,
					canc_pop_status: false,	
					},()=>$("#cancel_session").attr({'style':'display:block'}))
				}
		}
}

 cancelSession=e=>{
	let a = this.state.cancelSessionId;
	let date=this.state.cancelSessionDate;
	if(!this.state.currentSessionId){

	}else{
	$('#'+this.state.currentSessionId).addClass('d-none')
	}
	$('.main_loader').attr({'style':'display:flex'});
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	 axios.defaults.headers.common['Authorization'] = token;
	 axios.put(process.env.REACT_APP_NAME+"/api/v1/session/"+a+"/CancelSession")
	 .then(res => {
		 $('.main_loader').hide();
		 $("#participant_cancel_session_recc").attr({'style':'display:none'})
		 let responseMessage="Class has been successfully canceled."
		this.setState({
		responseMessage:responseMessage,
		canc_pop_status: true,				
		},()=>$("#cancel_session").attr({'style':'display:block'}));
		// console.log(res);
		this.upcomingSession();

	 }).catch(err =>{

	 })	 
}

cancelAllReccuring=()=>{
	$('.main_loader').attr({'style':'display:flex'});
	let date = this.state.cancelSessionDate;
	var postData = { reccuringId: this.state.cancelReccuringId, participantCancel: true};
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.put(process.env.REACT_APP_NAME+"/api/v1/session/cancelAllReccuring", postData).then(res => {
		$('.main_loader').hide();
		this.upcomingSession();
			
		this.props.showMainErrorPop("Classes has been successfully canceled.");
		$("#participant_cancel_session_recc").attr({'style':'display:none'})
	}).catch(err =>{ });
}
	
showMoreUpc = e => {
	let currentUpcCount = this.state.countUpcoming;
	currentUpcCount = currentUpcCount + 1;
	this.setState({countUpcoming: currentUpcCount}, () => {this.checkUpcomingSessions(this.state.startDate, 'thisWeek')})
}

renderer = ({ days,hours, minutes, seconds, completed }) => {
	if (completed) 
		{
			return <div className="pdash_button2 in_progress_btn" onClick={this.joinSession}>IN PROGRESS - JOIN</div>
		}
	if(days>0 || hours>0)
		{
			return <div className="pdash_button auto_cursor_btn">JOIN</div>
		}
	if(minutes<=this.state.defaultJoinTime)
		{
			return   <div className="counterHolder" > <div className="pdash_button2" onClick={this.joinSession} >JOIN</div> <div className="timeToStart_sq timer_participant"> {minutes>=10 && seconds >=10?<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>{seconds}</span></p>:''} {minutes<10 && seconds <10?<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>0{seconds}</span></p>:''} {minutes>=10 && seconds <10?<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>0{seconds}</span></p>:''}{minutes<10 && seconds >=10?<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>{seconds}</span></p>:''}	</div></div>
		}
	else 
		{		
			return <div className="pdash_button auto_cursor_btn">JOIN</div>
		}						
}	
renderer2 =	({ days,hours, minutes, seconds, completed }) => {
		if (completed) 
			{
				//return <div className="half_btn_holder join_upcpart join_new_width2" > <button className="new_view_button more_padd_join_upcpart active_join" onClick={this.joinSession} > IN PROGRESS - JOIN</button></div>
				return  <a href="javascript:void(0)" ><button className="new_view_button2 more_padd_join_upcpart active_join" onClick={this.joinSession} > IN PROGRESS - JOIN</button> </a>
			
			}
		if(days>0 || hours>0)
			{
				//return <div className="half_btn_holder join_upcpart join_new_width2" > <button className="new_view_button more_padd_join_upcpart pdash_button auto_cursor_btn"> JOIN </button></div>
				return <button className="new_view_button2 more_padd_join_upcpart pdash_button auto_cursor_btn"> JOIN </button>				
					
			}
		if(minutes<=this.state.defaultJoinTime)
			{
				return <div> 
					<div className="" >
						{minutes>=10 && seconds >=10?
							<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>{seconds}</span></p>
							:
							''
						} 
						{minutes<10 && seconds <10?
							<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>0{seconds}</span></p>
							:
							''
						} 
						{minutes>=10 && seconds <10?
							<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>0{seconds}</span></p>
							:
							''
						}
						{minutes<10 && seconds >=10?
							<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>{seconds}</span></p>
							:
							''
						}
					</div>
					<a href="javascript:void(0)"> 
						<button className="new_view_button2 more_padd_join_upcpart active_join" onClick={this.joinSession} > JOIN </button> 
					</a>  
				</div>	
				/*
				return <div className="half_btn_holder join_upcpart join_new_width2" > 
					<button className="new_view_button more_padd_join_upcpart active_join" onClick={this.joinSession} > JOIN </button> 
					<div className="timeToStart_upcpart list_part_timer" >{minutes>=10 && seconds >=10?
					<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>{seconds}</span></p>:''} 
					{minutes<10 && seconds <10?<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>0{seconds}</span></p>:''} 
					{minutes>=10 && seconds <10?<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>0{seconds}</span></p>:''}
					{minutes<10 && seconds >=10?<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>{seconds}</span></p>:''}
					</div> 
				</div>	
					*/
			}
		else 
			{
				//return <div className="half_btn_holder join_upcpart join_new_width2" > <button className="new_view_button more_padd_join_upcpart pdash_button auto_cursor_btn"> JOIN </button></div>
				return <button className="new_view_button2 more_padd_join_upcpart pdash_button auto_cursor_btn"> JOIN </button>
			
			}			
				
	}
	
updateStateFromChild=(newState) => {
	this.setState(newState);
}
	
joinSession = e =>{
	var user = JSON.parse(localStorage.getItem('userData'));
	var checkSessionValidate = this.joinSessionValidate(false, user.data.responseData.id, user.data.responseData.token);
	
	this.setState({
		urlPath: checkSessionValidate.urlPathVar,
		urlElectron: checkSessionValidate.urlElectronVar,
		userSignedUp: true,
		sessionScheduleDate: this.state.upcomingSessionArray[0].scheduleDate,
		sessionTimezone: this.state.upcomingSessionArray[0].timeZone,
		sessionChannelName: this.state.upcomingSessionArray[0].channelName,
		sessionName: this.state.upcomingSessionArray[0].name,
		isOnDemandSession: false
	});
}	

openActivityPop = (mod) => 
{
	this.setState({
		individualSession: mod
	}, ()=> {console.log(this.state.individualSession.activityArray)});
	window.$('#activityPop').modal('show');
}

getDate = (date) =>
{
	let day = new Date(date).getDate();
	let month = this.state.months[new Date(date).getMonth()];
	let year = new Date(date).getFullYear();
	let finalDate = day + ' ' +  month + ', ' + year;

	return finalDate; 
}

getSessionDate=(date1)=>
{
	let ab = new Date(date1).getTime();
	let day=new Date(ab).getDate();
	let month=(new Date(ab).getMonth())+1;
	let year=new Date(ab).getFullYear();
	if( day < 10 ) { day = '0'+day; }
	if( month < 10 ) { month = '0'+month; }
	let sessionDate= month+'/'+day+'/'+year;
	return sessionDate; 
}

getSessionTime = (date1)=>
{
	let ab = new Date(date1).getTime();
	
	let jk=new Date('2020-01-27T12:13:28.000Z').getTime();
	let hour=new Date(date1).getHours();
	let minute = new Date(date1).getMinutes();
	let sessionTime= hour+':'+minute;
	if(minute<10){
		minute='0'+minute
	}
	
	if(hour<12){
	if(hour<10){	
	sessionTime ='0'+hour+':'+minute+" " +'AM'
	}else{
	sessionTime=hour+':'+minute+" " +'AM'
	}
	return sessionTime ;
	}
	if(hour>12){
	if((hour-12)<10){
		sessionTime='0'+(hour-12)+':'+minute+" " +'PM'	
	}else{
	sessionTime=(hour-12)+':'+minute+" " +'PM'
	}	
	return sessionTime ;
	}
	if(hour==12 && minute>=0){
	sessionTime=hour+':'+minute+" " +'PM'	
	return sessionTime ;
	}    
  }
  
handleFilterChange = e =>{
	console.log(e.target.value);
	this.setState({
		[e.target.id]: e.target.value
	});	
}

sortSessionsAll=(selected, type)=>{
	//let selected = $('#sortSessions').val();
	let mainArray = '';
	if ( type == 'Upcoming' )
		{
			mainArray = this.state.upcomingSessionArray;
		}
	else if ( type == 'On-Demand' )
		{
			mainArray = this.state.onDemandSessions;
		}
	else 
		{
			mainArray = this.state.pastSessionData;
		}
	//let mainArray = this.state.sessionData;
	if ( selected === 'by Host' )
		{
			if ( !this.state.sortedByHost )
				{
					mainArray.sort(function(a, b){
						if(a.firstName < b.firstName) { return -1; }
						if(a.firstName > b.firstName) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByHost: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if(a.firstName > b.firstName) { return -1; }
						if(a.firstName < b.firstName) { return 1; }
						return 0;
					})
					
					this.setState({sortedByHost: false});
				}
		}
		
	if ( selected === 'by Duration' )
		{
			if ( !this.state.sortedByDuration )
				{
					mainArray.sort(function(a, b){
						if(a.duration < b.duration) { return -1; }
						if(a.duration > b.duration) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByDuration: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if(a.duration > b.duration) { return -1; }
						if(a.duration < b.duration) { return 1; }
						return 0;
					})
					
					this.setState({sortedByDuration: false});
				}
		}
		
	if ( selected === 'by Session' )
		{
			if ( !this.state.sortedBySession )
				{
					mainArray.sort(function(a, b){
						if(a.name < b.name) { return -1; }
						if(a.name > b.name) { return 1; }
						return 0;
					})
					
					//this.setState({sortedBySession: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if(a.name > b.name) { return -1; }
						if(a.name < b.name) { return 1; }
						return 0;
					})
					
					this.setState({sortedBySession: false});
				}
		}
		
	if ( selected === 'by Level' )
		{
			if ( !this.state.sortedByLevel )
				{
					mainArray.sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level < b.level) { return -1; }
								if(a.level > b.level) { return 1; }
							}
						return 0;
					})
					
					//this.setState({sortedByLevel: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level > b.level) { return -1; }
								if(a.level < b.level) { return 1; }
							}
						return 0;
					})
					
					this.setState({sortedByLevel: false});
				}
		}
		
	if ( selected === 'by Interest' )
		{
			if ( !this.state.sortedByinterest )
				{
					mainArray.sort(function(a, b){
						if(a.interestTitles < b.interestTitles) { return -1; }
						if(a.interestTitles > b.interestTitles) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByinterest: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if(a.interestTitles > b.interestTitles) { return -1; }
						if(a.interestTitles < b.interestTitles) { return 1; }
						return 0;
					})
					
					this.setState({sortedByinterest: false});
				}
		}
		
	if ( selected === 'by Recent Date' )
		{
			if(this.state.sessionType == 'Upcoming')
				{
					if ( !this.state.sortedDateRecent )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
								if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
								return 0;
							})
						}
				}
			
			else
				{
					if ( !this.state.sortedDateRecent )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return -1; }
								if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return 1; }
								return 0;
							})
						}
				}
				
		}
		
	if ( selected === 'by Older Date' )
		{
			if(this.state.sessionType == 'Upcoming')
				{
					if ( !this.state.sortedDateOlder )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return -1; }
								if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return 1; }
								return 0;
							})
						}
				}
			
			else
				{
					if ( !this.state.sortedDateOlder )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return -1; }
								if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return 1; }
								return 0;
							})
						}
				}
				
		}
		
	if ( selected === '' )
		{
			if(this.state.sessionType == 'Past')
				{
					if (mainArray.length > 0 )
					{
						mainArray.sort(function(a, b){
							if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return -1; }
							if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return 1; }
							return 0;
						})
					}
					else 
					{
						return false;
					}
				}
			
			else
				{
					if (mainArray.length > 0 )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
								if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
								return 0;
							})
						}
					else 
						{
							return false;
						}						
				}
		}
		
	this.setState({
				sessionData: mainArray
			});
} 

dateOfSession = (date2)=>
	{
	let date=new Date(date2).getDate()
	return date;
  }
  dayOfSession = (date2)=>
  {
	var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];  
	let day=dayofWeek[new Date(date2).getDay()];
	return day;
  } 
  
openThisDay = (mod) =>
{
	let dateNew=mod.split('T');
	let a = this.state.foundSessions;
	let b = [];
	
	for(let i=0;i<this.state.foundSessions.length;i++)
	{
		let sessionDate = this.state.foundSessions[i].scheduleDate.split('T');
			if(sessionDate[0] === dateNew[0])
			{
				b.push(this.state.foundSessions[i]);
			}
			else
			{
				
			}
	}
	this.setState({
			upcomingSessionArray : b,
			currentDayChoosen: mod
	}) 
	
}

getNextWeek = e =>
{
	console.log('getNextWeek');
	let count = this.state.nextWeekCount;
	
	let newCount = count + 1;
	
	let fullTime = this.state.newCurrentWeek[6].scheduleDate;
	let partTime = new Date(fullTime).getTime();
	let nextMonday = parseInt(partTime) + 86400000;
	let dateNextMonday = new Date(nextMonday);
	
	let curr = dateNextMonday; 
	
	let nextWeek = [];
	var week = [];
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

	for (let i = 1; i <= 7; i++) {
	  let first = curr.getDate() - curr.getDay() + i 
	  let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
	  week.push(day);
	  nextWeek.push({
			name:"No Data",
			hostFirstName:'',
			hostLastName:'',
			chargeForSession:0,
			scheduleDate: day
		})
	}
	this.setState({
		newCurrentWeek : nextWeek,
		nextWeekCount: newCount,
		currentDayChoosen: 0,
		week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate()
	}, () => 
	{
		this.checkUpcomingSessions(this.state.newCurrentWeek[0].scheduleDate, 'nextWeek');
	})
	
}
getPreviousWeek = e => 
{
	console.log('getPreviousWeek');
	let count = this.state.nextWeekCount;
	let newCount;
	if ( count > 0  )
		{
			newCount = count - 1;
		}
	
	let fullTime = this.state.newCurrentWeek[0].scheduleDate;
	let partTime = new Date(fullTime).getTime();
	
	let lastMonday = parseInt(partTime) - 604800000;
	let dateLastMonday = new Date(lastMonday);
	
	let curr = dateLastMonday; 
	let nextWeek = []
	var week = [];
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

	for (let i = 1; i <= 7; i++) {
	  let first = curr.getDate() - curr.getDay() + i 
	  let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
	  week.push(day);
	  nextWeek.push({
			name:"No Data",
			hostFirstName:'',
			hostLastName:'',
			chargeForSession:0,
			scheduleDate: day
		})
	  
	}
	
	this.setState({
		newCurrentWeek : nextWeek,
		nextWeekCount: newCount,
		currentDayChoosen: 0,
		week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate()
	}, () => {this.checkUpcomingSessions(this.state.newCurrentWeek[0].scheduleDate, 'nextWeek')})
} 

backToCurrentDate = e =>
{
	let curr = new Date 
	let week = []

	for (let i = 1; i <= 7; i++) {
	  let first = curr.getDate() - curr.getDay() + i 
	  let day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
	  week.push({
			name:"No Data",
			hostFirstName:'',
			hostLastName:'',
			chargeForSession:0,
			scheduleDate: day
		})
	}
	this.setState({
		newCurrentWeek : week,
		nextWeekCount: 0,
	})
}

pastSessionInfo=(e)=>
{
	let ak = JSON.parse(localStorage.getItem('userData'));
		const userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			count: this.state.pastSessionCount,
			filterLevel: this.state.filterLevel,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			startDate: this.state.fullStartDate,
			endDate: this.state.fullEndDate,
			searchSessionName: this.state.searchSessionName,
			sessionDate: ''
			}
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		axios.defaults.headers.common['Authorization'] = token;	 
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/adminPastSessionData",userData)
		.then(res => {
		$('.main_loader').hide();
		 if(res.data.responseMessage === "success"){
		if(res.data.responseData.sessionData.length>0){	 
		this.setState({
			pastSessionData:res.data.responseData.sessionData,
			currentShownPast: res.data.responseData.shownLength,
			pastSessionsCounter: res.data.responseData.dataLength,
		},() => { this.sortSessionsAll(this.state.caseForSort, 'Past') });
		
		}else{
		 console.log('=============There Is an Error===================>'); 
		}
		}}).catch(err =>{
		console.log('----------there is problem------------',err);
			});
		
}

getMorePastSessiosns =(e)=>
{
	var currentNum = this.state.pastSessionCount;
	var newCount = currentNum + 1;
	
	this.setState({
		pastSessionCount: newCount
	}, () => {
		$('.main_loader').attr({'style':'display:flex'});
		this.pastSessionInfo()
		})
}

LogoutFunction =(e)=>{
	localStorage.removeItem('userData');
	localStorage.removeItem('channelId');
	localStorage.removeItem('groupId');
	localStorage.removeItem('duration');
	localStorage.removeItem('scheduleDate');
	localStorage.removeItem('sessionEndDate'); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_ID); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); 
	window['user_loggedIn'] = 0; 
	browserHistory.push("/");
}

goToMyProfile=(e)=>{
	localStorage.setItem('fromParticipantDashboard', true);
	browserHistory.push("/myProfile");
}

goToSupport=(e)=>{
	localStorage.setItem('supportDashboard', true);
	browserHistory.push("/support");
}

setTimeRefresh=(mod)=>{
	let sessionEndTime = mod.sessionEndDate;
	let endTime = new Date (sessionEndTime).getTime();
	let currentTime = new Date().getTime();
	
	let timeForRefresh = endTime - currentTime;
	timeForRefresh= timeForRefresh + 30000;
	let mainthis = this;
	if (!this.state.viewStatus)
	{	
		setTimeout( function()
		{
			mainthis.checkUpcomingSessions(mainthis.state.startDate, 'thisWeek'); 
		},
		timeForRefresh
		);
	}
}
upComingScheduleDateMethod = (mod) =>{
	
	this.setState({
		viewStatus : true
	})
	var dateNextMonday = moment(mod).startOf('isoweek').toDate(); 
	let curr = dateNextMonday; 
	let nextWeek = [];
	let week = [];
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

	for (let i = 1; i <= 7; i++) {
	  let first = curr.getDate() - curr.getDay() + i 
	  let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
	  console.log('day');
	  console.log(day);
	  week.push(day);
	  nextWeek.push({
			name:"No Data",
			hostFirstName:'',
			hostLastName:'',
			chargeForSession:0,
			scheduleDate: day
		})
	}
	
	let currentTimeMonday = new Date(nextWeek[1].scheduleDate).getTime() / 1000;
	let timeSpace = currentTimeMonday - this.state.calendarStartTime;
	timeSpace = timeSpace / 86400;
	
	let fowardTimes = timeSpace / 7;
	fowardTimes = Math.round(fowardTimes);
	
	this.setState({
		newCurrentWeek : nextWeek,
		currentDayChoosen: 0,
		calendarCurrentTime: currentTimeMonday,
		nextWeekCount: fowardTimes,
		week:'Week of ' + monthArray[new Date(week[1]).getMonth()] +' '+ new Date(week[1]).getDate()
	}, () => 
	{
		this.checkUpcomingSessions(this.state.newCurrentWeek[1].scheduleDate, 'nextWeek');
	})
	
}

changeTutorialIndex=(pg)=>{
	let index = this.state.chosenTutorialIndex;
	let allWidth = $(window).width();
	if ( pg == 'Next' ) { index++; }
	else { index--; }
	
	this.setState({
		chosenTutorialIndex: index
	});
	
	if ( index == 1 )
		{
			$("html, body").animate({ scrollTop: 0 }, "slow");
		}
	
	if ( index == 2 )
		{
			let scrollTBtn = $('#join_btn_scroll')[0].offsetTop;
			$("html, body").animate({ scrollTop: scrollTBtn }, "slow");
		}
}

openInfoPopUp = (e, mod) => {
	if(mod == 'over')
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active');
			$(e.currentTarget).find('.tooltip_hold').addClass('tooltip_active');
		}
	else
		{
			$('.tooltip_hold').removeClass('tooltip_active');
		}
}

openInfoPopUpNew = (e, mod) => {
	if(mod == 'over')
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active2');
			$(e.currentTarget).find('.tooltip_hold').addClass('tooltip_active2');
		}
		
	else
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active2');
		}
}

  render() {

    return (
<div className="custom-width mx-auto px-lg-2 px-xl-3 padd_btm30">
	<div className="container-fluid px-3 px-md-3">
		<div className="hdr pt-3">
			<div className="row top-header px-4 py-2">
				<div className="col-md-8 col-sm-11 pl-lg-0 d-flex flex-wrap justify-content-center justify-content-lg-start p-2">
					<div className="align-self-center">
						<img src="/images/login-logo.png" className="logo mb-2 mb-sm-0" alt="logo" />
					</div>
					<div className="d-flex d-md-block justify-content-center px-4 py-2">
						<div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
							<div className="pl-4">
								<h2 className="mb-0">Welcome {this.state.loginName}!</h2>
								<p className="mb-0">You have {this.state.upcomingCounter} classes coming up</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4 mt-sm-3 col-sm-1 mt-md-0">
				<div className="row justify-content-end relative">
						<div className="">
							<div className="setting-icon position-relative cursor-pointer py-2" data-toggle="dropdown"><img src="../images/setting.png" /></div>
							<div className="dropdown-menu">
								<div className="dropdown-item new-pointer add_point" onClick={this.goToMyProfile}><img src="../images/gray-profile.png" className="mr-2" />Profile</div>
								<Link to="/dashboardLanding" className="dropdown-item"><img src="../images/gray-profile.png" className="mr-2" />Switch to Instructor</Link>
								<div className="dropdown-item point_class" onClick={this.goToSupport} ><img src="../images/gray-contact.png" className="mr-2" />Customer Support</div>
								<div className="dropdown-item new-pointer add_point" onClick={this.LogoutFunction} ><img src="../images/gray-logout.png" className="mr-2"  />Logout</div>
							</div>
						</div>
						{  this.state.chosenTutorialIndex == 1 && this.state.showTutorial && 
							<div className="all_tutorials tutorial_second_part">
								<MainTutorial
									chosenTutorial="participantTutorial"
									state={this.state}
									changeTutorialIndex={this.changeTutorialIndex}
									skipTutorial={this.skipTutorial}
								></MainTutorial>
								<div className="arrow_tutorial participant_arrow"></div>
							</div>
						}
					</div>
				</div>
			</div>	
		</div>
	</div>
	<div className="tab-sec px-3">
		<div className="cont">
			<div className="row">
				<div className="col-xl-8 col-lg-7 col-md-8">
					<ul className="nav nav-tabs" role="tablist">
						<li className="nav-item">
						  <a className={classnames("nav-link ondemandmenu new_text_align_mych ", {"active " : (this.state.sessionType === 'Upcoming')})} data-toggle="tab" href="#us" onClick={this.upcomingSession}>
						  Upcoming Class
						  {this.state.upcomingCounter > 0 &&
							<span> ({this.state.upcomingCounter}) </span>
						  }
						  </a>
						</li>
						<li className="nav-item">
							<Link to="#ps" className={classnames("nav-link pastsesmenu new_text_align_mych ", {"active " : (this.state.sessionType === 'Past')})} data-toggle="tab" onClick={()=>{this.pastSession()}} >
							Past Class
							{this.state.pastSessionsCounter > 0 &&
								<span> ({this.state.pastSessionsCounter}) </span>
							}
							</Link>
						</li>
						<li className="nav-item">
							<Link to="#onde" className={classnames("nav-link ondemandmenu new_text_align_mych ", {"active " : (this.state.sessionType === 'On-Demand')})} data-toggle="tab" onClick={this.onDemand}>
							On-Demand
							{this.state.onDemandCounter > 0 &&
							<span> ({this.state.onDemandCounter})</span>
							}
							</Link>
						</li>
						{ JSON.parse(localStorage.getItem('userData')).data.responseData.isMarketplaceUser == 0 &&
							<li className="nav-item">
								<Link to="#mych" className={classnames("nav-link ondemandmenu new_text_align_mych ", {"active " : (this.state.sessionType === 'My Channels')})} data-toggle="tab" onClick={this.myChannels}>
								My Channels
								{this.state.channelsCounter > 0 &&
									<span> ({this.state.channelsCounter}) </span>
								}
								</Link>
							</li>
						}
					</ul>
				</div>
				{ this.state.sessionType === 'Upcoming' &&
					<div className="col-xl-4 col-lg-5 col-md-4 pl-0 d-flex justify-content-end align-items-center datepick11" style= {{color: '#8f8f8f'}}>
						<div className="cal-backg right-25 w-100 d-flex justify-content-end align-items-center position-relative pos_rel_pass" onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave') } >
							<div className="whitesmoke font-18 mr-5 relative week_text">{this.state.week}</div>
							<DatePicker className="form-control" id="datepicker"  onChange={date => this.goToDate(date)} dateFormat="MMMM d, yyyy" />
							<div className="pos_rel_pass" >
								<div className="tooltip_hold pink_info part_position_tooltip">
									<div className="tooltip_text">Calendar</div>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
		{this.state.sessionType==="Upcoming" && this.state.newCurrentWeek && this.state.newCurrentWeek.length > 0 &&
			<div className="cal_holder" >
				{this.state.nextWeekCount > 0 &&
					<div className="left_arrow_holder no_overflow_tools" onClick={this.getPreviousWeek} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') }>
						<div className="tooltip_hold pink_info arrow_info">
							<div className="tooltip_text">Move Back</div>
						</div>
					</div>
				}
				<div className="cal_right_arrow no_overflow_tools" onClick={this.getNextWeek} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') }>
					<div className="tooltip_hold pink_info arrow_info">
						<div className="tooltip_text">Move Forward</div>
					</div>
				</div>
				{this.state.newCurrentWeek.map((row, i) => 
					<div className="date_holder" key={i} >
						{row.id && row.id > 0 ? 
						<div className={"date_in_holder " +( row.id && row.id > 0  ? 'active_cal_holder' : '' )} onClick={ () =>this.openThisDay(row.scheduleDate)} >
							{this.state.currentDayChoosen == row.scheduleDate &&
							<div className="active_circle"></div>
							}
							<div className="date_val" >{this.dateOfSession(row.scheduleDate)}</div>
							<div className="mon_text" >{this.dayOfSession(row.scheduleDate)}</div>
							{row.id && row.id != 0 &&
								<div className="active_border"></div>
							}
						</div>
						:
						<div className="date_in_holder" >
							<div className="date_val" >{this.dateOfSession(row.scheduleDate)}</div>
							<div className="mon_text" >{this.dayOfSession(row.scheduleDate)}</div>
						</div>
					}
					</div>
				)}
			{/*
			{this.state.sessionData2.map((row,i)=>
				<div className="date_holder" key={i} >
					{row.id && row.id > 0 ? 
						<div className={"date_in_holder " +( row.id && row.id > 0  ? 'active_cal_holder' : '' )} onClick={ () =>this.openThisDay(row.scheduleDate)} >
							<div className="date_val" >{this.dateOfSession(row.scheduleDate)}</div>
							<div>{this.dayOfSession(row.scheduleDate)}</div>
							{row.id && row.id != 0 &&
								<div className="active_border"></div>
							}
						</div>
						:
						<div className="date_in_holder" >
							<div className="date_val" >{this.dateOfSession(row.scheduleDate)}</div>
							<div>{this.dayOfSession(row.scheduleDate)}</div>
						</div>
					}
				</div>
		)} */}
			</div>
		}
		 <div className="content-container mt-2">
			<div className="row">
				<div className="col-md-12 mt-2">
					<div className={ "inner_containr px-4 py-3 mx--6 d-flex justify-content-between align-items-center relative demand_hold " + ( this.state.expandSearchStatus ? "extended_search_hold" : "" ) }>
						<div>
							{this.state.sessionType != 'My Channels' ?
								<div className="px-2 m-0 white relative" id="upcoming_tut">{this.state.sessionType} Classes </div>
								:
								<div className="px-2 m-0 white relative" id="upcoming_tut">{this.state.sessionType} </div>
							}					
						</div>	
						{ this.state.sessionType != 'My Channels' &&
						<div className="header_filters">
							<div className="channel_name channel_name3 new_width_search relative">
								<div className="flex-grow-1 input_field_container py-0 radius-8 main_search_item2 name_new_stats dashboard_search_hold cursor-pointer" id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}} >
									<div className="row mx-0 frst-input-row shadow-1 py-2 align-items-center radius-8 filter_hold_icon" id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}}>
										<div className="flex-grow-1"><input readOnly="" type="text" className="form-control search_input_dashboard_new cursor-pointer" placeholder="Search by Category, Class name, Duration" name="" value={this.state.searchedBy} id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}} disabled/>
										</div>
									</div>
									<div className="right-small-box filter_dropdown_icon" id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}}></div>
								</div>
								{ this.state.expandSearchStatus &&
									<div className="expanded_search_hold" onClick={(e)=>{this.stayOpenedSearch(e)}}>
										<div className="filter_sortby_hold">
											<div className="filter_container_title">Sort By</div>
											<div className="sort_by_inside">
												<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Session')}}>Class Name {this.state.choosenSort == 'by Session' && <div className="checked_sort"></div>}</div>
												<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Interest')}}>Category {this.state.choosenSort == 'by Interest' && <div className="checked_sort"></div>}</div>
												<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Duration')}}>Duration {this.state.choosenSort == 'by Duration' && <div className="checked_sort"></div>}</div>
												<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Level')}}>Level {this.state.choosenSort == 'by Level' && <div className="checked_sort"></div>}</div>
												<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Host')}}>Instructor {this.state.choosenSort == 'by Host' && <div className="checked_sort"></div>}</div>
												<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Recent Date')}}>Date (Recent to Older) {this.state.choosenSort == 'by Recent Date' && <div className="checked_sort"></div>}</div>
												<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Older Date')}}>Date (Older to Recent) {this.state.choosenSort == 'by Older Date' && <div className="checked_sort"></div>}</div>
											</div>
										</div>
										<div className="filter_main_hold">
											<div className="filter_container_title">Filter By</div>
											<div className="filter_inside">
												<div className="form-group-header">
													<span className="cover-border"></span>
													<label className="label dashboard_search_label">Class Name</label>
													<input className="input-field" id="searchSessionName" value={this.state.searchSessionName} onChange={this.handleFilterChange} />
												</div>
												{ this.state.channelData && this.state.channelData[0] &&
													<div className="form-group-header interest_search">
														<span className="cover-border"></span>
														<label className="label dashboard_search_label">Category</label>
														<select className="input-field dashboard_main_select" id="filterIntrest" value={this.state.filterIntrest} onChange={this.handleFilterChange} >
															<option value=""></option>
																{ this.state.channelData[0].channelInterest.map((item, key)=> 
																	<option value={item.id} key={key}>{item.title}</option>
																)}
														</select>
													</div>
												 }											
												<div className="form-group-header">
													<span className="cover-border"></span>
													<label className="label dashboard_search_label">Level</label>
													<select className="input-field dashboard_main_select" id="filterLevel" value={this.state.filterLevel} onChange={this.handleFilterChange} >
														<option value=""></option>
														<option value="Beginner">Beginner</option>
														<option value="Intermediate">Intermediate</option>
														<option value="Advanced">Advanced</option>
														<option value="Multi-Level">Multi-Level</option>
														
													</select>
												</div>
												 <div className="form-group-header">
													<span className="cover-border"></span>
													<label className="label dashboard_search_label">Duration (*Minutes)</label>
													<input type="text" className="input-field dashboard_main_select" id="filterDuration" value={this.state.filterDuration} onChange={this.handleFilterChange} />
												</div>
												 <div className="form-group-header add3_margtp40">
													<span className="cover-border"></span>
													<label className="label dashboard_search_label">Equipment</label>
													<input type="text" className="input-field dashboard_main_select" id="filterEquipment" value={this.state.filterEquipment} onChange={this.handleFilterChange} />
												</div>
												 <div className="form-group-header add2_margtp40">
													<span className="cover-border"></span>
													<label className="label dashboard_search_label">Pick Date</label>
													<div className="">
														<input type="text" value={this.state.searchShowTime || ''} className="input-field layout_border" readOnly/>
														<div className="date_picker_hold dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_hold date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedDate} onChange={date => this.setSearchDate(date, 1)} /></div>
														<div className="when-icon search_calendar_icon">
															<div className="flex_calendar_hold">
															</div>
														</div>
													</div>
												</div>
												 <div className="form-group-header filter_date_hold">
													<div className="above_picker">From</div>
													<span className="cover-border"></span>
													<label className="label dashboard_search_label">Start Date</label>
													<div className="">
														<input type="text" value={this.state.searchStartTime || ''} className="input-field layout_border" readOnly/>
														<div className="date_picker_hold dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_hold date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedStartDate} onChange={date => this.setSearchDate(date, 2)} /></div>
														<div className="when-icon search_calendar_icon">
															<div className="flex_calendar_hold">
															</div>
														</div>
													</div>
												</div>
												 <div className="form-group-header filter_date_hold">
													<div className="above_picker">To</div>
													<span className="cover-border"></span>
													<label className="label dashboard_search_label">End Date</label>
													<div className="">
														<input type="text" value={this.state.searchShowTime || ''} className="input-field layout_border" readOnly/>
														<div className="date_picker_hold dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_hold date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedEndDate} onChange={date => this.setSearchDate(date, 3)} /></div>
														<div className="when-icon search_calendar_icon">
															<div className="flex_calendar_hold">
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="filter_bottom_options">
											<div className="filter_clear_all" onClick={()=>{this.closeExpandFilter(2)}}>Clear All</div>
											<div className="filter_apply" onClick={(e)=>{this.searchResults(e, 1)}}>APPLY</div>
										</div>
									</div>
								}
							</div>
							<div className={"inline_btns_header btns_show grid_view pos_rel_pass" + (this.state.sessionPreview === 'square' ? ' active' : '')} onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave')} onClick={ () => { this.changeSessionScreen('square') }}>
								<div className="tooltip_hold pink_info more_top_pos">
									<div className="tooltip_text">Panel View</div>
								</div>
							</div>
							<div className={"inline_btns_header btns_show list_view pos_rel_pass" + (this.state.sessionPreview === 'list' ? ' active' : '')} onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave')}  onClick={ () => { this.changeSessionScreen('list') }}>
								<div className="tooltip_hold pink_info more_top_pos">
									<div className="tooltip_text">List View</div>
								</div>
							</div>
						</div>
						}
					</div>
				</div>
			</div>
		</div>
		<div className="d-flex align-items-center justify-content-center">
			{ this.state.sessionType ==='Upcoming' && !this.state.showTutorial &&
				<div className="wdth100">
					{ this.state.upcomingSessionArray.length > 0 &&
						<div>
							<ParticipantDashboardUpcomingSessions
								state={this.state}
								openUpcDrops={this.openUpcDrops}
								openClosePop={this.openClosePop}
								showMoreUpc={this.showMoreUpc}
								renderer={this.renderer}
								renderer2={this.renderer2}
								openActivityPop={this.openActivityPop}
								getSessionTime={this.getSessionTime}
							>
							</ParticipantDashboardUpcomingSessions>
						</div>
					}
					
					{ this.state.upcomingSessionArray.length == 0 && this.state.nextSession.length == 0 &&
						<div className="no_upc_sessions_participant" >You have no upcoming classes this week.</div>
					}
					
					{ this.state.upcomingSessionArray.length == 0 && this.state.nextSession.length > 0 &&
						<div className="no_upc_sessions_participant" >You have no upcoming classes this week. Your next class is on {this.getSessionDate(this.state.nextSession[0].scheduleDate)}, to view the class, press <button onClick={ () => this.upComingScheduleDateMethod(this.state.nextSession[0].scheduleDate) } type="button" className="join_button text-uppercase manage_btn">VIEW</button> </div>
					}
				</div>
			}
			{ this.state.sessionType === 'On-Demand' && 
				<div className="wdth100">
					{ this.state.onDemandSessions && this.state.onDemandSessions.length > 0 ? 
						<OnDemandSessionsParticipant
							state={this.state}
							openDemandDrops={this.openDemandDrops}
							openDemandSession={this.openDemandSession}
							showMore={this.showMore}
						>
						</OnDemandSessionsParticipant> 
						:
							<div className="text-light font-18 font-medium inner_containr p-4 my-3">You currently do not have any On-Demand classes.</div>	
					}
				</div>
			}
			{ this.state.sessionType === 'Past' &&
				<div className="wdth100">
					{this.state.pastSessionData.length > 0 &&
						<PastSessionsParticipant
							state={this.state}
							openPastDrops={this.openPastDrops}
							getMorePastSessiosns={this.getMorePastSessiosns}
							openActivityPop={this.openActivityPop}
							getSessionTime={this.getSessionTime}
						>
						</PastSessionsParticipant>
					}
					{this.state.pastSessionData.length == 0 &&
						<div className="no_upc_sessions_participant" >You have no past classes.</div>
					}
				</div>
			}
			{ this.state.sessionType === 'My Channels' &&
				<div className="wdth100 hide_over">
					<MyChannelsParticipant
						state={this.state}
					>
					</MyChannelsParticipant> 
				</div>
			}
			{ this.state.showTutorial &&
				<div className="wdth100 margin_top_tutorial">
						 <div className="demand_preview_square more_hg new_marg_top_tutorial" id="join_btn_scroll">
							<div className="all_inner_square new_back auto_cursor padd_participant pb120">
								<div className="footer_pad_participant">
									<div className="pdash_50 txt_lft top_align">
										<div className="pdash_fnt16 ">
											<span className="relative_interest">Yoga
												<div className="abs_levelsupc">
													<div className="level_title">Beginner</div>
													<div className="level_square green_square"></div>	
												</div>	
											</span>
										</div>
									</div>
									<div className="pdash_50 txt_rght">
										<button className="new_view_button upc_list_canc">CANCEL</button>
									</div>
									<div className="footer_pad">
										<div className="host_name_prt">{this.state.loginName} {this.state.loginLastName}</div>
										<div className="inline_parts_participant">
											<div className="inline_parts_img time_img"></div>
											01/01/2021
										</div>
										<div className="inline_parts_participant">
											<div className="inline_parts_img clock_img"></div>
											30 Min
										</div>
									</div>
									<div className="footer_pad marg_tp10 ">
										<div className="relative pl25 pr25 description_demand text_wrap btn_disp">Description
											<div className="icon_foot_part_l list_img"></div>
										</div>
									</div>
									<div className="new_list_mych">
										<div className="description_demand relative pl25 pr25">Equipment name
											<div className="icon_foot_part_l wrench_img"></div>									
										</div>
									</div>
									<div className="footer_pad abs_btns_participants">
										<div className="pdash_50 txt_lft new_align_upc relative_pos_join">
											<div className="new_view_button upc_list_canc btn_disp active_join ">JOIN</div>
												{  this.state.chosenTutorialIndex == 2 && this.state.showTutorial && 
													<div className="all_tutorials tutorial_third_part new_part_join_tutorial">
														<MainTutorial
															chosenTutorial="participantTutorial"
															state={this.state}
															changeTutorialIndex={this.changeTutorialIndex}
															skipTutorial={this.skipTutorial}
														></MainTutorial>
														<div className="arrow_tutorial third_part_arrow new_arrow_stats"></div>
													</div>
												}
										</div>
										<div className="pdash_50 txt_rght pdash_marg_tp10 new_align_upc">
											<div className="new_view_button upc_list_canc btn_disp">VIEW ACTIVITY LIST</div>
										</div>
									</div>
								</div>
							</div>
						 </div>
					</div> 
				}
			{ this.state.chosenTutorialIndex == 0 && this.state.showTutorial &&
				<div className="all_tutorials tutorial_first">
					<MainTutorial
						chosenTutorial="participantTutorial"
						state={this.state}
						changeTutorialIndex={this.changeTutorialIndex}
						skipTutorial={this.skipTutorial}
					></MainTutorial>
				</div>
			}
		</div>
		<div className="my-3">
		</div>
	</div>
	<div className="main_loader">
		<div className="main_loader_inner"></div>
	</div>
	{/*
	<div className="modal pr-0" id="autologinnative" aria-modal="true">
		<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
			<div className="modal-content bg-transparent">
				<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
					<button type="button" className="close white closepurple translatX-Y" onClick={this.hideNativePop}><span>x</span></button>
					
					{ this.state.deepLinkButton === 'Allow' && <p className="mb-0 py-4 white text-center">Allow Virdio to Open App.</p> }
					{ this.state.deepLinkButton !== 'Allow' && ( !this.state.urlElectron || this.state.urlElectron.indexOf('ondemand') < 0 ) && <p className="mb-0 py-4 white text-center">Please select which Virdio App you want to attend the class in?</p> }
					{ this.state.deepLinkButton !== 'Allow' && this.state.urlElectron && this.state.urlElectron.indexOf('ondemand') > 0 && <p className="mb-0 py-4 white text-center">Please select which Virdio App you want to view the On-demand video in?</p> }
				</div>
				<div className="text-center">
					<a href={this.state.urlPath} target="_blank"><button type="button" className="col-4 custom_btn1 mt-3 new_padding_buttons" onClick={this.hideNativePop}>{this.state.deepLinkButton}</button></a>
					{ this.state.showWindowsApp && this.state.urlElectron !== '' &&
						<button type="button" className="custom_btn1 col-4 mt-3 new_padding_buttons" onClick={() => { this.openNativeUrl(this.state.urlElectron) }} style={{marginLeft: '50px'}}>{osname} App</button>
					}
				</div>
			</div>
		</div>
	</div>
	*/}
	
	<div className="modal pr-0" id="autologinnative" aria-modal="true">
			<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="join_pop_container">
						<button type="button" className="close white closepurple join_close_iks" data-dismiss="modal"><span>x</span></button>
						{ this.state.showWindowsApp && this.state.urlElectron !== '' &&
							<div className="join_parent">
								<div className="join_title">Virdio recommends the Virdio App for the best experience</div>
								<div className="button_join_container">
									{ mobileTab && ios ?
										<a href={this.state.urlElectron} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div></a>
										:
										<div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div>
									}
								</div>
								<div className="browser_run">
									{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank"><span className="run_web">Run in the browser instead</span></a> }
								</div>
							</div>
						}
						{ !this.state.showWindowsApp || this.state.urlElectron === '' &&
							<div className="join_parent">
								<div className="join_title">Virdio recommends Web browser for the best experience</div>
								<div className="button_join_container">
									 <a href={this.state.urlPath} target="_blank"><div className="join_pop_btn">Run in the browser</div></a>
								</div>
								<div className="browser_run"></div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>	
		
		<div className="download_back_container" id="nativedownload">     
			<div className="download_content">
				<div className="down_close" onClick={e=> { $("#nativedownload").hide(); }}></div>
				<div className="for_down_logo">
					<div className="download_logo"></div>
				</div>
				<div className="download_text">Download the Virdio App to install on your device.</div>                
				<div className="download_text">Once downloaded, double click on the installer then drag and drop the Virdio icon into the Applications folder.</div>                
				<div className="for_down_btn">
					<a href={this.state.appDownload} target="_blank"><div className="down_btn" onClick={e=> { $("#nativedownload").hide(); window.$("#nativedownload2").modal('show') }}>Download</div></a>
				</div>
			</div>
		</div> 
			
		<div className="modal pr-0" id="nativedownload2" aria-modal="true">
			<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
						{ ( chrome || edge ) && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible on the bottom left of the browser, click on the file to Install Virdio.</p> }
						{ !chrome && !edge && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible at the top right of the browser, click on the file to Install Virdio.</p> }
						<p className="mb-0 py-4 white text-center">2. After the Installation is complete, click on the JOIN button below.</p>
					</div>
					<div className="text-center"><button type="button" className="custom_btn1 col-4 mt-3" onClick={() => { this.openNativeUrl(this.state.urlElectron); window.$("#nativedownload2").modal('hide') }}>JOIN</button></div>
				</div>
			</div>
		</div>
		
		<div className="main_loader">
			<div className="main_loader_inner"></div>
		</div>
	
	<div className="modal pr-0" id="cancel_session" aria-modal="true">
			<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
						<div className="py-4 text-light text-center font-20">{this.state.responseMessage}</div> 	
					</div>
					{!this.state.canc_pop_status ?	
						<div>
							{this.state.cancelSessionDate?
							<div className="text-center">
							<button type="button" 
								className="custom_btn1 mt-3 mx-2" 
								data-dismiss="modal" 
								onClick={e=>{
								$("#cancel_session").attr({'style':'display:none'});
								}}>No</button>
								<button type="button" 
								className="custom_btn1 mt-3 mx-2" 
								data-dismiss="modal" 
								onClick={e=>{
								this.cancelSession();	
								$("#cancel_session").attr({'style':'display:none'});
								}}>Yes</button>
							</div>:
							<div className="text-center">
							<button type="button" 
								className="custom_btn1 mt-3 mx-2" 
								data-dismiss="modal" 
								onClick={e=>{
								$("#cancel_session").attr({'style':'display:none'});
								}}>No</button>
								</div>
							}
						</div>
						:
						<div className="text-center">
							<button type="button" 
								className="custom_btn1 mt-3 mx-2" 
								data-dismiss="modal" 
								onClick={e=>{
								$("#cancel_session").attr({'style':'display:none'});
								}}>Ok</button>
						</div>
					}
						
				</div>
				</div>
		</div>

		
		<div className="modal pr-0" id="activityPop" aria-modal="true">
			<ActivityList
			state={this.state}
			getSessionTime={this.getSessionTime}
			getSessionDate={this.getSessionDate}
			></ActivityList>
		</div>
		<div className="modal pr-0" id="participant_cancel_session_recc" aria-modal="true">
			<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
						<button type="button" className="close white closepurple p-0 translatX-Y"  data-dismiss="modal" onClick={e=>$("#participant_cancel_session_recc").attr({'style':'display:none'})}><span>×</span></button>
						<div className="py-4 text-light text-center font-20">Do you want to Cancel Only the Current Class or All Future Classes?</div>
					</div>
					<div className="text-center">
						<button type="button" className="custom_btn1 mx-2 mt-3" onClick={this.cancelSession}>Current Class</button>
						<button type="button" className="custom_btn1 mt-3 mx-2" onClick={this.cancelAllReccuring}>All Future Classes</button>
					</div>	
				</div>
			</div>
		</div>
		
		{ this.state.userSignedUp &&
			<div className="full_popup">
				<ParticipantSignedUp 
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					openNativeUrl={this.openNativeUrl}
					sessionStartTimeHigher15Min={this.state.sessionStartTimeHigher15Min}
					urlElectron={this.state.urlElectron}
					urlPath={this.state.urlPath}
					scheduleDate={this.state.sessionScheduleDate}
					timeZone={this.state.sessionTimezone}
					sessionChannelName={this.state.sessionChannelName}
					isHost={this.state.isHost}
					sessionName={this.state.sessionName}
					isOnDemandSession={this.state.isOnDemandSession}
					onDemandId={this.state.demandSessionId}
					>
				</ParticipantSignedUp>
			</div>
		}
		
		<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
</div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ParticipantDashboard);
