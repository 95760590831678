import React, { Component } from "react";
import moment from 'moment';
import { getUserImage } from '../../util/getUserImage';

class ClassNotesList extends Component {
  
  constructor(props) {
		super(props);
		
		this.state = {
			
		}

		this.getUserImage = getUserImage.bind(this);
	}
 
	render() {
			return(
				<div>
					{ this.props.notes.map((item, i)=>
						<div className="note_list" key={i}>
							<div className="bottom_class_content">
								<div className="instructor_avatar" style={{backgroundImage: "url("+this.getUserImage(item.hostImage)+")"}}></div>
								<div className="instructor_name">{item.hostFirstName} {item.hostLastName}</div>
								<div className="note_date">{moment(item.createdAt).format('MM/DD/YYYY, hh:mm A')}</div>
							</div>
							<div className="class_note">{item.note}</div>
						</div>
					)}
				</div>
			)
		}
	}

export default ClassNotesList;