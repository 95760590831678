import axios from "axios";
import $ from 'jquery';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import SimpleReactValidator from 'simple-react-validator';
import { browserHistory } from 'react-router'

const submitHost = function(e)
{ 
  if (this.otpValidation.allValid()) 
		{
			$('.main_loader').attr({'style':'display:flex'});
					let otpDetail =
									{ 
										email :this.state.emailSign,
										code: this.state.otp
									} ;
				  
					var isSecondStep = localStorage.getItem('secondStep');
					localStorage.removeItem('secondStep');
				  
					if ( isSecondStep )
						{
							var goNext = localStorage.getItem('chanelRedirect');
							this.setState({
								redirect: goNext
							});
							localStorage.removeItem('chanelRedirect');
						}
				  
					axios.post(process.env.REACT_APP_NAME+"/api/v1/user/verify-otp", otpDetail)
					  .then(res => {
							if(res.data.responseMessage == "success")
								{
									let data = res.data.responseData;
									if ( this.state.isSignupChannel)
										{
											this.updateCookieAndStorage(res.data.responseData.id, res.data.responseData.token, res.data.responseData.tokenExpire);
											localStorage.setItem("userData", JSON.stringify(res));
											window.location = this.state.redirect;
											$('.main_loader').hide(); 
											
											return false;
										}
									
									var url = window.location.href;
									localStorage.setItem("userData", JSON.stringify(res));
							
									if ( url.indexOf('/trainingPlans') > -1 )
										{
											if ( this.props.state.isFreeTrial )
												{
													$("#sessionPay").attr({'style':'display:none'});
													if ( !this.props.state.isLoggedUser ) { localStorage.setItem('emailNext', this.state.emailSign); browserHistory.push("/login"); }
													else { $("#succes_pay").attr({'style':'display:block'}); }
												}
											else
												{
													$("#sessionPay").attr({'style':'display:none'});
													this.props.openChargeNow();
												}
												
											$('.main_loader').hide();
											return false;
										}
										
									if ( url.indexOf('/accesscode') > -1 )
										{
											this.setState({
												isOpenSign: false,
												verify: false,
											});
											$("#registration_popup").attr({'style':'display:block'});
										}
							
									if ( url.indexOf('/Register') > -1 )
										{
											localStorage.setItem('emailNext', this.state.emailSign);
											$("#succes_register").attr({'style':'display:block'});
											$('.main_loader').hide();
											return false;
										}
									
									if ( this.state.sessionType && this.state.sessionType === 'On Demand' )
										{
											
											if ( this.state.detectChannelPage )
												{
													this.updateCookieAndStorage(data.id, data.token, data.tokenExpire);
													localStorage.setItem("userData", JSON.stringify(res));
													this.setState({
														logedUser: true,
														logedUserName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName
													});
												}
											
											let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
											let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
											
											let deeplink = process.env.REACT_APP_URL+'auto-login'+'/'+loginId+'/'+token + '/' +this.state.demandSessionId;
											let electronPath = '';
											
											if ( android )
												{
													electronPath = 'intent://virdio/?userId='+loginId+'&userToken='+token+'&onDemandId='+this.state.demandSessionId+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;';
												}
											else if ( ios )
												{
													electronPath = process.env.REACT_APP_URL+"auth/?userId="+loginId+"&token="+token + '&ondemandid=' +this.state.demandSessionId;
												}
											else if ( osname == "Windows" || osname == "Mac OS" )
												{
													electronPath = 'virdioapp://login?userId='+loginId+'&userToken='+token+'&ondemandId='+this.state.demandSessionId;
												}
											
											this.setState({
												isLoginOpen: false,
												isOpenLoginAll: false,
												ondemandlink: deeplink,
												electronlink: electronPath,
												urlPath: deeplink,
												urlElectron: electronPath,
												isOpenSign: false,
												otp: '',
												userSignedUp: true,
												loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
												isOnDemandSession: true,
												sessionScheduleDate: this.state.openedDemand.createdAt,
												sessionTimezone: '',
												sessionChannelName: this.state.mainChannel.name,
												isHost: false,
												sessionName: this.state.openedDemand.name,
											});
											
											//if ( ios ) { window.open(deeplink) }
											//window.$('#autologinnative.auto_open_channel').modal('show');
											
											$('.main_loader').hide();
											//window.location.reload();
											return false;
										}
										
									
									
									if ( this.state.log_or_signup && !isSecondStep )
										{
											this.setState({
												log_or_signup: false,
												make_payment: true,
												listCard: true,
												currentUserId: res.data.responseData.id,
												currentUserToken: res.data.responseData.token,
												callbackwhenDone: 1
											});
											$('.main_loader').hide();
											return false;
										}
										
									var redirectMain = '';
									
									if ( this.state.redirect || ( this.props.state && this.props.state.redirect ) )
										{
											if ( this.state.redirect ) { redirectMain = this.state.redirect; }
											if ( this.props.state && this.props.state.redirect ) { redirectMain = this.props.state.redirect; }
										}
									if ( redirectMain !== 'Sign Up' && url.indexOf('/session') === -1 && url.indexOf('/verify-user') === -1 )
										{ 
											let streamingUrlPath;
											let electronPath = '';
											
											// Deeplink check
											if ( android && ( tablete===false || deviceType !== 'tablet' || res.data.responseData.type == 2 ) )
												{
													// Android mobile always app
													electronPath = 'intent://virdio/?userId='+res.data.responseData.id+'&userToken='+res.data.responseData.token+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;'
												}
											else if ( ios && ( tablete === false || deviceType !== 'tablet' || res.data.responseData.type == 2 ) )
												{
													// iOS mobile always app
													electronPath = process.env.REACT_APP_URL+"auth/?userId="+res.data.responseData.id+"&token="+res.data.responseData.token
												}
											else if ( tablete === true && deviceType === 'tablet' && res.data.responseData.type == 1 )
												{
													// Tablet always web
													streamingUrlPath = process.env.REACT_APP_URL+'auto-login'+'/'+res.data.responseData.id+'/'+res.data.responseData.token;
												}
											else if ( osname=="Windows" || osname=="Mac OS" )
												{
													// Native Windows and Mac always both choices
													electronPath = 'virdioapp://login?userId='+res.data.responseData.id+'&userToken='+res.data.responseData.token+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;';
													streamingUrlPath = process.env.REACT_APP_URL+'auto-login'+'/'+res.data.responseData.id+'/'+res.data.responseData.token;
												}
											else
												{
													// Fallback web
													streamingUrlPath = process.env.REACT_APP_URL+'auto-login'+'/'+res.data.responseData.id+'/'+res.data.responseData.token;
												}
											
											  this.setState({
												  message:'',
												  userType:res.data.responseData.type,
												  urlPath:streamingUrlPath,
												  urlElectron: electronPath,
												  isOpenSign: false,
												  verify: false,
											  },()=>{ console.log('submitHost turnof1 loader'); $('.main_loader').hide(); $("#session_alert_new").attr({'style':'display:block'}) })
										}
									 else
										 {
											console.log('ELSE');
											this.setState({
											  message:'',
											  userType:res.data.responseData.type,
											  isOpenSign: false,
											  verify: false
											 },()=>{
													if ( url.indexOf('/session') > -1 || url.indexOf('/verify-user') > -1 )
														{
															this.updateTokenAndStorage(res.data.responseData.id, res.data.responseData.token, res.data.responseData.tokenExpire);
															localStorage.setItem("userData", JSON.stringify(res));
														}
													
													console.log('submitHost turnof2 loader'); 
													$('.main_loader').hide(); 
													$("#registration_popup").attr({'style':'display:block'})}
												)
										}
										  
									$('.main_loader').hide();
							  }
						  else
							  {
									$('.main_loader').hide();
									console.log('submitHost turnof3 loader');
									console.log('=============There Is an Error===================>'); 
							  }
					  }).catch(err =>{
						  $('.main_loader').hide();
						  
						  if(err.response.data.responseCode===417)
							  {
									if(!err.response.data.errorData)
										{
											 this.setState({
												otperror: ''
											 })
										}
									else
										{
											this.setState({
											  otperror:err.response.data.errorData
											},()=>$("#error_otp_msg").attr({'style':'display:block'}))
										}
							  }
						  else
							  {
								this.setState({
								otperror:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
								},()=>$("#error_otp_msg").attr({'style':'display:block'}))
							  }
					  });

				
		} 
	else
		{
			$('.main_loader').hide();
			this.otpValidation.showMessages();
			this.setState({
			otperror:"Check your email and enter correct otp !"
			},()=>$("#error_otp_msg").attr({'style':'display:block'}))
			this.forceUpdate();
		}
}

export { submitHost };