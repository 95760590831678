import React, { Component } from "react";
import classnames from "classnames";
import { browserHistory } from 'react-router';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import $ from 'jquery';
import axios from "axios";

class MemberWorkoutPreferences extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
		virdioCategories: [],
		virdioMusic: [],
		virdioGoals: [],
		tempChosenCategories: [],
		tempChosenMusic: [],
		tempChosenGoals: [],
		tempWeeklyGoal: 0,
    }
	
}
 
componentDidMount(){
	this.getFitnessCategories();
}

getFitnessCategories=()=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFitnessCategories").then(res => { 
		this.setState({
			virdioCategories: res.data.responseData.virdioCategories,
			virdioMusic: res.data.responseData.virdioMusic,
			virdioGoals: res.data.responseData.virdioGoals
		});
	}).catch(err =>{ });	
}

goToLogin=()=>{
	localStorage.setItem("registeredEmail", this.props.state.emailInput);
	localStorage.setItem("registeredPassword", this.props.state.passwordInput);
	browserHistory.push('/login#MemberSignUp');
}


updateInfo=(mainArray, editArray, i)=>{
	var arrayMain = this.state[mainArray];
	var arrayEdit = this.state[editArray];

	if ( arrayEdit.indexOf(arrayMain[i].id) == -1 )
		{
			arrayEdit.push(arrayMain[i].id);
		}
	else
		{
			var indexOf = arrayEdit.indexOf(arrayMain[i].id);
			arrayEdit.splice(indexOf, 1);
		}
	
	this.setState({[editArray]: arrayEdit})
}

finishUpdateProfile=()=>{
	if ( isNaN(this.state.tempWeeklyGoal) || this.state.tempWeeklyGoal < 0 || this.state.tempWeeklyGoal == "" )
		{
			this.props.showMainErrorPop('Please add valid number for How many times in a week do you want to work out');
			return false;
		}
	
	var postData = { 
					userCategories:  this.state.tempChosenCategories,
					userMusic: this.state.tempChosenMusic,
					userGoals: this.state.tempChosenGoals,
					userWeeklyGoal: parseInt(this.state.tempWeeklyGoal)
				};
	$('.main_loader').attr({'style':'display:flex'});			
	let token = this.props.state.userToken;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/saveUserFitness", postData, {headers: {'Authorization': token}}).then(res => {
		$('.main_loader').attr({'style':'display:none'});
		this.goToLogin();
	}).catch(err =>{
		$('.main_loader').attr({'style':'display:none'});
		this.props.showMainErrorPop('Something went wrong. Please clear Your Virdio Local storage and try again.');
		console.log('err----', err) });		
}

  render() {
	 return (
		<div className="memberRegisterHolder workoutPreferences">
			<div className="skip_holder">
				<div className="exit_screen pointerNext" onClick={this.goToLogin}>SKIP</div>
			</div>
			<div className="sign_title">Workout preferences</div>
			<div className="one_fitness_edit">
				<div className="saved_info_title pb10">Preferred Categories</div>
				{ this.state.virdioCategories.map((item, i) =>
					<div className={classnames({'category_profile inlineWidth': true, 'active': this.state.tempChosenCategories.indexOf(item.id) > -1})} key={i} onClick={() => this.updateInfo('virdioCategories', 'tempChosenCategories', i)} >
						<div className="interest_icon" style={{ backgroundImage: "url("+item.icon+")"}}></div>
						<div className="category_title">{item.title}</div>
					</div>
				)}
			</div>
			<div className="one_fitness_edit">
				<div className="saved_info_title">Preferred Music</div>
					<div className="saved_info_content"> 
						{ this.state.virdioMusic.map((item, i) =>
							<div className={classnames({'one_preference less_width_preference': true, 'active': this.state.tempChosenMusic.indexOf(item.id) > -1})} key={i} onClick={() => this.updateInfo('virdioMusic', 'tempChosenMusic', i)}>{item.name}</div>
						)}
					</div>
			</div>
			<div className="one_fitness_edit">
				<div className="saved_info_title pb10">How many times in a week do you want to work out?</div>
				<input type="number" className="fitness_input" id="tempWeeklyGoal" value={this.state.tempWeeklyGoal} onChange={(e) => this.setState({[e.target.id]: e.target.value})} />
				<div className="number_input_title">times</div>
			</div>
			<div className="one_fitness_edit">
				<div className="saved_info_title pb10">What is your personal goal with Virdio?</div>
				{ this.state.virdioGoals.map((item, i) =>
					<div className={classnames({'one_preference': true, 'goal_preference inlineWidth': true, 'active': this.state.tempChosenGoals.indexOf(item.id) > -1})} key={i} onClick={() => this.updateInfo('virdioGoals', 'tempChosenGoals', i)}>{item.name}</div>
				)}
			</div>
			<div className="bottom_arrow">
				<div className={classnames({'inline_event': true})} onClick={this.finishUpdateProfile}>
					<div className="bottom_title_small">FINISH</div>
					<div className="arrow_next"></div>
				</div>
			</div>			
		</div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  },
  null,
  {forwardRef: true}
)(MemberWorkoutPreferences);