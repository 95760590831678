import React, { Component } from "react";
import { Link } from 'react-router';

class SwagBagWelcome extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		
	};
}
 
componentDidMount(){
	
};



render() {
	 return (
		<div className="all_virtual_pg">
			<div className="main_virtual_pop">
				<div className="for_main_logo">
					<div className="main_logo"></div>
					<div className="sub_logo"><span>&#8226;</span>Virtual Studio<span>&#8226;</span></div>
					<Link to="/">
						<div className="close_virtual"></div>
					</Link>
				</div>
				<div className="welcomes f20">Virdio Welcomes IDEA Virtual World 2020 Attendees</div>
				<div className="mainbagtext pt60">Congratulations</div>
				<div className="mainbagtext">It’s time to Sign Up and start using Virdio</div>
				<div className="mainbagtext">You get 2 weeks free and 30% off until December 31st, 2020</div>
				<div className="for_virtual_btn">
					<Link to="/trainingPlans#off20"><button type="button" className="done mt-3">SIGN UP</button></Link> 
				</div>
			</div>
		</div>	
    );
  }
}


export default SwagBagWelcome;
