import React, { Component } from "react";
import { Link } from 'react-router';
import {  browserHistory} from 'react-router'
import $ from 'jquery'
import SimpleReactValidator from 'simple-react-validator';
import axios from "axios";
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import SupportContainer from '../home/SupportContainer';



class verification extends Component {
  constructor(props) {
    super(props);
    this.state={
        checkHost:false,
        checkParticipent:false,
        accessCode:'',
        newsletterName:'',
        newsletterEmail:'',
        newsletterOther:'',
        newsletterAttribute:'',
        msg:'',
        errorClass_AccessCode:false,
        errorEmail:false,
		emailToInform: '',
		sucessMessage: '',
		supportPageName: 'Sign Up',
		supportPageApi: '/accesscode',
		userTypeFaqs: 2
    }
    this.validator = new SimpleReactValidator({autoForceUpdate: this});
    this.referenceCode = new SimpleReactValidator({autoForceUpdate: this});


}
 
componentDidMount(){
	
	var link = window.location;
	var queryString = link.search;
	var getAllValues = queryString.split('?')
	if ( getAllValues[1] )
		{
			var allValuesInLink = getAllValues[1];
			var valuesArray = allValuesInLink.split('&');
			for ( var i = 0; i < valuesArray.length; i++ )
				{
					let finalValue = valuesArray[i].split('=');
					if ( finalValue[0] == 'utm_medium' )
						{
							localStorage.setItem('utm_medium', finalValue[1]);
						}
						
					if ( finalValue[0] == 'utm_campaign' )
						{
							localStorage.setItem('utm_campaign', finalValue[1]);
						}	
					
					if ( finalValue[0] == 'utm_id' )
						{
							localStorage.setItem('utm_id', finalValue[1]);
						}

					if ( finalValue[0] == 'utm_source' )
						{
							localStorage.setItem('utm_source', finalValue[1]);						
						}
				}
		}
	
	
	if ( localStorage.getItem('userData') )
		{
			/*
			var userData = JSON.parse(localStorage.getItem('userData')).data.responseData;
			var dashboardPath = userData.type === 1 ? 'dashboardLanding' : 'participant-dashboard';
			var dashPage = process.env.REACT_APP_PATH+'/'+dashboardPath;
			window.location.href = dashPage;
			return false;
			*/
		}
}
signUpPage=(e)=>{

}
accessCodeMethod = e =>{
    if (this.referenceCode.allValid()) {
    

    
    let code=this.state.accessCode;
    axios      
    .get(process.env.REACT_APP_NAME+"/api/v1/newsletter/"+code+"/checkcode")          
      .then(res => {
       
        
        if(res.data.responseData.length>0){
         localStorage.setItem("email",res.data.responseData[0].email);   
         browserHistory.push('/hostSignUp');
        }else{
            this.setState({
                msg:'Invalid Reference Code!'
            },()=>$("#error_msg").attr({'style':'display:block'}))
        }
      })
      .catch(err =>{
          console.log('----------there is problem------------');

      });
    }else {
        this.referenceCode.showMessages();
        // rerender to show messages for the first time
        // you can use the autoForceUpdate option to do this automatically`
        if(!this.referenceCode.fieldValid('Access Code')){
            this.setState({
                msg:"Access Code is required !"
            },()=>$("#error_msg").attr({'style':'display:block'}))
           }
        this.forceUpdate();
      }
}
newsletterMethod=e=>{
    if (this.validator.allValid()) {
let name=this.state.newsletterName;
let email=this.state.newsletterEmail;
let intrest=this.state.newsletterAttribute;
let others=this.state.newsletterOther;
axios      
.post(process.env.REACT_APP_NAME+"/api/v1/newsletter/newsletter",{name,email,intrest,others})          
  .then(res => {
    // if(res.data.responseData.length>0){
    //  browserHistory.push('/hostSignUp');
    // }else{
    //     this.setState({
    //         msg:'Invalid Reference Code!'
    //     })
    //}
    this.setState({
        msg:'NewsLetter data submitted successfully! ',
        
    },()=>{
        setTimeout(() => {
        $("#form_host_participant").attr({'style':'display:none'})
        browserHistory.push('/');
      }, 2000);
      }
    );
  })
  .catch(err =>{
      console.log('----------there is problem------------');

  });   
}else {
    this.validator.showMessages();
    // rerender to show messages for the first time
    // you can use the autoForceUpdate option to do this automatically`
    this.forceUpdate();
  }
}

closePopup = e =>{
	this.setState({
			errorEmail: false,
			emailToInform: ''
	});
	$("#sendEmailInform").attr({'style':'display:none'})
}

sendEmail = e =>{
	
	if ( this.state.emailToInform === '' || !this.validateEmail(this.state.emailToInform)) 
		{
			this.setState({errorEmail: true});
			return false;
		}
	
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/updateChannelWaitingList",{'email': this.state.emailToInform})          
	  .then(res => {
	   $('.main_loader').hide();
	   $("#sendEmailInform").attr({'style':'display:none'})
	   
	   this.setState({
				errorEmail: false,
				emailToInform: ''
		});
	   if(res.data.responseData === "success")
			{
				this.setState({
					sucessMessage: 'Email successfully added. Thank you for your interest in Virdio.'
				},()=>{
						$("#emailInformSuccess").attr({'style':'display:block'})
				  }
				);
				//this.props.showMainErrorPop('Email successfully added. Thank you for your interest in Virdio.');
			}
		else
			{
				//this.props.showMainErrorPop('Email already on the list. Thank you for your interest in Virdio.');
				this.setState({
					sucessMessage: 'Email already on the list. Thank you for your interest in Virdio.'
				},()=>{
						$("#emailInformSuccess").attr({'style':'display:block'})
				  }
				);
			}
	  })
	  .catch(err =>{
		  console.log('----------there is problem------------');
			$('.main_loader').hide();	
	  });
}

validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

render() {
    return (
        <div id="root">
        <div className="newAccessHolder">
			<div className="newAccessHolder_h1">Sign up</div>
			<div className="newAccessHolder_h2">You cannot sign up for Virdio within the app.</div>
			<div className="newAccessHolder_h2">Go to our website and become a member to enjoy all of our amazing classes.</div>
			{/*
			<button type="button" className="close white closepurple translatX-Y abs_close" onClick={(e)=> browserHistory.push('/')}><span>x</span></button>
			<div className="mw-1260 px-2 px-md-4 my-4">
			    <div className="">
                    <h4 className="pick text-center mb-0 mb-md-1">Sign up for</h4>
                    <div className="text-center text-center my-3 my-md-4"><img src="/images/login-logo.png" className="width-200" alt="#"/></div>
                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                        <div className="parti_box cursor-pointer mb-0">
                            <div className="b-image position-relative align-self-start" id="checkParticipent" onClick={(e)=> browserHistory.push('/MemberRegister')}>
                                <img src="/images/participate.png" alt="participate" />
                            </div>
                           <div className="text-decoration-none">
								<p className="white mt-3 mb-0 mb-md-3" id="checkParticipent" onClick={(e)=> browserHistory.push('/MemberRegister')}>I want to participate in a class</p>
							</div>
                        </div>
                        <div onClick={(e)=> browserHistory.push('/trainingPlans')}>
							<div className="parti_box cursor-pointer mb-0">
								<div className="b-image position-relative align-self-start" id="checkHost">
									<img src="/images/host.png" alt="host" />
								</div>
								<div className="text-decoration-none"><p className="white mt-3 mb-0 mb-md-3" id="checkHost">I want to host a class</p></div>
							</div>
						</div>
                    </div>
                </div>
			</div>
			*/}
		</div>
        <div className="modal pr-0" id="access_code" aria-modal="true">
            <div className="modal-dialog modal-dialog-centered signUpwidth modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body px-4 pb-4">
                    <div className="d-flex justify-content-end">
                            <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>$("#access_code").attr({'style':'display:none'})}><span>x</span></button>
                        </div>
                      <h4 className="modal-title font-30 white pt-3 pb-2 text-center">Please enter the access code provided to you by Virdio </h4>
                        {/* <div className="text-center">
                          <div className="">
                            <input type="text" id = "accessCode" value = {this.state.accessCode} onChange={(e)=>this.setState({[e.target.id]:e.target.value})} className="input_field mx-auto my-3" />
                            <div className="message_error">{this.referenceCode.message('Access Code', this.state.accessCode, 'required')}</div>
                            <div className="red translatee text-center">{this.state.msg}</div>

                          </div>
                          <button type="button" className="custom_btn1" onClick={this.accessCodeMethod}>submit</button>
                        </div> */}
                        <div className="d-flex justify-content-center mt-3 flex-wrap">
                          <div className="height-80">
                            <input type="text" 
                            id = "accessCode" 
                            value = {this.state.accessCode}
                            ref={access=> (this._AccessCode = access)} 
                            onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_AccessCode:false})} 
                            className={(this.state.errorClass_AccessCode?"input-field red-outline":"input-field")} />
                            <div className="message_error">
                            {this.referenceCode.message('Access Code', this.state.accessCode, 'required')}
                            </div>
                            {/* <div className="red text-center">{this.state.msg}</div> */}
                          </div>
                          <div>
                            <button type="button" className="custom_btn1 ml-4 mt-2" onClick={this.accessCodeMethod}>submit</button>
                          </div>
                        </div>
                        <p className="pt-4 pb-3 text-white font-20 mb-0 mb-md-3 text-center">To join virdio's waiting list and subscribe to the Newsletter, Please press <button type="button" className="custom_btn1 ml-3" onClick={(e)=>{$("#access_code").attr({'style':'display:none'});browserHistory.push('/newsletter')}}>newsletter</button></p>
                        <p className="text-white text-center font-20 text-center">By using our products and services, you are agreeing to our <Link to="/TermsofService" target="_blank"><u>Terms Of Services</u></Link>, and <Link to="/PrivacyPolicy" target="_blank"><u>Privacy Policy</u></Link>.</p>
                    
                    </div>
                </div>
            </div>
          </div>
          
          {/* <div className="modal pr-0" id="error_msg" aria-modal="true"  >
   	        <div className="modal-dialog dialogwidth mx-auto">
            <div className="modal-content modl_bg_drk">
      	    <div className="modal-body px-4 pb-4">
		    <p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
            <button type="button" 
            className="custom_btn1 float-right" 
            data-dismiss="modal" 
            onClick={e=>{
              $("#error_msg").attr({'style':'display:none'});
              if(!this.referenceCode.fieldValid('Access Code') ){
                this.setState({
                  errorClass_AccessCode:true
                })
                this._AccessCode.focus();
              }
              if(this.state.msg==="Invalid Reference Code!"){
                this.setState({
                  errorClass_AccessCode:true
                })
                this._AccessCode.focus();
              }
              }}>ok</button>
          </div>
      </div>
    </div>
  </div>
           */}
           <div className="modal pr-0" id="error_msg" aria-modal="true">
              <div className="modal-dialog dialogwidth px-2 mx-auto modal-dialog-centered">
                <div className="modal-content bg-transparent">
                  <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
                    <p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
                  </div>
                  <div className="text-center">
                    <button type="button" 
                    className="custom_btn1 mt-4" 
                    data-dismiss="modal" 
                    onClick={e=>{
                      $("#error_msg").attr({'style':'display:none'});
                      if(!this.referenceCode.fieldValid('Access Code') ){
                        this.setState({
                          errorClass_AccessCode:true
                        })
                        this._AccessCode.focus();
                      }
                      if(this.state.msg==="Invalid Reference Code!"){
                        this.setState({
                          errorClass_AccessCode:true
                        })
                        this._AccessCode.focus();
                      }
                      }}>ok</button>
                    </div>
                  </div>
              </div>
          </div>
          
          <div className="modal pr-0" id="thank_you" aria-modal="true">
            <div className="modal-dialog modal-dialog-centered signUpwidth modal-dialog-centered">
                <div className="modal-content gray-box2">
                    <div className="modal-body px-4 pb-4">
                        <div className="d-flex justify-content-end">
                            <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={(e)=>{$("#thank_you").attr({'style':'display:none'});}}><span>x</span></button>
                        </div>
                      <h4 className="modal-title font-30 white pt-3 pb-2 text-center">Thank you for your interest in Virdio, We are currently live on a limited basis.</h4>
                        <p className="pt-md-5 mt-4 pb-4 text-white font-20 text-center">To join virdio's waiting list and subscribe to the Newsletter, Please press <button type="button" className="custom_btn1 ml-3" onClick={(e)=>{$("#thank_you").attr({'style':'display:none'});browserHistory.push('/newsletter')}}>newsletter</button></p>
                        <p className="text-white text-center font-20 text-center">By using our products and services, you are agreeing to our <Link to="/TermsofService" target="_blank"><u>Terms Of Services</u></Link>, and <Link to="/PrivacyPolicy" target="_blank"><u>Privacy Policy</u></Link>.</p>
                    </div>
                </div>
            </div>
          </div>
		<div className="main_loader">
			<div className="main_loader_inner"></div>
		</div>
		<div className="modal pr-0" id="sendEmailInform" aria-modal="true">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none add_pos_rel">
						<button type="button" class="close white closepurple translatX-Y abs_close" onClick={this.closePopup}><span>x</span></button>
						<p className="mb-0 py-4 white text-center fnt23b">Dear user, currently it is not able to create an account as a instructor. If you want us to inform you when this will be possible, please leave your email down below.</p>
						<div className="for_area_eject">
							<div className="">
								<input type="text" className={"input-field mb-0 " + (this.state.errorEmail ? 'error_reason' : '')} id ="emailToInform" placeholder = 'Email' value ={this.state.emailToInform} onChange={e=>this.setState({[e.target.id]:e.target.value})} />
								{this.validator.message('Email', this.state.emailToInform, 'required|email')}

							</div>
						</div>
					</div>
					<div className="text-center"> 
						<button type="button" className="col-4 custom_btn1 mt-3" onClick={this.sendEmail}>SEND</button>
					</div>
				</div>
			</div>
		</div>
		<div className="modal pr-0" id="emailInformSuccess" aria-modal="true">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none add_pos_rel">
						<p className="mb-0 py-4 white text-center fnt23b">{this.state.sucessMessage}</p>
					</div>
					<div className="text-center"> 
						<button type="button" className="col-4 custom_btn1 mt-3" onClick={e=>$("#emailInformSuccess").attr({'style':'display:none'})}>OK</button>
					</div>
				</div>
			</div>
		</div>
          {/* <div className="modal pr-0" id="form_host_participant" aria-modal="true" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content gray-box2 position-relative">
                    <div className="modal-body px-4 py-4">                        
                    <div className="text-center"><img src="/images/logo.png" className="logo-icon1" /></div>
                    <button type="button" className="close close-btn" data-dismiss="modal" onClick={(e)=>{$("#form_host_participant").attr({'style':'display:none'});}} aria-hidden="true">&times;</button>
                     <div className="mx-auto signUpwidth" style = {{color:'red'}}>{this.state.msg}</div>
                      <div className="host-participant-form pt-3 signUpwidth mx-auto">
                        <div className="">
                            <fieldset>
                                <legend>Personal Info</legend>
                                <div className="row mx-0 px-2">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <span className="cover-border gray-box2"></span>
                                            <label className="label font-medium">Name</label>
                                            <div className="">
                                                <input type="text" id ="newsletterName" value ={this.state.newsletterName} onChange={e=>this.setState({[e.target.id]:e.target.value})} className="input-field mb-0"  />
                                                <span className="signedup_2"></span>
                                                {this.validator.message('Name', this.state.newsletterName, 'required|alpha|min:1|max:20')}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <span className="cover-border gray-box2"></span>
                                            <label className="label font-medium">Email</label>
                                            <div className="">
                                                <input type="text" className="input-field mb-0" id ="newsletterEmail" value ={this.state.newsletterEmail} onChange={e=>this.setState({[e.target.id]:e.target.value})} />
                                                <span className="form_email"></span>
                                                {this.validator.message('Email', this.state.newsletterEmail, 'required|email')}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className="mt-4">
                            <fieldset>
                                <legend>Area Of Interest (One Or More)</legend>
                                <div className="row mx-0 px-1">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="custom--button bg_purple" onClick={e=>this.setState({newsletterAttribute:"Fitness"})}>Fitness</div>
                                            <div className="custom--button"  onClick={e=>this.setState({newsletterAttribute:"Wine"})}>Wine</div>
                                            <div className="custom--button" onClick={e=>this.setState({newsletterAttribute:"Cooking"})}>Cooking</div>
                                            <div className="custom--button" onClick={e=>this.setState({newsletterAttribute:"Beauty/Makeup"})}>Beauty/Makeup</div>
                                            <div className="custom--button" onClick={e=>this.setState({newsletterAttribute:"Home Improvement"})}>Home Improvement</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 px-2 mt-3">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <span className="cover-border gray-box2"></span>
                                            <label className="label font-medium">Other</label>
                                            <div className="">
                                                <input type="text" className="input-field mb-0" id = "newsletterOther" value = {this.state.newsletterOther} onChange={e=>this.setState({[e.target.id]:e.target.value})}/>
                                                <span className="others"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                      </div>
                        <p className="mt-4 text-center"><button type="button" className="custom_btn1" onClick={this.newsletterMethod}>submit</button></p>
                        <p className="text-white text-center mb-2 font-18 text-center">By using our products and services, you are agreeing to our <Link to="/TermsofService" target="_blank"><u>Terms Of Services</u></Link>, and <Link to="/PrivacyPolicy" target="_blank"><u>Privacy Policy</u></Link>.</p>
                    </div>
                </div>
            </div>
          </div> */}
		  
		  {/* <SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer> */}
	</div>
    )
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(verification);