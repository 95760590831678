import React, { Component } from "react";

class Access extends Component {
	constructor() {
		super();
		
		if ( !localStorage.getItem('userData') )
			{
				window['user_loggedIn'] = 0;
				var loginPath = process.env.REACT_APP_PATH+'/login';
				window.location.href = loginPath;
				var link = window.location;
				var queryString = link.search;
				var getAllValues = queryString.split('?')
				if ( getAllValues[1] )
					{
						var allValuesInLink = getAllValues[1];
						var valuesArray = allValuesInLink.split('&');	
						for ( var i = 0; i < valuesArray.length; i++ )
							{
								let finalValue = valuesArray[i].split('=');
								
								if ( finalValue[0] == 'plan' )
									{
										this.setState({
											planSelected: finalValue[1],
											planSelectedBefore: true,
										})
									}
								
								if ( finalValue[0] == 'planPay' )
									{
										if ( finalValue[1] == 1 )
											{
												this.setState({
													familyPlanType: false,
													singlePlanType: false,
												})	
											}
										else
											{
												this.setState({
													familyPlanType: true,
													singlePlanType: true,
												})
											}								
										
									}	
									
								if ( finalValue[0] == 'utm_medium' )
									{
										localStorage.setItem('utm_medium', finalValue[1]);
									}
									
								if ( finalValue[0] == 'utm_campaign' )
									{
										localStorage.setItem('utm_campaign', finalValue[1]);
									}	
								
								if ( finalValue[0] == 'utm_id' )
									{
										localStorage.setItem('utm_id', finalValue[1]);
									}

								if ( finalValue[0] == 'utm_source' )
									{
										localStorage.setItem('utm_source', finalValue[1]);						
									}			
							}		
					}
				return false;
			}
		else
			{
				var userData = JSON.parse(localStorage.getItem('userData')).data.responseData;
				var dashboardPath = userData.type === 1 ? 'dashboardLanding' : 'participant-dashboard';
				var dashPage = process.env.REACT_APP_PATH+'/'+dashboardPath;
				window.location.href = dashPage;
				var queryString = link.search;
				var getAllValues = queryString.split('?')
				if ( getAllValues[1] )
					{
						var allValuesInLink = getAllValues[1];
						var valuesArray = allValuesInLink.split('&');	
						for ( var i = 0; i < valuesArray.length; i++ )
							{
								let finalValue = valuesArray[i].split('=');
								
								if ( finalValue[0] == 'plan' )
									{
										this.setState({
											planSelected: finalValue[1],
											planSelectedBefore: true,
										})
									}
								
								if ( finalValue[0] == 'planPay' )
									{
										if ( finalValue[1] == 1 )
											{
												this.setState({
													familyPlanType: false,
													singlePlanType: false,
												})	
											}
										else
											{
												this.setState({
													familyPlanType: true,
													singlePlanType: true,
												})
											}								
										
									}	
									
								if ( finalValue[0] == 'utm_medium' )
									{
										localStorage.setItem('utm_medium', finalValue[1]);
									}
									
								if ( finalValue[0] == 'utm_campaign' )
									{
										localStorage.setItem('utm_campaign', finalValue[1]);
									}	
								
								if ( finalValue[0] == 'utm_id' )
									{
										localStorage.setItem('utm_id', finalValue[1]);
									}

								if ( finalValue[0] == 'utm_source' )
									{
										localStorage.setItem('utm_source', finalValue[1]);						
									}			
							}		
					}

				return false;
			}
          
		this.state = {
               
		}; 
	}

	render() {

		return (

				<div className="container">
					<div className="row">
						<div className="login-bg"></div>              
					</div>
				</div>

			);

	}

}


export default Access;

