import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { browserHistory} from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import Sortable from 'react-sortablejs';
import uniqueId from 'lodash/uniqueId';
class SessionWineSession extends Component {
  
  constructor(props) {
    super(props);
	this.scriptValidate = new SimpleReactValidator(); 
}

  render() {
    return (
			<div>
				<div className="gray-box2 no-border-radius">
					<div className="session">
						<h3 className="info inline_title"><img src="/images/testing.png" className="mr-3 mb-2 testing_img" />Testing Script</h3>
					</div>
					<div className="px-4 pb-3">
						<div className="row">
							{this.props.state.wineObjectToPush.map((row, w) => (
							<div className="for_form_shop" key={w}>
								<div className="inline_wines width25wine wine_mobile_hold">
									{ this.props.state.editWineIndex == -1 &&
										<div className="form-group mt-3 mb-1" data-toggle="modal" data-target="#myPickWineModel">
											<span className="cover-border"></span>
											<label className="label">Pick a Wine</label>
											<div className="input-field pointer paddt16">{this.props.state.wineObjectToPush[w].wineChoice}</div>
											<span className="emojis-icon"></span>
										</div>
									}
									{ this.props.state.editWineIndex > -1 &&
										<div className="form-group mt-3 mb-1 auto_cur">
											<span className="cover-border"></span>
											<label className="label">Pick a Wine</label>
											<div className="input-field auto_cur paddt16">{this.props.state.wineObjectToPush[w].wineChoice}</div>
											<span className="emojis-icon"></span>
										</div>
									}
								</div>
								<div className="inline_wines width50wine wine_mobile_hold" onClick={ () => this.props.setEmojis(w) }>
									<div className="form-group mt-3 mb-1" data-toggle="modal" data-target="#pick_emojis_modal">
										<span className="cover-border"></span>
										<label className="label">Pick Emojis</label>
										<div className="input-field pointer auto_h">
											{ this.props.state.wineObjectToPush[w] && this.props.state.wineObjectToPush[w].listAppearance &&
												<div className="inline_imgs">
													{this.props.state.wineObjectToPush[w].listAppearance.map((lis, i) => (
														<div key={i}>
															{ lis.status &&
																<div className="one_img">
																	<img src={lis.path} className="img_wine" />
																	<div className="img_name">{lis.name}</div>
																</div>
															}
														</div>
													))}
												</div>
											}
											{ this.props.state.wineObjectToPush[w] && this.props.state.wineObjectToPush[w].listAroma &&
												<div className="inline_imgs">
													{this.props.state.wineObjectToPush[w].listAroma.map((lis, i) => (
														<div key={i}>
															{ lis.status &&
																<div className="one_img">
																	<img src={lis.path} className="img_wine" />
																	<div className="img_name">{lis.name}</div>
																</div>
															}
														</div>
													))}
												</div>
											}
											{ this.props.state.wineObjectToPush[w] && this.props.state.wineObjectToPush[w].listPalate &&
												<div className="inline_imgs">
													{this.props.state.wineObjectToPush[w].listPalate.map((lis, i) => (
														<div key={i}>
															{ lis.status &&
																<div className="one_img">
																	<img src={lis.path} className="img_wine" />
																	<div className="img_name">{lis.name}</div>
																</div>
															}
														</div>
													))}
												</div>
											}
										</div>
										<span className="emojis-icon"></span>
									</div>
								</div>
								<div className="inline_wines width25wine wine_mobile_hold">
									<div className="d-flex">
										<div className="form-group mb-0 input-txt">
											<label className="switch mr-2">
												<input type="checkbox" checked={this.props.state.wineObjectToPush[w].testerStatus} />
												<span className="slider round" onClick={ () => this.props.changeStatusTesters(w) }></span>
											</label>
										</div>
										<div><span className="hdng p-0">Allow Testers to score? (optional)</span></div>
									</div>
								</div>
								{ this.props.state.editWineIndex == -1 && 
								<div className="add_shop" onClick={ () => this.props.pushNewWine(w) }></div>
								}
								{ this.props.state.editWineIndex > -1 && 
									<div>
										<div className="add_shop edit_shop" onClick={ () => this.props.pushNewWine(w) }></div>
										<div className="add_shop close_edit" onClick={this.props.closeEditWine}></div>
									</div>
								}
							</div>
							))}
							{ this.props.state.mainWineArray.length > 0 && 
								<div className="width_all" onMouseLeave={this.props.handleDraggEnd}>
									<div className="for_all_wines padding_winesb">
										<div className="one_wine">
											<div className="one_wine_title">Wine</div>
										</div>
										<div className="one_wine">
											<div className="one_wine_title">Appearance</div>
										</div>
										<div className="one_wine">
											<div className="one_wine_title">Aroma</div>
										</div>
										<div className="one_wine">
											<div className="one_wine_title">Palate</div>
										</div>
										<div className="one_wine"></div>
										<div className="one_wine"></div>
									</div>
									<div onMouseUp={this.props.handleDraggEnd} className="for_all_wines">
									{this.props.state.mainWineArray.map((wine, i) => (
										<div className="for_all_wines" key={wine.id} data-itemkey={i} data-oldarrange={i}>
											
												<div className="one_wine">
													<div className="one_wine_title white_clr">{wine.wineChoice}</div>
												</div>
												<div className="one_wine">
													{wine.listAppearance.map((lis, i) => (
														<div className={ "one_img_main " + ( !lis.status ? "hidden" : "" ) } key={i}>
															{ lis.status &&
																<img src={lis.path} className="img_wine" />
															}
														</div>
													))}
												</div>
												<div className="one_wine">
													{wine.listAroma.map((lis, i) => (
														<div className={ "one_img_main " + ( !lis.status ? "hidden" : "" ) } key={i}>
															{ lis.status &&
																<img src={lis.path} className="img_wine" />
															}
														</div>
													))}
												</div>
												<div className="one_wine">
													{wine.listPalate.map((lis, i) => (
														<div className={ "one_img_main " + ( !lis.status ? "hidden" : "" ) } key={i}>
															{ lis.status &&
																<img src={lis.path} className="img_wine" />
															}
														</div>
													))}
												</div>
												<div className="one_wine">
													<div className="d-flex">
														<div className="form-group mb-0 input-txt">
															<label className="switch mr-2">
																<input type="checkbox" disabled checked={wine.testerStatus} />
																<span className="slider round auto_cur"></span>
															</label>
														</div>
														<div><span className="hdng p-0">Allow Testers to score</span></div>
													</div>
												</div>
												<div className="one_wine txt_right">
													<div className="right_btns">
														<div className="inline_btns mr-1 mr-lg-2 mb-1 pointer" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><i className="fa fa-pencil" onClick={ () => this.props.editWine(i) } aria-hidden="true" ></i>
															<div className="tooltip_hold pink_info">
																<div className="tooltip_text">Edit</div>
															</div>
														 </div>
														<div className="inline_btns mr-1 mr-lg-2 mb-1 pointer" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><i className="fa fa-minus" onClick={ () => this.props.removeWine(i) } aria-hidden="true"></i>
															<div className="tooltip_hold pink_info">
																<div className="tooltip_text">Remove</div>
															</div>
														 </div>
														<div className="inline_btns mr-1 mr-lg-2 mb-1 pointer" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><i className="fa fa-bars" onMouseDown={this.props.handleDraggStart} onMouseMove={this.props.handleDraggitem}  aria-hidden="true"></i>
															<div className="tooltip_hold pink_info">
																<div className="tooltip_text">Change Order</div>
															</div>
														 </div>
													</div>
												</div>
										</div>
									))}
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
    );
  }
}

export default SessionWineSession;
