import React, { Component } from "react";
import { browserHistory } from 'react-router'
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';


class ReportsBilling extends Component {
constructor(props) {
	super(props);
	this.state = { 
					currentSubTab: 'today',
					searchStartDate: new Date(),
					searchEndDate: new Date(),
					dataChange: false,
					currentHost: -1,
					currentInterest: -1,
					sessionStatus: -1,
					
					currentArray: [],
					sortByVal: ['id', 'DESC'],
					
					pageCount: 200,
					
					userSessionPeriods: [],
					selectedPeriod: null,
					ambassadorSubTab: 'codes'
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

changeTab=(e)=>{
	
	this.setState({searchStartDate: new Date(), dataChange: false, sortByVal: ['id', 'DESC']}, function ()
		{
			this.props.updateList({startDate: new Date(), ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: {}, currentSubTab: e, sortByVal: ['id', 'DESC']});
		});
}

changeCurDate=(e)=>{

	this.setState({searchStartDate: e, dataChange: true}, function ()
		{
			this.props.updateList({startDate: this.state.searchStartDate, ajaxLoaded: false, currentArray: [], updateReload: 1});
		});
}

changeCurDateEnd=(e)=>{

	this.setState({searchEndDate: e, dataChange: true}, function ()
		{
			this.props.updateList({endDate: this.state.searchEndDate, ajaxLoaded: false, currentArray: [], updateReload: 1});
		});
}

updateThisFilter=(e)=>{
	console.log('ddd', e.target.id, e.target.value, this.state);
	this.setState({[e.target.id]: e.target.value}, function ()
		{
			this.props.updateList({billingMonth: this.state.selectedPeriod, ajaxLoaded: false, currentArray: [], updateReload: 1});
		});
	
	e.preventDefault();
}

resetFilter=()=>{
	this.setState({searchStartDate: new Date(), dataChange: false, currentHost: -1, currentInterest: -1, sessionStatus: -1});
	$('#currentInterest, #currentHost, #sessionStatus').val(-1).attr({'style':''});
	this.setState({searchStartDate: new Date(), dataChange: false}, function ()
		{
			this.props.updateList({startDate: new Date(), ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: {}});
		});
}

sortArrays=(sortby)=>{
	var sortorder = 'ASC';
	if ( this.state.sortByVal[0] == sortby ) { sortorder = 'DESC'; }

	this.props.updateList({ajaxLoaded: false, currentArray: [], updateReload: 1, startPage: 1, sortByVal: [sortby, sortorder]});
	this.setState({sortByVal: [sortby, sortorder]});
}

openPage=(pg)=>{

var fullFilter = {currentHost: this.state.currentHost, currentInterest: this.state.currentInterest, sessionStatus: this.state.sessionStatus};
this.props.updateList({startDate: this.state.searchStartDate, ajaxLoaded: false, currentArray: [], updateReload: 1, startPage: pg, filterDaily: fullFilter});

}


exportCsv=()=>{
	
	let csv;
	
	if ( this.props.currentSubTab == 'live' ) { csv = 'Class Name, Date, Duration, Attended, Billed'+"\r\n"; }
	if ( this.props.currentSubTab == 'ondemand' ) { csv = 'Recording Name, Host, Interest, Date, Views, Unique'+"\r\n"; }
	
	for ( let row = 0; row < this.props.currentArray.length; row++ )
		{
			var item = this.props.currentArray[row];
			if ( this.props.currentSubTab == 'live' ) 
				{ 
					csv += [item['name'], 
					moment(item['scheduleDate']).format('ddd MM/DD/YYYY @ hh:mm a'),
					item['duration'], 
					item['totalAttending'], 
					item['totalBilled']].join(',')+"\r\n"; 
				}
			if ( this.props.currentSubTab == 'ondemand' ) { csv += [item['name'], item['firstName']+' '+item['lastName'], item['interest'], item['createdAt'], item['totalviews'], item['totalUnique']].join(',')+"\r\n"; }
			
			if ( this.props.currentSubTab == 'ondemand' ) { csv += 'Recording Name, Host, Interest, Date, Views, Unique'+"\r\n"; }
		}
	
	$('#exportcsv').attr({'href': 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv), download: 'downloadReport.csv'});
}


componentDidMount(){
	let timeCreated = new Date(JSON.parse(localStorage.getItem('userData'))['data']['responseData']['createAt']);
	let nowTime = new Date();
	nowTime.setDate(1);
	
	let dates = [];
	while ( timeCreated < nowTime )
		{
			var cmonth = moment(nowTime).format('MMMM');
			if ( new Date().getFullYear() !== nowTime.getFullYear() ) { cmonth += ', ' + nowTime.getFullYear(); }
			
			dates.push([cmonth, moment(nowTime).format('YYYY-MM-DD')]);
			
			nowTime.setMonth(nowTime.getMonth() == 0 ? 11 : nowTime.getMonth()-1);
			if ( nowTime.getMonth() == 11 ) { nowTime.setYear(nowTime.getFullYear()-1); }
			
			console.log(nowTime);
		}
	
	var cmonth = moment(nowTime).format('MMMM');
	if ( new Date().getFullYear() !== nowTime.getFullYear() ) { cmonth += ', ' + nowTime.getFullYear(); }
	
	dates.push([cmonth, moment(nowTime).format('YYYY-MM-DD')]);
	
	this.setState({userSessionPeriods: dates, selectedPeriod: dates[0][1]} );
}

componentDidUpdate(){
	
	this.exportCsv();
}

expandRow=(ind)=>{
	
	var oldarr = this.props.currentArray;
	oldarr[ind]['expandRow'] = oldarr[ind]['expandRow'] == true ? false : true;
	this.props.updateList({currentArray: oldarr});
	
	this.props.loadData(ind);
}

changeAmbTab=(tab)=>{
	if ( !this.props.ajaxLoaded ) { return false; }
	
	this.setState({ambassadorSubTab: tab});

	this.props.updateStateFromChild({ambassadorSubTab: tab, currentArray: [], ajaxLoaded: false});
	
	let mainthis = this;
	setTimeout(function(){ mainthis.props.reloadData(); }, 300);
}

getPlanPdf = (e, mod) => {
	var billData = JSON.parse(mod.billData);
	var isFreeTrial = false;
	var checkPlanData = {};
	if ( billData[2] ) { checkPlanData = billData[2]; }
	if ( mod.trialDuration > 0 ) { isFreeTrial = true; }
	var postData = { channelId: mod.channelId, totalAmmount: mod.amount, planData: JSON.stringify(checkPlanData), confNumber: mod.id, channelName: mod.channelName, planSubId: mod.planSubId, isFreeTrial: isFreeTrial };

	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/getPlanPdf", postData)          
	.then(res => { 
		var pdfUrl = res.data.responseData;
		window.open(pdfUrl);
		$('.main_loader').hide();
	}).catch(err =>{ this.props.showMainErrorPop("Something went wrong."); $('.main_loader').hide(); });
}

  render() {
	
    return (
		<div>
			<div className="daily_session_hold noprint"> 
					<div className={ "daily_section " + ( this.props.currentSubTab == 'live' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('live')}}>
						<div className="daily_title">Live Classes</div>
						<div className="daily_arrow"></div>
					</div>
					<div className={ "daily_section " + ( this.props.currentSubTab == 'ondemand' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('ondemand')}}>
						<div className="daily_title">On-demand</div>
						<div className="daily_arrow"></div>
					</div>
					<div className={ "daily_section " + ( this.props.currentSubTab == 'invoices' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('invoices')}}>
						<div className="daily_title">Past Invoices</div>
						<div className="daily_arrow"></div>
					</div>
					<div className={ "daily_section " + ( this.props.currentSubTab == 'ambassador' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('ambassador')}}>
						<div className="daily_title">{this.props.couponType}</div>
						<div className="daily_arrow"></div>
					</div>
				</div>
			 
			<div className="pay_main">
				<div className="pay_head">
					<div className="pay_head_title noprint">Billing</div> 
					
					{ this.props.currentSubTab == 'live' && this.props.planInfo.planPaid === 1 && <div className="top_pay_info">
						<div className="pay_info_out min_w950">
							<div className="pric_per_mnth marg_btm5">
								<div className="wdth40_rght">Plan:</div>
								<div className="wdth10_rght">{this.props.planInfo['planName']}</div>
								<div className="wdth40_rght">Amount Paid:</div>
								<div className="wdth10_rght">${this.props.planInfo['planPrice'].toFixed(2)}</div>
							</div>
							<div className="pric_per_mnth marg_btm5">
								<div className="wdth40_rght">Members Included:</div>
								<div className="wdth10_rght">{this.props.planInfo['includedParticipants']}</div>
								
								<div className="wdth40_rght">Additional Member:</div>
								<div className="wdth10_rght"> ${this.props.planInfo['additionalParticipants'].toFixed(2)}</div>
							</div>
							<div className="pric_per_mnth marg_btm5">
								<div className="wdth40_rght">Actual Members to Date:</div>
								<div className="wdth10_rght">{this.props.planInfo['sessionUsersInThisMonth']}</div>
								
								<div className="wdth40_rght">Overage to Date:</div>
								<div className="wdth10_rght">${this.props.planInfo['overChargeUsersPrice']}</div>
							</div>
						</div>
					</div> }
					
					{ this.props.currentSubTab == 'ondemand' && this.props.planInfo.planPaid === 1 && <div className="top_pay_info">
						<div className="pay_info_out min_w950">
							<div className="pric_per_mnth marg_btm5">
								<div className="wdth40_rght">Plan:</div>
								<div className="wdth10_rght">{this.props.planInfo['onDemandPlan']}</div>
								
								<div className="wdth40_rght">Amount Paid:</div>
								<div className="wdth10_rght">${this.props.planInfo['onDemandPrice'].toFixed(2)}</div>
							</div>
							<div className="pric_per_mnth marg_btm5">
								<div className="wdth40_rght">Unique Clients Included:</div> 
								<div className="wdth10_rght">{this.props.planInfo['uniqueClients']}</div>
								
								<div className="wdth40_rght">Additional Per Unique Client:</div> 
								<div className="wdth10_rght">${this.props.planInfo['additionalClient'].toFixed(2)}</div>
							</div>
							<div className="pric_per_mnth marg_btm5">
								<div className="wdth40_rght">Actual Clients to Date:</div>
								<div className="wdth10_rght">{this.props.planInfo['recordingClientsInThisMonth']}</div>
								
								<div className="wdth40_rght">Client Overage to Date:</div>
								<div className="wdth10_rght">${this.props.planInfo['overChargeRecordingsClientsPrice']}</div>
							</div>
							<div className="pric_per_mnth marg_btm5">
								<div className="wdth40_rght">Recordings Stored On Cloud Included:</div>
								<div className="wdth10_rght">{this.props.planInfo['recordingsNumber']}</div>
								
								<div className="wdth40_rght">Additional Per Recording:</div>
								<div className="wdth10_rght">${this.props.planInfo['additionalRecording'].toFixed(2)}</div>
							</div>
							<div className="pric_per_mnth marg_btm5">
								<div className="wdth40_rght">Actual Recordings to Date:</div>
								<div className="wdth10_rght">{this.props.planInfo['recordingsInThisMonth']}</div>
								
								<div className="wdth40_rght">Recording Overage to Date:</div>
								<div className="wdth10_rght">${this.props.planInfo['overChargeRecordingsPrice']}</div>
							</div>
						</div>
					</div>
					}
					
					{ this.props.currentSubTab !== 'invoices' && this.props.currentSubTab !== 'ambassador' && <div>
							<div className="show_pay_page billingtopmonth noprint">
								<div className="form-group">
									<div className="rose_btn" onClick={()=> { window.print(); }}>Print</div>
								</div>
								<div className="form-group">
									<a href="#" id="exportcsv"><div className="rose_btn" onClick={()=> { this.exportCsv(); }}>Export to Excel</div></a>
								</div>
							</div>
							
							<div className="show_search_opt noprint">
								
								<div className="form-group">
									<span className="cover-border"></span>
									<label className="label">Period</label>
									<select id="selectedPeriod" onChange={this.updateThisFilter} className="input-field">
										{ this.state.userSessionPeriods.map((item, key) => <option key={key} value={item[1]}>{item[0]}</option> ) }
									</select>
								</div>
							</div>
						</div>
					}
					
					
					{ this.props.currentSubTab === 'ambassador' && 
						<div>
							<div className="for_ambassador_tabs">
								<div className="ambassador_tabs left_tab"><span className={"pad_ambasador_span cursor " + ( this.state.ambassadorSubTab == 'codes' ? "ambassador_active" : "" )} onClick={()=> { this.changeAmbTab('codes'); }}>Coupon Codes</span></div>
								<div className="ambassador_tabs center_tab"><span className={"pad_ambasador_span cursor " + ( this.state.ambassadorSubTab == 'channels' ? "ambassador_active" : "" )} onClick={()=> { this.changeAmbTab('channels'); }}>Channels</span></div>
								<div className="ambassador_tabs right_tab"><span className={"pad_ambasador_span cursor " + ( this.state.ambassadorSubTab == 'payments' ? "ambassador_active" : "" )} onClick={()=> { this.changeAmbTab('payments'); }}>Payments</span></div>
							</div>
							<div className="show_search_opt noprint w100">
								{ this.state.ambassadorSubTab !== 'codes' &&
									<div className="form-group">
										<span className="cover-border"></span>
										<label className="label">Period</label>
										<select id="selectedPeriod" onChange={this.updateThisFilter} className="input-field">
											{ this.state.userSessionPeriods.map((item, key) => <option key={key} value={item[1]}>{item[0]}</option> ) }
										</select>
									</div>
								}
							</div>
						</div>
					}
					
					{ this.props.currentArray.length > 0 &&
					<table className="billing_table">
						<thead>
							{ this.props.currentSubTab == 'live' && <tr>
								<th>Class Name
									{ this.props.currentArray.length > 0 &&
										<div onClick={(e) => this.sortArrays('name')} className="reports_sort_icon"></div>
									}	
								</th>
								<th>Date & Time
									{ this.props.currentArray.length > 0 &&
										<div onClick={(e) => this.sortArrays('scheduleDate')} className="reports_sort_icon"></div>
									}
								</th>
								<th>Duration
									{ this.props.currentArray.length > 0 &&	
										<div onClick={(e) => this.sortArrays('duration')} className="reports_sort_icon"></div>
									}
								</th> 
								<th>Attended</th>
								<th>Billed</th>
							</tr> }
							
							{ this.props.currentSubTab == 'ondemand' && <tr>
								<th>Recording Name
									{ this.props.currentArray.length > 0 &&	
										<div onClick={(e) => this.sortArrays('name')} className="reports_sort_icon"></div>
									}
								</th>
								<th>Instructor
									{ this.props.currentArray.length > 0 &&
										<div onClick={(e) => this.sortArrays('firstName')} className="reports_sort_icon"></div>
									}
								</th>
								<th>Category
									{ this.props.currentArray.length > 0 &&	
										<div onClick={(e) => this.sortArrays('interestname')} className="reports_sort_icon"></div>
									}
								</th> 
								<th>Date Recorded
									{ this.props.currentArray.length > 0 &&	
										<div onClick={(e) => this.sortArrays('createdAt')} className="reports_sort_icon"></div>
									}
								</th> 
								<th>Total Viewings
									{ this.props.currentArray.length > 0 &&
										<div onClick={(e) => this.sortArrays('totalviews')} className="reports_sort_icon"></div>
									}
								</th>
								<th>Total Unique Clients
									{ this.props.currentArray.length > 0 &&
										<div onClick={(e) => this.sortArrays('totalUnique')} className="reports_sort_icon"></div>
									}
								</th>
							</tr> }
							
							{ this.props.currentSubTab == 'invoices' && <tr>
								<th>Live Plan Name</th>
								<th>OnDemand Plan Name</th>
								<th>Amount</th> 
								<th>Date & Time</th>
								<th>Download</th>
							</tr> }
							
							{ this.props.currentSubTab == 'ambassador' && this.state.ambassadorSubTab == 'channels' && <tr>
								<th>Channel</th>
								<th>Description</th>
								<th>City</th> 
								<th>Address</th>
								<th>Coupon Code</th>
								<th>Coupon Percent</th>
								<th>Ambassador Percent</th>
								<th>Channel All Payments</th>
								<th>Ambassador Ammount</th>
							</tr> }
							
							{ this.props.currentSubTab == 'ambassador' && this.state.ambassadorSubTab == 'payments' && <tr>
								<th>Channel</th>
								<th>Live Plan</th>
								<th>Live Plan Ammount</th> 
								<th>On-Demand Plan</th>
								<th>On-Demand Plan Ammount</th>
								<th>Ambassador Percent</th>
								<th>Ambassador Percent Ammount</th>
								<th>Date</th>
							</tr> }
							
							{ this.props.currentSubTab == 'ambassador' && this.state.ambassadorSubTab == 'codes' && <tr>
								<th>Code</th>
								<th>Description</th>
								<th>Coupon Percent</th>
								<th>Channel Coupon Percent</th>
							</tr> }
							
						</thead>
						<tbody> 
							{ this.props.currentSubTab == 'live' && this.props.currentArray.map((item, key) =>
								<React.Fragment key={key}>
									<tr key={key}>
										<td>{ item['name'] }</td>
										<td>{ this.getSessionDate(item['scheduleDate'])} @ {this.getSessionTime(item['scheduleDate'])}</td>
										<td>{ item['duration'] }</td>
										<td>{ item['totalAttending'] } { item['totalAttending'] > 0 && <div className={'info_btn '+( item['expandRow'] ? 'open' : '')} onClick={(e) => this.expandRow(key)}></div> }</td>
										<td>{ item['totalBilled'] }</td>
									</tr>
									{
										item['expandRow'] &&
										<tr key={'mk'+key} className="subopen">
											<td colSpan={this.props.currentSubTab == 'today' ? 7 : 6}>
												{ !item['moreData'] && <div className="loadingResults"><div className="main_loader_inner"></div></div> }
												{ item['moreData'] && <div className="moreDataShow">
													{ item['moreData'].map((alldata, key) => <div key={'j'+key}>{ alldata['mainTitle'] && <span>{alldata['mainTitle']}</span> }<table className="billing_table">
														<thead>
															<tr>{ alldata['titles'].map((tt, key) => <th key={'c'+key}>{tt}</th> )}</tr>
														</thead>
														<tbody>
															{ alldata['data'].map((dta, key) => <tr key={'vv'+key}>{ dta.map((dtv, key) => <td className="dtval" key={'v'+key}>{dtv}</td> )}</tr>)}
														</tbody>
													</table></div> )}
												</div> }
											</td>
										</tr>
									}
								</React.Fragment>
							)}
							
							{ this.props.currentSubTab == 'ondemand' && this.props.currentArray.map((item, key) =>
								<React.Fragment key={key}>
									<tr key={key}>
										<td>{ item['name'] }</td>
										<td>{ item['firstName'] } { item['lastName'] }</td>
										<td>{ item['interestTitles'] }</td>
										<td>{ this.getSessionDate(item['createdAt'])} @ {this.getSessionTime(item['createdAt'])}</td>
										<td>{ item['totalviews'] }</td>
										<td>{ item['totalUnique'] } { item['totalUnique'] > 0 && <div className={'info_btn '+( item['expandRow'] ? 'open' : '')} onClick={(e) => this.expandRow(key)}></div> }</td>
									</tr>
									{
										item['expandRow'] &&
										<tr key={'mk'+key} className="subopen">
											<td colSpan={this.props.currentSubTab == 'today' ? 7 : 6}>
												{ !item['moreData'] && <div className="loadingResults"><div className="main_loader_inner"></div></div> }
												{ item['moreData'] && <div className="moreDataShow">
													{ item['moreData'].map((alldata, key) => <div key={'j'+key}>{ alldata['mainTitle'] && <span>{alldata['mainTitle']}</span> }<table className="billing_table">
														<thead>
															<tr>{ alldata['titles'].map((tt, key) => <th key={'c'+key}>{tt}</th> )}</tr>
														</thead>
														<tbody>
															{ alldata['data'].map((dta, key) => <tr key={'vv'+key}>{ dta.map((dtv, key) => <td className="dtval" key={'v'+key}>{dtv}</td> )}</tr>)}
														</tbody>
													</table></div> )}
												</div> }
											</td>
										</tr>
									}
								</React.Fragment>
							)}
							
							
							{ this.props.currentSubTab == 'invoices' && this.props.currentArray.map((item, key) =>
								<React.Fragment key={key}>
									<tr key={key}>
										<td>
											{item['planName'] != null ?
												<div>{ item['planName'] } {item['trialDuration'] > 0 ? '(Free Trial)' : ''}</div>
											:
												<div>N/A</div>
											}
										</td>
										<td>
											{item['onDemandPlan'] != null && item['onDemandPlan'] != '' ?
												<div>{ item['onDemandPlan'] } {item['trialDuration'] > 0 ? '(Free Trial)' : ''}</div>
											:
												<div>N/A</div>
											}
										</td>
										<td>${ item['amount'] }</td>
										<td>{ this.getSessionDate(item['billDate'])} @ {this.getSessionTime(item['billDate'])}</td>
										<td>
											{ JSON.parse(item.billData)[2] && <div className="refund_button" onClick={ (e) => { this.getPlanPdf(e, item); } }>PDF</div> }
											{ !JSON.parse(item.billData)[2] && <div>-</div> }
										</td>
									</tr>
								</React.Fragment>
								
								
								
								
							)}
							
							{ this.props.currentSubTab == 'ambassador' && this.state.ambassadorSubTab == 'channels' && this.props.currentArray.map((item, key) =>
								<React.Fragment key={key}>
									<tr key={key}>
										<td>{item['name']}</td>
										<td>{item['description']}</td>
										<td>{item['city']}</td>
										<td>{item['street_address1']}</td>
										<td>{item['subCode']}</td>
										<td>{item['subPercent']}%</td>
										<td>{item['channelPercent']}%</td>
										<td>${item['allChannelPay']}</td>
										<td>${item['allAmbassadorAmmount']}</td>
									</tr>
								</React.Fragment>
							)}
							
							{ this.props.currentSubTab == 'ambassador' && this.state.ambassadorSubTab == 'payments' && this.props.currentArray.map((item, key) =>
								<React.Fragment key={key}>
									<tr key={key}>
										<td>{item['name']}</td>
										<td>{item['planName']}</td>
										<td>${item['planPrice']}</td>
										<td>{item['onDemandPlan'] !== '' ? item['onDemandPlan'] : 'N/A'}</td>
										<td>${item['onDemandPrice'] !== 0 ? item['onDemandPrice'] : 0}</td>
										<td>{item['channelPercent']}%</td>
										<td>${item['channelPercentAmmount']}</td>
										<td>{ this.getSessionDate(item['planActivated'])} @ {this.getSessionTime(item['planActivated'])}</td>
									</tr>
								</React.Fragment>
							)}
							
							{ this.props.currentSubTab == 'ambassador' && this.state.ambassadorSubTab == 'codes' && this.props.currentArray.map((item, key) =>
								<React.Fragment key={key}>
									<tr key={key}>
										<td>{item['subCode']}</td>
										<td>{item['subText']}</td>
										<td>{item['subPercent']}%</td>
										<td>{item['channelCouponPercent']}%</td>
									</tr>
								</React.Fragment>
							)}
							
							
						</tbody>
					</table> }
					
					{ this.props.currentArray.length == 0 && this.props.ajaxLoaded && this.props.currentSubTab !== 'invoices' && this.props.currentSubTab !== 'ambassador' && <div className="noResults">No Classes Found</div> }
					{ this.props.currentArray.length == 0 && this.props.ajaxLoaded && this.props.currentSubTab == 'invoices' && <div className="noResults">No Past Invoices Found</div> }
					{ this.props.currentArray.length == 0 && this.props.ajaxLoaded && this.props.currentSubTab == 'ambassador' && this.state.ambassadorSubTab == 'channels' && <div className="noResults">No available Ambassador Channels for selected month.</div> }
					{ this.props.currentArray.length == 0 && this.props.ajaxLoaded && this.props.currentSubTab == 'ambassador' && this.state.ambassadorSubTab == 'payments' && <div className="noResults">No available Ambassador Payments for selected month.</div> }
					{ this.props.currentArray.length == 0 && this.props.ajaxLoaded && this.props.currentSubTab == 'ambassador' && this.state.ambassadorSubTab == 'codes' && <div className="noResults">No available Coupons.</div> }
					{ this.props.currentArray.length == 0 && !this.props.ajaxLoaded && <div className="loadingResults"><div className="main_loader_inner"></div></div> }
					
					{ this.props.currentArray.length > 0 && this.props.currentSubTab !== 'invoices' && this.props.currentSubTab !== 'ambassador' && <div className="billTotalSessions">
						<div>Number of Classes: {this.props.billingArray[0]}</div>
							{ this.props.currentSubTab != 'ondemand' &&
								<div>Total Members Attended: {this.props.billingArray[1]}</div>
							}
						<div>Total Members Billed: {this.props.billingArray[2]}</div>
					</div> }
					
				</div>
			</div>
			
			{/*<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>*/}
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ReportsBilling);