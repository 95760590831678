import React, { Component } from "react";

// import axios from "axios";
// import {  browserHistory} from 'react-router'
// import { Link } from 'react-router';



//import $ from 'jquery';
//import DateTimeField from "react-bootstrap-datetimepicker";

class ComingSoon extends Component {
  
  constructor(props) {
	super(props);

    
}
 

componentDidMount(){
  }


  render() {
	


    return (
        <div>
            <div className="coming-soon">
            <div className="black-coming-bg">
                    <div className="container-fluid px-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-sm-12 col-12">
                                    <div className="form-elements">
                                        <h3>Find out more...</h3>
                                        <div className="form-group">
                                            <input type="text" className="form-control mt-4" placeholder="Your Name" />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control mt-4" placeholder="email address" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 my-4 my-lg-0 col-sm-12 col-12">
                                    <div className="form-elements">
                                        <h3>What interests you?</h3></div>
                                    <div className="items-collection">
                                        <label className="noactive">
                                            <input type="checkbox" />Strength Training</label>
                                        <label className="noactive">
                                            <input type="checkbox" />Yoga</label>
                                        <label className="noactive">
                                            <input type="checkbox" />Fitness</label>
                                        <label className="noactive">
                                            <input type="checkbox" />Wine</label>
                                        <label className="noactive">
                                            <input type="checkbox" />Beauty</label>
                                        <label className="noactive">
                                            <input type="checkbox" />Cooking</label>
                                        <label className="noactive">
                                            <input type="checkbox" />Fishing</label>
                                        <label className="noactive">
                                            <input type="checkbox" />Gardening</label>
                                        <label className="noactive">
                                            <input type="checkbox" />Photography</label>
                                        <label className="noactive">
                                            <input type="checkbox" />Travel Tips</label>
                                        </div>
                                    <div className="form-elements">
                                        <div className="form-group">
                                            <input type="text" className="form-control small-input" placeholder="If other, Please specify..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-12 d-flex align-items-end justify-content-center">
                                    <a href="#" className="submit-btn"><img src="images/submit.png" />Submit</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>	
    );
  }
}

export default ComingSoon;
