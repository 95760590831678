import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";
import { getSessionTime } from '../../util/formatDateFunctions';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getOnDemandDuration } from '../../util/formatDateFunctions';

import moment from 'moment';
import moment1 from 'moment-timezone';


class ChannelOnDemand extends Component {
  
  constructor(props) {
	super(props);
	this.state={
    }
	
	this.getSessionTimeUtil = getSessionTime.bind(this);
	this.getSessionDate = getSessionDate.bind(this);
	this.getOnDemandDuration = getOnDemandDuration.bind(this);
}
 
componentDidMount(){
}

openSortDetails=(e)=>{
	if ( this.props.state.openSort )
		{
			this.props.updateStateFromChild({openSort: false, activeFilter: ''}, e);
		}
	else 
		{
			this.props.updateStateFromChild({openSort: true, activeFilter: ''}, e);
		}		
	
}

openSessionDetails=(e, mod)=>{
	if ( this.props.state.sessionDetailsOnDemandId == mod )
		{
			this.props.updateStateFromChild({sessionDetailsOnDemandId: 0, activeFilter: ''}, e);
		}
	else 
		{
			this.props.updateStateFromChild({sessionDetailsOnDemandId: mod, activeFilter: ''}, e);
		}		
	
}

selectSortOption=(e, mod)=>{
	
	this.props.updateStateFromChild({sortName: mod, openSort: false}, e);
	setTimeout(() => { this.props.getOnDemandsSessions(this.props.state.channelObject.id); });
}

openOnDemand=(e, mod)=>{
	e.stopPropagation();
	this.props.openDemandSession(mod.id, mod);
	this.props.updateStateFromChild({sessionType: 'On Demand', justSignIn: false, activeFilter: ''}, e);
}

getSessionDateFilter=(date)=>{
	let dateArray = date.split('@')
	let todayDate = dateArray[0];
	
	return todayDate;
}

  render() {
	 return (
	 <div className="channel_topscroll">
		<div className="channel_subscroll" >
				<div className="upc_channel" >		
					<div className="sortHolderOd" >
						<div className="sortTextOd" >Sort By</div>
						<div className={classnames("sortOption " + (this.props.state.openSort ? "activeDurationHolder" : "") )}  onClick={(e)=>{this.openSortDetails(e)}} >
							<div className="actPopPart" >{this.props.state.sortName}</div>
							<div className="actPopPart sortOptionArrowSvg" >
								{this.props.state.openSort ?
									<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 4.5L7.96317 1.01841L14.9263 4.5" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
									:
									<svg width="16" height="6" viewBox="0 0 16 6" fill='none' xmlns="http://www.w3.org/2000/svg">
										<path d="M15 1L8.03683 4.48159L1.07366 1" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								}
							</div>
						</div>
						{this.props.state.openSort && 
							<div className="sortOptionsHolder" >
								<div className="sortOptionText" onClick={ (e)=>{this.selectSortOption(e, 'Newest') }} >Newest</div>
								<div className="sortOptionText" onClick={ (e)=>{this.selectSortOption(e, 'Oldest') }} >Oldest</div>
								<div className="sortOptionText" onClick={ (e)=>{this.selectSortOption(e, 'Views (Low to High)') }} >Views (Low to High)</div>
								<div className="sortOptionText" onClick={ (e)=>{this.selectSortOption(e, 'Views (High to Low)') }} >Views (High to Low)</div>
								<div className="sortOptionText" onClick={ (e)=>{this.selectSortOption(e, 'A-Z') }} >A-Z</div>
								<div className="sortOptionText"  onClick={ (e)=>{this.selectSortOption(e, 'Z-A') }} >Z-A</div>
							</div>
						}
					</div>
					<div className="ch_up_date" >Results</div>
					{ this.props.state.mainDemandObject['allDemandSession'] && this.props.state.mainDemandObject['allDemandSession'].map((item, i)=> 
						<div key={i} >
							<div className="onDemandPartCh posRel">
								<div className="odPartInl margPart1 odPartInl_image" >
									<div className="ODVideoCh" style={{ backgroundImage: item.image != '' ? "url("+item.image+")" : ""}}  onClick={(e)=>{this.openOnDemand(e, item)}} >
										<div className="onDemandDurationCh" >{this.getOnDemandDuration(item.duration)}</div>
									</div>
								</div>
								<div className="odPartInl margPart2 odChName" onClick={(e)=>{this.openOnDemand(e, item)}}>{item.name}</div>
								<div className="odPartInl" >
									<div className="intrPartCh margPart2" >{item.interest_titles}</div>
								</div>
								<div className="odPartInl margPart2" >
									<div className={classnames("levelPartCh " + (item.level == 'Advanced' ? "redLvl" : "" ) + (item.level == 'Intermediate' ? "yellowLvl" : "" ) + (item.level == 'Beginner' ? "greenLvl" : "" ) )} >{item.level}</div>
								</div>
								<div className="odPartInl hostPartCh margPart2" >{item.firstName} {item.lastName}</div>
								<div className="odPartInl margPart2 textAlignDate" >
									<div className="avaDateHolder" >
										<div className="avaiableText" >Recorded date</div>
										{item.createdAt ?
											<div className="avaiableDateCh" >{this.getSessionDate(item.createdAt)}</div>
											:
											<div className="avaiableDateChCent" >NA</div>
										}
									</div>		
								</div>
								<div className="odPartInl viewsChHold" >
									<div className="viewsChImg" >
									<svg width="22" height="19" viewBox="0 0 22 19" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
										<path d="M0.180908 9.7251C1.12091 4.6051 5.60791 0.725098 10.9999 0.725098C16.3919 0.725098 20.8779 4.6051 21.8189 9.7251C20.8789 14.8451 16.3919 18.7251 10.9999 18.7251C5.60791 18.7251 1.12191 14.8451 0.180908 9.7251ZM10.9999 14.7251C12.326 14.7251 13.5978 14.1983 14.5354 13.2606C15.4731 12.3229 15.9999 11.0512 15.9999 9.7251C15.9999 8.39902 15.4731 7.12725 14.5354 6.18956C13.5978 5.25188 12.326 4.7251 10.9999 4.7251C9.67383 4.7251 8.40206 5.25188 7.46437 6.18956C6.52669 7.12725 5.99991 8.39902 5.99991 9.7251C5.99991 11.0512 6.52669 12.3229 7.46437 13.2606C8.40206 14.1983 9.67383 14.7251 10.9999 14.7251ZM10.9999 12.7251C10.2043 12.7251 9.4412 12.409 8.87859 11.8464C8.31598 11.2838 7.99991 10.5207 7.99991 9.7251C7.99991 8.92945 8.31598 8.16639 8.87859 7.60378C9.4412 7.04117 10.2043 6.7251 10.9999 6.7251C11.7956 6.7251 12.5586 7.04117 13.1212 7.60378C13.6838 8.16639 13.9999 8.92945 13.9999 9.7251C13.9999 10.5207 13.6838 11.2838 13.1212 11.8464C12.5586 12.409 11.7956 12.7251 10.9999 12.7251Z"/>
									</svg>
									</div>
									<div className="viewODch" >{item.totalviews}</div>
								</div>
								<div className={"sesArrow chleft17 " + (item.id == this.props.state.sessionDetailsOnDemandId ? "active" : "" )} onClick={ (e)=>{this.openSessionDetails(e, item.id)}}>
									{ item.id == this.props.state.sessionDetailsOnDemandId ?
										<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1 4.5L7.96317 1.01841L14.9263 4.5" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
										:
										<svg width="16" height="6" viewBox="0 0 16 6" fill='none' xmlns="http://www.w3.org/2000/svg">
											<path d="M15 1L8.03683 4.48159L1.07366 1" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									}
								</div>
							</div>
							{ item.id == this.props.state.sessionDetailsOnDemandId &&
										<div className="bottomPartCh" >
											<div className="bottomPart1Ch" >
												<div className="descBottom1" >Description</div>
												<div className="descDataBottom" >{item.description}</div>
												<div className="hostBtmMarg" >
													<div className="hostImgBtm" style={{ backgroundImage: item.userImage != '' ? "url("+item.userImage+")" : "url('/images/pic.png')"}}  ></div>
													<div className="hostBottomPart" >{item.firstName} {item.lastName}</div>
												</div>
												<div className="instrucotrBioCh" dangerouslySetInnerHTML={{__html: item.instructorText}}></div>
												<div className="descDataBottom">
													{item.availableUntil && <div className="avaiableDateCh2" >Available Until<br />{this.getSessionDateFilter(item.availableUntil)}</div> }
												</div>
												<div className="btnsBottomHolder" >		
													{item.activityArray.length > 0 &&										
														<div className="act_btn_channel" onClick={(e)=>{this.props.openActivityList(e, item)}} >View Activities</div>
													}
												</div>
											</div>
											<div className="bottomPart2Ch" >
												<div className="descBottom1" >Categories</div>
												{  item.interest_array_titles && item.interest_array_titles.map((inte, c)=>
													<div className="interestMap" key={c} >{inte}</div>
												)}
											</div>
											<div className="bottomPart2Ch" >
												<div className="descBottom1" >Required Equipment</div>
												<div className="euipmentHolderScroll" >
													{  item.equipmentList && item.equipmentList.map((eq, e)=>
														<div className="interestMap" key={e} >{eq}</div>
													)}
												</div>
											</div>
										</div>
							}
						</div>
					)}
					{this.props.state.allOnDemandLength > this.props.state.mainDemandObject['allDemandSession'].length &&
						<div className="loadMoreHolderCh" >
								<div className="btnPartCh" onClick={(e)=>{this.props.loadMoreOndemand(e)}} >Load more</div>
						</div>
					}
				</div>
		</div>
	</div>		
    );
  }
}
export default ChannelOnDemand;
