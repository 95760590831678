import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
import { deleteCookie } from '../auth/Logout';
import SupportContainer from '../home/SupportContainer';

class channelInterests extends Component {
  
  constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state={
		hostNameData: '',
		channelInterestArray: [],
		channelInterestIdsArray: [],
		tempChannelInterestIdsArray: [],
		mainInterests: [],
		tempMainInterests: [],
		interestToDelete: 0,
		userAvatar: '',
		supportPageName: 'Channel Interests',
		supportPageApi: '/channelInterests',
		userTypeFaqs: 2
    }
	this.deleteCookie = deleteCookie.bind(this);
}
 
componentDidMount(){
	this.setState({userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image});
	let hostName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName;
    this.setState({
        hostNameData: hostName,
    });

	this.getInterests();
}

getInterests=()=>{
	let postdata = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.channel }
    axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getChannelInterests", postdata)
	.then(res => {
			let ids = [];
			for ( var i = 0; i < res.data.responseData.length; i++ )
				{
					ids.push(res.data.responseData[i].id);
				}
			
			this.setState({
				channelInterestArray: res.data.responseData,
				channelInterestIdsArray: ids
			});
		$('.main_loader').hide(); 
	
	}).catch(err =>{ });
	
	let groupId = JSON.parse(localStorage.getItem("groupId"));
	 axios.get(process.env.REACT_APP_NAME+"/api/v1/user/"+groupId+"/getInterest")          
	.then(res => {
			this.setState({
				mainInterests: res.data.responseData
			});
	}).catch(err =>{ $('.main_loader').hide();  });
}

openAddingInterest=()=>{
	let mainInterests = JSON.parse(JSON.stringify(this.state.mainInterests));
	let channelInterestIdsArray = JSON.parse(JSON.stringify(this.state.channelInterestIdsArray));
	
	this.setState({
				tempChannelInterestIdsArray: channelInterestIdsArray,
				tempMainInterests: mainInterests
			});
			
	$('#interests_pop').attr({'style':'display:block'});
}



closeInterest=()=>{
	let mainInterests = JSON.parse(JSON.stringify(this.state.tempMainInterests));
	let channelInterestIdsArray = JSON.parse(JSON.stringify(this.state.tempChannelInterestIdsArray));
	
	this.setState({
				mainInterests: mainInterests,
				channelInterestIdsArray: channelInterestIdsArray
			});
	
	$("#interests_pop").attr({'style':'display:none'});
}

selectChannelInterest=(i)=>{
	let ids = this.state.channelInterestIdsArray;
	if ( ids.indexOf(this.state.mainInterests[i].id) == -1 )
		{
			ids.push(this.state.mainInterests[i].id);
		}
	else
		{
			let indexOf = ids.indexOf(this.state.mainInterests[i].id);
			ids.splice(indexOf, 1);
		}
		
	this.setState({
			channelInterestIdsArray: ids
		});
}

confrimInterests=()=>{
	if ( this.state.channelInterestIdsArray.length == 0 )
		{
			$("#add_interest_error").attr({'style':'display:block'});
			return false;
		}
	$('.main_loader').attr({'style':'display:flex'}); 
	let postdatadelete = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.channel }
	let postdataadd = { ids: this.state.channelInterestIdsArray, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel }
	
	//delete all interest
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/deleteChannelInterest", postdatadelete)
	.then(res => {
		
		// adding all selected interest
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/addNewInterests", postdataadd)
		.then(res => {
			this.getInterests();
			$("#interests_pop").attr({'style':'display:none'});
		}).catch(err =>{ });
	
	}).catch(err =>{ });
}

selectInterestToDelete=(i)=>{
	let interestToDeleteVar = this.state.channelInterestArray[i].id;
	
	this.setState({
			interestToDelete: interestToDeleteVar
		});
	
	$('#change_intpop').attr({'style':'display:block'});
}

deleteInterest=()=>{
	let postdata = {interestId: this.state.interestToDelete, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/deleteSpecificInterest", postdata)
	.then(res => {
		this.getInterests();
		$("#change_intpop").attr({'style':'display:none'});
	}).catch(err =>{ });	
}


  render() {
	 return (
       <div>
			<div className="container-fluid custom-width">
				<div className="row top-header px-2 px-md-4 py-4">
					<div className="col-lg-6 flex-wrap d-flex justify-content-center justify-content-lg-start p-2">
						<div className="align-self-center">
							<img src="/images/login-logo.png" className="logo my-2 cursor-pointer" alt="logo" onClick={e=>{$("#go_Dashboard").attr({'style':'display:block'});browserHistory.push('/DashboardLanding')}}/>
						</div>
						<div className="d-flex d-md-block justify-content-center px-2 px-md-4 py-2">
							<div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
								<div className="pl-4">
									<h3>Welcome {this.state.hostNameData}!</h3>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 txt_right">
						<button className="btn btn-outline-secondary font-weight-bold radius-8  align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}}>LOG OUT</button>
					</div>
				</div> 
			</div>
			<div className="for_all_members">
				<div className="members_container relative">
					<Link to='/DashboardLanding'>
						<div className="closePopup mainclose_position"></div>
					</Link>
					<div className="padd_bord">
						<div className="mt-4 menu_hosts">
							<h4 className="text-white d-inline-block font-22 pt-1 pl-2 mb-0">Add Category</h4>
							<Link className="pick ml-4 cursor">
								<img src="/images/add.png" alt="" onClick={this.openAddingInterest} />
							</Link>
						</div>
					</div>
					<div className="for_members_menu">
						<div className="one_menu_host interest_menu">Category</div>
						<div className="one_menu_host interest_menu">Status</div>
					</div>
					{ this.state.channelInterestArray.length > 0 &&
						<div className="padd_hosts">
							{this.state.channelInterestArray.map((row, i)=>
								<div className="one_member" key={i}>
									<div className="one_menu_host interest_menu host_rows">{row.title}</div>
									<div className="one_menu_host interest_menu host_rows">
										{ this.state.channelInterestArray.length > 1 ?
											<button className="btn btn-outline-success" onClick={ () => this.selectInterestToDelete(i) }>Active</button>
											:
											<button className="btn btn-outline-success" onClick={ () => this.props.showMainErrorPop('You must have at least one Interest') }>Active</button> 
										}
									</div>
								</div>
							)}  
						</div>
					}
					{ this.state.channelInterestArray.length === 0 &&
						<div className="no_hosts">No available Interests.</div>
					}
				</div>
			</div>
			
			<div className="modal pr-0" id="interests_pop" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="for_int_iks h50 relative">
							<div className="closePopup mainclose_position" onClick={this.closeInterest}></div>
						</div>
						<div className="all_intpop">
							<div className="inter_title">Select Category</div>
							<div className="row mx-0 px-4">
								{this.state.mainInterests.map((row,i) => ( 
									<div className="col-lg-4 col-md-6 pl-md-0" key = {i}>
										<p className="checktxt_name mb-3 ml-5 mt-2">{row.title}</p>
										<label className="custom-control custom-checkbox lebelheight pl-0">
											<input type="checkbox"
											checked = {this.state.channelInterestIdsArray.indexOf(row.id) > -1}
											onChange = {() => this.selectChannelInterest(i)}
											className="form-radio cursor" />
											<img src={row.image} className="ml-2 mr-3 interest_img cursor" alt="user-icon" />
										</label>
									</div>
								))}
							</div>
						</div>
						<div className="text-center">
							<button type="button" className="custom_btn1 mt-3" onClick={this.confrimInterests}>OK</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="change_intpop" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
							<div className="py-4 text-light text-center font-20">Are you sure you want to set status to Inactive?</div> 	
						</div>
						<div className="text-center">
							<button type="button" className="custom_btn1 mt-3" onClick={e=>{$("#change_intpop").attr({'style':'display:none'});}}>Cancel</button>
							<button type="button" className="custom_btn1 mt-3 ml-20" onClick={this.deleteInterest}>Yes</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="add_interest_error" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Please select at least one Category.</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal" onClick={e=>$("#add_interest_error").attr({'style':'display:none'})}>ok</button></div>
					</div>
				</div>
			</div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(channelInterests);
