import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { browserHistory} from 'react-router'
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
class SessionCharges extends Component {
  
  constructor(props) {
    super(props); 
}
  render() {
    return (
			<div>
				{ this.props.state.sessionCharge &&
					<div className="charge_containter">
						<div className="charge_inner">
							<div className="mobileclear">
								<div className="displ_inl marg_r10">For Live Class</div>
								<div className="displ_inl add_width70">
									<div className="add_width47 displ_inl marg_r10">
										<div className="mb10"><span>Member</span><img src="/images/bulb.png" className="ml-3 mb-2" /></div>
										<div className="pos_relat">
											<span className="cover-border cover-border_create"></span>
											<label className="label">Charges<sup></sup></label>
											<div data-toggle="modal" className="">
												<input
												  type="text"
												  className="input-field add_inp fields_back"
												  id = "liveSessionMemberCharges"
												  value = {this.props.state.liveSessionMemberCharges}
												  onChange = {(e)=>this.props.updateStateFromChild({[e.target.id] : e.target.value})}
												  />
												<span className="dollar_sign"></span>
											</div>
										</div>
									</div>
									<div className="add_width47 displ_inl">
										<div className="mb10"><span>Non-Member</span><img src="/images/bulb.png" className="ml-3 mb-2" /></div>
										<div className="pos_relat">
											<span className="cover-border cover-border_create"></span>
											<label className="label">Charges<sup></sup></label>
											<div data-toggle="modal" className="">
												<input
												  type="text"
												  className="input-field add_inp fields_back"
												  id = "liveSessionNonMemberCharges"
												  value = {this.props.state.liveSessionNonMemberCharges}
												  onChange = {(e)=>this.props.updateStateFromChild({[e.target.id] : e.target.value})}
												  />
												<span className="dollar_sign"></span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="mobileclear">
								<div className="displ_inl marg_r10">For On-demand</div>
								<div className="displ_inl add_width70">
									<div className="add_width47 displ_inl marg_r10">
										<div className="pos_relat">
											<span className="cover-border cover-border_create"></span>
											<label className="label">Charges<sup></sup></label>
											<div data-toggle="modal" data-target="#coming_soon_alert" className="">
												<input
												  type="text"
												  className="input-field add_inp fields_back"
												  id = "onDemandMemberCharges"
												  value = {this.props.state.onDemandMemberCharges}
												  disabled
												  onChange = {(e)=>this.props.updateStateFromChild({[e.target.id] : e.target.value})}
												  />
												<span className="dollar_sign"></span>
											</div>
										</div>
									</div>
									<div className="add_width47 displ_inl">
										<div className="pos_relat">
											<span className="cover-border cover-border_create"></span>
											<label className="label">Charges<sup></sup></label>
											<div data-toggle="modal" data-target="#coming_soon_alert" className="">
												<input
												  type="text"
												  className="input-field add_inp fields_back"
												  id = "onDemandNonMemberCharges"
												  value = {this.props.state.onDemandNonMemberCharges}
												  disabled
												  onChange = {(e)=>this.props.updateStateFromChild({[e.target.id] : e.target.value})}
												  />
												<span className="dollar_sign"></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
				{ !this.props.state.sessionCharge &&
					<div className="charge_info">Set Charging for Class toggle ON so you can enter prices for Class.</div>
				}
			</div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(SessionCharges);
