import React from 'react';
import $ from 'jquery';
const Songs = props => {

	var { playlist_name, num_songs, playlist_length, browser, browser_type } = props.songInfo;
	
    return <div>
		<div className="song_main_holder" onMouseLeave={props.handleDraggEnd} onClick={() => props.handleOpenList(props.index, props.mainIndex) } >
			<div className={"songs_list_item " + (props.openedList === props.index ? "active" : "")}>
				<div className="about_song song11">{playlist_name}</div>
				<div className="about_song song2">{num_songs}</div>
				<div className="about_song">{playlist_length}</div>
					{
						props.isFromPopup && 
						<div onClick={(e) => props.handleSelectItems(e, props.songInfo) } className={"checkBox " + (props.selectedPlaylist == props.index ? 'active' : '' )}></div>
					}
				<div className={"about_song " + (!props.isFromPopup ? "add_poss_rell" : "")}>{browser} { browser_type && '/ ' + browser_type}
					{
						!props.isFromPopup && 
						<div className="add_rem_button" onClick={(e) => props.tryDeletePlaylist(e, props.index) }>
							<div className="bg-circle mr-1 mr-lg-2 mb-1"><i className="fa fa-minus" aria-hidden="true"></i></div>
						</div>
					}
			</div>
			</div>
			<div className={"playlist_songs " + (props.openedList === props.index ? "active" : "") } onMouseLeave={props.handleDraggEnd} onClick={(e) => e.stopPropagation()}>
				<div className="pl_song_heder">
					<div className="s1">Name</div>
					<div className="s2">Length</div>
					<div className="s1">Artist</div>
				</div>
				<div className="plist_song_list" onMouseUp={props.handleDraggEnd}>
					{
						props.songInfo.songs.map((item, key) =>
							<div className="plist_main_hold" key={item.id} data-itemkey={key} data-oldarrange={key} onMouseDown={props.handleDraggStart} onMouseMove={props.handleDraggitem}>
								<div className="psong_item">
									<div className="s1">
										<div className="songNameWrap" >{item.name}</div>
									</div>
									<div className="s2">{item.length}</div>
									<div className="s1">{item.artist}</div>
								</div>
							</div>
						)
					}
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="confirm_delete_playlist" aria-modal="true">
			<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
			  <div className="modal-content bg-transparent px-2">
				<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
							<p className="py-4 text-light text-center font-20">Are you sure you want to delete playlist?</p> 	
				  
				  </div>
				  <div className="text-center">
				  <button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={() => props.deletePlaylist()}>yes</button>
				  <button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={e=>{$("#confirm_delete_playlist").attr({'style':'display:none'});}}>no</button>
				  </div>
			  </div>
			</div>
		  </div>
    </div>
}

export default Songs; 