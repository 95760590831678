import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";
import SessionFeedback from '../home/SessionFeedback';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class PastSessionsParticipant extends Component {
  
  constructor(props) {
	super(props);
	this.state={
		months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		sessionData: [],
		sessionActivity: [],
		sessionNotes: [],
		firstActivityName: '',
    }
	
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}
 
componentDidMount(){
	
}

getDate(date)
{
	let day = new Date(date).getDate();
	let month = this.state.months[new Date(date).getMonth()];
	let year = new Date(date).getFullYear();
	let finalDate = day + ' ' +  month + ', ' + year;
	return finalDate;
}

openFeedback=(mod)=>{
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		axios.defaults.headers.common['Authorization'] = token;	 
		$('.main_loader').attr({'style':'display:flex'});
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/pastSessionFeedback",{ "sessionId" : mod.id })
		.then(res => {
			$('.main_loader').hide();
			if ( res.data.responseData.ActivityList.length > 0 )
				{
					this.setState({
						sessionActivity: res.data.responseData.ActivityList,
						firstActivityName: res.data.responseData.ActivityList[0].name
					})
				}
			if ( res.data.responseData.notesList.length > 0 )
				{
					this.setState({
						sessionNotes: res.data.responseData.notesList
					})
				}
			this.setState({
				sessionData: mod
			}, () => {
				window.$('#FeedbackPop').modal('show');
				$('.hidden_div_sign').trigger('click');
				});	
		});
}
  render() {
	 return (
		<div className="marg_tp10" >
			{ this.props.state.sessionPreview === 'square' &&
			<div className="all_demain_view">
			{this.props.state.pastSessionData.map((item, i)=>
				<div className="demand_preview_square ch_h380" key={i}>
					<div className="all_inner_square new_back auto_cursor padd_participant pb120 pb70">
						
						{item.interestTitles &&
							<div className="footer_pad_participant">
								<div className="pdash_50 txt_lft top_align">
									<div className="pdash_fnt16">
										<span className="relative_interest">{item.interestTitles}{item.sessionPublicChannel == 0 ? ' [H]' : ''}
											{ !this.props.state.isMarketplaceChannel &&
												<div className="">
													<div className="level_title">{item.level}</div>
														{ item.level === 'Beginner' &&
															<div className="level_square green_square"></div>
														}
														{ item.level === 'Intermediate' &&  
															<div className="level_square yellow_square"></div>
														}
														{ item.level === 'Advanced' &&
															<div className="level_square red_square"></div>
														}
														{ item.level === 'Multi-Level' &&
															<div className="level_square multi_level_square"></div>
														}
												</div>
											}
										</span>
										{item.replayClassId > 0 &&
											<span className="replaySpanText">Replay</span>
										}
									</div>
								</div>
							</div>
						}
						<div className="footer_pad">
							<div className="name_holder_test" >
								<div className="footer_square100 main_color_part less_wd mb0 font17 inl_50 vrt_tp t_wrap w_65">{item.name}</div>
								{this.props.state.isHostDashboard == 1 && ( item.sessionStat === 'Canceled' || item.sessionStat === 'Attended' )&& new Date().getTime() < new Date(item.sessionEndDate.toString()).getTime() && (this.props.state.loggedUserData.id == item.hostId || this.props.state.isAdmin == 1) &&
									<button className="new_reopen_btn" onClick={e=>{this.props.reopenSession(item.id, i, item)}} >RE-OPEN</button>
								}
								{item.interestTitles ? 
									<div className="inl_50 att_status vrt_tp w_35 def_sessionStatPosition"> {item.sessionStat}</div>
									:
									<div className="inl_50 att_status vrt_tp w_35 def_sessionStatPosition"> {item.sessionStat}</div>
								}
							</div>
							{this.props.state.isHostDashboard == 1 &&
								<div>	
									{ item.totalParticipantsNum && item.totalParticipantsNum > 0 ?
										<div>
											<p className="mr-3 font_size_dash">Signed Up {item.totalSignUp}/{item.maxAttendee} (max)
												<span className="w20 marg_left_span" onClick={this.props.participantShowList}><img src="/images/information.png" name = {item.id}  className="mr-3 w-20 cursor-pointer mr0"  alt="" /></span>
											</p>
										</div>
										:
										<p className="mr-3 font_size_dash">Signed Up 0/{item.maxAttendee} (max)</p>
									
									}
								</div>
								}
														
							<div className="all_width_participant">
								<div className="host_name_prt vt_mdl">{item.hostFirstName} {item.hostLastName}</div>
								<div className="inline_parts_participant">
									<div className="inline_parts_img time_img"></div>
									{ this.getSessionDate(item.scheduleDate)} @ {this.getSessionTime(item.scheduleDate)}
								</div>
								<div className="inline_parts_participant">
									<div className="inline_parts_img clock_img"></div>
									{item.duration} Min
								</div>
							</div>
						</div>
						
						<div className="footer_pad marg_tp10">							
							{ item.description.length <= 50 &&
								<div className="relative pl25 pr25 description_demand text_wrap btn_disp">{item.description}
									<div className="icon_foot_part_l list_img"></div>
								</div>
							}
							{ item.description.length > 50 &&
								<div className="relative pl25 pr25 description_demand btn_disp desc_show_width">
									<span className="dots_text">{item.description}</span>
									<div className="icon_foot_part_l list_img"></div>	
									<div className={"icon_foot_part_r plus_main " + (item.descriptionDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openPastDrops(e, i, 'descriptionDropOpen') }}></div>							
									{ item.descriptionDropOpen && 
										<div className="abs_demand_drop drops_more" onClick={this.props.preventDef}>{item.description}</div>
									}
								</div>
							}
						</div>
						
						{ item.equipmentListName && item.equipmentListName != '' &&
							<div className="new_list_mych w50per_participant">
								{ item.equipmentListName.length <= 30 &&
									<div className="description_demand relative pl25 pr25">{item.equipmentListName}
										<div className="icon_foot_part_l wrench_img"></div>									
									</div>											
								}
								{ item.equipmentListName.length > 30 &&
									<div className="description_demand relative pl25 pr25"><span className="dots_text">{item.equipmentListName}</span>
									<div className="icon_foot_part_l wrench_img"></div>					
									<div className={"icon_foot_part_r plus_main " + (item.equipmentDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openPastDrops(e, i, 'equipmentDropOpen') }}></div>
										{ item.equipmentDropOpen &&
											<div className="abs_demand_drop drops_more" onClick={this.props.preventDef}>{item.equipmentListName}</div>
										}
									</div>
								}
							</div>
						}
						
						<div className={"footer_pad abs_btns_participants txt_right tx_cent " + (this.props.state.isHostDashboard == 1 ? 'chpad10b' : '')}>
							{ item.feedback > 0 &&
								<div className="new_view_button upc_list_canc btn_disp mrg_btn new_btn_less_px mrg_b10 dspl_blc" onClick={e=>{this.openFeedback(item)}}>CLASS FEEDBACK
										<div className="feedback_not" >{item.feedback}</div>
								</div>
							}
							{item.activityArray && item.activityArray.length > 0 &&
								<div className="new_view_button upc_list_canc btn_disp new_btn_less_px dspl_blc" onClick={e=>{this.props.openActivityPop(item)}} >VIEW ACTIVITY LIST</div>
							}
						</div>
					</div>
				</div>
				)}
			</div>
			}
			{ this.props.state.sessionPreview === 'list' &&
				<div className="all_demain_view">
					{this.props.state.pastSessionData.map((item, i)=>
					<div className={"listHolder_mych list_holderpadb new_padd_list_btn "+(i%2 == 0 ? '' : 'list_2nd_upcpart' )} key={i} >
						<div className="relative_list_info relative_list_right nprght">
							{this.props.state.isHostDashboard == 0 ?
								<div className="abs_demand_image mych_abs_image h98per list_img_mobile" style={{backgroundImage: "url('" + item.image + "')"}} ></div>
								:
								<div className="abs_demand_image mych_abs_image h98per list_img_mobile" style={{backgroundImage: "url('" + item.sessionImage + "')"}} ></div>
							}
							{item.interestTitles &&
							<div className="list_header mb0 relative_header_list">
								<span className="relative_interest">{item.interestTitles}
									{ !this.props.state.isMarketplaceChannel &&
										<div className="abs_levelsupc">
											<div className="level_title">{item.level}</div>
											{ item.level === 'Beginner' &&
												<div className="level_square green_square"></div>
											}
											{ item.level === 'Intermediate' &&  
												<div className="level_square yellow_square"></div>
											}
											{ item.level === 'Advanced' &&
												<div className="level_square red_square"></div>
											}
											{ item.level === 'Multi-Level' &&
												<div className="level_square multi_level_square"></div>
											}
										</div>
									}
								</span>
								{item.replayClassId > 0 &&
									<span className="replaySpanText">Replay</span>
								}
							</div>
							}
							<div className="footer_pad">
								<div className="disp-new main_color_part mb0 font17">{item.name}</div>
								<div className="host_name_prt_list">{item.hostFirstName} {item.hostLastName}</div>
								<div className="footer_square100 main_color_part less_wd mb0 font17">{item.sessionStat}</div>
								{this.props.state.isHostDashboard == 1 &&
										<div>
											{ item.totalParticipantsNum && item.totalParticipantsNum > 0 ?
												<div>
													<p className="mr-3 font_size_dash">Signed Up {item.totalSignUp}/{item.maxAttendee} (max)
														<span className="w20 marg_left_span" onClick={this.props.participantShowList}><img src="/images/information.png" name = {item.id}  className="mr-3 w-20 cursor-pointer mr0"  alt="" /></span>
													</p>
												</div>
												:
												<p className="mr-3 font_size_dash">Signed Up 0/{item.maxAttendee} (max)</p>
											}
										</div>
									}
								<div className="all_width_participant">
									
									<div className="inline_parts_participant">
										<div className="inline_parts_img time_img"></div>
										{ this.getSessionDate(item.scheduleDate)} @ {this.getSessionTime(item.scheduleDate)}
									</div>
									<div className="inline_parts_participant">
										<div className="inline_parts_img clock_img"></div>
										{item.duration} Min
									</div>
								</div>
							</div>
							<div className="footer_pad marg_tp10 pb0">							
								{ item.description.length <= 50 &&
									<div className="relative pl25 pr25 description_demand text_wrap btn_disp">{item.description}
										<div className="icon_foot_part_l list_img"></div>
									</div>
								}
								{ item.description.length > 50 &&
									<div className="relative pl25 pr25 description_demand btn_disp desc_show_width_list mw80per">
										<span className="dots_text">{item.description}</span>
										<div className="icon_foot_part_l list_img"></div>					
										<div className={"icon_foot_part_r plus_main " + (item.descriptionDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openPastDrops(e, i, 'descriptionDropOpen') }}></div>
										{ item.descriptionDropOpen && 
											<div className="abs_demand_drop drops_more" onClick={this.props.preventDef}>{item.description}</div>
										}
									</div>
								}
							</div>
							{ item.equipmentListName && item.equipmentListName != '' &&
								<div className="footer_pad">
									<div>
										{ item.equipmentListName.length <= 30 &&
											<div className="description_demand relative pl25 pr25 desc_show_width_list">{item.equipmentListName}
												<div className="icon_foot_part_l wrench_img"></div>									
											</div>											
										}
										{ item.equipmentListName.length > 30 &&
											<div className="description_demand relative pl25 pr25 desc_show_width_list"><span className="dots_text">{item.equipmentListName}</span>
											<div className="icon_foot_part_l wrench_img"></div>
											<div className={"icon_foot_part_r plus_main " + (item.equipmentDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openPastDrops(e, i, 'equipmentDropOpen') }}></div>								
											{ item.equipmentDropOpen &&
												<div className="abs_demand_drop drops_more" onClick={this.props.preventDef}>{item.equipmentListName}</div>
											}
										</div>
										}
									</div>
								</div>		
							}
							{ item.equipmentListName === '' &&
								<div className="empty_equipment"></div>
							}
							{this.props.state.isHostDashboard == 1 && ( item.sessionStat === 'Canceled' || item.sessionStat === 'Attended' ) && new Date().getTime() < new Date(item.sessionEndDate.toString()).getTime() && (this.props.state.loggedUserData.id == item.hostId || this.props.state.isAdmin == 1) &&
								<button className="new_reopen_btn"  onClick={e=>{this.props.reopenSession(item.id, i, item)}}> RE-OPEN </button> 
							}
						</div>
						<div className="act_btn_holder_list">	
							{ item.feedback > 0 &&
								<div className="half_btn_holder upper_btn">
									<div className="new_view_button upc_list_canc relative" onClick={e=>{this.openFeedback(item)}} >CLASS FEEDBACK
										<div className="feedback_not" >{item.feedback}</div>
									</div>
								</div>
							}
							{item.activityArray && item.activityArray.length > 0 &&
								<div className="half_btn_holder join_upcpart ch_cent">
									<div className="new_view_button upc_list_canc" onClick={e=>{this.props.openActivityPop(item)}} >VIEW ACTIVITY LIST</div>
								</div>
							}
						</div>	
					</div>
					)}
				</div>
			}
			{this.props.state.pastSessionsCounter > this.props.state.currentShownPast &&
				<div className="txt_center marg_tp30">
					{this.props.state.isHostDashboard == 0 &&
						<div className="pdash_button_more relative" onClick={this.props.getMorePastSessiosns}>Load more
							<div className="down_angle"></div>
						</div>
					}
					{this.props.state.isHostDashboard == 1 &&
						<div className="pdash_button_more relative" onClick={this.props.loadMorePrevious}>Load more
							<div className="down_angle"></div>
						</div>
					}
				</div>
			}
			<div className="modal pr-0" id="FeedbackPop" aria-modal="true">
				<SessionFeedback
				state={this.state}
				></SessionFeedback>
			</div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
	 )

  }
}
export default PastSessionsParticipant;
