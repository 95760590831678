import { SET_CURRENT_USER, SET_CURRENT_CHANNEL } from "../actions/types";

const initialState = 
	{
		logedUser: localStorage.getItem('userData') ?  JSON.parse(localStorage.getItem('userData')).data.responseData : {},
		channelData: localStorage.getItem("channelData") ? JSON.parse(localStorage.getItem("channelData")) : [],
		fullsubdomain: localStorage.getItem("fullsubdomain") ? localStorage.getItem("fullsubdomain") : "",
		totalSession: localStorage.getItem("totalSession") ? localStorage.getItem("totalSession") : 0,
	};
 
const user = (state = initialState, action) => {
	console.log("ACTIONSS ", action);
  switch (action.type) {
    case SET_CURRENT_USER: {
	  return {
				...state,
				logedUser: action.payload
			  }
    }
	case SET_CURRENT_CHANNEL: 
		{
			const channelData = action.payload.channelData ? action.payload.channelData : state.channelData;
			const subdomain = action.payload.subdomain ? action.payload.subdomain : state.fullsubdomain;
			const tottalSession = action.payload.tottalSession ? action.payload.tottalSession : state.totalSession;
			
			localStorage.setItem("channelData", JSON.stringify(channelData));
			localStorage.setItem("fullsubdomain", subdomain);
			localStorage.setItem("totalSession", tottalSession);
			return {
				...state,
				channelData: channelData,
				fullsubdomain: subdomain,
				totalSession: tottalSession
			}
		}
    default: {
      return state;
    }
  }
};

export default user;
