import React, { Component } from "react";
import { Link } from 'react-router';
import {  browserHistory} from 'react-router'

class signUp extends Component {
  constructor(props) {
    super(props);
    this.state={
        checkHost:false,
        checkParticipent:false
    }
}
 
componentDidMount(){
}
signUpPage=(e)=>{

}
render() {
    return (
        <div id="root">
        <div className="App d-flex justify-content-center align-items-center">
			<div className="mw-1260 px-2 px-md-4 my-4">
			    <div className="">
                    <h4 className="pick text-center mb-0 mb-md-1">Sign up for</h4>
                    <div className="text-center my-3 my-md-4"><img src="/images/login-logo.png" className="width-200" alt="#"/></div>
                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                        <div className="parti_box cursor-pointer mb-0">
                            <div className="b-image position-relative align-self-start" id="checkParticipent" onClick={(e)=>this.setState({[e.target.id]:!this.state.checkParticipent},()=>browserHistory.push("/participantSignup"))}>
                                <img src="/images/participate.png" alt="participate" />
                                {/* <label className="custom-control custom-checkbox">
                                    <input type="checkbox" 
                                    id="checkParticipent"
                                    checked={this.state.checkParticipent}
                                    onChange={(e)=>this.setState({[e.target.id]:!this.state.checkParticipent},()=>browserHistory.push("/participentSignup"))}
                                    className="form-radio" />                                   
                                </label> */}
                            </div>
                           <Link to = '/participantSignup' className="text-decoration-none"><p className="white mt-3 mb-0 mb-md-3">I want to participant in a class</p></Link>
                        </div>
                        <div className="parti_box cursor-pointer mb-0">
                            <div className="b-image position-relative align-self-start" id="checkHost" onClick={(e)=>this.setState({[e.target.id]:!this.state.checkHost},()=>browserHistory.push("/hostSignUp"))}>
                                <img src="images/host.png" alt="host" />
                                {/* <label className="custom-control custom-checkbox">
                                    <input type="checkbox"
                                    id="checkHost"
                                    checked={this.state.checkHost}
                                    onChange={(e)=>this.setState({[e.target.id]:!this.state.checkHost},()=>browserHistory.push("/hostSignUp"))}
                                    className="form-radio" />                                   
                                </label> */}
                            </div>
                            <Link to = '/hostSignUp' className="text-decoration-none"><p className="white mt-3 mb-0 mb-md-3">I want to host a class</p></Link>
                        </div>
                    </div>
                </div>
			</div>
		</div>        
	</div>
    )
  }
}

export default signUp;