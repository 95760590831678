import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import Calendar from 'react-calendar';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import { deleteCookie } from '../auth/Logout';
import SupportContainer from '../home/SupportContainer';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';


class SessionGroups extends Component {
  
  constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state={
        sessionData:[],
        firstName:'',
        firstName1:'',
        lastName:'',
        email:'',
        memberId:'',
        msgSuccess:'',
        getMemberId:0,
        hostName:'',
		endDateTemp: new Date(),
		enddate: '',
		limitedMember: false,
		importStatus: '',
		enableImport: false,
		userImports: [],
		userAvatar: '',
		channelCreateUser: false,
		mainFaqs: [],
		searchBy: '',
		groups: [],
		curentGroup : 0,
		supportPageName: 'Session Groups',
		supportPageApi: '/SessionGroups',
		userTypeFaqs: 2
    
    }
    this.validator = new SimpleReactValidator();  
    this.editValidator = new SimpleReactValidator();  
    this.deleteCookie = deleteCookie.bind(this);
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}
 
componentDidMount(){
	this.fetchGroupList();
	this.setState({userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image});
    let hostName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName
    this.setState({
        hostNameData:hostName
    });
	
	var marketplaceStatus = JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace;
	if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace != 1 )
		{
			browserHistory.push('/DashboardLanding'); 
		}

  };
 
fetchGroupList() {
    
      let channelId=localStorage.getItem("channelId");  
	  let searchText = this.state.searchBy;

      let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	  $('.main_loader').attr({'style':'display:flex'});
      axios.defaults.headers.common['Authorization'] = token;              
		axios.post(process.env.REACT_APP_NAME+"/api/v1/programs/getGroupList", { channel_id: channelId, searchText: searchText, count_show: 1000, type: 1 })          
        .then(res => {
		   $('.main_loader').hide();
           this.setState({
			  groups: res.data.responseData.sessionData
              });
          })
        .catch(err =>{
		  console.log('----------there is problem------------', err);
		   $('.main_loader').hide();
        });
}
	
getFaq=(e)=>{
	 axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqsById", {id: e})          
	.then(res => {
		this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs)});
	})
	.catch(err =>{ });  
}

openPreviews=(pg)=>{
	let mainUrl = mainUrl = 'https://'+this.state.fullsubdomain + "/#"+btoa(JSON.stringify({backroundColorPick: this.state.backroundColorPick, fontColorPick: this.state.fontColorPick, fontTitleColorPick: this.state.fontTitleColorPick, buttonsColorPick: this.state.buttonsColorPick, highlightColorPick: this.state.highlightColorPick, searchBackground: this.state.searchBackground, searchFont: this.state.searchFont, searchInputBackground: this.state.searchInputBackground, searchInputBorder: this.state.searchInputBorder}));
	
	window.open(mainUrl);
}
showNewPop = (mod) => 
	{
		$("#delete_template").attr({'style':'display:block'});
		this.setState({
			curentGroup : mod['id']
		});
	}
deleteGroup = e =>
{
	//console.log('CURRGROUP', this.state.curentGroup);
	$('.main_loader').attr({'style':'display:flex'});
		axios.post(process.env.REACT_APP_NAME+"/api/v1/programs/delete_group", {group_id : this.state.curentGroup})          
        .then(res => {
		   $("#delete_template").attr({'style':'display:none'});
		  this.fetchGroupList();
          })
        .catch(err =>{
		  console.log('----------there is problem------------', err);
		   $('.main_loader').hide();
        });
}	

openGroupEdit=(id)=>{
	browserHistory.push("/groupdetail/"+id); 
}

  render() {
	 return (
        <div>
            <div id="root">
                <div className="App">
                    <div className="container-fluid custom-width pb-5">
                        <div className="row top-header px-2 px-md-4 py-4">
                            <div className="col-lg-6 flex-wrap d-flex justify-content-center justify-content-lg-start p-2">
                            <div className="align-self-center">
                                <img src="/images/login-logo.png" className="logo my-2 cursor-pointer" alt="logo" onClick={e=>{browserHistory.push('/DashboardLanding')}}/>
                            </div>
                            <div className="d-flex d-md-block justify-content-center px-2 px-md-4 py-2">
                                <div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
                                <div className="pl-4">
                                <h3>Welcome {this.state.hostNameData}!</h3>
                                     {/* <p>No Class coming up this week</p> */}
                                    {/* <p>Next Class, Wednesday, 24 July 2019</p> */}
                                </div>
                                </div>
                            </div>
                            </div>
                             <div className="col-lg-6 ">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Average Attendance</p>
                                        <h3>0%</h3>
                                    </div>
                                    <span className=""></span>
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Views</p>
                                        <h3>0</h3>
                                    </div>
                                    {/* <span className="border-right gray-border"></span> */}
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Revenue</p>
                                        <h3>$0</h3>
                                    </div>
                                    {/* <span className="border-right gray-border"></span> */}
                                    <div className="message-notification mx-2 d-none">
                                        <img src="/images/message.png" alt = '#'/>
                                        <span className="message-count">0</span>
                                    </div>
                                    <button className="btn btn-outline-secondary font-weight-bold radius-8  align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}}>LOG OUT</button>
                                </div>
                            </div> 
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center flex-wrap">
                            <h4 className="text-white font-22 pt-1 pl-2 mb-2">Manage Member</h4>
                        </div> */}
                        <div className="gray-box2 radius-10 member-sec pb-5 relative">
                            <Link to='/DashboardLanding'>
								<div className="closePopup mainclose_position"></div>
							</Link>
                            <div className="d-flex justify-content-between align-items-center px-4 pb-4 border-bottom">
                                <div className="mt-4">
                                    <h4 className="text-white d-inline-block font-22 pt-1 pl-2 mb-0 membertopheader">Class Groups</h4>
									<Link onClick={e=>browserHistory.push('/GroupCreation')} className="pick ml-4 pointer"><img src="/images/add.png" alt=""/></Link>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center px-4 pb-4 flex-wrap">
                                <div className="mt-4 col-md-7 px-md-0 d-flex align-items-center">
                                    {/* <p className="text-white mb-0 font-18">Show</p>
                                    <select className="select_input-field mx-3" id="exampleFormControlSelect1">
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                    </select>
                                    <p className="text-white mb-0 font-18">Members</p> */}
                                </div>
                                <div className="mt-4 col-md-5 px-md-0">
                                    <div className="input-group mb-3 mt-4">
                                        <input type="text" className="form-control shadow-effect" placeholder="Search for Group Name" name="" onChange={(e) => this.setState({ searchBy: e.target.value })} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <a href="javascript:void(0)" onClick={()=>{this.fetchGroupList()}}><img src="/images/search.png" alt="search" /></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-md-4 membertable add_scroll_mem">
                                <div className="row mx-0 px-md-4 border-bottom">
                                    <div className="col-2 pl-sm-2 table_name_hold">
                                        <p className="text-white font-18">Name</p>
                                    </div>
                                    <div className="col-2 table_name_hold">
                                        <p className="text-white text-center font-18">Start Date</p>
                                    </div>
									<div className="col-2 table_name_hold">
                                        <p className="text-white text-center font-18">End Date</p>
                                    </div>
									<div className="col-1 table_name_hold">
                                        <p className="text-white text-center font-18">Subscribers</p>
                                    </div>
									<div className="col-1 table_name_hold">
                                        <p className="text-white text-center font-18">Type</p>
                                    </div>
                                    <div className="col-1 table_name_hold">
                                        <p className="text-white text-center font-18">Period</p>
                                    </div>
									<div className="col-1 table_name_hold">
                                        <p className="text-white text-center font-18">No. of classes</p>
                                    </div>
									<div className="col-1 table_name_hold">
                                        <p className="text-white text-center font-18">Reward</p>
                                    </div>
                                </div>
                                { this.state.groups.length > 0 &&	
									(this.state.groups.map((row,i)=>
										<div className="row mx-0 px-md-4 py-4" key={i}>
									   
											<div className="col-2 pl-sm-2 table_name_hold">
												<div className="d-flex">
													<p className="mb-0">{row.name}</p>
												</div>
											</div>

										<div className="col-2 d-flex justify-content-center align-items-center table_name_hold">
											<p className="mb-0">{row.group_type == 1 ? this.getSessionDate(row.start_date) : ''}</p>
										</div>
										
										<div className="col-2 d-flex justify-content-center align-items-center table_name_hold">
											<p className="mb-0">{row.group_type == 1 ? this.getSessionDate(row.end_date) : ''}</p>
										</div>
										
										<div className="col-1 d-flex justify-content-center align-items-center table_name_hold">
											<p className="mb-0">{row.member_count}</p>
										</div>
										
										<div className="col-1 d-flex justify-content-center align-items-center table_name_hold">
											<p className="mb-0">{row.group_type == 1 ? "Challenge" : "Program"}</p>
										</div>
										
										<div className="col-1 d-flex justify-content-center align-items-center table_name_hold">
											<p className="mb-0">{row.period} days</p>
										</div>
										
										<div className="col-1 d-flex justify-content-center align-items-center table_name_hold">
											<p className="mb-0">{row.session_count}</p>
										</div>	
										
										<div className="col-1 d-flex justify-content-center align-items-center table_name_hold">
											<p className="mb-0">{row.group_type == 1 ? row.reward : ''}</p>
										</div>									
										
										<div className="bg-circle mr-1 mr-lg-2 mb-1 pointer" onClick={() => this.openGroupEdit(row.id)}>
											<i className="fa fa-pencil"></i>
										</div>
										<div className="bg-circle mr-1 mr-lg-2 mb-1 pointer" onClick={ e => {this.showNewPop(row)}} >
											<i className="fa fa-minus" aria-hidden="true"></i>
										</div>
										</div>
									))
								}
								{ this.state.groups.length === 0 &&	
									<div className="empty_template">No results found.</div>
								}
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>			
	    
			<div className="modal pr-0" id="delete_template" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent relative">
						<button type="button" className="close white closepurple translatX-Y abs_close" onClick={e=>$("#delete_template").attr({'style':'display:none'})}><span>x</span></button>
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Are you sure you want to delete Group?</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={this.deleteGroup}>Delete</button></div>
					</div>
				</div>
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(SessionGroups);
