import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';

class EditVideoCutImage extends Component {
  
  constructor(props) {
	super(props);
	
	this.state = {
		editVideo: '',
		tag1mouseState: 0,
		dragEl: null,
		videoPosition: '00:00',
		videoDuration: '00:00',
		videoDurationNew: '00:00:00.00',
		videoDurationNewS: '00:00:00.00',
		videoDurationNewE: '00:00:00.00',
		videoPlaying: false,
		nowVideo: this.props.state.editOnDemandVideo.video,
		cutStart: 0,
		cutEnd: 0,
		originalDuration: 0
    }
}

draggVEDrag=(e)=>{

if ( this.state.dragEl !== null && this.state.dragEl.id == 'videdtg3' )
	{
		var zeroX =  $('.fullEditVideo_outmvid').offset().left;
		var diffX = e.clientX - zeroX;
		if ( diffX < 0 ) { diffX = 0; }
		if ( diffX > $('.fullEditVideo_outmvid').width() ) { diffX = $('.fullEditVideo_outmvid').width(); }
		
		this.state.dragEl.parentNode.style.width = (parseInt(diffX)  / $('.fullEditVideo_outmvid').width() * 100 ) + '%';
		
		this.calcDuration(parseInt(diffX)  / $('.fullEditVideo_outmvid').width());
	}

}

draggVEDragEnd=(e)=>{
	this.setState({dragEl: null}, () => { this.calcDuration(); });
}

calcDuration=(e)=>{
	var dur = $('.fullEditVideo_video video')[0].duration;
	var dur2 = dur;
	var newdur = this.getDurPercent(dur2);
	var newdur3 = this.getDurPercent(dur2, true);

	if ( e )
		{
			$('.fullEditVideo_video video')[0].currentTime = dur * e;
		}

	var newpos = this.getDurPercent($('.fullEditVideo_video video')[0].currentTime, true);

	this.setState({videoDuration: newdur3, videoPosition: newpos});

	var newwid = ($('.fullEditVideo_video video')[0].currentTime / dur * 100 );
	if ( newwid >= 100 && this.state.dragEl == null ) { newwid = 0; $('.fullEditVideo_video video')[0].currentTime = dur; $('.fullEditVideo_video video')[0].pause(); this.setState({videoPlaying: false}); }

	$('.fullEditVideo_inmvid')[0].style.width = newwid + '%';
}

getDurPercent=(dur, onlytwo)=>{
	var dhr = Math.floor(dur/60/60);
	var dmin = Math.floor(dur/60) - (dhr*60);
	var dsec = Math.floor(dur - (dmin*60) - (dhr*60*60));
	
	var dminsec = Math.floor((dur - dsec - (dmin*60) - (dhr*60*60))*100);
	
	dhr = dhr < 10 ? '0'+dhr : dhr;
	dmin = dmin < 10 ? '0'+dmin : dmin;
	dsec = dsec < 10 ? '0'+dsec : dsec;
	dminsec = dminsec < 10 ? '0'+dminsec : dminsec;
	
	
	return onlytwo ?  dhr+':'+dmin+':'+dsec : dhr+':'+dmin+':'+dsec+'.'+dminsec;
}

playPauseVideo=(e)=>{
	
	if ( $('.fullEditVideo_video video')[0].paused )
		{
			$('.fullEditVideo_video video')[0].play();
			this.setState({videoPlaying: true});
		}
	else
		{
			$('.fullEditVideo_video video')[0].pause();
			this.setState({videoPlaying: false});
		}
	
}

saveVideoCutImage=(e)=>{
	var canvas = document.getElementById('canvasImage');     
	var video = $('.fullEditVideo_video video')[0];

	canvas.width = video.videoWidth;
	canvas.height = video.videoHeight;
	canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

	this.props.importDemandImageCut(canvas.toDataURL('image/png'));
	this.props.closeVideoEdit();
}





  render() {
	 return (
		<div>
			<div className="fullEditVideo" onMouseUp={(e)=> { this.draggVEDragEnd(); }}>
				<div className="fullEditVideoInside" onMouseMove={(e)=>{ this.draggVEDrag(e); }}>
					<div className="closePopup" onClick={(e) => { this.props.closeVideoEdit(); }}></div>
					<div className="fullEditVideo_title">{this.props.state.editOnDemandVideo.name}</div>
					
					<div className="fullEditVideo_video">
						<video crossOrigin="anonymous" width="320" height="240" onClick={(e) => { this.playPauseVideo(); }} onTimeUpdate={() => { this.calcDuration(); }} onLoadedData={() => { this.calcDuration(); }}><source src={this.state.nowVideo} type="video/mp4" /></video>
						<div className="fullEditVideo_controls">
							<div className={'fullEditVideo_playbtn ' + (this.state.videoPlaying ? 'playingnow' : '')} onClick={(e) => { this.playPauseVideo(); }}></div>
							<div className="fullEditVideo_playtime">{this.state.videoPosition} / {this.state.videoDuration}</div>
							
							<div className="fullEditVideo_outmvid"><div className="fullEditVideo_inmvid"><div id="videdtg3" onMouseDown={(e)=> { e.preventDefault(); $('.fullEditVideo_video video')[0].pause(); this.setState({dragEl: e.currentTarget, videoPlaying: false}); }}></div></div></div>
						</div>
					</div>
				</div>
				
				<div className="form-content mb-0 ond_width">
					<div className="text-center">
						<button type="button" className="custom_btn1 mt-3 ml20" onClick={(e) => { this.saveVideoCutImage(); }}>Take Photo</button>
					</div>
				</div>
				<div className="canvasCont"><canvas id="canvasImage"></canvas></div>
			</div>
		</div>
    );
  }
}
export default EditVideoCutImage;
