import React, { Component } from "react";
// import { Link } from "react-router-dom";
//import PropTypes from "prop-types";@ak
import { connect } from "react-redux";
import { setCurrentUser } from "../../actions/authActions";
import classnames from "classnames";
import axios from "axios";
import $ from 'jquery';
import {  browserHistory,Link} from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import { showMainErrorPop } from '../../actions/popups';
import { setCookie } from '../../util/cookie';

import customProtocolCheck from "custom-protocol-check";
import { joinSessionValidate } from '../../util/joinSessionValidate';
import SupportContainer from '../home/SupportContainer';
import ParticipantSignedUp from '../home/participantSignedUp';

class Login extends Component {
  constructor(props) {
    super(props);
	if ( window.location.href.indexOf('login') > -1 || window.location.href.indexOf('session') > -1 || window.location.href.indexOf('verify-user') > -1 )
		{
			this.state = {
							email: '',
							password: '',
							type:"1",
							errors: {},
							errormsg:'',
							rememberMe:false,
							urlPath:'',
							urlElectron:'',
							appDownload:'',
							platformStatus:true,
							pageMessage:'',
							redirectPath:'/login',
							isLoginPage: true,
							showWindowsApp: true,
							dont_start_submit: false,
							deepLinkButton: 'Web App',
							passwordEye: false,
							supportPageName: 'Login',
							supportPageApi: '/login',
							userTypeFaqs: 2,
							showConfirmReset: false,
							userSignedUp: false,
							loggedUserData: {},
							sessionStartTimeHigher15Min: false,
							sessionScheduleDate: '',
							sessionTimezone: '',
							sessionChannelName: '',
							isHost: false,
							sessionName: '',
							isOnDemandSession: false,
							isMarketplaceUser: false,
						}
		}
	else
		{
			this.state = this.props.state;
		}
      
    this.validator = new SimpleReactValidator();
	window['onSubmitLogin'] =  this.onSubmit;
	this.setCookie = setCookie.bind(this);
	this.joinSessionValidate = joinSessionValidate.bind(this);

  }

  
  componentDidMount() {
    $('#email').on('focus', function() {
      //document.body.scrollTop = $(this).offset().top;
      document.body.scrollTop = 200;
  
  });
    // If logged in and user navigates to Login page, should redirect them to dashboard
    // if (this.props.auth.isAuthenticated) {@ak
      // this.props.history.push("/home");
      //localStorage.key('https://uatlive.virdio.com/home');
      // hereonly
     // localStorage.setItem('hostData',JSON.stringify({"id":1442,"firstName":"fsdhd","lastName":"czczcc","email":"monu234@mailinator.com","image":"ASD","type":1,"alreadymemberTothisChannel":"Yes","channelName":"czxcc","existingUser":"Yes","channelId":"569","channelAdmin":"1438","isInviteUser":1}));
       if(localStorage.getItem('hostData')){
       let checkHost=JSON.parse(localStorage.getItem('hostData'));
       if(checkHost.alreadymemberTothisChannel==="Yes" && (window.location.href.indexOf('session')  === -1 || window.location.href.indexOf('verify-user') === -1) ){
        this.setState({
          redirectPath:'/login',
          pageMessage:'You are already part of this '+checkHost.channelName,
          email:checkHost.email
        },()=>{localStorage.removeItem("hostData");$("#page_alert").attr({'style':'display:block'});})
       }
      }

      var localstoragedata=JSON.parse(localStorage.getItem('userData'));
    // alert(localStorage.getItem('emailId'));
     if(localStorage.getItem('verifyEmailID') && (window.location.href.indexOf('session') === -1 || window.location.href.indexOf('verify-user' ) === -1) ){
        this.setState({
        email:localStorage.getItem('verifyEmailID')
      },()=>localStorage.removeItem('verifyEmailID'))
    }
    if(osname=="Windows" || osname=="Linux" || osname=="Ubuntu" || osname=="Mac OS"){
      if((ff==true) && (parseFloat(fbrowserversion) < 72)){
        this.setState({
          platformStatus:false
        })
      }
        if((chrome==true) && (parseFloat(fbrowserversion) < 79)){
        this.setState({
          platformStatus:false
        })
        }
        if((edge==true) && (parseFloat(fbrowserversion) < 78)){
        this.setState({
          platformStatus:false
        })
        }
      }
      //alert(existingEmailId);
      // if(localstoragedata.userType == 1 && localstoragedata.sessionData.id != undefined){
      //   this.props.history.push("/host");
      // }else if(localstoragedata.userType == 2 && localstoragedata.sessionData.id != undefined){
      //   this.props.history.push("/guest");
      // } else {
      //   this.props.history.push("/home");
      // }

      if(localstoragedata && localstoragedata.sessionData && localstoragedata.sessionData.id != undefined){
        // this.props.history.push("/pre-screen");@ak
      } else {
        // this.props.history.push("/home");@ak
      }
      
    // } else { @ak
      // if (localStorage.chkbx && localStorage.chkbx != '') {
      //     $('#remember_me').attr('checked', 'checked');
      //     // $('#email').val(localStorage.email);
      //     this.setState({email:localStorage.email})
      // } else {
      //     $('#remember_me').removeAttr('checked');
      //     // $('#email').val('');
      //     this.setState({email:''})
      // }//@ak
    // }@ak
	//console.log(localStorage.getItem('chk'));
	//console.log(localStorage.getItem('userData'));
    if(localStorage.getItem('chkbx') === 'remember-me' && (window.location.href.indexOf('session') === -1 || window.location.href.indexOf('verify-user' ) === -1) ){
      //let ak =JSON.parse(localStorage.getItem('userData'));
      $('#remember_me').attr('checked', 'checked');
     
	  this.setState({
        email:localStorage.getItem('email')
      });
    }
	
	
    if(localStorage.getItem('emailNext') && (window.location.href.indexOf('session') === -1 || window.location.href.indexOf('verify-user' ) === -1) ){
     
	  this.setState({
        email:localStorage.getItem('emailNext')
      });
	  
	  localStorage.removeItem('emailNext');
    }
	
	
	let path = window.location.pathname;
	if ( path.indexOf('/login') > -1 )
		{
			let path_arr = path.split("/");
			if ( path_arr[2] && path_arr[3] && path_arr[4] )
				{
					var postDataCode = {'userId': path_arr[3], 'code': path_arr[4]}
					axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUpdateEmailCode", postDataCode).then(res => {
						if ( res.data.responseData === 'updateEmail' )
							{
								var postData = {'email': path_arr[2], 'userId': path_arr[3]};
								axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateUserEmail", postData).then(res_e => {
									this.setState({showConfirmReset: true});
								}).catch(err =>{});
							}
						else
							{
								this.props.showMainErrorPop('Email has already been updated from this URL.');
							}
					}).catch(err =>{});	
				}
		}
  }

//   componentWillReceiveProps(nextProps) {
//     if (nextProps.auth.isAuthenticated) {

//       var localstoragedata = JSON.parse(localStorage.getItem('userData'));

     
//       if(localstoragedata && localstoragedata.sessionData && localstoragedata.sessionData.id != undefined){
//         this.props.history.push("/pre-screen");
//       } else {
//         this.props.history.push("/home");
//       }
//     }


// if (nextProps.errors) {
//       this.setState({
//         errors: nextProps.errors.errorData
//       });
//     }
//   }

closeLoginPop = e => {
	var url = window.location.href;
	if ( url.indexOf('/login') == -1 )
		{
			this.props.closeLoginPop();
		}
		
	else
		{
			browserHistory.push('/');
		}
}

onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChange= e => {
    this.setState({type: e.target.value});
  };
  
updateStateFromChild=(newState) => {
	this.setState(newState);
}
  
onSubmit = e => {
	var addAfter = '';
	if ( document.referrer == '' || document.referrer.indexOf('virdio.com') < 0 )
		{
			addAfter = 'ref=signup';
		}
	
	var directLogin = localStorage.getItem('logedUserTryToParticipate');
	var directLoginOtherUser = localStorage.getItem('logedUserTryToParticipateWithOther');
	var anyLoginSessionLink = localStorage.getItem('anyLoginSessionLink');
	
	if ( e ) { e.preventDefault(); }
	console.log(this.validator.allValid());
	if ( this.validator.allValid() || ( this.props && this.props.state && this.props.state.logedUser && this.props.state.logedUser !== '' )) 
		{
			localStorage.removeItem('logedUserTryToParticipate');
			localStorage.removeItem('logedUserTryToParticipateWithOther');
			localStorage.removeItem('anyLoginSessionLink');
			let checkLogin= JSON.parse(localStorage.getItem('userData'));
			
			var isSecondStep = localStorage.getItem('secondStep');
			localStorage.removeItem('secondStep');
			
			if ( !isSecondStep )
				{
					localStorage.setItem('tempSession', localStorage.getItem('sessionIdData'));
				}
			else
				{
					if ( window.location.href.indexOf('session') > -1 )
						{
							anyLoginSessionLink = true;
						}
					localStorage.setItem('sessionIdData', localStorage.getItem('sessionNewData'));
				}
			
			var scheduleDate1 =[];
			var sessionId1 =[];
			var parentRecrId = localStorage.getItem('parentRecrId');
			if( parentRecrId != null )
				{
					console.log('THIS IF LOGIN');
					
					if ( directLogin && !directLoginOtherUser ) 
						{
							var userData = {
												auth: checkLogin.data.responseData.token,
												userId: checkLogin.data.responseData.id,
												sessionId: this.props.state && this.props.state.log_or_signup && !isSecondStep ? [] : [JSON.parse(localStorage.getItem('sessionIdData'))],
												scheduleDate:localStorage.getItem('scheduleDate'),
												duration:localStorage.getItem("duration")
											};
						}
					else
						{
							var userData = {
												email: this.state.email,
												password: this.state.password,
												sessionId: this.props.state && this.props.state.log_or_signup && !isSecondStep ? [] : [JSON.parse(localStorage.getItem('sessionIdData'))],
												scheduleDate:localStorage.getItem('scheduleDate'),
												duration:localStorage.getItem("duration")
											};
						}
				}
			else
				{
					console.log('THIS ELSE LOGIN');
					
					
					let sessionid = localStorage.getItem('sessionIdData');
					if ( sessionid )
						{
							sessionId1.push(JSON.parse(localStorage.getItem('sessionIdData')));
						}
					
					if ( directLogin && !directLoginOtherUser ) 
						{
							var userData = {
												auth: checkLogin.data.responseData.token,
												userId: checkLogin.data.responseData.id,
												sessionId: this.props.state && this.props.state.log_or_signup && !isSecondStep ? [] : sessionId1,
												scheduleDate:localStorage.getItem('scheduleDate'),
												duration:localStorage.getItem("duration")
											};
						}
					else
						{
							var userData = {
												email: this.state.email,
												password: this.state.password,
												sessionId: this.props.state && this.props.state.log_or_signup && !isSecondStep ? [] : sessionId1,
												scheduleDate:localStorage.getItem('scheduleDate'),
												duration:localStorage.getItem("duration")
											};
						}
				}
			
			
			if ( this.props.state && this.props.state.isSignupChannel && this.props.state.mainChannelData )
				{
					userData['add_member_channel'] = this.props.state.mainChannelData.id
				}
				
			$('.main_loader').attr({'style':'display:flex'});
			var response = {};
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/adminLogin", userData)
			.then(res => {
				
				localStorage.removeItem('sessionIdData');
				
				if( checkLogin )
					{
						localStorage.removeItem('userData');
						localStorage.removeItem('channelId');
						localStorage.removeItem('groupId');
					}
				
				response = res;
				if(res.data.responseMessage == "success"){
					
						if(res.data.responseData.isOtpVerified===0 && this.state && ( this.state.detectChannelPage || this.state.detectChannelSignUpPage ))
							{
								let ak = '';
								for(let i=0;i<this.state.password.length;i++)
								  {
									ak = ak+'*';
								  }
								
								this.props.updateStateFromChild({
									firstName: res.data.responseData.firstName,
									lastName: res.data.responseData.lastName,
									emailSign: res.data.responseData.email,
									passwordText: ak,
									isOpenSign: true,
									verify: true
								});
									
								$('.main_loader').hide();
								return false
							}
					
						if ( res.data.responseData && res.data.responseData.isOtpVerified && res.data.responseData.isOtpVerified!=0)
							{
								window['lastUserId'] = 0;
								//set cookie after login
								this.setCookie(process.env.REACT_APP_COOKIE_ID, res.data.responseData.id, res.data.responseData.tokenExpire);
								this.setCookie(process.env.REACT_APP_COOKIE_TOKEN, res.data.responseData.token, res.data.responseData.tokenExpire);
								localStorage.setItem("userData", JSON.stringify(res));
								window['user_loggedIn'] = 1;
								//set cookie after login
							}
							
						if ( this.props.updateStateFromChild )
							{
								this.props.updateStateFromChild({
													logedUser: true,
													logedUserName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName
												});
							}
						
						if ( res.data.responseData && res.data.responseData.channelGroup ) { localStorage.setItem('groupId', res.data.responseData.channelGroup); }
						
						if(res && res.data && res.data.responseData && res.data.responseData.settings)
							{
								var globalSettings = res.data.responseData.settings;
						
								localStorage.setItem('appDownload', ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink ));
							}
							
						if ( (directLogin || anyLoginSessionLink ) && (!this.props.state || ( this.props.state && !this.props.state.participantData) || (this.props.state && this.props.state.participantData && !this.props.state.participantData.sessionChargeAllowed) || (this.props.state && this.props.state.participantData && this.props.state.participantData.sessionChargeAllowed && this.props.state.participantData.sessionChargeAllowed != 1) || isSecondStep))
							{
								console.log('directLogin || anyLoginSessionLink');
								localStorage.setItem("userData", JSON.stringify(res));
								let urlPathVar = '';
								let urlElectronVar;
								let deepLinkButtonVar = 'Web App';
								let sSTH15M = true;
								if( (new Date(localStorage.getItem('scheduleDate')).getTime()-15*60*1000) < new Date().getTime() )
									{
										sSTH15M = false;
									}
								
								var user = JSON.parse(localStorage.getItem('userData'));
								
								if ( this.props.state && this.props.state.sessionType && this.props.state.sessionType === 'On Demand' )
									{
										var checkSessionValidate = this.joinSessionValidate(true, user.data.responseData.id, user.data.responseData.token, this.props.state.demandSessionId);
										this.setState({ 
												urlPath: checkSessionValidate.urlPathVar,
												urlElectron: checkSessionValidate.urlElectronVar,
												deepLinkButton: checkSessionValidate.deepLinkButton
										});
										
										urlPathVar = checkSessionValidate.urlPathVar;
										urlElectronVar = checkSessionValidate.urlElectronVar;
										deepLinkButtonVar = checkSessionValidate.deepLinkButton;
									}
								else
									{
										var isHost = false;
										if ( this.props && this.props.state && this.props.state.participantData && this.props.state.participantData.hostId === user.data.responseData.id ) { isHost = true; }
										
										var checkSessionValidate = this.joinSessionValidate(isHost, user.data.responseData.id, user.data.responseData.token, null);
										this.setState({ 
												urlPath: checkSessionValidate.urlPathVar,
												urlElectron: checkSessionValidate.urlElectronVar,
												deepLinkButton: checkSessionValidate.deepLinkButton
										});
										
										urlPathVar = checkSessionValidate.urlPathVar;
										urlElectronVar = checkSessionValidate.urlElectronVar;
										deepLinkButtonVar = checkSessionValidate.deepLinkButton;
									}
										
								var isHostVar = false;
								if ( this.props && this.props.state && this.props.state.participantData && this.props.state.participantData.hostId === user.data.responseData.id ) { isHostVar = true; }
								
								let userSignedUp = true;
								let logedUserNowParticate = true;
								let delayLoader = false;
								if ( this.props.state && this.props.state.justLogin )
									{
										userSignedUp = false;
										logedUserNowParticate = false;
										delayLoader = true;
									}
										
								//var mainthis = this;
								setTimeout( ()=>{ 
									
									this.props.updateStateFromChildSession({
											sessionStartTimeHigher15Min: sSTH15M,
											urlPath: urlPathVar,
											urlElectron: urlElectronVar,
											osname: this.state.osname,
											deepLinkButton: deepLinkButtonVar,
											logedUserNowParticate: logedUserNowParticate,
											tryWithOtherAccount: false,
											logedUser: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName,
											logedUserFirstName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName,
											userHostThisSession: res.data.responseData.userHostThisSession ? true : false,
											userSignedUp: userSignedUp,
											loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
											isHost: isHostVar,
											signedSessionsIds: parentRecrId ? userData['sessionId'] : [],
											delayLoader: delayLoader,
									});
									
								}, 50);

								if ( directLoginOtherUser )
									{
										this.props.updateStateFromChildSession({
												logedUserAlreadyParticate: false
											});
									}
								if ( this.props.state && this.props.state.justLogin )
									{
										this.props.reloadSessionInfoAfterLogin();
									}
								else 
									{
										$('.main_loader').hide();
									}									
								
								return false;
							}
						
						if ( window.location.href.indexOf('login') > -1 || ( ( window.location.href.indexOf('session') > -1 || window.location.href.indexOf('verify-user') > -1 ) && this.props.state && this.props.state.participantData && this.props.state.participantData.sessionChargeAllowed === 0 )  || ( ( window.location.href.indexOf('session') > -1 || window.location.href.indexOf('verify-user') > -1 ) && this.props.state && !this.props.state.log_or_signup && isSecondStep && this.props.state.participantData && this.props.state.participantData.sessionChargeAllowed === 1 ) )
							{
								if ( localStorage.getItem('backToChannel') )
									{
										window.location.href = localStorage.getItem('backToChannel');
										localStorage.removeItem('backToChannel');
										return false;
									}
								
								
								if ($('#remember_me').is(':checked')) 
									{
										localStorage.setItem("chk", 'true');
										localStorage.setItem('email', $('#email').val());
										localStorage.setItem('chkbx', $('#remember_me').val());
									}
								else 
									{
										localStorage.setItem("chk", 'false');
										localStorage.removeItem('email');
										localStorage.removeItem('chkbx');
									}									
								
								/* if(localStorage.getItem('sessionId'))
									{
										localStorage.removeItem('sessionId')
									} */

								if(res.data.responseData.type===1)
									{
										if(res.data.responseData.isOtpVerified===0)
											{
												localStorage.setItem("userData", JSON.stringify(res));
												localStorage.setItem('verifyOtpEmail',res.data.responseData.email)
												localStorage.setItem('verifyOtpfirstname',res.data.responseData.firstName);
												localStorage.setItem('verifyOtplastname',res.data.responseData.lastName);
												browserHistory.push("/hostSignUp");
											}
										else
											{
												if(res.data.responseData.sessionExistIn30Min==="no")
													{
														console.log('match');
														localStorage.setItem("userData", JSON.stringify(res));
														this.props.setCurrentUser(res.data.responseData);
														!res.data.responseData.channel?browserHistory.push("/ChannelCreation"):browserHistory.push("/DashboardLanding");
													}
												
												if(res.data.responseData.sessionExistIn30Min==="yes")
													{
														var isHost = false;
														if ( this.props && this.props.state && this.props.state.participantData && this.props.state.participantData.hostId === res.data.responseData.id ) { isHost = true; }
														localStorage.setItem("userData", JSON.stringify(res));
														var checkSessionValidate = this.joinSessionValidate(isHost, res.data.responseData.id, res.data.responseData.token, null);
														this.setState({ 
																urlPath: checkSessionValidate.urlPathVar,
																urlElectron: checkSessionValidate.urlElectronVar,
																deepLinkButton: checkSessionValidate.deepLinkButton,
																userSignedUp: true,
																loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
																sessionScheduleDate: res.data.responseData.scheduleDate,
																sessionTimezone: res.data.responseData.timeZone,
																sessionChannelName: res.data.responseData.channelName,
																isHost: res.data.responseData.isHost,
																sessionName: res.data.responseData.sessionName,
																signedSessionsIds: parentRecrId ? userData['sessionId'] : []
														});
														
														
														this.props.setCurrentUser(res.data.responseData)
														
														if ( JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).data.responseData.settings )
															{
																if ( parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.showNativeApplications) === 0 )
																	{
																		this.setState({showWindowsApp: false});
																	}
															}
													}
											}
									}
								
								if(res.data.responseData.type===3)
									{
										localStorage.setItem("userData", JSON.stringify(res));
										this.props.setCurrentUser(res.data.responseData)
										browserHistory.push("/AdminDashboard");
									}
								
								if(res.data.responseData.type===2)
									{
										if(res.data.responseData.isOtpVerified===0)
											{
												localStorage.setItem("userData", JSON.stringify(res));
												localStorage.setItem('verifyOtpEmail',res.data.responseData.email);
												localStorage.setItem('verifyOtpfirstname',res.data.responseData.firstName);
												localStorage.setItem('verifyOtplastname',res.data.responseData.lastName);
												browserHistory.push("/hostSignUp");
											}
										else if ( res.data.responseData.isMarketplaceUser == 1 )
											{
												
												localStorage.setItem("userData", JSON.stringify(res));
												this.props.setCurrentUser(res.data.responseData);
												
												var checkSessionValidate = this.joinSessionValidate(false, res.data.responseData.id, res.data.responseData.token, null);
												this.setState({ 
													urlPath: checkSessionValidate.urlPathVar,
													urlElectron: checkSessionValidate.urlElectronVar,
													deepLinkButton: checkSessionValidate.deepLinkButton,
													userSignedUp: true,
													loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
													isMarketplaceUser: true,
												});	
												return false;
											}											
										else
											{
												if(res.data.responseData.sessionExistIn30Min==="no")
													{
														localStorage.setItem("userData", JSON.stringify(res));
														this.props.setCurrentUser(res.data.responseData)
														
														browserHistory.push("/participant-dashboard");
														console.log('Open /participant-dashboard');																										
													}
												
												if(res.data.responseData.sessionExistIn30Min==="yes")
													{
														localStorage.setItem("userData", JSON.stringify(res));
														var checkSessionValidate = this.joinSessionValidate(false, res.data.responseData.id, res.data.responseData.token, null);
														this.setState({ 
																urlPath: checkSessionValidate.urlPathVar,
																urlElectron: checkSessionValidate.urlElectronVar,
																deepLinkButton: checkSessionValidate.deepLinkButton,
																userSignedUp: true,
																loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
																sessionScheduleDate: res.data.responseData.scheduleDate,
																sessionTimezone: res.data.responseData.timeZone,
																sessionChannelName: res.data.responseData.channelName,
																isHost: res.data.responseData.isHost,
																sessionName: res.data.responseData.sessionName,
																signedSessionsIds: parentRecrId ? userData['sessionId'] : []
														});

														this.props.setCurrentUser(res.data.responseData)
														
														if ( JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).data.responseData.settings )
															{
																if ( parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.showNativeApplications) === 0 )
																	{
																		this.setState({showWindowsApp: false});
																	}
															}
													}
											}
									}
								$('.main_loader').hide();
							}
						else

							{
								if ( this.props.state && this.props.state.isSignupChannel )
									{
										window.location = this.props.state.redirect;
										$('.main_loader').hide();
										
										return false;
									}
								
								if (  this.props.state && this.props.state.sessionType && this.props.state.sessionType === 'On Demand' )
									{
										localStorage.setItem("userData", JSON.stringify(res));
										let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
										let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
										
										let deeplink = '';
										let electronPath = '';

										var checkSessionValidate = this.joinSessionValidate(true, loginId, token, this.props.state.demandSessionId);
									
										deeplink = checkSessionValidate.urlPathVar;
										electronPath = checkSessionValidate.urlElectronVar;
	
										var isHostDemand = false;
										if ( this.props.state.openedDemand.hostId === loginId ) { isHostDemand = true; }
										
										this.props.updateStateFromChild({
											isLoginOpen: false,
											isOpenLoginAll: false,
											ondemandlink: deeplink,
											electronlink: electronPath,
											urlPath: deeplink,
											urlElectron: electronPath,
											userSignedUp: true,
											loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
											isOnDemandSession: true,
											sessionScheduleDate: this.props.state.openedDemand.createdAt,
											sessionTimezone: '',
											sessionChannelName: this.state.mainChannel.name,
											isHost: isHostDemand,
											sessionName: this.props.state.openedDemand.name,
											signedSessionsIds: parentRecrId ? userData['sessionId'] : []
										});
										
										$('.main_loader').hide();
										return false;
									}
								
								
								if ( this.props.state && this.props.state.log_or_signup && !isSecondStep )
									{
										console.log('STATE CARDS');
										
										let credit_cards = [];
										let res_token = res.data.responseData.token;
										let res_curr_id = res.data.responseData.id;
										
										localStorage.setItem("userData", JSON.stringify(res));
										
										axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/getcards", {'id': res.data.responseData.id}, {headers: {'Authorization': res_token}})
										  .then(res => {
														  console.log('res.data.responseData', res.data.responseData);
														  credit_cards = res.data.responseData;
											  
															this.props.updateStateChargeFromChild({
																log_or_signup: false,
																make_payment: true,
																listCard: true,
																creditCards: credit_cards,
																currentUserId: res_curr_id,
																currentUserToken: res_token,
																callbackwhenDone: this.onSubmit
															});
										  });
															$('.main_loader').hide();
										
										return false;
									}
							
							
								if( this.state.redirect !='Sign Up' )
									{ 
										let streamingUrlPath;
										let electronPath = '';
									
										var isHost = false;
										if ( this.props && this.props.state && this.props.state.participantData && this.props.state.participantData.hostId === res.data.responseData.id ) { isHost = true; }
										
										var checkSessionValidate = this.joinSessionValidate(isHost, res.data.responseData.id, res.data.responseData.token, null);
										
										streamingUrlPath = checkSessionValidate.urlPathVar;
										electronPath = checkSessionValidate.urlElectronVar;

										
										console.log(this.props.updateStateFromChildSession);
										if (this.props.updateStateFromChildSession )
											{
												this.props.updateStateFromChildSession({
													  message:'',
													  userType:res.data.responseData.type,
													  urlPath:streamingUrlPath,
													  urlElectron:electronPath,
													  isOpenSign: false,
													  verify: false,
													  isLoginOpen: false,
													  isOpenLoginAll: false,
												  });
												$('.main_loader').hide();
											}
										
									}
								 else
									 {
										 if (this.props.updateStateFromChild )
										 {
											 this.props.updateStateFromChild({
											  message:'',
											  userType:res.data.responseData.type,
											  isOpenSign: false,
											  verify: false,
											  isLoginOpen: false,
											  isOpenLoginAll: false,
											 }, true,()=> { $('.main_loader').hide(); $("#registration_popup").attr({'style':'display:block'}) })
										 }
									  }
									  
								
							}
				}
			else
				{
					console.log('=============There Is an Error===================>'); 
				}
			}).catch(err =>{
				$('.main_loader').hide();
				
				if( checkLogin )
					{
						localStorage.removeItem('userData');
						localStorage.removeItem('channelId');
						localStorage.removeItem('groupId');
					}
				
				console.log('Error happened:', err);
				
				if ( err.response.data.responseCode )
					{
						if(err.response.data.responseCode===417)
							{
								if ( err.response.data.errorData && err.response.data.errorData.name && err.response.data.errorData.name == 'TokenExpiredError' )
									{
										localStorage.removeItem('userData');
									}
								else
									{
										this.props.showMainErrorPop(err.response.data.errorData);
									}
							}
						else
							{
								this.props.showMainErrorPop('We are currently experiencing issues connecting to Virdio, please try in 1 minute!');
							}
					}
				else
					{
						if ( window.location.href.indexOf('login') == -1 ) 
							{ 
								this.props.showLoginAlert(); 
							}
						else 
							{ 
								this.props.showMainErrorPop('Entered email id does not exists in the System');
							}
					}
			});
		}
	else
		{
			console.log('hereeeee');
			this.validator.showMessages();
			this.forceUpdate();
		}
};

openNativeUrl = (nurl) => {
	
	var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;
	
	this.setState({appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink )});
	
	$('.main_loader').attr({'style':'display:flex'});
	customProtocolCheck(nurl,
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
							$("#nativedownload").show();
						  },
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
						  }, 15000);
}

checkLoginFun = e => {
	console.log('checkLoginFun');
	
	let path=window.location.pathname;
	let path_arr = path.split("/");
	
	if ( path_arr[1] === 'ondemand' )
		{
			if (this.validator.allValid()) 
			{
				this.props.updateStateFromChildSession({
								passwordFromLogin: this.state.password
						});
				this.setState({
					dont_start_submit : true
				}, () => {
					localStorage.setItem('validateEmailMethod_newOd', true);
					this.props.validateEmailMethod_newOd();
				})	
			}
		else
			{
				this.validator.showMessages();
				this.forceUpdate();
			}
		}
	else 
		{
			if (this.validator.allValid()) 
				{
					this.props.updateStateFromChildSession({
								passwordFromLogin: this.state.password
						});
					this.setState({
						dont_start_submit : true,
					}, () => {
						this.props.checkLoginFun();
					})	
				}
			else
				{
					this.validator.showMessages();
					this.forceUpdate();
				}
		}
}

passwordEyeCheck=(e)=>
	{
			if(e == 1)
				{
					this.setState({
						passwordEye: false
					});
				}
			
			else if(e == 0)
				{
					this.setState({
						passwordEye: true
					});
				}
	}
  

render() {
    const { errors } = this.state;

    
return (
      <div className="container">
        <div className="row">
          <div className="login-bg">
            <div className="login-box">
              <div className="col-md-7 col-lg-5 col-sm-6 text-light mx-auto width_session_sign_up">
              
              <div className="col-12 main_login_logos">
                <img src="/images/login-logo.png" className="login-logo" />
                <p className="login-tagline">Login and Join a Virtual Studio</p>
              </div>
              <form className = "form-horizontal pt-1" role = "form"  noValidate onSubmit={this.onSubmit} autoComplete="off">
              
                <div className={ "login-inner " + ( !this.state.isLoginPage ? "gray_clr_back" : "" ) }>
				<div className="paymentLogin">Login</div>
			   { this.props.state && this.props.state.loginOpenFromExisting ?
					<div className = "form-group pb-3 mb-0 mt-4">
						<span className="text-danger">{errors.email}{errors.emailincorrect}</span>
						<label className="main_color_part login_main_label">Enter your email address</label>
						<input type="email"  id="email" onChange={this.onChange} value={this.state.email} onBlur={e=> { this.props.sendEmailFromLogin(e,this.state.email)}}  error={errors.email}  className={classnames("", { invalid: errors.email || errors.emailincorrect })} className = "form-control channel_login_input"  />				
						{this.props.state && this.props.state.searchFont ?
							<div className="bocc">	
								<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" ><g><g><path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"/></g></g></svg>					
							</div>	
								:
							<div className="bocc bocBckground">	
								<svg version="1.1" fill="#b002ed" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" ><g><g><path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"/></g></g></svg>					
							</div>
						}	
				   </div> 
				   :
				   <div className = "form-group pb-3 mb-0 mt-4">
						<span className="text-danger">{errors.email}{errors.emailincorrect}</span>
						<label className="main_color_part login_main_label">Enter your email address</label>
						<input autoFocus type="email"  id="email" onChange={this.onChange} value={this.state.email}  error={errors.email}  className={classnames("", { invalid: errors.email || errors.emailincorrect })} className = "form-control channel_login_input"  />
						<div className="bocc">
							{this.props.state && this.props.state.searchFont ?
								<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" ><g><g><path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"/></g></g></svg>					
									:
								<svg version="1.1" fill="#b002ed" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" ><g><g><path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"/></g></g></svg>					
							}
						</div>
				  </div>
				}
                   {this.validator.message('Email' , this.state.email, 'required|email')}

                <div className = "form-group mt-4 mb-0">
                    <span className="text-danger">{errors.password}{errors.passwordincorrect}</span>
                  
                    <label className="main_color_part login_main_label">Password</label>
					{!this.state.passwordEye &&
						<div>
							<input type="password"  id="password" onChange={this.onChange} value={this.state.password} error={errors.password} className={classnames("", { invalid: errors.password || errors.passwordincorrect })} className = "form-control channel_login_input"  />
							{/* <div className="bocc"><img src="/images/password1.png" className="user-login" /></div> */}
							<span className="password_eye password_eyeSvg" onClick={e=>{ this.passwordEyeCheck(0)}} >
								{this.props.state && this.props.state.searchFont ?
									<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.333 469.333"> <g><g><g><path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"/><path d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667S293.547,341.333,234.667,341.333z"/></g></g></g></svg>
									:
									<svg version="1.1" fill="#b002ed" id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.333 469.333"> <g><g><g><path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"/><path d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667S293.547,341.333,234.667,341.333z"/></g></g></g></svg>
								}
							</span>
						</div>
					}
					{this.state.passwordEye &&
						<div>
							<input type="text"  id="password" onChange={this.onChange} value={this.state.password} error={errors.password} className={classnames("", { invalid: errors.password || errors.passwordincorrect })} className = "form-control channel_login_input"  />
							{/* <div className="bocc"><img src="/images/password1.png" className="user-login" /></div> */}
							<span className="password_eye password_eye_shown password_eyeSvg" onClick={e=>{ this.passwordEyeCheck(1)}} >
								{this.props.state && this.props.state.searchFont ?
									<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.44 469.44" ><g><g><g><path d="M231.147,160.373l67.2,67.2l0.32-3.52c0-35.307-28.693-64-64-64L231.147,160.373z"/><path d="M234.667,117.387c58.88,0,106.667,47.787,106.667,106.667c0,13.76-2.773,26.88-7.573,38.933l62.4,62.4c32.213-26.88,57.6-61.653,73.28-101.333c-37.013-93.653-128-160-234.773-160c-29.867,0-58.453,5.333-85.013,14.933l46.08,45.973C207.787,120.267,220.907,117.387,234.667,117.387z"/><path d="M21.333,59.253l48.64,48.64l9.707,9.707C44.48,145.12,16.64,181.707,0,224.053c36.907,93.653,128,160,234.667,160c33.067,0,64.64-6.4,93.547-18.027l9.067,9.067l62.187,62.293l27.2-27.093L48.533,32.053L21.333,59.253z M139.307,177.12l32.96,32.96c-0.96,4.587-1.6,9.173-1.6,13.973c0,35.307,28.693,64,64,64c4.8,0,9.387-0.64,13.867-1.6l32.96,32.96c-14.187,7.04-29.973,11.307-46.827,11.307C175.787,330.72,128,282.933,128,224.053C128,207.2,132.267,191.413,139.307,177.12z"/></g></g></g></svg>
									:
									<svg version="1.1" fill="#b002ed" id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.44 469.44" ><g><g><g><path d="M231.147,160.373l67.2,67.2l0.32-3.52c0-35.307-28.693-64-64-64L231.147,160.373z"/><path d="M234.667,117.387c58.88,0,106.667,47.787,106.667,106.667c0,13.76-2.773,26.88-7.573,38.933l62.4,62.4c32.213-26.88,57.6-61.653,73.28-101.333c-37.013-93.653-128-160-234.773-160c-29.867,0-58.453,5.333-85.013,14.933l46.08,45.973C207.787,120.267,220.907,117.387,234.667,117.387z"/><path d="M21.333,59.253l48.64,48.64l9.707,9.707C44.48,145.12,16.64,181.707,0,224.053c36.907,93.653,128,160,234.667,160c33.067,0,64.64-6.4,93.547-18.027l9.067,9.067l62.187,62.293l27.2-27.093L48.533,32.053L21.333,59.253z M139.307,177.12l32.96,32.96c-0.96,4.587-1.6,9.173-1.6,13.973c0,35.307,28.693,64,64,64c4.8,0,9.387-0.64,13.867-1.6l32.96,32.96c-14.187,7.04-29.973,11.307-46.827,11.307C175.787,330.72,128,282.933,128,224.053C128,207.2,132.267,191.413,139.307,177.12z"/></g></g></g></svg>
								}
							</span>
						</div>
					}
				</div>
                <br></br>

                {this.validator.message('Password' , this.state.password, 'required')}
                 {/* <div className="text-white">{this.state.errormsg}</div>  */}
                {/* <div class="form-group"><span class="cover-border bg_gray_clr">
                  </span><label class="label">Enter First Name</label>
                <input type="text" id="" class="input-field" value=""><span class="signedup_2" />
                </span></div> */}
                
                <div className = "form-group mt-4 mb-0 pl-0">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox"  className="custom-control-input" value="remember-me" id="remember_me" name="example1" />
                  {/* <input type="checkbox" id = "rememberMe"  checked={this.state.rememberMe} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.rememberMe},()=>console.log('rememberMe',this.state.rememberMe))}}/> */}
                    <label className="custom-control-label main_color_part" htmlFor="remember_me">Remember me</label>
                  </div>
                </div>
                {/*<div className = "form-group">
                  <div class = "form-check-inline radio">
                      <label>
                          <input type = "radio" name = "type" id = "host" onChange={this.handleChange} value = '1' checked /> Instructor
                      </label>
                    </div>
                    <div class="form-check-inline radio">
                      <label>
                          <input type = "radio" name = "type" id = "client" onChange={this.handleChange} value = '2'  checked={this.state.type === "2"}  /> Client
                      </label>
                    </div>
                  </div>*/}
      
                <div className = "form-group pt-3 mb-4">
				 { this.props.state && this.props.state.loginOpenFromExisting ? 
					 <div className = "d-flex flex-wrap justify-content-between align-items-center">
						<div className="btn-login btn btn-large btn-primary waves-effect waves-light hoverable blue accent-3 p-3 px-4 rounded pointer" onClick={this.checkLoginFun} >Log in</div>
						<Link to="/forgotpassword"  className="open-list" className="forgot-password mt-sm-0 mt-3 new_color_reg pink_color">Forgot password?</Link>
                    </div> : 
					 <div className = "d-flex flex-wrap justify-content-between align-items-center">
                      <button type = "button" onClick={e=>{this.closeLoginPop()}} className="btn-cancel btn btn-large btn-outline-secondary waves-effect waves-light hoverable blue accent-3 rounded p-3 px-4 channel_cancel_button">Cancel</button>
						<button type = "submit" className="btn-login btn btn-large btn-primary waves-effect waves-light hoverable blue accent-3 p-3 px-4 rounded">Log in</button>
                      <Link to="/forgotpassword"  className="open-list" className="forgot-password mt-sm-0 mt-3 new_color_reg pink_color">Forgot password?</Link>
                    </div>
				 }
				 {/*
                    <div className = "d-flex flex-wrap justify-content-between align-items-center">
                      <button type = "button" onClick={this.props.closeLogin} className="btn-cancel btn btn-large btn-outline-secondary waves-effect waves-light hoverable blue accent-3 rounded p-3 px-4 channel_cancel_button">Cancel</button>
                    { this.props.state && this.props.state.loginOpenFromExisting ? 
						<button className="btn-login btn btn-large btn-primary waves-effect waves-light hoverable blue accent-3 p-3 px-4 rounded" onClick={this.checkLoginFun} >Log in</button>
						: 
						<button type = "submit" className="btn-login btn btn-large btn-primary waves-effect waves-light hoverable blue accent-3 p-3 px-4 rounded">Log in</button>
					}
                      <Link to="/forgotpassword"  className="open-list" className="forgot-password mt-sm-0 mt-3 new_color_reg">Forgot password?</Link>
                    </div
				 */}	
                </div>
                
              </div>
              
              
            </form> 

            </div>
            </div>
            <Link to="/PrivacyPolicy" target="_blank" className="privacy-link">Click to view the virdio privacy policy</Link>
          </div>
          
        </div>
        {/* Platform Versioning Model */}
        <div className="modal py-3" id="streaming_option">
							<div className="modal-dialog mw-770 modal-dialog-centered">
								<div className="modal-content bg-transparent px-2">
									<div className="modal-body px-1 py-1 py-sm-3 px-sm-3 modalbg radius-10">
										<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>$("#streaming_option").attr({'style':'display:none'})} ><span>x</span></button>
										<div className="p-md-4 p-3 text-center">
											<h4 className="white text-center font-25">Virdio Streaming Options</h4> 
											<p className="text-white font-18 mb-0 text-center mt-md-2 d-inline-block"><b>Join at: 
											<input type="text" ref={(textarea)=>this.textArea=textarea}  className="input-field2" placeholder="Class Name" value={process.env.REACT_APP_URL} onChange={(e)=>console.log()}/></b></p>
											<Link to = "/login" className="join_button ml-2 d-inline-block" onClick={e=>{this.textArea.select();document.execCommand('copy');e.target.focus();$("#streaming_option").attr({'style':'display:none'});}}>Copy URL</Link>
											<p className="text-white font-18 text-center mt-md-2">You can copy and paste a link for {process.env.REACT_APP_URL} if you have one of the browsers:</p> 
											<div className="table-responsive">
												<table className="table table-bordered">
												<tbody>
													<tr className="text-white">
													<td>MacBook</td>
													<td><a href="https://apps.apple.com/us/app/virdio/id1489704718" target="_blank">Virdio App</a> | <a href="https://www.google.com/chrome/" target="_blank">Chrome</a> - v 79 + | <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> - v 72 +</td>
													</tr>
													<tr className="text-white">
													<td>Windows</td>
													<td><a href="https://www.google.com/chrome/" target="_blank">Chrome</a> - v 78 + | <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> - v 72 +| <a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a> - v 79 +</td>
													</tr>
													<tr className="text-white">
													<td>IOS</td>
													<td><a href="https://apps.apple.com/us/app/virdio/id1489704718" target="_blank">Virdio App</a></td>
													</tr>
													<tr className="text-white">
													<td>Android</td>
													<td><a href="https://play.google.com/store/apps/details?id=mvvm.f4wzy.com.virdioApp&hl=en" target="_blank">Virdio App</a></td>
													</tr>
													<tr className="text-white">
													<td>Linix</td>
													<td><a href="https://www.google.com/chrome/" target="_blank">Chrome</a> - v 78 + | <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> - v 72 +| <a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a> - v 79 +</td>
													</tr>
												</tbody>
												</table>
											</div>                        
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className="modal pr-0" id="registration_popup" aria-modal="true">
							<div className="modal-dialog px-2 dialogwidth modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
									  <h4 className="modal-title font-weight-bold white text-center">Welcome To Virdio</h4>
									  <p className="pt-4 text-white text-center font-22">Your sign up was successful.</p>
									  <p className="pb-4 text-light text-center font-20"> A mail is also sent to your inbox to let you check if your preferred device/system is ready for participating in the class.</p>

									  
									  </div>
									<div className="text-center">
									  <button type="button" onClick={this.props.reloadPg} className="custom_btn1 mt-3">ok</button>
									</div>
								</div>
							</div>
						</div>

        {/* session status model  */}
						<div className="modal pr-0" id="session_alert" aria-modal="true">
							<div className="modal-dialog mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
										<p className="mb-0 py-4 white text-center font-20">Your class is about to start within 30 minutes.</p>
									</div>
									<div className="text-center flex-wrap d-flex justify-content-center">
                  <button type="button" className="custom_btn1 mt-3 mx-2"  onClick={e=>{$("#session_alert").attr({'style':'display:none'});browserHistory.push('/participant-dashboard');}}>Dashboard</button>
                  {this.state.platformStatus?
					(
						this.state.urlElectron ?
						<a href="javascript:void(0)" onClick={e=>{$("#session_alert").attr({'style':'display:none'}); window.$("#autologinnative").modal('show') }}><div className=""><button className="custom_btn1 mt-3 mx-2">join</button></div></a>
						:
						<a href ={this.state.urlPath} target="_blank"><div className=""><button className="custom_btn1 mt-3 mx-2" onClick={e=>{$("#session_alert").attr({'style':'display:none'});}}>join</button></div></a>
					)
					
					
                  :
                  <Link to ="/login" >	  
	                <div className=""><button className="custom_btn1 mt-3 mx-2" onClick={e=>{$("#session_alert").attr({'style':'display:none'});$("#streaming_option").attr({'style':'display:block'});}}>
                  join</button></div></Link>
                }
                  
                  </div>
								</div>
							</div>
						</div>
						
				
            {/* Page message */}
            <div className="modal pr-0" id="page_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">{this.state.pageMessage}</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal" onClick={e=>{browserHistory.push(this.state.redirectPath); $("#page_alert").attr({'style':'display:none'});}}>ok</button></div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="autologinnative" aria-modal="true">
				<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="join_pop_container">
							<button type="button" className="close white closepurple join_close_iks" data-dismiss="modal"><span>x</span></button>
							{ this.state.showWindowsApp && this.state.urlElectron !== '' &&
								<div className="join_parent">
									<div className="join_title">Virdio recommends the Virdio App for the best experience</div>
									<div className="button_join_container">
										<a href={mobileTab && ios ? this.state.urlElectron : ''} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>Virdio APP</div></a>
										<div className="join_pop_btn dash_join_bnt" onClick={e=>{ window.$('#autologinnative').modal('hide'); browserHistory.push('/participant-dashboard');}}>Dashboard</div>
									</div>
									<div className="browser_run">
										{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank"><span className="run_web">Run in the browser instead</span></a> }
									</div>
								</div>
							}
							{ !this.state.showWindowsApp || this.state.urlElectron === '' &&
								<div className="join_parent">
									<div className="join_title">Virdio recommends Web browser for the best experience</div>
									<div className="button_join_container">
										<a href={this.state.urlPath} target="_blank"><div className="join_pop_btn">Run in the browser</div></a>
										<div className="join_pop_btn dash_join_bnt" onClick={e=>{ window.$('#autologinnative').modal('hide'); browserHistory.push('/participant-dashboard');}}>Dashboard</div>
									</div>
									<div className="browser_run"></div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
			
			<div className="download_back_container" id="nativedownload">     
				<div className="download_content">
					<div className="down_close" onClick={e=> { $("#nativedownload").hide(); }}></div>
					<div className="for_down_logo">
						<div className="download_logo"></div>
					</div>
					<div className="download_text">Download the Virdio App to install on your device.</div>                
					<div className="download_text">Once downloaded, double click on the installer then drag and drop the Virdio icon into the Applications folder.</div>                
					<div className="for_down_btn">
						<a href={this.state.appDownload} target="_blank"><div className="down_btn" onClick={e=> { $("#nativedownload").hide(); window.$("#nativedownload2").modal('show') }}>Download</div></a>
					</div>
				</div>
			</div> 
			
			<div className="modal pr-0" id="nativedownload2" aria-modal="true">
				<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
							{ ( chrome || edge ) && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible on the bottom left of the browser, click on the file to Install Virdio.</p> }
							{ !chrome && !edge && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible at the top right of the browser, click on the file to Install Virdio.</p> }
							<p className="mb-0 py-4 white text-center">2. After the Installation is complete, click on the JOIN button below.</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 col-4 mt-3" onClick={() => { this.openNativeUrl(this.state.urlElectron); window.$("#nativedownload2").modal('hide') }}>JOIN</button></div>
					</div>
				</div>
			</div>
			
			{ this.state.showConfirmReset && <div className="confirmed_email_reset">Your email has been confirmed. Please sign in to continue.</div> }
			
			{ this.state.isLoginPage && this.state.userSignedUp &&
				<div className="full_popup">
					<ParticipantSignedUp 
						state={this.state}
						updateStateFromChild={this.updateStateFromChild}
						openNativeUrl={this.openNativeUrl}
						sessionStartTimeHigher15Min={this.state.sessionStartTimeHigher15Min}
						urlElectron={this.state.urlElectron}
						urlPath={this.state.urlPath}
						scheduleDate={this.state.sessionScheduleDate}
						timeZone={this.state.sessionTimezone}
						sessionChannelName={this.state.sessionChannelName}
						isHost={this.state.isHost}
						sessionName={this.state.sessionName}
						isOnDemandSession={this.state.isOnDemandSession}
						>
					</ParticipantSignedUp>
				</div>
			}
			
			
			{ this.state.isLoginPage && <SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer> }
      </div>
    );
  }
}

// Login.propTypes = {
//   loginUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired
// };@ak

// const mapStateToProps = state => ({
//   auth: state.auth,
//   errors: state.user
// });@ak

// export default connect(
//   mapStateToProps,
//   { loginUser }
// )(Login);@ak

export default connect(
  null,
  { 
	setCurrentUser,
	showMainErrorPop
  }
)(Login);