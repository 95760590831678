import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';



class newsMain extends Component {
constructor(props) {
	super(props);
	this.state = {
		html: ''
	}

}


componentDidMount(){
	console.log(this.props.params.newsName);
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getNewsbyName", {name: this.props.params.newsName})
		.then(res => {
			this.setState({
				html: res.data.responseData.html
			});
		}).catch(err =>{ 
			
		});
	
}
 
  render() {

    return (
		<div className="newsallstyle padding_news">
			<div dangerouslySetInnerHTML={{__html: this.state.html}}></div>
		</div>
    );
  }
}

export default newsMain;