import axios from "axios";
import {  browserHistory} from 'react-router';
import moment from 'moment';

const getSessionDate=(date1, otherFormat = false)=>
	{
		let sessionDate = '-';
		let ab = new Date(date1);
		if ( ab && ab !== 'Invalid Date' )
			{
				ab = new Date(date1).getTime();
				let day = new Date(ab).getDate();
				let month = (new Date(ab).getMonth())+1;
				let year = new Date(ab).getFullYear();
				let day1 = new Date(ab).getDay();
				let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
				sessionDate = month+'/'+day+'/'+year;
				if ( otherFormat)
					{
						sessionDate = month+'/'+day+'/'+year;
					}
			}
		return sessionDate; 
	}
const getSessionTime = (date1)=>
	{
		let ab = new Date(date1);
		if ( ab && ab !== 'Invalid Date' )
			{
				let hour=new Date(date1).getHours();
				let minute = new Date(date1).getMinutes();
				let sessionTime= hour+':'+minute;
				if(minute<10)
					{
					  minute='0'+minute
					}

				if(hour<12)
					{
						if(hour<10)
							{	
								sessionTime ='0'+hour+':'+minute+" " +'AM'
							}
						else
							{
								sessionTime=hour+':'+minute+" " +'AM'
							}
						return sessionTime ;
					}
				if(hour>12)
					{
						if((hour-12)<10)
							{
								sessionTime='0'+(hour-12)+':'+minute+" " +'PM'	
							}
						else
							{
								sessionTime=(hour-12)+':'+minute+" " +'PM'
							}	
						return sessionTime ;
					}
				if(hour==12 && minute>=0)
					{
						sessionTime=hour+':'+minute+" " +'PM'	
						return sessionTime;
					}
			}
		else
			{
				return '-';
			}
	}
	
const getSessionTimeAdditional = (date1)=>
	{
		//time and pm and am are not separated
		let ab = new Date(date1);
		if ( ab && ab !== 'Invalid Date' )
			{
				let hour=new Date(date1).getHours();
				let minute = new Date(date1).getMinutes();
				let sessionTime= hour+':'+minute;
				if(minute<10)
					{
					  minute='0'+minute
					}

				if(hour<12)
					{
						if(hour<10)
							{	
								sessionTime ='0'+hour+':'+minute+'AM'
							}
						else
							{
								sessionTime=hour+':'+minute+'AM'
							}
						return sessionTime ;
					}
				if(hour>12)
					{
						if((hour-12)<10)
							{
								sessionTime='0'+(hour-12)+':'+minute+'PM'	
							}
						else
							{
								sessionTime=(hour-12)+':'+minute+'PM'
							}	
						return sessionTime ;
					}
				if(hour==12 && minute>=0)
					{
						sessionTime=hour+':'+minute+'PM'	
						return sessionTime;
					}
			}
		else
			{
				return '-';
			}
	}
	
const getOnDemandDuration=(time)=>{
	let minutes = 0;
	let hours = 0;
	
	if ( time > 59 )
		{
			minutes = Math.floor(time / 60);
			time = time - (minutes * 60);
		}
	
	if ( minutes > 59 )
		{
			hours = Math.floor(minutes / 60);
			minutes = minutes - (hours * 60);
		}
	
	if ( time < 10 )
		{
			time = '0'+time;
		}
		
	if ( minutes < 10 )
		{
			minutes = '0'+minutes;
		}	
	
	if ( hours > 0 )
		{
			return hours + ":" + minutes + ":" + time;
		}
	else 
		{
			return minutes + ":" + time;
		}
}	

const getLocalDateTime=(time, justDate)=>{
	let formated = moment(time).format('M/D/YYYY @ hh:mm A');
	if (justDate)
		{
			formated = moment(time).format('M/D/YYYY');
		}
	return formated;
}

export { getSessionDate, getSessionTime, getSessionTimeAdditional, getOnDemandDuration, getLocalDateTime };