import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router";

class MemberWelcome extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
		appUrl: process.env.REACT_APP_URL+'login',
    }
	
}
 
componentDidMount(){
	
}

openApp=()=>{
	let url = this.state.appUrl;
	window.open(url);
}

checkBackScreen=()=>{
	
	if ( this.props.state.showCCscreen )
		{
			this.props.goToNextStep(4);
		}
	else if ( !this.props.state.planSelectedBefore )
		{
			this.props.goToNextStep(3);
		}		
	else 
		{
			this.props.goToNextStep(1)
		}		
}

  render() {
	 return (
		<div className="memberRegisterHolder more_width_welcome">
			{!this.props.state.hideBack &&
				<div className="exit_content">
					<div>
						<div className="inline_event" onClick={(e)=>this.checkBackScreen(3)}>
							<div className="arrow_exit"></div>
							<div className="exit_screen">BACK</div>
						</div>
					</div>
				</div>
			}
			<div className="sign_title">Welcome To Virdio</div>
			<div className="sign_title reg_message">Registration was successful, you can close this page and return to the app</div>
			<div className="checkbox_container"></div>
			<div className="personalizeHolder">
				<div className="halfWidthContainer full_width_welcome_containter">
				{/*<div className="exit_screen pointerNext" onClick={(e)=>this.openApp()}>CLASS CATALOG</div>*/}
				</div>
				<div className="">
					<div className="exit_screen pointerNext" onClick={(e)=>this.props.goToNextStep(6)} >
						<div className="personalizeText">PERSONALIZE VIRDIO</div>
						<div className="arrow_next"></div>
					</div>
				</div>
			</div>
		</div>
    );
  }
}
export default MemberWelcome;
