const convertAudio = function(file)
{
	var finalItem = { };

	var promise = new Promise(function(resolve, reject) { 
		var path = URL.createObjectURL(file);
		var audio = new Audio();
		
		audio.addEventListener("canplaythrough", function() {
			finalItem['length'] = readableDuration(audio.duration);
			finalItem['real_length'] = audio.duration;
			var jsmediatags = window.jsmediatags;
			jsmediatags.read(file, 
				{
					onSuccess: function(tag) {
						console.log(tag);
						
						finalItem['artist'] = tag.tags['artist'] ? tag.tags['artist'] : 'Unknown';
						finalItem['name'] = tag.tags['title'] ? tag.tags['title'] : 'Unknown';
						finalItem['fileName'] = file.name;
						
						resolve(finalItem);
						URL.revokeObjectURL(path);
					},
					onError: function(error) {
						console.log(error);
					}
				});
		});
		
		audio.src = path;	
	});
	
	return promise;
}


const readableDuration = function(seconds) 
{
	var sec, min;
	sec = Math.floor( seconds );    
	min = Math.floor( sec / 60 );
	min = min >= 10 ? min : '0' + min;    
	sec = Math.floor( sec % 60 );
	sec = sec >= 10 ? sec : '0' + sec;    
	return min + ':' + sec;
}

 
export { convertAudio, readableDuration }