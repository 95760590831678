import React, { Component } from "react";
import classnames from "classnames";
import moment from 'moment';

class ReviewFilters extends Component {
  
  constructor(props) {
		super(props);
		
		this.state = {
			isOpenCategory: false,
			isOpenInstructor: false,
			isOpenWeek: false,
			isOpenDay: false,
			dayFilters: [
							{name: "All", dayInWeek: -1}, 
							{name: "Monday", dayInWeek: 0},
							{name: "Tuesday", dayInWeek: 1},
							{name: "Wednesday", dayInWeek: 2},
							{name: "Thursday", dayInWeek: 3},
							{name: "Friday", dayInWeek: 4},
							{name: "Saturday", dayInWeek: 5}, 
							{name: "Sunday", dayInWeek: 6}
						],
			weeks: []
		}
		
		this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
	}
	
	componentDidMount(){
		this.setWeeks();
		document.addEventListener('mousedown', this.handleClickOutside);
	}
	
	handleClickOutside(event) {
        if ( this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target) ) 
			{
			   this.setState({isOpenCategory: false, isOpenInstructor: false, isOpenWeek: false, isOpenDay: false});
			}
    }
	
	getMondayandSunday(d) {
		d = new Date(d);
		var day = d.getDay(),
		diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
		var monday = new Date(d.setDate(diff));
		var mondayStore = new Date(monday);
		var sunday = new Date(mondayStore.setDate(mondayStore.getDate() + 6));
		return {"monday": monday, "sunday": sunday};
	}
	
	setWeeks=()=>{
		var weeksFilter = [{id: -1, name: "All", startDate: "", endDate: ""}];
		for ( var i = 0; i < 4; i++ )
			{
				var increaseWeeks = 7 * i;
				var dateToSend = new Date();
				dateToSend.setDate(new Date().getDate() + increaseWeeks);
				
				var getDateObject = this.getMondayandSunday(dateToSend);
				var name = moment(getDateObject.monday).format("MM/DD") + " - " + moment(getDateObject.sunday).format("MM/DD");
				var objectToPush = {id: i, name: name, firstDay: getDateObject.monday, lastDay: getDateObject.sunday};
				weeksFilter.push(objectToPush);
			}	

		this.setState({weeks: weeksFilter});
	}
 
	openCloseFilter=(mod)=>{
		var isOpen = this.state[mod];
		isOpen = !isOpen;
		this.setState({[mod]: isOpen});
	}
	
	preventDef=(e)=>{
		e.preventDefault();
		e.stopPropagation();
	}
	
	selectFilter=(type, id, mod, isEmpty)=>{
		this.props.selectFilter(type, id, isEmpty);
		this.setState({[mod]: false});
	}
	
	selectDate=(type, item, mod)=>{
		this.props.selectDate(type, item);
		this.setState({[mod]: false});
	}
	
	getChosenFilter=(type, id)=>{
		var stringReturn = "";
		
		if ( type == "host" )
			{
				this.props.filterHosts.map((item) => { 
					if ( item.hostId == id ) 
						{ 
							stringReturn = item.hostFirstName + " " + item.hostLastName;
						} 
				});
			}
			
		if ( type == "category" ) 
			{
				this.props.filterCategories.map((item) => { 
					if ( item.id == id ) 
						{ 
							stringReturn = item.name;
						} 
				});
			}
			
		if ( type == "week" ) 
			{
				this.state.weeks.map((item) => { 
					if ( item.id == id ) 
						{ 
							stringReturn = item.name;
						} 
				});
			}
			
		if ( type == "day" ) 
			{
				this.state.dayFilters.map((item) => { 
					if ( item.dayInWeek == id ) 
						{ 
							stringReturn = item.name;
						} 
				});
			}
			
		return stringReturn;
	}
 
	render() {
			return(
				<div className={classnames({"review_filters_content": true, "publish_filters": this.props.chosenMenu === "PUBLISH"})} ref={this.wrapperRef}>
					<div className={classnames({"one_filter_review": true, "active": this.state.isOpenCategory})} onClick={() => this.openCloseFilter("isOpenCategory")}>{(this.props.filterCategories.length == 0 || this.props.chosenFilterCategories.length == 0) ? "Category (all)" : this.getChosenFilter("category", this.props.chosenFilterCategories[0]) }
						<div className={classnames({"filter_arrow": true, "active": this.state.isOpenCategory})}></div>
						{ this.state.isOpenCategory &&
							<div className="drop_filter" onClick={this.preventDef}>
								<div className="one_filter_drop" onClick={() => this.selectFilter("category", 0, "isOpenCategory", true)}>
									<div className={classnames({"filter_toogle": true, "active": this.props.chosenFilterCategories.length == 0})}></div>
									<div className="filter_option">All</div>
								</div>
								{ this.props.filterCategories.map((item, i)=>
									<div className="one_filter_drop" key={i} onClick={() => this.selectFilter("category", item.id, "isOpenCategory")}>
										<div className={classnames({"filter_toogle": true, "active": this.props.chosenFilterCategories.indexOf(item.id) > -1})}></div>
										<div className="filter_option">{item.name}</div>
									</div>
								)}
							</div>
						}
					</div>
					<div className={classnames({"one_filter_review": true, "active": this.state.isOpenInstructor})} onClick={() => this.openCloseFilter("isOpenInstructor")}>{(this.props.filterHosts.length == 0 || this.props.chosenFilterHosts.length == 0) ? "Instructor (all)" : this.getChosenFilter("host", this.props.chosenFilterHosts[0]) }
						<div className={classnames({"filter_arrow": true, "active": this.state.isOpenInstructor})}></div>
						{ this.state.isOpenInstructor &&
							<div className="drop_filter" onClick={this.preventDef}>
								<div className="one_filter_drop" onClick={() => this.selectFilter("instructor", 0, "isOpenInstructor", true)}>
									<div className={classnames({"filter_toogle": true, "active": this.props.chosenFilterHosts.length == 0})}></div>
									<div className="filter_option">All</div>
								</div>
								{ this.props.filterHosts.map((item, i)=>
									<div className="one_filter_drop" key={i} onClick={() => this.selectFilter("instructor", item.hostId, "isOpenInstructor")}>
										<div className={classnames({"filter_toogle": true, "active": this.props.chosenFilterHosts.indexOf(item.hostId) > -1})}></div>
										<div className="filter_option">{item.hostFirstName} {item.hostLastName}</div>
									</div>
								)}
							</div>
						}
					</div>
					<div className={classnames({"one_filter_review": true, "active": this.state.isOpenWeek})} onClick={() => this.openCloseFilter("isOpenWeek")}>{this.props.filterWeeks == -1 ? "Week (all)" : this.getChosenFilter("week", this.props.filterWeeks) }
						<div className={classnames({"filter_arrow": true, "active": this.state.isOpenWeek})}></div>
						{ this.state.isOpenWeek &&
							<div className="drop_filter" onClick={this.preventDef}>
								{ this.state.weeks.map((item, i)=>
									<div className="one_filter_drop" key={i} onClick={() => this.selectDate("weeks", item, "isOpenWeek")}>
										<div className={classnames({"filter_toogle": true, "active": this.props.filterWeeks == item.id})}></div>
										<div className="filter_option">{item.name}</div>
									</div>
								)}
							</div>
						}
					</div>
					{ this.props.filterWeeks > -1 &&
						<div className={classnames({"one_filter_review": true, "active": this.state.isOpenDay})} onClick={() => this.openCloseFilter("isOpenDay")}>{this.props.filterDays == -1 ? "Day (all)" : this.getChosenFilter("day", this.props.filterDays) }
							<div className={classnames({"filter_arrow": true, "active": this.state.isOpenDay})}></div>
							{ this.state.isOpenDay &&
								<div className="drop_filter" onClick={this.preventDef}>
									{ this.state.dayFilters.map((item, i)=>
										<div className="one_filter_drop" key={i} onClick={() => this.selectDate("days", item, "isOpenDay")}>
											<div className={classnames({"filter_toogle": true, "active": this.props.filterDays == item.dayInWeek})}></div>
											<div className="filter_option">{item.name}</div>
										</div>
									)}
								</div>
							}
						</div>
					}
					<div className="with_notes_content" onClick={this.props.changeNoteFilter}>
						<div className="notes_filter_title">Only with notes</div>
						<div className={classnames({"filter_toogle": true, "active": this.props.onlyWithNotes})}></div>
					</div>
				</div>
			)
		}
	}

export default ReviewFilters;