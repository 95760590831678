const readBlob = function(file) {
  var MIMEType = file.type;
  // decode base64 string, remove space for IE compatibility
  var reader = new FileReader();
	
  var promise = new Promise((resolve, reject) => {  
	//resolve(""); return;
	  reader.onload = function(readerEvt) {
		// This is done just for the proof of concept
		var binaryString = readerEvt.target.result;
		var base64 = btoa(binaryString);
		var blobfile = atob(base64);  
		var blobFromBlobFile = b64toBlob(base64, MIMEType, 512);
		resolve(blobFromBlobFile);
	  };

	  reader.readAsBinaryString(file);
  });

  return promise;
}	
		
const b64toBlob = function(b64Data, contentType, sliceSize) {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, {type: contentType});
	return blob;
}


export { readBlob } 