import React, { Component } from "react";
import { browserHistory } from 'react-router'
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import { getSessionDate } from '../../util/formatDateFunctions';

import { getSessionTime } from '../../util/formatDateFunctions';

class ReportsClient extends Component {
constructor(props) {
	super(props);
	this.state = { 
					currentSubTab: 'today',
					searchStartDate: this.props.startDate,
					searchEndDate: this.props.endDate,
					dataChange: false,
					currentHost: -1,
					currentInterest: -1,
					sessionStatus: -1,
					currentArray: [],
					sortByVal: ['id', 'DESC'],
					pageCount: 20,
					userSessionPeriods: [],
					selectedPeriod: null,
					searchClient: '',
					partName: '',
					partEmail: '',
				}

				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);

}

changeTab=(e)=>{
	if ( e != 'waivers' )
		{
			this.setState({searchStartDate: new Date(), dataChange: false, currentHost: -1, currentInterest: -1, sessionStatus: -1, searchStartDate: new Date(), dataChange: false}, function ()
				{
					$('#currentInterest, #currentHost, #sessionStatus').val(-1).attr({'style':''});
					this.props.updateList({startDate: new Date(), ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: {}, currentSubTab: e});
				});
		}
	else 
		{
			this.setState({searchStartDate: '', dataChange: false, currentHost: -1, currentInterest: -1, sessionStatus: -1}, function ()
				{
					$('#currentInterest, #currentHost, #sessionStatus').val(-1).attr({'style':''});
					this.props.updateList({startDate: new Date(), ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: {}, currentSubTab: e, firstStatus : 1});
				});
		}		
}

changeCurDate=(e)=>{

	this.setState({searchStartDate: e, dataChange: true}, function ()
		{
			this.props.updateList({startDate: this.state.searchStartDate, ajaxLoaded: false, currentArray: [], updateReload: 1});
		});
}

changeCurDateEnd=(e)=>{

	this.setState({searchEndDate: e, dataChange: true}, function ()
		{
			this.props.updateList({endDate: this.state.searchEndDate, ajaxLoaded: false, currentArray: [], updateReload: 1});
		});
}

updateThisFilter=(e)=>{
	this.setState({[e.target.id]: e.target.value}, function ()
		{
			var fullFilter = {currentHost: this.state.currentHost, currentInterest: this.state.currentInterest, sessionStatus: this.state.sessionStatus, searchClient: this.state.searchClient};
			this.props.updateList({startDate: this.state.searchStartDate, pageCount: this.state.pageCount, ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: fullFilter});
		});
	
	e.preventDefault();
}

resetFilter=()=>{
	
	this.setState({searchStartDate: new Date(), dataChange: false, currentHost: -1, currentInterest: -1, sessionStatus: -1});
	$('#currentInterest, #currentHost, #sessionStatus').val(-1).attr({'style':''});
	this.setState({searchStartDate: new Date(), dataChange: false, partName: '',partEmail: ''}, function ()
		{
			if ( this.props.currentSubTab == 'waivers' )
				{
					this.props.updateList({startDate: new Date(), ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: {}, firstStatus : 1});
				}
			else 
				{
					this.props.updateList({startDate: new Date(), ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: {}});
				}				
		});
}

sortArrays=(sortby)=>{
	var sortorder = 'ASC';
	if ( this.state.sortByVal[0] == sortby ) { sortorder = 'DESC'; }

	this.props.updateList({ajaxLoaded: false, currentArray: [], updateReload: 1, startPage: 1, sortByVal: [sortby, sortorder]});
	this.setState({sortByVal: [sortby, sortorder]});
}

openPage=(pg)=>{

var fullFilter = {currentHost: this.state.currentHost, currentInterest: this.state.currentInterest, sessionStatus: this.state.sessionStatus};
this.props.updateList({startDate: this.state.searchStartDate, ajaxLoaded: false, currentArray: [], updateReload: 1, startPage: pg, filterDaily: fullFilter, pickedStartPage: true});

}

componentDidMount(){
	this.setState({searchStartDate: new Date(), dataChange: false, currentHost: -1, currentInterest: -1, sessionStatus: -1});
	$('#currentInterest, #currentHost, #sessionStatus').val(-1).attr({'style':''});
}

componentDidUpdate(nextProps, prevState){
	if( moment(new Date(this.props.startDate)).format('YYYYMMDD') !== moment(new Date(nextProps.startDate)).format('YYYYMMDD') ) { console.log('match'); this.setState({ searchStartDate: this.props.startDate }); }
	if( moment(new Date(this.props.endDate)).format('YYYYMMDD') !== moment(new Date(nextProps.endDate)).format('YYYYMMDD') ) { console.log('match'); this.setState({ searchEndDate: this.props.endDate }); }
}

expandRow=(ind)=>{
	
	var oldarr = this.props.currentArray;
	oldarr[ind]['expandRow'] = oldarr[ind]['expandRow'] == true ? false : true;
	this.props.updateList({currentArray: oldarr});
	
	this.props.loadData(ind);
}

showMoreInfo = (e, sess_id, indx) => {
	e.stopPropagation();
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
}

openMoreInfo=(e, justClose)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	
};

setNewDate = (e) => {
	
	this.setState({
		searchStartDate: e
		});
}

setEndDateNew = (e) =>{
	this.setState({
		searchEndDate: e
		})
}

searchResults = () => {
	this.setState({ dataChange: true });
	
	var fullFilter = {currentHost: this.state.currentHost, participantName : this.state.partName, participantEmail : this.state.partEmail, waiverStartDate : moment(this.state.searchStartDate).format('MM/DD/YYYY'), endWaiverDate: moment(this.state.searchEndDate).format('MM/DD/YYYY')};
	this.props.updateList({startDate: this.state.searchStartDate, pageCount: this.state.pageCount, ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: fullFilter});	
}

getPlanPdf = () =>{
	$('.new_load_waivers').attr({'style':'display:block'});
	let uData = JSON.parse(localStorage.getItem('userData')).data.responseData;
	var fullFilter = {};
	//var fullFilter = {currentHost: this.state.currentHost, participantName : this.state.partName, participantEmail : this.state.partEmail, waiverStartDate : moment(this.state.searchStartDate).format('MM/DD/YYYY'), endWaiverDate: moment(this.state.searchEndDate).format('MM/DD/YYYY')};
	
	var sendData = {channelId: uData.channel, filterDaily: fullFilter};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelWaiversPDF", sendData).then(res => {
		var pdfUrl = res.data.responseData;
		window.open(pdfUrl);
		$('.new_load_waivers').attr({'style':'display:none'});
	}).catch(err =>{ $('.new_load_waivers').attr({'style':'display: none'}); });
	
}

  render() {
	
    return (
		<div>
			<div className="daily_session_hold noprint"> 
					<div className={ "daily_section " + ( this.props.currentSubTab == 'live' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('live')}}>
						<div className="daily_title">Live Classes</div>
						<div className="daily_arrow"></div>
					</div>
					<div className={ "daily_section " + ( this.props.currentSubTab == 'ondemand' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('ondemand')}}>
						<div className="daily_title">On-demand</div>
						<div className="daily_arrow"></div>
					</div>
					{ /* <div className={ "daily_section " + ( this.props.currentSubTab == 'livepayments' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('livepayments')}}>
						<div className="daily_title">Live Class Payments</div>
						<div className="daily_arrow"></div>
					</div> */ }
					<div className={ "daily_section " + ( this.props.currentSubTab == 'waivers' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('waivers')}}>
						<div className="daily_title">Waivers</div>
						<div className="daily_arrow"></div>
					</div>
				</div>
			 
			<div className="pay_main">
				<div className="pay_head">
					{ this.props.currentSubTab != 'waivers' ?
						<div className="pay_head_title noprint">Clients Reports</div>
						:
						<div className="pay_head_title noprint">Waivers</div>
					}
					
						<div className="show_pay_page">
							Show
								<span className="select_span">
									<select onChange={this.updateThisFilter} defaultValue="20" className="input-field" id="pageCount">
										<option value="10">10</option>
										<option value="20">20</option>
										<option value="50">50</option>
										<option value="100">100</option>
									</select>
								</span>
							{ this.props.currentSubTab != 'waivers' ?	
								<span>Clients</span>
								:
								<span>Waivers</span>
							}
						</div>
						
						<div className="show_search_opt noprint">
							{ ( this.state.dataChange || this.state.currentHost > 0 || this.state.currentInterest > 0 || this.state.sessionStatus > 0 ) && <div className="form-group">
								<div className="presetfilter" onClick={this.resetFilter}>Reset Filter</div>
							</div> }
							
							{ !this.props.currentSubTab == 'waivers' &&
								<div className="form-group">
									<span className="cover-border"></span>
									<label className="label">Search Clients</label>
									<input className="input-field" value={this.props.searchClient} onChange={this.updateThisFilter} id="searchClient" />
								</div>
							}
							{ this.props.currentSubTab == 'waivers' &&
								<div className="form-group">
									<span className="cover-border"></span>
									<label className="label">Member Name</label>
									<input id="partName" value={this.state.partName} onChange={(e)=>this.setState({[e.target.id]:e.target.value})} className="input-field" />
								</div>
							}
							{ this.props.currentSubTab == 'waivers' &&
								<div className="form-group">
									<span className="cover-border"></span>
									<label className="label">Member Email</label>
									<input id="partEmail" value={this.state.partEmail} onChange={(e)=>this.setState({[e.target.id]:e.target.value})} className="input-field" />
								</div>
							}
							{ this.props.currentSubTab == 'waivers' ?
								<div className="form-group place_hold_text">
									<span className="cover-border"></span>	
									<label className="label">From</label>
									<DatePicker popperPlacement="bottom-start" popperModifiers={{flip: { enabled: false }, preventOverflow: { enabled: true, escapeWithReference: true, boundariesElement: 'viewport' } }} className="form-control datapickpay" placeholderText={this.state.searchStartDate !== '' ? moment(this.state.searchStartDate).format('MM/DD/YYYY') : ''} onChange={date => this.setNewDate(date)} />	
								</div>
								:
								<div className="form-group">
									<span className="cover-border"></span>	
									<label className="label">Start Date</label>
									<DatePicker popperPlacement="bottom-start" popperModifiers={{flip: { enabled: false }, preventOverflow: { enabled: true, escapeWithReference: true, boundariesElement: 'viewport' } }} className="form-control datapickpay" placeholderText="mm/dd/yy" maxDate={new Date()} selected={this.state.searchStartDate} onChange={date => this.changeCurDate(date)} />
								</div>
							}
							
							{ this.props.currentSubTab == 'waivers' ?
								<div className={"form-group place_hold_text " +( this.props.currentSubTab == 'waivers' ? "marg_left_loop" : "" )}>
										<span className="cover-border"></span>
											<label className="label">To</label>
										<DatePicker popperPlacement="bottom-start" popperModifiers={{flip: { enabled: false }, preventOverflow: { enabled: true, escapeWithReference: true, boundariesElement: 'viewport' } }} className="form-control datapickpay" placeholderText={moment(this.state.searchEndDate).format('MM/DD/YYYY')} onChange={date => this.setEndDateNew(date)} />
								</div>
								:
								<div className={"form-group " +( this.props.currentSubTab == 'waivers' ? "marg_left_loop" : "" )}>
										<span className="cover-border"></span>
											<label className="label">End Date</label>
										<DatePicker popperPlacement="bottom-start" popperModifiers={{flip: { enabled: false }, preventOverflow: { enabled: true, escapeWithReference: true, boundariesElement: 'viewport' } }} className="form-control datapickpay" placeholderText="mm/dd/yy" maxDate={new Date()}selected={this.state.searchEndDate} onChange={date => this.changeCurDateEnd(date)} />
								</div>
							}
							
							{ this.props.currentSubTab == 'waivers' &&
								<div className="right-small-box search_reports_hold" onClick={this.searchResults}>
									<img src="/images/search_loop.png" />
								</div>
							}
							{ !this.props.currentSubTab == 'waivers' &&
								<div className="form-group">
									<span className="cover-border"></span>
									<label className="label">Instructor</label>
									<select id="currentHost" onChange={this.updateThisFilter} className="input-field">
										<option key={-1} value={-1}>All Instructors</option>
										{ this.props.allHosts.map((item, key) => <option key={key} value={item['userId']}>{item['username']}</option> ) }
									</select>
								</div>
							}
							{ !this.props.currentSubTab == 'waivers' &&
								<div className="form-group">
									<span className="cover-border"></span>
									<label className="label">Category</label>
									<select id="currentInterest" onChange={this.updateThisFilter} className="input-field">
										<option value="-1">All Categories</option>
										{ this.props.allInterests.map((item, key) => <option key={key} value={item['id']}>{item['title']}</option> ) }
									</select>
								</div>
							}
						</div>
					
					
					
					{ this.props.currentArray.length > 0 && this.props.currentSubTab !== 'livepayments' && this.props.currentSubTab !== 'waivers' &&
					<table className="billing_table">
						<thead>
							<tr>
								<th>Client Name
									{ this.props.currentArray.length > 1 &&
										<div onClick={(e) => this.sortArrays('firstName')} className="reports_sort_icon"></div>
									}
								</th>
								<th>Client Email
									{ this.props.currentArray.length > 1 &&
										<div onClick={(e) => this.sortArrays('email')} className="reports_sort_icon"></div>
									}
								</th>
								{ this.props.currentSubTab == 'live' && <th>Attended Classes 
									{ this.props.currentArray.length > 1 &&
										<div onClick={(e) => this.sortArrays('totalSessions2')} className="reports_sort_icon"></div> 
									}
								</th> }
								{ this.props.currentSubTab == 'ondemand' && <th>On Demand Videos Watched
									{ this.props.currentArray.length > 1 && <div onClick={(e) => this.sortArrays('totalViews')} className="reports_sort_icon"></div> }
								</th> }
							</tr>
						</thead>
						<tbody> 
							{ this.props.currentArray.map((item, key) =>
								<React.Fragment key={key}>
									<tr key={key}>
										<td>{ item['firstName'] } { item['lastName'] } <div className={'info_btn '+( item['expandRow'] ? 'open' : '')} onClick={(e) => this.expandRow(key)}></div></td>
										<td>{ item['email'] }</td>
										{ this.props.currentSubTab == 'live' && <td>{ item['totalSessions2'] ? item['totalSessions2'] : 0 }</td> }
										{ this.props.currentSubTab == 'ondemand' && <td>{ item['watchNum'] ? item['watchNum'] : 0 }</td> }
									</tr>
									{
										item['expandRow'] &&
										<tr key={'mk'+key} className="subopen">
											<td colSpan={this.props.currentSubTab == 'today' ? 7 : 6}>
												{ !item['moreData'] && <div className="loadingResults"><div className="main_loader_inner"></div></div> }
												{ item['moreData'] && 
													<div className="reports_holder_scroll">
														<div className="moreDataShow">
															{ item['moreData'].map((alldata, key) => <div key={'j'+key} className="new_div_vw">{ alldata['mainTitle'] && <span>{alldata['mainTitle']}</span> }<table className="billing_table">
																<thead>
																	<tr>{ alldata['titles'].map((tt, key) => <th key={'c'+key}>{tt}</th> )}</tr>
																</thead>
																<tbody>
																	{ alldata['data'].map((dta, key) => <tr key={'vv'+key}>{ dta.map((dtv, key) => <td className="dtval ch_add_dtval" key={'v'+key}>{dtv}</td> )}</tr>)}
																</tbody>
															</table></div> )}
														</div> 
													</div>
												}
											</td>
										</tr>
									}
								</React.Fragment>
							)}
						</tbody>
					</table> }
					{ this.props.currentArray.length > 0 && this.props.currentSubTab === 'waivers' &&
						<table className="billing_table">
							<thead>
								<tr>
									<th>Link</th>
									<th>Expire Date</th>
									<th>Status</th>
									<th>Member Name</th>
									<th>Member Email</th>
									<th>Date of Agreement</th>
									<th> <div className="refund_button" onClick={this.getPlanPdf} >PDF</div> </th>
								</tr>
							</thead>
							<tbody> 
								{ this.props.currentArray.map((item, key) =>
									<tr key={key}>
										<td>{item['pdfLink']}</td>
										{item['waiverEndDate'] != "-" ?
											<td>{this.getSessionDate(new Date(item['waiverEndDate']))}</td>
											:
											<td>-</td>
										}
										{item['status'] == 1 ? 
											<td>Accepted</td>
											:
											<td>Expired</td>
										}
										<td>{item['firstName']} {item['lastName']}</td>
										<td>{item['email']}</td>
										<td>{this.getSessionDate(item['signDate'])}</td>
					{/* whiteboard code
										{ item['waiverEndDate'] ?
											<td>{ this.getSessionDate(item['waiverEndDate'])} @ {this.getSessionTime(item['waiverEndDate'])}</td>
											:
											<td>-</td>
										}
										<td>{item['statusFormated']}</td>
										<td>{item['totalParticipantsSigned']}
											{ item.totalParticipantsSigned > 0 && 
												<div className="info_btn" onClick={(e) => this.showMoreInfo(e)}>
													<div className="all_info pink_info all_info_daily wdth500" >
														<div className="close_all_info">
															<div className="close_all_info_btn" onClick={ (e) => this.openMoreInfo(e, true)}>x</div>
														</div>
														<div className="scroll_all_info">
														{ item.waiverParticipantArray &&
															<table>
																<thead>
																	<tr>
																		<td>Member Id</td>
																		<td>Member Name</td>
																		<td>Email</td>
																	</tr>
																</thead>
																<tbody>
																{ item.waiverParticipantArray.map((it, ind) => 
																	<tr key={ind}>
																		<td>{it['participantId']}</td>
																		<td>{it['participantFirstName']} {it['participantLastName']}</td>
																		<td>{it['participantEmail']}</td>
																	</tr>
																)}
																</tbody>
															</table>
														}
														</div>
													</div>
												</div>
											}
										</td>
								*/}
									</tr>
								)}
							</tbody>
						</table>	
						
					}
					
					{ this.props.currentArray.length > 0 && this.props.currentSubTab === 'livepayments' &&
						<table className="billing_table">
							<thead>
								<tr>
									<th>Class Name</th>
									<th>Member Name</th>
									<th>Members Amount</th>
									<th>Non-Members Amount</th>
									<th>Member Charged Amount</th>
									<th>Date</th>
								</tr>
							</thead>
							<tbody> 
							{ this.props.currentArray.map((item, key) =>
								<React.Fragment key={key}>
									<tr key={key}>
										<td>{item['sessionName']}</td>
										<td>{item['firstName']} {item['lastName']}</td>
										<td>${item['chargeForSession']}</td>
										<td>${item['chargeForSessionNonMember']}</td>
										<td>${item['amount']}</td>
										<td>{item['billDate']}</td>
									</tr>
								</React.Fragment>
							)}
						</tbody>
						</table>
					}
					
					{ this.props.currentArray.length == 0 && this.props.ajaxLoaded && this.props.currentSubTab === 'waivers' && <div className="noResults">No Waivers Found</div> }
					{ this.props.currentArray.length == 0 && this.props.ajaxLoaded && this.props.currentSubTab !== 'livepayments' && this.props.currentSubTab !== 'waivers' && <div className="noResults">No Clients Found</div> }
					{ this.props.currentArray.length == 0 && this.props.ajaxLoaded && this.props.currentSubTab === 'livepayments' && <div className="noResults">No Payments Found</div> }
					{ this.props.currentArray.length == 0 && !this.props.ajaxLoaded && <div className="loadingResults"><div className="main_loader_inner"></div></div> }
					{ this.props.currentSubTab === 'waivers' && <div className="new_load_waivers"><div className="main_loader_inner waiver_inner"></div></div> }
					
					{ this.props.currentArray.length > 0 && this.props.currentSubTab !== 'livepayments' &&
						<div className="showPageNavigation">
							<div className="showpinfo">Total { this.props.currentArray[0].totalNumber }
								{ this.props.currentSubTab === 'waivers' ?
									<span> Waivers</span>
									:
									<span> Clients</span>
								}
							</div>
							{ this.props.totalPages.length > 1 && <div className="shopnumbers">
								<span>
									{ this.props.totalPages.map((item, nindex) => 
										<span key={nindex} onClick={() => this.openPage(item)} className={"pgNumbers " + (this.props.startPage === item ? 'active' : '')}>{item}</span>	
									)}
								</span>
							</div> }
						</div>
					}
					
				</div>
			</div>
		</div>
    );
  }
}

export default ReportsClient;