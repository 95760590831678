import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';

class SupportContainer extends Component {
	constructor(props) {
	super(props);

	this.state = {
		isOpenSupport: false,
		activeMenu: 'faq',
		faqArray: [],
		searchText: '',
		isOpenQuestion: false,
		openedQuestion: {},
		isOpenQuestionParent: false,
		openedQuestionParent: {},
		supportName: '',
		supportEmail: '',
		supportHelp: '',
		supportAttachmentsArray: [],
		errorEmail: false,
		errorHelp: false,
		showLoader: false,
		hasSearched: false
	  };
	  
	  window['openSupport'] = this.openSupport;

	}

	componentDidMount() {
		this.getQuestions('');
	}
	
	openCloseSupport=()=>{
		var isOpen = this.state.isOpenSupport;
		isOpen = !isOpen;
		this.setState({isOpenSupport: isOpen});
	}	

	changeMenu=(pg)=>{
		this.setState({activeMenu: pg});
	}
	
	getQuestions=(search)=>{
		var postData = {page: this.props.supportPageApi, userType: this.props.userTypeFaqs, searchText: search, isMobile: 0};
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getPageFaqs", postData).then(res => {
			this.setState({faqArray: res.data.responseData});
			if ( search !== '' ) { this.setState({hasSearched: true}); }
			else { this.setState({hasSearched: false}); }
		}).catch(err =>{ });
	}
	
	changeInput=(e)=>{
		this.setState({[e.target.id]:e.target.value});
		
		if ( e.target.id === 'supportEmail' ) { this.setState({errorEmail: false}); }
		if ( e.target.id === 'supportHelp' ) { this.setState({errorHelp: false}); }
	}
	
	checkEvent=(ev)=>{
		if ( ev.key === 'Enter' ) { this.getQuestions(ev.target.value); }
	}
	
	openQuestion=(que, parentQue)=>{
		if ( !parentQue )
			{
				this.setState({isOpenQuestion: true, openedQuestion: que});
				if ( que.isParent === 1 )
					{
						var postData = {questionId: que.id};
						axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getParentFaqQuestions", postData).then(res => {
							que['listQuestions'] = res.data.responseData;
							this.setState({openedQuestion: que});
							
						}).catch(err =>{ });
					}
			}
		else
			{
				this.setState({isOpenQuestionParent: true, openedQuestionParent: que});
			}
	}
	
	goBackQuestions=()=>{
		this.setState({isOpenQuestion: false, openedQuestion: {}});
	}
	
	goBackQuestionsParent=()=>{
		this.setState({isOpenQuestionParent: false, openedQuestionParent: {}});
	}
	
	openUrl=(url)=>{
		window.open(url);
	}
	
	startSearch=()=>{
		this.getQuestions(this.state.searchText);
	}
	
	openSupport=()=>{
		this.setState({isOpenSupport: true, activeMenu: 'contact'});
	}
	
	uploadAttachment=(ev)=>{
		var file = ev.target.files[0];
		
		if ( file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf' || file.type === 'video/mp4' )
			{
				var attachArraySupp = this.state.supportAttachmentsArray;
				if ( attachArraySupp.length < 5 ) { attachArraySupp.push(file); }
					
				this.setState({supportAttachmentsArray: attachArraySupp});
			}
	}
	
	removeAttach=(i)=>{
		var attachArraySupp = this.state.supportAttachmentsArray;
		attachArraySupp.splice(i, 1);
		
		this.setState({supportAttachmentsArray: attachArraySupp});
	}
	
	validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	
	sendZendesk=()=>{
		var canSendSupport = true;
		
		if ( !this.validateEmail(this.state.supportEmail) ) { canSendSupport = false; this.setState({errorEmail: true}); }
		if ( this.state.supportHelp === '' ) { canSendSupport = false; this.setState({errorHelp: true}); }
		
		if ( canSendSupport )
			{
				this.setState({showLoader: true});
				var attachmentsArray = [];
				if ( this.state.supportAttachmentsArray.length > 0 )
					{
						var pushedAttaches = 0;
						for ( var i = 0; i < this.state.supportAttachmentsArray.length; i++ )
							{
								const data = new FormData();
								data.append('file', this.state.supportAttachmentsArray[i]);
								axios.post(process.env.REACT_APP_NAME+"/api/v1/upload/uploadSupport", data)
								.then(res => {
									attachmentsArray.push(res.data.responseData.file);
									pushedAttaches++;
									if ( pushedAttaches === this.state.supportAttachmentsArray.length )
										{
											var postData = {
															'supportName': this.state.supportName,
															'supportEmail': this.state.supportEmail,
															'supportHelp': this.state.supportHelp,
															'token': btoa(this.state.supportEmail+'/token:8jJbNxo7V0XoMPnwF1mu5RYurG6BVeIIMQSqOxWF'),
															'attachments': JSON.stringify(attachmentsArray)
														};
									
											axios.post(process.env.REACT_APP_NAME+"/api/v1/user/sendSupportHelp", postData).then(res => {
												this.setState({supportName: '', supportEmail: '', supportHelp: '', supportAttachmentsArray: [], showLoader: false});
												this.props.showMainErrorPop("Thank you for contacting Virdio.");		
											}).catch(err =>{ this.setState({showLoader: false}); });
										}
										
								}).catch(err =>{ this.setState({showLoader: false}); });
							}
					}
				else
					{
						var postData = {
											'supportName': this.state.supportName,
											'supportEmail': this.state.supportEmail,
											'supportHelp': this.state.supportHelp,
											'token': btoa(this.state.supportEmail+'/token:8jJbNxo7V0XoMPnwF1mu5RYurG6BVeIIMQSqOxWF'),
											'attachments': '[]'
										};
									
						axios.post(process.env.REACT_APP_NAME+"/api/v1/user/sendSupportHelp", postData).then(res => {
							this.setState({supportName: '', supportEmail: '', supportHelp: '', supportAttachmentsArray: [], showLoader: false});
							this.props.showMainErrorPop("Thank you for contacting Virdio.");
						}).catch(err =>{ this.setState({showLoader: false}); });
					}
			}
	}
	
	backFromSearch=()=>{
		this.setState({searchText: ''});
		this.getQuestions('');
	}

	render() {

		return (

			<div className="allSupport">
				{ !this.state.isOpenSupport &&
					<div className="support_help" onClick={this.openCloseSupport}>Help
						<div className="question_icon"></div>
					</div>
				}
				{ this.state.isOpenSupport &&
					<div className="support_container">
						{ this.state.activeMenu === 'faq' && !this.state.isOpenQuestion && !this.state.hasSearched &&
							<div className="header_support">Virdio Help - {this.props.supportPageName}
								<div className="close_faqsupp" onClick={this.openCloseSupport}></div>
							</div>
						}
						{ this.state.activeMenu === 'faq' && !this.state.isOpenQuestion && this.state.hasSearched &&
							<div className="header_support back_support_title" onClick={this.backFromSearch}>Back
								<div className="back_arrow"></div>
							</div>
						}
						{ this.state.activeMenu === 'faq' && this.state.isOpenQuestion && !this.state.isOpenQuestionParent &&
							<div className="header_support back_support_title" onClick={this.goBackQuestions}>Back
								<div className="back_arrow"></div>
							</div>
						}
						{ this.state.activeMenu === 'faq' && this.state.isOpenQuestion && this.state.isOpenQuestionParent &&
							<div className="header_support back_support_title" onClick={this.goBackQuestionsParent}>Back
								<div className="back_arrow"></div>
							</div>
						}
						{ this.state.activeMenu === 'contact' && 
							<div className="header_support">Leave us a message
								<div className="close_faqsupp" onClick={this.openCloseSupport}></div>
							</div>
						}
						<div className="support_middle_part">
							<div className={(this.state.activeMenu !== 'faq' || this.state.isOpenQuestion ? 'd-none' : '')}>
								<div className="questions_middle_title">Frequently Asked Questions</div>
								<div className="questions_container">
									{ this.state.faqArray.length > 0 &&
										<div>
											{ this.state.faqArray.map((que, i)=>
												<div key={i} className="one_support_que" onClick={() => this.openQuestion(que)}>{que.question}
													<div className="abs_question_arrow"></div>
												</div>
											)}
										</div>
									}
									{ this.state.faqArray.length === 0 &&
										<div className="relative_empty">No available FAQ. Try searching again.</div>
									}
								</div>
								<div className="faq_search_container">
									<div className="search_faq">
										<div className="search_icon"></div>
										<input className="input_faq" type="text" autoComplete="off" placeholder="Write a question or problem" id="searchText" value={this.state.searchText} onChange={(e) => this.changeInput(e)} onKeyPress={event => {this.checkEvent(event)}} />
										<div className="start_search" onClick={this.startSearch}></div>
									</div>
								</div>
							</div>
							<div className={(this.state.activeMenu !== 'faq' || !this.state.isOpenQuestion ? 'd-none' : '')}>
								<div className="question_chosen_container">
									<div className="question_title_support">Question:</div>
									{ this.state.isOpenQuestionParent && this.state.openedQuestionParent && this.state.openedQuestionParent.question &&
										<div className="chosen_que">{this.state.openedQuestionParent.question}</div>
									}
									{ !this.state.isOpenQuestionParent && this.state.openedQuestion && this.state.openedQuestion.question &&
										<div className={"chosen_que " + (this.state.openedQuestion.question.length > 80 ? 'widder_question' : '')}>{this.state.openedQuestion.question}</div>
									}
								</div>
								{ this.state.openedQuestion.isParent === 0 &&
									<div className="answer_container">
										{ this.state.openedQuestion.answer !== '' &&
											<pre className="faq_answer" dangerouslySetInnerHTML={{__html: this.state.openedQuestion.answer}}></pre>
										}
										{ ( this.state.openedQuestion.videoUrl !== '' || this.state.openedQuestion.secondVideoUrl !== '' || this.state.openedQuestion.pdfUrl !== '' ) &&
											<div className="buttons_container_faq">
												{ this.state.openedQuestion.videoUrl !== '' &&
													<div className="faq_btns" onClick={() => this.openUrl(this.state.openedQuestion.videoUrl)}>Open Video</div>
												}
												{ this.state.openedQuestion.secondVideoUrl !== '' &&
													<div className="faq_btns" onClick={() => this.openUrl(this.state.openedQuestion.secondVideoUrl)}>Open Second Video</div>
												}
												{ this.state.openedQuestion.pdfUrl !== '' &&
													<div className="faq_btns" onClick={() => this.openUrl(this.state.openedQuestion.pdfUrl)}>Open PDF</div>
												}
											</div>
										}
									</div>
								}
								{ this.state.openedQuestion.isParent === 1 && this.state.openedQuestion.listQuestions && this.state.openedQuestion.listQuestions.length > 0 &&
									<div>
										{ !this.state.isOpenQuestionParent &&
											<div className="list_container_parent">
												{ this.state.openedQuestion.listQuestions.map((que, i)=>
													<div key={i} className="one_support_que" onClick={() => this.openQuestion(que, true)}>{que.question}
														<div className="abs_question_arrow"></div>
													</div>
												)}
											</div>
										}
										{ this.state.isOpenQuestionParent &&
											<div className="answer_container">
												{ this.state.openedQuestionParent.answer !== '' &&
													<pre className="faq_answer" dangerouslySetInnerHTML={{__html: this.state.openedQuestionParent.answer}}></pre>
												}
												{ ( this.state.openedQuestionParent.videoUrl !== '' || this.state.openedQuestionParent.secondVideoUrl !== '' || this.state.openedQuestionParent.pdfUrl !== '' ) &&
													<div className="buttons_container_faq">
														{ this.state.openedQuestionParent.videoUrl !== '' &&
															<div className="faq_btns" onClick={() => this.openUrl(this.state.openedQuestionParent.videoUrl)}>Open Video</div>
														}
														{ this.state.openedQuestionParent.secondVideoUrl !== '' &&
															<div className="faq_btns" onClick={() => this.openUrl(this.state.openedQuestionParent.secondVideoUrl)}>Open Second Video</div>
														}
														{ this.state.openedQuestionParent.pdfUrl !== '' &&
															<div className="faq_btns" onClick={() => this.openUrl(this.state.openedQuestionParent.pdfUrl)}>Open PDF</div>
														}
													</div>
												}
											</div>
										}
									</div>
								}
							</div>
							<div id="zendesk_part" className={"plug_container " + (this.state.activeMenu !== 'contact' ? 'd-none' : '')}>
								{ !this.state.showLoader &&
									<div>
										<div className="support_input_container">
											<div className="support_input_title">Your name (optional)</div>
											<input type="text" className="support_input" autoComplete="off" placeholder="Your name" id="supportName" value={this.state.supportName} onChange={(e) => this.changeInput(e)} />
										</div>
										<div className="support_input_container">
											<div className="support_input_title">Email address</div>
											<input type="text" className={"support_input " + (this.state.errorEmail ? 'support_error' : '')} autoComplete="off" placeholder="Your email address" id="supportEmail" value={this.state.supportEmail} onChange={(e) => this.changeInput(e)} />
										</div>
										<div className="support_input_container">
											<div className="support_input_title">How can we help you?</div>
											<textarea className={"support_input area_support " + (this.state.errorHelp ? 'support_error' : '')} autoComplete="off" id="supportHelp" value={this.state.supportHelp} onChange={(e) => this.changeInput(e)}></textarea>
										</div>
										<div className="support_input_container">
											{ this.state.supportAttachmentsArray.length === 0 &&
												<div className="support_input_title">Attachments <span>(Add up to 5 files)</span></div>
											}
											{ this.state.supportAttachmentsArray.length > 0 &&
												<div>
													<div className="support_input_title">Attachments ({this.state.supportAttachmentsArray.length})</div>
													{ this.state.supportAttachmentsArray.map((item, i)=>
														<div className="one_attach_support" key={i}>
															{ (item.type === 'image/jpeg' || item.type === 'image/png') &&
																<div className="support_attach_img image_icon"></div>
															}
															{ item.type === 'video/mp4' &&
																<div className="support_attach_img video_icon"></div>
															}
															{ item.type === 'application/pdf' &&
																<div className="support_attach_img document_icon"></div>
															}
															<div className="remove_attach" onClick={() => this.removeAttach(i)}></div>
															<div className="support_attach_name">{item.name}</div>
															<div className="support_attach_size">{Math.round(item.size/1000)} KB</div>
														</div>
													)}
												</div>
											}
											{ this.state.supportAttachmentsArray.length < 5 &&
												<div className="attachments_container">
													<span className="relative_files">(Add up to 5 files)
														<div className="clip_icon"></div>
													</span>
													<input type="file" className="opacity_file" onChange={ (e) => { this.uploadAttachment(e) }} />
												</div>
											}
										</div>
										<div className="support_send_container">
											<div className="support_send_btn" onClick={this.sendZendesk}>Send</div>
										</div>
									</div>
								}
								{ this.state.showLoader &&
									<div className="support_loader_holder">
										<div className="loader_support"></div>
										<div className="loader_support_info">Sending...</div>
									</div>
								}
							</div>
						</div>
						<div className="support_footer">
							<div className={"support_menu first_menu_supp " + (this.state.activeMenu === 'faq' ? 'active_support' : '')} onClick={() => this.changeMenu('faq')}>
								<div className="sup_footer_icons faq_icon"></div>
								<div className="sup_footer_title">F.A.Q.</div>
							</div>
							<div className={"support_menu " + (this.state.activeMenu === 'contact' ? 'active_support' : '')} onClick={() => this.changeMenu('contact')}>
								<div className="sup_footer_icons contact_icon"></div>
								<div className="sup_footer_title">Contact Virdio</div>
							</div>
						</div>
					</div>
				}
			</div>

		);

	 }

}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(SupportContainer);

