import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class ReportsNewSessions extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartDate: null,
					searchEndDate: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					productList: [],
					sortedChannelName: false,
					sortedSessionName: false,
					sortedHostName: false,
					sortedDate: false,
					sortByVal: ['id', 'DESC'],
					ajaxLoaded: false,
					checkInfoOpen: false,
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	document.addEventListener('mousedown', this.checkInfoOpen);
}
 
componentWillUnmount() {
	document.removeEventListener('mousedown', this.checkInfoOpen);
}

checkInfoOpen = (e) => {
	if(e.target !== "info_btn" && $(e.target).closest('.info_btn').length === 0 )
		{
			this.setState({ checkInfoOpen: false });
		}
}

openMoreInfo=(e, justClose)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	this.setState({ checkInfoOpen: false });
};

changePageCount = (e) => {
	let pg_id = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg_id
		});
	this.fetchSessionDetails(pg_id, e.target.value);
}

checkScroll = (ind) => {
	this.setState({
		checkInfoOpen: ((ind+3) >= this.state.billing_info.length) ? true : false
	});
}

getAllSessionUsers = (e, sess_id, indx, mod) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	var data = [...this.state.billing_info];
	
	$(e.currentTarget).find('.all_info').addClass('active');
	this.checkScroll(indx);
	
	if ( mod && mod == 1 )
		{
			axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/billing_info_users", { session_id: sess_id })          
			.then(res => {
				data[indx]['billUsers'] = res.data.responseData;
				this.setState({billing_info: data});
				$('.main_loader').hide();
			})
			.catch(err =>{
			  console.log('----------there is problem------------', err);
			});
		}
	if ( mod && mod == 2 )
		{
			axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getAllSessionProducts", { session_id: sess_id })          
			.then(res => {
				let products = res.data.responseData;
				this.setState({productList: products});
				$('.main_loader').hide();
			})
			.catch(err =>{
			  console.log('----------there is problem------------', err);
			});
		}
}

changePage = (e, mod) => {
	console.log(mod);
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchSessionDetails(mod, this.state.page_num_count);
		}
	
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ showSearch: !this.state.showSearch, searchStartDate: null, searchEndDate: null, searchBy: '' });
			
			this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

customChecked = () => {
	this.setState({ customCheck1: !this.state.customCheck1 });
}

searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchSessionDetails(pg, this.state.page_num_count); 
}

fetchSessionDetails = (page_id, countId, searchText = this.state.searchBy, startDate= this.state.searchStartDate, endDate= this.state.searchEndDate) => {
	
	
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});
  axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/reportsPastSessions", { page_id: page_id, countId: countId, searchText: searchText, startDate: startDate, endDate: endDate, openedFrom: 6, sortby: this.state.sortByVal })          
  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({billing_info: res.data.responseData['sessions'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	if( (this.state.page_id+6) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			 
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	});
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className="pay_head_title">New Classes of the Day</div>
								</div>
								<div className="show_pay_page">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
										</select>
									 </span>
									Classes
								</div>
								<div className="show_search_opt">
									
									{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-4' : ' col-xl-6')}>
														<input type="text" className="form-control" placeholder="Search for class name, category, channel, instructor or keyword" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
													</div>
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-8' : ' col-xl-6')}>
														{/* <div className="">
															<div className="px-4 d-flex justify-content-end align-items-center">				
																<div className="custom-control custom-checkbox mb-0">
																	<input type="checkbox" className="custom-control-input" id="customCheck1" value="false" checked={this.state.customCheck1} onChange={this.customChecked} name="example1" />
																	<label className="custom-control-label" htmlFor="customCheck1"><p className="pl-2 p-top-3 mb-0">ADVANCED</p></label>
																</div>
															</div>
														</div> */}
														
														{ 
															/* <div className="parent-row mb-3 full_width">				
																<div className="row mx-0 row1 d-flex">
																	<div className="col-md-12 d-flex flex-wrap align-items-center">	
																		<div className="filter_item">
																			<span className="searh_span">From</span>
																			<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchStartDate} onChange={date => this.setState({searchStartDate: date})} />
																		</div>
																		<div className="filter_item">
																			<span className="searh_span">To</span>
																			<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchEndDate} onChange={date => this.setState({searchEndDate: date})} />
																		</div>
																	</div>
																</div>
															</div>  */
														}
												</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
									
									
									
								</div>
								{ this.state.billing_info.length > 0 && 
									<table className="billing_table">
										<thead>
											<tr>
												<th>Channel Name
													<div onClick={ (e) => this.sortArrays('channel_name')} className="reports_sort_icon"></div>
												</th>
												<th>Class Name
														<div onClick={ (e) => this.sortArrays('name')} className="reports_sort_icon"></div>
												</th>
												<th>Instructor Name
													<div onClick={ (e) => this.sortArrays('host_firstName')} className="reports_sort_icon"></div>
												</th>
												<th>Date & Time
													<div onClick={ (e) => this.sortArrays('scheduleDate')} className="reports_sort_icon"></div>
												</th>  
												<th>Class length</th>
												<th>Members signed up</th>
											</tr>
										</thead>
										<tbody> 
											{ this.state.billing_info.map((item, key) =>
												<tr key={key}>
													<td>{ item['channel_name'] }</td>
													<td>{ item['name'] }
														<div className="info_btn" onClick={(e) => this.getAllSessionUsers(e, item['id'], key, 2)}>
															<div className="all_info pink_info1 all_info_daily all_session_info">
																<div className="close_all_info">
																	<div className="close_all_info_btn" onClick={ (e) => this.openMoreInfo(e, true)}>x</div>
																</div>
																<div className="scroll_all_info">
																	<div className="session_info_popup_hold">
																		<div className="session_info_hold">
																			<div className="session_info_title">DB Channel Id</div>
																			<div className="session_info_value">: {item['sessionChannelId']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Channel Name</div>
																			<div className="session_info_value">: {item['channel_name']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Channel City State</div>
																			<div className="session_info_value">: 
																			{ item['city'] != null &&
																						<span>{ item['city'] } { item['state'] }</span>
																					}
																					
																					{ item['city'] == null &&
																						<span>None</span>
																					}
																			</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Total Classes Hosted By Channel</div>
																			<div className="session_info_value">: {item['totalSessions']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Total Upcoming Classes by Channel</div>
																			<div className="session_info_value">: {item['totalUpcomingSessions']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">DB Class Id</div>
																			<div className="session_info_value">: {item['sessionId']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">DB Instructor Id</div>
																			<div className="session_info_value">: {item['userId']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Class length</div>
																			<div className="session_info_value">: {item['duration']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Class Created At</div>
																			<div className="session_info_value">: { this.getSessionDate(item['createdAt'])} @ {this.getSessionTime(item['createdAt'])}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Class Mindbody Id</div>
																			{ item['classScheduleId'] > 0 ?
																				<div className="session_info_value">: {item['classScheduleId']}</div>
																				:
																				<div className="session_info_value">: None</div>
																			}
																		</div>
																	</div>
																	<div className="session_info_popup_hold">
																		<div className="session_info_hold">
																			<div className="session_info_title">Instructor Name</div>
																			<div className="session_info_value">: {item['host_firstName']} { item['host_lastName'] }</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Instructor City State</div>
																			<div className="session_info_value">: 
																			{ item['city'] != null &&
																						<span>{ item['city'] } { item['state'] }</span>
																					}
																					
																					{ item['city'] == null &&
																						<span>None</span>
																					}
																			</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Instructor Sign Up Date on Virdio</div>
																			<div className="session_info_value">: {item['createAt']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Total Class Hosted on Virdio by Instructor</div>
																			<div className="session_info_value">: {item['totalHostSessions']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Total Upcoming Classes by Instructor</div>
																			<div className="session_info_value">: {item['totalHostUpcSessions']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Instructor Last Class Date</div>
																			<div className="session_info_value">: {item['latestDate']}</div>
																		</div>
																		<div className="session_info_hold">
																			<div className="session_info_title">Class status</div>
																			<div className="session_info_value">: {item['classStatus']}</div>
																		</div>
																	</div>
																	<div className="session_info_script_hold">Class Script</div>
																	<div className="reports_table_hold">
																		<table>
																			<thead>
																				<tr>
																					<th>Name</th>
																					<th>Type</th>
																					<th>Duration Type</th>
																					<th>Counts(Reps/Secs)</th> 
																					<th>Target Zone</th>
																					<th>Target BPM</th>
																					<th>Video</th>
																				</tr>
																			</thead>
																			<tbody>
																			{ this.state.productList.map((pro, key) =>
																				<tr key={key}>
																					<td>{pro['name']}</td>
																					<td>{pro['attributes'] && pro['attributes'].length > 0 &&
																					<div>
																					{pro['attributes'][0]['attrValue'] == "" &&
																						<div>-</div>
																					}
																					{pro['attributes'][0]['attrValue'] != "" &&
																						<div>{pro['attributes'][0]['attrValue']}</div>
																					}
																					</div>
																					}
																					<div>
																					{pro['attributes'] && pro['attributes'].length == 0 &&
																						<div>-</div>
																					}
																					</div>
																					</td>
																					<td>{pro['attributes'] && pro['attributes'].length > 1 &&
																					<div>
																					{pro['attributes'][1]['attrValue'] == "" &&
																						<div>-</div>
																					}
																					{pro['attributes'][1]['attrValue'] != "" &&
																						<div>{pro['attributes'][1]['attrValue']}</div>
																					}
																					</div>
																					}
																					<div>
																					{pro['attributes'] && pro['attributes'].length <= 1 &&
																						<div>-</div>
																					}
																					</div>
																					</td>
																					<td>{pro['attributes'] && pro['attributes'].length > 2 &&
																					<div>
																					{pro['attributes'][2]['attrValue'] == "" &&
																						<div>-</div>
																					}
																					{pro['attributes'][2]['attrValue'] != "" &&
																						<div>{pro['attributes'][2]['attrValue']}</div>
																					}
																					</div>
																					}
																					<div>
																					{pro['attributes'] && pro['attributes'].length <= 2 &&
																						<div>-</div>
																					}
																					</div>
																					</td>
																					<td>{pro['attributes'] && pro['attributes'].length > 5 &&
																					<div>
																					{pro['attributes'][5]['attrValue'] == "" &&
																						<div>-</div>
																					}
																					{pro['attributes'][5]['attrValue'] != "" &&
																						<div>{pro['attributes'][5]['attrValue']}</div>
																					}
																					</div>
																					}
																					<div>
																					{pro['attributes'] && pro['attributes'].length <= 5 &&
																						<div>-</div>
																					}
																					</div>
																					</td>
																					<td>{pro['attributes'] && pro['attributes'].length > 4 &&
																					<div>
																					{pro['attributes'][4]['attrValue'] == "" &&
																						<div>-</div>
																					}
																					{pro['attributes'][4]['attrValue'] != "" &&
																						<div>{pro['attributes'][4]['attrValue']}</div>
																					}
																					</div>
																					}
																					<div>
																					{pro['attributes'] && pro['attributes'].length <= 4 &&
																						<div>-</div>
																					}
																					</div>
																					</td>
																					<td>{pro['attributes'] && pro['attributes'].length > 3 &&
																					<div>
																					{pro['attributes'][3]['attrValue'] == "" &&
																						<div>-</div>
																					}
																					{pro['attributes'][3]['attrValue'] != "" &&
																						<div>{pro['attributes'][3]['attrValue']}</div>
																					}
																					</div>
																					}
																					<div>
																					{pro['attributes'] && pro['attributes'].length <= 3 &&
																						<div>-</div>
																					}
																					</div>
																					</td>
																				</tr>
																			)}
																			</tbody>
																		</table>
																	</div>
																</div>
															</div>
														</div>
													</td>
													<td>{item['host_firstName']} { item['host_lastName'] }</td>
													<td>{ this.getSessionDate(item['scheduleDate'])} @ {this.getSessionTime(item['scheduleDate'])}</td>
													<td>{ item['duration'] }</td>
													<td>{item['singedUpUsers']}
														{ item.singedUpUsers > 0 && 
															<div className="info_btn" onClick={(e) => this.getAllSessionUsers(e, item['id'], key, 1)}>
															<div className="all_info pink_info all_info_daily unset_wdth">
																	<div className="close_all_info">
																		<div className="close_all_info_btn" onClick={ (e) => this.openMoreInfo(e, true)}>x</div>
																	</div>
																<div className="scroll_all_info">
																	{ item.billUsers &&
																		<table>
																			<thead>
																				<tr>
																					<td>DB Member id</td>
																					<td>Member Name</td>
																					<td>Member Email Address</td>
																					<td>Member City State</td>
																					<td>Total Time Online</td>
																					<td>No of classes attended with Channel</td>
																					<td>No of classes attended on Virdio (all channels)</td>
																					<td>No of upcoming classes on Channel</td>
																					<td>No of upcoming classes on Virdio</td>
																					<td>OS/Browser</td>
																				</tr>
																			</thead>
																			<tbody>
																			{ item.billUsers.map((it, ind) => 
																				<tr key={ind}>
																					<td>{it['userId']}</td>
																					<td>{it['firstName']} { it['lastName'] }</td>
																					<td>{it['email']}</td>
																					{ it['city'] != null &&
																						<td>{ it['city'] } { it['state'] }</td>
																					}
																					
																					{ it['city'] == null &&
																						<td>Not selected</td>
																					}
																					<td>{it['attendingDurationConverted']}</td>
																					<td>{it['allSessionChannelCount']}</td>
																					<td>{it['allSessionCount']}</td>
																					<td>{it['allFutureChannelCount']}</td>
																					<td>{it['allFutureSessions']}</td>
																					<td>{it['deviceInfo']}</td>
																				</tr>
																			)}
																			</tbody>
																		</table>
																	}
																</div>
															</div>
														</div>
														}
													</td>
												</tr>
											
											) }
										</tbody>
									</table>
								}
								{ this.state.billing_info.length > 0 && 
									<div>
									<div className="showPageNavigation">
										<div className="showpinfo">Showing { this.state.page_id+1 } to { this.state.page_num_count } of { this.state.fullCount } classes</div>
										<div className="shopnumbers">
											
											{ (this.state.page_id - 1) > -1 &&
												<span onClick={(e) => this.changePage(e, this.state.page_id - 1)}>Previous</span>
											}
											
											<span>
												{ this.state.page_numbers.map((nitem, nindex) => 
													<span key={nindex} onClick={(e) => this.changePage(e, nitem-1)} className={"pgNumbers " + (this.state.page_id+1 === nitem ? 'active' : '')}>{nitem}</span>	
												)}
											</span>
											
											 { (this.state.page_id + 1) < this.state.maxPages &&
												<span onClick={(e) => this.changePage(e, this.state.page_id + 1)}>Next</span>
	
											 }
											</div>
									</div>
										<div className={ this.state.checkInfoOpen ? 'mob_pad_b250' : ''}></div>
									</div>
								}
								{ this.state.billing_info.length == 0 && this.state.ajaxLoaded && <div className="noResults2">No Classes Found</div> }
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Coming Soon</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
					</div>
				</div>
			</div>
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default ReportsNewSessions;