import React, { Component } from "react";
import { Link } from "react-router";
import $ from 'jquery';
import axios from "axios";
import { connect } from "react-redux";
import ChargingSession from '../home/ChargingSession';
import { showMainErrorPop } from '../../actions/popups';
import {  browserHistory } from 'react-router'
import SupportContainer from '../home/SupportContainer';

class trainingPlans extends Component {
	constructor() {
		super();

		this.cMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

		this.state = {
			sign_up: true,
			isTrainingPlans: true,
			isLoggedUser: false,
			isLoggedUser2: false,
			chosenPlanId: 0,
			chosenDemandPlanId: 0,
			chosenPlanName: '',
			chosenDemandPlanName: '',
			mainPlans: [],
			planRows: [],
			promoCode: {},
			promoDiscount: 1,
			promoText: '',
			cardsArray: [],
			isFreeTrial: false,
			contactName: '',
			contactCompanyName: '',
			contactEmail: '',
			contactMessage: '',
			promocodefld: '1',
			totalAmmount: 0,
			totalDiscount: 0,
			proRatedMonth: 0,
			totalAmmountFinal: 0,
			isOnDemandSelected: false,
			currentMonthYear: this.cMonth[((new Date()).getMonth())]+' '+(new Date()).getFullYear(),
			currentDate: this.cMonth[((new Date()).getMonth())]+' '+(new Date()).getDate()+', '+(new Date()).getFullYear(),
			currentLivePlan: '',
			currentDemandPlan: '',
			showFreeTrial: true,
			currentPlanMain: {},
			previousPlansDiscount: 0,
			discountPreviousLive: 0,
			discountPreviousDemand: 0,
			creditAmmount: 0,
			priceWithoutCredit: 0,
			channelImage: '',
			userFirstName: '',
			fullName: '',
			channelName: '',
			manualPayment: 0,
			noSendEmail: 0,
			ambasadorStatus: 0,
			supportPageName: 'Training Plans',
			supportPageApi: '/trainingPlans',
			userTypeFaqs: 2,
			enterPriceOverage: 0.59,
		};
		
		this.kk = 0;
		this.fullMonth = new Date(new Date().getFullYear(), new Date().getMonth()+1, 0).getDate();
		this.today = new Date().getDate()-1;
	}
	
startFreeTrial=()=>{
	this.setState({isFreeTrial: true});
	
	if ( !this.state.isLoggedUser )
		{
			$('.chargingPlans #sessionPay').attr({'style':'display:block'});
			$('.chargingPlans #sessionPay .hidden_div_train').trigger("click"); 
		}
	else
		{
			let userFirstName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName;
			$('.main_loader').attr({'style':'display:flex'});
			let postData = {userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, planId: this.state.chosenPlanId, demandPlanId: this.state.chosenDemandPlanId, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel, freeTrial: 1, firstName: userFirstName, channelName: this.state.channelName, manualPayment: this.state.manualPayment};
													
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/addUserPlan", postData)
			.then(res => {  
				$("#succes_pay").attr({'style':'display:block'});
				$('.main_loader').hide();
			}).catch(err =>{ $('.main_loader').hide(); });
		}
}

openChargeNow=()=>{
	$('.chargingPlans #sessionPay').attr({'style':'display:block'});
	$('.chargingPlans #sessionPay .hidden_div').trigger("click");
	this.setState({isLoggedUser: true});
}

selectPlan=(id, name)=>{
	console.log(name);
	if ( this.state.chosenPlanId == id )
		{
			this.setState({chosenPlanId: 0, chosenPlanName: ''});
		}
	else
		{
			if ( name == 'Enterprise' && this.state.contactName == '' )
				{
					window.$('#contactSales').modal('show');
					this.setState({chosenPlanId: id, chosenPlanName: name});
				}
			else
				{
					this.setState({chosenPlanId: id, chosenPlanName: name});
				}
		}
}

choseOnDemandPlan=(id, name)=>{
	
	if ( this.state.chosenDemandPlanId == id )
		{
			this.setState({chosenDemandPlanId: 0, chosenDemandPlanName: '', isOnDemandSelected: false});
		}
	else
		{
			if ( name == 'Enterprise' && this.state.contactName == '' )
				{
					window.$('#contactSales').modal('show');
					this.setState({chosenDemandPlanId: id, chosenDemandPlanName: name, isOnDemandSelected: true});
				}
			else
				{
					this.setState({chosenDemandPlanId: id, chosenDemandPlanName: name, isOnDemandSelected: true});
				}
		}
}

openCalculatePop=()=>{
	var isChosenSame = false;
	
	if ( this.state.currentPlanMain && this.state.currentPlanMain.id && this.state.currentPlanMain.isTrial === 0 && this.state.currentPlanMain.planPaid > 0 )
		{
			if ( this.state.currentPlanMain.planName ===  this.state.chosenPlanName &&  this.state.currentPlanMain.onDemandPlan === this.state.chosenDemandPlanName )
				{
					isChosenSame = true;
				}
		}
	
	if ( isChosenSame ) { this.props.showMainErrorPop('You selected your current plans.'); return false; }
	
	let mainthis = this;
	
	let totalAmmountVar = 0;
	let totalAmmountLive = 0;
	let totalAmmountDemand = 0;
	
	var livePlanPrice = 0;
	var demandPlanPrice = 0;

	$.each(this.state.mainPlans, function( a, b ) {
		if ( b.id === mainthis.state.chosenPlanId ) { totalAmmountVar = totalAmmountVar + b.planPrice; totalAmmountLive = totalAmmountLive + b.planPrice; livePlanPrice = b.planPrice;}
	});


	$.each(this.state.mainPlans, function( a, b ) {
		if ( b.id === mainthis.state.chosenDemandPlanId ) { totalAmmountVar = totalAmmountVar + b.planPrice; totalAmmountDemand = totalAmmountDemand + b.planPrice; demandPlanPrice = b.planPrice; }
	});
	
	totalAmmountVar = Number.parseFloat(totalAmmountVar).toFixed(2);
	totalAmmountLive = Number.parseFloat(totalAmmountLive).toFixed(2);
	totalAmmountDemand = Number.parseFloat(totalAmmountDemand).toFixed(2);
	
	let fullMonth = new Date(new Date().getFullYear(), new Date().getMonth()+1, 0).getDate();
	let today = new Date().getDate();
	today = today - 1;
	
	let monthRest = parseFloat(totalAmmountVar) * ( today / fullMonth );
	monthRest = Number.parseFloat(monthRest).toFixed(2);
	
	let monthRestLive = parseFloat(livePlanPrice) * ( today / fullMonth );
	monthRestLive = Number.parseFloat(monthRestLive).toFixed(2);
	
	let monthRestDemand = parseFloat(demandPlanPrice) * ( today / fullMonth );
	monthRestDemand = Number.parseFloat(monthRestDemand).toFixed(2);
	
	let discountMinus = 0;
	let discountMinusLive = 0;
	let discountMinusDemand = 0;
	
	totalAmmountVar = totalAmmountVar - monthRest;
	totalAmmountLive = totalAmmountLive - monthRestLive;
	totalAmmountDemand = totalAmmountDemand - monthRestDemand;
	
	if ( this.state.promoDiscount > 0 )
		{
			discountMinus = parseFloat(totalAmmountVar) - (this.state.promoDiscount*parseFloat(totalAmmountVar));
			discountMinus = Number.parseFloat(discountMinus).toFixed(2);
			
			discountMinusLive = parseFloat(totalAmmountLive) - (this.state.promoDiscount*parseFloat(totalAmmountLive));
			discountMinusLive = Number.parseFloat(discountMinusLive).toFixed(2);
			
			discountMinusDemand = parseFloat(totalAmmountDemand) - (this.state.promoDiscount*parseFloat(totalAmmountDemand));
			discountMinusDemand = Number.parseFloat(discountMinusDemand).toFixed(2);
		}
	
	
	let totalFinal = parseFloat(totalAmmountVar) - parseFloat(discountMinus);
	
	let totalFinalLive = parseFloat(totalAmmountLive) - parseFloat(discountMinusLive);
	totalFinalLive = totalFinalLive.toFixed(2);
	
	let totalFinalDemand = parseFloat(totalAmmountDemand) - parseFloat(discountMinusDemand);
	totalFinalDemand = totalFinalDemand.toFixed(2);
	
	var priceWithoutCredit = 0;
	
	if ( this.state.currentPlanMain && this.state.currentPlanMain.id && this.state.currentPlanMain.planPaid === 1 )
		{
			var planStartDay = new Date(this.state.currentPlanMain.planActivated).getDate();
			
			var planSpentDays = (today - planStartDay) + 1;
			
			var planRealDuration = (fullMonth - planStartDay) + 1; //how many days user is using Current plan
		
			var couponPrice = this.state.currentPlanMain.subPercent ? ( 100 - this.state.currentPlanMain.subPercent) / 100 : 1;
			
			var spentMoneyLive = this.state.currentPlanMain.planPrice - (this.state.currentPlanMain.planPrice / planRealDuration) * planSpentDays;
			var spentMoneyDemand = this.state.currentPlanMain.onDemandPrice - (this.state.currentPlanMain.onDemandPrice / planRealDuration) * planSpentDays;
			 
			var previousPlansDis = parseFloat(spentMoneyLive + spentMoneyDemand + this.state.currentPlanMain.planPriceOvercharge).toFixed(2);
			
			if( previousPlansDis == 'NaN' ){ previousPlansDis = 0; previousPlansDis = previousPlansDis.toFixed(2) }
			priceWithoutCredit = parseFloat(spentMoneyLive + spentMoneyDemand).toFixed(2);
			
			totalFinal = totalFinal - previousPlansDis;
			
			this.setState({
					previousPlansDiscount: previousPlansDis, 
					discountPreviousLive: parseFloat(spentMoneyLive).toFixed(2),
					discountPreviousDemand: parseFloat(spentMoneyDemand).toFixed(2)
			});
		}

	totalAmmountLive = Number.parseFloat(totalAmmountLive).toFixed(2);
	totalAmmountDemand = Number.parseFloat(totalAmmountDemand).toFixed(2);
	
	var totalCredit = 0;
	if ( totalFinal < 0 )  { totalCredit = (totalFinal * -1).toFixed(2); totalFinal = 0; }
	
	if( Number.isNaN(totalFinal) ){ totalFinal = 0 }
	if( totalCredit == 'NaN' ){ totalCredit = 0; totalCredit = totalCredit.toFixed(2) }
	if( priceWithoutCredit == 'NaN' ){ priceWithoutCredit = 0; priceWithoutCredit = priceWithoutCredit.toFixed(2) }
	totalFinal = totalFinal.toFixed(2);
	
	this.setState({totalAmmount: totalAmmountVar, totalDiscount: discountMinus, proRatedMonth: monthRest, totalAmmountFinal: totalFinal, creditAmmount: totalCredit, priceWithoutCredit: priceWithoutCredit, totalLiveAmount: totalAmmountLive, totalDemandAmount: totalAmmountDemand}, ()=>{});
	
	$("#payment_ammount").attr({'style':'display:block'});
}

closeAmmount=()=>{
	$("#payment_ammount").attr({'style':'display:none'});
}

openBuyScreen=()=>{
	$("#payment_ammount").attr({'style':'display:none'});
	this.setState({isFreeTrial: false});
	
	if ( !this.state.isLoggedUser ) 
		{ 
			$('.chargingPlans #sessionPay').attr({'style':'display:block'});
			$('.chargingPlans #sessionPay .hidden_div_train').trigger("click"); 
		}
	else 
		{ 
			
			if( this.state.manualPayment == 0 && this.state.noSendEmail == 0 && this.state.ambasadorStatus == 0 )
				{
					if ( this.state.cardsArray.length === 0 )
						{
							$('.main_loader').attr({'style':'display:flex'});
							let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
							axios.defaults.headers.common['Authorization'] = token;
							axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserCard",{"id": JSON.parse(localStorage.getItem('userData')).data.responseData.id})
							.then(res_e => {
								if ( res_e.data.responseData['default'] && res_e.data.responseData['default']['expires']  )
									{
										this.setState({ cardsArray: res_e.data.responseData['all'] })
									}
								
								$('.chargingPlans #sessionPay').attr({'style':'display:block'});
								$('.chargingPlans #sessionPay .hidden_div').trigger("click");
								$('.main_loader').hide();
								
							}).catch(err =>{ $('.main_loader').hide(); this.props.showMainErrorPop('Something went wrong. Please clear Your Virdio Local storage and try again.'); });
						}
					else
						{
							$('.chargingPlans #sessionPay').attr({'style':'display:block'});
							$('.chargingPlans #sessionPay .hidden_div').trigger("click");
						}
				}				
			else
				{
					let postData;
					$('.main_loader').attr({'style':'display:flex'});					
					if ( !this.state.isFreeTrial && this.state.noSendEmail == 0 && this.state.ambasadorStatus == 0 )
						{
							postData = 
										{
											userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, 
											planId: this.state.chosenPlanId, 
											demandPlanId: this.state.chosenDemandPlanId, 
											channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
											couponCode: this.state.promoCode,
											manualPayment: this.state.manualPayment,
											manualStatus: 1,
										};
										
						}
					else if ( !this.state.isFreeTrial && this.state.noSendEmail == 1  && this.state.ambasadorStatus == 0 )
						{
							postData = 
										{
											userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, 
											planId: this.state.chosenPlanId, 
											demandPlanId: this.state.chosenDemandPlanId, 
											channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
											couponCode: this.state.promoCode,
											manualPayment: this.state.manualPayment,
										};
						}
					else if ( !this.state.isFreeTrial && this.state.noSendEmail == 0  && this.state.ambasadorStatus == 1 )
						{
							postData = 
										{
											userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, 
											planId: this.state.chosenPlanId, 
											demandPlanId: this.state.chosenDemandPlanId, 
											channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
											couponCode: this.state.promoCode,
											skipManual: 1,
										};
						}						
					else
						{
							postData = 
										{
											userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, 
											planId: this.state.chosenPlanId, 
											demandPlanId: this.state.chosenDemandPlanId, 
											channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
											freeTrial: 1,
											couponCode: this.state.promoCode,
											manualPayment: this.state.manualPayment,
											manualStatus: 1,
										};
										
						}
					
					axios.post(process.env.REACT_APP_NAME+"/api/v1/user/addUserPlan", postData)
					.then(res => {
						
						if ( res.data.responseData.toString().indexOf('not able') > 0 )
							{
								this.props.showMainErrorPop(res.data.responseData);
							}
						else
							{
								if ( !this.state.isLoggedUser ) { browserHistory.push("/login"); }
								else { $("#succes_pay").attr({'style':'display:block'}); }
}
						$('.main_loader').hide();
						
					}).catch(err =>{
						console.log(err);
						$('.main_loader').hide();
						this.props.showMainErrorPop("Error processing this transaction.");
					});
				}
				
		}
}

contactSales=()=>{
	window.$zopim.livechat.window.show();
}

componentDidMount() {
	if ( window.location.hash !== '' && window.location.hash !== '#' )
		{
			this.setState({ promocodefld: window.location.hash.substr(1, 50) }, () => {this.checkCodeApply();});
			//$('#promocodefld').val(window.location.hash.substr(1, 50));
		}
	else
		{
			this.setState({ promocodefld: '' });
			///$('#promocodefld').val('');
		}
	

	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getPlans")          
	.then(res => {
		this.setState({mainPlans: res.data.responseData}, () => {
			if ( localStorage.getItem('userData') ) 
				{ 
					let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
					let userFirstName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName;
					let userLastName = JSON.parse(localStorage.getItem('userData')).data.responseData.lastName;
					let userFullName = userFirstName + ' ' + userLastName;
					this.setState({userFirstName: userFirstName, fullName: userFullName});
					axios.defaults.headers.common['Authorization'] = token;
					
					axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getCurrentPlanFull", {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel})          
					.then(res => {
						
						var isPlanExpired = 0; 
						
						if ( res.data.channelPlan.trialDuration > 0 )
							{
								var trialStartedDay = new Date(res.data.channelPlan.trialStarted);
								trialStartedDay.setHours(0);
								trialStartedDay.setMinutes(0);
								trialStartedDay.setSeconds(0);
								trialStartedDay = trialStartedDay.getTime();
								var trialExpiredDays = res.data.channelPlan.trialDuration * 86400000;
								var trialEndDay = trialStartedDay + trialExpiredDays;
								var currentDate = new Date().getTime();
								if ( currentDate > trialEndDay ) { isPlanExpired = 1; }
							}
						if ( res.data.channelPlan.ambassadorInternalReports )
							{
								this.setState({
									ambasadorStatus: res.data.channelPlan.ambassadorInternalReports,
								})
							}
							
						if ( res.data.channelPlan.trialDuration == 0 && res.data.channelPlan.planPaid === 1 )
							{
								var currentMonth = new Date().getMonth();
								var planMonth = new Date(res.data.channelPlan.planActivated).getMonth();
								if ( currentMonth > planMonth ) { isPlanExpired = 1; }
							}
							
						if ( isPlanExpired == 0 )
							{
								var mainthis = this;
								if ( res.data.channelPlan.id > 0 )
									{
										$.each(this.state.mainPlans, function( a, b ) {
											if ( b.planType.trim() === 'live' && b.planName.trim() === res.data.channelPlan.planName.trim() )
												{
													mainthis.selectPlan(b.id, b.planName);
												}
												
											if ( b.planType.trim() === 'ondemand' && b.planName.trim() === res.data.channelPlan.onDemandPlan.trim() )
												{
													mainthis.choseOnDemandPlan(b.id, b.planName);
												}
										});
										
										this.setState({currentLivePlan: res.data.channelPlan.planName, currentDemandPlan: res.data.channelPlan.onDemandPlan, currentPlanMain: res.data.channelPlan});
									
										if ( res.data.channelPlan.couponId > 0 && res.data.channelPlan.couponCode !== '' )
											{
												this.setState({promocodefld: res.data.channelPlan.couponCode});
												this.checkCodeApply();
											}
									}
							}
						
						if ( res.data.channelPlan.id > 0 )
							{
								this.setState({showFreeTrial: false});
							}
						else
							{
								axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/checkAnyPlan", {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel}).then(res => {
									if ( res.data.responseData == 1 ) { this.setState({showFreeTrial: false}); } 
								}).catch(err =>{ });
							}
						
					}).catch(err =>{ });
					
					this.setState({isLoggedUser: true, isLoggedUser2: true}); 
					localStorage.setItem('emailSign', JSON.parse(localStorage.getItem('userData')).data.responseData.email); 
					localStorage.setItem('email', JSON.parse(localStorage.getItem('userData')).data.responseData.email); 
				}
		});
	})
	.catch(err =>{ });
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getPlanRows")          
	.then(res => {
		this.setState({planRows: res.data.responseData});
	})
	.catch(err =>{ });
		if(localStorage.getItem('userData'))
			{
				axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelById",{"channelId": JSON.parse(localStorage.getItem('userData')).data.responseData.channel})
				.then(res => {
					this.setState({channelImage: res.data.responseData.image, channelName: res.data.responseData.name});
				}).catch(err =>{ });
				
				axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/insertChannelSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel})
				.then(res => {
					this.setState({
						manualPayment: res.data.responseData.manualPayment,
						noSendEmail: res.data.responseData.noSendEmail,
						}, ()=>{console.log(this.state.manualPayment, 'MANUAL')});
				}).catch(err =>{ });
			}
		
	localStorage.removeItem("chosenCouponId");
		
}

openInfoPopUp = (e) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
}

closeInfoPopUp=(e)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	
};

stopClick = (e) => {
	e.stopPropagation();
	e.preventDefault();
}

openPlus = (iid) => {
	if ( $('.parentplan_'+iid).hasClass('active') )
	{
		$('.parentplan_exists').removeClass('active');
	}
	else
	{
		$('.parentplan_exists').removeClass('active');
		$('.parentplan_'+iid).addClass('active');
	}
	
}

checkCodeApply = (e) => {
	//var codep = $('#promocodefld').val().trim();
	var codep = this.state.promocodefld;
	
	if ( codep )
		{
			this.setState({promoCode: '', promoText: '', promoDiscount: 1});
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getPromo", {codep: codep})          
				.then(res => {
					var code = res.data.responseData;
					
					localStorage.setItem('chosenCouponId', code.id);
					
					if ( !code )
						{
							this.setState({promoText: 'Code Not found.'});
						}
					else
						{
							this.setState({promoCode: code, promoText: code.subText, promoDiscount: ((100-code.subPercent)/100)});
						}
					
					$('.main_loader').hide();
				})
				.catch(err =>{ $('.main_loader').hide(); });			
		}
}

doPickPlan = (e) => {

	this.props.showMainErrorPop('Please select your Live Streaming Plan before you continue.');

}

validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

sendContact = (e) => {
	if ( this.state.contactName === '' || this.state.contactCompanyName === '' || this.state.contactEmail === '' || !this.validateEmail(this.state.contactEmail) || this.state.contactMessage === '' )
		{
			this.props.showMainErrorPop('Please fill all your Contact Information correctly.');
			return false;
		}
	
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/sendPlanMessage", {contact: this.state})          
		.then(res => {
			
			$('.main_loader').hide();
			window.$('#contactSales').modal('hide');
			
			this.props.showMainErrorPop('Your Contact Information has been sent. We will be contacting you shortly.');
		}).catch(err =>{ $('.main_loader').hide(); });
}

sendEmailAndClose = () => {
	let currentNumber = localStorage.getItem("cardNumber");
	let currentType = localStorage.getItem("cardtype");
	let confNumber = localStorage.getItem('confirmation_number');
	let fullCardInfo = currentType+' ***'+currentNumber
	var objectForEmail = {
							'email': localStorage.getItem('emailSign'),
							'currentMonthYear': this.state.currentMonthYear,
							'totalAmmount': this.state.totalAmmount,
							'subPercent': this.state.promoCode.subPercent ? this.state.promoCode.subPercent : 0,
							'totalDiscount': this.state.totalDiscount,
							'todayDay': this.today,
							'proRatedMonth': this.state.proRatedMonth,
							'previousPlansDiscount': this.state.previousPlansDiscount,
							'creditAmmount': this.state.creditAmmount,
							'priceWithoutCredit': this.state.priceWithoutCredit,
							'chargesDays': this.fullMonth-this.today,
							'totalAmmountFinal' : this.state.totalAmmountFinal,
							'totalAmmountLive' : this.state.totalLiveAmount,
							'totalAmmountDemand': this.state.totalDemandAmount,
							'firstName' : this.state.userFirstName,
							'currentPlan' : this.state.chosenPlanName,
							'onDemandPlan' : this.state.chosenDemandPlanName,
							'fullName' : this.state.fullName,
							'paymentDate' : this.state.currentDate,
							'channelName' : this.state.channelName,
							'cardInfo' : fullCardInfo,
							'confNumber' : confNumber
						};
						
	if(this.state.manualPayment == 0)
		{
						
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/sendPlanSummary", {objectEmail: objectForEmail})          
	.then(res => {
		$('.main_loader').hide();
		if ( this.state.isLoggedUser2 ) { browserHistory.push("/DashboardLanding"); }
		else { browserHistory.push("/login"); }
	}).catch(err =>{ $('.main_loader').hide(); });
}
		
	else
		{
			if ( this.state.isLoggedUser2 ) { browserHistory.push("/DashboardLanding"); }
			else { browserHistory.push("/login"); }
		}
}

	render() {
		this.kk = 0;
		this.pi = 0;
		return (
	
		  <div className="padding_traing myfaqhere">
				<div className="all_training_pg">
					
					<Link to={!this.state.isLoggedUser2 ? '/' : '/dashboardLanding'}><div className="closePopup settings_close_popup channel_settings_close"></div></Link>
					<div className="scroll_part_train scroll_part_traintop">
						<div className="training_title inline_title_train">Choose the Right Live Streaming Plan For You</div>
						<div className="inline_title_btns">
							{ this.state.chosenPlanId === 0 &&
								<div className="for_train_btn">
									{ this.state.showFreeTrial &&
										<button type="button" className="custom_btn1 gray_clr traing_btns" onClick={this.doPickPlan}>START FREE TRIAL</button>
									}
									<button type="button" className="custom_btn1 gray_clr ml20 traing_btns" onClick={this.doPickPlan}>BUY NOW</button>
								</div>
							}
							
							{ this.state.chosenPlanId > 0 &&
								<div className="for_train_btn">
									{ this.state.showFreeTrial && <button type="button" className="custom_btn1 main_rose_bc traing_btns" onClick={this.startFreeTrial}>START FREE TRIAL</button> }
									{ this.state.chosenPlanName !== 'Enterprise' && <button type="button" className="custom_btn1 main_rose_bc ml20 traing_btns" onClick={this.openCalculatePop}>BUY NOW</button> }
								</div>
							}
						</div>
						
						
						<div className="all_training_main">
							<form autoComplete="off">
							<div className="all_width_part">
								<div className="widder_train coupDiv">
								
									<div className="coupSubTitle pb5">Promotional Code:
										<input type="text" className="input-field" onChange={ (e) => this.setState({ promocodefld: e.target.value }) }  name="planPromoCode" autoComplete="none" value={this.state.promocodefld ? this.state.promocodefld : ""} />
										<button type="button" className="custom_btn1 main_rose_bc" onClick={ (e) => this.checkCodeApply(e)}>APPLY</button>
										
										<div className="coupText">{(this.state.promoText ? '* '+this.state.promoText : '')}</div>
										
									</div>
								
								</div>
								{this.state.mainPlans.map((item, i)=> ( item.planType == 'live' &&
									<div key={i} className={"smaller_train " + (this.state.chosenPlanId === item.id ? 'active' : '')}>
										<div className="header_smaller">{item.planName}</div>
										<div className={"all_bottom_info " + (item.planName === 'Enterprise' ? 'enter_padd' : '')}>
											{ item.planName === 'Pro' &&
												<div>
													<div className={'pay_i '+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')}>${item.planPrice}/Month</div>
													{ this.state.promoDiscount !== 1 && <div className="pay_i">${(item.planPrice*this.state.promoDiscount).toFixed(2)}/Month*</div> }
													<div className="for_rel_i">
														<div className="rel_info">Included {item.includedParticipants} Members/Month</div>
													</div>
													<div className={"start_at "+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')}>${(item.additionalParticipants ? item.additionalParticipants.toFixed(2) : '0.00' )}/additional Member</div>
													{ this.state.promoDiscount !== 1 && <div className={"start_at coupOver"}>${(item.additionalParticipants ? (item.additionalParticipants*this.state.promoDiscount).toFixed(2) : '0.00' )}/additional Member</div> }
													{ this.state.currentLivePlan !== 'Pro' &&
														<div className="bottom_btn buy_txt" onClick={() => this.selectPlan(item.id, item.planName)}>Select</div>
													}
													{ this.state.currentLivePlan === 'Pro' &&
														<div className="bottom_btn buy_txt" onClick={() => this.selectPlan(item.id, item.planName)}>Current Plan</div>
													}
												</div>
											}
											{ item.planName === 'Group' &&
												<div>
													<div className={'pay_i '+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')}>${item.planPrice}/Month</div>
													{ this.state.promoDiscount !== 1 && <div className="pay_i">${(item.planPrice*this.state.promoDiscount).toFixed(2)}/Month*</div> }
													<div className="for_rel_i">
														<div className="rel_info">Included {item.includedParticipants} Members/Month</div>
													</div>
													<div className={"start_at "+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')}>${(item.additionalParticipants ? item.additionalParticipants.toFixed(2) : '0.00' )}/additional Member</div>
													{ this.state.promoDiscount !== 1 && <div className={"start_at coupOver"}>${(item.additionalParticipants ? (item.additionalParticipants*this.state.promoDiscount).toFixed(2) : '0.00' )}/additional Member</div> }
													{ this.state.currentLivePlan !== 'Group' &&
														<div className="bottom_btn buy_txt" onClick={() => this.selectPlan(item.id, item.planName)}>Select</div>
													}
													{ this.state.currentLivePlan === 'Group' &&
														<div className="bottom_btn buy_txt" onClick={() => this.selectPlan(item.id, item.planName)}>Current Plan</div>
													}
												</div>
											}
											{ item.planName === 'Enterprise' &&
												<div>
													<div className="start_at">For {item.includedParticipants} + Member per Month</div>
													
													<div className={"start_at "+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')}>Starting at ${this.state.enterPriceOverage} /Member​</div>
													{ this.state.promoDiscount !== 1 && <div className={"start_at coupOver"}> Starting at ${(this.state.enterPriceOverage*this.state.promoDiscount).toFixed(2)} /Member </div>}
													{ this.state.currentLivePlan !== 'Enterprise' &&
														<div className="bottom_btn buy_txt" onClick={() => this.selectPlan(item.id, item.planName)}>Contact Sales</div>
													}
													{ this.state.currentLivePlan === 'Enterprise' &&
														<div className="bottom_btn buy_txt" onClick={() => this.selectPlan(item.id, item.planName)}>Current Plan</div>
													}
												</div>
											}
										</div>
									</div>
								) )}
							</div>
							</form>
							<table className="all_width_part">
								<tbody className="all_width_part">
									{this.state.planRows.map((item, i)=> ( item.rowType == 'live' &&
										<tr key={i} className="all_width_part">
											<th className={"widder_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : ' '+( this.kk++ ))}>
												<div className="column_title">{item.rowName}</div>
												{ item.haveInfo === 1 &&
													<div className="abs_circle_info mar_column" onClick={ (e) => this.openInfoPopUp(e) }>
														<div className="all_info pink_info1 all_info_daily all_session_info training_info" onClick={ (e) => this.stopClick(e) }>
															<div className="close_all_info">
																<div className="close_all_info_btn" onClick={ (e) => this.closeInfoPopUp(e)}>x</div>
															</div>
															{item.infotext}
														</div>
													</div>
												}
												
												{ item.havePlus === 1 &&
													<div className="app_plus"  onClick={ (e) => this.openPlus(item.id) }></div>
												}
											</th>
											<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenPlanName === 'Pro' ? ' selected_train' : '') + (item.id === 27 ? ' lst_smaller' : '')}>
												{ item.proPlan === 'checked' &&
													<div className="check_mark"></div>
												}
												{ item.proPlan === 'not_checked' &&
													<div className="check_mark iks_mark"></div>
												}
												{ item.proPlan !== 'checked' && item.proPlan !== 'not_checked' && item.proPlan !== '' &&
													<div>
														{ item.proPlan !== '99' &&
															<div>
																{ item.id === 3 &&
																	<div className="column_text">{item.proPlan} Instructors</div>
																}
																{ item.id === 5 &&
																	<div className="column_text">{item.proPlan}</div>
																}
																{ item.id === 20 &&
																	<div className="column_text">Includes Cloud Storage for {item.proPlan} Classes</div>
																}
																{ item.id === 22 &&
																	<div className="column_text">Create {item.proPlan} playlist per Instructor</div>
																}
															</div>
														}
														{ item.proPlan == '99' &&
															<div className="column_text">Call to discuss</div>
														}
													</div>
												}
												{ item.proPlan == '' &&
													<div className="empty_column"></div>
												}
											</th>
											<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenPlanName === 'Group' ? ' selected_train' : '') + (item.id === 27 ? ' lst_smaller' : '')}>
												{ item.groupPlan === 'checked' &&
													<div className="check_mark"></div>
												}
												{ item.groupPlan === 'not_checked' &&
													<div className="check_mark iks_mark"></div>
												}
												{ item.groupPlan !== 'checked' && item.groupPlan !== 'not_checked' && item.groupPlan !== '' &&
													<div>
														{ item.groupPlan !== '99' &&
															<div>
																{ item.id === 3 &&
																	<div className="column_text">{item.groupPlan} Instructors</div>
																}
																{ item.id === 5 &&
																	<div className="column_text">{item.groupPlan}</div>
																}
																{ item.id === 20 &&
																	<div className="column_text">Includes Cloud Storage for {item.groupPlan} Classes</div>
																}
																{ item.id === 22 &&
																	<div className="column_text">Create {item.groupPlan} playlist per Instructor</div>
																}
															</div>
														}
														{ item.groupPlan == '99' &&
															<div className="column_text">Call to discuss</div>
														}
													</div>
												}
												{ item.groupPlan == '' &&
													<div className="empty_column"></div>
												}
											</th>
											<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenPlanName === 'Enterprise' ? ' selected_train' : '') + (item.id === 27 ? ' lst_smaller' : '')}>
												{ item.enterprisePlan === 'checked' &&
													<div className="check_mark"></div>
												}
												{ item.enterprisePlan === 'not_checked' &&
													<div className="check_mark iks_mark"></div>
												}
												{ item.enterprisePlan !== 'checked' && item.enterprisePlan !== 'not_checked' && item.enterprisePlan !== '' &&
													<div>
														{ item.enterprisePlan !== '99' &&
															<div>
																{ item.id === 3 &&
																	<div className="column_text">{item.enterprisePlan} Instructors</div>
																}
																{ item.id === 5 &&
																	<div className="column_text">{item.enterprisePlan}</div>
																}
																{ item.id === 20 &&
																	<div className="column_text">Includes Cloud Storage for {item.enterprisePlan} Classes</div>
																}
																{ item.id === 22 &&
																	<div className="column_text">Create {item.enterprisePlan} playlist per Instructor</div>
																}
															</div>
														}
														{ item.enterprisePlan == '99' &&
															<div className="column_text">Contact to Discuss</div>
														}
													</div>
												}
												{ item.enterprisePlan == '' &&
													<div className="empty_column"></div>
												}
											</th> 
										</tr>
									) )}
								</tbody>
							</table>
						</div>
					</div>
					
					<div className="training_title full_title">Choose the Right On-Demand Plan For You ( Optional )​</div>
					
					<div className="scroll_part_train ondemandplanss">
						<div className="all_training_main">
							<div className="all_width_part">
								<div className="widder_train"></div>
								{this.state.mainPlans.map((item, i)=> ( item.planType == 'ondemand' &&
									<div key={i} className={"smaller_train " + (this.state.chosenDemandPlanId === item.id ? 'active' : '')}>
										<div className="header_smaller">{item.planName}</div>
										<div className={"all_bottom_info " + (item.planName === 'Enterprise' ? 'enter_padd' : '')}>
											{ item.planName === 'Pro' &&
												<div>
													<div className={'pay_i '+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')}>${item.planPrice}/Month</div>
													{ this.state.promoDiscount !== 1 && <div className="pay_i">${(item.planPrice*this.state.promoDiscount).toFixed(2)}/Month*</div> }
													<div className={"start_at "+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')}>${(item.additionalClient ? item.additionalClient.toFixed(2) : '0.00' )}/additional Client</div>
													{ this.state.promoDiscount !== 1 && <div className={"start_at coupOver"}>${(item.additionalClient ? (item.additionalClient*this.state.promoDiscount).toFixed(2) : '0.00' )}/additional Client</div>}
													{ this.state.currentDemandPlan !== 'Pro' &&
														<div className="bottom_btn buy_txt" onClick={() => this.choseOnDemandPlan(item.id, item.planName)}>Select</div>
													}
													{ this.state.currentDemandPlan === 'Pro' &&
														<div className="bottom_btn buy_txt" onClick={() => this.choseOnDemandPlan(item.id, item.planName)}>Current Plan</div>
													}
												</div>
											}
											{ item.planName === 'Group' &&
												<div>
													<div className={'pay_i '+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')}>${item.planPrice}/Month</div>
													{ this.state.promoDiscount !== 1 && <div className="pay_i">${(item.planPrice*this.state.promoDiscount).toFixed(2)}/Month*</div> }
													<div className={"start_at "+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')}>${(item.additionalClient ? item.additionalClient.toFixed(2) : '0.00' )}/additional Client</div>
													{ this.state.promoDiscount !== 1 && <div className={"start_at coupOver"}>${(item.additionalClient ? (item.additionalClient*this.state.promoDiscount).toFixed(2) : '0.00' )}/additional Client</div>}
													{ this.state.currentDemandPlan !== 'Group' &&
														<div className="bottom_btn buy_txt" onClick={() => this.choseOnDemandPlan(item.id, item.planName)}>Select</div>
													}
													{ this.state.currentDemandPlan === 'Group' &&
														<div className="bottom_btn buy_txt" onClick={() => this.choseOnDemandPlan(item.id, item.planName)}>Current Plan</div>
													}
												</div>
											}
											{ item.planName === 'Enterprise' &&
												<div>
													<div>Contact to Discuss</div>
													{ this.state.currentDemandPlan !== 'Enterprise' &&
														<div className="bottom_btn buy_txt" onClick={() => this.choseOnDemandPlan(item.id, item.planName)}>Contact Sales</div>
													}
													{ this.state.currentDemandPlan === 'Enterprise' &&
														<div className="bottom_btn buy_txt" onClick={() => this.choseOnDemandPlan(item.id, item.planName)}>Current Plan</div>
													}
												</div>
											}
										</div>
									</div>
								) )}
							</div>
						
							<table className="all_width_part mb60">
								<tbody className="all_width_part">
									{this.state.planRows.map((item, i)=> ( item.rowType == 'ondemand' &&
										<tr key={i} className="all_width_part">
											<th className={"widder_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : ' '+( this.kk++ ))}>
												<div className="column_title">{item.rowName}</div>
												{ item.haveInfo === 1 &&
													<div className="abs_circle_info mar_column" onClick={ (e) => this.openInfoPopUp(e) }>
														<div className="all_info pink_info1 all_info_daily all_session_info training_info" onClick={ (e) => this.stopClick(e) }>
															<div className="close_all_info">
																<div className="close_all_info_btn" onClick={ (e) => this.closeInfoPopUp(e)}>x</div>
															</div>
															{item.infotext}
														</div>
													</div>
												}
												
												{ item.havePlus === 1 &&
													<div className="app_plus"  onClick={ (e) => this.openPlus(item.id) }></div>
												}
											</th>
											<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenDemandPlanName === 'Pro' ? ' selected_train' : '') + (item.id === 30 ? ' lst_smaller' : '')}>
												<div>
													{item.rowName == 'Per additional Recording' ?
														<div className={'column_text '+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')} >{ item.id == 30 ? '$' : '' }{item.proPlan}</div>
														:
														<div className="column_text">{ item.id == 30 ? '$' : '' }{item.proPlan}</div>
													}
													{ this.state.promoDiscount !== 1 && item.rowName == 'Per additional Recording' && <div className="column_text">{ item.id == 30 ? '$' : '' }{(item.proPlan*this.state.promoDiscount).toFixed(2)}</div> }
												</div>
											</th>
											<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenDemandPlanName === 'Group' ? ' selected_train' : '') + (item.id === 30 ? ' lst_smaller' : '')}>
												<div>
													{item.rowName == 'Per additional Recording' ?
														<div className={'column_text '+(this.state.promoDiscount !== 1 ? ' crossedClass' : '')} >{ item.id == 30 ? '$' : '' }{item.groupPlan}</div>
														:
														<div className="column_text">{ item.id == 30 ? '$' : '' }{item.groupPlan}</div>
													}
													{ this.state.promoDiscount !== 1 && item.rowName == 'Per additional Recording' && <div className="column_text">{ item.id == 30 ? '$' : '' }{(item.groupPlan*this.state.promoDiscount).toFixed(2)}</div> }
												</div>
											</th>
											<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenDemandPlanName === 'Enterprise' ? ' selected_train' : '') + (item.id === 30 ? ' lst_smaller' : '')}>
												<div className="column_text">{item.enterprisePlan}&nbsp;</div>
											</th> 
										</tr>
									) )}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				
				
				<div className={"chargingPlans " + (this.state.isLoggedUser ? 'chargingPlansLogged' : '')}>
					<div className="modal over_payment_modal" id="sessionPay" aria-modal="true">
						<div className="for_main_form centered_pop">
							<ChargingSession state={this.state} cardsArray={this.state.cardsArray} openChargeNow={this.openChargeNow}></ChargingSession>
						</div>
					</div>
				</div>
				
				<div className="modal pr-0" id="succes_pay" aria-modal="true"> 
					<div className="modal-dialog mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent inline_pop">
							<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700 text-left payment_new_popup">
								<div className="payment_confirmation summary_popup_background">
									Payment Confirmation
								</div>
								<div className="mb-0 py-4 text-left font-20">
									{ !this.state.isFreeTrial && this.state.manualPayment == 0 &&
										<div className="payment_inner_hold">
											<div className="payment_check_mark"></div>
											<div className="payment_main_text_hold">
												Thank You {this.state.userFirstName}, We have recieved the payment of <strong>${this.state.totalAmmountFinal}</strong>. A Payment Confirmation email has been sent to your registered email address <span className="payinfo_email">{localStorage.getItem('emailSign')}</span>.
												<div>You can also view your payment details in Reports under Billing.</div>
											</div>
										</div>
									}
									{ !this.state.isFreeTrial && this.state.manualPayment == 1 &&
										<div className="payment_inner_hold">
											<div className="payment_check_mark"></div>
											<div className="payment_main_text_hold">
												Thank You {this.state.userFirstName}, We have recieved the payment request of <strong>${this.state.totalAmmountFinal}</strong>. You will be charged manually.
												<div>You can also view your payment details in Reports under Billing.</div>
											</div>
										</div>
									}
									{ this.state.isFreeTrial &&
										<div>
											{ !this.state.isOnDemandSelected &&
												<div className="paymenr_inner_confirm">
													Your Live Streaming plan has been selected.<br></br>You can change it at any time.
												</div>
											}
											{ this.state.isOnDemandSelected &&
												<div className="paymenr_inner_confirm">
													Your Live Streaming and On-demand plans have been selected.<br></br>You can change them at any time.
												</div>
											}
										</div>
									}
								</div>
								<div className="text-center flex-wrap d-flex justify-content-center">
									<div className="">
										{ this.state.isFreeTrial &&
											<Link className="main_link" to={(this.state.isLoggedUser2 ? '/DashboardLanding' : '/login')}><button className="custom_btn1 mt-3 mx-2">Go Back To {this.state.isLoggedUser2 ? 'Dashboard' : 'Login'}</button></Link>
										}
										{ !this.state.isFreeTrial &&
											<button className="custom_btn1 mt-3 mx-2" onClick={this.sendEmailAndClose}>Go Back To Dashboard</button>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal pr-0" id="payment_ammount" aria-modal="true"> 
					<div className="modal-dialog mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent inline_pop">
							<div className="modal-body modl_bg_drk m-2 summary_popup shadow-none relative">
								<div className="closePopup settings_close_popup summary_close" onClick={this.closeAmmount}></div>
								<div className="top_default_hold summary_popup_background">
									<div className="summary_channel_image" style={{ backgroundImage: this.state.channelImage && this.state.channelImage !== 'default.png' ? "url(" + this.state.channelImage + ")" : 'url()' }}></div>
									<div className="payment_titpop">Summary of Purchase</div>
								</div>
								<div className="for_all_amm">
									<div className="one_amm50 one_amm80 txt_left bold">Item</div>
									<div className="one_amm50 one_amm20 txt_right bold">Price</div>
									<div className="summary_popup_background">
										<div className="one_amm50 one_amm80 gray_letters txt_left">Live Monthly Subscription for {this.state.currentMonthYear} <strong>({this.state.chosenPlanName} Plan, {(this.fullMonth-this.today)} days billed)</strong></div>
										<div className="one_amm50 one_amm20 gray_letters txt_right">${this.state.totalLiveAmount}<div className="d-none">{this.pi++}</div></div>
									</div>
									<div>
										{ this.state.isOnDemandSelected && <div className="one_amm50 one_amm80 gray_letters txt_left">On-Demand Plan for {this.state.currentMonthYear} <strong>({this.state.chosenDemandPlanName} Plan, {(this.fullMonth-this.today)} days billed)</strong></div>}
										{ this.state.isOnDemandSelected && <div className="one_amm50 one_amm20 gray_letters txt_right">${this.state.totalDemandAmount}<div className="d-none">{this.pi++}</div></div>}
									</div>
									<div className={this.pi%2 == 0 ? 'summary_popup_background' : ''}>
										{ this.state.promoCode && this.state.totalDiscount > 0 && <div className="one_amm50 one_amm80 gray_letters txt_left">{this.state.promoCode.subPercent}% Discount</div> }
										{ this.state.promoCode && this.state.totalDiscount > 0 && <div className="one_amm50 one_amm20 gray_letters txt_right">-${this.state.totalDiscount}<div className="d-none">{this.pi++}</div></div> }
									</div>
									{ this.state.previousPlansDiscount > 0 &&
										<div className={this.pi%2 == 0 ? 'summary_popup_background' : ''}>
											<div className="one_amm50 one_amm80 gray_letters txt_left">Previous Plan Discount</div>
											<div className="one_amm50 one_amm20 gray_letters txt_right">-${this.state.previousPlansDiscount}<div className="d-none">{this.pi++}</div></div>
										</div>
									}
									{ this.state.priceWithoutCredit > 0 &&
										<div className={this.pi%2 == 0 ? 'summary_popup_background' : ''}>
											{ parseInt(this.state.creditAmmount) > 0 && <div className="one_amm50 one_amm80 gray_letters txt_left">Total Credit (remaining ${this.state.creditAmmount})</div> }
											{ parseInt(this.state.creditAmmount) === 0 && <div className="one_amm50 one_amm80 gray_letters txt_left">Total Credit</div> }
											<div className="one_amm50 one_amm20 gray_letters txt_right">-${this.state.priceWithoutCredit}<div className="d-none">{this.pi++}</div></div>
										</div>
									}
									<div className={this.pi%2 == 0 ? 'summary_popup_background' : ''}>
										<div className="one_amm50 one_amm80 gray_letters txt_left bold">Total Charges for {(this.fullMonth-this.today)} days</div>
										<div className="one_amm50 one_amm20 gray_letters txt_right bold">${this.state.totalAmmountFinal}<div className="d-none">{this.pi++}</div></div>
									</div>
								</div>
								<div className="text-center flex-wrap d-flex justify-content-center">
									<div className="">
										<button className="custom_btn1 mt-3 mx-2 summary_continue" onClick={this.openBuyScreen}>Make Payment</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				
				
				
				<div className="modal pr-0" id="contactSales" aria-modal="true">
					<div className="modal-dialog mw-570 mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
								<button type="button" className="close white closepurple p-0 translatX-Y" data-dismiss="modal"><span>&times;</span></button>
								<div className="title_subd">Enterprise Plan Contact Information</div>
								<div className = "form-group pb-3 mb-0 mt-4">
									<label className="mainLabel">Full Name</label>
									<input type="text" id="contactName" value={this.state.contactName} className="form-control white_clr" onChange={(e)=> this.setState({[e.target.id]:e.target.value})} maxLength="40" />
								</div>
								<div className = "form-group pb-3 mb-0 mt-4">
									<label className="mainLabel">Company Name</label>
									<input type="text" id="contactCompanyName" value={this.state.contactCompanyName} className="form-control white_clr" onChange={(e)=> this.setState({[e.target.id]:e.target.value})} maxLength="40" />
								</div>
								<div className = "form-group pb-3 mb-0 mt-4">
									<label className="mainLabel">Email</label>
									<input type="email" id="contactEmail" value={this.state.contactEmail} className="form-control white_clr" onChange={(e)=> this.setState({[e.target.id]:e.target.value})} maxLength="40" />
								</div>
								<div className = "form-group pb-3 mb-0 mt-4">
									<label className="mainLabel">Message</label>
									<textarea id="contactMessage" className="form-control white_clr" value={this.state.contactMessage} onChange={(e)=> this.setState({[e.target.id]:e.target.value})}></textarea>
								</div>
								
								
							</div>
							<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={this.sendContact}>SEND MESSAGE</button></div>
						</div>
					</div>
				</div>
				
				<div className="main_loader">
					<div className="main_loader_inner"></div>
				</div>
				
				<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		  </div>

		 );
		
		
	}

}


export default connect(
  null,
  { 
	showMainErrorPop
  }
)(trainingPlans);



