import React, { Component } from "react";

class ViewUsers extends Component {
	constructor(props) {
	super(props);

	this.state = {
	
	};
	  
	}
	
	preventDef=(ev)=>{
		ev.preventDefault();
		ev.stopPropagation();
	}

	render() {
		return (
			<div className="views_container" onClick={this.preventDef}>
				{ this.props.usersArray.map((item, i) => 
					<div className={"view_user " + (i == (this.props.usersArray.length - 1) ? 'mb0' : '')} key={i}>{item.firstName} {item.lastName}
						<div className="view_user_avatar" style={{backgroundImage: "url('" + item.image + "')"}}></div>
					</div>
				)}
			</div>
		);
	}

}

export default ViewUsers;

