import React, { Component } from "react";
import classnames from "classnames";

class ReviewMenu extends Component {
  
  constructor(props) {
		super(props);
		
		this.state = {
			
		}

	}

	render() {
			return(
				<div className="menu_content_review">
					<div className={classnames({'one_review_menu': true, 'active': this.props.chosenMenu == 'PENDING EDIT'})} onClick={() => this.props.changeMenu('PENDING EDIT')}>
						<div className="review_menu_title">PENDING EDIT</div>
						<div className="review_menu_number">{this.props.editArrayLength}</div>
					</div>
					<div className={classnames({'one_review_menu': true, 'active': this.props.chosenMenu == 'REVIEW'})} onClick={() => this.props.changeMenu('REVIEW')}>
						<div className="review_menu_title">REVIEW</div>
						<div className="review_menu_number">{this.props.reviewArrayLength}</div>
					</div>
					<div className={classnames({'one_review_menu': true, 'active': this.props.chosenMenu == 'PUBLISH'})} onClick={() => this.props.changeMenu('PUBLISH')}>
						<div className="review_menu_title">PUBLISH</div>
						<div className="review_menu_number">{this.props.publishArrayLength}</div>
					</div>
				</div>
			)
		}
	}

export default ReviewMenu;