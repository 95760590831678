import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import moment1 from 'moment-timezone';
import {  browserHistory } from 'react-router'
import Countdown from 'react-countdown-now';
import Header from './Header';
import ReactLightCalendar from '@lls/react-light-calendar'
import '@lls/react-light-calendar/dist/index.css'
import 'react-phone-number-input/style.css'
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import classnames from "classnames";
import SimpleReactValidator from 'simple-react-validator';
import Calendar from 'react-calendar';
import SupportContainer from '../home/SupportContainer';

class MembersList extends Component {
constructor(props) {
	super(props);
	this.state = {
		myProfileSelect: true,
		channelMembers: [],
		selectedMembers: [],
		selectedMembersAllInfo : [],
		currentUsers: [],
		membersInSession : [],
		membersLeft: -1,
		hasNoId: false,
		emptyStatus: false,
		firstName: '',
		lastName: '',
		email: '',
		searchValue: '',
		errorClass_MemberFirstName:false,
		errorClass_MemberLastName:false,
		errorClass_MemberEmail:false,
		channelCreateUser: false,
		limitedMember: false,
		enddate: '',
		endDateTemp: new Date(),
		channelSubdomain: '',
		userId: 0,
		supportPageName: 'Invite Members',
		supportPageApi: '/inviteMembers',
		userTypeFaqs: 2,
		errorTime: false,
		checkBoxStatus: false,
		canMoreJoin: false
	}
	this.validator = new SimpleReactValidator(); 
}

componentDidMount()
{
	//this.getMembers();
	
	let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	this.setState({
		userId : userId
	});
}

getMembers=(e)=>{
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
    //channdlId=1;
    if(!channelId){
      channelId=localStorage.getItem('channelId');
    }

   axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getMemberChannelWise", {'channelId': localStorage.getItem('channelId'), 'searchText': $('#search_input').val().trim()})          
		.then(res => {
			this.filterInSession();
			this.setState({
				channelMembers:res.data.responseData.memberData,
				channelSubdomain: res.data.responseData.channelShow.subdomain
			});
		})
	
	}
	
checkCreateUser=(e)=>{
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
    //channdlId=1;
    if(!channelId){
      channelId=localStorage.getItem('channelId');
    }
	
   axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getMemberChannelWise", {'channelId': localStorage.getItem('channelId'), 'searchText': $('#search_input').val().trim()})          
		.then(res => {
			this.setState({
				channelCreateUser:res.data.responseData.channelShow.createUser===0?false:true
			});
		})
	}

addToArray =(mod, type)=>
{
	let selected_id = mod['id'];
	let selectedArray = this.state.selectedMembers;
	let selectedAllInfoArray = this.state.selectedMembersAllInfo;
	let currentUsersArray = this.state.currentUsers;
	let membersLeftVar = this.state.membersLeft;
	
	if (selectedArray.indexOf(selected_id) === -1 )
		{
			selectedArray.push(selected_id);
			selectedAllInfoArray.push(mod);
			currentUsersArray.push(mod);
			
			let tempArray = [];
			if ( this.state.channelMembers && this.state.channelMembers.length > 0 )
				{
					for(let i = 0;i<this.state.channelMembers.length; i++)
						{
							if (!this.state.channelMembers[i]['alreadyThere'] && this.state.channelMembers[i]['user_id'] != this.state.userId )
								{
									tempArray.push(this.state.channelMembers[i]['id']);
								}
						}
				}
			
			let isAllIn = true;
			if ( tempArray.length > 0 )
				{
					for( let i=0; i<tempArray.length; i++)
						{
							if (selectedArray.indexOf(tempArray[i]) == -1 )
								{
									isAllIn = false;
								}
						}							
				}
			
			if ( tempArray.length > 0 && isAllIn )
				{
					this.setState({
						checkBoxStatus: true
					});
				}
		}
	else if (selectedArray.indexOf(selected_id) > -1 && type && type === 'all')
		{
			console.log("select all - don't splice");
		}		
	else 
		{
			var indexOf = selectedArray.indexOf(selected_id);
			var indexOff = selectedAllInfoArray.indexOf(mod);
			var indexOff2 = currentUsersArray.indexOf(mod);
			selectedArray.splice(indexOf, 1);
			selectedAllInfoArray.splice(indexOff, 1);
			currentUsersArray.splice(indexOff, 1);
			this.setState({
				checkBoxStatus: false
			});
		}

	var hasNoIdOld = false;
	for ( var usr1 of selectedAllInfoArray )
		{
			if ( usr1['user_id'] == null ) { hasNoIdOld = true; }
		}
	this.setState({
		hasNoId: hasNoIdOld,
		selectedMembers : selectedArray,
		selectedMembersAllInfo : selectedAllInfoArray,
		currentUsers : currentUsersArray
	});
}
removeFromArray = (mod) =>
{
	let selected_id = mod['id'];
	let selectedArray = this.state.selectedMembers;
	let selectedAllInfoArray = this.state.selectedMembersAllInfo;
	let currentUsersArray = this.state.currentUsers;
			
	var indexOf = selectedArray.indexOf(selected_id);
	var indexOff = selectedAllInfoArray.indexOf(mod);
	var indexOff2 = currentUsersArray.indexOf(mod);
	selectedArray.splice(indexOf, 1);
	selectedAllInfoArray.splice(indexOff, 1);
	currentUsersArray.splice(indexOff, 1);
	
	this.setState({
		selectedMembers : selectedArray,
		selectedMembersAllInfo : selectedAllInfoArray,
		currentUsers : currentUsersArray,
		checkBoxStatus: false,
	});
}
selectAllMembers = (e) =>
{
	if ( !this.state.checkBoxStatus )
		{
			let membersLeftVar = this.state.membersLeft; 
			let now_choosen = this.state.currentUsers.length;
			
			let add_num = parseInt(membersLeftVar) - parseInt(now_choosen);

			for(let i = 0;i<add_num; i++)
				{
					if( !this.state.channelMembers[i] )  { return; }
					
					if (!this.state.channelMembers[i]['alreadyThere'] && this.state.channelMembers[i]['user_id'] != this.state.userId )
						{
							this.setState({
								checkBoxStatus: true,
							});
							this.addToArray(this.state.channelMembers[i], 'all');
						}
					if ( this.state.channelMembers[i]['alreadyThere'] )
						{
							add_num = add_num + 1;
						}						
				}
		}
	else
		{
			if ( this.state.currentUsers && this.state.currentUsers.length > 0 )
				{
					let selected_array = [];
					for(let i = 0;i<this.state.currentUsers.length; i++)
						{
							if ( !this.state.currentUsers[i]['alreadyThere'] )
								{
									selected_array.push(this.state.currentUsers[i]);
								}
						}
					
					
					for(let i = 0;i<selected_array.length; i++)
						{
							this.removeFromArray(selected_array[i]);						
						}
				}
		}
}

inviteMember = (mod) =>
{
	let choosenUser = [];
	choosenUser.push(mod);
	$('.main_loader').attr({'style':'display:flex'}); 
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/inviteMember", {'sessionId' : this.props.state.choosenSessionId, 'sessionLink' : this.props.state.choosenSessionLink, 'member' : choosenUser})
	.then(res => {
		if ( res.data.responseData === 'success' )
			{
				this.filterInSession();
				$('.main_loader').hide();	
				this.props.showMainErrorPop('Invitation sent successfully.');
			}
		else 
			{
				$('#session_full').show();
				$('.main_loader').hide();	
			}
		
	});
}	

signUpMember = (mod, type) =>
{
	let selected_id = mod['id'];
	let selectedArray = this.state.selectedMembers;
	let selectedAllInfoArray = this.state.selectedMembersAllInfo;
	let currentUsersArray = this.state.currentUsers;

	var indexOf = selectedArray.indexOf(selected_id);
	var indexOff = selectedAllInfoArray.indexOf(mod);
	var indexOff2 = currentUsersArray.indexOf(mod);

	selectedArray.splice(indexOf, 1);
	selectedAllInfoArray.splice(indexOff, 1);
	currentUsersArray.splice(indexOff, 1);

	this.setState({
		selectedMembers : selectedArray,
		selectedMembersAllInfo : selectedAllInfoArray,
		currentUsers : currentUsersArray
	});
			
	let choosenUser = [];
	choosenUser.push(mod);
	$('.main_loader').attr({'style':'display:flex'}); 
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/insertMember", {'sessionId' : this.props.state.choosenSessionId, 'member' : choosenUser})
	.then(res => {
		
		this.setState({
					membersLeft: this.state.membersLeft-1
				});
		
		if ( res.data.responseData === 'success' )
			{
				this.filterInSession();
				$('.main_loader').hide();	
				this.props.showMainErrorPop('User has been signed up.');
			}
		else 
			{
				$('#session_full').show();
				$('.main_loader').hide();	
			}			
		
	});
}

filterMembers = e =>
{
	let input_val = $('#search_input').val().trim();
	if (input_val.length > 0)
		{
			let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
			axios.post(process.env.REACT_APP_NAME+"/api/v1/session/checkAllMembers", {'channelId' : channelId,'input_val' : input_val })
			.then(res => {
				
				
				if ( this.state.membersLeft == -1 )
					{
						this.setState({
								membersLeft: this.props.state.choosenSessionSpotsLeft,
								selectedMembers: [],
								selectedMembersAllInfo : []
							});
					}
				
				if ( res.data.responseData.length > 0 )
					{
						this.setState({
							channelMembers:res.data.responseData,
							emptyStatus: false
						});	
					}
				else 
					{
						this.setState({
							emptyStatus: true
						});	
					}					
				
				this.filterInSession();
				$('.main_loader').hide();		
			});
		}
	else 
		{
			this.setState({
						channelMembers: [],
						emptyStatus: false
					});
		}		
}

filterInSession = (e, mod) =>
{
	$('.main_loader').attr({'style':'display:flex'}); 
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/checkSessionMembers", {'sessionId' : this.props.state.choosenSessionId})
	.then(res => {
		if ( this.state.currentUsers.length === 0 )
			{
				this.setState({
				membersInSession : res.data.responseData,
				selectedMembers: [],
				selectedMembersAllInfo : []
				});	
			}
		else 
			{
				this.setState({
				membersInSession : res.data.responseData,
				});
			}

		let canMoreJoin = false;
		let mainArray = this.state.channelMembers;
		mainArray.map((item, ind) =>{
			var find_item = this.state.membersInSession.find((items) => { return parseInt(item['user_id']) === parseInt(items['id']) });
			if( find_item )
				{
					item['alreadyThere'] = true;
				}
			else
				{
					canMoreJoin = true;
				}
		});
		this.setState({
			channelMembers : mainArray,
			canMoreJoin: canMoreJoin
		});
		
		//console.log('this.state.channelMembers');
		//console.log(this.state.channelMembers);
		console.log(canMoreJoin);
		
		this.checkCreateUser();
		$('.main_loader').hide();
	});
	
}
inviteAll = e =>
{
	if (this.state.selectedMembers.length > 0 )
		{
			$('.main_loader').attr({'style':'display:flex'}); 
			axios.post(process.env.REACT_APP_NAME+"/api/v1/session/inviteMember", {'sessionId' : this.props.state.choosenSessionId, 'sessionLink' : this.props.state.choosenSessionLink, 'member' : this.state.selectedMembersAllInfo})
			.then(res => {
				if ( res.data.responseData === 'success' )
					{
						this.setState({
							membersLeft: this.state.membersLeft-this.state.selectedMembersAllInfo.length,
							selectedMembers: [],
							selectedMembersAllInfo : [],
							currentUsers: [],
							checkBoxStatus: false,
						});
						this.filterInSession();
						$('.main_loader').hide();	
						this.props.showMainErrorPop('All checked members have been invited.');
					}
				else 
					{
						$('#session_full').show();
						$('.main_loader').hide();	
					}
				
			});
		}	
	
	else
		{
			this.props.showMainErrorPop('Please select at least one member');
		}
}

signUpAll = (mod?) =>
{
	if (this.state.selectedMembers.length > 0 )
		{
			$('.main_loader').attr({'style':'display:flex'}); 
			axios.post(process.env.REACT_APP_NAME+"/api/v1/session/insertMember", {'sessionId' : this.props.state.choosenSessionId, 'member' : this.state.selectedMembersAllInfo})
			.then(res => {
				if ( res.data.responseData === 'success' )
					{
						
						this.setState({
							membersLeft: this.state.membersLeft-this.state.selectedMembersAllInfo.length,
							selectedMembers: [],
							selectedMembersAllInfo : [],
							currentUsers : [],
							checkBoxStatus: false,
						});
						
						this.filterInSession();
						$('.main_loader').hide();
						if (!mod)
							{
								this.props.showMainErrorPop('All checked members have been signed up.');
							}
						
					}
				else 
					{
						$('#session_full').show();
						$('.main_loader').hide();	
					}
				
			});
		}
		
	else
		{
			this.props.showMainErrorPop('Please select at least one member');
		}
}

signUpAllNew = e =>
{
	let current_selected = this.state.selectedMembersAllInfo;
	let valid_users = [];
	let valid_users_id = [];
	let allSelectedUsers = this.state.currentUsers;
	for (var i = 0; i < current_selected.length; i++)
		{
			if ( current_selected[i]['user_id'] !== null )
				{
					valid_users.push(current_selected[i]);
					valid_users_id.push(current_selected[i]['id']);
				}
			else 
				{
					let newIndex = allSelectedUsers.indexOf(current_selected[i]);
					allSelectedUsers.splice(newIndex, 1);
				}				
		}	
	this.setState({
		selectedMembersAllInfo : valid_users,
		selectedMembers : valid_users_id,
		currentUsers: allSelectedUsers,
		checkBoxStatus: false,
	}, () => 
		{
			if (this.state.selectedMembersAllInfo.length > 0 )
				{
					this.props.showMainErrorPop("Some of members you have selected can't join class. Those members have to register on Virdio first. Rest of them have been signed up.");	
					this.signUpAll('dont show');
				}
			else 
				{
					this.props.showMainErrorPop("Members you have selected can't join class. Those members have to register on Virdio first.");	
				}				
		});
}

closeAddMemberScreen = e =>
{
	this.setState({
		channelMembers : [],
		emptyStatus: false,
		selectedMembers: [],
		selectedMembersAllInfo : [],
		currentUsers: [],
		membersLeft : -1,
		checkBoxStatus: false,
	});		
	$('#search_input').val('');
	this.props.refreshThisWeek();
	this.props.closeAddMember()
}
inputTriger = e =>
{
	if (e.key === 'Enter') 
	{
		$("#members_search").trigger('click');
    }
}
chechInSessionMembers = e =>
{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/checkSessionMembers", {'sessionId' : this.props.state.choosenSessionId})
	.then(res => {
		
	});
}

openInvitePopNew = e =>
{
	$("#members_addModalNew").attr({'style':'display:block'});
}
addMembers=(e)=>{
    // let channelId = localStorage.getItem('channelId');
    let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
    //channdlId=1;
    if(!channelId){
       channelId=localStorage.getItem('channelId');
    }
    const userData ={
        channelId:channelId,
        firstName:this.state.firstName,
        lastName:this.state.lastName,
        email:this.state.email,
		endDate: this.state.enddate,
		channelCreateUser: this.state.channelCreateUser,
		subdomain:this.state.channelSubdomain
		}
		
    if (this.validator.allValid()) {
		
	if ( this.state.limitedMember && this.state.enddate === '' )
		{
			this.setState({errorTime: true});
			return false;
		}		
	
	$('.main_loader').attr({'style':'display:flex'}); 
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;    
    axios.post(process.env.REACT_APP_NAME+"/api/v1/session/member",userData)
    .then(res => {
		$('.main_loader').hide();
		this.setState({
			firstName : '',
			lastName : '',
			email: '',
			limitedMember: false,
			errorTime: false,
		});
       $("#members_addModalNew").attr({'style':'display:none'});
	   if ( res.data && res.data.responseMessage && res.data.responseMessage == 'success' )
		{
			this.props.showMainErrorPop('Member has been added successfully!!!');
		}
	}).catch(err =>{
		$('.main_loader').hide();
		this.setState({
			firstName : '',
			lastName : '',
			email: '',
			limitedMember: false,
		});
		this.props.showMainErrorPop(err.response.data.errorData);
		$("#members_addModalNew").attr({'style':'display:none'});
        });
    }else
	{
		this.validator.showMessages();
		if(!this.validator.fieldValid('firstName') )
		{
			this.setState({
			  errorClass_MemberFirstName:true
			})
		}
		if(!this.validator.fieldValid('lastName') )
		{
			this.setState({
			  errorClass_MemberLastName:true
			})
		}
		if(!this.validator.fieldValid('email') )
		{
			this.setState({
			  errorClass_MemberEmail:true
			})
		  }
		
		this.forceUpdate();
    }
	
  }
	closeAddPop = e =>
		{
			this.setState({
				errorClass_MemberFirstName:false,
				errorClass_MemberLastName:false,
				errorClass_MemberEmail:false,
				firstName: '',
				lastName: '',
				email: '',
			})
			this.validator.hideMessages();
		  $("#members_addModalNew").attr({'style':'display:none'});
		}
		
	updateState = (newState) => {
		this.setState(newState, ()=>{
				let createVirdioUser = this.state.channelCreateUser===false?0:1;
				$('.main_loader').attr({'style':'display:flex'}); 
				axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/updateMemberCreateUser", {'createUser' : createVirdioUser, 'channelId' : JSON.parse(localStorage.getItem('userData')).data.responseData.channel})          
				.then(res => {
					$('.main_loader').hide();
				})
				.catch(err =>{ $('.main_loader').hide(); }); 
			}
		);
		 
	}
	
	applyEndDate = () =>
	{
		let getFullYear=new Date(this.state.endDateTemp).getFullYear();
		let getMonth=(new Date(this.state.endDateTemp).getMonth()+1);
		let getDate=new Date(this.state.endDateTemp).getDate();
		
		if ( getMonth < 10 ) { getMonth = '0'+getMonth; }
		if ( getDate < 10 ) { getDate = '0'+getDate; }
		
		this.setState({ enddate: getFullYear+'-'+getMonth+'-'+getDate, errorTime: false })
		$("#calenderModelEndDate").attr({'style':'display:none'})
	}
	
	changeEndDate = (date) =>
		{
			this.setState({endDateTemp: date})
		}

		
  render() {
	  
	  
    return (
		<div className="container-fluid custom-width chsubPage">
			<div className="noprint"><Header></Header></div>
			<div className="hiddenDivMembersList" onClick={this.filterInSession} ></div>
			<div className="clearfix"></div>
			<div className="h1_hold_members">
				<div className="reports_main_title noprint members_new_fontSize members_holder_padding">Invite/Sign Up Members</div>
				<div className="closePopup noprint new_close_pos" onClick={this.closeAddMemberScreen} ></div>
			</div>
			<div className="d-flex justify-content-between align-items-center px-4 pb-4 border-bottom member_title_hold ch_padd relative">
				<div className="mt-4">
  <h4 className="text-white d-inline-block font-22 pt-1 pl-2 mb-0 membertopheader add_wdt">Members Listing {/*<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb member_bulb" onClick={e=>{this.getFaq(21)}}/>*/}</h4>
					<a className="pick ml-4"><img src="/images/add.png" className="add_curs" alt="" onClick={this.openInvitePopNew}/></a>
					{/*<Link to="/member" className="pick ml-4"><img src="/images/icon_upload.svg" alt="" onClick={e=>{$("#members_importModal").attr({'style':'display:block'})}}/></Link>*/}
				</div>
				<div className="member_create_user2">
					<span className="demand_middle toggle_distance">Create Virdio User When Importing Members</span>
					<label className="switch">
						<input type="checkbox" id = "channelCreateUser" checked={this.state.channelCreateUser} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.channelCreateUser})}}/>
						<span className="slider round"></span>
					</label>
					<div className="toggle_option">{this.state.channelCreateUser ? 'ON' : 'OFF'}</div>
					<img src="/images/bulb.png" onClick={e=>{this.props.showMainErrorPop('Create Virdio User When Importing Members.')}} className="ml-3 mb-2 cursor-pointer channel_bulb"/>
				</div>
			</div>
			<div className="reportsholdn holder_members_list members_border_bottom">
				<div className="new_holder_all_members_buttons">
					<div className="mt-3 members_new_button">
						<button className="join_button text-uppercase members_button_padd" onClick={this.inviteAll} >Invite All</button>
					</div>
					{ !this.state.hasNoId && <div className="mt-3 members_new_button">
						<button className="join_button text-uppercase members_button_padd" onClick={() => this.signUpAll()} >Sign Up All</button>
					</div> }
					{ this.state.hasNoId && <div className="mt-3 members_new_button">
						<button className="join_button text-uppercase members_button_padd inactive_signup_button" onClick={this.signUpAllNew} >Sign Up All</button>
					</div> }
				</div>
				<div className="channel_name channel_name3 no_marg_members">
					<div className="flex-grow-1 input_field_container py-0 radius-8  members_input">
						<div className="row mx-0 frst-input-row shadow-1 py-2 align-items-center radius-8">
							<div className="flex-grow-1"><input type="text" className="form-control search_input_dashboard" placeholder="Search Member by Name" name="" id="search_input" autoComplete="off" onKeyDown={this.inputTriger} />
							</div>
						</div>
						<div className="right-small-box" onClick={this.filterMembers} id="members_search" ><img src="/images/search_loop.png" alt="search" /></div>
					</div>
				</div>
			</div>
			<div className="reportsholdn holder_members_list members_border_bottom">
			
			{this.state.channelMembers.length > 0 && !this.state.emptyStatus && 
				<div className="members_25_holder first_hoder_new_width">
				{ this.state.membersLeft === 0 ? 
					<div className="check_box_width new_left_text_members " >Class full</div>
					:
					<div>
				{(this.state.membersLeft > this.state.selectedMembers.length && this.state.canMoreJoin == true) &&
						<div className="new_left_text_members " >Select All
							<div className="members_25_holder check_box_width"> 
									<div className={"new_check_box_member " + (this.state.checkBoxStatus ? "active" : "") } onClick={e=>this.selectAllMembers()}></div>
							</div>
						</div>
					}
					</div>
				}
				</div>
			}
				<div className={"members_25_holder new_2nd_holder " + (this.state.channelMembers.length > 0 ? "" : "new_hold_width") } >Name</div>
				<div className="members_25_holder new_2nd_holder" >Email</div>
				<div className="members_25_holder new_2nd_holder" >Action</div>
			</div>
			<div className="members_all_holder">
			{this.state.currentUsers.length > 0 && 
				<div className="selectedUsersArray" >
					<div className="selected_h1" >Selected Users</div>
					{this.state.currentUsers.length > 0 &&
					(this.state.currentUsers.map((user,i)=>
						<div className={ "one_member_holder " + ( i % 2 == 0 ? "member_grey " : "" ) } key={i}>
							<div className="members_25_holder check_box_width"> 
								<div className="new_check_box_member active" onClick={e=>this.removeFromArray(user)}></div>
							</div>
							<div className="members_25_holder text_wrap_demand less_font_members" >{user.firstName} {user.lastName}</div>
							<div className="members_25_holder text_wrap_demand less_font_members" >{user.email}</div>
							<div className="members_25_holder buttons_box_width" >
								{ this.state.membersLeft > this.state.selectedMembers.length && !user.alreadyThere &&
									<div>
										<div className="small_btn" onClick={e=>this.inviteMember(user)} >Invite</div>
										{ user.user_id !== null &&
											<div className="small_btn small_marg_left" onClick={e=>this.signUpMember(user, 'current')}>Sign Up</div> 
										}
										{ user.user_id === null &&
											<div className="small_btn small_marg_left hidden_click">Sign Up</div> 
										}
									</div>	
								}
								{ this.state.membersLeft <= this.state.selectedMembers.length && !user.alreadyThere &&
									<div>
										<div className="small_btn" onClick={e=>this.inviteMember(user)} >Invite</div>
										{ user.user_id !== null && <div className="small_btn small_marg_left" onClick={e=>this.signUpMember(user, 'current')}>Sign Up</div> }
										{ user.user_id === null && <div className="small_btn small_marg_left hidden_click" >Sign Up</div> }
									</div>
								}
								{ user.alreadyThere &&
									<div className="small_btn cover_small_btn" >In class</div>
								}
							</div>
						</div>
						))
					}
				</div>	
			}
			
			 {this.state.channelMembers.length > 0 && !this.state.emptyStatus &&
				(this.state.channelMembers.map((row,i)=>
				<div className={ "one_member_holder " + ( row.isHidden ? "hidden " : "" ) + ( i % 2 == 0 ? "member_grey " : "" ) } key={i}>
					<div className="members_25_holder check_box_width">
						{this.state.membersLeft > this.state.selectedMembers.length && !row.alreadyThere && this.state.userId != row.user_id &&
							<div className={"new_check_box_member " + (this.state.selectedMembers.indexOf(row.id) > -1 ? 'active' : '')} onClick={e=>this.addToArray(row)} ></div> 
						}
						{ this.state.membersLeft <= this.state.selectedMembers.length &&  this.state.selectedMembers.indexOf(row.id) > -1 && !row.alreadyThere &&
							<div className="new_check_box_member active" onClick={e=>this.addToArray(row)}> </div>
						}
						{ this.state.membersLeft <= this.state.selectedMembers.length &&  this.state.selectedMembers.indexOf(row.id) === -1 && !row.alreadyThere &&
							<div className="new_check_box_member new_opacity"> </div>
						}
					</div>
					<div className="members_25_holder text_wrap_demand less_font_members" >{row.firstName} {row.lastName}</div>
					<div className="members_25_holder text_wrap_demand less_font_members" >{row.email}</div>
					<div className="members_25_holder buttons_box_width" >
						{ this.state.membersLeft > this.state.selectedMembers.length && !row.alreadyThere && this.state.userId != row.user_id &&
							<div>
								<div className="small_btn" onClick={e=>this.inviteMember(row)} >Invite</div>
								{ row.user_id !== null &&
									<div className="small_btn small_marg_left" onClick={e=>this.signUpMember(row, 'pick')}>Sign Up</div> 
								}
								{ row.user_id === null &&
									<div className="small_btn small_marg_left hidden_click">Sign Up</div> 
								}
							</div>	
						}
						{ this.state.membersLeft <= this.state.selectedMembers.length && !row.alreadyThere && this.state.userId != row.user_id &&
							<div>
								<div className="small_btn" onClick={e=>this.inviteMember(row)} >Invite</div>
								{ row.user_id !== null && <div className="small_btn small_marg_left" onClick={e=>this.signUpMember(row, 'pick')}>Sign Up</div> }
								{ row.user_id === null && <div className="small_btn small_marg_left hidden_click" >Sign Up</div> }
							</div>
						}
						{ row.alreadyThere && this.state.userId != row.user_id &&
							<div className="small_btn cover_small_btn" >In class</div>
						}
					</div>
				</div>
				))
			 }
			 { this.state.channelMembers.length === 0 && !this.state.emptyStatus &&
				<div className="no_members_text" >Enter the member's first name to search Member database</div>
			 }
			{ this.state.emptyStatus &&
				<div className="no_members_text" >Can't find member with that first name.</div>
			 }
			</div>
			 {/* ADD MEMBER MODAL */}
             <div className="modal member_screen_mdl new_add_memb_modal" id="members_addModalNew" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content bg-transparent px-2">
						<div className="modal-header pb-2 border border-0 px-0 justify-content-center">
							<div className="text-center"><img src="/images/host.png" alt="host" />
							<h4 className="white text-center mb-0 mt-3">Add a Member</h4>
							</div>                            
                        </div> 
						<div className="modal-body py-3 px-3 modalbg radius-10">
							 <button type="button" className="close white closepurple translatX-Y" onClick={this.closeAddPop}><span>x</span></button>  
							 <div className="d-flex align-items-start flex-wrap mt-4">
								 <p className="text-white mr-3 mt-3 w-108">First Name</p>
                                <div className="form-group flex-grow-1 mb-0">
									<input type="text" 
									className={( this.state.errorClass_MemberFirstName ? "input-field red-outline" : "input-field" )}  
									id = "firstName" 
								   value= {this.state.firstName} 
								   onChange = {(e)=>this.setState({[e.target.id] : e.target.value, errorClass_MemberFirstName:false})}
									placeholder="First Name" maxLength="30" />
								   {this.validator.message('firstName', this.state.firstName, 'required|alpha_space|min:1|max:30')}
									<span className="signedup_2"></span>
								</div>
							</div>
							 <div className="d-flex align-items-start flex-wrap">
                                <p className="text-white mr-3 mt-3 w-108">Last Name</p>
                                <div className="form-group flex-grow-1 mb-0">
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" 
									className={(this.state.errorClass_MemberLastName ? "input-field red-outline" : "input-field" )}  
									id="lastName" 
                                    value= {this.state.lastName} onChange = {(e)=>this.setState({[e.target.id] : e.target.value, errorClass_MemberLastName:false})} placeholder="Last Name" maxLength="30" />
                                    {this.validator.message('lastName', this.state.lastName, 'required|alpha_space|min:1|max:30')}
                                    <span className="signedup_2"></span>
                                </div>							
                            </div>
							<div className="d-flex align-items-start flex-wrap">
                                <p className="text-white mr-3 mt-3 w-108">Email Address</p>
                                <div className="form-group flex-grow-1 mb-0">
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" 
										className={(this.state.errorClass_MemberEmail?"input-field red-outline":"input-field")}  
										id="email"  
										value= {this.state.email} onChange = {(e)=>this.setState({[e.target.id] : e.target.value, errorClass_MemberEmail:false})}  placeholder="Email" maxLength="100" />
                                    {this.validator.message('email', this.state.email, 'required|email')}
                                    <span className="form_email"></span>
                                </div>							
                            </div>
							<div className="d-flex align-items-start flex-wrap mt-3">
                                <p className="text-white w-108">Limited Time</p>
                                <div className="form-group flex-grow-1 mb-0" style={{marginLeft: '15px'}}>
                                    <label className="switch">
										<input type="checkbox" id="limitedMember" checked={this.state.limitedMember} onChange={(e)=>this.setState({[e.target.id] : !this.state.limitedMember})}/>
										<span className="slider round"></span>
									</label>
									<div className="toggle_option toggle_top">{this.state.limitedMember ? 'ON' : 'OFF'}</div>
                                </div>							
                            </div>
                             {this.state.limitedMember && <div className="d-flex align-items-start flex-wrap">
                                <p className="text-white mr-3 mt-3 w-108">End Date</p>
                                <div className="form-group flex-grow-1 mb-0" onClick={e=>{$("#calenderModelEndDate").attr({'style':'display:block'});}}>
                                    <span className="cover-border bg_gray_clr"></span>
                                    <input type="text" className={"input-field " + (this.state.errorTime ? 'red-alert' : '')} id="enddate" value={this.state.enddate}  placeholder="End Date" readOnly />
                                </div>							
                            </div>}
							<div className="text-center">
                                <button type="button" className="done mx-2 mt-3" onClick={this.addMembers}>Add Member</button>
                            </div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal cal_modal mt-3" id="calenderModelEndDate" data-backdrop="">
				<div className="modal-dialog d-md-flex d-block large_width1 mb-0">
					<div className="modal-content modalbg m-auto">
						<div className="modal-header px-4 pt-3 pb-0">
							<h4 className="white modal-title">Choose Date</h4>
							<button type="button pr-3" className="close white closepopup" onClick={e=>$("#calenderModelEndDate").attr({'style':'display:none'})}>&times;</button>
						</div>
						<div className="modal-body modal-body px-1 px-sm-3">
							<h3>Calender</h3>

							<Calendar
							   onChange={this.changeEndDate}
							   value={this.state.endDateTemp}
							   minDate={new Date()}
							 />
						</div>
						<div className="text-center position-absolute btn_btn1">
						{this.state.signUpSessionStatus?'':<button type="button" className="done mt-0" onClick={this.applyEndDate} data-dismiss="modal">done</button>}
					</div>
					</div>
				</div>
			</div>		

			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>			
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(MembersList);