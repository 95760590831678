import React, { Component } from "react";
import { Link, withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import axios from "axios";

import { browserHistory } from 'react-router'


import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "../../actions/types";

import $ from 'jquery';

class CancelLink extends Component {
            constructor() {
            super();
          
            this.state = {
                email:"",
                showError:false,
                messageFromServer:'',
                type:"1",
                errors: {},
				showPart: 0,
              };
        
            }

componentDidMount() {
	this.cancelSession();		
}

componentWillMount(){}

cancelSession = e =>{
	let path=window.location.pathname;
	let path_arr = path.split("/");
	const userData = {
	  userId: path_arr[2],
	  sessionId: path_arr[3]
	};
		
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/cancelViaLink", userData).then(res => {
		console.log('-------res.data.responseMessage', res.data.responseMessage);
		if ( res.data.responseMessage === 'success' )
			{
				this.setState({
					showPart: 1,
				})
			}		
		}).catch(err =>{
				this.setState({
					showPart: 2,
					messageFromServer: err.response.data.errorData
				}) });
}
goToVirdio=e=>{
	this.setState({ showPart: 1});
	browserHistory.push("/");
}

render() {

        return (
			<div>
				<div className="container">
					<div className="row">
						<div className="login-bg">
						</div>              
					</div>
				</div>
				{this.state.showPart == 1 &&
					<div id="message" className="modal zindex999999 show_modal" data-backdrop="static" data-keyboard="false">
							<div className="modal-dialog mw-700 mx-auto modal-dialog-centered ">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none text_center_pop">
										<div>You successfully cancelled your class. Go to  <span className="btn-primary vird_btn" onClick={this.goToVirdio} >Virdio</span> </div>
									</div>
								</div>
							</div>
					</div>		
				}
				{this.state.showPart == 2 &&
						<div id="message" className="modal zindex999999 show_modal" data-backdrop="static" data-keyboard="false">		
							<div className="modal-dialog mw-700 mx-auto modal-dialog-centered show_modal">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none text_center_pop">
										<div>{this.state.messageFromServer}</div>
									</div>
								</div>
							</div>
						</div>
				}
			</div>
         );

         }

}

const container = {
    "padding": "0px 15px",
    "max-width": "1140px"
  
  };

export default connect()(CancelLink);

