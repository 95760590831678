import React, { Component } from "react";
import SupportContainer from '../home/SupportContainer';

class SessionGuideline extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		supportPageName: 'Session Guidelines',
		supportPageApi: '/SessionGuideline',
		userTypeFaqs: 2
	}	
    
}
 

componentDidMount(){
  }


  render() {
	


    return (
      <div>
        <div id="root">
            <div className="App">
                <div className="container-fluid custom-width">
                    <div className="top-header px-5 pt-5">
                        <div className="">
                            <img src="images/login-logo.png" className="logo" />
                        </div>
                        <div className="privacy_banner position-relative">
                            <img src="images/terms-of-Use.png" alt="terms_use" className="w-100" />
                        </div>                                       
                    </div>
                    <div className="background_gray px-5 pt-5 pb-4">
                        <div className="content-privacy pt-5">
                            <h3 className="purple_text font-32 mb-4 text-center font-book">Class Guidelines</h3>
                            <h3 className="white"><u>Effective December 1, 2019</u></h3>
                            <p className="text-white">These Class Guidelines (the “Guidelines”) are incorporated by reference into the Virdio Terms of Service ( <a href="/TermsofService" target="blank">Terms of Service</a> ) Capitalized terms not defined herein have the meanings set forth in the Terms of Service. The following terms and conditions supplement the Virdio Terms of Service and are not exhaustive. Please be respectful of others when you use the Service.</p>
                            <h3 className="white"><u>General Guidelines</u></h3>
                            <ul>
                              <li>Post, stream or otherwise transmit content that:  <br  />
                              (i) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable;<br  />
                              (ii) you do not have a right to transmit under any law or under contractual or fiduciary relationships;  <br  />
                              (iii) poses or creates a privacy or security risk to any person; or  <br  />
                              (iv) infringes any intellectual property or other proprietary rights of any party;</li>
                              <li>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                              <li>Exploit or solicit personal information from anyone under the age of 18;</li>
                              <li>Violate any applicable local, state, national or international law, or any regulations having the force of law; </li>
                              <li>Interfere with or disrupt the Service, servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service; or </li>
                              <li>Obtain or otherwise attempt to access any materials or information through any means not intentionally made available or provided for through the Service.</li>
                              <li>Post, stream or transmit any content, including live video, that violates this Policy</li>
                              <li>Do anything illegal, facilitate any illegal activity, or promote violence.</li>	
                              <li>Do anything that threatens, exploits or otherwise harms children.</li>	
                              <li>Engage in any activity that is harmful, obscene, or indecent (particularly as such would be understood in the context of business usage). This includes, for example, displays of nudity, violence, pornography, sexually explicit material, or criminal activity.</li>	
                              <li>Facilitate or support human trafficking.</li>
                              <li>Engage in any activity that supports or facilitates terrorism or terrorist organizations</li>					
                              <li>Engage in any activity that is fraudulent, false, or misleading.</li>
                              <li>Engage in any activity that is defamatory, harassing, threatening or abusive.</li>
                              <li>Store or transmit any data or material that is fraudulent, unlawful, harassing, libelous, threatening, obscene, indecent or otherwise inappropriate.</li>
                              <li>Send unauthorized messages, advertising or spam, including unsolicited promotional or commercial content or other mass solicitation material.</li>
                              <li>Misrepresent the User’s identity or affiliation with any entity or organization, or impersonate any other person.</li>
                              <li>Harvest, collect, or gather user data without consent.</li>
                              <li>Violate or infringe any intellectual property or proprietary rights of others, including copyrights.</li>
                              <li>Engage in any activity that is harmful or disruptive to the Services or attempts to circumvent restrictions on access, usage or security of the Services. This includes transmitting viruses, malware or other malicious or destructive code or using tools that mask IP address location or to otherwise circumventing restrictions on use due to regulations or account closures.</li>
                            </ul>
                            
                            <h3 className="white"><u>Instructor(s) specific rules/regulations</u></h3>
                            <ul>
                              <li>Must be logged in
                                <ul>
                                  <li>Before hosting first class minimum of 15 minutes</li>
                                  <li>Recommend 10 minutes with a minimum of 5 minutes before class is to begin</li>
                                </ul>
                              </li>
                              <li>Must wear appropriate clothing for the activity you are hosting
                                <ul>
                                  <li>Fitness: Proper gym attire is required-no jeans, open toed shoes or clothing with profanity</li>
                                  <li>Wine Tasting: Same attire as you would use for on-site tastings</li>
                                </ul>
                              </li>
                              <li>No use of profanity</li>
                              <li>Instructor Network Requirements:</li>
								<ul>
									<li>The Instructor requirements are based on number of Members the Instructor would want to see on the screen at one time.  By default, if there are 8 or more members, we show 8 on the screen.  The instructor can show less than 8 by changing the On-screen settings.</li>
									<ul>
										<li>8 On-screen – 30-40mb Up and Down.</li>
										<li>Which averages to about 4-5mb per member.</li>
									</ul>
								</ul>								
                            </ul>

                            <h3 className="white"><u>Member(s) specific rules/regulations</u></h3>
                            <ul>
                              <li>Must be logged in
                                <ul>
                                  <li>Before first class 10 minutes</li>
                                  <li>Recommend 5 minutes with a minimum of 2 minutes before class is to begin</li>
                                </ul>
                              </li>
                              <li>Must wear appropriate clothing for the activity you are hosting
                                <ul>
                                  <li>Fitness: Proper gym attire is required-no jeans, open toed shoes or clothing with profanity</li>
                                  <li>Wine Tasting: Same attire as you would wear to an on-site tasting</li>
                                </ul>
                              </li>
                              <li>No use of profanity</li>
                              <li>Member Network Requirements:</li>
								<ul>
									 <li>Just the Instructor on the screen – 2-5mb Up/Down</li>
									 <li>Multi-Member Setting turned on (by Instructor) – approximately 20mb Up/Down.</li>
								</ul>
                            </ul>
                            <p className="">If you become aware of any actual or potential violations of the foregoing, please contact <a href="mailto:support@virdio.com"><u>support@virdio.com</u></a>. Without limiting any other remedies Virdio may have, we reserve the right to investigate and take appropriate legal action against anyone who, in our discretion, violates these Guidelines, including without limitation, removing the offending content from the Service or suspending or terminating the account of such violators.</p>                           
                        </div>
                    </div>            
                </div>
            </div>
        </div>	
		<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
      </div>	
    );
  }
}

export default SessionGuideline;
