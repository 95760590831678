import React, { Component } from "react";
import classnames from "classnames";
import { browserHistory } from 'react-router';
import { Link } from "react-router";

class MemberSubscribe extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
	
    }
	
}
 
componentDidMount(){
	
}

goToLogin=()=>{
	browserHistory.push('/login');
}

  render() {
	 return (
		<div className="memberRegisterHolder">
				<div className="sign_title fnt40">Activate your Free Virdio Trial</div>
				<div className="info_row_holder">				
					<div className="one_form_container">
						<div className="form_title">Name</div>
						<div className="relative_holder">
							<input type="text" tabIndex="3" className={classnames({'gray_inputs': true, 'error_input': this.props.state.nameError})} 
							id="nameValue" 
							value={this.props.state.nameValue} 
							onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, nameError: false})} 
							onFocus={() => this.props.updateStateFromChild({focusedElement: 'nameValue'})} 
							onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
							{ this.props.state.nameValue !== '' &&
								<div className="empty_input" onClick={()=>this.props.updateStateFromChild({nameValue: ""})}></div>
							}
						</div>
					</div>
				</div>
				<div className="info_row_holder">
					<div className="one_form_container">
						<div className="form_title">Email</div>
						<div className="relative_holder">
							<input type="text" tabIndex="3" className={classnames({'gray_inputs': true, 'error_input': this.props.state.errorEmail})} 
							id="emailInput" 
							value={this.props.state.emailInput} 
							onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorEmail: false})} 
							onFocus={() => this.props.updateStateFromChild({focusedElement: 'emailInput'})} 
							onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
							{ this.props.state.emailInput !== '' &&
								<div className="empty_input" onClick={()=>this.props.updateStateFromChild({emailInput: ""})}></div>
							}
						</div>
					</div>
				</div>
				<div className="info_row_holder">
					<div className="one_form_container">
						<div className="form_title">Password</div>
						<div className="relative_holder">
							<input 
							type={this.props.state.showPassword ? "text" : "password"} 
							tabIndex="4" 
							className={classnames({'gray_inputs': true, 'error_input': this.props.state.errorPassword})} 
							id="passwordInput" 
							value={this.props.state.passwordInput} 
							onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorPassword: false})} 
							onFocus={() => this.props.updateStateFromChild({focusedElement: 'password'})} 
							onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
							<div className="eye_show" id="showPassword" onClick={(e)=>this.props.updateStateFromChild({showPassword: !this.props.state.showPassword})}>
								{ this.props.state.showPassword && <div className="crossed_eye"></div> }
							</div>
							{ this.props.state.focusedElement == 'password' && 
								<div className="password_check_form">
									<div className="pass_check_title">Your password needs to:</div>
									<div className="pass_main_line">
										<div className={ "pass_done_icon " + ( !this.props.state.contain8Ch ? "pass_iks_icon" : "" ) }></div>
										<div className="pass_line_text">Minimum of 8 characters</div>
									</div> 
									<div className="pass_main_line">
										<div className={ "pass_done_icon " + ( !this.props.state.letterContain ? "pass_iks_icon" : "" ) }></div>
										<div className="pass_line_text">Minimum of 1 Letter</div>
									</div>
									<div className="pass_main_line">
										<div className={ "pass_done_icon " + ( !this.props.state.numberContain ? "pass_iks_icon" : "" ) }></div>
										<div className="pass_line_text">Minimum of 1 Number</div>
									</div>
									<div className="pass_main_line">
										<div className={ "pass_done_icon " + ( !this.props.state.specialSymbol ? "pass_iks_icon" : "" ) }></div>
										<div className="pass_line_text">Minimum of 1 Special Character</div>
										<div className="pass_line_text spec_char">(e.g. !,@,#,$,%,&,*)</div>
									</div>
								</div>
							}
						</div>
						{ this.props.state.errorPassword &&
							<div className="passwordErrorMessageTxt">Password does not meet required criteria</div>
						}
					</div>
				</div>
				{/*
				<div className="info_row_holder">
					<div className="one_form_container">
						<div className="form_title codeColorText">Have a discount/referral code?</div>
						<div className="relative_holder" className={classnames({'relative_holder': true, 'showButton': this.props.state.code.length > 0 })}  >
							<input type="text" tabIndex="5" className={classnames({'gray_inputs': true, 'foundCouponBorder': this.props.state.codeApplied})} 
							id="code" 
							value={this.props.state.code} 
							onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value})} 
							onFocus={() => this.props.updateStateFromChild({focusedElement: 'code'})} 
							onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
							{ this.props.state.code !== '' &&
								<div className="empty_input" onClick={()=>this.props.updateStateFromChild({code: "", codeApplied: false})}></div>
							}
						</div>
						{this.props.state.code.length > 0 &&
							<div className="applyBtnHolder">
								<div className={classnames({'inline_event': true})} onClick={(e)=>this.props.checkCoupon()}>
									<div className="bottom_title_small">APPLY</div>
								</div>
							</div>
						}
					</div>
				</div>
				*/}
				<div className="bottomRegisterPadding">
					<div className="loginBottomHolders">
						<div className="info_row_holder">
							<div className="one_form_container">
								<Link to="/login">
									<div className="registerText">Already have an account? <span className="signInSpan"> Sign in</span>
									</div>
								</Link>
							</div>
							<div className="one_form_container">
								<a href={this.props.state.videoLink} target="_blank" >
									<div className="registerText signInSpan">What’s virdio?</div>
								</a>
							</div>
						</div>	
					</div>
					<div className="loginBottomHolders">
						<div className="bottom_arrow">
							<div className={classnames({'signNewButton': true, 'opacity_event': this.props.state.emailInput == '' || this.props.state.passwordInput == '' || this.props.state.nameValue == '' })} onClick={(e)=>this.props.registerNewMember()}>
								<div className="">Sign in</div>
							</div>
						</div>
					</div>
				</div>
				{ /*
				<div className="borderMainHolder">
					<div className="bottomBorderPart"></div>
					<div className="orTextPart">OR</div>
					<div className="bottomBorderPart"></div>
				</div>
				<div className="registerOtherHolder">
					<div className="fbButtonPart" onClick={(e)=>this.props.loginFacebook()}>
						<div className="fbLogoImg"></div>
						<div className="fbText">Continue with Facebook</div>
					</div>
					<div className="fbButtonPart">
						<div className="fbLogoImg googleLogo"></div>
						<div className="fbText">Continue with Google</div>
					</div>
				</div>
				*/ }
				
		</div>
    );
  }
}
export default MemberSubscribe;
