import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";
import { getSessionTime } from '../../util/formatDateFunctions';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTimeAdditional } from '../../util/formatDateFunctions';



class ChannelUpcoming extends Component {
  
  constructor(props) {
	super(props);
	this.state={
    }
	
	this.getSessionTimeUtil = getSessionTime.bind(this);
	this.getSessionTimeUtilAdditional = getSessionTimeAdditional.bind(this);
	this.getSessionDate = getSessionDate.bind(this);
}
 
componentDidMount(){
}

openSessionDetails=(e, mod)=>{
	if ( this.props.state.sessionDetailsId == mod )
		{
			this.props.updateStateFromChild({sessionDetailsId: 0, activeFilter: ''}, e);
		}
	else 
		{
			this.props.updateStateFromChild({sessionDetailsId: mod, activeFilter: ''}, e);
		}		
	
}

openCancel=(e, mod)=>{
	e.stopPropagation();
	this.props.updateStateFromChild({cancelSessionId: mod, activeFilter: ''}, e);
	this.props.openCancel(e, 'open');
}

openSessionUrl=(e, mod, data?)=>{
	e.stopPropagation();
	this.props.updateStateFromChild({activeFilter: ''}, e);
	if ( data )
		{
			this.props.openURL(e, mod, data)
		}
	else 
		{
			this.props.openURL(e, mod)
		}		
	
}

  render() {
	 return (
	 <div className="channel_topscroll">
		<div className="channel_subscroll" >
				<div className="upc_channel" >
					{ this.props.state.upcomingArrayDates && this.props.state.upcomingArrayDates.map((item, i)=>
						<div key={i}>
							<div className="ch_up_date" >{item}</div>
							{ this.props.state.upcomingArray[item] && this.props.state.upcomingArray[item].map((data, d)=>
								<div className="upcoming_hold_all" key={d} >
									<div className="upcomingSessionPart posRel" >
										<div className="descTextPart" >
											<div className="dataTime" >{this.getSessionTimeUtilAdditional(data.scheduleDate)}</div>
											<div className="dataTime2" >{data.duration} min</div>
										</div>	
										<div className={classnames("descTextPart ")} >
											<div className={classnames("descPartCh ")} >{data.name}</div>
										</div>
										
											<div className="descTextPart" >
												<div className="intrPartCh" >{data.interestTitles}</div>
											</div>
										
										<div className="descTextPart" >
											<div className={classnames("levelPartCh " + (data.level == 'Advanced' ? "redLvl" : "" ) + (data.level == 'Intermediate' ? "yellowLvl" : "" ) + (data.level == 'Beginner' ? "greenLvl" : "" ) )} >{data.level}</div>
										</div>
										<div className="descTextPart hostPartCh" >{data.firstName} {data.lastName}</div>
										<div className="descTextPart btnPartChHold" >
											{ data.ejectedStatus &&
												<div className="ejeText" >Ejected</div>
											}
											{data.alreadyMember && (data.sessionStatus == 'started' || data.sessionStatus == 'halfhourless') && !data.ejectedStatus &&
												<div className="btnPartCh bookedBtn" onClick={(e)=>{this.openSessionUrl(e, data.id, data)}} >Join</div> 
											}
											{data.alreadyMember && data.sessionStatus != 'started' && data.sessionStatus != 'halfhourless' && !data.ejectedStatus &&
												<div className="btnPartCh bookedBtn" onClick={(e)=>{this.openCancel(e, data.id)}} >Booked!</div> 
											}
											{!data.alreadyMember && !data.ejectedStatus &&
												<div className="btnPartCh" onClick={(e) => this.openSessionUrl(e, data.sessionLink)} >Book</div>
											}
										</div>
										<div className={classnames("sesArrow "+ (data.id == this.props.state.sessionDetailsId ? "active" : "" ))} onClick={ (e)=>{this.openSessionDetails(e, data.id)}}>
											{ data.id == this.props.state.sessionDetailsId ?
												<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1 4.5L7.96317 1.01841L14.9263 4.5" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
												:
												<svg width="16" height="6" viewBox="0 0 16 6" fill='none' xmlns="http://www.w3.org/2000/svg">
													<path d="M15 1L8.03683 4.48159L1.07366 1" stroke={this.props.state.imgFillColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											}
										</div>
									</div>
									{ data.id == this.props.state.sessionDetailsId &&
										<div className="bottomPartCh" >
											<div className="bottomPart1Ch" >
												<div className="descBottom1" >Description</div>
												<div className="descDataBottom" >{data.description}</div>
												<div className="hostBtmMarg" >
													<div className="hostImgBtm" style={{ backgroundImage: data.hostImage != '' ? "url("+data.hostImage+")" : "url('/images/pic.png')"}}  ></div>
													<div className="hostBottomPart" >{data.firstName} {data.lastName}</div>
												</div>
												<div className="instrucotrBioCh" dangerouslySetInnerHTML={{__html: data.instructorText}}></div>
											</div>
											<div className="bottomPart2Ch" >
												<div className="descBottom1" >Categories</div>
												{  data.mainIntrestArray && data.mainIntrestArray.map((inte, c)=>
													<div className="interestMap" key={c} >{inte}</div>
												)}
											</div>
											<div className="bottomPart2Ch" >
												<div className="descBottom1" >Required Equipment</div>
												<div className="euipmentHolderScroll" >
													{  data.equipmentList && data.equipmentList.map((eq, e)=>
														<div className="interestMap" key={e} >{eq}</div>
													)}
												</div>
											</div>
										<div className="btnsBottomHolder" >
											<div className="signedUpCh" >
											<div className="actPopPart singedUpChSvg" >
												<svg width="21" height="21" viewBox="0 0 21 21" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
													<path d="M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H14C14 19.4087 13.3679 17.8826 12.2426 16.7574C11.1174 15.6321 9.5913 15 8 15C6.4087 15 4.88258 15.6321 3.75736 16.7574C2.63214 17.8826 2 19.4087 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10ZM16.284 13.703C17.6893 14.3359 18.882 15.3612 19.7186 16.6557C20.5552 17.9502 21.0002 19.4587 21 21H19C19.0002 19.844 18.6666 18.7125 18.0391 17.7416C17.4116 16.7707 16.5171 16.0017 15.463 15.527L16.283 13.703H16.284ZM15.596 2.413C16.6035 2.8283 17.465 3.53354 18.071 4.43923C18.6771 5.34492 19.0004 6.41024 19 7.5C19.0004 8.87233 18.4877 10.1952 17.5625 11.2088C16.6374 12.2224 15.3667 12.8535 14 12.978V10.965C14.7409 10.8589 15.4283 10.518 15.9613 9.99246C16.4943 9.4669 16.8447 8.78432 16.9612 8.04493C17.0776 7.30555 16.954 6.5483 16.6084 5.88435C16.2628 5.22041 15.7134 4.68475 15.041 4.356L15.596 2.413Z" />
												</svg>
											</div>
											<div className="actPopPart" >{data.singedUpUsers}/{data.maxAttendee} joined</div>
											</div>
											{data.parentRecrId>0 &&
												<div className="signedUpCh" >
													<div className="actPopPart singedUpChSvg" >
														<svg width="20" height="22" viewBox="0 0 20 22" fill={this.props.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
															<path d="M6 19.0002V20.9322C6.00015 21.0273 5.97319 21.1205 5.92227 21.2008C5.87134 21.2811 5.79858 21.3452 5.7125 21.3856C5.62642 21.426 5.53061 21.4411 5.43629 21.4289C5.34197 21.4168 5.25307 21.3781 5.18 21.3172L1.06 17.8842C0.980978 17.8183 0.92421 17.7296 0.89744 17.6303C0.87067 17.5309 0.875202 17.4257 0.910418 17.3291C0.945633 17.2324 1.00982 17.1489 1.09421 17.0901C1.17861 17.0312 1.27911 16.9998 1.382 17.0002H16C16.5304 17.0002 17.0391 16.7895 17.4142 16.4144C17.7893 16.0393 18 15.5306 18 15.0002V7.0002H20V15.0002C20 16.0611 19.5786 17.0785 18.8284 17.8286C18.0783 18.5788 17.0609 19.0002 16 19.0002H6ZM14 3.0002V1.0682C13.9998 0.973104 14.0268 0.87994 14.0777 0.799631C14.1287 0.719323 14.2014 0.6552 14.2875 0.614786C14.3736 0.574372 14.4694 0.559342 14.5637 0.571459C14.658 0.583576 14.7469 0.622337 14.82 0.683196L18.94 4.1162C19.019 4.18204 19.0757 4.27062 19.1025 4.36987C19.1293 4.46912 19.1248 4.57422 19.0898 4.67086C19.0547 4.76749 18.9906 4.85096 18.9064 4.90989C18.8222 4.96882 18.7218 5.00036 18.619 5.0002H4C3.46957 5.0002 2.96086 5.21091 2.58579 5.58598C2.21071 5.96106 2 6.46976 2 7.0002V15.0002H0V7.0002C0 5.93933 0.421427 4.92191 1.17157 4.17177C1.92172 3.42162 2.93913 3.0002 4 3.0002H14Z" />
														</svg>
													</div>
													<div className="actPopPart" >Recurring</div>
												</div>
											}
											{data.activityArray.length > 0 &&
												<div className="act_btn_channel" onClick={(e)=>{this.props.openActivityList(e, data)}} >View Activities</div>
											}
										</div>
										</div>
									}
								</div>
							)}
						</div>
					)}
					{this.props.state.upcomingLength > this.props.state.currentDataLength &&
						<div className="loadMoreHolderCh" >
								<div className="btnPartCh" onClick={(e)=>{this.props.loadMoreUpcoming(e)}} >Load more</div>
						</div>	
					}	
				</div>
		</div>
	</div>
    );
  }
}
export default ChannelUpcoming;
