import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import LineChart from "../graphs/LineChart";
import Label from "../graphs/AxisLabel";
import ChartTitle from "../graphs/ChartTitle";
import moment from 'moment';

class Graphs extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		dataMain: [ 
					[
						{ label: "20:05", x: 0, y: 0 },
						{ label: "20:10", x: 1, y: 200 },
						{ label: "20:15", x: 2, y: 400 },
						{ label: "20:20", x: 3, y: 200 },
						{ label: "20:25", x: 4, y: 450 },
						{ label: "20:30", x: 5, y: 800 },
						{ label: "20:35", x: 6, y: 1200 }
					], 
					[
						{ label: "20:05", x: 0, y: 0 },
						{ label: "20:10", x: 1, y: 400 },
						{ label: "20:15", x: 2, y: 200 },
						{ label: "20:20", x: 3, y: 700 },
						{ label: "20:25", x: 4, y: 450 },
						{ label: "20:30", x: 5, y: 1000 },
						{ label: "20:35", x: 6, y: 200 }
					], 
					[
						{ label: "20:05", x: 0, y: 200 },
						{ label: "20:10", x: 1, y: 700 },
						{ label: "20:15", x: 2, y: 400 },
						{ label: "20:20", x: 3, y: 300 },
						{ label: "20:25", x: 4, y: 850 },
						{ label: "20:30", x: 5, y: 800 },
						{ label: "20:35", x: 6, y: 1200 }
					], 
					[
						{ label: "20:05", x: 0, y: 0 },
						{ label: "20:10", x: 1, y: 500 },
						{ label: "20:15", x: 2, y: 350 },
						{ label: "20:20", x: 3, y: 100 },
						{ label: "20:25", x: 4, y: 650 },
						{ label: "20:30", x: 5, y: 800 },
						{ label: "20:35", x: 6, y: 600 }
					]
				],
				
				jsonData : {
								"code": 200,
								"message": "",
								"call_id": "00057b6093a5d3e8b1fc67aa",
								"call_info": [
									{
										"sid": "EDB224CCF4FB4F99815C24302BDF3301",
										"cname": "15056678066620",
										"uid": 630985881,
										"network": "Wi-Fi",
										"platform": "Android",
										"speaker": true,
										"sdk_version": "2.2.0.07_14",
										"device_type": "Android (6.0)",
										"join_ts": 1548670251,
										"leave_ts": 1548670815,
										"finished": true
									},
									],
								"metrics": [
									{
										"sid": "EDB224CCF4FB4F99815C24302BDF3301",
										"data": [
											{
												"mid": 20003,
												"kvs": [
													[
														1548670255,
														150
													],
													[
														1548670257,
														120
													],
													[
														1548670259,
														190
													],
												],
												"peer_uid": 0,
											},
										]
									},
							]
						},		
		styles: {
					  chartComponentsContainer: { display: 'grid', gridTemplateColumns: 'max-content 100%', alignItems: 'center' },
					  chartWrapper: { maxWidth: '100%', alignSelf: 'flex-start' }
				},
		dataGraph: [],		
		dataGraphFinal: []		
	};
}
 
componentDidMount(){
/* 	console.log('DATA');
	console.log(this.state.data);
	var dataMainArray = this.state.dataMain;
	
	console.log(dataMainArray); */
	console.log(this.state.jsonData);
	console.log(this.state.jsonData['metrics'][0]['data'][0]);
	this.filterData();
	
	};

filterData = e =>
{
	let dataMainArray = [];
	let dataGraphFinalArray = [];
		
	var grph = '';
	for ( var c = 0; c<this.state.jsonData['metrics'][0]['data'].length; c++)
		{
			dataMainArray = [];
			var i = 0;
			for ( grph of  this.state.jsonData['metrics'][0]['data'][c]['kvs'])
				{
					let grphTime = moment(grph[0]).format('HH:mm');
					let grphInfo = { label: grphTime, x: i, y: grph[1] };
					dataMainArray.push(grphInfo);
					i++;
				}
		dataGraphFinalArray.push(dataMainArray);
		}		
	this.setState({
		dataGraph: dataMainArray,
		dataGraphFinal : dataGraphFinalArray
	});
}

  render() {
	 return (
	
		<div>
		 {
			<div className="custom-width mx-auto customer_supp_page">
				<div className="outer-container">
					<div className="container-fluid px-3 px-md-5 mt30 mb30">
						{ this.state.dataGraphFinal.length > 0 &&
							<div className="for_graphs_inner">
								{ this.state.dataGraphFinal.map((item, key) => 
									<div key={key} className="one_graph">
										<div style={this.state.styles.chartComponentsContainer}><div/>
											<ChartTitle text="" />
											<Label text="" rotate/>
											<div style={this.state.styles.chartWrapper}>
												<LineChart
												  width={500}
												  height={500}
												  data={item}
												  horizontalGuides={5}
												  precision={2}
												  verticalGuides={1}
												/>
											</div>
											<Label text=""/>
										</div>
									</div>
								)}
							</div>
						}
						{this.state.jsonData['metrics'][0]['data'].length > 0 &&
							<div className="for_graphs_inner">
								{ this.state.jsonData['metrics'][0]['data'].map((item, key) => 
									<div key={key} className="one_graph">
										
									</div>
								)}
							</div>
						}
					</div>
				</div>
			</div>
			}
		</div>	
		
    );
  }
}
export default Graphs;
