import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import Calendar from 'react-calendar';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import { deleteCookie } from '../auth/Logout';
import SupportContainer from '../home/SupportContainer';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';


class SessionTemplates extends Component {
  
  constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state={
        sessionData:[],
        firstName:'',
        firstName1:'',
        lastName:'',
        email:'',
        memberId:'',
        msgSuccess:'',
        getMemberId:0,
        hostName:'',
		endDateTemp: new Date(),
		enddate: '',
		limitedMember: false,
		importStatus: '',
		enableImport: false,
		userImports: [],
		userAvatar: '',
		channelCreateUser: false,
		mainFaqs: [],
		searchBy: '',
		sessions: [],
		curentTemplate : 0,
		supportPageName: 'Session Templates',
		supportPageApi: '/SessionTemplates',
		userTypeFaqs: 2
    
    }
    this.validator = new SimpleReactValidator();  
    this.editValidator = new SimpleReactValidator();  
    this.deleteCookie = deleteCookie.bind(this);
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}
 
componentDidMount(){
	this.fetchPrevSessionList();
	this.setState({userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image});
    let hostName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName
    this.setState({
        hostNameData:hostName
    });

  };
 
fetchPrevSessionList() {
    
      let channelId=localStorage.getItem("channelId");  
	  let searchText = this.state.searchBy;

      let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	  $('.main_loader').attr({'style':'display:flex'});
      axios.defaults.headers.common['Authorization'] = token;              
		axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getPreviousSessionsByChannel", { channel_id: channelId, searchText: searchText, count_show: 1000, type: 1 })          
        .then(res => {
		   $('.main_loader').hide();
           this.setState({
			  sessions: res.data.responseData.sessionData
              });
          })
        .catch(err =>{
		  console.log('----------there is problem------------', err);
		   $('.main_loader').hide();
        });
}
	
getFaq=(e)=>{
	 axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqsById", {id: e})          
	.then(res => {
		this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs)});
	})
	.catch(err =>{ });  
}

openPreviews=(pg)=>{
	let mainUrl = mainUrl = 'https://'+this.state.fullsubdomain + "/#"+btoa(JSON.stringify({backroundColorPick: this.state.backroundColorPick, fontColorPick: this.state.fontColorPick, fontTitleColorPick: this.state.fontTitleColorPick, buttonsColorPick: this.state.buttonsColorPick, highlightColorPick: this.state.highlightColorPick, searchBackground: this.state.searchBackground, searchFont: this.state.searchFont, searchInputBackground: this.state.searchInputBackground, searchInputBorder: this.state.searchInputBorder}));
	
	window.open(mainUrl);
}
showNewPop = (mod) => 
	{
		$("#delete_template").attr({'style':'display:block'});
		this.setState({
			curentTemplate : mod['id']
		});
	}
deleteTemplate = e =>
{
	console.log(this.state.curentTemplate);
	$('.main_loader').attr({'style':'display:flex'});
		axios.post(process.env.REACT_APP_NAME+"/api/v1/session/delete_template", {template_id : this.state.curentTemplate})          
        .then(res => {
		   $("#delete_template").attr({'style':'display:none'});
		  this.fetchPrevSessionList();
          })
        .catch(err =>{
		  console.log('----------there is problem------------', err);
		   $('.main_loader').hide();
        });
}	

openTemplateEdit=(id)=>{
	browserHistory.push("/fitnessdetail/"+id+"/#fromTemplate"); 
}

  render() {
	 return (
        <div>
            <div id="root">
                <div className="App">
                    <div className="container-fluid custom-width pb-5">
                        <div className="row top-header px-2 px-md-4 py-4">
                            <div className="col-lg-6 flex-wrap d-flex justify-content-center justify-content-lg-start p-2">
                            <div className="align-self-center">
                                <img src="/images/login-logo.png" className="logo my-2 cursor-pointer" alt="logo" onClick={e=>{browserHistory.push('/DashboardLanding')}}/>
                            </div>
                            <div className="d-flex d-md-block justify-content-center px-2 px-md-4 py-2">
                                <div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
                                <div className="pl-4">
                                <h3>Welcome {this.state.hostNameData}!</h3>
                                     {/* <p>No Class coming up this week</p> */}
                                    {/* <p>Next Class, Wednesday, 24 July 2019</p> */}
                                </div>
                                </div>
                            </div>
                            </div>
                             <div className="col-lg-6 ">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Average Attendance</p>
                                        <h3>0%</h3>
                                    </div>
                                    <span className=""></span>
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Views</p>
                                        <h3>0</h3>
                                    </div>
                                    {/* <span className="border-right gray-border"></span> */}
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Revenue</p>
                                        <h3>$0</h3>
                                    </div>
                                    {/* <span className="border-right gray-border"></span> */}
                                    <div className="message-notification mx-2 d-none">
                                        <img src="/images/message.png" alt = '#'/>
                                        <span className="message-count">0</span>
                                    </div>
                                    <button className="btn btn-outline-secondary font-weight-bold radius-8  align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}}>LOG OUT</button>
                                </div>
                            </div> 
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center flex-wrap">
                            <h4 className="text-white font-22 pt-1 pl-2 mb-2">Manage Member</h4>
                        </div> */}
                        <div className="gray-box2 radius-10 member-sec pb-5 relative">
                            <Link to='/DashboardLanding'>
								<div className="closePopup mainclose_position"></div>
							</Link>
                            <div className="d-flex justify-content-between align-items-center px-4 pb-4 border-bottom">
                                <div className="mt-4">
                                    <h4 className="text-white d-inline-block font-22 pt-1 pl-2 mb-0 membertopheader">Class Templates</h4>
									<Link onClick={e=>browserHistory.push('/FitnessSessionCreation#fromTemplate')} className="pick ml-4 pointer"><img src="/images/add.png" alt=""/></Link>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center px-4 pb-4 flex-wrap">
                                <div className="mt-4 col-md-7 px-md-0 d-flex align-items-center">
                                    {/* <p className="text-white mb-0 font-18">Show</p>
                                    <select className="select_input-field mx-3" id="exampleFormControlSelect1">
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                    </select>
                                    <p className="text-white mb-0 font-18">Members</p> */}
                                </div>
                                <div className="mt-4 col-md-5 px-md-0">
                                    <div className="input-group mb-3 mt-4">
                                        <input type="text" className="form-control shadow-effect" placeholder="Search for Instructor and Class Name" name="" onChange={(e) => this.setState({ searchBy: e.target.value })} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <a href="javascript:void(0)" onClick={()=>{this.fetchPrevSessionList()}}><img src="/images/search.png" alt="search" /></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-md-4 membertable add_scroll_mem">
                                <div className="row mx-0 px-md-4 border-bottom">
                                    <div className="col-6 pl-sm-2 table_name_hold">
                                        <p className="text-white font-18">Name</p>
                                    </div>
									{ /* <div className="col-2 table_name_hold">
                                        <p className="text-white text-center font-18">Interest</p>
                                    </div> */ }
                                    <div className="col-6 table_name_hold">
                                        <p className="text-white text-center font-18">Instructor</p>
                                    </div>
                                    <div className="col-6 table_name_hold">
                                        <p className="text-white text-center font-18">Created Date</p>
                                    </div>
                                    <div className="col-6 table_name_hold">
                                        <p className="text-white text-center font-18">Duration</p>
                                    </div>
                                </div>
                                { this.state.sessions.length > 0 &&	
									(this.state.sessions.map((row,i)=>
										<div className="row mx-0 px-md-4 py-4" key={i}>
									   
											<div className="col-6 pl-sm-2 table_name_hold">
												<div className="d-flex">
													<p className="mb-0">{row.name}</p>
												</div>
											</div>
											
											{ /* <div className="col-2 d-flex justify-content-center align-items-center table_name_hold">
													<p className="mb-0">{row.interest_titles}</p>
											</div>  */ }
											
											<div className="col-6 d-flex justify-content-center align-items-center table_name_hold">
											   <p className="mb-0">{row.hostName}</p>
											</div>                                

										<div className="col-6 d-flex justify-content-center align-items-center table_name_hold">
											<p className="mb-0">{ this.getSessionDate(row.createdAtNotFormated)} @ {this.getSessionTime(row.createdAtNotFormated)}</p>
										</div>
										
										<div className="col-6 d-flex justify-content-center align-items-center table_name_hold">
											<p className="mb-0">{row.duration}</p>
										 </div>
										<div className="bg-circle mr-1 mr-lg-2 mb-1 pointer" onClick={() => this.openTemplateEdit(row.id)}>
											<i className="fa fa-pencil"></i>
										</div>
										<div className="bg-circle mr-1 mr-lg-2 mb-1 pointer" onClick={ e => {this.showNewPop(row)}} >
											<i className="fa fa-minus" aria-hidden="true"></i>
										</div>
										</div>
									))
								}
								{ this.state.sessions.length === 0 &&	
									<div className="empty_template">No results found.</div>
								}
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>			
	    
			<div className="modal pr-0" id="delete_template" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent relative">
						<button type="button" className="close white closepurple translatX-Y abs_close" onClick={e=>$("#delete_template").attr({'style':'display:none'})}><span>x</span></button>
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Are you sure you want to delete Template?</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={this.deleteTemplate}>Delete</button></div>
					</div>
				</div>
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(SessionTemplates);
