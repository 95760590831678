import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
class ReportsOvercharge extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					allPlanData: [],
					allOvercharged: [],
					addOvercahrePop: false,
					choosenPlan: 2,
					choosenChargeType: 'participants',
					choosenChargeNumber: 0,
					choosenChargePrice: 0,
					openEditPop: false,
					overage_id: 0,
					showConfPopup: false,
					showConfDelPopup: false
				}
}

componentDidMount(){
	
	this.getAllPlans();
	this.getAllOvercharged();
	
}

getAllPlans =(e)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getPlans", {})          
	.then(res => {
		this.setState({
			allPlanData: res.data.responseData
		});
		})
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	});
}

getAllOvercharged =(e)=>{
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/getOvercharged", {})          
	.then(res => {
		this.setState({
			allOvercharged: res.data.responseData
		});
		
		$('.main_loader').hide();
		})
	.catch(err =>{
	  $('.main_loader').hide();
	  console.log('----------there is problem------------', err);
	});
}

switchPopAction = (e)=>{
	this.setState({ 
		addOvercahrePop : e == 1 ? true : false,
		openEditPop: false,
		choosenPlan: 2,
		choosenChargeType: 'participants',
		choosenChargeNumber: 0,
		choosenChargePrice: 0
	});
}

openEditPop = (planId, chargeType, overcharge, overchargePrice, overcharge_id)=>{
	this.setState({ addOvercahrePop : true, openEditPop: true, choosenPlan: planId, choosenChargeType: chargeType, choosenChargeNumber: overcharge, choosenChargePrice: overchargePrice, overage_id: overcharge_id });
}

saveOvercharge = ()=>{
	console.log(this.state.choosenPlan, this.state.choosenChargeType, this.state.choosenChargeNumber, this.state.choosenChargePrice);
	
	if(this.state.choosenChargeNumber == '' || this.state.choosenChargeNumber == 0)
		{
			this.props.showMainErrorPop('Overage Number cannot be empty or 0');
			return false;
		}
		
	if(this.state.choosenChargePrice == '' || this.state.choosenChargePrice == 0)
		{
			this.props.showMainErrorPop('Overage Price cannot be empty or 0');
			return false;
		}	
		
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/insertCharge", {planId: this.state.choosenPlan, chargeType: this.state.choosenChargeType, chargeNumber: this.state.choosenChargeNumber, chargePrice: this.state.choosenChargePrice})          
	.then(res => {
		$('.main_loader').hide();
		this.setState({ 
			addOvercahrePop: false,
			choosenPlan: 2,
			choosenChargeType: 'participants',
			choosenChargeNumber: 0,
			choosenChargePrice: 0
		});
		
		this.getAllOvercharged();
		
		})
	.catch(err =>{
	  $('.main_loader').hide();
	  console.log('----------there is problem------------', err);
	});
}

editOverage = (e)=>{
	if(e == 0)
		{
			this.setState({ showConfPopup: true });
		}
	
	else
		{
			this.setState({ showConfPopup: false });
			
			if(this.state.choosenChargeNumber == '' || this.state.choosenChargeNumber == 0)
				{
					this.props.showMainErrorPop('Overage Number cannot be empty or 0');
					return false;
				}
				
			if(this.state.choosenChargePrice == '' || this.state.choosenChargePrice == 0)
				{
					this.props.showMainErrorPop('Overage Price cannot be empty or 0');
					return false;
				}	
			
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/updateOverage", {planId: this.state.choosenPlan, chargeType: this.state.choosenChargeType, chargeNumber: this.state.choosenChargeNumber, chargePrice: this.state.choosenChargePrice, overcharge_id: this.state.overage_id})          
			.then(res => {
				
				$('.main_loader').hide();
				this.setState({ 
					addOvercahrePop: false,
					choosenPlan: 2,
					choosenChargeType: 'participants',
					choosenChargeNumber: 0,
					choosenChargePrice: 0,
					overage_id: 0
				});
				
				this.getAllOvercharged();
				
				})
			.catch(err =>{
			  $('.main_loader').hide();
			  console.log('----------there is problem------------', err);
			});
		}
}

openDeletePop = (e)=>{
	this.setState({ showConfDelPopup: true, overage_id: e });
}

deleteOverage = ()=>{
		this.setState({ showConfDelPopup: false });
		$('.main_loader').attr({'style':'display:flex'});
		axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/deleteOverage", {overage_id: this.state.overage_id})
		.then(res => {
			
			$('.main_loader').hide();
			this.setState({ 
				addOvercahrePop: false,
				choosenPlan: 2,
				choosenChargeType: 'participants',
				choosenChargeNumber: 0,
				choosenChargePrice: 0,
				overage_id: 0
			});
			
			this.getAllOvercharged();
			
			})
		.catch(err =>{
		  $('.main_loader').hide();
		  console.log('----------there is problem------------', err);
		});
}

handleFilterChange = e => {
	this.setState({
		[e.target.id]: e.target.value
	},()=>{
		if(this.state.choosenPlan == 2 || this.state.choosenPlan == 3 || this.state.choosenPlan == 4)
			{
				this.state.choosenChargeType = 'participants';
			}
			
		else
			{
				this.state.choosenChargeType = 'clients';
				$('#choosenChargeType').val('clients');
			}
	});	
}

  render() {
	
    return (
		<div>
			<div className="pay_main"> 
				<div className="container-fluid payinfo_main_hold">
					<div className="main_part_pg">
					<div className="channel_topscroll">
						<div className="channel_subscroll">
							<div className="pay_head">
								<div className="month_h1" onClick={e=>{this.props.changeDailyComp({billingPlan: true}, 1)}}>Billing Plan</div>
								<div className="month_h1" onClick={e=>{this.props.changeDailyComp({channelPlan: true}, 1)}}>Channel Plan</div>
								<div className="month_h1 active_tab" onClick={e=>{this.props.changeDailyComp({overCharge: true}, 1)}}>Tiered Overages</div>
								<div className="month_h1" onClick={e=>{this.props.changeDailyComp({brandAmbassador: true}, 1)}}>Ambassador</div>
							</div>
							<div className="pay_head_title">
								<div className="overcharge_title">Tiered Overages</div>
								<div className="overcharge_title pointer" onClick={()=>{this.switchPopAction(1)}}><img src="/images/add.png" alt="" /></div>
							</div>
							{ this.state.allOvercharged.length > 0 ?
								<table className="billing_table overcharge_table">
									<thead>
										<tr>
											<th>Plan Id</th>
											<th>Plan Type</th>
											<th>Charge Type</th>
											<th>Overage Number</th>
											<th>Overage Price</th>
											<th>Edit</th>
											<th>Delete</th>
										</tr>
									</thead>
									<tbody> 
										{ this.state.allOvercharged.map((item, key) =>
											<tr key={key}>
												<td>{ item['planId'] }</td>
												<td>{ item['planName'] } ({ item['planType'] === 'live' ? 'Live' : 'On-Demand' })</td>
												<td>{ item['chargeType'] === 'participants' ? 'Participants' : item['chargeType'] === 'clients' ? 'Clients' : 'Recordings' }</td>
												<td>{ item['overcharge'] }</td>
												<td>${ item['overchargePrice'] }</td>
												<td>
													<div className="overage_edit_button" onClick={()=>{this.openEditPop(item['planId'], item['chargeType'], item['overcharge'], item['overchargePrice'], item['overcharge_id'])}}><img src="../images/edit.png" alt="" /></div>
												</td>
												<td>
													<div className="bg-circle pointer overcharge_circle" onClick={()=>{this.openDeletePop(item['overcharge_id'])}}><i className="fa fa-minus" aria-hidden="true"></i></div>
												</td>
											</tr>
										) }
									</tbody>
								</table>
								:
								<div className="no_results_overage">There are no Tiered Overages, click on the '+' icon to add a new one</div>
							}
						</div>
					</div>
					</div>
				</div>
			</div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			{ this.state.addOvercahrePop &&
				<div className="modal pr-0 dspl_blck" id="interests_pop" aria-modal="true">
					<div className="modal-dialog mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="all_intpop">
								<div className="closePopup mainclose_position" onClick={()=>{this.switchPopAction(2)}}></div>
								<div className="inter_title">{this.state.openEditPop ? 'Edit a Tiered Overage' : 'Add a Tiered Overage'}</div>
								<div className="add_overcharge_inner">
									<div className="overcharge_hold">
										<div className="form-group">
											<span className="cover-border"></span>
											<label className="label">Select Plan</label>
											<select id="choosenPlan" value={this.state.choosenPlan} className="input-field channel_choose_search layout_border search_font" onChange={this.handleFilterChange} >
												{ this.state.allPlanData && this.state.allPlanData.map((item, key) => 
													<option value={item['id']} key={key}>{item['planName']} ({item['planType'] === 'live' ? 'Live' : 'On-Demand'})</option>
												)}
											</select>
										</div>
									</div>
									<div className="overcharge_hold">
										<div className="form-group">
											<span className="cover-border"></span>
											<label className="label">Charge Type</label>
											{this.state.choosenPlan == 2 || this.state.choosenPlan == 3 || this.state.choosenPlan == 4 ?
												<select id="choosenChargeType" value={this.state.choosenChargeType} className="input-field channel_choose_search layout_border search_font" onChange={(e)=>this.setState({[e.target.id]:e.target.value})} >
													<option value='participants'>Member</option>
												</select>
												:
												<select id="choosenChargeType" value={this.state.choosenChargeType} className="input-field channel_choose_search layout_border search_font" onChange={(e)=>this.setState({[e.target.id]:e.target.value})} >
													<option value='clients'>Client</option>
													<option value='recordings'>Recording</option>
												</select>
											}
										</div>
									</div>
									<div className="overcharge_hold">
										<div className="form-group">
											<span className="cover-border"></span>
											<label className="label">Overcharge Number</label>
											<input id="choosenChargeNumber" type="number" value={this.state.choosenChargeNumber} className="input-field channel_choose_search layout_border search_font overcharge_input" onChange={(e)=>this.setState({[e.target.id]:e.target.value})} 
											/>
										</div>
									</div>
									<div className="overcharge_hold">
										<div className="form-group">
											<span className="cover-border"></span>
											<label className="label">Overcharge Price ($)</label>
											<input id="choosenChargePrice" type="number" value={this.state.choosenChargePrice} className="input-field channel_choose_search layout_border search_font overcharge_input" onChange={(e)=>this.setState({[e.target.id]:e.target.value})} 
											/>
										</div>
									</div>
								</div>
								<div className="text-center">
									{!this.state.openEditPop ?
										<button type="button" className="custom_btn1 mt-3" onClick={()=>{this.saveOvercharge()}}>SAVE</button>
										:
										<button type="button" className="custom_btn1 mt-3" onClick={()=>{this.editOverage(0)}}>SAVE</button>
									}
								</div>
							</div>
						</div>
						</div>
				</div>
			}
			
			{this.state.showConfPopup &&
				<div className="modal pr-0 dspl_blck" id="change_statuspop" aria-modal="true">
					<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
								<div className="py-4 text-light text-center font-20">Are you sure you want to edit this Overage?</div> 	
							</div>
							<div className="text-center">
								<button type="button" className="custom_btn1 mt-3" onClick={()=>{this.editOverage(1)}}>Yes</button>
								<button type="button" className="custom_btn1 mt-3 ml-20" onClick={e=>{this.setState({showConfPopup: false})}}>No</button>
							</div>
						</div>
					</div>
				</div>
			}
			
			{this.state.showConfDelPopup &&
				<div className="modal pr-0 dspl_blck" id="change_statuspop" aria-modal="true">
					<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
								<div className="py-4 text-light text-center font-20">Are you sure you want to delete this Overage?</div> 	
							</div>
							<div className="text-center">
								<button type="button" className="custom_btn1 mt-3" onClick={()=>{this.deleteOverage()}}>Yes</button>
								<button type="button" className="custom_btn1 mt-3 ml-20" onClick={e=>{this.setState({showConfDelPopup: false})}}>No</button>
							</div>
						</div>
					</div>
				</div>
			}
			
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ReportsOvercharge);