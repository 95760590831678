import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import axios from "axios";
class MainSignUp extends Component {
  constructor(props) {
    super(props);
	this.state = {
		showHelpPopup: false,
		passMinError: false,
		specialSymbol: false,
		numberContain: false,
		letterContain: false,
		passwordEye: true,
		passwordEyeRepeat: true,
		errorFirstName: false,
		errorLastName: false,
		errorEmail: false,
		passwordStatus: false,
		rePasswordStatus: false,
	}
	
	this.validator = new SimpleReactValidator({autoForceUpdate: this});
	this.otpValidation = new SimpleReactValidator();
	this.emailValidate  = new SimpleReactValidator();
  }

passwordEyeCheck=(e, a)=>{
	if(a == 'pass')
		{
			if(e == 1)
				{
					this.setState({
						passwordEye: false
					});
				}
			
			else if(e == 0)
				{
					this.setState({
						passwordEye: true
					});
				}
		}
		
	else
		{
			if(e == 1)
					{
						this.setState({
							passwordEyeRepeat: false
						});
					}
				
				else if(e == 0)
					{
						this.setState({
							passwordEyeRepeat: true
						});
					}
		}
	
}
showHelpPopupFun=(e)=>{
	this.setState({
		showHelpPopup:e
	});
}
checkValidation=(e)=>{
	let showHelp = this.state.showHelpPopup;
	
	let checking = this.checkPassword(this.props.state.password);
	
	this.setState({
		showHelpPopup:true
	});
	if ( checking )
		{
			let mainthis = this;
			setTimeout(function(){ 
				mainthis.setState({
					showHelpPopup: false
				});
			}, 1000);

		}
}	

resend=(e)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : this.props.state.emailSign})
    .then(res => {
			if( res.data.responseMessage == "success" )
				{
					this.props.showMainErrorPop("Your verification has been resent to email address");
				}
			else
				{
					this.setState({
						msg:''
					}) 
				}
    }).catch(err =>{
		this.setState({
			msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
		}) 
    });
}

checkPassword=(pass)=>{
	let checkAll = false;
	var regexcheck = /[!@#$%_+:;'"~`.,?|\^&*(){}[\]<>?/|\-]/;
	var contains_number = /\d+/;
	var Regex = /^[^a-zA-Z]*$/;
	
	this.setState({
		contain8Ch: false,
		specialSymbol: false,
		numberContain: false,
		letterContain: false
	});
	
	if ( !Regex.test(pass) ) 
		{ 
			this.setState({
				letterContain: true
			});
		}
	
	if ( pass.length >= 8 )
		{
			this.setState({
				contain8Ch: true
			});
		}
	
	if ( regexcheck.test(pass) )
		{
			this.setState({
				specialSymbol: true
			});
		}
		
	if ( contains_number.test(pass) )
		{
			this.setState({
				numberContain: true
			});
		}
		
	if ( pass != '' && pass.length >= 8 && regexcheck.test(pass) && contains_number.test(pass) && !Regex.test(pass) )
		{
			checkAll = true;
		}
		
	return checkAll;
}

removeEmailErroe = (e) =>
{
	this.props.updateStateFromChild({
		[e.target.id]:e.target.value,
		errorClass_Email:false
	})
}

checkValues = (e) =>{
	if (this.validator.allValid())
		{
			/* if (!this.props.state.firstName)
				{
					this.props.updateStateFromChild({errorClass_FirstName: true});
				}
			if (!this.props.state.lastName)
				{
					this.props.updateStateFromChild({errorClass_LastName: true});
				}
			if (!this.props.state.emailSign)
				{
					this.props.updateStateFromChild({errorClass_Email: true});
				}
			if (!this.props.state.password)
				{
					this.props.updateStateFromChild({errorClass_Password: true});
				}
			if (!this.props.state.rePassword)
				{
					this.props.updateStateFromChild({errorClass_RePassword: true});
				}

			if ( this.props.state.firstName && this.props.state.lastName && this.props.state.emailSign && this.props.state.password && this.props.state.rePassword)	
				{
					this.props.verify();
				} */
			this.props.verify();
		}
	else
		{
			if ( !this.validator.fieldValid('firstName') ) { if(!this.validator.getErrorMessages().firstName) { this.props.updateStateFromChild({errorClass_FirstName: false}); } else { this.props.updateStateFromChild({errorClass_FirstName: true}); }}
			if ( !this.validator.fieldValid('lastName') ) { if(!this.validator.getErrorMessages().lastName) { this.props.updateStateFromChild({errorClass_LastName: false}); } else { this.props.updateStateFromChild({errorClass_LastName: true}); }}
			if ( !this.validator.fieldValid('emailSign') ) { if(!this.validator.getErrorMessages().emailSign) { this.props.updateStateFromChild({errorClass_Email: false}); } else { this.props.updateStateFromChild({errorClass_Email: true}); }}
			if ( !this.validator.fieldValid('password') ) { if(!this.validator.getErrorMessages().password) { this.props.updateStateFromChild({errorClass_Password: false}); } else { this.props.updateStateFromChild({errorClass_Password: true}); }}
			if ( !this.validator.fieldValid('rePassword') ) { if(!this.validator.getErrorMessages().rePassword) { this.props.updateStateFromChild({errorClass_RePassword: false}); } else { this.props.updateStateFromChild({errorClass_RePassword: true}); }}
			this.validator.showMessages();
			this.forceUpdate();
		}
}

render() {
   
			return (
				<div>
					<div>
						{ !this.props.state.verify && 
							<div className="for_main_form formReg">
								<div className="gray-box-4 px-2 px-sm-4 pt-4 pb-0 input_bx-cont for_sign relative">
									<div className="paymentSignUp">Create Account</div>
									<button type="button" className="close white closepurple translatX-Y abs_close" onClick={this.props.closeSign}><span>x</span></button>
									<div className="form-group">
										<label className="label">Enter First Name<span className="inp_cover-border"></span></label>
										<input type="text" 
										className={(this.props.state.errorClass_FirstName?"input-field paddr45 red-outline":"input-field paddr45")} 
										value={this.props.state.firstName} 
										ref={fname=> this.props.updateRefState(fname, 'firstName')} 
										id ='firstName' 
										onChange={(e)=>{
										  this.props.updateStateFromChild({
											[e.target.id]:e.target.value,
											errorClass_FirstName:false
										  })}}  />
										{this.validator.message('firstName', this.props.state.firstName, 'required|min:1|max:30')}
										<span className="signedup_2 signedup_2_svg">
											{this.props.state && this.props.state.searchFont ?
												<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" ><g><g><path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"/></g></g></svg>					
													:
												<svg version="1.1" fill="#b002ed" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" ><g><g><path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"/></g></g></svg>					
											}
										</span>
									</div>
									<div className="form-group">
										<label className="label">Enter Last Name<span className="inp_cover-border"></span></label>
										<input type="text" 
										className={(this.props.state.errorClass_LastName?"input-field paddr45 red-outline":"input-field paddr45")} 
										value={this.props.state.lastName} 
										ref={lname=> this.props.updateRefState(lname, 'lastName')} 
										id ='lastName' 
										onChange={(e)=>{
										  this.props.updateStateFromChild({
											[e.target.id]:e.target.value,
											errorClass_LastName:false
										  })}}/>
										{this.validator.message('lastName', this.props.state.lastName, 'required|min:1|max:30')}
										<span className="signedup_2 signedup_2_svg">
											{this.props.state && this.props.state.searchFont ?
												<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" ><g><g><path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"/></g></g></svg>					
													:
												<svg version="1.1" fill="#b002ed" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" ><g><g><path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40c59.551,0,108,48.448,108,108S315.551,256,256,256z"/></g></g></svg>					
											}
										</span>
									</div>
									<div className="form-group">
										<label className="label">Email Address<span className="inp_cover-border"></span></label>
										<input type="email" 
										className={(this.props.state.errorClass_Email?"input-field paddr45 myinput red-outline" : "input-field paddr45 myinput")} 
										value={this.props.state.emailSign} 
										ref={eemail=> this.props.updateRefState(eemail, 'emailSign')}
										id ='emailSign' 
										onChange={e=>{this.removeEmailErroe(e)}}/>
										{this.validator.message('emailSign', this.props.state.emailSign, 'required|email')}
										
										<span className="form_email signedup_2_svg">
										{this.props.state && this.props.state.searchFont ?
											<svg  fill={this.props.state.searchFont}  width="27px" height="27px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 46.000000 46.000000"><g transform="translate(0.000000,46.000000) scale(0.100000,-0.100000)" stroke="none"><path d="M16 384 c-13 -12 -16 -41 -16 -148 0 -89 4 -136 12 -144 17 -17 419 -17 436 0 8 8 12 55 12 144 0 107 -3 136 -16 148 -23 24 -405 24 -428 0z m314 -99 c-47 -47 -92 -85 -100 -85 -8 0 -53 38 -100 85 l-85 85 185 0 185 0 -85 -85z m-243 -102 l-57 -58 0 115 0 115 57 -58 58 -57 -58 -57z m343 55 l0 -113 -57 57 -58 58 55 55 c30 30 56 55 57 55 2 0 3 -51 3 -112z m-200 -68 c8 0 26 11 40 25 l26 25 59 -60 59 -60 -184 0 -184 0 59 60 59 60 26 -25 c14 -14 32 -25 40 -25z"/></g></svg>
											:
											<svg  fill="#b002ed"  width="27px" height="27px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 46.000000 46.000000"><g transform="translate(0.000000,46.000000) scale(0.100000,-0.100000)" stroke="none"><path d="M16 384 c-13 -12 -16 -41 -16 -148 0 -89 4 -136 12 -144 17 -17 419 -17 436 0 8 8 12 55 12 144 0 107 -3 136 -16 148 -23 24 -405 24 -428 0z m314 -99 c-47 -47 -92 -85 -100 -85 -8 0 -53 38 -100 85 l-85 85 185 0 185 0 -85 -85z m-243 -102 l-57 -58 0 115 0 115 57 -58 58 -57 -58 -57z m343 55 l0 -113 -57 57 -58 58 55 55 c30 30 56 55 57 55 2 0 3 -51 3 -112z m-200 -68 c8 0 26 11 40 25 l26 25 59 -60 59 -60 -184 0 -184 0 59 60 59 60 26 -25 c14 -14 32 -25 40 -25z"/></g></svg>
										}
										</span>
									</div>
									<div className="form-group">
										<label className="label">Create  Password<span className="inp_cover-border"></span></label>
										{this.state.passwordEye &&
										<div>
											<input type="password" onKeyUp={this.checkValidation} onFocus={e=>{ this.showHelpPopupFun(true)}} onBlur={e=>{ this.showHelpPopupFun(false)}}
											className={(this.props.state.errorClass_Password?"input-field paddr45 red-outline":"input-field paddr45")} 
											value={this.props.state.password} ref={pwd=> this.props.updateRefState(pwd, 'password')} id ='password'
											autoComplete="new-password"
											name="password"
											onChange={(e)=>{
											  this.props.updateStateFromChild({
												[e.target.id]:e.target.value,
												errorClass_Password:false
											  })}} />
											{this.validator.message('password', this.props.state.password, 'required')}
										
											<span className="password_eye password_eyeSvg" onClick={e=>{ this.passwordEyeCheck(1, 'pass')}}>
												{this.props.state && this.props.state.searchFont ?
													<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.333 469.333"> <g><g><g><path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"/><path d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667S293.547,341.333,234.667,341.333z"/></g></g></g></svg>
													:
													<svg version="1.1" fill="#b002ed" id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.333 469.333"> <g><g><g><path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"/><path d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667S293.547,341.333,234.667,341.333z"/></g></g></g></svg>
												}
											</span>
										</div>
										}
										
										{!this.state.passwordEye &&
										<div>
											<input type="text" onKeyUp={this.checkValidation}
											className={(this.props.state.errorClass_Password?"input-field paddr45 red-outline":"input-field paddr45")} 
											value={this.props.state.password} ref={pwd=> this.props.updateRefState(pwd, 'password')} id ='password'
											autoComplete="new-password" 
											name="confirmpassword"
											onChange={(e)=>{
											  this.props.updateStateFromChild({
												[e.target.id]:e.target.value,
												errorClass_Password:false
											  })}} />
											{this.validator.message('password', this.props.state.password, 'required')}
										
											<span className="password_eye password_eye_shown password_eyeSvg" onClick={e=>{ this.passwordEyeCheck(0, 'pass')}}>
												{this.props.state && this.props.state.searchFont ?
													<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.44 469.44" ><g><g><g><path d="M231.147,160.373l67.2,67.2l0.32-3.52c0-35.307-28.693-64-64-64L231.147,160.373z"/><path d="M234.667,117.387c58.88,0,106.667,47.787,106.667,106.667c0,13.76-2.773,26.88-7.573,38.933l62.4,62.4c32.213-26.88,57.6-61.653,73.28-101.333c-37.013-93.653-128-160-234.773-160c-29.867,0-58.453,5.333-85.013,14.933l46.08,45.973C207.787,120.267,220.907,117.387,234.667,117.387z"/><path d="M21.333,59.253l48.64,48.64l9.707,9.707C44.48,145.12,16.64,181.707,0,224.053c36.907,93.653,128,160,234.667,160c33.067,0,64.64-6.4,93.547-18.027l9.067,9.067l62.187,62.293l27.2-27.093L48.533,32.053L21.333,59.253z M139.307,177.12l32.96,32.96c-0.96,4.587-1.6,9.173-1.6,13.973c0,35.307,28.693,64,64,64c4.8,0,9.387-0.64,13.867-1.6l32.96,32.96c-14.187,7.04-29.973,11.307-46.827,11.307C175.787,330.72,128,282.933,128,224.053C128,207.2,132.267,191.413,139.307,177.12z"/></g></g></g></svg>
													:
													<svg version="1.1" fill="#b002ed" id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.44 469.44" ><g><g><g><path d="M231.147,160.373l67.2,67.2l0.32-3.52c0-35.307-28.693-64-64-64L231.147,160.373z"/><path d="M234.667,117.387c58.88,0,106.667,47.787,106.667,106.667c0,13.76-2.773,26.88-7.573,38.933l62.4,62.4c32.213-26.88,57.6-61.653,73.28-101.333c-37.013-93.653-128-160-234.773-160c-29.867,0-58.453,5.333-85.013,14.933l46.08,45.973C207.787,120.267,220.907,117.387,234.667,117.387z"/><path d="M21.333,59.253l48.64,48.64l9.707,9.707C44.48,145.12,16.64,181.707,0,224.053c36.907,93.653,128,160,234.667,160c33.067,0,64.64-6.4,93.547-18.027l9.067,9.067l62.187,62.293l27.2-27.093L48.533,32.053L21.333,59.253z M139.307,177.12l32.96,32.96c-0.96,4.587-1.6,9.173-1.6,13.973c0,35.307,28.693,64,64,64c4.8,0,9.387-0.64,13.867-1.6l32.96,32.96c-14.187,7.04-29.973,11.307-46.827,11.307C175.787,330.72,128,282.933,128,224.053C128,207.2,132.267,191.413,139.307,177.12z"/></g></g></g></svg>
												}
											</span>
										</div>
										}
									</div>
									<div className="form-group">
										<label className="label">Retype Password<span className="inp_cover-border"></span></label>
										{this.state.passwordEyeRepeat &&
											<div>
												<input type="password" 
												className={(this.props.state.errorClass_RePassword?"input-field paddr45 red-outline":"input-field paddr45")} 
												value={this.props.state.rePassword} ref={rpwd1=> this.props.updateRefState(rpwd1, 'repassword')} 
												id ='rePassword' 
												autoComplete="new-password"
												onChange={(e)=>{
												  this.props.updateStateFromChild({
													[e.target.id]:e.target.value,
													errorClass_RePassword:false
												  })}} />
												{this.validator.message('rePassword', this.props.state.rePassword, 'required')}
												<span className="password_eye password_eyeSvg password_eyeSvg" onClick={e=>{ this.passwordEyeCheck(1, 'repeat')}}>
													{this.props.state && this.props.state.searchFont ?
														<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.333 469.333"> <g><g><g><path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"/><path d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667S293.547,341.333,234.667,341.333z"/></g></g></g></svg>
														:
														<svg version="1.1" fill="#b002ed" id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.333 469.333"> <g><g><g><path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"/><path d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667S293.547,341.333,234.667,341.333z"/></g></g></g></svg>
													}
												</span>
											</div>
										}
										
										{!this.state.passwordEyeRepeat &&
											<div>
												<input type="text" 
												className={(this.props.state.errorClass_RePassword?"input-field paddr45 red-outline":"input-field paddr45")} 
												value={this.props.state.rePassword} ref={rpwd1=> this.props.updateRefState(rpwd1, 'repassword')} 
												id ='rePassword' 
												autoComplete="new-password"
												onChange={(e)=>{
												  this.props.updateStateFromChild({
													[e.target.id]:e.target.value,
													errorClass_RePassword:false
												  })}} />
												{this.validator.message('rePassword', this.props.state.rePassword, 'required')}
												<span className="password_eye password_eye_shown password_eyeSvg" onClick={e=>{ this.passwordEyeCheck(0, 'repeat')}}>
													{this.props.state && this.props.state.searchFont ?
														<svg version="1.1" fill={this.props.state.searchFont} id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.44 469.44" ><g><g><g><path d="M231.147,160.373l67.2,67.2l0.32-3.52c0-35.307-28.693-64-64-64L231.147,160.373z"/><path d="M234.667,117.387c58.88,0,106.667,47.787,106.667,106.667c0,13.76-2.773,26.88-7.573,38.933l62.4,62.4c32.213-26.88,57.6-61.653,73.28-101.333c-37.013-93.653-128-160-234.773-160c-29.867,0-58.453,5.333-85.013,14.933l46.08,45.973C207.787,120.267,220.907,117.387,234.667,117.387z"/><path d="M21.333,59.253l48.64,48.64l9.707,9.707C44.48,145.12,16.64,181.707,0,224.053c36.907,93.653,128,160,234.667,160c33.067,0,64.64-6.4,93.547-18.027l9.067,9.067l62.187,62.293l27.2-27.093L48.533,32.053L21.333,59.253z M139.307,177.12l32.96,32.96c-0.96,4.587-1.6,9.173-1.6,13.973c0,35.307,28.693,64,64,64c4.8,0,9.387-0.64,13.867-1.6l32.96,32.96c-14.187,7.04-29.973,11.307-46.827,11.307C175.787,330.72,128,282.933,128,224.053C128,207.2,132.267,191.413,139.307,177.12z"/></g></g></g></svg>
														:
														<svg version="1.1" fill="#b002ed" id="Capa_1"  x="0px" y="0px" viewBox="0 0 469.44 469.44" ><g><g><g><path d="M231.147,160.373l67.2,67.2l0.32-3.52c0-35.307-28.693-64-64-64L231.147,160.373z"/><path d="M234.667,117.387c58.88,0,106.667,47.787,106.667,106.667c0,13.76-2.773,26.88-7.573,38.933l62.4,62.4c32.213-26.88,57.6-61.653,73.28-101.333c-37.013-93.653-128-160-234.773-160c-29.867,0-58.453,5.333-85.013,14.933l46.08,45.973C207.787,120.267,220.907,117.387,234.667,117.387z"/><path d="M21.333,59.253l48.64,48.64l9.707,9.707C44.48,145.12,16.64,181.707,0,224.053c36.907,93.653,128,160,234.667,160c33.067,0,64.64-6.4,93.547-18.027l9.067,9.067l62.187,62.293l27.2-27.093L48.533,32.053L21.333,59.253z M139.307,177.12l32.96,32.96c-0.96,4.587-1.6,9.173-1.6,13.973c0,35.307,28.693,64,64,64c4.8,0,9.387-0.64,13.867-1.6l32.96,32.96c-14.187,7.04-29.973,11.307-46.827,11.307C175.787,330.72,128,282.933,128,224.053C128,207.2,132.267,191.413,139.307,177.12z"/></g></g></g></svg>
													}
												</span>
											</div>
										}
									</div>
									{ this.state.showHelpPopup && 
									<div className="password_check_form main_signup_pop">
										<div className="pass_check_title">Your password needs to:</div>
										<div className="pass_main_line">
											<div className={ "pass_done_icon " + ( !this.state.contain8Ch ? "pass_iks_icon" : "" ) }></div>
											<div className="pass_line_text">Minimum of 8 characters</div>
									</div> 
										<div className="pass_main_line">
											<div className={ "pass_done_icon " + ( !this.state.letterContain ? "pass_iks_icon" : "" ) }></div>
											<div className="pass_line_text">Minimum of 1 Letter</div>
										</div>
										<div className="pass_main_line">
											<div className={ "pass_done_icon " + ( !this.state.numberContain ? "pass_iks_icon" : "" ) }></div>
											<div className="pass_line_text">Minimum of 1 Number</div>
										</div>
										<div className="pass_main_line">
											<div className={ "pass_done_icon " + ( !this.state.specialSymbol ? "pass_iks_icon" : "" ) }></div>
											<div className="pass_line_text">Minimum of 1 Special Character</div>
											<div className="pass_line_text spec_char">(e.g. !,@,#,$,%,&,*)</div>
										</div>
									</div>
									}
								</div> 
								<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={this.checkValues}>VERIFY</button></div>
							</div>
						}
					</div>
					<div className="over_flw">
						{ this.props.state.verify && 
							<div className="centered">
								<div className="row inline_parts mr0">
									<div className="all_w relative">
										<button type="button" className="close white closepurple translatX-Y abs_close" onClick={this.props.closeSign}><span>x</span></button>
										<div className="left_innr_cont h-100">
											<div className="mb-30 txt_left">
												<p className="checktxt">First Name</p>
												<p className="checktxt_name border border-0 mb-0"><img src="/images/signedup.png" className="mr-3" alt="user-icon" />{this.props.state.firstName}</p>
											</div>
											<div className="mb-30 txt_left">
												<p className="checktxt">Last Name</p>
												<p className="checktxt_name border border-0 mb-0"><img src="/images/signedup.png" className="mr-3" alt="user-icon" />{this.props.state.lastName}</p>
											</div>
											<div className="mb-30 txt_left">
												<p className="checktxt">Email Address</p>
												<p className="checktxt_name border border-0 mb-0"><img src="/images/form-email.png" className="mr-3" alt="user-icon" />{this.props.state.emailSign}</p>
											</div>
											<div className="mb-30 txt_left">
												<p className="checktxt">Create  Password</p>
												<p className="checktxt_name border border-0 mb-0"><img src="/images/passwrd.png" className="mr-3" alt="user-icon" />{this.props.state.passwordText}</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 mt-3 mt-lg-0 inline_parts">
									<div className="left_innr_cont h-100 pb-4 position-relative">
										<p className="pick pb-2">Verify Your Account</p>
										<p className="checktxt font-18">Where should we send you the verification code?</p>
										<div className="d-flex justify-content-center align-items-center flex-wrap pb-2 ">
											<div className="sms_email_box ml-3">
												<div className="b-image text-center gray-box-5">
													<img src="/images/email.png" alt="#" />
												</div>
												<p className="hdng1 font-18 text-center color_gray mt-3">
													<label className="custom-control pl-0 custom-checkbox">
														<input type="checkbox"
														id = "byEmail" 
														checked={this.props.state.byEmail} 
														disabled
														onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.byEmail})}}
														className="form-radio" />
														<span>By Email</span>                                   
													</label>
												</p>
											</div>
											<span className="err_msg">{this.props.state.message}</span>
										</div>
										<p className="pick mt-2 mb-4 font-18">ENTER THE CODE</p>
										<div className="d-flex justify-content-center align-items-center">
										<div className="">
											<p className="sml_input_box_ak d-inline">
												<input type="text" maxLength="4" id ="otp" value={this.props.state.otp} onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value})}/>
												{this.otpValidation.message('OTP', this.props.state.otp, 'required|size:4')}
											</p>
											<div className="text-white">{this.props.state.otperror}</div>
											</div>
											<div className="ml-4">
												<p className="checktxt font-18 mt-2 mb-0">Didn't receive?</p>
												<h4 className="font-16 px-0 font-weight-bold mt-0 purple_text cursor" onClick={this.resend}>RESEND</h4>
											</div>
										</div>
										<button type="button" className="position-absolute custom_btn" onClick={this.props.submitHost}>DONE</button>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			);
	}
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(MainSignUp);