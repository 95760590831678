import axios from "axios";

const deleteCookie = function(name)
	{
		var date = new Date();
		date.setTime(-1);
		var expires = "expires="+ date.toUTCString();
		document.cookie = name + "=" + '' + ";" + expires + ";path=/;domain=virdio.com";
	}

export { deleteCookie };