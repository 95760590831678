import React, { Component } from "react";
import moment from 'moment';
import classnames from "classnames";
import axios from "axios";
import { browserHistory } from 'react-router'
import { getUserImage } from '../../util/getUserImage';
import { getUsername } from '../../util/getUsername';
import { userPermissions } from '../../util/userPermissions';

class ReviewClasses extends Component {
  
  constructor(props) {
		super(props);
		
		this.state = {
			userPermissions: {},
			chosenPublish: []
		}

		this.getUserImage = getUserImage.bind(this);
		this.getUsername = getUsername.bind(this);
		this.userPermissions = userPermissions.bind(this);
		
		window.emptyPublishArray = this.emptyPublishArray;
	}
	
	componentDidMount(){
		var permissions = this.userPermissions(JSON.parse(localStorage.getItem('userData')).data.responseData.channelRole);
		this.setState({userPermissions: permissions});
	}

	openNotes=(item)=>{
		this.props.openNotes(item);
	}
	
	editClass=(id)=>{
		browserHistory.push("/fitnessdetail/"+id+'#marketPlaceEdit');
	}
	
	publishClass=(item, st, isLast)=>{
		var channelId = JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
		var postData = { sessionId: item, channelId: channelId, status: st };
		
		this.props.updateStateFromChild({showLoader: true, chosenFilterHosts: []});
		
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		axios.defaults.headers.common['Authorization'] = token;
		axios.post(process.env.REACT_APP_NAME+"/api/v1/session/publishClass", postData).then(res => {
			if ( st == 2 ) 
				{
					this.props.getClasses();
				}
			else if ( st == 3 && isLast ) 
				{
					this.props.getClasses(true);
					this.setState({chosenPublish: []});
				}
		}).catch(err =>{ });
	}
	
	choseClass=(id)=>{
		var arrayClass = this.state.chosenPublish;
		if ( arrayClass.indexOf(id) == -1 )
			{
				arrayClass.push(id);
			}
		else
			{
				var indOf = arrayClass.indexOf(id);
				arrayClass.splice(indOf, 1);
			}
			
		this.setState({chosenPublish: arrayClass});
	}
	
	publishSelected=()=>{
		this.state.chosenPublish.map((item, i) => { 
			var isLast = false;
			if ( i == (this.state.chosenPublish.length - 1) ) { isLast = true; }
			this.publishClass(item, 3, isLast);
		});
	}

	emptyPublishArray = () =>{
		this.setState({chosenPublish: []});
	}

	render() {
			return(
				<div className="classes_review_content">
					{ this.props.chosenMenu == "PUBLISH" && this.state.chosenPublish.length > 0 &&
						<div className="edit_review approve_review" onClick={this.publishSelected}>Publish
							<div className="publish_icon"></div>
						</div>
					}
					{ this.props.classesArray.length > 0 &&
						<div>
							{ this.props.classesArray.map((item, i)=>
								<div className="one_class_review" key={i}>
									<div className="class_review_info">
										<div className="top_info_class">
											<span className="top_span">{moment(item.scheduleDate).format('MM/DD/YYYY')}</span>
											<span className="top_span class_dot">&bull;</span>
											<span className="top_span">{moment(item.scheduleDate).format('hh:mm A')}</span>
											<span className="top_span class_dot">&bull;</span>
											<span className="top_span">{item.duration} min</span>
											<span className="class_interest">{item.interestTitles}</span>
										</div>
										<div className="class_name">{item.name}</div>
										<div className="bottom_class_content">
											<div className="instructor_avatar" style={{backgroundImage: "url("+this.getUserImage(item.hostImage)+")"}}></div>
											<div className="instructor_name">{this.getUsername(item.hostFirstName, item.hostLastName)}</div>
										</div>
									</div>
									<div className="class_review_buttons">
										{ this.state.userPermissions.addNotes &&
											<div className={classnames({"notes_button": true, "active": item.notesCount > 0})} onClick={() => this.openNotes(item)}>
												{ item.notesCount == 0 && <div className="plus_sign">+</div> }
												{ item.notesCount > 0 && <div className="count_note">{item.notesCount}</div> }
												<div className={classnames({"note_icon_review": true, "active": item.notesCount > 0})}></div>
											</div>
										}
										{ this.state.userPermissions.editClass &&
											<div className="edit_review" onClick={() => this.editClass(item.id)}>Edit</div>
										}
										{ (item.marketplaceStatus == 1 && this.state.userPermissions.reviewClassContent) &&
											<div className="edit_review publish_send" onClick={() => this.publishClass(item.id, 2)}>Ready to Publish</div>
										}
										{ (item.marketplaceStatus == 2 && this.state.userPermissions.createClass) &&
											<div className={classnames({"filter_toogle": true, "active": this.state.chosenPublish.indexOf(item.id) > -1})} onClick={() => this.choseClass(item.id)}></div>
										}
									</div>
								</div>
							)}
						</div>
					}
					{ this.props.classesArray.length == 0 && !this.props.showLoader &&
						<div className="empty_review_classes">No available Classes.</div>
					}
				</div>
			)
		}
	}
	
export default ReviewClasses;