import React, { Component } from "react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

class TokenSetup extends Component {
	constructor(props) {
	super(props);

	this.state = {
		tokenData: {},
		isCopied: false,
		isCopiedSand: false
	};
	  
	}
	
	componentDidMount(){
		this.getToken();
	}
	
	getToken=()=>{
		var postData = {channelId: this.props.channelId};
		axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelToken", postData).then(res => {
			this.setState({tokenData: res.data.responseData});
		}).catch(err =>{ });
	}
	
	setChannelToken=()=>{
		var postData = {channelId: this.props.channelId};
		axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/setChannelToken", postData).then(res => {
			this.getToken();
		}).catch(err =>{ });
	}
	
	copyToken=(type)=>{
		if ( type === 'live' ) { this.setState({isCopied: true, isCopiedSand: false}); }
		else { this.setState({isCopiedSand: true, isCopied: false}); }
	}
	
	render() {
		return (
			<div>
				{ !this.state.tokenData.id &&
					<div>
						<div className="empty_token">Currently your Channel does not have Secret Token for using Virdio External API. If you want to use Virdio External API click bellow to get your Secret Token.</div>
						<div className="inline_centered">
							<div className="token_btn" onClick={this.setChannelToken}>Get Secret Token</div>
						</div>
					</div>
				}
				{ this.state.tokenData.id &&
					<div>
						<div className="empty_token">Live Secret Token</div>
						<div className="inline_centered">
							<input type="text" className="input-field token_input" value={this.state.tokenData.secretToken} disabled />
							<CopyToClipboard text={this.state.tokenData.secretToken}><div className="token_btn" onClick={() => this.copyToken('live')}>{this.state.isCopied ? 'Copied' : 'Copy Secret Token'}</div></CopyToClipboard>
						</div>
						<div className="empty_token">Sandbox Secret Token</div>
						<div className="inline_centered">
							<input type="text" className="input-field token_input" value={JSON.parse(localStorage.getItem('userData')).data.responseData.settings.sandboxSecretToken} disabled />
							<CopyToClipboard text={JSON.parse(localStorage.getItem('userData')).data.responseData.settings.sandboxSecretToken}><div className="token_btn" onClick={() => this.copyToken('secret')}>{this.state.isCopiedSand ? 'Copied' : 'Copy Secret Token'}</div></CopyToClipboard>
						</div>
					</div>
				}
			</div>
		);
	}

}

export default TokenSetup;

