import React, { Component } from "react";
import axios from "axios";
import { Link } from 'react-router';
import $ from 'jquery'
import {  browserHistory} from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import utils from '../../util/functions'
class fileUpload extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            imageURL: '',
          };
          this.handleUploadImage = this.handleUploadImage.bind(this);
    }

  

onChangeHandler(e){
//alert('hi');
    let files=e.target.files[0];
    console.warn('------data-file-------',files)

    this.setState({
        imageName: files
    })

   // let reader= new FileReader();
  //  reader.readAsDataURL(files[0]);

    // reader.onload=(e)=>{
    //     console.warn("img Data",e.target.result)

    //     const url=process.env.REACT_APP_NAME+"/api/v1/upload/upload";
    //     const formData={file:e.target.result};

    //     axios.post(url, formData)
    //     .then(res => {
    
    //       console.log('=============lallittiwari12345===================>',res.data);;
    
          
    //     }).catch(err =>{
    //         this.setState({
    //             responseMessage:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
    //         })
    
    //     });
    // }

}



handleUploadImage(ev) {
    ev.preventDefault();

    const data = new FormData();
    data.append('file', this.uploadInput.files[0]);
    //data.append('filename', this.fileName.value);
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    fetch(process.env.REACT_APP_NAME+'/api/v1/upload/upload', {
      method: 'POST',
      body: data,
    }).then((response) => {
      response.json().then((body) => {

        console.log('---------file name--------------',body.responseData.file);
       // this.setState({ imageURL: `http://localhost:8001/${body.file}` });

        //console.log('--------this.state.-----------',this.state.imageURL)
      });
    });
  }

render() {
return(
<div>

<div className="row top-header py-4">
                    <div className="col-lg-6 flex-wrap d-flex justify-content-center justify-content-lg-start">
                        <div className="align-self-center">
                            <img src="/images/login-logo.png" className="logo" alt="logo" />
                        </div>
                        <div className="d-flex d-md-block justify-content-center px-4 py-2">
                            <div className="user-info d-flex align-items-center">
                                <img src="/images/attendee.png" className="user-avtar" alt = '#'/>
                                <div className="pl-4">
                                    <h3>Welcome {this.state.loginName}!</h3>
                                    <p>No class this week</p>
                                    {/* <p>Next Class, Wednesday, 24 July 2019</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="d-flex justify-content-between ">
                            <div className="header-info-right">
                                <p>Average Attendance</p>
                                <h3>0%</h3></div><span className="border-right gray-border"></span>
                            <div className="header-info-right">
                                <p>Total Views</p>
                                <h3>0</h3></div><span className="border-right gray-border"></span>
                            <div className="header-info-right">
                                <p>Total Revenue</p>
                                <h3>$0</h3></div><span className="border-right gray-border"></span>
                                  <div className="message-notification"><img src="images/message.png" alt=''/>
                              <span className="message-count">0</span></div>
                              <button className="btn btn-outline-secondary font-weight-bold radius-8 align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');browserHistory.push("/")}}>LOG OUT</button>
                        </div>
                    </div>
                </div>

{/* <div className="col-lg-4 col-md-6">
                                    <div className="form-group mb-0"><span className="cover-border"></span>
                                            <label className="label">Image</label>
                                            <div className="custom-file mb-3">
                                              <input type="file"  name="file" onChange = {(e)=>this.onChangeHandler(e)} />
                                          
                                            </div>
                                            
                                        </div>    
                                    </div>

                                    <button type="button" className="btn btn-primary save-btn font-book d-inline-block ml-4 my-4"onClick={e=>this.setState({channelInformation:false})}>Back</button>

<button type="button" className="btn btn-primary save-btn font-book d-inline-block ml-4 my-4" onClick={this.submitForm}>Create channel</button> */}




<form onSubmit={this.handleUploadImage}>
        <div>
          <input ref={(ref) => { this.uploadInput = ref; }} type="file" />
        </div>
        {/* <div>
          <input ref={(ref) => { this.fileName = ref; }} type="text" placeholder="Enter the desired name of file" />
        </div> */}
        <br />
        <div>
          <button>Upload</button>
        </div>
        <img src={this.state.imageURL} alt="img" />
      </form>
</div>
					
)
  }
}

export default fileUpload;