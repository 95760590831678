import axios from "axios";
import $ from 'jquery';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import { showMainErrorPop } from '../actions/popups';
import { connect } from "react-redux";
import moment1 from 'moment-timezone';
const verify = function(e)
{
	if ( this.validator.allValid() ) 
		{
			if( this.state.password===this.state.rePassword )
				{
					var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/     
				 
					var test = reg.test(this.state.password);
					
					if ( test ) 
						{
							let ak = ''; 
							let participantId = '';
							
							if ( this.state.sessionId || ( this.props.state && this.props.state.sessionId ) )
								{
									if ( this.state.sessionId ) { participantId = this.state.sessionId; }
									if ( this.props.state && this.props.state.sessionId ) { participantId = this.props.state.sessionId; }
								}
								
							 if	( localStorage.getItem('sessionIdData') )
								 {
									participantId = JSON.parse(localStorage.getItem('sessionIdData'));
								 }
							
							let usertype = 2;
							let url_loc = window.location.href;
							
							let demandSessionId = 0;
							if ( this.state.demandSessionId )
								{
									demandSessionId = this.state.demandSessionId;
								}
							
							if ( url_loc.indexOf('/trainingPlans') > -1 ) { usertype = 1; }
							 
							 const participentDetail = 
														 {
															firstName:this.state.firstName.trim(),
															lastName:this.state.lastName.trim(),
															email:this.state.emailSign.trim(),
															password:this.state.password.trim(),
															address1:"sector3",
															address2:"noida",
															city:"noida",
															state:"UP",
															zip:"123456",
															image:"",
															type:usertype,
															phone:this.state.phone.trim(),
															sessionId: Array.isArray(participantId) ? participantId : [participantId],
															timezone:moment1().tz(moment1.tz.guess()).format('z')
														};
							if ( demandSessionId != 0 )
								{
									participentDetail['demandSessionId'] = demandSessionId;
								}
			
							if (this.state.isSignupChannel && this.state.mainChannelData )
								{
									participentDetail['add_member_channel'] = this.state.mainChannelData.id
								}
							
							
						
							 window['emailSign'] = this.state.emailSign.trim();
								localStorage.setItem("emailSign", this.state.emailSign);
							  
								localStorage.setItem("remail", this.state.emailSign);
								localStorage.setItem("rpass", this.state.password);

								$('.main_loader').attr({'style':'display:flex'});
								
							  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/register", participentDetail)
							  .then(res => {
								   $('.main_loader').hide();
							  if(res.data.responseMessage == "success")
								  {
									  let regId = res.data.responseData.userId;
									  if(res && res.data && res.data.responseData && res.data.responseData.settings)
										{
											var globalSettings = res.data.responseData.settings;
									
											localStorage.setItem('appDownload', ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink ));
										}
									  
									  
									  
									  
									  for(let i=0;i<this.state.password.length;i++){
										ak = ak+'*';
									  }
									  this.setState({
									  verify:true,
									  signup:false,
									  passwordText:ak,
									  
									},()=>{
										
										if(this.state.showAgreePop == 0)
											{
												//localStorage.removeItem('sessionId');
												localStorage.removeItem('sessionIdData');
												localStorage.removeItem('scheduleDate',);
												localStorage.removeItem('sessionEndDate');
												localStorage.removeItem('duration');
												$("#email_verification").attr({'style':'display:block; z-index: 10000'});
											}
										
										else
											{
												this.setState({
													userMainId: regId
												});
												window.$("#waiverAgree").modal('show');
												let waiver_link = 'https://docs.google.com/gview?url='+encodeURIComponent(this.state.channelWaiverUrl)+'&embedded=true&t='+(new Date().getTime());
												$('.waiver_iframe').attr('src', '');
												setTimeout(function(){ $('.waiver_iframe').attr('src', waiver_link); }, 2000);
											}	
									})
									
									if ( this.props.state && this.props.state.isTrainingPlans && this.props.state.isFreeTrial )
										{
											let postData;
											let userFirstName = '';
											if(localStorage.getItem('userData'))
												{
													userFirstName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName;
												}
											
											if ( !this.props.state.isFreeTrial )
												{
													postData = {
																		userId: res.data.responseData.userId,
																		planId: this.props.state.chosenPlanId,
																		demandPlanId: this.props.state.chosenDemandPlanId,
																		couponCode: this.props.state.promoCode,
																		firstName: userFirstName
																	};
												}
											else
												{
													postData = {
																		userId: res.data.responseData.userId,
																		planId: this.props.state.chosenPlanId,
																		demandPlanId: this.props.state.chosenDemandPlanId,
																		couponCode: this.props.state.promoCode,
																		freeTrial: 1,
																		firstName: userFirstName
																	};
												}
											
											axios.post(process.env.REACT_APP_NAME+"/api/v1/user/addUserPlan", postData)
											.then(res => { }).catch(err =>{  });
										}
								  }
							 else
								 {
								   this.props.showMainErrorPop('Something went wrong. Please try again.');	
								 }
								 
								 $('.main_loader').hide();
							  }).catch(err =>{
								if ( err.response.data.errorData  == 'User already exists' && ( window.location.pathname == '/trainingPlans' || window.location.pathname == '/Register') )
									{
										this.setState({errorClass_Email : true});				 
									}
										
									$('.main_loader').hide();
									if ( err.response.data.errorData == 'User not verified')
										{
											$("#user_not_verified").attr({'style':'display:block'});
										}
									else
										{
											this.props.showMainErrorPop(err.response.data.errorData);		
											 $('.main_loader').hide();
										} 
							  });

					}
				else
					{
						$('.main_loader').hide();
						this.props.showMainErrorPop('Please check that password is matching the requirements');		
					}
					  

			}
		else
			{
				this.props.showMainErrorPop('Password mismatch!');	
			}
		} 
	else 
		{
		  this.validator.showMessages();
		 
			this.props.showMainErrorPop('One or more fields has an error. Please correct and press SAVE again!');	
		
		  this.forceUpdate();
		}
}

export { verify };