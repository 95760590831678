import React, { Component } from "react";
//import uniqueId from 'lodash/uniqueId';
//import { Link } from 'react-router'
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { hashHistory } from 'react-router';
import axios from "axios";
import {  browserHistory} from 'react-router'
import  { Redirect } from 'react-router-dom'
import moment1 from 'moment-timezone';

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "../../actions/types";

//import $ from 'jquery';

class inviteUser extends Component {
            constructor() {
            super();
           // let email;
            this.state = {
                data:"",
                showError:false,
                messageFromServer:'',
                type:"1",
                errors: {}
              };
        
            }

        componentDidMount() {

          this.inviteEmailId();
        }

        componentWillMount(){
          
        }

          
        inviteEmailId = e => {
    
         console.log('-----------verifyuser-------------------')

         let path=window.location.pathname;

         console.log('------path----------',path)

         let path_arr = path.split("/");

         console.log('------path_arr----------',path_arr[2])

         //let path_arrr = path_arr[2].split("#");

         //console.log('------path_arrlalit----------',path_arrr[0])

         const userData = {
          emailId: path_arr[2],
		 timezone: moment1().tz(moment1.tz.guess()).format('z')
        };
		
         axios
         .post(process.env.REACT_APP_NAME+"/api/v1/user/verify-email",userData)                
         .then(res => {
          
          console.log('---------verifyuser123333--------------',res.data)
          console.log(res.data.responseData.tempUserObj);
          localStorage.setItem("hostData", JSON.stringify(res.data.responseData.tempUserObj));
          if(res.data.responseData.tempUserObj.existingUser==="Yes"){
            browserHistory.push('/login')
          }else{
		  localStorage.setItem("email", res.data.responseData.tempUserObj.email);
          browserHistory.push('/hostSignup')
          }

        //   if(res.data.responseMessage == 'success')
        //   {
        //     localStorage.setItem("sessionId", res.data.sessionData.id);
        
        //      browserHistory.push("/signUp");

        //   }

        })
      
                        
          };

        render() {

        return (

            <div className="container">
            <div className="row">
              <div className="login-bg">

                ----------This is Verify link------------
             
              </div>              
            </div>
          </div>

         );

         }

}

const container = {
    "padding": "0px 15px",
    "max-width": "1140px"
  
  };

  export default inviteUser;

