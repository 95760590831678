import React, { Component } from "react";
import Header from './Header';
import axios from "axios";
import $ from 'jquery';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import { connect } from "react-redux";

import SimpleReactValidator from 'simple-react-validator';

import MainSignUp from '../auth/MainSignUp';
import { submitHost } from '../../util/submitHost';
import { verify } from '../../util/verify';
import { channelCheckUser } from '../../util/channelCheckUser';
import { showMainErrorPop } from '../../actions/popups';
import SupportContainer from '../home/SupportContainer';

class Register extends Component {
constructor(props)
{
	super(props);
	
	this.state = {
		email: '',
		password: '',
		firstName: '',
		lastName: '',
		phone: '',
		password: '',
		rePassword: '',
		verify: false,
		signup: false,
		validateEmail: true,
		sms: false,
		byEmail: true,
		message: '',
		passwordText: '',
		otp: '',
		msg: '',
		userType: '',
		otperror: '',
		errorClass_FirstName: false,
		errorClass_LastName: false,
		errorClass_Phone: false,
		errorClass_Email: false,
		errorClass_Password: false,
		errorClass_RePassword: false,
		redirectToRoute: '',
		userName: '',
		emailSign: '',
		participantData: '',
		participantValidate: true,
		validateEmail: false,
		inValidAccess: true,
		isOpenSign: false,
		isOpenLoginAll: false,
		supportPageName: 'Register',
		supportPageApi: '/Register',
		userTypeFaqs: 2
	}
	
	this.validator = new SimpleReactValidator({autoForceUpdate: this});
	this.otpValidation = new SimpleReactValidator();
	this.emailValidate  = new SimpleReactValidator();
	this.submitHost = submitHost.bind(this);
	this.verify = verify.bind(this);
	this.channelCheckUser = channelCheckUser.bind(this);
}

componentDidMount()
{

}



resend=(e)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : this.state.emailSign})
    .then(res => {
			if( res.data.responseMessage == "success" )
				{
					$("#resend_verification").attr({'style':'display:block'});
				}
			else
				{
					this.setState({
						msg:''
					}) 
				}
    }).catch(err =>{
		this.setState({
			msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
		}) 
    });
}

closeSign=(e)=> {
	browserHistory.push("/accesscode");
};

onChange=(e)=> {
    this.setState({ [e.target.id]: e.target.value });
};

updateRefState = (ref, mod) => {
	if ( mod == 'firstName' )
		{
			this._firstName = ref;
		}
	else if ( mod == 'lastName' )
		{
			this._lastName = ref;
		}
	else if ( mod == 'email' )
		{
			this._email = ref;
		}
	else if ( mod == 'emailSign' )
		{
			this._email = ref;
			this.emailSign = ref;
		}
	else if ( mod == 'password' )
		{
			this._password = ref;
		}
	else if ( mod == 'repassword' )
		{
			this._rePassword = ref;
		}
}

updateStateFromChild = (newState, mod) => {
	this.setState(newState);
	if ( mod ) 
		{
			$("#registration_popup").attr({'style':'display:block'});
			$('.main_loader').hide();
		}
}

render() {
	
    return (<div className="container-fluid custom-width newPSignup">
				<div className="main_pop_sign active">
					<div className="text-center"><img src="/images/participate.png" alt="host" className="w-60" /></div>
					<h4 className="white text-center pb-2 font-20 sign_main_text">Signing up as a Member</h4>
					<MainSignUp 
						state={this.state}
						closeSign={this.closeSign}
						onChange={this.onChange}
						resend={this.resend}
						submitHost={this.submitHost}
						updateRefState={this.updateRefState}
						updateStateFromChild={this.updateStateFromChild}
						verify={this.verify}
						>
					</MainSignUp>
				</div>
				
				
			 <div className="modal pr-0" id="error_msg" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
							<p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
						</div>
						<div className="text-center">
							<button type="button" 
							className="custom_btn1 mt-3" 
							data-dismiss="modal" 
							onClick={e=>{
							$("#error_msg").attr({'style':'display:none'});
							
							if(!this.validator.fieldValid('firstName')){
							this.setState({
							errorClass_FirstName:true
							})
							}
							
							if(!this.validator.fieldValid('lastName')){
							this.setState({
							errorClass_LastName:true
							})
							}
							if(!this.validator.fieldValid('email') ){
							this.setState({
							errorClass_Email:true
							})
							}
							if(!this.validator.fieldValid('password') || this.state.msg==="Password mismatch!"){
							this.setState({
							errorClass_Password:true
							})
							}
							if(!this.validator.fieldValid('rePassword')){
							this.setState({
							errorClass_RePassword:true
							})
							}
							}}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="succes_register" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
							<p className="mb-0 py-4 white text-center font-20">Your account has been successfully created.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<Link className="main_link" to='/login'><button className="custom_btn1 mt-3 mx-2">NEXT</button></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0" id="user_not_verified" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
							<p className="mb-0 py-4 white text-center font-20">Your username is already registered, but has not been verified. To complete your registration please select OK and you will be guided through the process.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<Link className="main_link" to='/login'><button className="custom_btn1 mt-3 mx-2">OK</button></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>);
}

}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(Register);