import React, { Component } from "react";
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
class PopupError extends Component {
constructor(props) {
	super(props);
	this.state = {
	}
}

  render() {
    return (
		<div>
			{ this.props.msgErrorPopup &&
			
			  <div id="password-error-change" className="modal settings_popup_active zindex999999" data-backdrop="static" data-keyboard="false">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<pre id="login-alert-text" className={"py-4 text-light text-center font-20 popup_error_text"+(this.props.popupAlign ? ' popup_align_left' : '')} dangerouslySetInnerHTML={{__html: this.props.msgErrorPopup}}></pre>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={() => { if ( this.props.callokf ) { this.props.showMainErrorPop(null); this.props.callokf(); } else { this.props.showMainErrorPop(null); }}}>OK</button></div>
					</div>
				</div>
			</div>
			  
			}	
		</div>
    );
  }
}


const mapStateToProps = state => {
	return { 
		msgErrorPopup: state.libraryPopups.mainErrorPopMsg,
		popupAlign: state.libraryPopups.popupAlign,
		callokf: state.libraryPopups.callokf
	}
}

export default connect(
  mapStateToProps,
  { 
	showMainErrorPop
  }
)(PopupError);