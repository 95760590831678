
window['eventCurrent'] = false;
const updatesse = function(data_name, data_value, callback, token)
{
	if ( typeof EventSource == 'undefined' ) { return false; }
	
	if ( window['eventCurrent'] )
		{
			window['eventCurrent'].close();
			window['eventCurrent'] = false;
		}
	
	if ( typeof data_value == 'object' ) { data_value = data_value.join(','); }
	
	var url = window.location.href;
	if ( url.indexOf('fitnessdetail') > -1 && data_name !== 'sessionIdEdit' ) { return false; }
	
	let url_var = process.env.REACT_APP_NAME+'/api/v1/updatesse/events?observeData=' + data_name + '&valueName=' + data_value;
	
	let dataToken = '';
	if ( token )
		{
			dataToken = '&authToken=' + token;
			url_var = process.env.REACT_APP_NAME+'/api/v1/updatesse/eventsauth?observeData=' + data_name + '&valueName=' + data_value + dataToken;
		}
		
	var events = new EventSource(url_var);
	
	events.onmessage = (event) => {
			if ( event.data == 'update' )
				{
					callback();
				}
			else if ( event.data !== 'first load' && event.data !== 'keep-alive' )
				{
					try {
						callback(event.data);
					}
					catch(err){  }
				}
		};
	
	window['eventCurrent'] = events;
}

export { updatesse };