import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";

import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class SessionFeedback extends Component {
  
  constructor(props) {
	super(props);
	this.state={
		months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		zoomActive: false,
		activeActivityArray: [],
		activeActivity: 0,
		selectedActivity: [],
		zoomImg: [],
		noActivitNotes: [],
    }
	
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}
 
componentDidMount(){
}

zoomAct=(mod)=>{
	this.setState({
		zoomActive: true,
		zoomImg: mod
	})
}

zoomOut=e=>{
	this.setState({
		zoomActive: false,
		zoomImg: []
	})
}
setActive=(mod, notes)=>{
	if (notes.length > 0)
		{
			this.setState({
				activeActivityArray: this.props.state.sessionActivity[mod],
				activeActivity: mod
			}, () => {
					this.zoomOut();
					this.setChoosenActImages();
					})
		}			
}
setFirstActivity=e=>{
	console.log(11);
	if ( this.props.state.sessionActivity && this.props.state.sessionActivity.length > 0 )
		{
			console.log(22);
			this.setState({
				activeActivityArray: this.props.state.sessionActivity[0],
			}, () =>{this.setChoosenActImages('first');});
		}
}

setChoosenActImages=(mod)=>{
	console.log(mod);
	if ( mod && mod == 'first' )
		{
			this.setState({
					activeActivity: 0, 
					zoomActive: false,
					zoomImg: []
					});
		}
	let act_id = this.state.activeActivityArray.sessionScriptId;
	let act_notes = [];
	let no_act_notes = [];
	if (this.props.state.sessionNotes.length > 0 )
		{
			for ( var i = 0; i<this.props.state.sessionNotes.length; i++)
				{
					if ( this.props.state.sessionNotes[i].activityId == act_id )
						{
							act_notes.push(this.props.state.sessionNotes[i]);
						}
					else if ( this.props.state.sessionNotes[i].activityId == 0 )
						{
							no_act_notes.push(this.props.state.sessionNotes[i]);
						}						
				}
		}
	else
		{
			act_notes = [];
		}	
	this.setState({
		selectedActivity: act_notes,
		noActivitNotes: no_act_notes,
	}, () => {
		if ( mod && mod == 'first' && this.state.noActivitNotes.length > 0 )
			{
				this.setNoAct();
			}
	})
}
closePop=e=>{
	this.setState({
		activeActivity: 0,
	})
}
setNoAct=e=>{
	let noActArray = this.state.noActivitNotes;
	this.setState({
		selectedActivity: noActArray,
		activeActivity: 'noAct'
	})
}

getDate=(date1)=>{
	let ab = new Date(date1).getTime();
	
	let day=new Date(ab).getDate();
	let month=(new Date(ab).getMonth())+1;
	let year=new Date(ab).getFullYear();
	if(month<10){
		month='0'+month
	}
	let sessionDate= month+'/'+day+'/'+year;
	
	return sessionDate; 
}

 getSessionTime = (date1)=>{
    let hour=new Date(date1).getHours();
    let minute = new Date(date1).getMinutes();
    let sessionTime= hour+':'+minute;
    if(minute<10){
      minute='0'+minute
    }
    
    if(hour<12){
    if(hour<10){	
    sessionTime ='0'+hour+':'+minute+" " +'AM'
    }else{
    sessionTime=hour+':'+minute+" " +'AM'
    }
    return sessionTime ;
    }
    if(hour>12){
    if((hour-12)<10){
      sessionTime='0'+(hour-12)+':'+minute+" " +'PM'	
    }else{
    sessionTime=(hour-12)+':'+minute+" " +'PM'
    }	
    return sessionTime ;
    }
    if(hour==12 && minute>=0){
    sessionTime=hour+':'+minute+" " +'PM'	
    return sessionTime ;
    }    
    }
  render() {
	 return (
		<div className="modal-dialog mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent ">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none no_padd_modal">
						<button type="button" className="close white closepurple translatX-Y new_X_position" data-dismiss="modal"><span onClick={this.closePop} >x</span></button>
						
						<div className="activity_pop_holder" >
							<div className="act_h1 img_object_fit" >Class Name: <span className="act_span1" >{this.props.state.sessionData.name}</span> </div>
							<div> <span className="act_span2 ml0" >  <img src="/images/record.svg" className="clock_img_act hg20" /> { this.getSessionDate(this.props.state.sessionData.scheduleDate)} @ {this.getSessionTime(this.props.state.sessionData.scheduleDate)} </span> <span className="act_span2 disp_blck2" >  <img src="/images/clock.svg" className="clock_img_act hg20" /> {this.props.state.sessionData.duration} Min</span></div>
						</div>
						<div className="feedback_holder" >
							<div className="feedback_1st_hold">
								<div className="feedback_h1" >Activity List</div>
								<div className="act_name_holder">	
									{this.state.noActivitNotes.length > 0 &&
										<div className={classnames("feedback_actName ", {"auto_cursor ": this.state.noActivitNotes.length == 0}, { 'active_activity ': this.state.activeActivity == 'noAct' })} onClick={this.setNoAct}>
										No Activity Notes
										<div className="act_note_num" >{this.state.noActivitNotes.length}</div>
										</div>
									}
									{this.props.state.sessionActivity.map((item, i)=> 
										<div className={classnames("feedback_actName ", {"auto_cursor ": item.notes.length == 0}, { "active_activity ": i == this.state.activeActivity })} key={i} onClick={e=>{this.setActive(i, item.notes)}} >
										{item.ActivityName}
											{ item.notes.length > 0	&&
												<div className="act_note_num" >{item.notes.length}</div>
											}
										</div>
									)}
								</div>
							</div>
							<div className="feedback_2nd_hold">
								<div className="act_name_hold" >
									<div className="act_h1 less_font_feedback" >Activity Name: <span className="act_span1" >{this.state.activeActivityArray.ActivityName}</span> </div>
								</div>
								{!this.state.zoomActive &&
									<div className="activity_images_holder">
										
										{this.state.selectedActivity.map((item, i)=>
											<div className="image_act " style={{backgroundImage: "url('" + item.file + "')"}} key={i} onClick={e=>{this.zoomAct(item)}} >
												<div className="zoom_act_img"  ></div>
											</div>										
										)}
									
									</div>
								}
								{this.state.zoomActive &&
									<div className="zoom_img_holder">
										<div className="zoom_activ" style={{backgroundImage: "url('" + this.state.zoomImg.file + "')"}} > </div>
										<div className="newBack_btn" onClick={this.zoomOut} >Back</div>
										{ this.state.zoomImg.note &&
											<div className="note_text">{this.state.zoomImg.note}</div>
										}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="hidden_div_sign" onClick={this.setFirstActivity}></div>
			</div>
  )}
}
export default SessionFeedback;
