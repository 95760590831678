import React, { Component } from "react";
import Header from './Header';
import axios from "axios";
import $ from 'jquery';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import ReportsDaily from '../reports/ReportsDaily';
import ReportsBilling from '../reports/ReportsBilling';
import ReportsClient from '../reports/ReportsClient';
import SupportContainer from '../home/SupportContainer';

class Reports extends Component {
constructor(props)
{
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	let nowTime = new Date();
	nowTime.setDate(1);
	
	this.state = {
					currentTab: 'daily',
					allHosts: [],
					allInterests: [],
					dailyReports: [],
					nowReports: [],
					filterDaily: {},
					startDate: new Date(),
					endDate: new Date(),
					ajaxLoaded: false,
					pageCount: 20,
					totalPages: [],
					sortByVal: ['id', 'DESC'],
					startPage: 1,
					currentSubTab: 'today',
					currentArray: [],
					planInfo: {},
					billingMonth: moment(nowTime).format('YYYY-MM-DD'),
					billingArray: [0, 0, 0, 0, 0],
					searchClient: '',
					ambassadorSubTab: 'codes',
					couponType: 'Ambassador',
					supportPageName: 'Channel Reports',
					supportPageApi: '/reports',
					userTypeFaqs: 2
				}
}

componentDidMount()
{
	this.reloadData();
	
	let ak = JSON.parse(localStorage.getItem('userData'));

	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getCurrentPlanFull", {channelId: ak.data.responseData.channel})          
		.then(res => {
			
			this.setState({planInfo: res.data.channelPlan});
			
		}).catch(err =>{ console.log('----------there is problem------------', err); });
		
	if ( localStorage.getItem('channelCouponType') )
		{
			if ( localStorage.getItem('channelCouponType') === 'channel' ) { this.setState({couponType: 'Channel Ambassador'}); }
			else { this.setState({couponType: 'Brand Ambassador'}); } 
		}
}

changeTab=(e)=>{
	
	var subtab = 'today';
	var startDate = new Date();
	var endDate = new Date();
	if ( e == 'billing' || e == 'clients' ) { subtab = 'live'; }
	if ( e == 'past' ) { startDate.setDate(1); }
	if ( e == 'future' ) { endDate.setMonth(endDate.getMonth() + 1); endDate.setDate(0) }
	$('#searchSessionName').val('');
	if (e)
		{
			this.setState({filterDaily: {}});
		}
	
	this.setState({currentTab: e, ajaxLoaded: false, currentArray: [], updateReload: 1, currentSubTab: subtab, startDate: startDate, endDate: endDate, sortByVal: ['id', 'DESC']}, function () { this.reloadData(); });
}

reloadData=(pg, type)=>{

	let uData = JSON.parse(localStorage.getItem('userData')).data.responseData;
	axios.defaults.headers.common['Authorization'] = uData.token;
	
	var ndate = this.state.startDate;
	
	if ( ndate )
		{
			ndate.setHours(0);
			ndate.setMinutes(0);
			ndate.setSeconds(0);
		}
	
	if ( this.state.currentTab === 'future' )
		{
			ndate = new Date();
		}
	
	if ( type && type == 'noFilter' )
		{
			ndate = undefined;
		}
	
	var edate = this.state.endDate;
	edate.setHours(23);
	edate.setMinutes(59);
	edate.setSeconds(59);
	
	var startPg = this.state.startPage;
	
	if (!pg)
		{
			startPg = 1;
		}
	
	var sendData = {channelId: uData.channel, startDate: ndate, pageCount: this.state.pageCount, startPage: startPg, filterDaily: this.state.filterDaily, sortby: this.state.sortByVal, clientStatus : 1};
	
	this.setState({startPage: startPg});
	
	if ( this.state.currentTab == 'past' || this.state.currentTab == 'future' || this.state.currentTab == 'clients' ) { sendData['endDate'] = edate; }
	if ( this.state.currentTab == 'billing' ) { sendData['billDate'] = this.state.billingMonth; sendData['pageCount'] = 200; sendData['subtype'] = this.state.currentSubTab; sendData['ambassadorSubTab'] = this.state.ambassadorSubTab; }
	
	if ( this.state.currentTab == 'clients' ) { sendData['clientsList'] = 1; sendData['searchText'] = this.state.searchClient; sendData['subtype'] = this.state.currentSubTab; }
	
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelReports", sendData).then(res => {

		// Daily Reports
		var dreport = res.data.reportsData.dailyReports;
		for ( let dof in dreport )
			{
				dreport[dof]['sessionTime'] = moment(dreport[dof]['scheduleDate']).format('MM/DD/YYYY @ h:mmA');
			}
		
		// New Reports
		var nreport = res.data.reportsData.nowReports;
		for ( let dof in nreport )
			{
				nreport[dof]['sessionTime'] = moment(nreport[dof]['scheduleDate']).format('MM/DD/YYYY @ h:mmA');
			}
		
		// Past Reports
		var preport = res.data.reportsData.pastReports;
		for ( let dof in preport )
			{
				preport[dof]['sessionTime'] = moment(preport[dof]['scheduleDate']).format('MM/DD/YYYY @ h:mmA');
			}
		
		// Future Reports
		var freport = res.data.reportsData.futureReports;
		for ( let dof in freport )
			{
				freport[dof]['sessionTime'] = moment(freport[dof]['scheduleDate']).format('MM/DD/YYYY @ h:mmA');
			}
		
		// Billing Reports
		var breport = res.data.reportsData.billingReportLive;
		for ( let dof in breport )
			{
				breport[dof]['sessionTime'] = moment(breport[dof]['scheduleDate']).format('MM/DD/YYYY @ h:mmA');
			}
		
		// OnDemand Reports
		var odreport = res.data.reportsData.billingReportOnDemand;
		for ( let dof in odreport )
			{
				odreport[dof]['sessionTime'] = moment(odreport[dof]['createdAt']).format('MM/DD/YYYY @ h:mmA');
			}
		
		// OnDemand Reports
		var invreport = res.data.reportsData.pastInvoices;
		for ( let iof in invreport )
			{
				invreport[iof]['billDateFix'] = moment(invreport[iof]['billDate']).format('MM/DD/YYYY @ h:mmA');
			}
		
		var totalNum = [0, 0, 0, 0, 0];
		var nowarray = dreport;
		if ( this.state.currentSubTab == 'new' && this.state.currentTab == 'daily' ) { nowarray = nreport; }
		if ( this.state.currentTab == 'past' ) { nowarray = preport; }
		if ( this.state.currentTab == 'future' ) { nowarray = freport; }
		if ( this.state.currentTab == 'billing' && this.state.currentSubTab == 'live' )
			{
				nowarray = breport;
				
				for ( let onses of breport )
					{
						totalNum[1] += onses['totalAttending'];
						totalNum[2] += onses['totalBilled'];
					}
				totalNum[0] = breport.length;
				
				if ( totalNum[2] - this.state.planInfo['includedParticipants'] > 0 ) { totalNum[3] = (totalNum[2] - this.state.planInfo['includedParticipants']) * this.state.planInfo['additionalParticipants']; }
			}
		if ( this.state.currentTab == 'billing' && this.state.currentSubTab == 'ondemand' )
			{
				nowarray = odreport;
				
				for ( let onses of odreport )
					{
						totalNum[2] += onses['totalUnique'];
					}
				totalNum[0] = odreport.length;
				
				if ( totalNum[2] - this.state.planInfo['uniqueClients'] > 0 ) { totalNum[3] = (totalNum[2] - this.state.planInfo['uniqueClients']) * this.state.planInfo['additionalClient']; }
				if ( totalNum[0] - this.state.planInfo['recordingsNumber'] > 0 ) { totalNum[4] = (totalNum[0] - this.state.planInfo['recordingsNumber']) * this.state.planInfo['additionalRecording']; }
			}
		
		if ( this.state.currentTab == 'clients' && this.state.currentSubTab == 'live' ) { nowarray = res.data.reportsData.clientsLive; }
		if ( this.state.currentTab == 'clients' && this.state.currentSubTab == 'ondemand' ) { nowarray = res.data.reportsData.clientsOnDemand; }
		if ( this.state.currentTab == 'clients' && this.state.currentSubTab == 'livepayments' ) { nowarray = res.data.reportsData.liveSessionPayments; }
		if ( this.state.currentTab == 'clients' && this.state.currentSubTab == 'waivers' ) { nowarray = res.data.reportsData.waivers; }
		
		if ( this.state.currentTab == 'billing' && this.state.currentSubTab == 'invoices' ) { nowarray = invreport; }
		
		var couponChannels = res.data.reportsData.ambassadorCouponsChannels;
		var couponPayments = res.data.reportsData.ambassadorCouponsPayments;
		var couponCodes = res.data.reportsData.ambassadorCouponsCodes;
		if ( this.state.currentTab == 'billing' && this.state.currentSubTab == 'ambassador' ) 
			{ 
				if ( this.state.ambassadorSubTab === 'channels' ) { nowarray = couponChannels; }
				if ( this.state.ambassadorSubTab === 'payments' ) { nowarray = couponPayments; }
				if ( this.state.ambassadorSubTab === 'codes' ) { nowarray = couponCodes; }
			}
		
		console.log('nowarray');
		console.log(nowarray);
		
		var totalPages = [];
		if ( nowarray[0] && nowarray[0]['totalNumber'] ) { totalPages = Array(Math.ceil(nowarray[0]['totalNumber']/this.state.pageCount)).fill(null).map((_, i) => i+1); }
		if ( this.state.startPage > 2 ) { totalPages = totalPages.slice(this.state.startPage - 3, this.state.startPage + 2); } else { totalPages = totalPages.slice(0, 5); }
		
		this.setState({allHosts: res.data.reportsData.allHosts, allInterests: res.data.reportsData.allInterests, currentArray: nowarray, dailyReports: dreport, nowReports: nreport, ajaxLoaded: true, totalPages: totalPages, billingArray: totalNum});
		
		$('.main_loader').hide();
		
	}).catch(err =>{ $('.main_loader').hide(); });
}

updateList=(e)=>{
	if ( e['updateReload'] )
		{
			
			let pickedStartPage = false;
			if ( e['pickedStartPage'] )
				{
					pickedStartPage = true;
				}
			var mainthis = this;
			this.setState(e, function() {
				
				if ( window['updateTimeoutR'] ) { clearTimeout(window['updateTimeoutR']); }
				window['updateTimeoutR'] = setTimeout(function () 
				{
					if (e['firstStatus'])
						{
							mainthis.reloadData(pickedStartPage, 'noFilter');
						}
					else 
						{						
							mainthis.reloadData(pickedStartPage); 
						}
				}, 2000);
			});
		}
	else
		{
			console.log('elseeee');
			this.setState(e);
		}
}

loadData=(ind, ses)=>{
	var itemId = this.state.currentArray[ind].id;
	var userId = this.state.currentArray[ind].userId;
	
	var oldArr = this.state.currentArray
	oldArr[ind]['moreData'] = undefined;
	
	let ak = JSON.parse(localStorage.getItem('userData'));
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/loadReportsData", {channelId: ak.data.responseData.channel, itemId: itemId, itemType: this.state.currentTab, itemSubType: this.state.currentSubTab, userId : userId, startDate: this.state.startDate, endDate: this.state.endDate, about_session: ses})          
		.then(res => {
			
			
			if ( res.data.loadedData && res.data.loadedData[0] )
				{
					oldArr[ind]['moreData'] = res.data.loadedData;
				}
			else
				{
					oldArr[ind]['expandRow'] = false;
				}
			
			this.setState({currentArray: []});
			this.setState({currentArray: oldArr});
			
		}).catch(err =>{ console.log('----------there is problem------------', err); });
	
}

getFaq=(e)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqsById", {id: e})          
	.then(res => {
		if(e == 39)
			{
				this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs, 39)});
			}
			
		else
			{
				this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs)});
			}
	})
	.catch(err =>{ });  
}

updateStateFromChild=(state)=>{
	this.setState(state);
}

	
render() {
	
    return (<div className="container-fluid custom-width">
			<div className="noprint"><Header loginName={this.state.loginName} totalSession={this.state.totalSession} fullsubdomain={this.state.fullsubdomain}></Header></div>
			
			<div className="clearfix"></div>
			<div className="gray-box reportsholdn">
				<div className="reports_main_title noprint">Channel Reports <img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb reports_bulb" onClick={e=>{this.getFaq(39)}}/></div>
				<div className="closePopup noprint" onClick={e=>{ browserHistory.push('/DashboardLanding') }}></div>
				
				<div className="reports_activity_hold noprint">
					<div className={ "reports_session_hold " + ( this.state.currentTab === 'daily' ? "reports_active" : "" ) } onClick={e=>{this.changeTab('daily')}}>
						<div className="reports_session_title">Daily Activity</div>
						<div className="reports_session_title_image daily_icon"></div>
						<div className="reports_session_box"></div>
					</div>
					<div className={ "reports_session_hold " + ( this.state.currentTab === 'past' ? "reports_active" : "" ) } onClick={e=>{this.changeTab('past')}}>
						<div className="reports_session_title">Past Classes</div>
						<div className="reports_session_title_image past_sessions_iocn"></div>
						<div className="reports_session_box"></div>
					</div>
					<div className={ "reports_session_hold " + ( this.state.currentTab === 'future' ? "reports_active" : "" ) } onClick={e=>{this.changeTab('future')}}>
						<div className="reports_session_title">Future Classes</div>
						<div className="reports_session_title_image"></div>
						<div className="reports_session_box"></div>
					</div>
					<div className={ "reports_session_hold " + ( this.state.currentTab === 'clients' ? "reports_active" : "" ) } onClick={e=>{this.changeTab('clients')}}>
						<div className="reports_session_title">Clients</div>
						<div className="reports_session_title_image billing_icon"></div>
						<div className="reports_session_box"></div>
					</div>
					<div className={ "reports_session_hold " + ( this.state.currentTab === 'billing' ? "reports_active" : "" ) } onClick={e=>{this.changeTab('billing')}}>
						<div className="reports_session_title">Billing</div>
						<div className="reports_session_title_image usage_icon"></div>
						<div className="reports_session_box"></div>
					</div>
				</div>
				 
				{ ( this.state.currentTab === 'daily' || this.state.currentTab === 'past' || this.state.currentTab === 'future' ) && <ReportsDaily loadData={this.loadData} allHosts={this.state.allHosts} currentSubTab={this.state.currentSubTab} currentArray={this.state.currentArray} startPage={this.state.startPage} totalPages={this.state.totalPages} ajaxLoaded={this.state.ajaxLoaded} allInterests={this.state.allInterests} updateList={this.updateList} currentTab={this.state.currentTab} startDate={this.state.startDate} endDate={this.state.endDate}></ReportsDaily> }
				{ ( this.state.currentTab === 'billing' ) && <ReportsBilling loadData={this.loadData} billingArray={this.state.billingArray} planInfo={this.state.planInfo} allHosts={this.state.allHosts} currentSubTab={this.state.currentSubTab} currentArray={this.state.currentArray} startPage={this.state.startPage} totalPages={this.state.totalPages} ajaxLoaded={this.state.ajaxLoaded} allInterests={this.state.allInterests} updateList={this.updateList} currentTab={this.state.currentTab} couponType={this.state.couponType} updateStateFromChild={this.updateStateFromChild} reloadData={this.reloadData}></ReportsBilling> }
				{ ( this.state.currentTab === 'clients' ) && <ReportsClient loadData={this.loadData} allHosts={this.state.allHosts} currentSubTab={this.state.currentSubTab} currentArray={this.state.currentArray} startPage={this.state.startPage} totalPages={this.state.totalPages} ajaxLoaded={this.state.ajaxLoaded} allInterests={this.state.allInterests} updateList={this.updateList} currentTab={this.state.currentTab} startDate={this.state.startDate} endDate={this.state.endDate}></ReportsClient> }
			
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>);
}

}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(Reports);