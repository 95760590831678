import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { browserHistory} from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import Sortable from 'react-sortablejs';
import uniqueId from 'lodash/uniqueId';
class SessionEquipmentList extends Component {
  
  constructor(props) {
    super(props);
	this.scriptValidate = new SimpleReactValidator(); 
}

openInfoPopUp = (e, mod) => {
	if(mod == 'over')
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active');
			$(e.currentTarget).find('.tooltip_hold').addClass('tooltip_active');
		}
		
	else
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active');
		}
	
}

  render() {
    return (
			<div>
				<div className="no-border-radius">
					<div className="px-4 pb-3">
						<div className="row">
							<div className="for_form_shop">
								<div className="inline_shop_widder widder_eq">
									<div className="form-group mt-3 mb-1">
										<span className="cover-border cover-border_create"></span>
										<label className="label">Item Name</label>
										<input type="text" className="input-field" id="itemNameShop" value={this.props.state.itemNameShop} onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} maxlength="500" />
									</div>
								</div>
								<div className="inline_shop_smaller relative">
									<div className="quantity_title_creation">Quantity</div>
									<div className="inline_quantity left_quant math_signs" onClick={ () => this.props.changeQuantity('-') }>-</div>
									<div className="inline_quantity main_quant">{this.props.state.shopQuantity}</div>
									<div className="inline_quantity right_quant math_signs" onClick={ () => this.props.changeQuantity('+') }>+</div>
								</div>
								<div className="inline_shop_widder widder_eq">
									<div className="form-group mt-3 mb-1">
										<span className="cover-border cover-border_create"></span>
										<label className="label">Item Notes</label>
										<input type="text" className="input-field" id="itemNotesShop" maxLength="250" value={this.props.state.itemNotesShop} onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} />
									</div>
								</div>
								<div className="add_shop" onClick={this.props.addNewEquipment}></div>
							</div>
						</div>
					</div>
					
					{ this.props.state.equipmentListMain.length > 0 &&
						<div>
							<div className="for_shop_list equipment_inner_hold" onMouseLeave={this.props.handleDraggEnd}>
								<div className="equipment_list_hold">
									<div className="shop_titles equ_w">Item Name</div>
									<div className="shop_titles equ_w">Quantity</div>
									<div className="shop_titles equ_w">Item Notes</div>
									<div className="shop_titles equ_w"></div>
									<div onMouseUp={this.props.handleDraggEnd}>
										{this.props.state.equipmentListMain.map((equ, i)=>
											<div key={equ.id} data-itemkey={i} data-oldarrange={i}>
												{ i !== this.props.state.editEquIndex &&
													<div className="main_all" >
															<div className="one_row_shop">
																<div className="shop_titles white_clr equ_w">
																	{ equ.name.length <= 15 &&
																		<span>{equ.name}</span>
																	}
																	{ equ.name.length > 15 &&
																		<span>{equ.name.slice(0, 15)}...</span>
																	}
																</div>
																<div className="shop_titles white_clr paddl25 equ_w">{equ.Quantity}</div>
																<div className="shop_titles white_clr equ_w">
																	{ equ.Link.length == 0 &&
																		<span>-</span>
																	}
																	{ equ.Link.length > 0 && equ.Link.length <= 15 &&
																		<span>{equ.Link}</span>
																	}
																	{ equ.Link.length > 15 &&
																		<span>{equ.Link.slice(0, 15)}...</span>
																	}
																</div>
																<div className="shop_titles equ_w">
																	<div className="font-icn">
																		 <div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') } onClick={ () => this.props.editEqu(i) }><i className="fa fa-pencil" aria-hidden="true"></i>
																			<div className="tooltip_hold pink_info">
																				<div className="tooltip_text">Edit</div>
																			</div>
																		 </div>
																		 <div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') } onClick={ () => this.props.copyEqu(i) }><i className="fa fa-copy" aria-hidden="true"></i>
																			<div className="tooltip_hold pink_info">
																				<div className="tooltip_text">Duplicate</div>
																			</div>
																		 </div>
																		 <div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') } onClick={ () => this.props.removeEquipmentList(i) }><i className="fa fa-minus" aria-hidden="true"></i>
																			<div className="tooltip_hold pink_info">
																				<div className="tooltip_text">Remove</div>
																			</div>
																		 </div>
																		 { this.props.state.editEquIndex === -1 &&
																			 <div className="mr-1 bg-circle mr-lg-2 mb-1 pointer no_overflow_tools" onMouseDown={this.props.handleDraggStart} onMouseMove={this.props.handleDraggitem} onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><i className="fa fa-bars"  aria-hidden="true"></i>
																				<div className="tooltip_hold pink_info">
																					<div className="tooltip_text">Change Order</div>
																				</div>
																			 </div>
																		 }
																	</div>
																</div>
															</div>
														</div>
													}
													{ i === this.props.state.editEquIndex && this.props.state.editEquIndex > -1 &&
														<div className="for_create_edit">
															<div className="edit_equ_part">
																<div className="inline_shop_widder w95per">
																	<div className="form-group mt-3 mb-1">
																		<span className="cover-border cover-border_create"></span>
																		<input type="text" className="input-field" id="equipmentEditName" value={this.props.state.equipmentEditName} onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} maxlength="500" />
																	</div>
																</div>
															</div>
															<div className="edit_equ_part">
																<div className="inline_shop_smaller w95per">
																	<div className="inline_quantity left_quant math_signs" onClick={ () => this.props.changeQuantityEdit('-') }>-</div>
																	<div className="inline_quantity main_quant">{this.props.state.equipmentEditQuantity}</div>
																	<div className="inline_quantity right_quant math_signs" onClick={ () => this.props.changeQuantityEdit('+') }>+</div>
																</div>
															</div>
															<div className="edit_equ_part">
																<div className="inline_shop_widder w95per">
																	<div className="form-group mt-3 mb-1">
																		<span className="cover-border cover-border_create"></span>
																		<input type="text" className="input-field" id="equipmentEditNotes" value={this.props.state.equipmentEditNotes} onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} />
																	</div>
																</div>
															</div>
															<div className="edit_equ_part">
																<div className="edit_button_equ" onClick={this.props.closeEditEqu}>CLOSE</div>
																<div className="edit_button_equ" onClick={this.props.saveEditedEquipment}>SAVE</div>
															</div>
														</div>
													}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					}
					
				</div>
		 </div>
    );
  }
}

export default SessionEquipmentList;
