import React, { Component } from "react";
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import moment1 from 'moment-timezone';
import $ from 'jquery';
import axios from "axios";
import classnames from "classnames";

class MemberPopups extends Component {
constructor(props)
{
	super(props);
	
	this.state = {
	}

}

componentDidMount()
{
}

moveOn=()=>{
	console.log('---here---', this.props.state.chosenPopup);
	if ( this.props.state.chosenPopup == 'selectSinglePlan' || this.props.state.chosenPopup == 'selectFamilyPlan' || this.props.state.chosenPopup == 'switchToFamilyPlan' || this.props.state.chosenPopup == 'switchToSinglePlan' )
		{
			this.props.addMemberPlan();
		}
	if ( this.props.state.chosenPopup == 'cancelMembership' || this.props.state.chosenPopup == 'cancelTrial')
		{
			this.props.cancelMembership();
		}
	if ( this.props.state.chosenPopup == 'removeCard' )
		{
			this.props.deleteCreditCard();
			
		}
	if ( this.props.state.chosenPopup == 'closeAddFamilyMembers' )
		{
			this.props.updateStateFromChild({showPop: false, chosenPopup: '', membersWithPlan: []});
		}		
}

closePop=()=>{
	
	if ( this.props.state.chosenPopup == 'selectSinglePlan' || this.props.state.chosenPopup == 'selectFamilyPlan' )
		{
			this.props.updateStateFromChild({showPop: false, chosenPopup: '', planSelected: 0});
		}
	else if ( this.props.state.chosenPopup == 'switchToSinglePlan' || this.props.state.chosenPopup == 'switchToFamilyPlan' )
		{
			this.props.checkMemberPlan();
			this.props.updateStateFromChild({showPop: false, chosenPopup: '', planSelected: 0});
		}
	else if ( this.props.state.chosenPopup == 'closeAddFamilyMembers' )
		{
			this.props.updateStateFromChild({showPop: false, chosenPopup: ''});
			this.props.openEditMembers();
		}		
	else 
		{
			this.props.updateStateFromChild({showPop: false, chosenPopup: '', membersWithPlan: []});
		}
	
}

render() {
	
	return (
		<div className="booked_pop_content">
			<div className={classnames("booked_container " + ( this.props.state.chosenPopup == 'switchToSinglePlan' ? "switchContainer" : "" ) + ( this.props.state.chosenPopup == 'switchToFamilyPlan' ? "switchContainer" : "" ) )} >
				{this.props.state.chosenPopup == 'selectSinglePlan' &&
					<div className="profileTextAlign popColor">
						<div className="booked_title">Subscribe to Single User?</div>
						<div className={classnames("new_plan_type_holder " + (this.props.state.singlePlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.props.setSinglePlanType(e, 1, true)}} >
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder selectedPartHolder2" >
										<div className="selectedDivPart selectedDivPart2">
											<div className="innerSelectedDiv2"></div>
										</div>
									</div>
									<div className="innerPlanName innerPlanName2">Monthly:</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText">
										<span className="selectedPriceText">${this.props.state.singlePlanNextMonthPrice}</span>/mo
								</div>
							</div>
						</div>
						<div className={classnames("new_plan_type_holder " + (this.props.state.singlePlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.props.setSinglePlanType(e, 2, true)}} >
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder selectedPartHolder2" >
										<div className="selectedDivPart selectedDivPart2">
											<div className="innerSelectedDiv2"></div>
										</div>
									</div>
									<div className="innerPlanName innerPlanName2">Annual:</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText">
									{!this.props.state.codeApplied && !this.props.state.calculateFullPrice ?
										<span className="selectedPriceText smallerText">$16.99</span>
										:
										<span className="selectedPriceText smallerText">${(this.props.state.singleAnnualNextMonthPrice / 12).toFixed(2)}</span>
									}	
										/mo
								</div>
								<div className="calcPriceText">									
										<span className="selectedPriceText smallerText yr_text">(${this.props.state.singleAnnualNextMonthPrice})/yr</span>									
								</div>
							</div>
						</div>
						{ !this.props.state.calculateFullPrice &&
							<div className="saveTip">Save 30% by paying annually</div>
						}
					</div>
				}
				{this.props.state.chosenPopup == 'selectFamilyPlan' && 
					<div className="profileTextAlign popColor">
						<div className="booked_title">Subscribe to Family?</div>
						<div className={classnames("new_plan_type_holder " + (this.props.state.familyPlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.props.setFamilyPlanType(e, 1, true)}}>
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder selectedPartHolder2" >
										<div className="selectedDivPart selectedDivPart2">
											<div className="innerSelectedDiv2"></div>
										</div>
									</div>
									<div className="innerPlanName innerPlanName2">Monthly:</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText">
										<span className="selectedPriceText">${this.props.state.familyPlanNextMonthPrice}</span>/mo
								</div>
							</div>
						</div>
						<div className={classnames("new_plan_type_holder " + (this.props.state.familyPlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.props.setFamilyPlanType(e, 2, true)}} >
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder selectedPartHolder2" >
										<div className="selectedDivPart selectedDivPart2">
											<div className="innerSelectedDiv2"></div>
										</div>
									</div>
									<div className="innerPlanName innerPlanName2">Annual:</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText">
										{!this.props.state.codeApplied && !this.props.state.calculateFullPrice ?
											<span className="selectedPriceText smallerText">$24.99</span>
											:
											<span className="selectedPriceText smallerText">${(this.props.state.familyAnnualNextMonthPrice / 12).toFixed(2)}</span>
										}
										/mo
								</div>
								<div className="calcPriceText">
									<span className="selectedPriceText smallerText yr_text">(${this.props.state.familyAnnualNextMonthPrice})/yr</span>									
								</div>
							</div>						
						</div>
						{ !this.props.state.calculateFullPrice &&
							<div className="saveTip">Save 30% by paying annually</div>
						}
					</div>
				}
				{this.props.state.chosenPopup == 'cancelMembership' &&
					<div className="profileTextAlign">
						<div className="booked_title">Cancel Membership?</div>
						<div className="booked_title smallerFontProfile" >You will lose access to all Virdio benefits on {this.props.state.loseBenefitsDate}</div>
					</div>
				}
				{this.props.state.chosenPopup == 'cancelTrial' &&
					<div className="profileTextAlign">
						<div className="cancNewTitle">Are you sure you want to cancel membership?</div>
						<div className="cancNewH2" >You will lose access to all Virdio benefits immediately</div>
					</div>
				}
				{this.props.state.chosenPopup == 'switchToSinglePlan' &&
					<div className="profileTextAlign popColor">
						<div className="booked_title">Switch to Single User?</div>
						<div className="booked_title smallerFontProfile">Users on your family plan will lose access to their Virdio membership.</div>
						<div className={classnames("new_plan_type_holder " + (this.props.state.singlePlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.props.setSinglePlanType(e, 1, true)}} >
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder selectedPartHolder2" >
										<div className="selectedDivPart selectedDivPart2">
											<div className="innerSelectedDiv2"></div>
										</div>
									</div>
									<div className="innerPlanName innerPlanName2">Monthly:</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText">
										<span className="selectedPriceText">${this.props.state.singlePlanNextMonthPrice}</span>/mo
								</div>
							</div>
						</div>
						<div className={classnames("new_plan_type_holder " + (this.props.state.singlePlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.props.setSinglePlanType(e, 2, true)}} >
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder selectedPartHolder2" >
										<div className="selectedDivPart selectedDivPart2">
											<div className="innerSelectedDiv2"></div>
										</div>
									</div>
									<div className="innerPlanName innerPlanName2">Annual:</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText">
									{!this.props.state.codeApplied ?
										<span className="selectedPriceText smallerText">$16.99</span>
										:
										<span className="selectedPriceText smallerText">${(this.props.state.singleAnnualNextMonthPrice / 12).toFixed(2)}</span>
									}	
										/mo
								</div>
								<div className="calcPriceText">									
										<span className="selectedPriceText smallerText yr_text">(${this.props.state.singleAnnualNextMonthPrice})/yr</span>									
								</div>
							</div>
						</div>
						{ !this.props.state.calculateFullPrice &&
							<div className="saveTip">Save 30% by paying annually</div>
						}
					</div>
				}
				{this.props.state.chosenPopup == 'switchToFamilyPlan' &&
					<div className="profileTextAlign popColor">
						<div className="booked_title">Switch to Family?</div>
						<div className="booked_title smallerFontProfile">You will be given access to three additional memberships.</div>
						<div className={classnames("new_plan_type_holder " + (this.props.state.familyPlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.props.setFamilyPlanType(e, 1, true)}}>
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder selectedPartHolder2" >
										<div className="selectedDivPart selectedDivPart2">
											<div className="innerSelectedDiv2"></div>
										</div>
									</div>
									<div className="innerPlanName innerPlanName2">Monthly:</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText">
										<span className="selectedPriceText">${this.props.state.familyPlanNextMonthPrice}</span>/mo
								</div>
							</div>
						</div>
						<div className={classnames("new_plan_type_holder " + (this.props.state.familyPlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.props.setFamilyPlanType(e, 2, true)}} >
								<div className="planTypeInnerHolder">
									<div className="selectedPartHolder selectedPartHolder2" >
											<div className="selectedDivPart selectedDivPart2">
												<div className="innerSelectedDiv2"></div>
											</div>
										</div>
										<div className="innerPlanName innerPlanName2">Annual:</div>
								</div>
								<div className="planTypeInnerHolder rightPriceHolder">
									<div className="calcPriceText">
											{!this.props.state.codeApplied ?
												<span className="selectedPriceText smallerText">$24.99</span>
												:
												<span className="selectedPriceText smallerText">${(this.props.state.familyAnnualNextMonthPrice / 12).toFixed(2)}</span>
											}
											/mo
									</div>
									<div className="calcPriceText">
										<span className="selectedPriceText smallerText yr_text">(${this.props.state.familyAnnualNextMonthPrice})/yr</span>									
									</div>
								</div>						
							</div>
							{ !this.props.state.calculateFullPrice &&
								<div className="saveTip">Save 30% by paying annually</div>
							}
					</div> 
				}
				{this.props.state.chosenPopup == 'membershipCanceled' &&
					<div className="profileTextAlign">
						<div className="booked_title">Membership Cancelled</div>
						<div className="booked_title smallerFontProfile cancMembershipImg" >We hope you come back soon.</div>	
					</div>
				}
				{this.props.state.chosenPopup == 'removeCard' &&
					<div className="profileTextAlign removeCardText">
						<div className="booked_title">Remove Card?</div>
						<div className="booked_title smallerFontProfile" >The card ending in {this.props.state.removeCardNum} will be removed.</div>	
						{this.props.state.memberCreditCards.length == 1 &&
							<div className="booked_title smallerFontProfile" >You will need to <span className="addCardSpanColor">add another payment method to continue</span> your membership</div>	
						}
					</div>
				}
				{this.props.state.chosenPopup == 'membersHavePlan' &&
					<div className="profileTextAlign removeCardText">
						<div className="booked_title">Added members already have active plan</div>
						<div className="booked_title smallerFontProfile" >There is a list of members with a plan: </div>	
						{this.props.state.membersWithPlan.map((member, i)=>
							<div key={i} className="booked_title smallerFontProfile" >{member}</div>
						)}
					</div>
				}
				{this.props.state.chosenPopup == 'closeAddFamilyMembers' &&
					<div className="profileTextAlign">
						<div className="booked_title">Cancel without adding members?</div>
						<div className="booked_title smallerFontProfile">You haven’t added any members. You can come back and add them at any time.</div>	
					</div>
				}
				
				{this.props.state.chosenPopup == 'failedPayment' &&
					<div className="profileTextAlign">
						<div className="booked_title">You have failed payments</div>
						<div className="booked_title smallerFontProfile">In order to proceed you need to add credit card</div>	
					</div>
				}
				{this.props.state.chosenPopup != 'cancelTrial' && this.props.state.chosenPopup != 'cancelMembership' &&
					<div>
						{this.props.state.chosenPopup == 'failedPayment' ?
							<div className="openBtnHolder" onClick={() => this.props.openAddDefCreditCard()} >
								<div className="active_booked_bnt">Open add credit card</div>
							</div>
							:
							<div>
								{ this.props.state.chosenPopup != 'membershipCanceled' && this.props.state.chosenPopup != 'membersHavePlan' ? 
									<div className="booked_bnts_content">
										<div className="inactive_booked_bnt" onClick={() => this.closePop()} >No</div>
										<div className="active_booked_bnt" onClick={() => this.moveOn()}>Yes</div>
									</div>
									:
									<div className="booked_bnts_content">
										<div className="active_booked_bnt dontBtnWidth" onClick={() => this.closePop()} >Done</div>
									</div>
								}
							</div>
						}
					</div>
				}
				{ ( this.props.state.chosenPopup == 'cancelTrial' || this.props.state.chosenPopup == 'cancelMembership' ) &&
					<div>
						<div className="booked_bnts_content">
							<div className="cancPopupButtons" onClick={() => this.moveOn()}>Yes</div>
							<div className="cancNoButton" onClick={() => this.closePop()} >No</div> 
						</div>
					</div>
				}
			</div>
		</div>
    );
}

}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(MemberPopups);