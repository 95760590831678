import { ADD_PLAYLIST, ADD_ELECTRON_PLAYLIST, RESET_SONG_TO_ADD, ADD_PLAYLIST_SONGS, CHANGE_PLAYLIST_STATUS, UPDATE_PLAYLISTS, DELETE_PLAYLIST } from "./types";
import store from '../store';
import { readableDuration } from '../services/convertAudio';
import { browserName as browName} from "react-device-detect";
import axios from 'axios';
import $ from 'jquery';

export const addPlaylist = content => ({
  type: ADD_PLAYLIST,
  payload: {
    content
  }
});

export const addElectronPlist = () => ({
	type: ADD_ELECTRON_PLAYLIST
});

export const addPlaylistSongs = content => ({
  type: ADD_PLAYLIST_SONGS,
  payload: {
    content
  }
});

export const resetSongList = () => ({
	type: RESET_SONG_TO_ADD
});

export const changePlaylistStatus = () => ({
	type: CHANGE_PLAYLIST_STATUS
});

export const updatePlaylists = content => ({
	type: UPDATE_PLAYLISTS,
	payload: {
		content
	}
});

export const deletePlaylist = playlist_id => dispatch => {
	
	let user_token = JSON.parse(localStorage.getItem("userData"));

	 axios
    .post(process.env.REACT_APP_NAME+"/api/v1/playlists/deleteplaylist", { 'playlist_id': playlist_id }, {headers: {'Authorization': user_token.data.responseData.token}})
    .then(() => { dispatch({type: DELETE_PLAYLIST, payload: {playlist_id: playlist_id } }) }  )
    .catch(err =>{});
};

export function addAsynPlaylists(content) {
	const saved_state = store.getState().menagePlaylist.playlistsToAdd;
	var new_playlists = {
		"playlist_name": content,
		"num_songs": saved_state.new_songs.length,
		"playlist_length": readableDuration(saved_state.real_length),
		"songs": saved_state.new_songs,
		"browser": browName
	  }
	
	return function (dispatch) {
		
		let user_token = JSON.parse(localStorage.getItem("userData"));
		
		axios.post(process.env.REACT_APP_NAME+"/api/v1/playlists/addplaylist", { new_playlists }, {headers: {'Authorization': user_token.data.responseData.token}})
		.then(res => {
			new_playlists['playlist_id'] = res.data.responseData.playlist.playlist_id;
			dispatch(addPlaylist(new_playlists));
			window.$('#pickPlaylistType').modal('hide');
		  });
	} 
}


export function addAsynPlaylistSongs(content) {
	return function (dispatch) {
		setTimeout(() => {
			dispatch(addPlaylistSongs(content))
		}, 500);
	}
}