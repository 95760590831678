import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { browserHistory} from 'react-router'
import { connect } from "react-redux";
import { togglePlaylistPopup } from '../../actions/popups';
import { showMainErrorPop } from '../../actions/popups';
class SessionPlaylistAndHost extends Component {
  
  constructor(props) {
    super(props); 
}

	
  
	render() {
    return (
			<div>
				{ ((!this.props.state.isMarketplaceChannel) || (this.props.state.pickedReplayId == 0 && this.props.state.isMarketplaceChannel && this.props.state.userPermissions.canChangeInstructor)) && !this.props.state.editReplayClass &&
					<div className="one_section_right first_right_section">
						<div className="section_right_title">Current Instructor</div>
						<div className="inline_bulb" onClick={e=>{this.props.getFaq(26)}}></div>
						{ this.props.state.isAdmin === 1 && this.props.state.hostList.length > 1 &&
							<div className="inline_add_main" data-toggle="modal" data-target="#myHost"></div>
						}
						{ this.props.state.hostName && this.props.state.hostName !== "" &&
							<div className="for_button_right_section">
								<div className="right_section_chosen">{ ((this.props.state.hostName).length > 21) ? (((this.props.state.hostName).substring(0,21-3)) + '...') : this.props.state.hostName }</div>
							</div>
						}
					</div>
				}
				{ this.props.state.pickedReplayId == 0 &&
					<div className={('one_section_right'+(this.props.state.isMarketplaceChannel ? ' d-none' : ''))}>
						<div className="section_right_title">My Playlist</div>
						<div className="inline_bulb" onClick={e=>{this.props.getFaq(15)}}></div>
						<div className="inline_add_main" onClick={(e) => this.props.togglePlaylistPopup(true)}></div>
						{ this.props.state.selectedPlaylist['playlist_name'] && 
							<div className="for_button_right_section">
								<div className="right_section_chosen">{ ((this.props.state.selectedPlaylist['playlist_name']).length > 21) ? (((this.props.state.selectedPlaylist['playlist_name']).substring(0,21-3)) + '...') : this.props.state.selectedPlaylist['playlist_name'] }
									<div className="remove_iks" onClick={ this.props.removeFromSelectedPlaylists }></div>
								</div>
							</div>
						}
					</div>
				}
				{ (this.props.state.isMarketplaceChannel && this.props.state.userPermissions.addReplayClasses) &&
					<div className="one_section_right mt20">
						<div className="section_right_title">Replay Classes</div>
						<div className="inline_add_main margin_inline" onClick={() => this.props.updateStateFromChild({isOpenReplay: true})}></div>
						{ this.props.state.pickedReplayId > 0 &&
							<div className="for_button_right_section">
								<div className="right_section_chosen relative_chosen">{this.props.state.replayName}
									<div className="close_chosen" onClick={() => this.props.updateStateFromChild({pickedReplayId: 0})}></div>
								</div>
							</div>
						}
					</div>
				}
			</div>
    );
  }
}
export default connect(
  null,
  { 
	togglePlaylistPopup,
	showMainErrorPop
  }
)(SessionPlaylistAndHost);
