import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";
import Countdown from 'react-countdown-now';

import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import { getUsername } from '../../util/getUsername';

class ParticipantDashboardUpcomingSessions extends Component {
  
  constructor(props) {
	super(props);
	this.state={
		months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    }
	
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
	this.getUsername = getUsername.bind(this);
}
 
componentDidMount(){
}

getDate(date)
{
	let day = new Date(date).getDate();
	let month = this.state.months[new Date(date).getMonth()];
	let year = new Date(date).getFullYear();
	let finalDate = day + ' ' +  month + ', ' + year;
	return finalDate;
}

canEditClass=(item)=>{
	var canEdit = false;
	if ( this.props.state.isHostDashboard == 1 )
		{
			if ( !this.props.state.isMarketplaceChannel )
				{
					if ( item.isDemoSession == 0 && (( item.duration<1500 || this.props.state.isAdmin == 1) || ( (item.hostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id) ||  this.props.state.isAdmin == 1 )) )
						{
							canEdit = true;
						}
				}
			else
				{
					if ( this.props.state.userPermissions.editClass )
						{
							canEdit = true;
						}
				}
		}
		
	return canEdit;
}

  render() {
	 return (
       <div className="marg_tp10">
			{ this.props.state.sessionPreview === 'square' &&
				<div className="all_demain_view">
				{this.props.state.upcomingSessionArray.map((item, i)=>
					<div className={"demand_preview_square "+ (this.props.state.isHostDashboard == 1 ? "host_more_hg":"more_hg")} key={i} >
						<div className="all_inner_square new_back auto_cursor padd_participant pb120">
							<div className="footer_pad_participant">
								<div className="pdash_50 txt_lft top_align">
									<div className="pdash_fnt16">
										<span className="relative_interest">{item.interestTitles}{item.sessionPublicChannel == 0 ? ' [H]' : ''}
											{ !this.props.state.isMarketplaceChannel &&
												<div className="">
													<div className="level_title">{item.level}</div>
														{ item.level === 'Beginner' &&
															<div className="level_square green_square"></div>
														}
														{ item.level === 'Intermediate' &&  
															<div className="level_square yellow_square"></div>
														}
														{ item.level === 'Advanced' &&
															<div className="level_square red_square"></div>
														}
														{ item.level === 'Multi-Level' &&
															<div className="level_square multi_level_square"></div>
														}
												</div>
											}
										</span>
										{item.replayClassId > 0 &&
											<span className="replaySpanText">Replay</span>
										}
									</div>
								</div>
								<div className="pdash_50 txt_rght">
								{this.props.state.isHostDashboard == 0 &&
									<button className="new_view_button upc_list_canc"  id={item.id} name={i} onClick={() => {this.props.openClosePop(item, i)}} >CANCEL</button>
								}
								{ this.canEditClass(item) &&
									<button className="new_view_button upc_list_canc edit_marg_btn host_btn_padd margb10"  id={item.id} name={i} onClick={() => this.props.editableMode(item, i)}>EDIT</button>
								}
								{this.props.state.isHostDashboard == 1 && (this.props.state.isAdmin == 1 && ( item.duration<1500 || this.props.state.isAdmin == 1) || ( (item.hostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id) ||  this.props.state.isAdmin == 1 )) &&
									<button className="new_view_button upc_list_canc host_btn_padd"  id={item.id} name={i} onClick={() => {this.props.cancelSessionModal(item, i)}} >CANCEL</button>
								}
								</div>
							</div> 
							<div className="footer_pad">
								<div className="footer_square100 main_color_part mb0 font17 ch400">{item.name}
									{item.parentRecrId>0?
										<img src="../images/r-icon.png" className="ml-3 align-self-start w-auto cursor-pointer px-2" data-toggle="modal" data-target="#recurringInfo" id={item.parentRecrId} name = {i} onClick={this.props.getRecurringSessionData} />
										:
										'' 
									}
								</div>
								{this.props.state.isHostDashboard == 1 &&
								<div>	
									{ item.totalParticipantsNum && item.totalParticipantsNum > 0 ?
										<div>
											<p className="mr-3 font_size_dash">Signed Up {item.totalSignUp}/{item.maxAttendee} (max)
												<span className="w20 marg_left_span"><img src="/images/information.png" name = {item.id}  className="mr-3 w-20 cursor-pointer mr0"  alt="" onClick={this.props.participantShowList} /></span>
											</p>
										</div>
										:
										<p className="mr-3 font_size_dash">Signed Up 0/{item.maxAttendee} (max)</p>
									
									}
								</div>
								}	
								<div className="all_width_participant">
									{ this.props.state.isHostDashboard == 0 &&
										<div className="host_name_prt vt_mdl breakW">{item.firstName} {item.lastName}</div>
									}
									{ this.props.state.isHostDashboard == 1 &&
										<div className="host_name_prt vt_mdl breakW">{this.getUsername(item.hostFirstName, item.hostLastName)}</div>
									}
									<div className="inline_parts_participant">
										<div className="inline_parts_img time_img"></div>
										{ this.getSessionDate(item.scheduleDate)} @ {this.getSessionTime(item.scheduleDate)}
									</div>
									<div className="inline_parts_participant">
										<div className="inline_parts_img clock_img"></div>
										{item.duration} Min
									</div>
								</div>
							</div>
							<div className="footer_pad marg_tp10">							
								{ item.description && item.description != null && item.description.length <= 50 &&
									<div className="relative pl25 pr25 description_demand text_wrap btn_disp">{item.description}
										<div className="icon_foot_part_l list_img"></div>
									</div>
								}
								{ item.description && item.description != null && item.description.length > 50 &&
									<div className="relative pl25 pr25 description_demand btn_disp desc_show_width">
										<span className="dots_text">{item.description}</span>
										<div className="icon_foot_part_l list_img"></div>
										<div className={"icon_foot_part_r plus_main " + (item.descriptionDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openUpcDrops(e, i, 'descriptionDropOpen') }}></div>
										{ item.descriptionDropOpen && 
											<div className="abs_demand_drop drops_more" onClick={this.props.preventDef}>{item.description}</div>
										}
									</div>
								}
							</div>							
							{ item.equipmentListName && item.equipmentListName != '' &&
								<div className={"new_list_mych "+(this.props.state.isHostDashboard == 0 ? "w50per_participant" : "w70per_participant")}>
									{ item.equipmentListName.length <= 30 &&
										<div className="description_demand relative pl25 pr25">{item.equipmentListName}
											<div className="icon_foot_part_l wrench_img"></div>									
										</div>											
									}
									{ item.equipmentListName.length > 30 &&
										<div className="description_demand relative pl25 pr25"><span className="dots_text">{item.equipmentListName}</span>
										<div className="icon_foot_part_l wrench_img"></div>
										<div className={"icon_foot_part_r plus_main " + (item.equipmentDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openUpcDrops(e, i, 'equipmentDropOpen') }}></div>
											{ item.equipmentDropOpen &&
												<div className="abs_demand_drop drops_more" onClick={this.props.preventDef}>{item.equipmentListName}</div>
											}
										</div>
									}
								</div>
							}
							{this.props.state.isHostDashboard == 1 && !this.props.state.isMarketplaceChannel &&
								<div className="footer_pad abs_btns_participants new_abs_pos">
										<div className="pdash_50 txt_lft new_align_upc">	
											<div className="new_view_button upc_list_canc btn_disp host_btn_padd link_zIndex" id={item.groupId} name={item.sessionLink} onClick={this.props.uneditableMode} >CLASS LINK</div>								
										</div>
									<div className={"pdash_50 pdash_marg_tp10 new_align_upc " + (!this.props.state.isMarketplaceChannel ? 'txt_rght' : '')}>
										<div className="new_view_button upc_list_canc btn_disp host_btn_padd chpad1014" onClick={e=>{this.props.openAddMember(item)}} >SIGN UP/INVITE</div>
									</div>
								
								</div>
							}
							<div className="footer_pad abs_btns_participants">
								<div className="pdash_50 txt_lft new_align_upc ch_tim">	
									{this.props.state.isHostDashboard == 0 &&
										<Countdown date={Date.now() + ((new Date(item.scheduleDate).getTime()-new Date().getTime()))} renderer={this.props.renderer}></Countdown> 
									}
									{this.props.state.isHostDashboard == 1 && item.hostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id &&
										<Countdown date={Date.now() + ((new Date(item.scheduleDate).getTime()-new Date().getTime()))} renderer={this.props.renderer}></Countdown> 
									}
								</div>
								{ item.activityArray && item.activityArray.length > 0 &&
									<div className="pdash_50 txt_rght pdash_marg_tp10 new_align_upc">
										<div className="new_view_button upc_list_canc btn_disp new_fnt11" onClick={e=>{this.props.openActivityPop(item)}} >VIEW ACTIVITY LIST</div>
									</div>
								}
							</div>
						</div>
					</div>
					)}
					{this.props.state.upcDataLength > this.props.state.upcShow &&
						<div className="txt_center marg_tp30">
							<div className="pdash_button_more relative" onClick={this.props.showMoreUpc} >Load more
								<div className="down_angle"></div>
							</div>
						</div>
					}
					{/*<div>
						{this.props.state.mainDemandObject.allDemandSession.length == 0 &&
							<div className="channel_no_results">No results found</div>
						}
					</div>*/}
				</div>
			}
			{this.props.state.sessionPreview === 'list' &&
				<div className="all_demain_view">
					{this.props.state.upcomingSessionArray.map((item, i)=>
						<div className={"listHolder_mych list_holderpadb padding_bottom10 new_list_padding_upc addpad70 " +(i%2 == 0 ? '' : 'list_2nd_upcpart ' ) + (this.props.state.isHostDashboard == 1 ? "host_list_holder" : "host_list_holder") } key={i} >
							<div className="relative_list_info relative_list_right nprght">
								{this.props.state.isHostDashboard == 0 ? 
									<div className="abs_demand_image mych_abs_image auto_cursor h98per list_img_mobile" style={{backgroundImage: "url('" + item.image + "')"}} ></div>
									:
									<div className="abs_demand_image mych_abs_image auto_cursor h98per list_img_mobile" style={{backgroundImage: "url('" + item.sessionImage + "')"}} ></div>
								}
								<div className="list_header mb0 relative_header_list">
									<span className="relative_interest">{item.interestTitles}
										{ !this.props.state.isMarketplaceChannel &&
											<div className="abs_levelsupc">
												<div className="level_title">{item.level}</div>
													{ item.level === 'Beginner' &&
														<div className="level_square green_square"></div>
													}
													{ item.level === 'Intermediate' &&  
														<div className="level_square yellow_square"></div>
													}
													{ item.level === 'Advanced' &&
														<div className="level_square red_square"></div>
													}
													{ item.level === 'Multi-Level' &&
														<div className="level_square multi_level_square"></div>
													}
											</div>
										}
									</span>
									{item.replayClassId > 0 &&
										<span className="replaySpanText">Replay</span>
									}
								</div>
								<div className="footer_pad">
									<div className="main_color_part mb0 font17 disp-new">{item.name}
										{item.parentRecrId>0?
											<img src="../images/r-icon.png" className="ml-3 align-self-start w-auto cursor-pointer px-2" data-toggle="modal" data-target="#recurringInfo" id={item.parentRecrId} name = {i} onClick={this.props.getRecurringSessionData} />
											:
											'' 
										}
									</div>
									{this.props.state.isHostDashboard == 0 &&
										<div className="host_name_prt_list breakW">{item.firstName} {item.lastName}</div>
									}
									{this.props.state.isHostDashboard == 1 &&
										<div className="host_name_prt_list breakW">{item.hostFirstName} {item.hostLastName}</div>
									}
									{this.props.state.isHostDashboard == 1 &&
										<div>
											{ item.totalParticipantsNum && item.totalParticipantsNum > 0 ?
												<div>
													<p className="mr-3 font_size_dash">Signed Up {item.totalSignUp}/{item.maxAttendee} (max)
														<span className="w20 marg_left_span"><img src="/images/information.png" name = {item.id}  className="mr-3 w-20 cursor-pointer mr0"  alt="" onClick={this.props.participantShowList} /></span>
													</p>
												</div>
												:
												<p className="mr-3 font_size_dash">Signed Up 0/{item.maxAttendee} (max)</p>
											}
										</div>
									}
									<div className="all_width_participant">
										<div className="inline_parts_participant">
											<div className="inline_parts_img time_img"></div>
											{ this.getSessionDate(item.scheduleDate)} @ {this.getSessionTime(item.scheduleDate)}
										</div>
										<div className="inline_parts_participant">
											<div className="inline_parts_img clock_img"></div>
											{item.duration} Min
										</div>
									</div>
								</div>
								<div className="footer_pad marg_tp10 pb0">							
									{ item.description && item.description != null && item.description.length <= 50 &&
										<div className="relative pl25 pr25 description_demand text_wrap btn_disp">{item.description}
											<div className="icon_foot_part_l list_img"></div>
										</div>
									}
									{ item.description && item.description != null && item.description.length > 50 &&
										<div className="relative pl25 pr25 description_demand btn_disp desc_show_width_list mw80per">
											<div className="desc_holder" >
												<span className="dots_text">{item.description}</span>
											</div>
											<div className="icon_foot_part_l list_img"></div>
											<div className={"icon_foot_part_r plus_main desc_holder displ_plus " + (item.descriptionDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openUpcDrops(e, i, 'descriptionDropOpen') }}></div>
											{ item.descriptionDropOpen && 
												<div className="abs_demand_drop desc_holder drops_more" onClick={this.props.preventDef}>{item.description}</div>
											}
										</div>
									}
								</div>
								{ item.equipmentListName && item.equipmentListName != '' &&
									<div className="footer_pad">
										<div>
											{ item.equipmentListName.length <= 50 &&
												<div className="description_demand relative pl25 pr25 desc_show_width_list">{item.equipmentListName}
													<div className="icon_foot_part_l wrench_img"></div>									
												</div>											
											}
											{ item.equipmentListName.length > 50 &&
												<div className="description_demand relative pl25 pr25 btn_near_text">
												<div className="desc_holder" >
													<span className="dots_text">{item.equipmentListName}</span>
												</div>
												<div className="icon_foot_part_l wrench_img"></div>
												<div className={"icon_foot_part_r plus_main desc_holder displ_plus " + (item.equipmentDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openUpcDrops(e, i, 'equipmentDropOpen') }}></div>
												{ item.equipmentDropOpen &&
													<div className="abs_demand_drop drops_more eq_drop" onClick={this.props.preventDef}>{item.equipmentListName}</div>
												}
											</div>
											}
										</div>
									</div>		
								}
								{ item.equipmentListName === '' &&
									<div className="empty_equipment"></div>
								}
							</div>
								<div className="canc_btn_holder_upcpart" > 
									{this.props.state.isHostDashboard == 0 &&
										<button className="new_view_button upc_list_canc"  id={item.id} name = {i}  onClick={() => {this.props.openClosePop(item, i)}} > CANCEL </button> 
									}
									
									{this.props.state.isHostDashboard == 1 && (this.props.state.isAdmin == 1 && ( item.duration<1500 || this.props.state.isAdmin == 1) || ( (item.hostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id) ||  this.props.state.isAdmin == 1 )) &&
										<button className="new_view_button2 canc_btn_width"  id={item.id} name = {i}  onClick={() => {this.props.cancelSessionModal(item, i)}} > CANCEL </button> 
									}
								</div>
								
								
							<div className="btns_holder_list" >
								<div className="btns_list_part" >
																
										{this.props.state.isHostDashboard == 0 &&
											<Countdown date={Date.now() + ((new Date(item.scheduleDate).getTime()-new Date().getTime()))} renderer={this.props.renderer2}></Countdown>	
										}
										{this.props.state.isHostDashboard == 1 && item.hostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id &&										
											<Countdown date={Date.now() + ((new Date(item.scheduleDate).getTime()-new Date().getTime()))} renderer={this.props.renderer2}></Countdown>	
										}
									
								</div>
							
								{item.activityArray && item.activityArray.length > 0 &&
									<div className="btns_list_part left_marg_btns">								
											<button className="new_view_button2 marg_act_btn ch_butt_new" onClick={e=>{this.props.openActivityPop(item)}} > VIEW ACTIVITY LIST </button> 					
									</div>
								}
							
								{this.props.state.isHostDashboard == 1 && !this.props.state.isMarketplaceChannel && 
									<div className="btns_list_part left_marg_btns" >					
										<div className="ch_inline">
											<button className="new_view_button2"  id={item.groupId} name={item.sessionLink} onClick={this.props.uneditableMode}>CLASS LINK</button> 
										</div>								
										<div className="ch_inline">
											<button className="new_view_button2 left_marg_btns"  onClick={e=>{this.props.openAddMember(item)}}>SIGN UP/INVITE</button> 
										</div>
									</div>
								}
								{ this.canEditClass(item) &&
									<div className="btns_list_part left_marg_btns" >	
										<button className="new_view_button2"  id={item.id} name = {i}  onClick={() => this.props.editableMode(item, i)} > EDIT </button> 
									</div>
								}
							</div>
						</div>
						)}
					{this.props.state.upcDataLength > this.props.state.upcShow &&
						<div className="txt_center marg_tp30">
							<div className="pdash_button_more relative" onClick={this.props.showMoreUpc} >Load more
								<div className="down_angle"></div>
							</div>
						</div>
					}	
					<div>
						{this.props.state.sessionData.length == 0 && this.props.state.isHostDashboard == 0 &&
							<div className="channel_no_results">No results found</div>
						}
					</div>
				</div>
		}
		
		
		
		</div>
    );
  }
}
export default ParticipantDashboardUpcomingSessions;
