//   utils = {

// 	// Register User
// 	stateCode : () => {
// 		let stateCode=[{state:'Alabama',postalCode:'AL',Id:0},
//         {state:'Alaska',postalCode:'AK',Id:1},
//         {state:'American Samoa',postalCode:'AS',Id:2},
//         {state:'Arizona',postalCode:'AZ',Id:3},
//         {state:'Arkansas',postalCode:'AR',Id:4},
//         {state:'California',postalCode:'CA',Id:5},
//         {state:'Colorado',postalCode:'CO',Id:6},
//         {state:'Connecticut ',postalCode:'CT',Id:7},
//         {state:'Delaware',postalCode:'DE',Id:8},
//         {state:'Dist. of Columbia',postalCode:'DC',Id:9},
//         {state:'Florida',postalCode:'FL',Id:10}
//         ]
// 		return stateCode;
// 	}

// 	// getRemainingTime : (date) => {
// 	// 	// var countDownDate = new Date("Jan 5, 2021 15:37:25").getTime();
// 	// 	var countDownDate = new Date(date).getTime();

// 	// 	// Update the count down every 1 second
// 	// 	var x = setInterval(function() {

// 	// 	  // Get today's date and time
// 	// 	  var now = new Date().getTime();

// 	// 	  // Find the distance between now and the count down date
// 	// 	  var distance = countDownDate - now;

// 	// 	  // Time calculations for days, hours, minutes and seconds
// 	// 	  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
// 	// 	  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
// 	// 	  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
// 	// 	  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

// 	// 	  // Display the result in the element with id="demo"
// 	// 	  let rem = days + "d " + hours + "h "
// 	// 	  + minutes + "m " + seconds + "s ";
// 	// 	  console.log('rem=', rem)

// 	// 	  // If the count down is finished, write some text 
// 	// 	  if (distance < 0) {
// 	// 	    clearInterval(x);
// 	// 	    console.log("EXPIRED")
// 	// 	    // document.getElementById("demo").innerHTML = "EXPIRED";
// 	// 	  }
// 	// 	}, 1000);
// 	// }


// }

const utils = {
    stateCode:()=>{
		let stateCode=[{state:'Alabama',postalCode:'AL',Id:0},
        {state:'Alaska',postalCode:'AK',Id:1},
        {state:'American Samoa',postalCode:'AS',Id:2},
        {state:'Arizona',postalCode:'AZ',Id:3},
        {state:'Arkansas',postalCode:'AR',Id:4},
        {state:'California',postalCode:'CA',Id:5},
        {state:'Colorado',postalCode:'CO',Id:6},
        {state:'Connecticut ',postalCode:'CT',Id:7},
        {state:'Delaware',postalCode:'DE',Id:8},
        {state:'Dist. of Columbia',postalCode:'DC',Id:9},
        {state:'Florida',postalCode:'FL',Id:10},
        {state:'Georgia',postalCode:'GA',Id:11},
        {state:'Guam',postalCode:'GU',Id:12},
        {state:'Hawaii',postalCode:'HI',Id:13},
        {state:'Idaho',postalCode:'ID',Id:14},
        {state:'Illinois',postalCode:'IL',Id:15},
        {state:'Indiana',postalCode:'IN',Id:16},
        {state:'Iowa',postalCode:'IA',Id:17},
        {state:'Kansas',postalCode:'KS',Id:18},
        {state:'Kentucky',postalCode:'KY',Id:19},
        {state:'Louisiana',postalCode:'LA',Id:20},
        {state:'Maine',postalCode:'ME',Id:21},
        {state:'Maryland',postalCode:'MD',Id:22},
        {state:'Marshall Islands',postalCode:'MH',Id:23},
        {state:'Massachusetts',postalCode:'MA',Id:24},
        {state:'Michigan',postalCode:'MI',Id:25},
        {state:'Micronesia',postalCode:'FM',Id:26},
        {state:'Minnesota',postalCode:'MN',Id:27},
        {state:'Mississippi',postalCode:'MS',Id:28},
        {state:'Missouri',postalCode:'MO',Id:29},
        {state:'Montana',postalCode:'MT',Id:30},
        {state:'Nebraska',postalCode:'NE',Id:31},
        {state:'Nevada',postalCode:'NV',Id:32},
        {state:'New Hampshire',postalCode:'NH',Id:33},
        {state:'New Jersey',postalCode:'NJ',Id:34},
        {state:'New Mexico',postalCode:'NM',Id:35},
        {state:'New York',postalCode:'NY',Id:36},
        {state:'North Carolina',postalCode:'NC',Id:37},
        {state:'North Dakota',postalCode:'ND',Id:38},
        {state:'Northern Marianas',postalCode:'MP',Id:39},
        {state:'Ohio',postalCode:'OH',Id:40},
        {state:'Oklahoma',postalCode:'OK',Id:41},
        {state:'Oregon',postalCode:'OR',Id:42},
        {state:'Palau',postalCode:'PW',Id:43},
        {state:'Pennsylvania',postalCode:'OH',Id:44},
        {state:'Puerto Rico',postalCode:'PR',Id:45},
        {state:'Rhode Island',postalCode:'RI',Id:46},
        {state:'South Carolina',postalCode:'SC',Id:47},
        {state:'South Dakota',postalCode:'SD',Id:48},
        {state:'Tennessee',postalCode:'TN',Id:49},
        {state:'Texas',postalCode:'TX',Id:50},
        {state:'Utah',postalCode:'UT',Id:51},
        {state:'Vermont',postalCode:'VT',Id:52},
        {state:'Virginia',postalCode:'VA',Id:53},
        {state:'Virgin Islands',postalCode:'VI',Id:54},
        {state:'Washington',postalCode:'WA',Id:55},
        {state:'West Virginia',postalCode:'WV',Id:56},
        {state:'Wisconsin',postalCode:'WI',Id:57},
        {state:'Wyoming',postalCode:'WY',Id:58},
        ]
        return stateCode;
    },
    candastateCode:()=>{
        let stateCode=[{state:'Alberta',postalCode:'AB',Id:0},
        {state:'British Columbia',postalCode:'BC',Id:1},
        {state:'Manitoba',postalCode:'MB',Id:2},
        {state:'New Brunswick',postalCode:'NB',Id:3},
        {state:'Newfoundland and Labrador',postalCode:'NL',Id:4},
        {state:'Northwest Territories',postalCode:'NT',Id:5},
        {state:'Nova Scotia',postalCode:'NS',Id:6},
        {state:'Nunavut',postalCode:'NU',Id:7},
        {state:'Ontario',postalCode:'ON',Id:8},
        {state:'Prince Edward Island',postalCode:'PE',Id:9},
        {state:'Quebec',postalCode:'QC',Id:10},
        {state:'Saskatchewan',postalCode:'SK',Id:11},
        {state:'Yukon',postalCode:'YT',Id:12},
       
        ]
        return stateCode;
    }
}
    
  
   export default utils;
