import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';


class ReportsPlanDetails extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					activeTab: 'trial',
					trialArray: [],
					enterpriseArray: [],
				}
	
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.checkAllTrial();
}
 
componentWillUnmount() {
}

changeTab = (mod) => {
	
	this.setState({
		activeTab : mod,
	})
	
	if ( mod == 'trial' )
		{
			this.checkAllTrial();
		}
	else 
		{
			this.checkEnterpice();
		}		
}

checkAllTrial=e=>{
	$('.main_loader').attr({'style':'display:flex'});	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/check_all_trial", {})          
	.then(res => {
		this.setState({
			trialArray: res.data.responseData
		})
		$('.main_loader').attr({'style':'display:none'});
	})
}

checkEnterpice=e=>{
	$('.main_loader').attr({'style':'display:flex'});	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/check_all_enterprice", {})          
	.then(res => {
		this.setState({
			enterpriseArray: res.data.responseData,
		})
		$('.main_loader').attr({'style':'display:none'});
	})
}

approveItem=(mod, num)=>{
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/update_trial", {plan : mod})
	.then(res => {
		$('.main_loader').attr({'style':'display:none'});
		this.checkAllTrial();
	})
	
}

editEnterprise = (mod, num)=>{
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/update_enterprise", {plan : mod})
	.then(res => {
		$('.main_loader').attr({'style':'display:none'});
		this.checkEnterpice();
	})
}

handleChange= (name, num)=> e =>{
	
	if ( this.state.activeTab == 'trial' )
		{
			let currentArray = this.state.trialArray;
			currentArray[num]['editStatus'] = 1;
		
			let targetNum = e.target.value;
		
			currentArray[num]['trialDurationNew'] = targetNum;
		
			this.setState({
				trialArray: currentArray,
			})
		}
	else
		{
			let currentArray = this.state.enterpriseArray;
			let targetNum = e.target.value;
			
			currentArray[num]['editStatus'] = 1;
			if ( name == 'planPriceNew' )
				{
					currentArray[num]['planPriceNew'] = targetNum;
				}
			else if ( name == 'includedParticipantsNew' )
				{
					currentArray[num]['includedParticipantsNew'] = targetNum;
				}
			else if ( name == 'additionalParticipantsNew' )
				{
					currentArray[num]['additionalParticipantsNew'] = targetNum;
				}
			else if ( name == 'onDemandPriceNew' )
				{
					currentArray[num]['onDemandPriceNew'] = targetNum;
				}
			else if ( name == 'recordingsNumberNew' )
				{
					currentArray[num]['recordingsNumberNew'] = targetNum;
				}
			else if ( name == 'additionalRecordingNew' )
				{
					currentArray[num]['additionalRecordingNew'] = targetNum;
				}
			else if ( name == 'uniqueClientsNew' )
				{
					currentArray[num]['uniqueClientsNew'] = targetNum;
				}
			else if ( name == 'additionalClientNew' )
				{
					currentArray[num]['additionalClientNew'] = targetNum;
				}		
				
			this.setState({
				enterpriseArray: currentArray
			})	
		}		
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className="container-fluid payinfo_main_hold">
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className={"month_h1 "+(this.state.activeTab == 'trial' ? "active_tab" : "")} onClick={ e =>{this.changeTab('trial')}} >Trail details</div>
									<div className={"month_h1 "+(this.state.activeTab == 'enterprise' ? "active_tab" : "")} onClick={ e =>{this.changeTab('enterprise')}} >Enterprise details</div>							
								</div>
								{this.state.activeTab == 'trial' &&
									<div>
										{ this.state.trialArray.length > 0 && 
											<table className="billing_table">
												<thead>
													<tr>
														<th>Channel Name</th>
														<th>Channel Id</th>
														<th>Live Plan</th>
														<th>Live Plan Price</th>
														<th>On Demand Plan</th>
														<th>On Demand Plan Price</th>
														<th>Trial Started</th>
														<th>Trial Duration</th>
														<th>Trial End</th>
													</tr>
												</thead>
												<tbody> 
													{ this.state.trialArray.map((item, key) =>
														<tr key={key}>
															<td>{ item['name'] }</td>
															<td>{ item['id'] }</td>
															<td>{ item['planName'] }</td>
															<td>{ item['planPrice'] }</td>
															<td>{ item['onDemandPlan'] }</td>
															<td>{ item['onDemandPlanPrice'] }</td>
															<td>{ this.getSessionDate(item['trialStarted'])} @ {this.getSessionTime(item['trialStarted'])}</td>
															<td>
																<div className="planPrice_span text_align_price_span" >{item['trialDuration']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="newDuration"
																		value={this.state.trialArray[key]['trialDurationNew']}
																		onChange={this.handleChange('newDuration', key)}
																	/>
																</div>
															</td>
															<td>{ this.getSessionDate(item['trialEndNotFormated'])} @ {this.getSessionTime(item['trialEndNotFormated'])}</td>
															{ item['editStatus'] == 1 &&
															<td className="tbl_width" >
																<span className="open_btn_reports" onClick={(e)=> {this.approveItem(item, key)}} >SAVE</span>													
															</td>
															}
															
														</tr>
													)}
												</tbody>
											</table>
										}
										{ this.state.trialArray.length == 0 && 
											<div className="noPaymentsFound" >No trial plans found</div>
										}
									</div>
								}
								{this.state.activeTab == 'enterprise' &&
									<div className="table_new_holder" >
										{ this.state.enterpriseArray.length > 0 && 
											<table className="billing_table less_font">
												<thead>
													<tr>
														<th className="button_td" >Channel Name</th>
														<th className="button_td">Channel Id</th>
														<th className="button_td" >Live Plan</th>
														<th className="button_td">Live Plan Price</th>
														<th className="button_td">Included Members</th>
														<th className="button_td">Additional Members</th>
														<th className="button_td">On Demand Plan</th>
														<th className="button_td">On Demand Plan Price</th>
														<th className="button_td">Recordings Number</th>
														<th className="button_td" >Additional Recording</th>
														<th className="button_td" >Clients Number</th>
														<th className="button_td" >Additional Clients</th>
													</tr>
												</thead>
												<tbody> 
													{ this.state.enterpriseArray.map((item, key) =>
														<tr key={key}>
															<td className="button_td" >{ item['name'] }</td>													
															<td className="button_td">{ item['id'] }</td>
															<td className="button_td">{ item['planName'] }</td>
															<td className="button_td">
																<div className="planPrice_span text_align_price_span" >{item['planPrice']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width2" 
																		id="planPriceNew"
																		value={this.state.enterpriseArray[key]['planPriceNew']}
																		onChange={this.handleChange('planPriceNew', key)}
																	/>
																</div>
															</td>
															<td className="button_td">
																<div className="planPrice_span text_align_price_span" >{item['includedParticipants']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width2" 
																		id="includedParticipantsNew"
																		value={this.state.enterpriseArray[key]['includedParticipantsNew']}
																		onChange={this.handleChange('includedParticipantsNew', key)}
																	/>
																</div>
															</td>
															<td className="button_td">
																<div className="planPrice_span text_align_price_span" >{item['additionalParticipants']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width2" 
																		id="additionalParticipantsNew"
																		value={this.state.enterpriseArray[key]['additionalParticipantsNew']}
																		onChange={this.handleChange('additionalParticipantsNew', key)}
																	/>
																</div>
															</td>
															<td className="button_td">{ item['onDemandPlan'] }</td>
															<td className="button_td">
																<div className="planPrice_span text_align_price_span" >{item['onDemandPrice']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width2" 
																		id="onDemandPriceNew"
																		value={this.state.enterpriseArray[key]['onDemandPriceNew']}
																		onChange={this.handleChange('onDemandPriceNew', key)}
																	/>
																</div>
															</td>
															<td className="button_td">
																<div className="planPrice_span text_align_price_span" >{item['recordingsNumber']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width2" 
																		id="recordingsNumberNew"
																		value={this.state.enterpriseArray[key]['recordingsNumberNew']}
																		onChange={this.handleChange('recordingsNumberNew', key)}
																	/>
																</div>
															</td>
															<td className="button_td">
																<div className="planPrice_span text_align_price_span" >{item['additionalRecording']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width2" 
																		id="additionalRecordingNew"
																		value={this.state.enterpriseArray[key]['additionalRecordingNew']}
																		onChange={this.handleChange('additionalRecordingNew', key)}
																	/>
																</div>
															</td>
															<td className="button_td">
																<div className="planPrice_span text_align_price_span" >{item['uniqueClients']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width2" 
																		id="uniqueClientsNew"
																		value={this.state.enterpriseArray[key]['uniqueClientsNew']}
																		onChange={this.handleChange('uniqueClientsNew', key)}
																	/>
																</div>
															</td>
															<td className="button_td">
																<div className="planPrice_span text_align_price_span" >{item['additionalClient']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width2" 
																		id="additionalClientNew"
																		value={this.state.enterpriseArray[key]['additionalClientNew']}
																		onChange={this.handleChange('additionalClientNew', key)}
																	/>
																</div>
															</td>
															{ item['editStatus'] == 1 &&
																<td className="button_td" >
																	<span className="open_btn_reports" onClick={(e)=> {this.editEnterprise(item, key)}} > Approve </span> 
																</td>
															}
														</tr>
													)}
												</tbody>
											</table>
										}
										{ this.state.enterpriseArray.length == 0 && 
											<div className="noPaymentsFound" >No plans found</div>
										}
									</div>
								}
							</div>
						</div>
					</div>	
				</div>
			</div>	
		</div>	
	);
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ReportsPlanDetails);