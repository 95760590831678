import React, { Component } from "react";
import axios from "axios";
import { Link } from 'react-router';
import $ from 'jquery'
import {  browserHistory} from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
import utils from '../../util/functions'
import { getCountryCallingCode } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { deleteCookie } from '../auth/Logout';
import SupportContainer from '../home/SupportContainer';

class EditChannelCreation extends Component {
  
  constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.phoneRef = React.createRef();
    this.state = {
        accountAddress:false,
        sessionCharge:false,
        msg:'',
        address:'',
        test1:'required',
        test2:'string',
        name:'',
        phoneNumber:'',
        ss:'',
        imageName:'',
        description:'',
        streetAddress1 :'',
        streetAddress2 :'',
        accountName:'',
        accountNumber:'',
        city:'',
        routingNumber:'',
        stateCode:'',
        zipCode:'',
        accountType:'',
        shoppingStatus:false,
        equipmentStatus:false,
        productStatus:false,
        exampleFormControlSelect2:'account Type',
        //exampleFormControlSelect3:'',
        hostList:[],
        hostList1:[],
        InterestHost:[],
        InterestHost1:[],
        selectedFile: '',
        equipmentCount:0,
        addShoppingList:[],
        selectedShoppingList:'',
        ShoppingCount:0,
        productCount:0,
        ///////////Add a new Product/////////////////////////
        shoppingProductName:'',
        shoppingVarietal:'',
        shoppingPrice:'',
        addEquipmentList:[],
        shoppingPh:'',
        shoppingAppearance:'',
        shoppingAroma:'',
        shoppingPalate:'',
        shoppingTestingNote:'',
        shoppingWineMakingNote:'',
        shoppingPair:'',
        addAttribute:[],
        something:[false,false,false,false,false,false],
        addProduct:[],
        productInformation:[],
        videoFile:'',
        imageFile:'',
        customRadio2:false,
        customRadio1:true,
        hostMail:[{mail:''},{mail:'ak@gmail.com'},{mail:'ak1@gmail.com'}],
        mail:'',
        loginName:'',
        exampleFormControlSelect1:'',
        groupType:[],
        chType:'',
        responseMessage:'',
        stateArray:[],//utils.stateCode(),
        channelInterestMessage:'',
        channelInformation:false,
        countryCode:'',
        groupName:'',
        countryArray: [],//[{ Id: 1, postalCode: "UNITED STATES", countryCode: "US" },{ Id: 2, postalCode: "Canada", countryCode: "CAN" }],
        file:'',
        errorClass_ZipCode:false,
        errorClass_StateCode:false,
        errorClass_PhoneNumber:false,
        errorClass_City:false,
        errorClass_streetAddress:false,
        errorClass_Description:false,
        errorClass_ChannelName:false,
		userAvatar: '',
		selectedCountryCode: false,
		regexZip: '',
		supportPageName: 'Edit Channel',
		supportPageApi: '/editChannel',
		userTypeFaqs: 2
    
    }
    this.validator = new SimpleReactValidator();
    this.shoppingValidator = new SimpleReactValidator();
    this.equipmentValidator = new SimpleReactValidator();    
	 this.deleteCookie = deleteCookie.bind(this);


}
 
componentDidMount(){
    window.scrollTo(0, 0);
	this.getCountries();
	this.setState(
		{
			userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image,
			channelInformation: true
		}
	);
    if(localStorage.getItem('userData')){
        let chkValue=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
        if(!chkValue){
       // $("#pick_channel").attr({'style':'display:block'});
        }
        else{

        }	
         //this.setChannelInterest();
         //this.setChannelHost();
        //    this.groupData();
        //this.fetchAttributesList();
       // console.log('this.props',this.props);
        this.channelData();
        this.setState({
        loginName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName
        })
    }
    else{
      browserHistory.push("/login");
      }
	  
	 console.log('didmount');
	 console.log(this.state.channelInformation);

}

getCountries(){
    axios      
    .get(process.env.REACT_APP_NAME+"/api/v1/user/getAllCountry")          
      .then(res => {
        //console.log(axios.defaults,'---------Country Code--------------',res.data.responseData[0]);
		
          this.setState({
			countryArray:res.data.responseData
           });
      })
      .catch(err =>{
        console.log('----------there is problem------------');

      });
}



stopEditingChannel = (e) => {
	
	if ( this.props.params.id )
		{
			let url = process.env.REACT_APP_NAME+"/api/v1/channel/"+this.props.params.id+'/stoplock';
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
			axios.get(url).then(res => { browserHistory.push('/DashboardLanding'); }).catch(err =>{ });
		}
}

channelData(){
    
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    // axios.defaults.headers.common['Content-Type']='multipart/form-data';
    axios      
    .get(process.env.REACT_APP_NAME+"/api/v1/session/"+this.props.params.id+"/individualChannelDetails?lockedit=1")          
      .then(res => {
        let ak=[];
        let ka=[];
            for(let i=0;i<res.data.responseData.channelInterest.length;i++){
            let n= {id: res.data.responseData.channelInterest[i].id,
                title: res.data.responseData.channelInterest[i].title,
                //image :'/images/session-image.jpg',
                image :res.data.responseData.channelInterest[i].image,
                type:true};
                ak.push(n);
                ka.push(res.data.responseData.channelInterest[i].id);
            }
            this.setState({
                InterestHost:ak,
                InterestHost1:ka,
                name : !res.data.responseData.name?'':res.data.responseData.name,
                exampleFormControlSelect1:res.data.responseData.groupId,
                groupName:res.data.responseData.groupId===1?"Fitness":"Wine",
                city:!res.data.responseData.city?'':res.data.responseData.city,
                description:!res.data.responseData.description?'':res.data.responseData.description,
                chType:!res.data.responseData.individualOrBusiness?'':res.data.responseData.individualOrBusiness,
                phoneNumber:!res.data.responseData.phone?'':res.data.responseData.phone,
                stateCode:!res.data.responseData.state_code?'':res.data.responseData.state_code,
                countryCode:!res.data.responseData.country_code?'':res.data.responseData.country_code,
                streetAddress1:!res.data.responseData.street_address1?'':res.data.responseData.street_address1,
                streetAddress2:!res.data.responseData.street_address2?'':res.data.responseData.street_address2,
                zipCode:!res.data.responseData.zip_code?'':res.data.responseData.zip_code,
                imageName:!res.data.responseData.image?'':res.data.responseData.image,
                file:res.data.responseData.image==="default.png"?'':res.data.responseData.image,
            },()=>{
				
				for ( var i = 0; i < this.state.countryArray.length; ++i ) {
					if ( parseInt(this.state.countryArray[i].id) === parseInt(this.state.countryCode) )		
						{
							axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getAllStateByName", {"countryName": this.state.countryArray[i].postalCode}).then(res_e => {
								var zipRegex = res_e.data.responseData[0].zipCodeRegex;
								if ( zipRegex == '' ) { zipRegex = '^\d{5}$'; }
								this.setState({regexZip: zipRegex});
							}).catch(err =>{ });
						}
				}
				
				this.getStateCode(this.state.countryCode)
			})
       
      })
      .catch(err =>{
          console.log('----------there is problem--Channel Detail----------');

      });

}
groupData(){
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    // axios.defaults.headers.common['Content-Type']='multipart/form-data';
    axios      
    .get(process.env.REACT_APP_NAME+"/api/v1/user/getAllGroups")          
      .then(res => {
        console.log(axios.defaults,'---------Group Data--------------',res.data.responseData);
          this.setState({
           groupType:res.data.responseData
           })
      })
      .catch(err =>{
          console.log('----------there is problem------------');

      });

}

fetchAttributesList() {
    let attributes = [];
    let productList= this.state.addProduct;  
    let  interestId=1;
    
    console.log('-----asdfghjkl----------',interestId);  

      axios      
      
      .get(process.env.REACT_APP_NAME+"/api/v1/session/"+interestId+"/attributeList")          
      .then(res => {
        console.log('---------interestIdproduct--------------',res.data.responseData);

          let eqarray=res.data.responseData;        
     
          let attributes = [];
          let productList= this.state.addProduct;
          for(let i =0;i<eqarray.length;i++){
           let n = {attrKey:eqarray[i].attrLabel,attrValue:'',status:false,id:i};
           attributes.push(n);
           console.log('attributes-----',attributes);   
             }
          
          let n = {name : 'ChannelCreation',
              attributes
            }
            productList.push(n);       
          this.setState({
          addProduct:productList
          },()=>console.log('-------------------------------------------addProduct',this.state.addProduct))
      })
      .catch(err =>{
          console.log('----------there is problem------------');

      });

  }
  

setChannelInterest=(id)=>{

    let  channelId=id; //
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    console.log("------setChannjksfhskjdhfkjdsh------------",process.env.REACT_APP_NAME+"/api/v1/session/"+channelId+"/allInterest");
    axios      
    .get(process.env.REACT_APP_NAME+"/api/v1/user/"+channelId+"/getInterest")
   // .get("/api/v1/session/interest")           
    .then(res => {
      console.log('---------channelInterest--------------',res.data.responseData);
      let channelArray= this.state.InterestHost;
       let eqarray=res.data.responseData;        
      for(let i=0;i<eqarray.length;i++){
        let n = {id: eqarray[i].id, title: eqarray[i].title,image :'images/session-image.jpg',type:false};
        channelArray.push(n); 
      }
      this.setState({
        InterestHost: channelArray
          });
    })
    .catch(err =>{
        console.log('----------there is problem------------');

    });
    
}

setChannelHost=(e)=>{

    let  channelId=1;
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    axios      

    //.get("/api/v1/session/"+channelId+"/hosts-list1")    
    .get(process.env.REACT_APP_NAME+"/api/v1/session/"+channelId+"/hosts-user-list")      
    .then(res => {
      console.log('---------channelHost--------------',res.data.responseData);
      let channelArray= this.state.hostList;
       let eqarray=res.data.responseData; 

       let n = {userId: eqarray.id, username: eqarray.firstName,image :'images/pic.jpg',type:false};
    channelArray.push(n);
    let arrayCheck = [];
    arrayCheck.push(eqarray.id); 

    //   for(let i=0;i<eqarray.length;i++){
    //    // let n = {userId: eqarray[i].userId, username: eqarray[i].username,image :'images/pic.jpg',type:false};
    //     let n = {userId: eqarray.id, username: eqarray.firstName,image :'images/pic.jpg',type:false};
    //     channelArray.push(n); 
    //   }
     // console.log('---------channelHost1111--------------',eqarray.id);   
      this.setState({
        hostList: channelArray,
        hostList1:arrayCheck
          },()=>console.log(">>>>>>>>>>>>>>>>>>>>>>>>",this.state.hostList1));
    })
    .catch(err =>{
        console.log('----------there is problem------------');

    });

}
 addEquipment = e =>{
    if (this.equipmentValidator.allValid()) {
   let arr = this.state.addEquipmentList;
   arr.push(this.state.selectedFile);
   let count=this.state.equipmentCount;
   console.log(count);
   count = count+1;
   console.log('*****',count);
   this.setState({
       addEquipmentList:arr,
       equipmentCount:count,
       selectedFile:''
   },()=>console.log(this.state.equipmentCount,'**********************',this.state.addEquipmentList))
} else{
    console.log('----------------This is a error--------------------')
      this.equipmentValidator.showMessages();
    // rerender to show messages for the first time
    // you can use the autoForceUpdate option to do this automatically`
    this.forceUpdate();
  }
 } 
 removeEquipment = e =>{
     let arr = this.state.addEquipmentList;
     let count=this.state.equipmentCount;
     console.log(count);
     count = count-1;
     console.log('*****',count);
     arr.splice(e.target.id,1);
     this.setState({
        addEquipmentList:arr,
        equipmentCount:count,
        },()=>console.log('**********************',this.state.addEquipmentList))
     
 }

 addShoppingListMethod = (e) => {
    if (this.shoppingValidator.allValid()) {
    let arr = this.state.addShoppingList;
    arr.push(this.state.selectedShoppingList);
    let count=this.state.ShoppingCount;
    console.log(count);
    count = count+1;
    console.log('*****',count);
    this.setState({
        addShoppingList:arr,
        ShoppingCount:count,
        selectedShoppingList:''
    })
    }else{
    console.log('----------------This is a error--------------------')
      this.shoppingValidator.showMessages();
    // rerender to show messages for the first time
    // you can use the autoForceUpdate option to do this automatically`
    this.forceUpdate();
  }
   
 }
 removeShoppingList = e =>{
    let arr = this.state.addShoppingList;
    let count=this.state.ShoppingCount;
    console.log(count);
    count = count-1;
    console.log('*****',count);
    arr.splice(e.target.id,1);
    this.setState({
       addShoppingList:arr,
       ShoppingCount:count,
       })
    
}

  sessionInfo = e =>{
    this.setState({
        [e.target.id] : e.target.value
    })
  }
  sessionCountryInfo= e =>{
	window['phoneRef'] = this.phoneRef;

	for ( var i = 0; i < this.state.countryArray.length; ++i ) {
		if ( parseInt(this.state.countryArray[i].id) === parseInt(e.target.value) )		
			{
				axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getAllStateByName", {"countryName": this.state.countryArray[i].postalCode}).then(res_e => {
					var zipRegex = res_e.data.responseData[0].zipCodeRegex;
					if ( zipRegex == '' ) { zipRegex = '^\d{5}$'; }
					this.setState({regexZip: zipRegex});
				
				}).catch(err =>{ });
			}
	}
	  
    // axios.defaults.headers.common['Content-Type']='multipart/form-data';
     this.setState({
        [e.target.id] : e.target.value,
        msg:''
       // stateArray:e.target.value==="0"?utils.stateCode():utils.candastateCode()
    },()=>{this.getStateCode(this.state.countryCode)})
  }
  getStateCode(data1){
	var selected_countri_code = this.state.countryArray.find((item) => { return parseInt(item['id']) === parseInt(data1) } );
	var selectedCountryCode = selected_countri_code ? selected_countri_code.countryCode : 'US';
	
	var call_code = '';
	try { call_code = getCountryCallingCode(this.state.selectedCountryCode ? this.state.selectedCountryCode : selectedCountryCode); } 
	catch(exception) { selectedCountryCode = 'US'; call_code = getCountryCallingCode('US'); };
	
	var new_call_code = '';
	try { new_call_code = getCountryCallingCode(selectedCountryCode); } 
	catch(exception) { selectedCountryCode = 'US'; new_call_code = getCountryCallingCode('US'); };
	
	var new_numb;
	if ( this.state.phoneNumber && this.state.phoneNumber !== '' ) { new_numb = this.state.phoneNumber.replace("+"+call_code, "+"+new_call_code); } 
	else { new_numb = "+"+new_call_code; }
    
	axios      
    .get(process.env.REACT_APP_NAME+"/api/v1/user/"+data1+"/getAllState")          
      .then(res => {
          this.setState({
           stateArray:res.data.responseData,
		   selectedCountryCode: selectedCountryCode,
		   phoneNumber: new_numb
           
           })
      })
      .catch(err =>{
          console.log('----------there is problem------------');

      });
}

onChangeHandler=event=>{
//   console.log(event.target.nextSibling.innerText=event.target.files[0].name,'========adadadadad=====',event.target.files[0]);

  //console.log(event.target,"=============",event.target.files[0]);
  


  const data = new FormData() 
    data.append('file', event.target.files[0]);
    console.log("type of image",event.target.files[0].type)
    const types = ['image/png', 'image/jpeg', 'image/gif']
    
	
	if (types.every(type => event.target.files[0].type !== type)) 
		{
			// create error message and assign to container   
			//alert(' is not a supported format\n');
			this.props.showMainErrorPop("Only .png and .jpeg extensions are supported.");
		}
	else
		{
				let reader = new FileReader();
				let file1 = event.target.files[0];
				reader.onloadend = () => {
					this.setState({
						file: reader.result
					});
				}
				reader.readAsDataURL(file1)
			
			
				$('.main_loader').attr({'style':'display:flex'});
				axios.post(process.env.REACT_APP_NAME+"/api/v1/upload/upload", data)
				.then(res => {
					console.log('dsddsd===================>',res.data.responseData.file)
					$('.main_loader').hide();
					this.setState({
						imageName:res.data.responseData.file
					})
				}).catch(err =>{
					console.log(err);
				})
		}
}
saveVideoFile = event=>{
    console.log(event.target.files[0]);
    const data = new FormData() 
      data.append('file', event.target.files[0]);
      console.log('----------------------',data);
      this.setState({
          [event.target.id]:data
    },()=>console.log(this.state.videoFile,'Preview---------',this.state.imageFile))
}
saveProductList=(e)=>{
   // alert('hi');
    //e.preventDefault();
    //console.log('$("#description").val()',$("#description").val());
    console.log('Product List',this.state.productInformation);
    const saveProduct = {
        name:this.state.shoppingProductName,
        productName:this.state.addProduct[0].name,
        attributes:this.state.productInformation,
        videoFile:this.state.videoFile,
        imageFile:this.state.imageFile
    }
    console.log('saveProduct',saveProduct)
    // if (this.validator.allValid()) {
    // console.log('saveProduct',saveProduct);
    // }else{
    //     console.log('error');
    //     this.validator.showMessages();
    // }

    // $("#product_lst_modal").attr({'style':'display:none'});
    // $("#audio_video_mdl").attr({'style':'display:none'});
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    axios.post(process.env.REACT_APP_NAME+"/api/v1/session/addProduct", {saveProduct})
    .then(res => {

      //console.log(res);
      if(res.data.responseMessage == "success")
      {
      console.log('=============lallittiwari12345===================>',res.data);
      }else{
        console.log('=============There Is an Error===================>'); 
      }
    })   

}

handleProductList = idx => evt => {
    const newShareholders = this.state.productInformation.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, attrValue: evt.target.value };
    });
  
    this.setState({ productInformation: newShareholders },()=> {
      console.log('Product List',this.state.productInformation[idx].attrValue)
    }
    );
  }
addAttribute = (e) => {
    e.preventDefault();
    console.log(e.target.id,'-------------',e.target.name);
    console.log('e.target.id',e.target.id);
    let attributeArray=this.state.addProduct;
    let arr = this.state.productInformation;
    let productCount = this.state.productCount;
    attributeArray[0].attributes[e.target.id].status = !attributeArray[0].attributes[e.target.id].status;
    if(attributeArray[0].attributes[e.target.id].status){
        arr.push(attributeArray[0].attributes[e.target.id]);
        productCount =  productCount+1;

    }else{
    for(let i=0;i<arr.length;i++){
        if(e.target.name === arr[i].attrKey){
            arr.splice(i,1);
            productCount =  productCount-1;
        }
    }
    }

    this.setState({
        addProduct:attributeArray,
        productInformation:arr,
        productCount:productCount
    },()=>console.log('----------------',this.state.productInformation))
    }
selectHost = (e) => {
    let arrayCheck = [];
    let hostContainer = this.state.hostList;
    hostContainer[e.target.id].type = !hostContainer[e.target.id].type;
    if(hostContainer[e.target.id].type) {
    arrayCheck = this.state.hostList1;
    arrayCheck.push(hostContainer[e.target.id].userId);    
      } 
    else {
      
       if(this.state.hostList1.length>0){
        for(let i=0;i<this.state.hostList1.length;i++){
           if(this.state.hostList1[i]=== hostContainer[e.target.id].userId){
            arrayCheck = this.state.hostList1;
            arrayCheck.splice(i,1);
            this.setState({
              hostList1 : arrayCheck 
            },()=> console.log('check or uncheck equipmentList', this.state.hostList1))
          }
        }
      }
    }
    this.setState({
      hostList : hostContainer,
      hostList1:arrayCheck
      },()=>
      { console.log(this.state.hostList1,'setEuipmentContainer==>',this.state.hostList);
        });
   
  }
  
  selectChannelInterest = (e) => {
    let arrayCheck = [];
    let channelContainer = this.state.InterestHost;
    channelContainer[e.target.id].type = !channelContainer[e.target.id].type;
    if(channelContainer[e.target.id].type) {
    arrayCheck = this.state.InterestHost1;
    arrayCheck.push(channelContainer[e.target.id].id);
    this.setState({
        channelInterestMessage:''
    })    
      } 
    else {
      
       if(this.state.InterestHost1.length>0){
        for(let i=0;i<this.state.InterestHost1.length;i++){
           if(this.state.InterestHost1[i]=== channelContainer[e.target.id].id){
            arrayCheck = this.state.InterestHost1;
            arrayCheck.splice(i,1);
            this.setState({
              InterestHost1 : arrayCheck 
            },()=> console.log('check or uncheck equipmentList', this.state.hostList1))
          }
        }
      }
    }
    this.setState({
      InterestHost : channelContainer,
      InterestHost1:arrayCheck
      },()=>
      { console.log(this.state.InterestHost1,'setEuipmentContainer==>',this.state.InterestHost);
        });
   
  }
  
	validateZipRegex=(zip)=>{
		var zipCodePattern = new RegExp(this.state.regexZip);
		return zipCodePattern.test(zip);
	}
  
  
  submitForm = (event) => {
    //  var ret=/^[A-Za-z]\w{0,16}$/;
    // var t = ret.test(this.state.zipCode);
    // if(this.state.countryCode==='2'){
    // if(t ){
       
    // }else{
    //     this.setState({
    //         msg:'Canada Zip Code should be alpha numeric'
    //     })
    //     return;
    // } 
    // }
    event.preventDefault();
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    // axios.defaults.headers.common['Content-Type']='multipart/form-data';
	let phoneNumberEdit;
	
	if(this.state.phoneNumber)
		{
			phoneNumberEdit = this.state.phoneNumber.trim();
		}
		
 //   console.log(axios.defaults);
    const channel = {
     chType:this.state.chType,
     groupId:this.state.exampleFormControlSelect1,   
    "name": this.state.name.trim(),
    token:JSON.parse(localStorage.getItem('userData')).data.responseData.token,
    "description": this.state.description.trim(),
    country_code:this.state.countryCode,
    "image": this.state.imageName,
    "phone": phoneNumberEdit,
    "street_address1": this.state.streetAddress1.trim(),
    "street_address2": this.state.streetAddress2.trim(),
    "city": this.state.city.trim(),
    "state_code": this.state.stateCode,
    "zip_code": this.state.zipCode.trim(),
    "account_name":this.state.accountName,
    "account_number":this.state.accountNumber,
    "account_type":this.state.accountType,
    "routing_number":this.state.routingNumber,
    "ss":this.state.ss,
    "ein":"7654",
    "chargeForSessiones":this.state.sessionCharge,
    "charge_amount":"30",
    "has_shopping_list":this.state.shoppingStatus,
    "has_equipment_list":this.state.equipmentStatus,
    "has_product_list":this.state.productStatus
  }

  const channelHost = {
      channel_Host:this.state.hostList1
  }
  const InterestHost = {
      Interest_Host:this.state.InterestHost1
  }
 // console.log('*******lalitChannel*********',channel,channelHost,InterestHost);
 let  channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
 console.log('out validator');
  if (this.validator.allValid() && isValidPhoneNumber(this.state.phoneNumber) && this.validateZipRegex(this.state.zipCode) ) {

    //let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    // const config = {
    //         headers: {
    //             'content-type': 'multipart/form-data'
    //         }
    //     };
 //   console.log('======image==',this.state.imageName)
	$('.main_loader').attr({'style':'display:flex'});
    axios.put(process.env.REACT_APP_NAME+"/api/v1/session/"+this.props.params.id+"/editChannel", {channel,channelHost,InterestHost})
    .then(res => {

 //     console.log('=============lallittiwari12345===================>',res.data);;

          if(res.data.responseMessage == "success")
			{
			  this.setState({
					  msg: "Channel has been updated Successfully",
					  channelInterestMessage:'*Channel has been updated Successfully'
					}
					,()=>{
						$("#registrationpopup").attr({'style':'display:block'})
						// setTimeout(() => {
						//     $("#registration_popup").attr({'style':'display:none'})
						//     browserHistory.push('/DashboardLanding');
						//   }, 1000);
					}
				);
        }else{

        this.setState({
          msg: "There Is a error in channel creation",
        });

      }
	  
	  $('.main_loader').hide();
    
    }).catch(err =>{
        console.log('----------there is problem------------',err);
        console.log(!err.response)
         $('.main_loader').hide();
		if(err.response.data.responseCode===417){
            if(!err.response.data.errorData){
                this.setState({
                  responseMessage:''
              })
              }else{
         
            this.setState({
                responseMessage:err.response.data.errorData
            },()=>$("#error_msg").attr({'style':'display:block'}))
        }
        
         }else{
        this.setState({
            responseMessage:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
        },()=>$("#error_msg").attr({'style':'display:block'}))
    }

    });

  }else{

    console.log('----------------This is a error--------------------')
    this.validator.showMessages();
    this.props.showMainErrorPop('One or more fields has an error. Please correct and press SAVE again');
	 if(!this.validator.fieldValid('Channel Name')){
		this.setState({
			errorClass_ChannelName:true
		})
		this._channelName.focus();
	   }
	   if(!this.validator.fieldValid('Description')){
		this.setState({
			errorClass_Description:true
		})   
		this._Description.focus();
	   }
	   
	   if(!this.validator.fieldValid('Street Address')){
		this.setState({
			errorClass_streetAddress:true
		})   
		this._streetaddress.focus();
	   }
	   if(!this.validator.fieldValid('City')){
		this.setState({
			errorClass_City:true
		})   
		this._city.focus();
	   }

	   if(!isValidPhoneNumber(this.state.phoneNumber)){
		this.setState({
			errorClass_PhoneNumber:true
		})
	   }
	   if(!this.validator.fieldValid('State Code')){
		this.setState({
			errorClass_StateCode:true
		})   
		this._statecode.focus();
	   }
	   
	   if(!this.validateZipRegex(this.state.zipCode)){
		this.setState({
			errorClass_ZipCode:true
		  })   
		this._zipcode.focus();
	   }
  // rerender to show messages for the first time
  // you can use the autoForceUpdate option to do this automatically`
  this.forceUpdate();
  }
}
 goToSession=e=>{
    browserHistory.push("/FitnessSessionCreation");
 }
 checkHost1=(e)=>{
	console.log(e.target)
	this.setState({
		[e.target.id]:!this.state.customRadio1,
		customRadio2:!this.state.customRadio2
		},()=>console.log(this.state.customRadio1))
}
checkHost2=(e)=>{
	console.log(e.target)
	this.setState({
		[e.target.id]:!this.state.customRadio2,
		customRadio1:!this.state.customRadio1
		},()=>console.log(this.state.customRadio2))
}
sessionType=(e)=>{
    console.log(e.target.value,'--------',typeof(e.target.value));
    if(e.target.value!=='0'){
	this.setState({
    [e.target.id]:e.target.value,
    InterestHost:[],
    InterestHost1:[]
    },()=>{
        this.setChannelInterest(this.state.exampleFormControlSelect1);
        console.log(this.state.exampleFormControlSelect1)
    })
}
}
InterstHostData =(e)=>{
    if(this.state.InterestHost1.length>0 && this.state.exampleFormControlSelect1 ){
     this.setState({
        channelInformation:true,
        channelInterestMessage:'' 

     })
 }
    if(this.state.InterestHost1.length===0 && this.state.exampleFormControlSelect1 ){
     this.setState({
       channelInterestMessage:'*Select minimum of one category to enable next button!'
     },()=>console.log(this.state.InterestHost1.length,"this.state.InterestHost1.length","this.state.exampleFormControlSelect1",this.state.exampleFormControlSelect1))
 }
 if(this.state.InterestHost1.length===0 && !this.state.exampleFormControlSelect1 ){
   this.setState({
     channelInterestMessage:'*Select one of Group type!'
   })
}
}

changePhoneInput = (mod) => {
	this.setState({ phoneNumber: mod, errorClass_PhoneNumber: false});
	
	if ( !mod ) 
		{ 
			this.setState({ phoneNumber: "" }, () => { this.getStateCode(this.state.countryCode) });
		}
}

onBlurCheck =(mod)=>{
	this.validator.allValid();
	if(!this.validator.fieldValid('Channel Name') && mod == 'name' )
		{
			this.setState({
				errorClass_ChannelName:true
			})
	   }
	if(!this.validator.fieldValid('Description') && mod == 'description' )
	   {
			this.setState({
				errorClass_Description:true
			})
	   }

	if(!this.validator.fieldValid('Street Address') && mod == 'streetAddress1'  )
	   {
			this.setState({
			errorClass_streetAddress:true
			})   
	   }
	if(!this.validator.fieldValid('City') && mod == 'city' )
	   {
			this.setState({
				errorClass_City:true
			})   		
	   }
	
	if(!isValidPhoneNumber(this.state.phoneNumber) && mod == 'phoneNumber' )
	   {
			this.setState({
				errorClass_PhoneNumber:true
			})
	   }
	if(!this.validator.fieldValid('State Code') && mod == 'code' )
	   {
			this.setState({
				errorClass_StateCode:true
			})   			
	   }
	if(!this.validateZipRegex(this.state.zipCode) && mod == 'zipCode' )
	   {
			this.setState({
			  errorClass_ZipCode:true
			})
	   }
}

render() {
return(
<div>
<div id="root">
        <div className="App">
            <div className="container-fluid custom-width edit_create_main">
                <div className="row top-header pt-3">
                    <div className="col-lg-6 flex-wrap d-flex justify-content-center justify-content-lg-start">
                        <div className="align-self-center">
                            <img src="/images/login-logo.png" className="logo" alt="logo" />
                        </div>
                        <div className="d-flex d-md-block justify-content-center px-4 py-2">
                            <div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
                                <div className="pl-4">
                                    <h3>Welcome {this.state.loginName}!</h3>
                                    <p>No class this week</p>
                                    {/* <p>Next Class, Wednesday, 24 July 2019</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="d-flex justify-content-end">
                            {/* @ak <div className="header-info-right">
                                <p>Average Attendance</p>
                                <h3>0%</h3></div><span className="border-right gray-border"></span>
                            <div className="header-info-right">
                                <p>Total Views</p>
                                <h3>0</h3></div><span className="border-right gray-border"></span>
                            <div className="header-info-right">
                                <p>Total Revenue</p>
                                <h3>$0</h3></div><span className="border-right gray-border"></span>
                                  <div className="message-notification"><img src="/images/message.png" alt=''/>
                              <span className="message-count">0</span></div> */}
                              {/* <button className="btn btn-outline-secondary font-weight-bold radius-8 align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId');browserHistory.push("/")}}>LOG OUT</button> */}
                              <button className="btn btn-outline-secondary font-weight-bold radius-8 ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId');localStorage.removeItem('channelInterest'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}}>LOG OUT</button>
                        </div>
                    </div>
                </div>
                {this.state.channelInformation?'':
                (this.state.InterestHost.length>0? 
                <div className="gray-box-4 mt-3">
                    <div className="hdng_text py-3 px-4 headerborder">
                        <h3 className="p-0 m-0 font-22">Select one or more areas of category (minimum one required) you want to market your class in</h3>
                    </div>
                    <div className="container-fluid py-3 host-container">
                        <div className="row mx-0 px-4">
                           {this.state.InterestHost.map((row,i) => ( 
                            <div className="col-lg-4 col-md-6 pl-md-0" key = {i}>
                                <p className="checktxt_name mb-3 ml-5 mt-2">{row.title}</p>
                                <label className="custom-control custom-checkbox lebelheight pl-0">
                                    <input type="checkbox"
                                    id={i}
                                    checked = {row.type}
                                    onChange= {(e) => { this.selectChannelInterest(e)}}
                                    className="form-radio" />
                                    <img src={row.image} className="ml-2 mr-3 interest_img" alt="user-icon" />
                                    <div>
                                        {/*
                                        <p className="checktxt mb-0">Next class on 22 JUL, 3:45 PM</p>
                                        <p className="checktxt">Past Revenue $0</p> */}
                                    </div>
                                </label>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>:'')}
                {this.state.channelInformation?'':
                (this.state.InterestHost.length>0?
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <p className="text-white mb-0 font-18">{this.state.channelInterestMessage}</p>
                    <button type="button" className="btn btn-primary save-btn mt-0 font-book mb-3 mt-3" onClick={this.InterstHostData}>Next</button>
                </div>:'')}
                {this.state.channelInformation?
                <div className="gray-box-4 mt-2">
					<div className="hdng_text py-3 px-4 headerborder relative">
                        <h3 className="p-0 m-0 font-22">Edit Channel</h3>
                        <div  id="msg" style={{color:'red'}}>{this.state.msg}</div>
						<div>
							<div onClick={this.stopEditingChannel} className="closePopup settings_close_popup channel_settings_close"></div>
						</div>
                    </div>
                    <div className="hdng_text py-3 d-flex px-4 headerborder">
                        <h3 className="p-0 m-0 font-22">Please enter the required information below (you can modify from your Dashboard/Settings)</h3>
                    </div>
                    <div className="container-fluid register-frm pt-4 px-4 participant_form_sign">
                        <div className="form">
                            <div className="form-content mb-0">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                        <div className="form-group"><span className="cover-border"></span>
                                            <label className="label new_label_position">Channel Name (Max 30 characters)*</label>
                                            <input type="text" 
                                            id ="name"
                                            value = {this.state.name}
                                            onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_ChannelName:false})}
                                            ref={chname => (this._channelName = chname)}
                                            className={(this.state.errorClass_ChannelName?"input-field red-outline":"input-field")} maxLength="30"
											onBlur={(e)=>{this.onBlurCheck('name')}}
											/>
                                            {this.validator.message('Channel Name', this.state.name, 'required|min:1|max:30')}
                                        </div>
                                        <div className="form-group"><span className="cover-border"></span>
                                            <label className="label new_label_position">Country*</label>
                                            <select className="input-field new_field_borders" 
                                            id="countryCode"
                                            value = {this.state.countryCode}
                                            style={{color:(this.state.countryCode?"white":'')}}
                                            onChange = {(e) => { this.sessionCountryInfo(e)}}
                                            onBlur={(e)=>{this.onBlurCheck('countryCode')}}
                                            >
                                            {/* <option value=''>Select Country</option> */}
                                            {this.state.countryArray.map((row,i)=>											
                                            <option value={row.id} key = {i}>{row.postalCode}</option>
                                            // <option value='2'>Wine</option>
                                            )}
                                            </select>
                                            {/* {this.validator.message('City', this.state.city, 'required|alpha')} */}
                                        </div>      
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                        <div className="form-group"><span className="cover-border"></span>
                                            <label className="label new_label_position more_top_label label_textarea">Description (Max 250 characters)*</label>
                                            <textarea type="text" 
                                            id ="description"
                                            value = {this.state.description}
                                            ref={ch1 => (this._Description = ch1)}
                                            onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_Description:false})}
                                            className={(this.state.errorClass_Description?"input-field red-outline new_field_borders":"input-field new_field_borders")} maxLength="250"
											onBlur={(e)=>{this.onBlurCheck('description')}}
											></textarea>
                                            {this.validator.message('Description', this.state.description, 'required|min:1|max:250')}
                                        </div> 
                                       
                                    </div>
									
									
									
                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                        <div className="form-group"><span className="cover-border"></span>
                                            <label className="label new_label_position more_top_label">Image</label>
                                            <div className="custom-file mb-3">
                                              <label className="custom-file-label custom-file-label11  input-field position-relative channelimageeditadd new_field_borders" htmlFor="customFile_img">
                                                  <img src={!this.state.file?"/images/browse-image.png ":this.state.file} className="browse_image" alt= ''/>
                                              </label>
											  
                                              <input type="file" className="custom-file-input abs_file_input" id="customFile_img" name="file" onChange = {(e) => { this.onChangeHandler(e) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4_ch">
                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                        <div className="form-group"><span className="cover-border"></span>
                                            <label className="label new_label_position">Phone*</label>
                                            <PhoneInput
											international={ this.state.selectedCountryCode && this.state.selectedCountryCode !== 'US' && this.state.selectedCountryCode !== 'CA' }
											country={this.state.selectedCountryCode ? this.state.selectedCountryCode : 'US'} 
											value={this.state.phoneNumber}
											withCountryCallingCode={true}
											id ="phoneNumber"
											className={(this.state.errorClass_PhoneNumber?"input-field red-outline":"input-field")} 
                                            placeholder="XXX-XXX-XXXX"			
											onChange={this.changePhoneInput}
											onBlur={(e)=>{this.onBlurCheck('phoneNumber')}}
											/>
											{ !isValidPhoneNumber(this.state.phoneNumber) && <div className="srv-validation-message">Please enter valid phone number</div> }
                                            <div className="srv-validation-message">Enter numbers only, no dashes or parentheses</div>
     
                                        </div>                                                                                                                                                                             
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6">                                    
                                        <div className="form-group"><span className="cover-border"></span>
                                            <label className="label new_label_position">Street Address 1*</label>
                                            <input type="text" 
                                            id ="streetAddress1"
                                            value = {this.state.streetAddress1}
                                            ref={ch3 => (this._streetaddress = ch3)}
                                            onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_streetAddress:false})}
                                            className={(this.state.errorClass_streetAddress?"input-field red-outline":"input-field")} placeholder="" 
											onBlur={(e)=>{this.onBlurCheck('streetAddress1')}}
											/>
                                            {this.validator.message('Street Address', this.state.streetAddress1, 'required')}
                                        </div>                                                                                                                                                                                
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6">                                    
                                        <div className="form-group"><span className="cover-border"></span>
                                            <label className="label new_label_position">Street Address 2</label>
                                            <input type="text" 
                                            id ="streetAddress2"
                                            value = {this.state.streetAddress2}
                                            onChange={(e)=>this.setState({[e.target.id]:e.target.value},()=>console.log(this.state.streetAddress2))}
                                            className="input-field" placeholder="" />
                                        </div>                                                                                                                                               
                                    </div>
                                </div>
                                <div className="row mt--10">
                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                        <div className="form-group mb-0"><span className="cover-border"></span>
                                            <label className="label new_label_position">City*</label>
                                            <input type="text" 
                                            id ="city"
                                            value = {this.state.city}
                                            ref={ch5 => (this._city = ch5)}
                                            onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_City:false})}
                                            className={(this.state.errorClass_City?"input-field red-outline":"input-field")} placeholder="" 
											onBlur={(e)=>{this.onBlurCheck('city')}}
											/>
                                            {this.validator.message('City', this.state.city, 'required|alpha_space')}
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                        <div className="form-group mb-0">
                                            <span className="cover-border"></span>
                                            <label className="label new_label_position">State/Province*</label>
                                            <select 
                                            className={(this.state.errorClass_StateCode?"input-field red-outline new_field_borders":"input-field new_field_borders")} 
                                            id="stateCode"
                                            ref={ch4 => (this._statecode = ch4)}

                                            value = {this.state.stateCode}
                                            style={{color:(this.state.stateCode?"white":'')}}
                                            onChange = {(e) => { this.sessionInfo(e) }}
                                             onBlur={(e)=>{this.onBlurCheck('code')}}
                                            >
                                            {/* <option value=''>Select State Code</option> */}
                                            {/* {this.state.stateArray.length>0?<option value=''>Select State Code</option>:<option value=''>{this.state.stateCode}</option>} */}
                                            {this.state.stateArray.sort(function(a, b){ if(a.stateCode < b.stateCode) { return -1; } if(a.stateCode > b.stateCode) { return 1; } return 0; }).map((row,i)=>											
                                            <option value={row.id} key = {i}>{row.stateCode}</option>
                                            // <option value='2'>Wine</option>
                                            )}
                                            </select>
                                            {this.validator.message('State Code' , this.state.stateCode, 'required')}
                                        </div>                                                                                 
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                        <div className="form-group mb-0"><span className="cover-border"></span>
                                            <label className="label">Zip/Postal Code*</label>
                                            <input type="text"
                                            id ="zipCode"
                                            value = {this.state.zipCode}
                                            ref={ch6 => (this._zipcode = ch6)}
                                            onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_ZipCode:false})}
                                            className={(this.state.errorClass_ZipCode?"input-field red-outline":"input-field")} placeholder="XXXXX"
											onBlur={(e)=>{this.onBlurCheck('zipCode')}}
											/>
                                        </div>                                
                                    </div>                                 
                                </div>
                                {/* <p className="text-light translatee">*Mandatory fields are Required</p>                     */}
                            </div>
                        </div>
                    </div>
                </div>    
                :''}
                {this.state.channelInformation?
                <div>
                <p className="text-light mb-0">*Mandatory fields are Required</p>     
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <div className="mb-4 d-flex flex-wrap disp_cent">
						<button type="button" className="btn btn-primary save-btn mt-0 font-book mx-2 mt-2 mt-md-0 displ" onClick={this.submitForm}>Update channel</button>
                    </div>
					<div className="main_loader">
						<div className="main_loader_inner"></div>
					</div>
                </div>
                </div>:''}
            </div>
        </div>
    </div>
{/* popup for successFul */}
<div className="modal pr-0" id="registrationpopup" aria-modal="true"  >
	<div className="modal-dialog small_width modal-dialog-centered">
		<div className="modal-content bg-trnsparent px-2">
			<div className="modal-body modl_bg_color px-4 pb-4 radius-10">
				<div className="d-flex justify-content-end">
					<button type="button" className="close white closepurple" data-dismiss="modal" onClick={e=>{$("#registrationpopup").attr({'style':'display:none'})}}><span>x</span></button>
				</div>
				<p className="pt-4 font-medium pb-5 mb-0 text-white text-center font-20">Channel is updated successfully</p>
			</div>
			<div className="text-center my-4">
			<button type="button" className="save-btn btn btn-primary font-18 font-weight-normal font-book mx-auto mt-0" onClick={e=>{$("#registrationpopup").attr({'style':'display:none'});browserHistory.push('/DashboardLanding')} }>go to dashboard</button>
		</div>
		</div>
	   
	</div>
  </div>
{/* pop successful end here */}
    <div className="modal pr-0 list-modal" id="shopping_lst_modal">
        {/* >2 Start*/}
       
        <div className={(this.state.ShoppingCount<3?"modal-dialog small_width":"modal-dialog large_width")}>
            <div className="modal-content modl_bg_color">
                <div className="modal-header border_none p-4">
                    <h4 className="modal-title white pt-3">Shopping List</h4>
                    <button type="button" className="close white closepopup" data-dismiss="modal">×</button>
                </div>
                {/* add Equipment List */}
                {/* -------------------------------- */}
                
                <div className="modal-body px-4 pb-5"> 
                    <div className="d-flex justify-content-between flex-wrap"> 
                    {this.state.addShoppingList.map((row,i)=>
                        <div className="form-group"><span className="cover-border"></span>
                            <label className="label">Item Name</label>
                            <input type="text" 
                            id ='selectedFile'
                            value = {row}
                            onChange ={(e)=>console.log(this.state.selectedShoppingList)}
                            className="input-field"
                            placeholder="" disabled />
                            <Link to="ChannelCreation" className="bg-circle position-absolute">
                                <i className="fa fa-minus pt-1" id={i} onClick={this.removeShoppingList} aria-hidden="true"></i>
                            </Link>
                        </div>
                        )}
                        {/*  */}
                    </div>
                    {/* modal copy */}
                    <div className="form-group m-auto"><span className="cover-border"></span>
                        <label className="label">Shopping List Item Name</label>
                        <input type="text" 
                        id ='selectedShoppingList'
                        value = {this.state.selectedShoppingList}
                        onChange ={(e)=>this.setState({[e.target.id]:e.target.value},()=>console.log(this.state.selectedShoppingList))}
                        className="input-field"
                         placeholder="" />
                       {this.shoppingValidator.message('Item name', this.state.selectedShoppingList, 'required|alpha')}
                    </div>
                    <div className="add_text text-center">
                        <Link to="ChannelCreation" className="bg-circle mr-4 d-inline-block float-none"><i className="fa fa-plus" onClick= {this.addShoppingListMethod} aria-hidden="true"></i></Link>
                    </div> 
                    {/*  */}
                </div>

                
                {/* --------------------------------- */}
                {/* <div className="modal-body px-4 pb-5"> 
                    <div className="form-group"><span className="cover-border"></span>
                        <label className="label">Equipment Item Name</label>
                        <input type="text" 
                        id ='selectedFile'
                        value = {this.state.selectedFile}
                        onChange ={(e)=>this.setState({[e.target.id]:e.target.value},()=>console.log(this.state.selectedFile))}
                        className="input-field"
                         placeholder="" />
                       
                    </div>
                    <div className="add_text text-center">
                        <a href="#" className="bg-circle mr-4 d-inline-block float-none"><i className="fa fa-plus" onClick= {this.addEquipment} aria-hidden="true"></i></a>
                    </div> 
                </div> */}
            </div>
        </div>

        {/* >2 End */}
        
        {/* <=3 Start */}
        {/* <div className="modal-dialog large_width">
            <div className="modal-content modl_bg_color">
                <div className="modal-header border_none p-4">
                    <h4 className="modal-title white pt-3">Shopping List</h4>
                    <button type="button" className="close white closepopup" data-dismiss="modal">×</button>
                </div>
                <div className="modal-body px-4 pb-5">
                    <div className="row"> 
                        <div className="col-md-4">
                            <div className="form-group"><span className="cover-border"></span>
                                <label className="label">Item Name</label>
                                <input type="text" className="input-field" placeholder="" />
                                <a href="#" className="bg-circle position-absolute">
                                    <i className="fa fa-minus pt-1" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group"><span className="cover-border"></span>
                                <label className="label">Item Name</label>
                                <input type="text" className="input-field" placeholder="" />
                                <a href="#" className="bg-circle position-absolute">
                                    <i className="fa fa-minus pt-1" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group"><span className="cover-border"></span>
                                <label className="label">Item Name</label>
                                <input type="text" className="input-field" placeholder="" />
                                <a href="#" className="bg-circle position-absolute">
                                    <i className="fa fa-minus pt-1" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add_text text-center">
                                <a href="#" className="bg-circle mr-4 d-inline-block float-none"><i className="fa fa-plus" aria-hidden="true"></i></a>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div> */}

        {/* <=3 End */}
            {/* <div className="donebg">
                <button type="button" data-dismiss="modal" className="done mt-2">save</button>
            </div> */}
    </div>


    <div className="modal pr-0 list-modal" id="equipment_lst_modal">
        {/* "modal-dialog small_width" Single >3 start {(this.state.something[0]?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"} */}
        <div className={(this.state.equipmentCount<3?"modal-dialog small_width":"modal-dialog large_width")}>
            <div className="modal-content modl_bg_color">
                <div className="modal-header border_none p-4">
                    <h4 className="modal-title white pt-3">Equipment List</h4>
                    <button type="button" className="close white closepopup" data-dismiss="modal">×</button>
                </div>

                {/* add Equipment List */}
                {/* -------------------------------- */}
                
                <div className="modal-body px-4 pb-5"> 
                    <div className="d-flex justify-content-between flex-wrap"> 
                    {this.state.addEquipmentList.map((row,i)=>
                        <div className="form-group mr-2"><span className="cover-border"></span>
                            <label className="label">Item Name</label>
                            <input type="text" 
                            id ='selectedFile'
                            value = {row}
                            onChange ={(e)=>console.log(this.state.selectedFile)}
                            className="input-field"
                            placeholder="" 
                            disabled/>
                            <Link to="ChannelCreation" className="bg-circle position-absolute">
                                <i className="fa fa-minus pt-1" id={i} onClick={this.removeEquipment} aria-hidden="true"></i>
                            </Link>
                        </div>
                        )}
                        {/*  */}
                    </div>
                    {/* modal copy */}
                    <div className="form-group m-auto"><span className="cover-border"></span>
                        <label className="label">Equipment Item Name</label>
                        <input type="text" 
                        id ='selectedFile'
                        value = {this.state.selectedFile}
                        onChange ={(e)=>this.setState({[e.target.id]:e.target.value},()=>console.log(this.state.selectedFile))}
                        className="input-field"
                         placeholder="" />
                     {this.equipmentValidator.message('Item name', this.state.selectedFile, 'required|alpha')}

                    </div>
                    <div className="add_text text-center">
                        <Link to="ChannelCreation" className="bg-circle mr-4 d-inline-block float-none"><i className="fa fa-plus" onClick= {this.addEquipment} aria-hidden="true"></i></Link>
                    </div> 
                </div>
                </div>
                {/* <div className="donebg">
                <button type="button" data-dismiss="modal" className="done mt-2">save</button>
            </div>  */}
        </div>
        </div>
        <div className="modal pr-0 show" id="product_lst_modal">
           <div className="modal-dialog large_width">
            <div className="modal-content modl_bg_color">
                <div className="modal-header px-4">
                    <h4 className="modal-title white">Product List<span>Tap on an attribute to make it active in the Product list</span></h4>
                    <button type="button" className="close white closepopup" data-dismiss="modal">×</button>
                </div>
                <div className="modal-body px-4 pb-5">
                    <div className="card cardbg mt-4">
                        <div className="form-group mb-0"><span className="cover-border"></span>
                            <label className="label">Name of the Product</label>
                            <input type="text" id='shoppingProductName' value={this.state.shoppingProductName} onChange={(e)=>this.setState({[e.target.id]:e.target.value},()=>console.log('*****',this.state.shoppingProductName))} className="input-field" />
                        </div>
                    </div>
                    <div className="card cardbg">
                        <h4 className="white mt-4 mb-3">Add Attribute</h4>
                        <div className="d-flex flex-wrap">
                        {this.state.addProduct.length>0?
                        (this.state.addProduct[0].attributes.map((row,i)=>
                        // {row.attributes.map((rows,l)=>
                        <Link to="HostSessionCreation" key={i} id ={i} name={row.attrKey} onClick = {this.addAttribute} className={(row.status?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mr-2 mt-2"} >{row.attrKey}</Link>
                        // )}
                        )):''}
                        </div>
                        <div className="border_bottom_dotted mt-4"></div>
                    </div>
                    <div className="card cardbg mt-5">
                        <div className="row">
                            {this.state.productInformation.length>0?
                            (this.state.productInformation.map((row,i)=>
                            <div className="col-md-4" key={i}>
                                <div className="form-group mb-0"><span className="cover-border"></span>
                                    <label className="label">{row.attrKey}</label>
                                    <input type="text" 
                                    id ={i}
                                    value={row.attrValue}
                                    onChange={this.handleProductList(i)}
                                    className="input-field" />
                                </div>
                                {/* {this.validator.message(row.attrKey, row.attrValue, this.state.test1+'|'+this.state.test2)} */}
                            </div>
                            )):''}
                        </div>
                        </div>
                </div>
            </div>
        </div> 
        <div className="text-center">
            {/* <button type="button" className="done mb-5 mt-2 mr-3">Preview</button> */}
            {/* <button type="button" data-toggle="modal" data-target="#audio_video_mdl" onClick= {this.state.saveProductList} className="done mt-4 mt-2">save</button> */}
            <button type="button" data-toggle="modal" data-target="#audio_video_mdl"  className="done mt-4 mt-2">Add Media</button>
            {/* <input type="submit" name="submit" value={this.saveProductList}/>                     */}
        </div>
       </div>

    <div className="modal pr-0 show" id="audio_video_mdl">
         <div className="modal-dialog large_width">
            <div className="modal-content modl_bg_color">
                {/* <div className="modal-header px-4">
                    <h4 className="modal-title white">Product List<span>Tap on an attribute to make it active in the Product list</span></h4>
                    <button type="button" className="close white closepopup" data-dismiss="modal">×</button>
                </div> */}
                <div className="modal-body px-4 pb-5">
                    <div className="card cardbg mt-4">
                    <div className="gray-box-5 mt-5">
      <div className="container-fluid register-frm pb-3 mt-md-4 px-4">                
        <div className="video_img position-relative">
          <span className="cover-border"></span>
          <label className="label">Description</label>
          <div className="input-field position-relative d-lg-flex flex-wrap d-block px-3">
            
            <div className="one flex-fill mr-4 position-relative">
            
              <div className="custom-file mb-3">
                <input type="file" className="custom-file-input" id="videoFile" name="file" onChange = {this.saveVideoFile} />
                <label className="custom-file-label px-1"  htmlFor="videoFile">
                    <img src="images/video2.png" className="browse_image1" alt=''/>
                    <p className="purple_text browse_text"><span className="white">VIDEO</span><br />Browse File</p>
                    <Link to="/ChannelCreation" className="bg-circle position-absolute"><i className="fa fa-minus pt-1" id="0" aria-hidden="true"></i></Link>
                </label>
              </div>
            </div>
            <div className="one flex-fill position-relative">
              <div className="custom-file mb-3">
                <input type="file" className="custom-file-input" id="imageFile" name="file" onChange = {this.saveVideoFile} />
                <label className="custom-file-label px-1" htmlFor="imageFile">
                    <img src="/images/image1.png" className="browse_image1" alt="#"/>
                    <p className="purple_text browse_text"><span className="white">IMAGE</span><br />Browse File</p>
                    <Link to="/ChannelCreation" className="bg-circle position-absolute"><i className="fa fa-minus pt-1" id="0" aria-hidden="true"></i></Link>
                </label>
              </div>
            </div>
          </div>
          <div className="text-center">
          <button type="button" data-toggle="modal" data-target="#audio_video_mdl" onClick= {this.saveProductList} className="done m-auto" >save</button>
            </div>
        </div>                          
      </div>
    </div>
                    </div>
                    
                </div>
            </div>
        </div> 
    </div>
    {/* shivani channel creation */}
    <div className="modal" id="pick_channel">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content bg-black">
                <div className="modal-header">
                    <button type="button" className="close white closepopup" onClick={e=>$("#pick_channel").attr({'style':'display:none'})} data-dismiss="modal">×</button>
                </div>
                <div className="modal-body pb-5">
                    <div className="py-5">
                        <h4 className="white text-center">Pick Channel Type</h4>
                        <div className="d-flex justify-content-center align-items-center flex-wrap">
                            <div className="f_box" onClick={e=>{this.setState({chType:1},()=>$("#pick_channel").attr({'style':'display:none'}))}}>
                                <div className="b-image">
                                    <img src="/images/business.png" />
                                </div>
                                <p className="white text-center mt-3" >Business</p>
                            </div>
                            <div className="f_box" onClick={e=>{this.setState({chType:2},()=>$("#pick_channel").attr({'style':'display:none'}))}}>
                                <div className="b-image">
                                    <img src="/images/individual.png" />
                                </div>
                                <p className="white text-center mt-3" >Individual</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* shivani channel creation */}
    <div className="modal pr-0 pt-4 pt-md-5" id="error_msg" aria-modal="true"  >
   	<div className="modal-dialog dialogwidth mx-auto">
      <div className="modal-content bg-transparent px-2">
      	<div className="modal-body px-4 pb-4 modl_bg_drk radius-10 shadow-none">
					<p className="py-4 text-light text-center font-20">{this.state.responseMessage}</p> 	
            
          </div>
          <div className="text-center">
          <button type="button" 
            className="custom_btn1 mt-3" 
            data-dismiss="modal" 
            onClick={e=>{
              $("#error_msg").attr({'style':'display:none'});
              if(!this.validator.fieldValid('Channel Name')){
                this.setState({
                    errorClass_ChannelName:true
                })
                this._channelName.focus();
               }
               if(!this.validator.fieldValid('Description')){
                this.setState({
                    errorClass_Description:true
                })   
                this._Description.focus();
               }
               
               if(!this.validator.fieldValid('Street Address')){
                this.setState({
                    errorClass_streetAddress:true
                })   
                this._streetaddress.focus();
               }
               if(!this.validator.fieldValid('City')){
                this.setState({
                    errorClass_City:true
                })   
                this._city.focus();
               }
               if(!this.validator.fieldValid('Phone Number')){
                this.setState({
                    errorClass_PhoneNumber:true
                })   
                this._phoneNumber.focus();
               }
               if(!this.validator.fieldValid('State Code')){
                this.setState({
                    errorClass_StateCode:true
                })   
                this._statecode.focus();
               }
               
               if(!this.validator.fieldValid('Zip Code')){
                this.setState({
                    errorClass_ZipCode:true
                  })   
                this._zipcode.focus();
               }
          }}>ok</button>
          </div>
      </div>
    </div>
  </div>
  {/* validateImage Model */}
                <div className="modal pr-0 pt-5 pt-md-5" id="cancel_session" aria-modal="true">
						<div className="modal-dialog dialogwidth mx-auto">
							<div className="modal-content bg-transparent">
								<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
									<div className="py-4 text-light text-center font-20">Image formate not supported</div>
								</div>
								<div className="text-center">
								<button type="button" 
									className="custom_btn1 mx-2 mt-3" 
									data-dismiss="modal" 
									onClick={e=>{
									$("#cancel_session").attr({'style':'display:none'});
									}}>Ok</button>
							</div>
							</div>
							</div>
						</div>

    {/* Channel host model */}
    <div className="modal" id="dasboard_myModal2">
						<div className="modal-dialog">
						<div className="modal-content">
							<div className="text-center">
								<img src="/images/host.png" alt="" />
								<p className="white mt-3">Invite Someone to be a Instructor</p>
							</div>
							<div className="modal-body round p-4 mt-3">
								<div className="card cardbg">
								<div className="px-3">
									<div className="row">
										<div className="col-md-6 mt-4 mt-4">
											<div className="custom-control custom-radio">
												<input type="radio" className="custom-control-input" id="customRadio1" value="true"   name="example1" checked={this.state.customRadio1} onChange={this.checkHost1} />
												<label className="custom-control-label" htmlFor="customRadio1">
													<p className="ml-2 mb-0 p-top-3">New Instructor</p>
												</label>
											</div>    
										</div>
										<div className="col-md-6 mt-4 mt-4">
											<div className="custom-control custom-radio mb-20">
												<input type="radio" className="custom-control-input" id="customRadio2" value="false" name="example1" checked={this.state.customRadio2} onChange={this.checkHost2}  />
												<label className="custom-control-label" htmlFor="customRadio2">
												<p className="ml-2 mb-0 p-top-3">Existing Instructor</p></label>
											</div>  
										</div>								
											{this.state.customRadio2 ?
											<div className="col-md-6 mt-4 mt-4" id="dash_land_block">
												{/* <span className="cover-border "></span>
												<label className="label">Enter First Name</label>
												<div className="">
													<input type="text" className="input-field" value={this.state.boissetWine[this.state.channelPopup].upComing} placeholder="First name" disabled/>
													<span className="signedup_2"></span>
												</div> */}
												<div className="form-group">
													<span className="cover-border bg_gray_clr"></span>
													<label className="label">Enter First Name</label>
													<input type="text" className="input-field" value='' placeholder="First name" disabled/>
													<span className="signedup_2"></span>
												</div>
											</div>
											:''}    
											{this.state.customRadio2 ?
											<div className="col-md-6 mt-4 mt-4" id="dash_land_block">
												{/* <span className="cover-border "></span>
												<label className="label">Enter Last Name</label>
												<div className="">
													<input type="text" className="input-field" placeholder="Last name" disabled/>
													<span className="signedup_2"></span>
												</div> */}
												<div className="form-group">
													<span className="cover-border bg_gray_clr"></span>
													<label className="label">Enter Last Name</label>
													<input type="text" className="input-field" placeholder="Last name" disabled/>
													<span className="signedup_2"></span>
												</div>
											</div>
											:''}
										{this.state.customRadio2 ?
										<div className="col-md-12 mt-4 mt-4">
											{/* <span className="cover-border "></span>
											<label className="label">Email Address</label>
											<div className="">
												
												<select className="input-field" id="mail" value={this.state.mail} onChange={this.mail}>                     
												{this.state.hostMail.map((row,i)=>
												<option key={i} value={row.mail}>{row.mail}</option>
												)}  
												</select>
												
											</div> */}
											<div className="form-group">
												<span className="cover-border bg_gray_clr"></span>
												<label className="label">Email Address</label>
												<select className="input-field" id="mail" value={this.state.mail} onChange={(e)=>this.setState({[e.target.id]:e.target.value})}>                     
												{this.state.hostMail.map((row,i)=>
												<option key={i} value={row.mail} key = {i}>{row.mail}</option>
												)}  
												</select>
												{/* <span className="dashboard_land"></span> */}
											</div>
										</div>
										:''}
										{/* XYZ */}
										{/* new user*/}
										{this.state.customRadio1 ?
											<div className="col-md-6 mt-4">
												{/* <span className="cover-border "></span>
												<label className="label">Enter First Name</label>
												<div className="">
													<input type="text" className="input-field"  placeholder="First namedasdsadsadasdasdsad" />
													<span className="signedup_2"></span>
												</div> */}
												<div className="form-group">
													<span className="cover-border bg_gray_clr"></span>
													<label className="label">Enter First Name</label>
													<input type="text"  className="input-field" placeholder="First name" />
													<span className="signedup_2"></span>
												</div>
											</div>
											:''}
											{this.state.customRadio1 ?
											<div className="col-md-6 mt-4">
												{/* <span className="cover-border "></span>
												<label className="label">Enter Last Name</label>
												<div className="">
													<input type="text" className="input-field" placeholder="Last name" />
													<span className="signedup_2"></span>
												</div> */}
												<div className="form-group">
													<span className="cover-border bg_gray_clr"></span>
													<label className="label">Enter Last Name</label>
													<input type="text"  className="input-field" placeholder="Last name" />
													<span className="signedup_2"></span>
												</div>
											</div>
											:''}
										{this.state.customRadio1 ?
										<div className="col-md-12 pr-md-2 mt-4">
											{/* <span className="cover-border "></span>
											<label className="label">Email Address</label>
											<div className="">
												<input type="email" className="input-field" placeholder="Last name" />
												<span className="dashboard_land"></span>
											</div> */}
											<div className="form-group">
												<span className="cover-border bg_gray_clr"></span>
												<label className="label">Email Address</label>
												<input type="text" id="" className="input-field" placeholder="Enter email" />
												<span className="dashboard_land"></span>
											</div>
										</div>
										:''}
										{/* new user end */}
										<div className="col-md-4">
											<h3 className="info">
												<img src="images/testing.png" className="mr-3 text_lft_icon" alt="script-icon" />Role
											</h3>
										</div>
											<div className="col-md-4 px-4">
												<div className="custom-control custom-radio">
													<input type="radio" className="custom-control-input" id="customRollRadio1" value="true"   name="example2" checked={this.state.customRollRadio1} onChange={this.checkRoll1} />
													<label className="custom-control-label" htmlFor="customRollRadio1"> 
													<p className="ml-2 mb-0 p-top-3">Adminstration & instructor</p> </label>
												</div> <br/>
												<div className="custom-control custom-radio">
													<input type="radio" className="custom-control-input" id="customRollRadio2" value="false"  name="example2" checked={this.state.customRollRadio2} onChange={this.checkRoll2} />
													<label className="custom-control-label" htmlFor="customRollRadio2">
													<p className="ml-2 mb-0 p-top-3">instructor</p></label>
												</div>  
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="donebg"><button type="button" data-toggle="modal" data-dismiss="modal"  className="done mt-5" id="checkHost" >Invite</button></div>
						</div>
						</div>
						</div>

{/* Channel Host Ends */}
		<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
    </div>
)
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(EditChannelCreation);