import React, { Component } from "react";
import { browserHistory } from 'react-router'
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class ReportsDaily extends Component {
constructor(props) {
	super(props);
	this.state = { 
					currentSubTab: 'today',
					searchStartDate: this.props.startDate,
					searchEndDate: this.props.endDate,
					dataChange: false,
					currentHost: -1,
					currentInterest: -1,
					sessionStatus: -1,
					
					currentArray: [],
					sortByVal: ['id', 'DESC'],
					
					pageCount: 20,
					lastTab: 'daily',
					searchSessionName: ''
				}
	
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

changeTab=(e)=>{
	this.setState({searchStartDate: new Date(), dataChange: false, currentHost: -1, currentInterest: -1, sessionStatus: -1, searchStartDate: new Date(), dataChange: false, searchSessionName : ''}, function ()
		{
			$('#currentInterest, #currentHost, #sessionStatus').val(-1).attr({'style':''});
			$('#searchSessionName').val('');
			this.props.updateList({startDate: new Date(), ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: {}, currentSubTab: e});
		});
}

changeCurDate=(e)=>{

	this.setState({searchStartDate: e, dataChange: true}, function ()
		{
			this.props.updateList({startDate: this.state.searchStartDate, ajaxLoaded: false, currentArray: [], updateReload: 1});
		});
}

changeCurDateEnd=(e)=>{

	this.setState({searchEndDate: e, dataChange: true}, function ()
		{
			this.props.updateList({endDate: this.state.searchEndDate, ajaxLoaded: false, currentArray: [], updateReload: 1});
		});
}

updateThisFilter=(e)=>{
	this.setState({[e.target.id]: e.target.value}, function ()
		{
			var fullFilter = {currentHost: this.state.currentHost, currentInterest: this.state.currentInterest, sessionStatus: this.state.sessionStatus, searchSessionName: this.state.searchSessionName};
			this.props.updateList({startDate: this.state.searchStartDate, pageCount: this.state.pageCount, ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: fullFilter});
		});
	
	e.preventDefault();
}

searchResults=(e)=>{
	var fullFilter = {currentHost: this.state.currentHost, currentInterest: this.state.currentInterest, sessionStatus: this.state.sessionStatus, searchSessionName: this.state.searchSessionName};
	this.props.updateList({startDate: this.state.searchStartDate, pageCount: this.state.pageCount, ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: fullFilter});
}

resetFilter=()=>{
	this.setState({searchStartDate: new Date(), dataChange: false, currentHost: -1, currentInterest: -1, sessionStatus: -1});
	$('#currentInterest, #currentHost, #sessionStatus').val(-1).attr({'style':''});
	this.setState({searchStartDate: new Date(), dataChange: false}, function ()
		{
			this.props.updateList({startDate: new Date(), ajaxLoaded: false, currentArray: [], updateReload: 1, filterDaily: {}});
		});
}

sortArrays=(sortby)=>{
	var sortorder = 'ASC';
	if ( this.state.sortByVal[0] == sortby ) { sortorder = 'DESC'; }

	this.props.updateList({ajaxLoaded: false, currentArray: [], updateReload: 1, startPage: 1, sortByVal: [sortby, sortorder]});
	this.setState({sortByVal: [sortby, sortorder]});
}

openPage=(pg)=>{

var fullFilter = {currentHost: this.state.currentHost, currentInterest: this.state.currentInterest, sessionStatus: this.state.sessionStatus, searchSessionName: this.state.searchSessionName};
this.props.updateList({startDate: this.state.searchStartDate, ajaxLoaded: false, currentArray: [], updateReload: 1, startPage: pg, filterDaily: fullFilter, pickedStartPage: true});

}

expandRow=(ind, ses)=>{
	
	var oldarr = this.props.currentArray;
	if ( ses )
		{
			if ( oldarr[ind]['expandRow2'] )
				{
					oldarr[ind]['expandRow'] = false;
					oldarr[ind]['expandRow2'] = false;
				}
			else
				{
					oldarr[ind]['expandRow'] = true;
					oldarr[ind]['expandRow2'] = true;
				}
		}
	else
		{
			if ( oldarr[ind]['expandRow'] )
				{
					if ( oldarr[ind]['expandRow2'] )
						{
							oldarr[ind]['expandRow2'] = false;
						}
					else
						{
							oldarr[ind]['expandRow'] = false;
						}
				}
			else
				{
					oldarr[ind]['expandRow'] = true;
				}
		}
	this.props.updateList({currentArray: oldarr});
	
	if ( oldarr[ind]['expandRow'] ) { this.props.loadData(ind, ses); }
}


componentDidMount(){

}

componentDidUpdate( nextProps, prevState ){
	
	if ( this.props.currentTab !== this.state.lastTab )
		{
			var startDate = new Date();
			var endDate = new Date();
			if ( this.props.currentTab == 'past' ) { startDate.setDate(1); }
			if ( this.props.currentTab == 'future' ) { endDate.setMonth(endDate.getMonth() + 1); endDate.setDate(0) }
			
			this.setState({lastTab: this.props.currentTab, searchStartDate: startDate, searchEndDate: endDate});
			
			this.setState({searchStartDate: startDate, dataChange: false, currentHost: -1, currentInterest: -1, sessionStatus: -1});
			$('#currentInterest, #currentHost, #sessionStatus').val(-1).attr({'style':''});
		}
	
	if( moment(new Date(this.props.startDate)).format('YYYYMMDD') !== moment(new Date(nextProps.startDate)).format('YYYYMMDD') ) { console.log('match'); this.setState({ searchStartDate: this.props.startDate }); }
	if( moment(new Date(this.props.endDate)).format('YYYYMMDD') !== moment(new Date(nextProps.endDate)).format('YYYYMMDD') ) { console.log('match'); this.setState({ searchEndDate: this.props.endDate }); }
}

  render() {
	
    return (
		<div>
			{ this.props.currentTab === 'daily' && <div className="daily_session_hold">
					<div className={ "daily_section " + ( this.props.currentSubTab == 'today' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('today')}}>
						<div className="daily_title">Day’s Classes</div>
						<div className="daily_arrow"></div>
					</div>
					<div className={ "daily_section " + ( this.props.currentSubTab == 'new' ? "daily_section_active" : "" ) } onClick={e=>{this.changeTab('new')}}>
						<div className="daily_title">Classes Created (New)</div>
						<div className="daily_arrow"></div>
					</div>
				</div> }
			
			<div className="pay_main">
				<div className="pay_head new_pay_head_width">
					{ this.props.currentTab === 'daily' && this.props.currentSubTab == 'today' && <div className="pay_head_title">Day’s Classes</div> }
					{ this.props.currentTab === 'daily' && this.props.currentSubTab == 'new' && <div className="pay_head_title">New Classes</div> }
					{ this.props.currentTab === 'past' && <div className="pay_head_title">Past Classes</div> }
					{ this.props.currentTab === 'future' && <div className="pay_head_title">Futue Classes</div> }
					
					<div className="show_pay_page">
						Show
							<span className="select_span">
								<select onChange={this.updateThisFilter} defaultValue="20" className="input-field" id="pageCount">
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="50">50</option>
									<option value="100">100</option>
								</select>
							</span>
						Classes
					</div>
					
					<div className="show_search_opt noprint search_reports">
						
						{ ( this.state.dataChange || this.state.currentHost > 0 || this.state.currentInterest > 0 || this.state.sessionStatus > 0 ) && <div className="form-group">
							<div className="presetfilter" onClick={this.resetFilter}>Reset Filter</div>
						</div> }
							<div className="form-group">
								<span className="cover-border"></span>
								<label className="label">Class Name</label>
								<input id="searchSessionName" onChange={this.updateThisFilter} className="input-field" />
							</div>
						{ this.props.currentTab === 'daily' && this.props.currentSubTab === 'today' && <div className="form-group">
								<span className="cover-border"></span>
								<label className="label">Date</label>
								<DatePicker popperPlacement="bottom-start" popperModifiers={{flip: { enabled: false }, preventOverflow: { enabled: true, escapeWithReference: true, boundariesElement: 'viewport' } }} className="form-control datapickpay" placeholderText="mm/dd/yy" selected={this.state.searchStartDate} onChange={date => this.changeCurDate(date)} />
							</div> }
							
						{ ( this.props.currentTab === 'past' || this.props.currentTab === 'future' ) && <div className="form-group">
								<span className="cover-border"></span>
								<label className="label">Start Date</label>
								<DatePicker popperPlacement="bottom-start" popperModifiers={{flip: { enabled: false }, preventOverflow: { enabled: true, escapeWithReference: true, boundariesElement: 'viewport' } }} className="form-control datapickpay" placeholderText="mm/dd/yy" maxDate={this.props.currentTab === 'past' ? new Date() : '' } minDate={this.props.currentTab === 'future' ? new Date() : '' } selected={this.state.searchStartDate} onChange={date => this.changeCurDate(date)} />
							</div> }
						
						{ ( this.props.currentTab === 'past' || this.props.currentTab === 'future' ) && <div className="form-group">
								<span className="cover-border"></span>
								<label className="label">End Date</label>
								<DatePicker popperPlacement="bottom-start" popperModifiers={{flip: { enabled: false }, preventOverflow: { enabled: true, escapeWithReference: true, boundariesElement: 'viewport' } }} className="form-control datapickpay" placeholderText="mm/dd/yy" maxDate={this.props.currentTab === 'past' ? new Date() : '' } minDate={this.props.currentTab === 'future' ? new Date() : '' } selected={this.state.searchEndDate} onChange={date => this.changeCurDateEnd(date)} />
							</div> }
						
						
						{ ( this.props.currentSubTab == 'today' && this.props.currentTab !== 'past' && this.props.currentTab !== 'future') && <div className="form-group">
							<span className="cover-border"></span>
							<label className="label">Status</label>
							<select id="sessionStatus" onChange={this.updateThisFilter} className="input-field">
								<option value={-1}>All</option>
								<option value={1}>Past</option>
								<option value={2}>Ongoing</option>
								<option value={3}>Future</option>
							</select>
						</div> }
						
						<div className="form-group">
							<span className="cover-border"></span>
							<label className="label">Instructor</label>
							<select id="currentHost" onChange={this.updateThisFilter} className="input-field">
								<option key={-1} value={-1}>All Instructors</option>
								{ this.props.allHosts.map((item, key) => <option key={key} value={item['userId']}>{item['username']}</option> ) }
							</select>
						</div>
						
						<div className="form-group">
							<span className="cover-border"></span>
							<label className="label">Categories</label>
							<select id="currentInterest" onChange={this.updateThisFilter} className="input-field">
								<option value="-1">All Categories</option>
								{ this.props.allInterests.map((item, key) => <option key={key} value={item['id']}>{item['title']}</option> ) }
							</select>
						</div>
						<div className="right-small-box search_reports_hold" onClick={this.searchResults}>
							<img src="/images/search_loop.png" />
						</div>
					</div>
					
					{ this.props.currentArray.length > 0 &&
						<div className="table_holder_scroll">
							<table className="billing_table">
								<thead>
									<tr>
										<th>Class Name
											{	this.props.currentArray.length > 1 &&
												<div onClick={(e) => this.sortArrays('name')} className="reports_sort_icon"></div>
											}
										</th>
										<th>Instructor Name
											{	this.props.currentArray.length > 1 &&
												<div onClick={(e) => this.sortArrays('firstName')} className="reports_sort_icon"></div>
											}
										</th>
										<th>Date & Time
											{	this.props.currentArray.length > 1 &&
												<div onClick={(e) => this.sortArrays('scheduleDate')} className="reports_sort_icon"></div>
											}
										</th> 
										<th>Time Zone</th>
										<th>Duration
											{	this.props.currentArray.length > 1 &&	
												<div onClick={(e) => this.sortArrays('duration')} className="reports_sort_icon"></div>
											}
										</th> 
										{ this.props.currentSubTab == 'today' && <th>Signed Up/Attending</th> }
										{/*{ this.props.currentSubTab == 'today' && <th></th> }*/}
										{ this.props.currentSubTab == 'new' && <th>Signed</th> }
									</tr>
								</thead>
								<tbody> 
									{ this.props.currentArray.map((item, key) =>
										<React.Fragment key={'m'+key}>
											<tr key={'mc'+key}>
												<td>{ item['name'] } <div className={'info_btn '+( item['expandRow'] && !item['expandRow2'] ? 'open' : '')} onClick={(e) => this.expandRow(key)}></div></td>
												<td>{ item['firstName'] } { item['lastName'] }</td>
												<td>{ this.getSessionDate(item['scheduleDate'])} @ {this.getSessionTime(item['scheduleDate'])}</td>
												<td>{ item['timeZone'] }</td>
												<td>{ item['duration'] }</td>
												{ this.props.currentSubTab == 'today' && 
													<td>{ item['totalSignup'] } / { item['totalAttending'] }

														{ item.totalSignup > 0 && 
															<div className={'info_btn '+( item['expandRow2'] ? 'open' : '')} onClick={(e) => this.expandRow(key, true)}></div>
														}
													</td> 
												}
													{/*{ this.props.currentSubTab == 'today' && <td>{ item['sessionStarted'] ? <div className="rose_btn">Details</div> : '' }</td> }*/}
												{ this.props.currentSubTab == 'new' && <td>{ item['totalSignup'] }</td> }
											</tr>
											{
												item['expandRow'] &&
												<tr key={'mk'+key} className="subopen">
													<td colSpan={this.props.currentSubTab == 'today' ? 7 : 6}>
														{ !item['moreData'] && <div className="loadingResults"><div className="main_loader_inner"></div></div> }
														{ item['moreData'] &&
															<div className="reports_holder_scroll">
																<div className="moreDataShow">
																	{ item['moreData'].map((alldata, key) => <div key={'j'+key} className="new_div_vw" >{ alldata['mainTitle'] && <span>{alldata['mainTitle']}</span> }<table className="billing_table">
																		<thead>
																			<tr>{ alldata['titles'].map((tt, key) => <th key={'c'+key}>{tt}</th> )}</tr>
																		</thead>
																		<tbody>
																			{ alldata['data'].map((dta, key) => <tr key={'vv'+key}>{ dta.map((dtv, key) => <td className="dtval" key={'v'+key}>{dtv}</td> )}</tr>)}
																		</tbody>
																	</table></div> )
																	}
																</div>
															</div>		
														}
													</td>
												</tr>
											}
										</React.Fragment>
									)}
								</tbody>
							</table> 
						</div>
						}
					
					{ this.props.currentArray.length == 0 && this.props.ajaxLoaded && <div className="noResults">No Classes Found</div> }
					{ this.props.currentArray.length == 0 && !this.props.ajaxLoaded && <div className="loadingResults"><div className="main_loader_inner"></div></div> }
					
					{ this.props.currentArray.length > 0 && 
						<div className="showPageNavigation">
							<div className="showpinfo">Total { this.props.currentArray[0].totalNumber } classes</div>
							{ this.props.totalPages.length > 1 && <div className="shopnumbers">
								<span>
									{ this.props.totalPages.map((item, nindex) => 
										<span key={nindex} onClick={() => this.openPage(item)} className={"pgNumbers " + (this.props.startPage === item ? 'active' : '')}>{item}</span>	
									)}
								</span>
							</div> }
						</div>
					}
				</div>
			</div>
		</div>
    );
  }
}

export default ReportsDaily;