import axios from "axios";
import $ from 'jquery';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import { browserHistory } from 'react-router'

const participateCheckUser = function(data)
{ 
	console.log(this.state);
	console.log(data);
	// User found
	var isFullSession = 0;
	if ( this.state.participantData.parentRecrId && this.state.participantData.parentRecrId > 0 )
		{
			var sessionIdData = JSON.parse(localStorage.getItem("sessionIdData"));
			var allSessionData = this.state.participantData.sessionDetails
			for ( var i = 0; i < sessionIdData.length; i++ )
				{
					for ( var j = 0; j < allSessionData.length; j++ )
						{
							if ( allSessionData[j].id === sessionIdData[i] && allSessionData[j].maxAttendee === allSessionData[j].totalSignUp )
								{
									isFullSession = 1;
								}
						}
				}
		}
	else
		{
			if ( this.state.participantData.maxAttendee == this.state.participantData.totalSignUp ) 
				{
					isFullSession = 1;
				}
		}
	
	//User ejected from class by Host
	if ( data.isEjected )
		{
			var ejectedMsg = "Hi "+data.firstName+", you are ejected from this class by Instructor.";
			if ( data.reasonEjected && data.reasonEjected !== '' )
				{
					ejectedMsg = "Hi "+data.firstName+", you are ejected from this class by Instructor. Reason: "+data.reasonEjected;
				}
			
			this.setState({
				msg:  ejectedMsg,
				redirectToRoute:'/login'
			},()=>{ $("#existingUserModel").attr({'style':'display:block'}); });
			localStorage.removeItem('sessionIdData');
			return false;
		}
			
// User already registed with another class at the same time
	if ( data.sameSessionExist==="alReadySession" && (this.state.typeSession === "public" || this.state.typeSession === "private") )
		{
			this.setState({
				msg:"Hi "+data.firstName+", you are already registered to attend a class during a class , it is not possible on Virdio to have Overlapping classes." ,
				redirectToRoute:'/login'
			},()=>{
					$("#existingUserModel").attr({'style':'display:block'})
					
					//setTimeout(() => { $("#existingUserModel").attr({'style':'display:none'}); browserHistory.push('/login'); }, 5000);
					}
			);
			localStorage.removeItem('sessionIdData');
			return false;
		}
		
// Check is participant limit reached for this class
	if ( data.sameSessionExist==="ParticipantLimitReached" &&  ( this.state.typeSession==="public" || this.state.typeSession === "private" ) )
		{
			this.setState({
				msg:"The class is full, please contact "+ this.state.participantData.hostFirstName +" for upcoming classes.",
				redirectToRoute:'/login'
				},()=>{
				$("#existingUserModel").attr({'style':'display:block'})
				localStorage.removeItem('sessionIdData');
				//setTimeout(() => { $("#existingUserModel").attr({'style':'display:none'}); browserHistory.push('/login'); }, 5000);
				}
			);
			return false;
		}
		
// Session is limited to members only | meaning not public
	if ( this.state.typeSession === "private" )
		{
			localStorage.setItem('emailNext', this.state.email);
			
			// Session is charging
			if ( this.state.participantData.sessionChargeAllowed == 1 && data.privateMember == 'yes' && !data.memberExpire )
				{
					let member = 1;
					let fcfs = this.calculatePrice( member, this.state.sessionsPicked );
					let cfs = this.calculatePrice( member, this.state.sessionsPicked, true);
					let sdfps = this.calculatePrice( member, this.state.sessionsPicked, false, true );
					
					this.setState({
							isChargingSessionIncluded: true,
							finalChargeForSession: fcfs,
							chargeForSession: cfs,
							sessionHostFirstName: this.state.participantData.hostFirstName,
							sessionHostLastName: this.state.participantData.hostLastName,
							sessionsTotal : this.state.totalSessions,
							log_in: true,
							sign_up: false,
							pickedSessions: sdfps,
							loginOpenFromExisting: false
						},()=>{ $("#chargePopup").attr({'style':'display:block'}) }
					);
				
					return false;
				}
			
			// Session is free below
			// Is user a private member - redirect to login
			if ( data.privateMember === "yes" && !data.memberExpire )
				{
					localStorage.setItem('emailNext', this.state.email);
					return true;
				}
				
			// if user membership is expired | show message 
			
			if ( data.memberExpire )
				{
					this.setState({
						msg: this.state.participantData.hostFirstName+" has set this class to be for channel members only and your membership is expired. If you feel this is a mistake, please contact  " +this.state.participantData.hostFirstName+".",
						redirectToRoute:'/login'
						},()=>{
						$("#existingUserModel").attr({'style':'display:block'})
						
						//setTimeout(() => { $("#existingUserModel").attr({'style':'display:none'}); browserHistory.push('/login'); }, 5000);
						}
					);
					localStorage.removeItem('sessionIdData');
					return false;;
				}
			
			
			// if user is not a private member | show message 
			if ( data.privateMember === "no" )
				{
					this.setState({
						msg: this.state.participantData.hostFirstName+" has set this class to be for channel members only and your name is not showing up on the member list.  If you feel this is a mistake, please contact " +this.state.participantData.hostFirstName+" and have them add you to their member list and try again.",
						redirectToRoute:'/login'
						},()=>{
						$("#existingUserModel").attr({'style':'display:block'})
						
						//setTimeout(() => { $("#existingUserModel").attr({'style':'display:none'}); browserHistory.push('/login'); }, 5000);
						}
					);
					localStorage.removeItem('sessionIdData');
					return false;
				}
		}		
	/* 
	// User already registed for this class
	if ( data.sameSessionExist === "Yes" && this.state.typeSession === "public" )
		{
			this.setState({
				msg:"Hi "+ data.firstName+ " you are already signed up for this class.",
				firstName: '',
				lastName: '',
				password: '',
				rePassword: '',
				redirectToRoute:'/login'
			},()=>{
					$("#existingUserModel").attr({'style':'display:block'}); 
					
						//setTimeout(() => { $("#existingUserModel").attr({'style':'display:none'}); browserHistory.push('/login'); }, 5000);
					}
			);
			localStorage.removeItem('sessionIdData');
			return false;
		}
		 */
		 
		 
	// User is not part of this class and he is Member or Host type and class is public
	if ( data.sameSessionExist==="No" && this.state.typeSession === "public" )
		{
			// Check is class already full
			if ( isFullSession == 1 )
				{
					$('#full_session_alert').attr({'style':'display:block'});
					return false;
				}
			
			localStorage.setItem('emailNext', this.state.email);
			// Session is charging
			if ( this.state.participantData.sessionChargeAllowed == 1 )
				{
					let member = data.privateMember == 'yes' ? 1 : 0;
					let fcfs = this.calculatePrice( member, this.state.sessionsPicked );
					let cfs = this.calculatePrice( member, this.state.sessionsPicked, true);
					let sdfps = this.calculatePrice( member, this.state.sessionsPicked, false, true );
				
					this.setState({
							isChargingSessionIncluded: true,
							finalChargeForSession: fcfs,
							chargeForSession: cfs,
							sessionHostFirstName: this.state.participantData.hostFirstName,
							sessionHostLastName: this.state.participantData.hostLastName,
							sessionsTotal : this.state.totalSessions,
							log_in: true,
							sign_up: false,
							pickedSessions: sdfps,
							loginOpenFromExisting: false
						},()=>{ $("#chargePopup").attr({'style':'display:block'}) }
					);
					return false;
				}
			else
				{
					// Session is free, proceed to login
					this.setState({
						msg:'User successfully found, please continue with login',
						redirectToRoute:'/login',
						user_selected : 'existing',
						},()=>{
						/* $("#existingUserModel").attr({'style':'display:block'})
						
						setTimeout(() => { $("#existingUserModel").attr({'style':'display:none'}); browserHistory.push('/login'); }, 5000); */
						}
					);
					return true;
				}
		}
	
	// Check is host trying to sign up
	if ( data.sameSessionExist === "sameHost" && data.type === 1 )
		{
			var milisecondSchedule = new Date(this.state.participantData.scheduleDate).getTime();
			var currentTime = new Date().getTime();
			var milisecondsDifference = milisecondSchedule - currentTime;
			var minutesDifference = Math.floor(milisecondsDifference / 60000);
			var defaultMinutes = 15;
			if ( JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).data.responseData.settings )
				{
					if ( parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.streamJoiningDefaultTime) > 0 )
						{
							defaultMinutes = parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.streamJoiningDefaultTime);
						}
				}
				
			if ( minutesDifference > defaultMinutes )
				{
					this.setState({
						msg:"Hi "+data.firstName+", you are hosting this class and can not sign up to participant in it " ,
						redirectToRoute:'/login'
						},()=>{
						$("#existingUserModel").attr({'style':'display:block'})
						
						//setTimeout(() => { $("#existingUserModel").attr({'style':'display:none'}); browserHistory.push('/login'); }, 5000);
						}
					);
					localStorage.removeItem('sessionIdData');
					return false;
				}
		}
	return true;
}

export { participateCheckUser };