import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import moment1 from 'moment-timezone';
import Calendar from 'react-calendar';
import SimpleReactValidator from 'simple-react-validator';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import {  browserHistory } from 'react-router'
import Countdown from 'react-countdown-now';
import Login from '../auth/Login';
import MainSignUp from '../auth/MainSignUp';
import OnDemandSessions from '../home/OnDemandSessions';
import { submitHost } from '../../util/submitHost';
import { verify } from '../../util/verify';
import { channelCheckUser } from '../../util/channelCheckUser';
import { connect } from "react-redux";
import { updatesse } from '../../util/updatesse';
import classnames from "classnames";
import ChargingSession from '../home/ChargingSession';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import customProtocolCheck from "custom-protocol-check";
import { showMainErrorPop } from '../../actions/popups';
import { deleteCookie } from '../auth/Logout';
import { joinSessionValidate } from '../../util/joinSessionValidate';
import { setCookie } from '../../util/cookie';
import SupportContainer from '../home/SupportContainer';
import ParticipantSignedUp from '../home/participantSignedUp';

import ChannelHeader from '../home/ChannelHeader';
import ChannelSearch from '../home/ChannelSearch';
import ChannelUpcoming from '../home/ChannelUpcoming';
import ChannelOnDemand from '../home/ChannelOnDemand';

import { getSessionDate } from '../../util/formatDateFunctions';
import { getOnDemandDuration } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class ChannelNew extends Component {
constructor(props) {
	super(props);
	this.state = {
		choosenTab: 'live',
		expandSearch: false,
		showDuration: false,
		showLevel: false,
		showHost: false,
		openedChannelName: '',
		isFoundChannel: true,
		logedUser: false,
		logedUserName : '',
		logedUserImage: '',
		channelObject: [],
		weeks7Array: [],
		upcomingArray: [],
		upcomingArrayDates: [],
		upcomingLength: 0,
		selectedWeekIndex: 0,
		mainChannelSessions: {},
		weeksArray: [],
		channelHost: [],
		channelInterestList: [],
		showInterest: false,
		activeFilter: '',
		showCancelPop: false,
		sessionDetailsId: 0,
		showActivityList: false,
		activityListSession: [],
		filterEquipment: '', 
		filterLevel: '', 
		fullStartDate: '', 
		fullEndDate: '', 
		caseForSort: '',
		openSort: false,
		mainDemandObject: [],
		sessionDetailsOnDemandId: 0,
		showLogOutBtn: false,
		responseMessage: '',
		cancelSessionId: 0,
		sortName: 'Newest',
		demandSessionId: 0,
		isLoginOpen: false,
		openedDemand: '',
		openLoginStart: true,
		isOpenLoginAll: false,
		mainUserData: {},
		userMainId: 0,
		errors: {},
		userSignedUp: false,
		sessionStartTimeHigher15Min: false,
		urlElectron: '',
		urlPath:'',
		sessionScheduleDate: '',
		sessionTimezone: '',
		sessionChannelName: '',
		isHost: false,
		sessionName: '',
		isOnDemandSession: false,
		sessionType: 'Upcoming',
		isOpenSign: false,
		emailSign: '',
		signup: false,
		verify: false,
		password: '',
		rePassword: '',
		phone: '',
		isLoadingSessions: false,
		justSignIn: false,
		backroundColorPick: '#000000',
		fontColorPick: '#DDDDDD',
		buttonsTextColor: "#6D118C",
		activeTabColor: '#EFF0F7',
		fontTitleColorPick: '#BCA4FF',
		buttonsColorPick: '#E4DAFF',
		highlightColorPick: '#FFFFFF',
		highlightColorPick2: '#FFFFFF',
		searchBackgroundColor: '#24223D',
		searchBoxShadow: '2px 4px 18px 11px #3d1a4a inset',
		searchBackground: '',
		searchFont: '#ffffff',
		searchInputBackground: '#24223D',
		searchInputBorder: '#ffffff',
		startDateFilter: '',
		endDateFilter: '',
		backFilter: 0,
		backFilterEnd: 7,
		selectedInterests: [],
		selectedLevel: '',
		selectedHostsArray: [],
		minDuration: 0,
		maxDuration: 0,
		searchByName: '',
		selectedEquipmentArray: [],
		currentEqText: '',
		timeOutRefresh: false,
		minDateFilter:new Date(),
		minEndDateFilter: new Date(),
		currentDataLength: 0,
		currentLoadNum: 1,
		currentLoadNumOnDemand: 1,
		allOnDemandLength: 0,
		detectChannelPage: true,
		firstTimeLoad: true,
		showClearButton: false,
		equipmentInput: '',
		closedSearch: '#545461',
		closedSearchBorder: '#545461',
		openSearchBorder: "#24223D",
		choosenTabColor: '#BCA4FF',
		dateHolderColor: '#24223D',
		chosenOptionColor: '#6D118C',
		equipmentInputBackground: '#0A091A',
		popupFontColor: '#ffffff',
		popupActHolder: '#3F3E4D',
		popupBackgroundColor: '#545461',
		calendarBackgroundColor: '#24223D',
		calendarBackgroundColorMonth: '#424141',
		inputInPopActivityColor: '#3F3E4D',
		imgFillColor: '#FFFFFF',
		choosenTabColorText: '#14142B',
		eqImgFillColor: '#6D118C',
		backFilterEndChanged: false,
		mainLoaderColor: '#bd00ff',
		inputFillColor: '#FFFFFF',
		resetBtnColor: '#E4DAFF',
		firstTabOnDemand: false,
		choosenSort: '',
		showLiveTab: 1,
		showOndemandTab: 1,
		isChannel: true,
	}
	this.validator = new SimpleReactValidator({autoForceUpdate: this});
	this.updatesse = updatesse.bind(this);	
	this.getSessionDate = getSessionDate.bind(this);
	this.getOnDemandDuration = getOnDemandDuration.bind(this);
	this.deleteCookie = deleteCookie.bind(this);
	this.emailValidate  = new SimpleReactValidator();
	this.joinSessionValidate = joinSessionValidate.bind(this);
	this.submitHost = submitHost.bind(this);
	this.verify = verify.bind(this);
	this.otpValidation = new SimpleReactValidator();
	this.setCookie = setCookie.bind(this);  
}



componentDidMount(){
	if( !this.props.from_search ) 
		{
			//$('.main_loader').attr({'style':'display:flex'});
			let host = window.location.host;
			let parts = host.split(".");
			let channelName = parts[0];

			if ( window.location == 'http://localhost:3000/' ) { channelName = 'mindchannel99'; }

			this.setState({ openedChannelName : channelName });	
			this.getChannelInfo(channelName);
		}
	else
		{
			this.setState({ isFoundChannel: true, sessionType: 'SearchResult', weeksArray: this.props.weekslist , mainChannel: this.props.sessionslist });
		}
		
	if ( localStorage.getItem("userData") )
		{
			this.setState({ logedUser: true });
			this.setState({
				logedUser: true,
				logedUserName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName,
		}, () => {
			console.log(this.state.logedUserName);
		});	
			if ( JSON.parse(localStorage.getItem('userData')).data.responseData.image && JSON.parse(localStorage.getItem('userData')).data.responseData.image != '/images/host-user.png' )
				{
					this.setState({
						logedUserImage: JSON.parse(localStorage.getItem('userData')).data.responseData.image,
					})
				}
		}	
}

selectMod =(mod)=>{
	
	this.setState({
		sessionDetailsOnDemandId: 0,
		sessionDetailsId: 0, 
		selectedInterests: [],
		selectedLevel: '',
		selectedHostsArray: [],
		minDuration: 0, 
		maxDuration: 0,
		searchByName: '',
		expandSearch: false, 
		selectedEquipmentArray: [],
	}, () => {
		this.startFilterSearchWithoutLoader();
	});
	
	if ( mod == 'live' ) 
		{
			this.setState({sessionType: 'Upcoming', choosenTab: mod});
		}
	else 
		{
			this.setState({sessionType: 'On Demand', choosenTab: mod});
		}		
}

updateStateFromChild = (state, ev)=>{
	if ( ev )
		{
			ev.preventDefault();
			ev.stopPropagation();	
		}
	
	this.setState(state);
}

closePartSignedUpChannel =()=>{
	this.setState({userSignedUp: false});
}

getChannelInfo=(subdomain)=>{
	$('.main_loader').attr({'style':'display:flex'});
	var postData = {channelName: subdomain};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelBySubdomain", postData).then(res => {
		if ( res.data.chanelData.id ) 
			{ 
				this.setState({
					channelObject: res.data.chanelData, 
					mainChannel: res.data.chanelData, 
					sessionChannelName: res.data.chanelData.name,
					showLiveTab: res.data.chanelData.channelSettings.showLiveTab,
					showOndemandTab: res.data.chanelData.channelSettings.showOndemandTab
					}, () => {	this.getWeeks();});
				
				if ( res.data.chanelData.channelSettings.firstTabOnDemand && res.data.chanelData.channelSettings.firstTabOnDemand == 1 )
					{
						this.setState({
							choosenTab: 'On-Demand',
							firstTabOnDemand: true
						});
					}
				else 
					{
						this.setState({
							firstTabOnDemand: false
						});
					}					
					
				if ( this.state.showLiveTab != 1 )
					{
						this.setState({
							choosenTab: 'On-Demand'
							});
					}
					
				if ( this.state.showOndemandTab != 1 && this.state.choosenTab == 'On-Demand' )
					{
						this.setState({
							choosenTab: 'live'
							});
					}
					
				if ( this.state.showLiveTab != 1 && this.state.choosenTab == 'live' )
					{
						this.setState({
							choosenTab: 'On-Demand'
							});
					}

				if ( this.state.showLiveTab != 1 && this.state.showOndemandTab != 1 )
					{
						this.setState({
							choosenTab: ''
							});
					}					
					
				if ( res.data.chanelData.ChannelInterest )
					{
						this.setState({
							channelInterestList: res.data.chanelData.ChannelInterest,
						});
					}
					
				if ( res.data.chanelData.channelHosts )
					{
						this.setState({
							channelHost: res.data.chanelData.channelHosts,
						});
					}	
					
				if ( window.location.hash.length > 5 )
					{
						let showPreview = JSON.parse(atob(window.location.hash.substr(1, 1000)));
						
						this.setState({
							backroundColorPick: showPreview['backroundColorPick'],
							fontColorPick: showPreview['fontColorPick'],
							buttonsTextColor: showPreview['buttonsTextColor'],
							activeTabColor: showPreview['activeTabColor'],
							fontTitleColorPick: showPreview['fontTitleColorPick'],
							buttonsColorPick: showPreview['buttonsColorPick'],
							highlightColorPick: showPreview['highlightColorPick'],
							searchBackground: showPreview['searchBackground'],
							searchFont: showPreview['searchFont'],
							searchInputBackground: showPreview['searchInputBackground'],
							searchInputBorder: showPreview['searchInputBorder'],
							dateHolderColor: showPreview['dateHolderColor'],
							chosenOptionColor: showPreview['chosenOptionColor'] 
							
						},()=>{
							this.setState({
									inputFillColor: this.state.searchFont
								});
							if ( this.state.searchInputBackground !== '#24223D' ) 
								{ 
									this.setState({
										closedSearch: this.state.searchInputBackground,
										equipmentInputBackground: this.state.searchInputBackground,
										popupActHolder: this.state.searchInputBackground,
									});
								}
							else 
								{
									this.setState({
										closedSearch: '#545461',
										equipmentInputBackground: '#0A091A',
										popupActHolder: '#3F3E4D',
									});
								}
							
							if ( this.state.searchInputBorder !== '#ffffff' ) 
								{ 
									this.setState({
										closedSearchBorder: this.state.searchInputBorder,
										openSearchBorder: this.state.searchInputBorder,
									});
								}
							else 
								{
									this.setState({
										closedSearchBorder: '#545461',
										openSearchBorder: '#24223D',
									});
								}
							
							if ( this.state.fontColorPick !== '#DDDDDD' ) 
								{ 
									this.setState({
										choosenTabColor: this.state.fontColorPick,
										popupFontColor: this.state.fontColorPick,
										imgFillColor: this.state.fontColorPick,
										choosenTabColorText: this.state.fontColorPick,
									});
								}
							else 
								{
									this.setState({
										choosenTabColor: '#BCA4FF',
										popupFontColor: '#ffffff',
										imgFillColor: '#ffffff',
										choosenTabColorText: '#14142B'
									});
								}
							
							if ( this.state.backroundColorPick !== '#000000' ) 
								{ 
									this.setState({
										popupBackgroundColor: this.state.backroundColorPick,
										calendarBackgroundColor: this.state.backroundColorPick,
										calendarBackgroundColorMonth: this.state.backroundColorPick,
										mainLoaderColor: this.state.backroundColorPick,
									});
								}
							else 
								{
									this.setState({
										popupBackgroundColor: '#545461',
										calendarBackgroundColor: '#24223D',
										calendarBackgroundColorMonth: '#424141',
										mainLoaderColor: '#bd00ff'
									});
								}

							if ( this.state.highlightColorPick !== '#BCA4FF' ) 
								{ 
									this.setState({
										highlightColorPick2: this.state.highlightColorPick,
									});
								}
							else 
								{
									this.setState({
										highlightColorPick2: '#FFFFFF',
									});
								}

							if ( this.state.dateHolderColor !== '#24223D' ) 
								{ 
									this.setState({
										inputInPopActivityColor: this.state.dateHolderColor,
									});
								}
							else 
								{
									this.setState({
										inputInPopActivityColor: '#3F3E4D',
									});
								}

							if ( this.state.buttonsTextColor !== '#6D118C' ) 
								{ 
									this.setState({
										eqImgFillColor: this.state.buttonsTextColor,
										resetBtnColor: this.state.buttonsTextColor,
									});
								}
							else 
								{
									this.setState({
										eqImgFillColor: '#6D118C',
										resetBtnColor: '#E4DAFF'
									});
								}
								
								
						});
					}
				else
					{
						this.setState({
								backroundColorPick: res.data.chanelData.channelSettings.backgroundColor ? res.data.chanelData.channelSettings.backgroundColor : '#000000',
								fontColorPick: res.data.chanelData.channelSettings.fontColor ? res.data.chanelData.channelSettings.fontColor : '#DDDDDD',
								fontTitleColorPick: res.data.chanelData.channelSettings.fontTitleColor ? res.data.chanelData.channelSettings.fontTitleColor : '#BCA4FF',
								buttonsColorPick: res.data.chanelData.channelSettings.buttonsColor ? res.data.chanelData.channelSettings.buttonsColor : '#E4DAFF',
								highlightColorPick: res.data.chanelData.channelSettings.highlightTextColor ? res.data.chanelData.channelSettings.highlightTextColor : '#FFFFFF',
								searchBackground: res.data.chanelData.channelSettings.searchBackground ? res.data.chanelData.channelSettings.searchBackground : '#24223D',
								searchFont: res.data.chanelData.channelSettings.searchFont ? res.data.chanelData.channelSettings.searchFont : '#ffffff',
								searchInputBackground: res.data.chanelData.channelSettings.inputBackground ? res.data.chanelData.channelSettings.inputBackground : '#24223D',
								searchInputBorder: res.data.chanelData.channelSettings.searchBorder ? res.data.chanelData.channelSettings.searchBorder : '#fff',
								buttonsTextColor: res.data.chanelData.channelSettings.buttonsTextColor ? res.data.chanelData.channelSettings.buttonsTextColor : '#6D118C',
								activeTabColor: res.data.chanelData.channelSettings.activeTabColor ? res.data.chanelData.channelSettings.activeTabColor : '#EFF0F7',
								dateHolderColor: res.data.chanelData.channelSettings.dateHolderColor ? res.data.chanelData.channelSettings.dateHolderColor : '#24223D',
								chosenOptionColor: res.data.chanelData.channelSettings.chosenOptionColor ? res.data.chanelData.channelSettings.chosenOptionColor : '#24223D'
							}, ()=>{ 
							
									this.setState({
										inputFillColor: this.state.searchFont
									});
									if ( this.state.searchInputBackground !== '#24223D' ) 
										{ 
											this.setState({
												closedSearch: this.state.searchInputBackground,
												equipmentInputBackground: this.state.searchInputBackground,
												popupActHolder: this.state.searchInputBackground,
											});
										}
									else 
										{
											this.setState({
												closedSearch: '#545461',
												equipmentInputBackground: '#0A091A',
												popupActHolder: '#3F3E4D',
											});
										}
									
									if ( this.state.searchInputBorder !== '#ffffff' ) 
										{ 
											this.setState({
												closedSearchBorder: this.state.searchInputBorder,
												openSearchBorder: this.state.searchInputBorder,
											});
										}
									else 
										{
											this.setState({
												closedSearchBorder: '#545461',
												openSearchBorder: '#24223D',
											});
										}
									
									if ( this.state.fontColorPick !== '#DDDDDD' ) 
										{ 
											this.setState({
												choosenTabColor: this.state.fontColorPick,
												popupFontColor: this.state.fontColorPick,
												imgFillColor: this.state.fontColorPick,
												choosenTabColorText: this.state.fontColorPick,
											});
										}
									else 
										{
											this.setState({
												choosenTabColor: '#BCA4FF',
												popupFontColor: '#ffffff',
												imgFillColor: '#ffffff',
												choosenTabColorText: '#14142B'
											});
										}
									
									if ( this.state.backroundColorPick !== '#000000' ) 
										{ 
											this.setState({
												popupBackgroundColor: this.state.backroundColorPick,
												calendarBackgroundColor: this.state.backroundColorPick,
												calendarBackgroundColorMonth: this.state.backroundColorPick,
												mainLoaderColor: this.state.backroundColorPick,
											});
										}
									else 
										{
											this.setState({
												popupBackgroundColor: '#545461',
												calendarBackgroundColor: '#24223D',
												calendarBackgroundColorMonth: '#424141',
												mainLoaderColor: '#bd00ff'
											});
										}

									if ( this.state.highlightColorPick !== '#BCA4FF' ) 
										{ 
											this.setState({
												highlightColorPick2: this.state.highlightColorPick,
											});
										}
									else 
										{
											this.setState({
												highlightColorPick2: '#FFFFFF',
											});
										}

									if ( this.state.dateHolderColor !== '#24223D' ) 
										{ 
											this.setState({
												inputInPopActivityColor: this.state.dateHolderColor,
											});
										}
									else 
										{
											this.setState({
												inputInPopActivityColor: '#3F3E4D',
											});
										}

									if ( this.state.buttonsTextColor !== '#6D118C' ) 
										{ 
											this.setState({
												eqImgFillColor: this.state.buttonsTextColor,
											});
										}
									else 
										{
											this.setState({
												eqImgFillColor: '#6D118C',
											});
										}

									if ( this.state.buttonsTextColor !== '#6D118C' ) 
										{ 
											this.setState({
												eqImgFillColor: this.state.buttonsTextColor,
												resetBtnColor: this.state.buttonsTextColor,
											});
										}
									else 
										{
											this.setState({
												eqImgFillColor: '#6D118C',
												resetBtnColor: '#E4DAFF'
											});
										}		
										
								});
					}
			}
		else 
			{ 
				$('.main_loader').hide();
				this.setState({isFoundChannel: false}); 
			}
		if ( !this.state.firstTimeLoad )
			{
				$('.main_loader').hide();
			}
		
		var mainthis = this;
		mainthis.updatesse('channelId', res.data.chanelData.id, function () { mainthis.setState({ startPage: 0 }, ()=>{  mainthis.checkUpdatedSessions(); }); });
	}).catch(err =>{ this.setState({isFoundChannel: false}); });
}

checkUpdatedSessions=()=>{
	this.getChannelInfo(this.state.openedChannelName);
	this.getUpcomingSession('noLoader');
	this.getOnDemandsSessions(this.state.channelObject.id, 'noLoader');
}

getWeeks=()=>{
	var weeksArray = [];
	var today = new Date();
	var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
	
	for ( var i = 0; i <= 6; i++ )
		{
			var objectToPush = {'firstDay': today, 'endDay': nextweek};
			weeksArray.push(objectToPush);
			today = new Date(today.getFullYear(), today.getMonth(), today.getDate()+8);
			nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
		}
	
	if (weeksArray.length > 0)
		{
			let newEndDay = weeksArray[0]['endDay'];
			this.setState({
				minEndDateFilter: newEndDay
			})
		}
	this.setState({weeks7Array: weeksArray}, () => {
		
		if ( this.state.firstTimeLoad )
			{
				$('.main_loader').attr({'style':'display:flex'});
			}
		this.getUpcomingSession();
		this.getOnDemandsSessions(this.state.channelObject.id);
	});
}

getUpcomingSession=(mod?)=>{
	
	if ( localStorage.getItem("userData") )
		{
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
		}
	
	if ( parseInt(this.state.maxDuration) < parseInt(this.state.minDuration) )
		{
			this.props.showMainErrorPop('Max duration should be greater than min duration.');
			return false;
		}
	
	var chosenWeek = this.state.weeks7Array[this.state.selectedWeekIndex];
	
	var postData = {};
	
	
	let hostsArray = '';
	if ( this.state.selectedHostsArray.length > 0 )
		{
			hostsArray = JSON.stringify(this.state.selectedHostsArray);
		}
	let interestArray = '';
	if ( this.state.selectedInterests.length > 0 )
		{
			interestArray = JSON.stringify(this.state.selectedInterests);
		}
	
	let equipmentArray = '';
	if ( this.state.selectedEquipmentArray.length > 0 )
		{
			equipmentArray = JSON.stringify(this.state.selectedEquipmentArray);
		}
	
	postData = {
		channelId: this.state.channelObject.id,
		startDay: '',
		endDay: '',
		filterEquipment: equipmentArray,
		filterDurationMin: this.state.minDuration,
		filterDurationMax: this.state.maxDuration,
		filterHost: hostsArray,
		filterInerest: interestArray,
		filterLevel: this.state.selectedLevel,
		searchByName: this.state.searchByName,
		showNum: this.state.currentLoadNum,
		isMarketplace: this.state.channelObject.isMarketplace == 1 ? true : false
	}
	
	var startDateMoment = '';
	var endDateMoment = '';
	
	if ( mod )
		{
			startDateMoment = moment(new Date(this.state.startDateFilter)).format('YYYY-MM-DD 00:00:00');
			endDateMoment = moment(new Date(this.state.endDateFilter)).format('YYYY-MM-DD 23:59:59');
		}
	else 
		{
			startDateMoment = moment(new Date(chosenWeek.firstDay)).format('YYYY-MM-DD 00:00:00');
			endDateMoment = moment(new Date(chosenWeek.endDay)).format('YYYY-MM-DD 23:59:59');
		}
	
	postData['startDay'] = startDateMoment;
	postData['endDay'] = endDateMoment;
	
	if ( !mod )
		{
			this.setState({
					startDateFilter: chosenWeek.firstDay,
					endDateFilter: chosenWeek.endDay
				});
		}
	if ( !mod || ( mod && mod != 'noLoader' ) )
		{
			$('.main_loader').attr({'style':'display:flex'});
		}	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelsUpcoming", postData).then(res => { 
		var currentSessions = res.data.responseData.sessionsArray;
		var todaysDate = new Date();
		var tomorrowDate = new Date();
		var tomorrowDate = tomorrowDate.setDate(tomorrowDate.getDate() + 1);
		var upcomingDates = [];
		var upcomingArrays = [];
		
		for ( var i = 0; i<currentSessions.length; i++ )
			{
				var date = moment(currentSessions[i].scheduleDate).format('dddd, MMM DD');
				
				if ( moment(todaysDate).format('dddd, MMM DD') == moment(currentSessions[i].scheduleDate).format('dddd, MMM DD') )
					{
						date = 'Today, ' + moment(currentSessions[i].scheduleDate).format('MMM DD');
					}
					
				if ( moment(tomorrowDate).format('dddd, MMM DD') == moment(currentSessions[i].scheduleDate).format('dddd, MMM DD') )
					{
						date = 'Tomorrow, ' + moment(currentSessions[i].scheduleDate).format('MMM DD');
					}
					
				if ( upcomingDates.indexOf(date) === -1 )
					{
						upcomingDates.push(date);
					}	
			}
		
		
		for ( var d = 0; d < upcomingDates.length; d++  )
			{
				for ( var c = 0; c<currentSessions.length; c++ )
					{
						var dateSession = moment(currentSessions[c].scheduleDate).format('dddd, MMM DD');
						
						if ( moment(todaysDate).format('dddd, MMM DD') == moment(currentSessions[c].scheduleDate).format('dddd, MMM DD') )
							{
								dateSession = 'Today, ' + moment(currentSessions[c].scheduleDate).format('MMM DD');
							}
							
						if ( moment(tomorrowDate).format('dddd, MMM DD') == moment(currentSessions[c].scheduleDate).format('dddd, MMM DD') )
							{
								dateSession = 'Tomorrow, ' + moment(currentSessions[c].scheduleDate).format('MMM DD');
							}
							
						if ( dateSession == upcomingDates[d] )
							{
								if ( !upcomingArrays[dateSession] ) 
									{ 
										upcomingArrays[dateSession] = []; 
									}
								upcomingArrays[dateSession].push(currentSessions[c]);
							}
					}
			}
		
		this.setState({
			upcomingArray: upcomingArrays,
			upcomingArrayDates: upcomingDates,
			upcomingLength: res.data.responseData.upcomingLength,
			currentDataLength: res.data.responseData.shownLength,
		}, ()=>{this.setFirstUpcomingTimeOut()});
		$('.main_loader').hide();
	}).catch(err =>{ });
}

loadMoreUpcoming =(e)=>{
	let currentNum = this.state.currentLoadNum;
	currentNum = currentNum + 1;
	this.setState({
		currentLoadNum: currentNum,
	}, ()=> { this.getUpcomingSession('load') });
}

loadMoreOndemand =(e)=>{
	let currentNum = this.state.currentLoadNumOnDemand;
	currentNum = currentNum + 1;
	this.setState({
		currentLoadNumOnDemand: currentNum,
	}, ()=> { this.getOnDemandsSessions(this.state.channelObject.id, 'load'); });
}

setFirstUpcomingTimeOut=()=>{
	
	var todaysDate = new Date();
	var date = 'Today, ' + moment(todaysDate).format('MMM DD');;
	
	if ( this.state.upcomingArray[date] )
		{
			if ( this.state.upcomingArray[date][0] )
				{
					let endDate = this.state.upcomingArray[date][0]['endDate'];
					var endDateTime = new Date(endDate).getTime();
					var currentTime = new Date(todaysDate).getTime();
					if ( endDateTime > currentTime )
						{
							let timeForRefresh = endDateTime - currentTime;
							timeForRefresh = timeForRefresh + 1000;

							setTimeout(() => {
								this.getUpcomingSession();
								console.log('-----timeForRefresh', timeForRefresh);
							}, timeForRefresh);
						}
					
				}
		}
	
	
}

updateWeekIndex=(i)=>{
	this.setState({selectedWeekIndex: i}, () => {this.getUpcomingSession()});
}
	
sortSessionsAll=(selected)=>{
	let mainArray = this.state.mainChannelSessions;
	let weekArray = this.state.weeksArray;

	for ( var k = 0; k < weekArray.length; k++ )
		{
			if ( !weekArray[k] || !mainArray[weekArray[k]] ) { return 0; }
			
			if ( selected === 'by Host' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(a.hostFirstName < b.hostFirstName) { return -1; }
						if(a.hostFirstName > b.hostFirstName) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Duration' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(a.duration < b.duration) { return -1; }
						if(a.duration > b.duration) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Session' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(a.name < b.name) { return -1; }
						if(a.name > b.name) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Level' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level < b.level) { return -1; }
								if(a.level > b.level) { return 1; }
							}
						return 0;
					})
				}
				
			if ( selected === 'by Interest' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(a.interestTitles < b.interestTitles) { return -1; }
						if(a.interestTitles > b.interestTitles) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Recent Date' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
						if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
						return 0;
					})
				}
				
			if ( selected === 'by Older Date' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return -1; }
						if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return 1; }
						return 0;
					})
				}
				
			if ( selected === '' )
				{
					mainArray[weekArray[k]].sort(function(a, b){
						if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
						if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
						return 0;
					})	
				}
				
			this.setState({
						mainChannelSessions: mainArray
					});
		}
}

openCancel = (e, mod)=>
{
	if ( mod == 'no' )
		{
			this.setState({
				showCancelPop: false,
			});
		}
	else 
		{
			this.setState({
				showCancelPop: true,
			});
		}		
}

getOnDemandsSessions=(id, mod?)=>{
	
		let hostsArray = '';
		if ( this.state.selectedHostsArray.length > 0 )
			{
				hostsArray = JSON.stringify(this.state.selectedHostsArray);
			}
		let interestArray = '';
		if ( this.state.selectedInterests.length > 0 )
			{
				interestArray = JSON.stringify(this.state.selectedInterests);
			}
			
		let equipmentArray = '';
		if ( this.state.selectedEquipmentArray.length > 0 )
			{
				equipmentArray = JSON.stringify(this.state.selectedEquipmentArray);
			}
		
		let data = 
			{
				channelId: id, 
				isFromChannel: true, 
				additionalSearch: {}, 
				filterEquipment: equipmentArray, 
				filterLevel: this.state.selectedLevel, 
				startDate: this.state.fullStartDate, 
				endDate: this.state.fullEndDate,
				minDuration: this.state.minDuration,
				maxDuration: this.state.maxDuration,
				filterInterest: interestArray,
				searchSessionName: this.state.searchByName,
				filterHost: hostsArray,
				showNum: this.state.currentLoadNumOnDemand,
				filterSort: this.state.sortName,
			}
		if ( JSON.stringify(this.state.searchNewFiedls) !== '[]' && JSON.stringify(this.state.searchNewFiedls) !== '{}' )
			{
				data['additionalSearch'] = this.state.searchNewFiedls;
			}
		if ( !mod || ( mod && mod != 'noLoader' ) )
			{
				$('.main_loader').attr({'style':'display:flex'});
			}
			
		axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getAllOnDemandChannel", data)
		 .then(res => {
			 var alldemand = [];
			 
			 this.setState({isLoadingSessions: false})
			 
			 this.setState({
					mainDemandObject: res.data.responseData,
					onDemandSessionCount: res.data.responseData.demandProcessedCount,
					allOnDemandLength: res.data.responseData.allDemandSessionsLength,
					
				});
				
			if ( res.data.responseData.allDemandSession.length > 25 && res.data.responseData.allInterests.length > 1 )
				{
					this.setState({
						gridDemandView: true,
						hasGridView: true,
						readOnceDemand: true
					});
				}
			
			$('.main_loader').hide();
				
		 }).catch(err =>{ this.setState({isLoadingSessions: false}) })
	}
	
openActivityList=(e, mod)=>{
	this.setState({
		activityListSession: mod,
		showActivityList: true,
	});
}	

closeActList=(e)=>{
	this.setState({
		showActivityList: false,
	});
}

closeLogin=()=>{
	this.setState({
		isOpenLoginAll: false,
		isLoginOpen: false
	});
}

sortOnDemand=(selected)=>{
	let mainArray = this.state.mainDemandObject;
		
	if ( selected === 'Views (Low to High)' )
		{
			mainArray.allDemandSession.sort(function(a, b){
						if(a.totalviews < b.totalviews) { return -1; }
						if(a.totalviews > b.totalviews) { return 1; }
						return 0;
					})
		}	

	if ( selected === 'Views (High to Low)' )
		{
			mainArray.allDemandSession.sort(function(a, b){
						if(a.totalviews > b.totalviews) { return -1; }
						if(a.totalviews < b.totalviews) { return 1; }
						return 0;
					})
		}	
			
	if ( selected === 'Newest' )
		{
			if ( !this.state.sortedDateRecent )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return -1; }
						if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return 1; }
						return 0;
					})
				}
				
		}
		
	if ( selected === 'Oldest' )
		{
			if ( !this.state.sortedDateOlder )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return -1; }
						if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return 1; }
						return 0;
					})
				}
				
		}
		
	if ( selected === 'A-Z' )
		{
			if ( !this.state.sortedBySession )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
						if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
						return 0;
					})
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.name.toLowerCase() > b.name.toLowerCase()) { return -1; }
						if(a.name.toLowerCase() < b.name.toLowerCase()) { return 1; }
						return 0;
					})
					
					this.setState({sortedBySession: false});
				}
		}	
		
	if ( selected === 'Z-A' )
		{
			mainArray.allDemandSession.sort(function(a, b){
						if(a.name.toLowerCase() > b.name.toLowerCase()) { return -1; }
						if(a.name.toLowerCase() < b.name.toLowerCase()) { return 1; }
						return 0;
					})
		}	
		
	this.setState({
				mainDemandObject: mainArray,
				sessionDetailsOnDemandId: 0,
			});
}

showLogoutPop=()=>{

	if ( this.state.showLogOutBtn )
		{
			this.setState({showLogOutBtn: false});
		}
	else 
		{
			this.setState({showLogOutBtn: true});
		}		
}

logoutUser=(e)=>{
	localStorage.removeItem('userData'); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_ID); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); 
	window['user_loggedIn'] = 0;
	this.setState({ logedUser: false }); 
	window.location.reload();
}

cancelSession=e=>{
	let a = this.state.cancelSessionId;
	$('.main_loader').attr({'style':'display:flex'});
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.put(process.env.REACT_APP_NAME+"/api/v1/session/"+a+"/CancelSession").then(res => {
		$('.main_loader').hide();
		
		let responseMessage="Class has been successfully canceled.";
		this.setState({
			responseMessage:responseMessage,
			showCancelPop: false,			
		});
}).catch(err =>{ console.log(err); })
}

openURL=(e,url, mod) => {
	if ( mod && localStorage.getItem('userData') )
		{
			console.log('mod true');
			let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
			let userToken = JSON.parse(localStorage.getItem('userData')).data.responseData.token;

			var isHost = false;
			if ( mod.hostId === userId ) { isHost = true; }
			
			var checkSessionValidate = this.joinSessionValidate(isHost, userId, userToken, null);

				
			this.setState({
				message:'',
				userType: 2,
				urlPath:checkSessionValidate.urlPathVar,
				urlElectron: checkSessionValidate.urlElectronVar,
				deepLinkButton: checkSessionValidate.deepLinkButton,
				userSignedUp: true,
				sessionScheduleDate: mod.scheduleDate,
				sessionTimezone: mod.timeZone,
				loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
				isHost: isHost,
				sessionName: mod.name,
				isOnDemandSession: false
			});
			
			$('.main_loader').hide();
			return false;
		}
	window.open(url+'#'+btoa(window.location.href));
}

updateCookieAndStorage=(id, token, expire)=>{
	window['lastUserId'] = 0;
   //set cookie after register
	this.setCookie(process.env.REACT_APP_COOKIE_ID, id, expire);
	this.setCookie(process.env.REACT_APP_COOKIE_TOKEN, token, expire);
	//set cookie after register
	window['user_loggedIn'] = 1;
}

openDemandSession=(id, mod)=>{
	if ( this.state.logedUser )
		{
			var privateDemand = false;
			if ( mod.channelPrivateMemberOnly === 1 ) { privateDemand = true; }
			let postdata = 
				{
					"channelId": this.state.mainChannel.id, 
					"email": JSON.parse(localStorage.getItem('userData')).data.responseData.email, 
					"sessionIsPrivate": privateDemand, 
					"onDemandId": mod.id
				};
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserExist", postdata ).then(res => {
				var dataRes = res.data.responseData;
				if ( dataRes.sessionPrivateMemberOnly )
					{
						this.props.showMainErrorPop('This On-demand video can only be viewed by members of '+this.state.mainChannel.name+'. Please contact '+this.state.mainChannel.name+' or your trainer to get access.');
						return false;
					}
					
				this.setState({
					demandSessionId: id
				}, () => {
							let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
							let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
							
							var isHost = false;
							if ( mod.hostId === loginId ) { isHost = true; } 
							
							var checkSessionValidate = this.joinSessionValidate(true, loginId, token, this.state.demandSessionId);
							
							this.setState({
								isLoginOpen: false,
								isOpenLoginAll: false,
								ondemandlink: checkSessionValidate.urlPathVar,
								electronlink: checkSessionValidate.urlElectronVar,
								urlPath: checkSessionValidate.urlPathVar,
								urlElectron: checkSessionValidate.urlElectronVar,
								deepLinkButton: checkSessionValidate.deepLinkButton,
								userSignedUp: true,
								sessionScheduleDate: mod.createdAt,
								sessionTimezone: '',
								loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
								isHost: isHost,
								sessionName: mod.name,
								isOnDemandSession: true
							});
						});
			}).catch(err =>{ });
		}
	else
		{
			this.setState({
				demandSessionId: id,
				isLoginOpen: true,
				openedDemand: mod,
				openLoginStart: true,
			});
		}
}

validateEmailMethod=(e)=>{
  /*thist part run only for onDemand session if user not logged in*/
  /*not onDemand sessions always lead to session link*/
  if ( this.emailValidate.allValid() ) 
	  {
		let dataRes;
		let userType;
		$('.main_loader').attr({'style':'display:flex'});
	
		let postdata = {"email":this.state.email,"sessionId": [this.state.sessionId]};
	
		if ( this.state.sessionType === "On Demand" ) 
			{ 
				postdata = { "email":this.state.email } 
				
				postdata['channelId'] = this.state.mainChannel.id;
				postdata['sessionIsPrivate'] = true;
				postdata['onDemandId'] = this.state.demandSessionId;
					
				postdata['loginFromSessionLink'] = true; 
				postdata['dontSendOtp'] = true;
				//postdata['registerPar'] = true;
			}
	
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserExist", postdata )
		.then(res => {
			  dataRes=res.data.responseData;
			
			if ( dataRes !== 'undefined' || dataRes == 'User exist, move to login' ) 
				  {
					if ( dataRes.sessionPrivateMemberOnly )
						{
							this.closeLogin();
							this.props.showMainErrorPop('This On-demand video can only be viewed by members of '+this.state.mainChannel.name+'. Please contact '+this.state.mainChannel.name+' or your trainer to get access.');
							$('.main_loader').hide();
							return false;
						}
					  
					let user_main_id = res.data.responseData.id;
					this.setState({
						mainUserData: res.data.responseData,
						userMainId: user_main_id,
					});
					
					
					if ( this.state.sessionType === "On Demand" )
						{
							if ( Object.keys(dataRes).length === 0 )
								{
									this.setState({
									  validateEmail:false,
									  signup:true,
									  isOpenSign: true,
									  emailSign: this.state.email
									})
								}
							else
								{
									this.setState({ isOpenLoginAll: true });
								}
							
							$('.main_loader').hide();
						}
					else
						{
							this.checkIfUserHasWaiver(res.data.responseData.id, false, 'login');
						}
						
				  }
			
		}).catch(err =>{
			
			$('.main_loader').hide();
			
			if(!dataRes)
				{
					this.setState({
					  validateEmail:false,
					  signup:true,
					  isOpenLoginAll: false,
					  isOpenSign: true,
					  emailSign: this.state.email
					})
				}
			else 
				{   
					this.setState({
					  otperror:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
					},()=>$("#error_otp_msg").attr({'style':'display:block'}))
				}
		});
		
	  }
  else
	  {
		  this.emailValidate.showMessages();
		  $("#error_email_msg").attr({'style':'display:block'})
	   
			this.forceUpdate();
	  }
  
}

updateStateFromChildSession = (newState) => {
	this.setState(newState);
	if ( this.state.justSignIn )
		{
			$('.main_loader').attr({'style':'display:flex'});
			window.location.reload();
			this.setState({
				justSignIn: false,
			})
		}
}

showLoginAlert = (e) => {
	$("#login_error_alert_new").attr({'style':'display:block'})
}

openNativeUrl = (nurl) => {
	
	if(!this.state.appDownload && localStorage.getItem('appDownload'))
		{
			this.setState({appDownload: localStorage.getItem('appDownload')});
		}
	
	if(!this.state.appDownload && localStorage.getItem('userData') && !localStorage.getItem('appDownload'))
		{
			var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;
		
			this.setState({appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink )});
		}
		
	$('.main_loader').attr({'style':'display:flex'});
	customProtocolCheck(nurl,
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative.auto_open_channel').modal('hide');
							window.$('.channelPg #autologinnative').modal('hide');
							$("#nativedownload.auto_open_channel").show();
							window.$('#nativedownload2.auto_open_channel').modal('hide');
						  },
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative.auto_open_channel').modal('hide');
							window.$('#nativedownload2.auto_open_channel').modal('hide');
							window.$('.channelPg #autologinnative').modal('hide');
						  }, 15000);
}

closeLoginNative=(e)=>{
	
	this.setState({
		isLoginOpen: false,
		isOpenLoginAll: false,
	});
	
	window.$('.channelPg #autologinnative').modal('show'); 
}

updateRefState = (ref, mod) => {
	if ( mod == 'firstName' )
		{
			this._firstName = ref;
		}
	else if ( mod == 'lastName' )
		{
			this._lastName = ref;
		}
	else if ( mod == 'email' )
		{
			this._email = ref;
		}
	else if ( mod == 'password' )
		{
			this._password = ref;
		}
	else if ( mod == 'repassword' )
		{
			this._rePassword = ref;
		}
}

closeSign=(e)=> {
	this.setState({
		isOpenSign: false,
		verify: false,
	});
};

onChange=(e)=> {
    this.setState({ [e.target.id]: e.target.value });
};

resend=(e)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : this.state.emailSign})
    .then(res => {
			if( res.data.responseMessage == "success" )
				{
					$("#resend_verification").attr({'style':'display:block'});
				}
			else
				{
					this.setState({
						msg:''
					}) 
				}
    }).catch(err =>{
		this.setState({
			msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
		}) 
    });
}

openLoginNew=(e)=>{

	this.setState({
		sessionType: 'Upcoming',
		demandSessionId: 0,
		isOpenLoginAll: true,
		isLoginOpen: true,
		justSignIn: true,
	})
	
}

closeLoginPop = (e) => {
	this.setState({
		isOpenLoginAll: false,
		isLoginOpen: false,
		justSignIn: false,
	});
}

chooseInterest = (e, mod)=>{
	e.stopPropagation();
	let mainArray = this.state.selectedInterests;
	
	let interestId = mod.id;
	
	if ( mainArray.indexOf(interestId) < 0 )
		{
			mainArray.push(interestId);
		}
	else 
		{
			let indexOf = mainArray.indexOf(interestId);
			mainArray.splice(indexOf, 1);
		}
	this.setState({
		selectedInterests: mainArray,
	}, ()=> {this.startFilterSearch()});
}

chooseLevel=(e, mod)=>{
	e.stopPropagation();
	if ( this.state.selectedLevel == mod )
		{
			this.setState({
				selectedLevel: '',
			}, () => {this.startFilterSearch()});
		}
	else 
		{
			this.setState({
				selectedLevel: mod,
			}, ()=> {this.startFilterSearch()})
		}		
	
}

chooseHosts=(e, mod)=>{
	e.stopPropagation();
	let mainArray = this.state.selectedHostsArray;
	
	let userId = mod.userId;
	
	if ( mainArray.indexOf(userId) < 0 )
		{
			mainArray.push(userId);
		}
	else 
		{
			let indexOf = mainArray.indexOf(userId);
			mainArray.splice(indexOf, 1);
		}
	this.setState({
		selectedHostsArray: mainArray,
	}, () => {this.startFilterSearch()});
}

startFilterSearch=()=>{
	
	if ( this.state.choosenTab == 'live' )
		{
			this.getUpcomingSession('filter');
		}
	else 
		{
			this.getOnDemandsSessions(this.state.channelObject.id);
		}		
	
}

startFilterSearchWithoutLoader=()=>{
	
	if ( this.state.choosenTab == 'live' )
		{
			this.getUpcomingSession('noLoader');
		}
	else 
		{
			this.getOnDemandsSessions(this.state.channelObject.id, 'noLoader');
		}		
	
}

clearFilter=()=>{
	this.setState({
		sessionDetailsOnDemandId: 0,
		sessionDetailsId: 0, 
		selectedInterests: [],
		selectedLevel: '',
		selectedHostsArray: [],
		selectedEquipmentArray: [],
		minDuration: 0, 
		maxDuration: 0,
		searchByName: '',
		expandSearch: false, 
		backFilter: 0,
		backFilterEnd: 7, 
		activeFilter: '',
		sortName: 'Newest',
		showClearButton: false,
		backFilterEndChanged: false
	}, () => {this.getWeeks() });
}

closePopFilter=()=>{
	this.setState({
		activeFilter: '',
		equipmentInput: '',
	})
}

  render() {

    return (
		<div className="channelPageHolder" onClick={(e)=>{this.closePopFilter()}}>
			{ this.state.isFoundChannel && 
				<div>
					<style>
						.channelNewH1 {'{ color: '+this.state.fontTitleColorPick+' !important;}'}
						.mainTabPart {'{ color: '+this.state.choosenTabColor+' !important; }'}
						.ch_up_date {'{ background-color: '+this.state.dateHolderColor+' !important; }'}
						.activeCircleCh {'{ background-color: '+this.state.chosenOptionColor+' !important; }'}
						.react-datepicker__day-names, .calendarHolderImg .react-datepicker__header, .calendarHolderImg .react-datepicker__current-month, .calendarHolderImg .react-datepicker__day--keyboard-selected {'{ background-color: '+this.state.calendarBackgroundColor+' !important; }'}
						.react-datepicker__week {'{ background-color: '+this.state.calendarBackgroundColorMonth+' !important; }'}
						.calendarHolderImg .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {'{ border-bottom-color: '+this.state.calendarBackgroundColor+' !important; }'}
						.actListBody {'{ background-color: '+this.state.popupBackgroundColor+' !important; }'}
						.channelPageHolder {'{ background-color: '+this.state.backroundColorPick+' !important;}'}
						.searchInputHolder {'{ background-color: '+this.state.searchInputBackground+' !important;}'}
						.equipmentInput, .searchInputHolder {'{ color: '+this.state.searchFont+' !important;}'}
						.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name, .odPartInl, .avaiableText, .noSessionsText, .noSessionsTextFilter, .descTextPart,  .sortOption, .selectText1, .sortTextOd, .sortOptionText, .durationMin, .userNameCh, .channelDescH2, .durationHolder, .minDateStart, .ch_up_date, .dataTime2, .avaiableDateCh, .descDataBottom, .interestMap, .instrucotrBioCh, .signedUpCh, .ejeText {'{ color: '+this.state.fontColorPick+' !important;}'}
						.dataTime {'{ color: '+this.state.highlightColorPick+' !important;}'}
						.descBottom1, .hostBottomPart {'{ color: '+this.state.highlightColorPick2+' !important;}'}
						.btnPartCh {'{ background-color: '+this.state.buttonsColorPick+' !important;}'}		
						.durationHolderAbs, .durationHolder, .calendarHolderSearch, .loggedUserCh, .logOutHolder, .sortOptionsHolder, .sortOption, .activeDurationHolder {'{ background-color: '+this.state.searchBackground+' !important;}'}
						.eqPartHolder, .applyBtnEq, .act_btn_channel {'{ background-color: '+this.state.buttonsColorPick+' !important; }'}
						.chosenTab {'{ background-color: '+this.state.activeTabColor+' !important; }'}
						.eqPartHolder, .clearAllFilterBtn, .applyBtnEq, .btnPartCh{'{ color: '+this.state.buttonsTextColor+' !important; }'}
						.act_btn_channel {'{ color: '+this.state.buttonsColorPick+' !important; }'}			
						.expandSearchBox {'{ background-color: '+this.state.closedSearch+' !important;}'}
						.equipmentInput {'{ background-color: '+this.state.equipmentInputBackground+' !important;}'}
						.activityPart {'{ background-color: '+this.state.inputInPopActivityColor+' !important;}'}
						.expandSearchBox {'{ border: 1px solid '+this.state.closedSearchBorder+' !important;}'}
						.searchInputHolder  {'{ border: 1px solid '+this.state.openSearchBorder+' !important;}'}
						.main_loader_inner  {'{ border-top: 5px solid '+this.state.mainLoaderColor+' !important;}'}
						.ch_up_date  {'{ border-bottom: 1px solid '+this.state.dateHolderColor+' !important;}'}
						.calendarHolderImg .react-datepicker__header, .calendarHolderImg .react-datepicker__current-month, .react-datepicker__month, .react-datepicker__day-name, .react-datepicker__day-names, .react-datepicker__week, .datepicker__day, .react-datepicker__month, .actListH2, .actListDate, .actListDuration, .activityPart, .actListH1, .logOutHolder {'{ color: '+this.state.popupFontColor+' !important;}'}
						.chosenTab {'{ color: #14142B !important; }'}
						.react-datepicker__day--disabled {'{opacity: 0.9}'}
						.react-datepicker__month {'{margin: 0px;}'}
						.resetBtn{'{background-color: transparent !important}'}
						.channelPageHolder .for_sign, .channelPageHolder .gray_clr_back {'{ background-color: '+this.state.calendarBackgroundColor+' !important; }'}
						.channelPageHolder .label, .channelPageHolder .login_main_label, .channelPageHolder .login-tagline,.channelPageHolder .main_color_part{'{ color: '+this.state.imgFillColor+' !important; }'}
						.channelPageHolder .form-control{'{ background-color: '+this.state.searchInputBackground+' !important; }'}
						.channelPageHolder .form-control{'{ border-color: '+this.state.searchInputBorder+' !important; }'}
						.channelPageHolder .form-control, .channelPageHolder .input-field,.channelPageHolder .srv-validation-message{'{ color: '+this.state.searchFont+' !important; }'}
						.channelPageHolder .custom_btn1, .channelPageHolder .abs_close, .channelPageHolder .save-btn, .channelPageHolder .btn-login{'{ background-color: '+this.state.buttonsColorPick+' !important; }'}
						.channelPageHolder .btn-login, .channelPageHolder .btn-cancel, .btnSignInCh, .channelPageHolder .input-field{'{ border-color: '+this.state.buttonsColorPick+' !important; }'}
						.channelPageHolder .custom_btn1, .channelPageHolder .closepurple span, .channelPageHolder .save-btn, .channelPageHolder .btn-login, .channelPageHolder .btn-cancel {'{ color: '+this.state.buttonsTextColor+' !important; }'}
						.channelPageHolder .forgot-password,.channelPageHolder .sign_main_text{'{ color: '+this.state.highlightColorPick+' !important; }'}
						.channelPageHolder .inp_cover-border{'{background-color: transparent}'}
						.channelPageHolder .react-datepicker__navigation--next{'{border-left-color: '+this.state.buttonsColorPick+' !important}'}
						.channelPageHolder .react-datepicker__navigation--previous{'{border-right-color: '+this.state.buttonsColorPick+' !important}'}
						.chosenTab{'{color: '+this.state.choosenTabColorText+' !important}'}
						.tabDot{'{background-color: '+this.state.choosenTabColorText+' !important}'}
						.channelPageHolder .react-datepicker__day--selected{'{background-color: '+this.state.buttonsColorPick+'}'}
						.channelPageHolder .channel_cancel_button:hover {'{background-color: '+this.state.buttonsColorPick+' !important}'}
						.channelPageHolder .custom-control-input:checked~.custom-control-label::before{'{background-color: '+this.state.buttonsColorPick+' !important}'}
						.channelPageHolder .custom-control-input:checked~.custom-control-label::before{'{border-color: '+this.state.buttonsColorPick+' !important}'}
						.channelPageHolder .react-datepicker__day:hover{'{background-color: '+this.state.buttonsColorPick+' !important}'}
						.channelPageHolder .react-datepicker__day--disabled:hover{'{background-color: transparent !important}'}
						.channelPageHolder .react-datepicker__day:hover{'{color: '+this.state.imgFillColor+' !important}'}
						.channelPageHolder .bocc{'{background-color: transparent !important}'}
						.modal-body{'{background-color: '+this.state.backroundColorPick+' !important}'}
						.text-light{'{color: '+this.state.fontColorPick+' !important}'}
						.cancelBtnCh, .custom_btn1{'{background-color: '+this.state.buttonsColorPick+' !important}'}
						.resetBtn, .noBtn {'{border-color: '+this.state.buttonsColorPick+' !important}'}
						.noBtn{'{background-color: transparent !important}'}
						.cancelBtnCh, .custom_btn1 {'{color: '+this.state.buttonsTextColor+' !important}'}
						.custom_btn1,button:not(:disabled){'{color: '+this.state.buttonsTextColor+' !important}'}
						.cacnelH1, .canch2 {'{color: '+this.state.fontColorPick+' !important}'}
						.cancBody{'{background-color: '+this.state.backroundColorPick+' !important}'}
						.btnSignInCh, .resetBtn, .noBtn{'{color: '+this.state.resetBtnColor+' !important}'}
						.react-datepicker__day-name:hover{'{ color: '+this.state.popupFontColor+' !important;}'}
						.act_btn_channel{'{background-color: transparent !important}'}
						.input-field{'{background-color: '+this.state.searchInputBackground+' !important}'}
						.act_btn_channel {'{ border: 1px solid '+this.state.buttonsColorPick+' !important; }'}
						
					</style>
					
					{this.state.logedUser ?
						<div className="logHolderCh" >	
							<div className="loggedUserCh" onClick={this.showLogoutPop} >
								<div className="userImgCh"  style={{ backgroundImage: this.state.logedUserImage != '' ? "url("+this.state.logedUserImage+")" : "url('/images/userImgChannel.svg')"}} ></div>
								<div className="userNameCh" >{this.state.logedUserName}</div>
								<div className="arrowDownUser" >
									<div className="absSvgHolder" >
										<svg width="8" height="4" viewBox="0 0 8 4" fill={this.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
											<path d="M4 4L0 0H8L4 4Z"/>
										</svg>
									</div>	
								</div>
							</div>
							{this.state.showLogOutBtn &&
								<div className="holdLogoutDiv" >
									<div className="logOutHolder" onClick={e=>{this.logoutUser()}} >
										<div className="actPopPart logOutImgHolder" >
											<svg width="19" height="20" viewBox="0 0 19 20" fill={this.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
												<path d="M1 20C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V4H14V2H2V18H14V16H16V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1ZM14 14V11H7V9H14V6L19 10L14 14Z" />
											</svg>
										</div>
										<div className="actPopPart" >Logout</div>
									</div>
								</div>
							}
						</div>
						:
						<div className="btnSignInCh" onClick={e=>{this.openLoginNew()}}  >Sign in</div>
					}
					<ChannelHeader state={this.state}></ChannelHeader>
					{this.state.firstTabOnDemand ?
						<div className="newChannelTabsHolder">
							{this.state.showOndemandTab == 1 &&
								<div className={classnames("mainTabPart " + (this.state.choosenTab == 'On-Demand' ? "chosenTab" : "") )} onClick={()=>{this.selectMod('On-Demand')}} >
									<span > On-Demand Classes </span>
									{this.state.choosenTab == 'On-Demand' && 
										<div className="tabDot" ></div>
									}
								</div>
							}
							
							{this.state.showLiveTab == 1 &&	
								<div className={classnames("mainTabPart " + (this.state.choosenTab == 'live' ? "chosenTab" : "") )}  onClick={()=>{this.selectMod('live')}} >
									<span>Live Classes</span> 
									{this.state.choosenTab == 'live' && 
										<div className="tabDot" ></div>
									}	
								</div>
							}
						</div>
						:
						<div className="newChannelTabsHolder">
							{this.state.showLiveTab == 1 &&	
								<div className={classnames("mainTabPart " + (this.state.choosenTab == 'live' ? "chosenTab" : "") )}  onClick={()=>{this.selectMod('live')}} >
									<span>Live Classes</span> 
									{this.state.choosenTab == 'live' && 
										<div className="tabDot" ></div>
									}							
								</div>
							}
							{this.state.showOndemandTab == 1 &&	
								<div className={classnames("mainTabPart " + (this.state.choosenTab == 'On-Demand' ? "chosenTab" : "") )} onClick={()=>{this.selectMod('On-Demand')}} >
									<span > On-Demand Classes </span>
									{this.state.choosenTab == 'On-Demand' && 
										<div className="tabDot" ></div>
									}
								</div>
							}
						</div>
					}
					<ChannelSearch
					state={this.state}
					changeSearchView={this.changeSearchView}
					updateStateFromChild={this.updateStateFromChild}
					chooseInterest={this.chooseInterest}
					chooseLevel={this.chooseLevel}
					chooseHosts={this.chooseHosts}
					startFilterSearch={this.startFilterSearch}
					getUpcomingSession={this.getUpcomingSession}
					clearFilter={this.clearFilter}>
					</ChannelSearch>
					{this.state.choosenTab == 'live' && this.state.currentDataLength > 0 &&
						<ChannelUpcoming 
							state={this.state}
							openCancel={this.openCancel}
							updateStateFromChild={this.updateStateFromChild}
							openActivityList={this.openActivityList}
							openURL={this.openURL}
							loadMoreUpcoming={this.loadMoreUpcoming}> 
						</ChannelUpcoming>
					}
					
					{this.state.choosenTab == 'live' && this.state.currentDataLength == 0 &&
						<div className="noSessionsHolder" >
							<div className="noSessionsText" >No classes found</div>
							{ ( this.state.backFilterEndChanged || this.state.backFilter > 0 || this.state.searchByName != '' || this.state.maxDuration > 0 || this.state.minDuration > 0 || this.state.selectedHostsArray.length > 0 || this.state.selectedInterests.length > 0 || this.state.selectedEquipmentArray.length > 0 || this.state.selectedLevel || this.state.showClearButton )&&
								<div className="expandFilterHolder" >
									<div className="filterBtnHolder2" >
										<div className="noSessionsTextFilter">No Live Classes that match your filters, try expanding your search</div>
									</div>
									<div className="filterBtnHolder2" >
										<div className="applyBtnEq" onClick={(e)=>{this.clearFilter()}} >Reset Filters</div>
									</div>
								</div>
							}
						</div>
					}
					
					{this.state.choosenTab == 'On-Demand' && this.state.mainDemandObject['allDemandSession'] && this.state.mainDemandObject['allDemandSession'].length > 0 &&
						<ChannelOnDemand
							state={this.state}
							updateStateFromChild={this.updateStateFromChild}
							openActivityList={this.openActivityList}
							sortOnDemand={this.sortOnDemand}
							openURL={this.openURL}
							openDemandSession={this.openDemandSession}
							loadMoreOndemand={this.loadMoreOndemand}
							getOnDemandsSessions={this.getOnDemandsSessions}>
						</ChannelOnDemand>
					}
					{this.state.choosenTab == 'On-Demand' && this.state.mainDemandObject['allDemandSession'] && this.state.mainDemandObject['allDemandSession'].length == 0 &&
						<div className="noSessionsHolder" >
							<div className="noSessionsText" >No On-Demand Classes</div>
							
							{ ( this.state.backFilterEndChanged || this.state.backFilter > 0 || this.state.searchByName != '' || this.state.maxDuration > 0 || this.state.minDuration > 0 || this.state.selectedHostsArray.length > 0 || this.state.selectedInterests.length > 0 || this.state.selectedEquipmentArray.length > 0 || this.state.selectedLevel ) ?
								<div className="expandFilterHolder" >
									<div className="filterBtnHolder2" >
										<div className="noSessionsTextFilter">No On-Demand Classes that match your filters, try expanding your search</div>
									</div>
									<div className="filterBtnHolder2" >
										<div className="applyBtnEq" onClick={(e)=>{this.clearFilter()}} >Reset Filters</div>
									</div>
								</div>
								:
								<div className="expandFilterHolder bottomMsgHolder" >
									<div className="noSessionsTextFilter odChFilter">{this.state.channelObject['name']} doesn’t have any On-Demand Classes posted at the moment...</div>
								</div>
							}
						</div>
					}
					
					{this.state.showCancelPop &&
						<div className="cancelBookedHolder" >
							<div className="cancBody">
								<div className="cacnelH1" >Cancel reservation?</div>
								<div className="canch2" >You can sign up again as long as there are spots available.</div>
								<div className="btnsHolderCanc">
									<div className="cancelBtnCh" onClick={(e)=>{this.cancelSession()}} >Cancel</div>
									<div className="cancelBtnCh noBtn" onClick={(e)=>{this.openCancel(e, 'no')}}>No</div>
								</div>
								{this.state.choosenTab == 'live' ? 
									<div className="actPopPart" >{this.state.activityListSession['duration']} Minutes </div>
									:
									<div className="actPopPart" >{this.getOnDemandDuration(this.state.activityListSession['duration'])}</div>
								}
							</div>
						</div>
					}
					{this.state.showActivityList &&
						<div className="cancelBookedHolder" >
								<div className="actListBody">
									<div className="closeActListHolder" onClick={(e)=>{this.closeActList()}} >
										<svg width="14" height="14" viewBox="0 0 14 14" fill={this.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
											<path d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z"/>
										</svg>
									</div>
									<div className="actListH1" >ACTIVITY LIST</div>
									<div className="actListH2" >{this.state.activityListSession['name']}</div>
									{this.state.choosenTab == 'live' &&
										<div className="actListDate">{this.getSessionDate(this.state.activityListSession['scheduleDate'])}</div>
									}
									<div className="actListDuration" >
										<div className="actPopPart secHolderPop" >
											<svg width="14" height="14" viewBox="0 0 14 14" fill={this.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
												<path d="M6.99967 13.6663C3.31767 13.6663 0.333008 10.6817 0.333008 6.99967C0.333008 3.31767 3.31767 0.333008 6.99967 0.333008C10.6817 0.333008 13.6663 3.31767 13.6663 6.99967C13.6663 10.6817 10.6817 13.6663 6.99967 13.6663ZM6.99967 12.333C8.41416 12.333 9.77072 11.7711 10.7709 10.7709C11.7711 9.77072 12.333 8.41416 12.333 6.99967C12.333 5.58519 11.7711 4.22863 10.7709 3.22844C9.77072 2.22824 8.41416 1.66634 6.99967 1.66634C5.58519 1.66634 4.22863 2.22824 3.22844 3.22844C2.22824 4.22863 1.66634 5.58519 1.66634 6.99967C1.66634 8.41416 2.22824 9.77072 3.22844 10.7709C4.22863 11.7711 5.58519 12.333 6.99967 12.333ZM7.66634 6.99967H10.333V8.33301H6.33301V3.66634H7.66634V6.99967Z" />
											</svg>
										</div>
										<div className="actPopPart" >{this.state.activityListSession['duration']} Minutes </div>
									</div>
									<div className="holderWithScrollAct" >
										{ this.state.activityListSession['activityArray'] && this.state.activityListSession['activityArray'].map((item, i)=>	
											<div className={classnames("activityPart " + (item.ActivityType == 'Rest' ? "restAct" : "") )} key={i} >{item.ActivityName}
												{item.DurationType == 'Reps' ? 
													<div className="actDurationPart" >
														<div className="actPopPart secHolderPop" >
															<svg width="14" height="14" viewBox="0 0 14 14" fill={this.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
																<path d="M6.99967 13.6663C3.31767 13.6663 0.333008 10.6817 0.333008 6.99967C0.333008 3.31767 3.31767 0.333008 6.99967 0.333008C10.6817 0.333008 13.6663 3.31767 13.6663 6.99967C13.6663 10.6817 10.6817 13.6663 6.99967 13.6663ZM6.99967 12.333C8.41416 12.333 9.77072 11.7711 10.7709 10.7709C11.7711 9.77072 12.333 8.41416 12.333 6.99967C12.333 5.58519 11.7711 4.22863 10.7709 3.22844C9.77072 2.22824 8.41416 1.66634 6.99967 1.66634C5.58519 1.66634 4.22863 2.22824 3.22844 3.22844C2.22824 4.22863 1.66634 5.58519 1.66634 6.99967C1.66634 8.41416 2.22824 9.77072 3.22844 10.7709C4.22863 11.7711 5.58519 12.333 6.99967 12.333ZM7.66634 6.99967H10.333V8.33301H6.33301V3.66634H7.66634V6.99967Z" />
															</svg>
														</div>
														<div className="actPopPart" >
															{item.repsSeconds} reps
														</div>
														{item.Video &&
															<div className="actDurationPart injectedVideoImgCh" >
																<svg height="14px" viewBox="0 -87 472 472" fill={this.state.imgFillColor} width="14px" xmlns="http://www.w3.org/2000/svg">
																	<path d="m467.101562 26.527344c-3.039062-1.800782-6.796874-1.871094-9.898437-.179688l-108.296875 59.132813v-35.480469c-.03125-27.601562-22.398438-49.96875-50-50h-248.90625c-27.601562.03125-49.96875 22.398438-50 50v197.421875c.03125 27.601563 22.398438 49.96875 50 50h248.90625c27.601562-.03125 49.96875-22.398437 50-50v-34.835937l108.300781 59.132812c3.097657 1.691406 6.859375 1.625 9.894531-.175781 3.039063-1.804688 4.898438-5.074219 4.898438-8.601563v-227.816406c0-3.53125-1.863281-6.796875-4.898438-8.597656zm-138.203124 220.898437c-.015626 16.5625-13.4375 29.980469-30 30h-248.898438c-16.5625-.019531-29.980469-13.4375-30-30v-197.425781c.019531-16.558594 13.4375-29.980469 30-30h248.90625c16.558594.019531 29.980469 13.441406 30 30zm123.101562-1.335937-103.09375-56.289063v-81.535156l103.09375-56.285156zm0 0"/>
																</svg>
															</div>
														}
													</div>
													:
													<div className="actDurationPart" >
														<div className="actPopPart secHolderPop" >
															<svg width="14" height="14" viewBox="0 0 14 14" fill={this.state.imgFillColor} xmlns="http://www.w3.org/2000/svg">
																<path d="M6.99967 13.6663C3.31767 13.6663 0.333008 10.6817 0.333008 6.99967C0.333008 3.31767 3.31767 0.333008 6.99967 0.333008C10.6817 0.333008 13.6663 3.31767 13.6663 6.99967C13.6663 10.6817 10.6817 13.6663 6.99967 13.6663ZM6.99967 12.333C8.41416 12.333 9.77072 11.7711 10.7709 10.7709C11.7711 9.77072 12.333 8.41416 12.333 6.99967C12.333 5.58519 11.7711 4.22863 10.7709 3.22844C9.77072 2.22824 8.41416 1.66634 6.99967 1.66634C5.58519 1.66634 4.22863 2.22824 3.22844 3.22844C2.22824 4.22863 1.66634 5.58519 1.66634 6.99967C1.66634 8.41416 2.22824 9.77072 3.22844 10.7709C4.22863 11.7711 5.58519 12.333 6.99967 12.333ZM7.66634 6.99967H10.333V8.33301H6.33301V3.66634H7.66634V6.99967Z" />
															</svg>
														</div>
														<div className="actPopPart" >
															{item.repsSeconds} sec
														</div>
														{item.Video &&
															<div className="actDurationPart injectedVideoImgCh" >
																<svg height="14px" viewBox="0 -87 472 472" fill={this.state.imgFillColor} width="14px" xmlns="http://www.w3.org/2000/svg">
																	<path d="m467.101562 26.527344c-3.039062-1.800782-6.796874-1.871094-9.898437-.179688l-108.296875 59.132813v-35.480469c-.03125-27.601562-22.398438-49.96875-50-50h-248.90625c-27.601562.03125-49.96875 22.398438-50 50v197.421875c.03125 27.601563 22.398438 49.96875 50 50h248.90625c27.601562-.03125 49.96875-22.398437 50-50v-34.835937l108.300781 59.132812c3.097657 1.691406 6.859375 1.625 9.894531-.175781 3.039063-1.804688 4.898438-5.074219 4.898438-8.601563v-227.816406c0-3.53125-1.863281-6.796875-4.898438-8.597656zm-138.203124 220.898437c-.015626 16.5625-13.4375 29.980469-30 30h-248.898438c-16.5625-.019531-29.980469-13.4375-30-30v-197.425781c.019531-16.558594 13.4375-29.980469 30-30h248.90625c16.558594.019531 29.980469 13.441406 30 30zm123.101562-1.335937-103.09375-56.289063v-81.535156l103.09375-56.285156zm0 0"/>
																</svg>
															</div>
														}
													</div>
												}
												
											</div>
										)}
									</div>	
								</div>
						</div>
					}
					<div className="main_loader">
						<div className="main_loader_inner"></div>
					</div>
					<div className={ "main_pop_sign " + ( this.state.isLoginOpen ? "active" : "" ) }>
						<div className="for_main_form centered_pop">
							<div>
								{ !this.state.isOpenLoginAll && 
									<div>
										<div className="col-12 main_login_logos">
											<img src="/images/login-logo.png" className="login-logo" />
											<p className="login-tagline">Login and Join a Virtual Studio</p>
										 </div>
										<div className="gray-box-4 px-4 pt-5 pb-4 input_bx-cont for_sign relative">
											<button type="button" className="close white closepurple translatX-Y abs_close" onClick={this.closeLogin}><span>x</span></button>
											<div className="form-group">
												<label className="label">Email Address<span className="inp_cover-border"></span></label>
												<input type="email" autoComplete="off"
												className={(this.state.errorClass_Email  ?"input-field red-outline":"input-field")} 
												value={this.state.email} id ='email'
												ref={mail=> (this._email = mail)} 
												onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_Email:false})} />
												{this.emailValidate.message('email', this.state.email, 'required|email')}
												<span className="form_email deny_grind">
													 <div>
														 <svg width="27px" height="27px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 46.000000 46.000000">
															<g transform="translate(0.000000,46.000000) scale(0.100000,-0.100000)"
																fill={this.state.searchFont} stroke="none">
																<path d="M16 384 c-13 -12 -16 -41 -16 -148 0 -89 4 -136 12 -144 17 -17 419 -17 436 0 8 8 12 55 12 144 0 107 -3 136 -16 148 -23 24 -405 24 -428 0z m314 -99 c-47 -47 -92 -85 -100 -85 -8 0 -53 38 -100 85 l-85 85 185 0 185 0 -85 -85z m-243 -102 l-57 -58 0 115 0 115 57 -58 58 -57 -58 -57z m343 55 l0 -113 -57 57 -58 58 55 55 c30 30 56 55 57 55 2 0 3 -51 3 -112z m-200 -68 c8 0 26 11 40 25 l26 25 59 -60 59 -60 -184 0 -184 0 59 60 59 60 26 -25 c14 -14 32 -25 40 -25z"/>
															</g>
														</svg>
													</div>
												</span>
											</div>
										</div>
										<div className="text-center">
											<button type="button" className="save-btn btn btn-primary mt-3 font-book mx-auto font-18" onClick={this.validateEmailMethod}>Continue</button>
										</div>
									</div>
								}
							</div>
							<div> 
								{ this.state.isOpenLoginAll && 
									<Login 
										state={this.state}
										closeLogin={this.closeLogin}
										updateStateFromChild={this.updateStateFromChild}
										updateStateFromChildSession={this.updateStateFromChildSession}
										showLoginAlert={this.showLoginAlert}
										closeLoginPop={this.closeLoginPop}
										>
									</Login>
								}
							</div>
						</div>
					</div>
					{ this.state.userSignedUp &&
						<div className="full_popup">
							<ParticipantSignedUp 
								state={this.state}
								updateStateFromChild={this.updateStateFromChild}
								openNativeUrl={this.openNativeUrl}
								sessionStartTimeHigher15Min={this.state.sessionStartTimeHigher15Min}
								urlElectron={this.state.urlElectron}
								urlPath={this.state.urlPath}
								scheduleDate={this.state.sessionScheduleDate}
								timeZone={this.state.sessionTimezone}
								sessionChannelName={this.state.sessionChannelName}
								isHost={this.state.isHost}
								sessionName={this.state.sessionName}
								isOnDemandSession={this.state.isOnDemandSession}
								isChannelPage={this.state.isChannel}
								closePartSignedUpChannel={this.closePartSignedUpChannel}
								>
							</ParticipantSignedUp>
						</div>
					}
					
						<div className="modal pr-0" id="session_alert_new" aria-modal="true">
							<div className="modal-dialog mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
										<p className="mb-0 py-4 white text-center font-20">Your class is ready to Join.</p>
									</div>
									<div className="text-center flex-wrap d-flex justify-content-center">
										{
											this.state.urlElectron ?
											<div className=""><button className="custom_btn1 mt-3 mx-2" onClick={this.closeLoginNative}>join</button></div>
											:
											<a href ={this.state.urlPath} target="_blank"><div className=""><button className="custom_btn1 mt-3 mx-2" onClick={this.closeLogin}>join</button></div></a>
										}
									</div>
								</div>
							</div>
						</div>
					
					<div className={ "main_pop_sign " + ( this.state.isOpenSign ? "active" : "" ) }>
						<div className="text-center"><img src="/images/participate.png" alt="host" className="w-60" /></div>
						<h4 className="white text-center pb-2 font-20 sign_main_text">Signing up as a Member</h4>
						<MainSignUp 
							state={this.state}
							closeSign={this.closeSign}
							onChange={this.onChange}
							resend={this.resend}
							submitHost={this.submitHost}
							updateRefState={this.updateRefState}
							updateStateFromChild={this.updateStateFromChild}
							verify={this.verify}
							>
						</MainSignUp>
					</div>
				</div>
			}
			{ !this.state.isFoundChannel && 
				<div className="no_channel_info">No available Channel with this subdomain.</div>
			}
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ChannelNew);