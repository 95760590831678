import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { connect } from "react-redux";
import { browserHistory} from 'react-router'
import axios from "axios";
import '../../App.css';
import Channel from './Channel';

class SearchResults extends Component {
  
  constructor(props) {
    super(props);
	this.weekslist = [];
	this.sessionslist = {};
	
	this.state = {}
}


componentWillMount(){
	const weeks_arr = Object.keys(this.props.searchSessionResults.sessions);
	console.log('week arr', weeks_arr);
	
	this.weekslist = weeks_arr;
	this.sessionslist = this.props.searchSessionResults;
}


  render() {
    return (<div className="search_result_main overy_a">
			<div className="row px-1 px-sm-4">
				<div className="col-lg-2 d-flex justify-content-center justify-content-sm-start flex-wrap px-2">
					<div className="align-self-center">
						<img src="/images/login-logo.png" className="logo" alt="logo" />
					</div>
				</div>
				<div className="col-lg-8 d-flex justify-content-center flex-wrap px-2">
					<div className="align-self-center">
						<h5>Search Matched { this.props.searchSessionResults.total_sessions } Classes</h5>
					</div>
				</div>
				<div className="col-lg-2 d-flex justify-content-center justify-content-sm-end flex-wrap px-2">
					<div className="rose_btn with_back" onClick={this.props.backFromSearch}>
						Back
					</div>
				</div>
			</div>
			<Channel from_search='true' weekslist={this.weekslist}  sessionslist={this.sessionslist} />
		 </div>
    );
  }
}

export default SearchResults;
