import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import { getLocalDateTime } from '../../util/formatDateFunctions';
import moment from 'moment';

class MemberPendingPayments extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartDate: null,
					searchEndDate: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					sortByVal: ['u.id', 'DESC'],
					ajaxLoaded: false,
					checkInfoOpen: false,
					allWeeks: [],
					filterWeek: '',
					memberPlansArray: [],
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
	this.getLocalDateTime = getLocalDateTime.bind(this);
}

componentDidMount(){
	this.getMemberPendingPayments();
}
 
componentWillUnmount() {
}


getMemberPendingPayments =(pageId, countId,)=>{
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/getMemberPendingPayments", {countId: countId,pageId: pageId})          
	.then(res => {
		
		if ( res.data.responseData.plansData )
			{
				this.setState({
					memberPlansArray: res.data.responseData.plansData,
				})
			}
		else 
			{
				this.setState({
					memberPlansArray: [],
				});
			}			
		
		$('.main_loader').attr({'style':'display:none'});
	}).catch(err =>{
		$('.main_loader').attr({'style':'display:none'});
		this.setState({
			memberPlansArray: [],
		});
		console.log('----------there is problem------------', err);
	});
}

handleChange= (name, num)=> e =>{
	let currentArray = [];
	currentArray = this.state.memberPlansArray;
	
	
	currentArray[num]['approveStatus'] = 1;
	let priceNum = e.target.value;
	
	if ( name == 'totalPriceAmount' )
		{
			currentArray[num]['totalPriceAmount'] = priceNum;
		}		
	else if ( name == 'discountValueAmount' )
		{
			currentArray[num]['discountValueAmount'] = priceNum;
		}	
	else if ( name == 'totalCalcPriceAmount' )
		{
			currentArray[num]['totalCalcPriceAmount'] = priceNum;
		}	
	
	this.setState({
				memberPlansArray: currentArray,
			});
	
}

approveALL=()=>{
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	axios.defaults.headers.common['Authorization'] = token;
	
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/approveMemberPayments", {plansData: this.state.memberPlansArray, adminId: userId})          
	.then(res => {
		this.getMemberPendingPayments();
	}).catch(err =>{
		$('.main_loader').attr({'style':'display:none'});
		console.log('-----err-', err);
	});
}

deletePending=(id)=>{
	
	if ( id !== undefined && id !== 0 )
		{	
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
			axios.defaults.headers.common['Authorization'] = token;
			
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/deleteMemberPayment", {id})          
			.then(res => {
				this.getMemberPendingPayments();
			}).catch(err =>{
				$('.main_loader').attr({'style':'display:none'});
				console.log('-----err-', err);
			});
		}
}

  render() {
	
    return (
		<div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className="pay_head_title">Pending Payments</div>
								</div>						
								<div>
									{ this.state.memberPlansArray && this.state.memberPlansArray.length > 0 && 
										<table className="billing_table">
											<thead>
												<tr>
													<th>Member Name</th>
													<th>Plan Name</th>
													<th>Plan type</th>
													<th>Date</th>
													<th>Plan Price</th>
													<th>Discount</th>
													<th>Total for charge</th>
													<th className="th_btn_holder" >
														<div className="open_btn_reports" onClick={this.approveALL}>APPROVE ALL</div>
													</th>
													<th></th>
												</tr>
											</thead>
											<tbody> 
												{ this.state.memberPlansArray.map((item, key) =>
													<tr key={key}>
														<td>{item['firstName']} {item['lastName']}</td>
														<td>{item['planName']}</td>
														{item['planType'] == 1 ?
															<td>Monthly</td>
															:
															<td>Annual</td>
														}															
														<td>{getLocalDateTime(item.updatedDate)}</td>
														<td className="td_price" >
															<div className="planPrice_span text_align_price_span" > ${(item['planPrice']).toFixed(2)} </div>												
															<div className="planPrice_span text_align_price_span" >
																<input 
																	type='num'
																	className="inp_detail_text inp_width" 
																	id="totalPriceAmount"
																	value={this.state.memberPlansArray[key]['totalPriceAmount']}
																	onChange={this.handleChange('totalPriceAmount', key)}
																/>
															</div>
														</td>
														<td className="td_price" >
															<div className="planPrice_span text_align_price_span" > {item['discountValue']}%</div>												
															<div className="planPrice_span text_align_price_span" >
																<input 
																	type='num'
																	className="inp_detail_text inp_width discount_inpt" 
																	id="discountValueAmount"
																	value={this.state.memberPlansArray[key]['discountValueAmount']}
																	onChange={this.handleChange('discountValueAmount', key)}
																/>
																 %
															</div>
														</td>
														<td className="td_price" >
															<div className="planPrice_span text_align_price_span" > ${(item['calcPrice']).toFixed(2)} </div>												
															<div className="planPrice_span text_align_price_span" >
																<input 
																	type='num'
																	className="inp_detail_text inp_width" 
																	id="totalCalcPriceAmount"
																	value={this.state.memberPlansArray[key]['totalCalcPriceAmount']}
																	onChange={this.handleChange('totalCalcPriceAmount', key)}
																/>
															</div>
														</td>
														{item['type'] == 1 ?
															<td>Monthly charge</td>
															:
															<td>End of trial</td>
														}
														<td className="td_btn_holder">
															<div className="open_btn_reports" onClick={()=> this.deletePending(item.id)}>DELETE</div>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									}
									{ this.state.memberPlansArray.length == 0 && 
										<div className="noPaymentsFound" >No pending member plans found</div>
									}
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>	
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default MemberPendingPayments;