import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

class EmbedSubdomain extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isCopied: false,
			iframeText: ''
		};
	  
	}
	
	componentDidMount(){
		this.setIframe();
	}
	
	copyFrame=()=>{
		this.setState({isCopied: true})
	}
	
	setIframe=()=>{
		var iframe = '';
		if ( this.props.subdomain !== '' )
			{
				iframe = '<iframe src="https://'+this.props.channelSubdomain+'"></iframe>';
				this.setState({iframeText: iframe});
			}
	}
	
	render() {
		return (
			<div>
				{ this.props.subdomain === '' &&
					<div className="empty_token pt30">Your Channel doesn't have subdomain. Please go to Dashboard and set Channel Subdomain.</div>
				}
				{ this.props.subdomain !== '' &&
					<div>
						<div className="empty_token">Embed Iframe</div>
						<div className="inline_centered">
							<input type="text" className="input-field token_input" value={this.state.iframeText} disabled />
							<CopyToClipboard text={this.state.iframeText}><div className="token_btn" onClick={() => this.copyFrame()}>{this.state.isCopied ? 'Copied' : 'Copy Iframe'}</div></CopyToClipboard>
						</div>
					</div>
				}
			</div>
		);
	}

}

export default EmbedSubdomain;

