import React from 'react';
import Songs from './Songs';
import SongPopups from './SongPopups'; 
import { connect } from "react-redux";
import { togglePopup, togglePlaylistPopup, showMainErrorPop } from '../../actions/popups';
import { convertAudio } from '../../services/convertAudio';
import { addPlaylistSongs } from '../../actions/playlists';
import { addPlaylist } from '../../actions/playlists';
import { addAsynPlaylists, addElectronPlist, addAsynPlaylistSongs, changePlaylistStatus, updatePlaylists, resetSongList, deletePlaylist } from '../../actions/playlists';
import { putSongsInDb } from '../../services/IndexDB';
import '../../App.css';
import axios from 'axios';
import {  browserHistory} from 'react-router';
import Header from './Header';
import { Link } from 'react-router';
import $ from 'jquery';

import { updatesse } from '../../util/updatesse';
import customProtocolCheck from "custom-protocol-check";
import SupportContainer from '../home/SupportContainer';

import { isMacOs as isMacOs, isWindows as isWindows, browserName as browName, isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablet,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";

class UserPlaylists extends React.Component {
	
	constructor(props){
		super(props);
		
		if ( !localStorage.getItem('userData') )
			{
				window['user_loggedIn'] = 0;
				var adminpath = process.env.REACT_APP_PATH+'/login';
				window.location.href = adminpath;
				return false;
			}
		
		this.state = {
			openedList: -1,
			new_playlist_name: "",
			songs_length: 0,
			playlist_input_error: false,
			selected_playlists: -1,
			openedMainIndex: -1,
			selectedFilesLIst: [],
			playlists: [],
			playing_song_link: "",
			totalSession: 0,
			showLoading: false,
			loginName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName,
			iframelink: '',
			safariLink: '',
			playlist_id: 0,
			showgetnameplaylist: false,
			electronfiles: false,
			appDownload: '',
			showWindowsApp: true,
			mainFaqs: [],
			supportPageName: 'Playlist',
			supportPageApi: '/UserPlaylists',
			userTypeFaqs: 2,
			showSupport: false,
			playlistAdded: false
		}
		
		this.convertAudio = convertAudio.bind(this);
		this.putSongsInDb = putSongsInDb.bind(this);
		this.updatesse = updatesse.bind(this);
	}
	
	componentDidMount()
	{
		this.loadAllSongs();
		
		if ( JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).data.responseData.settings )
			{
				if ( parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.showNativeApplications) === 0 )
					{
						this.setState({showWindowsApp: false});
					}
			}
			
		if ( window.location.href.indexOf('/UserPlaylists') > -1 ) { this.setState({showSupport: true}); }
	}
	
	loadAllSongs()
		{
			const mainthis = this;
			let user_token = JSON.parse(localStorage.getItem("userData"));
			axios.get(process.env.REACT_APP_NAME+"/api/v1/playlists/userplaylist", {headers: {'Authorization': user_token.data.responseData.token}})
			  .then(res => {
				mainthis.props.updatePlaylists(res.data.responseData);
			  });
			this.setState((state) => ({ iframelink: process.env.REACT_APP_URL+'iframetemp', safariLink: process.env.REACT_APP_URL+'api/v1/user/checkiframe' }));
		}
		
		
	componentWillReceiveProps(nextProps) {
		this.setState((state) => ({ playlists: nextProps.userPlaylist }));
		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot)
		{
			if( prevProps.userPlaylist.length !== this.props.userPlaylist.length ) 
				{
					this.setState({showLoading: false});
				}
		}
	
	shouldComponentUpdate(nextState)
		{
			if( ( nextState.playlistStatus === "pending" || nextState.playlistsToAdd.new_songs.length > 0 ) )
				{
					this.props.togglePopup(true, nextState.playlistStatus === "pending");
				}
			
			return true;
		}
		
	handleRemoveErros = () => 
		{
			if( this.state.playlist_input_error )
				{
					this.setState((state) => ({ playlist_input_error: false }));
				}
		}
	
	handlePlaylistName = (ev) => 
		{
			const el = ev.target;
			
			
			this.setState((state) => ({ new_playlist_name: el.value }));
		}
		
	handleSelectItems = (ev, mod) =>
		{
			ev.preventDefault();
			ev.stopPropagation();
			console.log(mod);
			
			this.props.handleSelectPlaylist(mod);
		}
		
	tryDeletePlaylist = (ev, mod) =>
		{
			ev.preventDefault();
			ev.stopPropagation();
			let pl_id = mod;
			this.setState({playlist_id: pl_id});
			
			$("#confirm_delete_playlist").attr({'style':'display:block'});
		}
		
	deletePlaylist = () =>
		{
			this.setState({showLoading: true});
			this.props.deletePlaylist(this.state.playlist_id);
			$("#confirm_delete_playlist").attr({'style':'display:none'}); 
		}
			
	handleSaveList = (ev, res) =>
		{
			const btnval = ev.currentTarget.innerHTML;
			let mainthis = this;
			if( btnval === "SAVE" ) 
				{
					if( this.state.new_playlist_name !== "" ) 
						{
							if ( this.state.electronfiles )
								{
									
									let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
									mainthis.updatesse('playlistDeviceName', [userId], function (data)
										{
											if ( data )
												{ 
													localStorage.setItem('userDeviceName', data);
												}
										});
									
									this.setState({showLoading: true});
									let user_token = JSON.parse(localStorage.getItem("userData"));
									axios.post(process.env.REACT_APP_NAME+"/api/v1/playlists/updateplaylistelectron", {uniqueId: this.state.electronfiles, playlistname: this.state.new_playlist_name, platform_type: osname}, {headers: {'Authorization': user_token.data.responseData.token}} )
									  .then(resd => {
										  mainthis.setState({showLoading: false});
										  this.props.addElectronPlist(); 
										  setTimeout(function(){ mainthis.setState({showgetnameplaylist: false}); mainthis.loadAllSongs(); }, 1000);
									  })
										.catch(() => {
											mainthis.setState({showLoading: false});
											mainthis.props.togglePopup(false, false);
											mainthis.setState({showgetnameplaylist: false});
											this.props.showMainErrorPop('No songs were selected, no playlists will be saved.');
										});
								}
							else
								{
									this.setState({showLoading: true});
									this.putSongsInDb(this.state.selectedFilesLIst, this.state.new_playlist_name)
										.then(() => {
											this.props.addAsynPlaylists(this.state.new_playlist_name); 
										    setTimeout(function(){ mainthis.props.togglePopup(false, res); mainthis.setState({showgetnameplaylist: false}); /* mainthis.loadAllSongs(); */ }, 1000);
										})
										.catch((err) => {
											mainthis.setState({showLoading: false});
											mainthis.props.togglePopup(false, false);
											mainthis.setState({showgetnameplaylist: false});
											
											if( err == "databasefull" ) 
												{
													this.props.showMainErrorPop('You cannot add more than 150 songs.');
												}
											else {
												this.props.showMainErrorPop('Something went wrong.');
											}
											
										});
								}
						}
					else 
						{
							this.setState((state) => ({ playlist_input_error: true }));
						}
				}
			else if( btnval === "OK" )
				{
					this.props.changePlaylistStatus();
					this.setState({showgetnameplaylist: false});
					this.props.togglePopup(false, false);
					window.$('#pickPlaylistType').modal('hide');
				}
			else
				{
					this.props.changePlaylistStatus();
					this.props.resetSongList();
					this.props.togglePopup(false, false);
					this.setState({showgetnameplaylist: false});
					window.$('#pickPlaylistType').modal('hide');
				}
		}
	
	choosenElement;
	choosenElementOffset;
	
	parentElementMain;
	reorderItem = false;
	handleOpenList = (inx, opIndx) => 
		{
			var next_value = inx;
			var next_value_ind = opIndx;
			if( next_value === this.state.openedList ) { next_value = -1; next_value_ind = -1; }
			
			this.setState((state) => ({ openedList: next_value, openedMainIndex: next_value_ind }));
		}
		
	handleDraggStart = (e) => {
		e.preventDefault();
		console.log(e);
		this.choosenElement = e.currentTarget;
		this.choosenElementOffset = e.currentTarget.getBoundingClientRect();
		this.parentElementMain = e.currentTarget.parentNode;
		e.currentTarget.classList.add("ondrag");
		this.choosenElement.style.top = "0px";
		this.reorderItem = false;
	}	
		
	lastIndex = 0;
	handleDraggitem = (e) => {
		if( this.choosenElementOffset )
			{
				var new_top = e.clientY - (this.choosenElementOffset.top) - (this.choosenElementOffset.height / 2);
				
				var songsMainHolder = this.parentElementMain;
				var songsMainHolderChilds = songsMainHolder.children;
				
				var blue = 1;
				var over_index = Math.abs(Math.round(new_top / this.choosenElementOffset.height));
				 
				if( new_top > (this.choosenElementOffset.height / 2) )
					{ 
						var mainind = parseInt(this.choosenElement.getAttribute("data-itemkey"));
						if( (parseInt(mainind)+1) < songsMainHolderChilds.length )
							{
								var choosen_elem = songsMainHolderChilds[mainind]; 
								
								if( choosen_elem.nextElementSibling ) 
									{
										choosen_elem.setAttribute('data-itemkey', parseInt(mainind)+1);
										choosen_elem.nextElementSibling.setAttribute('data-itemkey', parseInt(mainind)); 
										songsMainHolder.insertBefore(choosen_elem, choosen_elem.nextElementSibling.nextElementSibling)
									}
								
								
								this.reorderItem = true;
								this.choosenElement.style.top = "0px";
								new_top = 0;
								this.choosenElement = songsMainHolderChilds[choosen_elem.getAttribute("data-itemkey")];
								this.choosenElementOffset = this.choosenElement.getBoundingClientRect();
							}
					}
				else if( new_top < (this.choosenElementOffset.height / -2) )
					{ 
						var mainind = parseInt(this.choosenElement.getAttribute("data-itemkey"));
						if( (parseInt(mainind)) > 0 )
							{
								var choosen_elem = songsMainHolderChilds[mainind]; 
								
								if( choosen_elem.previousElementSibling ) 
									{
										choosen_elem.setAttribute('data-itemkey', parseInt(mainind)-1);
										choosen_elem.previousElementSibling.setAttribute('data-itemkey', parseInt(mainind)); 
										songsMainHolder.insertBefore(choosen_elem, choosen_elem.previousElementSibling)
									}
								
								this.reorderItem = true;
								
								this.choosenElement.style.top = "0px";
								new_top = 0;
								this.choosenElement = songsMainHolderChilds[choosen_elem.getAttribute("data-itemkey")];
								this.choosenElementOffset = this.choosenElement.getBoundingClientRect();
							}
					}
				
				new_top = e.clientY - (this.choosenElementOffset.top) - (this.choosenElementOffset.height / 2);
				this.choosenElement.style.top = new_top +"px";
				
			}
	}
	
	handleDraggEnd = (e) =>{
		if( this.choosenElement )
			{
				const mainthis = this;
				const stateIndex = this.state.openedMainIndex;
				this.choosenElement = undefined;
				this.choosenElementOffset = undefined;
				
				var songsMainHolder = this.parentElementMain;
				var songsMainHolderChilds = songsMainHolder.children;
				var old_state_in = JSON.parse(JSON.stringify(mainthis.state.playlists.slice()));
				var new_old_state_uncange = old_state_in[stateIndex]['songs'];
				var old_state = this.state.playlists.slice();
					
					
				old_state[stateIndex]['songs'] = [];
				

				
				for( var i = 0; i < songsMainHolderChilds.length; i++ )
					{
						
						var old_attr = parseInt(songsMainHolderChilds[i].getAttribute("data-oldarrange"));
						var new_attr = parseInt(songsMainHolderChilds[i].getAttribute("data-itemkey"));
						songsMainHolderChilds[i].setAttribute("data-itemkey", i);
						songsMainHolderChilds[i].setAttribute("data-oldarrange", i);
						
						
						old_state[stateIndex]['songs'].push(new_old_state_uncange[old_attr]);
					} 
				
				
				
				this.setState((state) => ({ playlists: old_state }));
				
				if( this.reorderItem )
					{
						let user_token = JSON.parse(localStorage.getItem("userData"));
						axios.post(process.env.REACT_APP_NAME+"/api/v1/playlists/updateplaylistsongs", this.state.playlists[stateIndex], {headers: {'Authorization': user_token.data.responseData.token}} )
						  .then(res => {
							  
						  });
					}
				
				if( document.querySelector('.ondrag') )
					{
						document.querySelector('.ondrag').style.top = "0px";
						document.querySelector('.ondrag').classList.remove("ondrag");
					}
			}	
	}
	
		
		
	handleFileChoose = (ev) => 
		{
			const saved_target = ev.currentTarget;
			var final_item = [];
			const mainthis = this;
			
			this.setState((state) => ({ selectedFilesLIst: saved_target.files }));
			let anyFileLargeThan10Mb = false;
			if ( safari )
				{
					for ( let i = 0; i < saved_target.files.length; i++ )
						{
							let fileSize = saved_target.files[i]['size']/1024/1024;
							console.log('fileSize');
							console.log(fileSize);
							if ( fileSize > 10 )
								{
									anyFileLargeThan10Mb = true;
								}
						}
				}
				
			if ( anyFileLargeThan10Mb )
				{
					window.$('#pickPlaylistType').modal('hide');
					if ( saved_target.files.length > 1 )
						{
							this.props.showMainErrorPop('One or more files are larger than 10MB.');
						}
					else
						{
							this.props.showMainErrorPop('The file is larger than 10MB.');
						}
					return;
				}
			
			Array.prototype.forEach.call(saved_target.files, function(file) {
				if( file.type === "audio/mpeg" || file.type === "audio/mp3" || file.type === "video/mp3" )
					{
						mainthis.convertAudio(file).then((res) => {
							mainthis.props.addAsynPlaylistSongs(res);
									res['id'] = Date.now() + "" + Math.floor(Math.random() * 1000000000000);
							final_item.push(res);
						});
					}  
			});
		}
	
	openAddNative = () => {
		
		var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;
	
		this.setState({showLoading: true, appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink )});
		
		var randomId = (new Date().getTime())+'_'+Math.random();
		customProtocolCheck("virdioapp://getplaylist-"+randomId,
							  () => {
								this.setState({showLoading: false});
								window.$('#pickPlaylistType').modal('hide');
								window.$('#nativedownload').modal('show');
							  },
							  () => {
								window.$('#pickPlaylistType').modal('hide');
								let mainthis = this;
								
								mainthis.updatesse('playlistWaiting', [randomId], function (data)
									{
										mainthis.setState({
												showLoading: false, 
												playlistAdded: true
											});
											
										if ( data && data == 'no_songs' )
											{
												mainthis.props.togglePopup(false, false);
												mainthis.setState({showgetnameplaylist: false});
												mainthis.props.showMainErrorPop('No songs were selected, no playlists will be saved.');
											}
									});
								/*---this show no songs added after 2 mins---*/
								/*
								mainthis.setState({playlistAdded: false});	
								
								setTimeout(function() {
									
										mainthis.setState({showLoading: false});
										mainthis.props.togglePopup(false, false);
										mainthis.setState({showgetnameplaylist: false});
										if ( window.location.href.indexOf('/UserPlaylists') > -1 && !mainthis.state.playlistAdded )
											{
												mainthis.props.showMainErrorPop('No songs were selected, no playlists will be saved.');
											}
									
									}, 120000);
								*/	
								this.setState({showgetnameplaylist: true, electronfiles: randomId});
							  }, 15000);
							
		
		
	}
	
	getFaq=(e)=>{
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqsById", {id: e})          
		.then(res => {
			this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs, true)});
		})
		.catch(err =>{ });  
	}
	
	
	
	
	render(){
		const openedPopup = this.props.openPopup;
		const popupState = this.props.popupState;
		
		console.log('Browser name:', browName);
		console.log('osname:', osname);
		
		return (<div className={"UserPlaylists " + (this.props.isFromPopup ? 'from_popup': '')}>
					
					{ !this.props.isFromPopup && 
					<Header></Header>
					}
					
					<div className="playlist_holder">
						<div className="playlist_scroll_x">
						<div className="playlist_head">
							<div className="playlist_main_title">
								<div className="p_title">My Playlist <img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb playlist_bulb" onClick={e=>{this.getFaq(16)}}/></div>
								
								{ !this.props.isFromPopup && ( osname === 'Windows' || osname === 'Mac OS' ) && !tablet &&
									<div className="new_playlist"  data-toggle="modal" data-target="#pickPlaylistType"></div> 
								}
							
								<div className="plOptions">
									<form action={this.state.safariLink} target="_blank" method="POST" id="playlistform">
										<input type="hidden" name="all_data0" id="all_data0" value="" />
										<input type="hidden" name="all_data1" id="all_data1" value="" />
										<input type="hidden" name="all_data2" id="all_data2" value="" />
									</form>
								</div>
								
							</div>
							
							{ !this.props.isFromPopup ?  
								<div className="backBtn"><div onClick={ browserHistory.goBack }>BACK</div></div>:
								<div className="closePopup" onClick={ this.props.togglePlaylistPopup }></div>
							}
						{ /*<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb edit_playlist_bulb" onClick={e=>{this.getFaq(17)}}/>*/ }
						</div>
						<div className="playlist_menu">
							<div className="menu11">Name</div>
							<div className="menu3">No. of Songs</div>
							<div className="menu1">Length</div>
							<div className="menu1">Browser</div>
						</div>
						<div className="songHold">
							{ 
								this.state.playlists.length > 0 && typeof this.state.playlists.map == 'function' ? 
								this.state.playlists.map((item, key) =>	
									( (!this.props.isFromPopup || ( this.props.isFromPopup && ( browName === item.browser || 'Native App' === item.browser || 'Native Windows' === item.browser || 'Native Mac OS' === item.browser ) )) && 
									<div key={key} className="playlists_list">
											<Songs 
												mainIndex={key}
												handleMouseOverMe={this.handleMouseOverMe}
												handleDraggEnd={this.handleDraggEnd}
												handleDraggStart={this.handleDraggStart}
												handleDraggitem={this.handleDraggitem}
												handleSelectItems={this.handleSelectItems}
												selectedPlaylist={this.props.selectedPlaylist}
												isFromPopup={this.props.isFromPopup}
												songInfo={item}
												index={item.playlist_id}
												openedList={this.state.openedList}
												handleOpenList={this.handleOpenList}
												tryDeletePlaylist={this.tryDeletePlaylist}
												deletePlaylist={this.deletePlaylist}
											></Songs>
									</div>
									)
											
								)
								:
								<div className="playlists_list empty_list">No Added Playlists</div>
							}
						</div>
						<SongPopups playlistName={this.state.new_playlist_name} handleRemoveErros={this.handleRemoveErros} playlist_input_error={this.state.playlist_input_error} popupState={popupState} handlePlaylistName={this.handlePlaylistName} isOpenedPopup={openedPopup} isOpenedPopup2={this.state.showgetnameplaylist} handleSaveList={this.handleSaveList}></SongPopups>
						</div>
					</div> 
					{ !safari && !this.props.isFromPopup && <iframe id="clientIframe" src={this.state.iframelink} width="1" height="1" border="0" frameBorder="0"></iframe> }
					
					{ this.state.showLoading &&
						<div className="main_loader active">
						   <div className="main_loader_inner"></div>
						</div>
					}
					
					<div className="modal pr-0" id="pickPlaylistType" aria-modal="true">
						<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
							<div className="modal-content bg-transparent relative">
								<button type="button" className="close white closepurple translatX-Y abs_close" data-dismiss="modal"><span>x</span></button>
								<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
									<p className="mb-0 py-4 white text-center">Virdio Playlists are specific to the Virdio App. Select if you plan to instructor your Class on Virdio App.</p>
								</div>
								<div className="text-center">
									{ browName == 'Chrome' && <button type="button" className="custom_btn1 mt-3 addplbutton phone_new_margin" onClick={this.openAddBrowser}>{browName} BROWSER<input type="file" multiple={true} onChange={ (e) => this.handleFileChoose(e) } /></button> }
									{ this.state.showWindowsApp &&
										<button type="button" className="custom_btn1 mt-3 phone_new_margin" onClick={this.openAddNative}>VIRDIO APPLICATION</button>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="modal pr-0" id="nativedownload" aria-modal="true">
						<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
							<div className="modal-content bg-transparent">
								<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
									<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
									<p className="mb-0 py-4 white text-center">Looks like the Virdio App is not installed on your device, please click the Download button to download the Virdio App Installer.</p>
									{ osname == 'Windows' && <p className="mb-0 py-4 white text-center">Once the Installer is downloaded, double click on the installer to launch it and proceed with installation steps.</p> }
									{ osname !== 'Windows' && <p className="mb-0 py-4 white text-center">Once the Installer is downloaded, double click on the installer to launch it and then move the Virdio Icon to the folder.</p> }
								</div>
						<div className="text-center"><a href={this.state.appDownload} target="_blank" onClick={e=> { window.$("#nativedownload").modal('hide'); window.$("#nativedownload2").modal('show') }}><button type="button" className="col-4 custom_btn1 mt-3">DOWNLOAD</button></a></div>
							</div>
						</div>
					</div>
			
					<div className="modal pr-0" id="nativedownload2" aria-modal="true">
						<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
							<div className="modal-content bg-transparent">
								<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
									<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
									{ ( chrome || edge ) && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible on the bottom left of the browser, click on the file to Install Virdio.</p> }
									{ !chrome && !edge && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible at the top right of the browser, click on the file to Install Virdio.</p> }
									<p className="mb-0 py-4 white text-center">2. After the Installation is complete, click on the ADD button below.</p>
								</div>
								<div className="text-center"><button type="button" className="custom_btn1 col-4 mt-3" onClick={() => { this.openAddNative(); window.$("#nativedownload2").modal('hide') }}>ADD</button></div>
							</div>
						</div>
					</div>
					
					{ this.state.showSupport && <SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer> }
			</div>
		);
	}
}

const mapStateToProps = state => {
	return { 
		openPopup: state.libraryPopups.showPlaylistPopup,
		popupState: state.libraryPopups.popupFinishState,
		playlistsToAdd: state.menagePlaylist.playlistsToAdd,
		playlistStatus: state.menagePlaylist.playlistStatus,
		userPlaylist: state.menagePlaylist.playlists,
	}
}

export default connect(
  mapStateToProps,
  { 
	togglePopup,
	addPlaylistSongs,
	addPlaylist,
	resetSongList,
	addAsynPlaylists,
	addElectronPlist,
	addAsynPlaylistSongs,
	togglePlaylistPopup,
	changePlaylistStatus,
	updatePlaylists,
	deletePlaylist,
	showMainErrorPop
	}
)(UserPlaylists);