import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import { deleteCookie } from '../auth/Logout';
import SupportContainer from '../home/SupportContainer';
import classnames from "classnames";
import { userPermissions } from '../../util/userPermissions';

class channelHosts extends Component {
  
  constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state={
		hostNameData: '',
		inviteHostEmail: '',
		customRollRadio1: true,
		customRollRadio2: false,
		responseMessage: '',
		hostsArray: [],
		choosenHostId: 0,
		choosenHostObject: {},
		currentHostId: 0,
		userAvatar: '',
		mainFaqs: [],
		isAdmin: 0,
		adminCount: 0,
		supportPageName: 'Channel Hosts',
		supportPageApi: '/channelHosts',
		userTypeFaqs: 2,
		isMarketplaceChannel: false,
		marketplaceUserType: 3,
		marketplaceTypes: [{name: 'Administrator & Instructor', type: 3}, {name: 'Instructor', type: 1}, {name: 'Operations Team', type: 4}, {name: 'Fitness Programming', type: 5}, {name: 'QA', type: 6}],
		userPermissions: {}
    }
	this.deleteCookie = deleteCookie.bind(this);
	this.userPermissions = userPermissions.bind(this);
}
 
componentDidMount(){
	let hostName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName;
	let hostId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	this.setState({userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image});
    this.setState({
        hostNameData: hostName,
        currentHostId: hostId
    });

	this.getHosts();
	
	if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 0 )
		{
			if ( localStorage.getItem('is_admin') && JSON.parse(localStorage.getItem('is_admin')) === 1 ) 
				{ 
					this.setState({isAdmin: 1});
				}
		}
	else
		{
			var channelRole = JSON.parse(localStorage.getItem('userData')).data.responseData.channelRole;
			if ( channelRole == "Owner" )
				{
					this.setState({isAdmin: 1});
				}
		}
		
	if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
		{
			this.setState({isMarketplaceChannel: true});
			var permissions = this.userPermissions(JSON.parse(localStorage.getItem('userData')).data.responseData.channelRole);
			this.setState({userPermissions: permissions});
		}	
}

getHosts=()=>{
	let postdata = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.channel }
    axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getChannelHosts", postdata)
	.then(res => {
		this.setState({
			hostsArray: res.data.responseData
		});
		
		var adminCnt = 0;
		for ( var i = 0; i < res.data.responseData.length; i++ )
			{
				if ( res.data.responseData[i].is_admin == 1 ) { adminCnt++; }
			}
			
		this.setState({adminCount: adminCnt});
		
		$('.main_loader').hide();
	}).catch(err =>{ });
}

getFaq=(e)=>{
	 axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqsById", {id: e})          
	.then(res => {
		this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs)});
	})
	.catch(err =>{ });  
}

openAddingHost=(e)=>{
	$('#dasboard_myModal2').attr({'style':'display:block'});
}

checkRoll1=(e)=>{
	this.setState({
		[e.target.id]:!this.state.customRollRadio1,
		customRollRadio2:!this.state.customRollRadio2
	})
}

checkRoll2=(e)=>{
	this.setState({
		[e.target.id]:!this.state.customRollRadio2,
		customRollRadio1:!this.state.customRollRadio1
	})
}

validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

inviteHostData=(e)=>{
	 if ( this.validateEmail(this.state.inviteHostEmail) ) 
		 {
			 $('.email_field').removeClass('red-outline');
			 $('.main_loader').attr({'style':'display:flex'});
			 let userType; 
			 if ( this.state.customRollRadio1 ) { userType = 3; }
			 if ( this.state.customRollRadio2 ) { userType = 1; }
			 if ( this.state.isMarketplaceChannel ) { userType = this.state.marketplaceUserType; }
			 
			 let postdata = { 
							"firstName" : '', 
							"lastName": '',
							"email": this.state.inviteHostEmail.trim(),
							"userType": userType, 
							"channelId": JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
							"channelAdmin": JSON.parse(localStorage.getItem('userData')).data.responseData.id,
							"userStatus":"newUser"
						}
			
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/inviteUser", postdata)
			.then(res => {
				 $('.main_loader').hide();
				this.setState({
					responseMessage:"Your invitation has been sent successfully."
				},()=>$("#invitation_popup").attr({'style':'display:block'}))
			}).catch(err =>{
				$('.main_loader').hide();
				if (err.response.data.responseCode===417)
					{
						this.setState({
							responseMessage:err.response.data.errorData
						},()=>$("#invitation_popup").attr({'style':'display:block'}))
					}
			});
		 }
	else
		{
			$('.email_field').addClass('red-outline');
		}
}

openStatusPop=(id, type)=>{
	if (type === 'act')
		{
			$('#change_statuspop').attr({'style':'display:block'});
		}
	else 
		{
			$('#change_statuspop_inc').attr({'style':'display:block'});
		}
	this.setState({
		choosenHostId: id
	});
}

openAdminPop=(mod, type)=>{
	if ( type === 'remove' ) { $('#remove_admin').attr({'style':'display:block'}); }
	else { $('#add_admin').attr({'style':'display:block'}); }
	
	this.setState({
		choosenHostId: mod.userId,
		choosenHostObject: mod
	});
}

removeHost=()=>{
	let id = this.state.choosenHostId;
	let postdata = {hostId: id, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/deleteChannelHost", postdata)
	.then(res => {
		this.getHosts();
		$('#change_statuspop').attr({'style':'display:none'});
	}).catch(err =>{ });
}
setActive = e =>{
	let id = this.state.choosenHostId;
	let postdata = {hostId: id, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/setActive", postdata)
	.then(res => {
		this.getHosts();
		$('#change_statuspop_inc').attr({'style':'display:none'});
	}).catch(err =>{ });
}

updateAdminStatus=(st)=>{
	$('.main_loader').attr({'style':'display:flex'});
	let postdata = {hostId: this.state.choosenHostId, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel, isAdmin: st};
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/changeAdminStatus", postdata)
	.then(res => {
		this.getHosts();
		$('#add_admin').attr({'style':'display:none'});
		$('#remove_admin').attr({'style':'display:none'});
	}).catch(err =>{ });
}

openCloseRole=(i)=>{
	var hostArrayVar = this.state.hostsArray;
	hostArrayVar.map((item, ind) => { if ( ind != i ) { item.isOpenRoles = false; } });
	
	hostArrayVar[i].isOpenRoles = !hostArrayVar[i].isOpenRoles;
	
	this.setState({hostsArray: hostArrayVar});
}

preventDef=(e)=>{
	e.preventDefault();
	e.stopPropagation();
}

changeUserRole=(id, role)=>{
	$('.main_loader').attr({'style':'display:flex'});
	let postdata = { userId: id, role: role, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel };
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateUserRole", postdata).then(res => {
		this.getHosts();
	}).catch(err =>{ });
}

  render() {
	 return (
       <div>
			<div className="container-fluid custom-width">
				<div className="row top-header px-2 px-md-4 py-4">
					<div className="col-lg-6 flex-wrap d-flex justify-content-center justify-content-lg-start p-2">
						<div className="align-self-center">
							<img src="/images/login-logo.png" className="logo my-2 cursor-pointer" alt="logo" onClick={e=>{$("#go_Dashboard").attr({'style':'display:block'});browserHistory.push('/DashboardLanding')}}/>
						</div>
						<div className="d-flex d-md-block justify-content-center px-2 px-md-4 py-2">
							<div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
								<div className="pl-4">
									<h3>Welcome {this.state.hostNameData}!</h3>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 txt_right">
						<button className="btn btn-outline-secondary font-weight-bold radius-8  align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}}>LOG OUT</button>
					</div>
				</div> 
			</div>
			<div className="for_all_members">
				<div className="members_container host_container relative">
					<div className="inner_host_cont relative">
						<Link to='/DashboardLanding'>
							<div className="closePopup mainclose_position"></div>
						</Link>
						<div className="padd_bord">
							<div className="mt-4 menu_hosts host_left">
								{ this.state.isMarketplaceChannel ? 
									<h4 className="text-white d-inline-block font-22 pt-1 pl-2 mb-0">Manage Roles</h4>
									:
									<h4 className="text-white d-inline-block font-22 pt-1 pl-2 mb-0">Add Instructor</h4>
								}
								<Link className="pick ml-4 cursor" onClick={this.openAddingHost}>
									<img src="/images/add.png" alt="" />
								</Link>
							</div>
						</div>
						<div className="for_members_menu">
							<div className="one_menu_host admin_width">Name</div>
							<div className="one_menu_host admin_width">Email</div>
							<div className="one_menu_host admin_width">Status</div>
							{ !this.state.isMarketplaceChannel && <div className="one_menu_host admin_width">Admin</div> }
							{ this.state.isMarketplaceChannel && <div className="one_menu_host admin_width">Role</div> }
						</div>
						{ this.state.hostsArray.length > 0 &&
							<div className="padd_hosts">
								{this.state.hostsArray.map((row, i)=>
									<div className="one_member" key={i}>
										<div className="one_menu_host admin_width host_rows new_dots_email">{row.username} 
											{row.is_admin === 1 && !this.state.isMarketplaceChannel && <span>Administrator</span> }
											{row.roleName !== "" && this.state.isMarketplaceChannel && <span>{row.roleName}</span> }
										</div>
										<div className="one_menu_host admin_width host_rows new_dots_email">{row.email}</div>
										{ !this.state.isMarketplaceChannel ?
											<div className="one_menu_host admin_width host_rows">
												{ row.is_admin != 1 && this.state.isAdmin == 1 && row.status == 1 &&
													<button className="btn btn-outline-success" onClick={ () => this.openStatusPop(row.userId, 'act') }>Active</button>
												}
												{ row.is_admin != 1 && this.state.isAdmin == 1 && row.status != 1 &&
													<button className="btn btn-outline-dark" onClick={ () => this.openStatusPop(row.userId, 'inc') }>Inactive</button>
												}
											</div>
											:
											<div className="one_menu_host admin_width host_rows">
												{ this.state.isAdmin == 1 && row.status == 1 &&
													<button className="btn btn-outline-success" onClick={ () => this.openStatusPop(row.userId, 'act') }>Active</button>
												}
												{ this.state.isAdmin == 1 && row.status != 1 &&
													<button className="btn btn-outline-dark" onClick={ () => this.openStatusPop(row.userId, 'inc') }>Inactive</button>
												}
											</div>
										}
										{ !this.state.isMarketplaceChannel &&
											<div className="one_menu_host admin_width host_rows">
												{ row.is_admin == 1 && this.state.isAdmin == 1 && this.state.adminCount > 1 && row.status == 1 &&
													<button className="btn btn-outline-dark" onClick={ () => this.openAdminPop(row, 'remove') }>Remove Admin</button>
												}
												{ row.is_admin !== 1 && this.state.isAdmin == 1 && row.status == 1 &&
													<button className="btn btn-outline-success" onClick={ () => this.openAdminPop(row, 'set') }>Set Admin</button>
												}
											</div>
										}
										{ (this.state.isMarketplaceChannel && row.roleName !== "Owner" && row.status == 1 && this.state.isAdmin == 1 ) &&
											<div className="one_menu_host admin_width host_rows">
												<div className={classnames({"one_filter_review": true, "active": row.isOpenRoles})} onClick={() => this.openCloseRole(i)}>{row.roleName !== "" ? row.roleName : "None"}
													<div className={classnames({"filter_arrow": true, "active": row.isOpenRoles})}></div>
													{ row.isOpenRoles &&
														<div className="drop_filter" onClick={this.preventDef}>
															{ this.state.marketplaceTypes.map((item, i)=>
																<div className="one_filter_drop" key={i} onClick={() => this.changeUserRole(row.userId, item.type)}>
																	<div className={classnames({"filter_toogle": true, "active": item.type == row.role})}></div>
																	<div className="filter_option">{item.name}</div>
																</div>
															)}
														</div>
													}
												</div>
											</div>
										}
									</div>
								)}  
							</div>
						}
						{ this.state.hostsArray.length === 0 &&
							<div className="no_hosts">No available instructors.</div>
						}
					</div>
				</div>
			</div>
			
			
			<div className="modal" id="dasboard_myModal2">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content translatee pt-4 pb-5">
						<div className="d-flex justify-content-end">
							<button type="button" className="close white closepurple translate-5" onClick={e=>$("#dasboard_myModal2").attr({'style':'display:none'})}><span>x</span></button>
						</div>
						<div className="text-center">
							<img src="/images/host.png" alt="" className="wd-70" />
							<p className="white font-20">Invite Someone to be a Instructor</p>
						</div>
						<div className="modal-body round p-4">
							<div className="card cardbg pb-2">
							<div className="px-3">
								<div className="row">
									<div className="col-md-12 pr-md-2 mt-2">
										<div className="form-group">
											<span className="cover-border bg_gray_clr"></span>
											<label className="label">Email Address</label>
											<input type="email"
											className="input-field email_field"
											placeholder="Enter email"
											id="inviteHostEmail" 
											value={this.state.inviteHostEmail} 
											onChange={e=>{this.setState({[e.target.id]:e.target.value})}} />
										</div>
									</div>
										{ !this.state.isMarketplaceChannel &&
											<div className="row">	
												<div className="col-md-4">
													<h3 className="info">
														<img src="images/testing.png" className="mr-3 text_lft_icon" alt="script-icon" />Role
														<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb channel_host_bulb" onClick={e=>{this.getFaq(10)}}/>
													</h3>
												</div>
												<div className="col-md-4 px-4">
													<div className="custom-control custom-radio">
														<input type="radio" className="custom-control-input" id="customRollRadio1" value="true"   name="example2" checked={this.state.customRollRadio1} onChange={this.checkRoll1} />
														<label className="custom-control-label" htmlFor="customRollRadio1"> 
														<p className="ml-2 mb-0 p-top-3">Administrator & Instructor</p> </label>
													</div> 
												</div>
												<div className="col-md-4 px-4">
													<div className="custom-control custom-radio">
														<input type="radio" className="custom-control-input" id="customRollRadio2" value="false"  name="example2" checked={this.state.customRollRadio2} onChange={this.checkRoll2} />
														<label className="custom-control-label" htmlFor="customRollRadio2">
														<p className="ml-2 mb-0 p-top-3">Instructor</p></label>
													</div>
												</div>
											</div>
										}
										{ this.state.isMarketplaceChannel &&
											<div className="roles_content">
												{ this.state.marketplaceTypes.map((row, i)=>
													<div className="one_role" key={i}>
														<div className={classnames({"filter_toogle": true, "active": this.state.marketplaceUserType == row.type})} onClick={() => this.setState({marketplaceUserType: row.type})}></div>
														<div className="role_option">{row.name}</div>
													</div>
												)}
											</div>
										}
									</div>
								</div>
							</div>
						</div>
						<div className="donebg"><button type="button"  className="done mt-4" id="checkHost" onClick={this.inviteHostData}>Invite</button></div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="invitation_popup" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
							<div className="py-4 text-light text-center font-20">{this.state.responseMessage}</div> 	
						</div>
						<div className="text-center">
							<button type="button" 
							className="custom_btn1 mt-3" 
							data-dismiss="modal" 
							onClick={e=>{
								this.setState({
									inviteHostEmail:''
								},()=>{
									$("#invitation_popup").attr({'style':'display:none'});
									$("#dasboard_myModal2").attr({'style':'display:none'});
								}
							)
							}}>ok</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="change_statuspop" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
							<div className="py-4 text-light text-center font-20">Are you sure you want to set status to Inactive?</div> 	
						</div>
						<div className="text-center">
							<button type="button" className="custom_btn1 mt-3" onClick={e=>{$("#change_statuspop").attr({'style':'display:none'});}}>Cancel</button>
							<button type="button" className="custom_btn1 mt-3 ml-20" onClick={this.removeHost}>Yes</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="change_statuspop_inc" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
							<div className="py-4 text-light text-center font-20">Are you sure you want to set status to Active?</div> 	
						</div>
						<div className="text-center">
							<button type="button" className="custom_btn1 mt-3" onClick={e=>{$("#change_statuspop_inc").attr({'style':'display:none'});}}>Cancel</button>
							<button type="button" className="custom_btn1 mt-3 ml-20" onClick={this.setActive}>Yes</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="remove_admin" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
							<div className="py-4 text-light text-center font-20">Are you sure you want to remove {this.state.choosenHostObject.username} from Channel Admins?</div> 	
						</div>
						<div className="text-center">
							<button type="button" className="custom_btn1 mt-3" onClick={e=>{$("#remove_admin").attr({'style':'display:none'});}}>Cancel</button>
							<button type="button" className="custom_btn1 mt-3 ml-20" onClick={ () => {this.updateAdminStatus(0)}}>Yes</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="modal pr-0" id="add_admin" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
							<div className="py-4 text-light text-center font-20">Are you sure you want to set {this.state.choosenHostObject.username} as Channel Admin?</div> 	
						</div>
						<div className="text-center">
							<button type="button" className="custom_btn1 mt-3" onClick={e=>{$("#add_admin").attr({'style':'display:none'});}}>Cancel</button>
							<button type="button" className="custom_btn1 mt-3 ml-20" onClick={ () => {this.updateAdminStatus(1)}}>Yes</button>
						</div>
					</div>
				</div>
			</div>
			
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}
export default connect(
  null,
  {
	showMainErrorPop
  }
)(channelHosts);
