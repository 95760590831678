import React, { Component } from "react";
import DatePicker from "react-datepicker";
class UsePolicy extends Component {
  
  constructor(props) {
	super(props);
	    
}
 
componentDidMount(){
	

  };

  


  render() {
	 return (
	<div>
            <div id="root">
            <div className="App">
                <div className="container-fluid custom-width">
                    <div className="top-header px-3 px-md-5 pt-5">
                        <div className="">
                            <img src="images/login-logo.png" className="logo" />
                        </div>
                        <div className="privacy_banner position-relative">
                            <img src="images/use-policy.png" alt="policy" className="w-100" />
                            {/* <div className="banner_text">
                                <h2 className="text-center mb-0">Privacy</h2>
                                <h3 className="text-center mb-0">Your provacy is important to us</h3>
                            </div> */}
                        </div>                                       
                    </div>
                    <div className="background_gray px-3 px-md-5 pt-4 pb-4">
                        <div className="content-privacy pt-4">
                            <h3 className="purple_text font-32 mb-4 text-center font-book">Code of Conduct </h3>
                            <h3 className="white"><u>Effective December 1, 2019 </u></h3>
                            <p className="text-white">This Code of Conduct (the “CoC”) is incorporated by reference into the Virdio Terms of Service. Capitalized terms not defined herein have the meanings set forth in the Terms of Service. The following terms and conditions supplement the Virdio Terms of Service and are not exhaustive.  Please be respectful of others when you use the Service. </p>
                            <h3 className="white"><u>When you use the Services, you will not: </u></h3>
                            <ul>
                                <li>Post, stream or otherwise transmit content that: 
                                    (i) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable;<br /> (ii) you do not have a right to transmit under any law or under contractual or fiduciary relationships;<br /> (iii) poses or creates a privacy or security risk to any person; or<br /> (iv) infringes any intellectual property or other proprietary rights of any party; </li>
                                <li>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity; </li>
                                <li>Exploit or solicit personal information from anyone under the age of 18; </li>
                                <li>Violate any applicable local, state, national or international law, or any regulations having the force of law;  </li>
                                <li>Interfere with or disrupt the Service, servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service; or  </li>
                                <li>Obtain or otherwise attempt to access any materials or information through any means not intentionally made available or provided for through the Service. </li>
                                <li>Post, stream or transmit any content, including live video, that violates this Policy </li>
                                <li>Do anything illegal, facilitate any illegal activity, or promote violence.</li>
                                <li>Do anything that threatens, exploits or otherwise harms children. </li>
                                <li>Engage in any activity that is harmful, obscene, or indecent (particularly as such would be understood in the context of business usage). This includes, for example, displays of nudity, violence, pornography, sexually explicit material, or criminal activity. </li>
                                <li>Facilitate or support human trafficking. </li>
                                <li>Engage in any activity that supports or facilitates terrorism or terrorist organizations</li>
                                <li>Engage in any activity that is fraudulent, false, or misleading. </li>
                                <li>Engage in any activity that is defamatory, harassing, threatening or abusive. </li>
                                <li>Store or transmit any data or material that is fraudulent, unlawful, harassing, libelous, threatening, obscene, indecent or otherwise inappropriate. </li>
                                <li>Send unauthorized messages, advertising or spam, including unsolicited promotional or commercial content or other mass solicitation material. </li>
                                <li>Misrepresent the User’s identity or affiliation with any entity or organization, or impersonate any other person. </li>
                                <li>Harvest, collect, or gather user data without consent.</li>
                                <li>Violate or infringe any intellectual property or proprietary rights of others, including copyrights. </li>
                                <li>Violate the privacy of others or distribute confidential or personal information of others. </li>
                                <li>Engage in any activity that is harmful or disruptive to the Services or attempts to circumvent restrictions on access, usage or security of the Services. This includes transmitting viruses, malware or other malicious or destructive code or using tools that mask IP address location or to otherwise circumventing restrictions on use due to regulations or account closures. </li>
                            </ul>		
                            <h3 className="white"><u>Instructor(s) specific rules/regulations </u></h3>
                            <ul>
                                <li>Must be logged in 5/10 minutes before class is to begin  </li>
                                <li>Must wear appropriate clothing for the activity you are hosting 
                                    <ul>
                                        <li>Fitness: Proper gym attire is required-no jeans, open toed shoes or clothing with profanity </li>
                                        <li>Wine Tasting: Business casual or attire with company logo </li> 
                                    </ul>
                                </li>
                                <li>No use of profanity </li>
                                <li>Follow the Virdio <a href="/TermsofService">Best Practices</a> to ensure quality production standards  </li>
                            </ul>

                            <h3 className="white"><u>Member(s) specific rules/regulations</u></h3>
                            <ul>
                                <li>Must be logged in </li>
                                <li>Must wear appropriate clothing for the activity you are hosting  
                                    <ul>
                                        <li>Fitness: Proper gym attire is required-no jeans, open toed shoes or clothing with profanity </li>
                                    </ul>
                                </li>
                                <li>No use of profanity</li>
                                <li>Follow Virdio <a href="/TermsofService">Best Practices</a></li>
                            </ul>


                                <p>If you become aware of any actual or potential violations of the foregoing, please contact <a href="#">support@virdio.com </a> Without limiting any other remedies Virdio may have, we reserve the right to investigate and take appropriate legal action against anyone who, in our discretion, violates this CoC, including without limitation, removing the offending content from the Service or suspending or terminating the account of such violators. </p>                                
                        </div>
                    </div>
                </div>
            </div>
        </div>	
	</div>	
    );
  }
}
export default UsePolicy;
