import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
import $ from 'jquery';

class IdeaWorldVirtual extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		firstName: '',
		lastName: '',
		email: '',
		errorFirstName: false,
		errorLastName: false,
		errorEmail: false,
	};
}
 
componentDidMount(){
	
};


validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

submitForm=()=> {
	var canSubmit = true;
	this.setState({
		errorFirstName: false,
		errorLastName: false,
		errorEmail: false,
	})
	
	if ( this.state.firstName === '' )
		{
			canSubmit = false;
			this.setState({ errorFirstName: true });
		}

	if ( this.state.lastName === '' )
		{
			canSubmit = false;
			this.setState({ errorLastName: true });
		}
		
	if ( this.state.email === '' || !this.validateEmail(this.state.email) )
		{
			canSubmit = false;
			this.setState({ errorEmail: true });
		}
		
	if ( canSubmit )
		{
			let postdata = {'firstName': this.state.firstName, 'lastName': this.state.lastName, 'email': this.state.email }
			
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/addVirtualUser", postdata)          
			.then(res => {
				this.setState({
					firstName: '',
					lastName: '',
					email: ''
				})
				
				$('#succes_registration').attr({'style':'display:block'});
			})
			.catch(err =>{ this.props.showMainErrorPop("Something went wrong. Please try again!"); });
		}
}

  render() {
	 return (
		<div className="all_virtual_pg">
			<div className="main_virtual_pop">
				<div className="for_main_logo">
					<div className="main_logo"></div>
					<div className="sub_logo"><span>&#8226;</span>Virtual Studio<span>&#8226;</span></div>
					<Link to="/">
						<div className="close_virtual"></div>
					</Link>
				</div>
				<div className="welcomes">Virdio Welcomes IDEA Virtual World 2020 Attendees</div>
				<div className="main_virtual_text">Sign Up now and get 2 weeks free and 25% off for 60 days*</div>
				<div className="for_main_form">
					<div className="main_virtual_form">
						<div className="virtual_form_title">Personal Info</div>
						<div className="one_formv50 f_form">
							<div className="form-group one_inputform">
								<label className="abs_label">First Name</label>
								<input type="text" className={"form-control input_virtual " + (this.state.errorFirstName ? 'error_virtual' : '')} value={this.state.firstName} id="firstName" onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorFirstName: false})} />
								<div className="img_holder"></div>
							</div>
						</div>
						<div className="one_formv50 s_form">
							<div className="form-group one_inputform">
								<label className="abs_label">Last Name</label>
								<input type="text" className={"form-control input_virtual " + (this.state.errorLastName ? 'error_virtual' : '')} value={this.state.lastName} id="lastName" onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorLastName: false})} />
								<div className="img_holder"></div>
							</div>
						</div>
						<div className="one_formv100">
							<div className="form-group one_inputform">
								<label className="abs_label">Email Address</label>
								<input type="email" className={"form-control input_virtual " + (this.state.errorEmail ? 'error_virtual' : '')} value={this.state.email} id="email" onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorEmail: false})} />
								<div className="img_holder email_img"></div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="main_virtual_text main_virtual_text2"><span>*</span> Offer good from September 1st 2020 thru December 31st 2020</div>
				
				<div className="for_virtual_btn">
					<button type="button" className="done mt-3" onClick={this.submitForm}>REGISTER</button> 
				</div>
			</div>
			
			
			<div className="modal pr-0" id="succes_registration" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center font-20">Thank you for your interest in Virdio. Be on the lookout for a conformation email which includes a link to sign up for a private demo.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<Link to="/"><button className="custom_btn1 mt-3 mx-2">OK</button></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>	
    );
  }
}

const mapStateToProps = state => {
	return { 
		openMainPopup: state.libraryPopups.showPlaylistMainPopup
	}
}

export default connect(
  mapStateToProps,
  { 
	showMainErrorPop
  }
)(IdeaWorldVirtual);
