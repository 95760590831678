import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import AddEditProduct from '../home/AddEditProduct';
import { productFetchAttributesList } from '../../util/productFetchAttributesList';
import { productAddAttribute } from '../../util/productAddAttribute';
import { productSaveProductList } from '../../util/productSaveProductList';

class products extends Component {
  
  constructor(props) {
	super(props);
	this.state={
        hostName:'',wineProduct:[] ,
		addProduct:[],
		productInformation:[],
		addAttribute:[],
		shoppingProductName: '',
		productAdd: false,
		imageName: false,
		productEdit: true,
		imagePreviewUrl:"/images/browse-image.png",
		chosenProductId: 0,
		userAvatar: ''
    
    }
    this.validator = new SimpleReactValidator();  
    this.editValidator = new SimpleReactValidator();  
	
	this.fetchAttributesList = productFetchAttributesList.bind(this);
	this.addAttribute = productAddAttribute.bind(this);
    this.saveProductList = productSaveProductList.bind(this);
    
}
 
deleteRowProducts = (mod,active) => {
	console.log(mod);
	let wine_prod = [...this.state.wineProduct];
	
	let is_active = !active;
	
	wine_prod.map(item => { if(item['id'] === mod['id']) { item['active'] = !item['active'] } });
	this.setState({ wineProduct: wine_prod });
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/deleteproduct",{active: is_active ? 1: 0, "product_id":mod['id']})
    .then(responseData => {
		
	}).catch(err =>{
		
	});
	
	
	
}
 
componentDidMount(){
    this.setState({userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image});
	let hostName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName
    this.setState({
        hostNameData:hostName
    });
    console.log('CHANNEL ID>>>>',localStorage.getItem('channelId'))
    console.log('GROUP ID>>>>',localStorage.getItem('groupId'))
	this.getProducts();
	this.fetchAttributesList();

  };

  updateStateFromChild=(newState, mod) => {
	this.setState(newState);
	if ( mod )
		{
			this.fetchAttributesList();
			$("#product_lst_modal").attr({'style':'display:none'});
		}
}
  
  getProducts=(mod)=>{
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
    let groupId=localStorage.getItem('groupId');
    //channdlId=1;
    if(!channelId){
        console.log('======sas=======',localStorage.getItem('channelId'))
      channelId=localStorage.getItem('channelId');
    }
    axios.post(process.env.REACT_APP_NAME+"/api/v1/session/channel/product-list",{"channelId":channelId,"groupId":groupId})
    .then(responseData => {
		console.log("GET MEMEBR LIST ======>>>>>>",responseData)
		this.setState({
            wineProduct:responseData.data.responseData
        }, ()=>{console.log("KKKKKK====",this.state.wineProduct)}
        )
		
		if ( mod )
			{
				$('.main_loader').hide();
			}				
	}).catch(err =>{
		console.log('----------there is problem------------',err);
		});
    }
	
	editProduct=(mod)=>{
		console.log('product');
		console.log(mod);
		let product_info = mod;
		
		let arr = this.state.productInformation;
		
		let copy_of_product = JSON.parse(JSON.stringify(this.state.addProduct));
		$.each(product_info.attributes, function( a, b ) {
		  $.each(copy_of_product[0].attributes, function( c, d ) {
			 if ( b['attrLabel'] == d['attrKey'] )
				{
					d['status'] = !d['status'];
					d['attrValue'] = b['attrValue'];
					
					 arr.push(copy_of_product[0].attributes[c]);
				}
			});
		});
		
		
		this.setState({
            shoppingProductName: product_info.description,
			addProduct: copy_of_product,
			productInformation: arr,
			chosenProductId: product_info.id, 
			imagePreviewUrl: product_info.image.indexOf("No_Image_Available.png") > -1 ? "/images/browse-image.png" : product_info.image,
			imageName: product_info.image.indexOf("No_Image_Available.png") > -1 ? false : product_info.image
        });
		
		let mainthis = this;
		setTimeout( function(){
			
			console.log('this.state.addProduct');
			console.log(mainthis.state.addProduct);
			
		}, 500);
		
		$("#product_lst_modal").attr({'style':'display:block'});
	}
	
handleProductList = idx => evt => {
    const newShareholders = this.state.productInformation.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, attrValue: evt.target.value };
    });
  
    this.setState({ productInformation: newShareholders },()=> {
      console.log('Product List',this.state.productInformation[idx].attrValue)
    }
    );
  }

  render() {
	 return (
        <div>
            <div id="root">
                <div className="App">
                    <div className="container-fluid custom-width pb-5">
                        <div className="row top-header px-2 px-md-4 py-4">
                            <div className="col-lg-6 flex-wrap d-flex justify-content-center justify-content-lg-start p-2">
                            <div className="align-self-center">
                                <img src="/images/login-logo.png" className="logo my-2 cursor-pointer" alt="logo" onClick={e=>{$("#go_Dashboard").attr({'style':'display:block'});browserHistory.push('/DashboardLanding')}}/>
                            </div>
                            <div className="d-flex d-md-block justify-content-center px-2 px-md-4 py-2">
                                <div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
                                <div className="pl-4">
                                <h3>Welcome {this.state.hostNameData}!</h3>
                                     {/* <p>No Class coming up this week</p> */}
                                    {/* <p>Next Class, Wednesday, 24 July 2019</p> */}
                                </div>
                                </div>
                            </div>
                            </div>
                             <div className="col-lg-6 ">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Average Attendance</p>
                                        <h3>0%</h3>
                                    </div>
                                    <span className=""></span>
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Views</p>
                                        <h3>0</h3>
                                    </div>
                                    {/* <span className="border-right gray-border"></span> */}
                                    <div className="header-info-right mx-2 d-none">
                                        <p>Total Revenue</p>
                                        <h3>$0</h3>
                                    </div>
                                    {/* <span className="border-right gray-border"></span> */}
                                    <div className="message-notification mx-2 d-none">
                                        <img src="/images/message.png" alt = '#'/>
                                        <span className="message-count">0</span>
                                    </div>
                                    <button className="btn btn-outline-secondary font-weight-bold radius-8  align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId');browserHistory.push("/")}}>LOG OUT</button>
                                </div>
                            </div> 
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center flex-wrap">
                            <h4 className="text-white font-22 pt-1 pl-2 mb-2">Manage Member</h4>
                        </div> */}
                        <div className="gray-box2 radius-10 member-sec pb-5 relative">
							<Link to='/DashboardLanding'>
								<div className="closePopup mainclose_position"></div>
							</Link>
                            <div className="d-flex justify-content-between align-items-center px-4 pb-4 border-bottom">
                                <div className="mt-4">
                                <h4 className="text-white d-inline-block font-22 pt-1 pl-2 mb-0">Manage Products</h4>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center px-4 pb-4 flex-wrap">
                                <div className="mt-4 col-md-7 px-md-0 d-flex align-items-center">
                                </div>
                                <div className="mt-4 col-md-5 px-md-0">
                                    <div className="input-group mb-3 mt-4" onClick={e=>{$("#coming_soon_alert").attr({'style':'display:block'})}}>
                                        <input type="text" className="form-control shadow-effect" placeholder="Enter product name" name="" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <a href="#"><img src="/images/search.png" alt="search" /></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-md-4 products_hold">
                                <div className="row mx-0 px-md-4 border-bottom">
                                    <div className="col-8 pl-0 pl-sm-2">
                                        <p className="text-white font-18">Name</p>
                                    </div>
                                    <div className="col-2">
                                        <p className="text-white text-center font-18">Edit</p>
                                    </div>
                                    <div className="col-2">
                                        <p className="text-white text-center font-18">Active</p>
                                    </div>
                                </div>
                                {this.state.wineProduct.length>0?	
                                (this.state.wineProduct.map((row,i)=>
                                <div className="row mx-0 px-md-4 py-4 add_poss_rell" key={i}>
                               
                                    <div className="col-8 pl-0 pl-sm-2">
                                        <div className="d-flex">
                                            <img src={row.image} className="ml-2 mr-3 align-self-start user-avtar" alt="user-icon" width="60" height="60" />
                                            <div>
                                                <p className="checktxt_name mb-2">{row.name} </p>
                                                {/* <p className="checktxt mb-0">{row.email}</p> */}
                                                {/* <p className="checktxt mb-0">Past Revenue $45,000</p> */}
                                            </div>
                                        </div>
                                    </div>                                
									<div className="col-2 d-flex justify-content-center align-items-center add_poss_rell">
										<div onClick={ () => this.editProduct(row) }>
											<div className="inline_btns mr-1 mr-lg-2 mb-1 pointer"><i className="fa fa-pencil" aria-hidden="true"></i></div>
										</div>
									</div>
									<div className="col-2 d-flex justify-content-center align-items-center add_poss_rell">
										<div className="main_settings_holder">
											<label className="switch">
												<input type="checkbox" id="sessionSearchable" checked={row['active']}  onChange = {(e)=>{this.deleteRowProducts(row, row['active'])}}/>
												<span className="slider round"></span>
											</label>
											<div className="toggle_option">{row['active'] ? 'ON' : 'OFF'}</div>
										</div>
									</div>
                                    
                                </div>
                                )):''}
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            {/* Coming Soon model  */}
            <div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
                <div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
                    <div className="modal-content bg-transparent">
                        <div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
                            <p className="mb-0 py-4 white text-center">Coming Soon</p>
                        </div>
                        <div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal" onClick={e=>{$("#coming_soon_alert").attr({'style':'display:none'})}}>ok</button></div>
                    </div>
                </div>
            </div>           

			<div className="modal pr-0 py-3" id="product_lst_modal">
				<AddEditProduct 
					state={this.state}
					fetchAttributesList={this.fetchAttributesList}
					updateStateFromChild={this.updateStateFromChild}
					addAttribute={this.addAttribute}
					handleProductList={this.handleProductList}
					saveProductList={this.saveProductList}
				> </AddEditProduct>
			</div>
			
			{/* EDIT PRODUCT SUCCESS POP UP */}
			<div className="modal pr-0" id="editProdSuccess_popup" aria-modal="true">
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
						<div className="py-4 text-light text-center font-20"> {this.state.responseMessage} </div> 	
						
						<div className="text-center">
							<button type="button" 
							className="custom_btn1 mt-3" 
							data-dismiss="modal" 
							onClick={e=>{
								this.setState({
								},()=>{
								$("#editProdSuccess_popup").attr({'style':'display:none'});
							})
							}}>ok</button>
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
	    </div>
    );
  }
}
export default products;
