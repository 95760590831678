import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import { browserHistory } from 'react-router'
import $ from 'jquery';
import FaqMain from '../home/FaqMain';
import SupportContainer from '../home/SupportContainer';

class CustomerSupport extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		mainFaqs: {},
		searchMain: '',
		isFaqComponent: true,
		showSignUp: true,
		accountLogin: '/DashboardLanding',
		supportPageName: 'Customer Support',
		supportPageApi: '/support',
		userTypeFaqs: 2
	};
}
 
componentDidMount(){
	if ( localStorage.getItem('supportDashboard') )
		{
			this.setState({
				showSignUp: false,
				accountLogin: '/participant-dashboard'
			}, () => { localStorage.removeItem('supportDashboard'); });
		}
	else 
		{
			if ( localStorage.getItem('userData') )
				{
					this.setState({
						showSignUp: false,
						accountLogin: '/DashboardLanding'
					}, () => { localStorage.removeItem('supportDashboard'); });
				}
		}		
	
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqs")          
	.then(res => {
		this.setState({mainFaqs: res.data.responseData});
	})
	.catch(err =>{ });
};

openSupportRequest = () => {
	window['openSupport']();
}

openCategory=(i)=>{
	let mainArray = this.state.mainFaqs;
	
	mainArray.all_categories[i]['isOpen'] = !mainArray.all_categories[i]['isOpen'];
	
	if ( !mainArray.all_categories[i]['isOpen'] )
		{
			mainArray.all_categories[i]['chosenQuestion'] = '';
			mainArray.all_categories[i]['chosenAnswer'] = '';
		}
	
	this.setState({mainFaqs: mainArray});
}

openCategoryQuestion=(i, que, ans, video, second_video, pdf)=>{
	let mainArray = this.state.mainFaqs;
	if(que == mainArray.all_categories[i]['chosenQuestion'])
		{
			mainArray.all_categories[i]['chosenAnswer'] = '';
			mainArray.all_categories[i]['chosenQuestion'] = '';
			mainArray.all_categories[i]['chosenVideo'] = '';
			mainArray.all_categories[i]['chosenSecondVideo'] = '';
			mainArray.all_categories[i]['chosenPdf'] = '';
		}
	else
		{
			mainArray.all_categories[i]['chosenQuestion'] = que;
			mainArray.all_categories[i]['chosenAnswer'] = ans;
			mainArray.all_categories[i]['chosenVideo'] = video;
			mainArray.all_categories[i]['chosenSecondVideo'] = second_video;
			mainArray.all_categories[i]['chosenPdf'] = pdf;
		}
	
	
	this.setState({mainFaqs: mainArray});
}

startSearching = (e) => {
	this.setState({[e.target.id]:e.target.value});
	let mainArray = this.state.mainFaqs;
	let searchMain = e.target.value;
	if ( searchMain.length > 0 )
		{
			$.each(mainArray.all_categories, function( a, b ) {
				b['isOpen'] = true;
				b['chosenQuestion'] = '';
				b['chosenAnswer'] = '';
				$.each(mainArray.categoriesWithSubcategories[b['name']], function( a1, b1 ) {
					$.each(mainArray.mainArray[b['name']][b1], function( a2, b2 ) {
						b2['isHidden'] = false;
						if ( b2['question'].toLowerCase().indexOf(searchMain.toLowerCase()) == -1 )
							{
								b2['isHidden'] = true;
							}
					});
				})
			});
		}
	else
		{
			$.each(mainArray.all_categories, function( a, b ) {
				b['isOpen'] = false;
				b['chosenQuestion'] = '';
				b['chosenAnswer'] = '';
				$.each(mainArray.categoriesWithSubcategories[b['name']], function( a1, b1 ) {
					$.each(mainArray.mainArray[b['name']][b1], function( a2, b2 ) {
						b2['isHidden'] = false;
					});
				})
			});
		}
	
	this.setState({mainFaqs: mainArray});
}

closeOpenedQuestiion = (mod) =>
{
	let mainArray = this.state.mainFaqs;
	mainArray.all_categories[mod]['chosenAnswer'] = '';
	mainArray.all_categories[mod]['chosenQuestion'] = ''; 
	mainArray.all_categories[mod]['chosenVideo'] = ''; 
	mainArray.all_categories[mod]['chosenSecondVideo'] = '';
	mainArray.all_categories[mod]['chosenPdf'] = ''; 
	this.setState({
		mainFaqs : mainArray
	});
}

openVideo=(url)=>{
	window.open(url);
}

  render() {
	 return (
		<div>
			<div className="myfaqhere custom-width mx-auto customer_supp_page">
				<div className="outer-container">
					<div className="container-fluid px-3 px-md-5 mb30">
						<div className="slider-header">
							<div className="row">
								<div className="col-md-5 relative">
									<h1 className="logo-text text-center text-md-left pt-4">
										<img src="/images/login-logo.png" className="logo" alt="logo" />
									</h1>
									<div className="for_all_header_faq">
										{ /* <div className="one_header_faq">SOLUTIONS</div> */ }
										{ /* <div className="one_header_faq">PRICING</div> */ }
										{/*  <div className="one_header_faq">COMPANY</div> */ }
										 <div className="one_header_faq lst_header active">CUSTOMER SUPPORT</div>
									</div>
								</div>
								{ this.state.showSignUp &&
									<div className="col-md-7 mt15_mob">
										<div className="d-flex justify-content-md-end justify-content-center align-items-center flex-wrap">
											<p className="pt-4 header-signup-text">Sign Up, It's Free</p>
											<button className="btn btn-primary text-uppercase mr-2 mr-md-3 font-weight-bold radius-8 mt-4" onClick={e=>browserHistory.push("/accesscode")}>Sign Up</button>
											<button className="btn btn-outline-secondary font-weight-bold radius-8 mt-4" onClick={e=>browserHistory.push("/login")}>LOG IN</button>
										</div>
									</div>
								}
								{ !this.state.showSignUp &&
									<div className="col-md-7 mt15_mob">
										<div className="d-flex justify-content-md-end justify-content-center align-items-center flex-wrap">
											<button className="btn btn-primary text-uppercase mr-2 mr-md-3 font-weight-bold radius-8 mt-4" onClick={e=>browserHistory.push(this.state.accountLogin)}>DASHBOARD</button>
										</div>
									</div>
								}
							</div>
						</div>
						<div className="all_bottom_faq"> 
							<div className="for_search_supp">
								{ this.state.showSignUp &&
									<Link to ="/">
										<div>
											<div className="closePopup mainclose_faq"></div>
										</div>
									</Link>
								}
								<div className="search_textsupp">How we can help you?</div>
								<div className="for_inputsupp">
									<div className="flex-grow-1 input_field_container py-0 radius-8 main_search_item">
										<div className="row mx-0 frst-input-row shadow-1 padd_searchh align-items-center radius-8">
											<div className="flex-grow-1 search_header">
												<input type="text" className="form-control search_input_dashboard font14" placeholder="Search" id="searchMain" value={this.state.searchMain} onChange={this.startSearching} />
											</div>
											<div className="right-small-box">
												<img src="/images/search.png" />
											</div>
										</div>
									</div>
								</div>
								{ this.state.searchMain.length > 0 &&
									<div className="faqs_support">
										<div className="faq_container">
											{ this.state.mainFaqs && this.state.mainFaqs.all_categories && this.state.mainFaqs.all_categories.map((item, i_cat) => 
												<div className={"mb20 " + (i_cat === (this.state.mainFaqs.all_categories.length - 1) ? 'mb0' : '')} key={i_cat}>
													<div className="one_category" onClick={ () => { this.openCategory(i_cat) }}>{item.name}
														<div className={"faq_open " + (item.isOpen ? 'faq_close' : '')}></div>
													</div>
													{ this.state.mainFaqs.categoriesWithSubcategories[item.name] && this.state.mainFaqs.categoriesWithSubcategories[item.name].length > 0 && item.isOpen &&
														<div className="faq_sub_container">
															{ this.state.mainFaqs.categoriesWithSubcategories[item.name].map((sub, i) => 
																<div className="subcategory_all" key={i}>{sub}
																	{ this.state.mainFaqs.mainArray[item.name][sub].map((que, i) => 
																		<div key={i}>
																			{ !que.isHidden &&
																				<div className="subcategory_ques" onClick={ () => { this.openCategoryQuestion(i_cat, que.question, que.answer, que.videoUrl, que.secondVideoUrl, que.pdfUrl) }}><span>&#8226;</span> {que.question}</div>
																			}
																		</div>
																	)}
																</div>
															)}
															{ item.chosenQuestion !== '' &&
																<div className="chosen_que_container">
																	<div className="closePopup mainclose_faq" onClick={ e => {this.closeOpenedQuestiion(i_cat)}}></div>
																	<div className="question_title">{item.chosenQuestion}</div>
																	{ item.chosenAnswer !== '' && <pre className="question_answer" dangerouslySetInnerHTML={{__html: item.chosenAnswer}}></pre> }
																	{ item.chosenPdf !== '' && <div className="question_answer video_faq" onClick={ () => { this.openVideo(item.chosenPdf)}}>Open PDF</div> }
																	{ item.chosenVideo !== '' && <div className="question_answer video_faq" onClick={ () => { this.openVideo(item.chosenVideo)}}>Open Video</div> }
																	{ item.chosenSecondVideo !== '' && <div className="question_answer video_faq" onClick={ () => { this.openVideo(item.chosenSecondVideo)}}>Open Second Video</div> }
																</div>
															}
														</div>
													}
													{ this.state.mainFaqs.categoriesWithSubcategories[item.name] && this.state.mainFaqs.categoriesWithSubcategories[item.name].length == 0 && item.isOpen &&
														<div className="empty_faq">No available Questions in {item.name} Category</div>
													}
												</div>
											)}
										</div>
									</div>
								}
							</div>
							<div className="support_opt">
								<div className="square_support_left" onClick={this.openSupportRequest}>
									<div className="support_title">Submit a Request</div>
									<div className="support_image request_img"></div>
								</div>
								<a href="https://calendly.com/brent-platt-virdio/virdio-customer-support-call">
									<div className="square_support_right">
										<div className="support_title">Service Call</div>
										<div className="support_image call_img"></div>
									</div>
								</a>
								<div className="knowlege">Knowlege Base</div>
								<Link to="/faq">
									<div className="square_support_left">
										<div className="support_title">FAQ's</div>
										<div className="support_image faq_img"></div>
									</div>
								</Link>
								<div className="square_support_right d-none">
									<div className="support_title">Guides</div>
									<div className="support_image guides_img"></div>
								</div>
								<div className="footer_title_supp">Contact us</div>
								<div className="footer_subtitle_supp">Virdio's Support Team is available 24/7</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>	
    );
  }
}
export default CustomerSupport;
