import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import axios from "axios";
import $ from 'jquery';
import { browserHistory, Link } from 'react-router'
import { showMainErrorPop } from '../../actions/popups';

import MarketplaceHeader from '../home/MarketplaceHeader';

class Login extends Component {
  constructor(props) {
    super(props);
	
	this.state = {
		imageSelected: false,
		imageUploaded: false
	}
	
  }

  
	componentDidMount() {
		
	}
	
	addUploadFile = () => {
		let file = $('#upFileIDImage')[0].files[0];
		if ( !file ) { return false; }
		
		
		const types = ['image/png', 'image/jpeg', 'image/jpg']
		if ( types.every(type => file.type !== type) ) 
			{
				this.props.showMainErrorPop('Wrong image type. Allowed types are: PNG and JPEG');
				console.log('WRONG TYPE');
			}
		else
		{
			this.setState({imageSelected: true});
		}
	}
	
	applyUploadFile = () => {
		
		let file = $('#upFileIDImage')[0].files[0];
		if ( !file ) { return false; }
		
		let email = $('#emailInputUpload').val();
		if ( !email ) { return false; }
		
		const data = new FormData() 
		data.append('file', file);
		data.append('email', email);

		const types = ['image/png', 'image/jpeg', 'image/jpg']
		if ( types.every(type => file.type !== type) ) 
			{
				this.props.showMainErrorPop('Wrong image type. Allowed types are: PNG and JPEG');
				console.log('WRONG TYPE');
			}
		else
			{
				$('.main_loader').attr({'style':'display:flex'});
				axios.post(process.env.REACT_APP_NAME+"/api/v1/upload/uploadIDImage", data)
				.then((res) => {
						$('.main_loader').hide();
						this.props.showMainErrorPop('Success! iD has been sent to Virdio');
						
						this.setState({imageSelected: false});
				}).catch(err =>{
						console.log(err);
						$('.main_loader').hide();
				})
			}
	}

render() {
return (
      <div>
		<MarketplaceHeader></MarketplaceHeader>
		<div className="uploadFileForm">
			
			<div className="uploadFileFormInside">
				<div className="uploadFileFormInsideTitle">Use the form below to upload your ID document</div>
				
				<div className="one_form_container">
					<div className="form_title">Virdio Account Email</div>
					<div className="relative_holder">
						<input type="text" className="gray_inputs" id="emailInputUpload" value={this.state.emailInputUpload} onChange={(e)=>{ this.setState({emailInputUpload: e.target.value}); }} />
					</div>
				</div>
				
				<div className="immem_file uploadFileFormInsideFile">
					Drag & drop ID OR Press to select ID from a file/image.
					{ this.state.imageSelected && <div><br /><br />Image Selected.</div> }
					
					<input type="file" id="upFileIDImage" name="upFileIDImage" onChange={this.addUploadFile} />
				</div>
				
				{ this.state.imageUploaded && <div>ID has been sent to Virdio</div> }
				
				<div className="uploadImageButton" onClick={this.applyUploadFile}>Upload ID to Virdio</div>
			</div>
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
      </div>
    );
  }
}

export default connect(
  null,
  {
	showMainErrorPop
  }
)(Login);