import React, { Component } from "react";
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';

class SessionFeedFm extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					maxHours: [],
					maxMinutes: [],
					pickedHour: 0,
					pickedMinute: 0,
					pickedStationId: '',
					pickedStationName: '',
					editHour: 0,
					editMinute: 0,
					editStationId: '',
					editIndex: -1,
					maxMinutesFull: 0,
					maxMinutesLastHour: 0,
					highestHour: 0
				}
}

componentDidMount(){
	this.setMaxValues();
}
 
setMaxValues=()=>{
	var sessionHour = this.props.state.sessionHour;
	var sessionMinute = this.props.state.sessionMinute;
	var maxHoursVar = [];
	var maxMinutesVar = [];
	
	var maxMinutesFullVar = (sessionHour * 60) + sessionMinute;
	var minutesFor = 0;
	//if ( sessionHour > 0 ) { minutesFor = 59; }
	//else { minutesFor = maxMinutesFullVar; }
	
	minutesFor = maxMinutesFullVar;

	for ( let i = 0; i <= sessionHour; i++ ) { maxHoursVar.push(parseInt(i)); }
	for ( let i = 0; i <= minutesFor; i++ ) { maxMinutesVar.push(parseInt(i)); }
	
	var maxMinutesLastHourVar = this.timeConvert(maxMinutesFullVar).minutes;
	
	var highestHourVar = Math.max(...maxHoursVar);

	this.setState({
					maxHours: maxHoursVar, 
					maxMinutes: maxMinutesVar, 
					pickedHour: maxHoursVar[0], 
					pickedMinute: maxMinutesVar[0], 
					maxMinutesFull: maxMinutesFullVar, 
					maxMinutesLastHour: maxMinutesLastHourVar,
					highestHour: highestHourVar
				});
}

addStation=()=>{
	var pickedMinutes = this.props.state.sessionFmTimes;
	var chosenMinute = (parseInt(this.state.pickedHour) * 60) + parseInt(this.state.pickedMinute);
	if ( this.props.state.fmMenu !== 'During Class' ) { chosenMinute = -1; }
	
	if ( this.state.pickedStationId == '' )
		{
			this.props.showMainErrorPop('Please pick valid FM Station.');
			return false;
		}
		
	if ( chosenMinute == 0 && this.props.state.fmMenu === 'During Class' )
		{
			this.props.showMainErrorPop('Please pick valid time for FM Station.');
			return false;
		}
		
	if ( pickedMinutes.indexOf(chosenMinute) > -1 )
		{
			this.props.showMainErrorPop('You already picked Station for this time.');
			return false;
		}
		
	var fmStationName = "";
	for ( var i = 0; i < this.props.state.fmStations.length; i++ )
		{
			if ( this.state.pickedStationId == this.props.state.fmStations[i].id )
				{
					fmStationName = this.props.state.fmStations[i].name;
				}
		}
		
	var objectToAdd = {time: chosenMinute, fmStationId: this.state.pickedStationId, fmStationName: fmStationName};
	this.props.addStations(objectToAdd);
	this.setState({pickedHour: 0, pickedMinute: 0, pickedStationId: ''});
	this.setMaxValues();
}

timeConvert=(n)=> {
	var num = n;
	var hours = (num / 60);
	var rhours = Math.floor(hours);
	var minutes = (hours - rhours) * 60;
	var rminutes = Math.round(minutes);
	return { hours: rhours, minutes: rminutes };
}

openEdit=(i)=>{
	var objectToEdit = this.props.state.sessionFmStations[i];
	var timeCon = this.timeConvert(objectToEdit.time);
	this.setState({
		editIndex: i,
		editStationId: objectToEdit.fmStationId,
		editMinute: timeCon.minutes,
		editHour: timeCon.hours
	});
}

closeEdit=()=>{
	this.setState({
		editIndex: -1,
		editStationId: "",
		editMinute: 0,
		editHour: 0
	});
}

editStation=()=>{
	var pickedMinutes = this.props.state.sessionFmTimes;
	var chosenMinute = (parseInt(this.state.editHour) * 60) + parseInt(this.state.editMinute);
	
	if ( chosenMinute == 0 )
		{
			this.props.showMainErrorPop('Please pick valid time for FM Station.');
			return false;
		}
		
	if ( pickedMinutes.indexOf(chosenMinute) > -1 && pickedMinutes.indexOf(chosenMinute) !== this.state.editIndex )
		{
			this.props.showMainErrorPop('You already picked Station for this time.');
			return false;
		}
		
	var fmStationName = "";
	for ( var i = 0; i < this.props.state.fmStations.length; i++ )
		{
			if ( this.state.editStationId == this.props.state.fmStations[i].id )
				{
					fmStationName = this.props.state.fmStations[i].name;
				}
		}
		
	var objectToEdit = {time: chosenMinute, fmStationId: this.state.editStationId, fmStationName: fmStationName};
	this.props.editStation(objectToEdit, this.state.editIndex);
	this.closeEdit();
	this.setMaxValues();
}

changeHour=(e)=>{
	this.setState({[e.target.id]: e.target.value, pickedMinute: 0});
	var minutesVar = 0;

	if ( e.target.value == this.state.highestHour ) { minutesVar = this.state.maxMinutesLastHour; }
	else { minutesVar = 59; }
	
	var maxMinutesVar = [];
	for ( let i = 0; i <= minutesVar; i++ ) { maxMinutesVar.push(parseInt(i)); }
	
	this.setState({ maxMinutes: maxMinutesVar });
}

  render() {
	
    return (
		<div>
			{ /* <div className="creation_menu menu_fm">
				<div className="one_creation_menu"><span className={"menu_create_span " + (this.props.state.fmMenu === 'During Class' ? 'active_creation_menu' : '')} onClick={() => this.props.updateStateFromChild({fmMenu: 'During Class'})}>During Class</span></div>
				<div className="one_creation_menu"><span className={"menu_create_span " + (this.props.state.fmMenu === 'Before Class' ? 'active_creation_menu' : '')} onClick={() => this.props.updateStateFromChild({fmMenu: 'Before Class'})}>Before Class</span></div>
			</div> */ }
			<div className="feed_fm_content">
				{ this.props.state.fmMenu === 'During Class' &&
					<>
						{ /* <div className="feed_fm_selects">
							<label>Hour</label>
							<select className="input-field fields_back" id="pickedHour" value={this.state.pickedHour} onChange={this.changeHour}>
								{ this.state.maxHours.map((row, i) =>
									<option key={i} value={row}>{row}</option>
								)}
							</select>
						</div> */ }
						<div className="feed_fm_selects">
							<label>Minute</label>
							<select className="input-field fields_back" id="pickedMinute" value={this.state.pickedMinute} onChange={(e) => this.setState({[e.target.id]: e.target.value})}>
								{ this.state.maxMinutes.map((row, i) =>
									<option key={i} value={row}>{row}</option>
								)}
							</select>
						</div>
					</>
				}
				<div className="feed_fm_selects">
					<label>Fm Station</label>
					<select className="input-field fields_back" id="pickedStationId" value={this.state.pickedStationId} onChange={(e) => this.setState({[e.target.id]: e.target.value})}>
						<option value=""></option>
						{ this.props.state.fmStations.map((row,i) =>
							<option key={i} value={row.id}>{row.name}</option>
						)}
					</select>
				</div>
				<div className="add_shop add_station" onClick={this.addStation}></div>
			</div>
			{ this.props.state.sessionFmStations.length > 0 &&
				<div>
					<div className="feed_fm_content">
						{ /* <div className="feed_fm_selects">
							<div className="fm_title">Hour</div>
						</div> */ }
						<div className="feed_fm_selects">
							<div className="fm_title">Minute</div>
						</div>
						<div className="feed_fm_selects">
							<div className="fm_title">Station</div>
						</div>
					</div>
					{ this.props.state.sessionFmStations.sort(function(a, b){ if(a.time < b.time) { return -1; } if(a.time > b.time) { return 1; } return 0; }).map((row,i) =>
						<div key={i}>
							<div className="feed_fm_content">
								{ /* <div className="feed_fm_selects">
									<div className="fm_title">{row.time > -1 ? this.timeConvert(row.time).hours : "Before Class"}</div>
								</div> */ }
								<div className="feed_fm_selects">
									<div className="fm_title">{row.time > -1 ? this.timeConvert(row.time).minutes : "Before Class"}</div>
								</div>
								<div className="feed_fm_selects">
									<div className="fm_title">{row.fmStationName}</div>
								</div>
								<div className="feed_buttons">
									<div className="feed_btn" onClick={() => this.openEdit(i)}>
										<i className="fa fa-pencil"></i>
									</div>
									<div className="feed_btn" onClick={() => this.props.removeStation(i)}>
										<i className="fa fa-minus"></i>
									</div>
								</div>
							</div>
							{ this.state.editIndex == i && 
								<div className="feed_fm_content">
									{
										/* <div className="feed_fm_selects">
											{ row.time > -1 &&
												<select className="input-field fields_back" id="editHour" value={this.state.editHour} onChange={(e) => this.setState({[e.target.id]: e.target.value})}>
													{ this.state.maxHours.map((row, i) =>
														<option key={i} value={row}>{row}</option>
													)}
												</select>
											}
										</div> */
									}
									<div className="feed_fm_selects">
										{ row.time > -1 &&
											<select className="input-field fields_back" id="editMinute" value={this.state.editMinute} onChange={(e) => this.setState({[e.target.id]: e.target.value})}>
												{ this.state.maxMinutes.map((row, i) =>
													<option key={i} value={row}>{row}</option>
												)}
											</select>
										}
									</div>
									<div className="feed_fm_selects">
										<select className="input-field fields_back" id="editStationId" value={this.state.editStationId} onChange={(e) => this.setState({[e.target.id]: e.target.value})}>
											{ this.props.state.fmStations.map((row,i) =>
												<option key={i} value={row.id}>{row.name}</option>
											)}
										</select>
									</div>
									<div className="feed_edit_btns">
										<div className="edit_button_equ" onClick={this.closeEdit}>CLOSE</div>
										<div className="edit_button_equ" onClick={this.editStation}>SAVE</div>
									</div>
								</div>
							}
						</div>
					)}
				</div>	
			}
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(SessionFeedFm);