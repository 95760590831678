import React, { Component } from "react";
import $ from 'jquery';
import Login from '../auth/Login';
import axios from "axios";
import { Link } from 'react-router';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";

class AddEditProduct extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		imagePreviewUrl: "/images/browse-image.png",
		shoppingProductName:''
	}
}

componentDidMount(){
	
	if(localStorage.getItem('verifyEmailID'))
		{
			this.setState({emailSign: localStorage.getItem('verifyEmailID') });
		}
  };

componentDidUpdate(prevProps, prevState) {
	
	 if ( this.props.cardsArray && this.props.cardsArray !== this.state.creditCards )
		{
			this.setState({creditCards: this.props.cardsArray});
		}
}

saveProduct = (mod?) => {
	this.setState({
		imagePreviewUrl: "/images/browse-image.png",
		shoppingProductName:''
	});
	this.props.saveProductList(mod);
	this.props.addAttribute();
}


_handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

	reader.readAsDataURL(file)
	
	const data = new FormData() 
	data.append('file', e.target.files[0]);

	const types = ['image/png', 'image/jpeg', 'image/gif']
	if (types.every(type => e.target.files[0].type !== type)) 
		{
			console.log('WRONG TYPE');
		}
	else
		{
			$('.main_loader').attr({'style':'display:flex'});
			
			axios.post(process.env.REACT_APP_NAME+"/api/v1/upload/uploadProductImage", data)
			.then(res => {
					this.props.updateStateFromChild({
						imageName:res.data.responseData.file,
						imagePreviewUrl:res.data.responseData.file
					});
					$('.main_loader').hide();
			}).catch(err =>{
					console.log(err);
					$('.main_loader').hide();
			})
		}	
}
  
 render() {
	 let imagePreviewUrl = this.props.state.imagePreviewUrl === '' ? this.state.imagePreviewUrl : this.props.state.imagePreviewUrl;
	let $imagePreview = null;
		// if (imagePreviewUrl) {
		  $imagePreview = (<div className="form-group mb-0 position-relative"><span className="cover-border"></span>
		  <label className="label">Image</label>
		  <div className="custom-file mb-3">
			<input className="fileInput custom-file-input" type="file" id="customFile_img" onChange={(e)=>this._handleImageChange(e)} />
			  <label className="custom-file-label custom-file-label11 mb-0 input-field position-relative" htmlFor="customFile_img">
			  <img src={imagePreviewUrl} className="browse_image" alt= ''/>
			  </label>
		  </div>                                            
	  </div>);
	 
	 return (
		 <div>
		 
			<div className="modal-dialog large_width modal-dialog-centered">
			<div className="modal-content bg-transparent">
			<div className="modal-body px-4 pb-1 modl_bg_color">
				<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>{
					this.props.updateStateFromChild({
						//responseMessage:"Your Product has been added successfully.",
						shoppingProductName:'',
						addProduct:[],
						imagePreviewUrl:"/images/browse-image.png",
						productInformation:[]
					}, true)
				}} ><span>x</span></button>
				<h4 className="modal-title white">Product List<span>Tap on an attribute to make it active in the Product list</span></h4>
				<div className="card cardbg mt-5">
				<div className="form-group mb-0"><span className="cover-border"></span>
				<label className="label">Name of the Product</label>
				<input type="text" id='shoppingProductName' value={this.props.state.shoppingProductName} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:[e.target.value]})}} className="input-field" />
			</div>
			</div>
			
			<div className="card cardbg">
			<h4 className="white mb-0">Add Attribute</h4>
			<div className="d-flex flex-wrap flex-lg-nowrap">
				<div className="d-flex flex-wrap flex_base align-self-start mt-2">
					{this.props.state.addProduct.length>0?
					(this.props.state.addProduct[0].attributes.map((row,i)=>
				// {row.attributes.map((rows,l)=>
					<Link to="HostSessionCreation" key={i} id ={i} name={row.attrKey} onClick = {this.props.addAttribute} className={(row.status?"btn btn-primary":"")+" btn btn-outline-secondary text-uppercase mt-2 mr-2"} >{row.attrKey}</Link>
				// )}
					)):''}
				</div>
				<div className="flex_base1 mt-3">
					{$imagePreview}
				</div>
			</div>
			<div className="border_bottom_dotted mt-3"></div>
			</div>
			<div className="card cardbg mt-3">
			<div className="row rowWithOverflow">
			{this.props.state.productInformation.length>0?
			(this.props.state.productInformation.map((row,i)=>
			<div className="col-md-4" key={i}>
				<div className="form-group mb-0"><span className="cover-border"></span>
					<label className="label">{row.attrKey}</label>
					<input type="text" 
					id ={i}
					value={row.attrValue}
					onChange={this.props.handleProductList(i)}
					className="input-field" />
				</div>
				{/* {this.validator.message(row.attrKey, row.attrValue, this.state.test1+'|'+this.state.test2)} */}
			</div>
			)):''}
			</div>
			</div>
			</div>
			<div className="text-center">
				{/* <button type="button" className="done mb-5 mt-2 mr-3">Preview</button> */}
				{/* <button type="button" data-toggle="modal" data-target="#audio_video_mdl" onClick= {this.state.saveProductList} className="done mt-4 mt-2">save</button> */}
				{/* image upload */}
				<div>
				{/* <input className="fileInput" 
				type="file" 
				onChange={(e)=>this._handleImageChange(e)} />ref={s => (this._session_details = s)}*/}
				
				</div>
				{/* image upload */}
				{
					this.props.state.productAdd &&
					<button type="button"  data-dismiss="modal"  onClick={ () => this.saveProduct()} className="custom_btn1 mt-3 mt-2">Add Product</button>
				}
				{
					this.props.state.productEdit &&
					<button type="button"  data-dismiss="modal"  onClick={ () => this.saveProduct('edit') } className="custom_btn1 mt-3 mt-2">Update Product</button>
				}
				{/* <input type="submit" name="submit" value={this.saveProductList}/>                     */}
				</div>
			</div>
			</div> 
			
		</div>
    );
  }
}
export default AddEditProduct;
