import { TOOGLE_POPUP, TOOGLE_PLAYLIST_POPUP,SHOW_MAIN_ERROR } from "./types";

export const togglePopup = (is_opened, finishState) => ({
  type: TOOGLE_POPUP,
  payload: { finish: finishState, isOpen: is_opened }
});

export const togglePlaylistPopup = is_opened => ({
  type: TOOGLE_PLAYLIST_POPUP,
  payload: { isOpen: is_opened } 
});

export const showMainErrorPop = (err, align, callokf) => ({
	type: SHOW_MAIN_ERROR,
	payload: { msg: err, align:align, callokf: callokf }
});