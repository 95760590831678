import React, { Component } from "react";
import DatePicker from "react-datepicker";
class PrivacyPolicy extends Component {
  
  constructor(props) {
	super(props);
	    
}
 
componentDidMount(){
	

  };
 render() {
	 return (
	<div>
		<div id="root">
			<div className="App">
				<div className="container-fluid custom-width">
					<div className="top-header px-3 px-md-5 pt-5">
						<div className="">
							<img src="images/login-logo.png" className="logo" />
						</div>
						<div className="privacy_banner position-relative">
							<img src="images/banner.png" alt="privacy-policy" className="w-100" />
							{/* <div className="banner_text">
								<h2 className="text-center mb-0">Privacy</h2>
								<h3 className="text-center mb-0">Your provacy is important to us</h3>
							</div> */}
						</div>                                       
					</div>
					<div className="background_gray px-3 px-md-5 pt-4 pb-4">
						<div className="content-privacy pt-4">
							<h3 className="purple_text font-32 text-center font-book mb-4">Privacy Policy</h3>
							<h3 className="white"><u>Effective: December 1st , 2019 </u></h3>
							<p className="text-white">Virdio Inc. ("Virdio") is committed to protecting your privacy and ensuring you have a positive experience on our website and in using our products and services (collectively, "Products"). This policy covers the Virdio website, mobile applications, and desktop clients and is applicable worldwide. <br /><br />
							This policy outlines our handling practices and how we collect and use the Personal Data you provide during your online and offline interactions with us. As used in this Privacy Policy, "Personal Data" means any information that can be used to individually identify a person, and may include, but is not limited to, name, email address, postal or other physical address, credit or debit card number, title, and other personally identifiable information. <br /><br />
							If you reside in the European Union ("EU"), United Kingdom, Lichtenstein, Norway, Iceland or Switzerland, you may have additional rights with respect to your Personal Data, as further outlined below. These rights may include rights under the EU's General Data Protection Regulation ("GDPR"), if you are a resident of the EU, United Kingdom, Lichtenstein, Norway or Iceland. <br /><br />
							Virdio Inc. will be the Controller of your Personal Data that is provided, collected and/or processed pursuant to this Privacy Policy in order to, for example, respond to requests for information or service, conclude a business transaction, provision the service, or otherwise for Virdio's business purposes. Virdio will be a Processor of data that is entered into the service when a Customer uses the Services (see "User-Generated Information", below.). If you have any questions about whether any of foregoing applies to you, please contact us using the information set forth in the "Contact Us" section below. <br />
							This policy may be updated from time to time for reasons such as operational practices or regulatory changes, so we recommend that you review our Privacy Policy when returning to our website. If we propose to make any material changes, we will notify you by means of a notice on this page prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices. </p>
							<h3 className="white"><u>Collection of your Personal Data</u></h3>
							<p className=""><b>We may collect, either as Controller or Processor, the following categories of Personal Data about you<br /> when you use or otherwise interact with our Service: </b></p>
							<ul>
								<li>Name</li>
								<li>Title</li>
								<li>Email Address</li>
								<li>Home/work/mobile telephone number </li>
								<li>Postal or other physical address </li>
								<li>Credit/debit card information </li>
								<li>IP addresses and other information collected passively, as further detailed in the "Passive Collection" section below</li>
								<li>Device identifiers, as further described in the "Mobile Application" section below</li>
								<li>Our servers automatically record certain information when you use the Service, including your IP address, operating system type and version, client version, IP addresses along the network path, and the MAC address of your internet connection("Host Information")</li>
								<li>We also automatically record information about your usage of the Service, including actions taken, date and time, frequency, duration, quantity, quality, network connectivity, and performance information related to logins, clicks, messages, contacts, content shared, calls, use of video and screen sharing, meetings, cloud recording, and other feature usage information ("Usage Information")</li>
								<li>Other information you upload, provide, or create while using the Service ("User-Generated Information"), as further detailed in the "User Generated Information" section below</li>
							</ul>
							<h3 className="white"><u>User-Generated Information </u></h3>
							<p className="">We collect and retain, generally as a Processor and in order to provide the Services, Personal Data and other information you upload, provide, or create while using the Service ("User-Generated Information"), including information related to:</p>
							<ul>
								<li><b>Classes:</b> Class title, invitation content, members, class link, date, time and duration. We collect activity recorded in the class (such as joining or leaving), including activity related to third-party integrations, together with the date, time, person engaged in the activity, and other members in the class with the date, time, duration, and quality ratings that you provide. We route audio and video call content and screen sharing content between the instructor and members, but we do not retain or store the content unless cloud recording is used.</li>
								<li><b>Messages:</b> Message content, sender and recipients, date, time, and read receipts. Content shared: Files and file names, sizes, and types</li>
								<li><b>Status:</b> Status information, for example about whether and when you are active or have turned on Busy. You can choose whether or not to share status information with other users.</li>
							</ul>
							<h3 className="white"><u>Passive Collection</u></h3>
							<p className="">Virdio and our third-party service providers automatically collect some information about you when you use our Products, using methods such as cookies and tracking technologies (further described below). Information automatically collected includes Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, the files viewed on our site (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, and/or clickstream data to analyze trends in the aggregate and administer the website and/or Products.<br  />

							In addition, when you use some of our Products, network information is transmitted back to us such as Product usage information. This information is transmitted back to us, so we can determine how users are interacting with our Products, to assist us with improving our Products, and to correct any problems that may occur. </p>

							<h3 className="white"><u>Mobile Application </u></h3>
							<p className="">When you download and use our Products, we automatically collect information on the type of device you use, operating system version, and the device identifier (or “UDID”). We send you push notifications from time-to-time in order to update you about any events or promotions that we may be running. If you no longer wish to receive these types of communications, you can turn them off at the device level. To ensure you receive proper notifications, we will need to collect certain information about your device such as operating system and user identification information. </p>

							<h3 className="white"><u>Processing of your Personal Data</u></h3>
							<p className="">We will use your Personal Data only in accordance with our Privacy Policy. If you do not wish us to continue using your Personal Data in this manner, you can request that your account be deactivated by contacting us as specified in the "Contact Us" section.<br  /><br  />

							We will only process your Personal Data if we have a lawful basis for doing so. Lawful bases for processing include consent, contractual necessity (i.e. processing that is necessary for the performance of a contract with you, such as your user agreement with us that allows us to provide you with the Products) and our "legitimate category" or the legitimate category of others (e.g. our users).</p>
							<h3 className="white"><u>We process Personal Data when you use our website, desktop client and mobile applications to use or sign-up to use our Service for purposes such as: </u></h3>
							<ul>
								<li>Account configuration</li>
								<li>Account maintenance</li>
								<li>Enabling classes between users and third-party members</li>
								<li>Hosting and storing personal data from classes (only to provide the Service) </li>
								<li>Personalizing, improving or operating our Service and business </li>
								<li>Fulfilling requests you make related to the Service </li>
								<li>Protecting, investigating and deterring against fraudulent, harmful, unauthorized or illegal activity </li>
								<li>Providing reports based on information collected from use of our Service  </li>
								<li>Processing your orders and deliver the Service that you have ordered </li>
								<li>Providing support and assistance for our Service </li>
								<li>Providing the ability to create personal profile areas and view protected content </li>
								<li>Providing the ability to contact you and provide you with shipping and billing information </li>
								<li>Providing customer feedback and support</li>
								<li>Complying with our contractual and legal obligations, resolving disputes with users, enforcing our agreements</li>
							</ul>
							<h3 className="white"><u>We process Personal Data when you visit our website to: </u></h3>
							<ul>
								<li>To keep you up to date on the latest Product announcements, software updates, software upgrades, system enhancements, special offers, and other information </li>
								<li>To provide customer feedback and support <a href="mailto:support@virdio.com"><u>support@virdio.com</u></a>  </li>
								<li>To provide and administer opt-in contests, sweepstakes or other marketing or promotional activities on the Virdio.com or affiliate websites </li>
								<li>Providing you with information and offers from us or third parties</li>
								<li>To the extent you choose to participate, to conduct questionnaires and surveys in order to provide better products and services to our customers and end users</li>
								<li>To personalize marketing communications and website content based on your preferences, such as in response to your request for specific information on products and services that may be of category </li>
								<li>To contact individuals that you refer to us and identify you as the source of the referral, in accordance with the "Referral" section below </li>						
							</ul>
							<h3 className="white"><u>Referrals</u></h3>
							<p className="">If you choose to use our referral service to tell a friend about our products, you represent that you have their consent to provide us your friend's name and email address. We will automatically send your friend a one-time email inviting him or her to visit the website. Unless we are authorized by your friend, we will only use your friend's name and email address for the purposes of sending this one-time email and maintaining an activity log of our referral program.</p>

							<h3 className="white"><u>Choice</u></h3>
							<p className="">You can choose whether to provide Personal Data to Virdio but note that you may be unable to access certain options, offers, and services if they require Personal Data that you have not provided. You can sign-up, and therefore consent, to receive email or newsletter communications from us. If you would like to discontinue receiving these communications, you can update your preferences by using the "Unsubscribe" link found in such emails or by contacting us using the information in the "Contact Us" section of this policy.</p>

							<h3 className="white"><u>Data Subject Rights </u></h3>
							<p className="">You have certain rights with respect to your Personal Data as set forth below. Please note that in some circumstances, we may not be able to fully comply with your requests, or we may ask you to provide us with additional information in connection with your request, which may be Personal Data, for example, if we need to verify your identity or the nature of your request. In such situations, however, we will still respond to let you know of our decision. To make any of the following requests, contact us using the contact details referred to in the "Contact Us" section of this policy.</p>
							<ul>
								<li><b>Access:</b> You can request more information about the Personal Data we hold about you. You can also request a copy of the Personal Data. </li>
								<li><b>Rectification:</b> If you believe that any Personal Data we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data. You can also correct some of this information directly by logging into your service account. Please contact us as soon as possible upon noticing any such inaccuracy or incompleteness.</li>
								<li><b>Objection:</b> You can contact us to let us know that you object to the collection or use of your Personal Data for certain purposes.</li>
								<li><b>Erasure:</b> You can request that we erase some or all of your Personal Data from our systems. Data subjects who want their data deleted can do so by deactivating their account. Deactivation will delete all account usage and related information. If you are not a Virdio customer and would like your data deleted, please contact the data Controller directly about deleting information.</li>
								<li><b>Restriction of Processing:</b> You can ask us to restrict further processing of your Personal Data.</li>					
								<li><b>Portability:</b> You have the right to ask for a copy of your Personal Data in a machine-readable format. You can also request that we transmit the data to another entity where technically feasible.</li>
								<li><b>Withdrawal of Consent:</b> If we are processing your Personal Data based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal Data, if such use or disclosure is necessary to enable you to utilize some or all of our Products. </li>
								<li><b>Right to File Complaint:</b> You have the right to lodge a complaint about Virdio's practices with respect to your Personal Data with the supervisory authority of your country or EU Member State.</li>
							</ul>
							<p className=""><b>Under certain circumstances we will not be able to fulfill your request, such as if it interferes with our regulatory obligations, affects legal matters, we cannot verify your identity, or it involves disproportionate cost or effort, but in any event we will respond to your request within a reasonable timeframe and provide you an explanation. In order to make such a request of us, please contact our Privacy Team at <a href="mailto:support@virdio.com"><u>support@virdio.com</u></a>. </b></p>
							<h3 className="white"><u>Data Retention </u></h3>
							<p className="">How long we retain your Personal Data depends on the type of data and the purpose for which we process the data. We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Notice unless a longer retention period is required or permitted by law.</p>

							<h3 className="white"><u>Cookies and Tracking Technologies </u></h3>
							<p className="">Virdio and our partners use cookies or similar technologies to analyze trends, administer the website, track users’ movements around the website, and gather information about our user base, such as location information based on IP addresses. Users can control the use of cookies at the individual browser level. <br /><br />
							Your browser may offer you a "Do Not Track" option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities over time and across different websites. Our Products do not support Do Not Track requests at this time, which means that we collect information about your online activity both while you are using the Products and after you leave our properties. </p>

							<h3 className="white"><u>Geolocation</u></h3>
							<p className="">We collect information about where you are located when you are using our Products. We use this information for purposes such as optimizing your connection to our data center, supporting compliance, and suggesting customizations to your experience with our Products (e.g. your language preference). </p>
							<h3 className="white"><u>Sharing your Personal Data </u></h3>
							<p className="">We do not sell or rent your Personal Data to third parties for any purposes, including marketing. <br />
							We share Personal Data within Virdio and its affiliated companies, and with third party service providers for purposes of data processing or storage.  <br /><br />
							We also share Personal Data with business partners, service vendors and/or authorized third-party agents or contractors in order to provide requested Products or transactions, including processing orders, processing credit card transactions, hosting websites, hosting event and seminar registration and providing customer support. We provide these third parties with Personal to complete/utilize the requested Product or transaction. <br /><br />
							In some cases, we may choose to buy or sell assets. In these types of transactions, user information is typically one of the transferred business assets. Moreover, if we, or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of us or our assets may continue to use your Personal Data as set forth in this policy. As required by law, we may respond to subpoenas, court orders, or similar legal process by disclosing your Personal Data and other related information, if necessary. We also may use Personal Data and other related information to establish or exercise our legal rights or defend against legal claims. We collect and possibly share Personal Data and any other additional information available to us in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Virdio's terms of service, or as otherwise required by law. <br /><br />
							While navigating Virdio's website or Products, you may be directed to content or functionality that is hosted by a third-party. When information is collected on behalf of Virdio exclusively, Virdio's Privacy Policy will be referenced and will govern how your information is used. For other, non-Virdio sites or services, the site/service owner's privacy policy will be referenced. You should review such third party's privacy and security policies prior to use.
						</p>
						<h3 className="white"><u>Security of your Personal Data </u></h3>
							<p className="">Virdio is committed to protecting the Personal Data you share with us. We utilize a combination of industry-standard security technologies, procedures, and organizational measures to help protect your Personal Data from unauthorized access, use or disclosure. When we transfer credit card information over the Internet, we protect it using Transport Layer Security (TLS) encryption technology. <br /><br />
							We recommend you take every precaution in protecting your Personal Data when you are on the Internet. For example, change your passwords often, use a combination of upper and lower-case letters, numbers, and symbols when creating passwords, and make sure you use a secure browser. If you have any questions about the security of your Personal Data, you can contact us at <a href="mailto:support@virdio.com"><u>support@virdio.com</u></a>.</p>

							<h3 className="white"><u>Linked websites and third-party services </u></h3>
							<p className="">Our websites and services may provide links to other third-party websites and services which are outside our control and not covered by this policy. We encourage you to review the privacy policies posted on these (and all) sites you visit or services you use. </p>

							<h3 className="white"><u>Newsgroups/forums </u></h3>
							<p className="">If you participate in a Virdio discussion forum or chat room, you should be aware that the information you provide there will be made broadly available to others, potentially inside or outside Virdio, who have access to that discussion forum or chat room. Also, please recognize that individual forums and chat rooms may have additional rules and conditions. Each member's opinion on a forum or chat room is his or her own and should not be considered as reflecting the opinion of Virdio.</p>

							<h3 className="white"><u>Class Recordings </u></h3>
							<p className="">If you use a feature of the Products that allows for Recordings (defined below), we collect information from you that you provide in connection with such use and through such Recordings, to the extent you provide it to us. This information may include Personal Data, if you provide us with Personal Data. </p>
							<p className=""><b>For Instructor - </b>By installing or using Virdio software or any other Virdio software, tool or feature that allows for class recording (each, a "Recording"), you acknowledge and agree that you and your company are responsible for:</p>
							<p className=""><span className="mr-3">(a)</span>clearly notifying all individuals (whether or not they are Virdio users) who are present in a class (whether in-person or remotely) (each, an "Attendee") for which you make a Recording that such class might be recorded, and</p>
							<p className=""><span className="mr-3">(b)</span>obtaining any consent necessary for such a recording as required by applicable laws, rules and regulations, including without limitation data privacy laws. By making a Recording of any class using any Virdio Products, you and your company represent and warrant that</p>
							<p className=""><span className="mr-3">(c)</span>you have received legally sufficient consent from all Attendees for such recording prior to starting a Recording, </p>
							<p className=""><span className="mr-3">(d)</span>you will only make, use, store and otherwise process such Recording in accordance with all applicable laws, rules and regulations, including data protection laws and</p>
							<p className=""><span className="mr-3">(e)</span>you will store and otherwise restrict access to such Recordings using appropriate technical and organizational safeguards.<br/><br/><b>Any person and/or entity who makes a Recording of a class shall be the data controller of that Recording, and Virdio will be the data processor with respect to the Recording.</b></p>
							<p className=""><b>For Members - </b>Please be advised that for any classes you attend, Recordings can be enabled by a instructor. By signing up for Virdio's services or otherwise using them in any way, including without limitation by attending any Virdio class, you expressly acknowledge that Virdio may, if instructed by a meeting instructor, make and store Recordings for Virdio classes, and may make such recordings available to instructors and other members at the direction of the instructor. </p>
							<h3 className="white"><u>Transfer and Storage of Personal Data</u></h3>
							<p className="">Our Products are hosted and operated in the United States ("U.S.") through Virdio and its service providers. We may transfer your Personal Data to the U.S., to any Virdio affiliate worldwide, or to third parties acting on our behalf for the purposes of processing or storage. By using any of our Products or providing any Personal Data for any of the purposes stated above, you consent to the transfer and storage of your Personal Data, whether provided by you or obtained through a third party, to the U.S. as set forth herein, including the hosting of such Personal Data on U.S. servers.</p>
							<h3 className="white"><u>EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield</u></h3>
							<p>Virdio participates in and has certified its compliance with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield. Virdio is committed to subjecting all Personal Data received from EU member countries, Switzerland, and the United Kingdom, in reliance on the Privacy Shield Frameworks, to the Framework's applicable Principles. To learn more about the Privacy Shield Frameworks, and to view our certification, visit the U.S. Department of Commerce's Privacy Shield List, <a href="https://www.privacyshield.gov/list" target="_blank">https://www.privacyshield.gov/list</a>. <br /><br />
							Virdio is responsible for the processing of Personal Data it receives, under the Privacy Shield Framework, and subsequently transfers to a third party acting as an agent on its behalf. Virdio complies with the Privacy Shield Principles for all onward transfers of Personal Data from the EU, Switzerland, and the United Kingdom including the onward transfer liability provisions. With respect to Personal Data received or transferred pursuant to the Privacy Shield Frameworks, Virdio is subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, Virdio may be required to disclose Personal Data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. <br /><br />
							If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third party dispute resolution provider (free of charge) at <a  href="https://feedback-form.truste.com/watchdog/request" target="_blank">https://feedback-form.truste.com/watchdog/request</a>.<br /><br />
							Under certain conditions, more fully described on the Privacy Shield website<br />
							<a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint" target="_blank">https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint</a>, <br />
							you can invoke binding arbitration when other dispute resolution procedures have been exhausted. </p>
							<h3 className="white"><u>Standard Contractual Clause</u></h3>
							<p>In certain cases, Virdio will transfer Personal Data from the EU in accordance with the European Commission-approved Standard Contractual Clauses, a copy of which can be obtained at <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32010D0087" target="_blank">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32010D0087.</a></p>
							<h3 className="white"><u>Contact Us </u></h3>
							<p className="">To address your individual rights or if you have any privacy-related questions or comments related to this privacy policy, please send an email to <a href="mailto:support@virdio.com"><u>support@virdio.com</u></a>. You can also contact us by writing to this address:</p>
							<h4 className="white font-18 mb-3">
							Virdio Inc.<br />

							Attention: Data Privacy Officer<br />

							901 North Glebe Road, 5th floor <br />

							Arlington, VA 22203 <br />
							</h4>
							<p className="">If you reside in the EU, United Kingdom, Lichtenstein, Norway or Iceland, you can also contact us at <a href="mailto:support@virdio.com"><u>support@virdio.com</u></a></p>
							<p><i>California Privacy Rights</i>: Under California Civil Code sections 1798.83-1798.84, California residents are entitled to ask us for a notice identifying the categories of personal customer information which we share with our affiliates and/or third parties for marketing purposes and providing contact information for such affiliates and/or third parties. If you are a California resident and would like a copy of this notice, please submit a written request the address above. 
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>	
	</div>	
    );
  }
}
export default PrivacyPolicy;
