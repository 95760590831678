import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import Calendar from 'react-calendar';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import moment from 'moment';

class ReportsInstructorCodes extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					instructorCode: '',
					instructorFirstName: '',
					instructorLastName: '',
					instructorEmail: '',
					errorInstructorCode: false,
					errorInstructorFirstName: false,
					errorInstructorLastName: false,
					errorInstructorEmail: false,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					ajaxLoaded: false,
					checkInfoOpen: false
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	document.addEventListener('mousedown', this.checkInfoOpen);
}
 
componentWillUnmount() {
	document.removeEventListener('mousedown', this.checkInfoOpen);
}

saveInstructorCode=(e)=>{
	let mainthis = this;
	this.setState({
			errorInstructorCode: false,
			errorInstructorFirstName: false,
			errorInstructorLastName: false,
			errorInstructorEmail: false,
		});
	
	if ( this.state.instructorCode == '' )
		{
			this.setState({
				errorInstructorCode: true
			});
			return false;
		}
	else
		{
			this.setState({
				errorInstructorCode: false
			});
		}
		
	if ( this.state.instructorFirstName == '' )
		{
			this.setState({
				errorInstructorFirstName: true
			});
			return false;
		}
		
	if ( this.state.instructorLastName == '' )
		{
			this.setState({
				errorInstructorLastName: true
			});
			return false;
		}
		
	if ( this.state.instructorEmail == '' || !this.validateEmail(this.state.instructorEmail))
		{
			this.setState({
				errorInstructorEmail: true
			});
			return false;
		}
	
    const data ={
			instructorCode: this.state.instructorCode,
			instructorFirstName: this.state.instructorFirstName,
			instructorLastName: this.state.instructorLastName,
			instructorEmail: this.state.instructorEmail,
		}
		
	$('.main_loader').attr({'style':'display:flex'}); 
    
    axios.post(process.env.REACT_APP_NAME+"/api/v1/user/saveInstructorCode",data)
    .then(res => {
		$('.main_loader').hide();
		mainthis.fetchSessionDetails(mainthis.state.page_id, mainthis.state.page_num_count);
		this.setState({
				instructorCode: '',
				instructorFirstName: '',
				instructorLastName: '',
				instructorEmail: ''
			});
	   if ( res.data && res.data.responseMessage && res.data.responseMessage == 'success' )
		{
			this.props.showMainErrorPop('Data successfully saved.');
		}
	}).catch(err =>{
			$('.main_loader').hide();
			
			this.props.showMainErrorPop(err.response.data.errorData);
        });
  }
  
 validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

checkInfoOpen = (e) => {
	if(e.target !== "info_btn" && $(e.target).closest('.info_btn').length === 0 )
		{
			this.setState({ checkInfoOpen: false });
		}
}

changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchSessionDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	console.log(mod);
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchSessionDetails(mod, this.state.page_num_count);
		}
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ showSearch: !this.state.showSearch, searchBy: '' });
			
			this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchSessionDetails(pg, this.state.page_num_count); 
}

fetchSessionDetails = (page_id, countId, searchText = this.state.searchBy) => {
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});
  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getInstructorCodes", { page_id: page_id, countId: countId, searchText: searchText})          
  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({billing_info: res.data.responseData['codes'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	if( (this.state.page_id+6) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			 
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className="container-fluid payinfo_main_hold">
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="top_pay_info chPad_topbot">
									<div className="w20history">
										<div className="form-group">										
											  <label className="label">Code</label>														
											  <input type="text" 
												className={(this.state.errorInstructorCode ? "input-field red-outline2" : "input-field" )} 
												id="instructorCode" 
												value= {this.state.instructorCode} onChange = {(e)=>this.setState({[e.target.id] : e.target.value})} placeholder="Code" maxLength="30" />
										  </div>
									  </div>
									  <div className="w20history">
										  <div className="form-group">										
											  <label className="label">First Name</label>														
											  <input type="text" 
												className={(this.state.errorInstructorFirstName ? "input-field red-outline2" : "input-field" )}  
												id="instructorFirstName" 
												value= {this.state.instructorFirstName} onChange = {(e)=>this.setState({[e.target.id] : e.target.value})} placeholder="First Name" maxLength="30" />
										  </div>
									  </div>
									  <div className="w20history">
										  <div className="form-group">										
											  <label className="label">Last Name</label>														
											  <input type="text" 
												className={(this.state.errorInstructorLastName ? "input-field red-outline2" : "input-field" )}  
												id="instructorLastName" 
												value= {this.state.instructorLastName} onChange = {(e)=>this.setState({[e.target.id] : e.target.value})} placeholder="Last Name" maxLength="30" />
										  </div>
									  </div>
									  <div className="w20history">
										  <div className="form-group">										
											  <label className="label">Email</label>														
											  <input type="text" 
												className={(this.state.errorInstructorEmail ? "input-field red-outline2" : "input-field" )}  
												id="instructorEmail" 
												value= {this.state.instructorEmail} onChange = {(e)=>this.setState({[e.target.id] : e.target.value})} placeholder="Email" maxLength="30" />
										  </div>
									  </div>
									<div className="w20history">
										<div className="inner_w_demand">
											<div className="text-center">
												<button type="button" className="saveButton" onClick={this.saveInstructorCode}>Save</button>
											</div>
										</div>
									</div>
								</div>
								<div className="pay_head">
									<div className="pay_head_title">Codes</div>
								</div>
								<div className="show_pay_page">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
											<option value="100000">All</option>
										</select>
									 </span>
									Codes
								</div>
								<div className="show_search_opt">
									
									{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-4' : ' col-xl-6')}>
														<input type="text" className="form-control" placeholder="Search by email, first name or last name" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
													</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
									
									
									
								</div>
								{ this.state.billing_info.length > 0 && 
									<table className="billing_table">
										<thead>
											<tr>
												<th>Code</th>
												<th>Instructor</th>
												<th>Email</th>
											</tr>
										</thead>
										<tbody> 
											{ this.state.billing_info.map((item, key) =>
												<tr key={key}>
													<td>{item['code']}</td>
													<td>{item['firstName']} {item['lastName']}</td>
													<td>{item['email']}</td>
												</tr>
											
											) }
										</tbody>
									</table>
								}
								{ this.state.billing_info.length > 0 && 
									<div>
										<div className="showPageNavigation">
											{ this.state.page_num_count != 100000 ?
												<div className="showpinfo">Showing { this.state.page_id+1 } to { this.state.page_num_count } of { this.state.fullCount } Codes</div>
												:
												<div className="showpinfo">Showing { this.state.fullCount } Codes</div>
											}
											<div className="shopnumbers">
												
												{ (this.state.page_id - 1) > -1 &&
													<span onClick={(e) => this.changePage(e, this.state.page_id - 1)}>Previous</span>
												}
												
												<span>
													{ this.state.page_numbers.map((nitem, nindex) => 
														<span key={nindex} onClick={(e) => this.changePage(e, nitem-1)} className={"pgNumbers " + (this.state.page_id+1 === nitem ? 'active' : '')}>{nitem}</span>	
													)}
												</span>
												
												 { (this.state.page_id + 1) < this.state.maxPages &&
													<span onClick={(e) => this.changePage(e, this.state.page_id + 1)}>Next</span>
		
												 }
												</div>
										</div>
										<div className={ this.state.checkInfoOpen ? 'mob_pad_b250' : ''}></div>
									</div>
								}
								{ this.state.billing_info.length == 0 && this.state.ajaxLoaded && <div className="noResults2">No Codes Found</div> }
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ReportsInstructorCodes);