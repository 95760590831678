import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import moment1 from 'moment-timezone';

import SimpleReactValidator from 'simple-react-validator';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import {  browserHistory } from 'react-router'
import Countdown from 'react-countdown-now';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import Header from './Header';
import ReactLightCalendar from '@lls/react-light-calendar';
import '@lls/react-light-calendar/dist/index.css';
import SupportContainer from '../home/SupportContainer';
import D2cliveclasses from '../home/D2cliveclasses';
import D2condemand from '../home/D2condemand';
import D2cinstructor from '../home/D2cinstructor';
import D2cmember from '../home/D2cmember';
import D2cinstructorTracking from '../home/D2cinstructorTracking';
import MemberPendingPayments from '../home/MemberPendingPayments';
import PaymentsTransactions from '../home/PaymentsTransactions';
import D2cadditionallive from '../home/D2cadditionallive';
import D2cadditionalondemand from '../home/D2cadditionalondemand';
import D2condemandreport from '../home/D2condemandreport';
import MembersTrackingList from '../home/MembersTrackingList';
import ChallengeReports from '../home/ChallengeReports';

class AdminReports extends Component {
constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state = {
		supportPageName: 'Admin Reports',
		supportPageApi: '/AdminReports',
		userTypeFaqs: 2,
		d2clive: false,
		d2condemand: false,
		d2cinstructor: false,
		d2cmember: false,
		d2cinstructortracking: false,
		dashboardActivity: true,
		detailedReports: false,
		sumaryReports: false,
		d2cMemberPayment: false,
		paymentTransactions: false,
		additionalLive: false,
		additionalOnDemand: false,
		ondemandreport: false,
		memberTracking: true,
		squatChallenge: false,		
	}
}

componentDidMount() {
	document.addEventListener('mousedown', this.handleClickOutside);
	
	if ( localStorage['userData'] )
		{
			let userData = JSON.parse(localStorage.getItem('userData'));
			if ( userData.data && userData.data.responseData && userData.data.responseData.adminReports != 1 )
				{
					browserHistory.push("/DashboardLanding");
				}
		}
	else
		{
			browserHistory.push("/login");
		}
		
}

componentWillUnmount() {
	document.removeEventListener('mousedown', this.handleClickOutside);
}

handleClickOutside = (e) => {
	if(e.target !== "info_btn" && $(e.target).closest('.info_btn').length === 0 )
		{
			$('.all_info').removeClass('active');
		}
}

changeReportsComp=(e)=>{
	this.setState({
	});
	
	if(e == 1)
		{
			this.setState({
				dashboardActivity: true,
				d2clive: false,
				d2condemand: false,
				d2cinstructor: false,
				d2cmember: false,
				d2cinstructortracking: false,
				detailedReports: false,
				sumaryReports: false,
				d2cMemberPayment: false,
				paymentTransactions: false,
				squatChallenge: false,
				additionalLive: false,
				additionalOnDemand: false,
				ondemandreport: false,
				memberTracking: true,
			});
		}
	
	else if(e == 2)
		{
			this.setState({
				dashboardActivity: false,
				d2cmember: true,
				d2clive: false,
				d2condemand: false,
				d2cinstructor: false,
				d2cinstructortracking: false,
				detailedReports: true,
				sumaryReports: false,
				d2cMemberPayment: false,
				paymentTransactions: false,
				squatChallenge: false,
				additionalLive: false,
				additionalOnDemand: false,
				ondemandreport: false,
				memberTracking: false,
			});
		}
		
	else if(e == 3)
		{
			this.setState({
				dashboardActivity: false,
				d2clive: true,
				d2condemand: false,
				d2cinstructor: false,
				d2cmember: false,
				d2cinstructortracking: false,
				detailedReports: true,
				sumaryReports: false,
				d2cMemberPayment: false,
				paymentTransactions: false,
				squatChallenge: false,
				additionalLive: false,
				additionalOnDemand: false,
				ondemandreport: false,
				memberTracking: false,
			});
		}
		
	else if(e == 4)
		{
			this.setState({
				dashboardActivity: false,
				d2clive: false,
				d2condemand: true,
				d2cinstructor: false,
				d2cmember: false,
				d2cinstructortracking: false,
				detailedReports: true,
				sumaryReports: false,
				d2cMemberPayment: false,
				paymentTransactions: false,
				squatChallenge: false,
				additionalLive: false,
				additionalOnDemand: false,
				ondemandreport: false,
				memberTracking: false,
			});
		}
		
	else if(e == 5)
		{
			this.setState({
				dashboardActivity: false,
				d2clive: false,
				d2condemand: false,
				d2cinstructor: true,
				d2cmember: false,
				d2cinstructortracking: false,
				detailedReports: true,
				sumaryReports: false,
				d2cMemberPayment: false,
				paymentTransactions: false,
				squatChallenge: false,
				additionalLive: false,
				additionalOnDemand: false,
				ondemandreport: false,
				memberTracking: false,
			});
		}
}

changeDailyComp=(e, type?)=>{
	this.setState({
		detailedReports: false,
		sumaryReports: false,
		d2cinstructortracking: false,
		d2cMemberPayment: false,
		paymentTransactions: false,
		squatChallenge: false,
		additionalLive: false,
		additionalOnDemand: false,
		ondemandreport: false,
		memberTracking: false,
	})
	
	
	if ( type && type == 1 )
		{
			this.setState({
				billingPlanReports: true,
			})
		}
	else if ( type && type == 2 )
		{
			this.setState({
				billingPlan: true,
			})
		}		
	this.setState(e);
}

  render() {

    return (
		<div className="container-fluid custom-width">
			<Header loginName={this.state.loginName} totalSession={this.state.totalSession} fullsubdomain={this.state.fullsubdomain} adminReports={true}></Header>
			<div className="reports_main_title add_pos_rel">Fitness By Virdio
				<div className="closePopup noprint right25" onClick={e=>{ browserHistory.push('/DashboardLanding') }}></div>
			</div>
			<div className="reports_activity_hold wrapf">
				<div className={ "reports_session_hold neww16 " + ( this.state.dashboardActivity ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(1)}}>
					<div className="reports_session_title">Dashboard</div>
					<div className="reports_session_title_image billing_icon"></div>
					<div className={ "reports_session_box " + ( this.state.dashboardActivity ? "reports_session_box_off" : "" ) } ></div>
				</div>
				<div className={ "reports_session_hold neww16 " + ( this.state.d2cmember ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(2)}}>
					<div className="reports_session_title">Members</div>
					<div className="reports_session_title_image billing_icon"></div>
					<div className={ "reports_session_box " + ( this.state.d2cmember ? "reports_session_box_off" : "" ) } ></div>
				</div>
				<div className={ "reports_session_hold neww16 " + ( this.state.d2clive ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(3)}}>
					<div className="reports_session_title">Live Classes</div>
					<div className="reports_session_title_image billing_icon"></div>
					<div className={ "reports_session_box " + ( this.state.d2clive ? "reports_session_box_off" : "" ) } ></div>
				</div>
				<div className={ "reports_session_hold neww16 " + ( this.state.d2condemand ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(4)}}>
					<div className="reports_session_title">On-Demand Classes</div>
					<div className="reports_session_title_image billing_icon"></div>
					<div className={ "reports_session_box " + ( this.state.d2condemand ? "reports_session_box_off" : "" ) } ></div>
				</div>
				<div className={ "reports_session_hold neww16 " + ( this.state.d2cinstructor ? "reports_active" : "" ) } onClick={e=>{this.changeReportsComp(5)}}>
					<div className="reports_session_title">Instructors</div>
					<div className="reports_session_title_image billing_icon"></div>
					<div className={ "reports_session_box " + ( this.state.d2cinstructor ? "reports_session_box_off" : "" ) } ></div>
				</div>
			</div>
			<div>
				<div className="daily_session_hold">
					{this.state.dashboardActivity ? 
						<div className={ "daily_section " + ( this.state.memberTracking ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({memberTracking: true})}} >
							<div className="daily_title">Members Visit Tracking</div>
							<div className={ "daily_arrow " + ( this.state.memberTracking ? "daily_arrow_active" : "" ) }></div>
						</div>
						:
						<div className={ "daily_section " + ( this.state.detailedReports ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({detailedReports: true})}}>
							<div className="daily_title">Detailed Report</div>
							<div className="daily_arrow"></div>
							<div className={ "daily_arrow " + ( this.state.detailedReports ? "daily_arrow_active" : "" ) }></div>
						</div>
					}
					<div className={ "daily_section " + ( this.state.sumaryReports ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({sumaryReports: true})}}>
						<div className="daily_title">Summary</div>
						<div className={ "daily_arrow " + ( this.state.sumaryReports ? "daily_arrow_active" : "" ) }></div>
					</div>
					{ (this.state.d2cinstructor || this.state.d2cmember || this.state.d2clive || this.state.d2condemand) ?
						<div className={ "daily_section " + ( this.state.d2cinstructortracking ? "daily_section_active" : "" ) + ( this.state.d2cMemberPayment ? "daily_section_active" : "" ) + ( this.state.additionalLive ? "daily_section_active" : "" ) + ( this.state.additionalOnDemand ? "daily_section_active" : "" )} > 
							{ this.state.d2clive ?
								<div onClick={e=>{this.changeDailyComp({additionalLive: true})}}>
									<div className="daily_title">Additional Live</div>
									<div className={ "daily_arrow " + ( this.state.additionalLive ? "daily_arrow_active" : "" ) }></div>
								</div>
								:
								<>
									{ this.state.d2condemand ?
										<div onClick={e=>{this.changeDailyComp({additionalOnDemand: true})}}>
											<div className="daily_title">Additional On-Demand</div>
											<div className={ "daily_arrow " + ( this.state.additionalLive ? "daily_arrow_active" : "" ) }></div>
										</div>
										:
										<>
											{ this.state.d2cinstructor ?
												<div onClick={e=>{this.changeDailyComp({d2cinstructortracking: true})}}>
													<div className="daily_title">Instructor Tracking</div>
													<div className={ "daily_arrow " + ( this.state.d2cinstructortracking ? "daily_arrow_active" : "" ) }></div>
												</div>
												:
												<div onClick={e=>{this.changeDailyComp({d2cMemberPayment: true})}}>
													<div className="daily_title">Payments</div>
													<div className={ "daily_arrow " + ( this.state.d2cMemberPayment ? "daily_arrow_active" : "" ) }></div>
												</div>
											}
										</>
									}
								</>
							}
						</div>
						:
						<div className={ "daily_section " + ( this.state.detailedReports ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({detailedReports: true})}}>
							<div className="daily_title">Detailed Report</div>
							<div className="daily_arrow"></div>
							<div className={ "daily_arrow " + ( this.state.detailedReports ? "daily_arrow_active" : "" ) }></div>
						</div>
						
						
					}
					{this.state.d2cmember ? 
						<div className={ "daily_section " + ( this.state.paymentTransactions ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({paymentTransactions: true})}}>
							<div className="daily_title"> Payments Transactions</div>
							<div className={ "daily_arrow " + ( this.state.paymentTransactions ? "daily_arrow_active" : "" ) }></div>
						</div>
						:
						<>
							{ this.state.d2condemand ?
								<div className={ "daily_section " + ( this.state.ondemandreport ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({ondemandreport: true})}}>
									<div className="daily_title">On-Demand Report</div>
									<div className={ "daily_arrow " + ( this.state.ondemandreport ? "daily_arrow_active" : "" ) }></div>
								</div>
								:
								<div className="daily_section dailiy_none"></div>
							}
						</>
					}		
					{this.state.d2cmember ? 
						<div className={ "daily_section " + ( this.state.squatChallenge ? "daily_section_active" : "" ) } onClick={e=>{this.changeDailyComp({squatChallenge: true})}}>
							<div className="daily_title"> Squat Challenge</div>
							<div className={ "daily_arrow " + ( this.state.squatChallenge ? "daily_arrow_active" : "" ) }></div>
						</div>
						:
						<div className="daily_section dailiy_none"></div>	
					}					
				</div>
			</div>
			<div>
				{this.state.d2clive && this.state.detailedReports &&
					<D2cliveclasses
						state={this.state}
					>
					</D2cliveclasses>
				}
				{this.state.d2condemand && this.state.detailedReports &&
					<D2condemand
						state={this.state}
					>
					</D2condemand>
				}
				{ this.state.d2cinstructor && this.state.detailedReports &&
					<D2cinstructor
						state={this.state}
					>
					</D2cinstructor>
				}
				{ this.state.d2cmember && this.state.detailedReports &&
					<D2cmember
						state={this.state}
					>
					</D2cmember>
				}
				{ this.state.d2cinstructor && this.state.d2cinstructortracking &&
					<D2cinstructorTracking
						state={this.state}
					>
					</D2cinstructorTracking>
				}
				{ this.state.d2cmember && this.state.d2cMemberPayment &&
					<MemberPendingPayments
						state={this.state}
					>
					</MemberPendingPayments>
				}	
				{ this.state.d2cmember && this.state.paymentTransactions &&
					<PaymentsTransactions
						state={this.state}
					></PaymentsTransactions>
				}
				{ this.state.d2cmember && this.state.squatChallenge &&
					<ChallengeReports
						state={this.state}
					></ChallengeReports>
				}
				{this.state.d2clive && this.state.additionalLive &&
					<D2cadditionallive
						state={this.state}
					>
					</D2cadditionallive>
				}
				{this.state.d2condemand && this.state.additionalOnDemand &&
					<D2cadditionalondemand
						state={this.state}
					>
					</D2cadditionalondemand>
				}
				{this.state.d2condemand && this.state.ondemandreport &&
					<D2condemandreport
						state={this.state}
					>
					</D2condemandreport>
				}
				{this.state.memberTracking &&
					<MembersTrackingList></MembersTrackingList>
				}
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}

export default AdminReports;