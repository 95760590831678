import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import ReportsPages from '../reports/ReportsPages';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import moment from 'moment';
import { getLocalDateTime } from '../../util/formatDateFunctions';

class D2cadditionalondemand extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartDate: null,
					searchEndDate: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					sortByVal: ['ods.id', 'DESC'],
					ajaxLoaded: false,
					checkInfoOpen: false,
					itemsDescription: 'On-Demand classes',
					allCategories: [],
					allSubcategories: [],
					allSubcategoriesTemp: [],
					filterCategory: '',
					filterSubCategory: '',
					allWeeks: [],
					filterWeek: ''
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
	this.getLocalDateTime = getLocalDateTime.bind(this);
}

componentDidMount(){
	this.getAllCategories();
	this.getWeeks(0);
	
	document.addEventListener('mousedown', this.checkInfoOpen);
}
 
componentWillUnmount() {
	document.removeEventListener('mousedown', this.checkInfoOpen);
}

checkInfoOpen = (e) => {
	if(e.target !== "info_btn" && $(e.target).closest('.info_btn').length === 0 )
		{
			this.setState({ checkInfoOpen: false });
		}
}

openMoreInfo=(e, justClose)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	this.setState({ checkInfoOpen: false });
};

changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchSessionDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchSessionDetails(mod, this.state.page_num_count);
		}
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ 
								showSearch: !this.state.showSearch, 
								searchStartDate: null, 
								searchEndDate: null, 
								searchBy: '', 
								filterWeek: '', 
								filterCategory: '', 
								filterSubCategory: ''}, ()=>{
									this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
								});
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchSessionDetails(pg, this.state.page_num_count); 
}

fetchSessionDetails = (page_id, countId, searchText = this.state.searchBy, startDate= this.state.searchStartDate, endDate= this.state.searchEndDate, category= this.state.filterCategory, subcategory= this.state.filterSubCategory) => {

	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});
  axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getOnDemandAdditionalSessions", { page_id: page_id, countId: countId, searchText: searchText, startDate: moment(startDate).startOf('day').toDate(), endDate: moment(endDate).endOf('day').toDate(), sortby: this.state.sortByVal, category: category, subcategory: subcategory })          
  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({billing_info: res.data.responseData['sessions'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	if( (this.state.page_id+6) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			 
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}

checkScroll = (ind) => {
	this.setState({
		checkInfoOpen: ((ind+3) >= this.state.billing_info.length) ? true : false
	});
}

showMoreInfo = (e, indx) => {
	e.stopPropagation();
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
	this.checkScroll(indx);
}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	});
}

handleFilterChange = (e, mod) => {
	let mainthis = this;
	this.setState({
		[e.id]: e.value
	}, ()=>{ 
		if (mod && mod == 'category') 
			{
				mainthis.choseSubcategory(e.value)
			}
		else if (mod && mod == 'week')
			{
				mainthis.choseWeek(e.value)
			}
	});
}

choseWeek = (e, mod) =>{
	let mainthis = this;
	let tempStart = '';
	let tempEnd = '';
	if (e != '' )
		{
			tempStart = new Date(e);
			let temp = tempStart.getTime()+(7*24*60*59*1000);
			tempEnd = new Date(temp);
		}
	this.setState({
		searchStartDate: tempStart, 
		searchEndDate: tempEnd
	}, ()=>{ if(mod) {
			mainthis.fetchSessionDetails(mainthis.state.page_id, mainthis.state.page_num_count);
		}});
}

choseSubcategory = e =>{
	let allSub = this.state.allSubcategoriesTemp;
	let temp = [];
	if (e != '' )
		{
			for (let i=0; i<allSub.length; i++)
				{
					if ( allSub[i]['interestId'] == e )
						{
							temp.push(allSub[i]);
						}
				}
		}
	else
		{
			temp = allSub;
		}
	this.setState({
			allSubcategories: temp,
			filterSubCategory: ''
		});
}

getAllCategories = e =>{

  let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
  axios.defaults.headers.common['Authorization'] = token;
	
  axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getAllCategories", {})          
  .then(res => {
	
	let allCategories = res.data.responseData['allCategories'];
	let allSubcategories = res.data.responseData['allSubcategories'];
	this.setState({
		allCategories: allCategories,
		allSubcategories: allSubcategories,
		allSubcategoriesTemp: allSubcategories
	});
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });
}

getWeeks = mod =>{ 
	let mainthis = this;
	var dt = new Date();
	var currentYear = dt.getFullYear();
	var currentMonth = dt.getMonth();
	let fistDayMonth = new Date((new Date().getFullYear()-mod), 0, 1);
	let topDate = new Date();
	
	let dates = []
	const addDays = function (days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};
		
	let currentDate = fistDayMonth
	if (currentDate.getDay() > 0) {
		currentDate.setDate(currentDate.getDate() - currentDate.getDay());
	}
	 
	while (currentDate <= topDate) {
	  let endWeekDate = addDays.call(currentDate, 6);
	  dates.push({
			begin: currentDate, 
			end: endWeekDate,
			title: moment(currentDate).format('MMM DD, YYYY')+'/'+moment(endWeekDate).format('MMM DD, YYYY'),
			value: currentDate
		});
	  currentDate = addDays.call(currentDate, 7);
	 }
	let reverseWeeks = dates.reverse();
	this.setState({
		allWeeks: reverseWeeks,
		filterWeek: reverseWeeks[0]['value']
	}, ()=> { mainthis.choseWeek(reverseWeeks[0]['value'], true)});
}

exportCsv=()=>{
	
	let csv = 'Class Id, Class Name, Date/Time, Host Name, Channel Publish, Total Views, Unique Views'+"\r\n";
	
	for ( let i = 0; i < this.state.billing_info.length; i++ )
		{
			csv += [this.state.billing_info[i]['id'], 
					this.state.billing_info[i]['name'], 
					this.getLocalDateTime(this.state.billing_info[i]['createdAt']),
					this.state.billing_info[i]['hostName'], 
					this.state.billing_info[i]['channelPublish'] == 1 ? 'Yes' : 'No', 
					this.state.billing_info[i]['totalViews'] ? this.state.billing_info[i]['totalViews'] : 0, 
					this.state.billing_info[i]['uniqueViews']].join(',')+"\r\n";
		}
	
	$('#exportcsvondemandclasses').attr({'href': 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv), download: 'On-Demand Classes.csv'});
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className="pay_head_title">On-Demand classes</div>
								</div>
								<div className="show_pay_page chwidth15">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
											<option value="10000000">All</option>
										</select>
									 </span>
									{this.state.itemsDescription}
									{ this.state.billing_info.length > 0 &&
										<div className="outExport">
											<div className="exportDiv">
												<a href="#" id="exportcsvondemandclasses"><div className="rose_btn" onClick={()=> { this.exportCsv(); }}>Export CSV</div></a>
											</div>
										</div>
									}
								</div>
								<div className="show_search_opt chwidth85">
									
									{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center col-xl-6 fl43">
														<input type="text" className="form-control" placeholder="Search for class name or instructor" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
														<div className="form-group-header interest_search">
															<span className="cover-border"></span>
															<label className="label dashboard_search_label">Category</label>
															<select className="input-field dashboard_main_select" id="filterCategory" value={this.state.filterCategory} onChange={(e) => this.handleFilterChange(e.target, 'category')}>
																<option value=""></option>
																	{ this.state.allCategories.map((item, key)=> 
																		<option value={item.id} key={key}>{item.title}</option>
																	)}
															</select>
														</div>
														<div className="form-group-header interest_search">
															<span className="cover-border"></span>
															<label className="label dashboard_search_label">Sub-Category</label>
															<select className="input-field dashboard_main_select" id="filterSubCategory" value={this.state.filterSubCategory} onChange={(e) => this.handleFilterChange(e.target)}>
																<option value=""></option>
																	{ this.state.allSubcategories.map((item, key)=> 
																		<option value={item.id} key={key}>{item.title}</option>
																	)}
															</select>
														</div>
													</div>
													<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center nopadlr col-xl-6 fl57">
														<div className="parent-row mb-3 full_width">				
															<div className="row mx-0 row1 d-flex">
																<div className="col-md-12 d-flex flex-wrap align-items-center">	
																	<div className="filter_item">
																		<span className="searh_span">From</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchStartDate} onChange={date => this.setState({searchStartDate: date, filterWeek: ''})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchEndDate} onChange={date => this.setState({searchEndDate: date, filterWeek: ''})} />
																	</div>
																	<div className="form-group-header interest_search mrglft15">
																		<span className="cover-border"></span>
																		<label className="label dashboard_search_label">Week</label>
																		<select className="input-field dashboard_main_select" id="filterWeek" value={this.state.filterWeek} onChange={(e) => this.handleFilterChange(e.target, 'week')} >
																			<option value=""></option>
																				{ this.state.allWeeks.map((item, key)=> 
																					<option value={item.value} key={key}>{item.title}</option>
																				)}
																		</select>
																	</div>
																</div>
															</div>
														</div>
												</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
									
									
									
								</div>
								{ this.state.billing_info.length > 0 && 
									<table className="billing_table">
										<thead>
											<tr>
												<th>Class Id
													<div onClick={ (e) => this.sortArrays('ods.id')} className="reports_sort_icon"></div>
												</th>
												<th>Class Name
													<div onClick={ (e) => this.sortArrays('ods.name')} className="reports_sort_icon"></div>
												</th>
												<th>Date/Time
													<div onClick={ (e) => this.sortArrays('ods.createdAt')} className="reports_sort_icon"></div>
												</th>
												<th>Host Name
													<div onClick={ (e) => this.sortArrays('us.name')} className="reports_sort_icon"></div>
												</th>
												<th>Channel Publish
													<div onClick={ (e) => this.sortArrays('ods.channelPublish')} className="reports_sort_icon"></div>
												</th>
												<th>Total Views
													<div onClick={ (e) => this.sortArrays('totalViews')} className="reports_sort_icon"></div>
												</th> 
												<th>Unique Views
													<div onClick={ (e) => this.sortArrays('uniqueViews')} className="reports_sort_icon"></div>
												</th>
											</tr>
										</thead>
										<tbody> 
											{ this.state.billing_info.map((item, key) =>
												<tr key={key}>
													<td>{item.id}</td>
													<td>{item.name}</td>
													<td>{this.getLocalDateTime(item.createdAt)}</td>
													<td>{item.hostName}</td>
													{ item.channelPublish == 1 ?
														<td>Yes</td>
														:
														<td>No</td>
													}
													{ item.totalViews ? 
														<td>{item.totalViews}</td>
														:
														<td>0</td>
													}
													<td>{item.uniqueViews}</td>
												</tr>
											
											) }
										</tbody>
									</table>
								}
								{ this.state.billing_info.length > 0 && 
									<ReportsPages 
										page_id={this.state.page_id}
										page_num_count={this.state.page_num_count}
										fullCount={this.state.fullCount}
										maxPages={this.state.maxPages}
										changePage={this.changePage}
										page_numbers={this.state.page_numbers}
										checkInfoOpen={this.state.checkInfoOpen}
										itemsDescription={this.state.itemsDescription}
									/>
								}
								{ this.state.billing_info.length == 0 && this.state.ajaxLoaded && <div className="noResults2">No On-Demand classes Found</div> }
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Coming Soon</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
					</div>
				</div>
			</div>
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default D2cadditionalondemand;