import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router";

class FamilyPlanMembers extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
		appUrl: process.env.REACT_APP_URL+'login',
		emailOpened1: true,
		emailOpened2: false,
		emailOpened3: false
    }
	
}
 
componentDidMount(){
	if ( this.props.state.allFamilyPlanMembers[0] ) { this.setState({ emailOpened1: true }); }
	if ( this.props.state.allFamilyPlanMembers[1] ) { this.setState({ emailOpened2: true }); }
	if ( this.props.state.allFamilyPlanMembers[2] ) { this.setState({ emailOpened3: true }); }
}

openEmailInput=(e)=>{
	this.setState({ [e.target.id]: true },()=>{console.log(this.state.emailOpened2);});
}

removeInputValue=(value, opened)=>{
	if ( opened != 'emailOpened1' )
		{
			this.setState({
				[opened]: false
			});
		}
	
	this.props.clearEmailInput(value);
}

  render() {
	 return (
		<div className="memberRegisterHolder more_width_welcome familyPlan">			
			<div className="planH1">Family Plan</div>
			<div className="sign_title">Edit Members</div>
			<div className="paddEmailHolders">
				<div className="emailMainH1"> 
					<span> Email address 1 </span> 
					{/*this.props.state.emailAddress1.length == 0 &&
						<span className="noEmailAddress">No email address added</span> 
					*/}
				</div>
				{ !this.state.emailOpened1 ?
					<div className="addEmailPart" id="emailOpened1" onClick={(e)=>this.openEmailInput(e)}></div> :
					<div className="family_input_holder">
						<input type="text" className="gray_inputs family_member_input" id="emailAddress1" value={this.props.state.emailAddress1} onChange={(e)=>this.props.changeMemberEmail(e)} autoComplete="off" disabled />
					</div>
				}
			</div>
			<div className="paddEmailHolders">
				<div className="emailMainH1">Email address 2</div>
				{ !this.state.emailOpened2 ?
					<div className="addEmailPart" id="emailOpened2" onClick={(e)=>this.openEmailInput(e)}></div> :
					<div className="family_input_holder">	
						<input type="text" className="gray_inputs family_member_input" id="emailAddress2" value={this.props.state.emailAddress2} onChange={(e)=>this.props.changeMemberEmail(e)} autoComplete="off"/>
						<div className="close_email_icon" onClick={(e)=>this.removeInputValue('emailAddress2', 'emailOpened2')}></div>
					</div>
				}
			</div>
			<div className="paddEmailHolders">
				<div className="emailMainH1">Email address 3</div>
				{ !this.state.emailOpened3 ?
					<div className="addEmailPart" id="emailOpened3" onClick={(e)=>this.openEmailInput(e)}></div> :
					<div className="family_input_holder">	
						<input type="text" className="gray_inputs family_member_input" id="emailAddress3" value={this.props.state.emailAddress3} onChange={(e)=>this.props.changeMemberEmail(e)} autoComplete="off"/>
						<div className="close_email_icon" onClick={(e)=>this.removeInputValue('emailAddress3', 'emailOpened3')}></div>
					</div>
				}
			</div>
			<div className="booked_bnts_content">
				<div className="inactive_booked_bnt" onClick={() => this.props.closeEditMembers()} >Cancel</div>
				<div className="active_booked_bnt" onClick={() => this.props.saveEditMembers()}>Save</div>
			</div>
		</div>
    );
  }
}
export default FamilyPlanMembers;
