import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import Calendar from 'react-calendar';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import moment from 'moment';

class ReportsChannelWaitingList extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartDate: null,
					searchEndDate: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					sortedChannelName: false,
					sortedHostName: false,
					sortedDate: false,
					sortByVal: ['id', 'DESC'],
					ajaxLoaded: false,
					startDateTemp: new Date(),
					startDate: '',
					allowedChannels: '',
					errorAllowedChannels: false,
					errorStartDate: false
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.getChannelWaitingListSettings();
}
 
componentWillUnmount() {
}

openMoreInfo=(e, justClose)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	
};

changeEndDate = (date) =>
	{
		this.setState({startDateTemp: date})
	}
	
applyEndDate = () =>
	{
		this.setState({
				errorStartDate: false
			});
		
		let getFullYear=new Date(this.state.startDateTemp).getFullYear();
		let getMonth=(new Date(this.state.startDateTemp).getMonth()+1);
		let getDate=new Date(this.state.startDateTemp).getDate();
		
		if ( getMonth < 10 ) { getMonth = '0'+getMonth; }
		if ( getDate < 10 ) { getDate = '0'+getDate; }
		
		this.setState({ startDate: getFullYear+'-'+getMonth+'-'+getDate })
		$("#calenderModelEndDate").attr({'style':'display:none'})
	}

changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchSessionDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	console.log(mod);
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchSessionDetails(mod, this.state.page_num_count);
		}
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ showSearch: !this.state.showSearch, searchStartDate: null, searchEndDate: null, searchBy: '' });
			
			this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

customChecked = () => {
	this.setState({ customCheck1: !this.state.customCheck1 });
}

searchByInput =() => {
	let pg = 0;
	this.setState({
			page_id: pg
		});
	this.fetchSessionDetails(pg, this.state.page_num_count); 
}

fetchSessionDetails = (page_id, countId, searchText = this.state.searchBy, startDate= this.state.searchStartDate, endDate= this.state.searchEndDate) => {
	
	
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});
  axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelWaitingList", { page_id: page_id, countId: countId, searchText: searchText, startDate: startDate, endDate: endDate, sortby: this.state.sortByVal })          
  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({billing_info: res.data.responseData['emails'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	if( (this.state.page_id+6) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			 
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}

getChannelWaitingListSettings = () => {
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});
  axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelWaitingListSettings", {})          
  .then(res => {
	this.setState({
			startDate: ( res.data.responseData['settings'] && res.data.responseData['settings']['channelWaitingListStartTime'] ) ? res.data.responseData['settings']['channelWaitingListStartTime'] : '' , 
			allowedChannels: ( res.data.responseData['settings'] && res.data.responseData['settings']['channelWaitingListAllowedNumber'] ) ? res.data.responseData['settings']['channelWaitingListAllowedNumber'] : ''
		});
	
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 $('.main_loader').hide();
  });
}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	});
}
	
getChannelInfo = (e, channelId, indx, mod, mod2) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	$('.main_loader').attr({'style':'display:flex'});
	var data = [...this.state.billing_info];
	
	$(e.currentTarget).find('.all_info').addClass('active');
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelData", { 'channelId': channelId, 'type': mod })          
	.then(res => {
		data[indx][mod2] = res.data.responseData;
		this.setState({billing_info: data
		},() => {
			/* console.log("NEW STATEEEE");
			console.log(this.state); */
			$('.main_loader').hide();
		});
	})
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	  $('.main_loader').hide();
	});
}

stopClick = (e) => {
	e.stopPropagation();
	e.preventDefault();
}

validateNumber(number) {
    const re = /^[0-9]+$/;
    return re.test(number);
}

saveWaitingList=(e)=>{
	
	if ( this.state.startDate == '' )
		{
			this.setState({
				errorStartDate: true
			});
			return false;
		}
	
    if ( this.state.allowedChannels == '' || !this.validateNumber(this.state.allowedChannels))
		{
			this.setState({
				errorAllowedChannels: true
			});
			return false;
		}
	
    const data ={
			channelWaitingListStartTime: this.state.startDate,
			channelWaitingListAllowedNumber: this.state.allowedChannels
		}
		
	$('.main_loader').attr({'style':'display:flex'}); 
    
    axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/saveWaitingListSettings",data)
    .then(res => {
		$('.main_loader').hide();
		this.setState({
				errorAllowedChannels: false,
				errorStartDate: false
			});
	   if ( res.data && res.data.responseMessage && res.data.responseMessage == 'success' )
		{
			this.props.showMainErrorPop('Data successfully saved.');
		}
	}).catch(err =>{
			$('.main_loader').hide();
			
			this.props.showMainErrorPop(err.response.data.errorData);
        });
  }
  
  sendEmail =(email)=>{
	
	let adminId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	  
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/sendEmailChannelWaiting", { email: email, adminId: adminId })          
	.then(res => {
		$('.main_loader').hide();
		
		if ( res.data && res.data.responseMessage && res.data.responseMessage == 'success' )
			{
				this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
				this.props.showMainErrorPop('Email successfully send.');
			}
		
	})
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	   $('.main_loader').hide();
	});
}

deleteWaiting=(id)=>{
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/deleteWaitingChannel", {id: id}).then(res => {
		this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
		this.props.showMainErrorPop('Successfully deleted from Waiting List.');
	})
	.catch(err =>{ $('.main_loader').hide(); });
}

showMoreInfo = (e, sess_id, indx) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className="container-fluid payinfo_main_hold">
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="top_pay_info chPad_topbot">
									<div className="one_w_demand">
										<div className="inner_w_demand">
											<div className="d-flex align-items-start flex-wrap">
												<p className="text-white mr-3 mt-3">Start Date</p>
												<div className="form-group flex-grow-1 mb-0" onClick={e=>{$("#calenderModelEndDate").attr({'style':'display:block'});}}>
													<input type="text" className={(this.state.errorStartDate ? "input-field red-outline" : "input-field" )}   id="enddate" value={this.state.startDate}  placeholder="Start Date" readOnly />
												</div>
											</div>
										</div>
									</div>
									<div className="one_w_demand">
										<div className="inner_w_demand">
											<div className="d-flex align-items-start flex-wrap">
												<p className="text-white mr-3 mt-3">Allowed Channels</p>
												<div className="form-group flex-grow-1 mb-0">
													<input type="text" 
													className={(this.state.errorAllowedChannels ? "input-field red-outline" : "input-field" )}  
													id="allowedChannels" 
													value= {this.state.allowedChannels} onChange = {(e)=>this.setState({[e.target.id] : e.target.value})} placeholder="Allowed Channels" maxLength="30" />
												</div>							
											</div>
										</div>
									</div>
									<div className="one_w_demand">
										<div className="inner_w_demand">
											<div className="text-center">
												<button type="button" className="saveButton" onClick={this.saveWaitingList}>Save</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Coming Soon</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
					</div>
				</div>
			</div>
			<div className="modal cal_modal mt-3" id="calenderModelEndDate" data-backdrop="">
				<div className="modal-dialog d-md-flex d-block large_width1 mb-0">
					<div className="modal-content modalbg m-auto">
						<div className="modal-header px-4 pt-3 pb-0">
							<h4 className="white modal-title">Choose Date</h4>
							<button type="button pr-3" className="close white closepopup" onClick={e=>$("#calenderModelEndDate").attr({'style':'display:none'})}>&times;</button>
						</div>
						<div className="modal-body modal-body px-1 px-sm-3">
							<h3>Calender</h3>

							<Calendar
							   onChange={this.changeEndDate}
							   value={this.state.endDateTemp}
							   minDate={new Date('2020-01-01')}
							 />
						</div>
						<div className="text-center position-absolute btn_btn1">
						{this.state.signUpSessionStatus?'':<button type="button" className="done mt-0" onClick={this.applyEndDate} data-dismiss="modal">done</button>}
					</div>
					</div>
				</div>
			</div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ReportsChannelWaitingList);