import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { connect } from "react-redux";

import { showMainErrorPop } from '../../actions/popups';

class EditVideoCut extends Component {
  
  constructor(props) {
	super(props);
	
	this.state = {
		editVideo: '',
		tag1mouseState: 0,
		dragEl: null,
		videoPosition: '00:00',
		videoDuration: '00:00',
		videoDurationNew: '00:00:00.00',
		videoDurationNewS: '00:00:00.00',
		videoDurationNewE: '00:00:00.00',
		videoPlaying: false,
		nowVideo: this.props.state.editOnDemandVideo.video.replace('_fullvideocut.mp4', '_fullvideo.mp4'),
		cutStart: 0,
		cutEnd: 0,
		originalDuration: 0
    }
}

draggVEDrag=(e)=>{

if ( this.state.dragEl !== null && ( this.state.dragEl.id == 'videdtg1' || this.state.dragEl.id == 'videdtg2' ) )
	{
		var diffX;
		var zeroX =  $('.fullEditVideo_duration').offset().left;
		
		if(e.clientX)
			{
				diffX = e.clientX - zeroX;
			}
			
		else if(e.touches && e.touches[0] && e.touches[0].clientX)
			{
				diffX = e.touches[0].clientX - zeroX;
			}
			
		else
			{
				return false;
			}
		
		if ( diffX < 0 ) { diffX = 0; }
		if ( diffX > $(this.state.dragEl.parentNode).width() ) { diffX = $(this.state.dragEl.parentNode).width(); }
		if ( this.state.dragEl.id == 'videdtg1' && diffX + zeroX > $('#videdtg2').offset().left )
			{
				console.log('touchmnove');
				diffX = $('#videdtg2').offset().left - zeroX;
			}
		else if ( this.state.dragEl.id == 'videdtg2' && diffX + zeroX - 30 < $('#videdtg1').offset().left )
			{
				console.log('touchmnove1');
				diffX = $('#videdtg1').offset().left - zeroX + 30;
			}
		
		

		this.state.dragEl.style.left = (parseInt(diffX)  / $('.fullEditVideo_duration').width() * 100 ) + '%';
		
		$('#inedtgdur')[0].style.left = (($('#videdtg1').offset().left - zeroX + 15) / $('.fullEditVideo_duration').width() * 100 ) + '%';
		$('#inedtgdur')[0].style.width = (($('#videdtg2').offset().left - $('#videdtg1').offset().left) / $('.fullEditVideo_duration').width() * 100 ) + '%';
		
		this.calcDuration();
	}
else if ( this.state.dragEl !== null && this.state.dragEl.id == 'videdtg3' )
	{
		var zeroX =  $('.fullEditVideo_outmvid').offset().left;
		var diffX;
		
		if(e.clientX)
			{
				diffX = e.clientX - zeroX;
			}
			
		else if(e.touches && e.touches[0] && e.touches[0].clientX)
			{
				diffX = e.touches[0].clientX - zeroX;
			}
			
		else
			{
				return false;
			}
		
		if ( diffX < 0 ) { diffX = 0; }
		if ( diffX > $('.fullEditVideo_outmvid').width() ) { diffX = $('.fullEditVideo_outmvid').width(); }
		
		this.state.dragEl.parentNode.style.width = (parseInt(diffX)  / $('.fullEditVideo_outmvid').width() * 100 ) + '%';
		
		this.calcDuration(parseInt(diffX)  / $('.fullEditVideo_outmvid').width());
	}



}

draggVEDragEnd=(e)=>{
	
	if ( this.state.dragEl !== null && this.state.dragEl.id !== 'videdtg3' )
		{
			$('.fullEditVideo_video video')[0].currentTime = 0;
		}
	this.setState({tag1mouseState: 0, tag2mouseState: 0, dragEl: null}, () => { this.calcDuration(); });
}

calcDuration=(e)=>{

var dur = $('.fullEditVideo_video video')[0].duration;
var leftwid = ($('#videdtg2').offset().left - $('#videdtg1').offset().left) / $('.fullEditVideo_duration').width();
var dur2 = dur * leftwid;
var newdur = this.getDurPercent(dur2);
var newdur3 = this.getDurPercent(dur2, true);

var zeroX =  $('.fullEditVideo_duration').offset().left;

leftwid = ($('#videdtg1').offset().left+15 - zeroX) / $('.fullEditVideo_duration').width();
var dur3 = dur * leftwid;
var newdur1 = this.getDurPercent(dur3);




if ( e )
	{
		$('.fullEditVideo_video video')[0].currentTime = dur3 + (dur2*e);
	}

if ( this.state.dragEl == null && dur3 > $('.fullEditVideo_video video')[0].currentTime )
	{
		$('.fullEditVideo_video video')[0].currentTime = dur3+0.00001;
	}


if ( this.state.dragEl !== null && this.state.dragEl.id == 'videdtg1' ) { $('.fullEditVideo_video video')[0].currentTime = dur3; }

leftwid = ($('#videdtg2').offset().left+15 - zeroX) / $('.fullEditVideo_duration').width();
var dur4 = dur * leftwid;
var newdur2 = this.getDurPercent(dur4);

if ( this.state.dragEl !== null && this.state.dragEl.id == 'videdtg2' ) { $('.fullEditVideo_video video')[0].currentTime = dur4; }

var newpos = this.getDurPercent($('.fullEditVideo_video video')[0].currentTime - dur3, true);

var startDuration = this.state.originalDuration;
if ( this.state.originalDuration == 0 )
	{
		startDuration = dur;
	}

this.setState({videoDurationNew: newdur, videoDurationNewS: newdur1, videoDurationNewE: newdur2, videoDuration: newdur3, videoPosition: newpos, cutStart: dur3, cutEnd: dur2, originalDuration: startDuration});

var newwid = (($('.fullEditVideo_video video')[0].currentTime - dur3) / dur2 * 100 );
if ( newwid >= 100 && this.state.dragEl == null ) { newwid = 0; $('.fullEditVideo_video video')[0].currentTime = dur3+0.00001; $('.fullEditVideo_video video')[0].pause(); this.setState({videoPlaying: false}); }

$('.fullEditVideo_inmvid')[0].style.width = newwid + '%';
}

getDurPercent=(dur, onlytwo)=>{
	var dhr = Math.floor(dur/60/60);
	var dmin = Math.floor(dur/60) - (dhr*60);
	var dsec = Math.floor(dur - (dmin*60) - (dhr*60*60));
	
	var dminsec = Math.floor((dur - dsec - (dmin*60) - (dhr*60*60))*100);
	
	dhr = dhr < 10 ? '0'+dhr : dhr;
	dmin = dmin < 10 ? '0'+dmin : dmin;
	dsec = dsec < 10 ? '0'+dsec : dsec;
	dminsec = dminsec < 10 ? '0'+dminsec : dminsec;
	
	
	return onlytwo ? dhr+':'+dmin+':'+dsec : dhr+':'+dmin+':'+dsec+'.'+dminsec;
}

playPauseVideo=(e)=>{
	
	if ( $('.fullEditVideo_video video')[0].paused )
		{
			$('.fullEditVideo_video video')[0].play();
			this.setState({videoPlaying: true});
		}
	else
		{
			$('.fullEditVideo_video video')[0].pause();
			this.setState({videoPlaying: false});
		}
	
}

saveVideoCut=(e)=>{
	
	var mainThis = this;
	if ( this.state.originalDuration > this.state.cutEnd && this.state.cutEnd > 1 )
		{
			$('.main_loader').attr({'style':'display:flex'});
			
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
			
			axios.post(process.env.REACT_APP_NAME+"/api/v1/upload/cutvideo", {videoId: this.props.state.editOnDemandVideo.id, startTime: this.state.videoDurationNewS, endTime: this.state.videoDurationNew}).then(res => {
				
				$('.main_loader').hide();
				mainThis.props.closeVideoEdit();
				
			}).catch(err =>{
				if ( err.response && err.response.data && err.response.data.responseCode===417 && err.response.data.errorData )
					{
						$('.main_loader').hide();
						mainThis.props.closeVideoEdit();
						
						mainThis.props.showMainErrorPop(err.response.data.errorData);
					}
				else
					{
						$('.main_loader').hide();
						mainThis.props.closeVideoEdit();
						
						mainThis.props.showMainErrorPop('Something went wrong.');
					}
			})
		}
	else if ( this.state.cutEnd <= 1 )
		{
			this.props.showMainErrorPop('Cut video too short. Please expand cut time.');
		}
}














componentDidMount(){

console.log();
	
}



  render() {
	 return (
		<div>
			<div className="fullEditVideo" onMouseUp={(e)=> { this.draggVEDragEnd(); }} onTouchEnd={(e)=> { this.draggVEDragEnd(); }}>
				<div className="fullEditVideoInside" onMouseMove={(e)=>{ this.draggVEDrag(e); }} onTouchMove={(e)=>{ this.draggVEDrag(e); }}>
					<div className="closePopup" onClick={(e) => { this.props.closeVideoEdit(); }}></div>
					<div className="fullEditVideo_title">{this.props.state.editOnDemandVideo.name}</div>
					
					<div className="fullEditVideo_video">
						<video width="320" height="240" onClick={(e) => { this.playPauseVideo(); }} onTimeUpdate={() => { this.calcDuration(); }} onLoadedData={() => { this.calcDuration(); }}><source src={this.state.nowVideo} type="video/mp4" /></video>
						<div className="fullEditVideo_controls">
							<div className={'fullEditVideo_playbtn ' + (this.state.videoPlaying ? 'playingnow' : '')} onClick={(e) => { this.playPauseVideo(); }}></div>
							<div className="fullEditVideo_playtime">{this.state.videoPosition} / {this.state.videoDuration}</div>
							
							<div className="fullEditVideo_outmvid"><div className="fullEditVideo_inmvid"><div id="videdtg3" onMouseDown={(e)=> { e.preventDefault(); $('.fullEditVideo_video video')[0].pause(); this.setState({dragEl: e.currentTarget, videoPlaying: false}); }} onTouchStart={(e)=> { e.preventDefault(); $('.fullEditVideo_video video')[0].pause(); this.setState({dragEl: e.currentTarget, videoPlaying: false}); }}></div></div></div>
						</div>
					</div>
					
					<div className="fullEditVideo_duration">
						<div className="fullEditVideo_durationin" id="inedtgdur"></div>
						<div id="videdtg1"
								className={'fullEditVideo_tag1 mousefvstate_' + this.state.tag1mouseState}
								onMouseEnter={(e)=> { if ( this.state.tag1mouseState == 0 ) { this.setState({tag1mouseState: 1}); }}}
								onMouseLeave={(e)=> { if ( this.state.tag1mouseState == 1 ) { this.setState({tag1mouseState: 0}); }}}
								onMouseDown={(e)=> { e.preventDefault(); $('.fullEditVideo_video video')[0].pause(); this.setState({dragEl: e.currentTarget, tag1mouseState: 2, videoPlaying: false}); }}
								onTouchStart={(e)=> { e.preventDefault(); $('.fullEditVideo_video video')[0].pause(); this.setState({dragEl: e.currentTarget, tag1mouseState: 2, videoPlaying: false}); }}
								></div>
						<div id="videdtg2"
								className={'fullEditVideo_tag2 mousefvstate_' + this.state.tag2mouseState}
								onMouseEnter={(e)=> { if ( this.state.tag2mouseState == 0 ) { this.setState({tag2mouseState: 1}); }}}
								onMouseLeave={(e)=> { if ( this.state.tag2mouseState == 1 ) { this.setState({tag2mouseState: 0}); }}}
								onMouseDown={(e)=> { e.preventDefault(); $('.fullEditVideo_video video')[0].pause(); this.setState({dragEl: e.currentTarget, tag2mouseState: 2, videoPlaying: false}); }}
								onTouchStart={(e)=> { e.preventDefault(); $('.fullEditVideo_video video')[0].pause(); this.setState({dragEl: e.currentTarget, tag2mouseState: 2, videoPlaying: false}); }}
								></div>
					</div>
					
					<div className="fullEditVideo_subtitle">Duration<br />{this.state.videoDurationNew}</div>
					
					<div className="fullEditVideo_stend">
						<div className="fullEditVideo_start">Start <div>{this.state.videoDurationNewS}</div></div><div className="fullEditVideo_end">End <div>{this.state.videoDurationNewE}</div></div>
					</div>
				</div>
				
				<div className="form-content mb-0 ond_width">
					<div className="text-center">
						<button type="button" className="custom_btn1 mt-3 ml20" onClick={(e) => { this.saveVideoCut(); }}>Save</button>
					</div>
				</div>
			</div>
		</div>
    );
  }
}
export default connect(
  null,
  {
	showMainErrorPop
  }
)(EditVideoCut);
