import React, { Component } from "react";
import classnames from "classnames";
import {browserHistory} from 'react-router'

class MarketplaceHeader extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
	
    }
	
}
 
componentDidMount(){
	
}

openLaunch=()=>{
	if ( this.props && this.props.updateStateFromChild )
		{
			this.props.updateStateFromChild({showLaunchPop: true});
		}
}

  render() {
	 return (
		<div className={classnames("marketplace_header just_logo_header " + (this.props.centerHeader ? "centarHeaderHolder" : "" ) + ( this.props && this.props.state && this.props.state.newMemberHeader ? "newMemberHeader" : "" ) )} > 
			<a href="/" >	
				<div className={classnames("market_logo " + (this.props.centerHeader ? "newDesignLogo" : "" ) + ( this.props && this.props.state && this.props.state.newMemberHeader ? "newDesignLogo" : "" ) )} ></div>
			</a>
			{this.props && this.props.state && this.props.state.isProfile == true &&
				<div className="signOutHeaderHolder" onClick={(e)=>{this.props.logOutMember()}} > 
					<div className="signBtnsHold">
						<div className="signNewText">Sign out</div>
						<div className="signOutIcon"></div>
					</div>
				</div>
			}
		</div>
    );
  }
}
export default MarketplaceHeader;
