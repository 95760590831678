import React, { Component } from "react";
import Header from './Header';
import axios from "axios";
import $ from 'jquery';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import SupportContainer from '../home/SupportContainer';

class channelSubscription extends Component {
constructor(props)
{
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state = {
					planInfo: {},
					isLoaded: false,
					planHours: 48,
					showReactivate: false,
					emptyMsg: '',
					pendingPayment: {},
					canMakePayment: false,
					supportPageName: 'Subscription',
					supportPageApi: '/channelSubscription',
					userTypeFaqs: 2
				}
}

componentDidMount()
{
	this.getPlan();
	this.checkForCanceled();
	this.getPendingPay();
	if ( localStorage.getItem('userData') )
		{
			var userData = JSON.parse(localStorage.getItem('userData')).data.responseData;
			if ( userData.settings.hoursAfterPlanExpired )
				{
					var hours = parseInt(userData.settings.hoursAfterPlanExpired);
					this.setState({planHours: hours});
				}
		}
}

openCancelPop=()=>{
	$('#cancel_sub').attr({'style':'display:block'});
}

closeCancel=()=>{
	$('#cancel_sub').attr({'style':'display:none'});
}

cancelSubscription=()=>{
	var postData = {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
	console.log(postData);
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/cancelPlanSubscription", postData).then(res => {
		this.checkForCanceled();
		this.getPlan();
		$('#cancel_sub').attr({'style':'display:none'});
		this.props.showMainErrorPop('Channel Subscription canceled.');
	}).catch(err =>{ }); 
}

checkForCanceled=()=>{
	var postData = {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkForCanceledPlan", postData).then(res => {
		if ( res.data.responseData === 1 ) { this.setState({showReactivate: true}); }
	}).catch(err =>{ }); 
}

reactivateSubscription=()=>{
	var postData = {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
	console.log(postData);
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/reactivatePlanSubscription", postData).then(res => {
		this.setState({showReactivate: false});
		this.getPlan();
		this.props.showMainErrorPop('Subscription sucessfully Reactivated.');
	}).catch(err =>{ }); 
}

getPlan=()=>{
	let ak = JSON.parse(localStorage.getItem('userData'));

	let token = ak.data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getCurrentPlanFull", {channelId: ak.data.responseData.channel}).then(res => {
		if ( res.data.channelPlan['isTrial'] )
			{
				res.data.channelPlan['startTime'] = moment(res.data.channelPlan['trialStarted']).format('MMM Do, YYYY');
				res.data.channelPlan['endTime'] = moment(res.data.channelPlan['trialEnding']).format('MMM Do, YYYY');
			}
		
		res.data.channelPlan['planTime'] = moment(res.data.channelPlan['planActivated']).format('MMM Do, YYYY');
		
		this.setState({planInfo: res.data.channelPlan, isLoaded: true});
		
		if ( !res.data.channelPlan.id )
			{
				axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getEmptyPlanMessage", {channelId: ak.data.responseData.channel}).then(resmsg => {
					if ( resmsg.data.responseData !== '' ) { this.setState({emptyMsg: resmsg.data.responseData}); }
				}).catch(err =>{});
			}
		
	}).catch(err =>{ console.log('----------there is problem------------', err); });
}

getPendingPay=()=>{
	let ak = JSON.parse(localStorage.getItem('userData'));

	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getPendingPay", {channelId: ak.data.responseData.channel}).then(res => {
		this.setState({pendingPayment: res.data.responseData});
		if ( res.data.responseData.status === 1 ) { this.setState({canMakePayment: true}); }
		else { this.setState({canMakePayment: false}); }
	}).catch(err =>{});
}

makePendingPayment=()=>{
	$('.main_loader').attr({'style':'display:flex'});
	var user = JSON.parse(localStorage.getItem('userData')).data.responseData;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/selectDefaultCard", {"userId": user.id}).then(res => {
		if ( !res.data.responseData ) 
			{
				this.props.showMainErrorPop("You don't have valid credit card so you can't proceed with Payment. Please go to your Profile settings and add valid Credit Card.");
				$('.main_loader').hide();
			}
		else
			{
				//make pending payment
				axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/makePayment", {"payId": this.state.pendingPayment.id}).then(res => {
					this.props.showMainErrorPop('Channel Subscription successfully charged.');
					$('.main_loader').hide();
					this.getPendingPay();
				}).catch(err =>{ this.props.showMainErrorPop('Payment failed. Please check your Credit Card and try again.'); $('.main_loader').hide(); });
			}
	}).catch(err =>{ $('.main_loader').hide(); });
}

render() {
	
    return (<div className="container-fluid custom-width chsubPage">
			<div className="noprint"><Header></Header></div>
			
			<div className="clearfix"></div>
			<div className="gray-box reportsholdn">
				<div className="reports_main_title noprint">Channel Subscription</div>
				<div className="closePopup noprint" onClick={e=>{ browserHistory.push('/DashboardLanding') }}></div>
				
				<div className="top_pay_info sidep_sub">
					{ this.state.isLoaded && !this.state.planInfo.id &&
						<div>
							<div className="no_plan_avail">Channel Subscription not available.</div>
							<div className="no_plan_avail">{this.state.emptyMsg}</div>
						</div>
					}
					{ this.state.isLoaded && this.state.planInfo.isTrial == 1 && 
						<div>
							<div className="one_trial">
								<div className="main_title_plan">Trial Period</div>
								<div className="box_trial">
									<div className="box_inline"><span className="white_ich">Start Date:</span> {moment(this.state.planInfo.trialStarted).format('MM/DD/YYYY')}</div>
									<div className="box_inline"><span className="white_ich">End Date:</span> {moment(this.state.planInfo.trialEnding).format('MM/DD/YYYY')}</div>
								</div>
							</div>
							<div className="one_trial">
								<div className="main_title_plan">Live Streaming</div>
								<div className="box_trial">
									<div className="box_inline"><span className="white_ich">Class Hosted:</span> {this.state.planInfo.totalSessions}</div>
									<div className="box_inline"><span className="white_ich">Members Attended:</span> {this.state.planInfo.sessionUsersInThisMonth}</div>
								</div>
							</div>
							<div className="one_trial">
								<div className="main_title_plan">On-Demand</div>
								<div className="box_trial">
									<div className="box_inline"><span className="white_ich">Recordings:</span> {this.state.planInfo.recordingsInThisMonth}</div>
									<div className="box_inline"><span className="white_ich">Client Viewings:</span> {this.state.planInfo.recordingClientsInThisMonth}</div>
								</div>
							</div>
							<div className="one_trial trial_smaller_live">
								<div className="main_title_plan">Live Streaming Plan - <span>{this.state.planInfo.planName}</span></div>
								<div className="box_trial full_box hei280">
									<div>
										<div className="box_inline full_box pb15"><span className="white_ich">Subscription:</span> ${this.state.planInfo.realLivePrice}</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Members:</span> {this.state.planInfo.includedParticipants} Included</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Overage:</span> ${this.state.planInfo.additionalParticipants}/member</div>
										{ this.state.planInfo.couponId > 0 &&
											<div className="box_inline full_box">Discounted price of {this.state.planInfo.subPercent}% Off until {moment(this.state.planInfo.endingCoupon).format('MM/DD/YYYY')}</div>
										}
									</div>
								</div>
							</div>
							<div className="one_trial trial_smaller_demand">
								<div className="main_title_plan">On-Demand Plan - <span>{this.state.planInfo.onDemandPlan}</span></div>
								<div className="box_trial full_box hei280">
									<div>
										<div className="box_inline full_box pb15"><span className="white_ich">Subscription:</span> ${( this.state.planInfo.realDemandPrice ? this.state.planInfo.realDemandPrice.toFixed(2) : 0)}</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Clients:</span> {this.state.planInfo.uniqueClients} Included</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Overage:</span> ${( this.state.planInfo.additionalClient ? this.state.planInfo.additionalClient.toFixed(2) : 0)}/client</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Recordings:</span> {this.state.planInfo.recordingsNumber} Included</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Overage:</span> ${( this.state.planInfo.additionalRecording ? this.state.planInfo.additionalRecording.toFixed(2) : 0)}/Recording</div>
										{ this.state.planInfo.couponId > 0 &&
											<div className="box_inline full_box">Discounted price of {this.state.planInfo.subPercent}% Off until {moment(this.state.planInfo.endingCoupon).format('MM/DD/YYYY')}</div>
										}
									</div>
								</div>
							</div>
						</div>
					}
					
					{ this.state.isLoaded && this.state.planInfo.isTrial == 0 && 
						<div>
							<div className="one_trial trial_smaller_live">
								<div className="main_title_plan">Live Streaming Plan - <span>{this.state.planInfo.planName}</span></div>
								<div className="box_trial full_box hei280">
									<div>
										<div className="box_inline full_box pb15"><span className="white_ich">Subscription:</span> ${this.state.planInfo.realLivePrice}</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Members:</span> {this.state.planInfo.includedParticipants} Included</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Overage:</span> ${this.state.planInfo.additionalParticipants}/member</div>
										{ this.state.planInfo.couponId > 0 &&
											<div className="box_inline full_box">Discounted price of {this.state.planInfo.subPercent}% Off until {moment(this.state.planInfo.endingCoupon).format('MM/DD/YYYY')}</div>
										}
									</div>
								</div>
							</div>
							<div className="one_trial trial_smaller_demand">
								<div className="main_title_plan">On-Demand Plan - <span>{this.state.planInfo.onDemandPlan}</span></div>
								<div className="box_trial full_box hei280">
									<div>
										<div className="box_inline full_box pb15"><span className="white_ich">Subscription:</span> ${( this.state.planInfo.realDemandPrice ? this.state.planInfo.realDemandPrice.toFixed(2) : 0)}</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Clients:</span> {this.state.planInfo.uniqueClients} Included</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Overage:</span> ${( this.state.planInfo.additionalClient ? this.state.planInfo.additionalClient.toFixed(2) : 0)}/client</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Recordings:</span> {this.state.planInfo.recordingsNumber} Included</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Overage:</span> ${( this.state.planInfo.additionalRecording ? this.state.planInfo.additionalRecording.toFixed(2) : 0)}/Recording</div>
										{ this.state.planInfo.couponId > 0 &&
											<div className="box_inline full_box">Discounted price of {this.state.planInfo.subPercent}% Off until {moment(this.state.planInfo.endingCoupon).format('MM/DD/YYYY')}</div>
										}
									</div>
								</div>
							</div>
							<div className="one_trial trial_smaller_live">
								<div className="main_title_plan">Month to Date</div>
								<div className="box_trial full_box">
									<div>
										<div className="box_inline full_box pb15"><span className="white_ich">Members:</span> {this.state.planInfo.sessionUsersInThisMonth}</div>
										<div className="box_inline full_box pb15"><span className="white_ich">Overage:</span> {this.state.planInfo.overChargeUsers}</div>
										<div className="box_inline full_box"><span className="white_ich">Overage$:</span> {this.state.planInfo.overChargeUsersPrice}</div>
									</div>
								</div>
							</div>
							<div className="one_trial trial_smaller_demand">
								<div className="main_title_plan">Month to Date</div>
								<div className="box_trial full_box">
									<div>
										<div className="box_inline pb15"><span className="white_ich">Members:</span> {this.state.planInfo.recordingClientsInThisMonth}</div>
										<div className="box_inline pb15"><span className="white_ich">Recordings:</span> {this.state.planInfo.recordingsInThisMonth}</div>
										<div className="box_inline pb15"><span className="white_ich">Overage:</span> {this.state.planInfo.overChargeRecordingsClients}</div>
										<div className="box_inline pb15"><span className="white_ich">Overage:</span> {this.state.planInfo.overChargeRecordings}</div>
										<div className="box_inline"><span className="white_ich">Overage$:</span> {this.state.planInfo.overChargeRecordingsClientsPrice}</div>
										<div className="box_inline"><span className="white_ich">Overage$:</span> {this.state.planInfo.overChargeRecordingsPrice}</div>
									</div>
								</div>
							</div>
						</div>
					}
					
						{ this.state.isLoaded && this.state.planInfo.id &&
							<div className="relative">
								<Link to="/trainingPlans"><button type="button" className="done mt-3">CHANGE PLAN</button></Link>
								{ this.state.planInfo.isCanceled === 0 &&
									<div className="cancel_plan" onClick={this.openCancelPop}>Cancel Subscription</div>
								}
							</div> 
						}
						{this.state.emptyMsg == "You don't have Channel Subscription." &&
							<div className="relative">
								<Link to="/trainingPlans"><button type="button" className="done mt-3">BUY PLAN</button></Link>
							</div>
						}
						{ this.state.isLoaded && this.state.showReactivate &&
							<div className="relative"><button type="button" className="done mt-3 reactivate_btn" onClick={this.reactivateSubscription}>REACTIVATE SUBSCRIPTION</button></div>
						}
					
						{ this.state.isLoaded && this.state.canMakePayment &&
							<div className="relative"><button type="button" className="done mt-3 reactivate_btn" onClick={this.makePendingPayment}>MAKE PAYMENT</button></div>
						}
						
						{ !this.state.isLoaded && <div className="loadingResults"><div className="main_loader_inner"></div></div> }
					</div>
			</div>
			
			<div className="modal pr-0" id="cancel_sub" aria-modal="true">
				<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Are you sure you want to cancel your Virdio Channel Subscription?</p>
						</div>
						<div className="text-center">
							<button type="button" className="col-4 custom_btn1 mt-3" onClick={this.cancelSubscription}>Yes</button>
							<button type="button" className="custom_btn1 col-4 mt-3" style={{marginLeft: '50px'}} onClick={this.closeCancel}>No</button>
						</div>
					</div>
				</div>
			</div>
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>);
}

}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(channelSubscription);