import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";

class myChannelsParticipant extends Component {
  
  constructor(props) {
	super(props);
	this.state={
		test: '',
		channelArrays: [],
    }
	
	window['newChannelsArray'] = this.newChannelsArray;
}
 
componentDidMount(){
	this.newChannelsArray();
}

openChannel = (mod) => {
	
	let adminpath = process.env.REACT_APP_PATH;
	adminpath = adminpath.replace('http://', '.').replace('https://', '.');
	
	let value= 'https://'+mod+adminpath;
	
	//window.open(value);
}
newChannelsArray = e =>{
	
	let adminpath = process.env.REACT_APP_PATH;
	adminpath = adminpath.replace('http://', '.').replace('https://', '.');
	if ( this.props.state.channelsData.length > 0 )
		{
			let currentArray = this.props.state.channelsData;
			for ( var i = 0; i<currentArray.length; i++ )
				{
					if ( currentArray[i]['subdomain'] != '' )
						{
							let value= 'https://'+currentArray[i]['subdomain']+adminpath;
							currentArray[i]['link'] = value;
							console.log(currentArray[i]['link']);
						}
				}
			this.setState({
				channelArrays: currentArray,
			})	
		}
	else 
		{
			this.setState({
				channelArrays: [],
			})
		}		
}
  render() {
	 return (
		<div className="myChannels_holder">
			{ this.state.channelArrays.length > 0 &&
				<div>
					<div className="h1_mychannels" >You are a Member of the following Channels or have attended Classes on these Channels.</div>
					<div className="table_scroll" >
						<table className="table_mych" >
							<thead>
								<tr className="toppart_holder" >
									<td className="first_width_mychannels" >Channel Name</td>
									<td className="sec_width_mychannels" >Channel Category</td>
									<td className="th_width_mychannels" >Action</td>
								</tr>
							</thead>	
							<tbody>
								{this.state.channelArrays.map((item, i)=>
									<tr className="new_border_td" key={i} >
										<td className="t1_width new_padd_mych" >{item.name}</td>
										<td className="t2_width new_padd_mych" >{item.channelIntests}</td>
										<td className="t3_width new_padd_mych" >
											{item.subdomain && item.subdomain != '' ?
												<a href={item.link} >
													<button className="new_view_button" >VIEW CHANNEL</button> 
												</a>
												:
												<div>NA</div>
											}
										</td>
									</tr>
								)}
							</tbody>			
						</table>
					</div>	
				</div>
			}
			{ this.state.channelArrays.length === 0 &&
				<div className="h1_mychannels pb0 txt_center">You are not member of any Channel.</div>
			}
			
		</div>	
	 )

  }
}
export default myChannelsParticipant;
