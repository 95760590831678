import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import ReportsPages from '../reports/ReportsPages';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import moment from 'moment';

class ChallengeReports extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchBy: '',
					liveClassesAttended: null,
					practiceAttended: null,
					practiceSquats: null,
					qualificationAttended: null,
					qualificationSquats: null,
					competeSquats: null,
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					sortByVal: ['users.id', 'DESC'],
					ajaxLoaded: false,
					checkInfoOpen: false,
					itemsDescription: 'Members',
					allWeeks: [],
					filterWeek: '',
					membersArray: [],
					filterBoolOptions: [{ title: 'Live Classes Attended', param: 'liveClassesAttended'},
										{ title: 'Practice Attended', param: 'practiceAttended'},
										{ title: 'Practice Squats', param: 'practiceSquats'}, 
										{ title: 'Qualification Attended', param: 'qualificationAttended'},
										{ title: 'Qualification Squats', param: 'qualificationSquats'},
										{ title: 'Competition Squats', param: 'competeSquats'}]
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	
	this.fetchMemberDetails(0, 10);
}

changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchMemberDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchMemberDetails(mod, this.state.page_num_count);
		}
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ 
							showSearch: !this.state.showSearch, 
							liveClassesAttended: null, 
							practiceAttended: null, 
							practiceSquats: null, 
							qualificationAttended: null, 
							qualificationSquats: null, 
							competeSquats: null, 
							searchBy: ''}, ()=>{
								this.fetchMemberDetails(this.state.page_id, this.state.page_num_count);
							});
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchMemberDetails(pg, this.state.page_num_count); 
}

fetchMemberDetails = (page_id, countId, searchText = this.state.searchBy) => {

	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});

  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getChallengeReports", {
																			page_id: page_id,
																			countId: countId,
																			searchText: searchText,
																			sortby: this.state.sortByVal,
																			
																			
																			liveClassesAttended: this.state.liveClassesAttended,
																			practiceAttended: this.state.practiceAttended,
																			practiceSquats: this.state.practiceSquats,
																			qualificationAttended: this.state.qualificationAttended,
																			qualificationSquats: this.state.qualificationSquats,
																			competeSquats: this.state.competeSquats,
																		})

  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({membersArray: res.data.responseData.challengeData, maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	
	if( (this.state.page_id+3) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			let newState = this.state.page_id < 3 ? 0 : this.state.page_id - 2;
			
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });
}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchMemberDetails(this.state.page_id, this.state.page_num_count);
	});
}


handleFilterChange = (e) => {

	let mainthis = this;
	this.setState({
		[e.id]: e.value
	});
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
							
								<div className="pay_head">
									<div className="pay_head_title">Challenge Reports</div>
								</div>
								<div className="show_pay_page chwidth15">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
											<option value="10000000">All</option>
										</select>
									 </span>
									{this.state.itemsDescription}
								</div>
								<div className="show_search_opt chwidth85">
								
								{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className="col-lg-12 col-md-12 d-flex d-flex flex-wrap align-items-center col-xl-12">
														<input type="text" className="form-control" placeholder="Search for member name or email" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
														{ this.state.filterBoolOptions.map((item, key)=>
																		<div key={key} className="form-group-header interest_search">
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label text_wrap text_limit">{item.title}</label>
																			<select className="input-field dashboard_main_select" id={item.param} value={this.state[item.param]} onChange={(e) => this.handleFilterChange(e.target)} >
																				<option value=""></option> 
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>
																	)}
													</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
								}
								
								
								</div>
								{ this.state.membersArray.length > 0 && 
									<div>
										<table className="billing_table">
											<thead>
												<tr>
													<th className="paddR20">Member Name
														<div onClick={ (e) => this.sortArrays('users.name')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Member Email
														<div onClick={ (e) => this.sortArrays('users.email')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Account Created
														<div onClick={ (e) => this.sortArrays('users.createAt')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Live Classes Attended
														<div onClick={ (e) => this.sortArrays('sessions_count')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Practice Attended
														<div onClick={ (e) => this.sortArrays('practice_count')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Practice Squats
														<div onClick={ (e) => this.sortArrays('practice_squat_count')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Qualification Attended
														<div onClick={ (e) => this.sortArrays('qu_count')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Qualification Squats
														<div onClick={ (e) => this.sortArrays('qu_squat_count')} className="reports_sort_icon vertMidd"></div>
													</th>
													<th>Competition Squat
														<div onClick={ (e) => this.sortArrays('compete_squat_count')} className="reports_sort_icon vertMidd"></div>
													</th>
												</tr>
											</thead>
											<tbody>
												{ this.state.membersArray.map((item, key) =>
													<tr key={key}>
														<td>{item['name']} {item['lastName']}</td>
														<td>{item['email']}</td>
														<td>{this.getSessionDate(item['createAt'])}</td>
														<td>{item['sessions_count']}</td>
														
														<td>{item['practice_count']}</td>
														<td>{(item['practice_squat_count'] ? item['practice_squat_count'] : 0)}</td>
														
														<td>{item['qu_count']}</td>
														<td>{(item['qu_squat_count'] ? item['qu_squat_count'] : 0)}</td>
														<td>{(item['compete_squat_count'] ? item['compete_squat_count'] : 0)}</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								}
								
								
								{ this.state.membersArray.length > 0 && 
									<ReportsPages 
										page_id={this.state.page_id}
										page_num_count={this.state.page_num_count}
										fullCount={this.state.fullCount}
										maxPages={this.state.maxPages}
										changePage={this.changePage}
										page_numbers={this.state.page_numbers}
										checkInfoOpen={this.state.checkInfoOpen}
										itemsDescription={this.state.itemsDescription}
									/>
								}
								
								{ this.state.membersArray.length == 0 && 
									<div className="noResults2">No Members Found</div> 
								}
							</div>
						</div>
					</div>
				</div>
			</div>		
		</div>
    );
  }
}

export default ChallengeReports;