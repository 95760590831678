import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import moment1 from 'moment-timezone';

import SimpleReactValidator from 'simple-react-validator';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import {  browserHistory } from 'react-router'
import Countdown from 'react-countdown-now';
import Login from '../auth/Login';
import MainSignUp from '../auth/MainSignUp';
import ChargingSession from '../home/ChargingSession';
import { submitHost } from '../../util/submitHost';
import { verify } from '../../util/verify';
import { channelCheckUser } from '../../util/channelCheckUser';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import Header from './Header';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import ReactLightCalendar from '@lls/react-light-calendar'
import '@lls/react-light-calendar/dist/index.css'
import SupportContainer from '../home/SupportContainer';


class HostSettings extends Component {
constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state = {
		sessionProperty: true,
		sessionPublishPage: true,
		sessionSearchable: true,
		showOtherParticipants: true,
		showHostParticipant: true,
		startRecording: true,
		sessionViewDemand: true,
		requireCamera: true,
		defaultRepsTime: 0,
		showPublic: false,
		showPublish: false,
		showSearchable: false,
		showParticipants: false,
		showHost: false,
		showRecording: false,
		showViewDemand: false,
		openedFromComp: true,
		mainFaqs: [],
		supportPageName: 'Host Settings',
		supportPageApi: '/HostSettings',
		userTypeFaqs: 2
	}
}

componentDidMount(){
	this.state.openedFromComp = true;
	this.showHostData();
}

showHostData=(e)=>
{
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/insertHostSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel, "user_id" : JSON.parse(localStorage.getItem('userData')).data.responseData.id})
	.then(res_e => {
		console.log(res_e.data.responseData, 'RESPONSE DATA');
		this.setState({
			sessionProperty: res_e.data.responseData.publicSession===0?false:true,
			sessionPublishPage: res_e.data.responseData.publishChannelPage===0?false:true,
			sessionSearchable: res_e.data.responseData.sessionSearchable===0?false:true,
			showOtherParticipants: res_e.data.responseData.showParticipants===0?false:true,
			showHostParticipant: res_e.data.responseData.showHostParticipant===0?false:true,
			startRecording: res_e.data.responseData.recordSession===0?false:true,
			sessionViewDemand: res_e.data.responseData.sessionOnDemandFree===0?false:true,
			requireCamera: res_e.data.responseData.requireCamera===0?false:true
		});
		if(this.state.openedFromComp)
			{
				this.setState({
					defaultRepsTime: res_e.data.responseData.defaultRepsTime
				});
			}
		
	}).catch(err =>{ });
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/insertChannelSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel})
	.then(res_e => {
		console.log(res_e.data.responseData, 'RESPONSE DATA');
		this.setState({
			showPublic: res_e.data.responseData.allowSessionProperty===0?false:true,
			showPublish: res_e.data.responseData.allowSessionPublicChannel===0?false:true,
			showSearchable: res_e.data.responseData.allowSessionSearchable===0?false:true,
			showParticipants: res_e.data.responseData.allowShowOtherParticipants===0?false:true,
			showHost: res_e.data.responseData.allowShowHostParticipant===0?false:true,
			showRecording: res_e.data.responseData.allowStartRecording===0?false:true,
			showViewDemand: res_e.data.responseData.allowSessionViewDemand===0?false:true,
			showRequireCamera: res_e.data.responseData.allowRequireCameraHost===0?false:true,
		});
		
	}).catch(err =>{ });
}

updateState = (newState) => {
	this.setState(newState);
}

updateHostSettings=(e)=>{
	this.state.openedFromComp = false;
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	if(this.state.defaultRepsTime != '' && this.state.defaultRepsTime <= 200)
		{
			this.setState({
				sessionProperty: this.state.sessionProperty===false?0:1,
				sessionPublishPage: this.state.sessionPublishPage===false?0:1,
				sessionSearchable: this.state.sessionSearchable===false?0:1,
				showOtherParticipants: this.state.showOtherParticipants===false?0:1,
				showHostParticipant: this.state.showHostParticipant===false?0:1,
				startRecording: this.state.startRecording===false?0:1,
				sessionViewDemand: this.state.sessionViewDemand===false?0:1,
				requireCamera: this.state.requireCamera===false?0:1,
				showSettingsPopUp: true
			});
			this.props.showMainErrorPop("Changes have been applied");
			
			axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/updateHostSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel, "user_id" : JSON.parse(localStorage.getItem('userData')).data.responseData.id, "publicSession": this.state.sessionProperty, "publishSession" : this.state.sessionPublishPage, "sessionSearchable" : this.state.sessionSearchable, "showOtherParticipants" : this.state.showOtherParticipants, "showHostParticipant" : this.state.showHostParticipant, "startRecording" : this.state.startRecording, "sessionViewDemand" : this.state.sessionViewDemand, "requireCamera" : this.state.requireCamera, "defaultRepsTime" : this.state.defaultRepsTime})
			.then(res_e => {
				
				browserHistory.push("/DashboardLanding");
				
				
			}).catch(err =>{ });
			
			//this.showHostData();
		}
		
	else
		{
			this.props.showMainErrorPop("Default Reps Time cannot be empty and cannot be higher than 200");
		}
	
}

closePopup=(e)=>{
	this.setState({
		showSettingsPopUp: false
	});
}

getFaq=(e)=>{
	 axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqsById", {id: e})          
	.then(res => {
		if(e == 14)
			{
				this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs, true)});
			}
			
		else
			{
				this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs)});
			}
		
	})
	.catch(err =>{ });  
}

  render() {

    return (
		<div className="container-fluid custom-width">
			<Header loginName={this.state.loginName} totalSession={this.state.totalSession} fullsubdomain={this.state.fullsubdomain}></Header>
			<div className="my_profile_inner_container channel_settings_container">
				<div className="repoorts_main_title">Instructor Settings  <img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb host_settings_bulb" onClick={e=>{this.getFaq(14)}}/></div>
				<Link to ="DashboardLanding"><div className="closePopup settings_close_popup channel_settings_close"></div></Link>
				<div className="channel_session_default">
					<div className="default_channel_session">
						<div className="default_channel_title">Instructor Class Defaults</div>
					</div>
				</div>
				<div className="channel_settings_allow_hold">
					{ this.state.showPublic &&
					<div className="main_settings_holder mobileclear channel_settings_choose">
						<span className="main_settings_text">Public Class</span>
						<div className="toggle_option toggle_option_right">{this.state.sessionProperty ? 'ON' : 'OFF'}</div>
						<label className="switch">
							<input type="checkbox" id = "sessionProperty" checked={this.state.sessionProperty} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.sessionProperty})}}/>
							<span className="slider round"></span>
						</label>
						
						<img src="/images/bulb.png" onClick={e=>{this.props.showMainErrorPop('Public Classes allow anyone with the link to sign up for the Class.  To attend a Class that is set to Private, the Member must be a Member of your Channel.')}} className="ml-3 mb-2 cursor-pointer channel_bulb"/>
					</div>
					}
					{ this.state.showPublish &&
					<div className="main_settings_holder mobileclear channel_settings_choose">
						<span className="main_settings_text">Publish on Channel Page</span>
						<div className="toggle_option toggle_option_right">{this.state.sessionPublishPage ? 'ON' : 'OFF'}</div>
						<label className="switch">
							<input type="checkbox" id = "sessionPublishPage" checked={this.state.sessionPublishPage} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.sessionPublishPage})}}/>
							<span className="slider round"></span>
						</label>
						
						<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer channel_bulb" onClick={e=>{this.props.showMainErrorPop('If you want Members to sign up or join the class from your channel page, set it to ON')}}/>
					</div>
					}
					{ this.state.showSearchable &&
					<div className="main_settings_holder mobileclear channel_settings_choose">
						<span className="main_settings_text">Class Searchable on Marketplace</span>
						<div className="toggle_option toggle_option_right">{this.state.sessionSearchable ? 'ON' : 'OFF'}</div>
						<label className="switch">
							<input type="checkbox" id = "sessionSearchable" checked={this.state.sessionSearchable} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.sessionSearchable})}}/>
							<span className="slider round"></span>
						</label>
						
						<img src="/images/bulb.png" onClick={e=>{this.props.showMainErrorPop('If you want Members to sign up or join the class from your channel page, set it to ON. Publishing the class to your channel page will also make the class searchable on virdio.com.')}} className="ml-3 mb-2 cursor-pointer channel_bulb"/>
					</div>
					}
					{ this.state.showParticipants &&
					<div className="main_settings_holder mobileclear channel_settings_choose">
						<span className="main_settings_text">Show other Members</span>
						<div className="toggle_option toggle_option_right">{this.state.showOtherParticipants ? 'ON' : 'OFF'}</div>
						<label className="switch">
							<input type="checkbox" id = "showOtherParticipants" checked={this.state.showOtherParticipants} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.showOtherParticipants})}}/>
							<span className="slider round"></span>
						</label>
						
						<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer channel_bulb" onClick={e=>{this.props.showMainErrorPop('If you want to allow Members to see each other in the Live classes, set it to ON.')}}/>
					</div>
					}
					{ this.state.showHost &&
					<div className="main_settings_holder mobileclear channel_settings_choose d-none">
						<span className="main_settings_text">Switch Instructor/Member PIP</span>
						<div className="toggle_option toggle_option_right">{this.state.showHostParticipant ? 'ON' : 'OFF'}</div>
						<label className="switch">
							<input type="checkbox" id = "showHostParticipant" checked={this.state.showHostParticipant} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.showHostParticipant})}}/>
							<span className="slider round"></span>
						</label>
						
						<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer channel_bulb" onClick={e=>{this.props.showMainErrorPop('Coming Soon.')}} />
					</div>
					}
					{ this.state.showRecording &&
					<div className="main_settings_holder mobileclear channel_settings_choose">
						<span className="main_settings_text">Record Class & Offer On-demand</span>
						<div className="toggle_option toggle_option_right">{this.state.startRecording ? 'ON' : 'OFF'}</div>
						<label className="switch">
							<input type="checkbox" id = "startRecording"  checked={this.state.startRecording} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.startRecording})}}/>
							<span className="slider round"></span>
						</label>
						
						<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer channel_bulb" onClick={e=>{this.props.showMainErrorPop('If you want to Record your class and offer it via On-Demand, set it to ON.')}} />
					</div>
					}
					{ this.state.showViewDemand &&
					<div className="main_settings_holder mobileclear channel_settings_choose d-none">
						<span className="main_settings_text">Class Members View On-demand for 30 days free</span>
						<div className="toggle_option toggle_option_right">{this.state.sessionViewDemand ? 'ON' : 'OFF'}</div>
						<label className="switch">
							<input type="checkbox" id = "sessionViewDemand" checked={this.state.sessionViewDemand} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.sessionViewDemand})}}/>
							<span className="slider round"></span>
						</label>
						
						<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer channel_bulb" onClick={e=>{this.props.showMainErrorPop('Coming Soon.')}} />
					</div>
					}
					{ this.state.showRequireCamera &&
					<div className="main_settings_holder mobileclear channel_settings_choose">
						<span className="main_settings_text">Require Members to have Camera</span>
						<div className="toggle_option toggle_option_right">{this.state.requireCamera ? 'ON' : 'OFF'}</div>
						<label className="switch">
							<input type="checkbox" id = "requireCamera" checked={this.state.requireCamera} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.requireCamera})}}/>
							<span className="slider round"></span>
						</label>
						
						<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer channel_bulb" onClick={e=>{this.props.showMainErrorPop('If you want to allow Members to attend Live classes without a camera, set it to ON.')}} />
					</div>
					}
				</div>
				<div className="form-group channel_reps_time">
					<div className="inline_bulb w80per">
						<span className="cover-border channel_settings_border"></span>
						<label className="label">Default Reps Time</label>
						<input type="number" className="input-field channel_settings_input" id="session_details" value={this.state.defaultRepsTime} onChange={e => this.updateState({defaultRepsTime: e.target.value})}/>
					</div>
					<div className="inline_bulb">
						<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer channel_bulb" onClick={e=>{this.props.showMainErrorPop('You can set the default time for reps based activities in Workout Builder')}}/>
					</div>
				</div>
			</div>
			<div className="saveBtn"><div onClick={e=>{this.updateHostSettings()}}>SAVE</div></div>
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(HostSettings);