import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { browserHistory} from 'react-router'
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
class SessionSettings extends Component {
  
  constructor(props) {
    super(props); 
}
  render() {
    return (
			<div className="for_settings">
				<div className={('one_settings'+(this.props.state.isMarketplaceChannel ? ' d-none' : ''))}>
					<div className="left_settings">
						<div className="settings_name">Public Class</div>
						<div className="inline_bulb" onClick={e=>{this.props.getFaq(22)}}></div>
					</div>
					<label className="switch toggle_align_middle toggle_switch">
						<input type="checkbox" id="sessionProperty" checked={!this.props.state.sessionProperty} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.sessionProperty})}} />
						<span className="slider round"></span>
					</label>
					<div className="toggle_option">{!this.props.state.sessionProperty ? 'ON' : 'OFF'}</div>
				</div>
				<div className={('one_settings'+(this.props.state.isMarketplaceChannel ? ' d-none' : ''))}>
					<div className="left_settings">
						<div className="settings_name">Publish on Channel Page</div>
						<div className="inline_bulb" onClick={()=>{this.props.showMainErrorPop('If you want Members to sign up or join the class from your channel page, set it to ON. Publishing the class to your channel page will also make the class searchable on virdio.com.')}}></div>
					</div>
					<label className="switch toggle_align_middle toggle_switch">
						<input type="checkbox" id="sessionPublicChannel" checked={this.props.state.sessionPublicChannel} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.sessionPublicChannel})}} />
						<span className="slider round"></span>
					</label>
					<div className="toggle_option">{this.props.state.sessionPublicChannel ? 'ON' : 'OFF'}</div>
				</div>
				{ /* <div className="one_settings">
					<div className="left_settings">
						<div className="settings_name">Class Searchable on Virdio.com</div>
						<div className="inline_bulb" onClick={()=>{this.props.showMainErrorPop('If you want Members to sign up or join the class from your channel page, set it to ON. Publishing the class to your channel page will also make the class searchable on virdio.com.')}}></div>
					</div>
					<label className="switch toggle_align_middle toggle_switch">
						<input type="checkbox" id="sessionSearchable" checked={this.props.state.sessionSearchable} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.sessionSearchable})}} />
						<span className="slider round"></span>
					</label>
					<div className="toggle_option">{this.props.state.sessionSearchable ? 'ON' : 'OFF'}</div>
				</div> */ }
				<div className={('one_settings'+(this.props.state.isMarketplaceChannel ? ' d-none' : ''))}>
					<div className="left_settings">
						<div className="settings_name">Start Music at Start Workout</div>
						<div className="inline_bulb" onClick={()=>{this.props.showMainErrorPop('If you want to Start Music when you start your class, set it to ON.')}}></div>
					</div>
					<label className="switch toggle_align_middle toggle_switch">
						<input type="checkbox" id="startMusic" checked={this.props.state.startMusic} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.startMusic})}} />
						<span className="slider round"></span>
					</label>
					<div className="toggle_option">{this.props.state.startMusic ? 'ON' : 'OFF'}</div>
				</div>
				<div className="one_settings">
					<div className="left_settings">
						<div className="settings_name">Start Recording at Start Workout</div>
						<div className="inline_bulb" onClick={()=>{this.props.showMainErrorPop('If you want to Record your class, set it to ON.')}}></div>
					</div>
					<label className="switch toggle_align_middle toggle_switch">
						<input type="checkbox" id="startRecording" checked={this.props.state.startRecording} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.startRecording})}} />
						<span className="slider round"></span>
					</label>
					<div className="toggle_option">{this.props.state.startRecording ? 'ON' : 'OFF'}</div>
				</div>
				<div className="one_settings">
					<div className="left_settings">
						<div className="settings_name">Show Other Members</div>
						<div className="inline_bulb" onClick={()=>{this.props.showMainErrorPop('If you want to allow Members to see each other in the Live classes, set it to ON.')}}></div>
					</div>
					<label className="switch toggle_align_middle toggle_switch">
						<input type="checkbox" id="showOtherParticipants" checked={this.props.state.showOtherParticipants} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.showOtherParticipants})}} />
						<span className="slider round"></span>
					</label>
					<div className="toggle_option">{this.props.state.showOtherParticipants ? 'ON' : 'OFF'}</div>
				</div>
				<div className="one_settings pb40">
					<div className="left_settings">
						<div className="settings_name">Disable Notification Sound</div>
						<div className="inline_bulb" onClick={()=>{this.props.showMainErrorPop('If you want to disable notification sound in the Live classes, set it to ON.')}}></div>
					</div>
					<label className="switch toggle_align_middle toggle_switch">
						<input type="checkbox" id="disableNotificationSound" checked={this.props.state.disableNotificationSound} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.disableNotificationSound})}} />
						<span className="slider round"></span>
					</label>
					<div className="toggle_option">{this.props.state.disableNotificationSound ? 'ON' : 'OFF'}</div>
				</div>
				<div className="container_title">Video Resolutions</div>
				<div className="playlist_main_title def_resulution">
					<div className="resolution_hold definition_select mobileclear less_padd_lefty">
						<select data-toggle="modal" className="input-field select_definition new_select_width fields_back color_white" id="highDefSelect" value={this.props.state.highDefSelect} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:e.target.value})}}>
							{ this.props.state.highResolutions.map((def, i)=> 
								<option className="gray_option" id={i} key={i}>{def}</option>
							)}
						</select>
					</div>
				</div>
			</div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(SessionSettings);
