import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import axios from "axios";

class BrandAmbassadorPTHub extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		mainPlans: [],
		planRows: [],
		showArrow: false,
	};
	
	this.kk = 0;
}
 
componentDidMount(){
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getPlans").then(res => {
		this.setState({mainPlans: res.data.responseData});
	}).catch(err =>{ });
	
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getPlanRows").then(res => {
		this.setState({planRows: res.data.responseData});
	}).catch(err =>{ });
	
	window.addEventListener('scroll', this.handleScroll);
};

handleScroll=()=>{
    let scrollTop = $(document).scrollTop();
	if ( scrollTop > 500 ) { this.setState({showArrow: true}); }
	else { this.setState({showArrow: false}); }
	
	this.kk = 0;
}

scrollTo=(id)=>{
	if ( $('#'+id+'') )
		{
			var scrollToVar = $('#'+id+'').offset().top;
			$("html, body").animate({ scrollTop: scrollToVar}, "fast");
		}
}

openInfoPopUp = (e) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
}

closeInfoPopUp=(e)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	
};

stopClick = (e) => {
	e.stopPropagation();
	e.preventDefault();
}

openPlus = (iid) => {
	if ( $('.parentplan_'+iid).hasClass('active') )
	{
		$('.parentplan_exists').removeClass('active');
	}
	else
	{
		$('.parentplan_exists').removeClass('active');
		$('.parentplan_'+iid).addClass('active');
	}
	
}

  render() {
	 return (
		<div className="channel-signup-container" id="top_page">
			<div className="channel_signup_header">
				<div className="virdio_ba_logo"></div>
				<div className="signup_header_items" onClick={()=>{this.scrollTo('about_virdio')}}>ABOUT VIRDIO</div>
				<div className="signup_header_items" onClick={()=>{this.scrollTo('features_divs')}}>FEATURES</div>
				<div className="signup_header_items lst" onClick={()=>{this.scrollTo('pricing_part')}}>PRICING</div>
			</div>
			{ this.state.showArrow && <div className="arrow_scroll" onClick={()=>{this.scrollTo('top_page')}}></div> }
			<div className="first_container_signup">
				<div className="first_container_middle">
					<div className="partners_logo">
						<img src="/images/pt_hub_logo.png" />
						<div>Has Partnered With <img src="/images/login-logo.png" alt="logo" /></div>
					</div>
					<div className="first_container_title">To Bring You The Most Advanced</div>
					<div className="first_container_title fc_second_title">Remote Fitness Training Platform</div>
					<div className="relative_line_ba">
						<div className="absolute_line_inner">Live Fitness Classes, Anytime Anywhere</div>
					</div>
					<div className="for_container_btn">
						<a href={process.env.REACT_APP_ADMINURL+'/trainingPlans#MYPTHUB30'}><div className="first_container_btn">START 15-DAY FREE TRIAL</div></a>
					</div>
					<div className="first_container_info">After the Free Trial Get 30% Off Using Promo Code</div>
				</div>
			</div>
			<div className="second_signup_container" id="about_virdio">
				<div className="main_ba_title_centered">ABOUT VIRDIO</div>
				<div className="padding_containers">
					<div className="left_container_ba vertical_mid">
						<div className="mac_activity_img"></div>
					</div>
					<div className="right_container_ba vertical_mid">
						<div className="second_container_text">Virdio is the leading platform for hosting remote interactive live and on-demand workouts, enabling personal trainers and gyms to train their clients anywhere in the world.</div>
						<div className="for_secondcnt_button">
							<a href={process.env.REACT_APP_ADMINURL+'/trainingPlans#MYPTHUB30'}><div className="first_container_btn">START 15-DAY FREE TRIAL</div></a>
						</div>
					</div>
				</div>
				<div className="main_ba_title_centered features_title" id="features_divs">FEATURES</div>
				<div className="curved_gray_container white_curved"></div>
			</div>
			<div className="third_signup_container">
				<div className="main_ba_title">LIVE INTERACTIVE CLASSES</div>
				<div className="left_container_ba vertical_top">
					<div className="for_fourth_container">
						<div className="one_third_row">Customizable Workouts with Virtual Equipment and Virtual Assistant<div className="abs_row_dot"></div></div>
						<div className="one_third_row">Integrated Music and Pre-recorded Videos<div className="abs_row_dot"></div></div>
						<div className="one_third_row">One-time or Recurring Classes<div className="abs_row_dot"></div></div>
						<div className="for_fourth_btn">
							<a href={process.env.REACT_APP_ADMINURL+'/trainingPlans#MYPTHUB30'}><div className="first_container_btn">START 15-DAY FREE TRIAL</div></a>
						</div>
					</div>
				</div>
				<div className="right_container_ba vertical_top">
					<div className="smaller_mac_activity_img"></div> 
				</div>
				<div className="curved_gray_container"></div>
			</div>
			<div className="fourth_signup_container">
				<div className="main_ba_title">ON-DEMAND CLASSES</div>
				<div className="left_container_ba vertical_top">
					<div className="third_mac_activity"></div>
				</div>
				<div className="right_container_ba vertical_top">
					<div className="for_third_container">
						<div className="one_third_row">Record Live Classes and offer them On-Demand<div className="abs_row_dot"></div></div>
						<div className="one_third_row">Track Heart Rate, Calories and VO2 Max with Wearables<div className="abs_row_dot"></div></div>
						<div className="one_third_row">Upload Videos not Recorded in Virdio<div className="abs_row_dot"></div></div>
						<div className="for_third_btn">
							<a href={process.env.REACT_APP_ADMINURL+'/trainingPlans#MYPTHUB30'}><div className="first_container_btn">START 15-DAY FREE TRIAL</div></a>
						</div>
					</div>
				</div>
				<div className="curved_gray_container white_curved"></div>
			</div>
			
			<div className="fifth_signup_container">
				<div className="main_ba_title">ANY DEVICE, ANYWHERE</div>
				<div className="left_container_ba vertical_top">
					<div className="for_fifth_container">
						<div className="one_third_row">macOS and Windows Apps<div className="abs_row_dot"></div></div>
						<div className="one_third_row">Browser Support (Chrome, Edge, Safari, and Firefox)<div className="abs_row_dot"></div></div>
						<div className="one_third_row">iOS/Android Mobile and Tablet Support<div className="abs_row_dot"></div></div>
						<div className="for_third_btn fifth_btn">
							<a href={process.env.REACT_APP_ADMINURL+'/trainingPlans#MYPTHUB30'}><div className="first_container_btn">START 15-DAY FREE TRIAL</div></a>
						</div>
					</div>
				</div>
				<div className="right_container_ba vertical_top">
					<div className="mobile_devicesimg"></div>
				</div>
				<div className="curved_gray_container"></div>
			</div>
			<div className="sixth_signup_container" id="pricing_part">
				<div className="main_ba_title_centered pricing_title">PRICING</div>
				<div className="curved_gray_container white_curved"></div>
			</div>
			<div className="seventh_signup_container">
				<div className="for_container_btn">
					<a href={process.env.REACT_APP_ADMINURL+'/trainingPlans#MYPTHUB30'}><div className="first_container_btn">START 15-DAY FREE TRIAL</div></a>
				</div>
				<div className="plans_title_ba">Live Streaming Plan</div>
				<div className="scroll_part_train scroll_part_traintop">
					<div className="all_training_main">
						<form autoComplete="off">
							<div className="all_width_part">
								<div className="widder_train coupDiv"></div>
								{this.state.mainPlans.map((item, i)=> ( item.planType == 'live' &&
									<div key={i} className="smaller_train">
										<div className="header_smaller">{item.planName}</div>
										<div className={"all_bottom_info " + (item.planName === 'Enterprise' ? 'enter_padd' : '')}>
											{ item.planName === 'Pro' &&
												<div>
													<div className="pay_i">${item.planPrice}/Month</div>
													<div className="for_rel_i">
														<div className="rel_info">Included {item.includedParticipants} Members/Month</div>
													</div>
													<div className="start_at">${(item.additionalParticipants ? item.additionalParticipants.toFixed(2) : '0.00' )}/additional Member</div>
													
												</div>
											}
											{ item.planName === 'Group' &&
												<div>
													<div className="pay_i">${item.planPrice}/Month</div>
													<div className="for_rel_i">
														<div className="rel_info">Included {item.includedParticipants} Members/Month</div>
													</div>
													<div className="start_at">${(item.additionalParticipants ? item.additionalParticipants.toFixed(2) : '0.00' )}/additional Member</div>
												</div>
											}
											{ item.planName === 'Enterprise' &&
												<div>
													<div className="start_at">For {item.includedParticipants} + Member per Month</div>
													<div className="start_at">Starting at $0.59/Member​</div>
												</div>
											}
										</div>
									</div>
								) )}
							</div>
						</form>
						<table className="all_width_part">
							<tbody className="all_width_part">
								{this.state.planRows.map((item, i)=> ( item.rowType == 'live' &&
									<tr key={i} className="all_width_part">
										<th className={"widder_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : ' '+( this.kk++ ))}>
											<div className="column_title">{item.rowName} {item.rowType}</div>
											{ item.haveInfo === 1 &&
												<div className="abs_circle_info mar_column" onClick={ (e) => this.openInfoPopUp(e) }>
													<div className="all_info pink_info1 all_info_daily all_session_info training_info" onClick={ (e) => this.stopClick(e) }>
														<div className="close_all_info">
															<div className="close_all_info_btn" onClick={ (e) => this.closeInfoPopUp(e)}>x</div>
														</div>
														{item.infotext}
													</div>
												</div>
											}
											{ item.havePlus === 1 &&
												<div className="app_plus"  onClick={ (e) => this.openPlus(item.id) }></div>
											}
										</th>
										<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenPlanName === 'Pro' ? ' selected_train' : '') + (item.id === 27 ? ' lst_smaller' : '')}>
											{ item.proPlan === 'checked' &&
												<div className="check_mark"></div>
											}
											{ item.proPlan === 'not_checked' &&
												<div className="check_mark iks_mark"></div>
											}
											{ item.proPlan !== 'checked' && item.proPlan !== 'not_checked' && item.proPlan !== '' &&
												<div>
													{ item.proPlan !== '99' &&
														<div>
															{ item.id === 3 &&
																<div className="column_text">{item.proPlan} Instructors</div>
															}
															{ item.id === 5 &&
																<div className="column_text">{item.proPlan}</div>
															}
															{ item.id === 20 &&
																<div className="column_text">Includes Cloud Storage for {item.proPlan} Classes</div>
															}
															{ item.id === 22 &&
																<div className="column_text">Create {item.proPlan} playlist per Instructor</div>
															}
														</div>
													}
													{ item.proPlan == '99' &&
														<div className="column_text">Call to discuss</div>
													}
												</div>
											}
											{ item.proPlan == '' &&
												<div className="empty_column"></div>
											}
										</th>
										<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenPlanName === 'Group' ? ' selected_train' : '') + (item.id === 27 ? ' lst_smaller' : '')}>
											{ item.groupPlan === 'checked' &&
												<div className="check_mark"></div>
											}
											{ item.groupPlan === 'not_checked' &&
												<div className="check_mark iks_mark"></div>
											}
											{ item.groupPlan !== 'checked' && item.groupPlan !== 'not_checked' && item.groupPlan !== '' &&
												<div>
													{ item.groupPlan !== '99' &&
														<div>
															{ item.id === 3 &&
																<div className="column_text">{item.groupPlan} Instructors</div>
															}
															{ item.id === 5 &&
																<div className="column_text">{item.groupPlan}</div>
															}
															{ item.id === 20 &&
																<div className="column_text">Includes Cloud Storage for {item.groupPlan} Classes</div>
															}
															{ item.id === 22 &&
																<div className="column_text">Create {item.groupPlan} playlist per Instructor</div>
															}
														</div>
													}
													{ item.groupPlan == '99' &&
														<div className="column_text">Call to discuss</div>
													}
												</div>
											}
											{ item.groupPlan == '' &&
												<div className="empty_column"></div>
											}
										</th>
										<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenPlanName === 'Enterprise' ? ' selected_train' : '') + (item.id === 27 ? ' lst_smaller' : '')}>
											{ item.enterprisePlan === 'checked' &&
												<div className="check_mark"></div>
											}
											{ item.enterprisePlan === 'not_checked' &&
												<div className="check_mark iks_mark"></div>
											}
											{ item.enterprisePlan !== 'checked' && item.enterprisePlan !== 'not_checked' && item.enterprisePlan !== '' &&
												<div>
													{ item.enterprisePlan !== '99' &&
														<div>
															{ item.id === 3 &&
																<div className="column_text">{item.enterprisePlan} Instructors</div>
															}
															{ item.id === 5 &&
																<div className="column_text">{item.enterprisePlan}</div>
															}
															{ item.id === 20 &&
																<div className="column_text">Includes Cloud Storage for {item.enterprisePlan} Classes</div>
															}
															{ item.id === 22 &&
																<div className="column_text">Create {item.enterprisePlan} playlist per Instructor</div>
															}
														</div>
													}
													{ item.enterprisePlan == '99' &&
														<div className="column_text">Contact to Discuss</div>
													}
												</div>
											}
											{ item.enterprisePlan == '' &&
												<div className="empty_column"></div>
											}
										</th> 
									</tr>
								) )}
							</tbody>
						</table>
					</div>
				</div>
				<div className="plans_title_ba ba_demand_title">On-Demand Plan (Optional)</div>
				<div className="scroll_part_train ondemandplanss">
					<div className="all_training_main">
						<div className="all_width_part">
							<div className="widder_train"></div>
							{this.state.mainPlans.map((item, i)=> ( item.planType == 'ondemand' &&
								<div key={i} className="smaller_train">
									<div className="header_smaller">{item.planName}</div>
									<div className={"all_bottom_info " + (item.planName === 'Enterprise' ? 'enter_padd' : '')}>
										{ item.planName === 'Pro' &&
											<div>
												<div className="pay_i">${item.planPrice}/Month</div>
												<div className="start_at">${(item.additionalClient ? item.additionalClient.toFixed(2) : '0.00' )}/additional Client</div>
											</div>
										}
										{ item.planName === 'Group' &&
											<div>
												<div className="pay_i">${item.planPrice}/Month</div>
												<div className="start_at">${(item.additionalClient ? item.additionalClient.toFixed(2) : '0.00' )}/additional Client</div>
											</div>
										}
										{ item.planName === 'Enterprise' &&
											<div>
												<div>Contact to Discuss</div>
											</div>
										}
									</div>
								</div>
							) )}
						</div>
					
						<table className="all_width_part mb60">
							<tbody className="all_width_part">
								{this.state.planRows.map((item, i)=> ( item.rowType == 'ondemand' &&
									<tr key={i} className="all_width_part">
										<th className={"widder_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : ' '+( this.kk++ ))}>
											<div className="column_title">{item.rowName}</div>
											{ item.haveInfo === 1 &&
												<div className="abs_circle_info mar_column" onClick={ (e) => this.openInfoPopUp(e) }>
													<div className="all_info pink_info1 all_info_daily all_session_info training_info" onClick={ (e) => this.stopClick(e) }>
														<div className="close_all_info">
															<div className="close_all_info_btn" onClick={ (e) => this.closeInfoPopUp(e)}>x</div>
														</div>
														{item.infotext}
													</div>
												</div>
											}
											
											{ item.havePlus === 1 &&
												<div className="app_plus"  onClick={ (e) => this.openPlus(item.id) }></div>
											}
										</th>
										<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenDemandPlanName === 'Pro' ? ' selected_train' : '') + (item.id === 30 ? ' lst_smaller' : '')}>
											<div><div className="column_text">{ item.id == 30 ? '$' : '' }{item.proPlan}</div></div>
										</th>
										<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenDemandPlanName === 'Group' ? ' selected_train' : '') + (item.id === 30 ? ' lst_smaller' : '')}>
											<div className="column_text">{ item.id == 30 ? '$' : '' }{item.groupPlan}</div>
										</th>
										<th className={"smaller_train lighter_color_blc " + (Math.abs(this.kk % 2) == 1 ? 'darker_color_blc' : '') + ' parentplan_'+item.parentId + ( item.parentId > 0 ? ' parentplan_exists' : '') + (this.state.chosenDemandPlanName === 'Enterprise' ? ' selected_train' : '') + (item.id === 30 ? ' lst_smaller' : '')}>
											<div className="column_text">{item.enterprisePlan}&nbsp;</div>
										</th> 
									</tr>
								) )}
							</tbody>
						</table>
					</div>
				</div>
				<div className="curved_gray_container plans_curved"></div>
			</div>
			<div className="footer_ba">Copyright 2020. All Rights Reserved.
			</div>
		</div>	
    );
  }
}


export default BrandAmbassadorPTHub;