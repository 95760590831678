import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { browserHistory} from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import Sortable from 'react-sortablejs';
import uniqueId from 'lodash/uniqueId';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
class SessionScript extends Component {
  
  constructor(props) {
    super(props);
	this.scriptValidate = new SimpleReactValidator(); 
}

	printScreen = () =>
	{
		var divToPrint = document.querySelector('.print_popuphidden');
		var htmlToPrint = '<link href="'+process.env.REACT_APP_PATH+'/css/style.css" rel="stylesheet">';
		htmlToPrint += divToPrint.innerHTML;
		var newWin = window.open("");
		if ( newWin )
			{
				newWin.document.write(htmlToPrint);
				
				if ( /apple/i.test(navigator.vendor) )
					{
						try { setTimeout(() => { newWin.document.execCommand('print', false, null); }, 2000); setTimeout(() => { newWin.close(); }, 3000); } catch { newWin.print(); newWin.close(); }
					}
				else
					{
						newWin.print(); newWin.close();
					}
			}
	}

  render() {
	let  arr =[];
    return (<div>
			
				<div>
					{ this.props.state.isMarketplaceChannel &&
						<div className="activity_sounds_container">
							<div className="activity_sound_title">Activity Sounds <div class="right_section_printt" onClick={()=> { this.printScreen(); }}>PRINT</div></div>
							<div className="form-group mt-3 mb-1">
								<span className="cover-border cover-border_create"></span>
								<label className="label">Activity Sound Start</label>
								<select className="input-field fields_back" style={{color:(this.props.state.workoutSoundStart !== "None"?"white":'')}} id="workoutSoundStart" value={this.props.state.workoutSoundStart} onChange = {(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value})}>
									{ this.props.state.workoutStartOptions.map((row, i) =>
										<option key={i} value={row}>{row}</option>
									)}
								 </select>
							</div>
							<div className="form-group mt-3 mb-1">
								<span className="cover-border cover-border_create"></span>
								<label className="label">Activity Sound End</label>
								<select className="input-field fields_back" style={{color:(this.props.state.workoutSoundEnd !== "None"?"white":'')}} id="workoutSoundEnd" value={this.props.state.workoutSoundEnd} onChange = {(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value})}>
									{ this.props.state.workoutEndOptions.map((row, i) =>
										<option key={i} value={row}>{row}</option>
									)}
								 </select>
							</div>
							<div className="form-group mt-3 mb-1">
								<span className="cover-border cover-border_create"></span>
								<label className="label">Show Personal Best</label>
								<select className="input-field fields_back" style={{color:(this.props.state.showPersonalBest ?"white":'')}} id="showPersonalBest" value={this.props.state.showPersonalBest} onChange = {(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value})}>
									{ this.props.state.showPersonalBestOptions.map((row, i) =>
										<option key={i} value={row}>{row}</option>
									)}
								 </select>
							</div>
						</div>
					}
					<div className="inline_with_copy addw40">
						<div className="container_title inline_title">Workout Builder</div>
						<div className="inline_bulb" onClick={e=>{this.props.getFaq(28)}}></div>
						<div className="copyWork w_unset" data-toggle="modal" data-target="#allprevsession" onClick={e=>{this.props.fetchPrevSessionListTemp()}} onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') } aria-hidden="true">
							<svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="svg_copy_icon"><path d="M1696 384q40 0 68 28t28 68v1216q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-288h-544q-40 0-68-28t-28-68v-672q0-40 20-88t48-76l408-408q28-28 76-48t88-20h416q40 0 68 28t28 68v328q68-40 128-40h416zm-544 213l-299 299h299v-299zm-640-384l-299 299h299v-299zm196 647l316-316v-416h-384v416q0 40-28 68t-68 28h-416v640h512v-256q0-40 20-88t48-76zm956 804v-1152h-384v416q0 40-28 68t-68 28h-416v640h896z" fill="#ffffff"/></svg>
							<div className="tooltip_hold pink_info tp25">
								<div className="tooltip_text">Copy Workout from Previous Class/Template</div>
							</div> 
						</div>
						<div className="inline_bulb" onClick={e=>{this.props.showMainErrorPop('Virdio allows you to quickly build your workout by copying from a previous class to template.', true)}}></div>
						{!this.props.state.isMarketplaceChannel &&
							<div className="form-group mt-3 mb-1 default_reps_hold2 def_for_other margtp30">
								<span className="cover-border cover-border_create"></span>
								<label className="label">Default Reps Time</label>
								<input
									className="input-field"
									id="repsDefaultTime"
									value = {this.props.state.repsDefaultTime}
									style={{color:(this.props.state.DurationType?"white":'')}} 

									onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value, repsSeconds:e.target.value})}
								  />
							  </div>
						  }
					</div>
					<div className="for_copy_workout addw60">
						 <div className={((this.props.state.isMarketplaceChannel ? ' d-none' : ''))}>
							<div className="settings_searchable padd10tpbt">
								<div className="inline_bulb ch_bulb" onClick={e=>{this.props.showMainErrorPop('You can have your workout run on an automatic or manual script. If Automatic is turned on your workout will move automatically from exercise to exercise. If Manual is turned on, the Instructor will have to move the script manually after each exercise ends to the next exercise.', true)}}></div>
								<span className="main_settings_text automatic_script_text fnt12 no_padd_right">Automatic</span>
								<label className="switch">
									<input type="checkbox" id="manualAutomaticScript" checked={this.props.state.manualAutomaticScript} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.manualAutomaticScript}, true)}}/>
									<span className="slider round"></span>
								</label>
								<div className="toggle_option">{this.props.state.manualAutomaticScript ? 'ON' : 'OFF'}</div>
							</div>
						 </div>
						 <div className={((this.props.state.isMarketplaceChannel ? ' d-none' : ''))}>
							{ this.props.state.showVirtual === 1 &&
								<div className="settings_searchable allow_left padd10tpbt">
									<div className="inline_bulb ch_bulb" onClick={e=>{this.props.showMainErrorPop('If this feature is turned on, the Members in the Class can move and set up any virtual equipment in the Class.', true)}}></div>
									<span className="main_settings_text automatic_script_text fnt12 no_padd_right">Allow member to move equipment</span>
									<label className="switch allow_move_switch marbot05">
										<input type="checkbox" id="allowPartMove" checked={this.props.state.allowPartMove} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.allowPartMove})}}/>
										<span className="slider round"></span>
									</label>
									<div className="toggle_option">{this.props.state.allowPartMove ? 'ON' : 'OFF'}</div>
								</div>
							}
						 </div>
						 <div className={((this.props.state.isMarketplaceChannel ? ' d-none' : ''))}>
							<div className="settings_searchable allow_left_par padd10tpbt">
								<div className="inline_bulb ch_bulb" onClick={e=>{this.props.showMainErrorPop('If this feature is turned on, Virdio will automatically calculate the height and distance of movement and angles.', true)}}></div>
								<span className="main_settings_text automatic_script_text fnt12 no_padd_right">Calculate Dimensions During FMS</span>
								<label className="switch allow_move_switch marbot05">
									<input type="checkbox" id="calculateAssessmentDimensions" checked={this.props.state.calculateAssessmentDimensions} onChange = {(e)=>{this.props.updateStateFromChild({[e.target.id]:!this.props.state.calculateAssessmentDimensions})}}/>
									<span className="slider round"></span>
								</label>
								<div className="toggle_option">{this.props.state.calculateAssessmentDimensions ? 'ON' : 'OFF'}</div>
							</div>
						 </div>
					</div>
				  <div className="relative">
					<div className="pb-3 activity-form all_act_form">
					<div className="add_shop" onClick = {this.props.addRow}></div>
					<div className="border-bottom">
					  <div className="row mx-0 pb20">
					  
					  <div className={"col-xl-"+(this.props.state.isMarketplaceChannel?'4':'2')+" col-lg-3 col-md-4 px-2"}>
						
						<div className="form-group mt-3 mb-1">
							<span className="cover-border cover-border_create"></span>
							<label className="label">Activity type</label>
						<select className="input-field fields_back"
						id="ActivityType" 
						value = {this.props.state.ActivityType}  
						style={{color:(this.props.state.ActivityType ? "white" : "")}} 
					   onChange = {(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, ActivityName:'', ActivityDisplayName:''})}>
						{this.props.activitynewtype}
						  </select>
						  </div>
					  </div>
					
					 {this.props.state.ActivityType!=="Virtual Equipment" && this.props.state.ActivityType!=="Virtual Assistant" &&
					  <div className={"col-xl-"+(this.props.state.isMarketplaceChannel?'4':'2')+" col-lg-3 col-md-4 px-2 pl-md-0"}>
						<div className="form-group mt-3 mb-1">
							<span className="cover-border cover-border_create"></span>
							<label className="label">Activity name ({this.props.state.ActivityLengthLeft}) </label>
							<input type="text"
							id = "ActivityName" 
							value= {this.props.state.ActivityName} 
							onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} 
							className="input-field"
							maxLength='30'
							ref={c => (this.props.updateMainInput(c))} />
						  </div>
						  {this.scriptValidate.message('Activity name', this.props.state.ActivityName, 'required')}
					 </div> }

					  {this.props.state.ActivityType=="Virtual Equipment" &&
						<div className={"col-xl-"+(this.props.state.isMarketplaceChannel?'4':'2')+" col-lg-2 col-md-4 px-2"}>
							<div className="form-group mt-3 mb-1">
								<span className="cover-border cover-border_create"></span>
								<label className="label">Activity name</label>
								<select
									className="input-field fields_back"
									id="ActivityName"
									value = {this.props.state.ActivityName}
									style={{color:(this.props.state.ActivityName?"white":'')}}
									onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})}>
									<option></option>
									<option>Agility Ladder Horizontal</option>
									<option>Boxing Bag 1</option>
									<option>Box Horizontal</option>
									<option>Diagonal Cones</option>
									<option>Bar High Above Hands – On Ground</option>
									<option>Lateral Cones</option>
									<option>Lateral Circles</option>
									<option>Lunge Box Touch 45 Degrees</option>
									<option>Lunge Straight</option>
									<option>Bar Low Forward Hands</option>
									<option>Reaction Drill - Center</option>
									<option>Squat Box</option>	
									<option>Squat Full</option>	
									<option>Push Up</option>	
									<option>Side Knees</option>	
								  </select>
							</div>
							{this.scriptValidate.message('Activity name', this.props.state.ActivityName, 'required')}
					  </div> }

					  {this.props.state.ActivityType=="Virtual Assistant" &&
						<div className={"col-xl-"+(this.props.state.isMarketplaceChannel?'4':'2')+" col-lg-2 col-md-4 px-2"}>
							<div className="form-group mt-3 mb-1">
								<span className="cover-border cover-border_create"></span>
								<label className="label">Activity name</label>
								<select
									className="input-field fields_back"
									id="ActivityName"
									value = {this.props.state.ActivityName}
									style={{color:(this.props.state.ActivityName?"white":'')}} 

									onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})}
								  >
									<option></option>
									<option>Squat</option>
								  </select>
							</div>
							{this.scriptValidate.message('Activity name', this.props.state.ActivityName, 'required')}
					  </div> }

					  <div className={"col-xl-"+(this.props.state.isMarketplaceChannel?'2':'1')+" col-lg-2 col-md-4 px-2 "+ (this.props.state.isMarketplaceChannel ? 'd-none': '')}>
						<div className="form-group mt-3 mb-1">
							<span className="cover-border cover-border_create"></span>
							<label className="label durationLabel">Duration type</label>
							<select
								className="input-field fields_back"
								id="DurationType"
								value = {this.props.state.DurationType}
								style={{color:(this.props.state.DurationType ? 'white':'')}} 

								onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})}
							  >
								<option></option>
								<option>Time</option>
								{ ( this.props.state.ActivityType !== "Virtual Equipment" && this.props.state.ActivityType !== "Virtual Assistant" ) && <option>Reps</option> }
							  </select>
						  </div>
						  {this.scriptValidate.message('Duration Type', this.props.state.DurationType, 'required')}
					  </div>
					 {this.props.state.DurationType==="Time"?
					  <div className={"col-xl-"+(this.props.state.isMarketplaceChannel?'2':'1')+" col-lg-1 col-md-4 px-2"}>
						<div className="form-group mt-3 mb-1"><span className="cover-border cover-border_create"></span>
						  <label className="label">Minutes</label>
						  <select className="input-field fields_back"
							style={{color:(this.props.state.activityHour?"white" : '')}} 
							id="activityHour"
							value={this.props.state.activityHour} 
							onChange={this.props.sessionInfo}>
							{this.props.forActivityHour()}
						  </select>
						</div>
					  </div>
					  :''}
					  {this.props.state.DurationType==="Time"?
					  <div className={"col-xl-"+(this.props.state.isMarketplaceChannel?'2':'1')+" col-lg-1 col-md-4 px-2"}>
						<div className="form-group mt-3 mb-1"><span className="cover-border cover-border_create"></span>
						  <label className="label">Seconds</label>
							<select className="input-field fields_back" 
							style={{color:(this.props.state.activityminutes?"white" : '')}}
							id="activityminutes" value={this.props.state.activityminutes} 
							onChange={this.props.sessionInfo}>
							{this.props.forActivityMinute()}
							</select>
						  </div>
					  </div>:
					  ''}

					  {this.props.state.DurationType==="Reps" &&
					  <div className="col-xl-2 col-lg-1 col-md-4 px-2">
						<div className="form-group mt-3 mb-1">
							<span className="cover-border cover-border_create"></span>
							<label className="label new_label_pos"># of Reps</label>
							<input type="text"
							id = "Count"
							value = {this.props.state.Count}
							onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})}
							
							className="input-field" />
						  </div>
						  {this.scriptValidate.message('Count', this.props.state.Count, 'required|integer')}
					  </div> }
					  
					  {this.props.state.DurationType==="Reps" &&
					   <div className="col-xl-2 col-lg-1 col-md-4 px-2">
							<div className="form-group mt-3 mb-1"><span className="cover-border cover-border_create"></span>
								  <label className="label">Seconds</label>
									<select className="input-field fields_back" 
									style={{color:(this.props.state.repsSeconds?"white" : '')}}
									id="repsSeconds" value={this.props.state.repsSeconds} 
									onChange={this.props.sessionInfo}>
									{this.props.forActivityMinute()}
									</select>
							 </div>
						  </div> }
					   { ( this.props.state.ActivityType !== "Pause" && this.props.state.ActivityType !== "Pause Virtual" && this.props.state.ActivityType !== "Virtual Equipment" && this.props.state.ActivityType !== "Virtual Assistant" && !this.props.state.isMarketplaceChannel  ) &&
					   <div className="col-xl-2 col-lg-2 col-md-4 px-2">
						<div className="form-group mt-3 mb-1 relative">
							<span className="cover-border cover-border_create"></span>
							<label className="label">Video <img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb session_video_bulb" onClick={e=>{this.props.showMainErrorPop('Insert the url of a pre-recorded video you want to play during your class.')}}/></label>
							<input type="text" placeholder="URL" id="Video" value={this.props.state.Video} className="input-field" onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} />
						  </div>
					  </div> }
					  
					{ ( this.props.state.ActivityType !== "Pause" && this.props.state.ActivityType !== "Pause Virtual" && this.props.state.ActivityType !== "Virtual Equipment" && this.props.state.ActivityType !== "Virtual Assistant" && !this.props.state.isMarketplaceChannel ) &&
					   <div className="col-xl-2 col-lg-1 col-md-4 px-2">
						<div className="form-group mt-3 mb-1 relative">
							<span className=""></span>
							<label className="label durationLabel">Repeat Video</label>
							<div className="videorepeatbtn">
								<label className="switch">
									<input type="checkbox" id="VideoRepeat" checked={this.props.state.VideoRepeat} onChange={(e)=> this.props.updateStateFromChild({[e.target.id]:!this.props.state.VideoRepeat})}/>
									<span className="slider round"></span>
								</label>
								<div className="toggle_option toggle_top">{this.props.state.VideoRepeat ? 'ON' : 'OFF'}</div>
							</div>
						  </div>
					  </div> }

					{ this.props.state.isMarketplaceChannel == 1 && <div style={{flexBasis: '100%'}}></div> }
					
					
					
					{this.props.state.ActivityType =="Virtual Equipment" &&
					  <div className={"col-xl-"+(this.props.state.isMarketplaceChannel?'4':'2')+" col-lg-3 col-md-4 px-2 pl-md-0"}>
						<div className="form-group mt-3 mb-1">
							<span className="cover-border cover-border_create"></span>
							<label className="label">Display Name ({this.props.state.ActivityDisplayLengthLeft}) </label>
							<input type="text" id="ActivityDisplayName" value={this.props.state.ActivityDisplayName} onChange={(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} className="input-field" maxLength='30' ref={c => (this.props.updateMainInput(c))} />
						  </div>
						  {this.scriptValidate.message('Display name', this.props.state.ActivityDisplayName, 'required')}
					 </div> }

					{ this.props.state.isMarketplaceChannel &&
						<div className="col-xl-4 col-lg-2 col-md-4 px-2"> 
							<div className="form-group mt-3 mb-1 relative">
								<span className="cover-border cover-border_create"></span>
								<label className="label">FM Station</label>
								<select className="input-field fields_back" id="chosenFMStation" value={this.props.state.chosenFMStation} onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]: e.target.value})}>
									<option value=""></option>
									{ this.props.state.fmStations.map((row,i) =>
										<option key={i} value={row.id}>{row.name}</option>
									)}
								</select>
							</div>
						</div>
					}
					
					{ this.props.state.isMarketplaceChannel &&
						<div className="col-xl-4 col-lg-3 col-md-4 px-2 pl-md-0">
							<div className="form-group mt-3 mb-1">
								<span className="cover-border cover-border_create"></span>
								<label className="label">Notes ({this.props.state.noteNumLeft}) </label>
								<input type="text"
								id = "ActivityNotes" 
								value= {this.props.state.ActivityNotes} 
								onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} 
								className="input-field"
								maxLength='200'
								/>
							  </div>
						 </div>
					}


				   { ( this.props.state.ActivityType !== "Pause" && this.props.state.ActivityType !== "Pause Virtual" && this.props.state.ActivityType !== "Virtual Equipment" && this.props.state.ActivityType !== "Virtual Assistant" && this.props.state.isMarketplaceChannel  ) &&
					   <div className="col-xl-4 col-lg-2 col-md-4 px-2">
						<div className="form-group mt-3 mb-1 relative">
							<span className="cover-border cover-border_create"></span>
							<label className="label">Video <img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb session_video_bulb" onClick={e=>{this.props.showMainErrorPop('Insert the url of a pre-recorded video you want to play during your class.')}}/></label>
							<input type="text" placeholder="URL" id="Video" value={this.props.state.Video} className="input-field" onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} />
						  </div>
					  </div>
					}
					</div>
					</div>
				  </div>
				  <div className="table-responsive no_overflow_tools activity_scroll">
					  { this.props.state.tablerows.length > 0 &&
							<div className="inner_scrool_x_act">
								<div className="faa_item" style={{fontWeight: 'bold'}}>
									<div>Activity Type</div>
									<div>Activity name</div>
									<div className="w10">Duration Type</div>
									<div>Count</div>
									<div>Video</div>
									<div className="w10">Repeat Video</div>
									{ this.props.state.isMarketplaceChannel &&
										<>
											<div>FM Station</div>
											<div>Notes</div>
										</>
									}
								</div>
								
								<div className="for_all_activity" onMouseLeave={this.props.handleDraggEnd}  onMouseUp={this.props.handleDraggEnd} onMouseMove={this.props.handleDraggitem}>
									{this.props.state.tablerows.map((row,i) => (
										
										<div key={row.sessionScriptId} data-rkey={row.sessionScriptId+i} data-itemkey={i} data-oldarrange={i}>
											{ i !== this.props.state.editActivityToken &&
												<div className={"activity_relative_holder onhover " + (i%2 == 0 ? 'nthclass' : '')}>
													<div className="activityNumDiv">{i+1}</div>
													<div className="faa_item2 act_type_padding">{row.ActivityType}</div>
													<div className="faa_item2">{row.ActivityName}{row.ActivityDisplayName && row.ActivityDisplayName != null && row.ActivityDisplayName != 'null' ?<div>{row.ActivityDisplayName}</div>:''}</div>
													<div className="faa_item2 w10">{row.DurationType}</div>
													{row.DurationType==="Time"?<div className="faa_item2">{Math.floor(row.Count/60)}min {(row.Count%60)}sec</div>:<div className="faa_item2">{row.Count} x {row.repsSeconds} </div>}
													
													{ row.Video.length < 20 &&
														<div className="faa_item2">{row.Video}</div>
													}
													{ row.Video.length >= 20 &&
														<div className="faa_item2">{row.Video.slice(0, 20)}...</div>
													}
													<div className="faa_item2 w10">{row.VideoRepeat ? 'Yes' : 'No'}</div>
													{ this.props.state.isMarketplaceChannel &&
														<>
															<div className="faa_item2">{row.fmStationName}</div>
															<div className="faa_item2">{row.ActivityNotes}</div>
														</>
													}
													<div className="faa_item2 w13">
														<div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') } onClick={() => this.props.editActivity(i)} id={i}><i className="fa fa-pencil" aria-hidden="true"></i>
															<div className="tooltip_hold pink_info">
																<div className="tooltip_text">Edit</div>
															</div>
														</div>
														<div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') } onClick={() => this.props.CopyActivity(i)} id={i}><i className="fa fa-copy" aria-hidden="true"></i>
															<div className="tooltip_hold pink_info">
																<div className="tooltip_text">Duplicate</div>
															</div>
														</div>
														<div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') } onClick={() => this.props.removeActivity(i)} id={i}><i className="fa fa-minus" aria-hidden="true"></i>
															<div className="tooltip_hold pink_info">
																<div className="tooltip_text">Remove</div>
															</div>
														</div>
														{ this.props.state.editActivityToken === -1 &&
															<div className="mr-1 bg-circle mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><span><i className="fa reorder_item fa-bars" onMouseDown={this.props.handleDraggStart} aria-hidden="true"></i></span>
																<div className="tooltip_hold pink_info">
																	<div className="tooltip_text">Change Order</div>
																</div> 
															</div>
														}
														{ this.props.state.editActivityToken === -1 && this.props.state.tablerows.length > 1 &&
															<div className="mr-1 bg-circle mr-lg-2 mb-1 pointer no_overflow_tools" onClick={e=>{this.props.changePlace(i)}} onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><span><i className="fa reorder_item fa-numbers" aria-hidden="true"></i></span>
																<div className="tooltip_hold pink_info">
																	<div className="tooltip_text">Change Place</div>
																</div>
															</div>
														}
													</div>
												</div>
											}
											{ i == this.props.state.editActivityToken && this.props.state.editActivityToken > -1 &&
												<div className="row mx-0 pb20 inner_workout_hold pr20">
													<div className="col-xl-4 col-lg-3 col-md-4 px-2">
														<div className="form-group mt-3 mb-1">
															<span className="cover-border cover-border_create"></span>
															<label className="label">Activity type</label>
															<select className="input-field fields_back"
																id="ActivityTypeEdit" 
																value = {this.props.state.ActivityTypeEdit}  
																style={{color:(this.props.state.ActivityTypeEdit ? "white" : "")}} 
																onChange = {(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, ActivityNameEdit:'', ActivityDisplayName: ''})} >
																{this.props.activitynewtype}
															</select>
														</div>
													</div>
													
												  {this.props.state.ActivityTypeEdit!=="Virtual Equipment" && this.props.state.ActivityTypeEdit!=="Virtual Assistant" &&
													<div className="col-xl-4 col-lg-3 col-md-4 px-2 pl-md-0">
														<div className="form-group mt-3 mb-1">
															<span className="cover-border cover-border_create"></span>
															<label className="label">Activity name ({this.props.state.nameEditNumLeft}) </label>
															<input type="text"
															id = "ActivityNameEdit" 
															value= {this.props.state.ActivityNameEdit} 
															onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} 
															className="input-field"
															maxLength='30'
															ref={c => (this.props.updateMainInput(c))} />
														</div>
														{this.scriptValidate.message('Activity name', this.props.state.ActivityNameEdit, 'required')}
												  </div> }
													
													{this.props.state.ActivityTypeEdit=="Virtual Equipment" &&
														<div className="col-xl-2 col-lg-3 col-md-4 px-2 pl-md-0">
															<div className="form-group mt-3 mb-1">
																<span className="cover-border cover-border_create"></span>
																<select
																	className="input-field fields_back"
																	id="ActivityNameEdit"
																	value = {this.props.state.ActivityNameEdit}
																	style={{color:(this.props.state.ActivityNameEdit?"white":'')}} 

																	onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})}
																  >
																		<option></option>
																		<option>Agility Ladder Horizontal</option>
																		<option>Boxing Bag 1</option>
																		<option>Box Horizontal</option>
																		<option>Diagonal Cones</option>
																		<option>Bar High Above Hands – On Ground</option>
																		<option>Lateral Cones</option>
																		<option>Lateral Circles</option>
																		<option>Lunge Box Touch 45 Degrees</option>
																		<option>Lunge Straight</option>
																		<option>Bar Low Forward Hands</option>
																		<option>Reaction Drill - Center</option>
																		<option>Squat Box</option>	
																		<option>Squat Full</option>	
																		<option>Push Up</option>	
																		<option>Side Knees</option>	
																  </select>
															</div>
															{this.scriptValidate.message('Activity name', this.props.state.ActivityNameEdit, 'required')}
													  </div> }
													
													{this.props.state.ActivityTypeEdit=="Virtual Assistant" &&
														<div className="col-xl-1 col-lg-3 col-md-4 px-2 pl-md-0">
															<div className="form-group mt-3 mb-1">
																<span className="cover-border cover-border_create"></span>
																<select
																	className="input-field fields_back"
																	id="ActivityNameEdit"
																	value = {this.props.state.ActivityNameEdit}
																	style={{color:(this.props.state.ActivityNameEdit?"white":'')}} 

																	onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})}
																  >
																	<option></option>
																	<option>Squat</option>
																  </select>
															</div>
															{this.scriptValidate.message('Activity name', this.props.state.ActivityNameEdit, 'required')}
													  </div> }
													{ !this.props.state.isMarketplaceChannel && 
													<div className="col-xl-1 col-lg-2 col-md-4 px-2">
														<div className="form-group mt-3 mb-1">
															<span className="cover-border cover-border_create"></span>
															<select
																className="input-field fields_back"
																id="DurationTypeEdit"
																value = {this.props.state.DurationTypeEdit}
																style={{color:(this.props.state.DurationTypeEdit?"white":'')}} 
																onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})}
																>
																<option></option>
																<option>Time</option>
																{ this.props.state.ActivityTypeEdit!=='Virtual Equipment' &&  <option>Reps</option> }
															</select>
														</div>
													</div>
													}
													{this.props.state.DurationTypeEdit === "Time" &&
														<div className="col-xl-2 col-lg-1 col-md-4 px-2">
															<div className="form-group mt-3 mb-1"><span className="cover-border cover-border_create"></span>
																<label className="label">Minutes</label>
																<select className="input-field fields_back"
																	style={{color:(this.props.state.activityHourEdit?"white" : '')}} 
																	id="activityHourEdit"
																	value={this.props.state.activityHourEdit} 
																	onChange={this.props.sessionInfo}>
																	{this.props.forActivityHour()}
																</select>
															</div>
														</div>
													}
													{ this.props.state.DurationTypeEdit === "Time" &&
														<div className="col-xl-2 col-lg-1 col-md-4 px-2">
															<div className="form-group mt-3 mb-1"><span className="cover-border cover-border_create"></span>
																<label className="label">Seconds</label>
																<select className="input-field fields_back" 
																	style={{color:(this.props.state.activityminutesEdit?"white" : '')}}
																	id="activityminutesEdit" value={this.props.state.activityminutesEdit} 
																	onChange={this.props.sessionInfo}>
																	{this.props.forActivityMinute()}
																</select>
															</div>
														</div>
													}
													{ this.props.state.DurationTypeEdit === "Reps" &&
														<div className="col-xl-2 col-lg-1 col-md-4 px-2">
															<div className="form-group mt-3 mb-1">
																<span className="cover-border cover-border_create"></span>
																<input type="text"
																id = "CountEdit"
																value = {this.props.state.CountEdit}
																onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})}
																className="input-field" />
															</div>
														</div>
													}
													{ this.props.state.DurationTypeEdit === "Reps" &&
														<div className="col-xl-2 col-lg-1 col-md-4 px-2">
															<div className="form-group mt-3 mb-1"><span className="cover-border cover-border_create"></span>
																<select className="input-field fields_back" 
																	style={{color:(this.props.state.repsSecondsEdit?"white" : '')}}
																	id="repsSecondsEdit" value={this.props.state.repsSecondsEdit} 
																	onChange={this.props.sessionInfo}>
																	{this.props.forActivityMinute()}
																</select>
															</div>
														</div> 
													}
													
													
													{this.props.state.ActivityTypeEdit =="Virtual Equipment" &&
													  <div className={"col-xl-"+(this.props.state.isMarketplaceChannel?'4':'2')+" col-lg-3 col-md-4 px-2 pl-md-0"}>
														<div className="form-group mt-3 mb-1">
															<span className="cover-border cover-border_create"></span>
															<label className="label">Display Name ({this.props.state.ActivityDisplayLengthLeftEdit}) </label>
															<input type="text" id="ActivityDisplayNameEdit" value={this.props.state.ActivityDisplayNameEdit} onChange={(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} className="input-field" maxLength='30' ref={c => (this.props.updateMainInput(c))} />
														  </div>
														  {this.scriptValidate.message('Display name', this.props.state.ActivityDisplayNameEdit, 'required')}
													 </div> }
										
													{ this.props.state.isMarketplaceChannel &&
														<div className="col-xl-4 col-lg-2 col-md-4 px-2"> 
															<div className="form-group mt-3 mb-1 relative">
																<span className="cover-border cover-border_create"></span> 
																<label className="label">FM Station</label>
																<select className="input-field fields_back" id="chosenFMStationEdit" value={this.props.state.chosenFMStationEdit} onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]: e.target.value})}>
																	<option value=""></option>
																	{ this.props.state.fmStations.map((row,i) =>
																		<option key={i} value={row.id}>{row.name}</option>
																	)}
																</select>
															</div>
														</div>
													}

													{ this.props.state.isMarketplaceChannel &&
														<div className="col-xl-4 col-lg-3 col-md-4 px-2">
															<div className="form-group mt-3 mb-1">
																<span className="cover-border cover-border_create"></span>
																<label className="label">Notes ({this.props.state.noteEditNumLeft})</label>
																<input type="text"
																id = "ActivityNotesEdit" 
																value= {this.props.state.ActivityNotesEdit} 
																onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} 
																className="input-field"
																maxLength='200'
																/>
															  </div>
														 </div> 
													}
													
													{ this.props.state.ActivityTypeEdit !== "Pause" && this.props.state.ActivityTypeEdit !== "Pause Virtual" && this.props.state.ActivityTypeEdit!=='Virtual Equipment' &&
														<div className="col-xl-2 col-lg-2 col-md-4 px-2">
															<div className="form-group mt-3 mb-1 relative">
																<label className="label">Video</label>
																<span className="cover-border cover-border_create"></span>
																<input type="text" placeholder="URL" id="VideoEdit" value={this.props.state.VideoEdit} className="input-field" onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} />
															</div>
														</div> 
													}
													
													{ this.props.state.ActivityTypeEdit !== "Pause" && this.props.state.ActivityTypeEdit !== "Pause Virtual" && this.props.state.ActivityTypeEdit!=='Virtual Equipment' && !this.props.state.isMarketplaceChannel && 
														<div className="col-xl-2 col-lg-1 col-md-4 px-2">
															<div className="form-group mt-3 mb-1 relative">
																<span className=""></span>
																<div className="videorepeatbtn">
																	<label className="switch">
																		<input type="checkbox" id="VideoRepeatEdit" checked={this.props.state.VideoRepeatEdit} onChange={(e)=> this.props.updateStateFromChild({[e.target.id]:!this.props.state.VideoRepeatEdit})}/>
																		<span className="slider round"></span>
																	</label>
																	<div className="toggle_option toggle_top">{this.props.state.VideoRepeatEdit ? 'ON' : 'OFF'}</div>
																</div>
															</div>
														</div> 
													}
													
													<div className="col-xl-1 col-lg-1 col-md-4 px-2 pt25">
														<div className="edit_button_equ ml0" onClick={this.props.closeEditActivity}>CLOSE</div>
													</div>
													<div className="col-xl-1 col-lg-1 col-md-4 px-2 pt25">
														<div className="edit_button_equ ml10" onClick={this.props.saveEditActivity}>SAVE</div>
													</div>
												</div>
											}
										</div>
									))}
								</div>
							</div>
						}
					</div>
					
					 { this.props.state.tablerows.length > 0 &&
						<div className="activity_remaining">
							<div className="activityNumberHold" >Workout Duration {this.props.state.activityDurationTotalMin} min {this.props.state.activityDurationTotalSec} sec </div>
							<div className="activityNumberHold" >Workout Pause {this.props.state.activityPauseTotalTimeMin} min {this.props.state.activityPauseTotalTimeSec} sec </div>
							<div className="activityNumberHold" >Total Workout Duration {this.props.state.activityTotalTime} min {this.props.state.activityTotalTimeSeconds} sec <span className="paddingjust"></span> {this.props.state.activityRemainingTime} min {this.props.state.activityRemainingTime2} sec Remaining Time</div>
						</div>
					 }
				  </div>
				</div>
				
				<div className="print_popuphidden">
					<div className="print_fields">{this.props.state.hostName}</div>
					<div className="print_fields">{this.props.state.session_details}</div>
					<div className="print_fields">{this.props.state.whenSessionTime} {this.props.state.chosenTimezone}</div>
					<div className="print_fields">{this.props.state.sessionMinute}</div>
					<br />
					<div className="print_fields">{this.props.state.chosenMusic}</div>
					<div className="print_fields">{this.props.state.chosenStationName}</div>
					<div className="print_fields">{this.props.state.workoutSoundStart}</div>
					<div className="print_fields">{this.props.state.workoutSoundEnd}</div>
					<br />
					<div className="print_fields">{ this.props.state.selectedinterestsNames }</div>
					<div className="print_fields">{ this.props.state.liveSelectedInterestsSubcategoriesNames }</div>
				
					<br />
					<br />
					<div className="print_fields_script">
						<div><b><div>Activity Type</div><div>Activity Name</div><div>Display Name</div><div>Duration</div><div>Notes</div><div>Music</div></b></div>
						
						{ this.props.state.tablerows.map((row,i) => (
							
							<div key={row.sessionScriptId}>
								<div>{row.ActivityType}</div>
								<div>{row.ActivityName}</div>
								<div>{row.ActivityDisplayName && row.ActivityDisplayName != null && row.ActivityDisplayName != 'null' && <span>{row.ActivityDisplayName}</span> }</div>
								<div>{Math.floor(row.Count/60)}min {(row.Count%60)}sec</div>
								<div>{row.ActivityNotes}</div>
								<div>{row.fmStationName}</div>
							</div>
						))}
						
					</div>
				</div>
				
				
		 </div>
    );
  }
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(SessionScript);
