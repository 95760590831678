import React, { Component } from "react";
import moment from 'moment';
import axios from "axios";
import ClassNotesList from '../home/ClassNotesList';
import { getUserImage } from '../../util/getUserImage';
import { getUsername } from '../../util/getUsername';
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";

class ClassNotes extends Component {
  
  constructor(props) {
		super(props);
		
		this.state = {
			textField: "",
			notes: []
		}

		this.getUserImage = getUserImage.bind(this);
		this.getUsername = getUsername.bind(this);
	}
 
	componentDidMount(){
		this.getNotes();
	}
	
	getNotes=()=>{
		this.props.updateStateFromChild({showLoader: true});
		var postData = {sessionId: this.props.classInfo.id};
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		axios.defaults.headers.common['Authorization'] = token;
		axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getMarketplaceNotes", postData).then(res => {
			this.setState({
				notes: res.data.responseData
			}, ()=>{
				document.getElementById("notes_scroll").scrollTop = document.getElementById("notes_scroll").scrollHeight;
			});
			this.props.updateStateFromChild({showLoader: false});
			
		}).catch(err =>{ });
	}

	saveNote=()=>{
		if ( this.state.textField.trim() !== "" )
			{
				this.props.updateStateFromChild({showLoader: true});
				var postData = {note: this.state.textField.trim(), sessionId: this.props.classInfo.id};
				let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
				axios.defaults.headers.common['Authorization'] = token;
				axios.post(process.env.REACT_APP_NAME+"/api/v1/session/saveSessionNote", postData).then(res => {
					this.setState({textField: ""});
					this.getNotes();
				}).catch(err =>{ 
					console.log('---err---', err.response.data.errorData); 
					if ( err.response.data.errorData == 'Class started' )
						{
							this.props.showMainErrorPop("Can't add note to class that should already start.");
							this.props.updateStateFromChild({showLoader: false});
						}
				});
			}
	}
	
	closeNotes=()=>{
		this.props.updateStateFromChild({isOpenNote: false});
		this.props.updateNotesCount(this.props.classInfo.id, this.state.notes.length);
	}

	render() {
			const classInfo = this.props.classInfo;
			
			return(
				<div className="notes_holder">
					<div className="notes_container">
						<div className="review_main_title note_title">Note
							<div className="close_flow" onClick={this.closeNotes}></div>
						</div>
						<div className="class_info_note">
							<div className="one_class_review note_class_review">
								<div className="class_review_info">
									<div className="top_info_class">
										<span className="top_span">{moment(classInfo.scheduleDate).format('MM/DD/YYYY')}</span>
										<span className="top_span class_dot">&bull;</span>
										<span className="top_span">{moment(classInfo.scheduleDate).format('hh:mm A')}</span>
										<span className="top_span class_dot">&bull;</span>
										<span className="top_span">{classInfo.duration} min</span>
										<span className="class_interest">{classInfo.interestTitles}</span>
									</div>
									<div className="class_name">{classInfo.name}</div>
									<div className="bottom_class_content">
										<div className="instructor_avatar" style={{backgroundImage: "url("+this.getUserImage(classInfo.hostImage)+")"}}></div>
										<div className="instructor_name">{this.getUsername(classInfo.hostFirstName, classInfo.hostLastName)}</div>
									</div>
								</div>
								<div className="class_review_buttons">
									<div className="status_button">{classInfo.marketplaceStatus == 0 ? "PENDING EDIT" : classInfo.marketplaceStatus == 1 ? "IN REVIEW" : "PENDING PUBLISH"}</div>
								</div>
							</div>
						</div>
						<div className="notes_content">
							<div className="notes_list" id="notes_scroll">
								<ClassNotesList notes={this.state.notes}></ClassNotesList>
							</div>
							<div className="area_note">
								<textarea placeholder="Type a note" maxLength="300" id="textField" value={this.state.textField} onChange={(e) => this.setState({[e.target.id]: e.target.value})}></textarea>
								<div className="area_send" onClick={this.saveNote}></div>
							</div>
						</div>
					</div>
				</div>
			)
		}
}
export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ClassNotes);