import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import { updatesse } from '../../util/updatesse';
import Login from '../auth/Login';
import MainSignUp from '../auth/MainSignUp';
import { submitHost } from '../../util/submitHost';
import { verify } from '../../util/verify';
import { setCookie } from '../../util/cookie';

class ChannelSignUp extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		mainChannelData: {},
		fullsubdomain: '',
		isLoginOpen: false,
		isOpenLoginAll: false,
		redirect: '',
		errorClass_Email: false,
		email: '',
		validateEmail: false,
		signup: false,
		isOpenSign: false,
		emailSign: '',
		errors: {},
		isSignupChannel: true,
		phone: '',
		password: '',
		rePassword: '',
		firstName: '',
		lastName: '',
		backroundColorPick: '',
		fontColorPick: '',
		buttonsColorPick: '#E4DAFF',
		highlightColorPick: '',
		backgroundColor: '#000000',
		fontColor: '#ffffff',
		buttonsColor: '#bd00ff',
		highlightTextColor: '#bd00ff',
		buttonsTextColor: '#6D118C',
		detectChannelSignUpPage: true
	};
	
	this.validator = new SimpleReactValidator({autoForceUpdate: this});
	this.emailValidate  = new SimpleReactValidator();
	this.otpValidation = new SimpleReactValidator();
	this.submitHost = submitHost.bind(this);
	this.verify = verify.bind(this);
	this.setCookie = setCookie.bind(this);
	this.updatesse = updatesse.bind(this);
}
 
componentDidMount(){
	let host = window.location.host;
	let parts = host.split(".");
	let subdomain = parts[0];
	
	let allsub = subdomain.split("-");
	
	let channelName = allsub[0];
	
	let postdata = {'channelName': channelName }
	
	let adminpath = process.env.REACT_APP_PATH;
	adminpath = adminpath.replace('http://', '.').replace('https://', '.');

	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelBySubdomain", postdata)          
	.then(res => {
		this.setState({ mainChannelData: res.data.chanelData, fullsubdomain: res.data.chanelData.subdomain + adminpath})
		
		if ( window.location.hash.length > 5 )
			{
				let showPreview = JSON.parse(atob(window.location.hash.substr(1, 500)));
				
				this.setState({
					backroundColorPick: showPreview['backroundColorPick'],
					fontColorPick: showPreview['fontColorPick'],
					buttonsColorPick: showPreview['buttonsColorPick'],
					highlightColorPick: showPreview['highlightColorPick'],
					buttonsTextColor: showPreview['buttonsTextColor']
					
				});
			}
			
		else
			{
				if ( res.data.chanelData.channelSettings.backgroundColor )
					{
						this.setState({
								backroundColorPick: res.data.chanelData.channelSettings.backgroundColor ? res.data.chanelData.channelSettings.backgroundColor : '#000000',
								fontColorPick: res.data.chanelData.channelSettings.fontColor ? res.data.chanelData.channelSettings.fontColor : '#ffffff',
								buttonsColorPick: res.data.chanelData.channelSettings.buttonsColor ? res.data.chanelData.channelSettings.buttonsColor : '#E4DAFF',
								highlightColorPick: res.data.chanelData.channelSettings.highlightTextColor ? res.data.chanelData.channelSettings.highlightTextColor : '#bd00ff',
								buttonsTextColor: res.data.chanelData.channelSettings.buttonsTextColor ? res.data.chanelData.channelSettings.buttonsTextColor : '#6D118C'
							});
					}
			}
			
		var mainthis = this;
		mainthis.updatesse('channelId', res.data.chanelData.id, function () {mainthis.refreshData() });	
	})
	.catch(err =>{ })
	
	
	
			
		
};

refreshData=()=>{
	console.log('--------testt----');
	let host = window.location.host;
	let parts = host.split(".");
	let subdomain = parts[0];
	
	let allsub = subdomain.split("-");
	
	let channelName = allsub[0];
	
	let postdata = {'channelName': channelName }
	
	let adminpath = process.env.REACT_APP_PATH;
	adminpath = adminpath.replace('http://', '.').replace('https://', '.');

	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelBySubdomain", postdata)          
	.then(res => {
		this.setState({ mainChannelData: res.data.chanelData, fullsubdomain: res.data.chanelData.subdomain + adminpath})
		
		if ( window.location.hash.length > 5 )
			{
				let showPreview = JSON.parse(atob(window.location.hash.substr(1, 500)));
				
				this.setState({
					backroundColorPick: showPreview['backroundColorPick'],
					fontColorPick: showPreview['fontColorPick'],
					buttonsColorPick: showPreview['buttonsColorPick'],
					highlightColorPick: showPreview['highlightColorPick'],
					buttonsTextColor: showPreview['buttonsTextColor']
					
				});
			}
			
		else
			{
				if ( res.data.chanelData.channelSettings.backgroundColor )
					{
						this.setState({
								backroundColorPick: res.data.chanelData.channelSettings.backgroundColor ? res.data.chanelData.channelSettings.backgroundColor : '#000000',
								fontColorPick: res.data.chanelData.channelSettings.fontColor ? res.data.chanelData.channelSettings.fontColor : '#ffffff',
								buttonsColorPick: res.data.chanelData.channelSettings.buttonsColor ? res.data.chanelData.channelSettings.buttonsColor : '#E4DAFF',
								highlightColorPick: res.data.chanelData.channelSettings.highlightTextColor ? res.data.chanelData.channelSettings.highlightTextColor : '#bd00ff',
								buttonsTextColor: res.data.chanelData.channelSettings.buttonsTextColor ? res.data.chanelData.channelSettings.buttonsTextColor : '#6D118C'
							});
					}
			}
	})
	.catch(err =>{ })
}

openChannel=()=> {
	window.location.href = 'https://'+this.state.fullsubdomain;
}

openLogin=()=>{
	let subdomain = 'https://'+this.state.fullsubdomain;
	
	this.setState({
		isLoginOpen: true,
		redirect: subdomain
	});

}

closeLoginPop = (e) => {
	this.setState({
		isOpenLoginAll: false,
		isLoginOpen: false
	});
}

validateEmailMethod=(e)=>{
  if ( this.emailValidate.allValid() ) 
	  {
		let dataRes;
		$('.main_loader').attr({'style':'display:flex'});
	
		let postdata = {"email":this.state.email};
		
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserExist", postdata )
		.then(res => {
				dataRes = res.data.responseData;
				
				if ( Object.keys(dataRes).length === 0 ) 
					{ 
						console.log('IN THISSSS'); 
						this.setState({ 
										validateEmail: false, 
										signup: true,
										isLoginOpen: false,
										isOpenLoginAll: false,
										isOpenSign: true,
										emailSign: this.state.email
									}) 
					}
				else 
					{ 
						this.setState({isOpenLoginAll: true}) 
					}
				
				$('.main_loader').hide();
			
		}).catch(err =>{
			
			$('.main_loader').hide();
			
			if( !dataRes )
				{
					if ( err.response.data.errorData == 'User not verified')
						{
							$("#user_not_verified").attr({'style':'display:block'});
						}
					else
						{
							this.setState({
							  validateEmail:false,
							  signup:true,
							  isOpenSign: true,
							  emailSign: this.state.email,
							})
						}
				}
			else 
				{   
					this.setState({
					  otperror:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
					},()=>$("#error_otp_msg").attr({'style':'display:block'}))
				}
		});
		
	  }
  else
	  {
		  this.emailValidate.showMessages();
		  $("#error_email_msg").attr({'style':'display:block'})
	   
			this.forceUpdate();
	  }
  
}

closeSign=(e)=> {
	this.setState({
		isOpenSign: false,
		verify: false,
	});
};

closeLoginPop = (e) => {
	this.setState({
		isOpenLoginAll: false,
		isLoginOpen: false
	});
}

onChange=(e)=> {
    this.setState({ [e.target.id]: e.target.value });
};

resend=(e)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : this.state.emailSign})
    .then(res => {
			if( res.data.responseMessage == "success" )
				{
					$("#resend_verification").attr({'style':'display:block'});
				}
			else
				{
					this.setState({
						msg:''
					}) 
				}
    }).catch(err =>{
		this.setState({
			msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
		}) 
    });
}

updateRefState = (ref, mod) => {
	if ( mod == 'firstName' )
		{
			this._firstName = ref;
		}
	else if ( mod == 'lastName' )
		{
			this._lastName = ref;
		}
	else if ( mod == 'email' )
		{
			this._email = ref;
		}
	else if ( mod == 'password' )
		{
			this._password = ref;
		}
	else if ( mod == 'repassword' )
		{
			this._rePassword = ref;
		}
}

updateStateFromChild = (newState, mod) => {
	this.setState(newState);
	if ( mod ) 
		{
			$("#registration_popup").attr({'style':'display:block'});
			$('.main_loader').hide();
		}
}

closeLogin=(e)=>{
	this.setState({
		isLoginOpen: false,
		isOpenLoginAll: false,
	});
}

goToLogin=(e)=> {
	$("#user_not_verified").attr({'style':'display:none'});
	
	this.setState({isOpenLoginAll: true}) 
};

updateCookieAndStorage=(id, token, expire)=>{
	window['lastUserId'] = 0;
   //set cookie after register
	this.setCookie(process.env.REACT_APP_COOKIE_ID, id, expire);
	this.setCookie(process.env.REACT_APP_COOKIE_TOKEN, token, expire);
	//set cookie after register
	window['user_loggedIn'] = 1;
}


  render() {
	 return (
		<div className="all_virtual_pg chsignpop">
		
		<style>
			.signup_text, .signup_welcome_text, .channel_title, .py-4.text-light.text-center.font-20, .checktxt_name, .abs_povered, .pick.pb-2, .pick.mt-2.mb-4.font-18, .main_color_part, .login-tagline, .label, .channel_main_button, .save-btn.btn-primary {'{ color:  '+this.state.fontColorPick+' !important; }'}
			.close_virtual, .form-radio:checked, .done.mt-3, .channel_main_button, .custom_btn, .abs_close, .save-btn.btn-primary {'{ background-color:  '+this.state.buttonsColorPick+' !important; }'}
			.rose_btn, .rose_clr, .closepurple, .btn-cancel:hover, .custom_btn1, .right-small-box, .nav-link_new.active:after  {'{ background-color: '+this.state.buttonsColorPick+' !important; }'}
			.save-btn.btn-primary, .btn-login {'{ background-color: '+this.state.buttonsColorPick+' !important; border: '+this.state.buttonsColorPick+' !important; }'}
			.one_info, .sess_interest, .rose_btn, .channel_clear_date, .srv-validation-message, .top_info_title, .description_text, .one_equ, .one_shopping_list, .left_part.bolded, .right_part.bolded, .mb-0.py-4, .sign_main_text, .login-tagline, .label, .back_text, .grid_title, .main_color_part, .channel_main_name, .channel_main_desc, .color_a.active, .week_part, .channel_no_results {'{ color: '+this.state.fontColorPick+' !important; }'}
			.container-fluid, .main_virtual_pop {'{ background-color: '+this.state.backroundColorPick+' !important; }'}
			.input_field_container{'{ border: 2px solid '+this.state.buttonsColorPick+' !important; }'}
			.channel_login_input{'{ border: 2px solid '+this.state.buttonsColorPick+' !important; border-top: none !important }'}
			.input-field {'{ border: 2px solid '+this.state.buttonsColorPick+' !important; }'}
			.channel_cancel_button, .form-radio, .sml_input_box_ak input, .form-radio:checked {'{ border-color: '+this.state.buttonsColorPick+' !important; }'}
			.custom-control-label::before {'{ background-color: '+this.state.buttonsColorPick+' !important; border-color: '+this.state.buttonsColorPick+' !important; }'}
			.purple_text, .forgot-password {'{ color: '+this.state.highlightColorPick+' !important; }'}
			.done.mt-3, .done button:not(:disabled){'{ color: '+this.state.buttonsTextColor+' !important; }'}
		</style>
		
			<div className="main_virtual_pop">
				<div className="channel_title">Welcome to {this.state.mainChannelData.name} on Virdio!
					{ this.state.mainChannelData.image !== 'default.png' &&
						<div className="abs_channel_img contain_img"  style={{backgroundImage: "url('" + this.state.mainChannelData.image + "')"}}>
							<div className="abs_povered">Powered by Virdio</div>
						</div>
					}
					{ this.state.mainChannelData.image === 'default.png' &&
						<div className="abs_channel_img contain_img"  style={{backgroundImage: "url('/images/powered_virdio.png')"}}></div>
					}
				</div>
				<div className="signup_text">{this.state.mainChannelData.signup_text}</div>
				<div className="signup_welcome_text" dangerouslySetInnerHTML={{__html: this.state.mainChannelData.signup_welcome_text}}></div>
				<div className="for_virtual_btn">
					<button type="button" className="done mt-3" onClick={this.openLogin}>SIGN UP</button> 
				</div>
			</div>
			
			
			<div className={ "main_pop_sign " + ( this.state.isLoginOpen ? "active" : "" ) }>
				<div className="for_main_form centered_pop">
					<div>
						{ !this.state.isOpenLoginAll && 
							<div>
								<div className="col-12 main_login_logos">
									<img src="/images/login-logo.png" className="login-logo" />
									<p className="login-tagline">Login and Join a Virtual Studio</p>
								 </div>
								<div className="gray-box-4 px-4 pt-5 pb-4 input_bx-cont for_sign relative">
									<button type="button" className="close white closepurple translatX-Y abs_close" onClick={this.closeLogin}><span>x</span></button>
									<div className="form-group">
										<label className="label">Email Address<span className="inp_cover-border"></span></label>
										<input type="email" 
										className={(this.state.errorClass_Email  ?"input-field red-outline":"input-field")} 
										value={this.state.email} id ='email'
										ref={mail=> (this._email = mail)} 
										onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_Email:false})} />
										{this.emailValidate.message('email', this.state.email, 'required|email')}
										<span className="form_email"></span>
									</div>
								</div>
								<div className="text-center">
									<button type="button" className="save-btn btn btn-primary mt-3 font-book mx-auto font-18 channel_main_button" onClick={this.validateEmailMethod}>Continue</button>
								</div>
							</div>
						}
					</div>
					<div> 
						{ this.state.isOpenLoginAll && 
							<Login 
								state={this.state}
								closeLogin={this.closeLogin}
								updateStateFromChild={this.updateStateFromChild}
								updateStateFromChildSession={this.updateStateFromChildSession}
								showLoginAlert={this.showLoginAlert}
								closeLoginPop={this.closeLoginPop}
								>
							</Login>
						}
					</div>
				</div>
			</div>
			
			<div className={ "main_pop_sign " + ( this.state.isOpenSign ? "active" : "" ) }>
				<div className="text-center"><img src="/images/participate.png" alt="host" className="w-60" /></div>
				<h4 className="white text-center pb-2 font-20 sign_main_text">Signing up as a Member</h4>
				<MainSignUp 
					state={this.state}
					closeSign={this.closeSign}
					onChange={this.onChange}
					resend={this.resend}
					submitHost={this.submitHost}
					updateRefState={this.updateRefState}
					updateStateFromChild={this.updateStateFromChild}
					verify={this.verify}
					>
				</MainSignUp>
			</div>
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="modal pr-0" id="user_not_verified" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
							<p className="mb-0 py-4 white text-center font-20">Your username is already registered, but has not been verified. To complete your registration please select OK and you will be guided through the process.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<div className="main_link" ><button className="custom_btn1 mt-3 mx-2" onClick={this.goToLogin}>OK</button></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>	
    );
  }
}

const mapStateToProps = state => {
	return { 
		openMainPopup: state.libraryPopups.showPlaylistMainPopup
	}
}

export default connect(
  mapStateToProps,
  { 
	showMainErrorPop
  }
)(ChannelSignUp);
