import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import { updatesse } from '../../util/updatesse';
import Login from '../auth/Login';
import { submitHost } from '../../util/submitHost';
import { verify } from '../../util/verify';
import { setCookie } from '../../util/cookie';

class CompanyPage extends Component {
  
  constructor(props) {
	super(props);
	this.state = {
		mainChannelData: {},
		fullsubdomain: '',
		isLoginOpen: false,
		isOpenLoginAll: false,
		redirect: '',
		errorClass_Email: false,
		email: '',
		validateEmail: false,
		signup: false,
		isOpenSign: false,
		emailSign: '',
		errors: {},
		isSignupChannel: true,
		phone: '',
		password: '',
		rePassword: '',
		firstName: '',
		lastName: '',
		backroundColorPick: '',
		fontColorPick: '',
		buttonsColorPick: '#E4DAFF',
		highlightColorPick: '',
		backgroundColor: '#000000',
		fontColor: '#ffffff',
		buttonsColor: '#bd00ff',
		highlightTextColor: '#bd00ff',
		buttonsTextColor: '#6D118C',
		detectChannelSignUpPage: true,
		loaded: false
	};
	
	this.validator = new SimpleReactValidator({autoForceUpdate: this});
	this.emailValidate  = new SimpleReactValidator();
	this.otpValidation = new SimpleReactValidator();
	this.submitHost = submitHost.bind(this);
	this.verify = verify.bind(this);
	this.setCookie = setCookie.bind(this);
	this.updatesse = updatesse.bind(this);
}
 
componentDidMount(){
	let host = window.location.host;
	let parts = host.split(".");
	let subdomain = parts[0];
	
	let allsub = subdomain.split("-");
	
	let channelName = allsub[0];
	
	let postdata = {'channelName': channelName }
	
	let adminpath = process.env.REACT_APP_PATH;
	adminpath = adminpath.replace('http://', '.').replace('https://', '.');

	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelBySubdomain", postdata)          
	.then(res => {
		this.setState({ mainChannelData: res.data.chanelData, fullsubdomain: res.data.chanelData.subdomain + adminpath, loaded: true})
		
		if ( window.location.hash.length > 5 )
			{
				let showPreview = JSON.parse(atob(window.location.hash.substr(1, 500)));
				
				this.setState({
					backroundColorPick: showPreview['backroundColorPick'],
					fontColorPick: showPreview['fontColorPick'],
					buttonsColorPick: showPreview['buttonsColorPick'],
					highlightColorPick: showPreview['highlightColorPick'],
					buttonsTextColor: showPreview['buttonsTextColor']
					
				});
			}
			
		else
			{
				if ( res.data.chanelData.channelSettings.backgroundColor )
					{
						this.setState({
								backroundColorPick: res.data.chanelData.channelSettings.backgroundColor ? res.data.chanelData.channelSettings.backgroundColor : '#000000',
								fontColorPick: res.data.chanelData.channelSettings.fontColor ? res.data.chanelData.channelSettings.fontColor : '#ffffff',
								buttonsColorPick: res.data.chanelData.channelSettings.buttonsColor ? res.data.chanelData.channelSettings.buttonsColor : '#E4DAFF',
								highlightColorPick: res.data.chanelData.channelSettings.highlightTextColor ? res.data.chanelData.channelSettings.highlightTextColor : '#bd00ff',
								buttonsTextColor: res.data.chanelData.channelSettings.buttonsTextColor ? res.data.chanelData.channelSettings.buttonsTextColor : '#6D118C'
							});
					}
			}
			
		var mainthis = this;
		mainthis.updatesse('channelId', res.data.chanelData.id, function () {mainthis.refreshData() });	
	})
	.catch(err =>{ })	
};

refreshData=()=>{
	console.log('--------testt----');
	let host = window.location.host;
	let parts = host.split(".");
	let subdomain = parts[0];
	
	let allsub = subdomain.split("-");
	
	let channelName = allsub[0];
	
	let postdata = {'channelName': channelName }
	
	let adminpath = process.env.REACT_APP_PATH;
	adminpath = adminpath.replace('http://', '.').replace('https://', '.');

	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelBySubdomain", postdata)          
	.then(res => {
		this.setState({ mainChannelData: res.data.chanelData, fullsubdomain: res.data.chanelData.subdomain + adminpath})
		
		if ( window.location.hash.length > 5 )
			{
				let showPreview = JSON.parse(atob(window.location.hash.substr(1, 500)));
				
				this.setState({
					backroundColorPick: showPreview['backroundColorPick'],
					fontColorPick: showPreview['fontColorPick'],
					buttonsColorPick: showPreview['buttonsColorPick'],
					highlightColorPick: showPreview['highlightColorPick'],
					buttonsTextColor: showPreview['buttonsTextColor']
					
				});
			}
			
		else
			{
				if ( res.data.chanelData.channelSettings.backgroundColor )
					{
						this.setState({
								backroundColorPick: res.data.chanelData.channelSettings.backgroundColor ? res.data.chanelData.channelSettings.backgroundColor : '#000000',
								fontColorPick: res.data.chanelData.channelSettings.fontColor ? res.data.chanelData.channelSettings.fontColor : '#ffffff',
								buttonsColorPick: res.data.chanelData.channelSettings.buttonsColor ? res.data.chanelData.channelSettings.buttonsColor : '#E4DAFF',
								highlightColorPick: res.data.chanelData.channelSettings.highlightTextColor ? res.data.chanelData.channelSettings.highlightTextColor : '#bd00ff',
								buttonsTextColor: res.data.chanelData.channelSettings.buttonsTextColor ? res.data.chanelData.channelSettings.buttonsTextColor : '#6D118C'
							});
					}
			}
	})
	.catch(err =>{ })
}

render() {
	 return (
		<div className="all_virtual_pg chsignpop">
		
		<style>
			.signup_text, .signup_welcome_text, .channel_title, .py-4.text-light.text-center.font-20, .checktxt_name, .abs_povered, .pick.pb-2, .pick.mt-2.mb-4.font-18, .main_color_part, .login-tagline, .label, .channel_main_button, .save-btn.btn-primary {'{ color:  '+this.state.fontColorPick+' !important; }'}
			.close_virtual, .form-radio:checked, .done.mt-3, .channel_main_button, .custom_btn, .abs_close, .save-btn.btn-primary {'{ background-color:  '+this.state.buttonsColorPick+' !important; }'}
			.rose_btn, .rose_clr, .closepurple, .btn-cancel:hover, .custom_btn1, .right-small-box, .nav-link_new.active:after  {'{ background-color: '+this.state.buttonsColorPick+' !important; }'}
			.save-btn.btn-primary, .btn-login {'{ background-color: '+this.state.buttonsColorPick+' !important; border: '+this.state.buttonsColorPick+' !important; }'}
			.one_info, .sess_interest, .rose_btn, .channel_clear_date, .srv-validation-message, .top_info_title, .description_text, .one_equ, .one_shopping_list, .left_part.bolded, .right_part.bolded, .mb-0.py-4, .sign_main_text, .login-tagline, .label, .back_text, .grid_title, .main_color_part, .channel_main_name, .channel_main_desc, .color_a.active, .week_part, .channel_no_results {'{ color: '+this.state.fontColorPick+' !important; }'}
			.container-fluid, .main_virtual_pop {'{ background-color: '+this.state.backroundColorPick+' !important; }'}
			.input_field_container{'{ border: 2px solid '+this.state.buttonsColorPick+' !important; }'}
			.channel_login_input{'{ border: 2px solid '+this.state.buttonsColorPick+' !important; border-top: none !important }'}
			.input-field {'{ border: 2px solid '+this.state.buttonsColorPick+' !important; }'}
			.channel_cancel_button, .form-radio, .sml_input_box_ak input, .form-radio:checked {'{ border-color: '+this.state.buttonsColorPick+' !important; }'}
			.custom-control-label::before {'{ background-color: '+this.state.buttonsColorPick+' !important; border-color: '+this.state.buttonsColorPick+' !important; }'}
			.purple_text, .forgot-password {'{ color: '+this.state.highlightColorPick+' !important; }'}
			.done.mt-3, .done button:not(:disabled){'{ color: '+this.state.buttonsTextColor+' !important; }'}
		</style>
		{ this.state.loaded == true &&
			<div className="main_virtual_pop">
				<div className="channel_title">{this.state.mainChannelData.signup_text}
					{ this.state.mainChannelData.image !== 'default.png' &&
						<div className="abs_channel_img contain_img"  style={{backgroundImage: "url('" + this.state.mainChannelData.image + "')"}}>
							<div className="abs_povered">Powered by Virdio</div>
						</div>
					}
					{ this.state.mainChannelData.image === 'default.png' &&
						<div className="abs_channel_img contain_img"  style={{backgroundImage: "url('/images/powered_virdio.png')"}}></div>
					}
				</div>
				<div className="signup_welcome_text" dangerouslySetInnerHTML={{__html: this.state.mainChannelData.signup_welcome_text}}></div>
				<div className="for_virtual_btn">
					<a href={process.env.REACT_APP_ADMINURL + 'memberRegister'}><button type="button" className="done mt-3">SIGN UP</button></a> 
				</div>
			</div> }
		</div>	
    );
  }
}

const mapStateToProps = state => {
	return { 
		openMainPopup: state.libraryPopups.showPlaylistMainPopup
	}
}

export default connect(
  mapStateToProps,
  { 
	showMainErrorPop
  }
)(CompanyPage);
