import React, { Component } from "react";
//import uniqueId from 'lodash/uniqueId';
//import { Link } from 'react-router'
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { hashHistory } from 'react-router';
import axios from "axios";
import {  browserHistory} from 'react-router'
import  { Redirect } from 'react-router-dom'
import ParticipentSignup from '../auth/participateSignUp'

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "../../actions/types";

//import $ from 'jquery';

class VerifyUser extends Component {
            constructor() {
            super();
           // let email;
            this.state = {
                data:"",
                showError:false,
                messageFromServer:'',
                type:"1",
                errors: {},
				openParticipantSession: false,
				od_status : 0,
              };
        
            }

        componentDidMount() {

          this.verifySessionId();
        }

        componentWillMount(){
          
        }

          
        verifySessionId = e => {
    
         console.log('-----------verifyuser-------------------')

         let path=window.location.pathname;

         console.log('------path----------',path)

         let path_arr = path.split("/");

         console.log('------path_arr----------',path_arr[2])

         //let path_arrr = path_arr[2].split("#");

         //console.log('------path_arrlalit----------',path_arrr[0])

         const userData = {
          sessId: path_arr[2]
        };

         axios
         .post(process.env.REACT_APP_NAME+"/api/v1/session/verifyuser",userData)                
         .then(res => {
          
    console.log(res.data.responseData.sessionDta.id,'---------verifyuser123333--------------',res.data.responseData.sessionDta)
         // console.log('Lalit');
          if(res.data.responseMessage == 'success')
          {
            localStorage.setItem("sessionId", res.data.responseData.sessionDta.id);
            localStorage.setItem("scheduleDate",res.data.responseData.sessionDta.scheduleDate);
            localStorage.setItem("duration",res.data.responseData.sessionDta.duration);
            localStorage.setItem("sessionEndDate",res.data.responseData.sessionDta.sessionEndDate);
            localStorage.setItem("allowSS",res.data.responseData.sessionDta.allowSS);
            localStorage.setItem("parentRecrId",res.data.responseData.sessionDta.parentRecrId);
            //localStorage.setItem("recurringDetails",JSON.stringify(res.data.responseData.sessionDta.recurringDetails));
			
			if ( window.location.search.slice(1) )
			{
				var dataCode = '';
				try { dataCode = atob(window.location.search.slice(1)); } catch (e) { }
				if ( dataCode )
				{
					dataCode = JSON.parse(dataCode);
					if ( dataCode && dataCode['email'] )
						{
							localStorage.setItem("channelEmail", dataCode['email']);
							if ( dataCode['backToChannel'] ) { localStorage.setItem("backToChannel", dataCode['backToChannel']); }
						}
				}
				
			}
			
			
           // browserHistory.push("/participantSignup");
		   this.setState({openParticipantSession: true});
          }

        })
      
                        
          };

        render() {

        return (
            <div>
				{this.state.openParticipantSession &&
					<ParticipentSignup state={this.state}></ParticipentSignup>
				}
            </div>

         );

         }

}

const container = {
    "padding": "0px 15px",
    "max-width": "1140px"
  
  };

  export default VerifyUser;

