import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'

class MainTutorial extends Component {
  
  constructor(props) {
	super(props);
	this.state={
		chosenTutorial: '',
		tutorialLastIndex: 0,
		participantTutorial: [
								{
									"title": "Welcome to Virdio!",
									"text": "Let's take a quick walk-through of the key features on your dashboard.",
									"second_text": "",
									"third_text": "",
									"enumerationTitle": "",
									"enumeration": [],
									"footerText": ""
								},
								{
									"title": "Settings",
									"text": "Use the Settings Icon to Manage your Profile and other Settings.",
									"second_text": "",
									"third_text": "",
									"enumerationTitle": "",
									"enumeration": [],
									"footerText": ""
								},
								{
									"title": "Join a Class",
									"text": "Once an upcoming class has less than 30 minutes to start time, you can click on the Join button to launch your Streaming App. There are 3 other ways to Join your class:",
									"second_text": "",
									"third_text": "",
									"enumerationTitle": "",
									"enumeration": [
														{
															"enumerationText": "Go to live.Virdio.com"
														},
														{
															"enumerationText": "Go to your Channel Page (if it has been shared with you by your Instructor) and Click on the Join button for the desired Class"
														},
														{
															"enumerationText": "If you are on an iOS or Android device, download the Virdio App and launch it to Join the class"
														}
													],
									"footerText": ""
								}
							],
		hostDashboardTutorial: [
									{
										"title": "Welcome to Virdio!",
										"text": "Let's take a quick walk-through of the key features on your dashboard.",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Instructor Settings",
										"text": "You can set your default Class settings with this menu option.",
										"second_text": "Examples of default Class settings are flags like start music when class starts, start recording when class starts, if you want the members to see each other, etc.",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Playlist",
										"text": "Virdio gives you the ability to create a playlist by selecting your licensed songs (in .mp3 format). You can then select and attach the playlist to a Class.",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "How to create a Playlist",
										"enumeration": [
															{
																"enumerationText": "Click on Playlist Option in Settings Menu"
															},
															{
																"enumerationText": "Press the '+' Sign on the top right once Playlist screen opens up"
															},
															{
																"enumerationText": "Select songs you want to add to Playlist, must be .mp3 format"
															},
															{
																"enumerationText": "Give the Playlist a name"
															},
															{
																"enumerationText": "You will get a confirmation that the Playlist is added"
															}
														],
										"footerText": "All Playlists are device and browser specific.",
									},
									{
										"title": "Upcoming Classes",
										"text": "This tab lists all Upcoming Classes for the week on the Channel",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Past Classes",
										"text": "This tab lists all Past Classes for the Channel",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "On Demand",
										"text": "This tab lists all Classes that have been recorded and are available for On-demand viewing",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Join a Class",
										"text": "Once an upcoming class has less than 30 minutes to start time, you can click on the Join button to launch your Streaming App. There are two other ways to Join your class:",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [
															{
																"enumerationText": "Go to live.Virdio.com"
															},
															{
																"enumerationText": "Go to your Channel Page and Click on the Join button for the desired Class"
															}
														],
										"footerText": ""
									},
									{
										"title": "Edit Channel",
										"text": "To edit any of the attributes for the Channel, use this option. E.g. if you need to modify your logo",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Channel Settings",
										"text": "You can set default Class settings for your Channel. You can also specify if the other instructors can change these settings.",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Generate Channel Page",
										"text": "The Channel Page is a web address (E.g. companyname.Virdio.com) where only your Classes are listed for clients to sign up and join. You can embed this web address in your website, giving your clients direct access to all your virtual Classes.",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "How to Generate your Channel Page:",
										"enumeration": [
															{
																"enumerationText": "Click on Generate Channel Page Option in Settings Menu"
															},
															{
																"enumerationText": "Choose a name for your Channel Page web address, E.g. myfitness"
															},
															{
																"enumerationText": "At the bottom of your screen you will see the complete web address, E.g. myfitness.Virdio.com"
															}
														],
										"footerText": "When creating a Class, you can specify if the Class is listed on your Channel Page, the default setting is listed on Channel Page."
									},
									{
										"title": "Setup Sign Up Page",
										"text": "Setup your Channel sign up Heading and Welcome message for Members.",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Channel Reports",
										"text": "You can check here your Channel Reports like: Daily Activity, Past Classes, Future Classes, Clients, Billing.",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Invite Instructors",
										"text": "If you wanted to add instructors to your channel, use this option to send an invite. Once the invite is accepted, the new Instructor can host Classes on your Channel.",
										"second_text": "The number of Instructors allowed on a Channel is based on the plan you signed up for. If you need to add more Instructors than your current Plan allows (Click you Channel Settings Icons to see Hosts available to be added), you can upgrade your Plan at anytime.",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Add Members",
										"text": "When creating a Class, you have the choice to mark the Class as Private or Public (by default Class is marked as Public). A Class marked Public, allows anyone with the access to the Class Link or Channel Page, if published on Channel Page, to Sign Up for the Class. ",
										"second_text": "If the Class is marked Private, then anyone signing up for the Class must be a Member of the Channel. You can use this option to add Members to your channel or use the Virdio automatic procces.",
										"third_text": "Setting Class to a Private and adding Members is optional. Using the Private Class option allows for an added level of security by limiting who can sign up for your Classes.",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "Products",
										"text": "Use this option to add Products you want to showcase in your Class",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									},
									{
										"title": "To create a Class",
										"text": "To create a new Class, use this button",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									}
								],
			fitnessSessionTutorial: [
										{
											"title": "Calendar Picker",
											"text": "",
											"second_text": "",
											"third_text": "",
											"enumerationTitle": "",
											"enumeration": [
																{
																	"enumerationText": "On this screen you will pick the date and time you want to schedule the Class"
																},
																{
																	"enumerationText": 'If you have a Recurring Class, you will click on the "Setup as Recurring" button.'
																}
															],
											"footerText": ""
										},
										{
											"title": "Assign Class to Instructor",
											"text": "When you create a Class you will be the default Instructor of the Class, if you have multiple instructors on your channel you can assign the Class to another Instructor.",
											"second_text": "",
											"third_text": "",
											"enumerationTitle": "",
											"enumeration": [],
											"footerText": ""
										},
										{
											"title": "Workout Activities",
											"text": "",
											"second_text": "",
											"third_text": "",
											"enumerationTitle": "",
											"enumeration": [
																{
																	"enumerationText": "In this section of the Class you define what activities the workout will consist of."
																},
																{
																	"enumerationText": "After you enter the activity information, use the '+ Activity' button to add Activity to your Class."
																},
																{
																	"enumerationText": "Repeat the proccess until you have added all the Activities in your Class."
																},
																{
																	"enumerationText": "Everyone participating in the Class will see the activities and know the duration or reps in the activity."
																},
																{
																	"enumerationText": "Adding activities to the Class is optional but reccomended for the best experience."
																}
															],
											"footerText": ""
										}
									],
			wineSessionTutorial: [
									{
										"title": "Calendar Picker",
										"text": "",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [
															{
																"enumerationText": "On this screen you will pick date and time you want to schedule the Class"
															},
															{
																"enumerationText": "If you have reccuring Class, you will click on the 'Setup as Recurring' button"
															}
														],
										"footerText": ""
									},
									{
										"title": "Assign Class to Instructor",
										"text": "When you create a Class you will be the default Instructor of the Class, if you have multiple instructors on your channel you can assign the Class to another Instructor.",
										"second_text": "",
										"third_text": "",
										"enumerationTitle": "",
										"enumeration": [],
										"footerText": ""
									}
								]
    }
}

prevDefault = (e) => {
	e.preventDefault();
	e.stopPropagation();
}
 
componentDidMount(){
	let tutorialPage = this.props.chosenTutorial;
	let tutorialLastIndexVar = this.state[tutorialPage].length-1;
	
	this.setState({
		chosenTutorial: tutorialPage,
		tutorialLastIndex: tutorialLastIndexVar
	});
	
	let allHeight = $(window).height();
	let allWidth = $(window).width();
	
	$('.tutorial_back').css('height', allHeight+'px');
	$('.tutorial_back').css('width', allWidth+'px');
}


  render() {
	 return (
		<div>
			<div className="tutorial_container" onClick={this.prevDefault}>
				{ typeof this.state.chosenTutorial != 'undefined' && typeof this.state.chosenTutorial[this.props.state.chosenTutorialIndex] != 'undefined' &&
					<div>
						<div className={ "tutorial_title " + ( this.props.state.chosenTutorialIndex > 0 ? "left_title" : "" ) }>{this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].title}</div>
						<div className="tutorialInnerScroll">
						<div className="tutorial_text">{this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].text}</div>
						{ this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].second_text !== '' &&
							<div className="tutorial_text">{this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].second_text}</div>
						}
						{ this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].third_text !== '' &&
							<div className="tutorial_text">{this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].third_text}</div>
						}
						{ this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].enumerationTitle !== '' &&
							<div className="enumeration_title">{this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].enumerationTitle}</div>
						}
						{ this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].enumeration.length > 0 &&
							<div>
								{ this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].enumeration.map((row, i)=>
									<div className="tutorial_text" key={i}><span>{i+1}.</span> {row.enumerationText}</div>
								)}
							</div>
						}
						{ this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].footerText !== '' &&
							<div className="tutorial_text">{this.state[this.state.chosenTutorial][this.props.state.chosenTutorialIndex].footerText}</div>
						}
						</div>
						<div className="tutorial_footer">
							<div className="tutorial_footer_l">
								{ this.props.state.chosenTutorialIndex != this.state.tutorialLastIndex &&
									<div className="tutorial_buttons" onClick={this.props.skipTutorial}>SKIP</div>
								}
								{ this.props.state.chosenTutorialIndex === this.state.tutorialLastIndex && this.state.chosenTutorial === 'hostDashboardTutorial' &&
									<div className="tutorial_buttons" onClick={this.props.skipTutorial}>SKIP</div>
								}
							</div>
							<div className="tutorial_footer_r">
								{ this.props.state.chosenTutorialIndex != 0 &&
									<div className="tutorial_buttons" onClick={ () => this.props.changeTutorialIndex('Prev') }>Prev</div>
								} 
								{ this.props.state.chosenTutorialIndex != this.state.tutorialLastIndex &&
									<div className="tutorial_buttons ml20" onClick={ () => this.props.changeTutorialIndex('Next') }>Next</div>
								}
								{ this.props.state.chosenTutorialIndex === this.state.tutorialLastIndex &&
									<div className="tutorial_buttons ml20" onClick={this.props.skipTutorial}>Finish</div>
								}
							</div>
						</div>
					</div>
				}
			</div>
			<div className={ "tutorial_back " + ( this.props.state.chosenTutorialIndex > 0 || this.state.chosenTutorial == 'wineSessionTutorial' || this.state.chosenTutorial == 'fitnessSessionTutorial' ? "none_trans" : "" ) } onClick={this.prevDefault}></div>
		</div>
    );
  }
}
export default MainTutorial;
