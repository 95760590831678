export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_CHANNEL = "SET_CURRENT_CHANNEL";
export const ADD_PLAYLIST = "ADD_PLAYLIST";
export const ADD_PLAYLIST_SONGS = "ADD_PLAYLIST_SONGS";
export const TOOGLE_POPUP = "TOOGLE_POPUP";
export const TOOGLE_PLAYLIST_POPUP = "TOOGLE_PLAYLIST_POPUP";
export const CHANGE_PLAYLIST_STATUS = "CHANGE_PLAYLIST_STATUS";
export const UPDATE_PLAYLISTS = "UPDATE_PLAYLISTS";
export const RESET_SONG_TO_ADD = "RESET_SONG_TO_ADD";
export const DELETE_PLAYLIST = "DELETE_PLAYLIST";
export const SHOW_MAIN_ERROR = "SHOW_MAIN_ERROR";
export const ADD_ELECTRON_PLAYLIST = "ADD_ELECTRON_PLAYLIST";