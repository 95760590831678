import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router";
import axios from "axios";
import moment1 from 'moment-timezone';
import $ from 'jquery';

class MemberPaymentHistory extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
		historyData: [],
    }
	
}
 
componentDidMount(){
	this.checkMemberPaymentHistory();
}


checkMemberPaymentHistory=()=>{
	const participentDetail = {
		'memberId': this.props.state.currentUserData.id,
	};
	$('.main_loader').attr({'style':'display:flex'});	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getMemberPaymentHistory", participentDetail).then(res => {
		if ( res.data.responseData )
			{
				let historyArray = res.data.responseData;
				for ( var i = 0; i < historyArray.length; i++ )
					{
						let payDate = historyArray[i]['updatedDate'];
						var formatDate = moment1(new Date(payDate)).format('MM/DD/YY');
						historyArray[i]['formatedDate'] = formatDate;
						
						historyArray[i]['formatedCard'] = '';
						
						var cardInfo = historyArray[i]['cardInfo'];
						if ( cardInfo )
							{
								var cardInfoArray = cardInfo.split('***');
								var cardType = cardInfoArray[0].trim();
								var cardNum = '';
								
								if ( cardInfoArray[1] )
									{
										cardNum = cardInfoArray[1];
									}
								
								historyArray[i]['formatedCard'] = cardType + '-' + cardNum;
							}
								
					}
				this.setState({
					historyData: historyArray,
				});
				$('.main_loader').attr({'style':'display:none'});		
			}
		else 
			{
				this.setState({
					historyData: [],
				})
				$('.main_loader').attr({'style':'display:none'});	
			}			
		
		
	}).catch(err =>{
		console.log('-error', err);
	}); 
}

  render() {
	 return (
		<div className="memberRegisterHolder more_width_welcome ccOnProfile paymentsWidth">
			<div className="closePaymentsHistory" onClick={(e)=>this.props.updateStateFromChild({showPaymentHistory: false})}></div>
			<div className="sign_title">Payment History</div>
			<div className="paymentHistoryValuesHolder">
				<div className="onePaymentValue">Date</div>
				<div className="onePaymentValue">Plan</div>
				<div className="onePaymentValue">Payment Method</div>
				<div className="onePaymentValue">Amount</div>
			</div>
			{this.state.historyData.length > 0 ? 
				<div>
					{ this.state.historyData.map((item, i)=>
						<div className="paymentsOneData" key={i}>
							<div className="onePaymentValue">{item['formatedDate']}</div>
							<div className="onePaymentValue">{item['planName']}</div>
							<div className="onePaymentValue">{item['formatedCard']}</div>
							<div className="onePaymentValue">${(item['calcPrice']).toFixed(2)}</div>
						</div>
					)}
				</div>
				:
				<div className="noHistoryText">No history</div>
			} 
		</div>
    );
  }
}
export default MemberPaymentHistory;
