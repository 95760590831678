import { TOOGLE_POPUP, TOOGLE_PLAYLIST_POPUP,SHOW_MAIN_ERROR } from "../actions/types";

const initialState = 
	{
		showPlaylistPopup: false,
		popupFinishState: false,
		showPlaylistMainPopup: false,
		mainErrorPopMsg: false,
		popupAlign: false,
		callokf: null
	};
 
const libraryPopups = (state = initialState, action) => {
  switch (action.type) {
    case TOOGLE_POPUP: {
	   if( action.payload.finish )
		   {
			    return {
					  ...state,
					  showPlaylistPopup: action.payload.isOpen,
					  popupFinishState: true
				  }
		   }
	  else
		  {
			   return {
					  ...state,
					  showPlaylistPopup: action.payload.isOpen,
					  popupFinishState: false
				  }
		  }
    }
	case TOOGLE_PLAYLIST_POPUP: {
		return { 
			...state,
			showPlaylistMainPopup: !state.showPlaylistMainPopup
		}
	}
	case SHOW_MAIN_ERROR: {
		return {
			...state,
			mainErrorPopMsg: action.payload.msg,
			popupAlign: action.payload.align,
			callokf: action.payload.callokf
		}
	}
    default: {
      return state;
    }
  }
};

export default libraryPopups;
