import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class ReportsHistoryParticipants extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartDate: null,
					searchEndDate: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					sortByVal: ['userId', 'DESC'],
					ajaxLoaded: false,
					checkInfoOpen: false
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	document.addEventListener('mousedown', this.checkInfoOpen);
}
 
componentWillUnmount() {
	document.removeEventListener('mousedown', this.checkInfoOpen);
}

checkInfoOpen = (e) => {
	if(e.target !== "info_btn" && $(e.target).closest('.info_btn').length === 0 )
		{
			this.setState({ checkInfoOpen: false });
		}
}

openMoreInfo=(e, justClose)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	this.setState({ checkInfoOpen: false });
};

changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchSessionDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	console.log(mod);
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchSessionDetails(mod, this.state.page_num_count);
		}
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ 
								showSearch: !this.state.showSearch, 
								searchStartDate: null, 
								searchEndDate: null, 
								searchBy: '' }, ()=>{
									this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
								});
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

customChecked = () => {
	this.setState({ customCheck1: !this.state.customCheck1 });
}

searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchSessionDetails(pg, this.state.page_num_count); 
}

fetchSessionDetails = (page_id, countId, searchText = this.state.searchBy, startDate= this.state.searchStartDate, endDate= this.state.searchEndDate) => {
	
	
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});
  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getAllParticipants", { page_id: page_id, countId: countId, searchText: searchText, startDate: startDate, endDate: endDate, sortby: this.state.sortByVal })          
  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({billing_info: res.data.responseData['participants'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	if( (this.state.page_id+6) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			 
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}

checkScroll = (ind) => {
	this.setState({
		checkInfoOpen: ((ind+3) >= this.state.billing_info.length) ? true : false
	});
}

showMoreInfo = (e, indx) => {
	e.stopPropagation();
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
	this.checkScroll(indx);
}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	});
}

getSessionsInfo = (e, hostId, indx, mod) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	$('.main_loader').attr({'style':'display:flex'});
	var data = [...this.state.billing_info];
	
	$(e.currentTarget).find('.all_info').addClass('active');
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getAllParticipantSessionsInfo", { 'participantId': hostId })          
	.then(res => {
		data[indx][mod] = res.data.responseData;
		this.setState({billing_info: data
		},() => {
			this.checkScroll(indx);
			$('.main_loader').hide();
		});
	})
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	  $('.main_loader').hide();
	});
}

stopClick = (e) => {
	e.stopPropagation();
	e.preventDefault();
}

changeUserStatus=(i)=>{
	var array = this.state.billing_info;
	if ( array[i].isMarketplaceUser == 0 ) { array[i].isMarketplaceUser = 1 }
	else { array[i].isMarketplaceUser = 0 }
	
	this.setState({billing_info: array});
	
	var postData = {userId: array[i].userId, isMarketplaceUser: array[i].isMarketplaceUser}
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateMarketplaceUser", postData).then(res => {}).catch(err =>{});
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className="pay_head_title">Members</div>
								</div>
								<div className="show_pay_page">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
										</select>
									 </span>
									Members
								</div>
								<div className="show_search_opt">
									
									{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-4' : ' col-xl-6')}>
														<input type="text" className="form-control" placeholder="Search for member name or member email" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
													</div>
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-8' : ' col-xl-6')}>
														{/* <div className="">
															<div className="px-4 d-flex justify-content-end align-items-center">				
																<div className="custom-control custom-checkbox mb-0">
																	<input type="checkbox" className="custom-control-input" id="customCheck1" value="false" checked={this.state.customCheck1} onChange={this.customChecked} name="example1" />
																	<label className="custom-control-label" htmlFor="customCheck1"><p className="pl-2 p-top-3 mb-0">ADVANCED</p></label>
																</div>
															</div>
														</div> */}
														<div className="parent-row mb-3 full_width">				
															<div className="row mx-0 row1 d-flex">
																<div className="col-md-12 d-flex flex-wrap align-items-center">	
																	<div className="filter_item">
																		<span className="searh_span">From</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchStartDate} onChange={date => this.setState({searchStartDate: date})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchEndDate} onChange={date => this.setState({searchEndDate: date})} />
																	</div>
																</div>
															</div>
														</div>
												</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
									
									
									
								</div>
								{ this.state.billing_info.length > 0 && 
									<table className="billing_table">
										<thead>
											<tr>
												<th>Member Id</th>
												<th>Member Name
													<div onClick={ (e) => this.sortArrays('firstName')} className="reports_sort_icon"></div>
												</th>
												<th>Member Email</th>
												<th>Date Sign up</th>
												<th>Attended Classes</th>
												<th>Channel Member</th>
												<th>Marketplace User</th>
											</tr>
										</thead>
										<tbody> 
											{ this.state.billing_info.map((item, key) =>
												<tr key={key}>
													<td>{item['userId']}</td>
													<td>{item['firstName']} {item['lastName']}</td>
													<td>{item['email']}</td>
													<td>{ this.getSessionDate(item['createAt'])} @ {this.getSessionTime(item['createAt'])}</td>
													<td>{item['totalParticipantSessions']}
														{ item.totalParticipantSessions > 0 && 
															<div className="info_btn" onClick={(e) => this.getSessionsInfo(e, item['userId'], key, 'totalSessionsArray')}>
																<div className="all_info pink_info all_info_daily" onClick={ (e) => this.stopClick(e) }>
																	<div className="close_all_info">
																		<div className="close_all_info_btn" onClick={ (e) => this.openMoreInfo(e, true)}>x</div>
																	</div>
																	<div className="scroll_all_info">
																	{ item.totalSessionsArray &&
																		<table>
																			<thead>
																				<tr>
																					<td>Channel Name</td>
																					<td>Class ID</td>
																					<td>Class Name</td>
																					<td>Date</td>
																					<td>Duration</td>
																					<td>Class Status</td>
																				</tr>
																			</thead>
																			<tbody>
																			{ item.totalSessionsArray.map((it, ind) => 
																				<tr key={ind}>
																					<td>{it['channelName']}</td>
																					<td>{it['sessionID']}</td>
																					<td>{it['sessionName']}</td>
																					<td>{ this.getSessionDate(it['scheduleDate'])} @ {this.getSessionTime(it['scheduleDate'])}</td>
																					<td>{it['duration']}</td>
																					<td>{it['classStatus']}</td>
																				</tr>
																			)}
																			</tbody>
																		</table>
																	}
																	</div>
																</div>
															</div>
														}
													</td>
													<td>{item['totalParticipantMemberChannels']}
														{ item.totalParticipantMemberChannels > 0 && 
															<div className="info_btn" onClick={(e) => this.showMoreInfo(e, key)}>
																<div className="all_info pink_info all_info_daily wdth500">
																	<div className="close_all_info">
																		<div className="close_all_info_btn" onClick={ (e) => this.openMoreInfo(e, true)}>x</div>
																	</div>
																	<div className="scroll_all_info">
																	{ item.totalMemberChannelsArray &&
																		<table>
																			<thead>
																				<tr>
																					<td>Channel Id</td>
																					<td>Channel Name</td>
																				</tr>
																			</thead>
																			<tbody>
																			{ item.totalMemberChannelsArray.map((it, ind) => 
																				<tr key={ind}>
																					<td>{it['channelId']}</td>
																					<td>{it['channelName']}</td>
																				</tr>
																			)}
																			</tbody>
																		</table>
																	}
																	</div>
																</div>
															</div>
														}
													</td>
													<td>
														<div className="switch_check" onClick={() => this.changeUserStatus(key)}>
															<input type="checkbox" checked={item.isMarketplaceUser} />
															<span className="slider_filter round_filter"></span>
														</div>
													</td>
												</tr>
											
											) }
										</tbody>
									</table>
								}
								{ this.state.billing_info.length > 0 && 
									<div>
										<div className="showPageNavigation">
											<div className="showpinfo">Showing { this.state.page_id+1 } to { this.state.page_num_count } of { this.state.fullCount } members</div>
											<div className="shopnumbers">
												
												{ (this.state.page_id - 1) > -1 &&
													<span onClick={(e) => this.changePage(e, this.state.page_id - 1)}>Previous</span>
												}
												
												<span>
													{ this.state.page_numbers.map((nitem, nindex) => 
														<span key={nindex} onClick={(e) => this.changePage(e, nitem-1)} className={"pgNumbers " + (this.state.page_id+1 === nitem ? 'active' : '')}>{nitem}</span>	
													)}
												</span>
												
												 { (this.state.page_id + 1) < this.state.maxPages &&
													<span onClick={(e) => this.changePage(e, this.state.page_id + 1)}>Next</span>
		
												 }
												</div>
										</div>
										<div className={ this.state.checkInfoOpen ? 'mob_pad_b250' : ''}></div>
									</div>
								}
								{ this.state.billing_info.length == 0 && this.state.ajaxLoaded && <div className="noResults2">No Members Found</div> }
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Coming Soon</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
					</div>
				</div>
			</div>
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default ReportsHistoryParticipants;