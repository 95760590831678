import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import ReportsPages from '../reports/ReportsPages';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import moment from 'moment';
import { getLocalDateTime } from '../../util/formatDateFunctions';

class D2cmember extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartTrial: new Date(),
					searchTrialTo: new Date(),
					searchEndTrial: null,
					searchEndTo: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					totalPagesNum: 0,
					page_numbers: [],
					sortByVal: ['u.id', 'DESC'],
					ajaxLoaded: false,
					checkInfoOpen: false,
					itemsDescription: 'Members',
					allWeeks: [],
					filterStartTrialWeek: '',
					filterEndTrialWeek: '',
					filterPlan: '',
					filterPeriod: '',
					filterHasCC: '',
					filterInstructors: '',
					filterCoupons: '',
					filterUtms: '',
					filterUtmSource: '',
					filterOptions: {instructors: [], coupons: [], utms: [], utmsources: []}
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
	this.getLocalDateTime = getLocalDateTime.bind(this);
}

componentDidMount(){
	this.getWeeks(0);
	
	if ( window['filterOptionsMembers'] )
		{
			this.setState({filterOptions: window['filterOptionsMembers']});
		}
	else
		{
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
			
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getMemberReportsFilter", {getFilters: 1}).then(res => {
				if ( res && res.data && res.data.responseData && res.data.responseData['filters'] )
					{
						window['filterOptionsMembers'] = res.data.responseData['filters'];
						this.setState({filterOptions: res.data.responseData['filters']});
					}
			});
		}
	
	document.addEventListener('mousedown', this.checkInfoOpen);
}
 
componentWillUnmount() {
	document.removeEventListener('mousedown', this.checkInfoOpen);
}

checkInfoOpen = (e) => {
	if(e.target !== "info_btn" && $(e.target).closest('.info_btn').length === 0 )
		{
			this.setState({ checkInfoOpen: false });
		}
}

openMoreInfo=(e, justClose)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	this.setState({ checkInfoOpen: false });
};

changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchSessionDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchSessionDetails(mod, this.state.page_num_count);
		}
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ 
				showSearch: !this.state.showSearch, 
				searchStartTrial: new Date(), 
				searchTrialTo: new Date(), 
				searchEndTrial: null, 
				searchEndTo: null, 
				searchBy: '', 
				filterStartTrialWeek: '', 
				filterPlan: '', 
				filterPeriod: '', 
				filterHasCC: ''}, ()=>{
					this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
				});
			
			
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchSessionDetails(pg, this.state.page_num_count); 
}

fetchSessionDetails = (page_id, countId, searchText = this.state.searchBy, startTrial= this.state.searchStartTrial, trialTo= this.state.searchTrialTo, endTrial= this.state.searchEndTrial, endTo= this.state.searchEndTo) => {

	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});

  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getMemberReports", {
																			page_id: page_id,
																			countId: countId,
																			searchText: searchText,
																			startTrial: moment(startTrial).startOf('day').toDate(),
																			trialTo: moment(trialTo).endOf('day').toDate(),
																			endTrial: endTrial ? moment(endTrial).startOf('day').toDate() : null,
																			endTo: endTo ? moment(endTo).endOf('day').toDate() : null,
																			sortby: this.state.sortByVal,
																			filterPlan: this.state.filterPlan,
																			filterPeriod: this.state.filterPeriod,
																			filterHasCC: this.state.filterHasCC,
																			filterInChallenge: this.state.filterInChallenge,
																			
																			filterInstructors: this.state.filterInstructors,
																			filterCoupons: this.state.filterCoupons,
																			filterUtms: this.state.filterUtms,
																			filterUtmSource: this.state.filterUtmSource
																		})

  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({billing_info: res.data.responseData['members'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	
	
	var totalPagesNum = 0;
	if ( res.data.responseData['maxPages'] > 0 )
		{
			let totalNumVar = parseInt(res.data.responseData['maxPages']);
			
			totalPagesNum = totalNumVar / parseInt(this.state.page_num_count);
			
			totalPagesNum = Math.ceil(totalPagesNum);
		}
	
	this.setState({
		totalPagesNum: totalPagesNum,
	});
	
	if( (this.state.page_id+3) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			let newState = this.state.page_id < 3 ? 0 : this.state.page_id - 2;
			
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}

checkScroll = (ind) => {
	this.setState({
		checkInfoOpen: ((ind+3) >= this.state.billing_info.length) ? true : false
	});
}

showMoreInfo = (e, indx) => {
	e.stopPropagation();
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
	this.checkScroll(indx);
}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	});
}

handleFilterChange = (e, mod) => {

	let mainthis = this;
	this.setState({
		[e.id]: e.value
	}, ()=>{
		if (mod)
			{
				mainthis.choseWeek(e.value, mod)
			}
	});
}

choseWeek = (e, mod) =>{
	let mainthis = this;
	let tempStart = '';
	let tempEnd = '';
	if (e != '' )
		{
			tempStart = new Date(e);
			let temp = tempStart.getTime()+(7*24*60*59*1000);
			tempEnd = new Date(temp);
		}
	if ( mod == 'startTrialWeek' )
		{
			this.setState({
				searchStartTrial: tempStart, 
				searchTrialTo: tempEnd
			}, ()=>{ if(mod) {
					mainthis.fetchSessionDetails(mainthis.state.page_id, mainthis.state.page_num_count);
				}});
		}
	else
		{
			this.setState({
				searchEndTrial: tempStart, 
				searchEndTo: tempEnd
			}, ()=>{ if(mod) {
					mainthis.fetchSessionDetails(mainthis.state.page_id, mainthis.state.page_num_count);
				}});
		}
}

getWeeks = mod =>{ 
	let mainthis = this;
	var dt = new Date();
	var currentYear = dt.getFullYear();
	var currentMonth = dt.getMonth();
	let fistDayMonth = new Date((new Date().getFullYear()-mod), 0, 1);
	let topDate = new Date();
	
	let dates = []
	const addDays = function (days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};
		
	let currentDate = fistDayMonth
	if (currentDate.getDay() > 0) {
		currentDate.setDate(currentDate.getDate() - currentDate.getDay());
	}
	 
	while (currentDate <= topDate) {
	  let endWeekDate = addDays.call(currentDate, 6);
	  dates.push({
			begin: currentDate, 
			end: endWeekDate,
			title: moment(currentDate).format('MMM DD, YYYY')+'/'+moment(endWeekDate).format('MMM DD, YYYY'),
			value: currentDate
		});
	  currentDate = addDays.call(currentDate, 7);
	 }
	let reverseWeeks = dates.reverse();
	this.setState({
		allWeeks: reverseWeeks,
	}, ()=> { mainthis.fetchSessionDetails(mainthis.state.page_id, mainthis.state.page_num_count);});
}

exportCsv=()=>{
	
	let csv = 'Member Id, First Name, Last Name, Email, Created At, Trial End, Plan, Period, Coupon Name, UTM Code Name, UTM Source, Last Class Book, Last Class Attend, Last On-Demand Viewed Date, Live Classes Attended (last 30 days), OnDemand Classes Attended (last 30 days), HassCC, inChallenge, Subscription Status'+"\r\n";
	
	for ( let i = 0; i < this.state.billing_info.length; i++ )
		{
			let date = this.getSessionDate(this.state.billing_info[i]['createdDate'])+' @ '+this.getSessionTime(this.state.billing_info[i]['createdDate']);
			csv += [this.state.billing_info[i]['userId'], 
					this.state.billing_info[i]['firstName'], 
					this.state.billing_info[i]['lastName'], 
					this.state.billing_info[i]['email'], 
					this.state.billing_info[i]['createAt'] ? this.getLocalDateTime(this.state.billing_info[i]['createAt']) : '-', 
					//this.state.billing_info[i]['trialStartedDate'] ? this.getLocalDateTime(this.state.billing_info[i]['trialStartedDate']) : '-', 
					this.state.billing_info[i]['trialEndDate'] ? this.getLocalDateTime(this.state.billing_info[i]['trialEndDate'], true) : '-', 
					this.state.billing_info[i]['planPricing'] ? this.state.billing_info[i]['planPricing'] : '-',
					this.state.billing_info[i]['planType'] ? this.state.billing_info[i]['planType'] : '-',
					this.state.billing_info[i]['subCode'] ? this.state.billing_info[i]['subCode'] : '-',
					this.state.billing_info[i]['utm_campaign'] ? this.state.billing_info[i]['utm_campaign'] : '-',
					this.state.billing_info[i]['utm_source'] ? this.state.billing_info[i]['utm_source'] : '-',
					this.state.billing_info[i]['last_class_book'] ? this.getLocalDateTime(this.state.billing_info[i]['last_class_book']) : '-', 
					this.state.billing_info[i]['last_class_attend'] ? this.getLocalDateTime(this.state.billing_info[i]['last_class_attend']) : '-', 
					this.state.billing_info[i]['onDemandLastViewDate'] ? this.getLocalDateTime(this.state.billing_info[i]['onDemandLastViewDate']) : '-', 
					this.state.billing_info[i]['last30LiveAttending'], 
					this.state.billing_info[i]['last30OndemandAttended'] ? this.state.billing_info[i]['last30OndemandAttended'] : '-', 
					this.state.billing_info[i]['hasCC'] == 1 ? 'Yes' : 'No', 
					this.state.billing_info[i]['inChallenge'] == 1 ? 'Yes' : 'No', 
					this.state.billing_info[i]['activeStatus'] ? this.state.billing_info[i]['activeStatus'] : '-'].join(',')+"\r\n";
		}
	
	$('#exportcsvmembers').attr({'href': 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv), download: 'Members.csv'});
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className="pay_head_title">Members</div>
								</div>
								<div className="show_pay_page chwidth15">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
											<option value="10000000">All</option>
										</select>
									 </span>
									{this.state.itemsDescription}
									{ this.state.billing_info.length > 0 &&
										<div className="outExport">
											<div className="exportDiv">
												<a href="#" id="exportcsvmembers"><div className="rose_btn" onClick={()=> { this.exportCsv(); }}>Export CSV</div></a>
											</div>
										</div>
									}
								</div>
								<div className="show_search_opt chwidth85">
									
									{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center col-xl-6 fl43">
														<input type="text" className="form-control" placeholder="Search for member name or email, coupon name, utm code name, instructor name" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
														
														{ this.state.filterOptions && this.state.filterOptions['instructors'] && 
															<div className="form-group-header interest_search">
																<span className="cover-border"></span>
																<label className="label dashboard_search_label">Instructor</label>
																<select className="input-field dashboard_main_select" id="filterInstructors" onChange={(e) => this.handleFilterChange(e.target)}>
																	<option value=""></option>
																	{ this.state.filterOptions['instructors'].map((item, key)=> <option key={key} value={item.id}>{item.name}</option> )}
																</select>
														</div> }
														
														{ this.state.filterOptions && this.state.filterOptions['coupons'] && 
															<div className="form-group-header interest_search">
																<span className="cover-border"></span>
																<label className="label dashboard_search_label">Coupon Code</label>
																<select className="input-field dashboard_main_select" id="filterCoupons" onChange={(e) => this.handleFilterChange(e.target)}>
																	<option value=""></option>
																	{ this.state.filterOptions['coupons'].map((item, key)=> <option key={key} value={item.id}>{item.name}</option> )}
																</select>
														</div> }
														
														{ this.state.filterOptions && this.state.filterOptions['utms'] && 
															<div className="form-group-header interest_search">
																<span className="cover-border"></span>
																<label className="label dashboard_search_label">UTM Code</label>
																<select className="input-field dashboard_main_select" id="filterUtms" onChange={(e) => this.handleFilterChange(e.target)}>
																	<option value=""></option>
																	{ this.state.filterOptions['utms'].map((item, key)=> <option key={key} value={item.name}>{item.name}</option> )}
																</select>
														</div> }
														{ this.state.filterOptions && this.state.filterOptions['utmsources'] && 
															<div className="form-group-header interest_search">
																<span className="cover-border"></span>
																<label className="label dashboard_search_label">UTM Source</label>
																<select className="input-field dashboard_main_select" id="filterUtmSource" onChange={(e) => this.handleFilterChange(e.target)}>
																	<option value=""></option>
																	{ this.state.filterOptions['utmsources'].map((item, key)=> <option key={key} value={item.name}>{item.name}</option> )}
																</select>
														</div> }
														
														
														<div className="form-group-header interest_search">
															<span className="cover-border"></span>
															<label className="label dashboard_search_label">Plan</label>
															<select className="input-field dashboard_main_select" id="filterPlan" onChange={(e) => this.handleFilterChange(e.target)}>
																<option value=""></option>
																<option value='single'>Single</option>
																<option value='family'>Family</option>
															</select>
														</div>
														<div className="form-group-header interest_search">
															<span className="cover-border"></span>
															<label className="label dashboard_search_label">Period</label>
															<select className="input-field dashboard_main_select" id="filterPeriod" onChange={(e) => this.handleFilterChange(e.target)}>
																<option value=""></option>
																<option value='annual'>Annual</option>
																<option value='monthly'>Monthly</option>
															</select>
														</div>
														<div className="form-group-header interest_search">
															<span className="cover-border"></span>
															<label className="label dashboard_search_label">HasCC</label>
															<select className="input-field dashboard_main_select" id="filterHasCC" onChange={(e) => this.handleFilterChange(e.target)}>
																<option value=""></option>
																<option value='Yes'>Yes</option>
																<option value='No'>No</option>
															</select>
														</div>
														<div className="form-group-header interest_search">
															<span className="cover-border"></span>
															<label className="label dashboard_search_label">In Challenge</label>
															<select className="input-field dashboard_main_select" id="filterInChallenge" onChange={(e) => this.handleFilterChange(e.target)}>
																<option value=""></option>
																<option value='Yes'>Yes</option>
																<option value='No'>No</option>
															</select>
														</div>
													</div>
													<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center nopadlr col-xl-6 fl57">
														<div className="parent-row mb-3 full_width">				
															<div className="row mx-0 row1 d-flex">
																<div className="col-md-12 d-flex flex-wrap align-items-center">	
																	<div className="filter_item">
																		<span className="searh_span">Trial Start</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchStartTrial} onChange={date => this.setState({searchStartTrial: date, filterStartTrialWeek: ''})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchTrialTo} onChange={date => this.setState({searchTrialTo: date, filterStartTrialWeek: ''})} />
																	</div>
																	<div className="form-group-header interest_search mrglft15">
																		<span className="cover-border"></span>
																		<label className="label dashboard_search_label">Week</label>
																		<select className="input-field dashboard_main_select" id="filterStartTrialWeek" value={this.state.filterStartTrialWeek} onChange={(e) => this.handleFilterChange(e.target, 'startTrialWeek')} >
																			<option value=""></option>
																				{ this.state.allWeeks.map((item, key)=> 
																					<option value={item.value} key={key}>{item.title}</option>
																				)}
																		</select>
																	</div>
																</div>
																<div className="col-md-12 d-flex flex-wrap align-items-center">	
																	<div className="filter_item">
																		<span className="searh_span">Trial End</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchEndTrial} onChange={date => this.setState({searchEndTrial: date, filterEndTrialWeek: ''})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" isClearable selected={this.state.searchEndTo} onChange={date => this.setState({searchEndTo: date, filterEndTrialWeek: ''})} />
																	</div>
																	<div className="form-group-header interest_search mrglft15">
																		<span className="cover-border"></span>
																		<label className="label dashboard_search_label">Week</label>
																		<select className="input-field dashboard_main_select" id="filterEndTrialWeek" value={this.state.filterEndTrialWeek} onChange={(e) => this.handleFilterChange(e.target, 'endTrialWeek')} >
																			<option value=""></option>
																				{ this.state.allWeeks.map((item, key)=> 
																					<option value={item.value} key={key}>{item.title}</option>
																				)}
																		</select>
																	</div>
																</div>
															</div>
														</div>
												</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
									
									
									
								</div>
								{ this.state.billing_info.length > 0 && 
									<table className="billing_table">
										<thead>
											<tr>
												<th className="paddR20">Member Id
													<div onClick={ (e) => this.sortArrays('u.id')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">First Name
													<div onClick={ (e) => this.sortArrays('u.firstName')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Last Name
													<div onClick={ (e) => this.sortArrays('u.lastName')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Email
													<div onClick={ (e) => this.sortArrays('u.email')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Created At
													<div onClick={ (e) => this.sortArrays('u.createAt')} className="reports_sort_icon vertMidd"></div>
												</th>
												{/*<th className="paddR20">Trial Start
													<div onClick={ (e) => this.sortArrays('T1.trialStartedDate')} className="reports_sort_icon vertMidd"></div>
												</th>*/}
												<th className="paddR20">Trial End
													<div onClick={ (e) => this.sortArrays('T1.trialEndDate')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Plan
													<div onClick={ (e) => this.sortArrays('T1.planPricing')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Period
													<div onClick={ (e) => this.sortArrays('T1.planType')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Coupon Name
													<div onClick={ (e) => this.sortArrays('mc.subCode')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">UTM Code Name
													<div onClick={ (e) => this.sortArrays('uuv.utm_campaign')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">UTM Source
													<div onClick={ (e) => this.sortArrays('uuv.utm_source')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Last Class Book
													<div onClick={ (e) => this.sortArrays('last_class_book')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Last Class Attend
													<div onClick={ (e) => this.sortArrays('last_class_attend')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Last On-Demand Viewed Date
													<div onClick={ (e) => this.sortArrays('onDemandLastViewDate')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Live Classes Attended (last 30 days)
													<div onClick={ (e) => this.sortArrays('last30LiveAttending')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">OnDemand Classes Attended (last 30 days)
													<div onClick={ (e) => this.sortArrays('last30OndemandAttended')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">HasCC
													<div onClick={ (e) => this.sortArrays('hasCC')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">In Challenge
													<div onClick={ (e) => this.sortArrays('u.inChallenge')} className="reports_sort_icon vertMidd"></div>
												</th>
												<th className="paddR20">Subscription Status
													<div onClick={ (e) => this.sortArrays('T1.activeStatus')} className="reports_sort_icon vertMidd"></div>
												</th>
											</tr>
										</thead>
										<tbody> 
											{ this.state.billing_info.map((item, key) =>
												<tr key={key}>
													<td>{item.userId}</td>
													<td>{item.firstName}</td>
													<td>{item.lastName}</td>
													<td>{item.email}</td>
													<td>{this.getLocalDateTime(item.createAt)}</td>
													{/* item.trialStartedDate ? 
														<td>{this.getLocalDateTime(item.trialStartedDate)}</td>
														:
														<td>-</td>
													*/}
													{ item.trialEndDate ? 
														<td>{this.getLocalDateTime(item.trialEndDate, true)}</td>
														:
														<td>-</td>
													}
													{ item.planPricing ? 
														<td className={" " + (item.familyEmails.length > 0 ? 'mn80' : '')}>{item.planPricing}
															{ item.familyEmails.length > 0 && 
																<div className="info_btn" onClick={(e) => this.showMoreInfo(e, key)}>
																	<div className="all_info pink_info all_info_daily wdth500" >
																		<div className="close_all_info">
																			<div className="close_all_info_btn" onClick={ (e) => this.openMoreInfo(e, true)}>x</div>
																		</div>
																		<div className="scroll_all_info">
																		{ item.familyEmails &&
																			<table>
																				<thead>
																					<tr>
																						<td>Email</td>
																					</tr>
																				</thead>
																				<tbody>
																				{ item.familyEmails.map((it, ind) => 
																					<tr key={ind}>
																						<td>{it}</td>
																					</tr>
																				)}
																				</tbody>
																			</table>
																		}
																		</div>
																	</div>
																</div>
															}
														</td>
														:
														<td>-</td>
													}
													{ item.planType ? 
														<td>{item.planType}</td>
														:
														<td>-</td>
													}
													{ item.subCode ? 
														<td>{item.subCode}</td>
														:
														<td>-</td>
													}
													{ item.utm_campaign ? 
														<td>{item.utm_campaign}</td>
														:
														<td>-</td>
													}
													{item.utm_source ? 
														<td>{item.utm_source}</td> 
														:
														<td>-</td>
													}
													{ item.last_class_book ?
														<td>{this.getLocalDateTime(item.last_class_book)}</td>
														:
														<td>-</td>
													}
													{ item.last_class_attend ?
														<td>{this.getLocalDateTime(item.last_class_attend)}</td>
														:
														<td>-</td>
													}
													{ item.onDemandLastViewDate ?
														<td>{this.getLocalDateTime(item.onDemandLastViewDate)}</td>
														:
														<td>-</td>
													}
													<td>{item.last30LiveAttending}</td>
													{ item.last30OndemandAttended ?
														<td>{item.last30OndemandAttended}</td>
														:
														<td>0</td>
													}
													{ item.hasCC == 0 ?
														<td>No</td>
														:
														<td>Yes</td>
													}
													{ item.inChallenge == 0 ?
														<td>No</td>
														:
														<td>Yes</td>
													}
													{ item.activeStatus ? 
														<td>{item.activeStatus}</td>
														:
														<td>-</td>
													}
												</tr>
											
											) }
										</tbody>
									</table>
								}
								{ this.state.billing_info.length > 0 && 
									<ReportsPages 
										page_id={this.state.page_id}
										page_num_count={this.state.page_num_count}
										fullCount={this.state.fullCount}
										maxPages={this.state.maxPages}
										changePage={this.changePage}
										page_numbers={this.state.page_numbers}
										checkInfoOpen={this.state.checkInfoOpen}
										itemsDescription={this.state.itemsDescription}
									/>
								}
								{ this.state.billing_info.length == 0 && this.state.ajaxLoaded && <div className="noResults2">No Members Found</div> }
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Coming Soon</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
					</div>
				</div>
			</div>
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default D2cmember;