import React, { Component } from "react";
import { Link } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import moment1 from 'moment-timezone';

import SimpleReactValidator from 'simple-react-validator';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import {  browserHistory } from 'react-router'
import Countdown from 'react-countdown-now';
import Login from '../auth/Login';
import MainSignUp from '../auth/MainSignUp';
import { submitHost } from '../../util/submitHost';
import { verify } from '../../util/verify';
import { channelCheckUser } from '../../util/channelCheckUser';
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import Header from './Header';
import ReactLightCalendar from '@lls/react-light-calendar'
import '@lls/react-light-calendar/dist/index.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import ChargingSession from '../home/ChargingSession';
import { getCountryCallingCode } from 'react-phone-number-input';
import { deleteCookie } from '../auth/Logout';
import SupportContainer from '../home/SupportContainer';
import EmailPreferences from '../home/EmailPreferences';
import { updatesse } from '../../util/updatesse';

class myProfile extends Component {
constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.phoneRef = React.createRef();
	this.state = {
		myProfileSelect: true,
		editProfile: false,
		changePassword: false,
		paymentMethods: false,
		emailPreferences: false,
		userInfo: {},
		userName: '',
		userLastName: '',
		userPhone: 0,
		userEmail: '',
		userBirthday: '',
		userGender: '',
		userCity: '',
		userAddress: '',
		userAddress2: '',
		userCountry: '',
		userCity: '',
		userZipCode: '',
		userState: '',
		userGender: '',
		editName: '',
		editLastName: '',
		editPhone: 0,
		editEmail: '',
		editBirthday: '',
		editGender: '',
		editAddress: '',
		editAddress2: '',
		editCountry: '',
		editCity: '',
		editZipCode: '',
		editState: '',
		userImage: '',
		userImageProfile: '',
		userType: 2,
		reminderCalender: new Date(),
		birthdayDate: '',
		sessionCalender: new Date(),
		sessionDay:'',
		sessionMonth:'',
		time2: '',
		sessionYear:'',
		genderList: ['Male', 'Female', 'Non-Binary', 'Prefer not to disclose'],
		showHelpPopup: false,
		passMinError: false,
		specialSymbol: false,
		numberContain: false,
		letterContain: false,
		password: '',
		editPassword: '',
		allIsChecked: false,
		password_confirm: '',
		passwordMatchError: false,
		isEmailValid: false,
		cardsArray: [],
		participantTotalSessions: 0,
		countryListMap: [],
		countryArray: [], //[{ Id: 1, postalCode: "United States", countryCode: "US" },{ Id: 2, postalCode: "Canada", countryCode: "CAN" }],
		stateArray: [],
		countryCodeId: 0,
		instructorTextProfile : '',
		instructorTextProfileArray : [],
		instructorTextProfileEdit: '',
		selectedCountryCode: null,
		passwordEyeOld: false,
		passwordEye: false,
		passwordEyeRepeat: false,
		regexZip: '',
		errorClass_country: false,
		supportPageName: 'My Profile',
		supportPageApi: '/myProfile',
		userTypeFaqs: JSON.parse(localStorage.getItem('userData')).data.responseData.type,
		editPasswordError: false,
		passwordError: false,
		password_confirmError: false,
		editEmailPassword: '',
		showResetEmail: false,
		emailStore: '',
		feetOptions: ['', 0, 1, 2, 3, 4, 5, 6, 7],
		inchOptions: ['', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
		poundsArray: ['', 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 152, 154, 155, 156, 167, 158,  159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180],
		userHeightFeet: '',
		userHeightInch: '',
		userWeight: '',
		editHeightFeet: '',
		editHeightInch: '',
		editWeight: '',
		fromParticipantDashboard: false,
		hideClose: false,
	}
	
	this.validator = new SimpleReactValidator({autoForceUpdate: this});  
	this.deleteCookie = deleteCookie.bind(this);
	this.updatesse = updatesse.bind(this);
}

getUserInfoAll=(e)=>{
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/usersGetAll",{"id": JSON.parse(localStorage.getItem('userData')).data.responseData.id})
		.then(res_e => {
			
			this.setState({
				userInfo: res_e.data.responseData,
				userName: res_e.data.responseData.firstName,
				userLastName: res_e.data.responseData.lastName,
				userPhone: res_e.data.responseData.phone ? res_e.data.responseData.phone : res_e.data.responseData.phone ? res_e.data.responseData.phone = '' : '-',
				userEmail: res_e.data.responseData.email,
				userCity: res_e.data.responseData.city ? res_e.data.responseData.city : res_e.data.responseData.city ? res_e.data.responseData.city = '' : '-',
				userAddress: res_e.data.responseData.address1 ? res_e.data.responseData.address1 : res_e.data.responseData.address1 ? res_e.data.responseData.address1 = '' : '-',
				userAddress2: res_e.data.responseData.address2 ? res_e.data.responseData.address2 : res_e.data.responseData.address2 ? res_e.data.responseData.address2 = '' : '-',
				userState: res_e.data.responseData.state ? res_e.data.responseData.state : res_e.data.responseData.state ? res_e.data.responseData.state = '' : '-',
				userCountry: res_e.data.responseData.country ? res_e.data.responseData.country : res_e.data.responseData.country ? res_e.data.responseData.country = '' : '-',
				userZipCode: res_e.data.responseData.zip ? res_e.data.responseData.zip : res_e.data.responseData.zip ? res_e.data.responseData.zip = '' : '-',
				userGender: res_e.data.responseData.gender ? res_e.data.responseData.gender : res_e.data.responseData.gender ? res_e.data.responseData.gender = '' : '-',
				userBirthday: res_e.data.responseData.birthday ? res_e.data.responseData.birthday : res_e.data.responseData.birthday ? res_e.data.responseData.birthday = '' : '-',
				editName: res_e.data.responseData.firstName,
				editLastName: res_e.data.responseData.lastName,
				editPhone: res_e.data.responseData.phone,
				editEmail: res_e.data.responseData.email,
				editAddress: res_e.data.responseData.address1,
				editAddress2: res_e.data.responseData.address2,
				editCity: res_e.data.responseData.city,
				editCountry: res_e.data.responseData.country ? res_e.data.responseData.country : '',
				editState: res_e.data.responseData.state ? res_e.data.responseData.state : '',
				editZipCode: res_e.data.responseData.zip ? res_e.data.responseData.zip : '',
				editGender: res_e.data.responseData.gender ? res_e.data.responseData.gender : '',
				birthdayDate: res_e.data.responseData.birthday ? res_e.data.responseData.birthday : '',
				userImage: res_e.data.responseData.image ? res_e.data.responseData.image : '/images/host-user.png',
				userImageProfile: res_e.data.responseData.image,
				//userType: res_e.data.responseData.type
				}, () => {
					
					let newName = res_e.data.responseData.firstName;
					let newLastName = res_e.data.responseData.lastName;
					let newImage = res_e.data.responseData.image;
					
					let userDataAll = JSON.parse(localStorage.getItem('userData'));
				
					userDataAll.data.responseData.firstName = newName;
					userDataAll.data.responseData.lastName = newLastName;
					userDataAll.data.responseData.image = newImage;
				
				
					 localStorage.setItem("userData", JSON.stringify(userDataAll));
				});
			
			let ins_text = res_e.data.responseData.instructorText ? res_e.data.responseData.instructorText : res_e.data.responseData.instructorText ? res_e.data.responseData.instructorText = '' : '-';
			let new_ins_text = '';
			if ( ins_text && ins_text !== '-' )
				{
					new_ins_text = ins_text.replace(/<br\s?\/?>/g,"\n");
				}
			this.setState({
				instructorTextProfile : ins_text,
				instructorTextProfileEdit : new_ins_text
			});
				
			this.getCountryCode(this.state.editCountry);
			
			if(res_e.data.responseData.phone == null)
				{
					this.setState({
						editPhone: ''
					});
				}
				
			if(res_e.data.responseData.address1 == null)
				{
					this.setState({
						editAddress: ''
					});
				}
				
			if(res_e.data.responseData.gender == null)
				{
					this.setState({
						editGender: ''
					});
				}
				
			if(res_e.data.responseData.birthday == null)
				{
					this.setState({
						birthdayDate: ''
					});
				}
			
			var userData = JSON.parse(localStorage.getItem('userData'));
			userData.data.responseData['image'] = res_e.data.responseData.image ? res_e.data.responseData.image : '/images/host-user.png';
			localStorage.setItem('userData', JSON.stringify(userData));

			if ( userData.data.responseData.type ) { this.setState({userType: userData.data.responseData.type}); }
			
			$('.user-avtar.pic.profile_avatar').css({'background-image': 'url("'+(res_e.data.responseData.image ? res_e.data.responseData.image : '/images/host-user.png')+'")'})
			
		}).catch(err =>{ });
		
}

componentDidMount(){
	
	this.getUserInfoAll();
	this.getCountries();
	
	let totals = localStorage.getItem('participantTotalSessions');
	if ( totals ) { this.setState({ participantTotalSessions: totals }); }

	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	 axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserCard",{"id": JSON.parse(localStorage.getItem('userData')).data.responseData.id})
		.then(res_e => {
			this.setState({ cardsArray: res_e.data.responseData['all'] })
		}).catch(err =>{ });
		
		
	$('#oldPassword').val('');
	$('#oldPassword1').val('');
	$('#password').val('');
	$('#password_confirm').val('');
	
	this.getHeightWeight();
	if ( localStorage.getItem('fromParticipantDashboard') )
		{
			localStorage.removeItem('fromParticipantDashboard');
			this.setState({ fromParticipantDashboard: true });
		}
		
	let currentUserData = JSON.parse(localStorage.getItem('userData')).data.responseData;
	var mainthis = this;
	this.updatesse('marketplaceDashboard', [currentUserData.id], function (data)
		{
			mainthis.getUserInfoAll();
			mainthis.getHeightWeight();
		});
	
	if ( window.location.hash !== '' && window.location.hash !== '#' )
		{
			this.setState({
				hideClose: true
			});
		}
}

getHeightWeight(){
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getCaloriesInfo").then(res => {
		if ( res.data.responseData && res.data.responseData.id )
			{
				this.setState({
					userHeightFeet: res.data.responseData.heightFeet,
					userHeightInch: res.data.responseData.heightInches,
					userWeight: res.data.responseData.weightPounds,
					editHeightFeet: res.data.responseData.heightFeet,
					editHeightInch: res.data.responseData.heightInches,
					editWeight: res.data.responseData.weightPounds,
				});
			}
    }).catch(err => { console.log('getCaloriesInfo error - ', err); });
}


getCountries(){
    axios      
    .get(process.env.REACT_APP_NAME+"/api/v1/user/getAllCountry")          
      .then(res => {
        //console.log(axios.defaults,'---------Country Code--------------',res.data.responseData[0]);
		
          this.setState({
			countryArray:res.data.responseData
           });
      })
      .catch(err =>{
        console.log('----------there is problem------------');

      });
}

changeProfileMod=(e)=>{
	
	if(e == 1)
		{
			this.setState({
				myProfileSelect: true,
				editProfile: false,
				changePassword: false,
				paymentMethods: false,
				emailPreferences: false
			});
		}
	
	else if(e == 2)
		{
			this.setState({
				myProfileSelect: false,
				editProfile: true,
				changePassword: false,
				paymentMethods: false,
				emailPreferences: false
			});
		}
		
	else if(e == 3)
		{
			this.setState({
				myProfileSelect: false,
				editProfile: false,
				changePassword: true,
				paymentMethods: false,
				emailPreferences: false
			});
		}
		
	else if(e == 4)
		{
			this.setState({
				myProfileSelect: false,
				editProfile: false,
				changePassword: false,
				paymentMethods: true,
				emailPreferences: false
			});
			
			setTimeout(function(){ $('.chargingProfile .hidden_div').trigger("click"); }, 100);
		}
		
	else if ( e == 5 )
		{
			this.setState({
				myProfileSelect: false,
				editProfile: false,
				changePassword: false,
				paymentMethods: false,
				emailPreferences: true
			});
		}
}

changeInputVal = (state) =>{
	this.setState(state);
}
changeAreaVal = (state) =>
{
	this.setState(state);
}

changeInputValNew = (state, mod) =>{
	console.log(mod);
	if ( mod == '' )
		{
			this.setState({
				errorClass_country: true,
			})
		}
	else 
		{
			this.setState({
				errorClass_country: false,
			})
		}		
	this.setState(state);
	console.log(state);
	this.getCountryCode(mod);
}
  
getCountryCode(countryName){
	let countryCodeIdBy;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getAllStateByName",{"countryName" : countryName})
	 .then(res_e => {
		 countryCodeIdBy = res_e.data.responseData[0].id;
		 var zipRegex = res_e.data.responseData[0].zipCodeRegex;
		 if ( zipRegex == '' ) { zipRegex = '^\d{5}$'; }
		 this.setState({regexZip: zipRegex});
		 this.getStateCode(countryCodeIdBy);
	}).catch(err =>{ 
		this.setState({
			editState : '',
			stateArray: []
		});
	});
	
}

getStateCode(countryName){
	var selected_countri_code = this.state.countryArray.find((item) => { return parseInt(item['id']) === parseInt(countryName) } );
	var selectedCountryCode = selected_countri_code ? selected_countri_code.countryCode : 'US';
	
	var call_code = '';
	try { call_code = getCountryCallingCode(this.state.selectedCountryCode ? this.state.selectedCountryCode : selectedCountryCode); } 
	catch(exception) { selectedCountryCode = 'US'; call_code = getCountryCallingCode('US'); };
	
	var new_call_code = '';
	try { new_call_code = getCountryCallingCode(selectedCountryCode); } 
	catch(exception) { selectedCountryCode = 'US'; new_call_code = getCountryCallingCode('US'); };

	var new_numb;
	if ( this.state.editPhone && this.state.editPhone !== '' ) { new_numb = this.state.editPhone.replace("+"+call_code, "+"+new_call_code); }
	else { new_numb = "+"+new_call_code; }
	
	axios      
	.get(process.env.REACT_APP_NAME+"/api/v1/user/"+countryName+"/getAllState")          
	  .then(res => {
		  this.setState({
				stateArray:res.data.responseData,
		   selectedCountryCode: selectedCountryCode,
		   editPhone: new_numb
		   })
	  })
	  .catch(err =>{
		  console.log('----------there is problem------------');

	  });
}

closePopup=(e)=>{
	this.setState({
		passwordMatchError: false
	});
}

validateZipRegex=(zip)=>{
	var zipCodePattern = new RegExp(this.state.regexZip);
    return zipCodePattern.test(zip);
}

editUserInfo=(e)=>{
	
	var isChangingEmail = false;
	if ( this.state.editEmail.trim() !== this.state.userEmail.trim() )
		{
			isChangingEmail = true;
			
			if ( this.state.editEmailPassword === '' )
				{
					this.props.showMainErrorPop("You are trying to change your email. In that case your password is required, please add it bellow email field.");
					return false;
				}
		}
	
	let descriptionText = $('#desc_text').val();
	let dsedMainText = '';
	if ( descriptionText && descriptionText !== '' )
		{
			dsedMainText = descriptionText.replace(/\r\n|\r|\n/g,"<br />");
		}
	
	let emailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	
	if (emailValid.test(this.state.editEmail.trim()))
		{ 
			this.setState({
				isEmailValid: true
			});
		}
		
	else
		{
			this.setState({
				isEmailValid: false
			});
		}

	let thisValidStatus = true;
	
	if ( this.state.editCountry )
		{
			if (isValidPhoneNumber(this.state.editPhone))
				{
					thisValidStatus = true;
				}
			else
				{
					thisValidStatus = false;
				}				
		}
	else 
		{
			thisValidStatus = false;
		}	
		
	var ages = 0;
	if ( this.state.birthdayDate !== '' )
		{
			var yearOfBirth = new Date(this.state.birthdayDate).getFullYear();
			var currentYear = new Date().getFullYear();
			ages = currentYear - yearOfBirth;
		}
		
	var canSaveHeightWeight = true;
	
	if ( JSON.parse(localStorage.getItem('userData')).data.responseData.type === 2 )
		{
			if ( this.state.editHeightFeet !== '' || this.state.editHeightInch !== '' )
				{
					if ( parseInt(this.state.editHeightFeet) === 0 && parseInt(this.state.editHeightInch) === 0 ) { canSaveHeightWeight = false; }
				}
		}
	
	if ( this.state.editWeight !== '' )
		{
			if ( parseInt(this.state.editWeight) <= 0 || isNaN(parseInt(this.state.editWeight)) ) { canSaveHeightWeight = false; }
		}
		
	if ( !canSaveHeightWeight ) { this.props.showMainErrorPop('You are trying to add Height or Weight and your values are not correct. Please add correct values or empty your values.'); return false; }


	let mainthis = this;
	if ( this.validator.allValid() && thisValidStatus && this.validateZipRegex(this.state.editZipCode) ) 
		{	
			if (this.state.editName != '' )
				{
					$('.main_loader').attr({'style':'display:flex'});
					setTimeout(function(){
						if(mainthis.state.isEmailValid)
							{
								var new_edit_state = $('#state_select').val();
								
								var postData = {
													"id": JSON.parse(localStorage.getItem('userData')).data.responseData.id, 
													"firstName": mainthis.state.editName, 
													"lastName": mainthis.state.editLastName, 
													"phone": mainthis.state.editPhone, 
													"address1": mainthis.state.editAddress, 
													"gender": mainthis.state.editGender, 
													"birthday": mainthis.state.birthdayDate, 
													"editEmail": mainthis.state.editEmail.trim(), 
													"email": mainthis.state.userEmail.trim(), 
													"userImage": mainthis.state.userImage, 
													"address2": mainthis.state.editAddress2, 
													"city": mainthis.state.editCity, 
													"state": new_edit_state, 
													"zip": mainthis.state.editZipCode, 
													"country": mainthis.state.editCountry, 
													"instructorText": dsedMainText,
													"isChangingEmail": isChangingEmail,
													"editEmailPassword": mainthis.state.editEmailPassword,
													"heightFeet": mainthis.state.editHeightFeet,
													"heightInches": mainthis.state.editHeightInch,
													"weight": mainthis.state.editWeight,
													"userType": JSON.parse(localStorage.getItem('userData')).data.responseData.type,
													"ages": ages
												};
												
								
								let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
								axios.defaults.headers.common['Authorization'] = token;
								axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateUserInfo", postData)
									.then(res_e => {
										$('.main_loader').hide();
										mainthis.getUserInfoAll();
										mainthis.getHeightWeight();
										mainthis.props.showMainErrorPop("Changes have been applied");
										mainthis.changeProfileMod(1);
										mainthis.setState({editEmailPassword: ''});
										if ( isChangingEmail ) { mainthis.setState({showResetEmail: true, emailStore: mainthis.state.editEmail}); }
										//browserHistory.goBack();
										
										
									}).catch(err =>{ mainthis.props.showMainErrorPop(err.response.data.errorData); $('.main_loader').hide(); });
							}
						else
							{
								$('.main_loader').hide();
								mainthis.props.showMainErrorPop("Please make sure that all fields are valid");
							}
						
					}, 1000);
					
				}
				
			else
				{
					mainthis.props.showMainErrorPop("Please make sure that all fields are valid");
				}			
		}			
	else
		{
			if ( !thisValidStatus ) { if ( !mainthis.state.editCountry ){this.setState({ errorClass_country: true,})} }
			if ( !isValidPhoneNumber(this.state.editPhone) ) { if(!isValidPhoneNumber(this.state.editPhone)) { this.setState({errorClass_editPhone:true}) } else { this.setState({ errorClass_editPhone:false }) }}
			if ( !this.validator.fieldValid('editName') ) { if(!this.validator.getErrorMessages().editName) { this.setState({errorClass_editName:false}) } else { this.setState({ errorClass_editName:true }) }}
			if ( !this.validator.fieldValid('editLastName') ) { if(!this.validator.getErrorMessages().editLastName) { this.setState({errorClass_editLastName:false}) } else { this.setState({ errorClass_editLastName:true }) }}
			if ( !this.validateZipRegex(this.state.editZipCode) ) { this.setState({ errorClass_editZipCode:true }) } else { this.setState({ errorClass_editZipCode: false }); }
			//if ( !this.validator.fieldValid('editCity') ) { if(!this.validator.getErrorMessages().editCity) { this.setState({errorClass_editCity:false}) } else { this.setState({ errorClass_editCity:true }) }}
			//if ( !this.validator.fieldValid('editAddress') ) { if(!this.validator.getErrorMessages().editAddress) { this.setState({errorClass_editAddress:false}) } else { this.setState({ errorClass_editAddress:true }) }}
			
			this.props.showMainErrorPop("Please make sure that all fields are valid");
		}
}

resendEmail=()=>{
	var postData = {
		id: JSON.parse(localStorage.getItem('userData')).data.responseData.id,
		userEmail: this.state.userEmail,
		editEmail: this.state.emailStore
	};
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendEmailConfirm", postData).then(res_e => {
		this.props.showMainErrorPop("Email resended.");
		this.setState({showResetEmail: false});
	}).catch(err =>{});
}

checkUserPassword=(e)=>{
	if ( !this.state.allIsChecked )
		{
			if ( this.state.editPassword == '' )
				{
					this.setState({
						editPasswordError: true
					})
					this.props.showMainErrorPop("Please fill all required Password fields."); 
					return false; 
				}
				
			if ( this.state.password == '' )
				{
					this.setState({
						passwordError: true
					})
					this.props.showMainErrorPop("Please fill all required Password fields."); 
					return false; 
				}
				
			if ( this.state.password_confirm == '' )
				{
					this.setState({
						password_confirmError: true
					})
					this.props.showMainErrorPop("Please fill all required Password fields."); 
					return false; 
				}
				
			this.props.showMainErrorPop("New password does not meet the requirements"); 
			return false; 
		}
	
	if ( this.state.password != this.state.password_confirm ) { this.props.showMainErrorPop("Passwords don't match"); return false; }
	
	if ( this.state.editPassword == this.state.password && this.state.editPassword == this.state.password_confirm ) { this.props.showMainErrorPop("New passowrd can't be the same as your current password."); return false; }
	
	if( this.state.allIsChecked && this.state.password == this.state.password_confirm )
		{
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserPass",{"id": JSON.parse(localStorage.getItem('userData')).data.responseData.id, "password" : this.state.editPassword, "email": JSON.parse(localStorage.getItem('userData')).data.responseData.email, "new_password" : this.state.password})
			.then(res_e => {
				if(res_e.data.responseData == 'failed')
					{
						this.props.showMainErrorPop("Current password is invalid");
					}
					
				else
					{
						this.props.showMainErrorPop("Password has been changed successfully");
						this.setState({
								password: '',
								password_confirm: '',
								passwordEyeOld: false,
								passwordEye: false,
								passwordEyeRepeat: false,
								editPassword: ''
						});
						setTimeout( ()=>{
							$('#oldPassInput').val('');
							$('#oldPassInput1').val('');
							console.log('match');
						}, 1000)
					}
					
				$('.main_loader').hide();
			}).catch(err =>{ $('.main_loader').hide(); });
		}
}

reminderDate=(e)=>{
	$("#calenderModel").attr({'style':'display:none'});
	let getFullYear=new Date(this.state.sessionCalender).getFullYear();
	let getMonth=(new Date(this.state.sessionCalender).getMonth())+1;
	let getDate=new Date(this.state.sessionCalender).getDate();
	this.setState({
		birthdayDate:getMonth+'-'+getDate+'-'+getFullYear
	})
	console.log(this.state.birthdayDate, 'date');
}

onChange1 = (date) =>
{
  console.log(date, 'DATE123');
  //2019-10-20
  const month = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']
  let getFullYear=new Date(date).getFullYear();
  let getMonth=month[(new Date(date).getMonth())];
  let getDate=new Date(date).getDate();
 // console.log(getFullYear+'-'+getMonth+'-'+getDate,'--------------',new Date(date).getFullYear(),new Date(date).getDate(),new Date(date).getMonth());
//let sessionDate =new Date(this.state.when).getDate()+'-'++''new Date(this.state.when).getDate() 
this.setState({
  sessionCalender: date,
  sessionDay:getDate,
  sessionMonth:getMonth,
  time2: '00:00',
  sessionYear:getFullYear
})

}

chooseGender=(e)=>{
	var addGender = e;
	this.setState({
		editGender: addGender
	});
}

checkValidation=(e)=>{
	let showHelp = this.state.showHelpPopup;

	let checking = this.checkPassword(this.state.password);
	
	this.setState({
		showHelpPopup:true
	});
	if ( checking )
		{
			let mainthis = this;
			setTimeout(function(){ 
				mainthis.setState({
					showHelpPopup: false
				});
			}, 1000);

		}
}	

showHelpPopupFun=(e)=>{
	this.setState({
		showHelpPopup:e
	});
}

changeUserImage=(ev)=>{
	let reader = new FileReader();
	console.log(ev);
	let file = ev.target.files[0];
	reader.onloadend = () => {
		if ( file.type === 'image/png' || file.type === 'image/jpeg' )
			{
				 $('.main_loader').attr({'style':'display:flex'});
				 const data = new FormData();
				 data.append('file', file);
				 let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
				 axios.defaults.headers.common['Authorization'] = token;
				 axios.post(process.env.REACT_APP_NAME+"/api/v1/upload/upload", data)
				 .then(res => {
					this.setState({ userImage: res.data.responseData.file })
					$('.main_loader').hide();
					
				 }).catch(err =>{ 
				});
			}
			
		else
			{
				this.props.showMainErrorPop("Only .png and .jpeg extensions are supported");
			}
		
	 }
	  
	reader.readAsDataURL(file);
}

changePhoneInput = (mod) => {
	this.setState({ editPhone: mod});
}


checkPassword=(pass)=>{
	let checkAll = false;
	this.state.allIsChecked = false;
	var regexcheck = /[!@#$%_+:;'"~`.,?|\^&*(){}[\]<>?/|\-]/;
	var contains_number = /\d+/;
	var Regex = /^[^a-zA-Z]*$/;
	
	this.setState({
		contain8Ch: false,
		specialSymbol: false,
		numberContain: false,
		letterContain: false
	});
	
	if ( !Regex.test(pass) ) 
		{ 
			this.setState({
				letterContain: true
			});
		}
	
	if ( pass.length >= 8 )
		{
			this.setState({
				contain8Ch: true
			});
		}
	
	if ( regexcheck.test(pass) )
		{
			this.setState({
				specialSymbol: true
			});
		}
		
	if ( contains_number.test(pass) )
		{
			this.setState({
				numberContain: true
			});
		}
		
	if ( pass != '' && pass.length >= 8 && regexcheck.test(pass) && contains_number.test(pass) && !Regex.test(pass) )
		{
			checkAll = true;
			this.state.allIsChecked = true;
		}
		
	return checkAll;
}

passwordEyeCheck=(e, a)=>{
	if(a == 'pass')
		{
			if(e == 0)
				{
					this.setState({
						passwordEye: false
					});
				}
			
			else if(e == 1)
				{
					this.setState({
						passwordEye: true
					});
				}
		}
	else if ( a == 'old')
		{
			if(e == 0)
				{
					this.setState({
						passwordEyeOld: false
					});
				}
			
			else if(e == 1)
				{
					this.setState({
						passwordEyeOld: true
					});
				}
		}		
	else
		{
			if(e == 0)
					{
						this.setState({
							passwordEyeRepeat: false
						});
					}
				
			else if(e == 1)
					{
						this.setState({
							passwordEyeRepeat: true
						});
					}
		}
	
}

onBlurCheck =(mod)=>{
	
	this.validator.allValid();

	if ( !isValidPhoneNumber(this.state.editPhone) && mod == 'phone') 
		{ if(isValidPhoneNumber(this.state.editPhone)) { this.setState({errorClass_editPhone:false}) } else { this.setState({ errorClass_editPhone:true }) }}
	if ( !this.validator.fieldValid('editName') && mod == 'name' ) 
		{ if(!this.validator.getErrorMessages().editName) { this.setState({errorClass_editName:false}) } else { this.setState({ errorClass_editName:true }) }}
	if ( !this.validator.fieldValid('editLastName') && mod == 'lastName' ) 
		{ if(!this.validator.getErrorMessages().editLastName) { this.setState({errorClass_editLastName:false}) } else { this.setState({ errorClass_editLastName:true }) }}
	if ( !this.validator.fieldValid('editZipCode') && mod == 'zip' ) 
		{ if(!this.validator.getErrorMessages().editZipCode) { this.setState({errorClass_editZipCode:false}) } else { this.setState({ errorClass_editZipCode:true }) }}
			
}

handleFilterChange = e => {
	this.setState({
		[e.target.id]: e.target.value
	});	
}

goBackMarketplaceMember=(e)=>{
	browserHistory.push('/login#MarketplaceBack');
}

  render() {

    return (
		<div className="container-fluid custom-width">
			{ this.state.userType == '1' &&
				<Header loginName={this.state.loginName} totalSession={this.state.totalSession} fullsubdomain={this.state.fullsubdomain} fromParticipantDashboard={this.state.fromParticipantDashboard}></Header>
			}
			{ this.state.userType == '2' &&
				<div className="hdr pt-3">
					{this.state.hideClose &&
						<div className="profile_back_button" onClick={e=>{this.goBackMarketplaceMember(e)}} >BACK</div>	
					}
					<div className="row top-header px-4 py-2">
						<div className="col-md-8 col-sm-11 pl-lg-0 d-flex flex-wrap justify-content-center justify-content-lg-start p-2">
							<div className="align-self-center">
								<img src="/images/login-logo.png" className="logo mb-2 mb-sm-0" alt="logo" />
							</div>
							<div className="d-flex d-md-block justify-content-center px-4 py-2">
								<div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userImageProfile ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/host-user.png)' }}></div>
									<div className="pl-4">
										<h2 className="mb-0">Welcome {this.state.userName}</h2>
										<p className="mb-0">You have {this.state.participantTotalSessions} classes coming up</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 mt-sm-3 col-sm-1 mt-md-0">
						<div className="row justify-content-end relative">
								<div className="">
									<div className="setting-icon position-relative cursor-pointer py-2" data-toggle="dropdown"><img src="../images/setting.png" /></div>
									<div className="dropdown-menu">
										{this.state.hideClose == false &&
											<Link to ="/myProfile" className="dropdown-item"><img src="../images/gray-profile.png" className="mr-2" />Profile</Link>
										}
										{/*
											{this.state.hideClose == false &&
												<Link to ="/participant-dashboard" className="dropdown-item" data-toggle="modal" data-target="#coming_soon_alert" >
													<img src="../images/gray-setting.png" className="mr-2" />Security &amp; Settings
												</Link>
											}
										*/}
										{this.state.hideClose == false &&
											<Link to ="/dashboardLanding" className="dropdown-item"><img src="../images/gray-profile.png" className="mr-2" />Switch to Instructor</Link>
										}
										<Link to ="/participant-dashboard" className="dropdown-item" data-toggle="modal" data-target="#coming_soon_alert" ><img src="../images/gray-contact.png" className="mr-2" />Customer Support</Link>
										<Link to ='/' className="dropdown-item" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId');localStorage.removeItem('duration');localStorage.removeItem('scheduleDate');localStorage.removeItem('sessionEndDate'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}} ><img src="../images/gray-logout.png" className="mr-2"  />Logout</Link>
									</div>
								</div>
							</div>
						</div>
					</div>	
				</div>
			}
			<div className="my_profile_inner_container">
				{this.state.hideClose == false &&
					<div className="closePopup settings_close_popup channel_settings_close" onClick={browserHistory.goBack}></div>
				}
				<div className="profile_main_holder">
					<div className="profile_user_info_hold breakWord">
						{this.state.editProfile &&
							<div className="profile_picture_hold" style={{ backgroundImage: this.state.userImage ? "url(" + this.state.userImage + ")" : 'url(/images/host-user.png)' }}>
								<input type="file" onChange={ (e) => this.changeUserImage(e) } />
							</div>
						}
						{ !this.state.editProfile &&
							<div className="profile_picture_hold" style={{ backgroundImage: this.state.userImageProfile ? "url(" + this.state.userImageProfile + ")" : 'url(/images/host-user.png)' }}></div>
						}
						<div className="profile_user_name">{this.state.userName} {this.state.userLastName}</div>
						{this.state.userCity != '-' &&
							<div className="profile_location_hold">
								<div className="profile_loc_logo"></div>
								<div className="profile_location">{this.state.userCity}</div>
							</div>
						}
					</div>
					<div className="profile_selections_hold profile_main_menu_selection">
						<div className={ "profile_selection " + ( this.state.myProfileSelect ? "selection_active" : "" ) } onClick={e=>{this.changeProfileMod(1)}}>
							<div className="profile_loc_logo profile_main_icon"></div>
							<div className="profile_location">My Profile</div>
						</div>
						<div className={ "profile_selection " + ( this.state.editProfile ? "selection_active" : "" ) } onClick={e=>{this.changeProfileMod(2)}}>
							<div className="profile_loc_logo profile_main_edit"></div>
							<div className="profile_location">Edit Profile</div>
						</div>
						<div className={ "profile_selection " + ( this.state.emailPreferences ? "selection_active" : "" ) } onClick={e=>{this.changeProfileMod(5)}}>
							<div className="profile_loc_logo profile_main_edit"></div>
							<div className="profile_location">Email Preferences</div>
						</div>
						<div className={ "profile_selection " + ( this.state.changePassword ? "selection_active" : "" ) } onClick={e=>{this.changeProfileMod(3)}}>
							<div className="profile_loc_logo profile_main_password"></div>
							<div className="profile_location">Change Password</div>
						</div>
						<div className={ "profile_selection " + ( this.state.paymentMethods ? "selection_active" : "" ) } onClick={e=>{this.changeProfileMod(4)}}>
							<div className="profile_loc_logo profile_main_payment"></div>
							<div className="profile_location">Payment Methods</div>
						</div>
					</div>
				</div>
				<div className="profile_main_holder profile_content_hold">
					{ this.state.myProfileSelect &&
						<div>
							<div className="profile_title_main">Settings / My Profile</div>
							<div className="profile_menu_border"></div>
							<div className="profile_settings_hold">
								<div className="profile_selection selection_active1">CONTACT INFORMATION</div>
								<div className="menu_item_hold">
									<div className="settings_subject">First Name: </div>
									<div className="settings_subject_content setSubNew">{this.state.userName}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Last Name: </div>
									<div className="settings_subject_content setSubNew">{this.state.userLastName}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Phone: </div>
									<div className="settings_subject_content setSubNew">{this.state.userPhone}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Country: </div>
									<div className="settings_subject_content setSubNew">{this.state.userCountry}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Street Address 1: </div>
									<div className="settings_subject_content setSubNew">{this.state.userAddress}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Street Address 2: </div>
									<div className="settings_subject_content setSubNew">{this.state.userAddress2}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">State/Province: </div>
									<div className="settings_subject_content setSubNew">{this.state.userState}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">City: </div>
									<div className="settings_subject_content setSubNew">{this.state.userCity}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Zip/Postal Code: </div>
									<div className="settings_subject_content setSubNew">{this.state.userZipCode}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Email: </div>
									<div className="settings_subject_content setSubNew">{this.state.userEmail}</div>
								</div>
								{ this.state.showResetEmail &&
									<div className="menu_item_hold">
										<div className="settings_subject"></div>
										<div className="resend_container">
											<div className="resend_top_title">Check your email <span>{this.state.userEmail}</span> to confirm your new email address. We wont change your email until you confrim new email.</div>
											<div className="resend_confirmation" onClick={this.resendEmail}>Resend email confirmation</div>
										</div>
									</div>
								}
							</div>
							<div className="profile_settings_hold">
								<div className="profile_selection selection_active1">BASIC INFORMATION</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Birthday: </div>
									<div className="settings_subject_content setSubNew">{this.state.userBirthday}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Gender: </div>
									<div className="settings_subject_content setSubNew">{this.state.userGender}</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Height: </div>
									<div className="settings_subject_content setSubNew">{this.state.userHeightFeet !== '' ? this.state.userHeightFeet : '-'} feet {this.state.userHeightInch !== '' ? this.state.userHeightInch : '-'} inch</div>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Weight: </div>
									<div className="settings_subject_content setSubNew">{this.state.userWeight !== '' ? this.state.userWeight : '-'} lbs</div>
								</div>
								{ this.state.userType == '1' &&
									<div className="menu_item_hold" >
										<div className="settings_subject new_vertical_mob">Instructor Bio: </div>
										<div className="settings_subject_content settings_wrap_content">
										 <div className="profile_text" dangerouslySetInnerHTML={{__html: this.state.instructorTextProfile}}></div>
										</div>
									</div>
								}
							</div>
						</div>
					}
					{ this.state.editProfile &&
						<div>
							<div className="profile_title_main">Settings / Edit Profile</div>
							<div className="profile_menu_border"></div>
							<div className="profile_settings_hold">
								<div className="profile_selection selection_active1">CONTACT INFORMATION</div>
								<div className="menu_item_hold">
									<div className="settings_subject">First Name: </div>
									<input type="text" 
										className={(this.state.errorClass_editName?"input-field red-outline settings_profile_input":"input-field settings_profile_input")} 
										value={this.state.editName} 
										id ='editName' 
										onChange={(e)=>{
										  this.changeInputVal({
											[e.target.id]:e.target.value,
											errorClass_editName:false
										  })}}
										onBlur={(e)=>{this.onBlurCheck('name')}}
										/>
										{this.validator.message('editName', this.state.editName, 'required|min:1|max:30')}
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Last Name: </div>
									<input type="text" 
										className={(this.state.errorClass_editLastName?"input-field red-outline settings_profile_input":"input-field settings_profile_input")} 
										value={this.state.editLastName} 
										id ='editLastName' 
										onChange={(e)=>{
										  this.changeInputVal({
											[e.target.id]:e.target.value,
											errorClass_editLastName:false
										  })}}  
										  onBlur={(e)=>{this.onBlurCheck('lastName')}}
										  />
										{this.validator.message('editLastName', this.state.editLastName, 'required|min:1|max:30')}
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Phone: </div>
									<PhoneInput
										international={ this.state.selectedCountryCode !== 'US' && this.state.selectedCountryCode !== 'CA' && this.state.selectedCountryCode !== null }
										country={this.state.selectedCountryCode ? this.state.selectedCountryCode : 'US'} 
										value={this.state.editPhone}
										withCountryCallingCode={true}
										ref={this.phoneRef}
										className={(this.state.errorClass_editPhone?"red-outline input-field settings_profile_input settings_phone_icon":"input-field settings_profile_input settings_phone_icon")}
										onChange={(e)=>{
											this.changePhoneInput(e);
											this.changeInputVal({ errorClass_editPhone:false })
											}}  
											onBlur={(e)=>{this.onBlurCheck('phone')}}
											/>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Country: </div>
									<select type="text" className={(this.state.errorClass_country ? "red-outline input-field settings_profile_input settings_phone_icon" : "input-field settings_profile_input")} onChange={e => this.changeInputValNew({editCountry: e.target.value}, e.target.value)} value={this.state.editCountry}>
										<option></option>
										{this.state.countryArray.map((row,i)=>											
                                            <option value={row.postalCode}  key = {i}>{row.postalCode}</option>
										)}
									</select>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Street Address 1: </div>
									<input type="text" 
										className={(this.state.errorClass_editAddress?"input-field red-outline settings_profile_input settings_address_field":"input-field settings_profile_input settings_address_field")} 
										value={this.state.editAddress} 
										id ='editAddress' 
										onChange={(e)=>{
										  this.changeInputVal({
											[e.target.id]:e.target.value,
											errorClass_editAddress:false
										  })}}  />
										
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Street Address 2: </div>
									<input type="text" className="input-field settings_profile_input settings_address_field" value={this.state.editAddress2}  onChange={e => this.changeInputVal({editAddress2: e.target.value})}/>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">State/Province: </div>
									<select type="text" id="state_select" className="input-field settings_profile_input settings_address_field" onChange={e => this.changeInputVal({editState: e.target.value})} value={this.state.editState}>
										<option></option>	
										{this.state.stateArray.sort(function(a, b){ if(a.stateCode < b.stateCode) { return -1; } if(a.stateCode > b.stateCode) { return 1; } return 0; }).map((row,i)=>
											<option value={row.stateCode} key = {i}>{row.stateCode}</option>
										)}
									</select>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">City: </div>
									<input type="text" 
										className={(this.state.errorClass_editCity?"input-field red-outline settings_profile_input settings_address_field":"input-field settings_profile_input settings_address_field")} 
										value={this.state.editCity} 
										id ='editCity' 
										onChange={(e)=>{
										  this.changeInputVal({
											[e.target.id]:e.target.value,
											errorClass_editCity:false
										  })}}  />
										
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Zip/Postal Code: </div>
									<input type="text" 
										className={(this.state.errorClass_editZipCode?"input-field red-outline settings_profile_input  settings_address_field channel_settings_input":"input-field settings_profile_input  settings_address_field channel_settings_input")} 
										value={this.state.editZipCode} 
										id ='editZipCode' 
										onChange={(e)=>{
										  this.changeInputVal({
											[e.target.id]:e.target.value,
											errorClass_editZipCode:false
										  })}}  
										  onBlur={(e)=>{this.onBlurCheck('zip')}}
										  />
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Email: </div>
									<input type="text" id="editEmail" className="input-field settings_profile_input settings_email_icon" value={this.state.editEmail} onChange={e => this.changeInputVal({editEmail: e.target.value})}/>
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Password <span className="block_span">(if changing email)</span></div>
									<input type="password" id="editEmailPassword" className="input-field settings_profile_input none_img" value={this.state.editEmailPassword} onChange={e => this.changeInputVal({editEmailPassword: e.target.value})}/>
								</div>
							</div>
							<div className="profile_settings_hold">
								<div className="profile_selection selection_active1">BASIC INFORMATION</div>
								<div className="menu_item_hold" >
									<div className="settings_subject" >Birthday: </div>
									<input type="text" className="input-field settings_profile_input settings_calendar_icon"  value={this.state.birthdayDate} readOnly  onClick={e=>{$("#calenderModel").attr({'style':'display:block; margin-top: 0px !important; padding-top: 15px;'});}} />
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject">Gender: </div>
									<select type="text" className="input-field settings_profile_input" onChange={e => this.chooseGender(e.target.value)} value={this.state.editGender}>
										<option></option>
										{ this.state.genderList.map((def, i)=> 
											<option id={i} key={i}>{def}</option>
										)}
									</select>
								</div>
								<div>
									<div className="menu_item_hold">
										<div className="settings_subject">Height: </div>
										<div className="inline_selectsprof">
											<select className="input-field select_profile" id="editHeightFeet" value={this.state.editHeightFeet} onChange={this.handleFilterChange}>
												{ this.state.feetOptions.map((item, key) => 
													<option value={item} key={key}>{item}</option>
												)}
											</select>
											<div className="select_title_room">feet</div>
										</div>
										<div className="inline_selectsprof">
											<select className="input-field select_profile" id="editHeightInch" value={this.state.editHeightInch} onChange={this.handleFilterChange}>
												{ this.state.inchOptions.map((item, key) => 
													<option value={item} key={key}>{item}</option>
												)}
											</select>
											<div className="select_title_room">inch</div>
										</div>
									</div>
									<div className="menu_item_hold">
										<div className="settings_subject">Weight: </div>
										<div className="inline_selectsprof">
											<input type="text" 
												className="input-field settings_profile_input  settings_address_field channel_settings_input w100p"
												value={this.state.editWeight} 
												id ='editWeight' 
												maxLength="3"
												onChange={(e)=>{
												  this.changeInputVal({
													[e.target.id]:e.target.value
												  })}}
												  />
											<div className="select_title_room">lbs</div>
										</div>
									</div>
								</div>
							{ this.state.userType == '1' &&
								<div className="menu_item_hold" >
									<div className="settings_subject new_vertical">Instructor Bio: </div>
									<textarea id="desc_text" value={this.state.instructorTextProfileEdit} onChange={e => this.changeAreaVal({instructorTextProfileEdit: e.target.value})}  className="input-field settings_profile_input hide_settings_img"></textarea>
								</div>
							}
								<div className="settings_buttons_hold">
									<div className="join_button profile_save_button" onClick={e=>{this.editUserInfo()}}>Save Changes</div>
								</div>
							</div>
						</div>
					}
					<div className={(!this.state.emailPreferences ? "d-none" : "") } >
						<div className="profile_title_main">Email Preferences</div>
						<div className="profile_menu_border"></div>
						<div className="profile_settings_hold">
							<EmailPreferences></EmailPreferences>
						</div>
					</div>
					{ this.state.changePassword &&
						<div>
							<div className="profile_title_main">Settings / Change Password</div>
							<div className="profile_menu_border"></div>
							<div className="profile_settings_hold">
								<div className="profile_selection selection_active1">CHANGE PASSWORD</div>
								<div className="menu_item_hold">
									<div className="settings_subject myprofile_password">Current Password: </div>
									{!this.state.passwordEyeOld &&	
										<div className="form-group pass_new_displ" >
											<input type="password" className={"input-field settings_profile_input paddr50 " + (this.state.editPasswordError ? "red-alert" : "") } name="oldPassword" autoComplete="new-password" id="oldPassInput" value={this.state.editPassword} onChange={e => this.changeInputVal({editPassword: e.target.value, editPasswordError : false})}/>
											<span className="password_eye" onClick={e=>{ this.passwordEyeCheck(1, 'old')}}></span>
										</div>
									}
									{this.state.passwordEyeOld &&	
										<div className="form-group pass_new_displ" >
											<input type="text" className={"input-field settings_profile_input paddr50 " + (this.state.editPasswordError ? "red-alert" : "")} name="oldPassword1" autoComplete="new-password" id="oldPassInput1" value={this.state.editPassword} onChange={e => this.changeInputVal({editPassword: e.target.value, editPasswordError : false})}/>
											<span className="password_eye password_eye_shown" onClick={e=>{ this.passwordEyeCheck(0, 'old')}}></span>
										</div>
									}		
								
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject myprofile_password">New Password: </div>
										{!this.state.passwordEye &&	
											<div className="form-group pass_new_displ" >
												 <input type="password" onKeyUp={this.checkValidation}
													autoComplete="new-password"
													className={"input-field settings_profile_input paddr50 "+ (this.state.passwordError ? "red-alert" : "")}
													value={this.state.password} id ='password'
													 onChange={(e)=>{
													  this.setState({
														[e.target.id]:e.target.value,
														passwordError:false
													  })}} onKeyUp={this.checkValidation} onFocus={e=>{ this.showHelpPopupFun(true)}} onBlur={e=>{ this.showHelpPopupFun(false)}}/>
													  <span className="password_eye" onClick={e=>{ this.passwordEyeCheck(1, 'pass')}}></span>
											  </div>
										}
										{this.state.passwordEye &&	
											<div className="form-group pass_new_displ" >
												 <input type="text" onKeyUp={this.checkValidation}
													autoComplete="new-password"
													className={"input-field settings_profile_input " + (this.state.passwordError ? "red-alert" : "")}
													value={this.state.password} id ='password'
													 onChange={(e)=>{
													  this.setState({
														[e.target.id]:e.target.value,
														passwordError:false
													  })}} onKeyUp={this.checkValidation} onFocus={e=>{ this.showHelpPopupFun(true)}} onBlur={e=>{ this.showHelpPopupFun(false)}}/>
													  <span className="password_eye password_eye_shown" onClick={e=>{ this.passwordEyeCheck(0, 'pass')}}></span>
											  </div>
										}
										  
								</div>
								<div className="menu_item_hold">
									<div className="settings_subject myprofile_password">Confirm Password: </div>
										{!this.state.passwordEyeRepeat &&	
											<div className="form-group pass_new_displ" >
												<input type="password" className={"input-field settings_profile_input " + (this.state.password_confirmError ? "red-alert" : "")} value={this.state.password_confirm} onChange={e => this.changeInputVal({password_confirm: e.target.value, password_confirmError: false})}
												/>
												 <span className="password_eye" onClick={e=>{ this.passwordEyeCheck(1, 'ree')}}></span>
											</div>
										}
										{this.state.passwordEyeRepeat &&	
											<div className="form-group pass_new_displ" >
												<input type="text" className={"input-field settings_profile_input " + (this.state.password_confirmError ? "red-alert" : "")} value={this.state.password_confirm} onChange={e => this.changeInputVal({password_confirm: e.target.value, password_confirmError: false})}
												/>
												 <span className="password_eye password_eye_shown" onClick={e=>{ this.passwordEyeCheck(0, 'ree')}}></span>
											</div>
										}	
								</div>
								<div className="settings_buttons_hold">
									<div className="join_button profile_save_button" onClick={e=>{this.checkUserPassword()}}>Save Changes</div>
								</div>
							</div>
							{ this.state.showHelpPopup && 
								<div className="password_check_form">
									<div className="pass_check_title">Your password needs to:</div>
									<div className="pass_main_line">
										<div className={ "pass_done_icon " + ( !this.state.contain8Ch ? "pass_iks_icon" : "" ) }></div>
										<div className="pass_line_text">Minimum of 8 characters</div>
									</div> 
									<div className="pass_main_line">
										<div className={ "pass_done_icon " + ( !this.state.letterContain ? "pass_iks_icon" : "" ) }></div>
										<div className="pass_line_text">Minimum of 1 Letter</div>
									</div>
									<div className="pass_main_line">
										<div className={ "pass_done_icon " + ( !this.state.numberContain ? "pass_iks_icon" : "" ) }></div>
										<div className="pass_line_text">Minimum of 1 Number</div>
									</div>
									<div className="pass_main_line">
										<div className={ "pass_done_icon " + ( !this.state.specialSymbol ? "pass_iks_icon" : "" ) }></div>
										<div className="pass_line_text">Minimum of 1 Special Character</div>
										<div className="pass_line_text spec_char">(e.g. !,@,#,$,%,&,*)</div>
									</div>
								</div>
							}
						</div>
					}
					<div className={(!this.state.paymentMethods ? "d-none" : "") }>
						<div className="profile_title_main">Settings / Payment Methods</div>
						<div className="profile_menu_border"></div>
						<div className="profile_settings_hold">
							<div className="chargingProfile">
								<div className="profile_selection selection_active1">PAYMENT METHODS</div>
								<ChargingSession
									state={this.state}
									cardsArray={this.state.cardsArray}
								></ChargingSession>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="modal cal_modal mt-3" id="calenderModel" data-backdrop="">
						<div className="modal-dialog d-md-flex d-block large_width1 mb-0">
							<div className="modal-content modalbg m-auto">
								<div className="modal-header px-4 pt-3 pb-0">
									<h4 className="white modal-title">Choose Date</h4>
									<button type="button pr-3" className="close white closepopup" onClick={e=>$("#calenderModel").attr({'style':'display:none'})}>&times;</button>
								</div>
								<div className="modal-body modal-body px-1 px-sm-3">
									<h3>Calender</h3>

									{/* <ReactLightCalendar startDate={startDate} endDate={endDate} onChange={this.onChange} range displayTime /> */}
									{/* <ReactLightCalendar timezone = {this.state.localTimeZone}
									disableDates={date => date <= (new Date().getTime())}
									startDate={startDate} endDate={endDate} onChange={this.onChange} range = {true} displayTime ={true} /> */}

									<Calendar
									   onChange={this.onChange1}
									   value={this.state.sessionCalender}
									   maxDate={new Date()}
									  // calendarType= "ISO 8601"
									 />
								</div>
								<div className="text-center position-absolute btn_btn1">
								{this.state.signUpSessionStatus?'':<button type="button" className="done mt-0"onClick={this.reminderDate} data-dismiss="modal">done</button>}
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
			<div className="modal pr-0" id="succes_pay" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent inline_pop">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
							<p className="mb-0 py-4 white text-center font-20">Succesfully added Credit Card as default.</p>
						</div>
						<div className="text-center flex-wrap d-flex justify-content-center">
							<div className="">
								<button className="custom_btn1 mt-3 mx-2" onClick={e=>{ $("#succes_pay").attr({'style':'display:none'}); }}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			
			<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8">
							<p className="mb-0 py-4 white text-center">Coming Soon</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
					</div>
				</div>
			</div>
			
			<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(myProfile);