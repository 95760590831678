import React, { Component } from "react";
import moment from 'moment';
import classnames from "classnames";

class ReplayClasses extends Component {
  
  constructor(props) {
		super(props);
		
		this.state = {
			pickedClass: 0
		}
		
	}
 
	componentDidMount(){
		this.setState({pickedClass: this.props.pickedReplayId});
	}
	
	pickClass=(id)=>{
		var chosenId = this.state.pickedClass;
		var pickedId = 0;
		if ( id !== chosenId ) { pickedId = id; };
		this.setState({pickedClass: pickedId});
	}
	

	render() {
			
			return(
				<div className="review_holder review_holder_creation">
					<div className="review_content">
						<div className="review_main_title">Replay Classes
							<div className="close_flow" onClick={() => this.props.updateStateFromChild({isOpenReplay: false})}></div>
						</div>
						<div className="replay_classes_content">
							<div className="replay_titles main_replay_titles">
								<div className="one_replay_title">Name</div>
								<div className="one_replay_title">Instructor</div>
								<div className="one_replay_title">Created At</div>
							</div>
							{ this.props.replayClasses.map((item, i) => 
								<div className="replay_titles" key={i}>
									<div className="filter_toogle" className={classnames({"filter_toogle": true, "active": this.state.pickedClass == item.id})} onClick={() => this.pickClass(item.id)}></div>
									<div className="replay_info_content">
										<div className="one_replay_title">{item.name}</div>
										<div className="one_replay_title">{item.firstName} {item.lastName}</div>
										<div className="one_replay_title">{moment(item.createdAt).format('MM/DD/YYYY hh:mm A')}</div>
									</div>
								</div>
							)}
						</div>
						<div className="replay_button_content">
							<div className="edit_review" onClick={() => this.props.pickReplayClass(this.state.pickedClass)}>Save</div>
						</div>
					</div>
				</div>
			)
		}
}

export default ReplayClasses;