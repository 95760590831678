import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router";

class MemberCardInfo extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
		appUrl: process.env.REACT_APP_URL+'login',
		showWhatIsPop: false,
    }
	
}
 
componentDidMount(){
	
}

openApp=()=>{
	let url = this.state.appUrl;
	window.open(url);
}

goBackStep=()=>{
	
	if ( this.props.planSelectedBefore )
		{
			this.props.goToNextStep(1)
		}
	else
		{
			this.props.goToNextStep(3)
		}	
	
}

showWhatIsPop=(type)=>{
	
	if ( type )
		{
			if ( this.state.showWhatIsPop )
				{
					this.setState({
						showWhatIsPop: false,
					})
				}
			else 
				{
					this.setState({
						showWhatIsPop: true,
					})
				}				
		}
	else 
		{
			this.setState({
				showWhatIsPop: true,
			})
		}		
	
}

hideWhatIsPop=()=>{
	this.setState({
		showWhatIsPop: false,
	})
}

  render() {
	 return (
		<div className="memberRegisterHolder more_width_welcome ccOnProfile">
			{!this.props.state.isProfile && !this.props.state.hideBack && !this.props.signedUpPage &&
				<div className="exit_content">
					<div>
						<div className="inline_event" onClick={(e)=>this.goBackStep()}>
							<div className="arrow_exit"></div>
							<div className="exit_screen">BACK</div>
						</div>
					</div>
				</div>	
			}
			{!this.props.state.isProfile ?
				<div className="sign_title">Payment</div>
				:
				<div className="welcomeTextHolder" >
					<div className="welcomeTextH1 addPadding48">Add payment method</div>
				</div>
			}
			
			<div className="info_row_holder">
					<div className="one_form_container">
						<div className="relative_holder">
							<input type="text" tabIndex="6" className={classnames({'gray_inputs': true, 'error_input': this.props.state.errorHolderName})} 
							id="cardHolderName" 
							placeholder="Name on card"
							value={this.props.state.cardHolderName} 
							onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorHolderName: false})} 
							onFocus={() => this.props.updateStateFromChild({focusedElement: 'cardHolderName'})} 
							onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
							{ this.props.state.cardHolderName !== '' &&
								<div className="empty_input" onClick={()=>this.props.updateStateFromChild({cardHolderName: ""})}></div>
							}
						</div>
					</div>
				</div>
			<div className="info_row_holder">
				<div className="one_form_container">
					<div className="relative_holder">
						<input type="tel" tabIndex="1" className={classnames({'gray_inputs': true, 'error_input': this.props.state.errorCardNum})} 
						id="cardNumber" maxLength="16"
						placeholder="Card Number"
						value={this.props.state.cardNumber} 
						onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorCardNum: false})} 
						onFocus={() => this.props.updateStateFromChild({focusedElement: 'cardNumber'})} 
						onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
						{ this.props.state.cardNumber !== '' &&
							<div className="empty_input" onClick={()=>this.props.updateStateFromChild({cardNumber: ""})}></div>
						}
					</div>
				</div>
			</div>		
			<div className="holderWithDatesCard">
				<div className="inputDateHolderNew first25">
					<div className="inline_relative">
						<input type="tel" className={classnames({'fitness_input dates_width': true, 'error_input': this.props.state.errorCardMM})}
						maxLength="2" id="cardMonth" maxLength="2" 
						tabIndex="2"
						placeholder="MM"
						value={this.props.state.cardMonth} 
						onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorCardMM: false})} 
						onFocus={() => this.props.updateStateFromChild({focusedElement: 'cardMonth'})} 
						onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
						{ this.props.state.cardMonth !== '' &&
							<div className="empty_input" onClick={()=>this.props.updateStateFromChild({cardMonth: ""})}></div>
						}
					</div>
				</div>
				<div className="inputDateHolderNew first25">
					<div className="inline_relative">
						<input type="tel" className={classnames({'fitness_input dates_width': true, 'error_input': this.props.state.errorCardYear})}
						id="cardYear" maxLength="4" 
						tabIndex="3"
						placeholder="YYYY"
						value={this.props.state.cardYear} 
						onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorCardYear: false})} 
						onFocus={() => this.props.updateStateFromChild({focusedElement: 'cardYear'})} 
						onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
						{ this.props.state.cardYear !== '' &&
							<div className="empty_input" onClick={()=>this.props.updateStateFromChild({cardYear: ""})}></div>
						}
					</div>
				</div>
				<div className="inputDateHolderNew last50">
					<div className="inline_relative">
						<input type="text" tabIndex="4" className={classnames({'gray_inputs': true, 'error_input': this.props.state.errorCardCVV})} 
						id="cardCVV" 
						placeholder="Security Code"
						value={this.props.state.cardCVV} 
						onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorCardCVV: false})} 
						onFocus={() => this.props.updateStateFromChild({focusedElement: 'cardCVV'})} 
						onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
						{ this.props.state.cardCVV !== '' &&
							<div className="empty_input" onClick={()=>this.props.updateStateFromChild({cardCVV: ""})}></div>
						}
					</div>
				</div>
			</div>
			<div className="cardWhatText" onMouseOut={(e)=> {this.hideWhatIsPop()}} onMouseOver={(e)=> {this.showWhatIsPop(false)}} onClick={(e)=>this.showWhatIsPop(true)} >What’s this?
				{this.state.showWhatIsPop && 
					<div className="whatIsPopHolder">
						<div className="arrowPointerPopHolder"></div>
						The CVV/CVC code (Card Verification Value/Code) is located on the back of your credit/debit card on the right side of the white signature strip; it is always the last 3 digits in case of VISA and MasterCard.
					</div>
				}
			</div>
			<div className="info_row_holder">
				<div className="one_form_container">
					<div className="relative_holder">
						<input type="tel" tabIndex="5" className={classnames({'gray_inputs': true, 'error_input': this.props.state.errorCardZip})} 
							id="cardZip" maxLength="5"
							value={this.props.state.cardZip}
							placeholder="Zip Code"
							onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorCardZip: false})} 
							onFocus={() => this.props.updateStateFromChild({focusedElement: 'cardZip'})} 
							onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
							{ this.props.state.cardZip !== '' &&
								<div className="empty_input" onClick={()=>this.props.updateStateFromChild({cardZip: ""})}></div>
							}
					</div>
				</div>
			</div>	
			
				{!this.props.state.isProfile &&
					<div className="bottom_arrow">
						<div className={classnames({'inline_event': true, 'opacity_event': this.props.state.cardNumber == '' || this.props.state.cardHolderName == '' || this.props.state.cardZip == '' || this.props.state.cardCVV == '' || this.props.state.cardYear == ''  || this.props.state.cardMonth == '' || this.props.state.cardInput == '' })} onClick={(e)=>this.props.addCreditCard()}>
							<div className="bottom_title_small">CONTINUE</div>
							<div className="arrow_next"></div>
						</div>
					</div>
				}
				{this.props.state.isProfile &&
					<div className="booked_bnts_content">
						<div className="addCardHolderHalf">
							<div className="active_booked_bnt_new" onClick={() => this.props.addCreditCard()}>Save</div>
						</div>
						<div className="addCardHolderHalf">
							<div className="inactive_booked_bnt inactiveNewWidth" onClick={() => this.props.closeAddCC()} >Cancel</div>
						</div>
						
						
					</div>
				}
		</div>
    );
  }
}
export default MemberCardInfo;
