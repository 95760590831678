import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
//import { getSessionDate } from '../../util/formatDateFunctions';
//import { getSessionTime } from '../../util/formatDateFunctions';


class ReportsMonthBilling extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartDate: null,
					searchEndDate: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					productList: [],
					sortedChannelName: false,
					sortedSessionName: false,
					sortedHostName: false,
					sortedDate: false,
					planInfo: [],
					refundPopUp: false,
					refundAmount: 0,
					fixedAmount: 0,
					billing_id: 0,
					host_id: 0,
					refundEmail: '',
					customerName: '',
					customerLastName: '',
					showConfPopup: false,
					cardToken: 0,
					hasDefaultCard: false,
					sortByVal: ['id', 'DESC'],
					channelId: 0,
					ajaxLoaded: false,
					plansArray: [],
					overagesArray: [],
					trialsArray: [],
					showPlanPop: false,
					openedPlan: [],
					editLivePrice: false,
					editODPrice: false,
					newLivePrice: 0,
					newODPrice: 0,
					editLiveOver: false,
					recordsOverageEdit: false,
					clientsOverageEdit: false,
					priceToAddClientsNew: 0,
					activeTab: 'trial',
					livePlanPriceNew: 0,
					odPlanPriceNew: 0,
					tabTypeNum: 1,
					editLiveOverage: false,
					liveOverageNew: 0,
					clientsOverageNew: 0,
					recordsOverageNew: 0,
					editBillingNum: undefined,
					editBillingOdNum: undefined,
					livePricesArray: [],
					onDemandPricesArray: [],
					showApproveError: false,
				}
				
	//this.getSessionDate = getSessionDate.bind(this);
	//this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.checkTrial(0, this.state.page_num_count);
}
 
componentWillUnmount() {
}

openMoreInfo=(e, justClose)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	
};

getPendingPayments = (pageId, countId, searchText = this.state.searchBy) =>{
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	$('.main_loader').attr({'style':'display:flex'});	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/get_pending_payments", {countId: countId,pageId: pageId, sortby: this.state.sortByVal, searchText: searchText,type: 'billing'})          
	.then(res => {
		$('.main_loader').attr({'style':'display:none'});
		if ( res.data.responseData.plansData.length > 0 )
			{
				
				this.setState({
					plansArray : res.data.responseData.plansData,
					fullCount : res.data.responseData.counter[0]['counterNum'],
					});
				const maxPg = Math.ceil(res.data.responseData.counter[0]['counterNum'] / this.state.page_num_count);
		
				this.setState({
					maxPages : maxPg 
				})
				
				
				if( (this.state.page_id+6) <= maxPg )
					{
						let howMuch = maxPg < 5 ? maxPg : 5;
						const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
						this.setState({page_numbers: newArray});
					}
			}
		else 
			{
				this.setState({
					plansArray : [],
					fullCount: 0,
					});
			}			
	}) 
	.catch(err =>{
		console.log('----------there is problem------------', err);
	});
}

changePageCount = (e) => {
	let pg_id = 0;
	this.setState({ 
			page_num_count: parseInt(e.target.value),
			page_id: pg_id
		});
	if (this.state.activeTab == 'billing')
		{
			this.getPendingPayments(pg_id, this.state.page_num_count);
		}
	else if ( this.state.activeTab == 'trial' )	
		{
			this.checkTrial(pg_id, this.state.page_num_count);
		}
	else
		{
			this.checkAllOverages(pg_id, this.state.page_num_count);
		}		

}

getAllSessionUsers = (e, sess_id, indx, mod) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	$('.main_loader').attr({'style':'display:flex'});
	var data = [...this.state.billing_info];
	$(e.currentTarget).find('.all_info').addClass('active');
	
	//if( data[indx]['billUsers'] ) { return; }
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/billing_info_users", { session_id: sess_id })          
	.then(res => {
		data[indx]['billUsers'] = res.data.responseData;
		this.setState({billing_info: data
		},() => {
			console.log("NEW STATEEEE");
			console.log(this.state);
		});
	})
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	});

	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getAllSessionProducts", { session_id: sess_id })          
	.then(res => {
		let products = res.data.responseData;
		this.setState({productList: products
		},() => {
			console.log("Product List");
			console.log(this.state);
		});
		$('.main_loader').hide();
	})
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	});
}

changePage = (e, mod) => {
	console.log(mod);
	console.log(this.state.page_num_count);
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.getPendingPayments(mod, this.state.page_num_count);
		}
}


stopClick = (e) => {
	e.stopPropagation();
	e.preventDefault();
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ showSearch: !this.state.showSearch, searchStartDate: null, searchEndDate: null, searchBy: '' });
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

customChecked = () => {
	this.setState({ customCheck1: !this.state.customCheck1 });
}

searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.getPendingPayments(this.state.page_id, this.state.page_num_count); 
}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	});
}

showMoreInfo = (e, sess_id, indx) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
}

getPlanPdf = (e, mod) => {
	var billData = JSON.parse(mod.billData);
	
	var checkPlanData = {};
	if ( billData[2] ) { checkPlanData = billData[2]; }
	var postData = { channelId: mod.channelId, totalAmmount: mod.amount, planData: JSON.stringify(checkPlanData), planSubId: mod.planSubId };
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/getPlanPdf", postData)          
	.then(res => { 
		var pdfUrl = res.data.responseData;
		window.open(pdfUrl);
		$('.main_loader').hide();
	}).catch(err =>{ this.props.showMainErrorPop("Something went wrong. Please try again."); $('.main_loader').hide(); });
}

openPlan=(mod)=>{
	this.setState({
		showPlanPop : true,
		openedPlan: mod,
	})
}

openOverage=(mod)=>{
	this.setState({
		showPlanPop : true,
		openedPlan: mod,
	})
}

hidePopup=(e)=>{
	this.setState({
		showPlanPop : false,
		openedPlan: [],
		editLivePrice : false,
		editODPrice : false,
		newLivePrice: 0,
		newODPrice: 0,
		livePlanPriceNew: 0,
	})
}

editPriceFun = (mod) =>{
			let num = this.state.editBillingNum;
			let choosenPlan = this.state.plansArray[num];
			
			let planId = choosenPlan['id'];
			let planPrice = this.state.livePlanPriceNew;
			
			let newPendingPlan = choosenPlan;
			
			$('.main_loader').attr({'style':'display:flex'});	
			axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/update_pending_payment", {planId: planId, planPrice: planPrice, priceType : 1})          
			.then(res => {
				$('.main_loader').attr({'style':'display:none'});
				/*
				newPendingPlan['liveAmount'] = planPrice;
				this.setState({
					openedPlan : newPendingPlan,
				})
				*/
				this.setState({
					editLivePrice : false,
					editBillingNum: undefined,
				})
			});
			
			
}

openEditPriceFun = (mod) =>{
	let liveAmount = this.state.plansArray[mod]['liveAmount'];
	this.setState({
		editLivePrice : true,
		editBillingNum: mod,
		livePlanPriceNew: liveAmount,
	})
}

editODPrice = (mod) =>{
	/*
	if (this.state.editODPrice)
		{
			let newPendingPlan = this.state.openedPlan;
			
			let newOdPrice = this.state.odPlanPriceNew;
			let planId = this.state.openedPlan['id'];
			
			$('.main_loader').attr({'style':'display:flex'});	
			axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/update_pending_payment", {planId: planId, planPrice: newOdPrice, priceType : 2})          
			.then(res => {
				$('.main_loader').attr({'style':'display:none'});
				newPendingPlan['onDemandAmount'] = newOdPrice;
				this.setState({
					openedPlan : newPendingPlan,
				})
			});
			
			this.setState({
				editODPrice : false
			})
		}
	else 
		{
			let newOdPrice = this.state.openedPlan['onDemandAmount'];
			this.setState({
				editODPrice : true,
				odPlanPriceNew : newOdPrice,
			})
		}
		*/
}

openEditODPrice = (mod) =>{
	console.log(mod);
	this.setState({
		
	})
}

setPlanPrice = (e) =>{
	this.setState({
		newLivePrice: e
	})
}

setODPlanPrice = (e) =>{
	this.setState({
		newODPrice : e
	})
}

editLiveOverFun = (e) =>{
	if (this.state.editLiveOver)
		{
			this.setState({
				editLiveOver: false,
			})
		}
	else 
		{
			this.setState({
				editLiveOver: true,
			})
		}		
}

editRecords = (e) =>{
	if (this.state.recordsOverageEdit)
		{
			this.setState({
				recordsOverageEdit: false,
			})
		}
	else 
		{
			this.setState({
				recordsOverageEdit: true,
			})
		}
}

editClients = (e) =>{
	if (this.state.clientsOverageEdit)
		{
			this.setState({
				clientsOverageEdit: false,
			})
		}
	else 
		{
			let creditNum = this.state.openedPlan['planDetails']['priceToAddClients'];
			this.setState({
				clientsOverageEdit: true,
				priceToAddClientsNew: creditNum
			})
		}
}

handleChange= (name, num)=> e =>{
	
	let currentArray = [];
	if ( this.state.activeTab == 'billing' )
		{
			currentArray = this.state.plansArray;
		}
	else if ( this.state.activeTab == 'trial' )
		{
			currentArray = this.state.trialsArray;
		}
	else
		{
			currentArray = this.state.overagesArray;
		}		
	
	currentArray[num]['approveStatus'] = 1;
	
	let priceNum = e.target.value;
	
	if (name == 'livePlanPriceNew')
		{
			currentArray[num]['liveAmountNew'] = priceNum;
		}
	else if ( name == 'odPlanPriceNew' )
		{
			currentArray[num]['onDemandAmountNew'] = priceNum;
		}
	else if ( name == 'liveOverageNew' )
		{
			currentArray[num]['liveAmountNew'] = priceNum;
		}
	else if ( name == 'clientsOverageNew' )
		{
			currentArray[num]['clientsOverageNew'] = priceNum;
		}
	else if ( name == 'recordsOverageNew' )
		{
			currentArray[num]['recordsOverageNew'] = priceNum;
		}
	else if ( name == 'totalOverUsers' )
		{
			currentArray[num]['participantsNumNew'] = priceNum;
		}
	else if ( name == 'totalOverClients' )
		{
			currentArray[num]['clientsNumNew'] = priceNum;
		}
	else if ( name == 'totalOverRecordings' )
		{
			currentArray[num]['recordsNumNew'] = priceNum;
		}	
		
	if ( this.state.activeTab == 'billing' )
		{
			this.setState({
				plansArray: currentArray,
			});
		}
	else if ( this.state.activeTab == 'trial' )
		{
			this.setState({
				trialsArray: currentArray,
			});
		}
	else
		{
			this.setState({
				overagesArray: currentArray,
			});
		}
		
  };

sendMail = (e) => {
	
	let planId = this.state.openedPlan['id'];
	let userId = this.state.openedPlan['channelOwnerId'];
	
	$('.main_loader').attr({'style':'display:flex'});	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/send_charging_email", {planId: planId, userId: userId, type : this.state.activeTab})          
	.then(res => {
		$('.main_loader').attr({'style':'display:none'});	
		this.hidePopup();
		if (this.state.activeTab == 'billing')
			{
				this.getPendingPayments(this.state.page_id, this.state.page_num_count);
			}
		else if ( this.state.activeTab == 'overages' )	
			{
				this.checkAllOverages(this.state.page_id, this.state.page_num_count);
			}
		else 
			{
				this.checkTrial(this.state.page_id, this.state.page_num_count);
			}			
		
	})
}

changeTab = (mod) => {
	this.setState({
		activeTab : mod,
	})
	if (mod == 'overages')
		{
			this.checkAllOverages(0, this.state.page_num_count);
		}
	else if (mod == 'billing')
		{
			this.getPendingPayments(0, this.state.page_num_count);
		}
	else 
		{
			this.checkTrial(0, this.state.page_num_count);
		}	
}

checkTrial = (pageId, countId, searchText = this.state.searchBy) =>{
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	$('.main_loader').attr({'style':'display:flex'});	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/get_pending_payments", {countId: countId,pageId: pageId, sortby: this.state.sortByVal, searchText: searchText,type: 'trial'})          
	.then(res => {
		$('.main_loader').attr({'style':'display:none'});
		if ( res.data.responseData.plansData.length > 0 )
			{
				this.setState({
					trialsArray : res.data.responseData.plansData,
					fullCount : res.data.responseData.counter[0]['counterNum'],
					});
				const maxPg = Math.ceil(res.data.responseData.counter[0]['counterNum'] / this.state.page_num_count);
		
				this.setState({
					maxPages : maxPg 
				})
				
				
				if( (this.state.page_id+6) <= maxPg )
					{
						let howMuch = maxPg < 5 ? maxPg : 5;
						const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
						this.setState({page_numbers: newArray});
					}
			}
		else 
			{
				this.setState({
					trialsArray : [],
					fullCount: 0,
					});
			}			
	}) 
	.catch(err =>{
		console.log('----------there is problem------------', err);
	});
}

checkAllOverages = (pageId, countId, searchText = this.state.searchBy) =>{
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	$('.main_loader').attr({'style':'display:flex'});	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/get_pending_payments", {countId: countId,pageId: pageId, sortby: this.state.sortByVal, searchText: searchText,type: 'overages'})          
	.then(res => {
		
		$('.main_loader').attr({'style':'display:none'});
		if ( res.data.responseData.plansData.length > 0 )
			{
				
				this.setState({
					overagesArray : res.data.responseData.plansData,
					fullCount : res.data.responseData.counter[0]['counterNum'],
					});
				const maxPg = Math.ceil(res.data.responseData.counter[0]['counterNum'] / this.state.page_num_count);
		
				this.setState({
					maxPages : maxPg 
				})
				
				
				if( (this.state.page_id+6) <= maxPg )
					{
						let howMuch = maxPg < 5 ? maxPg : 5;
						const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
						this.setState({page_numbers: newArray});
					}
			}
		else 
			{
				this.setState({
					overagesArray : [],
					fullCount: 0,
					});
			}
		
	}).catch(err =>{
		console.log('----------there is problem------------', err);
	});
	
}

editLiveOverFun = e =>{
	if (this.state.editLiveOverage)
		{
			this.setState({
				editLiveOverage: false,
			})
		}
	else 
		{
			//let creditNum = this.state.openedPlan['planDetails']['priceToAddClients'];
			let creditNum = 0;
			this.setState({
				editLiveOverage: true,
				liveOverageNew: creditNum,
			})
		}	
}

editClientsPrice = e=>{
	if (this.state.clientsOverageEdit)
		{
			let planId = this.state.openedPlan['id'];
			let planPrice = this.state.clientsOverageNew;
			
			let newPendingPlan = this.state.openedPlan;
			
			$('.main_loader').attr({'style':'display:flex'});	
			axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/update_pending_payment", {planId: planId, planPrice: planPrice, priceType : 3})          
			.then(res => {
				$('.main_loader').attr({'style':'display:none'});
				newPendingPlan['clientsOverage'] = planPrice;
				this.setState({
					openedPlan : newPendingPlan,
				})
			});
			
			this.setState({
				clientsOverageEdit: false,
			})
		}
	else
		{
			let clientPrice = this.state.openedPlan['clientsOverage'];
			this.setState({
				clientsOverageEdit: true,
				clientsOverageNew: clientPrice,
			})
		}		
}

editRecordsPrice = e =>{
	
	if ( this.state.recordsOverageEdit )
		{
			let planId = this.state.openedPlan['id'];
			let planPrice = this.state.recordsOverageNew;
			
			let newPendingPlan = this.state.openedPlan;
			
			$('.main_loader').attr({'style':'display:flex'});	
			axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/update_pending_payment", {planId: planId, planPrice: planPrice, priceType : 4})          
			.then(res => {
				$('.main_loader').attr({'style':'display:none'});
				newPendingPlan['recordsOverage'] = planPrice;
				this.setState({
					openedPlan : newPendingPlan,
				})
			});
			
			this.setState({
				recordsOverageEdit: false,
			})
		}
	else
		{
			let clientPrice = this.state.openedPlan['recordsOverage'];
			this.setState({
				recordsOverageEdit: true,
				recordsOverageNew: clientPrice,
			})
		}
}

approveALL = e =>{
	
	let currentType = this.state.activeTab;
	let currentArray = [];
	let sendStatus = true;
	if ( currentType == 'billing' )
		{
			currentArray = this.state.plansArray;
			
		}
	else if ( currentType == 'trial' )
		{
			currentArray = this.state.trialsArray;
			
		}
	else
		{
			currentArray = this.state.overagesArray;
			
		}
		
	for ( var i = 0; i<currentArray.length; i++ )
		{
			if (currentArray[i]['approveStatus'] == 1)
				{
					sendStatus = false;
				}
		}
	
	if ( sendStatus )
		{
			let adminId = 0;
			if (JSON.parse(localStorage.getItem('userData')))
				{
					adminId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
				}
		
			$('.main_loader').attr({'style':'display:flex'});	
			axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/approve_all", {type: currentType, plansArray: currentArray, adminId: adminId})          
			.then(res => {
				$('.main_loader').attr({'style':'display:none'});
				if ( currentType == 'billing' )
					{
						this.getPendingPayments(0, this.state.page_num_count);
					}
				else if ( this.state.activeTab == 'overages' )	
					{
						this.checkAllOverages(0, this.state.page_num_count);
					}
				else 
					{
						this.checkTrial(0, this.state.page_num_count);
					}				
			});
			
		}
	else 
		{
			this.setState({showApproveError : true})
		}		
		
}

approveItem =(mod, type)=>{
	//console.log(mod);	
	//console.log(type);
	let currentArray = [];
	if ( this.state.activeTab == 'billing' )
		{
			currentArray = this.state.plansArray;
			
		}
	else if ( this.state.activeTab == 'trial' )
		{
			currentArray = this.state.trialsArray;
			
		}
	else
		{
			currentArray = this.state.overagesArray;
			
		}		
		
		currentArray[type]['approveStatus'] = 0;
		
	if ( this.state.activeTab == 'billing' )
		{
			this.setState({
				plansArray: currentArray,
			});
		}
	else if ( this.state.activeTab == 'trial' )
		{
			this.setState({
				trialsArray: currentArray,
			});
		}
	else
		{
			this.setState({
				overagesArray: currentArray,
			});
		}
}

closeApprovePop = e=>{
	this.setState({
		showApproveError: false,
	})
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className="container-fluid payinfo_main_hold">
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className={"month_h1 "+(this.state.activeTab == 'trial' ? "active_tab" : "")} onClick={ e =>{this.changeTab('trial')}} >Trail Expirations</div>
									<div className={"month_h1 "+(this.state.activeTab == 'billing' ? "active_tab" : "")} onClick={ e =>{this.changeTab('billing')}} >Billing For Next Month</div>
									<div className={"month_h1 "+(this.state.activeTab == 'overages' ? "active_tab" : "")} onClick={ e =>{this.changeTab('overages')}} >Overages</div>
								</div>
						{this.state.activeTab == 'billing' &&
							<div>
										{ this.state.plansArray.length > 0 && 
											<table className="billing_table">
												<thead>
													<tr>
														<th>Channel Name</th>
														<th>Instructor Name</th>
														<th>Live Plan</th>
														<th>Live Plan Amount</th>
														<th>On Demand Plan</th>
														<th>Amount</th>
														<th className="th_btn_holder" >
															<div className="open_btn_reports" onClick={this.approveALL}>SEND ALL</div>
														</th>
													</tr>
												</thead>
												<tbody> 
													{ this.state.plansArray.map((item, key) =>
														<tr key={key}>
															<td>{ item['c_name'] }</td>
															<td>{ item['channelOwnerName'] }</td>
															<td>{ item['livePlan'] }</td>
															<td className="td_price" >													
																<div className="planPrice_span text_align_price_span" > ${item['liveAmount']} </div>												
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="livePlanPriceNew"
																		value={this.state.plansArray[key]['liveAmountNew']}
																		onChange={this.handleChange('livePlanPriceNew', key)}
																	/>
																</div>
															</td>
															<td>{ item['onDemandPlan'] != '' ? item['onDemandPlan'] : '-' }</td>												
															<td>							
																<div className="planPrice_span text_align_price_span" >${item['onDemandAmount']}</div>																															
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="odPlanPriceNew"
																		value={this.state.plansArray[key]['onDemandAmountNew']}
																		onChange={this.handleChange('odPlanPriceNew', key)}
																	/>
																</div>																														
															</td>
															<td>
															{ item['approveStatus'] == 1 &&
																<span className="open_btn_reports" onClick={(e)=> {this.approveItem(item, key)}} > Approve </span> 
															}
															</td>
														</tr>
													)}
												</tbody>
											</table>
										}
										{ this.state.plansArray.length == 0 && 
											<div className="noPaymentsFound" >No pending payments found</div>
										}
										{/* this.state.plansArray.length > 0 && 
											<div className="showPageNavigation">
												<div className="showpinfo">Showing { this.state.page_id+1 } to { this.state.page_num_count } of { this.state.fullCount } billings</div>
												<div className="shopnumbers">
													
													{ (this.state.page_id - 1) > -1 &&
														<span onClick={(e) => this.changePage(e, this.state.page_id - 1)}>Previous</span>
													}
													
													<span>
														{ this.state.page_numbers.map((nitem, nindex) => 
															<span key={nindex} onClick={(e) => this.changePage(e, nitem-1)} className={"pgNumbers " + (this.state.page_id+1 === nitem ? 'active' : '')}>{nitem}</span>	
														)}
													</span>
													
													 { (this.state.page_id + 1) < this.state.maxPages &&
														<span onClick={(e) => this.changePage(e, this.state.page_id + 1)}>Next</span>
			
													 }
													</div>
											</div>
										*/}
									</div>
								}
								{this.state.activeTab == 'trial' &&								
									<div>
										{ this.state.trialsArray.length > 0 && 
											<table className="billing_table">
												<thead>
													<tr>
														<th>Channel Name</th>
														<th>Instructor Name</th>
														<th>Live Plan</th>
														<th>Live Plan Amount</th>
														<th>On Demand Plan</th>
														<th>Amount</th>
														<th>Trial End Date</th>
														<th className="th_btn_holder" >
															<div className="open_btn_reports" onClick={this.approveALL}>SEND ALL</div>
														</th>
													</tr>
												</thead>
												<tbody> 
													{ this.state.trialsArray.map((item, key) =>
														<tr key={key}>
															<td>{ item['c_name'] }</td>
															<td>{ item['channelOwnerName'] }</td>
															<td>{ item['livePlan'] }</td>
															<td className="td_price" >
																<div className="planPrice_span text_align_price_span" > ${item['liveAmount']} </div>												
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="livePlanPriceNew"
																		value={this.state.trialsArray[key]['liveAmountNew']}
																		onChange={this.handleChange('livePlanPriceNew', key)}
																	/>
																</div>
															</td>
															
															<td>{ item['onDemandPlan'] != '' ? item['onDemandPlan'] : '-' }</td>
															<td className="td_price" >
																	<div className="planPrice_span text_align_price_span" >${item['onDemandAmount']}</div>																															
																	<div className="planPrice_span text_align_price_span" >
																		<input 
																			type='num'
																			className="inp_detail_text inp_width" 
																			id="odPlanPriceNew"
																			value={this.state.trialsArray[key]['onDemandAmountNew']}
																			onChange={this.handleChange('odPlanPriceNew', key)}
																		/>
																	</div>
															</td>
															<td>{item['trialEnd']}</td>
															<td>
																{item['approveStatus'] == 1 &&
																	<span className="open_btn_reports" onClick={(e)=> {this.approveItem(item, key)}} > Approve </span> 
																}
															</td>
														</tr>
													)}
												</tbody>
											</table>
										}
										{ this.state.trialsArray.length == 0 && 
											<div className="noPaymentsFound" >No pending trials found</div>
										}
										{/* this.state.trialsArray.length > 0 && 
											<div className="showPageNavigation">
												<div className="showpinfo">Showing { this.state.page_id+1 } to { this.state.page_num_count } of { this.state.fullCount } billings</div>
												<div className="shopnumbers">
													
													{ (this.state.page_id - 1) > -1 &&
														<span onClick={(e) => this.changePage(e, this.state.page_id - 1)}>Previous</span>
													}
													
													<span>
														{ this.state.page_numbers.map((nitem, nindex) => 
															<span key={nindex} onClick={(e) => this.changePage(e, nitem-1)} className={"pgNumbers " + (this.state.page_id+1 === nitem ? 'active' : '')}>{nitem}</span>	
														)}
													</span>
													
													 { (this.state.page_id + 1) < this.state.maxPages &&
														<span onClick={(e) => this.changePage(e, this.state.page_id + 1)}>Next</span>
			
													 }
													</div>
											</div>
										*/}
									</div>
								}
								
								{this.state.activeTab == 'overages' &&
									<div>
										{ this.state.overagesArray.length > 0 && 
											<table className="billing_table">
												<thead>
													<tr>
														<th>Channel Name</th>
														<th>Instructor Name</th>
														<th>Live Plan</th>
														<th>Live members</th>
														<th>Live Plan Overages</th>
														<th>On Demand Plan</th>
														<th>On Demand Clients</th>
														<th>Client overages</th>
														<th>Recordings number</th>
														<th>Recording overages</th>
														<th>Coupon Status</th>
														<th className="th_btn_holder" >
															<div className="open_btn_reports" onClick={this.approveALL}>SEND ALL</div>
														</th>
													</tr>
												</thead>
												<tbody> 
													{ this.state.overagesArray.map((item, key) =>
														<tr key={key}>
															<td>{ item['c_name'] }</td>
															<td>{ item['channelOwnerName'] }</td>
															<td>{ item['livePlan'] }</td>
															<td>
																<div className="planPrice_span text_align_price_span" >{ item['participantsNum'] }</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="totalOverUsersNew"
																		value={this.state.overagesArray[key]['participantsNumNew']}
																		onChange={this.handleChange('totalOverUsers', key)}
																	/>
																</div>
															</td>
															<td className="td_price" >
																<div className="planPrice_span text_align_price_span" > ${item['liveAmount']} </div>												
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="livePlanPriceNew"
																		value={this.state.overagesArray[key]['liveAmountNew']}
																		onChange={this.handleChange('livePlanPriceNew', key)}
																	/>
																</div>
															</td>
															<td>{ item['onDemandPlan'] != '' ? item['onDemandPlan'] : '-' }</td>
															<td>
																<div className="planPrice_span text_align_price_span" >{ item['clientsNum']}</div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="totalOverClients"
																		value={this.state.overagesArray[key]['clientsNumNew']}
																		onChange={this.handleChange('totalOverClients', key)}
																	/>
																</div>	
															</td>
															<td className="td_price" >
																<div className="planPrice_span text_align_price_span" > ${item['clientsOverage']} </div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="clientsOverageNew"
																		value={this.state.overagesArray[key]['clientsOverageNew']}
																		onChange={this.handleChange('clientsOverageNew', key)}
																	/>
																</div>
															</td>
															<td>
																<div className="planPrice_span text_align_price_span" > { item['recordsNum'] } </div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="totalOverRecordings"
																		value={this.state.overagesArray[key]['recordsNumNew']}
																		onChange={this.handleChange('totalOverRecordings', key)}
																	/>
																</div>
															</td>
															<td className="td_price" >
																<div className="planPrice_span text_align_price_span" > ${item['recordsOverage']} </div>
																<div className="planPrice_span text_align_price_span" >
																	<input 
																		type='num'
																		className="inp_detail_text inp_width" 
																		id="recordsOverageNew"
																		value={this.state.overagesArray[key]['recordsOverageNew']}
																		onChange={this.handleChange('recordsOverageNew', key)}
																	/>
																</div>
															</td>
															<td>
																{item.couponStatus &&
																	<div> {item.couponStatus}% Off </div>
																}
															</td>
															<td> 
																{item['approveStatus'] == 1 &&
																	<span className="open_btn_reports" onClick={(e)=> {this.approveItem(item, key)}} > Approve </span> 
																}
															</td>
														</tr>
													)}
												</tbody>
											</table>
										}
										{ this.state.overagesArray.length == 0 && 
											<div className="noPaymentsFound" >No pending overages found</div>
										}
										{/* this.state.overagesArray.length > 0 && 
											<div className="showPageNavigation">
												<div className="showpinfo">Showing { this.state.page_id+1 } to { this.state.page_num_count } of { this.state.fullCount } billings</div>
												<div className="shopnumbers">
													
													{ (this.state.page_id - 1) > -1 &&
														<span onClick={(e) => this.changePage(e, this.state.page_id - 1)}>Previous</span>
													}
													
													<span>
														{ this.state.page_numbers.map((nitem, nindex) => 
															<span key={nindex} onClick={(e) => this.changePage(e, nitem-1)} className={"pgNumbers " + (this.state.page_id+1 === nitem ? 'active' : '')}>{nitem}</span>	
														)}
													</span>
													
													 { (this.state.page_id + 1) < this.state.maxPages &&
														<span onClick={(e) => this.changePage(e, this.state.page_id + 1)}>Next</span>
			
													 }
													</div>
											</div>
										*/}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			{this.state.showApproveError &&
			<div className="modal pr-0 disp_blck_pop" id="approve_changes" aria-modal="true">
				<div className="modal-dialog mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">You have to approve all changes first.</p>
						</div>
						<div className="text-center">
							<button type="button" className="custom_btn1 mt-3" onClick={this.closeApprovePop}>ok</button>
						</div>
					</div>
				</div>
			</div>
			}
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			{this.state.showPlanPop &&
				<div className="modal pr-0 dspl_blck" id="pending_pop" aria-modal="true">
						<div className="modal-dialog mx-auto modal-dialog-centered">
							<div className="modal-content bg-transparent">
								{this.state.activeTab != 'overages' &&
									<div className="all_intpop">
										<div className="closePopup mainclose_position" onClick={()=>{this.hidePopup()}}></div>
										{this.state.activeTab == 'billing' &&
											<div className="inter_title">Pending Payment plan</div>
										}
										{this.state.activeTab == 'trial' &&
											<div className="inter_title">Pending Trial</div>
										}
										<div className="plan_details_holder">
											<div className="one_detail_holder text_align_detail">
												<div className="lessWidthDetails" >
													<div className="disp-inl">Channel name: </div>
													<div className="disp-inl text_width_detail">{this.state.openedPlan['channelName']}</div>
												</div>
												<div className="lessWidthDetails" >
													<div className="disp-inl">Channel id: </div>
													<div className="disp-inl text_width_detail">{this.state.openedPlan['channelId']}</div>
												</div>
												<div className="lessWidthDetails" >
													<div className="disp-inl">Channel owner name: </div>
													<div className="disp-inl text_width_detail">{this.state.openedPlan['channelOwnerName']}</div>
												</div>
												<div className="lessWidthDetails" >
													<div className="disp-inl">Channel owner id: </div>
													<div className="disp-inl text_width_detail">{this.state.openedPlan['channelOwnerId']}</div>
												</div>
											</div>
											<div className="prices_holder" >
												<div className="price_details_holder">
													<div className="one_detail_holder text_align_detail">
														<div className="h1_Details" >Live plan details</div>
														<div className="price_text" >
															<div className="disp-inl">Live plan: </div>
															<div className="disp-inl inp_detail_text">{this.state.openedPlan['livePlan']}</div>
														</div>
														<div className="price_text" >
															<div className="disp-inl">Live plan amount: </div>
															{!this.state.editLivePrice ?
																<div className="disp-inl inp_detail_text">${parseFloat(this.state.openedPlan['liveAmount']).toFixed(2)}</div>
																: 
																<input 
																	type='num'
																	className="disp-inl inp_detail_text inp_width" 
																	id="livePlanPriceNew"
																	value={this.state.livePlanPriceNew}
																	onChange={this.handleChange('livePlanPriceNew')}
																/>
															}
															{!this.state.editLivePrice ?
																<div className="edit_amount_span" onClick={this.editPriceFun} ></div>
																:
																<div className="edit_amount_span hide_pen_img" onClick={this.editPriceFun} >SAVE</div>
															}
														</div>
													</div>
												</div>
												<div className="price_details_holder" >
													<div className="one_detail_holder text_align_detail">
														<div className="h1_Details" >On Demand plan details</div>
														<div className="price_text" >
															<div className="disp-inl">On Demand Plan: </div>
															<div className="disp-inl inp_detail_text">{this.state.openedPlan['onDemandPlan']}</div>
														</div>
														<div className="price_text" >
															<div className="disp-inl">On Demand plan amount: </div>
															{!this.state.editODPrice ?
																<div className="disp-inl inp_detail_text">${parseFloat(this.state.openedPlan['onDemandAmount']).toFixed(2)}</div>
																:
																<input 
																	type='num' 
																	className="disp-inl inp_detail_text inp_width" 
																	id="odPlanPriceNew"
																	value={this.state.odPlanPriceNew}
																	onChange={this.handleChange('odPlanPriceNew')}
																/>
															}
															{!this.state.editODPrice ?
																<div className="edit_amount_span" onClick={this.editODPrice} ></div>
																:
																<div className="edit_amount_span hide_pen_img" onClick={this.editODPrice} >SAVE</div>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="send_btn_holder" >
											<div className="open_btn_reports" onClick={this.sendMail} >SEND</div>
										</div>
									</div>
								}
								{this.state.activeTab == 'overages' &&
									<div className="all_intpop">
										<div className="closePopup mainclose_position" onClick={()=>{this.hidePopup()}}></div>
										<div className="inter_title">Overage details</div>
										<div className="plan_details_holder">
											<div className="one_detail_holder text_align_detail">
												<div className="lessWidthDetails" >
													<div className="disp-inl">Channel name: </div>
													<div className="disp-inl text_width_detail">{this.state.openedPlan['channelName']}</div>
												</div>
												<div className="lessWidthDetails" >
													<div className="disp-inl">Channel id: </div>
													<div className="disp-inl text_width_detail">{this.state.openedPlan['channelId']}</div>
												</div>
												<div className="lessWidthDetails" >
													<div className="disp-inl">Channel owner name: </div>
													<div className="disp-inl text_width_detail">{this.state.openedPlan['channelOwnerName']}</div>
												</div>
												<div className="lessWidthDetails" >
													<div className="disp-inl">Channel owner id: </div>
													<div className="disp-inl text_width_detail">{this.state.openedPlan['channelOwnerId']}</div>
												</div>
											</div>
											<div className="prices_holder" >
												<div className="price_details_holder">
													<div className="one_detail_holder text_align_detail">
														<div className="h1_Details" >Live plan details</div>
														<div className="price_text" >
															<div className="disp-inl">Live plan: </div>
															<div className="disp-inl inp_detail_text">{this.state.openedPlan['livePlan']}</div>
														</div>
														<div className="includedHolder" >
															<div className="lessWidthDetails" >
																<div className="disp-inl">Included members: </div>
																<div className="disp-inl text_width_detail">{this.state.openedPlan['planDetails']['includedUsers']}</div>
															</div>
															<div className="lessWidthDetails" >
																<div className="disp-inl">Total members: </div>
																<div className="disp-inl text_width_detail">{this.state.openedPlan['planDetails']['totalOverUsers']}</div>
															</div>
														
														</div>
														<div className="price_text" >
															<div className="disp-inl">Live plan overage amount: </div>
															{!this.state.editLivePrice ?
																<div className="disp-inl inp_detail_text">${parseFloat(this.state.openedPlan['liveAmount']).toFixed(2)}</div>
																: 
																<input 
																	type='num'
																	className="disp-inl inp_detail_text inp_width" 
																	id="livePlanPriceNew"
																	value={this.state.livePlanPriceNew}
																	onChange={this.handleChange('livePlanPriceNew')}
																/>
															}
															{!this.state.editLivePrice ?
																<div className="edit_amount_span" onClick={this.editPriceFun} ></div>
																:
																<div className="edit_amount_span hide_pen_img" onClick={this.editPriceFun} >SAVE</div>
															}
														</div>
													</div>
												</div>
												<div className="price_details_holder">
														<div className="one_detail_holder text_align_detail">
															<div className="h1_Details" >On Demand plan details</div>
															<div className="price_text" >
																<div className="disp-inl">On Demand: </div>
																<div className="disp-inl inp_detail_text">{this.state.openedPlan['onDemandPlan']}</div>
															</div>			
															<div>
																<div className="lessWidthDetails" >
																	<div className="disp-inl">Included recordings: </div>
																	<div className="disp-inl text_width_detail">{this.state.openedPlan['planDetails']['includedRecordings']}</div>
																</div>
																<div className="lessWidthDetails" >
																	<div className="disp-inl">Total recordings: </div>
																	<div className="disp-inl text_width_detail">{this.state.openedPlan['planDetails']['totalOverRecordings']}</div>
																</div>
															</div>
															<div className="includedHolder" >		
																<div className="lessWidthDetails" >
																	<div className="disp-inl">Included clients: </div>
																	<div className="disp-inl text_width_detail">{this.state.openedPlan['planDetails']['includedClients']}</div>
																</div>
																<div className="lessWidthDetails" >
																	<div className="disp-inl">Total clients: </div>
																	<div className="disp-inl text_width_detail">{this.state.openedPlan['planDetails']['totalOverClients']}</div>
																</div>														
															</div>
															<div className="price_text" >
																<div className="disp-inl">On demand clients overage amount: </div>
																{!this.state.clientsOverageEdit ?
																<div className="disp-inl inp_detail_text">${parseFloat(this.state.openedPlan['clientsOverage']).toFixed(2)}</div>
																:
																<input 
																	type='num' 
																	className="disp-inl inp_detail_text inp_width" 
																	id="clientsOverageNew"
																	value={this.state.clientsOverageNew}
																	onChange={this.handleChange('clientsOverageNew')}
																/>
																}
																{!this.state.clientsOverageEdit ?
																	<div className="edit_amount_span" onClick={this.editClientsPrice} ></div>
																	:
																	<div className="edit_amount_span hide_pen_img" onClick={this.editClientsPrice} >SAVE</div>
																}
															</div>
															<div className="price_text" >
																<div className="disp-inl">On demand recordings overage amount: </div>
																{!this.state.recordsOverageEdit ?
																<div className="disp-inl inp_detail_text">${parseFloat(this.state.openedPlan['recordsOverage']).toFixed(2)}</div>
																:
																<input 
																	type='num' 
																	className="disp-inl inp_detail_text inp_width" 
																	id="recordsOverageNew"
																	value={this.state.recordsOverageNew}
																	onChange={this.handleChange('recordsOverageNew')}
																/>
																}
																{!this.state.recordsOverageEdit ?
																	<div className="edit_amount_span" onClick={this.editRecordsPrice} ></div>
																	:
																	<div className="edit_amount_span hide_pen_img" onClick={this.editRecordsPrice} >SAVE</div>
																}
															</div>
														
														</div>
													</div>	
											</div>
											<div className="send_btn_holder" >
												<div className="open_btn_reports" onClick={this.sendMail} >SEND</div>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>	
			}
		</div>	
	);
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ReportsMonthBilling);