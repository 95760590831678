import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router'
import $ from 'jquery'
import axios from "axios";
import {  browserHistory} from 'react-router'
import { css } from "@emotion/core";
import { setCookie } from '../../util/cookie';
import { deleteCookie } from '../auth/Logout'
//import { ClipLoader } from "react-spinners";
import moment1 from 'moment-timezone';
import ClipLoader from "react-spinners/ClipLoader";
import PhoneInput from 'react-phone-number-input/input'

const override = css`
  display: block;
  margin: 0 auto;
  
`;
class hostSignUp extends Component {
  constructor(props) {
  super(props);
  this.state={
    firstName:'',
    lastName:'',
    email:'',
    loading: false,
    phone:'',
    password:'',
    rePassword:'',
    verify:false,//false
    signup:true,//true
    sms:false,
    byEmail:true,
    message:'',
    passwordText:'',
    otp:'',
    msg:'',
    userType:'',
    channelId:'',
    channelAdmin:'',
    otperror:'',
    errorClass_FirstName:false,
    errorClass_LastName:false,
    errorClass_Phone:false,
    errorClass_Email:false,
    errorClass_Password:false,
    errorClass_RePassword:false,
	showHelpPopup: false,
	passwordEye: true,
	passwordEyeRepeat: true
  }
  this.validator = new SimpleReactValidator({autoForceUpdate: this});
  this.otpValidation = new SimpleReactValidator(); 
  this.setCookie = setCookie.bind(this);  
  this.deleteCookie = deleteCookie.bind(this);  

}
 
componentDidMount(){
  //console.log("JSON.parse(localStorage.getItem('hostData')).channelId",JSON.parse(localStorage.getItem('hostData')).channelId)
  // localStorage.setItem('verifyOtpEmail','abhi282@mailinator.com')
  // localStorage.setItem('verifyOtpfirstname','ak');
  // localStorage.setItem('verifyOtplastname','ka');
  if(localStorage.getItem('email') || localStorage.getItem('hostData') || localStorage.getItem('verifyOtpEmail')){
    
  }else{
	browserHistory.push('/accessCode');
    return;
  }
  if(localStorage.getItem('verifyOtpEmail')){ 
    console.log('==============================================================================');
    this.setState({
      firstName:localStorage.getItem('verifyOtpfirstname'),
      lastName:localStorage.getItem('verifyOtplastname'),
      email:localStorage.getItem('verifyOtpEmail'),
      passwordText:'********',
      channelId:0,
      verify:true,//false
	  signup:false,//true
	  userType: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).data.responseData.type : ''
    },()=>{
      localStorage.removeItem('verifyOtpfirstname');
      localStorage.removeItem('verifyOtplastname');
      localStorage.removeItem('verifyOtpEmail');
      $("#email_verification").attr({'style':'display:block'});
    });
	if(localStorage.getItem('hostData'))
		{
			this.setState({
				 channelId:JSON.parse(localStorage.getItem('hostData')).channelId
			})
		}
   return;
  }
  
  if(localStorage.getItem('hostData')){
    console.log("============");
    this.setState({
      firstName:JSON.parse(localStorage.getItem('hostData')).firstName,
      lastName:JSON.parse(localStorage.getItem('hostData')).lastName,
      email:JSON.parse(localStorage.getItem('hostData')).email,
      channelId:JSON.parse(localStorage.getItem('hostData')).channelId,
      channelAdmin:JSON.parse(localStorage.getItem('hostData')).channelAdmin
    })
  }
}

sessionInfo=(e)=>{
 this.setState({
    [e.target.id]:e.target.value
  })
}
setValue=d=>{
  this.setState({
    phone:d
  })

}
phonesessionInfo=(e)=>{
  var keyCode = e.target.value;
  this.setState({
    [e.target.id]:e.target.value
  },()=>console.log(this.state.phone))
 }
verify=(e)=>{
  // var ret=/^[A-Za-z]\w{0,16}$/;
  // var t = ret.test(this.state.password);
  // if(t){
  //   alert(true)
  // }else{
  //   alert(this.state.password)
  // }
  e.preventDefault();
    if (this.validator.allValid()) {
      if(this.state.password===this.state.rePassword){
        //let ak='';
      //   for(let i=0;i<this.state.password.length;i++){
      //     ak = ak+'*';
      //   }
      // this.setState({
      //   verify:true,
      //   signup:false,
      //   passwordText:ak
      // })
      
      var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/      ///^[A-Za-z]\w{7,14}$/;
      // To validate the said format we use the regular expression ^[A-Za-z]\w{7,15}$, where \w matches any word character (alphanumeric) including the underscore (equivalent to [A-Za-z0-9_]).
      // pehle wale ka Minimum eight characters, at least one letter, one number and one special character: 
      var test = reg.test(this.state.password);
      
      let ps=localStorage.getItem('email');
      if(localStorage.getItem('email')){
      if(this.state.email===ps){
       if (test) {
          //alert('pass');
          //this.setState({value: event.target.value});
       	

      let ak='';
      const participentDetail = {
        firstName:this.state.firstName.trim(),
        lastName:this.state.lastName.trim(),
        email:this.state.email.trim(),
        password:this.state.password.trim(),
        address1:"sector3",
        address2:"noida",
        city:"noida",
        state:"UP",
        zip:"123456",
        type:1,
        image:"",
        phone:this.state.phone.trim(),
        channelId:this.state.channelId,
        channelAdmin:this.state.channelAdmin,
		timezone:moment1().tz(moment1.tz.guess()).format('z')
        
    }
	
	localStorage.setItem("remail", this.state.email);
	localStorage.setItem("rpass", this.state.password);
	
	
  //  console.log('>>>>>>>>>>>>>>>>',participentDetail);
    localStorage.removeItem("email");
      axios.post(process.env.REACT_APP_NAME+"/api/v1/user/register", participentDetail)
      .then(res => {
        //localStorage.removeItem('hostData');
       if(res.data.responseMessage === "success")
      {
      
      for(let i=0;i<this.state.password.length;i++){
        ak = ak+'*';
      }
      this.setState({
      verify:true,
      signup:false,
      passwordText:ak,
      channelId:!res.data.responseData.channelId?0:1,
   
    },()=>{$("#email_verification").attr({'style':'display:block'});})
      }else{
       
       this.setState({
        msg:''
        
      }) 
      }
      }).catch(err =>{
      console.log('----------there is problem------------',err);
      if(err.response.data.responseCode===417){
        this.setState({
          msg:err.response.data.errorData
        },()=>$("#error_msg").attr({'style':'display:block'})) 
      }
      });
    }else{
      this.setState({
        msg:'Password is not matching with Format!'
      },()=>$("#error_msg").attr({'style':'display:block'}))
    }  	
  }else{
    this.setState({
      msg:'Email Id does not associated with reference code!'
    },()=>$("#error_msg").attr({'style':'display:block'}))
  }

} else {
  if(localStorage.getItem('hostData')){
  if (test) {
    //alert('pass');
    //this.setState({value: event.target.value});
   

let ak='';
const participentDetail = {
  firstName:this.state.firstName,
  lastName:this.state.lastName,
  email:this.state.email,
  password:this.state.password,
  address1:"sector3",
  address2:"noida",
  city:"noida",
  state:"UP",
  zip:"123456",
  type:1,
  image:"",
  phone:this.state.phone,
  channelId:this.state.channelId,
  channelAdmin:this.state.channelAdmin,
  timezone:moment1().tz(moment1.tz.guess()).format('z')
  
}

localStorage.setItem("remail", this.state.email);
localStorage.setItem("rpass", this.state.password);

//  console.log('>>>>>>>>>>>>>>>>',participentDetail);
localStorage.removeItem("email");
axios.post(process.env.REACT_APP_NAME+"/api/v1/user/register", participentDetail)
.then(res => {
  //localStorage.removeItem('hostData');

 if(res.data.responseMessage === "success")
{

for(let i=0;i<this.state.password.length;i++){
  ak = ak+'*';
}
this.setState({
verify:true,
signup:false,
passwordText:ak,
channelId:!res.data.responseData.channelId?0:1

},()=>{$("#email_verification").attr({'style':'display:block'});})
}else{
 console.log('=============There Is an Error===================>',res);
 this.setState({
  msg:''
  
}) 
}
}).catch(err =>{
console.log('----------there is problem------------',err);
this.setState({
  msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
  
},()=>$("#error_msg").attr({'style':'display:block'})) 
});
}else{
this.setState({
  msg:'Password is not matching with Format!'
},()=>$("#error_msg").attr({'style':'display:block'}))
}
} //if(localStorage.getItem('hostData'))
else{
  this.setState({
    msg:"Not a invited instructor!" 
  },()=>$("#error_msg").attr({'style':'display:block'}))
}
} //else
//------------
    }else{
      this.setState({
        msg:'Password mismatch!'
      },()=>$("#error_msg").attr({'style':'display:block'}))
    }} else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.setState({
        msg:'One or more fields has an error. Please correct and press SAVE again!'
      },()=>$("#error_msg").attr({'style':'display:block'}))
      
      // if(!this.validator.fieldValid('firstname')){
      //   this._firstName.focus();
      // }
      // if(!this.validator.fieldValid('lastName')){
      //   this._lastName.focus();
      // }
      // if(!this.validator.fieldValid('phone')){
      //   this._phone.focus();
      // }
      // if(!this.validator.fieldValid('email')){
      //   this._email.focus();
      // }
      // if(!this.validator.fieldValid('password')){
      //   this._password.focus();
      // }
      // if(!this.validator.fieldValid('rePassword')){
      //   this._rePassword.focus();
      // }
      this.forceUpdate();
    }
 }
 resend=e=>{
//   const participentDetail = {
//     firstName:this.state.firstName,
//     lastName:this.state.lastName,
//     email:this.state.email,
//     password:this.state.password,
//     address1:"sector3",
//     address2:"noida",
//     city:"noida",
//     state:"UP",
//     zip:"123456",
//     type:1,
//     image:"",
//     phone:this.state.phone
    
// }
// console.log('>>>>>>>>>>>>>>>>',participentDetail);
  
  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : this.state.email})
  .then(res => {
    console.log('>>>>>>>>>>>>>>>>>>>Checking',res)
   if(res.data.responseMessage === "success")
  {
  console.log('=============lallittiwari12345===================>',res.data);
  $("#resend_verification").attr({'style':'display:block'});
  }else{
   console.log('=============There Is an Error===================>',res);
   this.setState({
    msg:''
    
  }) 
  }
  }).catch(err =>{
  console.log('----------there is problem------------',err);
  this.setState({
    msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
    
  }) 
  });
 }
 updateQueryApi=e=>{
   let email=this.state.email
  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/userToken", {email})
  .then(res => {
    console.log('>>>>>>>>>>>>>>>>>>>Checking',res)
   if(res.data.responseMessage === "success")
  {
    localStorage.setItem("userData", JSON.stringify(res));
  
  }else{
   console.log('=============There Is an Error===================>',res);
   this.setState({
    msg:''
    
  }) 
  }
  }).catch(err =>{
  console.log('----------there is problem------------',err);
  this.setState({
    msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
    
  }) 
  });
 }
 submitHost=(e)=>{
  if (this.otpValidation.allValid()) {

    // console.log('nextPage');
    // $("#registration_popup").attr({'style':'display:block'});
    // this.setState({
    //   message:'',
      
    // })
    let otpDetail={ 
      email : this.state.email,
      code:this.state.otp
      } 
      // this.setState({
      //   loading:true
      // })
      axios.post(process.env.REACT_APP_NAME+"/api/v1/user/verify-otp", otpDetail)
        .then(res => {
          console.log("============",res);
         if(res.data.responseMessage === "success"){
		 
		 window['lastUserId'] = 0;
		   //set cookie after register
			this.setCookie(process.env.REACT_APP_COOKIE_ID, res.data.responseData.id, res.data.responseData.tokenExpire);
			this.setCookie(process.env.REACT_APP_COOKIE_TOKEN, res.data.responseData.token, res.data.responseData.tokenExpire);
			//set cookie after register
        localStorage.setItem("userData", JSON.stringify(res));
		window['user_loggedIn'] = 1;
        // localStorage.removeItem('hostData');
 
        $("#registration_popup").attr({'style':'display:block'});
        this.setState({
        message:'',
        userType:res.data.responseData.type,
        loading:false
        })
  
        }else{
         console.log('=============There Is an Error===================>'); 
        } 
        }).catch(err =>{
        console.log('----------there is problem------------',err.response);
        
        if(err.response.data.responseCode===417){
          if(!err.response.data.errorData){
            this.setState({
              otperror:''
            })
          }else{
          this.setState({
            otperror:err.response.data.errorData
          },()=>$("#error_otp_msg").attr({'style':'display:block'}))
        }
      }else{
        this.setState({
        otperror:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
        },()=>$("#error_otp_msg").attr({'style':'display:block'}))
    }
        // this.setState({
        //   loading:false
        // })
        });
		
} else{
  console.log('----------------This is a error--------------------')
    this.otpValidation.showMessages();
    this.setState({
      otperror:"Check your email and enter correct otp !"
    },()=>$("#error_otp_msg").attr({'style':'display:block'}))
  
  // rerender to show messages for the first time
  // you can use the autoForceUpdate option to do this automatically`
  this.forceUpdate();
}
  // 

} 

checkPassword=(pass)=>{
	let checkAll = false;
	var regexcheck = /[!@#$%_+:;'"~`.,?|\^&*(){}[\]<>?/|\-]/;
	var contains_number = /\d+/;
	var Regex = /^[^a-zA-Z]*$/;
	
	this.setState({
		contain8Ch: false,
		specialSymbol: false,
		numberContain: false,
		letterContain: false
	});
	
	if ( !Regex.test(pass) ) 
		{ 
			this.setState({
				letterContain: true
			});
		}
	
	if ( pass.length >= 8 )
		{
			this.setState({
				contain8Ch: true
			});
		}
	
	if ( regexcheck.test(pass) )
		{
			this.setState({
				specialSymbol: true
			});
		}
		
	if ( contains_number.test(pass) )
		{
			this.setState({
				numberContain: true
			});
		}
		
	if ( pass != '' && pass.length >= 8 && regexcheck.test(pass) && contains_number.test(pass) && !Regex.test(pass) )
		{
			checkAll = true;
		}
		
	return checkAll;
}

checkValidation=(e)=>{
	let showHelp = this.state.showHelpPopup;
	
	let checking = this.checkPassword(this.state.password);
	
	this.setState({
		showHelpPopup:true
	});
	if ( checking )
		{
			let mainthis = this;
			setTimeout(function(){ 
				mainthis.setState({
					showHelpPopup: false
				});
			}, 1000);

		}
}

showHelpPopupFun=(e)=>{
	this.setState({
		showHelpPopup:e
	});
}

closePopUp=(e)=>{
  $("#registration_popup").attr({'style':'display:none'});
}
skipStart=e=>{
 
  if(this.state.userType===1){
    if ( this.state.channelId===0 )
		{
			browserHistory.push("/ChannelCreation");
			localStorage.removeItem('hostData');
		}
	else
		{
			this.deleteCookie(process.env.REACT_APP_COOKIE_ID); 
			this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); 
			window['user_loggedIn'] = 0;
			browserHistory.push("/login");
			localStorage.removeItem('hostData');
		}
  
  }
  if(this.state.userType===3){
   
    browserHistory.push("/AdminDashboard");
    }
  if(this.state.userType===2){
  
  browserHistory.push("/participant-dashboard");
  }
}


passwordEyeCheck=(e, a)=>{
	if(a == 'pass')
		{
			if(e == 1)
				{
					this.setState({
						passwordEye: false
					});
				}
			
			else if(e == 0)
				{
					this.setState({
						passwordEye: true
					});
				}
		}
		
	else
		{
			if(e == 1)
					{
						this.setState({
							passwordEyeRepeat: false
						});
					}
				
				else if(e == 0)
					{
						this.setState({
							passwordEyeRepeat: true
						});
					}
		}
	
}

render() {
  
     return (
      <div id="root">
      <div className="App">
        <div className="container-fluid px-2">
          {this.state.signup?
          <form className = "form-horizontal pt-1"    onSubmit={this.verify} >
          <div className="mw-770 mx-auto py-4">
            <div className="text-center"><img src="/images/host.png" alt="host" className="w-60" /></div>
            <h4 className="white text-center pb-2 font-18">Signing up as a { this.state.userType === 2 ? 'participant' : 'host' }</h4>
            <div className="gray-box-4 px-2 px-sm-4 pt-4 pb-0 input_bx-cont relative host_form_signup">
              <div className="form-group">
                <label className="label">Enter First Name (Max 30 Characters)<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="text" autoFocus
                className={(this.state.errorClass_FirstName?"input-field red-outline":"input-field")} 
                ref={fname=> (this._firstName = fname)} 
                value={this.state.firstName} 
                id ='firstName' 
                onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_FirstName:false})}  />
                {this.validator.message('firstName', this.state.firstName, 'required|alpha_space|min:1|max:30')}
                <span className="signedup_2"></span>
              </div>
              <div className="form-group">
                <label className="label">Enter Last Name (Max 30 Characters)<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="text" 
                 className={(this.state.errorClass_LastName  ?"input-field red-outline":"input-field")}
                 value={this.state.lastName} ref={lname=> (this._lastName = lname)} 
                id ='lastName' 
                onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_LastName:false})}/>
                {this.validator.message('lastName', this.state.lastName, 'required|alpha_space|min:1|max:30')}
                <span className="signedup_2"></span>
              </div>
              <div className="form-group">
                <label className="label">Email Address<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="text" 
                className={(this.state.errorClass_Email  ?"input-field red-outline":"input-field")} 
                value={this.state.email} id ='email' ref={mail=> (this._email = mail)} 
                onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_Email:false})} />
                {this.validator.message('email', this.state.email, 'required|email')}
                <div className="srv-validation-message">eg. xyz@virdio.com</div>
                <span className="form_email"></span>
              </div>
              
              {/* <div className="form-group">
                <label className="label">Mobile Number<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="text"
                 className={(this.state.errorClass_Phone  ?"input-field red-outline":"input-field")}
                 value={this.state.phone} id ='phone' ref={phNumber=> (this._phone = phNumber)} placeholder="XXX-XXX-XXXX" 
                onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_Phone:false})} /> */}
                {/* <PhoneInput
              country="US"
              value={this.state.phone}
              onChange={this.setValue}
              className="input-field"
               />   */}
                {/* {this.validator.message('phone', this.state.phone, 'required|phone|size:12')}
                <div className="srv-validation-message">eg. (571-278-7200) mobile number should be 12 digit and (0-9) with (-)</div>
                <span className="mobile_phone1"></span>
              </div> */}
              {this.state.passwordEye &&
			  <div className="form-group">
                <label className="label">Create  Password<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="password" 
                className={(this.state.errorClass_Password  ?"input-field red-outline":"input-field")}
                 ref={pwd=> (this._password = pwd)} value={this.state.password} id ='password'  
				onKeyUp={this.checkValidation} onFocus={e=>{ this.showHelpPopupFun(true)}} onBlur={e=>{ this.showHelpPopupFun(false)}}
                onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_Password:false})} />
                {this.validator.message('password', this.state.password, 'required')}
                <div className="srv-validation-message">Password minimum eight characters, at least one letter, one number and one special character eg.1234567A@</div>
                <span className="password_eye" onClick={e=>{ this.passwordEyeCheck(1, 'pass')}}></span>
              </div>
			  }
			  {!this.state.passwordEye &&
			  <div className="form-group">
                <label className="label">Create  Password<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="text" 
                className={(this.state.errorClass_Password  ?"input-field red-outline":"input-field")}
                 ref={pwd=> (this._password = pwd)} value={this.state.password} id ='password'  
				onKeyUp={this.checkValidation} onFocus={e=>{ this.showHelpPopupFun(true)}} onBlur={e=>{ this.showHelpPopupFun(false)}}
                onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_Password:false})} />
                {this.validator.message('password', this.state.password, 'required')}
                <div className="srv-validation-message">Password minimum eight characters, at least one letter, one number and one special character eg.1234567A@</div>
                <span className="password_eye password_eye_shown" onClick={e=>{ this.passwordEyeCheck(0, 'pass')}}></span>
              </div>
			  }
				{this.state.passwordEyeRepeat &&
				 <div className="form-group">
					<label className="label">Retype Password<sup>*</sup><span className="inp_cover-border"></span></label>
					<input type="password" 
					className={(this.state.errorClass_RePassword?"input-field red-outline":"input-field")} 
					ref={rpwd=> (this._rePassword = rpwd)} value={this.state.rePassword} id ='rePassword' 
					onChange={(e)=>{
					  this.setState({
						[e.target.id]:e.target.value,
						errorClass_RePassword:false
					  })}} />
					{this.validator.message('rePassword', this.state.rePassword, 'required')}
					<span className="password_eye" onClick={e=>{ this.passwordEyeCheck(1, 'repeat')}}></span>
				  </div>
				}
				{!this.state.passwordEyeRepeat &&
				 <div className="form-group">
					<label className="label">Retype Password<sup>*</sup><span className="inp_cover-border"></span></label>
					<input type="text" 
					className={(this.state.errorClass_RePassword?"input-field red-outline":"input-field")} 
					ref={rpwd=> (this._rePassword = rpwd)} value={this.state.rePassword} id ='rePassword' 
					onChange={(e)=>{
					  this.setState({
						[e.target.id]:e.target.value,
						errorClass_RePassword:false
					  })}} />
					{this.validator.message('rePassword', this.state.rePassword, 'required')}
					<span className="password_eye password_eye_shown" onClick={e=>{ this.passwordEyeCheck(0, 'repeat')}}></span>
				  </div>
				}
				{ this.state.showHelpPopup && 
					<div className="password_check_form">
						<div className="pass_check_title">Your password needs to:</div>
						<div className="pass_main_line">
							<div className={ "pass_done_icon " + ( !this.state.contain8Ch ? "pass_iks_icon" : "" ) }></div>
							<div className="pass_line_text">Minimum of 8 characters</div>
						</div> 
						<div className="pass_main_line">
							<div className={ "pass_done_icon " + ( !this.state.letterContain ? "pass_iks_icon" : "" ) }></div>
							<div className="pass_line_text">Minimum of 1 Letter</div>
						</div>
						<div className="pass_main_line">
							<div className={ "pass_done_icon " + ( !this.state.numberContain ? "pass_iks_icon" : "" ) }></div>
							<div className="pass_line_text">Minimum of 1 Number</div>
						</div>
						<div className="pass_main_line">
							<div className={ "pass_done_icon " + ( !this.state.specialSymbol ? "pass_iks_icon" : "" ) }></div>
							<div className="pass_line_text">Minimum of 1 Special Character</div>
							<div className="pass_line_text spec_char">(e.g. !,@,#,$,%,&,*)</div>
						</div>
					</div>
				}
              
            </div>
            <div className="text-center">
              {this.state.verify?'':<div style={{color:'red'}}>{this.state.msg}</div>}
              {/* <button type="button" className="done mt-3" onClick={this.verify}>Submit</button> */}
              <button type="submit" className="done mt-3" >Submit</button>
            </div>
          </div>
          </form>
          :''}

          {this.state.verify?

         <div className="py-4 mw-1260 px-3 px-md-5 mx-auto mb-5">
         <div className="text-center"><img src="/images/host.png" alt="host" /></div>
         <h4 className="white text-center pb-3">Signing up as a { this.state.userType === 2 ? 'participant' : 'host' }</h4>
         <div className="row">
           <div className="col-lg-6">
             <div className="left_innr_cont h-100">
               <div className="mb-15">
                 <p className="checktxt">Enter First Name</p>
                 <p className="checktxt_name border border-0 mb-0"><img src="/images/signedup.png" className="mr-3" alt="user-icon" />{this.state.firstName}</p>
               </div>
               <div className="mb-15">
                 <p className="checktxt">Enter Last Name</p>
                 <p className="checktxt_name border border-0 mb-0"><img src="/images/signedup.png" className="mr-3" alt="user-icon" />{this.state.lastName}</p>
               </div>
               <div className="mb-15">
                 <p className="checktxt">Email Address</p>
                 <p className="checktxt_name border border-0 mb-0"><img src="/images/form-email.png" className="mr-3" alt="user-icon" />{this.state.email}</p>
               </div>
               {/* <div className="mb-30">
                 <p className="checktxt">Mobile Number</p>
                 <p className="checktxt_name border border-0 mb-0"><img src="/images/phone.png" className="mr-3" alt="user-icon" />{this.state.phone}</p>
               </div> */}
               <div className="mb-15">
                 <p className="checktxt">Create  Password</p>
                 <p className="checktxt_name border border-0 mb-0"><img src="/images/passwrd.png" className="mr-3" alt="user-icon" />{this.state.passwordText}</p>
               </div>
               <div className="mb-15">
                 <p className="checktxt">Retype Password</p>
                 <p className="checktxt_name border border-0 mb-0"><img src="/images/passwrd.png" className="mr-3" alt="user-icon" />{this.state.passwordText}</p>
               </div>
             </div>
           </div>
           {/* To verify */}
           
           <div className="col-lg-6 mt-3 mt-lg-0">
             <div className="left_innr_cont h-100 pb-3 position-relative">
               <p className="pick pb-2">Verify Your Account</p>
               {/* <p className="checktxt font-18 my-4">An email with a code was sent to your inbox. Please enter the code here. If you do not receive the email please check your Junk/Spam box. If you still do not receive the email after a few minutes, please press the “RESEND”button</p> */}
               <div className="d-flex justify-content-center align-items-center flex-wrap pb-2 err_msg">
                 {/* <div className="sms_email_box mr-3">
                     <div className="b-image text-center">
                         <img src="/images/sms.png"  alt ="#"/>
                     </div>
                     <p className="hdng1 font-16 text-center mt-3">
                       <label className="custom-control pl-0 custom-checkbox">
                           <input type="checkbox" 
                           id = "sms" 
                           checked={this.state.sms} 
                           onChange = {(e)=>{this.setState({[e.target.id]:!this.state.sms},()=>console.log('sms',this.state.sms))}}
                           className="form-radio" />
                           <span>By SMS</span>                                   
                       </label>
                     </p>
                 </div> */}
                     <ClipLoader
                     css={override}
                     size={150}
                     //size={"150px"} this also works
                     color={"#123abc"}
                     loading={this.state.loading}
                     />
                 <div className="sms_email_box ml-3">
                     <div className="b-image text-center gray-box-5">
                         <img src="/images/email.png" alt ="#" />
                     </div>
                     <p className="hdng1 font-18 text-center color_gray mt-3">
                       <label className="custom-control pl-0 custom-checkbox">
                           <input type="checkbox"
                           id = "byEmail" 
                           checked={this.state.byEmail} 
                           onChange = {(e)=>{this.setState({[e.target.id]:!this.state.byEmail})}}
                           className="form-radio" />
                           <span>By Email</span>                                   
                       </label>
                     </p>
                 </div>
                 <span className="err_msg">{this.state.message}</span>
               </div>
               <p className="pick mt-0 mb-3 font-18">ENTER THE CODE</p>
               <div className="d-flex justify-content-center align-items-center">
                 <div className="">
                   <p className="sml_input_box_ak d-inline">
                     {/* <input type="text" maxLength="1"/> */}
                     <input type="text" maxLength="4" id ="otp" value={this.state.otp} onChange={(e)=>this.setState({[e.target.id]:e.target.value})}/>
                    
                     
                   </p>
                   {/* <div className="text-white">{this.state.otperror}</div> */}
                   {/* <p className="sml_input_box d-inline">
                     <input type="text" maxLength="1"/>
                   </p>
                   <p className="sml_input_box d-inline">
                     <input type="text" maxLength="1"/>
                   </p>
                   <p className="sml_input_box d-inline">
                     <input type="text" maxLength="1"/>
                   </p> */}
                 </div>
                 <div className="ml-4">
                   <p className="checktxt font-18 mt-2 mb-0">Didn't receive?</p>
                   <h4 className="font-16 px-0 font-weight-bold mt-0 purple_text"><Link to="/hostSignUp" onClick={this.resend} className="purple_text text-decoration-none">RESEND</Link></h4>
                 </div>
               </div>
               <Link to = "/hostSignUp" className="position-absolute custom_btn"   onClick={this.submitHost}>submit</Link>
             </div>
           </div>
           
         </div>
       </div>
          :''}
          <div className="modal pr-0" id="registration_popup" aria-modal="true">
            <div className="modal-dialog dialogwidth modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
                      <h4 className="modal-title font-weight-bold white pt-3 text-center">Welcome To Virdio</h4>
                      {/* <p className="py-4 text-light text-center">Your registration was successful!  Let's start with creating a channel, a virtual studio for you to host future classes/events.</p> */}
                      <p className="py-4 text-white font-22 text-center">Your sign up was successful.</p> 
                    </div>
                    <div className="text-center">
                      <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={this.skipStart}>ok</button>
             
                    </div>
                </div>
            </div>
          </div>

          <div className="modal pr-0" id="error_msg" aria-modal="true">
            <div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
              <div className="modal-content bg-transparent">
                <div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
                  <p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
                </div>
                <div className="text-center">
                <button type="button" 
                className="custom_btn1 mt-3" 
                data-dismiss="modal" 
                onClick={e=>{
                  $("#error_msg").attr({'style':'display:none'});
                 // alert(!this.validator.fieldValid('firstname'))
                if(!this.validator.fieldValid('firstname')){
                  if(!this.validator.getErrorMessages().firstName){
                    this.setState({
                      errorClass_FirstName:false
                    })
                  }
                  else{
                  this.setState({
                    errorClass_FirstName:true
                  })
                }
                  this._firstName.focus();
                }
                if(!this.validator.fieldValid('lastName')){
                  this.setState({
                    errorClass_LastName:true
                  })
                  this._lastName.focus();
                }
                // if(!this.validator.fieldValid('phone')){
                //   this.setState({
                //     errorClass_Phone:true
                //   })
                //   this._phone.focus();
                // }
                if(!this.validator.fieldValid('email') ){
                  this.setState({
                    errorClass_Email:true
                  })
                  this._email.focus();
                }
                if(!this.validator.fieldValid('password') || this.state.msg==="Password mismatch!"){
                  this.setState({
                    errorClass_Password:true
                  })
                  this._password.focus();
                }
                if(!this.validator.fieldValid('rePassword')){
                  this.setState({
                    errorClass_RePassword:true
                  })
                  this._rePassword.focus();
                }
                  }}>ok</button>
                  </div>
              </div>
            </div>
          </div>


      <div className="modal pr-0" id="error_otp_msg" aria-modal="true"   >
        <div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
          <div className="modal-content bg-transparent">
            <div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
              <p className="py-4 text-light text-center font-20">{this.state.otperror}</p> 
            </div>
            <div className="text-center">
              <button type="button" className="custom_btn1 mt-3" data-dismiss="modal"
              onClick={e=>{
                $("#error_otp_msg").attr({'style':'display:none'});
                }}>ok</button>
            </div>
          </div>
        </div>
      </div>            
{/* Resend verification */}
<div className="modal pr-0" id="resend_verification" aria-modal="true" >
            <div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
                      {/* <p className="py-4 text-light text-center font-20">Please enter the code on the following screen after pressing “OK”</p> */}
                      <p className="py-4 text-light text-center mb-0 font-20"> A verification code has been resent to your email address. Please enter it once you receive the email.</p>
                        
                    </div>
                    <div className="text-center">
                      <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#resend_verification").attr({'style':'display:none'});}}>ok</button>
                    </div>
                </div>
            </div>
          </div>
                {/* email_verification */}

          <div className="modal pr-0" id="email_verification" aria-modal="true" >
            <div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
                      {/* <p className="py-4 text-light text-center font-20">Please enter the code on the following screen after pressing “OK”</p> */}
                      <p className="py-4 text-light text-center mb-0 font-20"> A verification code has been sent to your email address. Please enter the code on the following screen after pressing "OK"</p>
                        
                    </div>
                    <div className="text-center">
                      <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#email_verification").attr({'style':'display:none'});}}>ok</button>
                    </div>
                </div>
            </div>
          </div>
        </div>          
      </div>
    </div>
    )
  }
}

export default hostSignUp;