import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import { getOnDemandDuration } from '../../util/formatDateFunctions';


class OnDemandSessionsParticipant extends Component {
  
  constructor(props) {
	super(props);
	this.state={
		months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    }
	
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
	this.getOnDemandDuration = getOnDemandDuration.bind(this);
}
 
componentDidMount(){
	
}

getDate(date)
{
	let day = new Date(date).getDate();
	let month = this.state.months[new Date(date).getMonth()];
	let year = new Date(date).getFullYear();
	let finalDate = day + ' ' +  month + ', ' + year;
	return finalDate;
}

  render() {
	 return (
		<div className="pad_more_top">
		{ this.props.state.sessionPreview === 'square' &&
		<div className="all_demain_view od_demand_background">
			{ this.props.state.onDemandSessions.map((item, i) => 
						<div key={i} onClick={e =>{this.props.openDemandSession(item)}} className={classnames("demand_preview_square")} style={{backgroundImage: "url('" + item.image + "')"}}>
							<div className="all_inner_square">
								<div className="for_demand_header" >
									<div className="demand_interest">{item.interestTitles}</div>
									<div className="for_demand_level">
										<div className="level_title">{item.level}</div>
										{ item.level === 'Beginner' &&
											<div className="level_square green_square"></div>
										}
										{ item.level === 'Intermediate' &&
											<div className="level_square yellow_square"></div>
										}
										{ item.level === 'Advanced' &&
											<div className="level_square red_square"></div>
										}
										{ item.level === 'Multi-Level' &&
											<div className="level_square multi_level_square"></div>
										}
									</div>
								</div>
								<div className="channel_demand_name">{item.channelName}</div>
								<div className="footer_square">
									<div className="footer_pad">
										<div className="footer_square100 main_color_part less_wd mb0 font17">{item.name}</div>
										<div className="all_width_participant">
											<div className="host_name_prt">{item.firstName} {item.lastName}</div>
											<div className="inline_parts_participant">
												<div className="inline_parts_img time_img"></div>
												{ this.getSessionDate(item.createdAt)} @ {this.getSessionTime(item.createdAt)}
											</div>
											<div className="inline_parts_participant">
												<div className="inline_parts_img clock_img"></div>
												{this.getOnDemandDuration(item.duration)}
											</div>
											{ item.availableUntil !== '' &&
												<div className="inline_parts_participant">
													<div className="inline_parts_img deletecan_img"></div>
													{item.availableUntil}
												</div>
											}
										</div>
									</div>
									{ item.description.length <= 70 &&
										<div className="footer_pad">
											<div className="relative pl25 pr25 description_demand">{item.description}
												<div className="icon_foot_part_l list_img"></div>
											</div>
										</div>
									}
									{ item.description.length > 70 &&
										<div className="footer_pad">
											<div className="relative pl25 pr25 description_demand"><span className="dots_text">{item.description}</span>
												<div className="icon_foot_part_l list_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.descriptionDropOpen ? 'minus_main' : '')}  onClick={ (e) => { this.props.openDemandDrops(e, i, 'descriptionDropOpen') }} ></div>
												{ item.descriptionDropOpen &&
													<div className="abs_demand_drop drops_more" >{item.description}</div>
												}
											</div>
										</div>
									}
									<div className="footer_pad">
										{ item.equipment.length <= 50 && item.equipment !== '' &&
											<div className=" description_demand relative pl25 pr25 equipmentdemand new_width_part_list">{item.equipment}
												<div className="icon_foot_part_l wrench_img"></div>
											</div>
										}
										{ item.equipment.length > 50 && item.equipment !== '' &&
											<div className="description_demand relative pl25 pr25 new_width_part_list"><span className="dots_text">{item.equipment}</span>
												<div className="icon_foot_part_l wrench_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.equipmentDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'equipmentDropOpen') }}></div>
												{ item.equipmentDropOpen &&
													<div className="abs_demand_drop drops_more" >{item.equipment}</div>
												}
											</div>
										}
									</div>
									
									<div className="footer_pad">
										{ item.goal.length <= 15 && item.goal !== '' &&
											<div className="description_demand relative pl25 pr25 new_width_part_list">{item.goal}
												<div className="icon_foot_part_l target_img"></div>
											</div>
										}
										{ item.goal.length > 15 && item.goal !== '' &&
											<div className="description_demand relative pl25 pr25 new_width_part_list"><span className="dots_text">{item.goal}</span>
												<div className="icon_foot_part_l target_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.goalDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'goalDropOpen') }} ></div>
												{ item.goalDropOpen &&
													<div className="abs_demand_drop drops_more"  >{item.goal}</div>
												}
											</div>
										}
									</div>
									
									<div className="footer_pad">
										{ /* <div className="footer_square25 description_demand new_vert_part">Member ${item.memberCharges}</div>
										<div className="footer_square25 w65per description_demand new_vert_part">Non-Member ${item.nonMemberCharges}</div> */ }
										<div className="footer_square10 description_demand relative pl25 new_vert_part">{item.totalviews ? item.totalviews : 0}
											<div className="icon_foot_part_l view_img"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
		</div>
		}
		{ this.props.state.sessionPreview === 'list' &&
			<div>
				{ this.props.state.onDemandSessions.map((item, i) => 
						<div className={classnames("one_list_demand standard_color", { 'even_list_participant': i % 2 == 0 })}	key={i}>
							<div className="list_header">
								<div className="sess_interest inline_interest text_wrap_demand new_hold_top interest_demand" title={item.interestTitles}>{item.interestTitles}</div>
								<div className="sess_interest inline new_hold_top">{item.level}</div>
								{ item.level === 'Beginner' &&
									<div className="level_square green_square mid_align"></div>
								}
								{ item.level === 'Intermediate' &&
									<div className="level_square yellow_square mid_align"></div>
								}
								{ item.level === 'Advanced' &&
									<div className="level_square red_square mid_align"></div>
								}
								{ item.level === 'Multi-Level' &&
									<div className="level_square multi_level_square mid_align"></div>
								}
							</div>
							<div className="relative_list_info less_padding_list">
								<div className="abs_demand_image new_position_list" style={{backgroundImage: "url('" + item.image + "')"}} onClick={e =>{this.props.openDemandSession(item)}}  ></div>						
								<div className="channel_demand_name pb0">{item.channelName}</div>
								<div className="footer_pad">
									<div className="footer_square25 list_color new_first_width_list auto_demand_widths">{item.name}</div>
									<div className="footer_square25 list_color auto_demand_widths">{item.firstName} {item.lastName}</div>
									<div className="footer_square25 relative pl25 list_color auto_demand_widths">{this.getOnDemandDuration(item.duration)}
										<div className="icon_foot_part_l clock_img"></div>
									</div>
								</div>
								<div className="footer_pad">
									<div className="footer_square33 w30per mr20">
										{ item.description.length <= 40 &&
											<div className="relative pl25 pr25 description_demand">{item.description}
												<div className="icon_foot_part_l list_img"></div>
											</div>
										}
										{ item.description.length > 40 &&
											<div className="relative pl25 pr25 description_demand"><span className="dots_text">{item.description}</span>
												<div className="icon_foot_part_l list_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.descriptionDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'descriptionDropOpen') }}></div>
												{ item.descriptionDropOpen &&
													<div className="abs_demand_drop drops_more" onClick={this.props.preventDef}>{item.description}</div>
												}
											</div>
										}
									</div>
									<div className="footer_square33 w30per mr20">
										{ item.equipment.length <= 15 && item.equipment !== '' &&
											<div className="description_demand relative pl25 pr25">{item.equipment}
												<div className="icon_foot_part_l wrench_img"></div>
											</div>
										}
										{ item.equipment.length > 15 && item.equipment !== '' &&
											<div className="description_demand relative pl25 pr25"><span className="dots_text">{item.equipment}</span>
												<div className="icon_foot_part_l wrench_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.equipmentDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'equipmentDropOpen') }}></div>
												{ item.equipmentDropOpen &&
													<div className="abs_demand_drop drops_more" onClick={this.props.preventDef}>{item.equipment}</div>
												}
											</div>
										}
									</div>
									<div className="footer_square33 w30per mr20">
										{ item.goal.length <= 20 && item.goal !== '' &&
											<div className="description_demand relative pl25 pr25">{item.goal}
												<div className="icon_foot_part_l target_img"></div>
											</div>
										}
										{ item.goal.length > 20 && item.goal !== '' &&
											<div className="description_demand relative pl25 pr25"><span className="dots_text">{item.goal}</span>
												<div className="icon_foot_part_l target_img"></div>
												<div className={"icon_foot_part_r plus_main " + (item.goalDropOpen ? 'minus_main' : '')} onClick={ (e) => { this.props.openDemandDrops(e, i, 'goalDropOpen') }}></div>
												{ item.goalDropOpen &&
													<div className="abs_demand_drop drops_more" onClick={this.props.preventDef}>{item.goal}</div>
												}
											</div>
										}
									</div>
								</div>
								<div className="footer_pad">
									<div className="footer_square25 description_demand relative pl25 ">{ this.getSessionDate(item.createdAt)} @ {this.getSessionTime(item.createdAt)}
										<div className="icon_foot_part_l record_img"></div>
									</div>
									{ /* <div className="footer_square25 description_demand">Member ${item.memberCharges}</div>
									<div className="footer_square25 description_demand">Non-Member ${item.nonMemberCharges}</div> */ }
									<div className="footer_square25 description_demand relative pl25">{item.totalviews ? item.totalviews : 0}
										<div className="icon_foot_part_l view_img"></div>
									</div>
									{ item.availableUntil !== '' &&
										<div className="footer_square25 description_demand relative pl25">{item.availableUntil}
											<div className="icon_foot_part_l deletecan_img"></div>
										</div>
									}
								</div>
								{ this.props.state.isDashboard && item.onDemandSessionLink && item.onDemandSessionLink !== '' && item.processed === 1 &&
									<div className="footer_square25 text-right onDemandLinkBtn"><button  name={item.onDemandSessionLink} className="session_btn text-uppercase align-self-start session_btn_od line_session_btn" id='od_open' onClick={this.props.uneditableMode} >On Demand Link</button></div>
								}
								{ this.props.state.isDashboard &&
									<div>
										{ this.props.state.isDashboard && <a href={item.video} target="_blank" download><div className="abs_icons_list download_img"></div></a> }
										<div className="abs_icons_list edit_OD new_editOd_padd" onClick={ (e) => { this.props.openEditDemand(e, i) }}>EDIT</div>
										<div className="abs_icons_list delete_img t60" onClick={ (e) => { this.props.openDeleteDemand(e, item.id) }}></div>
									</div>
								}
							</div>
						</div>
					)}
			</div>
		}
		
		{  this.props.state.onDemandShown !== this.props.state.onDemandFound &&
			<div className="txt_center marg_tp30">
				<div className="pdash_button_more relative" onClick={this.props.showMore} >Load more
					<div className="down_angle"></div>
				</div>
			</div>
		}
		
		</div>
	 )

  }
}
export default OnDemandSessionsParticipant;
