import React, { Component } from "react";
import { Link, withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import axios from "axios";

import { browserHistory } from 'react-router'


import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "../../actions/types";

import $ from 'jquery';

class Verifylink extends Component {
            constructor() {
            super();
           // let email;
            this.state = {
                email:"",
                showError:false,
				userEmailError: false,
                messageFromServer:'',
                type:"1",
                errors: {}
              };
        
            }

        componentDidMount() {

          this.verifyEmail();
        }

        componentWillMount(){
          
        }

          
          verifyEmail = e => {
    
         console.log('------------veryfylink-------------------')

         let path=window.location.pathname;
		 let redirectLink = window.location.href;
		 let redirectLinks = redirectLink.split("?");
		 let userSubdomain = redirectLinks.length > 1 ? redirectLinks[1] : false;
		 console.log(redirectLinks, 'LIIIIIINK');
         console.log('------path----------',path)

         let path_arr = path.split("/");

         console.log('------path_arr----------',path_arr[1])

         const userData = {
          email: path_arr[2],
          otpcode: path_arr[3]
        };

         axios
         .post(process.env.REACT_APP_NAME+"/api/v1/user/verify-link",userData)                
         .then(res => {

          console.log('---------verifylink123333--------------',res.data)

          if(res.data.responseData.message == 'OTP is not Valid')
			  {
				browserHistory.push("/login");
			  }
		  else if(res.data.responseData.message == 'Email not exists in system' || res.data.responseData.message == "Email doesn't exists in system" ) 
			  {
				browserHistory.push("/login");
			  }
		  else if(res.data.responseData.message == 'Password confirmed')
			  {
				 window.$('#reset_password_expired').modal('show');
			  }
		  else if(res.data.responseData.message == 'your link is verified')
			  {
				  
				localStorage.setItem('resetemail', res.data.responseData.link);
				if ( userSubdomain && userSubdomain.length > 1 )
					{
						localStorage.setItem('redirectPage', userSubdomain);
					}
				
				browserHistory.push("/reset-password");
			  }

        }).catch(err =>{
			if(err.response.data.errorData == 'Email not exists in system' || err.response.data.errorData == "Email doesn't exists in system" ) 
				{
					this.setState({
						userEmailError: true
					}, ()=>{ window.$('#reset_password_expired').modal('show'); })
					
				}
			else 
				{
					browserHistory.push("/login");
				}				
		});
      
                        
          };

        render() {

        return (
			<div>
				<div className="container">
					<div className="row">
						<div className="login-bg">
						</div>              
					</div>
				</div>
				<div id="reset_password_expired" className="modal zindex999999" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
								{!this.state.userEmailError ? 
									<pre id="login-alert-text" className={"py-4 text-light text-center font-20 popup_error_text"+(this.props.popupAlign ? ' popup_align_left' : '')}>Reset password link expired</pre>
									:
									<pre id="login-alert-text" className={"py-4 text-light text-center font-20 popup_error_text"+(this.props.popupAlign ? ' popup_align_left' : '')}>Email doesn't exists in system or Virdio account not verified.</pre>
								}
							</div>
							<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={e=>{window.$('#reset_password_expired').modal('hide'); browserHistory.push("/login")}}>OK</button></div>
						</div>
					</div>
				</div>
			</div>
         );

         }

}

const container = {
    "padding": "0px 15px",
    "max-width": "1140px"
  
  };

export default connect()(Verifylink);

