import React from 'react';
import { isSafari as safari } from "react-device-detect";

const SongPopups = props => {
    return <div className={"popups_holder " + (!props.isOpenedPopup && !props.isOpenedPopup2 ? "" : "active")} >
		<div className="pop_hold">
			{ !props.popupState && 
				<div className="new_song"  >
					<div className="new_song_tigle">Add to My Playlist</div>
					{ safari && <div>Your browser will open and close new tabs during adding playlist.<div id="enablepopups">Popups are not enabled. Please click on popup icon in top address bar of your browser and press Save again.</div></div> }
					<div className="new_song_body">
						<div className="new_song_title">Playlist Name</div>
						<input onKeyPress={props.handleRemoveErros} className={"purple_input " + (props.playlist_input_error ? "error" : "")} type="text" maxLength="20" onChange={(e) => props.handlePlaylistName(e)}  />
					</div>
				</div>
			}
			
			{ props.popupState && 
				<div className="new_song"  >
					<div className="saved_song_body">
						Your Playlist {'"'+props.playlistName+'"'} is saved.
					</div>
				</div>
			}
			
			<div className="closePopup" onClick={(e) => props.handleSaveList(e, false) }></div>
			<div className="saveBtn">
				{ 
					!props.popupState ? <div onClick={(e) => props.handleSaveList(e, true) }>SAVE</div> : <div onClick={(e) => props.handleSaveList(e, false) }>OK</div>
				}
			</div> 
		</div>
    </div>
}

export default SongPopups;