import React, { Component } from "react";
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import moment1 from 'moment-timezone';
import $ from 'jquery';
import axios from "axios";
import MarketplaceHeader from '../home/MarketplaceHeader';
import classnames from "classnames";
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import {  browserHistory,Link} from 'react-router';
import { setCookie } from '../../util/cookie';
import { setCurrentUser } from "../../actions/authActions";
import { joinSessionValidate } from '../../util/joinSessionValidate';
import ParticipantSignedUp from '../home/participantSignedUp';
import customProtocolCheck from "custom-protocol-check";
import SupportContainer from '../home/SupportContainer';
import MemberVerify from '../home/MemberVerify';

class WebLogin extends Component {
constructor(props)
{
	super(props);
	
	this.state = {
		emailInput: '',
		passwordInput: '',
		code: '',
		showPassword: false,
		errorFirstName: false,
		errorLastName: false,
		errorEmail: false,
		errorPassword: false,
		focusedElement: '',
		type:"1",
		errors: {},
		errormsg:'',
		rememberMe:false,
		urlPath:'',
		urlElectron:'',
		appDownload:'',
		platformStatus:true,
		pageMessage:'',
		redirectPath:'/login',
		isLoginPage: true,
		showWindowsApp: true,
		dont_start_submit: false,
		deepLinkButton: 'Web App',
		passwordEye: false,
		supportPageName: 'Login',
		supportPageApi: '/login',
		userTypeFaqs: 2,
		showConfirmReset: false,
		userSignedUp: false,
		loggedUserData: {},
		sessionStartTimeHigher15Min: false,
		sessionScheduleDate: '',
		sessionTimezone: '',
		sessionChannelName: '',
		isHost: false,
		sessionName: '',
		isOnDemandSession: false,
		isMarketplaceUser: false,
		isWebLogin: true,
		registeredEmail: '',
		showOtp: false,
		verifyCode: '',
		facebookLogin: '',
		continueWithFacebook: false,
		continueWithApple: false,
		newHeader: true,
		hideForm: false,
		challengeJoin: false,
		iFrameLink: false,
		appleLogin: '',
		appleEmail: '',
		appleFirstName: '',
		appleLastName: '',
	}
	this.setCookie = setCookie.bind(this);
	this.joinSessionValidate = joinSessionValidate.bind(this);
		
}

componentDidMount()
{
	window.onbeforeunload = null;
	if ( window.location.hash !== '' && window.location.hash !== '#' )
		{
			if ( window.location.hash == '#MemberSignUp' )
				{
					let memberEmail = localStorage.getItem('registeredEmail');
					let memberPassword = localStorage.getItem('registeredPassword');
					
					let fbRegister = localStorage.getItem("registeredFBToken");
					let appleRegister = localStorage.getItem("registeredAppleToken");
					
					console.log('try login 0', fbRegister);
					if ( fbRegister )
						{
							console.log('try login');
							this.setState({
								facebookLogin: fbRegister,
								hideForm: true
							}, ()=>{ console.log('try login 2'); this.loginNow(false, true, false); });
						}
					else if ( appleRegister )
						{
							this.setState({
								appleLogin: appleRegister,
								hideForm: true
							}, ()=>{ this.loginNow(false, false, true); });
						}
					else
						{
							this.setState({
								emailInput: memberEmail,
								passwordInput: memberPassword,
								hideForm: true,
							}, ()=>{ this.loginNow()});
						}
					
					localStorage.removeItem("registeredFBToken");
					localStorage.removeItem("registeredAppleToken");
				}
			else if ( window.location.hash == '#ContinueWithFacebook' || window.location.hash == '#ContinueWithFacebook;s3' )
				{
					this.setState({
						continueWithFacebook: true,
					});		
				}	
			else if ( window.location.hash == '#continueWithApple' || window.location.hash == '#continueWithApple;s3' )
				{
					this.setState({
						continueWithApple: true,
					});		
				}				
			else 
				{
					if ( localStorage.getItem('hasOldLoginEmail') )
						{
							this.setState({emailInput: localStorage.getItem('hasOldLoginEmail') });
						}
					
					localStorage.removeItem('registeredEmail');
					localStorage.removeItem('registeredPassword');
				}	
				
			let userData = JSON.parse(localStorage.getItem('userData'));
			if ( userData && window.location.hash == '#MarketplaceBack' )
				{							
					var checkSessionValidate = this.joinSessionValidate(false, userData.data.responseData.id, userData.data.responseData.token, null);
					this.setState({ 
								urlPath: checkSessionValidate.urlPathVar,
								urlElectron: checkSessionValidate.urlElectronVar,
								deepLinkButton: checkSessionValidate.deepLinkButton,
								userSignedUp: true,
								loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
								isMarketplaceUser: true,
							});	
				}
		}
	else 
		{
			if ( localStorage.getItem('hasOldLoginEmail') )
				{
					this.setState({emailInput: localStorage.getItem('hasOldLoginEmail') });
				}
			
			localStorage.removeItem('registeredEmail');
			localStorage.removeItem('registeredPassword');
			
			if ( localStorage.getItem('userData') )
				{
					this.loginNow(true);
				}
		}
	
	
	var link = window.location;
	var queryString = link.search;
	var getAllValues = queryString.split('?')
	if ( getAllValues[1] )
		{
			var allValuesInLink = getAllValues[1];
			var valuesArray = allValuesInLink.split('&');	
			for ( var i = 0; i < valuesArray.length; i++ )
				{
					let finalValue = valuesArray[i].split('=');
					
					if ( finalValue[0] == 'joinChallenge' )
						{
							this.setState({challengeJoin: true});
						}
						
					if ( finalValue[0] == 'iFrameOpen' )
						{
							this.setState({iFrameLink: true});
						}	
				}		
		}
		
		
	document.addEventListener('AppleIDSignInOnSuccess', (event) => {
			// Handle successful response.
			console.log('---Handle successful response.');
			console.log(event.detail);
			let data = event.detail;
			
			if ( data && data['authorization'] && data['authorization']['id_token'] )
				{
					let firstName = '';
					let lastName = '';
					let email = '';
					
					if ( data['user'] )
						{
							firstName = data['user']['name']['firstName']; 
							lastName = data['user']['name']['lastName']; 
							email = data['user']['email']; 
						}
					
					this.setState({
						appleLogin: data['authorization']['id_token'],
						appleEmail: email,
						appleFirstName: firstName,
						appleLastName: lastName,
					}, ()=>{
						this.loginNow(false, false, true);
					});
				}
		});
		
	document.addEventListener('AppleIDSignInOnFailure', (event) => {
		 // Handle error.
		 console.log('---Handle error');
		 console.log(event.detail.error);
		 
		if ( event && event.detail && event.detail.error && event.detail.error == 'popup_blocked_by_browser' )
			{
				return this.props.showMainErrorPop('Please make sure your browser is not blocking popup for Apple sign in.');
			}
	});	
	
	let mainthis = this;
	setTimeout(function(){
		if ( window.AppleID ) { mainthis.initApple(); }
	}, 300);
}



initApple = () => {
  window.AppleID.auth.init({
    clientId: "com.virdio.appleSignin",
    scope: "name email",
    redirectURI: process.env.REACT_APP_PATH+'/login',
    state: "origin:web",
    usePopup: true,
  });
};

singInApple =() => { window.AppleID.auth.signIn(); };

loginNow=(doAutoLogin, facebookStatus, appleStatus)=>{
	
	var userData = {};
	
	let checkLogin = JSON.parse(localStorage.getItem('userData'));
	
	if ( !facebookStatus && !appleStatus )
		{
			if ( doAutoLogin && checkLogin && checkLogin.data && checkLogin.data.responseData && checkLogin.data.responseData.token && checkLogin.data.responseData.id )
				{
					userData = {
								auth: checkLogin.data.responseData.token,
								userId: checkLogin.data.responseData.id
							}; 
				}
			else if ( doAutoLogin ) 
				{ 
					return false; 
				}
			else
				{
					if ( this.state.emailInput == '' || this.state.passwordInput == '' ) { return false; }
					
					let checkingEmail = this.emailValidation();
					if ( checkingEmail == false )
						{
							this.setState({errorEmail: true});
							return false;
						}	
						
					userData = {email: this.state.emailInput, password: this.state.passwordInput};
				}
		}
	else if ( facebookStatus )
		{
			userData = {facebookLogin: this.state.facebookLogin};
		}
	else if ( appleStatus )
		{
			userData = {appleToken: this.state.appleLogin, 
						user_email: this.state.appleEmail,
						user_first_name: this.state.appleFirstName,
						user_last_name: this.state.appleLastName};
		}
	
	if ( this.state.challengeJoin )
		{
			userData['challengeJoin'] = true;
		}
		
	$('.main_loader').attr({'style':'display:flex'});
	var response = {};
	var parentRecrId = localStorage.getItem('parentRecrId');
	
	var directLogin = localStorage.getItem('logedUserTryToParticipate');
	var directLoginOtherUser = localStorage.getItem('logedUserTryToParticipateWithOther');
	var anyLoginSessionLink = localStorage.getItem('anyLoginSessionLink');
	var isSecondStep = localStorage.getItem('secondStep');
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/adminLogin", userData).then(res => {
		localStorage.removeItem('sessionIdData');
		let adminLoginResponse = res.data.responseData;	
				if( checkLogin )
					{
						localStorage.removeItem('userData');
						localStorage.removeItem('channelId');
						localStorage.removeItem('groupId');
					}
				
				response = res;
				if(res.data.responseMessage == "success"){
					let adminLoginResponse = res.data.responseData;
					if ( res.data && res.data.responseData && res.data.responseData.email )
						{
							localStorage.setItem('hasOldLoginEmail', res.data.responseData.email);
						}
					
					$('.main_loader').hide();
						if(res.data.responseData.isOtpVerified===0 && this.state && ( this.state.detectChannelPage || this.state.detectChannelSignUpPage ))
							{
								
								let ak = '';
								for(let i=0;i<this.state.password.length;i++)
								  {
									ak = ak+'*';
								  }
								
								this.props.updateStateFromChild({
									firstName: res.data.responseData.firstName,
									lastName: res.data.responseData.lastName,
									emailSign: res.data.responseData.email,
									passwordText: ak,
									isOpenSign: true,
									verify: true
								});
									
								$('.main_loader').hide();
								return false
							}
					
						if ( res.data.responseData && res.data.responseData.isOtpVerified && res.data.responseData.isOtpVerified!=0)
							{
								window['lastUserId'] = 0;
								//set cookie after login
								this.setCookie(process.env.REACT_APP_COOKIE_ID, res.data.responseData.id, res.data.responseData.tokenExpire);
								this.setCookie(process.env.REACT_APP_COOKIE_TOKEN, res.data.responseData.token, res.data.responseData.tokenExpire);
								localStorage.setItem("userData", JSON.stringify(res));
								window['user_loggedIn'] = 1;
								//set cookie after login
								
								if ( facebookStatus || appleStatus )
									{
										var userDataLogged = JSON.stringify(res);
										if ( window.opener && window.opener.postMessage )
											{
												window.opener.postMessage(JSON.stringify({source: "auto-login", userData: userDataLogged}), '*');
												window.close();
												return true;
											}
									}
							}
							
						if ( this.props.updateStateFromChild )
							{
								this.props.updateStateFromChild({
													logedUser: true,
													logedUserName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName
												});
							}
						
						if ( res.data.responseData && res.data.responseData.channelGroup ) { localStorage.setItem('groupId', res.data.responseData.channelGroup); }
						
						if(res && res.data && res.data.responseData && res.data.responseData.settings)
							{
								var globalSettings = res.data.responseData.settings;
						
								localStorage.setItem('appDownload', ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink ));
							}
							
						if ( (directLogin || anyLoginSessionLink ) && (!this.props.state || ( this.props.state && !this.props.state.participantData) || (this.props.state && this.props.state.participantData && !this.props.state.participantData.sessionChargeAllowed) || (this.props.state && this.props.state.participantData && this.props.state.participantData.sessionChargeAllowed && this.props.state.participantData.sessionChargeAllowed != 1) || isSecondStep))
							{
								console.log('directLogin || anyLoginSessionLink');
								localStorage.setItem("userData", JSON.stringify(res));
								let urlPathVar = '';
								let urlElectronVar;
								let deepLinkButtonVar = 'Web App';
								let sSTH15M = true;
								if( (new Date(localStorage.getItem('scheduleDate')).getTime()-15*60*1000) < new Date().getTime() )
									{
										sSTH15M = false;
									}
								
								var user = JSON.parse(localStorage.getItem('userData'));
								
								if ( this.props.state && this.props.state.sessionType && this.props.state.sessionType === 'On Demand' )
									{
										var checkSessionValidate = this.joinSessionValidate(true, user.data.responseData.id, user.data.responseData.token, this.props.state.demandSessionId);
										this.setState({ 
												urlPath: checkSessionValidate.urlPathVar,
												urlElectron: checkSessionValidate.urlElectronVar,
												deepLinkButton: checkSessionValidate.deepLinkButton
										});
										
										urlPathVar = checkSessionValidate.urlPathVar;
										urlElectronVar = checkSessionValidate.urlElectronVar;
										deepLinkButtonVar = checkSessionValidate.deepLinkButton;
									}
								else
									{
										var isHost = false;
										if ( this.props && this.props.state && this.props.state.participantData && this.props.state.participantData.hostId === user.data.responseData.id ) { isHost = true; }
										
										var checkSessionValidate = this.joinSessionValidate(isHost, user.data.responseData.id, user.data.responseData.token, null);
										this.setState({ 
												urlPath: checkSessionValidate.urlPathVar,
												urlElectron: checkSessionValidate.urlElectronVar,
												deepLinkButton: checkSessionValidate.deepLinkButton
										});
										
										urlPathVar = checkSessionValidate.urlPathVar;
										urlElectronVar = checkSessionValidate.urlElectronVar;
										deepLinkButtonVar = checkSessionValidate.deepLinkButton;
									}
										
								var isHostVar = false;
								if ( this.props && this.props.state && this.props.state.participantData && this.props.state.participantData.hostId === user.data.responseData.id ) { isHostVar = true; }
								
								let userSignedUp = true;
								let logedUserNowParticate = true;
								let delayLoader = false;
								if ( this.props.state && this.props.state.justLogin )
									{
										userSignedUp = false;
										logedUserNowParticate = false;
										delayLoader = true;
									}
										
								//var mainthis = this;
								setTimeout( ()=>{ 
									
									this.props.updateStateFromChildSession({
											sessionStartTimeHigher15Min: sSTH15M,
											urlPath: urlPathVar,
											urlElectron: urlElectronVar,
											osname: this.state.osname,
											deepLinkButton: deepLinkButtonVar,
											logedUserNowParticate: logedUserNowParticate,
											tryWithOtherAccount: false,
											logedUser: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName,
											logedUserFirstName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName,
											userHostThisSession: res.data.responseData.userHostThisSession ? true : false,
											userSignedUp: userSignedUp,
											loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
											isHost: isHostVar,
											signedSessionsIds: parentRecrId ? userData['sessionId'] : [],
											delayLoader: delayLoader,
									});
									
								}, 50);

								if ( directLoginOtherUser )
									{
										this.props.updateStateFromChildSession({
												logedUserAlreadyParticate: false
											});
									}
								if ( this.props.state && this.props.state.justLogin )
									{
										this.props.reloadSessionInfoAfterLogin();
									}
								else 
									{
										$('.main_loader').hide();
									}									
								
								return false;
							}
						
						if ( window.location.href.indexOf('login') > -1 || ( ( window.location.href.indexOf('session') > -1 || window.location.href.indexOf('verify-user') > -1 ) && this.props.state && this.props.state.participantData && this.props.state.participantData.sessionChargeAllowed === 0 )  || ( ( window.location.href.indexOf('session') > -1 || window.location.href.indexOf('verify-user') > -1 ) && this.props.state && !this.props.state.log_or_signup && isSecondStep && this.props.state.participantData && this.props.state.participantData.sessionChargeAllowed === 1 ) )
							{
								if ( localStorage.getItem('backToChannel') )
									{
										window.location.href = localStorage.getItem('backToChannel');
										localStorage.removeItem('backToChannel');
										return false;
									}
								
								
								if ($('#remember_me').is(':checked')) 
									{
										localStorage.setItem("chk", 'true');
										localStorage.setItem('email', $('#email').val());
										localStorage.setItem('chkbx', $('#remember_me').val());
									}
								else 
									{
										localStorage.setItem("chk", 'false');
										localStorage.removeItem('email');
										localStorage.removeItem('chkbx');
									}									
								
								if(res.data.responseData.type===1)
									{
										if(res.data.responseData.isOtpVerified===0)
											{
												localStorage.setItem("userData", JSON.stringify(res));
												localStorage.setItem('verifyOtpEmail',res.data.responseData.email)
												localStorage.setItem('verifyOtpfirstname',res.data.responseData.firstName);
												localStorage.setItem('verifyOtplastname',res.data.responseData.lastName);
												this.setState({
													registeredEmail: res.data.responseData.email,
													showOtp: true,
												});
											}
										else
											{
												if(res.data.responseData.sessionExistIn30Min==="no")
													{
														console.log('match');
														localStorage.setItem("userData", JSON.stringify(res));
														this.props.setCurrentUser(res.data.responseData);
														!res.data.responseData.channel ? window.top.location = "/ChannelCreation" : window.top.location = "/DashboardLanding";
													}
												
												if(res.data.responseData.sessionExistIn30Min==="yes")
													{
														var isHost = false;
														if ( this.props && this.props.state && this.props.state.participantData && this.props.state.participantData.hostId === res.data.responseData.id ) { isHost = true; }
														localStorage.setItem("userData", JSON.stringify(res));
														var checkSessionValidate = this.joinSessionValidate(isHost, res.data.responseData.id, res.data.responseData.token, null);
														this.setState({ 
																urlPath: checkSessionValidate.urlPathVar,
																urlElectron: checkSessionValidate.urlElectronVar,
																deepLinkButton: checkSessionValidate.deepLinkButton,
																userSignedUp: true,
																loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
																sessionScheduleDate: res.data.responseData.scheduleDate,
																sessionTimezone: res.data.responseData.timeZone,
																sessionChannelName: res.data.responseData.channelName,
																isHost: res.data.responseData.isHost,
																sessionName: res.data.responseData.sessionName,
																signedSessionsIds: parentRecrId ? userData['sessionId'] : []
														});
														
														
														this.props.setCurrentUser(res.data.responseData)
														
														if ( JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).data.responseData.settings )
															{
																if ( parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.showNativeApplications) === 0 )
																	{
																		this.setState({showWindowsApp: false});
																	}
															}
													}
											}
									}
								
								if(res.data.responseData.type===3)
									{
										localStorage.setItem("userData", JSON.stringify(res));
										this.props.setCurrentUser(res.data.responseData)
										window.top.location = "/AdminDashboard";
									}
								
								if(res.data.responseData.type===2)
									{
										if(res.data.responseData.isOtpVerified===0)
											{
												localStorage.setItem("userData", JSON.stringify(res));
												localStorage.setItem('verifyOtpEmail',res.data.responseData.email);
												localStorage.setItem('verifyOtpfirstname',res.data.responseData.firstName);
												localStorage.setItem('verifyOtplastname',res.data.responseData.lastName);
												this.setState({
													registeredEmail: res.data.responseData.email,
													showOtp: true,
												});
												//window.top.location = "/hostSignUp";
											}
										else if ( res.data.responseData.isMarketplaceUser == 1 )
											{
												
												localStorage.setItem("userData", JSON.stringify(res));
												this.props.setCurrentUser(res.data.responseData);
												
												const participentDetail = {
														'memberId': res.data.responseData.id,
													};
												axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkForMemberFailedPayments", participentDetail).then(res => {	
													if ( res.data.responseData && res.data.responseData['id'] )
														{
															window.top.location = "/MemberProfile";
														}
													else 
														{	
															axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getMemberPlan", participentDetail).then(res => {
																
																let redirectToProfile = false;
																
																if ( res.data.responseData && res.data.responseData['id'] )
																	{
																		redirectToProfile = false;
																	}
																else 
																	{
																		redirectToProfile = true;	
																	}

																if ( !redirectToProfile )
																	{
																		
																		if ( !this.state.iFrameLink )
																			{
																				var checkSessionValidate = this.joinSessionValidate(false, adminLoginResponse.id, adminLoginResponse.token, null);

																				this.setState({ 
																					urlPath: checkSessionValidate.urlPathVar,
																					urlElectron: checkSessionValidate.urlElectronVar,
																					deepLinkButton: checkSessionValidate.deepLinkButton,
																					userSignedUp: true,
																					loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
																					isMarketplaceUser: true,
																				});	
																			}
																		else 
																			{
																				window.top.location = "/login";
																			}																			
																		
																	}
																else 
																	{
																		window.top.location = "/MemberProfile";
																	}	
																		
																}).catch(err =>{
																	console.log('-error', err);
																	window.top.location = "/MemberProfile";
																}); 															
														}			
												}).catch(err =>{
													window.top.location = "/MemberProfile";
												}); 											
												return false;
											}											
										else
											{
												if(res.data.responseData.sessionExistIn30Min==="no")
													{
														localStorage.setItem("userData", JSON.stringify(res));
														this.props.setCurrentUser(res.data.responseData)
														
														window.top.location = "/participant-dashboard";
														console.log('Open /participant-dashboard');																										
													}
												
												if(res.data.responseData.sessionExistIn30Min==="yes")
													{
														localStorage.setItem("userData", JSON.stringify(res));
														var checkSessionValidate = this.joinSessionValidate(false, res.data.responseData.id, res.data.responseData.token, null);
														this.setState({ 
																urlPath: checkSessionValidate.urlPathVar,
																urlElectron: checkSessionValidate.urlElectronVar,
																deepLinkButton: checkSessionValidate.deepLinkButton,
																userSignedUp: true,
																loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
																sessionScheduleDate: res.data.responseData.scheduleDate,
																sessionTimezone: res.data.responseData.timeZone,
																sessionChannelName: res.data.responseData.channelName,
																isHost: res.data.responseData.isHost,
																sessionName: res.data.responseData.sessionName,
																signedSessionsIds: parentRecrId ? userData['sessionId'] : []
														});

														this.props.setCurrentUser(res.data.responseData)
														
														if ( JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).data.responseData.settings )
															{
																if ( parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.showNativeApplications) === 0 )
																	{
																		this.setState({showWindowsApp: false});
																	}
															}
													}
											}
									}
								$('.main_loader').hide();
							}
						else

							{
								if ( this.props.state && this.props.state.isSignupChannel )
									{
										window.location = this.props.state.redirect;
										$('.main_loader').hide();
										
										return false;
									}
								
								if (  this.props.state && this.props.state.sessionType && this.props.state.sessionType === 'On Demand' )
									{
										localStorage.setItem("userData", JSON.stringify(res));
										let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
										let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
										
										let deeplink = '';
										let electronPath = '';

										var checkSessionValidate = this.joinSessionValidate(true, loginId, token, this.props.state.demandSessionId);
									
										deeplink = checkSessionValidate.urlPathVar;
										electronPath = checkSessionValidate.urlElectronVar;
	
										var isHostDemand = false;
										if ( this.props.state.openedDemand.hostId === loginId ) { isHostDemand = true; }
										
										this.props.updateStateFromChild({
											isLoginOpen: false,
											isOpenLoginAll: false,
											ondemandlink: deeplink,
											electronlink: electronPath,
											urlPath: deeplink,
											urlElectron: electronPath,
											userSignedUp: true,
											loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
											isOnDemandSession: true,
											sessionScheduleDate: this.props.state.openedDemand.createdAt,
											sessionTimezone: '',
											sessionChannelName: this.state.mainChannel.name,
											isHost: isHostDemand,
											sessionName: this.props.state.openedDemand.name,
											signedSessionsIds: parentRecrId ? userData['sessionId'] : []
										});
										
										$('.main_loader').hide();
										return false;
									}
								
								
								if ( this.props.state && this.props.state.log_or_signup && !isSecondStep )
									{
										console.log('STATE CARDS');
										
										let credit_cards = [];
										let res_token = res.data.responseData.token;
										let res_curr_id = res.data.responseData.id;
										
										localStorage.setItem("userData", JSON.stringify(res));
										
										axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/getcards", {'id': res.data.responseData.id}, {headers: {'Authorization': res_token}})
										  .then(res => {
														  console.log('res.data.responseData', res.data.responseData);
														  credit_cards = res.data.responseData;
											  
															this.props.updateStateChargeFromChild({
																log_or_signup: false,
																make_payment: true,
																listCard: true,
																creditCards: credit_cards,
																currentUserId: res_curr_id,
																currentUserToken: res_token,
																callbackwhenDone: this.onSubmit
															});
										  });
															$('.main_loader').hide();
										
										return false;
									}
							
							
								if( this.state.redirect !='Sign Up' )
									{ 
										let streamingUrlPath;
										let electronPath = '';
									
										var isHost = false;
										if ( this.props && this.props.state && this.props.state.participantData && this.props.state.participantData.hostId === res.data.responseData.id ) { isHost = true; }
										
										var checkSessionValidate = this.joinSessionValidate(isHost, res.data.responseData.id, res.data.responseData.token, null);
										
										streamingUrlPath = checkSessionValidate.urlPathVar;
										electronPath = checkSessionValidate.urlElectronVar;

										
										console.log(this.props.updateStateFromChildSession);
										if (this.props.updateStateFromChildSession )
											{
												this.props.updateStateFromChildSession({
													  message:'',
													  userType:res.data.responseData.type,
													  urlPath:streamingUrlPath,
													  urlElectron:electronPath,
													  isOpenSign: false,
													  verify: false,
													  isLoginOpen: false,
													  isOpenLoginAll: false,
												  });
												$('.main_loader').hide();
											}
										
									}
								 else
									 {
										 if (this.props.updateStateFromChild )
										 {
											 this.props.updateStateFromChild({
											  message:'',
											  userType:res.data.responseData.type,
											  isOpenSign: false,
											  verify: false,
											  isLoginOpen: false,
											  isOpenLoginAll: false,
											 }, true,()=> { $('.main_loader').hide(); $("#registration_popup").attr({'style':'display:block'}) })
										 }
									  }
									  
								
							}
				}
			else
				{
					$('.main_loader').hide();
					console.log('=============There Is an Error===================>'); 
				}
			
	}).catch(err =>{
		$('.main_loader').hide();
		if ( err.response.data.responseCode )
			{
				if(err.response.data.responseCode===417)
					{
						if ( err.response.data.errorData && err.response.data.errorData.name && err.response.data.errorData.name == 'TokenExpiredError' )
							{
								localStorage.removeItem('userData');
							}
						else
							{
								this.props.showMainErrorPop(err.response.data.errorData);
							}
					}
				else
					{
						this.props.showMainErrorPop('We are currently experiencing issues connecting to Virdio, please try in 1 minute!');
					}
			}
		else
			{
				if ( window.location.href.indexOf('login') == -1 ) 
					{ 
						this.props.showLoginAlert(); 
					}
				else 
					{ 
						this.props.showMainErrorPop('Entered email id does not exists in the System');
					}
			}
			
	});
}

emailValidation(){
	const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	if(!this.state.emailInput || regex.test(this.state.emailInput) === false)
		{
            return false;
        }
        return true;
    }

updateStateFromChild=(newState) => {
	this.setState(newState);
}

openNativeUrl = (nurl) => {
	
	var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;
	
	this.setState({appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink )});
	
	$('.main_loader').attr({'style':'display:flex'});
	customProtocolCheck(nurl,
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
							$("#nativedownload").show();
						  },
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
						  }, 15000);
}

resendCode=()=>{
	$('.main_loader').show();
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : this.state.registeredEmail}).then(res => {
		if( res.data.responseMessage === "success" )
			{
				$('.main_loader').hide();
				$("#resend_verification").attr({'style':'display:block'});
			}
	}).catch(err =>{
		$('.main_loader').hide();
		this.props.showMainErrorPop("We are getting trouble in reaching to our server/s. Please refresh the page and try again!");
  });
  
}

verifyAccount=()=>{
	
	let otpDetail={ 
			email : this.state.registeredEmail,
			code:this.state.verifyCode
		}
		
	if ( this.state.verifyCode.length == 0 )
		{
			this.props.showMainErrorPop('Code can not be empty!');
			return false;
		}		
		
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/verify-otp", otpDetail) .then(res => {
		if( res.data.responseMessage === "success" ) 
			{
				this.setState({
					showOtp: false,
				});
				this.loginNow();
			}
	 }).catch(err =>{
		  if( err.response.data.responseCode===417 )
			{
				$('.main_loader').hide();
				if( !err.response.data.errorData )
					{
						
					}
				else
					{
						this.props.showMainErrorPop(err.response.data.errorData);
					}
			}
		else
			{
				$('.main_loader').hide();
				this.props.showMainErrorPop('We are getting trouble in reaching to our server/s. Please refresh the page and try again!');	
			}				
	 });		
	
}

loginFacebook = () =>
		{
			if ( window.FB )
				{
					var mainThis = this;
					window.FB.login(function(response)
						{
							if ( response && response.authResponse && response.authResponse.accessToken )
								{
									mainThis.setState({facebookLogin: response.authResponse.accessToken}, ()=>{
										mainThis.loginNow(false, true);
									});	
								}
							
						}, {scope: 'public_profile,email'});
					
				}
		}

render() {
	
    return (
		<div className={"memberRegisterContainer login_container " + (this.state.iFrameLink ? "noMarginContainer" : "" ) } >
			{!this.state.iFrameLink &&
				<MarketplaceHeader
					centerHeader={this.state.hideForm}
				></MarketplaceHeader>
			}
		{ !this.state.showOtp ?
			<div>
				{ !this.state.continueWithFacebook && !this.state.continueWithApple && !this.state.hideForm &&
					<div className="memberRegisterHolder web_login_holder">
						<div className="sign_title fnt40">Sign in</div>
						<div className="sign_title member_h2">Do not have an account?
							<Link to={"/MemberRegister" + (this.state.iFrameLink ? '?iFrameOpen' : '') }>
								<div className="signInSpan"><span>&#160;</span>Sign Up</div>
							</Link>
						</div>
						<div className="info_row_holder">
							<div className="one_form_container">
								<div className="form_title">Email</div>
								<div className="relative_holder">
									<input type="text" tabIndex="1" className={classnames({'gray_inputs': true, 'error_input': this.state.errorEmail})} 
									id="emailInput" 
									value={this.state.emailInput}
									onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorEmail: false})}
									onFocus={(e) => this.setState({focusedElement: 'emailInput'})}							
									onBlur={(e) => this.setState({focusedElement: ''})}							
									/>
									{ this.state.emailInput !== '' &&
										<div className="empty_input" onClick={()=>this.setState({emailInput: ""})}></div>
									}
									{this.state.errorEmail &&
										<div className="email_error_msg" >The email must be a valid email address.</div>
									}
								</div>
							</div>
						</div>
						<div className="info_row_holder">
							<div className="one_form_container">
								<div className="form_title">Password</div>
								<div className="relative_holder">
									<input 
									type={this.state.showPassword ? "text" : "password"} 
									tabIndex="2" 
									className={classnames({'gray_inputs': true, 'error_input': this.state.errorPassword})} 
									id="passwordInput" 
									value={this.state.passwordInput} 
									onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorPassword: false})}
									onFocus={(e) => this.setState({focusedElement: 'password'})}
									onBlur={(e) => this.setState({focusedElement: ''})}
									/>
									<div className="eye_show" id="showPassword" onClick={(e)=>this.setState({showPassword: !this.state.showPassword})} >
										{ this.state.showPassword && <div className="crossed_eye"></div> }
									</div>
								</div>
							</div>
						</div>
					<div className="bottomLoginPadding">
						<div className="loginBottomHolders">
							<div className="info_row_holder">
								<div className="one_form_container">
									<Link to="/forgotpassword">
										<div className="form_title forgotClickHolder">Forgot password?</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="loginBottomHolders">
							<div className="bottom_arrow">
								<div className={classnames({'signNewButton': true, 'opacity_event': this.state.emailInput == '' || this.state.passwordInput == '' })} onClick={(e)=>this.loginNow()}>
									<div className="">Sign in</div>
								</div>
							</div>
						</div>
											
					</div>
					<div className="borderMainHolder">
						<div className="bottomBorderPart"></div>
						<div className="orTextPart">OR</div>
						<div className="bottomBorderPart"></div>
					</div>
					<div className="otherLoginHolder">
						<div className="fbButtonPart" id="openFb" onClick={(e)=>this.loginFacebook()}>
							<div className="fbLogoImg"></div>
							<div className="fbText">Continue with Facebook</div>
						</div>
						<div className="fbButtonPart appleBtnPadding">
							<div    
								onClick={(e)=>this.singInApple()}
								id="appleid-signin" 
								className="signin-button" 
								data-color="white"     
								data-border="false" 
								data-type="sign-in"
								data-mode="center-align"
								data-width="100%"
							></div>
						</div>
						{ /*
							<div className="fbButtonPart">
								<div className="fbLogoImg googleLogo"></div>
								<div className="fbText">Continue with Google</div>
							</div>
						*/ }
					</div>
					
					<div className="privacy_web_holder" >
						<Link to="/PrivacyPolicy" target="_blank" className="web_login_privacy">Click to view the virdio privacy policy</Link>
					</div>
					</div>
				}
				{ this.state.continueWithFacebook && !this.state.hideForm &&
					<div className="memberRegisterHolder web_login_holder ctnHolderMain">
						<div className="sign_title fnt30">In order to continue login with Facebook, please press CONTINUE button below.</div>
						<div className="continueButtonHolder">
							<div className="ctnButton" onClick={(e)=>this.loginFacebook()} >CONTINUE</div>
						</div>
					</div>
				}
				{ this.state.continueWithApple && !this.state.hideForm &&
					<div className="memberRegisterHolder web_login_holder ctnHolderMain">
						<div className="sign_title fnt30">In order to continue login with Apple, please press CONTINUE button below.</div>
						<div className="continueButtonHolder">
							<div className="ctnButton" onClick={(e)=>this.singInApple()} >CONTINUE</div>
						</div>
					</div>
				}
				{ this.state.isLoginPage && this.state.userSignedUp &&
					<div className="full_popup">
						<ParticipantSignedUp 
							state={this.state}
							updateStateFromChild={this.updateStateFromChild}
							openNativeUrl={this.openNativeUrl}
							sessionStartTimeHigher15Min={this.state.sessionStartTimeHigher15Min}
							urlElectron={this.state.urlElectron}
							urlPath={this.state.urlPath}
							scheduleDate={this.state.sessionScheduleDate}
							timeZone={this.state.sessionTimezone}
							sessionChannelName={this.state.sessionChannelName}
							isHost={this.state.isHost}
							sessionName={this.state.sessionName}
							isOnDemandSession={this.state.isOnDemandSession}
							isWebLogin={true}
							>
						</ParticipantSignedUp>
					</div>
				}
			</div>
			:
				<div>
					<MemberVerify
						state={this.state}
						updateStateFromChild={this.updateStateFromChild}
						resendCode={this.resendCode}
						verifyAccount={this.verifyAccount}
					>
					</MemberVerify>
				</div>
			}
			{ this.state.isLoginPage && <SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer> }
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="modal pr-0" id="resend_verification" aria-modal="true" >
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
						  <p className="py-4 text-light text-center mb-0 font-20"> A verification code has been resent to your email address. Please enter it once you receive the email.</p>
						</div>
						<div className="text-center">
						  <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#resend_verification").attr({'style':'display:none'});}}>ok</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

}

export default connect(
  null,
  { 
	setCurrentUser,
	showMainErrorPop
  }
)(WebLogin);