import React, { Component } from "react";
import axios from "axios";
import uniqueId from 'lodash/uniqueId';
import Sortable from 'react-sortablejs';
import ReactLightCalendar from '@lls/react-light-calendar'
import '@lls/react-light-calendar/dist/index.css'
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router';
import TimePicker from 'react-time-picker';
import moment from 'moment';
import moment1 from 'moment-timezone';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import Calendar from 'react-calendar';
import $ from 'jquery';
import {  browserHistory} from 'react-router'
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import Header from './Header';
import { browserName as browName} from "react-device-detect";

import { showMainErrorPop } from '../../actions/popups';
import { deleteCookie } from '../auth/Logout';
import { updatesse } from '../../util/updatesse';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import { userPermissions } from '../../util/userPermissions';
import SupportContainer from '../home/SupportContainer';

//import DateTimeField from "react-bootstrap-datetimepicker";
const override = css`
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  border: 4px solid;
  border-top-color: #bd00ff;
  border-right-color: #bd00ff;
  border-bottom-color: transparent;
  border-left-color: #bd00ff;
`;
const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',special:'@',hour:'numeric',minute:'numeric'};
const DATE_OPTIONS_CUSTOM = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
class GroupCreation extends Component {
  
  constructor(props) {
    super(props);
    const date = new Date();
    const startDate = date.getTime();
	this.sessionFieldsRef = React.createRef();
	
	var plus5 = new Date();
	 plus5.setMinutes(plus5.getMinutes()+5);
	 
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
    this.state = {
		page_count: 1,
        sessions: [],
		selectedSessions: [],
		selectedSessionsGrpCreation: [],
		selectedSessionsTemp: [],
		period: [5, 7, 10, 14, 15, 20, 25, 30],
		perDayDuration: [5, 10, 15, 20, 25, 30],
		nameNumAvailable: 30,
		nameNumLeft: 30,
		types: ['Challenge', 'Program'],
		group_type: 'Challenge',
		lastIntDays: ['st', 'nd', 'rd', 'th'],
		group_name: '',
		description:'',
		descriptionStatus:'',
		durationPerDay: 5,
		durationPeriod: 5,
		durationNums: [],
		hostsList: [],
		programHost: '',
		programHostId: 0,
		levels: ['', 'Beginner', 'Intermediate', 
					 'Advanced', 'Multi-Level'],
		levelChosen: '',
		startCalender: '',
		endCalender: '',
		startCalenderDate: '',
		endCalenderDate: '',
		reward: '',
		sessionListMain: true,
		onDemandList: false,
		changePlace: 0,
		changePlaceNew: '',
		isLoadingSessions: false,
		loading: false,
		dateSelects: []
    }
	
    this.validator = new SimpleReactValidator();
	this.deleteCookie = deleteCookie.bind(this);	
	this.updatesse = updatesse.bind(this);
	this.getSessionDateUtil = getSessionDate.bind(this);
	this.getSessionTimeUtil = getSessionTime.bind(this);
	window['onbeforeunload'] = this.onbeforeunload;
}


componentDidUpdate(prevProps, prevState){
	if(this.state.templateWhaitForConfirm && (this.props.openedTemplateSave === null || this.props.openedTemplateSave === '' ))
		{
			this.setState({templateWhaitForConfirm: false}, () => {
				window.location.href = '/SessionGroups';
			});
			
		}
}

 
componentDidMount(prevProps){
	let channelId=localStorage.getItem("channelId");
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
	axios.get(process.env.REACT_APP_NAME+"/api/v1/session/"+channelId+"/hosts-list1")
		.then(res => {
			this.setState({
				hostsList: res.data.responseData
		}); }).catch(err =>{
          console.log('----------there is problem------------');

      });
	
	if ( !this.props.params.id ) {
   
		axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/insertChannelSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel, "user_id" : JSON.parse(localStorage.getItem('userData')).data.responseData.id})
		.then(res_e => {
			this.setState({
				channelSettings: res_e.data.responseData
			});
			
		if ( res_e.data.responseData.durationNums && this.state.isMarketplaceChannel)
			{
				var numArray = [];

				for ( var i = 0; i < res_e.data.responseData.durationNums.length; i++ )
					{
						let currentNum = res_e.data.responseData.durationNums[i]['num'];
						numArray.push(currentNum);
					}
				
				this.setState({
					durationNums: numArray,
				});
			}
		else 
			{
				let durationNumRegular = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59];
				this.setState({
						durationNums: durationNumRegular,
					});
			}	
			
		}).catch(err =>{ });
	}
	else {
		let groupId = this.props.params.id;
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		axios.defaults.headers.common['Authorization'] = token;              
		axios.post(process.env.REACT_APP_NAME+"/api/v1/programs/getGroupData", { groupId })          
		.then(res => {
		   this.setState({
			   group_type: res.data.responseData.group_type == 1 ? 'Challenge' : 'Program',
			   group_name: res.data.responseData.name,
			   description: res.data.responseData.description,
			   descriptionStatus:res.data.responseData.description,
			   startCalender: res.data.responseData.group_type == 1 ?res.data.responseData.start_date : '',
			   endCalender: res.data.responseData.group_type == 1 ? res.data.responseData.end_date : '',
			   durationPeriod: res.data.responseData.period,
			   durationPerDay: res.data.responseData.per_day_duration,
			   //type: res.data.responseData.class_type,
			   reward: res.data.responseData.group_type == 1 ? res.data.responseData.reward : '',
			   programHostId: res.data.responseData.hostId,
			   programHost: res.data.responseData.username,
			   levelChosen: res.data.responseData.level,
			   selectedSessions: res.data.responseData.group_classes
			  }, ()=> {this.populateDateFields();this.forDemandDates()});
		  })
		.catch(err =>{
		  console.log('----------there is problem------------', err);
		   $('.main_loader').hide();
			this.setState({
				  showLoadMore: false,
				   isLoadingSessions: false
			  });
		});
	}
	
}

openInfoPopUp = (e, mod) => {
	if(mod == 'over')
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active');
			$(e.currentTarget).find('.tooltip_hold').addClass('tooltip_active');
		}
		
	else
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active');
		}
	
}

openAddClasses = () => {
	
	if ( this.state.group_type == 'Challenge' )
		{
			this.setState({
				sessionListMain: true,
				onDemandList: false,
				selectedSessionsTemp: [],
				selectedSessionsGrpCreation: []
			},()=>{this.fetchUpcomingSessionList()});
		}
	else
		{
			this.setState({
				sessionListMain: false,
				onDemandList: true,
				selectedSessionsTemp: [],
				selectedSessionsGrpCreation: []
			},()=>{this.fetchUpcomingSessionList()});
		}
}

fetchUpcomingSessionList() {
  this.setState({isLoadingSessions: true});
 
  let channelId=localStorage.getItem("channelId");  
  let searchText = this.state.searchBy;

  let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
  let count_load = this.state.page_count;
  let session_type = this.state.onDemandList;
  $('.main_loader').attr({'style':'display:flex'});
  
  axios.defaults.headers.common['Authorization'] = token;              
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getUpcomingSessionsByChannel", { channel_id: channelId, searchText: searchText, count_show: count_load, type: session_type })          
	.then(res => {
	   $('.main_loader').hide();
	   this.setState({
		  sessions: res.data.responseData.sessionData,
		  showLoadMore: false,
		  dataLength: res.data.responseData.dataLength,
		  shownLength: res.data.responseData.shownLength,
		  isLoadingSessions: false
		  });
	  })
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	   $('.main_loader').hide();
		this.setState({
			  showLoadMore: false,
			   isLoadingSessions: false
		  });
	});
}

changeSectionComp = (e, mod) =>{
	let hasClass = $(e.currentTarget).hasClass('list_section_active');
	if(hasClass){return false;} 
	
	if(mod == 1)
		{
			this.setState({
				sessionListMain: true,
				onDemandList: false
			},()=>{this.fetchUpcomingSessionList()});
		}
		
	else if(mod == 2)
		{
			this.setState({
				sessionListMain: false,
				onDemandList: true
			},()=>{this.fetchUpcomingSessionList()});
		}
}

loadMoreUpcoming = (e) => {
	let count_main = this.state.page_count + 1;
	
	this.setState({
		page_count: count_main,
		showLoadMore: true
	});

	let mainthis = this;
	setTimeout(function(){ mainthis.fetchUpcomingSessionList(); }, 200);
}

forProgramDays(){
	var arr = [];
	
    for (let i = 1; i <= this.state.durationPeriod; i++) 
		{
			let currentNum = '';
			let lastInt = '';
			let integ = i.toString();
			if (integ[integ.length - 1] == 1)
				{
					lastInt = this.state.lastIntDays[0];
				}
			else if (integ[integ.length - 1] == 2)
				{
					lastInt = this.state.lastIntDays[1];
				}
			else if (integ[integ.length - 1] == 3)
				{
					lastInt = this.state.lastIntDays[2];
				}
			else
				{
					lastInt = this.state.lastIntDays[3];
				}
			currentNum = i.toString() + lastInt + ' day';
			arr.push(<option key={currentNum} value={currentNum}>{currentNum}</option>)
		}
  return arr; 
}

forDemandDates(){
	
	var arr = [];
	var setDate = new Date();
	var setEndDate = this.state.endCalender;
	
	if (this.state.startCalender)
		{
			setDate = this.state.startCalender;
		}
	
	var periodDays = moment(setEndDate).diff(setDate);
	var diffDays = Math.ceil(periodDays / (1000 * 60 * 60 * 24));
	
	for (let i = 0; i <= diffDays; i++) 
		{
			let currentNum = '';
			currentNum = moment(setDate, "MMM DD, YYYY").add('days', i);
			
			var day = currentNum.format('DD');
			var month = currentNum.format('MMM');
			var year = currentNum.format('YYYY');
			
			currentNum = month + ' ' + day + ', ' + year;
			
			arr.push(currentNum)
		}
		
	this.setState({
		dateSelects: arr
	});
}

forDemandTime(){
	var arr = [];
	var hourNum = 24;
	
	for (let i = 0; i <= hourNum; i++) 
		{
			let currentNum = '';
			var timeVal = new Date(null).setHours(i);
			timeVal = moment(timeVal).format('hh:mm');
			let amInt = ' AM';
			let integ = timeVal.toString();
			var currentNum1 = integ + amInt;
			
			arr.push(<option key={currentNum1} value={currentNum1}>{currentNum1}</option>)
		}
	for (let i = 0; i <= hourNum; i++) 
		{
			let currentNum = '';
			var timeVal = new Date(null).setHours(i);
			timeVal = moment(timeVal).format('hh:mm');
			let pmInt = ' PM';
			let integ = timeVal.toString();
			var currentNum2 = integ + pmInt;
			
			arr.push(<option key={currentNum2} value={currentNum2}>{currentNum2}</option>)
		}
	return arr;
}

populateDateFields(){
	var arrToAlter = this.state.selectedSessions;
	
    for (let session of arrToAlter ) 
		{
			var number = session.program_day;
			let newNum = '';
			let lastInt = '';
			let integ = number.toString();
			if (integ[integ.length - 1] == 1)
				{
					lastInt = this.state.lastIntDays[0];
				}
			else if (integ[integ.length - 1] == 2)
				{
					lastInt = this.state.lastIntDays[1];
				}
			else if (integ[integ.length - 1] == 3)
				{
					lastInt = this.state.lastIntDays[2];
				}
			else
				{
					lastInt = this.state.lastIntDays[3];
				}
			newNum = number.toString() + lastInt + ' day';
			session.program_day = newNum;
		}
	
	this.setState({
		selectedSessions: arrToAlter
	}, () => this.updateCharLeft());
}

// period values
forPeriod() {
  var arr = [];
	
    for (let i = 0; i < this.state.period.length; i++) 
		{
			let currentNum = this.state.period[i];
			arr.push(<option key={currentNum} value={currentNum}>{currentNum}</option>)
		}
  return arr; 
}

// day values
forDayDuration() {
  var arr = [];
	
    for (let i = 0; i < this.state.perDayDuration.length; i++) 
		{
			let currentNum = this.state.perDayDuration[i];
			arr.push(<option key={currentNum} value={currentNum}>{currentNum}</option>)
		}
  return arr; 
}

forInstructors() {
	var arr = [];
	
    for (let i = 0; i < this.state.hostsList.length; i++) 
		{
			let hostName = this.state.hostsList[i].username;
			let hostId = this.state.hostsList[i].userId;
			arr.push(<option key={hostName} value={hostName}>{hostName}</option>)
		}
	return arr; 
}

updateCharLeft = () =>
	{
		let nameCharactersLeft = 0;
		let currentNum = this.state.group_name.length;
		nameCharactersLeft = this.state.nameNumAvailable - currentNum;
		this.setState({
			nameNumLeft: nameCharactersLeft,
		})
	}

updateDescField = (inp) => {
	this._description = inp;
}

groupInfo = e =>{
	if ( e.target.id == 'group_type' )
		{
			let currentType = this.state.group_type;
			if( currentType !== e.target.value )
				{
					this.setState({
						selectedSessions: [],
						selectedSessionsTemp: [],
					});
				}
		}

  this.setState({
      [e.target.id] : e.target.value,
      errormsg:'',
  })
  
	if ( e.target.id == 'type' )
		{
			this.setState({
				errorClass_Type: false
			})
		}

	if ( e.target.id == 'durationPerDay' )
		{
			this.setState({
				errorClass_Duration: false
			})
		}
	if ( e.target.id == 'durationPeriod' )
		{
			this.setState({
				errorClass_durationPeriod: false
			})
			this.forProgramDays();
		}
}

onChangeStartCalender = date =>
	{
		var date = moment(date).format('MMM DD, YYYY');
		this.setState({
			//startCalender: date,
			startCalender: date
		},() => this.forDemandDates())
	}

onChangeEndCalender = date =>
	{
		var date = moment(date).format('MMM DD, YYYY');
		this.setState({
			//endCalender: date,
			endCalender: date
		},() => this.forDemandDates())
	}

closeCalender = (e, cal_type) => {
	
	
	if ( cal_type == 1 )
	{
		this.setState({
		startCalenderDate: this.state.startCalenderDate
		});
		$("#calenderModelStart").attr({'style':'display:none'});
	}
	
	if ( cal_type == 2 )
	{
		this.setState({
		endCalenderDate: this.state.endCalenderDate
		});
		$("#calenderModelEnd").attr({'style':'display:none'});
	}
}

setCalenderDate=(e, date)=>{
	
	var dateCalender = this.state.startCalender;
	var calenderSelected = 'calenderModelStart';

	if ( date == 2 )
		{
			dateCalender = this.state.endCalender;
			calenderSelected = 'calenderModelEnd';
		}
	
	if ( dateCalender == '' )
		{
			$("#"+ calenderSelected).attr({'style':'display:none'});
			return false;
		}

	//let getFullYear=new Date(this.state.recurEndCalender).getFullYear();
	let getFullYear=moment(new Date(dateCalender)).format('YYYY');
	//let getMonth=(new Date(this.state.recurEndCalender).getMonth())+1;
	let getMonth=moment(new Date(dateCalender)).format('MM');
	//let getDate=new Date(this.state.recurEndCalender).getDate();
	let getDate=moment(new Date(dateCalender)).format('DD');

	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    var fullDate=monthArray[getMonth-1]+' '+getDate+', '+getFullYear;
	this.setState({
		[e.target.id]: fullDate
	}, ()=> { $("#"+ calenderSelected).attr({'style':'display:none'}) });

}

searchByInput = (e) => {
	let searchText = this.state.searchBy;
	this.setState({
		showLoadMore: true,
		page_count: 1
	});
	let mainthis = this;
	setTimeout(function(){ mainthis.fetchUpcomingSessionList(); }, 200);
}

sortArrays=(mod, array, array_name)=>{
	let arraySort = array;
	
	if( mod == 'interest' )
		{
			if ( !this.state.sortedChannelName )
				{
					arraySort.sort(function(a, b){
						if(a.interest_title < b.interest_title) { return -1; }
						if(a.interest_title > b.interest_title) { return 1; }
						return 0;
					})
					
					this.setState({ sortedChannelName: true });
				}
			else
				{
					arraySort.sort(function(a, b){
							if(a.interest_title > b.interest_title) { return -1; }
							if(a.interest_title < b.interest_title) { return 1; }
							return 0;
						})
				
					this.setState({ sortedChannelName: false });
				}
			
		}
		
	else if ( mod == 'ses_name' )
		{
			if ( !this.state.sortedSessionName )
				{
					arraySort.sort(function(a, b){
						if(a.name < b.name) { return -1; }
						if(a.name > b.name) { return 1; }
						return 0;
					})
					
					this.setState({ sortedSessionName: true });
				}
			else
				{
					arraySort.sort(function(a, b){
							if(a.name > b.name) { return -1; }
							if(a.name < b.name) { return 1; }
							return 0;
						})
				
					this.setState({ sortedSessionName: false });
				}
		}
		
	else if ( mod == 'host_name' )
		{
			if ( !this.state.sortedHostName )
				{
					arraySort.sort(function(a, b){
						if(a.hostName < b.hostName) { return -1; }
						if(a.hostName > b.hostName) { return 1; }
						return 0;
					})
					
					this.setState({ sortedHostName: true });
				}
			else
				{
					arraySort.sort(function(a, b){
							if(a.hostName > b.hostName) { return -1; }
							if(a.hostName < b.hostName) { return 1; }
							return 0;
						})
				
					this.setState({ sortedHostName: false });
				}
		}
		
	else if ( mod == 'date_time' )
		{
			if ( !this.state.sortedDate )
				{
					var mainthis = this;
					arraySort.sort(function(a, b){
						if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
						if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
						
						if ( mainthis.state.onDemandList )
							{
								if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return -1; }
								if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return 1; }
							}
						
						return 0;
					})
					
					this.setState({ sortedDate: true });
				}
			else
				{
					var mainthis = this;
					arraySort.sort(function(a, b){
							if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return -1; }
							if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return 1; }
							
							if ( mainthis.state.onDemandList )
								{
									if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return -1; }
									if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return 1; }
								}
							
							return 0;
							
						})
					
					this.setState({ sortedDate: false });
				}
		}
		
	this.setState({
		array_name: arraySort
	});
}

selectClass = (e, session) =>
	{ 
		var alreadySelected = this.state.selectedSessionsGrpCreation;
		var startDateChall = moment(this.state.startCalender).format("MMM DD, YYYY");
		
		if ( this.state.group_type == 'Challenge' && session.sessionType == 1 && startDateChall > session.scheduleDate )
					{
						this.props.showMainErrorPop('This class cannot be selected because class starts before challenge.');
						return false;
					}
		if ( this.state.group_type == 'Challenge' && session.sessionType == 1 && startDateChall === 'Invalid date' )
					{
						this.props.showMainErrorPop('Before adding live classes first choose start and end date.');
						return false;
					}
		for ( var obj of alreadySelected )
			{
				if ( e.currentTarget.value == obj.classId && session.sessionType == 1 )
					{
						this.props.showMainErrorPop("This live class has already been selected. It would have taken place twice at the same time in the group, which would have prevented the member to complete the group if the host ends the session abruptly.");
						return false;
					}
			}
		
		var setDate = new Date();
		if (this.state.startCalenderDate)
			{
				setDate = this.state.startCalenderDate;
			}

		let currentDate = '';
		currentDate = moment(setDate, 'YYYY-MM-DD');
		
		var day = currentDate.format('DD');
		var month = currentDate.format('MM');
		var year = currentDate.format('YYYY');
		
		currentDate = month + '/' + day + '/' + year;
		
		let currentTime = '';
		var timeVal = new Date(null).setHours(0);
		timeVal = moment(timeVal).format('hh:mm');
		let amInt = ' AM';
		let integ = timeVal.toString();
		currentTime = integ + amInt;
		
		if ( this.state.selectedSessionsTemp.indexOf(e.currentTarget.value) < 0 )
			{
				var newListTemp = this.state.selectedSessionsTemp;
				newListTemp.push(e.currentTarget.value);
				this.setState({selectedSessionsTemp: newListTemp});
				alreadySelected.push({
					'classId': e.currentTarget.value,
					'name': session.name,
					'category': session.interest_title,
					'instructor': session.hostName,
					'date': session.sessionType == 1 ? session.scheduleDate : session.createdAt,
					'time': session.sessionType == 1 ? session.scheduleDateNotFormated : '',
					'duration': session.duration,
					'type': session.sessionType,
					'program_day': '1st day',
					'schedule_date': session.sessionType == 2 ? currentDate : null,
					'schedule_time': '11:59 AM'
				});
			}
		else
			{
				var unselectIndex = alreadySelected.map(session => session.classId).indexOf(e.currentTarget.value);
				alreadySelected.splice(unselectIndex, 1);
				
				var newListTemp = this.state.selectedSessionsTemp;
				newListTemp.splice(newListTemp.indexOf(e.currentTarget.value), 1);
				this.setState({selectedSessionsTemp: newListTemp});
			}
		
		this.setState({selectedSessionsGrpCreation: alreadySelected});
		
	}
	
getSelectedClasses = () =>{
	
	var alreadySelected2 = this.state.selectedSessions;
	
	for ( var obj of this.state.selectedSessionsGrpCreation )
		{
			alreadySelected2.push(obj);
		}
	this.setState({selectedSessions: alreadySelected2});
}

changePlace = (ind) =>{
	this.state.changePlace = ind;
	$("#changeActivityPlace").attr({'style': 'display:block'});
}

saveChangePlace = () =>{
	if ( this.state.changePlaceNew )
		{
			if ( this.state.changePlaceNew > this.state.selectedSessions.length || parseInt(this.state.changePlaceNew) == 0 || ( this.state.changePlaceNew.length > 0 && this.state.changePlaceNew[0] == 0 ))
				{
					$("#activityPositionNumber").attr({'style': 'display:block'});
					return false;
				}
			
			let newInd = this.state.changePlaceNew-1
			let arr = this.state.selectedSessions;
			let element = arr.splice(this.state.changePlace, 1)[0];

			arr.splice(newInd, 0, element);
			this.setState({
					selectedSessions: arr,
					changePlaceNew: ''
				});
			
			$("#changeActivityPlace").attr({'style': 'display:none'});
		}
}

changeProgramDay = e =>{
	let sessionIndex = e.target.id.split('_');
	sessionIndex = sessionIndex[1];
	let targetState = this.state.selectedSessions;
	targetState[sessionIndex].program_day = e.target.value;
	this.setState({selectedSessions: targetState});
}

changeDemandDate = e =>{
	let sessionIndex = e.target.id.split('_');
	sessionIndex = sessionIndex[1];
	let targetState = this.state.selectedSessions;
	targetState[sessionIndex].schedule_date = e.target.value;
	this.setState({selectedSessions: targetState});
}

changeDemandTime = e =>{
	let sessionIndex = e.target.id.split('_');
	sessionIndex = sessionIndex[1];
	let targetState = this.state.selectedSessions;
	targetState[sessionIndex].schedule_time = e.target.value;
	this.setState({selectedSessions: targetState});
}

changeProgramHost = e =>{
	let programIndex = e.target.options.selectedIndex;
	if ( programIndex > 0 )
		{
			let host = this.state.hostsList.filter(item => this.state.hostsList.indexOf(item) == programIndex - 1);
			let hostId = host[0].userId;
			let hostName = host[0].username;
			let programToSelect = e.target.value;
			
			this.setState({programHost: hostName, programHostId: hostId});
		}
	else
		{
			this.setState({programHost: '', programHostId: 0});
		}
}

closeChangePlace = () =>{
	this.setState({
					changePlaceNew: ''
				});
			
	$("#changeActivityPlace").attr({'style': 'display:none'});
}

removeClass = (e) => {
  var dataArray = this.state.selectedSessions;
  dataArray.splice(e, 1);
  this.setState({
    selectedSessions:dataArray
  })
}

submitForm = () =>
	{	
		 if ( this.state.group_name.trim() == '' || this.state.description.trim() == '' )
		  {
			this.props.showMainErrorPop("Please fill all required fields correctly.");
			 if ( this.state.group_name.trim() == '')
				 {
					 this.setState({
						  errorClass_GroupName:true
						})
				 }
			
			if ( this.state.description.trim() == '')
				 {
					 this.setState({
						  errorClass_Description:true
						})
				 }
				
			return false;
		  }
  
		if ( this.state.group_type != 'Program' )
			{
				if ( this.state.startCalender == '' )
				  {
					this.props.showMainErrorPop("Please select group start date.");
					return false;
				  }
				if ( this.state.endCalender == '' )
				  {
					this.props.showMainErrorPop("Please select group end date.");
					return false;
				  }
				if ( new Date(this.state.startCalender) > new Date(this.state.endCalender) )
				  {
					this.props.showMainErrorPop("Start date needs to be set in the past relative to the end date.");
					return false;
				  }
				  
				var dateToComp = new Date();
				dateToComp = dateToComp.setDate(dateToComp.getDate() - 1);
				
				if ( this.props.params.id && new Date(this.state.startCalender) < dateToComp)
				  {
					this.props.showMainErrorPop("Please select the start date to be in future so that the Challenge can become active again.");
					return false;
				  }
				 
				// var rewardReg = new RegExp('^[0-9]+$');
				// rewardReg = rewardReg.test(this.state.reward);
				
				// if ( !rewardReg && this.state.reward !== '' )
				  // {
						// this.props.showMainErrorPop("Reward amount cannot contain letters.");
						// return false;
				   // }
				  
				// if ( this.state.reward.length > 6 )
				  // {
						// this.props.showMainErrorPop("Reward amount cannot contain more than 6 figures.");
						// return false;
				  // }
				
				// if ( this.state.reward <= 0 )
				  // {
						// this.props.showMainErrorPop("Reward amount cannot be 0.");
						// return false;
				  // }
			}
		var alreadySelected = this.state.selectedSessions;
		var startDateChall = moment(this.state.startCalender).format("MMM DD, YYYY");
		for ( var obj of alreadySelected )
		{
			var type = obj.type;
			var schedule_date = moment(obj.schedule_date).format("MMM DD, YYYY");
			if ( this.state.group_type == 'Challenge' && type == 1 && startDateChall > obj.date )
					{
						this.props.showMainErrorPop( 'Challenge start date cannot be after class start date.' );
						return false;
					}
		}
		if ( this.state.selectedSessions.length == 0 )
		  {
				this.props.showMainErrorPop("You need to select at least a single class for your class group.");
				return false;
		  }
		
		let channelId=localStorage.getItem("channelId");
		this.setState({loading: true});
		
		var startDateToSend = this.state.startCalender;
		startDateToSend = moment(startDateToSend).format('YYYY-MM-DD 00:00');
		var endDateToSend = this.state.endCalender;
		endDateToSend = moment(endDateToSend).format('YYYY-MM-DD 23:59');
		
		let url;
		if( this.props.params.id )
			{ 
				url = process.env.REACT_APP_NAME+"/api/v1/programs/"+this.props.params.id+"/editGroup";
			}
		else
			{
				url = process.env.REACT_APP_NAME+"/api/v1/programs/createNewGroup";
			}
			
		var group = {
			name: this.state.group_name.trim(),
			channel_id: channelId,
			description: this.state.description.trim(),
			start_date: this.state.group_type == 'Challenge' ? startDateToSend : null,
			end_date: this.state.group_type == 'Challenge' ? endDateToSend : null,
			period: this.state.durationPeriod,
			per_day_duration: this.state.durationPerDay,
			reward: this.state.reward.trim(),
			group_type: this.state.group_type == 'Challenge' ? 1 : 2,
			hostId: this.state.programHostId,
			level: this.state.group_type == 'Challenge' ? '' : this.state.levelChosen,
			group_classes: this.state.selectedSessions
		};
		
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		axios({method: (this.props.params.id ? "put" : "post"), url, data: group, headers: { Authorization: token }})          
		.then(res => {
		   this.setState({loading: false});
				browserHistory.push('/SessionGroups');
		  })
		.catch(err =>{
		  console.log('----------there is problem------------', err);
		   $('.main_loader').hide();
			this.setState({
					loading: false,
					showLoadMore: false,
					isLoadingSessions: false
			  });
		});
	}

enterInputValue = (mod) =>{
	let temp = mod.replace(/[^0-9]/g,"");
	
	this.setState({changePlaceNew: temp});
}

////////////////////////////////////////////////////////////////////////////////

  render() {
	 

    let strold=[];
    if(this.state.mon==true){
      strold.push('Mon')
    }
    if(this.state.tue==true){
      strold.push('Tue')
    }
    if(this.state.wed==true){
      strold.push('Wed')
    }
    if(this.state.thu==true){
      strold.push('Thu')
    }
    if(this.state.fri==true){
      strold.push('Fri')
    }
    if(this.state.sat==true){
      strold.push('Sat')
    }
    if(this.state.sun==true){
      strold.push('Sun')
    }
    strold=strold.toString();
    var selectWeekDays = strold.replace(/,\s*$/, "");
	
    let  arr =[];
    let allSessions = '';
	let allSessionsSelected = '';
    let hostSession='';
	let allProgramSessions = '';
    const { startDate, endDate } = this.state;
	let mainthis = this;
	allSessions = this.state.sessions.map((session, idx) => {
		  const { id, Name, channelName, hostName, interestId, interest_title, scheduleDate, duration } = session;
		  
		  var durTime = session.sessionType === 1 ? session.duration : (('0'+Math.floor(session.duration/60)).substr(-2)+':'+('0'+(session.duration-(Math.floor(session.duration/60)*60))).substr(-2));

		  return (
					<tr data-position="100000000000000" id={"online-user-row-"+id} key={idx}>
						<td>{session.name}</td>
						<td>{session.interest_title}</td> 
						<td>{session.hostName}</td>
						{ session.sessionType === 1 ?
							<td>{ mainthis.getSessionDateUtil(session.scheduleDateNotFormated)} @ {mainthis.getSessionTimeUtil(session.scheduleDateNotFormated)}</td>
							: 
							<td>{ mainthis.getSessionDateUtil(session.createdAtNotFormated)} @ {mainthis.getSessionTimeUtil(session.createdAtNotFormated)}</td>
						}
						<td>{durTime}</td>
						<td>
							<div className="custom-control custom-radio">
								<input type="checkbox" className="custom-control-input" name="id" value={id} checked={this.state.selectedSessionsTemp.indexOf(id+'') > -1}  id={"customRadiobtn"+id}  onChange={e => this.selectClass(e, session) } />
								<label className="custom-control-label" name="id" htmlFor={"customRadiobtn"+id}></label>
							</div> 
						</td>
					</tr>		
			);
	  })
	  
	  
	let programSessions = this.state.sessions.filter(function(oneSession){
		  return oneSession.sessionType !== 1;
	  });
	allProgramSessions = programSessions.map((session,idx)=>{
		const { id, Name, channelName, hostName, interestId, interest_title, scheduleDate, duration } = session;
		  
		var durTime = session.sessionType === 1 ? session.duration : (('0'+Math.floor(session.duration/60)).substr(-2)+':'+('0'+(session.duration-(Math.floor(session.duration/60)*60))).substr(-2));
		 
		return(
					<tr data-position="100000000000000" id={"online-user-row-"+id} key={idx}>
						<td>{session.name}</td>
						<td>{session.interest_title}</td> 
						<td>{session.hostName}</td>
							<td>{ mainthis.getSessionDateUtil(session.createdAtNotFormated)} @ {mainthis.getSessionTimeUtil(session.createdAtNotFormated)}</td>
						<td>{durTime}</td>
						<td>
							<div className="custom-control custom-radio">
								<input type="checkbox" className="custom-control-input" name="id" value={id} checked={this.state.selectedSessionsTemp.indexOf(id+'') > -1}  id={"customRadiobtn"+id}  onChange={e => this.selectClass(e, session) } />
								<label className="custom-control-label" name="id" htmlFor={"customRadiobtn"+id}></label>
							</div> 
						</td>
					</tr>
						);
		
	})
	
	let sessionsToDisplay = (this.state.group_type == 'Challenge') ? allSessions : allProgramSessions;
    return (
				<div className="container-fluid custom-width">
					<Header loginName={this.state.loginName} totalSession={this.state.totalSession} fullsubdomain={this.state.fullsubdomain}></Header>
					
					<div className={"session_creation_container contRelative rel_pos_less "+( !this.props.params.id ? "paddtp50" : "" )}>
						<div className="create_header">
							<div className="create_session_title width_texxt">
								<span className="relative">
									{ !this.props.params.id ?
										'Create Group'
										:
										'Edit Group'
									}
									
									{ !this.props.params.id &&
										<img src="/images/bulb.png" className="create_session_bulb" onClick={e=>{this.props.showMainErrorPop('Fill the selected fields in order to create a group that will be populated with classes of your choice.', true)}}/> 
										
									}
								</span>
							</div>
							<div className="previous_container new_text_align_create_sessions">
								 <div className={"close_session_creation  close_abs_position"} onClick={(e)=>browserHistory.push('/SessionGroups')}></div>
							</div>
						</div>
						<div className="main_container_creation">
							<div className="container_title">Group Info</div>
							<div className="container_subtitle">Fields marked with an <span>*</span> are Required.</div>
							<div className="for_info_fields">
								<div className="fields">
									 <div className="width50fields first_fields">
									    <div className="mdnopadding">
											<div className="form-group"><span className="cover-border cover-border_create"></span>
											  <label className="label">Group Type<sup className="start_color">*</sup></label>
												<select className={"input-field fields_back " +(this.state.errorClass_groupType ? 'red-alert' : '')} style={{color:(this.state.group_type?"white":'')}} id="group_type" value={this.state.group_type} onChange={this.groupInfo}>
												{this.state.types.map((item, i) =>
													<option key={i} value={item}>{item}</option>
												)}
												</select>
											</div>
									    </div>
										<div className="form-group">
										  <div className="invalid-show"></div>
										  <span className=""></span>
										  <label className="label">Group Name ({this.state.nameNumLeft})<sup className="start_color">*</sup></label>
										  <input type="text" className={(this.state.errorClass_GroupName ?"input-field red-alert":"input-field")}
										   ref={s => (this._group_name = s)} id = "group_name" 
										   value= {this.state.group_name}
										   onChange = {(e)=>this.setState({[e.target.id] : e.target.value,description:this.state.descriptionStatus.length>0?this.state.description:e.target.value,errorClass_GroupName:false, errorClass_Description:false}, ()=> {this.updateCharLeft(); } )} placeholder="Group Name" maxLength="30"/>
										  {this.validator.message('group_name', this.state.group_name, 'required|min:1|max:30')}
										</div>
										<div className="form-group">
										  <span className="cover-border cover-border_create"></span>
										  <label className="label ch_top19">Description (Max 250 characters)<sup className="start_color">*</sup></label>
										  <textarea type="text" id="description" ref={d => this.updateDescField(d)} 
										  value = {this.state.description} 
										   onChange = {(e)=>this.setState({[e.target.id] : e.target.value,descriptionStatus:this.state.description,errorClass_Description:false})} 
										  className={(this.state.errorClass_Description ?"input-field fields_back none_resize red-alert":"input-field fields_back none_resize")} maxLength="250"></textarea>
										  {this.validator.message('description', this.state.description, 'required|min:1|max:250')}
										</div>	
									 </div>
									 <div className="width50fields second_fields">
										<div className="row rownomargins">
											<div className="col-md-6 mdnopadding mdpaddingright">
												<div className="form-group"><span className="cover-border cover-border_create"></span>
												  <label className="label">Per Day Duration<sup className="start_color">*</sup></label>
													<select className={"input-field fields_back " +(this.state.errorClass_Duration ? 'red-alert' : '')} style={{color:(this.state.durationPerDay?"white":'')}} id="durationPerDay" value={this.state.durationPerDay} onChange={this.groupInfo}>
													{this.forDayDuration()}
													</select>
												</div>
											</div>
											<div className="col-md-6 mdnopadding mdpaddingright">
												<div className="form-group"><span className="cover-border cover-border_create"></span>
												  <label className="label">Period (days)<sup className="start_color">*</sup></label>
													<select className={"input-field fields_back " +(this.state.errorClass_durationPeriod ? 'red-alert' : '')} style={{color:(this.state.durationPeriod?"white":'')}} id="durationPeriod" value={this.state.durationPeriod} onChange={this.groupInfo}>
													{this.forPeriod()}
													</select>
												</div>
											</div>
										</div>
										{this.state.group_type == 'Program' ?
											<>
											<div className="mdnopadding mdpaddingright w100">
												<div className="form-group"><span className="cover-border cover-border_create"></span>
												  <label className="label">Main Instructor<sup className="start_color">*</sup></label>
													<select className={"input-field fields_back " +(this.state.errorClass_programHost ? 'red-alert' : '')} style={{color:(this.state.programHost?"white":'')}} id="programHost" value={this.state.programHost} onChange={this.changeProgramHost}>
													<option>Not Selected</option>
													{this.forInstructors()}
													</select>
												</div>
											</div>
											<div className="mdnopadding mdpaddingright w100">
												<div className="form-group"><span className="cover-border cover-border_create"></span>
												  <label className="label">Level<sup className="start_color">*</sup></label>
													<select className={"input-field fields_back " +(this.state.errorClass_levelChosen ? 'red-alert' : '')} style={{color:(this.state.levelChosen?"white":'')}} id="levelChosen" value={this.state.levelChosen} onChange={this.groupInfo}>
													{this.state.levels.map((item, i) =>
														<option key={i} value={item}>{item}</option>
													)}
													</select>
												</div>
											</div>
											</>
											:
											<>
											<div className="row rownomargins">
												<div className="col-md-6 mdnopadding mdpaddingright">
													<div className="form-group" >
														<div onClick={e=>{$("#calenderModelStart").attr({'style':'display:block'});}}>
														  <span className=""></span>
														  <label className="label">Start Date<sup className="start_color">*</sup></label>
														  <input type="text" id="startCalenderDate" value = {this.state.startCalender} onChange = {this.groupInfo} className={(this.state.errorClass_Datevalidatior ? "input-field red-alert":"input-field")} placeholder="Pick a date" ref={wh => (this._when = wh)} readOnly/>
														  {this.validator.message('startCalenderDate', this.state.startCalender, 'required')}
														  <div className="when-icon" ></div>
														</div>
													</div>
												</div>
												<div className="col-md-6 mdnopadding mdpaddingright">
													<div className="form-group" >
														<div onClick={e=>{$("#calenderModelEnd").attr({'style':'display:block'});}}>
														  <span className=""></span>
														  <label className="label">End Date<sup className="start_color">*</sup></label>
														  <input type="text" id="endCalenderDate" value = {this.state.endCalender} onChange = {this.groupInfo} className={(this.state.errorClass_Datevalidatior ? "input-field red-alert":"input-field")} placeholder="Pick a date" ref={wh => (this._when = wh)} readOnly/>
														  {this.validator.message('endCalenderDate', this.state.endCalender, 'required')}
														  <div className="when-icon" ></div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-6 mdnopadding mdpaddingright">
												<div className="form-group">
												  <span className=""></span>
												  <label className="label">Reward</label>
												  <input type="text" id = "reward"
												   value = {this.state.reward}
												   maxlength="24"
												   onChange = {(e)=>this.setState({[e.target.id] : e.target.value,errorClass_reward:false,errormsg:''})}
												   className={(this.state.errorClass_reward  ?"input-field red-alert":"input-field")} placeholder="" ref={max => (this._reward = max)}/>
												  {this.validator.message('reward', this.state.reward, 'required|integer')}
												</div>
											</div>
											</>
										}
									</div>
								</div>
								<div className="open_icon_right">
									<img src="/images/add.png" className="px-2 width-60 cursor-pointer" alt="" data-toggle="modal" data-target="#allprevsession" onClick={()=>{this.openAddClasses() }} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') } aria-hidden="true" />
									<div className="tooltip_hold pink_info tp25">
										<div className="tooltip_text">Select Classes</div>
									</div> 
									<div className="inline_bulb" onClick={e=>{this.props.showMainErrorPop('Select classes for this group.', true)}}></div>
								</div>
								<div className="table-responsive no_overflow_tools activity_scroll">
									{ this.state.selectedSessions.length > 0 &&
										<div className="inner_scrool_x_act">
											<div className={"item_group"+ (this.state.group_type == 'Program' ? " item_group_prog" : '')} style={{fontWeight: 'bold'}}>
												<div style={{width: '22px'}}></div>
												<div>Class Name</div>
												<div>Category</div>
												<div>Instructor</div>
												<div>Date</div>
												<div>Duration</div>
												<div>Type</div>
											</div>
											<div className="for_all_activity">
												{ this.state.selectedSessions.map((row, i) =>
													<div  className={(this.state.group_type == 'Program' && row.type == 1) ? 'd-none' : null } key={row.classId+'-'+i} data-rkey={row.classId+i} data-itemkey={i} data-oldarrange={i}>
														<div className={"activity_relative_holder onhover " + (i%2 == 0 ? 'nthclass' : '')}>
														<div className="activityNumDiv">{i + 1}</div>
															<div className={"item_group2"+(this.state.group_type == 'Program' ? " item_group2_prog":'')}>{row.name}</div>
															<div className={"item_group2"+(this.state.group_type == 'Program' ? " item_group2_prog":'')}>{row.category}</div>
															<div className={"item_group2"+(this.state.group_type == 'Program' ? " item_group2_prog":'')}>{row.instructor}</div>
															{this.state.group_type == 'Challenge' ? 
																row.type == 1 ? 
																<>
																	<div className="item_group2">{row.date}</div>
																</> :
																<>
																	<div className="item_group2">
																		<select className={"input-field fields_back for_group_fields " +(this.state.errorClass_DemandDate ? 'red-alert' : '')} style={{color:(row.schedule_date ?"white":'')}} id={"selectedDate_" + i} disabled={this.state.endCalender ? false : true} value={row.schedule_date} onChange={this.changeDemandDate}>
																			{this.state.dateSelects.map((item, i) =>
																				<option key={i} value={item}>{item}</option>
																			)}
																		</select>
																	</div>
																</>

															:
																<>
																	<div className="item_group2_prog">
																		<select className={"input-field fields_back for_group_fields " +(this.state.errorClass_ProgramDays ? 'red-alert' : '')} style={{color:(row.program_day ?"white":'')}} id={"selectedDay_" + i} value={row.program_day} onChange={this.changeProgramDay}>
																			{this.forProgramDays()}
																		</select>
																	</div>
																</>
															}
															<div className={"item_group2"+(this.state.group_type == 'Program' ? " item_group2_prog":'')}>{row.type === 1 ? row.duration : (('0'+Math.floor(row.duration/60)).substr(-2)+':'+('0'+(row.duration-(Math.floor(row.duration/60)*60))).substr(-2))}</div>
															<div className="item_group2 w14">{row.type === 1 ? 'Live' : 'On-demand'}</div>
															<div className="item_group2 w6">
																<div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') } onClick={() => this.removeClass(i)} id={i}><i className="fa fa-minus" aria-hidden="true"></i>
																	<div className="tooltip_hold pink_info">
																		<div className="tooltip_text">Remove</div>
																	</div>
																</div>
																{ this.state.selectedSessions.length > 1 &&
																	<div className="mr-1 bg-circle mr-lg-2 mb-1 pointer no_overflow_tools" onClick={e=>{this.changePlace(i)}} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') }><span><i className="fa reorder_item fa-numbers" aria-hidden="true"></i></span>
																		<div className="tooltip_hold pink_info">
																			<div className="tooltip_text">Change Place</div>
																		</div>
																	</div>
																}
															</div>
														</div>
													</div> )}
											</div>
										</div>
									}
								</div>
								<div className="for_save_create">
									<ClipLoader css={override} size={150} color={"#123abc"} loading={this.state.loading} />
									<div className="create_session_btn" onClick={(e)=> {this.submitForm(e)}}>SAVE & EXIT</div>
								</div>
							</div>
						</div>
					</div>
					
				  {/* VIEW CALENDAR MODAL (START DATE)*/} 
				 
				  <div className="modal cal_modal mt-3" id="calenderModelStart" data-backdrop="">
				  <div className="modal-dialog small_width1">
					<div className="modal-content modalbg m-auto">
					  <div className="modal-header px-4 pt-3 pb-0">
						<h4 className="white modal-title">Choose Start Date</h4>
						<button type="button pr-3" className="close white closepopup" onClick={(e)=>{this.closeCalender(e,1)}}>&times;</button>
					  </div>
					  <div className="modal-body px-1 px-sm-3">
					  <h3>Calender</h3>
						<Calendar
						   onChange={this.onChangeStartCalender}
						   value={this.state.startCalenderDate}
						   minDate={new Date()}
						  // calendarType= "ISO 8601"
						 />
					  <div className="botm_container">
						<div className="row mt-4 mx-0 justify-content-between flex-wrap">
						</div>
					  </div>
					  </div>
						<div className="text-center position-absolute btn_btn1">
						<button type="button" id="startCalender" className="done mt-0" onClick={ (e) => this.setCalenderDate(e, 1) }>done</button>
						</div>
					  </div>
					</div>
				  </div>
				  
				  {/* VIEW CALENDAR MODAL (END DATE)*/} 
				 
				  <div className="modal cal_modal mt-3" id="calenderModelEnd" data-backdrop="">
				  <div className="modal-dialog small_width1">
					<div className="modal-content modalbg m-auto">
					  <div className="modal-header px-4 pt-3 pb-0">
						<h4 className="white modal-title">Choose End Date</h4>
						<button type="button pr-3" 
						className="close white closepopup" onClick={(e)=>{this.closeCalender(e, 2)}}>&times;</button>
					  </div>
					  <div className="modal-body px-1 px-sm-3">
					  <h3>Calender</h3>
						<Calendar
						   onChange={this.onChangeEndCalender}
						   value={this.state.endCalenderDate}
						   minDate={new Date()}
						  // calendarType= "ISO 8601"
						 />
					  <div className="botm_container">
						<div className="row mt-4 mx-0 justify-content-between flex-wrap">
						</div>
					  </div>
					  </div>
						<div className="text-center position-absolute btn_btn1">
						<button type="button" id="endCalender" className="done mt-0" onClick={ (e) => this.setCalenderDate(e, 2) }>done</button>
						</div>
					  </div>
					</div>
				  </div>
				  
				   {/* select sessions */}
				  <div className="modal pt-3 pt-md-4" id="allprevsession">
				  <div className="modal-dialog modal-dialog-centered">
					<div className="modal-content bg-transparent">
					  <div className="modal-body pt-0 py-3 modalbg px-4 radius-8">
						<button type="button" className="close white closepurple translatX-Y session_list_close" data-dismiss="modal"><span>x</span></button>
						<div className="modal-title headerborder pb-3 px-4 mx-3 session_list_title">Class List</div>
						<div className="list_section_hold">
						{this.state.group_type == 'Challenge' && <div className={ "list_section " + ( this.state.sessionListMain ? "list_section_active" : "" ) } onClick={e=>{this.changeSectionComp(e, 1)}}>Live Sessions</div>}
							{this.state.group_type == 'Challenge' ? <div className={ "list_section_right " + ( this.state.onDemandList ? "list_section_active" : "" ) } onClick={e=>{this.changeSectionComp(e, 2)}}>On-demand</div>
							: <div  className={ "list_section_right " + (this.state.group_type == 'Program' ? "list_section_active" : null) } onClick={e=>{this.changeSectionComp(e, 2)}}>On-demand</div> }
						</div>
						<div className="main_input_section">
							<div className="flex-grow-1 input_field_container py-0 radius-8 main_section_search">
								<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
									<div className="col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center">
										<input type="text" className="form-control" placeholder="Search for instructor and category" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
									</div>
									<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-8' : ' col-xl-6')}>
										{/* <div className="">
											<div className="px-4 d-flex justify-content-end align-items-center">				
												<div className="custom-control custom-checkbox mb-0">
													<input type="checkbox" className="custom-control-input" id="customCheck1" value="false" checked={this.state.customCheck1} onChange={this.customChecked} name="example1" />
													<label className="custom-control-label" htmlFor="customCheck1"><p className="pl-2 p-top-3 mb-0">ADVANCED</p></label>
												</div>
											</div>
										</div> */}
									</div>
								</div>
								<div className="right-small-box" onClick={this.searchByInput}>
									<img src="/images/search_loop.png" alt="search" />
								</div>
							</div>
						</div>
						<div className="billing_table">
							{ !this.state.isLoadingSessions && this.state.sessions.length > 0 &&
								  <table className="table">
									<thead>
									  <tr>
										<th scope="col">Class Name
											<div onClick={ (e) => this.sortArrays('ses_name', this.state.sessions, 'sessions')} className="reports_sort_icon"></div>
										</th>
										<th scope="col">Category
											<div onClick={ (e) => this.sortArrays('interest', this.state.sessions, 'sessions')} className="reports_sort_icon"></div>
										</th>
										<th scope="col">Instructor
											<div onClick={ (e) => this.sortArrays('host_name', this.state.sessions, 'sessions')} className="reports_sort_icon"></div>
										</th>
										<th scope="col">Date
											<div onClick={ (e) => this.sortArrays('date_time', this.state.sessions, 'sessions')} className="reports_sort_icon"></div>
										</th>
										<th scope="col">Duration</th>
										<th scope="col"></th>
									  </tr>
									</thead>
									{ this.state.sessions.length > 0 &&
										<tbody id="online-user-list">
										{sessionsToDisplay}                
										</tbody>
									}
								  </table>
							}
							{ !this.state.isLoadingSessions && this.state.sessions.length === 0 &&
								<div className="empty_template pt20">No results found.</div>
							}
						</div>
						<div className="text-center">
							{ this.state.shownLength != this.state.dataLength && !this.state.isLoadingSessions &&
								<div className="dashboard_load_hold">
									<div className="custom_btn1 px-5 mt-3 load_more_main_button"><div onClick={(e) => this.loadMoreUpcoming()}>load more</div></div>
								</div>
							}
						</div>
					  </div>
					  <div className="text-center sticky_button">
						<button type="button" className="custom_btn1 px-5 mt-3 stickybutton" onClick={this.getSelectedClasses} data-dismiss="modal">ok</button>
					  </div>
					</div>
				  </div>
				</div>  
				<div className="modal pr-0" id="changeActivityPlace" aria-modal="true">
					<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
								<div className="closePopup mainclose_pos15" onClick={this.closeChangePlace}></div>
								<p className="mb-0 py-4 white text-center">Change place to</p>
								<div className="centDiv">
									<input 
									  className="changePlaceInp"
									  type="text"
									  id = "changePlaceNew" 
									  maxlength="4"
									  value ={this.state.changePlaceNew} 
									  onChange = {(e)=> this.enterInputValue(e.target.value)}
									/>
								</div>
							</div>
							<div className="text-center"> 
								<button type="button" className="col-4 custom_btn1 mt-3" onClick={this.saveChangePlace}>SAVE</button>
							</div>
						</div>
					</div>
				</div>
				
				
				
				<div className="main_loader">
					<div className="main_loader_inner"></div>
				</div>
				
			</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(GroupCreation);
