import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { browserHistory} from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
import Sortable from 'react-sortablejs';
import uniqueId from 'lodash/uniqueId';
class SessionShopingList extends Component {
  
  constructor(props) {
    super(props);
	this.scriptValidate = new SimpleReactValidator(); 
}

  render() {
    return (<div>
			 {/* Shopping List Start */}
				<div className="gray-box2 no-border-radius black_back_imp no_overflow_tools"> 
					<div className="session">
						<h3 className="info inline_title"><img src="/images/shopping-icon.png" className="mr-3 mb-2" />Shopping List</h3>
						<div className="col-md-4 inline_title second_tit_shopp">
							<Link to ="" className="pick cursor" onClick={this.props.openScriptPop}><img src="/images/picking.png" className="mr-2" alt = '#'/> Add Products from Script</Link>
						</div>
					</div>
				  <div className="px-4 pb-3">
					<div className="row">
							<div className="for_form_shop">
								<div className="inline_shop_widder">
									<div className="form-group mt-3 mb-1 shopping_input_hold">
										<span className="cover-border"></span>
										<label className="label">Item Name</label>
										<input type="text" className="input-field" id="itemNameShop" value={this.props.state.itemNameShop} onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} />
									</div>
								</div>
								<div className="inline_shop_smaller">
									<div className="inline_quantity left_quant math_signs" onClick={ () => this.props.changeQuantity('-') }>-</div>
									<div className="inline_quantity main_quant">{this.props.state.shopQuantity}</div>
									<div className="inline_quantity right_quant math_signs" onClick={ () => this.props.changeQuantity('+') }>+</div>
								</div>
								<div className="inline_shop_widder price_widder">
									<div className="form-group mt-3 mb-1 shopping_input_hold">
										<span className="cover-border"></span>
										<label className="label">Item Price</label>
										<input type="text" className="input-field txt_center" id="itemNotesPrice" value={this.props.state.itemNotesPrice} onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} />
									</div>
								</div>
								<div className="inline_shop_widder paddl30 shopping_item_note">
									<div className="form-group mt-3 mb-1 shopping_input_hold">
										<span className="cover-border"></span>
										<label className="label">Item Notes</label>
										<input type="text" className="input-field" id="itemNotesShop" maxLength="250" value={this.props.state.itemNotesShop} onChange = {(e)=> this.props.updateStateFromChild({[e.target.id]:e.target.value})} />
									</div>
								</div>
								{ this.props.state.editShopIndex == -1 && 
									<div className="add_shop" onClick={this.props.addNewShop}></div>
								}
								{ this.props.state.editShopIndex > -1 && 
									<div>
										<div className="add_shop edit_shop" onClick={this.props.addNewShop}></div>
										<div className="add_shop close_edit" onClick={this.props.closeEdit}></div>
							</div>
								}
					</div>
					  </div>
					</div>
					{ this.props.state.addedShopList.length > 0 &&
						<div className="for_shop_list equipment_inner_hold" onMouseLeave={this.props.handleDraggEnd}>
							<div className="shopping_inner_container">
								<div className="shop_titles">Item Name</div>
								<div className="shop_titles">Quantity</div>
								<div className="shop_titles">Item Notes</div>
								<div className="shop_titles">Price</div>
								<div className="shop_titles"></div>
								<div onMouseUp={this.props.handleDraggEnd}>
								{this.props.state.addedShopList.map((shop, i)=>
									<div className="main_all" key={shop.id_main} data-itemkey={i} data-oldarrange={i}>
										<div className="one_row_shop">
											<div className="shop_titles white_clr">
												{ shop.Link.length <= 15 &&
													<span>{shop.Link}</span>
												}
												{ shop.Link.length > 15 &&
													<span>{shop.Link.slice(0, 15)}...</span>
												}
											</div>
											<div className="shop_titles white_clr paddl25">{shop.Quantity}</div>
											<div className="shop_titles white_clr">
												{ shop.itemNote.length == 0 &&
													<span>-</span>
												}
												{ shop.itemNote.length > 0 && shop.itemNote.length <= 15 &&
													<span>{shop.itemNote}</span>
												}
												{ shop.itemNote.length > 15 &&
													<span>{shop.itemNote.slice(0, 15)}...</span>
												}
											</div>
											<div className="shop_titles white_clr">${shop.price}</div>
											<div className="shop_titles">
												<div className="font-icn">
													 <div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><i className="fa fa-pencil" onClick={ () => this.props.editShop(i) } aria-hidden="true" ></i>
														<div className="tooltip_hold pink_info">
															<div className="tooltip_text">Edit</div>
														</div>
													 </div>
													 <div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><i className="fa fa-copy" onClick={ () => this.props.copyShop(i) } aria-hidden="true"></i>
														<div className="tooltip_hold pink_info">
															<div className="tooltip_text">Duplicate</div>
														</div>
													 </div>
													 <div className="bg-circle mr-1 mr-lg-2 mb-1 pointer no_overflow_tools" onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><i className="fa fa-minus" onClick={ () => this.props.removeShop(i) } aria-hidden="true"></i>
														<div className="tooltip_hold pink_info">
															<div className="tooltip_text">Remove</div>
														</div>
													 </div>
													 <div className="mr-1 bg-circle mr-lg-2 mb-1 pointer no_overflow_tools" onMouseDown={this.props.handleDraggStart} onMouseMove={this.props.handleDraggitem} onMouseOver={ (e) => this.props.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.props.openInfoPopUp(e, 'leave') }><i className="fa fa-bars"  aria-hidden="true"></i>
														<div className="tooltip_hold pink_info">
															<div className="tooltip_text">Change Order</div>
														</div>
													 </div>
												</div>
											</div>
										</div>
									</div>
									)}
								</div>
							</div>
						</div>
					}
				  
				</div>
				{/* Shopping List End  */}
		 </div>
    );
  }
}

export default SessionShopingList;
