import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';
import { Link } from 'react-router';
import axios from "axios";
import {  browserHistory} from 'react-router'
import { Form, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import { participateCheckUser } from '../../util/participateCheckUser';
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import { updatesse } from '../../util/updatesse';
import ChargingSession from '../home/ChargingSession';
import classnames from "classnames";
import Login from '../auth/Login';
import { setCookie } from '../../util/cookie';
import moment from 'moment';
import customProtocolCheck from "custom-protocol-check";
import { joinSessionValidate } from '../../util/joinSessionValidate';
import SupportContainer from '../home/SupportContainer';
import ParticipantSignedUp from '../home/participantSignedUp';
import moment1 from 'moment-timezone';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import { getOnDemandDuration } from '../../util/formatDateFunctions';

//import a from '../../../../api/v1/'
class participentSignup extends Component {
  constructor(props) {
  super(props);
  this.state={
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    password:'',
    rePassword:'',
    verify:false,//false
    signup:false,//false
    validateEmail:true,//true
    sms:false,
    byEmail:true,
    message:'',
    passwordText:'',
    otp:'',
    msg:'',
    userType:'',
    otperror:'',
    errorClass_FirstName:false,
    errorClass_LastName:false,
    errorClass_Phone:false,
    errorClass_Email:false,
    errorClass_Password:false,
    errorClass_RePassword:false,
    redirectToRoute:'',
    userName:'',
    participantData:'',
    participantValidate:true,//true
    validateEmail:false,
    inValidAccess:true,
    sessionType:'',
    typeSession:'',
    urlPath:'',
    urlElectron:'',
	appDownload:'',
	platformStatus:true,
	allowSS:false,
	parentRecrId:false,
	sessionDetailsData:[],
	checkedBoxes: [],
	sessionIdArr:[],
	isChecked: false,
	allChecked: false,
	openShopList: false,
	shoppingList: [],
	totalShopPrice: 0,
	isChargingSessionIncluded: false,
	memberChargeForSession: '',
	nonMemberChargeForSession: '',
	sessionHostFirstName: '',
	sessionHostLastName: '',
	finalChargeForSession: '',
	sessionData: '',
	totalSessionsMemberPrice: 0,
	totalSessionsNonMemberPrice: 0,
	totalSessions : 0,
	sessionsPicked: [],
	openEquList: false,
	equipmentList: [],
	showHelpPopup: false,
	passMinError: false,
	specialSymbol: false,
	numberContain: false,
	letterContain: false,
	errors: {},
	mainUserData: {},
	channelWaiverUrl: '',
	mainUrlView: '',
	hostChannelId: 0,
	hostSessionId: 0,
	userMainId: 0,
	regUserId: 0,
	loginAgree: true,
	registerAgree: false,
	showAgreePop: 0,
	user_selected : 'existing',
	waiverEndDate: new Date(),
	loginOpenFromExisting : true,
	ondemandlink: '',
	electronlink: '',
	both_show: true,
	showWindowsApp: true,
	deepLinkButton: 'Web App',
	logedUser: '',
	tryWithOtherAccount: false,
	logedUserAlreadyParticate: false,
	logedUserNowParticate: false,
	sessionStartTimeHigher15Min: true,
	passwordEye: false,
	passwordEyeRepeat: false,
	email_error_status : false,
	logedUserFirstName: '',
	addAfter: '',
	fromChannelToSessionLink: '',
	passwordFromLogin: '',
	userHostThisSession: false,
	supportPageName: 'Class Sign Up',
	supportPageApi: '/sessionSignUp',
	userTypeFaqs: 2,
	userSignedUp: false,
	loggedUserData: {},
	sessionChannelName: '',
	isHost: false,
	sessionName: '',
	privateRecMsg1: '',
	privateRecMsg2: '',
	privateRecMsg3: '',
	signedSessionsIds: [],
	sessionLinkLoginTry: false,
	justLogin: false,
	delayLoader: false,
	registerNoSession: false,
	isMarketplaceSession: 0,
  }
	this.validator = new SimpleReactValidator({autoForceUpdate: this});
	this.otpValidation = new SimpleReactValidator();
	this.emailValidate  = new SimpleReactValidator();

	this.OnCheckChange = this.OnCheckChange.bind(this);
	this.participateCheckUser = participateCheckUser.bind(this);
	//console.log('constructor',this.state.phone)
	this.updatesse = updatesse.bind(this);
	this.setCookie = setCookie.bind(this);
	this.joinSessionValidate = joinSessionValidate.bind(this);
	
	this.getSessionDateUtil = getSessionDate.bind(this);
	this.getSessionTimeUtil = getSessionTime.bind(this);
	this.getOnDemandDuration = getOnDemandDuration.bind(this);
}
 
componentDidMount(){
	if ( window.location.hash && window.location.hash.length > 10 )
		{
			var channelLink = atob(window.location.hash.substr(1));
			if ( channelLink.indexOf('virdio.com') > 0 )
				{
					this.setState({ fromChannelToSessionLink : channelLink });
				}
		}
	
	if ( document.referrer == '' || document.referrer.indexOf('virdio.com') < 0 )
		{
			this.setState({
				addAfter: 'ref=signup'
			});
		}			
	
	setTimeout( ()=>{
		
		if (this.props.state.od_status && this.props.state.od_status === 1 )
			{
				this.setState({
					sessionType : 'On Demand',
					demandSessionId : this.props.state.od_session_info[0].ondemandId,
					sessionChannelName: this.props.state.od_session_info[0].channelName,
					sessionStartTimeHigher15Min: false,
				})
				console.log(this.props.state.od_session_info);
				
				if ( localStorage.getItem('userData') )
					{
						var loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
						var token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
						var odId = this.props.state.od_session_info[0].ondemandId;
						var user = JSON.parse(localStorage.getItem('userData'));
						
						var isHost = false;
						if ( this.props.state.od_session_info[0].hostId === loginId ) { isHost = true; }
						
						var checkSessionValidate = this.joinSessionValidate(true, user.data.responseData.id, user.data.responseData.token, odId);
						this.setState({ 
								userType: 2,
								urlPath: checkSessionValidate.urlPathVar,
								urlElectron: checkSessionValidate.urlElectronVar,
								deepLinkButton: checkSessionValidate.deepLinkButton,
								loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
								userSignedUp: true,
								isHost: isHost
						});

					}
				
				
			}
		else
			{
				  if(localStorage.getItem('verifyOtpEmail')){ 
					this.setState({
					  firstName:localStorage.getItem('verifyOtpfirstname'),
					  lastName:localStorage.getItem('verifyOtplastname'),
					  email:localStorage.getItem('verifyOtpEmail'),
					  //allowSS:localStorage.getItem('allowSS'),
					  
					  
					  passwordText:'********',
					  participantValidate:false,//true
					  verify:true,//false
					  validateEmail:false,//true
					},()=>{
					   localStorage.removeItem('verifyOtpfirstname');
					  localStorage.removeItem('verifyOtplastname');
					  localStorage.removeItem('verifyOtpEmail');
					  $("#email_verification").attr({'style':'display:block'});

					});
					return;
				  }
				  
				  

				  if(osname=="Windows" || osname=="Linux" || osname=="Ubuntu" || osname=="Mac OS"){
					if((ff==true) && (parseFloat(fbrowserversion) < 72)){
					  console.log('==========================================');
					  this.setState({
						platformStatus:false
					  })
					}
					  if((chrome==true) && (parseFloat(fbrowserversion) < 79)){
					  this.setState({
						platformStatus:false
					  })
					  }
					  if((edge==true) && (parseFloat(fbrowserversion) < 78)){
					  this.setState({
						platformStatus:false
					  })
					  }
					}

					this.getSessionInfoFull(false);

					
					if ( localStorage.getItem('channelEmail') )
					 {
							let email_var = localStorage.getItem('channelEmail');
							email_var = email_var.replace(/[&\/\\#,+()$~%'":*?!=<>{}]/gi, '');
							
						this.setState({
								email: email_var
						})
						
						localStorage.removeItem('channelEmail');
						}
			}
	   if ( localStorage.getItem('userData') )
		 { 


			this.setState({
					logedUser: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName,
					logedUserFirstName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName,
					loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData
			});
		 }
	}, 0);
}

updateStateFromChild = (newState) => {
	if ( this.state.od_status === 0 )
		{
			console.log(newState);
			localStorage.removeItem('sessionNewData');
			localStorage.removeItem('tempSession');
			localStorage.removeItem('secondStep');
			
			$("#registration_popup2").attr({'style':'display:block'});
		}
	else
		{
			var a =[];
			var pickedSessions = [];
			var checkboxes = document.querySelectorAll('.recurring_session_detail input[type="checkbox"]:checked');

			for (var checkbox of checkboxes)
				{
					if ( checkbox.value !== "on" )
						{
							a.push(Number(checkbox.value));
						}
				}
			if ( a.length > 0 )
				{
					if ( this.state.participantData && this.state.participantData.sessionDetails )
						{
							let currentArray = this.state.participantData.sessionDetails;
							for ( var i = 0; i<a.length; i++ )
								{
									var ses_id = a[i];
									for ( let c = 0; c<currentArray.length; c++ )
										{
											if ( currentArray[c]['id'] == ses_id )
												{
													currentArray[c]['alreadyMember'] = true;
												}
										}
								}
						}
				}				
			this.setState(newState);
		}	
}

getSessionInfoFull = (loaderhide) => {


   let a =localStorage.getItem('sessionId');
   //a=1203//1201//1123//1065//1033//1016//995//822//821//1211//818;//721
   var cancelSession;
   let message;
   
	if ( localStorage.getItem('userData') )
		{
			if ( JSON.parse(localStorage.getItem('userData')) )
				{
					let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
					if ( token ) { axios.defaults.headers.common['Authorization'] = token; }
				}
		}
	
	if ( !a )
		{
			this.setState({
								msg: 'For security reasons your Sign Up process has timed out, please close this browser tab and click on the Sign Up link again',
								redirectToRoute:'/login',
								inValidAccess:false
								},()=>{	
								$("#existingUserModel").attr({'style':'display:block'})
								/*
								setTimeout(() => {
									existingUserModel").attr({'style':'display:none'})
									browserHistory.push('/login');
							  }, 5000);
							  */
							  });
		}

    if ( !loaderhide ) { $('.main_loader').attr({'style':'display:flex'}); }
	
	if ( localStorage.getItem("userData") )
		{
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
		}
    axios.get(process.env.REACT_APP_NAME+"/api/v1/session/"+a+"/individualsessiondetailForAdmin")
      .then(res => {
          let expireStatus;
		  let hideRightPart = false;
		  $('.main_loader').hide();
		  
		  if ( !res.data.responseData.sessionData )
			{
				this.setState({
									msg: 'For security reasons your Sign Up process has timed out, please close this browser tab and click on the Sign Up link again',
									redirectToRoute:'/login',
									inValidAccess:false
									},()=>{
									
									$("#existingUserModel").attr({'style':'display:block'})
									/*
									setTimeout(() => {
									  $("#existingUserModel").attr({'style':'display:none'})
									  browserHistory.push('/login');
								  }, 5000); */
								  });
								  
				return false;
			}


		if ( res && res.data && res.data.responseData && res.data.responseData.isMarketplaceChannel )
			{
				this.setState({
					isMarketplaceSession: res.data.responseData.isMarketplaceChannel
				}, ()=>{
					if ( this.state.isMarketplaceSession == 1 )
						{
							this.setState({
								both_show: false,
							});
						}
				});
			}
				
		if ( res && res.data && res.data.responseData && res.data.responseData.sessionData )
			{
				if ( res.data.responseData.sessionData.allowSS === 0 && parseInt(res.data.responseData.sessionData.parentRecrId) > 0 )
					{
						var mainthis = this;
						setTimeout(function(){ 
							$("#selectAllCbx").prop("checked", true);
							var check = $("#selectAllCbx")[0];
							mainthis.CheckAllBox(check, true);
						}, 500);
					}
			}
		
		// Refresh token
		if ( res && res.data && res.data.responseData && res.data.responseData.sessionData && res.data.responseData.sessionData.tokenData )
			{
				var userData = JSON.parse(localStorage.getItem('userData'));
				userData.data.responseData['token'] = res.data.responseData.sessionData.tokenData[0];
				userData.data.responseData['tokenExpire'] = res.data.responseData.sessionData.tokenData[1];
				
				this.setCookie(process.env.REACT_APP_COOKIE_ID, userData.data.responseData['id'], userData.data.responseData['tokenExpire']);
				this.setCookie(process.env.REACT_APP_COOKIE_TOKEN, userData.data.responseData['token'], userData.data.responseData['tokenExpire']);
				
				localStorage.setItem('userData', JSON.stringify(userData));
			}
		// Refresh token
		
		this.setState({sessionChannelName: res.data.responseData.sessionData.channelName});
			
		var mainthis = this;
		mainthis.updatesse('channelId', res.data.responseData.sessionData.sessionChannelId, function ()
			{
				var url = window.location.href;
				if ( url.toLowerCase().indexOf('/participantsignup') < 0 ) { return false; }
				
				mainthis.getSessionInfoFull(true);
			});
		
		console.log(res.data.responseData.sessionData.id);
		localStorage.setItem("sessionIdData", JSON.stringify(res.data.responseData.sessionData.id));
		if(res.data.responseData.sessionData.parentRecrId == null)
			{
				if(new Date().getTime()>new Date(res.data.responseData.sessionData.sessionEndDate).getTime() || res.data.responseData.sessionData.isCompleted>0	||(res.data.responseData.sessionData.maxAttendee<res.data.responseData.sessionData.totalSignUp) )
					{
						console.log('----tt111----');
					  //alert('dsds');
					  // localStorage.removeItem('sessionId');
					  //  localStorage.removeItem('scheduleDate');
					  //  localStorage.removeItem('sessionEndDate');
					  //  localStorage.removeItem('duration');
					  expireStatus=false
					  if(res.data.responseData.sessionData.maxAttendee<res.data.responseData.sessionData.totalSignUp){
						  if ( !res.data.responseData.sessionData.alreadyMember )
							{
								 hideRightPart = true;
							}
						message=res.data.responseData.sessionData.hostFirstName + ", has set a limit on the number of Members in their class, which has been reached.  You are welcome to participant in other classes hosted by "+ res.data.responseData.sessionData.hostFirstName +" or contact "+ res.data.responseData.sessionData.hostFirstName +" for additional options"
					  }else{
					  hideRightPart = true;
					  this.setState({ both_show : false }); 
					  message="This class has already concluded. Please contact "+ res.data.responseData.sessionData.hostFirstName+" for upcoming classes."
					 
					  }

					  if(res.data.responseData.sessionData.isCompleted===2 || res.data.responseData.sessionData.sessionStatus===2){
						hideRightPart = true;
						this.setState({both_show : false});
					   message="This class has been cancelled by "+res.data.responseData.firstName+". You are welcome to participate in other classes hosted by "+ res.data.responseData.firstName +" or contact "+ res.data.responseData.firstName+" for additional options"
					  }
					  
						/* let awk=[];
						let scheduleDate;
						//if(res.data.responseData.sessionData.sessionDetails.length===0){
						for(let j=0;j<res.data.responseData.sessionData.sessionDetails.length;j++){
							awk.push({
							id:res.data.responseData.sessionData.sessionDetails[j].id
							
							})	
							
						} */
					  
					}
				else
					{
						expireStatus=true
					}
			}
		
		if(res.data.responseData.sessionData.parentRecrId != null){
			hideRightPart = false;
			let len = res.data.responseData.sessionData.sessionDetails.length;
			let lastScheduleDate = res.data.responseData.sessionData.sessionDetails[len-1].sessionEndDate;
			let lastisCompleted = res.data.responseData.sessionData.sessionDetails[len-1].isCompleted;
			
			if(new Date().getTime()>new Date(lastScheduleDate).getTime() || lastisCompleted>0	||(res.data.responseData.sessionData.maxAttendee<res.data.responseData.sessionData.totalSignUp) ){
			  
			  expireStatus=false
			  if(res.data.responseData.sessionData.maxAttendee<res.data.responseData.sessionData.totalSignUp){
				  if ( !res.data.responseData.sessionData.alreadyMember )
					{
						 hideRightPart = true;
					}
				message=res.data.responseData.sessionData.hostFirstName + ", has set a limit on the number of Members in their class, which has been reached.  You are welcome to participant in other classes hosted by "+ res.data.responseData.sessionData.hostFirstName +" or contact "+ res.data.responseData.sessionData.hostFirstName +" for additional options"	
			 }else{
				hideRightPart = true;
			   message="This class has already concluded. Please contact "+ res.data.responseData.sessionData.hostFirstName+" for upcoming classes.";
					
			  }

			  if(res.data.responseData.sessionData.isCompleted===2 || res.data.responseData.sessionData.sessionStatus===2){
				hideRightPart = true;
				message="This class has been cancelled by "+res.data.responseData.firstName+". You are welcome to participate in other classes hosted by "+ res.data.responseData.firstName +" or contact "+ res.data.responseData.firstName+" for additional options"
			  }
			  
			}else{
			  expireStatus=true
			  
			}
			if ( res.data.responseData.sessionData.sessionDetails && res.data.responseData.sessionData.sessionDetails.length > 0 )
				{
					let signedSessionsIdsTemp = [];
					for (var j = 0; j < res.data.responseData.sessionData.sessionDetails.length; j++)
						{
							if ( res.data.responseData.sessionData.sessionDetails[j]['alreadyMember'] && new Date(res.data.responseData.sessionData.sessionDetails[j]['sessionEndDate']).getTime() > new Date().getTime() )
								{
									signedSessionsIdsTemp.push(res.data.responseData.sessionData.sessionDetails[j]['id']);	
								}
						}
					let ids = [];
					if ( signedSessionsIdsTemp.length > 0 )
						{
							ids.push(signedSessionsIdsTemp);
						}
					else
						{
							ids = signedSessionsIdsTemp;
						}
					this.setState({
							signedSessionsIds : ids
						});
				}
		}
		console.log('---expireStatus----',expireStatus);
        
		console.log('-----select All------', res.data.responseData.sessionData.allowSS)
	    if(res.data.responseData.sessionData.allowSS !=1){			
			this.setState({isChecked: true })
		}   
		
		let shoppingListArr = res.data.responseData.sessionData.shoppingList;

		$.each(shoppingListArr, function( a, b ) {
			b['total'] = 0;
			b['total'] = b['total'] + ( b['quantity'] * b['price'] );
		});
		
		var isHost = false;
		if ( localStorage.getItem('userData') )
			{
				if ( res.data.responseData.sessionData.hostId === JSON.parse(localStorage.getItem('userData')).data.responseData.id ) { isHost = true; }
			}
		
        //console.log(this.getSessionDate(new Date(res.data.responseData.sessionData.sessionEndDate)))
        this.setState({
		  //sessionDetailsData:awk,
          participantData:res.data.responseData.sessionData,
          inValidAccess:expireStatus,
          redirectToRoute:'/',
          msg:message,
          typeSession:res.data.responseData.sessionData.charges===0?"public":"private",
		  allowSS:res.data.responseData.sessionData.allowSS ==1 ?true:false,
		  parentRecrId:res.data.responseData.sessionData.parentRecrId ==null ?false :true,
		  shoppingList: shoppingListArr,
		  equipmentList: res.data.responseData.sessionData.equipmentList,
		  hostChannelId: res.data.responseData.sessionData.sessionChannelId,
		  hostSessionId: res.data.responseData.sessionData.id,
		  channelWaiverUrl: res.data.responseData.channelSettings.waiverLink,
		  showAgreePop: res.data.responseData.channelSettings.showWaiver,
		  waiverEndDate: res.data.responseData.channelSettings.waiverEndDate,
		  logedUserAlreadyParticate: res.data.responseData.sessionData.parentRecrId != null ? false : res.data.responseData.sessionData.alreadyMember,
		  userSignedUp: res.data.responseData.sessionData.parentRecrId != null ? false : res.data.responseData.sessionData.alreadyMember,
		  isHost: isHost
        }
        ,()=>{
			
			let waiver_link = 'https://docs.google.com/gview?url='+encodeURIComponent(this.state.channelWaiverUrl)+'&embedded=true';
			$('.waiver_iframe').attr('src', '');
			setTimeout(function(){ $('.waiver_iframe').attr('src', waiver_link); }, 1000);
			$("#waiverAgree").attr({'style':'display:block; height:1px'});
			console.log('WAIVER URL');
			console.log(this.state.channelWaiverUrl);
					
			if(res.data.responseData.sessionData.parentRecrId != null)
				{
					if ( this.state.participantData && this.state.participantData.sessionDetails && this.state.participantData.sessionDetails.length > 0 )
						{
							for (var j = 0; j < this.state.participantData.sessionDetails.length; j++)
								{
									if ( this.state.participantData.sessionDetails[j]['alreadyMember'] && new Date(this.state.participantData.sessionDetails[j]['sessionEndDate']).getTime() > new Date().getTime() && (new Date(this.state.participantData.sessionDetails[j]['scheduleDate']).getTime()-15*60*1000) < new Date().getTime() && this.state.participantData.sessionDetails[j]['isCompleted'] == 0 )
										{
											this.setState({
												sessionStartTimeHigher15Min : false
											});
										}
									/* console.log(this.state.participantData.sessionDetails[j]['alreadyMember']);
									console.log(this.state.participantData.sessionDetails[j]['sessionEndDate']); */
									if ( this.state.participantData.sessionDetails[j]['alreadyMember'] && new Date(this.state.participantData.sessionDetails[j]['sessionEndDate']).getTime() > new Date().getTime() )
										{
											this.setState({
												logedUserAlreadyParticate : true,
												//userSignedUp: true
											}, () => {
												if ( !this.state.sessionStartTimeHigher15Min )
													{
														this.setState({
															userSignedUp: true
														});
													}
											});
										}
								}
						}
						
				}
			else
				{
				
					if( (new Date(this.state.participantData.scheduleDate).getTime()-15*60*1000) < new Date().getTime() )
						{
							this.setState({
								sessionStartTimeHigher15Min : false
							});
						}
							
				}
				
			
			setTimeout( ()=>{
			if ( this.state.logedUserAlreadyParticate && ((new Date(this.state.participantData.scheduleDate).getTime()-15*60*1000) < new Date().getTime()))
				{
					let userId;
					let userToken;
					if ( localStorage.getItem("userData") )
						{
							userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
							userToken = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
						}
	
					var isHost = false;
					if ( this.state.participantData.hostId === userId ) { isHost = true; }
					 
					var checkSessionValidate = this.joinSessionValidate(isHost, userId, userToken, null);
					this.setState({ 
							urlPath: checkSessionValidate.urlPathVar,
							urlElectron: checkSessionValidate.urlElectronVar,
							deepLinkButton: checkSessionValidate.deepLinkButton
					}); 

				}
			}, 0);
          }
        )
		
		
		
		this.calculateTotal();
		console.log('--------------------STATE', this.state.both_show);
      }).catch(err =>{
		  
		  $('.main_loader').hide();
       console.log(cancelSession,"=============",err);
      if(!err.response){

      }
    else{if(err.response.data.responseCode===417){
      this.setState({
        msg:err.response.data.errorData,
        redirectToRoute:'/login',
        inValidAccess:false
        },()=>{
        
        $("#existingUserModel").attr({'style':'display:block'})

		/*
        setTimeout(() => {
          $("#existingUserModel").attr({'style':'display:none'})
          browserHistory.push('/login');
      }, 5000);
      */
	  });
      }
	  else if ( err.response.data.responseCode == 400 )
			{
				let checkLogin = JSON.parse(localStorage.getItem('userData'));
				if ( checkLogin )
					{
						localStorage.removeItem('userData');
						localStorage.removeItem('channelId');
						localStorage.removeItem('groupId');
					}
				
				this.setState({
									msg: 'For security reasons your Sign Up process has timed out, please close this browser tab and click on the Sign Up link again',
									inValidAccess:false
									},()=>{
									
									$("#existingUserModel").attr({'style':'display:block'})

									/*
									setTimeout(() => {
									  $("#existingUserModel").attr({'style':'display:none'})
									  browserHistory.push('/login');
								  }, 5000);
								  */
								  });
				return false;
			}
    }
      })
	  
	  
  
  }
  
  getSessionDateNew=(date1)=> {
	let ab = new Date(date1).getTime();
	let day=new Date(ab).getDate();
	let day1=new Date(ab).getDay();
	let month=(new Date(ab).getMonth())+1;
	var Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	let year=new Date(ab).getFullYear();
	if ( month < 10 ) { month = '0' + month }
	
	let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	
	let sessionDate= weekday[day1] + ' ' + Months[(new Date(ab).getMonth())]+' '+day + ', '+ year;
	return sessionDate; 
}
  
  getReccuringDateNew=(date1)=> {
	let ab = new Date(date1).getTime();
	let day=new Date(ab).getDate();
	let day1=new Date(ab).getDay();
	let month=(new Date(ab).getMonth())+1;
	var Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	let year=new Date(ab).getFullYear();
	if ( month < 10 ) { month = '0' + month }
	
	let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	
	let sessionDate= weekday[day1] + ', ' + Months[(new Date(ab).getMonth())]+' '+day;
	return sessionDate; 
}
  
  getSessionDate=(date1)=>{
    let ab = new Date(date1).getTime();
    let day=new Date(ab).getDate();
    let month=(new Date(ab).getMonth())+1;
    let year=new Date(ab).getFullYear();
    let sessionDate= month+'/'+day+'/'+year;
    return sessionDate; 
   }
   getSessionTime = (date1)=>{
    let hour=new Date(date1).getHours();
    let minute = new Date(date1).getMinutes();
    let sessionTime= hour+':'+minute;
    if(minute<10){
      minute='0'+minute
    }
    
    if(hour<12){
    if(hour<10){	
    sessionTime ='0'+hour+':'+minute+" " +'AM'
    }else{
    sessionTime=hour+':'+minute+" " +'AM'
    }
    return sessionTime ;
    }
    if(hour>12){
    if((hour-12)<10){
      sessionTime='0'+(hour-12)+':'+minute+" " +'PM'	
    }else{
    sessionTime=(hour-12)+':'+minute+" " +'PM'
    }	
    return sessionTime ;
    }
    if(hour==12 && minute>=0){
    sessionTime=hour+':'+minute+" " +'PM'	
    return sessionTime ;
    }    
    } 
sessionInfo=(e)=>{
  this.setState({
    [e.target.id]:e.target.value
  })
}

continuePay=(e)=> {
	if ( this.state.user_selected === "existing" ) { this.checkIfUserHasWaiver(this.state.userMainId, false, 'login'); }
	
	localStorage.setItem('verifyEmailID', this.state.email);
	
	 this.setState({
        log_or_signup: true
        },()=>{
	$("#chargePopup").attr({'style':'display:none'})
	$("#sessionPay").attr({'style':'display:block'})
	$('.hidden_div_sign').trigger('click');
	});
}
phonesessionInfo=(e)=>{
  var keyCode = e.target.value;
  //alert(keyCode.charCodeAt(0));  
   if ( (keyCode.charCodeAt(0) != 8 || keyCode.charCodeAt(0) ==32 || keyCode.charCodeAt(0) ==46 || keyCode.charCodeAt(0) ==47 ) && (keyCode.charCodeAt(0) < 45 || keyCode.charCodeAt(0) > 57)) {
    return false;
  }else{
  this.setState({
     [e.target.id]:e.target.value
   },()=>console.log(this.state.phone))
  }
 }
 
register=(participentDetail)=>{
	let ak='';
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/register", participentDetail)
	.then(res => {
	$('.main_loader').hide();
	if(res.data.responseMessage == "success")
		{
			/*need to test this function*/
			//this.sendSignupMail();
			for(let i=0;i<this.state.password.length;i++)
				{
					ak = ak+'*';
				}

			let signedUpSessionIds = [];
			if ( participentDetail && participentDetail['sessionId'] && participentDetail['sessionId'].length > 0 )
				{
					signedUpSessionIds.push(participentDetail['sessionId']);
				}
			this.setState({
				regUserId: res.data.responseData.userId,
				registerAgree: true,
				loginAgree: false,
				signedSessionsIds: this.state.parentRecrId ? signedUpSessionIds : []
			});

			if(this.state.showAgreePop == 1 && (new Date().getTime() < new Date(this.state.waiverEndDate).getTime()))
				{
					$("#waiverAgree").attr({'style':'display:block; height:auto'});
				}
			else
				{
					for(let i=0;i<this.state.password.length;i++)
						{
							ak = ak+'*';
						}
					this.setState({
						participantValidate: false,
						verify:true,
						signup:false,
						passwordText:ak
					},()=>{
						localStorage.removeItem('scheduleDate',);
						localStorage.removeItem('sessionEndDate');
						localStorage.removeItem('duration');
					$("#email_verification").attr({'style':'display:block'});})
				}
		}
	else
		{
			console.log('=============There Is an Error===================>');
			this.setState({
				msg:''
			}) 
		}
	}).catch(err =>{
		console.log('----------there is problem------------',err);
		if(err.response.data.responseCode===417)
			{
				if ( err.response.data.errorData == 'User not verified')
					{
						$("#user_not_verified2").attr({'style':'display:block'});
					}
				else
					{
						this.setState({
							msg:err.response.data.errorData,
							email : '',
						},()=>$("#error_msg").attr({'style':'display:block'})) 
					}
			}
		$('.main_loader').hide();
	});
}	
 
verify=(e)=>{
	e.preventDefault();
    if (this.validator.allValid()) 
		{
			if(this.state.password===this.state.rePassword)
				{
					var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/      ///^[A-Za-z]\w{7,14}$/;
					// To validate the said format we use the regular expression ^[A-Za-z]\w{7,15}$, where \w matches any word character (alphanumeric) including the underscore (equivalent to [A-Za-z0-9_]).
					// pehle wale ka Minimum eight characters, at least one letter, one number and one special character: 
					var test = reg.test(this.state.password);
					if(localStorage.getItem('sessionId') || this.props.state.od_status === 1)
						{
							if (test) 
								{
									//alert('pass');
									//this.setState({value: event.target.value});
									
									let participantId=[];
									if(localStorage.getItem('sessionIdData'))
										{
											if(JSON.parse(localStorage.getItem('sessionIdData')) && JSON.parse(localStorage.getItem('sessionIdData')).length && JSON.parse(localStorage.getItem('sessionIdData')).length > 0)
												{
													participantId = localStorage.getItem('sessionIdData');	
												}
											else 
												{
													participantId.push(localStorage.getItem('sessionIdData'));
												}
										}
									else
										{
											participantId=[];
										} 

									const participentDetail = {
										firstName:this.state.firstName.trim(),
										lastName:this.state.lastName.trim(),
										email:this.state.email.trim(),
										password:this.state.password.trim(),
										address1:"sector3",
										address2:"noida",
										city:"noida",
										state:"UP",
										zip:"123456",
										image:"",
										type:2,
										phone:this.state.phone.trim(),
										sessionId:participantId,
										timezone:moment1().tz(moment1.tz.guess()).format('z')        
									}

									if ( this.state.demandSessionId && this.state.demandSessionId != 0 )
										{
											participentDetail['demandSessionId'] = this.state.demandSessionId;
										}

									if ( participentDetail['sessionId'] != '[]' )
										{
											this.setState({registerNoSession: false});
										}
									else 
										{
											this.setState({registerNoSession: true});
										}	
										
									if ( participentDetail['sessionId'] != '[]' )
										{
											/*if session id is not empty, this means that the session is recurring or not recurring (not onDemand)*/
											/*in this case, validate all sessions*/
											if ( this.state.parentRecrId )
												{
													/*if session is recurring, validate any session and if it not passes validation, stop register*/
													var stopRegister = this.validateEmailMethod(false, false, true, true).then((res) => { 
														if ( !res ) 
															{
																this.register(participentDetail);
															}
													}).catch( err =>
													{
														console.log(err);
													});
												}
											else
												{
													/*if session is not recurring, again validate*/
													var stopRegister = this.validateEmailMethod(false, false, true, true, true).then((res) => { 
														if ( !res ) 
															{
																/*if it passes validation, register user and signup to session*/
																this.register(participentDetail);
															}
														else
															{
																/*if it not passes validation, register user and dont signup to session*/
																participentDetail['sessionId'] = '[]';
																this.setState({registerNoSession: true});
																this.register(participentDetail);
															}
													}).catch( err =>
													{
														console.log(err);
													});
												}
										}
									else
										{
											//this section includes onDemand sessions or recurring sessions if none are selected
											//this part need to discuss (onDemand session can be a private)
											this.register(participentDetail);
										}
								}
							else
								{
									this.setState({
										msg:'Password is not matching with Format!'
									},()=>$("#error_msg").attr({'style':'display:block'}))
								}
						}
					else
						{
							this.setState({
								msg:'Not a invited Member!'
							},()=>$("#error_msg").attr({'style':'display:block'}))
						}
				}
			else
				{
					this.setState({
						msg:'Password mismatch!'
					},()=>$("#error_msg").attr({'style':'display:block'}))
				}
		} 
	else 
		{
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.setState({
				msg:'One or more fields has an error. Please correct and press SAVE again!'
			},()=>$("#error_msg").attr({'style':'display:block'}))
			
			if (!this.validator.fieldValid('email'))
				{
					this.setState({
						email_error_status : true,
					})
				}
			this.forceUpdate();
		}
}

 submitHost=(e)=>{
	if (this.otpValidation.allValid()) 
		{
			let otpDetail={ 
				email :this.state.email,
				code:this.state.otp
			} 

			console.log('----------------',otpDetail);
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/verify-otp", otpDetail)
			.then(res => {
			if(res.data.responseMessage == "success")
				{
					window['lastUserId'] = 0;

					//set cookie after register
					this.setCookie(process.env.REACT_APP_COOKIE_ID, res.data.responseData.id, res.data.responseData.tokenExpire);
					this.setCookie(process.env.REACT_APP_COOKIE_TOKEN, res.data.responseData.token, res.data.responseData.tokenExpire);
					//set cookie after register
					window['user_loggedIn'] = 1;

					// localStorage.removeItem('sessionId');
					localStorage.setItem("userData", JSON.stringify(res));
					// console.log('=============lallittiwari12345===================>',res.data);
					
					if ( this.state.registerNoSession )
						{
							this.setState({registerNoSession: false});
							window.location.reload();
							return false;
						}
					
					if ( localStorage.getItem('backToChannel') )
						{
							window.location.href = localStorage.getItem('backToChannel');
							localStorage.removeItem('backToChannel');
							return false;
						}
					
					localStorage.setItem("userData", JSON.stringify(res));
					let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
					let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;

					if ( this.state.sessionLinkLoginTry )
						{
							$("#signup_session_registration_popup_after_verify_otp").attr({'style':'display:block'});
							return false;
						}
					
					if ( this.props.state.od_status === 1 )
						{
							var checkSessionValidate = this.joinSessionValidate(true, loginId, token, this.state.demandSessionId);

							var isHost = false;
							if ( this.props.state.od_session_info[0].hostId === loginId ) { isHost = true; }

							this.setState({
								isLoginOpen: false,
								isOpenLoginAll: false,
								ondemandlink: checkSessionValidate.urlPathVar,
								electronlink: checkSessionValidate.urlElectronVar,
								urlPath: checkSessionValidate.urlPathVar,
								urlElectron: checkSessionValidate.urlElectronVar,
								deepLinkButton: checkSessionValidate.deepLinkButton,
								userSignedUp: true,
								loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
								isHost: isHost
							});

							$('.main_loader').hide();
							//window.location.reload();
							return false;
						}
					else
						{
							var isHost = false;
							if ( this.state.participantData.hostId === res.data.responseData.id ) { isHost = true; }

							var checkSessionValidate = this.joinSessionValidate(false, loginId, token, null);
							this.setState({ 
								urlPath: checkSessionValidate.urlPathVar,
								urlElectron: checkSessionValidate.urlElectronVar,
								deepLinkButton: checkSessionValidate.deepLinkButton
							});

							this.setState({
								message:'',
								userType:res.data.responseData.type,
								userSignedUp: true,
								loggedUserData: res.data.responseData,
								isHost: isHost
							});
						}
				}
			else
				{
					console.log('=============There Is an Error===================>'); 
				}
			}).catch(err =>{
				console.log('----------there is problem------------',err);
				if(err.response.data.responseCode===417)
					{
						if(!err.response.data.errorData)
						{
							this.setState({
								otperror:''
							})
						}
						else
							{
								this.setState({
									otperror:err.response.data.errorData
								},()=>$("#error_otp_msg").attr({'style':'display:block'}))
							}
					}
				else
					{
						this.setState({
							otperror:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
						},()=>$("#error_otp_msg").attr({'style':'display:block'}))
					}
			});

			// $("#registration_popup").attr({'style':'display:block'});
			// this.setState({
			//   message:'',

			// })


		}
	else
		{
			console.log('----------------This is a error--------------------')
			this.otpValidation.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.setState({
				otperror:"Check your email and enter correct otp !"
			},()=>$("#error_otp_msg").attr({'style':'display:block'}))
			this.forceUpdate();
		}
} 

checkValidation=(e)=>{
	let showHelp = this.state.showHelpPopup;
	
	let checking = this.checkPassword(this.state.password);
	
	this.setState({
		showHelpPopup:false
	});
	if ( checking )
		{
			let mainthis = this;
			setTimeout(function(){ 
				mainthis.setState({
					showHelpPopup: false
				});
			}, 1000);

		}
}	

showHelpPopupFun=(e)=>{
	this.setState({
		showHelpPopup:false
	});
}

removeErrorStatus=e=>{
	this.setState({
		email_error_status : false
	})
}

checkPassword=(pass)=>{
	let checkAll = false;
	var regexcheck = /[!@#$%_+:;'"~`.,?|\^&*(){}[\]<>?/|\-]/;
	var contains_number = /\d+/;
	var Regex = /^[^a-zA-Z]*$/;
	
	this.setState({
		contain8Ch: false,
		specialSymbol: false,
		numberContain: false,
		letterContain: false
	});
	
	if ( !Regex.test(pass) ) 
		{ 
			this.setState({
				letterContain: true
			});
		}
	
	if ( pass.length >= 8 )
		{
			this.setState({
				contain8Ch: true
			});
		}
	
	if ( regexcheck.test(pass) )
		{
			this.setState({
				specialSymbol: true
			});
		}
		
	if ( contains_number.test(pass) )
		{
			this.setState({
				numberContain: true
			});
		}
		
	if ( pass != '' && pass.length >= 8 && regexcheck.test(pass) && contains_number.test(pass) && !Regex.test(pass) )
		{
			checkAll = true;
		}
		
	return checkAll;
} 

resend=e=>{
  //  console.log('>>>>>>>>>>>>>>>>',participentDetail);
    axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : this.state.email})
    .then(res => {
    //  console.log('>>>>>>>>>>>>>>>>>>>Checking',res)
     if(res.data.responseMessage == "success")
    {
  //  console.log('=============lallittiwari12345===================>',res.data);
  $("#resend_verification").attr({'style':'display:block'});
    }else{
    // console.log('=============There Is an Error===================>',res);
     this.setState({
      msg:''
      
    }) 
    }
    }).catch(err =>{
    console.log('----------there is problem------------',err);
    this.setState({
      msg:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
      
    }) 
    });
   }
closePopUp=(e)=>{
  $("#registration_popup").attr({'style':'display:none'});
}
skipStart=e=>{
  if(this.state.userType===1){
    browserHistory.push("/ChannelCreation");
  
  }
  if(this.state.userType===3){
    browserHistory.push("/AdminDashboard");
    }
  if(this.state.userType===2){
  browserHistory.push("/participent-dashboard");
  }
}

validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


validateEmailMethod=(e, mod?, validateFromRegisterBlur?, validateFromRegisterRecurring?, validateFromRegisterNotRecurring?)=> {
	//validateFromRegisterBlur - in this case, show any error from validation
	//validateFromRegisterRecurring - in this case, show any error from validation, except "Proceed creating account"
	//validateFromRegisterNotRecurring - in this case, just return resolve, dont show error
	return new Promise((resolve, reject) => {
	console.log('signup session(s)');
	var userEmail = this.state.email;

	var loginFromSessionLink = false;
	if ( localStorage.getItem('validateEmailMethodFromCheckLoginFun') )
		{
			loginFromSessionLink = true;
			localStorage.removeItem('validateEmailMethodFromCheckLoginFun');
		}

	if ( !userEmail && $('#email').val() )
		{
			userEmail = $('#email').val();
			this.setState({ email: userEmail }); 
		}

	if ( !mod )
		{
			if ( !this.validateEmail(userEmail) )
				{
					console.log('userEmail');
					console.log(userEmail, this.state.email);
					this.props.showMainErrorPop('The email must be a valid email address.');
					localStorage.removeItem('anyLoginSessionLink');
					return false;
				}
		}
	var isFullSession = this.state.participantData.maxAttendee == this.state.participantData.totalSignUp ? 1 : 0;

	var sesPriceMember = 0;
	var sesPriceNonMember = 0;
	var countSessionsPicked = 0;

	var a =[];
	var pickedSessions = [];
	var checkboxes = document.querySelectorAll('.recurring_session_detail input[type="checkbox"]:checked');

	for (var checkbox of checkboxes)
		{
			console.log('-------------------',checkbox);
			console.log('-------------------',checkbox.value);
			if ( checkbox.value !== "on" )
				{
					a.push(Number(checkbox.value));
					console.log('-------------------',checkbox.value);
					sesPriceMember = sesPriceMember+Number(checkbox.getAttribute('data-sessionmemberprice'));
					sesPriceNonMember = sesPriceNonMember+Number(checkbox.getAttribute('data-sessionnonmemberprice'));
					countSessionsPicked++;

					var key = checkbox.getAttribute('data-sessionkey');
					pickedSessions.push(this.state.participantData.sessionDetails[key]);
				}
		}

	this.setState({
		totalSessionsMemberPrice: sesPriceMember,
		totalSessionsNonMemberPrice: sesPriceNonMember,
		totalSessions: countSessionsPicked,
		sessionsPicked: pickedSessions
	});

	if ( a.length <= 0 )
		{
			this.setState({justLogin: true});
		}

	let email_var = '';
	if (mod)
		{
			email_var = mod;
		}
	else 
		{
			email_var = userEmail;
		}		

	let dataRes;
	let user_main_id;
	let userType = 1;
	console.log(a, '---SESSION----');

	localStorage.setItem("sessionIdData", JSON.stringify(a));
	let pass = '';
	if ( this.state.passwordFromLogin != '' )
		{
			pass = this.state.passwordFromLogin;
		}

	let sessionIsPrivate = false;
	let parentRecrId = false;
	if ( this.state.parentRecrId )
		{	
			parentRecrId = true;
			/*if session recurring, check on backend every id that is sent*/
			sessionIsPrivate = true;
		}

	let registerPar = false;
	if ( validateFromRegisterBlur )
		{
			registerPar = true;
			pass = '';
		}

	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserExist",{"email":email_var,"sessionId":a,'password':pass, 'loginFromSessionLink': loginFromSessionLink, 'sessionIsPrivate' : sessionIsPrivate, 'parentRecrId' : parentRecrId, 'registerPar': registerPar})
	.then(res => {

		if ( res.data.responseData && res.data.responseData['wrongPassword'] )
			{
				this.props.showMainErrorPop('Invalid password.');
				$('.main_loader').hide();
				return false;
			}

		if ( res.data.responseData == "Proceed creating account" )
			{
				$('.main_loader').hide();
				if (!validateFromRegisterRecurring)
					{
						this.setState({
									participantValidate:true,
									validateEmail:false,
									signup:false,
									user_selected: 'new',
									msg:"Checking email successfull, please proceed creating account."
								}, () => {
									$("#checkbox_error_msg").attr({'style':'display:block'});
								});
					}
				else
					{
						resolve(false);
					}
						
				return false;
			}	

		if ( res.data.responseData && res.data.responseData.otpNotVerified )
			{
				if ( !validateFromRegisterNotRecurring )
					{
						let ak = '';
						for(let i=0;i<this.state.passwordFromLogin.length;i++)
							{
								ak = ak+'*';
							}
						let signedUpSessionIds = [];
						if ( a && a.length > 0 )
							{
								signedUpSessionIds.push(a);
							}

						this.setState({
							participantValidate: false,
							verify:true,
							signup:false,
							firstName: res.data.responseData.firstName,
							lastName: res.data.responseData.lastName,
							email: res.data.responseData.email,
							passwordText: ak,
							signedSessionsIds: this.state.parentRecrId ? signedUpSessionIds : [],
							sessionLinkLoginTry: true
						},()=>{
							$("#email_verification").attr({'style':'display:block'});
							$('.main_loader').hide();
						})
					}
				if ( validateFromRegisterRecurring )
					{
						resolve(true);
					}						
				return false;
			}
		if ( res.data.responseData && res.data.responseData.sessionPrivateMemberOnly )
			{
				if ( !validateFromRegisterNotRecurring )
					{
						let message1 = '';
						let message2 = '';
						let message3 = '';

						let privateSessions = [];
						for ( var j = 0; j < res.data.responseData.chargesSession.length; j++)
							{
								if ( res.data.responseData.chargesSession.length > 1 && j != 0 )
									{
										privateSessions.push(" "+res.data.responseData.chargesSession[j].name+",  On "+this.getSessionDateUtil(res.data.responseData.chargesSession[j].scheduleDate, true)+"  at "+this.getSessionTimeUtil(res.data.responseData.chargesSession[j].scheduleDate));
									}
								else
									{
										privateSessions.push(res.data.responseData.chargesSession[j].name+",  On "+this.getSessionDateUtil(res.data.responseData.chargesSession[j].scheduleDate, true)+"  at "+this.getSessionTimeUtil(res.data.responseData.chargesSession[j].scheduleDate));
									}
							}

						if ( res.data.responseData.memberStatus == 'not_member')
							{
								if ( res.data.responseData.chargesSession.length > 1)
									{	
										message1 = this.state.participantData.hostFirstName+" has set these classes to be for channel members only and your name is not showing up on the member list.";
									}
								else
									{
										message1 = this.state.participantData.hostFirstName+" has set this class to be for channel members only and your name is not showing up on the member list.";
									}
								message2 = "["+privateSessions+"]";
								message3 = "If you feel this is a mistake, please contact " +this.state.participantData.hostFirstName+" and have them add you to their member list and try again.";
							}
						else if ( res.data.responseData.memberStatus == 'member_expired')
							{
								if ( res.data.responseData.chargesSession.length > 1)
									{
										message1 = this.state.participantData.hostFirstName+" has set these classes to be for channel members only and your membership is expired.";
									}
								else
									{
										message1 = this.state.participantData.hostFirstName+" has set this class to be for channel members only and your membership is expired.";
									}
								message2 = "["+privateSessions+"]";
								message3 = "If you feel this is a mistake, please contact  " +this.state.participantData.hostFirstName+".";
							}
						this.setState({
							privateRecMsg1: message1,
							privateRecMsg2: message2,
							privateRecMsg3: message3,
						},()=>{
							$("#private_recurring_session").attr({'style':'display:block'});
							$('.main_loader').hide();
						});
					}
				if ( validateFromRegisterRecurring )
					{
						resolve(true);
					}
					
				return false;
			}
			
		if ( res.data.responseData == "User exist, move to login" )
			{
				if ( !validateFromRegisterNotRecurring )
					{
						this.setState({
							user_selected: 'existing',
							msg:"This email is already taken, try login insted.",
						}, () => {
							$("#existingUserModel").attr({'style':'display:block'});
						})
						$('.main_loader').hide();
					}
				
				if ( validateFromRegisterRecurring )
					{
						resolve(true);
					}
				return false;
			}
			
		if ( res.data.responseData && res.data.responseData.sameSessionExist && res.data.responseData.sameSessionExist==="ParticipantLimitReached" ) 
			{
				if ( !validateFromRegisterNotRecurring )
					{
						this.setState({
							msg:"The class is full, please contact "+ this.state.participantData.hostFirstName +" for upcoming classes."
							},()=>{
							$("#existingUserModel").attr({'style':'display:block'})
							localStorage.removeItem('sessionIdData');
							}
						);
						$('.main_loader').hide();
					}
				if ( validateFromRegisterRecurring )
					{
						resolve(true);
					}
				return false;
			}

		this.setState({
			passwordFromLogin: ''
		});
		let rembemberSessionId = localStorage.getItem("sessionIdData");
		if ( mod )
			{
				localStorage.removeItem("sessionIdData");
			}
		if ( res.data.responseData && res.data.responseData.type )
			{
				userType=res.data.responseData.type;
			}

		$('.main_loader').hide();

		localStorage.setItem('verifyEmailID', email_var);

		user_main_id = res.data.responseData.id;
		// User has been found as Virdio user, continue with check
		this.setState({
			mainUserData: res.data.responseData
		});

		this.setState({
			userMainId: user_main_id,
			loginAgree: true,
			registerAgree: false
		});

		if ( this.state.user_selected !== 'new' )
			{
				localStorage.setItem("sessionIdData", rembemberSessionId);
				var checkUser = this.participateCheckUser(res.data.responseData);
				if ( checkUser ) 
					{ 
						var waiverCheck = this.checkIfUserHasWaiver(res.data.responseData.id, false, 'login').then((res) => {
						if ( !res ) 
							{ 
								if ( mod )
									{
										localStorage.setItem('logedUserTryToParticipate', true);
									}
								window['onSubmitLogin'](); 
							}
						}).catch( err =>
						{
							console.log(err);
						});
					}	
			}

	}).catch(err => {

			if(err.response.data.responseCode===417)
				{
					this.setState({
						participantValidate:true,
						validateEmail:false,
						signup:false,
						msg:"Oops, we could not find your email in Virdio.  Please check your email or create an account in Virdio."
					}, () => {
						$("#existing_error").attr({'style':'display:block'});
					});
				}
			else
				{
					console.log('----------there is problem------------',err);
					this.setState({otperror:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'}, ()=>$("#error_otp_msg").attr({'style':'display:block'}));
				}
			$('.main_loader').hide();
	});
	});
}

/* OD VALIDATE TEST */
validateEmailMethod_newOd=(e)=>{
	console.log('signup onDemand');
	if ( this.emailValidate.allValid() ) 
		{
			let dataRes;

			var channelMainName = '';
			var loginFromSessionLink = false;
			if ( localStorage.getItem('validateEmailMethod_newOd') )
				{
					loginFromSessionLink = true;
					localStorage.removeItem('validateEmailMethod_newOd');
				}
			let pass = '';
			if ( this.state.passwordFromLogin != '' )
				{
					pass = this.state.passwordFromLogin;
				}

			let postdata = { "email":this.state.email, "loginFromSessionLink":loginFromSessionLink, 'password':pass }

			if ( this.props && this.props.state && this.props.state.od_session_info && this.props.state.od_session_info.length > 0 )
				{
					postdata['channelId'] = this.props.state.od_session_info[0].demandChannelId;
					postdata['onDemandId'] = this.props.state.od_session_info[0].ondemandId;
					channelMainName = this.props.state.od_session_info[0].channelName;
				}
			
			if ( this.state.user_selected == 'new' )
				{
					postdata['registerPar'] = true;
				}

			$('.main_loader').attr({'style':'display:flex'});

			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserExist", postdata )
			.then(res => {
				dataRes=res.data.responseData;
				
				if ( dataRes['wrongPassword'] )
					{
						this.props.showMainErrorPop('Invalid password.');
						$('.main_loader').hide();
						return false;
					}
					
				if ( dataRes == "Proceed creating account" )
					{
						this.setState({
								user_selected: 'new',
								msg:"Checking email successfull, please proceed creating account."
							}, () => {
								$("#checkbox_error_msg").attr({'style':'display:block'});
							});
						$('.main_loader').hide();
						return false;
					}
					
				if ( res.data.responseData && res.data.responseData.otpNotVerified )
					{
						let ak = '';
						for(let i=0;i<this.state.passwordFromLogin.length;i++)
							{
								ak = ak+'*';
							}

						this.setState({
							participantValidate: false,
							verify:true,
							signup:false,
							firstName: res.data.responseData.firstName,
							lastName: res.data.responseData.lastName,
							email: res.data.responseData.email,
							passwordText: ak
						},()=>{
							$("#email_verification").attr({'style':'display:block'});
							$('.main_loader').hide();
						})
						return false;
					} 	
					
				if ( dataRes.sessionPrivateMemberOnly )
					{
						this.props.showMainErrorPop('This On-demand video can only be viewed by members of '+channelMainName+'. Please contact '+channelMainName+' or your trainer to get access.');
						$('.main_loader').hide();
						return false;
					}
					
				if ( res.data.responseData == "User exist, move to login" )
					{	
						this.setState({
							user_selected: 'existing',
							email : dataRes.email,
							msg:"This email is already taken, try login insted.",
						}, () => {
							$("#existingUserModel").attr({'style':'display:block'});
						});
						$('.main_loader').hide();
						return false;
					}
	
				this.setState({
						passwordFromLogin: ''
					});	
					
				if ( this.state.user_selected !== 'new' )
					{						 
						let user_main_id = res.data.responseData.id;
						this.setState({
							mainUserData: res.data.responseData,
							userMainId: user_main_id,
						});

						if((android && omobile) || (ios && omobile))
						{
							if ( android )
								{
									this.setState({deepLinkButton: 'Allow'});
								}
							if ( ios )
								{
									this.setState({deepLinkButton: 'Allow'});
								}											
						}

						if ( this.state.sessionType === "On Demand" )
							{
								if ( Object.keys(dataRes).length === 0 )
									{
										this.setState({
											validateEmail:false,
											signup:true,
											isOpenSign: true,
											emailSign: this.state.email
										})
									}
								else
									{
										this.setState({ isOpenLoginAll: true });
									}

								$('.main_loader').hide();
								localStorage.setItem('anyLoginSessionLink', true);
								window['onSubmitLogin']();
							}
						else
							{
								//this.checkIfUserHasWaiver(res.data.responseData.id, false, 'login');
							}
					}

			}).catch(err =>{
				$('.main_loader').hide();

				if(err.response.data.responseCode===417)
					{
						this.setState({
								participantValidate:true,
								validateEmail:false,
								signup:false,
								msg:"Oops, we could not find your email in Virdio.  Please check your email or create an account in Virdio."
						}, () => {
							$("#existing_error").attr({'style':'display:block'});
						});
						$('.main_loader').hide();
					}
				else 
					{  
						this.setState({
							otperror:'We are getting trouble in reaching to our server/s. Please refresh the page and try again!'
						},()=>$("#error_otp_msg").attr({'style':'display:block'}))
					}
			});
		}
	else
		{
			this.emailValidate.showMessages();
			$("#error_email_msg").attr({'style':'display:block'})

			this.forceUpdate();
		}
}

sendEmailFromLogin = (e, mod) =>
{
	this.setState(
	{
		email : mod
	})
}

checkLoginFun = e =>
{
	localStorage.setItem('anyLoginSessionLink', true);
	localStorage.setItem('validateEmailMethodFromCheckLoginFun', true);
	this.validateEmailMethod();
}
checkIfUserHasWaiver=(e, par, mod)=> {
	return new Promise((resolve, reject) => {
	$('.main_loader').attr({'style':'display:flex'});
	var haveWaiver = false;

	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/channelSignedWaivers",{'channel_id' : this.state.hostChannelId, 'user_id' : e, 'session_id' : this.state.hostSessionId, 'pdf_link' : this.state.channelWaiverUrl, 'openedFrom' : par, 'waiver_end_date' : moment(this.state.waiverEndDate).format('MM-DD-YYYY') })
	.then(res => {
			let ak='';
			if(mod == 'login')
				{
					if(!par)
						{
							if ( res && res.data && res.data.responseData && res.data.responseData.channelId )
								{
									resolve(false);
									$("#waiverAgree").attr({'style':'display:none; height:auto'});
								}
							else
								{
									if(this.state.showAgreePop == 1 && new Date().getTime() < new Date(this.state.waiverEndDate).getTime())
										{
											if ( this.state.justLogin )
												{
													$('.main_loader').hide();
												}
											console.log('IN THIS IFFFFFFFFF');
											$("#waiverAgree").attr({'style':'display:block; height:auto'});
											/* let waiver_link = 'https://docs.google.com/gview?url='+encodeURIComponent(this.state.channelWaiverUrl)+'&embedded=true&t='+(new Date().getTime());
											$('.waiver_iframe').attr('src', '');
											setTimeout(function(){ $('.waiver_iframe').attr('src', waiver_link); }, 2000); */
											resolve(true);
										}
									else 
										{
											resolve(false);
										}
								}		
						}
					else
						{
							$("#waiverAgree").attr({'style':'display:none; height:auto'});
							 localStorage.setItem('logedUserTryToParticipate', true);
							 if ( localStorage.getItem('anyLoginSessionLink') )
								{
									localStorage.setItem('logedUserTryToParticipateWithOther', true);
								}
							 window['onSubmitLogin']();
							 resolve(false);
						}
				}
			else
				{
					$("#waiverAgree").attr({'style':'display:none; height:auto'});
					for(let i=0;i<this.state.password.length;i++)
					  {
						ak = ak+'*';
					  }
					
					this.setState({
					  verify:true,
					  signup:false,
					  passwordText:ak,
					  participantValidate: false
					  
					  },()=>{
					//localStorage.removeItem('sessionId');
					localStorage.removeItem('scheduleDate',);
					localStorage.removeItem('sessionEndDate');
					localStorage.removeItem('duration');
					if ( this.state.justLogin )
						{
							$('.main_loader').hide();
						}
					$("#email_verification").attr({'style':'display:block'});})
					resolve(false);
				}
				
			if ( !this.state.justLogin )
				{
					$('.main_loader').hide();
				}			
			
		}).catch(err => {
			console.log('----------there is problem------------',err);
			resolve(false);
		});
		
	});
	
}

calculatePrice=(mod, arr, numb, mod2)=>{
	let totalPrice = 0;
	let pricePerSession = '';
	let priceArray = [];
	let diferentValue = 0;
	let sessionDataForPayScreen = [];

	for (var i = 0; i < arr.length; i++) {
		if ( arr[i].sessionProperty == '0' )
			{
				if ( mod == 1 )
					{
						totalPrice = totalPrice+arr[i].chargeForSession;
						if ( priceArray.length > 0 && priceArray.indexOf(arr[i].chargeForSession) == -1 )
							{
								diferentValue = 1;
							}
						
						priceArray.push(arr[i].chargeForSession);
						if ( mod2 )
							{
								sessionDataForPayScreen.push({
																'hostName': arr[i].hostFullName,
																'sessionStartTime': arr[i].externFormatDate,
																'duration': arr[i].duration,
																'amount': arr[i].chargeForSession,
																'id': arr[i].id
															});
							}
					}
				else
					{
						totalPrice = totalPrice+arr[i].chargeForSessionNonMember;
						if ( priceArray.length > 0 && priceArray.indexOf(arr[i].chargeForSessionNonMember) == -1 )
							{
								diferentValue = 1;
							}
						
						priceArray.push(arr[i].chargeForSessionNonMember);
						if ( mod2 )
							{
								sessionDataForPayScreen.push({
																'hostName': arr[i].hostFullName,
																'sessionStartTime': arr[i].externFormatDate,
																'duration': arr[i].duration,
																'amount': arr[i].chargeForSessionNonMember,
																'id': arr[i].id
															});
							}
					}
			}
		else
			{
				totalPrice = totalPrice+arr[i].chargeForSession;
				if ( priceArray.length > 0 && priceArray.indexOf(arr[i].chargeForSession) == -1 )
					{
						diferentValue = 1;
					}
				
				priceArray.push(arr[i].chargeForSession);
				if ( mod2 )
					{
						sessionDataForPayScreen.push({
														'hostName': arr[i].hostFullName,
														'sessionStartTime': arr[i].externFormatDate,
														'duration': arr[i].duration,
														'amount': arr[i].chargeForSession,
														'id': arr[i].id
													});
					}
			}
	}
	
	if ( !numb )
		{
			if ( mod2 )
				{
					return sessionDataForPayScreen;
				}
			return totalPrice;
		}
	else
		{
			if ( diferentValue == 0 )
				{
					pricePerSession = priceArray[0];
					return pricePerSession;
				}
			else
				{
					console.log('priceArray', priceArray);
					let max = Math.max.apply(Math, priceArray) 
					let min = Math.min.apply(Math, priceArray) 
					pricePerSession = min+'-'+max;
					return pricePerSession;
				}
		}
}

	OnCheckChange = (event)=>{
		if ( this.state.participantData.allowSS == 0 )
			{
				this.CheckAllBox(event);
			}
		else 
			{
				const target = event.target;
				var value = target.value;
				var i =0; var selectedCB=[];
				if(target.checked){
					this.state.checkedBoxes[value] = value;
					this.setState(initialState => ({  isChecked: true,	}));
					
				}else{			
					this.state.checkedBoxes.splice(value, 1);
					this.setState(initialState => ({  isChecked: false,	}));
					
				}
				var checkBox =[]; var a11 =[];
				for(var i in this.state.checkedBoxes){
					checkBox.push(Number(this.state.checkedBoxes[i]))
				}
				var checkboxes = document.getElementsByName('checkedBoxes[]');
				var checkboxChecked = document.querySelectorAll('.recurring_session_detail input[type="checkbox"]:checked');
				//console.log('-------checkboxChecked all----------',checkboxChecked);
				for (var checkbox of checkboxChecked) {
				  if(checkbox.value !== "on"){
					a11.push(Number(checkbox.value));
				  }
				}
				
				if ( this.state.participantData.sessionDetails && this.state.participantData.sessionDetails.length > 1 )
					{
						let scheduleDate = 0;
						let scheduleDateT = '';
						let logedUser = false;
						if ( this.state.loged_user != '' ) { logedUser = true; }
						
						this.setState({ logedUserNowParticate: logedUser,
										logedUserAlreadyParticate: logedUser,
										//userSignedUp: logedUser
								});
						
						for (var j = 0; j < this.state.participantData.sessionDetails.length; j++)
							{
								if ( a11.indexOf(this.state.participantData.sessionDetails[j]['id']) > -1 )
									{
										if ( scheduleDate == 0 || scheduleDate > new Date(this.state.participantData.sessionDetails[j]['scheduleDate']).getTime() )
											{
												scheduleDate = new Date(this.state.participantData.sessionDetails[j]['scheduleDate']).getTime();
												scheduleDateT = new Date(this.state.participantData.sessionDetails[j]['scheduleDate']).toISOString();
											}
										if ( !this.state.participantData.sessionDetails[j]['alreadyMember'] )
											{
												logedUser = false;
												this.setState({ logedUserNowParticate: logedUser,
																logedUserAlreadyParticate: logedUser,
																//userSignedUp: logedUser
														});
											}
									}
							}
						localStorage.setItem('scheduleDate', scheduleDateT);
					}
				
				if(checkboxes.length === a11.length){
					this.setState({ allChecked: true })			
				}else{
					this.setState({ allChecked: false })
				}	

				this.setState({sessionIdArr:a11});
				
				var a =[];
				var checkboxes11 = document.querySelectorAll('.recurring_session_detail input[type="checkbox"]:checked');
				for (var checkbox of checkboxes11) {
				  if(checkbox.value !== "on"){
					a.push(Number(checkbox.value));
				  }
				}
				localStorage.setItem("sessionIdData", JSON.stringify(a));
			}			
		
	}	
    
	CheckAllBox = (e, checkAll)=>{
		var target = e.target;
		if ( checkAll ) { target = e; }
		var checkBox =[];
		let new_chk_array = [];
		//var checkboxes = document.getElementsByTagName('input');
		
		var checkboxes = document.getElementsByName('checkedBoxes[]');
		if(target.checked){
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].type == 'checkbox') {
					checkboxes[i].checked = true;
					this.setState(initialState => ({  isChecked: true,	}));
					this.setState({ allChecked: true })
				new_chk_array.push(Number(checkboxes[i]['id']));
				}
			}
		} else {
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].type == 'checkbox') {
					checkboxes[i].checked = false;
					this.setState(initialState => ({  isChecked: false,	}));
					this.setState({ allChecked: false })
				}
				new_chk_array = [];
			}
			
		}
		
		if ( this.state.participantData.sessionDetails && this.state.participantData.sessionDetails.length > 1 )
			{
				let scheduleDate = 0;
				let scheduleDateT = '';
				let logedUser = false;
				if ( this.state.loged_user != '' ) { logedUser = true; }
				this.setState({ logedUserNowParticate: logedUser,
								logedUserAlreadyParticate: logedUser,
								//userSignedUp: logedUser
						});
				
				for (var j = 0; j < this.state.participantData.sessionDetails.length; j++)
					{
						if ( new_chk_array.indexOf(this.state.participantData.sessionDetails[j]['id']) > -1 )
							{
								if ( scheduleDate == 0 || scheduleDate > new Date(this.state.participantData.sessionDetails[j]['scheduleDate']).getTime() )
									{
										scheduleDate = new Date(this.state.participantData.sessionDetails[j]['scheduleDate']).getTime();
										scheduleDateT = new Date(this.state.participantData.sessionDetails[j]['scheduleDate']).toISOString();
									}
								if ( !this.state.participantData.sessionDetails[j]['alreadyMember'] )
									{
										logedUser = false;
										this.setState({ logedUserNowParticate: logedUser,
														logedUserAlreadyParticate: logedUser,
														//userSignedUp: logedUser
												});
							}
					}
					}
				localStorage.setItem('scheduleDate', scheduleDateT);
			}
		
		this.setState({
			sessionIdArr : new_chk_array
		}, () => {
			let one_already_checked = false;
			if ( this.state.participantData.sessionDetails && this.state.participantData.sessionDetails.length > 1 )
				{
					for (var j = 0; j < this.state.participantData.sessionDetails.length; j++)
						{
							if ( this.state.participantData.sessionDetails[j]['alreadyMember'] )
								{
									one_already_checked = true;
								}
						}
				}
		});
		
		var a =[];
		var checkboxes11 = document.querySelectorAll('.recurring_session_detail input[type="checkbox"]:checked');
		for (var checkbox of checkboxes11) {
		  if(checkbox.value !== "on"){
			a.push(Number(checkbox.value));
		  }
		}
		localStorage.setItem("sessionIdData", JSON.stringify(a));
		
	}
	
	openShopList = (e)=>{
		this.setState({openShopList: true})
	}
	
	closeShopPop = (e)=>{
		this.setState({openShopList: false})
	}
	
	openEquList = (e)=>{
		if (this.state.openEquList)
			{
				this.setState({openEquList: false})
			}
		else 
			{			
				this.setState({openEquList: true})
			}
	}
	
	closeEquPop = (e)=>{
		this.setState({openEquList: false})
	}
	
	changeQuantity = (st, i) => {
		var array = this.state.shoppingList.slice();
		
		if ( st == '+' ) { array[i]['quantity']++; }
		else { if ( array[i]['quantity'] > 1 ) { array[i]['quantity']--; } }
		
		this.calculateAllTotal();
		
		this.setState({
			shoppingList: array
		})
	}
	
	calculateAllTotal = () => {
		var array = this.state.shoppingList.slice();
		
		$.each(array, function( a, b ) {
			b['total'] = 0;
			b['total'] = b['total'] + ( b['quantity'] * b['price'] );
		});
		
		this.setState({
			shoppingList: array
		})
		
		this.calculateTotal();
	}
	
	calculateTotal = () => {
		var array = this.state.shoppingList.slice();
		let total = 0;
		$.each(array, function( a, b ) {
			if ( b['selected'] == 1 )
				{
					total = total + b['total'];
				}
		});
		
		this.setState({
			totalShopPrice: total
		})
	}
	
	changeSelected = (i) => {
		var array = this.state.shoppingList.slice();
		
		array[i]['selected'] = !array[i]['selected'];
		
		this.setState({
			shoppingList: array
		})
		
		this.calculateTotal();
	}
	
	orderFromHost = (i) => {
		var array = this.state.shoppingList.slice();
		
		var hasany = false;
		for (var item of array)
			{
				if ( item['selected'] ) { hasany = true; }
			}
		
		if ( !hasany ) { return false; }
		
		
		
    if ( localStorage.getItem('userData') )
		{
			if ( JSON.parse(localStorage.getItem('userData')) )
				{
					let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
					if ( token )
						{
							axios.defaults.headers.common['Authorization'] = token;
							
							axios.post(process.env.REACT_APP_NAME+"/api/v1/session/orderlist", {"shoplist" : array, 'session': this.state.participantData})
							.then(res => {
								
								this.getSessionInfoFull(false);
								
								this.closeShopPop();
								window.$('#success_order').modal('show');
							
							}).catch(err =>{ this.closeShopPop(); });
						}
				}
		} else { this.closeShopPop(); }
		
	}
	
	
	
backToChannelPg = (e) => {
	
	if ( localStorage.getItem("backToChannel") )
		{
			localStorage.removeItem('backToChannel');
	window.location.href = localStorage.getItem("backToChannel");
		}
	else
		{
			window.location.href = '/login';
		}
}	

showLoginNative = (e) => {
	window.$('#autologinnative.signupPagePopup').modal('show');
}

openNativeUrl = (nurl) => {
	if ( nurl === '' && localStorage.getItem('userData') )
		{
			if ( this.props.state.od_status && this.props.state.od_status === 1 )
				{
					var loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
					var token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
					var odId = this.props.state.od_session_info[0].ondemandId;
					var user = JSON.parse(localStorage.getItem('userData'));
					var checkSessionValidate = this.joinSessionValidate(true, user.data.responseData.id, user.data.responseData.token, odId);
					nurl = checkSessionValidate.urlElectronVar;
					var isHost = false;
					if ( this.props.state.od_session_info[0].hostId === loginId ) { isHost = true; }
					this.setState({urlElectron: checkSessionValidate.urlElectronVar, urlPath: checkSessionValidate.urlPathVar, isHost: isHost});		
				}
			else
				{
					let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
					let userToken = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
					var isHost = false;
					if ( this.state.participantData.hostId === userId ) { isHost = true; }
					var checkSessionValidate = this.joinSessionValidate(isHost, userId, userToken, null);
					nurl = checkSessionValidate.urlElectronVar;
					this.setState({urlElectron: checkSessionValidate.urlElectronVar, urlPath: checkSessionValidate.urlPathVar, isHost: isHost});
				}
		}
		
	var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;
	
	this.setState({appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink )});
	
	$('.main_loader').attr({'style':'display:flex'});
	customProtocolCheck(nurl,
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative.signupPagePopup').modal('hide');
							$("#nativedownload.signupPagePopup").show();
						  },
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative.signupPagePopup').modal('hide');
							
							setTimeout( ()=>{ this.windowClose(); }, 30000);
						  }, 15000);
}

updateUserDemand=()=>{
	var onDemandId = this.props.state.od_session_info[0].ondemandId;
	var userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	var postData = {onDemandId: onDemandId, userId: userId};
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateUserDemand", postData).then(res => { }).catch(err =>{ });
}

changeSelectedNew = (mod) =>
{
	this.setState({
		user_selected : mod
	});
}

updateStateFromChildSession = (newState, sessions) => {
	this.setState(newState);
	if (sessions)
		{
			if ( this.state.participantData && this.state.participantData.sessionDetails && this.state.participantData.sessionDetails.length > 0 )
				{
					for ( let i = 0; i < this.state.participantData.sessionDetails.length; i++ )
						{
							if ( sessions[0].indexOf(this.state.participantData.sessionDetails[i]['id']) > -1 )
								{
									let changeState = this.state.participantData;
									changeState.sessionDetails[i]['alreadyMember'] = true;
									this.setState({participantData: changeState});
									console.log(this.state.participantData.sessionDetails);
								}
						}
				}
		}
}

loginWithOtherUser = (e) => {
	this.setState({
		tryWithOtherAccount: true,
		userSignedUp: false
	});
	
	if ( this.state.participantData && this.state.participantData.sessionDetails && this.state.participantData.sessionDetails.length > 1 )
		{
			
			let array = this.state.participantData;
			for (var j = 0; j < array['sessionDetails'].length; j++)
				{
					array['sessionDetails'][j]['alreadyMember'] = false;
				}
			this.setState({
					participantData: array,
					
				});
			let showBothStatus = false;
			
			var checkboxes = document.getElementsByName('checkedBoxes[]');
			for ( var i = 0; i < checkboxes.length; i++)
				{
				
					if ( (checkboxes[i].checked && !checkboxes[i].disabled) || (checkboxes[i].checked && checkboxes[i].disabled && this.state.participantData.allowSS == 0) )
						{
							showBothStatus = true;
						}
					else if ( checkboxes[i].disabled && this.state.participantData.allowSS == 1)
						{
							if (checkboxes[i].type == 'checkbox') {
								checkboxes[i].checked = false;
								this.setState(initialState => ({  isChecked: false,	}));
								this.setState({ allChecked: false })
							}
						}						
					
				}
		}
}

signUpToSession = (e) =>{
	
	var a =[];
	var pickedSessions = [];
	var checkboxes = document.querySelectorAll('.recurring_session_detail input[type="checkbox"]:checked');
	
	for (var checkbox of checkboxes)
		{
			if ( checkbox.value !== "on" )
				{
					a.push(Number(checkbox.value));	
					var key = checkbox.getAttribute('data-sessionkey');
					pickedSessions.push(this.state.participantData.sessionDetails[key]);
				}
		}
	
	if ( a.length < 1 )
		{
			this.setState({ msg: "Please select at least one session.", },() => $("#checkbox_error_msg").attr({'style':'display:block'}))
			this.forceUpdate();
		}
	else 
		{
			let email = JSON.parse(localStorage.getItem('userData')).data.responseData.email;
			this.validateEmailMethod(false, email);
		}		
	
}

existingPopSelect = (mod) => 
{
	let selected = '';
	console.log(mod);
	if ( parseInt(mod) === 1 )
		{
			selected = 'existing';
			
		}
	else 
		{
			selected = 'new';
		}
		
	this.setState({
		user_selected: selected
	})	
	$("#existing_error").attr({'style':'display:none'});
	console.log(selected);	
}


closeChargePop=()=>{
	$("#chargePopup").attr({'style':'display:none'});
	this.setState({loginOpenFromExisting: true});
}

passwordEyeCheck=(e, a)=>{
	if(a == 'pass')
		{
			if(e == 0)
				{
					this.setState({
						passwordEye: false
					});
				}
			
			else if(e == 1)
				{
					this.setState({
						passwordEye: true
					});
				}
		}
		
	else
		{
			if(e == 1)
					{
						this.setState({
							passwordEyeRepeat: false
						});
					}
				
				else if(e == 0)
					{
						this.setState({
							passwordEyeRepeat: true
						});
					}
		}
}

showNewPartScreen = e =>{
	window.location.reload();
}

sendSignupMail=e=>{
	 let a =localStorage.getItem('sessionId');
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/sendSignupMail", {email : this.state.email, sessionId: a})          
        .then(res => {
				console.log(res);
			})
}

windowClose=(e)=>{
		window.$('#closethistab').modal('show');
		setTimeout( ()=>{
			window.close();
		}, 2000);
}
	
goToLogin=(e)=> {
	$("#user_not_verified2").attr({'style':'display:none'});
	
	this.setState({
		user_selected : 'existing'
	});
};

onBlurCheck =(mod)=>{
	this.validator.allValid();
	if(!this.validator.fieldValid('firstName') && mod == 'name' )
		{
			this.setState({
				errorClass_FirstName:true
			})
	   }
	if(!this.validator.fieldValid('lastName') && mod == 'lastName' )
		{
			this.setState({
				errorClass_LastName:true
			})
	   }
	if(!this.validator.fieldValid('rePassword') && mod == 'rePassword' )
		{
			this.setState({
				errorClass_RePassword:true
			})
	   }   
	   
}
	
openWaiver=()=>{
	window.open('https://docs.google.com/gview?url='+encodeURIComponent(this.state.channelWaiverUrl)+'&embedded=true');
}

triggerOpened=()=>{
	window.$('#closethistab').modal('show');
	setTimeout( ()=>{ window.close(); }, 2000);
}

sortByDate = (a, b) => {
    return ((a < b) ? -1 : ((a > b) ? 1 : 0));
  }

returnLowestDate=()=>{
	let dates = [];
	for (let i=0; i< this.state.participantData.sessionDetails.length; i++)
		{
			if ( this.state.signedSessionsIds[0].indexOf(this.state.participantData.sessionDetails[i]['id']) > - 1 )
				{
					dates.push(this.state.participantData.sessionDetails[i]['scheduleDate']);
				}
		}
		
	let lowestDate = dates.sort(this.sortByDate);
	return lowestDate[0];
}

reloadSessionInfoAfterLogin = () =>{
	this.getSessionInfoFull(true);
	this.setState({delayLoader: true});
}

validateSession = () =>{
	/*validate from register from session link*/
	this.validateEmailMethod(false, false, true);
}

render(){
	var srData =[];
	for(var key in this.state.participantData.sessionDetails){ 
		if(new Date().getTime()>new Date(this.state.participantData.sessionDetails[key].sessionEndDate).getTime()||this.state.participantData.sessionDetails[key].isCompleted>0){
			
			srData.push(
				 <div className="date_div" key={key}>
					<label className="custom-control custom-checkbox d-flex pl-0 mb-0 no_border_red cursor">
					{/*<input className="form-radio"
							  type="checkbox"
							  name="checkedBoxes11"
							  disabled={true}              
							  onChange={this.OnCheckChange}
					/>*/}					
					  <p className="mt-1 mb-2 align-self-center fade_start">{this.getReccuringDateNew(this.state.participantData.sessionDetails[key].scheduleDate)}</p>
					</label>
				  </div> 
				)
		}else{
			
			srData.push(
			 <div className="date_div" key={key}>
				<div className="checkBox_inl" >
					<label className="cursor">
						  <input className={ "form-radio checkBox_inl " + ( this.state.participantData.sessionDetails[key].alreadyMember ? "add_opa07" : "" )}
							  type="checkbox"
							  name="checkedBoxes[]"
							  disabled={(this.state.participantData.sessionDetails[key].alreadyMember || this.state.participantData.allowSS == 0) ? true : false}
							  checked={this.state.participantData.parentRecrId ? ( this.state.participantData.sessionDetails[key].alreadyMember ? true : this.state.isChecked[key]) : true }
							  value={this.state.participantData.sessionDetails[key].id}
							  id={this.state.participantData.sessionDetails[key].id}                 
							  data-sessionmemberprice={this.state.participantData.chargeForSession}
							  data-sessionnonmemberprice={this.state.participantData.chargeForSessionNonMember}
							  data-sessionkey={key}
							  onChange={!this.state.participantData.sessionDetails[key].alreadyMember ? this.OnCheckChange : ''}
						  />
						  <p className="mt-1 mb-2 align-self-center checkBox_inl less_font_checkbox">{this.getReccuringDateNew(this.state.participantData.sessionDetails[key].scheduleDate)} {this.state.participantData.sessionDetails[key].maxAttendee === this.state.participantData.sessionDetails[key].totalSignUp ? '(Max attendees reached)' : ''}</p>
					</label>
				</div>
			  </div> 
			)
		}
	}
		
	return(
      <div id="root">
	  
		   <div className="App">
        <div className="container-fluid no_padding">
       
       {this.state.validateEmail?
        <div className="mw-770 mx-auto">
            <div className="text-center"><img src="/images/host.png" alt="host" /></div>
            <h4 className="white text-center pb-3">Signing up as a Member</h4>
            
            <div className="gray-box-4 px-4 pt-5 pb-4 input_bx-cont">
              
              <div className="form-group">
                <label className="label">Email Address<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="text" 
                className={(this.state.errorClass_Email  ?"input-field red-outline":"pr-5 input-field")} 
                value={this.state.email} id ='email'
                ref={mail=> (this._email = mail)} 
                onChange={(e)=>this.setState({[e.target.id]:e.target.value,errorClass_Email:false})} />
                {this.emailValidate.message('email', this.state.email, 'required|email')}
               
              </div>
              
              
            </div>


            <div className="text-center">
            
            <button type="button" className="save-btn btn btn-primary mt-3 font-book mx-auto font-18 main_color_submit"  onClick={this.validateEmailMethod}>Submit</button></div>
            </div>:''}

          {this.state.signup?
         <form className = "form-horizontal pt-1 participant_form_sign"    onSubmit={this.verify} >
          <div className="mw-770 mx-auto py-4">
            <div className="text-center"><img src="/images/host.png" alt="host" className="w-60" /></div>
            <h4 className="white text-center pb-3 font-18">Signing up as a Member</h4>
            <div className="gray-box-4 px-2 px-sm-4 pt-4 pb-0 input_bx-cont">
              <div className="form-group">
                <label className="label">Enter First Name (Max 30 characters)<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="text" 
                className={(this.state.errorClass_FirstName?"input-field red-outline":"input-field")} 
                value={this.state.firstName} 
                ref={fname=> (this._firstName = fname)} 
                id ='firstName' 
                onChange={(e)=>{
                  this.setState({
                    [e.target.id]:e.target.value,
                    errorClass_FirstName:false
                  })}}  />
                {this.validator.message('firstName', this.state.firstName, 'required|alpha_space|min:1|max:30')}
                
              </div>
              <div className="form-group">
                <label className="label">Enter Last Name (Max 30 characters)<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="text" 
                className={(this.state.errorClass_LastName?"input-field red-outline":"input-field")} 
                value={this.state.lastName} 
                ref={lname=> (this._lastName = lname)} 
                id ='lastName' 
                onChange={(e)=>{
                  this.setState({
                    [e.target.id]:e.target.value,
                    errorClass_LastName:false
                  })}}/>
                {this.validator.message('lastName', this.state.lastName, 'required|alpha_space|min:1|max:30')}
               
              </div>
              <div className="form-group">
                <label className="label">Email Address<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="text" className="pr-5 input-field myinput" value={this.state.email} id ='email' onChange={this.sessionInfo} disabled/>
                {this.validator.message('email', this.state.email, 'required|email')}
                <div className="srv-validation-message">eg. xyz@virdio.com</div>
                
              </div>
             
              <div className="form-group">
                <label className="label">Create  Password<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="password" onKeyUp={this.checkValidation} onKeyUp={this.checkValidation} 
                className={(this.state.errorClass_Password?"input-field red-outline":"input-field")} 
                value={this.state.password} ref={pwd=> (this._password = pwd)} id ='password'
                 onChange={(e)=>{
                  this.setState({
                    [e.target.id]:e.target.value,
                    errorClass_Password:false
                  })}} />
                {this.validator.message('password', this.state.password, 'required')}
                <div className="srv-validation-message">Password minimum eight characters, at least one letter, one number and one special character eg.1234567A@</div>
                <span className="pass_word"></span>
              </div>
              <div className="form-group">
                <label className="label">Retype Password<sup>*</sup><span className="inp_cover-border"></span></label>
                <input type="password" 
                className={(this.state.errorClass_RePassword?"input-field red-outline":"input-field")} 
                value={this.state.rePassword} ref={rpwd1=> (this._rePassword = rpwd1)} 
                id ='rePassword' 
                onChange={(e)=>{
                  this.setState({
                    [e.target.id]:e.target.value,
                    errorClass_RePassword:false
                  })}} 
				  />
                {this.validator.message('rePassword', this.state.rePassword, 'required')}
				
				<div className="password_check_form participant_pass_pop">
					<div className="pass_check_title">Your password needs to:</div>
					<div className="pass_main_line">
						<div className={ "pass_done_icon " + ( !this.state.contain8Ch ? "pass_iks_icon" : "" ) }></div>
						<div className="pass_line_text">Minimum of 8 characters</div>
					</div>
					<div className="pass_main_line">
						<div className={ "pass_done_icon " + ( !this.state.letterContain ? "pass_iks_icon" : "" ) }></div>
						<div className="pass_line_text">Minimum of 1 Letter</div>
					</div>
					<div className="pass_main_line">
						<div className={ "pass_done_icon " + ( !this.state.numberContain ? "pass_iks_icon" : "" ) }></div>
						<div className="pass_line_text">Minimum of 1 Number</div>
					</div>
					<div className="pass_main_line">
						<div className={ "pass_done_icon " + ( !this.state.specialSymbol ? "pass_iks_icon" : "" ) }></div>
						<div className="pass_line_text">Minimum of 1 Special Character</div>
						<div className="pass_line_text spec_char">(e.g. !,@,#,$,%,&,*)</div>
					</div>
				</div>
				

                <span className="pass_word"></span>
              </div>
            </div>
            <div className="text-center">
            {this.state.verify?'':<div style={{color:'red'}}>{this.state.msg}</div>}
            <button type="submit" className="save-btn btn btn-primary mt-3 font-book mx-auto font-18 main_color_submit">Submit</button>
            </div>
            </div>
           </form>

          :'' }

          {this.state.verify?
            <div className="py-4 mw-1260 px-3 px-md-5 mx-auto mb-5">
            <div className="text-center"><img src="/images/host.png" alt="host" className="w-60" /></div>
            <h4 className="white text-center pb-2 font-20">Signing up as a Member</h4>
            <div className="row">
              <div className="col-lg-6">
                <div className="left_innr_cont h-100">
                  <div className="mb-30">
                    <p className="checktxt">First Name</p>
                    <p className="checktxt_name border border-0 mb-0"><img src="/images/signedup.png" className="mr-3" alt="user-icon" />{this.state.firstName}</p>
                  </div>
                  <div className="mb-30">
                    <p className="checktxt">Last Name</p>
                    <p className="checktxt_name border border-0 mb-0"><img src="/images/signedup.png" className="mr-3" alt="user-icon" />{this.state.lastName}</p>
                  </div>
                  <div className="mb-30">
                    <p className="checktxt">Email Address</p>
                    <p className="checktxt_name border border-0 mb-0"><img src="/images/form-email.png" className="mr-3" alt="user-icon" />{this.state.email}</p>
                  </div>
                  
                  <div className="mb-30">
                    <p className="checktxt">Create  Password</p>
                    <p className="checktxt_name border border-0 mb-0"><img src="/images/passwrd.png" className="mr-3" alt="user-icon" />{this.state.passwordText}</p>
                  </div>
                </div>
              </div>
            
              
              <div className="col-lg-6 mt-3 mt-lg-0">
                <div className="left_innr_cont h-100 pb-4 position-relative">
                  <p className="pick pb-2">Verify Your Code</p>
                  
                  <div className="d-flex justify-content-center align-items-center flex-wrap pb-2 ">
                   
                    <div className="sms_email_box ml-3">
                        <div className="b-image text-center gray-box-5">
                            <img src="/images/email.png" alt="#" />
                        </div>
                        <p className="hdng1 font-18 text-center color_gray mt-3">
                          <label className="custom-control pl-0 custom-checkbox">
                              <input type="checkbox"
                              id = "byEmail" 
                              checked={this.state.byEmail} 
							  disabled
                              onChange = {(e)=>{this.setState({[e.target.id]:!this.state.byEmail})}}
                              className="form-radio" />
                              <span>By Email</span>                                   
                          </label>
                        </p>
                    </div>
                    <span className="err_msg">{this.state.message}</span>
                  </div>
                  <p className="pick mt-2 mb-4 font-18">ENTER THE CODE</p>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="">
                      <p className="sml_input_box_ak d-inline">
                        <input type="text" maxLength="4" id ="otp" value={this.state.otp} onChange={(e)=>this.setState({[e.target.id]:e.target.value})}/>
                        {this.otpValidation.message('OTP', this.state.otp, 'required|size:4')}
                      </p>
                      
                    </div>
                    <div className="ml-4">
                      <p className="checktxt font-18 mt-2 mb-0">Didn't receive?</p>
                      <h4 className="font-16 px-0 font-weight-bold mt-0 purple_text pointer text-decoration-none" onClick={this.resend}>RESEND</h4>
                    </div>
                  </div>
                  <button type="button" className="position-absolute custom_btn" onClick={this.submitHost}>submit</button>
                </div>
              </div>
              
            </div>
          </div>
          :''}
          

          {this.state.participantValidate ?
           <div className="text-white m-auto" id="participant_sign_up">
              <div className="modal-content bg-none radius-10 position-relative no_overflow_new">
                  <div className="modal-body px-0 py-0 ">
				  { this.props && this.props.state && this.props.state.od_status && this.props.state.od_status === 1 ?
				  <div className="top_part_holder abs_holder" style={{ backgroundImage: this.props.state.od_session_info[0].image != '' ? "url("+this.props.state.od_session_info[0].image+")" : "url('/images/session-banner.jpg')"}} >
					<div className="opacity_div" >	
						<div className="top_label_part" >On Demand Class Sign-up</div>
						<div className="host_info_holder" >
							<div className="all_host_info">
								<div className="all_host_info_parts part_1st hei_aut_700" >
									<div className="host_image_pic" style={{ backgroundImage: this.props.state.od_session_info[0].hostImage != '' ? "url("+this.props.state.od_session_info[0].hostImage+")" : "url('/images/profile.png')"}} ></div>
								</div>
								<div className="all_host_info_parts part_2nd height_auto add_paddl10" >
									<div className="top_part_h1 padtp15" >{this.props.state.od_session_info[0].name}</div>
									<div className="top_part_h2 desc_holderNew breakW" >{this.props.state.od_session_info[0].description}</div>
									<div className="top_part_h3" > By <span className="host_name_top"> {this.props.state.od_session_info[0].firstName} {this.props.state.od_session_info[0].lastName}</span> | Categories: <span className="intr_span" >{this.props.state.od_session_info[0].interest_titles}</span> </div>
								</div>
								<div className="all_host_info_parts part_1st ch_img_align ch_img_hold" >
									<div className="host_image_pic channel_image" style={{ backgroundImage: (this.props.state.od_session_info[0].channelImage && this.props.state.od_session_info[0].channelImage.indexOf('.s3')) ? "url("+this.props.state.od_session_info[0].channelImage+")" : "url('/images/session-banner.jpg')"}} >
										<div className="channel_name_label breakWord" >{this.props.state.od_session_info[0].channelName}</div>
									</div>
									<div className="pow_new">Powered by <span className="new_vird">Virdio</span></div>
								</div>
							</div>
						</div>
					</div>	
				</div>
				:
				<div className="top_part_holder abs_holder" style={{ backgroundImage: this.state.participantData.image != '' ? "url("+this.state.participantData.image+")" : "url('/images/session-banner.jpg')"}} >
					<div className="opacity_div" >	
						<div className="top_label_part" >Class Sign-up</div>
						<div className="host_info_holder" >
							<div className="all_host_info">
								<div className="all_host_info_parts part_1st hei_aut_700" >
									<div className="host_image_pic" style={{ backgroundImage: this.state.participantData.hostImage != '' ? "url("+this.state.participantData.hostImage+")" : "url('/images/profile.png')"}} ></div>
								</div>
								<div className="all_host_info_parts part_2nd height_auto add_paddl10" >
									<div className="top_part_h1 padtp15" >{this.state.participantData.name}</div>
									<div className="top_part_h2 desc_holderNew breakW" >{this.state.participantData.description}</div>
									<div className="top_part_h3" > By <span className="host_name_top"> {this.state.participantData.hostFirstName} {this.state.participantData.hostLastName}</span> | Categories: <span className="intr_span" >{this.state.participantData.interestTitles}</span> </div>
								</div>
								<div className="all_host_info_parts part_1st ch_img_align ch_img_hold" >
									<div className="host_image_pic channel_image" style={{ backgroundImage: (this.state.participantData && this.state.participantData.channelImage.indexOf('.s3') > -1) ? "url("+this.state.participantData.channelImage+")" : "url('/images/session-banner.jpg')"}} >
										<div className="channel_name_label breakWord" >{this.state.participantData.channelName}</div>
									</div>
									<div className="pow_new">Powered by <span className="new_vird">Virdio</span></div>
								</div>
							</div>
						</div>
					</div>	
				</div>
				}
				  {/*
                      <div className="detail_session_img position-relative new_no_border_radius">	
                          <img src={!this.state.participantData.image ? "/images/session-banner.jpg": this.state.participantData.image} className="w-100 image new_no_border_radius minh300" />
							<div className="mb-0 trans_image">
								<div className="new_channel_name small_width text_wrap_demand" title={this.state.participantData.channelName}>{this.state.participantData.channelName}</div>
								<img src={!this.state.participantData.channelImage || this.state.participantData.channelImage==="default.png" ? "/images/host-user.png" : this.state.participantData.channelImage} className="new_radius_img border" />
								<div className="small_width">Powered by Virdio</div>
							</div>
							<div className="sessionName_new_holder" >
								{ this.props && this.props.state && this.props.state.od_status && this.props.state.od_status === 1 ?
									<div className="new_sessionName text_wrap_demand">On Demand name: <span className="pl15">{this.props.state.od_session_info[0].name}</span></div>
									:
									<div className="new_sessionName text_wrap_demand">Class name: <span className="pl15">{this.state.participantData.name}</span>
										{this.state.participantData.sessionDetails && this.state.participantData.sessionDetails.length > 1 &&
											<div className="new_displ">
												<img src="../images/r-icon.png" className="ml-3 align-self-start w-auto cursor-pointer px-2" data-toggle="modal" data-target="#recurringInfo" />
											</div>
										}
									</div>	
								}
								{ this.props && this.props.state && this.props.state.od_status && this.props.state.od_status === 1 ?
									<div className="new_sessionName_name less_font_desc text_wrap_demand"><span className="fw500">Description:</span> <span className="pl15">{this.props.state.od_session_info[0].description}</span></div>
									:
									<div className="new_sessionName_name less_font_desc text_wrap_demand"><span className="fw500">Description:</span> <span className="pl15">{this.state.participantData.description}</span></div>
								}
							</div>
                      </div>
					 */}
					<div className="btm_part_part" >
                      <div className="bottom_part_holder">
						<div className={"new_half_holder_reg new_backgorund_color " + (this.state.both_show ? "" : "new_width_reg ") + (this.state.logedUser != '' ? 'poss_midd' : '')}>
								  {/*
									<div className="d-flex translatey mb-1 flex-wrap">	
										  <p className="mb-0 mt-2"><img src={!this.state.participantData.hostImage || this.state.participantData.hostImage==="default.png" ? "/images/host-user.png" : this.state.participantData.hostImage} className="radius-10 max-wd-120 border mr-2 no_bord_reg session_user_img" /></p>
									</div>
									*/}
									{/*  
									  <div className="border-bottom pb-2 mt--68">
										<div className="row justify-content-between">
										  <div className="mx-2">
										
										  { this.state.participantData.shoppingList && this.state.participantData.shoppingList.length > 0 ?
										  <p className="text-white font-16 mb-1">
											Shopping List:
											{ this.state.participantData.order_pending == 0 ?
												<button type="button" className="btn btn-primary px-2 py-1 mt-1 font-8 font-weight-bold ml-2" onClick={this.openShopList}>Show Details</button>
											: '' }
											{ this.state.participantData.order_pending == 1 ?
												<button type="button" className="btn btn-primary px-2 py-1 mt-1 font-8 font-weight-bold ml-2" onClick={this.openShopList}>Order Pending Review</button>
											: '' }
										  </p> : '' }
										  </div>
										  </div>
									  </div>
									*/}
										
									 <div className="bottom_prt1" >
										{this.props.state.od_status && this.props.state.od_status === 1 ?
											<div className="prt1_padding" >
												<div className="bottom_part_h1" >On Demand Class Details</div>
												<div className="detail_holder_bottom" >
													<div className="detail_prt1">Duration</div>
													<div className="detail_prt1 white_text2">{this.getOnDemandDuration(this.props.state.od_session_info[0].od_duration)}</div>
												</div>
												<div className="detail_holder_bottom" >
													<div className="detail_prt1">Difficulty</div>
													<div className="detail_prt1 white_text2">{this.props.state.od_session_info[0].level}</div>
												</div>											
											</div>
											:
											<div className="prt1_padding" >
												{this.state.participantData &&
												<div>
													<div className="bottom_part_h1" >Class Details</div>
													<div className="detail_holder_bottom" >
														<div className="detail_prt1">Duration</div>
														<div className="detail_prt1 white_text2">{this.state.participantData.duration} min</div>
													</div>
													<div className="detail_holder_bottom" >
														<div className="detail_prt1">Difficulty</div>
														<div className="detail_prt1 white_text2">{this.state.participantData.level}</div>
													</div>
													{this.state.participantData.equipmentListName != '' &&
														<div className="detail_holder_bottom" >
															<div className="detail_prt1 vrt_tp">Equipment</div>
															<div className="detail_prt1 white_text2 breakW">{this.state.participantData.equipmentListName}</div>
														</div>
													}
													<div className="detail_holder_bottom" >
														<div className="detail_prt1">Instructor Timezone</div>
														<div className="detail_prt1 white_text2">{this.state.participantData.timeZone}</div>
													</div>
													<div className="detail_holder_bottom" >
														<div className="detail_prt1">Class begins</div>
														<div className="detail_prt1 white_text2">{this.getSessionDateUtil(this.state.participantData.scheduleDate)} @ {this.getSessionTimeUtil(this.state.participantData.scheduleDate)}</div>
													</div>
												</div>
												}
											</div>
										}
										{this.state.participantData.parentRecrId &&
											<div>
												<div className="btm_border" ></div>
												<div className="prt1_padding prt2_padding_bottom" >
													<div className="bottom_part_h1 biceps_img" >Supercharge your workout </div>
													{ this.state.participantData.allowSS === 1 &&
														<div className="top_part_h2" >This class is scheduled to repeat. Select one or more dates below to sign up for this class and step up your game! </div>	
													}
													{ this.state.participantData.allowSS === 0 &&
														<div className="top_part_h2" >This class is scheduled to repeat. Instructor is allowed to Sign up only to all classes.</div>	
													}
												</div>
												<label className="custom-control custom-checkbox d-flex padd_left_new">
													  <input className="form-radio" type="checkbox" name="checkBoxAll" id="selectAllCbx"
													  onChange={this.CheckAllBox}
													  checked = {this.state.allChecked}
													   disabled={this.state.participantData.allowSS == 0 ? true : false}
													  />
													  <p className="mt-1 mb-2">Select/Unselect All</p>
												</label>
											</div>
										}
										{this.props.state.od_status && this.props.state.od_status === 1 ?
											<div>
											{ this.props.state.od_session_info[0].image &&
												<div className="new_holder_od_part" style={{ backgroundImage: this.props.state.od_session_info[0].image ? "url(" + this.props.state.od_session_info[0].image + ")" : 'url(/images/img1.jpg)' }} > </div>
											}
											</div>
											:
										  <div className={"recurring_session_detail no_border_red " + (srData.length < 2 ? 'd-none' : '')}>
											<div className="row">{srData}</div>
										  </div>
										}
									</div>		  
											
										
										
									  <div className="mt-2">
									 
									  {	this.state.inValidAccess?'':
										  !this.state.inValidAccess || (this.state.participantData.maxAttendee<this.state.participantData.totalSignUp)?this.state.msg:''}
									  </div>
									 
								  </div>
                            { ( ((this.state.tryWithOtherAccount && this.state.logedUser != '') || this.state.logedUser == '') && ( this.state.participantData.sessionDetails && this.state.participantData.sessionDetails.length > 1 && this.state.both_show ) || ( ((this.state.tryWithOtherAccount && this.state.logedUser != '') || this.state.logedUser == '') && this.state.participantData.sessionDetails && this.state.participantData.sessionDetails.length === 1 && this.state.both_show ) || ( ((this.state.tryWithOtherAccount && this.state.logedUser != '') || this.state.logedUser == '') && this.props.state && this.props.state.od_status === 1 && this.state.both_show) ) &&
							<div className="other_half_holder_reg" >
								<div className="new_sessionCreate" >Create Your Account</div>
								<div className="gray_label">
									<div className={ "new_user_hold "} onClick={e => this.changeSelectedNew('existing')} ><span className={ "new_sp1 " + ( this.state.user_selected === 'existing' ? "active_create" : "" ) }> Log in </span> </div>
									<div className={ "new_user_hold "} onClick={e => this.changeSelectedNew('new')}> <span className={ "new_sp1 " + ( this.state.user_selected === 'new' ? "active_create" : "" )}> Create an account </span> </div>
								</div>
								{ this.state.user_selected === 'new' &&
									<div className="email_info">Enter your email, then click outside email field so we can check if member with your email already exists.</div>
								}
								{ this.state.user_selected === 'new' &&
								<div>
									 <form className = "form-horizontal pt-1 participant_form_sign"    onSubmit={this.verify} >
										  <div className="mw-770 mx-auto py-4">
											<div className="gray-box-4 px-2 px-sm-4 pt-4 pb-0 input_bx-cont no_background_reg">
											  <div className="form-group">
												<div className="label text_wrap_demand label_new_width">Enter First Name (Max 30 characters)<sup>*</sup><span className="inp_cover-border"></span></div>
												<input type="text" 
												className={(this.state.errorClass_FirstName?"input-field red-outline":"input-field")} 
												value={this.state.firstName} 
												ref={fname=> (this._firstName = fname)} 
												id ='firstName' 
												onChange={(e)=>{
												  this.setState({
													[e.target.id]:e.target.value,
													errorClass_FirstName:false
												  })}}  
												  onBlur={(e)=>{this.onBlurCheck('name')}}
												  />
												{this.validator.message('firstName', this.state.firstName, 'required|alpha_space|min:1|max:30')}
												
											  </div>
											  <div className="form-group">
												<div className="label text_wrap_demand label_new_width">Enter Last Name (Max 30 characters)<sup>*</sup><span className="inp_cover-border"></span></div>
												<input type="text" 
												className={(this.state.errorClass_LastName?"input-field red-outline":"input-field")} 
												value={this.state.lastName} 
												ref={lname=> (this._lastName = lname)} 
												id ='lastName' 
												onChange={(e)=>{
												  this.setState({
													[e.target.id]:e.target.value,
													errorClass_LastName:false
												  })}}
												  onBlur={(e)=>{this.onBlurCheck('lastName')}}
												  />
												{this.validator.message('lastName', this.state.lastName, 'required|alpha_space|min:1|max:30')}
												
											  </div>
											  <div className="form-group">
												<div className="label text_wrap_demand label_new_width">Email Address<sup>*</sup><span className="inp_cover-border"></span></div>
												{this.props.state.od_status && this.props.state.od_status === 1 ?
												<input type="text" className={"pr-5 input-field myinput " + (this.state.email_error_status ? 'red-outline' : '') } 
												value={this.state.email} 
												id ='email' 
												onChange={this.sessionInfo} 
												onBlur={this.validateEmailMethod_newOd} 
												onKeyUp={this.removeErrorStatus}
												/>
												:
												<input type="text" className={"pr-5 input-field myinput " + (this.state.email_error_status ? 'red-outline' : '') } 
												value={this.state.email} 
												id ='email' 
												onChange={this.sessionInfo}
												onKeyUp={this.removeErrorStatus}
												onBlur={this.validateSession}
												/>
												}
												{this.validator.message('email', this.state.email, 'required|email')}
												<div className="srv-validation-message">eg. xyz@virdio.com</div>
												
											  </div>
											 
											 {!this.state.passwordEye &&
												 <div className="form-group">
													<div className="label text_wrap_demand label_new_width">Create  Password<sup>*</sup><span className="inp_cover-border"></span></div>
													<input type="password" onKeyUp={this.checkValidation} onKeyUp={this.checkValidation} 
													className={(this.state.errorClass_Password?"input-field red-outline":"input-field")} 
													value={this.state.password} ref={pwd=> (this._password = pwd)} id ='password'
													autoComplete="new-password"
													name="password"
													onChange={(e)=>{
													  this.setState({
														[e.target.id]:e.target.value,
														errorClass_Password:false
													  })}} />
													{this.validator.message('password', this.state.password, 'required')}
													<div className="srv-validation-message">Password minimum eight characters, at least one letter, one number and one special character eg.1234567A@</div>
													<span className="password_eye" onClick={e=>{ this.passwordEyeCheck(1, 'pass')}}></span>
													<span className="password_eye_text">Show</span>
												  </div>
											  }
											  {this.state.passwordEye &&
												 <div className="form-group">
													<div className="label text_wrap_demand label_new_width">Create  Password<sup>*</sup><span className="inp_cover-border"></span></div>
													<input type="text" onKeyUp={this.checkValidation} onKeyUp={this.checkValidation} 
													className={(this.state.errorClass_Password?"input-field red-outline":"input-field")} 
													value={this.state.password} ref={pwd=> (this._password = pwd)} id ='password'
													autoComplete="new-password"
													name="password"
													onChange={(e)=>{
													  this.setState({
														[e.target.id]:e.target.value,
														errorClass_Password:false
													  })}} />
													{this.validator.message('password', this.state.password, 'required')}
													<div className="srv-validation-message">Password minimum eight characters, at least one letter, one number and one special character eg.1234567A@</div>
													<span className="password_eye password_eye_shown" onClick={e=>{ this.passwordEyeCheck(0, 'pass')}}></span>
													<span className="password_eye_text">Hide</span>
												  </div>
											  }
											  {!this.state.passwordEyeRepeat &&
											  <div className="form-group">
												<div className="label text_wrap_demand label_new_width">Retype Password<sup>*</sup><span className="inp_cover-border"></span></div>
												<input type="password" 
												className={(this.state.errorClass_RePassword?"input-field red-outline":"input-field")} 
												value={this.state.rePassword} ref={rpwd1=> (this._rePassword = rpwd1)} 
												id ='rePassword' 
												autoComplete="new-password"
												name="confirmpassword"
												onChange={(e)=>{
												  this.setState({
													[e.target.id]:e.target.value,
													errorClass_RePassword:false
												  })}} 
												    onBlur={(e)=>{this.onBlurCheck('rePassword')}}
												  />
												{this.validator.message('rePassword', this.state.rePassword, 'required')}
												{ this.state.showHelpPopup &&
												<div className="password_check_form participant_pass_pop">
													<div className="pass_check_title">Your password needs to:</div>
													<div className="pass_main_line">
														<div className={ "pass_done_icon " + ( !this.state.contain8Ch ? "pass_iks_icon" : "" ) }></div>
														<div className="pass_line_text">Minimum of 8 characters</div>
													</div>
													<div className="pass_main_line">
														<div className={ "pass_done_icon " + ( !this.state.letterContain ? "pass_iks_icon" : "" ) }></div>
														<div className="pass_line_text">Minimum of 1 Letter</div>
													</div>
													<div className="pass_main_line">
														<div className={ "pass_done_icon " + ( !this.state.numberContain ? "pass_iks_icon" : "" ) }></div>
														<div className="pass_line_text">Minimum of 1 Number</div>
													</div>
													<div className="pass_main_line">
														<div className={ "pass_done_icon " + ( !this.state.specialSymbol ? "pass_iks_icon" : "" ) }></div>
														<div className="pass_line_text">Minimum of 1 Special Character</div>
														<div className="pass_line_text spec_char">(e.g. !,@,#,$,%,&,*)</div>
													</div>
												</div>
												}

												<span className="password_eye" onClick={e=>{ this.passwordEyeCheck(0, 'repeat')}}></span>
												<span className="password_eye_text">Show</span>
											  </div>
											  }
											  
											  {this.state.passwordEyeRepeat &&
											  <div className="form-group">
												<div className="label text_wrap_demand label_new_width">Retype Password<sup>*</sup><span className="inp_cover-border"></span></div>
												<input type="text" 
												className={(this.state.errorClass_RePassword?"input-field red-outline":"input-field")} 
												value={this.state.rePassword} ref={rpwd1=> (this._rePassword = rpwd1)} 
												id ='rePassword' 
												autoComplete="new-password"
												name="confirmpassword"
												onChange={(e)=>{
												  this.setState({
													[e.target.id]:e.target.value,
													errorClass_RePassword:false
												  })}} 
												    onBlur={(e)=>{this.onBlurCheck('rePassword')}}
												  />
												{this.validator.message('rePassword', this.state.rePassword, 'required')}
												{ this.state.showHelpPopup &&
												<div className="password_check_form participant_pass_pop">
													<div className="pass_check_title">Your password needs to:</div>
													<div className="pass_main_line">
														<div className={ "pass_done_icon " + ( !this.state.contain8Ch ? "pass_iks_icon" : "" ) }></div>
														<div className="pass_line_text">Minimum of 8 characters</div>
													</div>
													<div className="pass_main_line">
														<div className={ "pass_done_icon " + ( !this.state.letterContain ? "pass_iks_icon" : "" ) }></div>
														<div className="pass_line_text">Minimum of 1 Letter</div>
													</div>
													<div className="pass_main_line">
														<div className={ "pass_done_icon " + ( !this.state.numberContain ? "pass_iks_icon" : "" ) }></div>
														<div className="pass_line_text">Minimum of 1 Number</div>
													</div>
													<div className="pass_main_line">
														<div className={ "pass_done_icon " + ( !this.state.specialSymbol ? "pass_iks_icon" : "" ) }></div>
														<div className="pass_line_text">Minimum of 1 Special Character</div>
														<div className="pass_line_text spec_char">(e.g. !,@,#,$,%,&,*)</div>
													</div>
												</div>
												}

												<span className="password_eye password_eye_shown" onClick={e=>{ this.passwordEyeCheck(1, 'repeat')}}></span>
												<span className="password_eye_text">Hide</span>
											  </div>
											  }
											  
											</div>
											<div className="text-center">
											<div className="new_password_holder" > 
												<div className="pass_h1" >Your password needs:</div>
												<div className="pass_h1 text_pass">
													<div className="pass_inl" >
														<span className={ "span_abs pass_done_icon " + ( !this.state.letterContain  ? "pass_iks_icon" : "" ) }></span>	
														One letter character
													</div>
													<div className="pass_inl" >
														<span className={ "span_abs pass_done_icon " + ( !this.state.contain8Ch ? "pass_iks_icon" : "" ) }></span>	
														8 character minimum
													</div>
												</div>
												<div className="pass_h1 text_pass">
													<div className="pass_inl" >
														<span className={ "span_abs pass_done_icon " + ( !this.state.numberContain  ? "pass_iks_icon" : "" ) }></span>
														One number character
													</div>
													<div className="pass_inl pos_rel_pass" >
														<span className={ "span_abs pass_done_icon " + ( !this.state.specialSymbol ? "pass_iks_icon" : "" ) }></span>
														One special character
														<div className="span_abs" > (e.g. !, @, #, $, %, &, *) </div>
													</div>
												</div>
											</div>
											<button type="submit" className="save-btn btn btn-primary mt-3 font-book mx-auto font-18 main_color_submit">Create Your Account</button>
											</div>
											</div>
										   </form>
								</div>
								}
								{ this.state.user_selected === 'existing' &&
									<div className="login_holder_reg" >
									
									<Login 
										state={this.state}
										closeLogin={this.closeLogin}
										updateStateFromChild={this.updateStateFromChild}
										updateStateFromChildSession={this.updateStateFromChildSession}
										showLoginAlert={this.showLoginAlert}
										validateEmailMethod={this.validateEmailMethod}
										validateEmailMethod_newOd={this.validateEmailMethod_newOd}
										sendEmailFromLogin={this.sendEmailFromLogin}
										checkLoginFun={this.checkLoginFun}
										reloadSessionInfoAfterLogin={this.reloadSessionInfoAfterLogin}
										>
									</Login>
									
									</div>
								}
							</div>
							}
							{ (!this.state.tryWithOtherAccount && this.state.logedUser != '' && this.state.both_show) ?
								<div className="other_half_holder_reg oth_stl" >
									{	(!this.state.logedUserAlreadyParticate && !this.state.logedUserNowParticate && !this.props.state.od_status ) ?
										<div>
											<div className="loged_user">{this.state.logedUser}</div>
											{ this.state.sessionStartTimeHigher15Min ?
												<div><button onClick={this.signUpToSession} type="button" className="ch_button">SIGN UP FOR CLASS</button></div>
												:
												<div><button onClick={this.signUpToSession} type="button" className="ch_button">SIGN UP & JOIN CLASS</button></div>
											}
											<div className="loged_user marg_t30">OR</div>
											<div><button onClick={this.loginWithOtherUser} type="button" className="ch_button2">Sign up as a different User</button></div>
											<div className="displ_none">
												<Login 
													state={this.state}
													closeLogin={this.closeLogin}
													updateStateFromChild={this.updateStateFromChild}
													updateStateFromChildSession={this.updateStateFromChildSession}
													showLoginAlert={this.showLoginAlert}
													validateEmailMethod={this.validateEmailMethod}
													sendEmailFromLogin={this.sendEmailFromLogin}
													checkLoginFun={this.checkLoginFun}
													reloadSessionInfoAfterLogin={this.reloadSessionInfoAfterLogin}
													>
												</Login>
											</div>
										</div>
										:
										''
									}
									
									{ !this.state.logedUserAlreadyParticate && this.state.logedUserNowParticate && !this.props.state.od_status  ?
										<div>
											{ this.state.sessionStartTimeHigher15Min ?
												<div>
													<div className="loged_user">{this.state.logedUser}, you have been successfully signed up for the class.</div>
													<div>
														{ this.state.fromChannelToSessionLink == '' ?
															<div><button type="button" onClick={e=>window.location.href = process.env.REACT_APP_PATH+'/participant-dashboard' } className="ch_button">GO TO DASHBOARD</button></div>
															:
															<div><button onClick={e=>window.location.href = this.state.fromChannelToSessionLink } type="button" className="ch_button">GO TO CHANNEL</button></div>
														}
													</div>
												</div>
												:
												''
											}
											{ !this.state.sessionStartTimeHigher15Min ?
												<div>
													{ this.state.userHostThisSession ?
														<div className="loged_user">{this.state.logedUser}, you are hosting this class. Click below to allow Virdio to open App.</div>
														:
														<div className="loged_user">{this.state.logedUser}, you have been successfully signed up for the class.  Click below to allow Virdio to open App.</div>
													}
													<div className="text-center ch_padd">
														{ this.state.urlElectron &&
															<div className="for_join_sessionurl">
																<div className="recommends_url">For best experience use the Virdio App!</div>
																<div className="button_join_container">
																	{ mobileTab && ios ?
																		<a href={this.state.urlElectron} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div></a>
																		:
																		<div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div>
																	}
																
																</div>
																<div className="browser_run pt40">
																	{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank"><span className="run_web">Run in the browser instead</span></a> }
																</div>
															</div>
														}
														{ !this.state.urlElectron &&
															<div className="for_join_sessionurl">
																<div className="recommends_url">For best experience use the Virdio Web App!</div>
																<div className="button_join_container">
																	 <a href={this.state.urlPath} target="_blank"><div className="join_pop_btn">Run in the browser</div></a>
																</div>
															</div>
														}
													</div>
												</div>
												:
												''
											}
										</div>
										:
										''
									}
									{	(this.state.logedUserAlreadyParticate && !this.props.state.od_status ) ?
										<div>
											<div className="loged_user">{this.state.logedUserFirstName}, you are already signed up. { !this.state.sessionStartTimeHigher15Min ? <span>Click below to Join</span> : '' }</div>
											{ this.state.sessionStartTimeHigher15Min ?
												<div>
													{ this.state.fromChannelToSessionLink == '' ?
														<div><button type="button" onClick={e=>window.location.href = process.env.REACT_APP_PATH+'/participant-dashboard' } className="ch_button">GO TO DASHBOARD</button></div>
														:
														<div><button onClick={e=>window.location.href = this.state.fromChannelToSessionLink } type="button" className="ch_button">GO TO CHANNEL</button></div>
													}
												</div>
												:
												<div className="text-center ch_padd">
													{ this.state.urlElectron &&
														<div className="for_join_sessionurl">
															<div className="recommends_url">For best experience use the Virdio App!</div>
															<div className="button_join_container">
																{ mobileTab && ios ?
																	<a href={this.state.urlElectron} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div></a>
																	:
																	<div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div>
																}
															</div>
															<div className="browser_run pt40">
																{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank"><span className="run_web">Run in the browser instead</span></a> }
															</div>
														</div>
													}
													{ !this.state.urlElectron &&
														<div className="for_join_sessionurl">
															<div className="recommends_url">For best experience use the Virdio Web App!</div>
															<div className="button_join_container">
																 <a href={this.state.urlPath} target="_blank"><div className="join_pop_btn">Run in the browser</div></a>
															</div>
														</div>
													}
												</div>
											}
											<div className="loged_user marg_t30">OR</div>
											<div><button onClick={this.loginWithOtherUser} type="button" className="ch_button2">Sign up as a different User</button></div>
											<div className="displ_none">
												<Login 
													state={this.state}
													closeLogin={this.closeLogin}
													updateStateFromChild={this.updateStateFromChild}
													updateStateFromChildSession={this.updateStateFromChildSession}
													showLoginAlert={this.showLoginAlert}
													validateEmailMethod={this.validateEmailMethod}
													sendEmailFromLogin={this.sendEmailFromLogin}
													checkLoginFun={this.checkLoginFun}
													reloadSessionInfoAfterLogin={this.reloadSessionInfoAfterLogin}
													>
												</Login>
											</div>
										</div>
										:
										''
									}
									
									{ this.props.state.od_status == 1 &&
										<div>
										{!this.state.tryWithOtherAccount && this.state.logedUser != '' && this.state.both_show ?
											<div>
												<div>
													{this.state.deepLinkButton === 'Allow' ? 
														<div className="loged_user ch_padd">Allow Virdio to Open App.</div>
														:
														<div className="loged_user ch_padd">Please select which Virdio App you want to view the On-demand video in?</div>
													}
													
													<div className="text-center ch_padd">
														{ this.state.urlElectron &&
															<div className="for_join_sessionurl">
																<div className="recommends_url">For best experience use the Virdio App!</div>
																<div className="button_join_container">
																	{ mobileTab && ios ?
																		<a href={this.state.urlElectron} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); this.updateUserDemand() }}>VIRDIO APP</div></a>
																		:
																		<div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); this.updateUserDemand() }}>VIRDIO APP</div>
																	}
																</div>
																<div className="browser_run pt40">
																	{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank" onClick={() => { this.windowClose(); this.updateUserDemand() }}><span className="run_web">Run in the browser instead</span></a> }
																</div>
															</div>
														}
														{ !this.state.urlElectron &&
															<div className="for_join_sessionurl">
																<div className="recommends_url">For best experience use the Virdio Web App!</div>
																<div className="button_join_container">
																	 <a onClick={() => { this.windowClose(); this.updateUserDemand() }} href={this.state.urlPath} target="_blank"><div className="join_pop_btn">Run in the browser</div></a>
																</div>
															</div>
														}
													</div>
												</div>										
												<div className="loged_user marg_t30">OR</div>
												<div><button onClick={this.loginWithOtherUser} type="button" className="ch_button2">Login as different user</button></div>
												<div className="displ_none">
													<Login 
														state={this.state}
														closeLogin={this.closeLogin}
														updateStateFromChild={this.updateStateFromChild}
														updateStateFromChildSession={this.updateStateFromChildSession}
														showLoginAlert={this.showLoginAlert}
														validateEmailMethod={this.validateEmailMethod}
														sendEmailFromLogin={this.sendEmailFromLogin}
														checkLoginFun={this.checkLoginFun}
														reloadSessionInfoAfterLogin={this.reloadSessionInfoAfterLogin}
														>
													</Login>
												</div>
											</div>
											:
											''
											}
										</div>
									}
								
								</div>

								:
								''
							}
						</div>
					</div>	
                  </div>
                  <div className="text-center mt-3 d-none">			  
                
					{this.state.inValidAccess?
						
						<button type="button" className="btn btn-primary px-5 py-2" onClick={this.validateEmailMethod}>Sign up</button>
						:
						this.state.parentRecrId?
							<button type="button" className="btn btn-primary px-5 py-2" oonClick={this.validateEmailMethod}>Sign up</button>
							:
							<button type="button" className="btn btn-primary px-5 py-2" onClick={e=>browserHistory.push(this.state.redirectToRoute)}>Virdio</button>
					}
				  </div>
				  </div>
				</div>
			  :''}

            
          <div className="modal pr-0" id="registration_popup" aria-modal="true">
            <div className="modal-dialog px-2 dialogwidth modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
                      <h4 className="modal-title font-weight-bold white text-center">Welcome To Virdio</h4>
                      <p className="pt-4 text-white text-center font-22">Your sign up was successful.</p>
                      <p className="pb-4 text-light text-center font-20"> A mail is also sent to your inbox to let you check if your preferred device/system is ready for participating in the class.</p>

                      
                      </div>
                    <div className="text-center">
                      <button type="button" onClick={this.showNewPartScreen} className="custom_btn1 mt-3">ok</button>
                    </div>
                </div>
            </div>
          </div>
                 
        <div className="modal py-3" id="streaming_option">
							<div className="modal-dialog mw-770 modal-dialog-centered">
								<div className="modal-content bg-transparent px-2">
									<div className="modal-body px-1 py-1 py-sm-3 px-sm-3 modalbg radius-10">
										<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>$("#streaming_option").attr({'style':'display:none'})} ><span>x</span></button>
										<div className="p-md-4 p-3 text-center">
											<h4 className="white text-center font-25">Virdio Streaming Options</h4> 
											<p className="text-white font-18 mb-0 text-center mt-md-2 d-inline-block"><b>Join at: 
											<input type="text" ref={(textarea)=>this.textArea=textarea}  className="input-field2" placeholder="Class Name" value={process.env.REACT_APP_URL} onChange={(e)=>console.log()}/></b></p>
											<Link to = "/participantSignup" className="join_button ml-2 d-inline-block" onClick={e=>{this.textArea.select();document.execCommand('copy');e.target.focus();$("#streaming_option").attr({'style':'display:none'});}}>Copy URL</Link>
											<p className="text-white font-18 text-center mt-md-2">You can copy and paste a link for {process.env.REACT_APP_URL} if you have one of the browsers:</p> 
											<div className="table-responsive">
												<table className="table table-bordered">
												<tbody>
													<tr className="text-white">
													<td>MacBook</td>
													<td><a href="https://apps.apple.com/us/app/virdio/id1489704718" target="_blank">Virdio App</a> | <a href="https://www.google.com/chrome/" target="_blank">Chrome</a> - v 79 + | <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> - v 72 +</td>
													</tr>
													<tr className="text-white">
													<td>Windows</td>
													<td><a href="https://www.google.com/chrome/" target="_blank">Chrome</a> - v 78 + | <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> - v 72 +| <a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a> - v 79 +</td>
													</tr>
													<tr className="text-white">
													<td>IOS</td>
													<td><a href="https://apps.apple.com/us/app/virdio/id1489704718" target="_blank">Virdio App</a></td>
													</tr>
													<tr className="text-white">
													<td>Android</td>
													<td><a href="https://play.google.com/store/apps/details?id=mvvm.f4wzy.com.virdioApp&hl=en" target="_blank">Virdio App</a></td>
													</tr>
													<tr className="text-white">
													<td>Linix</td>
													<td><a href="https://www.google.com/chrome/" target="_blank">Chrome</a> - v 78 + | <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> - v 72 +| <a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a> - v 79 +</td>
													</tr>
												</tbody>
												</table>
											</div>                        
										</div>
									</div>
								</div>
							</div>
						</div>
          
						<div className="modal pr-0" id="session_alert" aria-modal="true">
							<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
                  <h4 className="modal-title font-weight-bold white text-center">Welcome To Virdio</h4>
                  <p className="mb-0 pb-4 pt-3 white text-center font-20">Your class starts in 30 minutes, would you like to join the class now or go to your dashboard?</p>
                  <p className="white text-center font-20"> A mail is also sent to your inbox to let you check if your preferred device/system is ready for participating in the class.</p>
                  </div>
									<div className="text-center flex-wrap d-flex justify-content-center">
                  <button type="button" className="custom_btn1 mt-3 mx-2"  onClick={e=>{$("#session_alert").attr({'style':'display:none'}); window.location.href = process.env.REACT_APP_PATH+'/participant-dashboard'; }}>Go to Dashboard</button>
                  {this.state.platformStatus?
					(
						this.state.urlElectron ?
						<a href="#"><div className=""><button className="custom_btn1 mt-3 mx-2" onClick={e=>{$("#session_alert").attr({'style':'display:none'}); this.showLoginNative()}}>join</button></div></a>
						:
						<a href ={this.state.urlPath} target="_blank"><div className=""><button className="custom_btn1 mt-3 mx-2" onClick={e=>{$("#session_alert").attr({'style':'display:none'});}}>join</button></div></a>
					)
                  :
                  <Link to ="/participantSignup" >	  
	                <div className=""><button className="custom_btn1 mt-3 mx-2" onClick={e=>{$("#session_alert").attr({'style':'display:none'});$("#streaming_option").attr({'style':'display:block'});}}>
                  join</button></div></Link>}
                  
                  </div>
								</div>
							</div>
						</div>
         
        <div className="modal pr-0" id="resend_verification" aria-modal="true" >
            <div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
                      <p className="py-4 text-light text-center mb-0 font-20"> A verification code has been resent to your email address. Please enter it once you receive the email.</p>
                        
                    </div>
                    <div className="text-center">
                      <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#resend_verification").attr({'style':'display:none'});}}>ok</button>
                    </div>
                </div>
            </div>
          </div>
         
          <div className="modal pr-0" id="commingSoon" aria-modal="true" >
            <div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
                      <p className="py-4 text-light text-center mb-0 font-20"> Coming Soon.</p>
                        
                    </div>
                    <div className="text-center">
                      <button type="button" className="custom_btn1 mt-3" onClick={e=>{$("#commingSoon").attr({'style':'display:none'});}}>ok</button>
                    </div>
                </div>
            </div>
          </div>


          <div className="modal pr-0" id="email_verification" aria-modal="true">
            <div className="modal-dialog px-2 dialogwidth mx-auto modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
                       <p className="py-4 text-light text-center font-20">A verification code has been sent to your email address. Please enter the code on the following screen after pressing "OK"</p>
                    </div> 
                    <div className="text-center">
                      <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#email_verification").attr({'style':'display:none'});}}>ok</button>
                    </div>                       
                </div>
            </div>
          </div>
		  
		  
		  <div className="modal pr-0" id="checkbox_error_msg" aria-modal="true" >
            <div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
            <div className="modal-content bg-transparent">
              <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
                <p className="py-4 text-light text-center font-20">{this.state.msg}</p> 
              </div>
              <div className="text-center">
              <button type="button" 
              className="custom_btn1 mt-3" 
              data-dismiss="modal" 
              onClick={e=>{
                $("#checkbox_error_msg").attr({'style':'display:none'});
              }}>ok</button>
              </div>
          </div>
        </div>
      </div>     

		<div className="modal pr-0" id="existing_error" aria-modal="true" >
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
				  <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
					<p className="py-4 text-light text-center font-20">{this.state.msg}</p> 
				  </div>
				  <div className="text-center">
					<button type="button" className="custom_btn1 mt-3 mx-3" onClick={e =>{this.existingPopSelect('1')}} >Check Email</button>
					<button type="button" className="custom_btn1 mt-3 mx-3" onClick={e =>{this.existingPopSelect('2')}} >Create Account</button>
				  </div>
			  </div>
			</div>
      </div>  	  

          <div className="modal pr-0" id="error_msg" aria-modal="true" >
            <div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
            <div className="modal-content bg-transparent">
              <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
                <p className="py-4 text-light text-center font-20">{this.state.msg}</p> 
              </div>
              <div className="text-center">
              <button type="button" 
              className="custom_btn1 mt-3" 
              data-dismiss="modal" 
              onClick={e=>{
                $("#error_msg").attr({'style':'display:none'});
              if(!this.validator.fieldValid('firstname')){
                
                if(!this.validator.getErrorMessages().firstName){
                  this.setState({
                    errorClass_FirstName:false
                  })
                }
                else{
                this.setState({
                  errorClass_FirstName:true
                })
              }
                this._firstName.focus();
              }
              if(!this.validator.fieldValid('lastName')){
                this.setState({
                  errorClass_LastName:true
                })
                this._lastName.focus();
              }
              
              if(!this.validator.fieldValid('email') ){
                this.setState({
                  errorClass_Email:true
                })
              }
              if(!this.validator.fieldValid('password') || this.state.msg==="Password mismatch!"){
                this.setState({
                  errorClass_Password:true
                })
                this._password.focus();
              }
              if(!this.validator.fieldValid('rePassword')){
                this.setState({
                  errorClass_RePassword:true
                })
                this._rePassword.focus();
              }
                    }}>ok</button>
              </div>
          </div>
        </div>
      </div>       

        <div className="modal pr-0" id="error_otp_msg" aria-modal="true">
          <div className="modal-dialog px-2 dialogwidth mx-auto modal-dialog-centered">
            <div className="modal-content bg-transparent">
              <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
                <p className="py-4 text-light text-center font-20">{this.state.otperror}</p>
              </div>
              <div className="text-center"> 
                  <button type="button" 
                  className="custom_btn1 mt-3" 
                  data-dismiss="modal" 
                  onClick={e=>{
                    $("#error_otp_msg").attr({'style':'display:none'});
                  }}>ok</button>
                </div>	 
            </div>
          </div>
      </div>  

      <div className="modal pr-0" id="existingUserModel12" aria-modal="true" >
            <div className="modal-dialog dialogwidth mx-auto px-2 modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body modl_bg_drk shadow-none px-4 pb-4 radius-8">
                      <p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
                    </div>
                    <div className="text-center">
                    <button type="button" className="custom_btn1 mt-3" 
                     data-dismiss="modal"
                     onClick={e=>{$("#existingUserModel12").attr({'style':'display:none'});
                     this.setState({
                    validateEmail:false,
                    signup:true,
                    msg:''
                    })}}>ok</button>
                    </div>
                </div>
            </div>
          </div>

           <div className="modal pr-0" id="existingUserModel13" aria-modal="true" >
            <div className="modal-dialog dialogwidth mx-auto px-2 modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body modl_bg_drk shadow-none px-4 pb-4 radius-8">
                      <p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
                    </div>
                    <div className="text-center">
                    <button type="button" className="custom_btn1 mt-3" 
                     data-dismiss="modal"
                     onClick={e=>{
                       $("#existingUserModel13").attr({'style':'display:none'});
                       browserHistory.push('/')
                     }}>ok</button>
                    </div>
                </div>
            </div>
          </div>         

          <div className="modal pr-0" id="existingUserModel" aria-modal="true" >
            <div className="modal-dialog dialogwidth mx-auto px-2 modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body modl_bg_drk shadow-none px-4 pb-4 radius-8">
                      <p className="py-4 text-light text-center font-20 over350">{this.state.msg}</p> 	
                    </div>
                    <div className="text-center">
                    <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#existingUserModel").attr({'style':'display:none'});}}>ok</button>
                    </div>
                </div>
            </div>
          </div>
		  
		   
		  
		 <div className={ "main_pop_sign zi1000 shoplistdiv " + ( this.state.openShopList ? "active" : "" ) }>
			<div className="for_main_form centered_pop">
				<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
					<div className="title_subd marb25">Shopping List
						<button type="button" className="close white closepurple translatX-Y abs_close_sub" onClick={this.closeShopPop}><span>x</span></button>
					</div>
					<div className="one_shopping_list paddb20">
						<div className="shop40">Items</div>
						<div className="shop20">Quantity</div>
						<div className="shop20">Price</div>
						<div className="shop20">Total</div>
					</div>
					{this.state.shoppingList.map((shop, i)=>
						<div className="one_shopping_list paddb20" key={i} >
							<div className="shop40 relative_shop" onClick={ () => this.changeSelected(i) }>{shop.link}
								<div className={"abs_cbx " + ( shop.selected ? "active" : "" ) }>
									<div className="abs_cbx_checked">&#10004;</div>
								</div>
							</div>
							<div className="shop20">
								<div className="inline_quantity left_quant math_signs smaller_signs" onClick={ () => this.changeQuantity('-', i) }>-</div>
								<div className="inline_quantity main_quant smaller_signs">{shop.quantity}</div>
								<div className="inline_quantity right_quant math_signs smaller_signs" onClick={ () => this.changeQuantity('+', i) }>+</div>
							</div>
							<div className="shop20">
								<div>${shop.price}</div>
							</div>
							<div className="shop20">
								<div>${shop.total}</div>
						</div>
						</div>
					)}
					<div className="for_total">
						<div className="total_title">Total Selected</div>
						<div className="total_price">${this.state.totalShopPrice}</div>
					</div>
				</div>
				<div className="text-center">
					<button type="button" className="custom_btn1 mt-3" onClick={ () => this.orderFromHost() }>Order from Instructor</button>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="success_order" aria-modal="true">
			<div className="modal-dialog mw-570 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<div className="title_subd">Shopping List
							<button type="button" className="close white closepurple translatX-Y abs_close_sub" data-dismiss="modal"><span>x</span></button>
						</div>
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Your request is being sent to {this.state.participantData.channelName} for review. If {this.state.participantData.channelName} has any questions regarding your order, they will contact you directly.</p>
						</div>
					</div>
					<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">OK</button></div>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="full_session_alert">
			<div className="modal-dialog mw-570 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Class is already full.</p>
						</div>
					</div>
					<div className="text-center"><button type="button" className="custom_btn1 mt-3"  onClick={e=>{$("#full_session_alert").attr({'style':'display:none'});}}>OK</button></div>
				</div>
			</div>
		</div>
		
		<div className="main_loader">
			<div className="main_loader_inner"></div>
		</div>
		  
		<div className="modal pr-0" id="registration_popup2" aria-modal="true">
				<div className="modal-dialog px-2 dialogwidth modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
						  <h4 className="modal-title font-weight-bold white text-center">Welcome To Virdio</h4>
						  <p className="pt-4 text-white text-center font-22">Your sign up was successful.</p>
						  <p className="pb-4 text-light text-center font-20"> A mail is also sent to your inbox to let you check if your preferred device/system is ready for participating in the class.</p>

						  
						  </div>
						<div className="text-center">
						  <button type="button" onClick={this.backToChannelPg} className="custom_btn1 mt-3">ok</button>
						</div>
					</div>
				</div>
			</div>
		  <div className="modal" id="chargePopup" aria-modal="true">
			<div className="for_main_form centered_pop">
				<div>
					<div className="gray-box-4 px-4 pt-5 pb-4 input_bx-cont for_sign relative back_cl2a">
						<button type="button" className="close white closepurple translatX-Y abs_close" onClick={this.closeChargePop}><span>x</span></button>
						{ this.state.sessionsTotal <= 1 && 
							<p className="py-4 text-light text-center font-20">{this.state.sessionHostFirstName} {this.state.sessionHostLastName} is charging ${this.state.chargeForSession} for the class. A Credit Card is required to hold on your spot, which will be charged at the start of the class. You can cancel up to 4 hours prior to the start of the class.</p>
						} 
						{ this.state.sessionsTotal > 1 && 
							<p className="py-4 text-light text-center font-20">{this.state.sessionHostFirstName} {this.state.sessionHostLastName} is charging ${this.state.chargeForSession} per class, which comes to ${this.state.finalChargeForSession} for the {this.state.sessionsTotal} classes you have selected. A Credit Card is required to hold your spot, which will be charged at the start of each class. You can cancel up to 4 hours prior to the start of a class.</p>
						}
					</div>
					<div className="text-center">
						<button type="button" className="save-btn btn btn-primary mt-3 font-book mx-auto font-18" onClick={this.continuePay}>Continue</button>
					</div>
				</div>
			</div>
			</div>
			{ this.state.isChargingSessionIncluded &&
				<div className="modal over_payment_modal" id="sessionPay" aria-modal="true">
					<div className="for_main_form centered_pop">
						<ChargingSession state={this.state} updateStateParticipantSignUpFromChildSession={this.updateStateFromChild}></ChargingSession>
					</div>
				</div>
			}
        </div>          
      </div>
      <div className="main_loader"><div className="main_loader_inner"></div></div>  
	  
		<div className="modal pr-0 signupPagePopup" id="autologinnative" aria-modal="true">
			<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="join_pop_container">
						<button type="button" className="close white closepurple join_close_iks" data-dismiss="modal"><span>x</span></button>
						{ this.state.urlElectron !== '' &&
							<div className="join_parent">
								<div className="join_title">Virdio recommends the Virdio App for the best experience</div>
								<div className="button_join_container">
									{ mobileTab && ios ?
										<a href={this.state.urlElectron} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div></a>
										:
										<div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); }}>VIRDIO APP</div>
									}
									
								</div>
								<div className="browser_run">
									{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank"><span className="run_web">Run in the browser instead</span></a> }
								</div>
							</div>
						}
						{ this.state.urlElectron === '' &&
							<div className="join_parent">
								<div className="join_title">Virdio recommends Web browser for the best experience</div>
								<div className="button_join_container">
									 <a href={this.state.urlPath} target="_blank"><div className="join_pop_btn">Run in the browser</div></a>
								</div>
								<div className="browser_run"></div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
		
		<div className="download_back_container signupPagePopup" id="nativedownload">     
			<div className="download_content">
				<div className="down_close" onClick={e=> { $("#nativedownload.signupPagePopup").hide(); }}></div>
				<div className="for_down_logo">
					<div className="download_logo"></div>
				</div>
				<div className="download_text">Download the Virdio App to install on your device.</div>                
				<div className="download_text">Once downloaded, double click on the installer then drag and drop the Virdio icon into the Applications folder.</div>                
				<div className="for_down_btn">
					<a href={this.state.appDownload} target="_blank"><div className="down_btn" onClick={e=> { $("#nativedownload.signupPagePopup").hide(); window.$("#nativedownload2.signupPagePopup").modal('show') }}>Download</div></a>
				</div>
			</div>
		</div> 
			
		<div className="modal pr-0 signupPagePopup nativedown2_sign" id="nativedownload2" aria-modal="true">
			<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
						{ ( chrome || edge ) && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible on the bottom left of the browser, click on the file to Install Virdio.</p> }
						{ !chrome && !edge && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible at the top right of the browser, click on the file to Install Virdio.</p> }
						<p className="mb-0 py-4 white text-center">2. After the Installation is complete, click on the JOIN button below.</p>
					</div>
					<div className="text-center"><button type="button" className="custom_btn1 col-4 mt-3" onClick={() => { this.openNativeUrl(this.state.urlElectron); window.$("#nativedownload2.signupPagePopup").modal('hide') }}>JOIN</button></div>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="waiverAgree" aria-modal="true">
			<div className="modal-dialog px-2 dialogwidth modal-dialog-centered">
				<div className="modal-content bg-transparent waiver_scroll">
					<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
					  <div className="d-flex justify-content-end">
						<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>{$("#waiverAgree").attr({'style':'display:none; height:auto'});}}><span>x</span></button>
					  </div>
					  <h4 className="modal-title font-weight-bold white text-center">Waiver</h4>
					  <p className="pt-4 text-white text-center font-22">Before joining the class, you need to agree to our conditions.</p>
						<div className="relative">
							<iframe src="about:blank" className="waiver_iframe" frameborder="0"></iframe>
							<div className="show_loader_iframe">
								<div className="main_loader_inner"></div>
							</div>
						</div>
						<div className="pdf_trouble">If you have trouble with loading Waiver PDF please click <span className="waiver_span" onClick={this.openWaiver}>here</span>.</div>
					  </div>
					<div className="text-center">
						{this.state.loginAgree &&
							<button type="button" onClick={() => {this.checkIfUserHasWaiver(this.state.userMainId, true, 'login')}} className="custom_btn1 mt-3">AGREE</button>
						}
						{this.state.registerAgree &&
							<button type="button" onClick={() => {this.checkIfUserHasWaiver(this.state.regUserId, true, 'register')}} className="custom_btn1 mt-3">AGREE</button>
						}
					</div>
				</div>
			</div>
		</div>
		
		
		<div className="modal pr-0" id="autologinnative" aria-modal="true">
			<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="join_pop_container">
						<button type="button" className="close white closepurple join_close_iks" data-dismiss="modal"><span>x</span></button>
						{ this.state.showWindowsApp && this.state.urlElectron !== '' &&
							<div className="join_parent">
								<div className="join_title">Virdio recommends the Virdio App for the best experience</div>
								<div className="button_join_container">
									{ mobileTab && ios ?
										<a href={this.state.urlElectron} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron);}}>Virdio APP</div></a>
										:
										<div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron);}}>Virdio APP</div>
									}
								</div>
								<div className="browser_run">
									{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank"><span className="run_web">Run in the browser instead</span></a> }
								</div>
							</div>
						}
						{ !this.state.showWindowsApp || this.state.urlElectron === '' &&
							<div className="join_parent">
								<div className="join_title">Virdio recommends Web browser for the best experience</div>
								<div className="button_join_container">
									 <a href={this.state.urlPath} target="_blank"><div className="join_pop_btn">Run in the browser</div></a>
								</div>
								<div className="browser_run"></div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
		
		
		<div id="closethistab" className="modal new_blck zindex999999" data-backdrop="static" data-keyboard="false">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<pre id="login-alert-text" className={"py-4 text-light text-center font-20 popup_error_text"+(this.props.popupAlign ? ' popup_align_left' : '')}>Class is open. You can close this window now</pre>
					</div>
				</div>
			</div>
		</div>
		
		<div id="closethistab" className="modal new_blck zindex999999" data-backdrop="static" data-keyboard="false">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<pre id="login-alert-text" className={"py-4 text-light text-center font-20 popup_error_text"+(this.props.popupAlign ? ' popup_align_left' : '')}>Class is open. You can close this window now</pre>
					</div>
				</div>
			</div>
		</div>
		<div className="modal pr-0" id="user_not_verified2" aria-modal="true">
			<div className="modal-dialog mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent inline_pop">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
						<p className="mb-0 py-4 white text-center font-20">Your username is already registered, but has not been verified. To complete your registration please select OK and you will be guided through the process.</p>
					</div>
					<div className="text-center flex-wrap d-flex justify-content-center">
						<div className="">
							<div className="main_link" ><button className="custom_btn1 mt-3 mx-2" onClick={this.goToLogin}>OK</button></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="modal pr-0 zInd2000" id="private_recurring_session" aria-modal="true">
			<div className="modal-dialog mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent inline_pop">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
						<p className="mb-0 py-4 white text-center font-20 no_pad_bott">{this.state.privateRecMsg1}</p>
						<p className="mb-0 py-4 white text-center font-20 no_pad_bott canceled_sessions_warn">{this.state.privateRecMsg2}</p>
						<p className="mb-0 py-4 white text-center font-20">{this.state.privateRecMsg3}</p>
					</div>
					<div className="text-center">
						<button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#private_recurring_session").attr({'style':'display:none'});}}>ok</button>
                    </div>
				</div>
			</div>
		</div>
		<div className="modal pr-0" id="signup_session_registration_popup_after_verify_otp" aria-modal="true">
            <div className="modal-dialog dialogwidth modal-dialog-centered">
                <div className="modal-content bg-transparent">
                    <div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
                      <div className="d-flex justify-content-end"></div>
                      <h4 className="modal-title font-weight-bold white pt-3 text-center">Welcome To Virdio</h4>
                      <p className="py-4 text-white font-22 text-center">Your sign up was successful.</p> 
                    </div>
                    <div className="text-center">
                      <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={this.showNewPartScreen}>ok</button>
             
                    </div>
                </div>
            </div>
         </div>
		{ this.state.userSignedUp &&
			<div className="full_popup">
				<ParticipantSignedUp 
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					triggerOpened={this.triggerOpened}
					openNativeUrl={this.openNativeUrl}
					sessionStartTimeHigher15Min={this.state.sessionStartTimeHigher15Min}
					urlElectron={this.state.urlElectron}
					urlPath={this.state.urlPath}
					scheduleDate={this.props.state.od_status === 1 ? this.props.state.od_session_info[0].scheduleDate : ( (this.state.participantData.parentRecrId && this.state.signedSessionsIds && this.state.signedSessionsIds.length > 0 ) ? this.returnLowestDate() : this.state.participantData.scheduleDate)}
					timeZone={this.props.state.od_status === 1 ? '' : this.state.participantData.timeZone}
					sessionChannelName={this.state.sessionChannelName}
					isHost={this.state.isHost}
					sessionName={this.props.state.od_status === 1 ? this.props.state.od_session_info[0].name : this.state.participantData.name}
					isOnDemandSession={this.props.state.od_status === 1 ? true : false}
					logedUserAlreadyParticate={this.state.logedUserAlreadyParticate}
					onDemandId={this.props.state.od_status === 1 ? this.props.state.od_session_info[0].ondemandId : 0}
					>
				</ParticipantSignedUp>
			</div>
		}
		<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
	</div>
	)
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(participentSignup);