import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { connect } from "react-redux";
import { browserHistory} from 'react-router'
import axios from "axios";
import { deleteCookie } from '../auth/Logout';



class Header extends Component {
  
  constructor(props) {
    super(props);
	
	this.state = {
		subdomain: '',
		fullsubdomain: '',
		fullsubpath: '',
		emptySubdomain: false,
		existSubdomain: false,
		userAvatar: '',
		totalSessionAll: 0,
		isAdminReports: 0
	}
	
	this.deleteCookie = deleteCookie.bind(this);
}


componentDidMount(){
	this.setState({ fullsubdomain: this.props.fullsubdomain, userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image, loginName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName, isAdminReports: JSON.parse(localStorage.getItem('userData')).data.responseData.adminReports });
	
	/* Check Token Expired */
	let user_data = JSON.parse(localStorage.getItem("userData"));
	if ( user_data && user_data['data'] && user_data['data']['responseData'] && user_data['data']['responseData']['tokenExpire'] )
		{
			
			let count1 = 0;
			if ( localStorage.getItem('channelSessionCount') )
				{
					count1 = localStorage.getItem('channelSessionCount');
				}
				
			let count2 = 0;
			if ( localStorage.getItem('participantTotalSessions') )
				{
					count2 = localStorage.getItem('participantTotalSessions');
				}
				
			this.setState({
				totalSessionAll: (this.props && this.props && this.props.fromParticipantDashboard) ? count2 : count1
			});
			
			let expireTime = (user_data['data']['responseData']['tokenExpire'] - (new Date().getTime()));
			
			console.log(expireTime);
			
			if ( expireTime > 0 )
				{
					if ( window['expiretimer'] ) { clearTimeout(window['expiretimer']); }
					window['expiretimer'] = setTimeout(function ()
						{
							localStorage.removeItem("userData");
							$('#notloggedin').show();
						}, expireTime);
				}
			else
				{
					localStorage.removeItem("userData");
					$('#notloggedin').show();
				}
		}
	/* Check Token Expired */
}

setPlaceholderToValue=(e)=>{
	
	if ( this.props.channelData[0] )
		{
			let subdomain = this.props.channelData[0].name.toLowerCase().replace(/\s/g, '');

			this.setState({ subdomain: subdomain, existSubdomain: false, emptySubdomain: false });
		}
}

removeAlert=(e)=>{
	this.setState({ existSubdomain: false, emptySubdomain: false });
}

openSubdomain=(e)=>{
	window.open('https://'+this.state.fullsubdomain);
}

generatePage=(e)=>{
	
	let newsub = this.state.subdomain.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?!=<>{}]/gi, '');
	this.setState({ subdomain: newsub, existSubdomain: false });
	
	if ( newsub != '' ) 
		{
			var mainthis = this;
			
			axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/checksubdomain", {"subdomain" : newsub })          
			.then(res => {
					
					let data = res.data;
					if ( $.isEmptyObject(data) )
						{
							axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/updatesubdomain",{"channel_id": this.props.channelData[0].id, "subdomain" : newsub })          
								.then(res => {
									
								window.$('#generate_subdomain').modal('hide');
								window.$('#success_subdomain').modal('show');
								
								
								var adminpath = process.env.REACT_APP_PATH;
								adminpath = adminpath.replace('http://', '.').replace('https://', '.');
							
								this.setState({ fullsubdomain: newsub + adminpath });
							});
						}
					else
						{
							this.setState({ existSubdomain: true });
						}
			})

		}

}

openInfoPopUpNew = (e, mod) => {
	if(mod == 'over')
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active2');
			$(e.currentTarget).find('.tooltip_hold').addClass('tooltip_active2');
		}
		
	else
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active2');
		}
} 

  render() {
	  
	  
	  
	let path=window.location.pathname;
    return (<div>
				<div className="row top-header px-1 px-sm-4">
					<div className="col-lg-6 d-flex justify-content-center justify-content-sm-start flex-wrap px-2">
						<div className="align-self-center">
							<img src="/images/login-logo.png" className="logo" alt="logo" />
						</div>
						<div className="d-flex d-md-block justify-content-center p-4">
							<div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
								<div className="pl-4">
									<h3>Welcome {this.props.logedUser['firstName']}</h3>
									<p>You have {this.state.totalSessionAll ? this.state.totalSessionAll : 0} classes coming up</p>
									{/* <p>Next Class, Wednesday, 24 July 2019</p> */}
									 
									{/* @ak <p><button className="btn btn-outline-secondary font-weight-bold radius-8 align-self-center mt-2" onClick={this.switchToHost}>Switch To Member</button></p> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 ">
						<div className="d-flex justify-content-end flex-wrap">
							 
							 { path === "/UserPlaylists" ?
								<button className="btn btn-outline-secondary my-2 font-weight-bold radius-8  align-self-center ml-3" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId');this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}}>LOG OUT</button>
							 :
								 <div className="">
									{ this.props.adminReports &&
										<a class="cursor-pointer mr65 dsplInl" href="/ReportsDashboard"><button type="button" class="join_button text-uppercase manage_btn" id="0">Full Reports</button></a>
									}
									<div className={"setting-icon position-relative py-2 cursor-pointer " + (this.props.adminReports ? 'dsplInl' : '')} data-toggle="dropdown" onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave') } >
										<img src="../images/setting.png" />
										<div className="tooltip_hold pink_info more_top_pos">
											<div className="tooltip_text">Settings</div>
										</div>
									</div>
									<div className="dropdown-menu">
									<Link to ="/myProfile" className="dropdown-item"><img src="../images/gray-profile.png" className="mr-2" />Profile</Link>
									<Link to ="/DashboardLanding" className="dropdown-item d-none" data-toggle="modal" data-target="#coming_soon_alert" ><img src="../images/gray-setting.png" className="mr-2" />Security &amp; Settings</Link>
									{/* <Link to ="/participant-dashboard" className="dropdown-item"><img src="../images/gray-profile.png" className="mr-2" />Switch to Member</Link> */ }
									<Link to ="/HostSettings" className="dropdown-item"><img src="../images/gray-setting.png" className="mr-2" />Instructor Settings</Link>
									<Link to ="/UserPlaylists" className="dropdown-item" ><img src="../images/music.png" className="mr-2" />Playlist</Link>
									{/* <Link to ="/PayInfo" className="dropdown-item" ><img src="../images/money.png" className="mr-2" />Class Payments</Link> */}
									<Link to ="/support" className="dropdown-item"><img src="../images/gray-contact.png" className="mr-2" />Customer Support</Link>
									<Link to ='/' className="dropdown-item" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId');localStorage.removeItem('channelInterest'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}} >

									<img src="../images/gray-logout.png" className="mr-2"  />Logout</Link>
									</div>
								</div>
							 }
						</div>
					</div>
				</div>
				
				{this.props.channelData[0] && path !== "/UserPlaylists" && 
					<div className="modal pr-0" id="generate_subdomain" aria-modal="true">
						<div className="modal-dialog mw-570 mx-auto modal-dialog-centered">
							<div className="modal-content bg-transparent">
								<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
									<button type="button" className="close white closepurple p-0 translatX-Y" data-dismiss="modal" onClick={this.removeAlert}><span>&times;</span></button>
									<div className="title_subd">Generate Channel Page Subdomain</div>
									<div className="subd_exam">Enter the subdomain you want for your channel.<br />At the bottom of the screen you will see the full web address as you type the subdomain name of your choice.</div>
									<div className = "form-group pb-3 mb-0 mt-4">
										<label className="mainLabel">Subdomain Name</label>
										<input type="text"  id="subdomain" value={this.state.subdomain.replace('https', '').replace('http', '').replace(/\s/g, '').replace(/[&\/\\#,+()$~%.'":*?!=<>{}]/gi, '')} placeholder={this.props.channelData[0].name.toLowerCase().replace(/\s/g, '')} className = "form-control white_clr" onChange = {(e)=> this.setState({[e.target.id]:e.target.value})} />
										<span className="globeinpput"></span>
									</div>
									{ this.state.subdomain != '' &&
										<div className="subd_exam">Your URL is: {'https://'+this.state.subdomain.replace('https', '').replace('http', '').replace(/\s/g, '').replace(/[&\/\\#,+()$~%.'":*?!=<>{}]/gi, '') + this.state.fullsubpath} </div>
									}
									
									{ this.state.emptySubdomain &&
										<div className="subd_exam red_alert">Please enter valid subdomain.</div>
									}
									
									{ this.state.existSubdomain &&
										<div className="subd_exam red_alert">{this.state.subdomain.replace('https', '').replace('http', '').replace(/\s/g, '').replace(/[&\/\\#,+()$~%.'":*?!=<>{}]/gi, '') + this.state.fullsubpath}  has already been reserved, please choose a different subdomain.</div>
									}
								</div>
								<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={this.generatePage}>Generate Channel Page</button></div>
							</div>
						</div>
					</div>
				}
				
				{this.props.channelData[0] && path !== "/UserPlaylists" && 
				<div className="modal pr-0 dddd" id="show_subdomain" aria-modal="true">
					<div className="modal-dialog mw-570 mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
								<button type="button" className="close white closepurple p-0 translatX-Y" data-dismiss="modal"><span>&times;</span></button>
								<div className="title_subd">View Channel Page URL</div> 
								<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
									<p className="mb-0 py-4 white text-center cursor" onClick={this.openSubdomain}>{this.state.fullsubdomain}</p>
								</div>
							</div>
							<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">OK</button></div>
						</div>
					</div>
				</div>}
				
				
				{/* Coming Soon model  */}
				<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
					<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
								<p className="mb-0 py-4 white text-center">Coming Soon</p>
							</div>
							<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
						</div>
					</div>
				</div>
				
				
				<div className="modal pr-0" id="notloggedin" aria-modal="true">
					<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
								<p className="mb-0 py-4 white text-center">You have been logged off from Virdio as your class has expired. Please log back in to continue.</p>
							</div>
							<div className="text-center"><Link to="/login"><button type="button" className="custom_btn1 mt-3">Login</button></Link></div>
						</div>
					</div>
				</div>
		 </div>
    );
  }
}

const mapStateToProps = state => {
	return { 
		logedUser: state.user.logedUser,
		channelData: state.user.channelData,
		fullsubdomain: state.user.fullsubdomain,
		totalSession: state.user.totalSession,
	}
} 


export default connect(
  mapStateToProps,
  { }
)(Header);
