import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import { connect } from "react-redux";
import ReviewMenu from '../home/ReviewMenu';
import ReviewFilters from '../home/ReviewFilters';
import ReviewClasses from '../home/ReviewClasses';
import ClassNotes from '../home/ClassNotes';
import MainLoader from '../home/MainLoader';
import { showMainErrorPop } from '../../actions/popups';

class ReviewClassesContainer extends Component {
  
	constructor(props) {
		super(props);
		
		this.state = {
			chosenMenu: 'PENDING EDIT',
			editArray: [],
			reviewArray: [],
			publishArray: [],
			editArrayLength: 0,
			reviewArrayLength: 0,
			publishArrayLength: 0,
			fullLength: 0,
			totalHours: 0,
			totalMinutes: 0,
			mainArray: [],
			isOpenNote: false,
			classInfo: {},
			showLoader: false,
			pendingEditHosts: [],
			pendingReviewHosts: [],
			pendingPublishHosts: [],
			filterHosts: [],
			chosenFilterHosts: [],
			chosenFilterHostsEdit: [],
			chosenFilterHostsReview: [],
			chosenFilterHostsPublish: [],
			onlyWithNotes: false,
			onlyWithNotesEdit: false,
			onlyWithNotesReview: false,
			onlyWithNotesPublish: false,
			editCategories: [],
			reviewCategories: [],
			publishCategories: [],
			filterCategories: [],
			chosenFilterCategories: [],
			chosenFilterCategoriesEdit: [],
			chosenFilterCategoriesReview: [],
			chosenFilterCategoriesPublish: [],
			filterWeeks: -1,
			filterDays: -1, 
			filterWeeksEdit: -1,
			filterWeeksReview: -1,
			filterWeeksPublish: -1,
			filterDaysEdit: -1,
			filterDaysReview: -1,
			filterDaysPublish: -1,
			startDateEdit: "",
			endDateEdit: "",
			startDateReview: "",
			endDateReview: "",
			startDatePublish: "",
			endDatePublish: "",
			startOfWeekEdit: "",
			startOfWeekReview: "",
			startOfWeekPublish: "",
			channelRole: JSON.parse(localStorage.getItem('userData')).data.responseData.channelRole,
			currentUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
			isLoadedFirstTime: false,
		}

	}
 
	componentDidMount(){
	   this.getClasses();
	}

	updateStateFromChild=(state)=>{
		this.setState(state);
	}
	
	changeMenu=(menu)=>{
		var array = menu == "PENDING EDIT" ? this.state.editArray : menu == "REVIEW" ? this.state.reviewArray : this.state.publishArray;
		var filterHostsVar = menu == "PENDING EDIT" ? this.state.pendingEditHosts : menu == "REVIEW" ? this.state.pendingReviewHosts : this.state.pendingPublishHosts;
		var chosenFilterHostsVar = menu == "PENDING EDIT" ? this.state.chosenFilterHostsEdit : menu == "REVIEW" ? this.state.chosenFilterHostsReview : this.state.chosenFilterHostsPublish; 
		var onlyWithNotesVar = menu == "PENDING EDIT" ? this.state.onlyWithNotesEdit : menu == "REVIEW" ? this.state.onlyWithNotesReview : this.state.onlyWithNotesPublish;
		var filterCategoriesVar = menu == "PENDING EDIT" ? this.state.editCategories : menu == "REVIEW" ? this.state.reviewCategories : this.state.publishCategories;
		var chosenFilterCategoriesVar = menu == "PENDING EDIT" ? this.state.chosenFilterCategoriesEdit : menu == "REVIEW" ? this.state.chosenFilterCategoriesReview : this.state.chosenFilterCategoriesPublish;
		var filterWeeksVar = menu == "PENDING EDIT" ? this.state.filterWeeksEdit : menu == "REVIEW" ? this.state.filterWeeksReview : this.state.filterWeeksPublish;
		var filterDaysVar = menu == "PENDING EDIT" ? this.state.filterDaysEdit : menu == "REVIEW" ? this.state.filterDaysReview : this.state.filterDaysPublish;
	
		this.setState({
				chosenMenu: menu, 
				mainArray: array,
				filterHosts: filterHostsVar,
				chosenFilterHosts: chosenFilterHostsVar,
				onlyWithNotes: onlyWithNotesVar,
				filterCategories: filterCategoriesVar,
				chosenFilterCategories: chosenFilterCategoriesVar,
				filterWeeks: filterWeeksVar,
				filterDays: filterDaysVar
			});
	}
	
	getClasses=(fromPublishing)=>{
		this.setState({showLoader: true});
		document.getElementById('review_container').scrollTop = 0;
		var postData = { 
						channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
						hostsEdit: this.state.channelRole == 'Instructor' ? [this.state.currentUserData.id] : this.state.chosenFilterHostsEdit,
						hostsReview: this.state.channelRole == 'Instructor' ? [this.state.currentUserData.id] : this.state.chosenFilterHostsReview,
						hostsPublish: this.state.channelRole == 'Instructor' ? [this.state.currentUserData.id] : this.state.chosenFilterHostsPublish,
						categoriesEdit: this.state.chosenFilterCategoriesEdit,
						categoriesReview: this.state.chosenFilterCategoriesReview,
						categoriesPublish: this.state.chosenFilterCategoriesPublish,
						onlyWithNotesEdit: this.state.onlyWithNotesEdit,
						onlyWithNotesReview: this.state.onlyWithNotesReview,
						onlyWithNotesPublish: this.state.onlyWithNotesPublish,
						startDateEdit: this.state.startDateEdit,
						endDateEdit: this.state.endDateEdit,
						startDateReview: this.state.startDateReview,
						endDateReview: this.state.endDateReview,
						startDatePublish: this.state.startDatePublish,
						endDatePublish: this.state.endDatePublish
					};
					
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		axios.defaults.headers.common['Authorization'] = token;
		axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getPendingClasses", postData).then(res => {
			this.setState({
				editArray: res.data.responseData.pendingEdit,
				reviewArray: res.data.responseData.pendingReview,
				publishArray: res.data.responseData.pendingPublish,
				editArrayLength: res.data.responseData.pendingEditLength,
				reviewArrayLength: res.data.responseData.pendingReviewLength,
				publishArrayLength: res.data.responseData.pendingPublishLength,
				totalHours: res.data.responseData.totalHours,
				totalMinutes: res.data.responseData.totalMinutes,
				fullLength: res.data.responseData.pendingEditLength + res.data.responseData.pendingReviewLength + res.data.responseData.pendingPublishLength,
				mainArray: this.state.chosenMenu == "PENDING EDIT" ? res.data.responseData.pendingEdit : this.state.chosenMenu == "REVIEW" ? res.data.responseData.pendingReview : res.data.responseData.pendingPublish,
				pendingEditHosts: res.data.responseData.pendingEditHosts,
				pendingReviewHosts: res.data.responseData.pendingReviewHosts,
				pendingPublishHosts: res.data.responseData.publishHosts,
				filterHosts: this.state.chosenMenu == "PENDING EDIT" ? res.data.responseData.pendingEditHosts : this.state.chosenMenu == "REVIEW" ? res.data.responseData.pendingReviewHosts : res.data.responseData.publishHosts,
				editCategories: res.data.responseData.editCategories,
				reviewCategories: res.data.responseData.reviewCategories,
				publishCategories: res.data.responseData.publishCategories,
				filterCategories: this.state.chosenMenu == "PENDING EDIT" ? res.data.responseData.editCategories : this.state.chosenMenu == "REVIEW" ? res.data.responseData.reviewCategories : res.data.responseData.publishCategories,
				showLoader: false
			});
			
			if ( window.location.hash == '#classManagement' && res.data.responseData.pendingEdit.length === 0 && !this.state.isLoadedFirstTime ) 
				{
					this.changeMenu('REVIEW');
				}
				
			if ( window.emptyPublishArray ) { window.emptyPublishArray(); }
			if ( fromPublishing ) { this.props.showMainErrorPop("All selected class(es) are published"); }
			this.setState({isLoadedFirstTime: true});
		}).catch(err =>{ });
	}
	
	openNotes=(item)=>{
		this.setState({isOpenNote: true, classInfo: item});
		document.getElementById('review_container').scrollTop = 0;
	}
	
	updateStateFromChild=(state)=>{
		this.setState(state);
	}
	
	updateNotesCount=(id, count)=>{
		var mainArrayVar = this.state.mainArray;
		var editArrayVar = this.state.editArray;
		var reviewArrayVar = this.state.reviewArray;
		var publishArrayVar = this.state.publishArray;
		
		mainArrayVar.map((item) => { if ( item.id == id ) { item.notesCount = count; } });
		editArrayVar.map((item) => { if ( item.id == id ) { item.notesCount = count; } });
		reviewArrayVar.map((item) => { if ( item.id == id ) { item.notesCount = count; } });
		publishArrayVar.map((item) => { if ( item.id == id ) { item.notesCount = count; } });
	
		this.setState({
			mainArray: mainArrayVar,
			editArray: editArrayVar,
			reviewArray: reviewArrayVar,
			publishArray: publishArrayVar
		});
	}
	
	selectFilter=(type, id, isEmpty)=>{
		var array = [id];
		if ( isEmpty ) { array = []; }

		if ( type == "instructor" )
			{
				var instructorArray = this.state.chosenMenu == "PENDING EDIT" ? "chosenFilterHostsEdit" : this.state.chosenMenu == "REVIEW" ? "chosenFilterHostsReview" : "chosenFilterHostsPublish"; 
				this.setState({chosenFilterHosts: array, [instructorArray]: array}, () => {this.getClasses()});
			}
			
		if ( type == "category" )
			{
				var categoryArray = this.state.chosenMenu == "PENDING EDIT" ? "chosenFilterCategoriesEdit" : this.state.chosenMenu == "REVIEW" ? "chosenFilterCategoriesReview" : "chosenFilterCategoriesPublish";
				this.setState({chosenFilterCategories: array, [categoryArray]: array}, () => {this.getClasses()});
			}
	}
	
	changeNoteFilter=()=>{
		var isWithNote = this.state.chosenMenu == "PENDING EDIT" ? this.state.onlyWithNotesEdit : this.state.chosenMenu == "REVIEW" ? this.state.onlyWithNotesReview : this.state.onlyWithNotesPublish;
		var stateMod = this.state.chosenMenu == "PENDING EDIT" ? "onlyWithNotesEdit" : this.state.chosenMenu == "REVIEW" ? "onlyWithNotesReview" : "onlyWithNotesPublish";
		isWithNote = !isWithNote;
		this.setState({[stateMod]: isWithNote, onlyWithNotes: isWithNote}, () => {this.getClasses()});
	}
	
	selectDate=(type, item)=>{
		var startDateVar = "";
		var endDateVar = "";
		var weeksFilterType = this.state.chosenMenu == "PENDING EDIT" ? "filterWeeksEdit" : this.state.chosenMenu == "REVIEW" ? "filterWeeksReview" : "filterWeeksPublish";
		var daysFilterType = this.state.chosenMenu == "PENDING EDIT" ? "filterDaysEdit" : this.state.chosenMenu == "REVIEW" ? "filterDaysReview" : "filterDaysPublish";
		var startDateType = this.state.chosenMenu == "PENDING EDIT" ? "startDateEdit" : this.state.chosenMenu == "REVIEW" ? "startDateReview" : "startDatePublish";
		var endDateType = this.state.chosenMenu == "PENDING EDIT" ? "endDateEdit" : this.state.chosenMenu == "REVIEW" ? "endDateReview" : "endDatePublish";
		var startOfWeekType = this.state.chosenMenu == "PENDING EDIT" ? "startOfWeekEdit": this.state.chosenMenu == "REVIEW" ? "startOfWeekReview" : "startOfWeekPublish";
		
		if ( type == "weeks" )
			{
				this.setState({[weeksFilterType]: item.id, filterWeeks: item.id, [startOfWeekType]: item.firstDay});
				
				if ( item.id > -1 )
					{
						startDateVar = item.firstDay;
						if ( this.state[daysFilterType] == -1 )
							{
								endDateVar = item.lastDay;
							}
						else
							{
								var endInWeek = new Date(startDateVar).setDate(startDateVar.getDate() + this.state[daysFilterType]);
								startDateVar = new Date(endInWeek);
								endDateVar = new Date(endInWeek);
							}
					}
				
				this.setState({[startDateType]: startDateVar, [endDateType]: endDateVar}, () => {this.getClasses()});
			}
		
		if ( type == "days" )
			{
				this.setState({[daysFilterType]: item.dayInWeek, filterDays: item.dayInWeek});
				
				if ( this.state[weeksFilterType] > -1 && this.state[startOfWeekType] !== "" )
					{
						var startDateCurrent = this.state[startOfWeekType];
						
						var endInWeekDay = new Date(new Date(startDateCurrent).setDate(startDateCurrent.getDate() + item.dayInWeek));
						var startDate = endInWeekDay;
						
						if ( item.dayInWeek == -1 )
							{
								startDate = startDateCurrent;
								endInWeekDay = new Date(new Date(startDateCurrent).setDate(startDateCurrent.getDate() + 6));
							}
							
						this.setState({[startDateType]: startDate, [endDateType]: endInWeekDay}, () => {this.getClasses()});
					}
			}	
	}

	render() {
			return(
				<div className={classnames({"review_content": true, "hidden_overflow": (this.state.isOpenNote || this.state.showLoader)})} id="review_container">
					<div className="review_main_title">Class Management
						<div className="close_flow" onClick={() => this.props.updateStateFromChild({isOpenReviewClasses: false})}></div>
					</div>
					<div className="review_subtitle">{this.state.channelRole}</div>
					<div className="menu_length_container">
						<div className="menu_content">
							<ReviewMenu 
								chosenMenu={this.state.chosenMenu} 
								changeMenu={this.changeMenu} 
								editArrayLength={this.state.editArrayLength} 
								reviewArrayLength={this.state.reviewArrayLength}
								publishArrayLength={this.state.publishArrayLength}
							></ReviewMenu>
						</div>
						<div className="classes_length_content">
							<div className="one_count_classes first_count">{this.state.fullLength} Classes</div>
							<div className="one_count_classes">{this.state.totalHours} hours {this.state.totalMinutes} mins</div>
						</div>
					</div>
					<ReviewFilters
						filterHosts={this.state.filterHosts}
						chosenFilterHosts={this.state.chosenFilterHosts}
						filterCategories={this.state.filterCategories}
						chosenFilterCategories={this.state.chosenFilterCategories}
						chosenMenu={this.state.chosenMenu}
						onlyWithNotes={this.state.onlyWithNotes}
						filterWeeks={this.state.filterWeeks}
						filterDays={this.state.filterDays}
						selectFilter={this.selectFilter}
						changeNoteFilter={this.changeNoteFilter}
						selectDate={this.selectDate}
					></ReviewFilters>
					<ReviewClasses 
						classesArray={this.state.mainArray} 
						showLoader={this.state.showLoader}
						chosenMenu={this.state.chosenMenu}
						openNotes={this.openNotes} 
						updateStateFromChild={this.updateStateFromChild}
						getClasses={this.getClasses}
					></ReviewClasses>
					{ this.state.isOpenNote &&
						<ClassNotes classInfo={this.state.classInfo} updateStateFromChild={this.updateStateFromChild} updateNotesCount={this.updateNotesCount}></ClassNotes>
					}
					{ this.state.showLoader && <MainLoader></MainLoader> }
				</div>
			)
		}
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ReviewClassesContainer);