import React, { Component } from "react";

// import axios from "axios";
// import {  browserHistory} from 'react-router'
// import { Link } from 'react-router';



//import $ from 'jquery';
//import DateTimeField from "react-bootstrap-datetimepicker";

class News extends Component {
  
  constructor(props) {
	super(props);

    
}
 

componentDidMount(){
  }


  render() {
	


    return (
        <div>
            <div id="root">
                <div className="App">
                    <div className="container-fluid custom-width">
                        <div className="top-header px-5 pt-5">
                            <div className="">
                                <img src="images/login-logo.png" className="logo" />
                            </div>
                            <div className="privacy_banner position-relative">
                                <img src="images/terms-of-Use.png" alt="terms_use" className="w-100" />
                            </div>                                       
                        </div>
                        <div className="background_gray px-5 pt-4 pb-4">
                            <div className="content-privacy pt-4">
                                <h3 className="purple_text font-32 mb-4 text-center font-book">NEWS</h3>
                                <h3 className="white">Upcoming Virdio events. </h3>
                                <h3 className="white font-20">Come visit us </h3>
                                <ul>
                                <li>
                                    IDEA Personal Trainer Institute <br />
                                    CORE Sponsor <br />
                                    March 5-8, 2020<br />
                                    Hilton Alexandria Mark Center<br />
                                    5000 Seminary Road<br />
                                    Alexandria, VA 22311<br />
                                    </li>                             
                                </ul>
                                <ul className="mt-4">                                    
                                    <li>
                                    	IHRSA 2020 Intrernational Trade Show & Convention <br />
                                        Exhibitor Booth #3246 <br />
                                        March 18-21, 2020 <br />
                                        San Diego Convention Center <br />
                                        111 West Harbor Drive <br />
                                        San Diego, CA 91201 <br />
                                    </li>                                     
                                </ul>
                                <ul className="mt-4">                                    
                                    <li>
                                    	IDEA World & Club and Studio Summit<br />
                                        Club & Studio Row <br />
                                        July 9-12, 2020<br />
                                        Anaheim Convention Center<br />
                                        800 W Katella Ave.<br />
                                        Anaheim, CA 92802<br />

                                    </li>                                     
                                </ul>							
                            </div>
                        </div>            
                    </div>
                </div>
            </div>	
      </div>	
    );
  }
}

export default News;
