import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';

class EmailPreferences extends Component {
  constructor(props) {
  super(props);
  this.state={
		emailTypes: ["Confirmations Emails", "Reminders Emails"],
		emailPreferences: JSON.parse(localStorage.getItem('userData')).data.responseData.type === 1 ? 
			{
				"Confirmations Emails": [
											{
												emailName: 'Created Class',
												emailType: 1,
												status: 1
											},
											{
												emailName: 'Cancelled Class',
												emailType: 1,
												status: 1
											},
											{
												emailName: 'Max limit reached',
												emailType: 1,
												status: 1
											}
										],
				"Reminders Emails": [
										{
											emailName: '15 - minute reminder',
											emailType: 2,
											status: 1
										},
										{
											emailName: 'Someone has joined the Class',
											emailType: 2,
											status: 1
										}
									]
				
				
			}
			:
			{
				
				"Confirmations Emails": [
											{
												emailName: 'Signed Up for Class',
												emailType: 1,
												status: 1
											},
											{
												emailName: 'Cancelled Class',
												emailType: 1,
												status: 1
											},
											{
												emailName: 'Invites to Classes',
												emailType: 1,
												status: 1
											}
										],
				"Reminders Emails" : [
										{
											emailName: '15 - minute reminder',
											emailType: 2,
											status: 1
										}
									]
			}
  }

}
 
componentDidMount(){
	this.getPreferences();
}

getPreferences=()=>{
	var postData = { userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, type: JSON.parse(localStorage.getItem('userData')).data.responseData.type }
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getUserPreferences", postData).then(res => {
		this.setState({emailTypes: res.data.responseData.emailTypes, emailPreferences: res.data.responseData.userPreferences});
	}).catch(err =>{ });
}

updateEmail=(type, i)=>{
	var preferencesArray = this.state.emailPreferences;
	var arrayType = '';
	if ( type === 1 ) { arrayType = 'Confirmations Emails'; }
	else { arrayType = 'Reminders Emails'; }
	
	preferencesArray[arrayType][i].status = !preferencesArray[arrayType][i].status;
	
	this.setState({emailPreferences: preferencesArray});
}

saveChanges=()=>{
	var preferencesArray = this.state.emailPreferences;
	var emailTypes = this.state.emailTypes;
	var statusArray = [];
	
	for ( var i = 0; i < emailTypes.length; i++ )
		{
			for ( var y = 0; y < preferencesArray[emailTypes[i]].length; y++ )
				{
					if ( preferencesArray[emailTypes[i]][y].status ) { preferencesArray[emailTypes[i]][y].status = 1; }
					else { preferencesArray[emailTypes[i]][y].status = 0; }
					
					statusArray.push({id: preferencesArray[emailTypes[i]][y].id, status: preferencesArray[emailTypes[i]][y].status});
				}
		}
	
	var postData = { emailsStatus: JSON.stringify(statusArray) };
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateEmailPreferences", postData).then(res => {
		this.props.showMainErrorPop('Successfully updated Email Preferences');
	}).catch(err =>{ });	
}

	render() {
	 
	 return (
		<div className="preferences_container">
			{ this.state.emailTypes.map((item, key) => 
				<div key={key}>
					<div className="preferences_title">{item}</div>
					{ this.state.emailPreferences[item].map((pref, i) => 
						<div className="one_settings" key={i}>
							<div className="left_settings mr20">
								<div className="settings_name">{pref.emailName}</div>
							</div>
							<label className="switch toggle_align_middle toggle_switch">
								<input type="checkbox" checked={pref.status} onChange={() => this.updateEmail(pref.emailType, i) } />
								<span className="slider round"></span>
							</label>
						</div>
					)}
				</div>
			)}
			<div className="settings_buttons_hold mt35">
				<div className="join_button profile_save_button" onClick={this.saveChanges}>Save Changes</div>
			</div>
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(EmailPreferences);