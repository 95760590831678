import React, { Component } from "react";
import { Link } from 'react-router';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import axios from "axios";

import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';

import { browserHistory } from 'react-router'
import SupportContainer from '../home/SupportContainer';

class ResetPassword extends Component {
	
  constructor(props) {
    super(props);

        this.state = {
            email:"",
            msg:"",
            password:"",
            cpassword:"",
            showError:false,
            type:"1",
            errors: {},
			showHelpPopup: false,
			passMinError: false,
			specialSymbol: false,
			numberContain: false,
			letterContain: false,
			fullSubdomain: '',
			redirectPage: '',
			passwordEye: false,
			passwordEyeRepeat: false,
			supportPageName: 'Reset Password',
			supportPageApi: '/resetpassword',
			userTypeFaqs: 2,
			isDisabledBtn: false,
          };

		this.validator = new SimpleReactValidator();
    }


    componentDidMount() {
		let userPath = '';
		if (localStorage.getItem('redirectPage') )
			{
				this.setState({
					//redirectPage : localStorage.getItem('redirectPage')
				}, () => {
					userPath = this.state.redirectPage; 
					localStorage.removeItem('redirectPage');
					
					if ( localStorage.getItem('resetemail') )
						{
							this.setState({ email: localStorage.getItem('resetemail') });
							localStorage.setItem('resetemail', '');
							
							let adminpath = process.env.REACT_APP_PATH;
							adminpath = adminpath.replace('http://', '.').replace('https://', '.');
							
							this.setState({fullSubdomain:  userPath + adminpath}, ()=>{console.log(this.state.fullSubdomain, 'DAAAAAAAA');});
						}
					else
						{
							browserHistory.push("/login");
						}
						
				})
			}
		else 
			{			
				if ( localStorage.getItem('resetemail') )
					{
						this.setState({ email: localStorage.getItem('resetemail') });
						localStorage.setItem('resetemail', '');
						
						let adminpath = process.env.REACT_APP_PATH;
						adminpath = adminpath.replace('http://', '.').replace('https://', '.');
						
						this.setState({fullSubdomain:  userPath + adminpath}, ()=>{console.log(this.state.fullSubdomain, 'DAAAAAAAA');});
					}
				else
					{
						browserHistory.push("/login");
					}
					
			}
    }
	
openChannelPage=()=>{
	let mainUrl = '';
	mainUrl = 'https://'+this.state.fullSubdomain;
	localStorage.removeItem('redirectPage');
	window.open(mainUrl, "_self");
}

    componentWillMount(){

    }
	
	checkValidation=(e)=>{
		let showHelp = this.state.showHelpPopup;
		
		let checking = this.checkPassword(this.state.password);
		
		this.setState({
			showHelpPopup: true
		});
		if ( checking )
			{
				let mainthis = this;
				setTimeout(function(){ 
					mainthis.setState({
						showHelpPopup: false
					});
				}, 1000);

			}
	}

	showHelpPopupFun=(e)=>{
		this.setState({
			showHelpPopup:e
		});
	}

	checkPassword=(pass)=>{
		let checkAll = false;
		var regexcheck = /[!@#$%_+:;'"~`.,?|\^&*(){}[\]<>?/|\-]/;
		var contains_number = /\d+/;
		var Regex = /^[^a-zA-Z]*$/;
		
		this.setState({
			contain8Ch: false,
			specialSymbol: false,
			numberContain: false,
			letterContain: false
		});
		
		if ( !Regex.test(pass) ) 
			{ 
				this.setState({
					letterContain: true
				});
			}
		
		if ( pass.length >= 8 )
			{
				this.setState({
					contain8Ch: true
				});
			}
		
		if ( regexcheck.test(pass) )
			{
				this.setState({
					specialSymbol: true
				});
			}
			
		if ( contains_number.test(pass) )
			{
				this.setState({
					numberContain: true
				});
			}
			
		if ( pass != '' && pass.length >= 8 && regexcheck.test(pass) && contains_number.test(pass) && !Regex.test(pass) )
			{
				checkAll = true;
			}
			
		return checkAll;
	}

    handleChange= name => e => {
        this.setState({[name]: e.target.value});
      };


      resetPassword = e => {
        e.preventDefault();
                     
        if(this.state.password == "" || this.state.cpassword == "")
        {
          this.setState({
            msg:'Password and Confirm Password field should not blank',
          });
        }else if(this.state.password != this.state.cpassword)
        {
          this.setState({
            msg:'Password and Confirm Password should be matched',
          });

        }else{

          const userData = {
            email: this.state.email,
            password: this.state.password,
			resetPassword: true,
          };

          console.log('------update password------',userData)
			var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/     
				 
			var test = reg.test(this.state.password);
			
			if (test)
				{
					var mainthis = this;
					axios.put(process.env.REACT_APP_NAME+"/api/v1/user/update-password", userData)
					.then(res => {
						
						console.log(res);
						console.log('-------res.data.responseData', res.data.responseData);
						if ( res.data.responseData == 'password updated' )
							{
								console.log('-------update------------',res.data.message)

								  //    this.props.history.push({
								  //     pathname: '/login',
								  //     state: {msg: res.data.message}  
								  // })

								mainthis.setState({
									msg:res.data.responseData,   
									isDisabledBtn: true
								});
							
								if(this.state.redirectPage && this.state.redirectPage !== '')
									{
										setTimeout(function(){ mainthis.openChannelPage(); }, 3000);
									}
							}
						else
							{
							  console.log('-------Not update------------',res.data.responseData)

							  mainthis.setState({
								msg:res.data.responseData,                
								});
				
							}

					})
					.catch(err =>{
						console.log('there is problem');
				
						mainthis.setState({
							msg: err.response.data.errorData              
						  });
					});
				}
			else 
				{
					this.setState({
					msg:'Please check that password is matching the requirements. To see requirements start typing in Password field.',
				  });
				}				
		}
		
                
      };
	  
	passwordEyeCheck=(e, a)=>
		{
			if(a == 'pass')
				{
					if(e == 1)
						{
							this.setState({
								passwordEye: false
							});
						}
					
					else if(e == 0)
						{
							this.setState({
								passwordEye: true
							});
						}
				}
				
			else
				{
					if(e == 1)
							{
								this.setState({
									passwordEyeRepeat: false
								});
							}
						
						else if(e == 0)
							{
								this.setState({
									passwordEyeRepeat: true
								});
							}
				}
	}


    render() {

    return (

        <div className="container">
        <div className="row">
          <div className="login-bg">
            <div className="login-box">
              <div className="col-md-7 col-lg-5 col-sm-6 text-light mx-auto">
              
              <div className="col-12">
                <img src="/images/login-logo.png" className="login-logo" />
                <p className="login-tagline">Update Password</p>
              </div>
              <form className = "form-horizontal pt-1" role = "form"  noValidate onSubmit={this.resetPassword} autocomplete="off">
              
                <div className="login-inner">

                {(
                    ()=>{
                        if(this.state.msg == "password updated" && this.state.redirectPage === '') {
                          return  <div id="msg"  style={{color:'green'}}>Password updated successfully. Click <Link to="/login"><span style={{color:'#007bff'}}>here</span></Link> to login</div>;
                        } 
						else if(this.state.msg == "password updated" && this.state.redirectPage != '') {
                          return  <div id="msg"  style={{color:'green'}}>Password updated successfully. You will be redirected to Channel Page soon...</div>;
                        } 
						else {
                      
                            return  <div id="msg"  style={{color:'red'}}>{this.state.msg}</div>;                     
                        }
                    }
                  )()}
              
				{!this.state.passwordEye &&
					<div className = "form-group mt-4 mb-0">
				   
						<span className="text-danger">{this.state.errors.password}{this.state.errors.passwordincorrect}{this.state.errors.message}</span>
						<label>Enter your Password</label>
						<input type="password"  id="password" onChange={this.handleChange('password')} onKeyUp={this.checkValidation}  value={this.state.password} error={this.state.errors.password} className={classnames("", { invalid: this.state.errors.password || this.state.errors.passwordincorrect }) + 'form-control'} onKeyUp={this.checkValidation} onFocus={e=>{ this.showHelpPopupFun(true)}} onBlur={e=>{ this.showHelpPopupFun(false)}} />
						<span className="password_eye" onClick={e=>{ this.passwordEyeCheck(0, 'pass')}}></span>
					</div>
				}
				{this.state.passwordEye &&
					<div className = "form-group mt-4 mb-0">
				   
						<span className="text-danger">{this.state.errors.password}{this.state.errors.passwordincorrect}{this.state.errors.message}</span>
						<label>Enter your Password</label>
						<input type="text"  id="password" onChange={this.handleChange('password')} onKeyUp={this.checkValidation}  value={this.state.password} error={this.state.errors.password} className={classnames("", { invalid: this.state.errors.password || this.state.errors.passwordincorrect }) + 'form-control'} onKeyUp={this.checkValidation} onFocus={e=>{ this.showHelpPopupFun(true)}} onBlur={e=>{ this.showHelpPopupFun(false)}} />
						<span className="password_eye password_eye_shown" onClick={e=>{ this.passwordEyeCheck(1, 'pass')}}></span>
					</div>
				}
				{ this.state.showHelpPopup && 
					<div className="password_check_form">
						<div className="pass_check_title">Your password needs to:</div>
						<div className="pass_main_line">
							<div className={ "pass_done_icon " + ( !this.state.contain8Ch ? "pass_iks_icon" : "" ) }></div>
							<div className="pass_line_text">Minimum of 8 characters</div>
						</div> 
						<div className="pass_main_line">
							<div className={ "pass_done_icon " + ( !this.state.letterContain ? "pass_iks_icon" : "" ) }></div>
							<div className="pass_line_text">Minimum of 1 Letter</div>
                   
                </div>
						<div className="pass_main_line">
							<div className={ "pass_done_icon " + ( !this.state.numberContain ? "pass_iks_icon" : "" ) }></div>
							<div className="pass_line_text">Minimum of 1 Number</div>
						</div>
						<div className="pass_main_line">
							<div className={ "pass_done_icon " + ( !this.state.specialSymbol ? "pass_iks_icon" : "" ) }></div>
							<div className="pass_line_text">Minimum of 1 Special Character</div>
							<div className="pass_line_text spec_char">(e.g. !,@,#,$,%,&,*)</div>
						</div>
					</div>
				}
                
				{!this.state.passwordEyeRepeat &&
					<div className = "form-group mt-4 mb-0">
					   
						<label> Confirm Password</label>
						<input type="password"  id="cpassword" onChange={this.handleChange('cpassword')} value={this.state.cpassword} className = "form-control"  />
						<span className="password_eye" onClick={e=>{ this.passwordEyeCheck(0, 'repeat')}}></span>						
					</div>
                }
				{this.state.passwordEyeRepeat &&
					<div className = "form-group mt-4 mb-0">
					   
						<label> Confirm Password</label>
						<input type="text"  id="cpassword" onChange={this.handleChange('cpassword')} value={this.state.cpassword} className = "form-control"  />
						<span className="password_eye password_eye_shown" onClick={e=>{ this.passwordEyeCheck(1, 'repeat')}}></span>						
					</div>
                }
				
                <input type="hidden" name='email' id="email"  value={this.state.email}  className = "form-control"  /> 
				
                <div className = "form-group pt-3 mb-4">
                    <div className = "d-flex flex-wrap justify-content-between align-items-center">
                    

                      {/* <button type = "button" className="btn-cancel btn btn-large btn-outline-secondary waves-effect waves-light hoverable blue accent-3 rounded p-3 px-4">Cancel</button> */}
					  {	!this.state.isDisabledBtn &&
						<button type = "submit" className="btn-login btn btn-large btn-primary waves-effect waves-light hoverable blue accent-3 p-3 px-4 rounded">Submit</button>     
					  }	
					  { this.state.isDisabledBtn &&
						<div className="btn-login btn btn-large btn-primary waves-effect waves-light hoverable blue accent-3 p-3 px-4 rounded opacity_disabled">Submit</div>
					  }
							
							
                    </div>
                </div>
                
              </div>
                                
            </form> 

            </div>
            </div>
            <Link to="/PrivacyPolicy" target="_blank" className="privacy-link">Click to view the virdio privacy policy</Link>
          </div>
          
        </div>
		<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
      </div>

     );

     }

}

const container = {
    "padding": "0px 15px",
    "max-width": "1140px"
  
  };


export default ResetPassword;