import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import axios from "axios";
import {  browserHistory} from 'react-router'
import { Link } from 'react-router';
import $ from 'jquery'
import queryString from 'query-string';


class Form extends Component {
  
  constructor(props) {
	super(props);
    this.state={
        newsletterName:'',
        newsletterEmail:'',
        newsletterOther:'',
        newsletterAttribute:'',
        fitness:true,
        wine:false,
        cooking:false,
        beauty:false,
        homeImprovement:false,
        customRadio2:false,
        customRadio3:false,
        customRadio1:true,
        errorClass_Name:false,
        errorClass_Email:false,
        onclickOk :false,
        source:''
    }
    this.validator = new SimpleReactValidator({autoForceUpdate: this});

}
 
componentDidMount(){
    // let queryData=queryString.parse(this.props.location.search);
    //      console.log("manm=========", queryData.source);
    // console.log(!this.props.location.search)
    // console.log("======MANMOHAN",queryString.parse(this.props.location.search))
    
  };
  newsletterMethod=e=>{
    if (this.validator.allValid()) {
    let attributeInfo='';
    console.log("!====",!attributeInfo)
    if(this.state.fitness){
    
        attributeInfo=",fitness";
    
    }
    if(this.state.wine){
    
    attributeInfo=",wine "+attributeInfo
    
    }
        if(this.state.cooking){
            
            attributeInfo=",cooking"+attributeInfo
        
        }
        if(this.state.beauty){
            
            attributeInfo=",beauty"+attributeInfo
        
        }
        if(this.state.homeImprovement){
        
            attributeInfo=",homeimprovement"+attributeInfo
            
        }
        let hostType;
        if(this.state.customRadio1){
        hostType=0;
        }
        if(this.state.customRadio2){
            hostType=1;
        }
        if(this.state.customRadio3){
            hostType=2;
        }
        let queryData=queryString.parse(this.props.location.search);
         //console.log("manm=========", this.props.location.search);
        let name=this.state.newsletterName;
        let email=this.state.newsletterEmail;
        let intrest=attributeInfo;
        let others=!this.props.location.search?this.state.newsletterOther:'IDEAFit:'+this.state.newsletterOther;
        let source = queryData.source;
        
axios      
.post(process.env.REACT_APP_NAME+"/api/v1/newsletter/newsletter",{name,email,intrest,others,hostType,source})   
       
.then(res => {
     this.setState({
        msg:'Thank you for your interest in Virdio. We have received your information and will be contacting you shortly.',
        onclickOk:true,
        
    },()=>{
        $("#error_msg").attr({'style':'display:block'})
    //     setTimeout(() => {
    //     $("#error_msg").attr({'style':'display:none'})    
    //       browserHistory.push('/');
    //   }, 2000);
      }
    );
  })
  .catch(err =>{
      console.log('----------there is problem------------',err);

  });   
}else {
    this.validator.showMessages();
    // rerender to show messages for the first time
    // you can use the autoForceUpdate option to do this automatically`
    this.setState({
        msg:'One or more fields has an error. Please correct and press SAVE again!',
        
    },()=>
    $("#error_msg").attr({'style':'display:block'}))
    this.forceUpdate();
  }
}

checkHost1=(e)=>{
//	console.log(e.target)
	this.setState({
		[e.target.id]:!this.state.customRadio1,
        customRadio2:false,
        customRadio3:false
		})
}
checkHost2=(e)=>{
//	console.log(e.target)
	this.setState({
		[e.target.id]:!this.state.customRadio2,
        customRadio1:false,
        customRadio3:false
		
		})
}

checkHost3=(e)=>{
//	console.log(e.target)
	this.setState({
		[e.target.id]:!this.state.customRadio3,
        customRadio1:false,
        customRadio2:false
		
		})
}



  render() {
	 return (
	<div>
        <div className="container-fluid px-3 py-4 py-xl-5 px-lg-5" id="form_host_participant">
            <div className="form_container gray-box2 position-relative mx-auto radius-10 pt-3 px-3">
                        <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>browserHistory.push("/")}><span>x</span></button>
                    <div className="px-2 px-md-5 pb-4 mx-md-3">                        
                    <div className="text-center pt-2"><img src="/images/logo.png" className="logo-icon1" /></div>
                    <div className="mx-auto signUpwidth" style = {{color:'red'}}>{this.state.msg}</div>
                      <div className="host-participant-form pt-2 mx-auto">
                        <div className="">
                            <fieldset>
                                <legend>Personal Info</legend>
                                <div className="row mx-0 px-md-2 mt-2">
                                    <div className="col-md-6 px-2 px-md-3">
                                        <div className="form-group">
                                            <span className="cover-border gray-box2"></span>
                                            <label className="label font-medium">Name</label>
                                            <div className="mb-4">
                                            <input type="text" 
                                            id ="newsletterName"
                                            ref={newsName=> (this._newsFirstName = newsName)} 
                                            value ={this.state.newsletterName} 
                                            onChange={e=>this.setState({[e.target.id]:e.target.value,errorClass_Name:false})} 
                                            className={(this.state.errorClass_Name?"input-field red-outline":"input-field mb-0")}  />
                                                <span className="signedup_user"><img src="../images/signedup.png" /></span>
                                                {this.validator.message('Name', this.state.newsletterName, 'required|alpha_space|min:1|max:20')}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 px-2 px-md-3">
                                        <div className="form-group">
                                            <span className="cover-border gray-box2"></span>
                                            <label className="label font-medium">Email</label>
                                            <div className="">
                                            <input type="text"
                                            className={(this.state.errorClass_Email?"input-field red-outline":"input-field mb-0")} 
                                             id ="newsletterEmail"
                                              value ={this.state.newsletterEmail}
                                              ref={newsmail=> (this._newsFirstEmail = newsmail)} 
                                              onChange={e=>this.setState({[e.target.id]:e.target.value,errorClass_Email:false})} />
                                                <span className="signedup_user"><img src="../images/form-email.png" className="form_email_img" /></span>
                                                {this.validator.message('Email', this.state.newsletterEmail, 'required|email')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            {/*  */}
                           <div className="row">
                            <div className="col-md-12 d-flex justify-content-between flex-wrap">
                                <p className="font-20 font-weight-bold pt-3 mb-2 text-white">I want to join as a</p>
                                <div className="pt-3">
								<div className="custom-control custom-radio">
								    <input type="radio" className="custom-control-input" id="customRadio1" value="true"   name="example1" checked={this.state.customRadio1} onChange={this.checkHost1} />
								    <label className="custom-control-label" htmlFor="customRadio1">
									    <p className="ml-2 mb-0 p-top-3 text-white font-weight-bold">Instructor</p>
									</label>
								</div>    
							    </div>
                                <div className="pt-3">
                                <div className="custom-control custom-radio mb-20">
                                    <input type="radio" className="custom-control-input" id="customRadio2" value="false" name="example1" checked={this.state.customRadio2} onChange={this.checkHost2}  />
                                    <label className="custom-control-label" htmlFor="customRadio2">
                                    <p className="ml-2 mb-0 p-top-3 text-white font-weight-bold">Participate</p></label>
                                </div>  
                                </div>
                                <div className="pt-3">
                                <div className="custom-control custom-radio mb-20">
                                    <input type="radio" className="custom-control-input" id="customRadio3" value="false" name="example1" checked={this.state.customRadio3} onChange={this.checkHost3}  />
                                        <label className="custom-control-label" htmlFor="customRadio3">
                                        <p className="ml-2 mb-0 p-top-3 text-white font-weight-bold">Both</p></label>
                                </div>  
                                </div>
                            </div> 
                            {/* <div className="col-md-4 mt-1">
								<div className="custom-control custom-radio">
								    <input type="radio" className="custom-control-input" id="customRadio1" value="true"   name="example1" checked={this.state.customRadio1} onChange={this.checkHost1} />
								    <label className="custom-control-label" htmlFor="customRadio1">
									    <p className="ml-2 mb-0 p-top-3 text-white font-weight-bold">Instructor</p>
									</label>
								</div>    
							</div>
                            <div className="col-md-4 mt-1">
                                <div className="custom-control custom-radio mb-20">
                                    <input type="radio" className="custom-control-input" id="customRadio2" value="false" name="example1" checked={this.state.customRadio2} onChange={this.checkHost2}  />
                                    <label className="custom-control-label" htmlFor="customRadio2">
                                    <p className="ml-2 mb-0 p-top-3 text-white font-weight-bold">Participate</p></label>
                                </div>  
                            </div>
                            <div className="col-md-4 mt-1">
                                <div className="custom-control custom-radio mb-20">
                                    <input type="radio" className="custom-control-input" id="customRadio3" value="false" name="example1" checked={this.state.customRadio3} onChange={this.checkHost3}  />
                                        <label className="custom-control-label" htmlFor="customRadio3">
                                        <p className="ml-2 mb-0 p-top-3 text-white font-weight-bold">Both</p></label>
                                </div>  
                            </div> */}
                        </div>
                            {/*  */}
                        </div>
                        <div className="mt-1">
                            <fieldset>
                                <legend>Area Of Interest (One Or More)</legend>
                                <div className="row mx-0 px-md-1">
                                    <div className="col-md-12 px-2 px-md-3">
                                        <div className="d-flex align-items-center flex-wrap">
                                            <div className={this.state.fitness?"custom--button bg_purple mt-2":"custom--button  mt-2"} onClick={e=>this.setState({fitness:!this.state.fitness})}>Fitness</div>
									{/*
										   {!this.props.location.search?
                                            <div className={this.state.wine?"custom--button bg_purple mt-2":"custom--button  mt-2"} onClick={e=>this.setState({wine:!this.state.wine})} >Wine</div>
                                            :''}
											
									*/}
                                            {/* <div className={this.state.cooking?"custom--button bg_purple mt-2":"custom--button  mt-2"} onClick={e=>this.setState({cooking:!this.state.cooking})}>Cooking</div>
                                            <div className={this.state.beauty?"custom--button bg_purple mt-2":"custom--button  mt-2"} onClick={e=>this.setState({beauty:!this.state.beauty})}>Beauty/Makeup</div>
                                            <div className={this.state.homeImprovement?"custom--button bg_purple mt-2":"custom--button  mt-2"} onClick={e=>this.setState({homeImprovement:!this.state.homeImprovement})}>Home Improvement</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 px-2 mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <span className="cover-border gray-box2"></span>
                                            <label className="label font-medium">Other</label>
                                            <div className="mb-4">
                                            <input type="text" className="input-field mb-0" id = "newsletterOther" value = {this.state.newsletterOther} onChange={e=>this.setState({[e.target.id]:e.target.value})}/>
                                            <span className="signedup_user"><img src="../images/other.png" className="form_other_img" /></span>                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                      </div>
                        <p className="mt-4 text-center"><button type="button" className="custom_btn1" onClick={this.newsletterMethod}>submit</button></p>
                        <p className="text-white text-center mb-0 font-18 text-center">By using our products and services, you are agreeing to our <Link to="/TermsofService" target="_blank"><u>Terms Of Services</u></Link>, and <Link to="/PrivacyPolicy" target="_blank"><u>Privacy Policy</u></Link>.</p>
                    </div>
            </div>
          </div>

          {/* popup for message */}
          <div className="modal pr-0" id="error_msg" aria-modal="true"  >
   	        <div className="modal-dialog px-2 dialogwidth mx-auto modal-dialog-centered">
            <div className="modal-content modl_bg_drk">
      	    <div className="modal-body px-4 pb-4">
		    <p className="py-4 text-light text-center font-20">{this.state.msg}</p> 	
            {
            this.state.msg==="NewsLetter data submitted successfully!"?
            '':
            <button type="button" 
            className="custom_btn1 float-right" 
            data-dismiss="modal" 
            onClick={e=>{
                if(this.state.onclickOk){
                    $("#error_msg").attr({'style':'display:none'});
                    browserHistory.push('/');
                }else{
              $("#error_msg").attr({'style':'display:none'});
              if(!this.validator.fieldValid('Name') ){
                this.setState({
                  errorClass_Name:true
                })
                this._newsFirstName.focus();
              }
              if(!this.validator.fieldValid('Email') ){
                this.setState({
                    errorClass_Email:true
                })
                this._newsFirstEmail.focus();
              }
              }}}>ok</button>
              }
          </div>
            </div>
            </div>
        </div>
	{/*  pop up message ends */}
    </div>	
    );
  }
}
export default Form;
