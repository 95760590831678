import React from 'react';
import UserPlaylists from './UserPlaylists';
class PlaylistPopup extends React.Component {
	
	constructor(props){
		super(props);
		
		this.state = {
			selectedPlaylist: {}
		}
		
		
	}
	
	handleLocalSelectPlaylist = (mod) => {
		this.setState((state) => ({ selectedPlaylist: mod }));
		this.props.handleTempSelectedItem(mod);
	}
	
	handleMainSelectPlaylist = () => {
		let userDeviceName = localStorage.getItem('userDeviceName');
		if ( userDeviceName && this.state.selectedPlaylist)
			{
				if ( userDeviceName && this.state.selectedPlaylist.device_name && userDeviceName != this.state.selectedPlaylist.device_name )
					{
						window.$('#playlist_check').modal('show');
						return;
					}
			} 
			
		this.props.handleSelectPlaylist(this.state.selectedPlaylist);
	}
	
	goNext = () => {
		this.props.handleSelectPlaylist(this.state.selectedPlaylist);
		window.$('#playlist_check').modal('hide');
	}
	
	cancelAddPlaylist = () => {
		window.$('#playlist_check').modal('hide');
	}
	
	render() 
		{
			return <div>
						<div className={"popups_holder playlist_popup_holder " + (!this.props.openMainPopup ? "" : "active")} >
							<UserPlaylists selectedPlaylist={this.props.selectedPlaylist_id} handleSelectPlaylist={this.handleLocalSelectPlaylist} isFromPopup={true} playlists={this.props.playlists}></UserPlaylists>
							<div className="saveBtn">
								<div onClick={this.handleMainSelectPlaylist}>OK</div>
							</div> 
						</div>
						<div className="modal pr-0 zInd2000" id="playlist_check" aria-modal="true">
							<div className="modal-dialog mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent inline_pop">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
										<p className="mb-0 py-4 white text-center font-20 no_pad_bott">The playlist you selected was created on a different device and can only be used to instructor Classes on that device.</p>
										<p className="mb-0 py-4 white text-center font-20">Do you want to continue to use this playlist?</p>
									</div>
									<div className="text-center flex-wrap d-flex justify-content-center">
										<div className="">
											<button className="custom_btn1 mt-3 mx-2" onClick={this.goNext}>YES</button>
											<button className="custom_btn1 mt-3 mx-2" onClick={this.cancelAddPlaylist}>NO</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
		}
} 

export default PlaylistPopup;