import { ADD_ELECTRON_PLAYLIST, ADD_PLAYLIST,RESET_SONG_TO_ADD, ADD_PLAYLIST_SONGS, CHANGE_PLAYLIST_STATUS, UPDATE_PLAYLISTS, DELETE_PLAYLIST} from "../actions/types";

const initialState = 
	{
		playlistStatus: "inactive",
		playlists: [],
		playlistsToAdd: {
				"new_songs": [],
				"real_length": 0,
				"fileName": ""
		}
	};

const menagePlaylist = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLAYLIST: {	
      return {
		  ...state,
		  playlists: [action.payload.content, ...state.playlists],
		  playlistStatus: "pending",
		  playlistsToAdd: {
			  new_songs: [],
			  real_length: 0
		  }
	  };
    }
	case ADD_ELECTRON_PLAYLIST: {
		return {
		  ...state,
		  playlistStatus: "pending",
		  playlistsToAdd: {
			  new_songs: [],
			  real_length: 0
		  }
	  };
	}
	case ADD_PLAYLIST_SONGS: {
		return {
		  ...state,
		  playlistsToAdd: {
			  new_songs: [...state.playlistsToAdd.new_songs, action.payload.content],
			  real_length: action.payload.content.real_length + state.playlistsToAdd.real_length
		  }
	  } 	
    }
	case CHANGE_PLAYLIST_STATUS:{
		return {
			...state,
			playlistStatus: "inactive",
		}
	}
	case UPDATE_PLAYLISTS: {
		return {
			...state,
			playlists: action.payload.content,
		}
	}
	case RESET_SONG_TO_ADD:{
		return {
			...state,
			 playlistStatus: "inactive",
			 playlistsToAdd: {
				  new_songs: [],
				  real_length: 0
			  }	
		}
	}
	case DELETE_PLAYLIST: {
		console.log(state.playlists);
		console.log(action);
		return {
			...state,
			playlists: state.playlists.filter((item) => item['playlist_id'] != action.payload.playlist_id),
			showLoading: false
		}
	}
    default: {
      return state;
    }
  }
};

export default menagePlaylist;
