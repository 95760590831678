import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import { Link } from 'react-router';
import { browserHistory } from 'react-router'
import classnames from "classnames";



class ChannelHeader extends Component {
  
  constructor(props) {
	super(props);
	this.state={
    }
	
	
}
 
componentDidMount(){
}


  render() {
	 return (
		<div className="paddtp20">
			<div className="channelNewH1"   >
				{this.props.state.channelObject['image'] != '' && this.props.state.channelObject['image'] != 'default.png' &&
					<div className="ch_img_div" style={{ backgroundImage: this.props.state.channelObject['image'] != '' ? "url("+this.props.state.channelObject['image']+")" : "url('/images/powered_virdio.png')"}} ></div>
				}
				<div className="chName" >{this.props.state.channelObject['name']}</div>
			</div>
			<div className="channelDescH2" >{this.props.state.channelObject['description']}</div>
		</div>
    );
  }
}
export default ChannelHeader;
