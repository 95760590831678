import axios from "axios";
import $ from 'jquery';
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablete,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
const productAddAttribute = function(e)
{ 
	
    let attributeArray=this.state.addProduct;
    let arr = this.state.productInformation;
    let productCount = this.state.productCount;
	if( e )
		{
			e.preventDefault();
    attributeArray[0].attributes[e.target.id].status = !attributeArray[0].attributes[e.target.id].status;
    if(attributeArray[0].attributes[e.target.id].status){
        arr.push(attributeArray[0].attributes[e.target.id]);
        productCount =  productCount+1;

    }else{
    for(let i=0;i<arr.length;i++){
        if(e.target.name === arr[i].attrKey){
            arr.splice(i,1);
            productCount =  productCount-1;
        }
    }
    }
		}
    else 
		{
			arr = [];
			for( let i = 0;i<attributeArray[0]['attributes'].length; i++)
				{
					/*--------leave block on ---------*/
					//attributeArray[0]['attributes'][i]['status'] = false;
				}
		}
    
    this.setState({
        addProduct:attributeArray,
        productInformation:arr,
        productCount:productCount
    },()=>console.log('----------------',this.state.productInformation))
}

export { productAddAttribute };