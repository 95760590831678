import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import { showMainErrorPop } from '../../actions/popups';
import { connect } from "react-redux";
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';


class ReportsAdminBilling extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartDate: null,
					searchEndDate: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					productList: [],
					sortedChannelName: false,
					sortedSessionName: false,
					sortedHostName: false,
					sortedDate: false,
					planInfo: [],
					refundPopUp: false,
					refundAmount: 0,
					fixedAmount: 0,
					billing_id: 0,
					host_id: 0,
					refundEmail: '',
					customerName: '',
					customerLastName: '',
					showConfPopup: false,
					cardToken: 0,
					hasDefaultCard: false,
					sortByVal: ['id', 'DESC'],
					channelId: 0,
					ajaxLoaded: false
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
}
 
componentWillUnmount() {
}

openMoreInfo=(e, justClose)=> {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	
};

changePageCount = (e) => {
	let pg_id = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg_id
		});
	this.fetchSessionDetails(pg_id, e.target.value);
}

getAllSessionUsers = (e, sess_id, indx, mod) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	$('.main_loader').attr({'style':'display:flex'});
	var data = [...this.state.billing_info];
	$(e.currentTarget).find('.all_info').addClass('active');
	
	//if( data[indx]['billUsers'] ) { return; }
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/billing_info_users", { session_id: sess_id })          
	.then(res => {
		data[indx]['billUsers'] = res.data.responseData;
		this.setState({billing_info: data
		},() => {
			console.log("NEW STATEEEE");
			console.log(this.state);
		});
	})
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	});

	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getAllSessionProducts", { session_id: sess_id })          
	.then(res => {
		let products = res.data.responseData;
		this.setState({productList: products
		},() => {
			console.log("Product List");
			console.log(this.state);
		});
		$('.main_loader').hide();
	})
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	});
}

changePage = (e, mod) => {
	console.log(mod);
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchSessionDetails(mod, this.state.page_num_count);
		}
}


stopClick = (e) => {
	e.stopPropagation();
	e.preventDefault();
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ 
								showSearch: !this.state.showSearch, 
								searchStartDate: null, 
								searchEndDate: null, 
								searchBy: '' }, ()=>{
									this.fetchSessionDetails(this.state.page_id, this.state.page_num_count); 
								});
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

customChecked = () => {
	this.setState({ customCheck1: !this.state.customCheck1 });
}

searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchSessionDetails(pg, this.state.page_num_count); 
}

fetchSessionDetails = (page_id, countId, searchText = this.state.searchBy, startDate= this.state.searchStartDate, endDate= this.state.searchEndDate) => {
	
	
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});
 axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/get_billing_reports", {page_id: page_id, countId: countId, searchText: searchText, startDate: startDate, endDate: endDate, sortby: this.state.sortByVal})          
	.then(res => {
		
	this.setState({planInfo: res.data.responseData['sessions']});
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({billing_info: res.data.responseData['sessions'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded:true});
	if( (this.state.page_id+6) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			 
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}

sortArrays=(sortby)=>{
	var sortorder = this.state.sortByVal[1];
	if ( this.state.sortByVal[0] == sortby ) 
		{ 
			if ( sortorder == 'ASC' )
				{
					sortorder = 'DESC'; 
				}
			else
				{
					sortorder = 'ASC'; 
				}
		}
	this.setState({
			sortByVal: [sortby, sortorder]
		}, () => {
				this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	});
}

openRefund =(amount, host_id, billing_id, email, name, lastname, channelId) => {
	this.setState({refundAmount: amount, fixedAmount: amount, billing_id: billing_id, host_id: host_id, refundEmail: email, customerName: name, customerLastName: lastname, channelId: channelId},()=>{
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/selectDefaultCard", {"userId": this.state.host_id})
		.then(res => {
				if(res.data.responseData)
					{
						this.setState({cardToken: res.data.responseData.cardToken, refundPopUp: true,}, ()=>{console.log(this.state.cardToken);});
					}
				
				else
					{
						this.props.showMainErrorPop("This user doesn't have a credit card");
					}
				
			}).catch(err =>{ });
	});
}

refund =()=>{
	if(this.state.refundAmount != '')
		{
			this.setState({showConfPopup: true});
		}
		
	else
		{
			this.props.showMainErrorPop('Refund Amount cannot be empty');
		}
	
}

confirmRefund =()=>{
	$('.main_loader').attr({'style':'display:flex'});
	let adminId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/refundPlan", { 'host_id': this.state.host_id, 'billing_id': this.state.billing_id, 'amount': this.state.refundAmount, 'email': this.state.refundEmail, 'customerName': this.state.customerName, 'customerLastName': this.state.customerLastName, 'token': this.state.cardToken, 'adminId': adminId, 'channelId':this.state.channelId })          
	.then(res => {
		let checkRefStatus = JSON.parse(res.data.responseData.ret);
		console.log(checkRefStatus.reply.response);
		$('.main_loader').hide();
		this.setState({refundPopUp: false, showConfPopup: false});
		
		if(checkRefStatus.reply.response != 'Error')
			{
				this.props.showMainErrorPop('Refunded successfully');
			}
			
		else
			{
				this.props.showMainErrorPop('There has been an error during the Refund process');
			}
		this.fetchSessionDetails(0, this.state.page_num_count);
		
	})
	.catch(err =>{
	  console.log('----------there is problem------------', err);
	});
}

showMoreInfo = (e, sess_id, indx) => {
	e.stopPropagation(); 
	$('.all_info').removeClass('active');
	$(e.currentTarget).find('.all_info').addClass('active');
}

getPlanPdf = (e, mod) => {
	var billData = JSON.parse(mod.billData);
	
	var checkPlanData = {};
	if ( billData[2] ) { checkPlanData = billData[2]; }
	var postData = { channelId: mod.channelId, totalAmmount: mod.amount, planData: JSON.stringify(checkPlanData), confNumber: mod.billing_id, channelName: mod.name, planSubId: mod.planSubId };
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/getPlanPdf", postData)          
	.then(res => { 
		var pdfUrl = res.data.responseData;
		window.open(pdfUrl);
		$('.main_loader').hide();
	}).catch(err =>{ this.props.showMainErrorPop("Something went wrong."); $('.main_loader').hide(); });
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className="container-fluid payinfo_main_hold">
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className="month_h1 active_tab" onClick={e=>{this.props.changeDailyComp({billingPlan: true}, 1)}}>Billing Plan</div>
									<div className="month_h1" onClick={e=>{this.props.changeDailyComp({channelPlan: true}, 1)}}>Channel Plan</div>
									<div className="month_h1" onClick={e=>{this.props.changeDailyComp({overCharge: true}, 1)}}>Tiered Overages</div>
									<div className="month_h1" onClick={e=>{this.props.changeDailyComp({brandAmbassador: true}, 1)}}>Ambassador</div>
								</div>
								<div className="pay_head_title">Billing Plan Reports</div>
								<div className="show_pay_page">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
										</select>
									 </span>
									Billings
								</div>
								<div className="show_search_opt">
									
									{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-4' : ' col-xl-6')}>
														<input type="text" className="form-control" placeholder="Search for channel" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
													</div>
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-8' : ' col-xl-6')}>
														{/* <div className="">
															<div className="px-4 d-flex justify-content-end align-items-center">				
																<div className="custom-control custom-checkbox mb-0">
																	<input type="checkbox" className="custom-control-input" id="customCheck1" value="false" checked={this.state.customCheck1} onChange={this.customChecked} name="example1" />
																	<label className="custom-control-label" htmlFor="customCheck1"><p className="pl-2 p-top-3 mb-0">ADVANCED</p></label>
																</div>
															</div>
														</div> */}
														<div className="parent-row mb-3 full_width">				
															<div className="row mx-0 row1 d-flex">
																<div className="col-md-12 d-flex flex-wrap align-items-center">	
																	<div className="filter_item">
																		<span className="searh_span">From</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchStartDate} onChange={date => this.setState({searchStartDate: date})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchEndDate} onChange={date => this.setState({searchEndDate: date})} />
																	</div>
																</div>
															</div>
														</div>
												</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
									
									
									
								</div>

								{ this.state.planInfo.length > 0 && 
									<table className="billing_table">
										<thead>
											<tr>
												<th>Channel Name
													<div onClick={ (e) => this.sortArrays('name')} className="reports_sort_icon"></div>
												</th>
												<th>Instructor Name
													<div onClick={ (e) => this.sortArrays('firstName')} className="reports_sort_icon"></div>
												</th>
												<th>Date & Time
													<div onClick={ (e) => this.sortArrays('billDate')} className="reports_sort_icon"></div>
												</th>
												<th>Live Plan</th>
												<th>On Demand Plan</th>
												<th>Amount</th>
												<th>Refund</th>
												<th>Download</th>
											</tr>
										</thead>
										<tbody> 
											{ this.state.planInfo.map((item, key) =>
												<tr key={key}>
												<td>{ item['name'] ? item['name'] : '-' }</td>
													<td>{item['firstName']} { item['lastName'] }</td>
													<td>{ this.getSessionDate(item['billDateNotFormated'])} @ {this.getSessionTime(item['billDateNotFormated'])}</td>
													<td>{item['planName'] &&
														<div>
															{item['planName']}
														</div>
													}
													{!item['planName'] &&
														<div>
															-
														</div>
													}
													</td>
												<td>{item['onDemandPlanName'] && item['onDemandPlanName']!== '' &&
														<div>
															{item['onDemandPlanName']}
														</div>
													}
												{(!item['onDemandPlanName'] || item['onDemandPlanName'] === '' ) &&
														<div>
															-
														</div>
													}
													</td>
													<td>
														${item['amount']}
													</td>
													<td>
														<div className="refund_button" onClick={(e)=>this.openRefund(item['amount'], item['id'], item['billing_id'], item['email'], item['firstName'], item['lastName'], item['channelId'])}>Refund</div>
														{ item['refunds'] && item['refunds'].length > 0 &&
															<div className="info_btn chtop" onClick={(e) => this.showMoreInfo(e)}>
																<div className="all_info pink_info all_info_daily wdth600" >
																	<div className="close_all_info">
																		<div className="close_all_info_btn" onClick={ (e) => this.openMoreInfo(e, true)}>x</div>
																	</div>
																	<div className="scroll_all_info">
																	{ item['refunds'] &&
																		<table>
																			<thead>
																				<tr>
																					<td>Admin Id</td>
																					<td>Admin Name</td>
																					<td>Date</td>
																					<td>Amount</td>
																					<td>Status</td>
																				</tr>
																			</thead>
																			<tbody>
																			{ item.refunds.map((it, ind) => 
																				<tr key={ind}>
																					<td>{it['hostId']}</td>
																					<td>{it['firstName']} {it['lastName']}</td>
																					<td>{ this.getSessionDate(it['date'])} @ {this.getSessionTime(it['date'])}</td>
																					<td>${it['amount']}</td>
																					<td>{it['status']}</td>
																				</tr>
																			)}
																			</tbody>
																		</table>
																	}
																	</div>
																</div>
															</div>
														}
													</td>
													<td>
														{ JSON.parse(item.billData)[2] && <div className="refund_button" onClick={ (e) => { this.getPlanPdf(e, item); } }>PDF</div> }
														{ !JSON.parse(item.billData)[2] && <div>-</div> }
													</td>
												</tr>
											) }
										</tbody>
									</table>
								}
								{ this.state.planInfo.length > 0 && 
									<div className="showPageNavigation">
										<div className="showpinfo">Showing { this.state.page_id+1 } to { this.state.page_num_count } of { this.state.fullCount } billings</div>
										<div className="shopnumbers">
											
											{ (this.state.page_id - 1) > -1 &&
												<span onClick={(e) => this.changePage(e, this.state.page_id - 1)}>Previous</span>
											}
											
											<span>
												{ this.state.page_numbers.map((nitem, nindex) => 
													<span key={nindex} onClick={(e) => this.changePage(e, nitem-1)} className={"pgNumbers " + (this.state.page_id+1 === nitem ? 'active' : '')}>{nitem}</span>	
												)}
											</span>
											
											 { (this.state.page_id + 1) < this.state.maxPages &&
												<span onClick={(e) => this.changePage(e, this.state.page_id + 1)}>Next</span>
	
											 }
											</div>
									</div>
								}
								{ this.state.planInfo.length == 0 && this.state.ajaxLoaded && <div className="noResults2">No Billings Found</div> }
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Coming Soon</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
					</div>
				</div>
			</div>
			{this.state.refundPopUp &&
				<div className="modal pr-0 dspl_blck" id="refundPop">
					<div className="modal-dialog mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
								<button type="button" className="close white closepurple translatX-Y" onClick={()=>{this.setState({refundPopUp: false})}}><span>x</span></button>
								<p className="mb-0 py-4 white text-center">Refund Plan</p>
								<div className="form-group email_channel_settings refund_amount_area"><span className="cover-border"></span>
									<label className="label">Amount ($) *</label>
									<input type="number"
										id ="refundAmount"
										value = {this.state.refundAmount}
										onChange={(e)=>this.setState({[e.target.id]:e.target.value})}
										className="input-field refund_input" maxLength="500"
									/>
								</div> 
								<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={()=>{this.refund()}}>refund</button></div>
							</div>
						</div>
					</div>
				</div>
			}
			{this.state.showConfPopup &&
				<div className="modal pr-0 dspl_blck" id="change_statuspop" aria-modal="true">
					<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
								<div className="py-4 text-light text-center font-20">Are you sure you want to refund?</div> 	
							</div>
							<div className="text-center">
								<button type="button" className="custom_btn1 mt-3" onClick={()=>{this.confirmRefund()}}>Yes</button>
								<button type="button" className="custom_btn1 mt-3 ml-20" onClick={e=>{this.setState({showConfPopup: false})}}>No</button>
							</div>
						</div>
					</div>
				</div>
			}
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(ReportsAdminBilling);