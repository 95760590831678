import React, { Component } from "react";

class MainLoader extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
	
    }
	
}

  render() {
	 return (
		<div className="main_loader flex_loader">
			<div className="main_loader_inner"></div>
		</div>
    );
  }
}
export default MainLoader;
