import React, { Component } from "react";

// import axios from "axios";
// import {  browserHistory} from 'react-router'
// import { Link } from 'react-router';



//import $ from 'jquery';
//import DateTimeField from "react-bootstrap-datetimepicker";

class Support extends Component {
  
  constructor(props) {
	super(props);

    
}
 

componentDidMount(){
  }


  render() {
	


    return (
        <div>
            <div id="root">
                <div className="App">
                    <div className="container-fluid custom-width">
                        <div className="top-header px-5 pt-5">
                            <div className="">
                                <img src="images/login-logo.png" className="logo" />
                            </div>
                            <div className="privacy_banner position-relative">
                                <img src="images/terms-of-Use.png" alt="terms_use" className="w-100" />
                            </div>                                       
                        </div>
                        <div className="background_gray px-5 pt-5 pb-4">                               
                            <div className="faqs-screen pt-5">
                                <h3 className="purple_text font-32 mb-4 text-center font-book">Virdio Help Center</h3>
                                <p className="text-white font-18">Virdio support team ensures that our users expectations are met, always. Our support team is well trained to understand the users requirements and resolve issues related to our service/s. To improve our services and support, we appreciate suggestions and comments at any time.<br />Please mail your queries <a href="mailto:support@virdio.com" target="blank">@ support@virdio.com</a></p>
                                {/* <div id="accordion" className="mt-5 w-75">
                                    <div className="card">
                                    <div className="card-header">
                                        <a className="card-link" data-toggle="collapse" href="#collapseOne">
                                        <i className="fa fa-circle mr-3"></i>Will I be charged for using a Dial-In number to join a Virdio Meeting?
                                        </a>
                                    </div>
                                    <div id="collapseOne" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                        Virdio dial-in numbers are available based on subscription level. If you are the instructor of a meeting you can check to see what dial-in numbers are accessible to you and your member before you start. When you are invited to a meeting, you can check your invitation to see what dial-in numbers can be used. If you decide to use a toll number to join a Virdio meeting, you will be charged based on the rates of your phone service provider.
                                        </div>
                                    </div>
                                    </div>
                                    <div className="card">
                                    <div className="card-header">
                                        <a className="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                        <i className="fa fa-circle mr-3"></i>Personal meeting ID (PMI) and personal link
                                    </a>
                                    </div>
                                    <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                        Personal meeting ID and personal link are two ways to access your personal meeting room. Your personal meeting room is a virtual room that is permanently reserved for you. You can start it at any time or schedule it for future use. 
                                        </div>
                                    </div>
                                    </div>
                                    <div className="card">
                                    <div className="card-header">
                                        <a className="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                        <i className="fa fa-circle mr-3"></i>Where can I find the meeting invitation text?
                                        </a>
                                    </div>
                                    <div id="collapseThree" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                        You can find the invitation for a scheduled in the Virdio desktop client, Virdio mobile app, or on the web. You can copy the invitation and send it out to your attendees. You can share it anywhere that text can be pasted, but the most common methods to share the full invitation are via email or by adding it to a calendar invitation.
                                        </div>
                                    </div>
                                    </div>
                                    <div className="card">
                                    <div className="card-header">
                                        <a className="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                        <i className="fa fa-circle mr-3"></i>What is a Meeting ID?
                                        </a>
                                    </div>
                                    <div id="collapseThree" className="collapse" data-parent="#accordion">
                                        <div className="card-body">
                                        The meeting ID is the meeting number associated with an instant or scheduled meeting. The meeting ID can be a 9, 10 or 11-digit number. The 9-digit number is used for instant, scheduled or recurring meeting. The 10, or 11-digit number is used for Personal Meeting IDs.
                                        </div>
                                    </div>
                                    </div>
                                </div>                             */}
                            </div>
                        </div>            
                    </div>
                </div>
            </div>	
      </div>	
    );
  }
}

export default Support;
